import { graphData } from './store/models/graph-data.model';
import { LoginService } from 'src/app/core/services/login';
import { MatomoAnalyticsUtils } from './core/utils/matomo-analytics.utils';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_ID } from '@angular/core';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { LocalStorage } from 'src/app/core/utils/local-storage';
import { DateUtils } from 'src/app/core/utils';
import { Utils } from './core/utils/utils';

// Routes
import { APP_ROUTES } from './app.routes.module';

// Modules
import { GuardsModule } from './core/guards/guards.module';

// Interceptors
import { ErrorInterceptor } from './core/services/shared/error-interceptor.service';
import { JwtInterceptor } from './core/services/shared/jwt-interceptor.service';

// Components
import { AppComponent } from 'src/app/app.component';
import { PagesModule } from 'src/app/pages/pages.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LayoutModule } from './core/layout/layout.module';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { IConfig } from "ngx-mask";
import { NgxMaskModule } from "ngx-mask";
import { CoreServiceModule } from './core/services/core-services.module';
import { QuillModule } from 'ngx-quill';
// import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
// import { GoogleLoginProvider } from 'angularx-social-login';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxDocViewerModule } from 'ngx-doc-viewer'

import { MatomoModule } from 'ngx-matomo-client';
import { NbThemeModule, NbLayoutModule, NbDatepickerModule, NbToastrModule, NbWindowModule, NbSpinnerModule, NbTabsetModule } from '@nebular/theme';
import { NbButtonModule, NbCardModule } from '@nebular/theme';
import { NbGlobalLogicalPosition, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';

import { userReducer } from './store/reducers/user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { profilesReducer } from './store/reducers/profiles.reducer';
import { selectedProfileReducer } from './store/reducers/selected-profile.reducer';
import { graphDataReducer } from "./store/reducers/graph-data.reducer";
import { CountdownModule } from "ngx-countdown";
import { CookieService } from 'ngx-cookie-service';
import { CookieModule } from 'ngx-cookie';
import { InactivityService } from './core/services/inactivity/inactivity.service';
import { GoogleChartsModule } from 'angular-google-charts';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { uiStateReducer } from './store/reducers/ui-state.reducer';
import { NgxPaginationModule } from 'ngx-pagination';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	//return new TranslateHttpLoader(http);
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// let config = new AuthServiceConfig([
// 	{
// 		id: GoogleLoginProvider.PROVIDER_ID,
// 		provider: new GoogleLoginProvider(
// 			"1043386557581-d8mkegi24n815n5lnurtfau60a49sk0t.apps.googleusercontent.com",
// 			{ scope: "https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.gender.read" }
// 		),

// 	}
// ]);

let physicalPositions = NbGlobalPhysicalPosition;
let logicalPositions = NbGlobalLogicalPosition;

// export function provideConfig() {
// 	return config;
// }

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [NgxPaginationModule,
        LayoutModule,
        APP_ROUTES,
        GuardsModule,
        PagesModule,
        BrowserAnimationsModule,
        MatDialogModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NgxMaskModule.forRoot({
            validation: false,
        }),
        CoreServiceModule,
        QuillModule.forRoot(),
        //SocialLoginModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
        }),
        NgxCaptchaModule,
        NgxDocViewerModule,
        MatomoModule.forRoot({
            scriptUrl: "//matomo.salware.com/matomo.js",
            trackers: [
                {
                    trackerUrl: "http://matomo.salware.com/matomo.php",
                    siteId: environment.idMatomo,
                },
            ],

        }),
        NbThemeModule.forRoot({ name: "default" }),
        NbLayoutModule,
        NbButtonModule,
        NbSpinnerModule,
        NbCardModule,
        NbTabsetModule,
        NbDatepickerModule.forRoot(),
        NbToastrModule.forRoot({
            destroyByClick: true,
            duration: 3000,
            preventDuplicates: true,
            position: NbGlobalPhysicalPosition.TOP_RIGHT,
        }),
        StoreModule.forRoot({
            user: userReducer,
            profiles: profilesReducer,
            selectedProfile: selectedProfileReducer,
            graphData: graphDataReducer,
            uiState: uiStateReducer,
        }, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        CountdownModule,
        //EffectsModule.forRoot([UserEffects]),
        BrowserModule,
        CookieModule.forRoot(),
        NbWindowModule.forRoot({
            buttons: {
                minimize: false,
                maximize: true,
                fullScreen: true,
                close: true,
            },
            closeOnBackdropClick: false,
        }),
        GoogleChartsModule], providers: [
        MatomoAnalyticsUtils,
        DateUtils,
        Utils,
        { provide: APP_ID, useValue: 'ng-cli-universal' },
        { provide: LocalStorage, useFactory: getLocalStorage },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
            deps: [LoginService],
        },
        // {
        // 	provide: AuthServiceConfig,
        // 	useFactory: provideConfig
        // },
        CookieService,
        InactivityService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}

export function getLocalStorage() {
	return window.localStorage;
}


