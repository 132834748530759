<button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon ngbTooltip="{{'REPORTBUTTON.MESSAGE' | translate }}" class="icon-head icon-size-medium pointer">sms</mat-icon>
</button>
<mat-menu class="w-100" #menu="matMenu">
    <button mat-menu-item>
        <mat-icon>comment</mat-icon>
        <span>{{'REPORTBUTTON.COMMENT' | translate }}</span>
    </button>
    <button mat-menu-item>
        <mat-icon>error_outline</mat-icon>
        <span>{{'REPORTBUTTON.ERROR' | translate }}</span>
    </button>
    <button mat-menu-item>
        <mat-icon>flag</mat-icon>
        <span>{{'REPORTBUTTON.REPORT' | translate }}</span>
    </button>
</mat-menu>
