export const FILE_FORMATS = {
    AUDIO_TYPE: 'audio',
    IMAGE_TYPE: 'picture',
    PDF_TYPE: 'pdf',
    TXT_TYPE: 'text',
    VIDEO_TYPE: 'video',
    VIDEO_PREVIEW_TYPE: 'videoImage'
}

export const AVAILABLE_TYPE_FILES = [
    'application/rtf',
    'application/pdf',
    'text/plain',
    'text/rtf',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-excel',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
]
