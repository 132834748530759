<div class="sticky-top">
    <div class="modal-header" align="center">
        <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'ESTUDIANTECENTRO.SELECCIONEUNCENTRO' | translate }}</span>

        <button type="button" class="close" data-dismiss="modal" (click)="closeModal('')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>

<div class="modal-body fondoEditarCurso" [ngClass]="userService.getColorByRol()">

    <div class="row mt-2">
        <div class="col-12">
            <app-alert context="ModalEstudianteCentro"></app-alert>
        </div>
    </div>

    <div class="row mt-2">

        <div [ngClass]="profile.toLowerCase() === 'profesor' ? 'col-sm-8' : 'col-sm-12'">

            <input (input)="buscarCentro()" [(ngModel)]="textoCentro" class="form-control textoFontSize09 colorTextoActivado" type="text" placeholder="{{ 'ESTUDIANTECENTRO.SELECCIONETEXTOCENTROSDESEABUSCAR' | translate }}">
        </div>

        <div *ngIf="profile.toLowerCase() === 'profesor'" class="col-sm-4">
            <button (click)="crearCentro()" class="botonLoginRegistrar">{{ 'ESTUDIANTECENTRO.NOEXISTECENTRO' | translate }}</button>
        </div>
    </div>

    <div class="row">

        <div class="col-sm-12">
            <div class="form-control mt-2" style="max-height: 500px; overflow-y: auto;">

                <div *ngFor="let center of centersFilter | sortBy:'asc':'centerName'" (click)="obtenerDatosCentro(center.idCenter)" class="p-1 pointer">
                    <span class="textoFontSize09 colorTextoActivado">  {{ center.idCenter}} - {{ center.centerName}}</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="mostrarDatosCentro" class="mt-2">

        <div class="row my-3">
            <div class="col-12" align="center">
                <span class="textoFontSize1 colorTextoActivado"> {{ 'ESTUDIANTECENTRO.DATOSDELCENTRO' | translate }} </span>
            </div>
        </div>

        <div class="row">

            <div class="col-sm-4">
                <div class="card form-group">
                    <img *ngIf="center != undefined" [src]="center.centerPicture | imagen:'center'" class="card-img-top img-circle">
                </div>
            </div>

            <div class="col-sm-8">
                <div class="row">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">location_city</i>
                    </div>

                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.NOMBRE' | translate }}</mat-label>
                            <input matInput [ngModel]="center.centerName" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">map</i>
                    </div>

                    <div clasS="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.DIRECCION' | translate }}</mat-label>
                            <input matInput [ngModel]="center.centerAddress" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">location_on</i>
                    </div>

                    <div clasS="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.PAIS' | translate }}</mat-label>
                            <input matInput [ngModel]="center.centerCountry" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">mail_outline</i>
                    </div>

                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.EMAIL' | translate }}</mat-label>
                            <input matInput [ngModel]="center.centerMail" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">web</i>
                    </div>

                    <div clasS="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.URL' | translate }}</mat-label>
                            <input matInput [ngModel]="center.centerUrl" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">

                    <div class="col-8"></div>

                    <div class="col-4" align="right">
                        <button (click)="asignarCentro()" class="botonLoginRegistrar">{{ 'ESTUDIANTECENTRO.ASIGNAR' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!mostrarDatosCentro" class="row mt-2">

        <div class="col-12 my-5" align="center">
            <span class="textoFontSize09 colorTextoActivado">{{ 'ESTUDIANTECENTRO.SELECCIONEUNCENTROPARAASIGNAR' | translate }}</span>
        </div>
    </div>
</div>
