<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="border-bottom-0">
		<!-- Titulo de la pantalla -->
		<div class="container">
			<div class="d-flex flex-column gap-3 text-center">
				<!-- Titulo -->
				<div class="form-group">
					<h6>{{ survey.title }}</h6>
				</div>
				<!-- Descripcion -->
				<div class="form-group">
					<p class="m-0">{{ survey.description }}</p>
				</div>
			</div>
		</div>
	</nb-card-header>

	<nb-card-body class="p-0 p-md-3">
		<div class="container">
			<div class="d-flex">
				<app-loading [isVisible]="cargando"></app-loading>
				<!-- TARJETAS PREGUNTAS -->
				<div *ngIf="survey.questionsList.length !== 0" class="d-flex flex-column w-100 gap-5 py-4">
					<div *ngFor="let question of survey.questionsList; let i = index" class="d-flex"
					[ngStyle]="{'color': !listAnswers[i].ok && showMessage ? 'red' : '',
					'border': !listAnswers[i].ok && showMessage ? 'solid' : ''}">
						<app-surveys-question-answer
						[question]="question"
						[answerElement]="listAnswers[i]"
						[index]="i"
						(responseSelected)="onResponseSelected($event)"
						class="w-100">
						</app-surveys-question-answer>
					</div>
					<!-- Enviar respuestas -->
					<div class="d-flex flex-column justify-content-center align-items-center">
						<button nbButton size="medium" hero  status="primary" (click)="sendResponse()">
							{{ 'ENCUESTAS.SENDANSWER' | translate }}
						</button>
						<p style="color: red;" *ngIf="showMessage">*{{ 'ENCUESTAS.ANSWERPENDING' | translate }}</p>
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>
