export enum LOCALSTORAGESTRINGS{
    GETACTIVITY  =  'modoTraerActividad',
    LANG = 'language',
    PADSLOCALCATEGORY = 'padStatus',
    LABELSLOCALCATEGORY = 'labelStatus',
    USER = 'user',
    COURSEID = 'cursoID',
    GRAPHID = 'grafoID',
    NODEX = 'nodeX',
    NODEY = 'nodeY',
    SEARCHNODE = 'buscarNodo',
    CONFIGSITE = 'config-site',
    N1 = 'n1',
    CURRENTUSER = 'currentUser',
    WINDOW = 'window',
    CANVASVISIBILITY  = 'canvasVisibility',
    TUTORIALDATA = 'TutorialLocalStorageData',
    HISTORYDATAACTIVITIES = 'DATA_HISTORICO_ACTIVIDADES',
    HISTORYDATEACTIVITIES = 'DATE_HISTORICO_ACTIVIDADES',
    AUTOMATIC = 'automatic',
		TOKEN = 'token',
		GRAPHSHISTORY = 'graphsHistory',
		GRAPHSHISTORY_EDITOR = 'graphsHistory_editor',
		LOADINITIALGRAPH = 'loadInitialGraph'
}
