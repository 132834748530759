<nb-card class="no-edge-card mb-0">
	<app-loading [isVisible]="cargando"></app-loading>
	<nb-card-header class="flex justify-content-between">
		<div class="col-11">
			<h5>{{"TOTALHISTORYUSER.GLOBALDATA" | translate }}</h5>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			 (click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="row">
			<div class="col-12">

				<div class="container">
					<form [formGroup]="formDate" (keydown.enter)="$event.preventDefault()">
						<div class="row mt-4">

							<div class="col-lg-6">
								<div class="row">
									<div class="col-12 col-md-6 d-flex flex-column">
										<label>{{"TOTALHISTORYUSER.DATEFROM" | translate }}</label>
										<input type="date" nbInput fullWidth fieldSize="small" formControlName="desde" class="form-control" />
									</div>

									<div class="col-12 col-md-6 d-flex flex-column mt-3 mt-md-0">
										<label>{{"TOTALHISTORYUSER.DATETO" | translate }}</label>
										<input type="date" nbInput fullWidth fieldSize="small" formControlName="hasta" class="form-control" />

										<div>
											<button nbButton class="btn-themecolor mt-3" size="small" (click)="filterDate()">
												{{"TOTALHISTORYUSER.SEARCH" | translate }}
											</button>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-6">
								<div class="row justify-content-lg-end mt-lg-0 mt-3">
									<div class="col-12 col-md-6 d-flex flex-column">
										<label>{{"TOTALHISTORYUSER.FILTER" | translate }}</label>
										<div class="d-flex">
											<input formControlName="search" type="search" nbInput fullWidth fieldSize="small" class="form-control" (keyup)="filterDataSource($event)" />
											<button nbSuffix nbButton ghost size="small" (click)="clearFilter()">
												<nb-icon class="pointer" icon="close-circle-outline" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
											</button>
										</div>
									</div>

									<div class="col-12 col-md-6 d-flex flex-column align-items-center" *ngIf="dataSource" (click)="exportToXLS(dataSource)">
										<label>{{"TOTALHISTORYUSER.DOWNLOADEXCEL" | translate }}</label>
										<span>
											<mat-icon svgIcon="icon-excel"></mat-icon>
										</span>
									</div>
								</div>
							</div>

						</div>
					</form>
				</div>

				<div class="container">
					<div class="row">
						<div class="col-12" *ngIf="dataSource" style="overflow-x: auto">
							<table mat-table [dataSource]="dataSource" class=" total-data-table" matSort
								(matSortChange)="announceSortChange($event)">

								<!--- Note that these columns can be defined in any order.
											The actual rendered columns are set as a property on the row definition" -->

								<!-- Position Column -->
								<ng-container matColumnDef="fecha">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by fecha"> {{
										"TOTALHISTORYUSER.FECHA" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
								</ng-container>

								<!-- accesos_diarios Column -->
								<ng-container matColumnDef="accesos_diarios">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by accesos_diarios">
										{{ "TOTALHISTORYUSER.ACCESOS_DIARIOS" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.accesos_diarios}} </td>
								</ng-container>

								<!-- us_totals Column -->
								<ng-container matColumnDef="us_totals">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by us_totals"> {{ "TOTALHISTORYUSER.US_TOTALS" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.us_totals}} </td>
								</ng-container>

								<!-- us_estudiantes Column -->
								<ng-container matColumnDef="us_estudiantes">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by us_estudiantes"> {{ "TOTALHISTORYUSER.US_ESTUDIANTES" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.us_estudiantes}} </td>
								</ng-container>

								<!-- us_profesores Column -->
								<ng-container matColumnDef="us_profesores">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by us_profesores"> {{ "TOTALHISTORYUSER.US_PROFESORES" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.us_profesores}} </td>
								</ng-container>

								<!-- us_padres Column -->
								<ng-container matColumnDef="us_padres">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by us_padres"> {{ "TOTALHISTORYUSER.US_PADRES" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.us_padres}} </td>
								</ng-container>

								<!-- proyectos Column -->
								<ng-container matColumnDef="proyectos">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by proyectos"> {{ "TOTALHISTORYUSER.PROYECTOS" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.proyectos}} </td>
								</ng-container>

								<!-- ideografos Column -->
								<ng-container matColumnDef="ideografos">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ideografos"> {{ "TOTALHISTORYUSER.IDEOGRAFOS" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.ideografos}} </td>
								</ng-container>
								<!-- idaes Column -->
								<ng-container matColumnDef="ideas">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ideas"> {{ "TOTALHISTORYUSER.IDEAS" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.ideas}} </td>
								</ng-container>

								<!-- actividades Column -->
								<ng-container matColumnDef="actividades">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by actividades"> {{ "TOTALHISTORYUSER.ACTIVIDADES" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.actividades}} </td>
								</ng-container>

								<!-- videos_subidos Column -->
								<ng-container matColumnDef="videos_subidos">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by videos_subidos"> {{ "TOTALHISTORYUSER.VIDEOS_SUBIDOS" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.videos_subidos}} </td>
								</ng-container>

								<!-- Positimagenes_subidasion Column -->
								<ng-container matColumnDef="imagenes_subidas">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by imagenes_subidas"> {{ "TOTALHISTORYUSER.IMAGENES_SUBIDAS" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.imagenes_subidas}} </td>
								</ng-container>

								<!-- audios_subidos Column -->
								<ng-container matColumnDef="audios_subidos">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by audios_subidos"> {{ "TOTALHISTORYUSER.AUDIOS_SUBIDOS" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.audios_subidos}} </td>
								</ng-container>

								<!-- documentos_subidos Column -->
								<ng-container matColumnDef="documentos_subidos">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by documentos_subidos"> {{ "TOTALHISTORYUSER.DOCUMENTOS_SUBIDOS" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.documentos_subidos}} </td>
								</ng-container>

								<!-- textos_subidos Column -->
								<ng-container matColumnDef="textos_subidos">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by textos_subidos"> {{ "TOTALHISTORYUSER.TEXTOS_SUBIDOS" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.textos_subidos}} </td>
								</ng-container>

								<!-- desafios Column -->
								<ng-container matColumnDef="desafios">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by desafios"> {{ "TOTALHISTORYUSER.DESAFIOS" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.desafios}} </td>
								</ng-container>

								<!-- ideografos_abiertos Column -->
								<ng-container matColumnDef="ideografos_abiertos">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ideografos_abiertos"> {{ "TOTALHISTORYUSER.IDEOGRAFOS_ABIERTOS" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.ideografos_abiertos}} </td>
								</ng-container>

								<!-- ideas_abiertas Column -->
								<ng-container matColumnDef="ideas_abiertas">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ideas_abiertas"> {{ "TOTALHISTORYUSER.IDEAS_ABIERTAS" | translate }}. </th>
									<td mat-cell *matCellDef="let element"> {{element.ideas_abiertas}} </td>
								</ng-container>

								<!-- actividades_resp Column -->
								<ng-container matColumnDef="actividades_resp">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by actividades_resp"> {{ "TOTALHISTORYUSER.ACTIVIDADES_RESP" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.actividades_resp}} </td>
								</ng-container>

								<!-- bajas_us_total Column -->
								<ng-container matColumnDef="bajas_us_total">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by bajas_us_total"> {{ "TOTALHISTORYUSER.BAJAS_US_TOTAL" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.bajas_us_total}} </td>
								</ng-container>

								<!-- bajas_us_estudiantes Column -->
								<ng-container matColumnDef="bajas_us_estudiantes">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by bajas_us_estudiantes"> {{ "TOTALHISTORYUSER.BAJAS_US_ESTUDIANTES" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.bajas_us_estudiantes}} </td>
								</ng-container>

								<!-- bajas_us_profesores Column -->
								<ng-container matColumnDef="bajas_us_profesores">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by bajas_us_profesores"> {{ "TOTALHISTORYUSER.BAJAS_US_PROFESORES" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.bajas_us_profesores}} </td>
								</ng-container>

								<!-- bajas_us_padres Column -->
								<ng-container matColumnDef="bajas_us_padres">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by bajas_us_padres"> {{ "TOTALHISTORYUSER.BAJAS_US_PADRES" | translate }} </th>
									<td mat-cell *matCellDef="let element"> {{element.bajas_us_padres}} </td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
							</table>

							<mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons
								aria-label="Select page of periodic elements">
							</mat-paginator>

						</div>

						<div class="col-12 flex justify-content-center align-content-center mt-5" *ngIf="!dataSource">
							<div class="col-md-6 no-data-message">
								<p>{{"TOTALHISTORYUSER.NODATATOSHOW" | translate }}.</p>
								<p>{{"TOTALHISTORYUSER.PRESSTOSEARCH" | translate }}.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>
