<form class="container" [formGroup]="answerForm">
	<ng-container formArrayName="answers">
		<div class="row" *ngFor="let answer of answersArray.controls; let i = index; trackBy: trackBy;">
			<div class="col d-flex align-items-center" [formGroupName]="i">
				<ng-container [ngSwitch]="answersArrayType[i].value">
					<div class="col-12 d-flex align-items-center" *ngSwitchCase="elementTypes.A_TEXTS">
						<div class="col-11 d-flex flex-column">
							<label>{{'CURSOMODOAUTO.EXPLICACION' | translate}} {{i + 1}}</label>
							<textarea nbInput fullWidth class="form-control" formControlName="data" (blur)="onTextBlur(i,null,$event)" rows="4"
								(keyup)="onKeyUp($event,i)" maxlength="500">
							</textarea>
						</div>
						<button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
							<mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
						</button>
					</div>

					<div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.A_PICTURES">
						<div ngResizable (rzStop)="onResizeStop($event,i)"
							[style.width]="answersArrayWidth[i].value ? answersArrayWidth[i].value + 'px' : 'fit-content'"
							[style.height]="answersArrayHeight[i].value ? answersArrayHeight[i].value + 'px' : '100%'">
							<img class="w-100 h-100" [src]="answersArrayData[i].value| quizElements:'image' |safe"
								*ngIf="answersArrayData[i].value" />
						</div>

						<mat-progress-bar mode="buffer" *ngIf="!answersArrayData[i].value"></mat-progress-bar>
						<button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
							<mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
						</button>
					</div>

					<div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.A_VIDEOS">

						<div ngResizable (rzStop)="onResizeStop($event,i)"
							[style.width]="answersArrayWidth[i].value ? answersArrayWidth[i].value + 'px' : 'fit-content'"
							[style.height]="answersArrayHeight[i].value ? answersArrayHeight[i].value + 'px' : '100%'">
							<video class="w-100 h-100" controls [src]="answersArrayData[i].value | quizElements:'video' | safe"
								*ngIf="answersArrayData[i].value">
							</video>
						</div>

						<mat-progress-bar mode="buffer" *ngIf="!answersArrayData[i].value"></mat-progress-bar>
						<button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
							<mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
						</button>
					</div>

					<div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.A_AUDIOS">
						<audio class="w-100 d-block" controls [src]="answersArrayData[i].value | quizElements:'audio' | safe"
							*ngIf="answersArrayData[i].value">
						</audio>
						<mat-progress-bar mode="buffer" *ngIf="!answersArrayData[i].value"></mat-progress-bar>
						<button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
							<mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
						</button>
					</div>

					<div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.A_PDFS">
						<div ngResizable (rzStop)="onResizeStop($event,i)"
							[style.width]="answersArrayWidth[i].value ? answersArrayWidth[i].value + 'px' : 'fit-content'"
							[style.height]="answersArrayHeight[i].value ? answersArrayHeight[i].value + 'px' : '100%'">
							<iframe #pdfElement class="w-100 h-100" [src]="answersArrayData[i].value | quizElements:'pdf' | safe"
								allowfullscreen *ngIf="answersArrayData[i].value"></iframe>
						</div>

						<mat-progress-bar mode="buffer" *ngIf="!answersArrayData[i].value"></mat-progress-bar>
						<button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
							<mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
						</button>
					</div>
				</ng-container>

				<div
					[ngClass]="{'h-100 flex justify-content-center align-items-center': answersArrayData[i].value !== elementTypes.A_TEXTS}">
					<button mat-icon-button class="ml-2" *ngIf="answersArray.controls.length > 1 " (click)="onDeleteClick(i)">
						<mat-icon class="mat-icon-white">delete</mat-icon>
					</button>
				</div>
			</div>
		</div>
	</ng-container>
</form>
