<div class="container p-2">

    <div class="row d-flex justify-content-center align-items-center">

        <div class="w-100 d-flex justify-content-center m-2 ">
            <mat-icon class="text-center"  svgIcon="warning"></mat-icon>
        </div>

        <div class="col-12 p-2">
            <p class="text-center">{{'EDITORS.REMOVEEDITORS' | translate}}</p>
            <p class="text-center">{{'EDITORS.CANTEDIT' | translate}}</p>
        </div>
        <div class="col-4">
            <button class="btn btn-block btn-sm btn-danger" type="text" (click)="aceptar()">{{'GLOBALS.OK' | translate}}</button>
        </div>
        <div class="col-4">
            <button class="btn btn-block btn-sm btn-light" type="text" (click)="cancelar()">{{'GLOBALS.CANCEL' | translate}}</button>
        </div>
    </div>

</div>
