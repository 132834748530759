<button mat-icon-button [matMenuTriggerFor]="menuRedesSociales">
    <mat-icon ngbTooltip="{{'MISC.SHARE' | translate }}" class="icon-head icon-size-medium pointer">
        share
    </mat-icon>
</button>
<mat-menu #menuRedesSociales="matMenu">
    <div class="row mx-auto align-items-center justify-content-center">
        <div class="col-lg-3" *ngFor="let btn of buttons; let i = index">
            <button *ngIf="i !== 4" class="mt-2" [ngStyle]="{'background-color': btn.color}">
                <a class="enlace_desactivado" target="_blank">
                    <i class="{{btn.icon}}" style="font-size:24px; color: white"></i>
                </a>
            </button>
            <button *ngIf="i === 4" class="mt-2" [ngStyle]="{'color': btn.color}">
                <mat-icon class="button-salware" svgIcon={{btn.icon}} (click)="shareOnSalware()"></mat-icon>
            </button>
        </div>
    </div>
</mat-menu>
