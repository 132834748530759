<mat-dialog-content class="mat-typography">
    <div id="texte_a_afficher" class="texte_inselectionnable">
        <span class="stabilisation"></span>
        <p style="text-align: center;"><strong>NOSOTROS</strong></p>
        <p style="text-align: left;"><span class="flou"><br></span>La Fundación Salware fue constituida el 13 de
            noviembre de 2020 ante el notario Don Ignacio Gomá Lanzón,
            siendo los patronos fundadores:
        </p>

        <p>PRESIDENTE: Ramón Antonio García Salmerón</p>

        <p>SECRETARIA: Carolina Marín Martín</p>

        <p>VOCAL: Rafael Hurtado Coll</p>

        <p>VOCAL: Manuel López Acebedo</p>

        <p>VOCAL: Gonzalo Martínez González</p>
        <p>La Fundación Salware desarrolla su actividad en España, y realiza casi la totalidad de sus actividades en
            línea,
            teniendo su sede social en Madrid, calle María Francisca, 9. El código de identificación fiscal español es
            G02863587. Su correo de contacto
            es fundacion@salware.com.
        </p>
        <p>Está inscrita en Registro de Fundaciones de la Comunidad de Madrid el 21 de diciembre de 2021 con el número
            03/782704.9/21, en el tomo CCXCIII. Folios 1-28.
        </p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
</mat-dialog-actions>