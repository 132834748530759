import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

// Utils
import { DomainRouting } from 'src/app/core/utils';

// Services
import { LoginService } from 'src/app/core/services/login';

@Injectable()
export class AuthGuard implements CanActivate {
    public constructor(private router: Router,
                       private domainRoutingService: DomainRouting,
                       private loginService: LoginService ) { }

    public canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot ) {

        let canActivate = false;

        if (!this.loginService.validateUserLocalStorage()) {
            if (this.isInBrowser()) {
                this.domainRoutingService.redirectToAuthPoint( this.router, routerStateSnapshot.url );
            }
        } else {
            canActivate = true;
        }

        return canActivate;
    }

    private isInBrowser(): boolean {
        return typeof window !== 'undefined';
    }
}
