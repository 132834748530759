
import { ActionTypes, ActionsUnion } from '../actions/user.action';
import { initialUser } from '../models/user.model';

export function userReducer(state = initialUser, action: ActionsUnion) {
  switch (action.type) {
    case ActionTypes.register:
			return action.payload

    default:
			return state;
  }
}
