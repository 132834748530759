import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalStorage } from '../../utils';
export interface VisibilityCanvas {  nodes: boolean; quizzes: boolean; }
@Injectable({
  providedIn: 'root'
})

export class SigmaToolbarsService {

  public helpStatus = new Subject<boolean>(); //new Observable<boolean>();
  public showInfo = new Subject<boolean>();
  public visibility = new Subject<{nodes: boolean, quizzes: boolean}>();
  public showModalCourses = new Subject<string>();
  public hideElements = new Subject<boolean>()
  public hideMenuLateral = new Subject<boolean>();
	public updateGraph = new Subject<boolean>();

  constructor(private localStrage: LocalStorage) { }

  public changeHelpStatus(status: boolean): void {
    this.helpStatus.next(status);
  }

  public changeShowInfo( showInfo ){
    this.showInfo.next(showInfo);
  }

  public changeVisibility( visibility ) {
    this.setItemVisibility(visibility);
    this.visibility.next(visibility);
  }

  public changeShowModalCourses(value:string){
    this.showModalCourses.next(value)
  }

  public setItemVisibility(values:VisibilityCanvas){
    this.localStrage.setItem(LOCALSTORAGESTRINGS.CANVASVISIBILITY, JSON.stringify(values));
  }

  public setHideElements(): void{
    this.hideElements.next(true);
  }

  public setHideMenuLateral():void{
    this.hideMenuLateral.next(true)
  }

	public changeUpdateGraph(status:boolean){
		this.updateGraph.next(status)
	}
}
