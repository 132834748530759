import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { QuizTemplateElement } from 'src/app/core/models/quizzes/quiz-template-element.model';
import { TemplateElementType } from 'src/app/core/models/quizzes/template-element-type.model';
import { TEMPLATE_ELEMENTS_TYPES } from 'src/app/core/utils/template-elements-types';

@Component({
    selector: 'app-quiz-edit-template-element',
    templateUrl: './quiz-edit-template-element.component.html',
    styleUrls: ['./quiz-edit-template-element.component.scss']
})
export class QuizEditTemplateElementComponent implements OnInit, OnChanges {
    @Input() element: QuizTemplateElement;
    @Input() editable: boolean = false;

    @Output() templateElementFileUpload: EventEmitter<any> = new EventEmitter<any>();
    @Output() templateElementOptionValue: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() templateElementSetDescription: EventEmitter<QuizTemplateElement> = new EventEmitter<QuizTemplateElement>();

    type: TemplateElementType;
    // templateElementsTypes: TemplateElementType[] = TEMPLATE_ELEMENTS_TYPES;

    constructor() { }

    ngOnInit() {
        this.type = TEMPLATE_ELEMENTS_TYPES[this.element.elementsType.idElementType];
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    onChange(event: any) {
        this.templateElementOptionValue.next((event.target.value) === 'true');
    }

    loadFile(fileEvent) {
        this.templateElementFileUpload.next(fileEvent);
    }

    setDescription() {
        this.templateElementSetDescription.next(this.element);
    }
}
