<div class="sticky-top sticky-top-header">
    <div class="modal-header" align="center">
        <div class="col-1">
            <i class="material-icons ti-size-2">person_search</i>
        </div>
        <div class="col-2">
            <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'TASK.RIVALSEARCH' | translate }}</span>
        </div>
        <div class="col-8 text-center">
            <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{task.nomTarea}}</span>
        </div>
        <div class="col-1 text-center">
            <button type="button" class="close pointer" [ngStyle]="{'color':'rgb(29, 73, 153)'}" data-dismiss="modal"
                (click)="closeModal('')">
                <span class="textoFontSize18" aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
