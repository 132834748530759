import { CommonModule, DatePipe } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule } from "@angular/router";

import { NgbModule, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { WebcamModule } from "ngx-webcam";

// Envelope
import { FileUploadModule } from "ng2-file-upload";

// Modules
import { MaterialModule } from "./material.module";

// Pipes
import { TranslateModule } from "@ngx-translate/core";

// Providers
import { ColorPickerModule } from "ngx-color-picker";

// Services
import { CoreServiceModule } from "../core/services/core-services.module";
// tslint:disable-next-line: max-line-length
import { NgxPrintModule } from "ngx-print";

import { NgxMaskModule } from "ngx-mask";

import { ResizableModule } from "angular-resizable-element";
import { SharedModule } from "../shared/shared.module";
import { UserDataModule } from "./user-data/user-data.module";
import { SortablejsModule } from "ngx-sortablejs";
import { NoPageFoundComponent } from "./no-page-found/no-page-found.component";
import { TableInformeComponent } from "./table-informe/table-informe.component";

@NgModule({
	declarations: [
		// No Page
		NoPageFoundComponent,
	],
	imports: [
		SharedModule,
		CommonModule,
		HttpClientModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		CoreServiceModule,
		FileUploadModule,
		WebcamModule,
		MaterialModule,
		ColorPickerModule,
		NgxPrintModule,
		NgbModule,
		ColorPickerModule,
		ResizableModule,
		NgxMaskModule,
		UserDataModule,
		SortablejsModule,
	],
	exports: [
		// No Page
		NoPageFoundComponent,
		MaterialModule,
		SortablejsModule,
		TranslateModule,
	],
	providers: [NgbActiveModal, DatePipe],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PagesModule {}
