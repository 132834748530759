import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';

import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { TranslateService } from '@ngx-translate/core';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { UsersService } from 'src/app/core/services/users';
import { LocalStorage } from 'src/app/core/utils';
import { formsValidations } from 'src/app/core/utils/forms-validations';

@Component({
  selector: 'app-modal-change-pass',
  templateUrl: './modal-change-pass.component.html',
  styleUrls: ['./modal-change-pass.component.scss']
})
export class ModalChangePassComponent implements OnInit{

	@Output() action: EventEmitter<boolean> = new EventEmitter<boolean>()

  mensaje: string;
	optionalTitle: string;
	disableCancel: boolean;
	formulario: UntypedFormGroup;
	validationMessages = {
		oldPassword: [],
		newPassword: [],
		newPassword2: [],
	};
	lang: string;
	public hidePassword = true;
	public hidePassword2 = true;
	public hidePassword3 = true;
	passCoincide: boolean = true;

  constructor(
		public translateService: TranslateService,
		private activeModal: NgbActiveModal,
		private formBuild: UntypedFormBuilder,
		private localStorage: LocalStorage,
		private dateAdapter: DateAdapter<Date>,
		private userService: UsersService,
		private toaster: ToasterService
		){}

	ngOnInit(): void {
		this.crearInitialForm();
		this.traducirOpciones();
	}

  aceptar() {
		if(this.formulario.invalid && !this.passCoincide){
			false;
		} else{
			const fv1 = this.formulario.value;
			this.userService.changePassword(fv1.oldPassword, fv1.newPassword).subscribe(res => {
				if(res.error.code === 0){
					//this.activeModal.close(true);
					this.action.emit(true);
				} else {
					this.toaster.error(this.translateService.instant(res.error.msg));
				}
			});
		}
  }

  cancelar() {
    //this.activeModal.close(false);
			this.action.emit(false);
  }

  closeModal(sendData?: any) {
    this.activeModal.close(false);
  }

	crearInitialForm() {
		this.formulario = this.formBuild.group({
			oldPassword: ["",
				{
					validators: Validators.compose([Validators.required]),
				},
			],
			newPassword: ["",
				{
					validators: Validators.compose([Validators.required, Validators.pattern(formsValidations.PASSWORDPATTERN)]),
				},
			],
			newPassword2: ["",
				{
					validators: Validators.compose([Validators.required, Validators.pattern(formsValidations.PASSWORDPATTERN)]),
				},
			]
		});
	}

	validacionPass(){
		const fv1 = this.formulario.value;
		if((fv1.newPassword === fv1.newPassword2)){
			this.passCoincide = true;
		} else {
			this.passCoincide = false;
		}
	}

	traducirOpciones() {
		// Recupero el lenguaje
		this.lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
		this.translateService.use(this.lang);
		this.dateAdapter.setLocale(this.lang);

		this.translateService
			.get("VALIDACIONES.LACONTRASENAESOBLIGATORIA")
			.subscribe((res: string) => {
				this.validationMessages.oldPassword.push({ type: "required",	message: res });
			});
		this.translateService
			.get("VALIDACIONES.PASSWORDPATTERN")
			.subscribe((res: string) => {
				this.validationMessages.oldPassword.push({ type: "pattern", message: res });
			});

		this.translateService
			.get("VALIDACIONES.LACONTRASENAESOBLIGATORIA")
			.subscribe((res: string) => {
				this.validationMessages.newPassword.push({ type: "required",	message: res });
			});
		this.translateService
			.get("VALIDACIONES.PASSWORDPATTERN")
			.subscribe((res: string) => {
				this.validationMessages.newPassword.push({ type: "pattern", message: res });
			});

		this.translateService
			.get("VALIDACIONES.LACONTRASENAESOBLIGATORIA")
			.subscribe((res: string) => {
				this.validationMessages.newPassword2.push({ type: "required",	message: res });
			});
		this.translateService
			.get("VALIDACIONES.PASSWORDPATTERN")
			.subscribe((res: string) => {
				this.validationMessages.newPassword2.push({ type: "pattern", message: res });
			});
	}

	get oldPasswordControl(): UntypedFormControl {
		if (this.formulario) {
			return this.formulario.get("oldPassword") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get newPasswordControl(): UntypedFormControl {
		if (this.formulario) {
			return this.formulario.get("newPassword") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get newPasswordControl2(): UntypedFormControl {
		if (this.formulario) {
			return this.formulario.get("newPassword2") as UntypedFormControl;
		} else {
			return null;
		}
	}
}
