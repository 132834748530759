<table class="table table-responsive table-hover mb-0 p-0">
    <thead>
        <tr>
            <th class="text-center" scope="col">{{'OPERATORS.SYMBOL' | translate}}</th>
            <th class="text-center" scope="col">{{'OPERATORS.NAME' | translate}}</th>
            <th class="text-center" scope="col">{{'OPERATORS.PHRASES' | translate}}</th>
        </tr>
    </thead>
    <tbody [ngClass]="type">
        <tr class="pointer" [ngClass]="{'activated': operator.checked}" *ngFor="let operator of operators" (click)="selectOperator(operator)">
            <td>
                <app-operator-icon [operator]="operator"></app-operator-icon>
            </td>
            <td>{{operator.name | translate}}</td>
            <td>{{operator.description | translate}}</td>
        </tr>
    </tbody>
</table>
