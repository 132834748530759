import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';
import { Subject } from 'rxjs';
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SigmaCanvasService } from "../sigma-canvas.service";
import { GraphService } from 'src/app/core/services/graph/graph.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

declare var $
@Injectable({
    providedIn: 'root'
  })
export class BackgroundUtils {


    private _settingBackground: boolean = false;
    public progress: Subject<number> = new Subject<number>()
    public _background: string | null = '';


    public get background(): string | null {
        return this._background;
    }
    public set background(value: string | null) {
        this._background = value;
    }

    public get settingBackground(): boolean {
        return this._settingBackground;
    }
    public set settingBackground(value: boolean) {
        this._settingBackground = value;
    }

	constructor(private translateService: TranslateService, private sigmaCanvasService: SigmaCanvasService, private toaster: ToasterService,
        private graphServ: GraphService, private imagePipe:ImagenPipe) {}

    public setBackground() {
        this.settingBackground = !this.settingBackground
    }

    public addBackground(file){
        this.settingBackground = false
        $('#pActions').hide();

        this.graphServ.saveBackgroundImage(this.sigmaCanvasService.graphId, file).subscribe((event: HttpEvent<any>) => {


            switch (event.type) {
                case HttpEventType.UploadProgress:
                    this.progress.next(Math.round(event.loaded / event.total * 100))
                  break;
                case HttpEventType.Response:
                    this.background = event.body.data.name;
										this.graphServ.bgImage.next(event.body.data.name);
                    this.setGraphBackground(event.body.data.name);
                    this.toaster.success(this.translateService.instant('SIGMACOMPONENT.BACKGROUNDUPLOADED'));


            }


        }, err => this.toaster.success(this.translateService.instant('SIGMACOMPONENT.BACKGROUNDERROR'))
        );


    }

    public rmBackground(){
        this.graphServ.saveBackgroundImage(this.sigmaCanvasService.graphId, undefined).subscribe((event: HttpEvent<any> ) => {

            switch (event.type) {
                case HttpEventType.Response:
                    this.background = null
                    this.setGraphBackground(null)
                    this.toaster.success(this.translateService.instant('SIGMACOMPONENT.BACKGROUNDUPLOADED'));
            }

        }, err => this.toaster.success(this.translateService.instant('SIGMACOMPONENT.SIGMACOMPONENT'))
        );
        $('#pActions').hide();
    }

    public setGraphBackground(url = '') {
        document.getElementById("sigma-container").style.backgroundImage = url ? "url('" + this.imagePipe.transform(url, 'graphBg') + "')" : "";
    }
}
