import { GruposService, SOCKETMESSAGES } from 'src/app/core/services/groups/grupos.service';
import { CommonModule, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NbCardModule, NbIconModule, NbSelectComponent, NbTooltipModule, NbDialogService } from '@nebular/theme';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
	startOfDay,
	endOfDay,
	subDays,
	addDays,
	endOfMonth,
	isSameDay,
	isSameMonth,
	addHours,
} from "date-fns";
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
	DAYS_OF_WEEK,
	CalendarDateFormatter
} from 'angular-calendar';
import { EventColor } from "calendar-utils";
import { debounceTime, finalize, Subject, Subscription, take, takeUntil } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { LoginService } from 'src/app/core/services/login';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { id } from 'date-fns/locale';
import { CoursesService } from 'src/app/core/services/courses';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalListadoCursosComponent } from '../grupos/modal-listado-cursos/modal-listado-cursos.component';
import { ModalGruposEditarComponent } from '../modal-grupos-editar/modal-grupos-editar.component';
import { User } from 'src/app/core/models/users/user.models';
import { CenterService } from 'src/app/core/services/center/center.service';

import { GetDataService } from "src/app/core/services/get-data/get-data.service";
import { MateriaModel } from 'src/app/core/models/masters';
import { ModalCursosListadoComponent } from '../cursos/modal-cursos-listado';
import { ModalSocketCoursesComponent } from '../grupos/modal-socket-courses/modal-socket-courses.component';
import { SocketService } from 'src/app/core/services/socket/socket-service.service';
import { SurveysListComponent } from '../surveys/surveys-list/surveys-list.component';
import { ModalAceptarCancelarComponent } from '../modal/modal-aceptar-cancelar/modal-aceptar-cancelar.component';
import { TranslateService } from '@ngx-translate/core';
import { Profiles } from 'src/app/core/utils/profiles.enum';
import { MastersService } from "src/app/core/services/masters";
import { ModalSocketSurveysComponent } from '../grupos/modal-socket-surveys/modal-socket-surveys.component';

interface salwareEvent {
	idEvent?: Number;
	Name: string;
	Nivel: string;
	IdUser?: Number;
	IdCenter?: Number;
	IdGroup?: Number[];
	idSubject?: Number;
	groupName?: string;
	IdCourse?: Number;
	IdTarget?: Number;
	IdSurvey?: Number;
	eventType?: Number;
	anonymous?: Number;
	Realizado?: Number;
	Fecha_Inicio?: Date;
	Fecha_Fin?: Date;
	createDate?: Date;
}

const colors: Record<string, EventColor> = {
	red: {
		primary: "#dc1431",
		secondary: "#d04545",
	},
	blue: {
		primary: "#fcec00",
		secondary: "#000080",
	},
	green: {
		primary: "#00a650",
		secondary: "#9cce04",
	},
};

@Component({
	selector: "app-modal-schedules",
	standalone: false,
	templateUrl: "./modal-schedules.component.html",
	styleUrls: ["./modal-schedules.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default,
	encapsulation: ViewEncapsulation.None,
})
export class ModalSchedulesComponent {
	idCenter: any;
	target: any;
	contentSetted: boolean = false;
	asignatura: any;
	user: User;
	refresh = new Subject<void>();
	events: CalendarEvent[] = [];
	activeDayIsOpen: boolean = false;
	levelsOfCountry: any;
	selectedGroupInfo: any;
	view: CalendarView = CalendarView.Month;
	viewDate = new Date();
	locale: string = "es";
	weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
	weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
	CalendarView = CalendarView;
	editingEvent: salwareEvent;
	cameWithEvents: boolean = false;
	preLoadedEvents: any;
	selectedGroupsEditing = [];

	idGroup: number = 0;
	comentario: string = '';
	fechaComentario: string = '';
	private comentarioSubject = new Subject<string>(); // Para capturar cambios
	private onDestroy$ = new Subject<void>(); // Para manejar la limpieza de subscripciones
  

	private subscriptions: Subscription[] = [];
	academicProjectTypes = [
		{ value: "1", label: "De estudio curricular" },
		{ value: "2", label: "De estudio aplicado" },
		{ value: "3", label: "Prueba PISA" },
		{ value: "4", label: "Pensar Creando" },
		{ value: "5", label: "Control" },
	];

	tiposEncuesta = [
		{ value: "1", label: "A Alumnos" },
		{ value: "2", label: "A Profesores" },
		{ value: "3", label: "A Padres" },
	];

	projectTypes = this.academicProjectTypes;

	subjectsList: MateriaModel[] = this.getDataService.appSubjects;
	auxSubjectsList: MateriaModel[] = this.getDataService.appSubjects;

	modalData: {
		action: string;
		event: salwareEvent;
	};
	actions: CalendarEventAction[] = [
		{
			label: "Editar ",
			a11yLabel: "Edit",
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.handleEvent("Edited", event);
			},
		},
		{
			label: "  Borrar",
			a11yLabel: "Delete",
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.events = this.events.filter((iEvent) => iEvent !== event);
				this.handleEvent("Deleted", event);
			},
		},
		{
			label: "  Nuevo",
			a11yLabel: "Add",
			onClick: (): void => {
				this.modalService.open(this.modalContent, {
					windowClass: MODAL_DIALOG_TYPES.W30,
				});
			},
		},
	];
	selectedLevel: any;
	isEditing: boolean = false;
	isLoading: boolean;
	subject: any;
	levelSelected: any;
	auxEvents: any;
	selectedSubject: number;
	selectedProject: any;
	filteredEvents: any;
	selectedLevelToFilter: string;
	groupsNamesList: any = [];
	projectType: any = 0;
	dayEvents: CalendarEvent<any>[];
	excludeDays: number[] = [0, 6]; // Exclude Sunday (0) and Saturday (6)
	isCenter: boolean;
	selectedGroups: any;
	selectedOption = 1;

	private destroy$ = new Subject();
	allGroups: any = [];
	constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private activengb: NgbActiveModal,
		private loginService: LoginService,
		private GruposService: GruposService,
		private toaster: ToasterService,
		public coursesService: CoursesService,
		private centerService: CenterService,
		public getDataService: GetDataService,
		private groupService: GruposService,
		private dialogService: NbDialogService,
		private socketService: SocketService,
		private translateService: TranslateService,
		private MastersService: MastersService
	) {
		//this.inactivityService.startInactivityTimer();
	}
	@ViewChild("modalEvents", { static: true }) modalContent: TemplateRef<any>;
	@ViewChild("modalEventsList", { static: true })
	modalEventsList: TemplateRef<any>;
	@ViewChild("levelSelector", { static: true })
	levelSelector: NbSelectComponent;

	ngOnInit(): void {
		let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		this.idGroup = storedGroupInfo ? storedGroupInfo.idGroup : 0;
		// Suscribirse al Subject y aplicar debounceTime
		this.comentarioSubject
		.pipe(
			debounceTime(1000), // Esperar 3 segundos después del último cambio
			takeUntil(this.onDestroy$) // Limpiar subscripción al destruir el componente
		)
		.subscribe((nuevoComentario) => {
			this.guardarComentario(nuevoComentario);
		});
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.centerService
			.getListCenterByIdUserForUser()
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe((result) => {
				this.idCenter = result.idCenter;
				if (this.cameWithEvents == false) {
					this.pullCenterEvents(result.idCenter);
				} else {
					this.events = this.preLoadedEvents;
				}

				//Obtengo los centros asociados al usuario actual
				// this.centersAssignedToUser = new MatTableDataSource<CenterById>(result);
				// this.centersAssignedToUser.paginator = this.paginator;
				this.groupService.getGruposByCenter(this.idCenter).subscribe((res) => {
					res.data.forEach((group) => {
						this.allGroups.push(group);

						this.groupsNamesList.push({
							idGroup: group.idGroup,
							title: group.title,
						});

						this.selectedGroups = this.groupsNamesList.map(
							(group) => group.idGroup
						);
					});
				});
			});

		this.user = this.loginService.getUser();
		this.isCenter = this.loginService.esCentro();

		this.coursesService
			.getCountryLevelsByCountry("ESP")
			.subscribe((countryLevels) => {
				if (countryLevels) {
					this.levelsOfCountry = countryLevels.data;
				}
			});
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.destroy$.next(true);
	}

	get levelColor(): string {
		return this.selectedLevel ? "green" : "initial";
	}

	get subjectColor(): string {
		return this.selectedSubject ? "green" : "initial";
	}

	get projectColor(): string {
		return this.selectedProject ? "green" : "initial";
	}

	updateProjectTypes() {
		if (this.selectedOption == 2) {
			this.projectTypes = this.academicProjectTypes;
			this.editingEvent.eventType = 1;
		} else if (this.selectedOption == 1) {
			this.projectTypes = this.tiposEncuesta;
			this.editingEvent.eventType = 2;
		}
	}

	setLevel(level) {
		let levelName = this.levelsOfCountry.find((l) => l.countryLevel == level);
		this.levelSelected = levelName;
		this.editingEvent.Nivel = levelName.countryLevel;
		this.selectedLevel = levelName.countryLevel;

		if (this.editingEvent) {
			const nameParts = this.editingEvent.Name.split("|");
			nameParts[0] = levelName.countryLevel;
			this.editingEvent.Name = nameParts.join("|");
		}

		// Filter groupsNamesList based on the selected level
		this.groupsNamesList = this.allGroups
			.filter((group) => group.nivel === levelName.countryLevel)
			.map((group) => ({
				idGroup: group.idGroup,
				title: group.title,
			}));

		this.coursesService
			.getListSubjectByIdCenterIdLevel(this.idCenter, levelName.idCountryLevel)
			.subscribe((res) => {
				if (res.data == null) {
					//if there are no subjects for the selected level, we just fill it whit every subject again
					this.subjectsList = this.auxSubjectsList;
				} else {
					this.subjectsList = res.data;
				}
			});
	}

	selectContent() {
		if (this.selectedOption == 1) {
			const modalRef = this.modalService.open(ModalCursosListadoComponent, {
				scrollable: false,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			});

			modalRef.componentInstance.cameFromCalendar = true;
			modalRef.componentInstance.projectType = this.projectType;
			modalRef.componentInstance.levelFromCalendar = this.levelSelected;
			modalRef.componentInstance.subjectFromCalendar = this.subject;

			modalRef.result.then((data) => {
				this.contentSetted = true;
				this.target = data.target;
				this.editingEvent.IdCourse = data.cours.idCourse;
				this.editingEvent.IdTarget = data.target.idTarget;
				this.editingEvent.eventType = 1;
				this.asignatura = data.target.tittle;

				if (this.editingEvent) {
					const nameParts = this.editingEvent.Name.split("|");
					nameParts[2] = data.target.tittle;
					this.editingEvent.Name = nameParts.join("|");
				}
			});
		} else {
			const modalRef = this.modalService.open(SurveysListComponent, {
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			});
			modalRef.componentInstance.cameFromCalendar = true;
			modalRef.result.then((data) => {
				this.contentSetted = true;
				this.editingEvent.IdSurvey = data.idSurvey;
				this.editingEvent.eventType = 2;
				this.editingEvent.anonymous = data.visible;

				if (this.editingEvent) {
					const nameParts = this.editingEvent.Name.split("|");
					nameParts[2] = data.title;
					this.editingEvent.Name = nameParts.join("|");
				}
			});
		}
	}

	openGroupsSelector() {
		const modalGrupos = this.modalService.open(ModalGruposEditarComponent, {
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});

		modalGrupos.componentInstance.cameFromCenter = true;
		modalGrupos.componentInstance.idCenter = this.idCenter;
		modalGrupos.componentInstance.idSubject = this.subject;
		modalGrupos.componentInstance.levelSelected = this.levelSelected;
		modalGrupos.result.then((result) => {
			//extract all the idGroups from the result and set them to the editingEvent
			this.editingEvent.IdGroup = result.map((group) => group.idGroup);

			//update groupNamesList with the selected groups names to show them in the calendar
			this.groupsNamesList = [];
			result.forEach((group) => {
				this.groupsNamesList.push({
					idGroup: group.idGroup,
					title: group.name,
				});
			});

			//this.selectedGroupInfo = result;
			//this.setSelectedGroupInfo();
		});
	}

	updateGroupsIds(event: number[]): void {
		this.editingEvent.IdGroup = event;
	}

	setSelectedGroupInfo() {
		let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		if (storedGroupInfo) {
			if (storedGroupInfo.idUser != this.user.idUser) {
				localStorage.removeItem("selectedGroupInfo");
			}
		}
		this.selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);

		this.editingEvent.IdGroup = this.selectedGroupInfo.idGroup;
		this.editingEvent.groupName = this.selectedGroupInfo.title;
	}

	dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
		if (isSameMonth(date, this.viewDate)) {
			this.dayEvents = events;
			this.viewDate = date;

			if (this.isCenter) {
				// Open the modal with the list of events for the selected day
				if (events.length === 0) {
					this.editingEvent = {
						Name: " | | ",
						Nivel: "2 ESO",
						IdUser: this.loginService.getUser().idUser,
						IdCenter: this.idCenter,
						IdGroup: this.selectedGroups,
						idSubject: this.subject,
						IdCourse: 0,
						IdTarget: 0,
						IdSurvey: 0,
						eventType: 1,
						Realizado: 0,
						Fecha_Inicio: date,
						Fecha_Fin: subDays(date, 7),
						createDate: new Date(),
					};

					// Open the modal for creating a new event
					this.modalService.open(this.modalContent, {
						windowClass: MODAL_DIALOG_TYPES.W30,
					});
				} else {
					// Open the modal with the list of events for the selected day
					this.modalService.open(this.modalEventsList, {
						windowClass: MODAL_DIALOG_TYPES.W30,
					});
				}
			} else {
				// traemos el comentario
				this.comentario = '';
				const fecha = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
				this.fechaComentario = fecha;
				this.groupService.getScheduleComentary(this.idGroup, fecha).subscribe(res => {
					if(res.data[0]){
						this.comentario = res.data[0].comentary;
					}
				});
				// For teacher profile, open the modal with the list of events for the selected day
				this.modalService.open(this.modalEventsList, {
					windowClass: MODAL_DIALOG_TYPES.W30,
				});
			}
		}
	}

	addCleanEvent({ date, events }: { date: Date; events: CalendarEvent[] }) {
		this.editingEvent = {
			Name: " | | ",
			Nivel: "2 ESO",
			IdUser: this.loginService.getUser().idUser,
			IdCenter: this.idCenter,
			IdGroup: [],
			idSubject: this.subject,
			IdCourse: 0,
			IdTarget: 0,
			IdSurvey: 0,
			Realizado: 0,
			eventType: 1,
			Fecha_Inicio: this.viewDate,
			Fecha_Fin: subDays(this.viewDate, 7), // Set Fecha_Fin to be one week before the date
			createDate: new Date(),
		};

		this.handleEvent("Clicked", null);
	}

	saveNewEvent(event: salwareEvent) {
		this.GruposService.createUpdateEvent(event).subscribe((res) => {
			if (res) {
				let freshlyCreatedEvent = res.data
				if (
					res.error.msg == "Evento creado" ||
					res.error.msg == "Evento Actualizado"
				) {
					const newEvent: any = {
						id: Number(freshlyCreatedEvent.idEvent),
						title: freshlyCreatedEvent.name,
						start: new Date(freshlyCreatedEvent.fecha_Inicio),
						end: new Date(freshlyCreatedEvent.fecha_Fin),
						color: freshlyCreatedEvent.colour, // Adjust color as needed
						draggable: true,
						idSubject: freshlyCreatedEvent.idSubject,
						idGroup: freshlyCreatedEvent.idGroup,
						idCourse: freshlyCreatedEvent.idCourse,
						idTarget: freshlyCreatedEvent.idTarget,
						idSurvey: freshlyCreatedEvent.idSurvey,
						eventType: freshlyCreatedEvent.eventType,
						resizable: {
							beforeStart: true,
							afterEnd: true,
						},
					};
				this.events = [...this.events, newEvent];

				// Check if the new event falls on the currently selected day
				if (isSameDay(new Date(event.Fecha_Inicio), this.viewDate)) {
					this.dayEvents = [...this.dayEvents, newEvent];
				}

					this.isEditing = false;
					//notification success adn close modal
					this.toaster.success("Evento guardado correctamente");

					//i need to close only the modal with id #modalContent

					this.pullCenterEvents(this.idCenter);
				} else {
					this.toaster.error("Error al crear el evento");
				}
			}
		});
	}

	pullCenterEvents(idCenter) {
		this.GruposService.getCenterEvents(idCenter).subscribe((res) => {
			if (res) {
				this.auxEvents = res.data;
				this.fillEventsWithPulledEvents(res.data);
			}
		});
	}

	fillEventsWithPulledEvents(events) {
		this.events = events.map((event) => {
			return {
				id: event.idEvent,
				level: event.nivel,
				idGroup: event.idGroup,
				idCourse: event.idCourse,
				idSubject: event.idSubject,
				idTarget: event.idTarget,
				realizado: event.realizado ? 1 : 0,
				eventType: event.eventType,
				idUser: event.idUser,
				start: addDays(new Date(event.fecha_Inicio), 1),
				end: addDays(new Date(event.fecha_Fin), 1),
				title: event.name,
				color:
					event.colour == "rojo"
						? colors.red
						: event.colour == "azul"
						? colors.blue
						: colors.green,
				actions: this.actions,
				resizable: {
					beforeStart: true,
					afterEnd: true,
				},
				draggable: true,
			};
		});
	}

	eventTimesChanged({ event, newStart, newEnd }: any): void {
		let newEvent = {
			idEvent: event.id,
			Name: event.title,
			Nivel: event.level,
			IdUser: event.idUser,
			IdCenter: this.idCenter,
			IdGroup: event.idGroup,
			idSubject: this.subject,
			IdCourse: event.idCourse,
			eventType: event.eventType,
			IdTarget: event.idTarget,
			Realizado: event.realizado,
			Fecha_Inicio: new Date(newStart),
			Fecha_Fin: new Date(newEnd),
			createDate: new Date(),
		};

		this.saveNewEvent(newEvent);
		this.handleEvent("Dropped or resized", event);
	}

	handleEvent(action: string, event?: any): void {

		if (action == "Edited") {
			this.isEditing = true;
			this.editingEvent = {
				idEvent: event.id,
				Name: event.title,
				Nivel: event.level,
				IdUser: event.idUser,
				IdCenter: this.idCenter,
				IdGroup: event.idGroup,
				idSubject: this.subject,
				IdCourse: event.idCourse,
				eventType: event.eventType,
				IdTarget: event.idTarget,
				IdSurvey: event.idSurvey,
				Realizado: event.realizado,
				Fecha_Inicio: new Date(event.start),
				Fecha_Fin: new Date(event.end),
				createDate: new Date(),
			};
			this.selectedLevel = event.level;
			this.selectedGroupsEditing = event.idGroup;
			//set the subject selector to the event's subject
			this.setSubject(event.idSubject, true);
			//this.setLevel(event.level);

			this.selectedSubject = event.idSubject;
			this.contentSetted = true;
			let modal = this.modalService.open(this.modalContent, {
				windowClass: MODAL_DIALOG_TYPES.W30,
			});
			modal.result.then((result) => {
				this.isEditing = false;
				this.contentSetted = false;
			}
			);
		} else if (action == "Deleted") {
			this.deleteEvent(event);
		} else if (action == "Clicked") {
			if (event === null || this.activeDayIsOpen === false) {
				// Si no hay eventos o el día ya está abierto, abrir el modal
				this.modalService.open(this.modalContent, {
					windowClass: MODAL_DIALOG_TYPES.W30,
				});
			} else {
				// Si hay eventos y el día no está abierto, abrir el panel con la lista de eventos
				this.activeDayIsOpen = true;
			}
		}
	}

	addEvent(): void {
		this.events = [
			...this.events,
			{
				title: "New event",
				start: startOfDay(new Date()),
				end: endOfDay(new Date()),
				color: colors.red,
				draggable: true,
				resizable: {
					beforeStart: true,
					afterEnd: true,
				},
			},
		];
	}

	deleteEvent(eventToDelete: CalendarEvent) {
		this.events = this.events.filter((event) => event !== eventToDelete);
		this.dayEvents = this.dayEvents.filter((event) => event !== eventToDelete);

		this.GruposService.deleteEventByIdEvent(eventToDelete.id).subscribe(
			(res) => {
				if (res) {
					if (res.error.msg == "El evento ha sido eliminado") {
						this.toaster.success("Evento Eliminado Correctamente");
						this.pullCenterEvents(this.idCenter);
					} else {
						this.toaster.error("Error al eliminar el evento");
					}
				}
			}
		);
	}

	setSubject(subject, noFilter?: boolean) {
		this.subject = subject;
		this.selectedSubject = subject;
		// Find the subject name on subjectsList
		let subjectName = this.subjectsList.find((s) => s.idSubject == subject);

		// Ensure that the editingEvent's idSubject is updated when a new subject is selected
		if (this.editingEvent) {
			this.editingEvent.idSubject = subject;
			const nameParts = this.editingEvent.Name.split("|");
			nameParts[1] = subjectName.subject;
			this.editingEvent.Name = nameParts.join("|");
		}

		// Filter groupsNamesList based on the selected subject
		if (!noFilter) {
			this.groupsNamesList = this.allGroups
				.filter((group) => group.idSubject === subject)
				.map((group) => ({
					idGroup: group.idGroup,
					title: group.title,
				}));
		}
	}

	setProjectType(projectType) {
		this.projectType = projectType;
		this.selectedProject = projectType;
	}

	filterBySubject(subject: number) {
		this.selectedSubject = subject;
		this.applyFilters();
	}

	filterByLevel(level: string) {
		this.selectedLevelToFilter = level;
		this.applyFilters();
	}

	applyFilters() {
		this.filteredEvents = this.auxEvents.filter((event) => {
			const matchesSubject =
				this.selectedSubject === null ||
				event.idSubject === this.selectedSubject;
			const matchesLevel =
				this.selectedLevelToFilter === null ||
				event.nivel === this.selectedLevelToFilter;
			return matchesSubject || matchesLevel;
		});
		this.fillEventsWithPulledEvents(this.filteredEvents);
	}

	resetFilters() {
		this.selectedSubject = null;
		this.selectedLevel = null;
		this.filteredEvents = [...this.auxEvents];
		this.fillEventsWithPulledEvents(this.filteredEvents);
	}

	setView(view: CalendarView) {
		this.view = view;
	}

	closeOpenMonthViewDay() {
		this.activeDayIsOpen = false;
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	open(dialog: TemplateRef<any>) {
		this.dialogService.open(dialog);
	}

	playEvent(event) {
		if(this.checkIfGruposSelected(event.idGroup)){
			this._openChallenge(event.idCourse, event.idTarget, event.idSurvey, event.title, event.id);
		} else{
			this.toaster.error("Selecciona un grupo para poder realizar el evento.");
		}
	}

	checkIfGruposSelected(grupos) {
		let selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);
		if (selectedGroupInfo == null) {
			//SI NO TENEMOS NINGUN GRUPO SELECCIONADO ABRIMOS ALERT PARA QUE SELECCIONE UN GRUPO
			const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30,
			});
			modalRef.componentInstance.optionalTitle = this.translateService.instant(
				"ALERTCHALLENGENODE.SELECTGROUPTITLE"
			);
			modalRef.componentInstance.mensaje = this.translateService.instant(
				"ALERTCHALLENGENODE.SELECTGROUPMESSAGE"
			);

			modalRef.result.then((result: boolean) => {
				if (result) {
					this.MastersService.openGroups(Profiles.Teacher, grupos);
				}
			});
		}
		return selectedGroupInfo;
	}

	private _openChallenge(idCourse, idTarget, idSurvey, name, id) {
		let selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);

		let modal: NgbModalRef;
		const idGroup = selectedGroupInfo.idGroup;
		this.groupService.sendMsgToRoomClients(idGroup,
			'control_about_to_start',
			idGroup
		).subscribe();
		const msgSubs = this.socketService.msgFromServer.subscribe((data) => {
			//Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
			const dataArray = data.split(":");
			const type = dataArray[0];
			const onlineUsers: number = parseInt(dataArray[1]);
			switch (type) {
				case SOCKETMESSAGES.USERSCOUNTER:
					if(idSurvey == 0){
						// Modal con el listado de cursos, para que el profesor pueda elegir los quizzes a realizar
						modal = this.modalService.open(ModalSocketCoursesComponent, {
							scrollable: true,
							windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
						});
					} else{
						// Modal con el listado de cursos, para que el profesor pueda elegir los quizzes a realizar
						modal = this.modalService.open(ModalSocketSurveysComponent, {
							scrollable: true,
							windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
						});
					}
					modal.componentInstance.idEventFromCalendar = id;
					modal.componentInstance.fromNode = true;
					modal.componentInstance.group = selectedGroupInfo.group;
					modal.componentInstance.onlineUsers = onlineUsers;
					//modal.componentInstance.fromNode = true;
					modal.componentInstance.idCurso = idCourse;
					modal.componentInstance.targetFromCalendar = idTarget;
					modal.componentInstance.surveyFromCalendar = idSurvey;
					modal.componentInstance.idEvent = id;
					modal.componentInstance.nameSessionFromCalendar = name;
					modal.componentInstance.cameFromCalendar = true;
					modal.result
						.then(
							() => {},
							(err) => {}
						)
						.finally(() => {
							this.finishControl(id);
							this._removeSocket(idGroup);
						});
					msgSubs.unsubscribe();
					break;
			}
		});
		this.subscriptions.push(msgSubs);
		this.socketService.createSocket(idGroup, 'calendarControl'); //Creo el socket
		const openSocketSub = this.socketService.openSocket.subscribe((ev) => {
			//Cuando se cree el socket, procedemos a guardar la sesion en el servidor
			this.groupService.createGroupSession(idGroup, null, true).subscribe(
				(result) => {
					if (result.data == null) {
						this.groupService
							.getIfSessionExists(idGroup)
							.pipe(takeUntil(this.destroy$), take(1))
							.subscribe((result) => {
								if (result.data.existSesion == true) {
									this.groupService
										.getGroupSession(idGroup)
										.subscribe((result) => {
											this.socketService.idSession = result.data[0].idSession;
											openSocketSub.unsubscribe();
										});
								}
							});
					} else {
						this.socketService.idSession = result.data.idSession;
						openSocketSub.unsubscribe();
					}
				},
				(err) => {
					modal.close();
				}
			);
		});
		this.subscriptions.push(openSocketSub);
		//this.closeNode();
	}
	finishControl(idEvent) {
		this.groupService.finishEvent(idEvent).subscribe((res) => {
			if (res) {
				//this.pullCenterEvents(this.idCenter);
				console.log("finalizar evento ->", res.error.msg);
			}
		});
	}

	private _removeSocket(idGroup) {
		this.socketService.removeSocket(); //Si ha habido error al guardar la sesión, tenemos que destruir el socket y cerrar el modal
		this.groupService.deleteGroupSession(idGroup).subscribe(); //Elimino la sesión de la bd
		this.subscriptions.forEach((s) => s.unsubscribe()); //Elimino las suscripciones a eventos
	}

	// Método para manejar cambios en el comentario
	onComentarioChange(nuevoComentario: string): void {
	this.comentarioSubject.next(nuevoComentario); // Emitir cambios
	}

	// Método para guardar el comentario
	guardarComentario(comentario: string) {
		this.groupService.createScheduleComentary(comentario, this.fechaComentario, this.idGroup).subscribe();
	}
}
