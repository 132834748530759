<ngx-wrapper-copilot ></ngx-wrapper-copilot>
<div *ngIf="slowConection" class="d-flex position-relative justify-content-center w-100" style="background-color: #ff3d71;">
	<div class="d-flex slowConectionBar">
			<p style="color: antiquewhite; margin: 0;">{{ "GLOBALS.SLOWCONECTION" | translate }}</p>
	</div>
</div>
<nb-layout>
	<nb-layout-column class="bg-white">
		<router-outlet>
		</router-outlet>
	</nb-layout-column>
 </nb-layout>



