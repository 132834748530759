<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header *ngIf="!minimal" class="border-bottom-0">
		<div class="row">
			<div class="col-12 col-md-12 modal-title">
				<h2>{{ "COMPETITION.COMPETITIONRESULTS" | translate }}</h2>
			</div>
		</div>
	</nb-card-header>

	<div *ngIf="!minimal" class="closeModal">
		<nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left"
			[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"></nb-icon>
	</div>

	<nb-card-body>
		<div [nbSpinner]="isLoading" nbSpinnerStatus="danger">
			<div class="row h-180">
				<div *ngIf="!isMobile;" class="col-12 col-xl-3">
					<div class="flex flex-column background_themecolor h-180 pl-3 pt-3 pb-3 pr-0">
						<h6>{{ "COMPETITION.GAMESHISTORY" | translate }}</h6>
						<nb-list class="pr-3">
							<nb-list-item *ngFor="let game of gamesHistory" (click)="getGameData(game.hex)" [class.selected]="gameSelected == game.hex">
								{{ game.nameSession }} | {{ game.date }}
							</nb-list-item>

							<nb-list-item *ngIf="gamesHistory.length == 0" class="no-games">
								{{ "COMPETITION.NOGAMESTOSHOW" | translate }}
							</nb-list-item>
						</nb-list>
					</div>
				</div>
				<div *ngIf="isMobile;" class="pl-2">
					<nb-icon class="m-1 pointer" icon="menu-outline" [options]="{ animation: { type: 'zoom' } }"
						(click)="toggleMenu()">
					</nb-icon>

					<div class="left-files" [ngClass]="{'show-files': isMenuOpen}">
						<div class="flex flex-column background_themecolor h-180">
							<div class="closeModal">
								<nb-icon class="m-1 pointer" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
									(click)="toggleMenu()" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
								</nb-icon>
							</div>

							<h6>{{ "COMPETITION.GAMESHISTORY" | translate }}</h6>
							<nb-list class="pr-4">
								<nb-list-item *ngFor="let game of gamesHistory" (click)="getGameData(game.hex)" [class.selected]="gameSelected == game.hex">
									{{ game.nameSession }} / {{ game.hex }}
								</nb-list-item>

								<nb-list-item *ngIf="gamesHistory.length == 0" class="no-games">
									{{ "COMPETITION.NOGAMESTOSHOW" | translate }}
								</nb-list-item>
							</nb-list>
						</div>
					</div>
				</div>
				<div class="col-12 col-xl-9 h-180 d-flex justify-content-center align-items-center" *ngIf="teamsOfGame == null">
					<h5>{{ "COMPETITION.SELECTGAMES" | translate }}</h5>
				</div>
				<div class="col-12 col-xl-9 h-180 overflow-auto" *ngIf="teamsOfGame != null">
					<nb-card>
						<nb-card-body class="score-wrapper" style="display: none;">

							<table class="table  table-responsive " *ngIf="is1vs1 == false">
								<thead *ngIf="totalHeadTable ? totalHeadTable.length > 1 : false;">
									<tr class="bg-light header-table mr-2" *ngIf="totalHeadTable">
										<th scope="row" *ngFor="let list of totalHeadTable; let i = index">
											<div class="adjust-th-center" *ngIf="totalHeadTable.length > 1">
												<div class="data-text same-width">
													<div class="d-flex justify-content-center align-items-center" *ngIf="i === 0" >
														<img src="../../../../../assets/images/icons/ActividadCuadrado.svg" width="24" height="24">
														<span class="separador text-primary">/</span> <mat-icon svgIcon="AnadirActividadesMultiples"></mat-icon>
													</div>

													<div class="d-flex justify-content-center align-items-center " [ngbTooltip]="FullNameTooltip" placement="bottom">
														<div class="avatar text-center  mb-2" *ngIf="i != 0" [ngStyle]="{'background-image': getUserAvatar(list.image)}"></div>
													</div>
													<div>
														<p class="text-center courseTittle">{{list.name}}</p>
													</div>
												</div>
											</div>
										</th>
									</tr>
								</thead>
								<tbody *ngIf="totalHeadTable ? totalHeadTable.length > 1 : false; else sinRespuestas">
									<tr *ngFor="let filas of totalBodyTable; let i = index" [ngClass]="{'sticky-row': filas.row[0].color === '#FFE7D5'}">
										<th *ngFor="let data of filas.row" scope="row"
											[ngStyle]="{
												'background-color': data.color !== null && i ? data.color : '#ffff',
												'text-align': 'center',
												'font-weight': '500',
												'color': data.color === '#FFE7D5' ? '#930F1F' : (data.color === '#FFC9AC' ? '#7A0920' : 'black')
											}"
											[ngClass]="{
												'empty': !i,
												'isChecked': data.isChecked,
												'isNotChecked': !data.isChecked
											}"
											>
											<div class="data-text">
												<div class="d-flex justify-content-center align-items-center" *ngIf="data.title !== null" >
													<span class="text-title">
															<div class="" *ngIf="data.title == '% de acierto' else defaultTitle">
															<span class="d-flex align-items-center">% {{ "COMPETITION.ACIERTOS" | translate }}
																<mat-icon svgIcon="IconoTickRevision2" ></mat-icon>
															</span>
															</div>

															<ng-template #defaultTitle >
																{{data.title | titlecase }}
															</ng-template>

													</span>
													<!-- <div *ngIf="i">
														<mat-icon *ngIf="data.isMultiplex === 0" svgIcon="AnadirActividad" ></mat-icon><mat-icon *ngIf="data.isMultiplex === 1" svgIcon="AnadirActividadesMultiples" ></mat-icon>
													</div> -->
												</div>
											</div>
											<div class="nota-media" *ngIf="!i">
												<span class="strong-text">{{data.texto}}</span>
											</div>
											<div *ngIf="data.texto  !== null && i" class="data-nota">
												<div *ngIf="!data.isChecked"  class="d-flex justify-content-center align-items-center pointer">
														<mat-icon svgIcon="inte" (click)="viewQuiz(data)"></mat-icon>
												</div>
												<div *ngIf="data.isChecked">
													<a class="text-white font-weight-bold pointer-event" (click)="viewQuiz(data)"  >
														<div class="d-flex align-items-center justify-content-center" *ngIf="challengeOn">
															<mat-icon [svgIcon]="data.isChallengeResult === 2 ? 'IconoTickRevision2' : 'IconoXRevision'" class="mr-1" *ngIf="data.isChallengeResult !== 0"></mat-icon>
															<span>{{data.texto}}</span>
														</div>
														<div class="d-flex align-items-center justify-content-center" *ngIf="!challengeOn">
															<mat-icon [svgIcon]="data.iconResult ? 'IconoTickRevision2' : 'IconoXRevision'" class="mr-1"></mat-icon>
															<span>{{data.texto}}</span>
															<mat-icon *ngIf="data.sos == 1" svgIcon="sos" style="margin-left: 10px;"></mat-icon>
														</div>
													</a>
												</div>
											</div>
										</th>
									</tr>
								</tbody>

								<ng-template #sinRespuestas>
									<tr>
										<td class="text-center d-flex justify-content-center align-items-center" colspan="5">
											<p class="m-0">{{ "COMPETITION.NOPLAYERPLAYEDFORTHISTEAM" | translate }}</p>
										</td>
									</tr>
								</ng-template>
							</table>
						</nb-card-body>
					</nb-card>

					<nb-card *ngFor="let team of teamsOfGame">
						<ng-container *ngIf="this.isTeams && this.idGroup != 0; else teamName">
							<nb-card-header>Resultados:</nb-card-header>
						</ng-container>
						<ng-template #elseTemplate>
							<nb-card-header>{{team.name}}</nb-card-header>
						</ng-template>


						<nb-card-body>
							<table class="table  table-responsive ">
								<thead *ngIf="team.cabecera ? team.cabecera.length > 1 : false;">
									<tr class="bg-light header-table mr-2" *ngIf="team.cabecera">
										<th scope="row" *ngFor="let list of team.cabecera; let i = index">
											<div class="adjust-th-center" *ngIf="team.cabecera.length > 1">
												<div class="data-text same-width">
													<div class="d-flex justify-content-center align-items-center" *ngIf="i === 0" >
														<img src="../../../../../assets/images/icons/ActividadCuadrado.svg" width="24" height="24">
														<span class="separador text-primary">/</span> <mat-icon svgIcon="AnadirActividadesMultiples"></mat-icon>
													</div>

													<div class="d-flex justify-content-center align-items-center " [ngbTooltip]="FullNameTooltip" placement="bottom">
														<div class="avatar text-center  mb-2" *ngIf="i != 0" [ngStyle]="{'background-image': getUserAvatar(list.image)}"></div>
													</div>
													<div>
														<p class="text-center courseTittle">{{list.name}}</p>
													</div>
												</div>
											</div>
										</th>
									</tr>
								</thead>
								<tbody *ngIf="team.cabecera ? team.cabecera.length > 1 : false; else sinRespuestas">
									<tr *ngFor="let filas of team.bodyTable; let i = index" [ngClass]="{'sticky-row': filas.row[0].color === '#FFE7D5'}">
										<th *ngFor="let data of filas.row" scope="row"
											[ngStyle]="{
												'background-color': data.color !== null && i ? data.color : '#ffff',
												'text-align': 'center',
												'font-weight': '500',
												'color': data.color === '#FFE7D5' ? '#930F1F' : (data.color === '#FFC9AC' ? '#7A0920' : 'black')
											}"
											[ngClass]="{
												'empty': !i,
												'isChecked': data.isChecked,
												'isNotChecked': !data.isChecked
											}"
											>
											<div class="data-text">
												<div class="d-flex justify-content-center align-items-center" *ngIf="data.title !== null" >
													<span class="text-title">
															<div class="" *ngIf="data.title == '% de acierto' else defaultTitle">
															<span class="d-flex align-items-center">% {{ "COMPETITION.ACIERTOS" | translate }}
																<mat-icon svgIcon="IconoTickRevision2" ></mat-icon>
															</span>
															</div>

															<ng-template #defaultTitle >
																{{data.title | titlecase }}
															</ng-template>

													</span>
													<!-- <div *ngIf="i">
														<mat-icon *ngIf="data.isMultiplex === 0" svgIcon="AnadirActividad" ></mat-icon><mat-icon *ngIf="data.isMultiplex === 1" svgIcon="AnadirActividadesMultiples" ></mat-icon>
													</div> -->
												</div>
											</div>
											<div class="nota-media" *ngIf="!i">
												<span class="strong-text">{{data.texto}}</span>
											</div>
											<div *ngIf="data.texto  !== null && i" class="data-nota">
												<div *ngIf="!data.isChecked"  class="d-flex justify-content-center align-items-center pointer">
														<mat-icon svgIcon="inte" (click)="viewQuiz(data)"></mat-icon>
												</div>
												<div *ngIf="data.isChecked">
													<a class="text-white font-weight-bold pointer-event" (click)="viewQuiz(data)"  >
														<div class="d-flex align-items-center justify-content-center" *ngIf="challengeOn">
															<mat-icon [svgIcon]="data.isChallengeResult === 2 ? 'IconoTickRevision2' : 'IconoXRevision'" class="mr-1" *ngIf="data.isChallengeResult !== 0"></mat-icon>
															<span>{{data.texto}}</span>
														</div>
														<div class="d-flex align-items-center justify-content-center" *ngIf="!challengeOn">
															<mat-icon [svgIcon]="data.iconResult ? 'IconoTickRevision2' : 'IconoXRevision'" class="mr-1"></mat-icon>
															<span>{{data.texto}}</span>
															<mat-icon *ngIf="data.sos == 1" svgIcon="sos" style="margin-left: 10px;"></mat-icon>
														</div>
													</a>
												</div>
											</div>
										</th>
									</tr>
								</tbody>

								<ng-template #sinRespuestas>
									<tr>
										<td class="text-center d-flex justify-content-center align-items-center" colspan="5">
											<p class="m-0">{{ "COMPETITION.NOPLAYERPLAYEDFORTHISTEAM" | translate }}</p>
										</td>
									</tr>
								</ng-template>
							</table>
						</nb-card-body>
					</nb-card>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>








