<nb-card class="mb-0 no-edge-card">
	<nb-card-header class="border-bottom-0">
		<div class="col-12 modal-title">
			<h6 class="m-0">{{'INSTAGRAMVIDEO.TITLE' | translate}}</h6>
		</div>
	</nb-card-header>
</nb-card>

<div class="closeModal">
	<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
		(click)="closeModal('')" data-dismiss="modal" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
	</nb-icon>
</div>

<nb-card-body>
	<form [formGroup]="form" (ngSubmit)="postVideo()" #thisForm="ngForm">
		<div class="row form-group py-3" align="left">
			<div class="col-12">
					<div align="center">
							<h3 class="mb-4">{{'INSTAGRAMVIDEO.TITLE' | translate}}</h3>
					</div>

					<div class="form-row">
						<div class="full-width mb-3">
							<div class="row">
								<div class="col-md-12">
									<div class="col space-form">
										<input type="text" nbInput fullWidth placeholder="{{'INSTAGRAMVIDEO.LINK' | translate}}" name="url" formControlName="url">
										<div *ngFor="let validation of validationMessages.url">
											<div class="error-message"
													*ngIf="urlControl?.hasError(validation.type) && (urlControl?.dirty || urlControl?.touched || thisForm.submitted)">
													{{validation.message}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row form-group mt-2 mb-3 justify-content-center">
						<div class="col-md-6 d-flex justify-content-center" *ngIf="format.url">
							<button nbButton status="warning" (click)="delete()">
									<span>{{'INSTAGRAMVIDEO.DELETE' | translate}}</span>
							</button>
						</div>

						<div class="col-md-6 d-flex justify-content-center">
							<button nbButton class="btn-themecolor" type="submit">
								<span>{{'INSTAGRAMVIDEO.OK' | translate}}</span>
							</button>
						</div>

					</div>

			</div>

		</div>
	</form>
</nb-card-body>
