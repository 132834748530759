
import { ActionTypes, ActionsUnion } from '../actions/selected-profile.action';
import { initialSelectedProfile } from '../models/selected-profile.model';

export function selectedProfileReducer(state = initialSelectedProfile, action: ActionsUnion) {
  switch (action.type) {
    case ActionTypes.selectProfile:
      return action.payload;

    default:
			return state;
  }
}
