<div class="sticky-top sticky-top-header">
    <div class="modal-header" align="center">
        <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'NODOS.PANTALLAOPERADORES' | translate }}
        </span>
    </div>
    <div class="modal-body fondoEditarCurso">
        <form class="form-horizontal mb-2 pa-3">
            <div class="row">
                <div class="col-lg-3">
                    <img src="../../../../../assets/images/cerebro.png" class="img-fluid" />
                </div>
                <div class="col-lg-3 p-0">
                    <div class="h-100">
                        <mat-button-toggle-group class="flex flex-column">
                            <mat-button-toggle class="w-100 abstract" color="primary" (click)="showOperators('abstract')">REASONING</mat-button-toggle>
                            <mat-button-toggle class="w-100 temporary" color="accent" (click)="showOperators('temporary')">WHEN?</mat-button-toggle>
                            <mat-button-toggle class="w-100 assostatic" color="warn" (click)="showOperators('assostatic')">
                                <p>WHO?</p>
                                <p>WHERE?</p>
                                <p>HOW?</p>
                                <p>WHY?</p>
                                <p>FOR WHAT?</p>
                                <p>WITH WHAT?</p>
                            </mat-button-toggle>
                            <mat-button-toggle class="w-100 senmotemo" color="success" (click)="showOperators('senmotemo')">
                                <p>IT IS</p>
                                <p>I SENSE</p>
                                <p>I FEEL</p>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
                <div class="col-lg-6">

                    <div class="col-lg-12">
                        <div style="border: 1px solid #ccc; border-radius: 5px;">
                            <app-operators-table [type]="showType" [operators]="visibleOperators" (operatorClicked)="onOperatorClicked($event)"></app-operators-table>
                        </div>

                        <!-- BUTTONS -->
                        <div class="row mt-4">
                            <div class="col-md-6">
                                <button mat-flat-button color="primary" class="w-100" (click)="adicionarIconos(iconosSenmotemo,iconosAssostatic,iconosTemporary,iconosAbstract)">
                                    {{'NODOS.NOTOPERATORS' | translate}}
                                </button>
                            </div>
                            <div class="col-md-6">

                                <button mat-flat-button color="primary" class="w-100" [disabled]="!oneIconSelected" (click)="adicionarIconos(iconosSenmotemo,iconosAssostatic,iconosTemporary,iconosAbstract)">
                                    {{'ACEPTARCANCELAR.ACEPTAR' | translate}}
                                </button>
                            </div>
                        </div>
                        <!-- BUTTONS -->
                    </div>
                </div>
            </div>

        </form>
    </div>
