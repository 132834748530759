import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-modal-alert-challenges',
	templateUrl: './modal-alert-challenges.component.html',
	styleUrls: ['./modal-alert-challenges.component.scss']
})
export class ModalAlertChallengesComponent implements OnInit {

  mensaje: string;
	botonAceptar: string;
	disableCancel: boolean;

	assignedProjects: number;
	assignedStudents: number;
	action: string;
	resp: number = 0;
	group: any;

  constructor(
		public translateService: TranslateService,
    private activeModal: NgbActiveModal
	) {
  }

	ngOnInit() {
		if(this.assignedProjects === 0 && this.assignedStudents === 0 && this.action === "challenge"){
			this.mensaje = this.translateService.instant('ALERTGROUP.NOPROJECTNOSTUDENT');
			this.botonAceptar = this.translateService.instant('ALERTGROUP.ADDPROJECT');
			this.resp = 1;
		} else if(this.assignedProjects === 0 && this.assignedStudents != 0 && this.action === "challenge"){
			this.mensaje = this.translateService.instant('ALERTGROUP.NOPROJECT');
			this.botonAceptar = this.translateService.instant('ALERTGROUP.ADDPROJECT');
			this.resp = 2;
		} else if(this.assignedProjects != 0 && this.assignedStudents === 0 && this.action === "challenge"){
			this.mensaje = this.translateService.instant('ALERTGROUP.NOSTUDENT');
			this.botonAceptar = this.translateService.instant('ALERTGROUP.ADDSTUDENT');
			this.resp = 3;
		} else if(this.assignedProjects === 0 && this.assignedStudents === 0 && this.action === "report"){
			this.mensaje = this.translateService.instant('ALERTGROUP.NOPROJECTNOSTUDENT2');
			this.botonAceptar = this.translateService.instant('ALERTGROUP.ADDPROJECT');
			this.resp = 1;
		} else if(this.assignedProjects === 0 && this.assignedStudents != 0 && this.action === "report"){
			this.mensaje = this.translateService.instant('ALERTGROUP.NOPROJECT2');
			this.botonAceptar = this.translateService.instant('ALERTGROUP.ADDPROJECT');
			this.resp = 2;
		} else if(this.assignedProjects != 0 && this.assignedStudents === 0 && this.action === "report"){
			this.mensaje = this.translateService.instant('ALERTGROUP.NOSTUDENT2');
			this.botonAceptar = this.translateService.instant('ALERTGROUP.ADDSTUDENT');
			this.resp = 3;
		}
	}

  aceptar() {
    this.activeModal.close(this.resp);
  }

  cancelar() {
    this.activeModal.close(0);
  }

  closeModal(sendData?: any) {
    this.activeModal.close(0);
  }

}
