<!-- <div class="col-md-8 col-12">
	<app-loading [isVisible]="cargando"></app-loading>

	<div *ngIf="!cargando && cursos.length !== 0" class="bg-white">
		<div class="row" *ngIf="!viewList">
			<div
				*ngFor="let curso of cursos | sortBy : 'asc' : 'courseTittle'"
				(click)="verDetalleCurso(curso.idCourse)"
				class="col-lg-4 col-md-6 col-sm-12 pointer"
			>
				<div class="card form-group">
					<div
						class="img-container"
						ngbTooltip="{{ curso.description }}"
						placement="top"
					>
						<img
							[src]="curso.cpicture | imagen : 'cursos'"
							class="img-fluid"
							[alt]="curso.courseTittle"
							(error)="updateUrl($event)"
						/>
					</div>
					<div class="card-body">
						<h6 class="card-title text-center">
							{{
								curso.courseTittle
									| titlecase
									| truncate : [60, "..."]
									| uppercase
							}}
						</h6>
					</div>
				</div>
			</div>
		</div>

		<div class="row tree" *ngIf="viewList">
			<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
				<mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
					<button
						mat-icon-button
						(click)="verGrafo(node.item)"
						ngbTooltip="{{ 'NODOS.GRAFO' | translate }}"
					>
						<img
							style="width: 24px; height: 24px"
							class="icon-head"
							src="assets/images/icons/graph.png"
						/>
					</button>
					{{
						node.item
							| slicestring
							| titlecase
							| truncate : [60, "..."]
							| uppercase
					}}
				</mat-tree-node>

				<mat-tree-node
					*matTreeNodeDef="let node; when: hasChild"
					matTreeNodePadding
				>
					<button
						mat-icon-button
						class="iconos_izq"
						[attr.aria-label]="'Toggle ' + node.item"
						matTreeNodeToggle
					>
						<mat-icon
							class="mat-icon-rtl-mirror"
							style="width: 24px; height: 24px"
						>
							{{
								treeControl.isExpanded(node)
									? "arrow_drop_down"
									: "arrow_right"
							}}
						</mat-icon>

						<img
							style="width: 24px; height: 24px"
							src="assets/images/icons/curso.svg"
						/>
					</button>

					{{
						node.item
							| slicestring
							| titlecase
							| truncate : [60, "..."]
							| uppercase
					}}
					<mat-progress-bar
						*ngIf="node.isLoading"
						mode="indeterminate"
						class="example-tree-progress-bar"
					></mat-progress-bar>
				</mat-tree-node>
			</mat-tree>
		</div>
	</div>

	<div
		*ngIf="!cargando && !cursos.length"
		class="alert alert-warning text-center mt-5 animated fadeIn faster"
	>
		<h4 class="alert-heading">{{ "CURSOS.NOHAYREGISTROS" | translate }}</h4>
		<p>
			<i class="fa fa-warning fa-2x"></i>
		</p>
	</div>
</div>
</div> -->

<nb-card class="mb-0 no-edge-card">
	<nb-card-header class="border-bottom-0">
		<div class="row">
			<div class="col-12 modal-title">
				<h2>{{ "CURSOS.TITLE" | translate }}</h2>
			</div>
		</div>

		<div class="row mt-3">
			<div class="col-8 d-flex">
				<form (ngSubmit)="filtrarCursosNode()" class="search-form">
					<input type="search" autocomplete="off" nbInput fullWidth fieldSize="large" name="search" id="search"
						placeholder="{{ 'CURSOS.SEARCH' | translate }}" [(ngModel)]="filtro.courseTittle" />
					<nb-icon class="icon-color-blue closebtn pointer text-right m-1 float-right search-icon" icon="search"
						[options]="{ animation: { type: 'pulse' } }"></nb-icon>
					<!-- <input type="search" /> -->
				</form>
			</div>

			<!-- FILTRADO DE CURSOS -->
			<div class="col-4 d-flex justify-content-center align-content-center">
				<nb-actions size="medium">
					<nb-action *ngIf="viewList" icon="grid-outline" (click)="changeView(false)"></nb-action>
					<nb-action *ngIf="!viewList" icon="list-outline" (click)="changeView(true)"></nb-action>
					<nb-action icon="file-add-outline" *ngIf="loginService.esAutor()" (click)="nuevoCurso()"></nb-action>
					<!-- <nb-action icon="funnel-outline" disabled></nb-action> -->
				</nb-actions>

				<nb-toggle *ngIf="loginService.esEstudiante()" labelPosition="right" id="mycourses" [formControl]="isMyCourses"
					(change)="checkValue()">{{ "CURSOS.MYCOURSES" | translate }}</nb-toggle>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="row d-flex justify-content-center cards-container">
			<div class="col-sm-6 col-md-12 col-12">
				<app-loading [isVisible]="cargando"></app-loading>

				<div *ngIf="cursos.length !== 0">
					<div class="row" *ngIf="!viewList">
						<div *ngFor="let curso of cursos | sortBy : 'asc' : 'courseTittle'"
							(click)="verDetalleCurso(curso.idCourse)" class="col-lg-2 col-md-3 col-sm-12">
							<nb-card size="small" class="project-info pointer">
								<nb-card-header>
									<div class="img-container" ngbTooltip="{{ curso.description }}" placement="top">
										<img [src]="curso.cpicture | imagen : 'cursos'" class="img-fluid" [alt]="curso.courseTittle"
											(error)="updateUrl($event)" />
									</div>
								</nb-card-header>
								<nb-card-body>
									<p class="">
										{{
										curso.courseTittle
										| titlecase
										| truncate : [60, "..."]
										| uppercase
										}}
									</p>
								</nb-card-body>
								<nb-card-footer>
									<div *ngIf="isStudent == true"
										class="footer d-flex justify-content-center align-items-center mr-1 ml-1 mt-2">
										<div class="averageKnowledge mr-1">
											{{ curso.averageKnowledge }}
										</div>
										<div class="progress half-width">
											<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
												aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
												[style.backgroundColor]="curso.barColor" [style.width]="curso.progress + '%'"></div>
										</div>
										<div class="averageKnowledge ml-1">
											{{ curso.progress + "%" }}
										</div>
									</div>
								</nb-card-footer>
							</nb-card>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- VISTA MODO ARBOL -->
		<div class="row tree" *ngIf="viewList">
			<div class="col-4">
				<h5 class="text-title font-weight-bold">
					{{ "INFORMES.EXPANDIRVISTA" | translate }}
				</h5>
				<app-mat-tree-cursos [idCourse]="idCourse" [treeCourses]="treeCourses" (clickElement)="clickElement($event)"
					(createNewElement)="createNewElement($event)"></app-mat-tree-cursos>
			</div>

			<div class="col-md-8">
				<div class="w-100">
					<h5 class="text-title font-weight-bold">
						{{ "INFORMES.TABLA_DATOS_QUIZES" | translate }}
					</h5>
				</div>
				<div class="w-100">
					<!-- CONTAINER CON LAS OPCIONES SELECCIONADAS EN LA COLUMNA DE LA IZQUIERDA-->

					<div class="d-flex align-items-center justify-content-center pt-5" *ngIf="isLoading && !idCourse">
						<!--   <mat-spinner [diameter]="50" ></mat-spinner>           -->
					</div>

					<!-- VISUALIZAR NODO -->
					<div (mousemove)="showFiles(true)" (click)="showFiles(true)" *ngIf="node && !isLoading">
						<app-node-detail-files [showInContainer]="true" [showFilesContainer]="isShowFiles" [node]="node"
							[onSaveClicked$]="saveClicked$" [canEdit]="false" (fileSelected)="onFileSelected($event)"
							(onHoverElements)="showFiles($event)"></app-node-detail-files>
					</div>
					<!--FIN VISUALIZAR NODO -->

					<!-- VISUALIZAR QUIZ -->

					<div *ngIf="quiz && !isLoading">
						<!-- SIMPLE QUIZ -->
						<div *ngIf="quiz && quiz.idMultiplexQuiz === 0" class="quiz-container" id="quiz"
							(mousemove)="showFiles(true)" (click)="showFiles(true)">
							<div class="body-container py-3 hide-scrollbars" id="container">
								<app-quiz-play-body [disabledButton]="disabledButton" [courseId]="idSelectedCourse" [graphId]="idGraph"
									[quiz]="quiz" [elements]="elements" [answered]="answered" [modeAuto]="true" [modeSocket]="false"
									(result)="onViewGif($event)" (notResponseGif)="hidenGif($event)" (answeredChange)="onAnswered()">
								</app-quiz-play-body>
							</div>
						</div>
						<!-- END SIMPLE QUIZ -->

						<!-- MULTIPLE QUIZ -->
						<div *ngIf="quiz && quiz.idMultiplexQuiz === 1" class="quiz-container" id="quiz"
							(mousemove)="showFiles(true)" (click)="showFiles(true)">
							<div class="body-container py-3 hide-scrollbars">
								<app-quiz-play-multiple-body [disabledButton]="disabledButton" [courseId]="idSelectedCourse"
									[graphId]="idGraph" [quiz]="quiz" [elements]="elements" [answered]="answered" [modeAuto]="true"
									(result)="onViewGif($event)" (notResponseGif)="hidenGif($event)" (answeredChange)="onAnswered()">
								</app-quiz-play-multiple-body>
							</div>
						</div>
						<!-- END MULTIPLE QUIZ -->
					</div>

					<!-- FIN VISUALIZAR QUIZ -->
				</div>
			</div>
		</div>
		<!-- FIN VISTA MODO ARBOL -->
	</nb-card-body>

</nb-card>
