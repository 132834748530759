<div class="sticky-top sticky-top-header w-100">
    <div class="tittle py-2">
        <div class="row d-flex justify-content-between align-items-center">
            <div class="col-12 text-center">
                <span *ngIf="node.id !== 'temp'; else newNodeTitle"
                      class="textoFontSize08 font-weight-bold span-text-center">
                    {{ 'NODOS.NODE' | translate }}: {{node.label}}
                </span>
                <ng-template #newNodeTitle>
                    <span class="textoFontSize08 font-weight-bold span-text-center">
                        {{'NODOS.REGISTRARNODO' | translate}}
                    </span>
                </ng-template>
            </div>
        </div>
        <div class="close" style="position: absolute; right: .5em; top: .35em">
            <button  *ngIf="!modeAuto" mat-icon-button style="float: right;" type="button" [ngStyle]="{'color':'rgb(29, 73, 153)'}" data-dismiss="modal" (click)="closeModal('')">
                <span class="textoFontSize18 font-weight-bold span-text-center" aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>

<div class="header-options">
    <div class="row">
        <div class="col d-flex justify-content-between align-items-center py-2">
            <div class="pl-3">
                <button mat-icon-button (click)="showInfo()">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </div>
            <div class="pr-3 d-inline-block d-md-flex justify-content-between align-items-center">

                <button class="mr-md-3" mat-icon-button ngbTooltip="{{'NODOS.DELETE' | translate }}" (click)="deleteNode()" *ngIf="loginService.esAutor()">
                    <mat-icon svgIcon="delete" class="icon-head pointer" data-toggle="tooltip" data-placement="top"></mat-icon>
                </button>

                <div class="mr-md-3" *ngIf="loginService.esAutor()" >
                    <form [formGroup]="form">
                        <mat-slide-toggle formControlName="published" (click)="publish($event)">{{'EDITARCURSO.PUBLISH' | translate}}</mat-slide-toggle>
                    </form>
                </div>

                <button class="mr-md-3 disabled" mat-icon-button ngbTooltip="{{'NODOS.HELP' | translate }}">
                    <mat-icon svgIcon="sos" class="icon-head pointer" data-toggle="tooltip" data-placement="top"></mat-icon>
                </button>

                <button class="mr-md-3" mat-icon-button>
                    <img style="width: 28px; height: 28px" class="icon-head" src="assets/images/icons/libro_magico.png" />
                </button>

                <ng-container class="mr-md-3">
                    <app-share-button></app-share-button>
                </ng-container>

                <ng-container class="mr-md-3">
                    <app-report-button></app-report-button>
                </ng-container>

                <button class="mr-md-3" mat-icon-button ngbTooltip="{{'NODOS.PRINT' | translate }}">
                    <mat-icon class="icon-head  pointer" (click)="imprimir()">
                        print
                    </mat-icon>
                </button>

                <button class="mr-md-3" mat-icon-button ngbTooltip="{{'NODOS.INFORMATION' | translate }}">
                    <mat-icon class="icon-head  pointer">
                        info
                    </mat-icon>
                </button>

                <mat-button-toggle-group [multiple]="true">
                    <mat-button-toggle mat-icon-button>
                        <mat-icon class="icon-head pointer">
                            visibility
                        </mat-icon>
                    </mat-button-toggle>

                    <mat-button-toggle mat-icon-button>
                        <mat-icon class="icon-head pointer">
                            hearing
                        </mat-icon>
                    </mat-button-toggle>

                    <mat-button-toggle mat-icon-button>
                        <mat-icon class="icon-head pointer">
                            mic
                        </mat-icon>
                    </mat-button-toggle>

                    <mat-button-toggle mat-icon-button>
                        <mat-icon class="icon-head pointer">
                            pan_tool
                        </mat-icon>
                    </mat-button-toggle>

                    <mat-button-toggle mat-icon-button>
                        <mat-icon class="icon-head pointer">
                            keyboard
                        </mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>

            </div>
        </div>
    </div>
</div>
