<div class="h-100 mh-100 w-100 modal-body" id="node">
    <app-loading [isVisible]="cargando"></app-loading>
    <app-node-detail-transparent-header [course]="course"  [node]="node" [type]="type" [idCurso]="idCurso" [idMapa]="idMapa"></app-node-detail-transparent-header>
    <app-node-menu [showFiles]="isShowFiles" [course]="course" [node]="node" [idCurso]="idCurso" [idMapa]="idMapa" [canEdit]="canEdit" (showPads)="showPads($event)" (showLabels)="showLabels($event)" [style.right]="!isShowFiles ? '3%' : '18%'"></app-node-menu>
    <app-node-pad-buttons [isShow]="isShowPads" [isShowLabels]="isShowLabels" (action)="getAction($event)"></app-node-pad-buttons>
    <!-- <app-node-pad-controller [isShow]="isShowPads" [isShowLabels]="isShowLabels" (action)="getControllerAction($event)"></app-node-pad-controller> -->
    <div (mousemove)="showFiles(true)" (click)="showFiles(true)">
        <app-node-detail-files [showFilesContainer]="isShowFiles" [node]="node" [inicial]="inicial" [mensaje]="mensaje" [onSaveClicked$]="saveClicked$" [canEdit]="canEdit" (nodeFilesTypeChanged)="onNodeFilesTypeChanged($event)" (nodeFileAdded)="onNodeFileAdded($event)" (onHoverElements)="showFiles($event)"></app-node-detail-files>
    </div>
</div>
