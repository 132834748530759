<nb-card class="no-edge-card mb-0 background_themecolor modal-height-100-30" (click)="closeFiltersPanel()">
	<nb-card-header class="px-3 py-0 flex flex-column border-bottom-0">

		<div class="row p-4 background_white" style="gap: 2rem;">
			<!-- Botones de Navegacion -->
			<!-- <div class="col-12 col-md-4 col-lg-2 d-flex align-items-center p-0" style="flex-basis: content;">
				<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center checked-page">
					<mat-icon svgIcon="graph" [ngbTooltip]="'MENUACTIONS.STUDENTTITLE2' | translate"></mat-icon>
				</button>

				<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center mx-4" (click)="informesStudentGrupo()">
					<mat-icon svgIcon="informes" [ngbTooltip]="'MENUACTIONS.STUDENTTITLE8' | translate"></mat-icon>
				</button>

				<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center">
					<mat-icon svgIcon="desafios_icon" [ngbTooltip]="'MENUACTIONS.STUDENTTITLE7' | translate"></mat-icon>
				</button>
			</div> -->
			<div class="col-12 col-md-7 col-lg-8 modal-title px-2">
				<h4 *ngIf="screenView === 3">{{'EDITARCURSOMAPA.PROYECTODESTINO' | translate}}</h4>
				<h4 *ngIf="screenView === 4">{{'EDITARCURSOMAPA.PROYECTODESTINO_COPY' | translate}}</h4>
			</div>
		</div>

		<!-- Filtros -->
		<div class="row px-3 py-2 mt-3">
			<div class="col-12 col-md-6 col-lg-9 mb-4 mb-md-0">
				<!-- Version movil -->
				<div *ngIf="isMobile;" class="justify-content-end">
					<div class="d-flex align-items-center" style="gap: 2rem;">
						<button nbButton class="btn-themecolor" size="small" (click)="toggleFilters()">{{ buttonText}}</button>
						<nb-action *ngIf="viewList" icon="grid-outline" nbTooltip="{{ 'CURSOS.VERMOSAICO' | translate }}"
							nbTooltipPlacement="top" (click)="changeView(false)"></nb-action>
						<nb-action *ngIf="!viewList" icon="list-outline" nbTooltip="{{ 'CURSOS.VERARBOL' | translate }}"
							nbTooltipPlacement="top" (click)="changeView(true)"></nb-action>
						<nb-action class="icon-option icon-proyecto-muchos-grafos position-relative pointer" nbTooltip="{{ 'CURSOS.NUEVOCURSO' | translate }}"
							nbTooltipPlacement="top" *ngIf="loginService.esAutor()" (click)="nuevoCurso()">
							<nb-icon class="plus-editor" icon="plus-outline"></nb-icon>
						</nb-action>
					</div>
					<div class="filtros" [ngClass]="{ 'show-filters': showElement }">
						<div class="item-filtros">
							<div>
								<nb-select name="lang1" placeholder="small" size="small" [(selected)]="selectedOrder" (selectedChange)="changeOrder($event)">
									<nb-option *ngFor="let order of orderList" [value]="order.idOrder">{{ order.nombre }}
									</nb-option>
								</nb-select>
							</div>
							<div>
								<nb-actions size="small">
									<nb-action icon="clock-outline" nbTooltip="Historial" nbTooltipStatus="primary"
										(click)="historyFilter()"></nb-action>
									<nb-action icon="funnel-outline" [nbPopover]="filtersTemplate" nbPopoverPlacement="bottom"
										nbPopoverTrigger="noop" (click)="openFiltersPanel()"></nb-action>
									<ng-template #filtersTemplate>
										<nb-card class="m-0">
											<nb-card-header>
												<p>{{'FILTER.FILTER' | translate}}</p>
												<button nbButton class="btn-themecolor" type="button" (click)="clearFilters()">
													<span>{{'FILTER.CLEARFILTER' | translate}}</span>
												</button>
											</nb-card-header>
											<nb-card-body class="container-filters">

														<div class="column-filters">
															<nb-select name="idProjectsTypes" placeholder="{{'FILTER.PROJECTTYPE' | translate}}" size="small" [(selected)]="selectedProjectType" (selectedChange)="applyFilters($event, 1)">
																<nb-option *ngFor="let projectType of projectTypeList" [value]="projectType.idProjectsTypes">{{ projectType.descripcion }}</nb-option>
															</nb-select>

															<nb-select name="lang1" placeholder="{{'FILTER.LANGUAGE' | translate}}" size="small" [(selected)]="selectedLang"
																(selectedChange)="applyFilters($event,2)">
																<nb-option *ngFor="let lang of langList" [value]="lang.idLang">{{ lang.nombre }}
																</nb-option>
															</nb-select>

															<nb-select name="subject" placeholder="{{'FILTER.SUBJECT' | translate}}" size="small" [(selected)]="selectedSubject"
																(selectedChange)="applyFilters($event,3)">
																<nb-option *ngFor="let subject of subjectsList" [value]="subject.idSubject">{{ subject.subject }}
																</nb-option>
															</nb-select>

													<!-- <nb-select name="idCountry" size="small" [(selected)]="selectedCountry" placeholder="pais"
													(selectedChange)="searchLevels($event);applyFilters($event,3);">
													<nb-option *ngFor="let country of countriesList" value="{{ country.idCountry }}">{{ country.country_es }}
													</nb-option>
												</nb-select> -->

															<ng-select name="idCountry" [(selected)]="selectedCountry" size="small"
																placeholder="{{'FILTER.COUNTRY' | translate}}" appearance="outline" (change)="searchLevels($event);applyFilters($event,4);"
																	>
																	<ng-option  *ngFor="let country of countriesList" value="{{ country.idCountry }}">{{ country.country_es }}</ng-option>
															</ng-select>

															<nb-select name="idCountryLevel" size="small" [(selected)]="selectedLevelCountry"
																[placeholder]="(!filterCountrySelected ? 'FILTER.SELECTED_COUNTRY' : 'FILTER.LEVEL') | translate"
																[disabled]="!filterCountrySelected" (selectedChange)="applyFilters($event,5)">
																<nb-option *ngFor="let level of levelsOfCountry" [value]="level.idCountryLevel">{{ level.countryLevel }}
																</nb-option>

														<nb-option
															*ngIf="notCountryLevelsForThisCountry">{{'MODALINFORMATIONCOURSE.NOTLEVELSFORCOUNTRY'
															|
															translate}}</nb-option>
													</nb-select>

														</div>
														<div class="column-filters">
															<button nbButton shape="round" size="small" class="btn-themecolor" type="button" (click)="clearFiltersList(1)"
																[disabled]="selectedProjectType == null"><nb-icon icon="close"></nb-icon></button>
															<button nbButton shape="round" size="small" class="btn-themecolor" type="button" (click)="clearFiltersList(2)"
																[disabled]="selectedLang == null"><nb-icon icon="close"></nb-icon></button>
															<button nbButton shape="round" size="small" class="btn-themecolor" type="button" (click)="clearFiltersList(3)"
																[disabled]="selectedSubject == null"><nb-icon icon="close"></nb-icon></button>
															<button nbButton shape="round" size="small" class="btn-themecolor" type="button" (click)="clearFiltersList(4)"
																[disabled]="selectedCountry == null"><nb-icon icon="close"></nb-icon></button>
															<button nbButton shape="round" size="small" class="btn-themecolor" type="button" (click)="clearFiltersList(5)"
																[disabled]="selectedLevelCountry == null"><nb-icon icon="close"></nb-icon></button>
														</div>


											</nb-card-body>
										</nb-card>
									</ng-template>
								</nb-actions>
							</div>
							<div style="gap: 0.5rem;" class="d-flex flex-column align-items-center" *ngIf="loginService.esEstudiante()">
								<nb-toggle class="item-profile" id="mycourses" [formControl]="isMyCourses" (change)="checkValue()"></nb-toggle>
								<span class="text-center" style="line-height: 1rem; font-size: 12px;">{{'CURSOS.MYCOURSES' | translate}}</span>
							</div>

							<div style="gap: 0.5rem;" class="d-flex flex-column align-items-center"
								*ngIf="loginService.esEstudiante() && infoGrupo != null">
								<nb-toggle class="item-profile" id="mycourses" (change)="getListadoCursosGrupo($event)"></nb-toggle>
								<span class="text-center" style="line-height: 1rem; font-size: 12px;">Cursos del grupo</span>
							</div>

							<div style="gap: 0.5rem;" class="d-flex flex-column align-items-center" *ngIf="viewList">
								<nb-toggle class="item-profile" id="activateHelpList" [formControl]="activateHelpList" (change)="helpListToggle(activateHelpList.value)"></nb-toggle>
								<span class="text-center" style="line-height: 1rem; font-size: 12px;">{{ 'SOS.HELPLIST' | translate}}</span>
							</div>

						</div>
					</div>
				</div>
				<!-- Version web -->
				<div *ngIf="!isMobile;" class="d-flex align-items-center" style="gap: 2rem;">
					<div class="d-flex flex-column align-items-center">
						<nb-select name="lang1" [(selected)]="selectedOrder" placeholder="small" size="small" (selectedChange)="changeOrder($event)">
							<nb-option *ngFor="let order of orderList" [value]="order.idOrder">{{ order.nombre }}
							</nb-option>
						</nb-select>
					</div>

					<div style="gap: 0.5rem;" class="d-flex flex-column align-items-center" *ngIf="loginService.esEstudiante()">
						<nb-toggle class="item-profile" id="mycourses" [formControl]="isMyCourses" (change)="checkValue()"></nb-toggle>
						<span class="text-center" style="line-height: 1rem; font-size: 12px;">{{'CURSOS.MYCOURSES' | translate}}</span>
					</div>

					<div style="gap: 0.5rem;" class="d-flex flex-column align-items-center"
						*ngIf="loginService.esEstudiante() && infoGrupo != null">
						<nb-toggle class="item-profile" id="mycourses" (change)="getListadoCursosGrupo($event)"></nb-toggle>
						<span class="text-center" style="line-height: 1rem; font-size: 12px;">Proyectos del grupo</span>
					</div>

					<div style="gap: 0.5rem;" class="d-flex flex-column align-items-center" *ngIf="viewList">
						<nb-toggle class="item-profile" id="activateHelpList" [formControl]="activateHelpList" (change)="helpListToggle(activateHelpList.value)"></nb-toggle>
						<span class="text-center" style="line-height: 1rem; font-size: 12px;">{{ 'SOS.HELPLIST' | translate}}</span>
					</div>

					<nb-actions size="small">
						<nb-action icon="clock-outline" [ngClass]="{'selected-icons': historyFilterActive}" nbTooltip="Historial" nbTooltipPlacement="top" (click)="historyFilter()"></nb-action>

						<nb-action icon="funnel-outline" [ngClass]="{'selected-icons': iconSelected}" [nbPopover]="filtersTemplate" nbPopoverPlacement="bottom" nbTooltip="Filtros" nbTooltipPlacement="top"
							nbPopoverTrigger="noop" (click)="openFiltersPanel()"></nb-action>

						<ng-template #filtersTemplate>
							<nb-card class="m-0">
								<nb-card-header class="border-bottom-0">
									<p>{{'FILTER.FILTER' | translate}}</p>
									<div class="d-flex justify-content-center">
										<button nbButton size="small" class="btn-themecolor" type="button"
											(click)="clearFilters();selectLang.handleClearClick();selectSubject.handleClearClick();selectCountry.handleClearClick();clearFiltersList(4);clearFilters();">
											<span>{{'FILTER.CLEARFILTER' | translate}}</span>
										</button>
									</div>
								</nb-card-header>
								<nb-card-body class="container-filters">

												<div class="column-filters">
													<!-- <nb-select name="lang1" placeholder="Idioma" size="small" [(selected)]="selectedLang"
														(selectedChange)="applyFilters($event,1)">
														<nb-option *ngFor="let lang of langList" [value]="lang.idLang">{{ lang.nombre }}
														</nb-option>
													</nb-select> -->
													<nb-select #selectProjectType name="idProjectsTypes" appearance="outline" placeholder="{{'FILTER.PROJECTTYPE' | translate}}" size="medium"
														[(selected)]="selectedProjectType"
														(selectedChange)="applyFilters($event,0)">
																<nb-option *ngFor="let projectType of projectTypeList" [value]="projectType.id">{{ projectType.descripcion }}
																</nb-option>
													</nb-select>

													<nb-select #selectLang name="lang1" appearance="outline" placeholder="{{'FILTER.LANGUAGE' | translate}}" size="medium"
														[(selected)]="selectedLang"
														(selectedChange)="applyFilters($event,1)">
																	<nb-option *ngFor="let lang of langList" [value]="lang.idLang">{{ lang.nombre }}</nb-option>
													</nb-select>

										<!-- <nb-select name="subject" placeholder="Asignatura" size="small" [(selected)]="selectedSubject"
										(selectedChange)="applyFilters($event,2)">
										<nb-option *ngFor="let subject of subjectsList" [value]="subject.idSubject">{{ subject.subject }}
										</nb-option>
									</nb-select> -->

													<nb-select #selectSubject name="subject" appearance="outline" placeholder="{{'FILTER.SUBJECT' | translate}}" size="medium"
														[(selected)]="selectedSubject"
														(selectedChange)="applyFilters($event,2)">
																	<nb-option *ngFor="let subject of subjectsList" [value]="subject.idSubject">{{ subject.subject }}</nb-option>
													</nb-select>

										<!-- <nb-select name="idCountry" size="small" [(selected)]="selectedCountry" placeholder="pais"
										(selectedChange)="searchLevels($event);applyFilters($event,3);">
										<nb-option *ngFor="let country of countriesList" value="{{ country.idCountry }}">{{ country.country_es }}
										</nb-option>
									</nb-select> -->

													<nb-select #selectCountry name="idCountry" [(selected)]="selectedCountry" size="medium"
																placeholder="{{'FORM.SPAIN' | translate}}" appearance="outline" (selectedChange)="searchLevels($event);applyFilters($event,3);">
																	<nb-option  *ngFor="let country of countriesList" value="{{ country.idCountry }}">{{ country.country_es }}</nb-option>
													</nb-select>

													<nb-select name="idCountryLevel" size="medium" [(selected)]="selectedLevelCountry"
														[placeholder]="(!filterCountrySelected ? 'FILTER.SELECTED_COUNTRY' : 'FILTER.LEVEL') | translate"
														[disabled]="!filterCountrySelected" (selectedChange)="applyFilters($event,4)">
														<nb-option *ngFor="let level of levelsOfCountry" [value]="level.idCountryLevel">{{ level.countryLevel }}</nb-option>
														<nb-option *ngIf="notCountryLevelsForThisCountry">{{'MODALINFORMATIONCOURSE.NOTLEVELSFORCOUNTRY' | translate}}</nb-option>
													</nb-select>

									</div>
									<!-- <div class="column-filters">
									<button nbButton shape="round" size="small" class="btn-themecolor" type="button" (click)="clearFiltersList(1)"
										[disabled]="selectedLang == null"><nb-icon icon="close"></nb-icon></button>
									<button nbButton shape="round" size="small" class="btn-themecolor" type="button" (click)="clearFiltersList(2)"
										[disabled]="selectedSubject == null"><nb-icon icon="close"></nb-icon></button>
									<button nbButton shape="round" size="small" class="btn-themecolor" type="button" (click)="clearFiltersList(3)"
										[disabled]="selectedCountry == null"><nb-icon icon="close"></nb-icon></button>
									<button nbButton shape="round" size="small" class="btn-themecolor" type="button" (click)="clearFiltersList(4)"
										[disabled]="selectedLevelCountry == null"><nb-icon icon="close"></nb-icon></button>
								</div> -->


								</nb-card-body>
							</nb-card>
						</ng-template>

						<nb-action *ngIf="viewList" icon="grid-outline" nbTooltip="{{ 'CURSOS.VERMOSAICO' | translate }}"
							nbTooltipPlacement="top" (click)="changeView(false)"></nb-action>

						<nb-action *ngIf="!viewList" icon="list-outline" nbTooltip="{{ 'CURSOS.VERARBOL' | translate }}"
							nbTooltipPlacement="top" (click)="changeView(true)"></nb-action>

					</nb-actions>
				</div>
			</div>
			<!-- Buscador -->
			<div class="col-12 col-md-6 col-lg-3 d-flex align-items-center">
				<form [formGroup]="formulario" class="search-form">
					<input type="search" nbInput fullWidth fieldSize="small" placeholder="{{ 'CURSOS.SEARCH' | translate }}"
						formControlName="filtrado">
					<nb-icon class="icon-color-blue closebtn pointer text-right m-1 float-right search-icon" icon="search"
						[options]="{ animation: { type: 'pulse' } }"></nb-icon>
				</form>
			</div>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<button nbButton class="btn-themecolor" (click)="back()">
			{{'GRUPOS.VOLVER' | translate}} </button>
	</div>

	<nb-card-body>
		<!-- VISTA MODO GRILLA -->
		<div *ngIf="!viewList" class="row background_white d-flex justify-content-center py-4 px-2 min-h-100">
			<div class="col-12">
				<app-loading [isVisible]="cargando"></app-loading>
				<div *ngIf="cursos.length === 0">
					<nb-card>
						<nb-card-body>
							<h4>{{ 'CURSOS.FILTERNOTMATCH' | translate }}</h4>
						</nb-card-body>
					</nb-card>
				</div>
				<div *ngIf="cursos.length !== 0">
					<div class="row" *ngIf="!viewList">
						<div *ngFor="let curso of cursos" (click)="accionGrafo(curso)"
							class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
							<nb-card size="small" class="pointer"
							[ngClass]="{'project-info-1': curso.project_type == 1, 'project-info-2': curso.project_type == 2, 'project-info-3': curso.project_type == 3, 'project-info-4': curso.project_type == 4}">
								<nb-card-header class="p-2">
									<div class="img-container" nbTooltip="{{curso.description}}" nbTooltipPlacement="top">
										<img [src]="curso.cpicture | imagen:'cursos'" class="img-fluid" (error)="updateUrl($event)">
									</div>
								</nb-card-header>
								<nb-card-body class="p-2">
									<p *ngIf="isStudent == true" class="courseTittle">{{
										curso.courseTittle | titlecase | truncate:[60, '...'] | uppercase}}</p>

									<p *ngIf="isStudent == false" class="courseTittle-teacher">{{
										curso.courseTittle | titlecase | truncate:[60, '...'] | uppercase}}</p>
								</nb-card-body>
								<nb-card-footer *ngIf="isStudent == true && !actividadesFlash">
									<div class="footer d-flex justify-content-center align-items-center mr-1 ml-1">
										<div class="averageKnowledge mr-1">{{curso.averageKnowledge }}</div>
										<div class="progress half-width">
											<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
												aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
												[style.backgroundColor]="curso.barColor" [style.width]="curso.progress+'%'"></div>
										</div>
										<div class="averageKnowledge ml-1">{{curso.progress+'%'}}</div>
									</div>
								</nb-card-footer>
							</nb-card>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- VISTA MODO LISTA -->
		<div class="row min-h-100" style="gap: 1.5rem;" *ngIf="viewList">

			<div class="col-12 col-md-4 py-4 pl-4 pr-0 container-tree background_white">
				<h5 class="text-title font-weight-bold pr-4 mb-4">
					{{ 'INFORMES.EXPANDIRVISTA' | translate }}
				</h5>
				<app-mat-tree-cursos [isHelpList]="activateHelpList.value" [idCourse]="idCourse" [treeCourses]="treeCourses" [fromMover]="true"
					(clickElement)="accionGrafo($event)" (createNewElement)="createNewElement($event)"></app-mat-tree-cursos>
			</div>

			<div class="col py-4 pl-4 pr-0 container-tree background_white">
				<!-- BOTONES PARA IR CAMBIANDO ENTRE QUIZZES MULTIPLES COMO PROFESOR -->
				<div id="botones-multiples" *ngIf="!loginService.esEstudiante() && (quiz && quiz.idMultiplexQuiz === 1)"
					class="d-flex justify-content-center align-items-center flex-wrap">
					<button *ngFor="let hijo of listQM; let i = index"
						[ngClass]="{'boton-qM': true, 'boton-qM-selected': i === selectedButtonIndex}" [class.flex-grow-1]="true"
						(click)="selectButton(i, hijo.quizMultiple.idQuizMultiple)">
						{{ i + 1 }}
					</button>
				</div>
				<!-- END BOTONES PARA IR CAMBIANDO ENTRE QUIZZES MULTIPLES COMO PROFESOR -->

				<!-- <div class="row justify-content-center">
					<div class="w-100 col-sm-12 col-md-10 mb-3">

						<h5 class="text-title text-center font-weight-bold">
							{{ 'INFORMES.TABLA_DATOS_QUIZES' | translate }}
						</h5>

					</div>
				</div> -->

				<div class="w-100 height-max">
					<!-- CONTAINER CON LAS OPCIONES SELECCIONADAS EN LA COLUMNA DE LA IZQUIERDA-->
					<div class="d-flex align-items-center justify-content-center pt-5" *ngIf="isLoading && !idCourse">
						<!--   <mat-spinner [diameter]="50" ></mat-spinner>           -->
					</div>
					<!-- VISUALIZAR NODO -->
					<div (mousemove)="showFiles(true)" (click)="showFiles(true)" *ngIf="node && !isLoading">
						<app-node-detail-files [showInContainer]="true" [showFilesContainer]="isShowFiles" [node]="node"
							[onSaveClicked$]="saveClicked$" [canEdit]="false" (fileSelected)="onFileSelected($event)"
							(onHoverElements)="showFiles($event)"></app-node-detail-files>
					</div>
					<!--FIN VISUALIZAR NODO -->
					<!-- VISUALIZAR QUIZ -->
					<div *ngIf="quiz && !isLoading">
						<!-- SIMPLE QUIZ -->
						<div *ngIf="quiz && quiz.idMultiplexQuiz === 0" class="quiz-container" id="quiz"
							(mousemove)="showFiles(true)" (click)="showFiles(true)">
							<div class="body-container p-3 hide-scrollbars" id="container">
								<app-quiz-play-body [disabledButton]="disabledButton" [courseId]="idSelectedCourse" [graphId]="idGraph"
									[quiz]="quiz" [elements]="elements" [answered]="answered" [modeAuto]="true" [modeSocket]="false"
									(result)="onViewGif($event)" (notResponseGif)="hidenGif($event)" (answeredChange)="onAnswered()">
								</app-quiz-play-body>
							</div>
						</div>
						<!-- END SIMPLE QUIZ -->
						<!-- MULTIPLE QUIZ -->
						<div *ngIf="quiz && quiz.idMultiplexQuiz === 1" class="quiz-container" id="quiz"
							(mousemove)="showFiles(true)" (click)="showFiles(true)">
							<div class="body-container p-3 hide-scrollbars">
								<app-quiz-play-multiple-body [disabledButton]="disabledButton" [courseId]="idSelectedCourse"
									[graphId]="idGraph" [quiz]="quiz" [elements]="elements" [answered]="answered" [modeAuto]="true"
									(result)="onViewGif($event)" (notResponseGif)="hidenGif($event)" (answeredChange)="onAnswered()"
									[listQM]="listQM">
								</app-quiz-play-multiple-body>
							</div>
						</div>
						<!-- END MULTIPLE QUIZ -->
					</div>
					<!-- FIN VISUALIZAR QUIZ -->
				</div>
			</div>
		</div>
		<!-- FIN VISTA MODO ARBOL -->
	</nb-card-body>

</nb-card>
