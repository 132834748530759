import { Estado } from "src/app/core/models/tuturial/tutorial.model";
import { TutorialDataClass } from "src/app/core/models/users/user.models";

export interface loggedInUser {
		idUser: number;
    firstName: string;
    surname: string;
    keyWord: string;
    mail: string;
    mobile: string;
    profile: string;
    birthdate: number;
    idGenre: number;
    idLanguageIso: string;
    pictureUser: string;
    tutorialSW: TutorialDataClass;
    invitedToGroup: string;
		identification: string;
		extension?: string;
		profileEditor?: boolean;
		profileStudent?: boolean;
		profileTeacher?: boolean;
		profileParent?: boolean;
		profileAdmin?: boolean;
}

export const initialUser: loggedInUser =
{
	idUser: 1,
	birthdate:1,
	extension:"",
	firstName:"jhon",
	idGenre:1,
	idLanguageIso:"es",
	keyWord:"-",
	mail:"",
	mobile:"",
	pictureUser:"",
	profile:null,
	surname:"doe",
	tutorialSW:{estado: Estado.NO_MOSTRAR, fechaAlta: new Date, fechaFinal: null},
	invitedToGroup:null,
	identification:"5732068923",
}


