import { finalize } from 'rxjs/operators';
import { Utils } from './../../../../core/utils/utils';
import { UsersService } from './../../../../core/services/users/users.service';
import { User } from './../../../../core/models/users/user.models';

import { CourseTargetModel } from './../../../../core/models/courses/course-target.model';
import { ImagenPipe } from './../../../pipes/imagen.pipe';
import { ErrorStateMatcher } from '@angular/material/core';
import { IdiomaModel } from './../../../../core/models/masters/idioma.model';
import { NivelModel } from './../../../../core/models/masters/nivel.model';
import { MateriaModel } from 'src/app/core/models/masters';
import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, NgForm, FormGroupDirective } from '@angular/forms';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Models
import { CourseModel } from 'src/app/core/models/courses';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from 'src/app/core/services/courses';
import { TargetsService } from 'src/app/core/services/targets';
import { LoginService } from 'src/app/core/services/login';
import { AlertService } from 'src/app/core/services/shared';

// Components
import { ModalEditarCursoMapaComponent } from 'src/app/shared/components/cursos/modal-editar-curso-mapa';

import { ModalAceptarCancelarComponent } from 'src/app/shared/components/modal';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { environment } from 'src/environments/environment';
import { NgxCopilotService } from 'ngx-copilot';
import { CursoData, TutotrialDataLocal } from 'src/app/core/models/tuturial/tutorial.model';
import { LocalStorage } from 'src/app/core/utils/local-storage';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

const NOIMAGE = "../../../../../assets/images/icons/account_circle.svg"
export class ParentGroupValidationStateMatcher implements ErrorStateMatcher {
	isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const invalidCtrl = !!(control && control.invalid && (control.dirty || control.touched));
		const invalidParent = !!(control && (control.dirty || control.touched) && control.parent && control.parent.invalid && control.parent.dirty);

		return (invalidCtrl || invalidParent);
	}
}

@Component({
	selector: 'app-modal-editar-curso',
	templateUrl: './modal-editar-curso.component.html',
	styleUrls: ['./modal-editar-curso.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [ ImagenPipe ]
})


export class ModalEditarCursoComponent implements OnInit {
	@Input() id: string;
  @Input() showModalFormat: boolean = true
	tutorialCurso: CursoData = {
		btnNuevo: false,
		btnPublicar: false,
		ideografo: true,
		editGrafo: false
	}
	public formCourse: UntypedFormGroup;
	public progress: number = 0
    public graphView:string = 'gridList'
	clickSidebar: boolean = false;

	curso: CourseModel
	courseTargets: CourseTargetModel[] = []
	idImage: string = ''

	user: User
	saving: boolean = false

	matcher = new ParentGroupValidationStateMatcher();

	// Maestros
	materias: MateriaModel[] = [];
	niveles: NivelModel[] = [];
	idiomas: IdiomaModel[] = [];
	edadEstimada: number[] = [ 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];
	cargando: boolean = false;

	scrolled: boolean = false;

	validationMessages = {
			title: [],
			description: [],
			subject: [],
			lang1: [],
			project_type: [],
			level: [],
			image: []
	};

	options:any
	exclusiveOptions:any;

	@ViewChild('copilot', { static: true }) copilotContainer: ElementRef<HTMLElement>;


	constructor(public router: Router,
		public translateService: TranslateService,
		public coursesService: CoursesService,
		public targetsService: TargetsService,
		private getDataService: GetDataService,
		public loginService: LoginService,
		private alertService: AlertService,
		private modalService: NgbModal,
		public activeModal: NgbActiveModal,
		private fb: UntypedFormBuilder,
		private imagePipe: ImagenPipe, private  copilot:  NgxCopilotService, elem: ElementRef, private localStorageService: LocalStorage,
		public userService:UsersService,
		private utils:Utils,
		private toaster: ToasterService
	) {
		this.createForm()

		//Drag&Drop update function
		this.options = {
				draggable: '.draggable',
				onUpdate: (event: any) => {
						this.saveChanges(event,false);
				}
		};

		this.exclusiveOptions = {
			draggable: '.draggable',
			onUpdate: (event: any) => {
					this.saveChanges(event, true);
			}
		};
	}

	tutorialDisponible() {
		if(this.loginService.comprobarTutorial()){
			let canvasGrafoTutorial = JSON.parse(this.localStorageService.getItem(LOCALSTORAGESTRINGS.TUTORIALDATA)) as TutotrialDataLocal;
			this.tutorialCurso = canvasGrafoTutorial.curso;
				this.abrirTutorial();
		}
		else {
			this.tutorialCurso.btnNuevo = true;
			this.tutorialCurso.editGrafo = true;
			this.tutorialCurso.btnPublicar = true;
			this.tutorialCurso.ideografo = true;
		}
	}
	abrirTutorial() {
		if (!this.tutorialCurso.btnNuevo) {
			// this.cargando = true;
			setTimeout(() => {
			// this.cargando = false;
				this.initPosition(1);
			}, 1000);
		}
	}

	updateUrl(event: any) {
		event.target.src = '../../assets/images/no-image.png'
	}

	ngOnInit() {

		this.cargando = true;
		this.user = this.loginService.getUser()
        this.materias = this.getDataService.appSubjects
        this.idiomas = this.getDataService.appLanguages

		// Obtengo los maestros
		this.obtenerDatosMaestros();

		if (this.id !== 'nuevo') {
			// Si no es nuevo busco sus datos
			this.obtenerDatosCurso(this.id);
			this.tutorialDisponible()
		}
		else
			this.cargando = false;

		this._translateText()


	}

	/*Re initialize in specify step*/
	initPosition  = (stepNumber:any) => {
		this.copilot.checkInit(stepNumber)

	};

	/*Next Step*/
	nextStep = (stepNumber:any) =>  this.copilot.next(stepNumber);

	/*Finish*/
	done= () =>  {
		this.copilot.removeWrapper()
		let cursoTotorial = JSON.parse(this.localStorageService.getItem(LOCALSTORAGESTRINGS.TUTORIALDATA)) as TutotrialDataLocal;
		cursoTotorial.curso.ideografo = true;
		this.loginService.updateDataLocalTutorial(cursoTotorial);
	};

	helpCurso = () => {
		let cursoTotorial = JSON.parse(this.localStorageService.getItem(LOCALSTORAGESTRINGS.TUTORIALDATA)) as TutotrialDataLocal;
		cursoTotorial.curso.btnNuevo = true;
		cursoTotorial.curso.btnPublicar = true;
		cursoTotorial.curso.ideografo = true;
		this.copilot.removeWrapper();
		this.loginService.updateDataLocalTutorial(cursoTotorial);
	};


	isMine(): boolean {
		if (this.loginService.esAutor() && this.user.idUser === this.curso.user.idUser)
			return true
		return false
	}

	private _translateText() {

        this.translateService.get('VALIDACIONES.TITLEREQUIRED').subscribe((res: string) => {
            this.validationMessages.title.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.DESCRIPTIONREQUIRED').subscribe((res: string) => {
            this.validationMessages.description.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.DESCRIPTIONMINLENGTH').subscribe((res: string) => {
            this.validationMessages.description.push({ type: 'minlength', message: res });
        });

        this.translateService.get('VALIDACIONES.SUBJECTREQUIRED').subscribe((res: string) => {
            this.validationMessages.subject.push({ type: 'required', message: res });
        });
        this.translateService.get('VALIDACIONES.LANG1REQUIRED').subscribe((res: string) => {
            this.validationMessages.lang1.push({ type: 'required', message: res });
        });
				this.translateService.get('VALIDACIONES.LANG1REQUIRED').subscribe((res: string) => {
					this.validationMessages.project_type.push({ type: 'required', message: res });
				});
        this.translateService.get('VALIDACIONES.LEVELREQUIRED').subscribe((res: string) => {
            this.validationMessages.level.push({ type: 'required', message: res });
        });
        this.translateService.get('VALIDACIONES.IMAGETREQUIRED').subscribe((res: string) => {
            this.validationMessages.image.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.COURSETITLEMINLENGTH').subscribe((res: string) => {
            this.validationMessages.title.push({ type: 'minlength', message: res });
        });
        this.translateService.get('VALIDACIONES.COURSETITLEMAXLENGTH').subscribe((res: string) => {
            this.validationMessages.title.push({ type: 'maxlength', message: res });
        });

        this.formCourse.markAllAsTouched()
	}


	createForm() {

		this.formCourse = this.fb.group({
			image: [{ value: '', disabled: false }],
			title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
			description: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(500)]],
			subject: ['', [Validators.required]],
			level: [''],
			lang1: ['', [Validators.required]],
			project_type: ['', [Validators.required]],
			published : [false]
		})
	}



	obtenerDatosMaestros() {
        this.idiomas = this.getDataService.appLanguages
        this.niveles = this.getDataService.appLevels
	}


	obtenerDatosCurso(id) {

		this.cargando = true;

		this.coursesService.getCourseById(id).subscribe(response => {
			this.curso = response.data.courses // Course detail
			if (this.isMine()) {
				this.courseTargets = response.data.coursesTarget // Course graphs
			}
			else {
				this.courseTargets = response.data.coursesTarget.filter((element: any) => {
					return element.target.published !== null;
				})
			}


			this.formCourse.patchValue({
				title: this.curso.courseTittle,
				description: this.curso.description,
				subject: this.curso.subject.idSubject,
				level: this.curso.courseSWLevel,
				lang1: this.curso.language.idLanguage,
				project_type: this.curso.project_type,
				image: this.curso.cpicture ? this.imagePipe.transform(this.curso.cpicture, 'cursos') : '',
				published : this.curso.published ? true  : false
			})

			if (!this.isMine())
				this.formCourse.disable()

			this.cargando = false;

			this.tutorialDisponible()
		});
	}

	/**
	 * Publish or unpublish course
	 * @param $ev Click event in the switch component
	 */
	publish($ev){
		$ev.preventDefault()
		$ev.stopImmediatePropagation()
		let currentValue:boolean = !this.formCourse.get('published').value
		let modalMessage:string = currentValue ? this.translateService.instant('EDITARCURSO.PUBLISHMSG') : this.translateService.instant('EDITARCURSO.UNPUBLISHMSG')
		let errorMessage:string = currentValue ? this.translateService.instant('GENERAL.KOPUBLISH') : this.translateService.instant('GENERAL.KOUNPUBLISH')
		let okMessage:string = currentValue ? this.translateService.instant('GENERAL.OKPUBLISH') : this.translateService.instant('GENERAL.OKUNPUBLISH')

		//Open modal message alert to confirm the selection

		const modalRef = this.modalService.open(ModalAceptarCancelarComponent,
			{
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30
			});


		modalRef.componentInstance.mensaje = modalMessage

		modalRef.result.then((result: boolean) => {
			if (result) {
				this.getDataService.setPublishType('course',Number(this.id),currentValue).subscribe(result => {
					if(result.data){
						this.toaster.success(okMessage)
						this.formCourse.get('published').setValue(currentValue) // Update form value
					}
					else
						this.toaster.error(this.translateService.instant('EDITARCURSO.KOPUBLISHCONTENT'))
				}, err => {
					this.toaster.error(errorMessage)
				})

			}
		});
	}

	// BORRAR CURSO
	borrarCurso() {
		if (!this.loginService.esAutor()) {
			return;
		}

		// Abro un modal preguntando si desea borrar el curso o no
		const modalRef = this.modalService.open(ModalAceptarCancelarComponent,
			{
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30
			});


		modalRef.componentInstance.mensaje = this.translateService.instant('EDITARCURSO.DESEABORRARCURSO');

		modalRef.result.then((result: boolean) => {
			// Si devuelve true lo borro y si devuelve false no hago nada

			if (result) {

				this.coursesService.deleteCourse(this.curso.idCourse)
					.subscribe((resp: any) => {
						// Cierro la modal del detalle del curso
						this.activeModal.close('Curso borrado');
						this.toaster.success(this.translateService.instant('EDITARCURSO.OKDELETE'))
					}, (error) => {
						this.toaster.error(this.translateService.instant('EDITARCURSO.KODELETE'))
					});
			}
		});
	}
	// FIN BORRAR CURSO

	closeModal(sendData?: any) {

		//Primero guardar la informacion del formulario si es valida
		if (this.curso !== undefined && this.formCourse.valid && this.changues() && this.isMine())
			this.savData();
		else
			this.activeModal.close(sendData);
	}
	changues() {
		const v = this.formCourse.value
		if( this.curso.courseTittle !== v.title ||  this.curso.description !== v.description
			||this.curso.subject.idSubject !== v.subject
			||this.curso.courseSWLevel !== v.level
			||this.curso.subject.idSubject !== v.subject
			|| this.curso.language.idLanguage !== v.lang1
			|| this.curso.project_type !== v.project_type
			|| this.changueImagen(this.curso.cpicture, v.image)
		   ) return true;


		return false;
	}
	changueImagen(cpicture: string, image: any): boolean {

		const imgPicUrl = `${environment.imagesCourses}${cpicture}`;
		if (imgPicUrl === image || (cpicture === null && image === '')) {
			return false;
		}
		return true;
	}

	/**
	 * Create/update Course
	 */

	savData():void {
        if(!this.formCourse.valid)
            return

        let image: File | string = ''
        this.saving = true
        const v = this.formCourse.value
        let request: CourseModel = new CourseModel(v.level, v.title, v.subject, v.description, v.lang1, null, v.project_type, this.user.idUser)
        if (this.id !== 'nuevo') {
            request.idCourse = this.curso.idCourse
            //Si el curso no tiene imagen
            if (this.curso.cpicture == '' || this.curso.cpicture == null) {
                if (typeof v.image === 'object')
                    image = v.image
            }
            else { //Si el curso tiene imagen
                if (typeof v.image == 'string' && v.image != '')
                    request.cpicture = this.curso.cpicture
                else if (typeof v.image == 'object') {
                    request.cpicture = this.curso.cpicture
                    image = v.image
                }
                else if (typeof v.image == 'string' && v.image == '') {
                    request.cpicture = null
                }
            }
        }
        else{
            image = v.image
        }

        request.published = v.published ? Date.now() : null

        this.coursesService.setCourse(request, image).pipe(finalize(() => this.saving = false)).subscribe((event: HttpEvent<any>) => {
            switch (event.type) {
                case HttpEventType.UploadProgress:
                    this.progress = Math.round(event.loaded / event.total * 100);
                    break;
                case HttpEventType.Response:
                    setTimeout(() => {
                        this.progress = 0
                        if(this.id !== 'nuevo')
                            this.toaster.success(this.translateService.instant('EDITARCURSO.OKSAVE'))
                        this.obtenerDatosCurso(event.body.data.idCourse)
                        this.id = event.body.data.idCourse.toString()
                        if (this.clickSidebar) {
                            this.clickSidebar = false;
                        }else {
                            this.activeModal.close(this.translateService.instant('EDITARCURSO.OKSAVE'))
                        }

                    }, 500);
            }
        }, error => {
            this.activeModal.close(this.translateService.instant('EDITARCURSO.CANCELSAVE'))
            this.toaster.info(this.translateService.instant('EDITARCURSO.CANCELSAVE'))
        })
	}
	// FIN CURSO

	// MAPAS
	verDetalleMapa(idMapa: number) {

		// Si soy estudiante le mando al mapa directamente
		// Se cambia a si no es autor, para permitir al padre
		if (!this.loginService.esAutor()) {
			// Y le mando al mapa seleccionado
			this.router.navigate([`/course/${this.curso.idCourse}/graph/${idMapa}`]);

			this.closeModal('Abrir mapa');

			return;
		}

		if (this.loginService.esAutor()) {
			// Si soy autor le llevo a la edicion de metadatos del mapa
			const modalRef = this.modalService.open(ModalEditarCursoMapaComponent, { scrollable: true, windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` });

			modalRef.componentInstance.id = idMapa;
			modalRef.componentInstance.curso = this.curso;

			modalRef.result.then((result) => {
				if (result && result === 'closeAll') {
						this.closeModal('Editar nodos mapa');
				} else if (result) {
					this.targetsService.getCourseTargetByIdCourse(this.curso.idCourse).subscribe(result => {
						this.courseTargets = result.data
						if(!this.tutorialCurso.ideografo)
							this.nextStep(3);
					});
				}
			}, (err) => { })
		}
	}

	nuevoMapa() {

		if (!this.loginService.esAutor()) { return; }

		const modalRef = this.modalService.open(ModalEditarCursoMapaComponent, { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W90 });

		modalRef.componentInstance.id = 'nuevo';
		modalRef.componentInstance.curso = this.curso;

		modalRef.result.then((result) => {
			if (result) {
				this.targetsService.getCourseTargetByIdCourse(this.curso.idCourse).subscribe(result => {
						this.nextStep(3);
						this.courseTargets = result.data;
				})
			}
		}, (reason) => {
		});
	}
	// FIN MAPAS

	getUserAvatar():string{
        return this.utils.getUserAvatar(this.user.pictureUser )
	}

    getImageBackground(image:string|null):string{
        let noImg = '../../assets/images/no-image.png'
        if(!image)
            return `url('${noImg}')`
        return `url(${this.imagePipe.transform(image, 'mapas')})`
    }

    saveChanges(event:any, isExclusive:boolean){

        let currentOrdinal: number = this.courseTargets[event.newIndex].ordinal
        let newOrdinal:number = event.newIndex + 1

        this.targetsService.updateGraphOrder(Number(this.id), currentOrdinal, newOrdinal, isExclusive).subscribe(result => {
            this.courseTargets = result.data.coursesTarget
        }, err => {
            this.toaster.error(this.translateService.instant('EDITARCURSO.KOMOVEGRAPH'))
        })
    }

}
