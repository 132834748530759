<div class="col-12 d-flex flex-column align-items-center mt-5">

	<!-- TEXTO -->
	<div class="d-flex justify-content-center">
		<div>
			<h6>{{ 'DARSEBAJA.MESSAGE' | translate }}</h6>
		</div>
	</div>

	<!-- BOTONES -->
	<div class="d-flex justify-content-center gap-3 mt-4">
		<div>
			<button nbButton class="btn-themecolor" size="small" (click)="aceptar()">{{ 'DARSEBAJA.OK' | translate }}</button>
		</div>
		<div>
			<button nbButton class="btn-red" size="small" (click)="cancelar()">{{ 'ACEPTARCANCELAR.CANCELAR' | translate }}</button>
		</div>
	</div>
</div>
