<form  [formGroup]="infoForm">
    <div class="row">
        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-10">
            <div class="d-flex flex-column">
                <label>{{ 'QUIZZES.TITULO' | translate }}</label>
                <input nbInput fullWidth fieldSize="small" class="form-control" type="text" formControlName="title" (blur)="onTextBlur($event)" />
            </div>
        </div>

        <!-- <div class="col-4"></div> -->

        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-2">
            <div class="d-flex flex-column">
                <!-- <label>Título</label> -->
                <input nbInput fullWidth fieldSize="small" class="form-control" type="time" formControlName="duration" (blur)="onTextBlur($event)" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="d-flex flex-column">
                <label>{{ 'QUIZZES.INSTRUCCIONES' | translate }}</label>
                <input nbInput fullWidth fieldSize="small" class="form-control" type="text" formControlName="instructions" (blur)="onTextBlur($event)" />
            </div>
        </div>
    </div>
</form>


