<!-- <div class="sticky-top sticky-top-header"> -->
    <!-- HEADER SUPERIOR (AZUL) -->
    <div class="modal-header tittle" align="center">
        <div class="col-sm-1">
            <mat-icon class="icon-tittle icon-size-small pointer" (click)="save()">save</mat-icon>
        </div>
        <div class="col-sm-7 align-self-center text-center">
            <span *ngIf="node && node.text"
                class="textoFontSize08 font-weight-bold span-text-center">{{ 'NODOS.GRAFO' | translate }}:
                {{node.text}}</span>
        </div>
        <div class="col-sm-2 align-self-center text-center">
            <button type="button" class="close pointer" [ngStyle]="{'color':'rgb(29, 73, 153)'}" data-dismiss="modal"
                (click)="closeModal('')">
                <span class="textoFontSize18 font-weight-bold span-text-center" aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <!-- HEADER BOTONERA (GRIS) -->
    <div class="options" align="center">
                        <!-- *ngIf="loginService.esEstudiante()"  -->
                        <div class="row pull-right">
                            <div class="icons">
                                <mat-icon class="icon-head icon-size-medium pointer" color="primary"
                                    data-toggle="tooltip" data-placement="top" ngbTooltip="{{'QUIZZES.HELP' | translate }}"
                                    svgIcon="askforhelp" style="padding: 1px;"></mat-icon>
                            </div>
                            <div class="icons">
                                <!-- A.G. 23/11/2020 -->
                                <button mat-icon-button (click)="openSelectPattern()">
                                    <mat-icon class="icon-head icon-size-medium pointer" ngbTooltip="{{'QUIZZES.TEMPLATES' | translate }}" color="primary" svgIcon="template" style="padding: 1px;"></mat-icon>
                                </button>
                            </div>
                            <!--div class="col-1 col-sm-1 ml-1" (click)="closeModal('')">
                                <mat-icon class="icon-head icon-size-medium pointer">reduce_capacity</mat-icon>
                            </div>
                            <div class="col-1 col-sm-1 ml-1">
                                <mat-icon class="icon-head icon-size-medium pointer">publish</mat-icon>
                            </div-->
                            <div class="icons">
                                <button mat-icon-button [matMenuTriggerFor]="menuRedesSociales">
                                    <mat-icon ngbTooltip="{{'QUIZZES.TOSHARE' | translate }}" class="icon-head icon-size-medium pointer">share
                                    </mat-icon>
                                </button>
                                <mat-menu #menuRedesSociales="matMenu">
                                    <div class="row mx-auto align-items-center justify-content-center">
                                        <div class="col-lg-3" *ngFor="let btn of buttons ;let i = index">
                                            <button *ngIf="i!=4" class="button-redes mt-2 pointer"
                                                [ngStyle]="{'background-color': btn.color}"
                                                (click)="enviarRedesSociales(btn.id)">
                                                <a class="enlace_desactivado" target="_blank"><i class="{{btn.icon}}"
                                                        style="font-size:24px; color: white"></i></a>
                                            </button>
                                            <button *ngIf="i==4" class="mt-2" [ngStyle]="{'color': btn.color}">
                                                <mat-icon class="button-salware pointer" svgIcon={{btn.icon}}
                                                    (click)="seleccionarDestinatario()"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </mat-menu>
                            </div>
                            <div class="icons">
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon ngbTooltip="{{'QUIZZES.MESSAGE' | translate }}" class="icon-head icon-size-medium pointer">sms</mat-icon>
                                </button>
                                <mat-menu class="w-100" #menu="matMenu">
                                    <button mat-menu-item>
                                        <mat-icon>comment</mat-icon>
                                        <span>{{'QUIZZES.COMENTARIO' | translate }}</span>
                                    </button>
                                    <button mat-menu-item>
                                        <mat-icon>error_outline</mat-icon>
                                        <span>{{'QUIZZES.ERROR' | translate }}</span>
                                    </button>
                                    <button mat-menu-item>
                                        <mat-icon>flag</mat-icon>
                                        <span>{{'QUIZZES.REPORTE' | translate }}</span>
                                    </button>
                                </mat-menu>
                            </div>
                            <div class="icons">
                                <mat-icon class="icon-head icon-size-medium pointer" ngbTooltip="{{'QUIZZES.PRINT' | translate }}" (click)="imprimir()">print
                                </mat-icon>
                            </div>
                            <div class="icons dropdown" id="drop">
                                <a class="btn dropdown-toggle p-0" href="#" role="button" id="dropdownMenuLink"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <mat-icon class="icon-head icon-size-medium pointer" ngbTooltip="{{'QUIZZES.INFORMATION' | translate }}" svgIcon="information"></mat-icon>
                                </a>
                                <div id="dropMenu" class="dropdown-menu dropdown-menu-right p-0 w-25 h-auto"
                                    aria-labelledby="dropdownMenuLink">
                                    <app-quiz-form [idCurso]="idCurso" [idMapa]="idMapa" (newQuiz)="receiveNewNodeData($event)"
                                        (changed)="setPendingChanges($event)" #quizForm>
                                    </app-quiz-form>
                                </div>
                            </div>
                            <!--div class="col-1 col-sm-1 ml-1"-->
                                <!--mat-icon class="icon-head pointer" ngbPopover={{informacionQuiz}}
                                        triggers="mouseenter:mouseleave" popoverTitle="{{'QUIZZES.INFORMACION'|translate}}">format_italic</mat-icon-->
                                <!--mat-icon class="icon-head icon-size-medium pointer">format_italic</mat-icon-->
                            <!--/div-->
                        </div>
            <!--/div-->
            <!--/div-->
            <!-- TRANSPARENTE (DROPDOWN MENU)-->
            <!--div class="col-lg-6 col-md-6 col-sm-6"-->
            <!-- <div style="position: absolute; width: 100%; background: none;">-->
                <!-- A.G. -->
                <!-- <div class="dropdown" id="drop">
                    <a class="btn dropdown-toggle p-0" href="#" role="button" id="dropdownMenuLink"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <mat-icon class="icon-head icon-size-medium pointer">help_outline</mat-icon>
                    </a>
                    <div id="dropMenu" class="dropdown-menu dropdown-menu-right p-0 w-25 h-auto"
                        aria-labelledby="dropdownMenuLink">
                        <app-quiz-form [idCurso]="idCurso" [idMapa]="idMapa" (newNode)="receiveNewNodeData($event)"
                            (changed)="setPendingChanges($event)" #quizForm>
                        </app-quiz-form>
                    </div>
                </div>
            </div> -->
            <!--/div-->
            <!-- Fin A.G. -->
    </div>
<!-- </div> -->
