import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

	getInformeCenter(idParametro: number, idConsulta: number) {
		const url = `report/informeCenter/${idParametro}/${idConsulta}`;
		return this.http.get<any>(url);
	}

	getInformeCenter2(idParametro: number, idConsulta: number) {
		const url = `report/xxxinformeCenter/${idParametro}/${idConsulta}`;
		return this.http.get<any>(url);
	}

	getInformeCenterMini(idParametro: number, idConsulta: number) {
		const url = `report/informeCenterMini/${idParametro}/${idConsulta}`;
		return this.http.get<any>(url);
	}

	getInformeCenter2Mini(idParametro: number, idConsulta: number) {
		const url = `report/xxxinformeCenterMini/${idParametro}/${idConsulta}`;
		return this.http.get<any>(url);
	}

	getGrupos() {
		const url = `report/xxxgetGrupos`;
		return this.http.get<any>(url);
	}
	getAsignaturas() {
		const url = `report/xxxgetAsignaturas`;
		return this.http.get<any>(url);
	}
	getNiveles() {
		const url = `report/xxxgetNiveles`;
		return this.http.get<any>(url);
	}

	//***********************************************
	//**********     INFORMES DE ADMIN     **********

	//ENDPOINT QUE TRAE LOS DATOS INICIALES DEL PANEL DE CONTROL DEL ROL ADMIN
	datosInicialesPanelControlAdmin() {
		const url = `report/datosInicialesPanelControlAdmin`;
		return this.http.get<any>(url);
	}

	getInformeAdmin(role: number, idConsulta: number) {
		const url = `report/informeAdmin/${role}/${idConsulta}`;
		return this.http.get<any>(url);
	}

}
