import { Subscription } from 'rxjs';
import { CenterService } from './../../../core/services/center/center.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ImagenPipe } from '../../pipes/imagen.pipe';

@Component({
  selector: 'app-center-icon',
  templateUrl: './center-icon.component.html',
  styleUrls: ['./center-icon.component.scss'],
  providers: [ImagenPipe]
})
export class CenterIconComponent implements OnInit, OnDestroy {

    private subs = new Subscription
    bgImage:string

    constructor(private centerService:CenterService,  private imagePipe: ImagenPipe) { }

    ngOnInit() {
        this.getImageCenter()
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe()
    }

    private getImageCenter(): void{
        if(this.centerService.currentConfig){
            this.bgImage = `url(${this.imagePipe.transform(this.centerService.currentConfig.imgLogo, 'logoCenter')})`
        }
        else{
            this.subs = this.centerService.centerConfig.subscribe(data => {
                this.bgImage = `url(${this.imagePipe.transform(data.imgLogo, 'logoCenter')})`
            })
        }
    }

}
