import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-surveys-question-answer',
  templateUrl: './surveys-question-answer.component.html',
  styleUrls: ['./surveys-question-answer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SurveysQuestionAnswerComponent implements OnInit {
  @Input() question: any = null;
  @Output() responseSelected = new EventEmitter<any>();
	likertOptions: number[] = [];

  selectedOptionLikert: number;
  selectedOptionSingle: number;
  openResponse: string = '';

  private openResponseSubject = new Subject<string>();

  constructor(
    public translateService: TranslateService,
  ){}

  ngOnInit(): void {
		this.likertOptions = Array.from({ length: this.question.likertOptions }, (_, i) => i + 1);
    // Configurar el Subject para emitir el valor tras un retardo
    this.openResponseSubject
      .pipe(
        debounceTime(1000),  // Retrasa la emisión 500ms después de que el usuario ha dejado de escribir
        distinctUntilChanged()  // Solo emite si el valor cambia
      )
      .subscribe((response) => {
        this.emitSelectedResponse();  // Llama al método para emitir la respuesta
      });
  }

  // Método que emite el valor seleccionado o ingresado
  emitSelectedResponse() {
		let response;
    if (this.question.type === 1) {
      // Likert Scale (Seleccion única)
      response = {
				idSurveyQuestion: this.question.idSurveyQuestion,
				answer: this.selectedOptionLikert.toString(),
				ok: true,
			};
    } else if (this.question.type === 2) {
      // Selección única
      response = {
				idSurveyQuestion: this.question.idSurveyQuestion,
				answer: this.selectedOptionSingle.toString(),
				ok: true,
			};
    } else if (this.question.type === 3) {
      // Selección múltiple
      const selectedOptions = this.getSelectedOptions();
      response = {
				idSurveyQuestion: this.question.idSurveyQuestion,
				answer: selectedOptions,
				ok: true,
			};
    } else if (this.question.type === 4) {
      // Respuesta abierta
      response = {
				idSurveyQuestion: this.question.idSurveyQuestion,
				answer: this.openResponse,
				ok: true,
			};
    }
    // Emitir el objeto de respuesta al componente padre
    this.responseSelected.emit(response);
  }

  // Filtra y devuelve solo las opciones seleccionadas (para tipo 3)
	getSelectedOptions() {
		return this.question.options
			.filter((option) => option.selected)
			.map((option) => option.idSurveyQuestionOption)
			.join(',');  // Convierte el array en un string separado por comas
	}

  // Método que se llama cuando cambia el valor del campo de texto
  onOpenResponseChange(newValue: string) {
    this.openResponse = newValue;
    this.openResponseSubject.next(this.openResponse);
  }

}
