<form
	[formGroup]="optionForm"
	*ngIf="QuizTypes.TEXT && quiz.idMultiplexQuiz === 0"
>
	<ng-container formArrayName="options">
		<div class="row justify-content-center">
			<ng-container [ngSwitch]="quizType">
				<!-- QUIZZ DE INTRODUCIR DATOS -->
				<div class="col-sm-12 col-md-10" [formGroupName]="1" *ngSwitchCase="QuizTypes.TEXT">
					<form [formGroup]="optionFormTexto">
						<textarea
							(blur)="readText()"
							autocomplete="off"
							nbInput
							fullWidth
							placeholder="{{ 'QUIZZES.RESPUESTA' | translate }} {{ 1 }}"
							formControlName="answer"
							[disabled]="answered"
							maxlength="250"
						></textarea>

						<!-- <mat-form-field appearance="standard" class="full-width">
                            <mat-label>{{'QUIZZES.RESPUESTA' | translate}} {{1}}</mat-label>
                            <input (blur)="readText()" autocomplete="off"  matInput type="text" formControlName="answer" [readonly]="this.answered" />
                        </mat-form-field> -->
					</form>
				</div>

				<div class="col-sm-12 col-md-10" [formGroupName]="1" *ngSwitchCase="QuizTypes.IA">
					<form [formGroup]="optionFormTexto">
						<textarea
							(blur)="readText()"
							autocomplete="off"
							nbInput
							fullWidth
							placeholder="{{ 'QUIZZES.RESPUESTA' | translate }} {{ 1 }}"
							formControlName="answer"
							[disabled]="answered"
							maxlength="250"
						></textarea>

						<!-- <mat-form-field appearance="standard" class="full-width">
                            <mat-label>{{'QUIZZES.RESPUESTA' | translate}} {{1}}</mat-label>
                            <input (blur)="readText()" autocomplete="off"  matInput type="text" formControlName="answer" [readonly]="this.answered" />
                        </mat-form-field> -->
					</form>
				</div>

				<div class="row center-aling w-100" *ngSwitchCase="QuizTypes.TEXT">
					<ng-container
						*ngFor="let option of optionsArray.controls; let i = index"
					>
						<app-loading [isVisible]="isLoading"></app-loading>
						<!-- Inputs Ocultos -->
						<input
							*ngIf="option.value.type === O_AUDIOS"
							type="file"
							id="{{ i }}"
							accept="audio/mp3"
							(change)="onFileSelected(i, $event, O_AUDIOS)"
							hidden
						/>
						<input
							*ngIf="option.value.type === O_VIDEOS"
							type="file"
							id="{{ i }}"
							accept="video/mp4"
							(change)="onFileSelected(i, $event, O_VIDEOS)"
							hidden
						/>
						<input
							*ngIf="option.value.type === O_PICTURES"
							type="file"
							id="{{ i }}"
							accept="image/*"
							(change)="onFileSelected(i, $event, O_PICTURES)"
							hidden
						/>
						<input
							*ngIf="option.value.type === O_PDFS"
							type="file"
							id="{{ i }}"
							accept="application/pdf,application/vnd.ms-excel"
							(change)="onFileSelected(i, $event, O_PDFS)"
							hidden
						/>

						<ng-container *ngIf="arrayFileView[i].view" class="center-aling">
							<div
								class="col-xs-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 container-file"
							>
								<div class="row">
									<div
										class="col-12 center-aling"
										style="padding-left: 0px; padding-right: 0px"
									>
										<img
											*ngIf="option.value.type === O_PICTURES"
											#imgElement
											class="file-container-view"
											id="{{ i }}"
											[src]="arrayFileView[i].url | safe"
										/>
										<audio
											*ngIf="
												arrayFileView[i].view && option.value.type === O_AUDIOS
											"
											class="file-container-view"
											id="{{ i }}"
											[src]="arrayFileView[i].url | safe"
											controls
										></audio>
										<video
											*ngIf="
												arrayFileView[i].view && option.value.type === O_VIDEOS
											"
											class="file-container-view"
											[src]="arrayFileView[i].url | safe"
											controls
										></video>
										<iframe
											*ngIf="
												arrayFileView[i].view && option.value.type === O_PDFS
											"
											#pdfElement
											class="file-container-view"
											style="max-height: 70% !important"
											[src]="arrayFileView[i].url | safe"
											allowfullscreen
										></iframe>
									</div>
									<div class="col-12 center-aling" style="padding-top: 3px">
										<span *ngIf="option.value.type === O_VIDEOS">{{
											"QUIZZES.VIDEO" | translate
										}}</span>
										<span *ngIf="option.value.type === O_PICTURES">{{
											"QUIZZES.IMAGEN" | translate
										}}</span>
										<span *ngIf="option.value.type === O_AUDIOS">{{
											"QUIZZES.AUDIO" | translate
										}}</span>
										<span *ngIf="option.value.type === O_PDFS">{{
											"QUIZZES.DOCUMENTO" | translate
										}}</span>
										&nbsp;
										<mat-icon
											class="edit pointer"
											ngbTooltip="{{ 'GLOBALS.EDIT' | translate }}"
											placement="right"
											(click)="onFileSelectedReplace(i, option.value)"
										>
											edit
										</mat-icon>
										<mat-icon
											*ngIf="
												arrayFileView[i].view && option.value.type === O_AUDIOS
											"
											ngbTooltip="{{ 'QUIZZES.RECORDNEWAUDIO' | translate }}"
											class="edit pointer"
											(click)="grabarAudio(i, option.value)"
											>mic</mat-icon
										>
										<mat-icon
											*ngIf="
												arrayFileView[i].view && option.value.type === O_VIDEOS
											"
											ngbTooltip="{{ 'QUIZZES.RECORDNEWVIDEO' | translate }}"
											class="edit pointer"
											(click)="grabarVideo(i, option.value)"
											>camera</mat-icon
										>
										<mat-icon
											*ngIf="
												arrayFileView[i].view &&
												option.value.type === O_PICTURES
											"
											ngbTooltip="{{ 'QUIZZES.NEWPHOTO' | translate }}"
											class="edit pointer"
											(click)="tomarFoto(i, option.value)"
											>camera</mat-icon
										>
									</div>
								</div>
							</div>
						</ng-container>

						<ng-container *ngIf="!arrayFileView[i].view" class="center-aling">
							<div class="row mt-4">
								<div class="col-12">
									<div class="row">
										<ng-container *ngIf="option.value.type === O_VIDEOS">
											<div class="col-4" [ngClass]="{ disabled: hideResponse }">
												<div class="row">
													<div class="col-12 center-aling">
														<div class="row">
															<div class="col-12">
																<mat-icon
																	class="icon-mat-type pointer center-aling"
																	id="mat-icon-{{ i }}"
																	(click)="openFile(i, option.value)"
																	color="primary"
																	>videocam</mat-icon
																>
															</div>
														</div>
													</div>

													<div class="col-12 mt-2 center-aling">
														<p class="card-text center-text">
															<span>{{ "QUIZZES.VIDEO" | translate }}</span>
														</p>
													</div>
												</div>
											</div>
											<div class="col-4" [ngClass]="{ disabled: hideResponse }">
												<div class="row">
													<div class="col-12 center-aling">
														<div class="row">
															<div class="col-12">
																<mat-icon
																	class="icon-mat-type pointer"
																	id="mat-icon-{{ i }}"
																	(click)="grabarVideo(i, option.value)"
																	color="primary"
																	>camera</mat-icon
																>
															</div>
														</div>
													</div>

													<div class="col-12 mt-2 center-aling">
														<p class="card-text center-text">
															<span>{{ "QUIZZES.GRABAR" | translate }}</span>
														</p>
													</div>
												</div>
											</div>
										</ng-container>

										<ng-container *ngIf="option.value.type === O_PICTURES">
											<div class="col-4" [ngClass]="{ disabled: hideResponse }">
												<div class="row">
													<div class="col-12 center-aling">
														<div class="row">
															<div class="col-12">
																<mat-icon
																	class="icon-mat-type pointer center-aling"
																	id="mat-icon-{{ i }}"
																	(click)="openFile(i, option.value)"
																	color="primary"
																	>image</mat-icon
																>
															</div>
														</div>
													</div>

													<div class="col-12 mt-2 center-aling">
														<p class="card-text center-text">
															<span>{{ "QUIZZES.IMAGEN" | translate }}</span>
														</p>
													</div>
												</div>
											</div>
											<div class="col-4" [ngClass]="{ disabled: hideResponse }">
												<div class="row">
													<div class="col-12 center-aling">
														<div class="row">
															<div class="col-12">
																<mat-icon
																	class="icon-mat-type pointer"
																	id="mat-icon-{{ i }}"
																	(click)="tomarFoto(i, option.value)"
																	color="primary"
																	>camera</mat-icon
																>
															</div>
														</div>
													</div>

													<div class="col-12 mt-2 center-aling">
														<p class="card-text center-text">
															<span>{{ "QUIZZES.FOTO" | translate }}</span>
														</p>
													</div>
												</div>
											</div>
										</ng-container>

										<ng-container *ngIf="option.value.type === O_AUDIOS">
											<div
												*ngIf="!viewAudio"
												class="col-4"
												[ngClass]="{ disabled: hideResponse }"
											>
												<div class="row">
													<div class="col-12 center-aling">
														<div class="row">
															<div class="col-12">
																<mat-icon
																	class="icon-mat-type pointer center-aling"
																	id="mat-icon-{{ i }}"
																	(click)="openFile(i, option.value)"
																	color="primary"
																	>volume_up</mat-icon
																>
															</div>
														</div>
													</div>

													<div class="col-12 mt-2 center-aling">
														<p class="card-text center-text">
															<span>{{ "QUIZZES.AUDIO" | translate }}</span>
														</p>
													</div>
												</div>
											</div>
											<div
												*ngIf="!viewAudio"
												class="col-4"
												[ngClass]="{ disabled: hideResponse }"
											>
												<div class="row m-0 p-0">
													<div class="col-12 center-aling">
														<div class="row">
															<div class="col-12">
																<mat-icon
																	class="icon-mat-type pointer"
																	id="mat-icon-{{ i }}"
																	(click)="_grabarAudio(i, option.value)"
																	color="primary"
																	>mic</mat-icon
																>
															</div>
														</div>
													</div>

													<div class="col-12 mt-2 center-aling">
														<p class="card-text center-text">
															<span>{{ "QUIZZES.GRABAR" | translate }}</span>
														</p>
													</div>
												</div>
											</div>
											<div *ngIf="viewAudio" class="col">
												<audio-component
													[viewElement]="viewAudio"
													(emitAudio)="emitAudioEvent($event)"
													(close)="closeElement($event)"
												></audio-component>
											</div>
										</ng-container>

										<div
											*ngIf="option.value.type === O_PDFS"
											class="col-sm-12 col-md-4 col-lg-3 col-xl-2"
										>
											<div class="row" [ngClass]="{ disabled: hideResponse }">
												<div class="col-12 center-aling">
													<mat-icon
														class="icon-mat-type pointer"
														(click)="openFile(i, option.value)"
														color="primary"
														>picture_as_pdf</mat-icon
													>
												</div>
												<div class="col-12 mt-2 center-aling">
													<p class="card-text center-text">
														<span>{{ "QUIZZES.DOCUMENTO" | translate }}</span>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</ng-container>
</form>

<form class="quiz-options" [formGroup]="optionForm">
	<ng-container formArrayName="options">
		<div
			class="row justify-content-center"
			*ngFor="let option of optionsArray.controls; let i = index"
		>
			<ng-container [ngSwitch]="quizType">
				<!-- QUIZZES DE SELECCIÓN ÚNICA -->
				<div
					class="col-sm-12 col-md-10 flex align-items-start mb-4 justify-content-center"
					[formGroupName]="i"
					*ngSwitchCase="QuizTypes.SINGLE"
				>
					<div
						class="count mr-3 align-self-center"
						*ngIf="answered && !hideCounter && !loadingResponses"
					>
						{{ option.get("totalResponses").value }}
					</div>
					<nb-radio-group class="w-100 option-bordered"  [formControl]="correctOptionControl"
						[ngClass]="{
							'bg-correct': answered == true && optionsArrayIsCorrect[i].value,
							'bg-incorrect': answered == true && !optionsArrayIsCorrect[i].value,
							'radio-selected': correctOptionControl.value === option.get('id').value
						}"
						[class.bg-correct]="answered && optionsArrayIsCorrect[i].value"
						(click)="clickedOptionDiv(correctOptionControl,option.get('id'),option.get('id').value)"
					>
						<nb-radio
							class="mr-2 pl-2"
							[disabled]="answered || hideResponse"
							[value]="option.get('id').value"
							[checked]="option.get('id').touched"
						>
							<ng-container [ngSwitch]="optionsArrayType[i].value">
								<div *ngSwitchCase="elementTypes.O_TEXTS">
									<p class="option">
										{{ optionsArrayData[i].value }}
									</p>
								</div>

								<div
									class="w-100 h-100 p-2 flex justify-content-between align-items-end"
									*ngSwitchCase="elementTypes.O_PICTURES"
								>
									<img
										class="mx-auto img-fluid"
										[style.width]="
											optionsArrayWidth[i].value
												? optionsArrayWidth[i].value + 'px'
												: 'fit-content'
										"
										[style.height]="
											optionsArrayHeight[i].value
												? optionsArrayHeight[i].value + 'px'
												: '100%'
										"
										[src]="
											optionsArrayData[i].value | quizElements : 'image' | safe
										"
										*ngIf="optionsArrayData[i].value"
									/>
								</div>

								<div
									class="w-100 h-100 p-2 flex justify-content-between align-items-end"
									*ngSwitchCase="elementTypes.O_VIDEOS"
								>
									<video
										class="w-100 mw-100"
										[style.width]="
											optionsArrayWidth[i].value
												? optionsArrayWidth[i].value + 'px'
												: 'fit-content'
										"
										[style.height]="
											optionsArrayHeight[i].value
												? optionsArrayHeight[i].value + 'px'
												: '100%'
										"
										controls
										[src]="
											optionsArrayData[i].value | quizElements : 'video' | safe
										"
										*ngIf="optionsArrayData[i].value"
									></video>
								</div>

								<div
									class="w-100 h-100 p-2 flex justify-content-between align-items-end"
									*ngSwitchCase="elementTypes.O_AUDIOS"
								>
									<audio
										class="w-100 d-block"
										controls
										[src]="
											optionsArrayData[i].value | quizElements : 'audio' | safe
										"
										*ngIf="optionsArrayData[i].value"
									></audio>
								</div>

								<div
									class="w-100 h-100 p-2 flex justify-content-between align-items-end"
									*ngSwitchCase="elementTypes.O_PDFS"
								>
									<iframe
										#pdfElement
										class="w-100 h-100"
										[style.width]="
											optionsArrayWidth[i].value
												? optionsArrayWidth[i].value + 'px'
												: 'fit-content'
										"
										[style.height]="
											optionsArrayHeight[i].value
												? optionsArrayHeight[i].value + 'px'
												: '100%'
										"
										[src]="
											optionsArrayData[i].value | quizElements : 'pdf' | safe
										"
										allowfullscreen
										*ngIf="optionsArrayData[i].value"
										style="min-height: 920px"
									></iframe>
								</div>
							</ng-container>
						</nb-radio>
					</nb-radio-group>
				</div>

				<!-- QUIZZES MULTIPLES -->
				<div
					class="col-sm-12 col-md-10 d-flex align-items-center"
					[formGroupName]="i"
					*ngSwitchCase="QuizTypes.MULTIPLE"
				>
					<nb-checkbox
						status="primary"
						class="mr-2 w-100 largerCheckbox"
						[disabled]="answered"
						formControlName="checked"
						[ngClass]="{
							'bg-correct': answered && optionsArrayIsCorrect[i].value,
							'bg-incorrect': answered && !optionsArrayIsCorrect[i].value
						}"
					>
						<ng-container [ngSwitch]="optionsArrayType[i].value">
							<div *ngSwitchCase="elementTypes.O_TEXTS">
								<p class="option">
									{{ optionsArrayData[i].value }}
								</p>
							</div>

							<div
								class="w-100 h-100 p-2 flex justify-content-between align-items-end"
								*ngSwitchCase="elementTypes.O_PICTURES"
							>
								<img
									class="mx-auto img-fluid"
									[style.width]="
										optionsArrayWidth[i].value
											? optionsArrayWidth[i].value + 'px'
											: 'fit-content'
									"
									[style.height]="
										optionsArrayHeight[i].value
											? optionsArrayHeight[i].value + 'px'
											: '100%'
									"
									[src]="
										optionsArrayData[i].value | quizElements : 'image' | safe
									"
									*ngIf="optionsArrayData[i].value"
								/>
							</div>

							<div
								class="w-100 h-100 p-2 flex justify-content-between align-items-end"
								*ngSwitchCase="elementTypes.O_VIDEOS"
							>
								<video
									class="w-100 mw-100"
									[style.width]="
										optionsArrayWidth[i].value
											? optionsArrayWidth[i].value + 'px'
											: 'fit-content'
									"
									[style.height]="
										optionsArrayHeight[i].value
											? optionsArrayHeight[i].value + 'px'
											: '100%'
									"
									controls
									[src]="
										optionsArrayData[i].value | quizElements : 'video' | safe
									"
									*ngIf="optionsArrayData[i].value"
								></video>
							</div>

							<div
								class="w-100 h-100 p-2 flex justify-content-between align-items-end"
								*ngSwitchCase="elementTypes.O_AUDIOS"
							>
								<audio
									class="w-100 d-block"
									controls
									[src]="
										optionsArrayData[i].value | quizElements : 'audio' | safe
									"
									*ngIf="optionsArrayData[i].value"
								></audio>
							</div>

							<div
								class="w-100 h-100 p-2 flex justify-content-between align-items-end"
								*ngSwitchCase="elementTypes.O_PDFS"
							>
								<iframe
									#pdfElement
									class="w-100 h-100"
									[style.width]="
										optionsArrayWidth[i].value
											? optionsArrayWidth[i].value + 'px'
											: 'fit-content'
									"
									[style.height]="
										optionsArrayHeight[i].value
											? optionsArrayHeight[i].value + 'px'
											: '100%'
									"
									[src]="
										optionsArrayData[i].value | quizElements : 'pdf' | safe
									"
									allowfullscreen
									*ngIf="optionsArrayData[i].value"
									style="min-height: 920px"
								></iframe>
							</div>
						</ng-container>
					</nb-checkbox>
				</div>
			</ng-container>
		</div>
	</ng-container>
</form>
