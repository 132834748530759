import { DemoValidationGuard } from 'src/app/core/guards/demo-validation.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { AuthGuard, ProfileValidationGuard } from '.';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    AuthGuard,
    ProfileValidationGuard,
    DemoValidationGuard
  ]
})
export class GuardsModule { }
