import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/core/services/login';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';
import { PadsUtils } from 'src/app/core/utils/pads.utils';

@Component({
	selector: 'app-recordar-quiz-pad-buttons',
	templateUrl: './quiz-pad-buttons.component.html',
	styleUrls: ['./quiz-pad-buttons.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class RecordarQuizPadButtonsComponent implements OnInit {
	@Input() isShow: boolean
	@Input() isShowLabels:boolean
	@Output() action = new EventEmitter<string>()
    @Output() onHoverElement:EventEmitter<boolean> = new EventEmitter<boolean>();


	_topButton: string = ''
	_leftButton: string = ''
	_rightButton: string = 'check_graph'
	viewCopilotPadLeft: boolean;
	courseId: number;
	graphId: number;


	constructor(private activatedRoute: ActivatedRoute, private toolsService: SigmaToolbarsService, private quizService: QuizzesService, private loginService: LoginService, private padsUtils:PadsUtils) { }

	ngOnInit() {}

	setKnowledge(color: string) {
		this._topButton = color
		this.padsUtils.vibratePad()
	}

	selectAtomatic() {
			this.quizService.setAutomatic(true);
			this._rightButton = 'automatic';
			this.padsUtils.vibratePad()
	}

	selectManual() {
			this.quizService.setAutomatic(false);
			this._rightButton = 'manual'
	}

	emitshowModalCourse() {
			this.toolsService.changeShowModalCourses('gridList');
			this.padsUtils.vibratePad()
	}
	clickAction(value:string){
		this.action.emit(value)
	}
    onEnterPad(value){
        this.onHoverElement.emit(value)
    }

}
