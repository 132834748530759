export class PadModel {
    showPadsGraph: boolean;
    showPadsNode:  boolean;
    showPadsQuiz:  boolean;

    constructor(showPadsGraph: boolean, showPadsNode: boolean, showPadsQuiz:boolean) {
        this.showPadsGraph = showPadsGraph;
        this.showPadsNode = showPadsNode;
        this.showPadsQuiz = showPadsQuiz;
    }
}

export class LabelModel {
    showLabelsGraph: boolean;
    showLabelsNode:  boolean;
    showLabelsQuiz:  boolean;

    constructor(showLabelsGraph: boolean, showLabelsNode: boolean, showLabelsQuiz:boolean) {
        this.showLabelsGraph = showLabelsGraph;
        this.showLabelsNode = showLabelsNode;
        this.showLabelsQuiz = showLabelsQuiz;
    }
}
