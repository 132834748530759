<div *ngFor="let template of templates">
    <div class="col-lg-4 col-md-4 col-sm-4 col-12 p-2" style="left:18%">
        <input type="text" class="form-control fondoBlanco" id="nomPlantilla" value="{{template.templateTittle}}"
            placeholder="{{ 'PATTERNS.TITTLETEMPLATE' | translate }}" [disabled]="true">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4 col-12 p-2" style="left:18%">
        <textarea class="form-control fondoBlanco" id="instructions" rows="3"
            placeholder="{{ 'PATTERNS.MESSAGE' | translate }}" [disabled]="true">{{template.quizInstructions}}
        </textarea>
    </div>
    <div class="col-lg-11 col-md-11 col-sm-11 col-12">
        <div style="position: relative" class="p-0 mt-2">
            <div *ngIf="template.writable==0" class="container">
                <div *ngFor="let element of template.elementsQP" class="row">
                    <div id="{{element.elementType}}" style="position: absolute; z-index: 10;"
                        [style.top.px]="element.yposition" [style.left.px]="element.xposition"
                        [style.width.px]="element.xsize" [style.height.px]="element.ysize"
                        class="element-box {{element.style}}" (click)="reproducirRecurso(element)"
                        [ngStyle]="{'background-image': 'url(' + getImage(element) + ')','background-size' : 'cover','opacity': 1}">
                        <mat-icon *ngIf="element.elementType == 0" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.VIDEO' | translate }}">{{element.icon}}</mat-icon>
                        <mat-icon *ngIf="element.elementType == 1" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.IMAGE' | translate }}">{{element.icon}}</mat-icon>
                        <mat-icon *ngIf="element.elementType == 2" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.AUDIO' | translate }}">{{element.icon}}</mat-icon>
                        <mat-icon *ngIf="element.elementType == 3" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.PDF' | translate }}">{{element.icon}}</mat-icon>
                        <mat-icon *ngIf="element.elementType == 4" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.TEXT' | translate }}" id="more" data-toggle="modal"
                            data-target="#descModal" (click)="getDescription(element)">{{element.icon}}</mat-icon>
                        <mat-icon *ngIf="element.elementType == 5" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.VIDEO' | translate }}">{{element.icon}}</mat-icon>
                        <mat-icon *ngIf="element.elementType == 6" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.IMAGE' | translate }}">{{element.icon}}</mat-icon>
                        <mat-icon *ngIf="element.elementType == 7" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.AUDIO' | translate }}">{{element.icon}}</mat-icon>
                        <mat-icon *ngIf="element.elementType == 8" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.PDF' | translate }}">{{element.icon}}</mat-icon>
                        <mat-icon *ngIf="element.elementType == 9" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.TEXT' | translate }}" id="more" data-toggle="modal"
                            data-target="#descModal" (click)="getDescription(element)">{{element.icon}}</mat-icon>
                    </div>
                    <div *ngIf="element.elementType==5 || element.elementType==6 || element.elementType==7 || element.elementType==8 ||
                        element.elementType==9" style="position: absolute" [style.top.px]="element.yposition"
                        [style.left.px]="element.xposition + element.xsize">
                        <mat-checkbox [disabled]="isRevealed" (change)="checkResponse()"></mat-checkbox>
                    </div>
                </div>
            </div>
            <div *ngIf="template.writable==1" class="container">
                <div *ngFor="let element of template.elementsQP" class="row">
                    <div *ngIf="0<=element.elementType  && element.elementType<=4" id="{{element.elementType}}"
                        style="position: absolute; z-index: 10;" [style.top.px]="element.yposition"
                        [style.left.px]="element.xposition" [style.width.px]="element.xsize"
                        [style.height.px]="element.ysize" class="element-box {{element.style}}"
                        (click)="reproducirRecurso(element)"
                        [ngStyle]="{'background-image': 'url(' + getImage(element) + ')','background-size' : 'cover','opacity': 1}"
                        id="#{{element.idTemplateElement}}">
                        <mat-icon *ngIf="element.elementType == 0" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.VIDEO' | translate }}">{{element.icon}}</mat-icon>
                        <mat-icon *ngIf="element.elementType == 1" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.IMAGE' | translate }}">{{element.icon}}</mat-icon>
                        <mat-icon *ngIf="element.elementType == 2" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.AUDIO' | translate }}">{{element.icon}}</mat-icon>
                        <mat-icon *ngIf="element.elementType == 3" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.PDF' | translate }}">{{element.icon}}</mat-icon>

                        <mat-icon *ngIf="element.elementType == 4" class="pointer" aria-hidden="false" placement="top"
                            ngbTooltip="{{'QUIZFORMCOMPONENT.TEXT' | translate }}" id="more" data-toggle="modal"
                            data-target="#descModal" (click)="getDescription(element)">{{element.icon}}</mat-icon>
                    </div>
                    <div *ngIf="5<=element.elementType  && element.elementType<=9" id="{{element.elementType}}"
                        style="position: absolute; z-index: 10;" [style.top.px]="element.yposition"
                        [style.left.px]="element.xposition" [style.width.px]="element.xsize"
                        [style.height.px]="element.ysize" class="element-box {{element.style}}"
                        [ngStyle]="{'background-image': 'url(' + getImage(element) + ')','background-size' : 'cover','opacity': 1}"
                        id="#{{element.idTemplateElement}}">
                        <input *ngIf="(5<=element.elementType  && element.elementType<9)" class="input-text"
                            id="{{element.idTemplateElement}}" type="file" (change)="cargarRecurso($event,element)" />
                        <label for="{{element.idTemplateElement}}">
                            <mat-icon *ngIf="element.elementType == 5" class="pointer" aria-hidden="false"
                                placement="top" ngbTooltip="{{'QUIZFORMCOMPONENT.VIDEO' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="element.elementType == 6" class="pointer" aria-hidden="false"
                                placement="top" ngbTooltip="{{'QUIZFORMCOMPONENT.IMAGE' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="element.elementType == 7" class="pointer" aria-hidden="false"
                                placement="top" ngbTooltip="{{'QUIZFORMCOMPONENT.AUDIO' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="element.elementType == 8" class="pointer" aria-hidden="false"
                                placement="top" ngbTooltip="{{'QUIZFORMCOMPONENT.PDF' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="element.elementType == 9" class="pointer" aria-hidden="false"
                                placement="top" ngbTooltip="{{'QUIZFORMCOMPONENT.TEXT' | translate }}" id="more"
                                data-toggle="modal" data-target="#descModal" (click)="getDescription(element)">
                                {{element.icon}}
                            </mat-icon>
                        </label>
                    </div>
                </div>
            </div>

            <div class="modal fade w-50 max-vh-20" data-keyboard="false" data-backdrop="static" id="descModal"
                tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" cdkDrag>
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            {{ 'QUIZZES.QUESTIONTEXT' | translate }}
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                (click)="closeModalText()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body well">
                            <div class="row p-1 m-1">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                                    <label>{{'QUIZZES.ESTILOFUENTE'|translate }}</label>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                                    <select class="col-lg-12 col-12" matNativeControl (change)="showFondStyle($event)">
                                        <option value="0" default>{{'QUIZZES.SELECCIONAOPCION'|translate}}</option>
                                        <option *ngFor="let fontStyle of fontStyles" value="{{fontStyle}}">{{fontStyle}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                                    <label>{{'QUIZZES.TAMANOFUENTE'|translate}}</label>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                                    <select class="col-lg-12 col-12" matNativeControl (change)="showFontSize($event)">
                                        <option value="12" default>{{'QUIZZES.SELECCIONAOPCION'|translate}}</option>
                                        <option *ngFor="let fontSize of fontSizes" value="{{fontSize}}">{{fontSize}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <textarea rows="5" class="form-control" #desc value="{{description}}"
                                [ngStyle]="{'font-style':fontStylex, 'font-size': fontSizex}"></textarea>
                            <div class="text-right well">
                                    <button mat-flat-button color="primary" class="mt-1 col-sm-3" data-dismiss="modal"
                                    type="submit">{{ 'QUIZZES.SAVE' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="answer">
            <div *ngFor="let element of template.elementsCorrect" style="position: absolute; z-index: 10;"
                [style.top.px]="element.yposition" [style.left.px]="element.xposition" [style.width.px]="element.xsize"
                [style.height.px]="element.ysize" class="rectangle element-box {{element.style}}"
                (click)="reproducirRecurso(element)"
                [ngStyle]="{'background-image': 'url(' + getImage(element) + ')','background-size' : 'cover','opacity': 1}">
                <mat-icon *ngIf="element.elementType == 10" class="pointer" aria-hidden="false" placement="top"
                    ngbTooltip="{{'QUIZFORMCOMPONENT.VIDEO' | translate }}">{{element.icon}}</mat-icon>
                <mat-icon *ngIf="element.elementType == 11" class="pointer" aria-hidden="false" placement="top"
                    ngbTooltip="{{'QUIZFORMCOMPONENT.IMAGE' | translate }}">{{element.icon}}</mat-icon>
                <mat-icon *ngIf="element.elementType == 12" class="pointer" aria-hidden="false" placement="top"
                    ngbTooltip="{{'QUIZFORMCOMPONENT.AUDIO' | translate }}">{{element.icon}}</mat-icon>
                <mat-icon *ngIf="element.elementType == 13" class="pointer" aria-hidden="false" placement="top"
                    ngbTooltip="{{'QUIZFORMCOMPONENT.PDF' | translate }}">{{element.icon}}</mat-icon>
                <mat-icon *ngIf="element.elementType == 14" class="pointer" aria-hidden="false" placement="top"
                    ngbTooltip="{{'QUIZFORMCOMPONENT.TEXT' | translate }}" id="more" data-toggle="modal"
                    data-target="#descModal" (click)="getDescription(element)">{{element.icon}}</mat-icon>
            </div>
        </div>
    </div>
</div>
