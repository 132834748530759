import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICoursesExplorarContenido } from 'src/app/shared/components/explorar-contenido/interfaces/explorar-contenido.interface';
import { CourseDetailResponseModel } from '../../models/courses/course-detail-response.model';

@Injectable({
  providedIn: 'root'
})
export class ExporarContenidoService {
  ideoGrafoActive = new Subject();


  constructor(private http:HttpClient) { }

  public emitClickIdeoGrafo(value) {
    this.ideoGrafoActive.next(value);
}

  getListadoCursosConGrafos(): Observable<any>{
    return this.http.get<any>('course/list-courses-publicados').pipe(
      map(
        res => (res.data as ICoursesExplorarContenido[]).map(
          (cursos:any)  => (
              {
                  title: cursos.courseTittle,
                  idCourse: cursos.idCourse,
                  idCountry: cursos.idCountry,
                  courseSWLevel: cursos.courseSWLevel,
                  user: cursos.user.firstName + " "+ cursos.user.surname ,
                  listIdeoGrafos: [...cursos.listIdeoGrafos]
              }
          )
        )
      )
    )
  }

  getListadoQuizesIdeografos(idCourse: number, idGrafo: number) : Observable<any>{
    return this.http.get<any>(`quizze/course/${idCourse}/graph/${idGrafo}/listquizzes`)
  }

}
