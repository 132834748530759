import {
	InstagramVideo,
	SpotifyAudio,
	TiktokVideo,
} from "./../../../../../../../../core/models/shared/external-video.model";
import { ModalTiktokVideoComponent } from "./../modal-tiktok-video/modal-tiktok-video.component";
import { ModalInstagramVideoComponent } from "./../modal-instagram-video/modal-instagram-video.component";
import { LoginService } from "../../../../../../../../core/services/login/login.service";
import { TranslateService } from "@ngx-translate/core";
import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from "@angular/core";
import { NodeFilesFormat } from "src/app/core/models/nodes/node-files-format.model";
import { FILE_FORMATS } from "src/app/core/utils/file-formats";
import { ModalMicrofonoAudioComponent } from "src/app/shared/components/modal-microfono-audio/modal-microfono-audio.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalYoutubeVideoComponent } from "../modal-youtube-video/modal-youtube-video.component";
import { YoutubeVideo } from "src/app/core/models/shared/external-video.model";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { ModalSpotifyAudioComponent } from "../modal-spotify-audio/modal-spotify-audio.component";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

export interface DownloadFileModel {
	type: string;
	fileName: string;
}

@Component({
	selector: "app-node-detail-files-uploader",
	templateUrl: "./node-detail-files-uploader.component.html",
	styleUrls: ["./node-detail-files-uploader.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class NodeDetailFilesUploaderComponent implements OnInit {
	@Input() format: NodeFilesFormat;
	@Input() progress: number;
	@Input() node: any;
	@Output() filesUploaderFileSelected: EventEmitter<any> =
		new EventEmitter<any>();
	@Output() filesUploaderVideoPreview: EventEmitter<any> =
		new EventEmitter<any>();
	@Output() filesUploaderPlayFile: EventEmitter<string> =
		new EventEmitter<string>();
	@Output() filesUploaderDeleteFile: EventEmitter<any> =
		new EventEmitter<any>();
	@Output() filesUploaderDownloadFile: EventEmitter<DownloadFileModel> =
		new EventEmitter<DownloadFileModel>();
	@Output() filesYoutubeVideo: EventEmitter<YoutubeVideo> =
		new EventEmitter<YoutubeVideo>();
	@Output() filesInstagramVideo: EventEmitter<InstagramVideo> =
		new EventEmitter<InstagramVideo>();
	@Output() filesTiktokVideo: EventEmitter<TiktokVideo> =
		new EventEmitter<TiktokVideo>();
	@Output() filesSpotifyAudio: EventEmitter<SpotifyAudio> =
		new EventEmitter<SpotifyAudio>();

	isTxt: boolean = false;
	isPdf: boolean = false;
	isVideo: boolean = false;
	isImagen: boolean = false;
	isAudio: boolean = false;
	esAutor: boolean = false;
	ext: string = "";
	sanitizedUrl: SafeResourceUrl;
	PDF_TYPE = FILE_FORMATS.PDF_TYPE;
	FILES_URL = environment.remoteFilesUrl + "nodeContent";
	haveSpotify: boolean;
	form: UntypedFormGroup;

	constructor(
		private translate: TranslateService,
		private modalService: NgbModal,
		private loginService: LoginService,
		private toaster: ToasterService,
		private sanitizer: DomSanitizer,
		private fb: UntypedFormBuilder,
	) {}

	ngOnInit() {
		this.isTxt = this.format.type === FILE_FORMATS.TXT_TYPE;
		this.isPdf = this.format.type === FILE_FORMATS.PDF_TYPE;
		this.isVideo = this.format.type === FILE_FORMATS.VIDEO_TYPE ? true : false;
		this.isImagen = this.format.type === FILE_FORMATS.IMAGE_TYPE;
		this.isAudio = this.format.type === FILE_FORMATS.AUDIO_TYPE;
		this.haveSpotify = this.node.nodesFiles.spotifyAudio === null ? false : true;

		if (this.format.background) {
			const parts = this.format.background.split(".");
			if (parts.length > 1) {
				this.ext = parts.pop();
				if (this.ext && (this.ext !== "" || this.ext !== undefined)) {
					this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.format.background);
				}
			}
		}
	}

	private fileUrl(type: string, fileName: string) {
		if (!fileName) return undefined;
		return `${this.FILES_URL}/${type}/${fileName}`;
	}

	nohacerNada() {}

	onFileSelected(event, acceptFileType: string) {
		if(this.isAudio && this.node.nodesFiles.spotifyAudio){
			this.nohacerNada();
		} else{
			if (!this.node.rented) {
				let file: File = event.target.files[0] as File;
				if (this._checkFiles(file.type, acceptFileType))
					this.filesUploaderFileSelected.emit({ event, format: this.format });
				else
					this.toaster.error(
						this.translate.instant("NODEFORMCOMPONENT.FILERRORFORMAT")
					);
			}
		}
	}

	goSpotify(url: string){
		//this.toaster.error("Nos fuimos pa spotify");
		window.open(url, '_blank');
	}

	onVideoPreview(event) {
		let file: File = event.target.files[0] as File;
		if (this._checkFiles(file.type, "image/*"))
			this.filesUploaderVideoPreview.emit(event);
		else
			this.toaster.error(
				this.translate.instant("NODEFORMCOMPONENT.FILERRORFORMAT")
			);
	}

	playFile() {
		this.filesUploaderPlayFile.emit(this.format.type);
	}

	private _checkFiles(fileType: string, acceptFileType: string): boolean {
		let tmpFileType: string = fileType.split("/")[0];
		let tmpAcceptFileType: string = acceptFileType.split("/")[0];

		//Hay que comprobar los ficheros cuando se suben

		if (tmpAcceptFileType === "application") return true;

		if (
			tmpFileType !== tmpAcceptFileType ||
			(tmpFileType === "video" && fileType !== acceptFileType)
		)
			return false;

		return true;
	}

	streamOrHardDisk($event, format) {
		if (!this.node.rented) {
			let fileDevice: File;
			const modalRef = this.modalService.open(ModalMicrofonoAudioComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30,
			});
			if (format === "image/*") {
				modalRef.componentInstance.soloFoto = true;
			} else if (format === "audio/*") {
				modalRef.componentInstance.soloAudio = true;
			} else if (format === "video/mp4") {
				modalRef.componentInstance.soloVideo = true;
			}
			modalRef.result.then((res) => {
				fileDevice = res as File;
				this.filesUploaderFileSelected.emit({
					$event,
					format: this.format,
					fileDevice,
				});
			});
		}
	}

	deleteFile(type: string, fileName: string, idNodeFile: number): void {
		this.filesUploaderDeleteFile.emit({
			type: type,
			fileName: fileName,
			idNodeFile: idNodeFile,
		});
	}

	downloadFile(type: string, fileName: string): void {
		this.filesUploaderDownloadFile.emit({ type: type, fileName: fileName });
	}

	openModalExternalVideo(type: number): void {
		let component = null;
		let options = { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W30 };

		switch (type) {
			case 1:
				component = ModalYoutubeVideoComponent;
				break;
			case 2:
				component = ModalInstagramVideoComponent;
				break;
			case 3:
				component = ModalTiktokVideoComponent;
		}

		const modalRef = this.modalService.open(component, options);
		modalRef.componentInstance.format = this.format;

		modalRef.result.then(
			(res) => {
				if (res) {
					switch (type) {
						case 1:
							this.filesYoutubeVideo.emit(res as YoutubeVideo);
							break;
						case 2:
							this.filesInstagramVideo.emit(res as InstagramVideo);
							break;
						case 3:
							this.filesTiktokVideo.emit(res as TiktokVideo);
							break;
					}
				}
			},
			(err) => {}
		);
	}

	deleteExternalVideo(type: number){
		this.form = this.fb.group({
			url: this.format.url,
			start: this.format.start,
			end: this.format.end,
			nameFileVideo: [null],
			isDelete: 1,
			idNodeFile: this.format.idNodeFile
		});
		switch (type) {
			case 1:
				this.filesYoutubeVideo.emit(this.form.value as YoutubeVideo);
				break;
			case 2:
				this.filesInstagramVideo.emit(this.form.value as InstagramVideo);
				break;
			case 3:
				this.filesTiktokVideo.emit(this.form.value as TiktokVideo);
				break;
		}
	}

	openModalSpotifyAudio(): void {
		let component = null;
		let options = { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W30 };
		component = ModalSpotifyAudioComponent;

		const modalRef = this.modalService.open(component, options);
		modalRef.componentInstance.format = this.format;
		modalRef.componentInstance.node = this.node;

		modalRef.result.then(
			(res) => {
				this.haveSpotify = this.node.nodesFiles.spotifyAudio === null ? false : true;
				this.filesSpotifyAudio.emit(res as SpotifyAudio);
			},
			(err) => {}
		);
	}
}
