import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as printJS from 'print-js';
import { QuizModel } from 'src/app/core/models/quizzes';
import { QuizTypes } from 'src/app/core/models/quizzes/quiz-types.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { QuizEditInfoComponent } from 'src/app/shared/components/nodes/quiz-edit/components/quiz-edit-info/quiz-edit-info.component';

@Component({
    selector: 'app-actividad-quiz-play-header',
    templateUrl: './actividad-quiz-play-header.component.html',
    styleUrls: ['./actividad-quiz-play-header.component.scss']
})
export class ActividadQuizPlayHeaderComponent implements OnInit {
    @Input() quiz: QuizModel;
    @Input() idCurso: number;
    @Input() idMapa: number;
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() modeAuto: boolean = false;

    constructor(private modalService: NgbModal) {
    }

    ngOnInit() {
    }

    public get quizTypes() {
        return QuizTypes;
    }

    onCloseModal(sendData?: any) {
        this.close.next(true);
    }

    showInfo() {
        const modalRef = this.modalService.open(QuizEditInfoComponent,
            {
                scrollable: false,
                windowClass: MODAL_DIALOG_TYPES.W95
            }
        );
        modalRef.componentInstance.quiz = this.quiz;
        modalRef.componentInstance.courseId = this.idCurso;
        modalRef.componentInstance.graphId = this.idMapa;
    }
}
