import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_LANGUAGES } from 'src/app/core/utils';

@Pipe({
    name: 'languageName'
})
export class LanguageNamePipe implements PipeTransform {

    constructor(private translate: TranslateService) { }

    transform(languageIsoCode: string): any {
        let result: string;
        switch (languageIsoCode.toLowerCase()) {
            case APP_LANGUAGES.DE:
                result = this.translate.instant('IDIOMA.ALEMAN')
                break;
            case APP_LANGUAGES.ES:
                result = this.translate.instant('IDIOMA.ESPAÑOL')
                break;
            case APP_LANGUAGES.PT:
                result = this.translate.instant('IDIOMA.PORTUGUES')
                break;
            case APP_LANGUAGES.EN:
                result = this.translate.instant('IDIOMA.INGLES')
                break;
        }
        return result;
    }
}
