import { Observable } from 'rxjs';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';

import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { TranslateService } from '@ngx-translate/core';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { DEFAULTMOBILEPREFIX } from 'src/app/core/models/masters/masters.enum';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';
import { LocalStorage } from 'src/app/core/utils';
import { formsValidations } from 'src/app/core/utils/forms-validations';
import { PrefixCountry } from 'src/app/core/models/shared/prefixCountry.model';
import { RegisterService } from 'src/app/core/services/register/register.service';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
  selector: 'app-modal-change-mobile',
  templateUrl: './modal-change-mobile.component.html',
  styleUrls: ['./modal-change-mobile.component.scss']
})
export class ModalChangeMobileComponent implements OnInit{

	@Output() action: EventEmitter<boolean> = new EventEmitter<boolean>()

 	mensaje: string;
	optionalTitle: string;
	disableCancel: boolean;
	formulario: UntypedFormGroup;
	validationMessages = {
		mobile: [],
		prefix: [],
		code: [],
	};
	lang: string;

	private mobileMinLength: number = formsValidations.MOBILE_MIN_LENGTH;
	private mobileMaxLength: number = formsValidations.MOBILE_MAX_LENGTH;
	private mobilePattern: string = formsValidations.MOBILE_PATTERN;
	$phonePrefixes: Observable<PrefixCountry[]>;
	introCode: boolean = false;
	samePhone: boolean = true;

  constructor(
		public translateService: TranslateService,
		private activeModal: NgbActiveModal,
		private formBuild: UntypedFormBuilder,
		private localStorage: LocalStorage,
		private dateAdapter: DateAdapter<Date>,
		private userService: UsersService,
		private loginService: LoginService,
		private registerService: RegisterService,
		private toaster: ToasterService
		){}

	ngOnInit(): void {
		this.crearInitialForm();
		this.traducirOpciones();
		this.$phonePrefixes = this.loginService.getPhonePrefixV2();
		this.verificarMovil();
	}

	aceptar(){
		if(this.formulario.valid && !this.samePhone){
			const fm = this.formulario.value;
			const prefix = fm.prefix.replace('+', '');
			this.registerService.mobileRequestSms(prefix, fm.mobile).subscribe( res => {
				if(res.error_id === 'INVALID_NUMBER'){
					this.toaster.error(this.translateService.instant('CAMBIARMOVIL.WRONGPHONE'));
				} else if(res.error_id === 'PHONE_NUMBER_ALREADY_USED'){
					this.toaster.error(this.translateService.instant('REGISTRO.MAILORPHONEEXIST'));
				} else {
					this.toaster.success(this.translateService.instant('CAMBIARMOVIL.CODESENDED'));
					this.introCode = true;
				}
		})
		}
	}

  cancelar() {
    //this.activeModal.close(false);
		this.action.emit(false);
  }

  closeModal(sendData?: any) {
    this.activeModal.close(false);
  }

	crearInitialForm() {
		this.formulario = this.formBuild.group({
			mobile: new UntypedFormControl("", [
				Validators.minLength(this.mobileMinLength),
				Validators.maxLength(this.mobileMaxLength),
				Validators.pattern(this.mobilePattern),
			]),
			prefix: ['+' + this.loginService.getUser().extension, Validators.required],
			code: [""],
		});
	}

	verificarMovil(){
		const fm = this.formulario.value;
		if(fm.mobile === this.loginService.getUser().mobile){
			this.samePhone = true;
		} else{
			this.samePhone = false;
		}
	}

	validacionPin(ev): void {
		//Cuando sean 6 digitos ejecutamos la llamada
		if (ev.target.value.length === 6){
			const fm = this.formulario.value;
			const prefix = fm.prefix.replace('+', '');
			this.userService.changeMobile(fm.mobile, prefix, fm.code).subscribe(res => {
				this.toaster.success(this.translateService.instant('CAMBIARMOVIL.PHONEEDITED'));
				this.closeModal();
				this.logout();
			}, (error) => {
				this.toaster.error(this.translateService.instant('CAMBIARMOVIL.ERRORCODE'));
			})
		}
	}

	logout(): void {
		localStorage.removeItem('selectedGroupInfo');
		this.loginService.logout(true);
	}

	traducirOpciones() {
		// Recupero el lenguaje
		this.lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
		this.translateService.use(this.lang);
		this.dateAdapter.setLocale(this.lang);

		this.translateService
			.get("VALIDACIONES.ELTELEFONOMOVILESOBLIGATORIO")
			.subscribe((res: string) => {
				this.validationMessages.mobile.push({ type: "required", message: res });
			});

		this.translateService
			.get("VALIDACIONES.MOBILEMIN")
			.subscribe((res: string) => {
				this.validationMessages.mobile.push({	type: "minlength", message: res	});
			});

		this.translateService
			.get("VALIDACIONES.MOBILEMAX")
			.subscribe((res: string) => {
				this.validationMessages.mobile.push({	type: "maxlength", message: res	});
			});

		this.translateService
			.get("VALIDACIONES.MOBILENUMEROS")
			.subscribe((res: string) => {
				this.validationMessages.mobile.push({ type: "pattern", message: res });
			});

		this.translateService
			.get("VALIDACIONES.PREFIXREQUIRED")
			.subscribe((res: string) => {
				this.validationMessages.prefix.push({ type: "required", message: res });
			});

		this.translateService
			.get("VALIDACIONES.CODEREQUIRED")
			.subscribe((res: string) => {
				this.validationMessages.code.push({ type: "required", message: res });
			});
	}

	get mobileControl(): UntypedFormControl {
		if (this.formulario) {
			return this.formulario.get("mobile") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get prefixControl(): UntypedFormControl {
		if (this.formulario) {
			return this.formulario.get("prefix") as UntypedFormControl;
		} else {
			return null;
		}
	}

}
