<div style="position: relative; height:100%; width:100%;" class="d-flex flex-column justify-content-center align-items-center">

	<!-- GRAFICA -->
  <canvas id="myChart"></canvas>
	<div class="position-icon-top">
		<mat-icon class="custom-icon" svgIcon="QuizSimple"></mat-icon>
	</div>

	<div class="position-icon-button">
		<mat-icon svgIcon="ideasvg"></mat-icon>
	</div>

	<!-- BOTONES DE ZOOM -->
	<div class="d-flex w-100 justify-content-end p-3">
		<button nbButton status="basic" size="small" shape="rectangle" id="zoomOutButton"><nb-icon icon="minimize-outline"></nb-icon></button>
		<button nbButton status="basic" size="small" shape="rectangle" id="zoomInButton"><nb-icon icon="maximize-outline"></nb-icon></button>
	</div>
</div>
