import { Time } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { QuizModel } from 'src/app/core/models/quizzes';
import { QuizTemplateElement } from 'src/app/core/models/quizzes/quiz-template-element.model';
import { QuizTemplate } from 'src/app/core/models/quizzes/quiz-template.model';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { UtilsService } from 'src/app/core/services/shared/utils.service';
import { LocalStorage } from 'src/app/core/utils';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { TEMPLATE_ELEMENTS_TYPES } from 'src/app/core/utils/template-elements-types';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';
import { QuizEditTemplateTextEditorComponent } from './components/quiz-edit-template-text-editor/quiz-edit-template-text-editor.component';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
    selector: 'app-quiz-edit-template',
    templateUrl: './quiz-edit-template.component.html',
    styleUrls: ['./quiz-edit-template.component.scss'],
    providers: [ImagenPipe]
})
export class QuizEditTemplateComponent implements OnInit, OnChanges, OnDestroy {
    @Input() mensajeBody: string;
    @Input() quizEnviado: any;
    @Input() date: Date | string;
    @Input() template: QuizTemplate;
    @Input() quiz: QuizModel;
    @Input() courseId: number;
    @Input() graphId: number;
    @Input() onSaveClicked$: Observable<any>;

    visibility: boolean;
    intervalId;
    time: Time;
    iniBlock: boolean;
    countSeconds: number;
    timerId: number = null;
    elements: QuizTemplateElement[] = [];
    elementsTemplatesQuizzes: any[];
    source: any;
    newQuiz: any;
    progress: boolean;
    imagen: any;
    titulo: string;
    elementCurrent: any;
    description: string;

    form: UntypedFormGroup;
    validationMessages = {
        title: [],
        instructions: []
    }

    isLoading: boolean = false;
    saveClickedSubscription: Subscription;

    constructor(
        private quizService: QuizzesService,
        private modalService: NgbModal,
        private fb: UntypedFormBuilder,
        private localStorage: LocalStorage,
        private translateService: TranslateService,
        private utilsService: UtilsService,
				private toaster: ToasterService

    ) {
        this.form = this.fb.group({
            quizTittle: ['', Validators.required],
            quizInstructions: ''
        });

        this.quizService.currentQuiz.subscribe((q: QuizModel) => {
            this.quiz = q
        });

    }

    ngOnInit() {
        this.traducirOpciones();
        this.titleControl.patchValue(this.quiz.quizTittle);
        this.instructionsControl.patchValue(this.quiz.quizInstructions);
        this.description = "";
        this.progress = false;
        // await this.quizService.currentQuiz.subscribe( (q) => this.quiz = q);
        this.visibility = true;
        if (this.template) {
            /*RECALCULAR LAS POSCIONES DE LOS ELEMENTOS DEL TEMPLATE*/
            this.handleTemplate(this.template);
        }

        this.saveClickedSubscription = this.onSaveClicked$.subscribe(res => this.save());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.template && changes.template.currentValue) {
            this.handleTemplate(changes.template.currentValue);
        }
    }

    ngOnDestroy() {
        this.saveClickedSubscription.unsubscribe();
        if (this.timerId) {
            clearInterval(this.timerId);
        }
    }

    private handleTemplate(template) {
        this.elements = [];
    }

    get titleControl(): UntypedFormControl {
        return this.form.get('quizTittle') as UntypedFormControl;
    }

    get instructionsControl(): UntypedFormControl {
        return this.form.get('quizInstructions') as UntypedFormControl;
    }

    elementsTemplatesQuizzesToElements() {
        this.elements = [];
        for (var i = 0; i < this.elementsTemplatesQuizzes.length; i++) {
            this.elements[i] = this.elementsTemplatesQuizzes[i].templateElement[0];
        }
        this.calculatePositions2(this.elements);
        this.assignIconTypeElement(this.elements);
        return this.elements;
    }

    descubrirRespuesta() {
    }

    autoEvaluacionNegativa() {
    }

    autoEvaluacionPositiva() {
    }

    nextQuiz() {
        this.mensajeBody = "Paso al siguiente quiz";
    }


    calculatePositions(elements: any[]) {
        if (elements != null) {
            elements.forEach((element) => {
                element.yposition = element.newyposition;
                element.xposition = element.newxposition;
            });
        }
    }

    calculatePositions2(elements: any[]) {
        if (elements != null) {
            elements.forEach((element) => {
                element.yposition = element.yposition;
                element.xposition = element.xposition;
            });
        }
    };

    cargarRecurso(fileEvent, element) {
        this.progress = true;
        var file = fileEvent.target.files[0];
        if (file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (event: any) => {
                this.source = event.target.result;

                //Cargar archivo
                const base64 = this.source.split(';base64,')[1];
                const fileBlob = this.dataURItoBlob(base64);
                const fileData = new File([fileBlob], file.name, { type: file.type });

                this.quizService.setQuizElementData(this.quiz.idOriginal, element.idTemplateElement, element.responseCheck, element.idTemplateElementQuizz, fileData).subscribe((res) => {
                    element.data = res.data.data;
                    element.idTemplateElementQuizz = res.data.idTemplateElementQuizz;

                    this.toaster.success(this.translateService.instant('NODEFORMCOMPONENT.FILESAVED'));
                },
                    err => this.toaster.error(this.translateService.instant('NODEFORMCOMPONENT.FILEERROR'))
                );
            }
        }
        this.progress = false;
    }

    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/png' });
        return blob;
    }

    changeValue(event: boolean, element: QuizTemplateElement) {
        this.quizService.setQuizElementData(this.quiz.idOriginal, element.idTemplateElement, event ? 1 : 0, element.idTemplateElementQuizz, undefined, element.data).subscribe((res) => {
            element.responseCheck = event;
            // document.getElementById(idDiv).style.backgroundImage = 'url(' + this.imagePipe.transform(res.data.data, 'quizElement') + ')';
        });
    }

    setElementType(quiz: any, element: any) {
        switch (element.elementsType.idElementType) {
            case (0): {
                quiz.stringQuestionsVideo = element.idTemplateElement;
                break;
            }
            case (1): {
                quiz.stringQuestionsImage = element.idTemplateElement;
                break;
            }
            case (2): {
                quiz.stringQuestionsAudio = element.idTemplateElement;
                break;
            }
            case (3): {
                quiz.stringQuestionsDoc = element.idTemplateElement;
                break;
            }
            case (4): {
                quiz.stringQuestionsText = element.idTemplateElement;
                break;
            }
            case (5): {
                quiz.stringOptionsVideo = element.idTemplateElement;
                break;
            }
            case (6): {
                quiz.stringOptionsImage = element.idTemplateElement;
                break;
            }
            case (7): {
                quiz.stringOptionsAudio = element.idTemplateElement;
                break;
            }
            case (8): {
                quiz.stringOptionsDoc = element.idTemplateElement;
                break;
            }
            case (9): {
                quiz.stringOptionsText = element.idTemplateElement;
                break;
            }
            case (10): {
                quiz.stringAnswersVideo = element.idTemplateElement;
                break;
            }
            case (11): {
                quiz.stringAnswersImage = element.idTemplateElement;
                break;
            }
            case (12): {
                quiz.stringAnswersAudio = element.idTemplateElement;
                break;
            }
            case (13): {
                quiz.stringAnswersDoc = element.idTemplateElement;
                break;
            }
            case (14): {
                quiz.stringAnswersText = element.idTemplateElement;
                break;
            }
            default: {
                break;
            };
        }
    }

    setDescription(element: QuizTemplateElement) {
        const modalRef = this.modalService.open(QuizEditTemplateTextEditorComponent,
            {
                scrollable: true,
                windowClass: MODAL_DIALOG_TYPES.W95,
                backdrop: 'static'
            }
        );

        modalRef.componentInstance.text = element.data;

        modalRef.result.then(
            (result) => {
                if (result) {
                    this.quizService.setQuizElementData(this.quiz.idOriginal, element.idTemplateElement, +element.responseCheck, element.idTemplateElementQuizz, undefined, result).subscribe((res) => {
                        element.data = result;
                        this.toaster.success(this.translateService.instant('NODEFORMCOMPONENT.FILESAVED'));
                    },
                        err => this.toaster.error(this.translateService.instant('NODEFORMCOMPONENT.FILEERROR'))
                    );
                }
            },
            (reason) => {
            });
    }

    onChange(event, element: any) {
        var resposeCheck = event.target.value;
        if (resposeCheck == "true") {
            if (event.target.classList.contains('result-wrong')) {
                event.target.classList.remove('result-wrong');
            }

            event.target.classList.add('result-correct')
        } else {
            if (event.target.classList.contains('result-correct')) {
                event.target.classList.remove('result-correct');
            }
            event.target.classList.add('result-wrong')
        }
        this.elements.forEach((x) => {
            if (x.idTemplateElement == element.idTemplateElement) x.responseCheck = resposeCheck ? 1 : 0;
        })

    }

    saveQuizz() {}


    assignIconTypeElement(elements: any) {
        for (var i = 0; i < this.elements.length; i++) {
            this.elements[i].style = TEMPLATE_ELEMENTS_TYPES[elements[i].elementsType.idElementType].name;
            this.elements[i].icon = TEMPLATE_ELEMENTS_TYPES[elements[i].elementsType.idElementType].icon;
        }
    }


    private traducirOpciones() {
        // Recupero el lenguaje
        const lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
        this.translateService.use(lang);

        this.translateService.get('VALIDACIONES.TITLEREQUIRED').subscribe((res: string) => {
            this.validationMessages.title.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.INSTRUCTIONSREQUIRED').subscribe((res: string) => {
            this.validationMessages.instructions.push({ type: 'required', message: res });
        });
    }

    public save() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const fv = this.form.value;
            const tmpQuiz = { ...this.quiz };

            tmpQuiz.quizTittle = fv.quizTittle;
            tmpQuiz.quizInstructions = fv.quizInstructions;

            this.isLoading = true;
            this.quizService.createQuiz(this.courseId, this.graphId, tmpQuiz).pipe(map((res: any) => res.data), finalize(() => this.isLoading = false)).subscribe(
                res => {
                    this.quizService.currentQuiz.next(res);
                    this.toaster.success(this.translateService.instant('QUIZFORMCOMPONENT.SAVED'));
                },
                err => {
                    this.toaster.error(this.translateService.instant('QUIZFORMCOMPONENT.ERROR'));
                }
            );
        }
    }

    public getElementType(element: QuizTemplateElement) {
        return TEMPLATE_ELEMENTS_TYPES[element.elementsType.idElementType];
    }
}
