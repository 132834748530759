<!-- Numero de asistencias en un dia -->
<div *ngIf="screen == 1">
  <nb-card class="mb-0 no-edge-card">
    <nb-card-header class="border-bottom-0 pb-0">
      <div class="row">
				<div class="col-10">
					<div class="d-flex flex-wrap align-items-center">
						<h5>{{ name }} - {{fechaCorta}}</h5>
					</div>
				</div>
			</div>
    </nb-card-header>
    <div class="closeModal">
      <nb-icon class="m-1" icon="close-circle" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left"
        [options]="{ animation: { type: 'zoom' } }" (click)="closeModal()"></nb-icon>
    </div>
		<nb-card-body class="pt-0">
			<nb-tabset *ngIf="!isMobileView">
				<nb-tab *ngFor="let item of dato; let i = index" [tabTitle]="adjustToUserTime(item.texto)">
					<ng-template nbTabTitle>
						<a
							href="javascript:void(0)"
							[ngClass]="getTabClass(forms[i].get('status')?.value)">
							{{ adjustToUserTime(item.texto) }}
						</a>
					</ng-template>
					<div class="container-form">
						<form class="w-100" [formGroup]="forms[i]" (ngSubmit)="justify(i)">
							<div class="d-flex flex-column">
								<div class="form-group">
									<label>Status:</label>
									<select class="form-control" formControlName="status">
										<option value="1">{{ 'ASISTENCIA.PENDIENTE' | translate }}</option>
										<option value="2">{{ 'ASISTENCIA.AUSENTE' | translate }}</option>
										<option value="3">{{ 'ASISTENCIA.PRESENTE' | translate }}</option>
										<option value="4">{{ 'ASISTENCIA.CONRETRASO' | translate }}</option>
										<option value="5">{{ 'ASISTENCIA.JUSTIFICADO' | translate }}</option>
									</select>
								</div>
								<div class="form-group">
									<label>{{ 'ASISTENCIA.MOTIVO' | translate }}:</label>
									<textarea class="form-control" formControlName="motivo" rows="5" maxlength="250"></textarea>
								</div>
								<div class="py-4 d-flex justify-content-center">
									<button nbButton class="d-block w-100 btn-themecolor" type="submit" [class.disabled]="!roleAsignado">
										<span>{{ "MISDATOS.SAVE" | translate }}</span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</nb-tab>
			</nb-tabset>

			<!-- Modo dropdown (pantalla móvil) -->
			<div *ngIf="isMobileView" class="dropdown-tabs">
				<div class="form-group">
					<label>Asistencias:</label>
					<select class="form-control" [(ngModel)]="selectedTab">
						<option *ngFor="let item of dato; let i = index" [value]="i">
							{{ adjustToUserTime(item.texto) }}
						</option>
					</select>
				</div>

				<!-- Contenido del tab seleccionado -->
				<div class="tab-content">
					<form class="w-100" [formGroup]="forms[selectedTab]" (ngSubmit)="justify(selectedTab)">
						<div class="d-flex flex-column">
							<div class="form-group">
								<label>Status:</label>
								<select class="form-control" formControlName="status">
									<option value="1">{{ 'ASISTENCIA.PENDIENTE' | translate }}</option>
									<option value="2">{{ 'ASISTENCIA.AUSENTE' | translate }}</option>
									<option value="3">{{ 'ASISTENCIA.PRESENTE' | translate }}</option>
									<option value="4">{{ 'ASISTENCIA.CONRETRASO' | translate }}</option>
									<option value="5">{{ 'ASISTENCIA.JUSTIFICADO' | translate }}</option>
								</select>
							</div>
							<div class="form-group">
								<label>{{ 'ASISTENCIA.MOTIVO' | translate }}:</label>
								<textarea class="form-control" formControlName="motivo" rows="5" maxlength="250"></textarea>
							</div>
							<div class="py-4 d-flex justify-content-center">
								<button nbButton class="d-block w-100 btn-themecolor" type="submit" [class.disabled]="!roleAsignado">
									<span>{{ "MISDATOS.SAVE" | translate }}</span>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</nb-card-body>
  </nb-card>
</div>

<!-- Detalle de asistencias del estudiante -->
<div *ngIf="screen == 2">
	<nb-card class="mb-0 no-edge-card">
		<nb-card-header class="border-bottom-0">
      <div class="row">
				<div class="col-10">
					<div class="d-flex flex-wrap align-items-center gap-2">
						<h5>{{ name }} - {{ 'ASISTENCIA.ESTADISTICAS' | translate }}</h5>
					</div>
				</div>
			</div>
    </nb-card-header>
		<div class="closeModal">
			<nb-icon class="m-1" icon="close-circle" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left"
				[options]="{ animation: { type: 'zoom' } }" (click)="closeModal()"></nb-icon>
		</div>
		<nb-card-body class="pt-0">
			<div class="row">
				<div class="d-flex col-lg-6 col-12 gap-1 flex-column">
					<label class="m-0" for="startDate">{{ 'ASISTENCIA.FECHAINICIO' | translate }}:</label>
					<input type="date" id="startDate" nbInput class="form-control" fullWidth status="basic" fieldSize="small"
						[(ngModel)]="fechaInicio" (ngModelChange)="validarFechas()">
				</div>

				<div class="d-flex col-lg-6 col-12 gap-1 flex-column pt-3 pt-lg-0">
					<label class="m-0" for="endDate">{{ 'ASISTENCIA.FECHAFIN' | translate }}:</label>
					<input type="date" id="endDate" nbInput class="form-control" fullWidth status="basic" fieldSize="small"
						[(ngModel)]="fechaFin" (ngModelChange)="validarFechas()">
				</div>
			</div>
			<div class="d-flex mt-4">
				<nb-card class="background_themecolor mb-0">
					<nb-card-body>
						<div class="d-flex flex-wrap gap-4 justify-content-center align-items-center">
							<!-- Pendiente por responder -->
							<div class="container-detail_asisten">
								<span class="text-red">
									{{summary.totalPending}}/{{summary.totalRecords}}
								</span>
								<p>{{ 'ASISTENCIA.PENDIENTEPORRESPONDER' | translate }}</p>
							</div>
							<!-- Ausente -->
							<div class="container-detail_asisten">
								<span class="text-red">
									{{summary.totalAbsent}}/{{summary.totalRecords}}
								</span>
								<p>{{ 'ASISTENCIA.AUSENTE' | translate }}</p>
							</div>
							<!-- Presente -->
							<div class="container-detail_asisten">
								<span class="text-green">
									{{summary.totalPresent}}/{{summary.totalRecords}}
								</span>
								<p>{{ 'ASISTENCIA.PRESENTE' | translate }}</p>
							</div>
							<!-- Con retraso -->
							<div class="container-detail_asisten">
								<span class="text-yellow">
									{{summary.totalLate}}/{{summary.totalRecords}}
								</span>
								<p>{{ 'ASISTENCIA.CONRETRASO' | translate }}</p>
							</div>
							<!-- Justificado -->
							<div class="container-detail_asisten">
								<span class="text-blue">
									{{summary.totalJustified}}/{{summary.totalRecords}}
								</span>
								<p>{{ 'ASISTENCIA.JUSTIFICADO' | translate }}</p>
							</div>
						</div>
					</nb-card-body>
				</nb-card>
			</div>
		</nb-card-body>
	</nb-card>
</div>
