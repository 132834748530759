<nb-card class="no-edge-card mb-0 modal-height-100-62">
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>

	<nb-card-body>
		<!-- Sección con datos no sociales -->
		<div class="container" *ngIf="this.tipoDatos != 'social'">
			<div *ngIf="cargarCharts" class="card-container" [ngClass]="{'six-cards': consultas.length > 4}">
				<div *ngFor="let consulta of consultas" class="card-item" (click)="openChart(consulta.id)">
					<!-- Contenido de la tarjeta -->
					<h6>{{ consulta.title }}</h6>
					<div class="chart-container">
						<!-- Aquí van las gráficas -->
						<ng-container [ngSwitch]="consulta.id">
							<!-- ==========  PROFESOR  ========== -->
							<!-- GRAFICA RADIAL -->
							<app-radial-mini *ngSwitchCase="1" [parametroID]="1" [consultaID]="1" [datasetsImported]="datasetsImported_1"
								[labelsImported]="labelsImported_1">
							</app-radial-mini>
							<!-- GRAFICA SOLAR -->
							<app-solar-mini *ngSwitchCase="2" [parametroID]="1" [consultaID]="2" [datasetsImported]="datasetsImported_2"
								[labelsImported]="labelsImported_2">
							</app-solar-mini>
							<!-- ==========  ESTUDIANTE  ========== -->
							<!-- GRAFICA SOLAR -->
							<app-solar-mini *ngSwitchCase="8" [parametroID]="1" [consultaID]="8" [datasetsImported]="datasetsImported_1"
								[labelsImported]="labelsImported_1">
							</app-solar-mini>
							<!-- GRAFICA LISTA -->
							<app-lista-mini *ngSwitchCase="9" [parametroID]="1" [consultaID]="9" [datasetsImported]="datasetsImported_2"
								[labelsImported]="labelsImported_2">
							</app-lista-mini>
							<!-- ==========  GRUPOS  ========== -->
							<!-- GRAFICA SOLAR -->
							<app-solar-mini *ngSwitchCase="4" [parametroID]="1" [consultaID]="4" [datasetsImported]="datasetsImported_1"
								[labelsImported]="labelsImported_1">
							</app-solar-mini>
							<!-- GRAFICA BARRAS -->
							<app-barras-mini *ngSwitchCase="5" [parametroID]="1" [consultaID]="5" [datasetsImported]="datasetsImported_2"
								[labelsImported]="labelsImported_2">
							</app-barras-mini>
							<!-- ==========  NIVELES  ========== -->
							<!-- GRAFICA BARRAS -->
							<app-barras-mini *ngSwitchCase="10" [parametroID]="1" [consultaID]="10" [datasetsImported]="datasetsImported_1"
								[labelsImported]="labelsImported_1">
							</app-barras-mini>
							<!-- GRAFICA RADIAL -->
							<app-radial-mini *ngSwitchCase="11" [parametroID]="1" [consultaID]="11" [datasetsImported]="datasetsImported_2"
								[labelsImported]="labelsImported_2">
							</app-radial-mini>
							<!-- ==========  PADRES  ========== -->
							<!-- GRAFICA LISTA -->
							<app-lista-mini *ngSwitchCase="12" [parametroID]="1" [consultaID]="12" [datasetsImported]="datasetsImported_1"
								[labelsImported]="labelsImported_1">
							</app-lista-mini>
							<!-- GRAFICA SOLAR -->
							<app-solar-mini *ngSwitchCase="13" [parametroID]="1" [consultaID]="13" [datasetsImported]="datasetsImported_2"
								[labelsImported]="labelsImported_2">
							</app-solar-mini>
							<!-- ==========  ASIGNATURAS  ========== -->
							<!-- GRAFICA BARRAS -->
							<app-barras-mini *ngSwitchCase="19" [parametroID]="1" [consultaID]="19" [datasetsImported]="datasetsImported_1"
								[labelsImported]="labelsImported_1">
							</app-barras-mini>
							<!-- GRAFICA RADIAL -->
							<app-radial-mini *ngSwitchCase="20" [parametroID]="1" [consultaID]="20" [datasetsImported]="datasetsImported_2"
								[labelsImported]="labelsImported_2">
							</app-radial-mini>
							<!-- ==========  ABANDONO  ========== -->
							<!-- GRAFICA SOLAR -->
							<app-solar-mini *ngSwitchCase="21" [parametroID]="1" [consultaID]="21" [datasetsImported]="datasetsImported_1"
								[labelsImported]="labelsImported_1">
							</app-solar-mini>
							<!-- GRAFICA LISTA -->
							<app-lista-mini *ngSwitchCase="22" [parametroID]="1" [consultaID]="22" [datasetsImported]="datasetsImported_2"
								[labelsImported]="labelsImported_2">
							</app-lista-mini>
						</ng-container>
					</div>
					<div class="layer"></div>
				</div>
			</div>
		</div>

		<!-- Sección con datos sociales -->
		<div class="container" *ngIf="this.tipoDatos == 'social'">
			<div *ngIf="surveysList.length !== 0" class="card-container justify-content-center">
				<div *ngFor="let survey of surveysList; let i = index" class="card-surveysList" (click)="openSurvey(survey)">
					<nb-card class="pointer mb-0 no-edge-card" nbTooltip="{{survey.title}}" nbTooltipPlacement="top">
						<nb-card-header class="border-bottom-0 p-0 header-select">
							<div class="d-flex justify-content-center align-items-center h-100">
								<img [src]="survey.image | imagen:'encuestas'" class="img-fluid" (error)="updateUrl($event)" *ngIf="networkSpeed >= 1">
								<img [src]="'../../assets/images/no-image.png'" class="img-fluid" *ngIf="networkSpeed < 1">
							</div>
						</nb-card-header>
						<nb-card-body>
							<div class="d-flex text-center justify-content-center align-items-center">
								<p class="m-0 surveysTitle">{{ survey.title }}</p>
							</div>
						</nb-card-body>
						<nb-card-footer class="border-top-0 text-center pt-0">
							<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;"
								[text]="survey.questions + ' Preguntas'">
							</nb-tag>
						</nb-card-footer>
					</nb-card>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>
