
import { Utils } from "./../../../utils/utils";
import {
	Component,
	ElementRef,
	HostListener,
	OnInit,
	Optional,
	ViewEncapsulation,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { User } from "src/app/core/models/users/user.models";
import { LoginService } from "src/app/core/services/login";
import { UserDataGeneralComponent } from "src/app/pages/user-data/user-data-general/user-data-general.component";
import { ImagenPipe } from "src/app/shared/pipes/imagen.pipe";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { UsersService } from "src/app/core/services/users";
import { Profiles } from "src/app/core/utils/profiles.enum";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ModalProfileActionsComponent } from "src/app/shared/components/modal-profile-actions/modal-profile-actions.component";
import { Store } from "@ngrx/store";
import { State } from "src/app/store/models/store.model";
import { availableProfiles } from "src/app/store/models/profiles.model";
import { MeetingsComponent } from "src/app/pages/meetings/meetings.component";
import { LocalStorage } from "src/app/core/utils";
import { MastersService } from "src/app/core/services/masters";
import { ControlPanelComponent } from '../../../../shared/components/control-panel/control-panel.component';
import { ControlPanelPadresComponent } from '../../../../shared/components/control-panel-padres/control-panel-padres.component';
import {
	NbWindowRef
} from "@nebular/theme";
import { ChatAssistantComponent } from "src/app/shared/components/chat-assistant/chat-assistant.component";
import { ControlPanelAdminComponent } from "src/app/shared/components/control-panel-admin/control-panel-admin.component";
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';
const NOIMAGE = "../../../../../assets/images/icons/account_circle.svg";

@Component({
    selector: "app-user-account",
    templateUrl: "./user-account.component.html",
    styleUrls: ["./user-account.component.scss"],
    providers: [ImagenPipe],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class UserAccountComponent implements OnInit {
	showUserAccount: boolean = false;
	user: User;
	profiles = Profiles;
	inside: boolean;
	actualProfile: string;
	private destroy$ = new Subject();
	profilesOfUser: availableProfiles;
	comeFromProfile: string;
	tutorialesActivos: boolean = false;

	@HostListener("document:mousedown", ["$event"])
	onGlobalClick(event): void {
		if (!this.eRef.nativeElement.contains(event.target)) {
			this.showUserAccount = false;
			this.destroy$.next(true);
		}
	}

	constructor(
		private loginService: LoginService,
		private modalService: NgbModal,
		private imagePipe: ImagenPipe,
		private utils: Utils,
		public usersService: UsersService,
		private store: Store<State>,
		private eRef: ElementRef,
		private localStorage: LocalStorage,
		private mastersService: MastersService,
		private masterService: MastersService,
		private swUpdate: SwUpdate,
		@Optional() private NbWindowRef: NbWindowRef
	) {
		this.user = this.loginService.getUser();
		this.localStorage.setItem("TutorialesON", this.tutorialesActivos);
	}

	ngOnInit() {
		this.store
			.select((store) => store.profiles)
			.subscribe((profiles) => {
				this.profilesOfUser = profiles;
			});
		this.store
			.select((store) => store.selectedProfile)
			.subscribe((selectedProfile) => {
				//this.actualProfile = selectedProfile["selectedProfile"];
				this.actualProfile = this.loginService.getProfile();
			});

		let lastSelectedProfile = localStorage.getItem("lastSelectedProfile");

		if (lastSelectedProfile != null) {
			if (
				lastSelectedProfile == this.profiles.Author &&
				this.profilesOfUser.profileEditor == true
			) {
				this.setProfile(this.profiles.Author);
			} else if (
				lastSelectedProfile == this.profiles.Teacher &&
				this.profilesOfUser.profileTeacher == true
			) {
				this.setProfile(this.profiles.Teacher);
			} else if (
				lastSelectedProfile == this.profiles.Student &&
				this.profilesOfUser.profileStudent == true
			) {
				this.setProfile(this.profiles.Student);
			} else if (
				lastSelectedProfile == this.profiles.Admin &&
				this.profilesOfUser.profileAdmin == true
			) {
				this.setProfile(this.profiles.Admin);
			}
			// else if (
			// 	lastSelectedProfile == this.profiles.Center &&
			// 	this.profilesOfUser.profileCenter == true
			// ) {
			// 	this.setProfile(this.profiles.Center);
			// }
		}
		//definimos variable para tutoriales
		this.masterService.setIdTutorialContext(98);
	}

	ngOnDestroy() {
		this.destroy$.next(true);
	}

	showMenuPanel() {
		if (!this.swUpdate.isEnabled) {
			console.log('Service Worker NO está habilitado. Asegúrate de estar en producción.');
			this.openModal();
		} else {
			this.swUpdate.checkForUpdate().then(isUpdateAvailable => {
				if (isUpdateAvailable) {
					if (confirm('Nueva versión disponible. Es necesario actualizar ahora, si no, podrían haber errores en algunas funciones. ¿Actualizar ahora?')) {
						window.location.reload();
					}
				}
			});
		}

		if (this.NbWindowRef) {
			this.NbWindowRef.close();
		}
		if (!this.showUserAccount) {
			this.showUserAccount = !this.showUserAccount;
		} else {
			this.showUserAccount = !this.showUserAccount;
			this.destroy$.next(true);
		}
	}

	openModal() {
		// Obtener la versión más reciente desde el archivo ngsw.json
		fetch('/ngsw.json', { cache: 'no-store' })
			.then(response => response.json())
			.then(data => {
				const latestVersion = data.assetGroups[0]?.version || '';

				// Obtener la versión guardada previamente en localStorage
				const currentVersion = localStorage.getItem('appVersion');

				console.log('Versión en caché:', currentVersion);
				console.log('Nueva versión detectada:', latestVersion);

				// Si la versión guardada no existe, la guardamos por primera vez
				if (!currentVersion) {
					localStorage.setItem('appVersion', latestVersion);
					return;
				}

				// Si la versión ha cambiado, mostrar el mensaje de actualización
				if (currentVersion !== latestVersion) {
					if (confirm('Nueva versión disponible. ¿Deseas actualizar ahora?')) {
						window.location.reload();
					}
				}

				// Actualizar la versión en localStorage
				localStorage.setItem('appVersion', latestVersion);
			})
			.catch(err => console.log('Error al obtener la versión:', err));
	}


	setProfile(profile) {
		if (profile == this.profiles.Author) {
			this.comeFromProfile = this.actualProfile;
		}
		if (
			profile == this.profiles.Teacher &&
			this.actualProfile == "ESTUDIANTE"
		) {
			localStorage.removeItem("selectedGroupInfo");
		}
		if (profile == this.profiles.Student && this.actualProfile == "PROFESOR") {
			localStorage.removeItem("selectedGroupInfo");
		}
		if (
			profile == this.profiles.Teacher &&
			this.comeFromProfile == "ESTUDIANTE"
		) {
			localStorage.removeItem("selectedGroupInfo");
		}
		if (
			profile == this.profiles.Student &&
			this.comeFromProfile == "PROFESOR"
		) {
			localStorage.removeItem("selectedGroupInfo");
		}
		localStorage.setItem("lastSelectedProfile", profile);
		this.loginService.setProfile(profile);
		this.showModalProfileActions();

		//Creamos el log
		const bodyLog = {
			action: "cambio perfil",
			actionData:
				"El usuario con id " +
				this.user.idUser +
				" ha activado el perfil " +
				profile +
				".",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	showGeneralSettings(): void {
		this.modalService.dismissAll();
		this.showUserAccount = false;
		let config = {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W80}`,
		};
		this.modalService.open(UserDataGeneralComponent, config);
		//Creamos el log
		const bodyLog = {
			action: "ajustes generales",
			actionData:
				"El usuario con id " +
				this.user.idUser +
				" ha entrado en los Ajustes Generales.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	getUserAvatar(): string {
		return this.utils.getUserAvatar(this.user.pictureUser);
	}

	logout(): void {
		//Creamos el log
		const bodyLog = {
			action: "logout",
			actionData:
				"El usuario con id " + this.user.idUser + " se ha deslogeado.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
		this.modalService.dismissAll();
		localStorage.removeItem("selectedGroupInfo");
		this.loginService.logout(true);
	}

	showModalProfileActions() {
		if (this.loginService.getProfile() != "CENTRO") {
			this.modalService.dismissAll();

			let dontLoadMenu = this.localStorage.getItem("dontLoadMenu");
			if (
				(this.utils.loadMenu === true && dontLoadMenu === "false") ||
				dontLoadMenu == null
			) {
				// Determinar la clase del modal según el perfil
				const modalClass =
					this.loginService.getProfile() === "PROFESOR"
						? MODAL_DIALOG_TYPES.W90
						: MODAL_DIALOG_TYPES.W40;

				this.modalService.open(ModalProfileActionsComponent, {
					scrollable: false,
					windowClass: modalClass,
				});
				this.showUserAccount = false;
				this.destroy$.next(true);
			} else {
				setTimeout(() => {
					localStorage.setItem("dontLoadMenu", "false");
					this.utils.loadMenu = true;
				}, 300);
			}
		}
	}

	sos() {
		this.modalService.open(MeetingsComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W80,
		});
		this.showUserAccount = false;
		this.destroy$.next(true);
	}

	showChatAssistant() {
		this.modalService.dismissAll();
		this.showUserAccount = false;
		this.destroy$.next(true);
		this.modalService.open(ChatAssistantComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W80,
		});
	}

	showControlPanel() {
		this.modalService.dismissAll();
		this.showUserAccount = false;

		this.modalService.open(ControlPanelComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});

		localStorage.setItem("lastSelectedProfile", this.profiles.Center);
		this.loginService.setProfile(this.profiles.Center);

		//==========================
		//===== Creamos el log =====
		//==========================
		const bodyLog = {
			action: "mando de control",
			actionData: "Ha entrado en el mando de control.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
		//==========================
		//==========================
	}

	showControlPanelAdmin() {
		this.modalService.dismissAll();
		this.showUserAccount = false;

		this.modalService.open(ControlPanelAdminComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});

		localStorage.setItem("lastSelectedProfile", this.profiles.Admin);
		this.loginService.setProfile(this.profiles.Admin);

		//Creamos el log
		const bodyLog = {
			action: "mando de control",
			actionData: "Ha entrado en el mando de control de administrador.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	showControlPanelParent() {
		this.modalService.dismissAll();
		this.showUserAccount = false;

		this.modalService.open(ControlPanelPadresComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});

		localStorage.setItem("lastSelectedProfile", this.profiles.Father);
		this.loginService.setProfile(this.profiles.Father);

		//Creamos el log
		const bodyLog = {
			action: "mando de control padres",
			actionData: "Ha entrado en el mando de control de padres.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	onToggleTutorialChange() {
		//console.log('Tutoriales activos:', this.tutorialesActivos);
		this.tutorialesActivos = !this.tutorialesActivos;
		this.localStorage.setItem("TutorialesON", this.tutorialesActivos);
	}
}
