<div class="well">
    <div class="row mt-0 mb-3">
        <div class="col pull-right">
            <button type="button" class="close">&times;</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <!--form (ngSubmit)="onSubmit()" #dataForm="ngForm" [formGroup]="form"-->
            <form [formGroup]="form">
                <div class="form-group align-items-center row my-0">
                    <label for="name"
                        class="col-sm-4 col-form-label col-form-label-sm">{{ 'QUIZFORMCOMPONENT.NODENAME' | translate }}</label>
                    <div class="col-sm-8">
                        <input class="form-control form-control-sm" type="text"
                            placeholder="{{ 'QUIZFORMCOMPONENT.TYPENODENAME' | translate }}" formControlName="name">
                    </div>
                </div>
                <div class="form-group align-items-center row mb-0 mt-1">
                    <label for="duration"
                        class="col-sm-4 col-form-label col-form-label-sm">{{ 'QUIZFORMCOMPONENT.DURATION' | translate }}</label>
                    <div class="col-sm-8">
                        <input class="form-control form-control-sm" type="time" formControlName="duration">
                    </div>
                </div>
                <div class="form-group align-items-center row mb-0 mt-1">
                    <label for="level"
                        class="col-sm-4 col-form-label col-form-label-sm">{{ 'QUIZFORMCOMPONENT.LEVEL' | translate }}</label>
                    <div class="col-sm-8">
                        <input class="form-control form-control-sm" type="number" formControlName="level">
                    </div>
                </div>
                <div class="form-group align-items-center row mb-0 mt-0">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm"
                            for="language1">{{ 'QUIZFORMCOMPONENT.LANGUAGE1' | translate }}</label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <select class="form-control form-control-sm" formControlName="language1">
                            <option *ngFor="let language of languages" [ngValue]="language.id">
                                    {{ language.valor }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group align-items-center row mb-0 mt-1">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm"
                            for="language2">{{ 'QUIZFORMCOMPONENT.LANGUAGE2' | translate }}</label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <select class="form-control form-control-sm" formControlName="language2">
                            <option *ngFor="let language of languages" [ngValue]="language.id">
                                    {{ language.valor }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record && record.author">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm"
                            for="author">{{ 'QUIZFORMCOMPONENT.AUTHOR' | translate }}</label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">{{record.author.firstName}}</label>
                    </div>
                </div>
                <div class="form-group align-items-center row mb-0 mt-1">
                    <label for="ordinal"
                        class="col-sm-4 col-form-label col-form-label-sm">{{ 'QUIZFORMCOMPONENT.ORDINAL' | translate }}</label>
                    <div class="col-sm-8">
                        <input class="form-control form-control-sm" type="number" formControlName="ordinal">
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record && record.creationDateString">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm"
                            for="creation">{{ 'QUIZFORMCOMPONENT.CREATION' | translate }}</label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">{{record.creationDateString}}</label>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record && record.lastEditionDateString">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm"
                            for="edition">{{ 'QUIZFORMCOMPONENT.EDITION' | translate }}</label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">{{record.lastEditionDateString}}</label>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record && record.idOriginal">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm"
                            for="id">{{ 'QUIZFORMCOMPONENT.ID' | translate }}</label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">{{record.idOriginal}}</label>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record && record.idPattern">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm"
                            for="id">{{ 'QUIZFORMCOMPONENT.IDPATTERN' | translate }}</label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">{{record.idPattern}}</label>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record"> <!-- OJO -->
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm"
                            for="id">{{ 'QUIZFORMCOMPONENT.COMPUCORRECT' | translate }}</label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">Yes</label>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record"> <!-- OJO -->
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm"
                            for="id">{{ 'QUIZFORMCOMPONENT.WRITABLE' | translate }}</label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">No</label>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record"> <!-- OJO -->
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm"
                            for="id">{{ 'QUIZFORMCOMPONENT.MULTIPLEXED' | translate }}</label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">Yes</label>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record"> <!-- OJO -->
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm"
                            for="id">{{ 'QUIZFORMCOMPONENT.CHILDREN' | translate }}</label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">3</label>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record && record.idfather"> <!-- OJO -->
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm"
                            for="id">{{ 'QUIZFORMCOMPONENT.IDFATHER' | translate }}</label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">{{record.idfather}}</label>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record && record.idmather"> <!-- OJO -->
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm"
                            for="id">{{ 'QUIZFORMCOMPONENT.IDMATHER' | translate }}</label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">{{record.idmather}}</label>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
