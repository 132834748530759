<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}"
			nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-header class="flex flex-column border-bottom-0">
		<div class="row">
			<div class="col-10 modal-title">
				<h4>{{ survey?.title }}</h4>
			</div>
		</div>
	</nb-card-header>
	<nb-card-body class="p-0">
		<div *ngIf="survey">
			<app-loading [isVisible]="cargando"></app-loading>

			<nb-card class="no-edge-card background_themecolor mb-0">
				<nb-card-body>
					<form [formGroup]="formulario" #thisForm="ngForm" class="row d-flex justify-content-center p-2">
						<div class="col-12 col-lg-3">
							<nb-card class="survey-info pointer background_themecolor no-edge-card mb-0 align-items-center">
								<div class="imagecontainer">
									<app-imgupload [isShowDelete]="true" formControlName="cpicture"></app-imgupload>
									<div class="text-center avatar-detail-course mb-2 d-flex align-content-center"
									[ngStyle]="{'background-image': 'url(../../../../assets/images/no-image.png)'}"></div>
								</div>
							</nb-card>
						</div>
						<div class="col-12 col-lg-7 my-4 my-lg-0">
							<nb-card class="mb-0 background_themecolor pointer no-edge-card ">
								<nb-card-header style="padding: 0;">
									<div class="form-group">
										<label for="exampleFormControlInput1">{{ 'EDITARCURSO.TITLE' | translate }}</label>
										<input nbInput fullWidth formControlName="title" type="text" id="exampleFormControlInput1"
											placeholder="Titulo" maxlength="100">
									</div>
								</nb-card-header>
								<nb-card-body style="padding: 0;">
									<div class="form-group">
										<label for="exampleFormControlTextarea1">{{ 'EDITARCURSO.DESCRIPCION' | translate }}</label>
										<textarea nbInput fullWidth formControlName="description" id="exampleFormControlTextarea1" rows="3"
											maxlength="500"></textarea>
									</div>
								</nb-card-body>
							</nb-card>
						</div>
						<div class="col-12 col-lg-2 my-4 my-lg-0">
							<nb-card class="mb-0 background_themecolor pointer no-edge-card">

								<h6 class="text-center mt-2">{{'MODALINFORMATIONCOURSE.AUTHOR' | translate}}:</h6>
								<div class="w-100 d-flex justify-content-center">
									<div class="text-center avatar-detail mb-2 d-flex align-content-center"
									[ngStyle]="{'background-image': getUserAvatar()}"></div>
								</div>
								<h6 class="text-center">{{survey.user.firstName}} {{survey.user.surname}}</h6>
							</nb-card>
						</div>
						<div class="row mt-4 cont-opt justify-content-center">
							<div class="flex cont-opt justify-content-center flex-lg-row flex-column">
								<nb-toggle labelPosition="start" *ngIf="id!='nuevo' && loginService.esAutor()"
									formControlName="visible">{{ formulario.get('visible').value
									? ('EDITARCURSO.VISIBLE' |
									translate) : ('EDITARCURSO.NOVISIBLE' | translate) }}</nb-toggle>
								<nb-toggle labelPosition="start" *ngIf="id!='nuevo' && loginService.esAutor()"
									formControlName="published">{{
									formulario.get('published').value ? ('EDITARCURSO.PUBLIC' |
									translate) : ('EDITARCURSO.PRIVATE' | translate) }}</nb-toggle>
							</div>
							<div class="flex cont-opt flex-lg-row flex-column">
								<nb-select name="lang" placeholder="{{'FILTER.LANGUAGE' | translate}}" formControlName="lang">
									<nb-option *ngFor="let lang of langList" [value]="lang.idLang">
										{{ lang.nombre }}
									</nb-option>
								</nb-select>
							</div>
							<div>
								<nb-icon class="pointer" style="color: red;" icon="trash-2-outline" ></nb-icon>
							</div>
						</div>
					</form>
				</nb-card-body>
			</nb-card>

			<nb-card class="no-edge-card">
				<nb-card-body>
					<div class="flex flex-row align-items-center mt-3" style="gap: 20px;">
						<div class="modal-title">
							<h2>{{ 'PATTERNS.QUESTIONS' | translate }}</h2>
						</div>
					</div>
					<div *ngIf="!viewList" class="row background_white d-flex justify-content-center py-4 px-2 min-h-100">
						<div class="col-12">
							<app-loading [isVisible]="cargando"></app-loading>
							<!-- TARJETAS PREGUNTAS -->
							<div *ngIf="questionsList.length !== 0">
								<div class="d-flex flex-column gap-5 justify-content-center">
									<div *ngFor="let question of questionsList" class="card-questions">
										<nb-card class="pointer mb-0 questions-info" (click)="openQuestion(question)">
											<nb-card-header class="p-2 d-flex justify-content-center align-items-center"
												nbTooltipPlacement="top">
												<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;"
												[text]="getQuestionType(question.type).title"
												[style.background]="getQuestionType(question.type).color"></nb-tag>
											</nb-card-header>
											<nb-card-body class="p-2 d-flex justify-content-center align-items-center">
												<p>{{ question.title }}</p>
											</nb-card-body>
										</nb-card>
									</div>
								</div>
							</div>
							<!-- NUEVA PREGUNTAS -->
							<div class="card-questions" style="margin-top: 3rem;">
								<nb-card class="pointer mb-0 questions-info" style="background-color: lightblue;"
									nbTooltip="{{ 'HEADER.OBJETO_NUEVAPREGUNTA' | translate }}" (click)="newQuestion()">
									<nb-card-body class="p-2 d-flex justify-content-center align-items-center">
										<nb-icon style="font-size: xxx-large;" class="icon-new" icon="plus-circle-outline"></nb-icon>
									</nb-card-body>
								</nb-card>
							</div>
						</div>
					</div>
				</nb-card-body>
			</nb-card>
		</div>
	</nb-card-body>
</nb-card>
