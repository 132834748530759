import { ProfileChange } from './../../models/users/profile-change.model';
import { map } from 'rxjs/operators';
import { User } from './../../models/users/user.models';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
// Utils
import { LocalStorage } from 'src/app/core/utils/local-storage';
// Models
import { LoginResponse } from 'src/app/core/models/users';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { LoginModel } from '../../models/users/login.model';
import { Profiles } from '../../utils/profiles.enum';
import { Estado, TutotrialDataLocal,  } from '../../models/tuturial/tutorial.model';
import { PrefixCountry } from '../../models/shared/prefixCountry.model';
import { LOCALSTORAGESTRINGS } from '../../models/masters/localstorage.enum';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/models/store.model';
import { ApiResponse } from '../../models/shared/api-response.model';
import { selectProfile } from 'src/app/store/actions/selected-profile.action';
const PREFIXCOUNTRIES = '../../../../assets/json/countries.json'          //-------------ESTE LISTADO TIENE A TODOS LOS PAISES------------------
const PREFIXCOUNTRIESv2 = '../../../../assets/json/countries_v2.json'

@Injectable()
export class LoginService {


    private usuario: User;
    private _token: string;

		public get token(): string {
			return this._token;
		}
		public set token(value: string) {
			this._token = value;
		}

    public currentProfile: BehaviorSubject<ProfileChange> = new BehaviorSubject<ProfileChange>(null);
    public currentProfile$: Observable<ProfileChange> = this.currentProfile.asObservable();
    public currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    public currentUser$: Observable<User> = this.currentUser.asObservable();
    public closeModalLogin = new Subject<boolean>();
    public tutorialUpdate: BehaviorSubject<TutotrialDataLocal> = new BehaviorSubject<TutotrialDataLocal>(null);
    public tutorialUpdate$: Observable<TutotrialDataLocal> = this.tutorialUpdate.asObservable();
    tutotrialDataLocal: TutotrialDataLocal = {
        idUser: 0,
        curso: {
            btnNuevo: false,
            btnPublicar: false,
            ideografo: false,
            editGrafo: false
        },
        canvasGrafo: {
            crearActividad: false,
            nodoNodo: false,
            quizQuiz: false,
        },
        quizData: {
            btnPublicar: false,
            quizQuiz: false
        },
        estudiante: {
            cambiarContenido: false
        },
        sideBarData: false
    };


	constructor(private router: Router, private localStorage: LocalStorage, private http: HttpClient,private store: Store<State>) {}

    login(login: LoginModel): Observable<LoginResponse> {
        const url:string = 'auth/login';
        return this.http.post<LoginResponse>(url, login);
    }

    getPhonePrefix(): Observable<PrefixCountry[]>{
        return this.http.get<PrefixCountry[]>(PREFIXCOUNTRIESv2).pipe(map(res =>{
            res.sort(
                (firstObject: PrefixCountry, secondObject: PrefixCountry) =>
                    (firstObject.name_en > secondObject.name_en) ? 1 :
                    (firstObject.name_en === secondObject.name_en) ? (
                        (firstObject.code > secondObject.code) ? 1 : -1 )
                        : -1
            )
           return res as PrefixCountry[]

        } ))
    }

		getPhonePrefixV2(): Observable<PrefixCountry[]>{
			return this.http.get<PrefixCountry[]>(PREFIXCOUNTRIESv2).pipe(map(res =>{
					res.sort(
							(firstObject: PrefixCountry, secondObject: PrefixCountry) =>
									(firstObject.name_en > secondObject.name_en) ? 1 :
									(firstObject.name_en === secondObject.name_en) ? (
											(firstObject.code > secondObject.code) ? 1 : -1 )
											: -1
					)
				 return res as PrefixCountry[]

			} ))
	}

    public validateUserLocalStorage() {
        // Tengo que validar que la session este abierta - PENDIENTE
         this.usuario =  JSON.parse(this.localStorage.getItem(LOCALSTORAGESTRINGS.USER)) as User;
				 const token:string = this.localStorage.getItem(LOCALSTORAGESTRINGS.TOKEN)

        if (token !== null) {
            return true;
        }

        return false;
    }

    public setUser(usuario: User) {
        this.usuario = usuario;
        this.setUserLocalStorage(this.usuario)
    }

    public setUserLocalStorage(usuario: User) {

        this.usuario = usuario;
        this.currentUser.next(this.usuario);
        this.usuario.keyWord = '-';
        this.localStorage.setItem(LOCALSTORAGESTRINGS.USER, JSON.stringify(this.usuario));
        this.comprobarTutorial();
    }

		public setToken(tokeType: string, token: string): void{
			this.localStorage.setItem(LOCALSTORAGESTRINGS.TOKEN, tokeType + ' ' + token)
		}

		public getToken(): string{
			return this.localStorage.getItem(LOCALSTORAGESTRINGS.TOKEN) as string
		}


    /**
     * return true -> Activa el flujo de informacion del tutorial
     * return false -> no hace nada.
     */
    comprobarTutorial() {
        if(this.usuario.tutorialSW.estado === Estado.PENDIENTE){
            this.setLocalTutorialJSON();
            return true;
        }
        return false;
    }

    setLocalTutorialJSON() {
        //comprobar si Tiene echo de antes
        this.existDataLocal()
        this.tutotrialDataLocal.idUser = this.usuario.idUser;
        this.localStorage.setItem(LOCALSTORAGESTRINGS.TUTORIALDATA, JSON.stringify(this.tutotrialDataLocal))
    }

    /**
     * setear en el localSotorage el tutorial
     */
    LocalTutorialJSON() {
        this.localStorage.setItem(LOCALSTORAGESTRINGS.TUTORIALDATA, JSON.stringify(this.tutotrialDataLocal))
    }
    /**
     * Si existe data local del tutorial en el navegador la reasignamos.
     * Si no existe o no es del usuario la data, ponemos todos los valores de inicio.
     */
    existDataLocal() {
        let tutorialLocal = this.localStorage.getItem(LOCALSTORAGESTRINGS.TUTORIALDATA);
        if (tutorialLocal !== undefined && tutorialLocal !== null ) {
            tutorialLocal =JSON.parse(tutorialLocal) as TutotrialDataLocal;
            if (tutorialLocal.idUser === this.usuario.idUser) {
                this.tutotrialDataLocal = tutorialLocal;
            }
        }
    }

    /**
     *
     * @param canvasGrafoTutorial actualiza la data del tutorial
     */
    updateDataLocalTutorial(canvasGrafoTutorial: TutotrialDataLocal) {
        this.tutotrialDataLocal = canvasGrafoTutorial;
        this.LocalTutorialJSON();
        this.tutorialUpdate.subscribe( res => {
            this.comprobarComppletado(res);
        })
    }

    /**
     * se comprueba si esta terminado el tutorial para finalizar y enviar al back para cambiar de estado PENDIENTE -> TERMINADO.
     */
    comprobarComppletado(res) {
        if(this.helpCursoCompleto() && this.helpCanvasLienzoCompleto()
            && !this.helpQuizCompleto() && this.helpGrafoEstudiante() && this.helpSidebar)
        {
            console.info('OK! AYUDA GUIADA FINALIZADA')
            this.cambiarEstadoTutorial().subscribe(res => {
                this.setTutorialComplete();
            }, err =>
            console.log(err)
            );
        }
        else {
        }
    }
    helpGrafoEstudiante() {
        return this.tutotrialDataLocal.estudiante.cambiarContenido? true: false;
    }

    helpSidebar() {
        return this.tutotrialDataLocal.sideBarData? true: false;
    }
    setTutorialComplete() {
        this.usuario.tutorialSW.estado = Estado.FINALIZADO;
        this.localStorage.setItem(LOCALSTORAGESTRINGS.USER, JSON.stringify(this.usuario));
        this.localStorage.removeItem(LOCALSTORAGESTRINGS.TUTORIALDATA);
    }

    //Metodo para Llamar al api y terminar el tutorial.
    cambiarEstadoTutorial() {
        const fecha = new Date(this.usuario.tutorialSW.fechaAlta);
        const añoActual = fecha.getFullYear();
        const mesActual = fecha.getMonth()+1;
        const diaActual = fecha.getDay();
        let mes = fecha.getMonth().toString().length == 1 ? `0${fecha.getMonth()+1}` : fecha.getMonth().toString();
        let dia = fecha.getDate().toString().length == 1 ? `0${fecha.getDate()+1}` : fecha.getDate().toString();
        let  fechaAlta = `${añoActual}-${mes}-${dia}`
        let url:string = `user/finalizartutorial?idUser=${this.usuario.idUser}&fechaAlta=${fechaAlta}`;
        let form: FormData = new FormData()
        return this.http.put<any>(url, form);
    }

    helpCursoCompleto() {
        return (
            this.tutotrialDataLocal.curso.btnNuevo &&
            this.tutotrialDataLocal.curso.btnPublicar &&
            this.tutotrialDataLocal.curso.ideografo && this.tutotrialDataLocal.sideBarData
            ? true: false);
    }
    helpCanvasLienzoCompleto() {
        return (
            this.tutotrialDataLocal.canvasGrafo.crearActividad &&
            this.tutotrialDataLocal.canvasGrafo.nodoNodo &&
            this.tutotrialDataLocal.canvasGrafo.quizQuiz
            ? true: false);
    }
    helpQuizCompleto() {
        return this.tutotrialDataLocal.quizData.btnPublicar? true: false;
    }

    getDataTutorial(): TutotrialDataLocal{
        return JSON.parse(this.localStorage.getItem(LOCALSTORAGESTRINGS.TUTORIALDATA)) as TutotrialDataLocal
    }

    private getUserLocalStorage() {
        if (this.usuario === undefined || this.usuario === null) {
            this.usuario = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.USER));
        }
    }

    public getUser(): User {
        return this.usuario;
    }

    public getProfile() {
        return this.usuario.profile;
    }

    public setProfile(newProfile: any, sendEvent:boolean = true ) {
        // Si el perfil ha cambiado
        if (this.usuario.profile !== newProfile) {
            this.usuario.profile = newProfile;
            this.currentProfile.next({profile: newProfile, sendEvent: sendEvent});
						this.store.dispatch(new selectProfile({ selectedProfile: newProfile }));
            this.setUserLocalStorage(this.usuario);
        }
    }

    public esPerfilVacio() {
        if(!this.usuario)
            return

        return this.usuario.profile === undefined || this.usuario.profile === null || this.usuario.profile === '';
    }

    public esEstudiante() {
        if(!this.usuario)
            return false
        return !this.esPerfilVacio() && this.usuario.profile.toUpperCase() === Profiles.Student;
    }

    public esPadre() {
        if(!this.usuario)
            return false
        return !this.esPerfilVacio() && this.usuario.profile.toUpperCase() === Profiles.Father;
    }

    public esProfesor() {
        if(!this.usuario)
            return false
        return !this.esPerfilVacio() && this.usuario.profile.toUpperCase() === Profiles.Teacher;
    }

    public esAutor() {
        if(!this.usuario)
            return false
        return !this.esPerfilVacio() && this.usuario.profile.toUpperCase() === Profiles.Author;
    }

    public esCentro() {
        if(!this.usuario)
            return false
        return !this.esPerfilVacio() && this.usuario.profile.toUpperCase() === Profiles.Center;
    }

    public esEmpleador() {
        if(!this.usuario)
            return false
        return !this.esPerfilVacio() && this.usuario.profile.toUpperCase() === Profiles.Employer;
    }

    public isGuestUser(){
        return localStorage.getItem('INVITADO') === 'true' ? true : false
    }

    public logout(redirectToLogin: boolean = true) {

    	this.localStorage.removeItem(LOCALSTORAGESTRINGS.USER);
			this.localStorage.removeItem(LOCALSTORAGESTRINGS.TOKEN)

			this._token = null

			if (redirectToLogin)
					this.router.navigate(['login']);
    }

		/**
	 * Return la version de la api como un string
	 * @param id
	 * @returns
	 */
	public getApiVersion() {
		const url = `commons/getapiversion`;
		return this.http.get<any>(url);
	}
}
