<div class="quiz-container" id="quiz" >

    <div class="header-options">
        <div class="col flex justify-content-between align-items-center px-4 py-3">
            <div>
                <div class="d-flex flex-column">
                    <label>{{'OPENQUIZPREVIEW.CODE' | translate}}</label>
                    <input #copiarText nbInput class="form-control" fullWidth type="text"  [(ngModel)]="quiz.id" readonly>
                    <button matSuffix mat-icon-button aria-label="Clear"  ngbTooltip="{{'OPENQUIZPREVIEW.COPYCODE' | translate}}"  (click)="coiparId(quiz.id)">
                      <mat-icon>content_copy</mat-icon>
                    </button>
                </div>
            </div>
            <div>
                <button  mat-icon-button (click)="closeModal()" >
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>





    <div class="body-container py-3 w-100 mt-4">

        <div class="w-100 h-100">
            <div class="pb-4">
                <div >
                    <app-preview-quiz-play-body-info class="mt-4" [quiz]="quiz" [timer$]="0"></app-preview-quiz-play-body-info>
                </div>
                <div class="mt-4">
                    <!-- <h4>{{'QUIZPLAY.DATA' | translate}}</h4> -->
                    <!-- <app-quiz-play-body-data-preview [questions]="questions"></app-quiz-play-body-data-preview> -->
                </div>
                <div class="mt-4">
                    <!-- <h4 class="mb-4">{{'QUIZPLAY.RESPONSE' | translate}}</h4> -->
                    <app-quiz-play-body-option-preview *ngIf="quiz" [options]="options"  [quiz]="quiz" [quizType]="quizType" [answered]="true" (saveOption)="onSaveOption($event)" (saveOptionText)="saveTxt($event)" [quiz]="quiz" [quizType]="quizType" [answered]="answered"
                    (reavelRespuesta)="discoverAnswer($event)"
                    ></app-quiz-play-body-option-preview>
                </div>

                <div class="mt-4">
                    <!-- <h4>{{'QUIZPLAY.ANSWER' | translate}}</h4> -->
                    <p><strong>{{'QUIZPLAY.ANSWER' | translate}}</strong></p>
                    <app-quiz-play-body-answer-preview [answers]="answers"></app-quiz-play-body-answer-preview>
                </div>
            </div>
        </div>
    </div>
</div>
