<form class="container" [formGroup]="dataForm">
    <ng-container formArrayName="questions">
        <div class="row" *ngFor="let question of questionsArray.controls; let i = index;">
            <div class="col d-flex align-items-center" [formGroupName]="i">
                <ng-container [ngSwitch]="questionsArrayType[i].value">
                    <div *ngSwitchCase="elementTypes.Q_TEXTS">
                        <p  [innerHTML]="questionsArrayData[i].value" class="card-img-top"   class="mb-3"></p>
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.Q_PICTURES">
                        <img class="mx-auto img-fluid" [style.width]="questionsArrayWidth[i].value ? questionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="questionsArrayHeight[i].value ? questionsArrayHeight[i].value + 'px' : '100%'" [src]="questionsArrayData[i].value| quizElements:'image' |safe" *ngIf="questionsArrayData[i].value" />
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.Q_VIDEOS">
                        <video class="w-100 mw-100" [style.width]="questionsArrayWidth[i].value ? questionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="questionsArrayHeight[i].value ? questionsArrayHeight[i].value + 'px' : '100%'" controls [src]="questionsArrayData[i].value | quizElements:'video' | safe" *ngIf="questionsArrayData[i].value">
                        </video>
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.Q_AUDIOS">
                        <audio class="w-100 d-block" controls [src]="questionsArrayData[i].value | quizElements:'audio' | safe" *ngIf="questionsArrayData[i].value">
                        </audio>
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.Q_PDFS">
                        <iframe #pdfElement class="w-100 h-100" [style.width]="questionsArrayWidth[i].value ? questionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="questionsArrayHeight[i].value ? questionsArrayHeight[i].value + 'px' : '100%'" [src]="questionsArrayData[i].value | quizElements:'pdf' | safe" allowfullscreen *ngIf="questionsArrayData[i].value" style="min-height: 920px;"></iframe>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</form>
