
<nb-card class="mb-0 no-edge-card m-auto max-w-900" #cardElement>
	<nb-card-header class="border-bottom-0 p-0">
		<app-quiz-play-multiple-body-info class="mt-4" [quiz]="quiz"
			[timer$]="time$" (timeEnded)="onTimeEnded($event)" [stopTime]="stopTime"></app-quiz-play-multiple-body-info>
	</nb-card-header>
	<div class="mt-4">
		<app-quiz-play-multiple-body-data [questions]="questions"></app-quiz-play-multiple-body-data>
	</div>
	<div class="mt-4" *ngIf="loginService.esEstudiante()">
		<app-quiz-play-multiple-body-option *ngIf="quiz" [options]="options" (saveOption)="onSaveOption($event)"
			(saveOptionMultiple)="saveOptionMultiple($event)" (emitAudio)="emitAudioFile($event)"
			(emitText)="emitText($event)" (emitAudioToText)="compararTexto($event)" (saveOptionText)="saveTxt($event)"
			[quiz]="quiz" [quizType]="quizType" [answered]="answered" [autoCorrection]="autoCorrection"></app-quiz-play-multiple-body-option>
	</div>
	<!-- TEXTO GRABADO CON MICRO -->
	<div class="row justify-content-center mt-3" *ngIf="this.textodeAudio !== '' || this.textodeAudio">
		<div class="col-md-8 col-12">
			<div class="cuadro-texto-micro">
				<p class="text-black">
					{{ this.textodeAudio === 'undefined' ? "No se ha podido grabar el audio con el micro" : this.textodeAudio }}
				</p>
			</div>
		</div>
	</div>

	<div class="mt-4" *ngIf="answered && answers.length && !challengeOn">
		<app-quiz-play-multiple-body-answer [answers]="answers"></app-quiz-play-multiple-body-answer>
	</div>

	<!-- Botón de scroll -->
	<nb-icon icon="arrowhead-down-outline" *ngIf="scrollBotton" class="scrollButton" (click)="scrollToBottom()" status="primary"></nb-icon>

	<nb-card-footer class="no-top-border py-5" id="footer">

		<div *ngIf="!answered && !hideCheckButton && !challengeOn; else checkedToggle" class="col-12 d-flex justify-content-center">
			<button nbButton class="btn-themecolor" nbTooltip="Mostrar Solución" nbTooltipPlacement="bottom"
				(click)="discoverAnswer()"><nb-icon icon="checkmark-circle-2"></nb-icon>{{'QUIZPLAYBODY.UPLOADANSWER' |
				translate}}</button>
		</div>
		<div *ngIf="!answered && !hideCheckButton && challengeOn; else checkedToggle" class="col-12 d-flex justify-content-center">
			<button nbButton status="success" nbTooltip="Mostrar Solución" nbTooltipPlacement="bottom"
				(click)="discoverAnswer()"><nb-icon icon="checkmark-circle-2"></nb-icon>{{'QUIZPLAYBODY.SAVERESPONSE' |
				translate}}</button>
		</div>
		<ng-template #checkedToggle>
			<div class="col-12 d-flex justify-content-center">

			</div>
		</ng-template>
		<!--<div *ngIf="mostrarMensajeTimeAcabado && loginService.esEstudiante()" class="alert alert-danger m-4"
			role="alert">
			{{'QUIZZES.DIALOGS.TIEMPOFUERA' | translate}}
		</div> -->
	</nb-card-footer>
</nb-card>
