import { Injectable } from '@angular/core';

import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class RegisterIconsService {

    private icons = ['add_info',
        'add_quizz',
        'add_multi_quizz',
        'search_info',
        'search_quizz',
        'sing',
        'column',
        'piscina',
        'summation',
        'logaritmo',
        'innovation',
        'maths',
        'check',
        'foco',
        'police',
        'function',
        'waves',
        'force',
        'justice',
        'red',
        'subconjunto',
        'congruente',
        'implica',
        'clock',
        'operators',
        'askforhelp',
        'view_nodes',
        'view_quizzes',
        'view_all',
        'stairs',
        'graph',
        'salware',
        'alumnos',
        'favorite',
        'profesores',
        'send',
        'delete',
        'edit',
				'LapizLineaBlanco',
        'save',
        'done',
        'favorito',
        'person',
        'lonely',
        'couple',
        'add',
        'pulling-rope',
        'person_search',
        'template',
        'template_add',
        'manual',
        'automatic',
        'information',
        'school',
        'fact_check',
        'calendar_today',
        'work_outline',
        'military_tech',
        'sports_esports',
        'contact_phone',
        'psychology',
        'settings',
        'business',
        'group',
        'groups',
        'location_city',
        'person',
        'escalator',
        'play_arrow',
        'cast_for_education',
        'record_voice_over',
        'work',
        'person_search',
        'fullscreen',
        'fullscreen_exit',
        'sports_esports_outlined',
        'edit_note',
        'manage_accounts',
        'author',
        'center',
        'father',
        'student',
        'teacher',
        'user',
        'power_settings',
        'emoji_emotions',
        'share',
        'print',
        'center_focus',
        'remove',
        'expand_less',
        'chevron_left',
        'chevron_right',
        'expand_more',
        'auto_stories',
        'visibility',
        'touch_app',
        'check_graph',
        'sos',
        'classroom',
        'book',
        'rule',
        'reavel_solution',
        'face',
        'account_circle',
        'menu_close',
        'menu_open',
        'clear_all',
        'arrow_forward',
        'buscar_curso',
        'automatico',
        'cursolibro',
        'esquema',
        'bocadillo',
        'mic',
        'act',
        'idea',
        'idea-act',
        'inte',
        'youtube',
				'spotify',
        'ideasvg',
        'inteidea',
        'search',
        'ideografo',
        'curso',
        'act_instantaneas',
        'ActividadCuadrado',
        'ActividadIdea',
        'IdeaVerde',
				'turtle',
        'AnadirIdea',
        'AnadirActividad',
        'AnadirActividadesMultiples',
        'content_paste',
        'TraerIdea',
        'TraerActividad',
        'Sticker',
        'insert_photo',
        'link_salware',
        'link',
        'content_copy',
        'informes',
        'asociar-blanco',
        'entender',
        'memorizar',
        'sos-picos',
        'repasar',
        'project-provisional',
        'filter_courses',
        'filter_icon',
        'instagram',
        'linkedin',
        'twitter',
        'warning',
        'quizcertificar',
        'certificarquizicon',
        'quiz_certificado',
        'person_add',
        'schedule_send',
        'notifications_active',
        'aceptar',
        'rechazar',
        'description',
        'lista',
        'west',
        'upload_file',
        'TreeIdea',
        'TreeQuiz',
        'QuizSimple',
        'QuizMulti',
        'ActividadInstantanea',
        'IconoXRevision',
				'equisCirculoBlanco',
        'IconoTickRevision2',
				'checknuevo',
        'tiktok',
        'download',
        'center_focus_white',
        'arrow_forward_white',
        'fullscreen_white',
        'flecha_fina_white',
        'actividad_icono_rectangular',
        'icon-excel',
        'desafios_icon',
        'Recorar-Baraja',
        'ProyectoComoMuchosGrafos',
				'ProyectoComoMuchosGrafos_green',
        'ProyectoComoMuchosGrafosWhite',
        'proyecto-3-grafos',
				'acercar_ideografo',
				'alejar_ideografo',
				'simbolo_centrar',
				'simbolo_empequenecer',
				'simbolo_pantalla_completa',
				'logo_sw',
				'logo_sw_white',
				'qm_preview',
				'play_outline',
				'actividad_barra',
				'CentrarMásEspacio',
				'danger-icon-color'
    ];

    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private http: HttpClient) { }

    public registerIcons(): void {
        this.loadIcons(Object.values(this.icons), 'assets/images/icons');
    }

    private loadIcons(iconKeys: string[], iconUrl: string): void {
        iconKeys.forEach(key => {
            this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
        });
    }
}
