<div class="row flex justify-content-center align-items-center">
    <div class="col-12">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table w-100" matSort  multiTemplateDataRows>
            <ng-container matColumnDef="identificador">
                <th mat-header-cell *matHeaderCellDef>{{'SEARCHACTIVITIES.ID' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.identificador}} </td>
            </ng-container>

            <ng-container matColumnDef="titulo">
                <th mat-header-cell *matHeaderCellDef>{{'SEARCHACTIVITIES.TITLE' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.titulo}} </td>
            </ng-container>
            <ng-container matColumnDef="resultado">
                <th mat-header-cell *matHeaderCellDef>{{'SEARCHACTIVITIES.RESULT' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{ element.escrito }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <mat-paginator [pageSize]="[5]"></mat-paginator>
    </div>
</div>
