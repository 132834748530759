import {initialOptions} from 'src/app/shared/components/nodes/vis-canvas/vis-canvas.model';

export interface uiState {
	footerBarOpen: boolean;
	discoverModeOn: boolean;
	discoverModeActualStep: number;
	discoverModeAllSteps: number[];
	visConfig: any;
	nodesVisibility: { nodes: boolean; quizzes: boolean };
}

export const initialUiState: uiState = {
	footerBarOpen: true,
	discoverModeOn: false,
	discoverModeActualStep: 0,
	discoverModeAllSteps: [],
	visConfig: initialOptions,
	nodesVisibility: { nodes: true, quizzes: true },
};
