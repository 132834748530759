import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { OptionsModel } from 'src/app/core/models/surveys/options.model';
import { QuestionModel } from 'src/app/core/models/surveys/question.model';
import { SurveyModel } from 'src/app/core/models/surveys/survey.model';
import { LoginService } from 'src/app/core/services/login';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { SurveysService } from 'src/app/core/services/surveys/surveys.service';
import { UsersService } from 'src/app/core/services/users';
import { MatomoAnalyticsUtils } from 'src/app/core/utils/matomo-analytics.utils';
import { Utils } from 'src/app/core/utils/utils';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';

interface NavigatorWithConnection extends Navigator {
  connection?: {
    downlink: number;
  };
}
@Component({
  selector: 'app-surveys-details',
  templateUrl: './surveys-details.component.html',
  styleUrls: ['./surveys-details.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SurveysDetailsComponent implements OnInit {
	idSurvey: number = 0;
	survey: SurveyModel = null;
	questionsList: QuestionModel[] = [];
	activeIndex: number | null = null;

	public formulario: UntypedFormGroup;
	surveyTitle: string = "";
	surveyDescription: string = "";
	viewList: boolean = false;
	cargando = false;
	questionsTypes = [
		{	id: 1, color: "#ff3d71", title: "Respuesta libre"	},
		{	id: 2, color: "#4ac9b4", title: "Selección única"	},
		{	id: 3, color: "#ffc94d", title: "Selección múltiple"},
	];
	langList = [
		{ idLang: 38,	nombre: "Español"	},
		{ idLang: 36,	nombre: "Ingles" },
		{ idLang: 31,	nombre: "Alemán" },
		{ idLang: 46,	nombre: "Francés" },
		{ idLang: 71,	nombre: "Italiano" },
		{ idLang: 127, nombre: "Portugués" },
	];
	profileList = [
		{ id: 2,	title: "Estudiante"	},
		{ id: 3,	title: "Profesor" },
		{ id: 4,	title: "Padre" },
	];
	questionEdited: boolean = false;
	networkSpeed = 1;

	constructor(
		public activeModal: NgbActiveModal,
		public surveysService: SurveysService,
		private formBuild: UntypedFormBuilder,
		public loginService: LoginService,
		private imagePipe: ImagenPipe,
		private utils: Utils,
		private modalService: NgbModal,
		private ma: MatomoAnalyticsUtils,
		public translateService: TranslateService,
		private toaster: ToasterService,
		private usersService: UsersService,
	){
		this.createForm();
	}

	ngOnInit(): void{
		this.networkSpeed = this.getNetworkSpeed(); //primero verificamos la velocidad de internet
		this.loadInitialSurveyData();
		this.loadQuestions();
	}

	getNetworkSpeed() {
		const navigatorWithConnection = navigator as NavigatorWithConnection;
		if (navigatorWithConnection.connection) {
			return navigatorWithConnection.connection.downlink;
		} else {
			return 10; // No se pudo obtener la velocidad de conexión
		}
	}

	closeModal(sendData?: any) {
		// Si no hay cambios en el formulario ni en las preguntas, cierra el modal directamente
		if (this.formulario.pristine && !this.questionEdited) {
			this.activeModal.close(sendData);
		} else {
			// Si hay cambios, guarda los datos y luego cierra el modal si la operación es exitosa
			this.editSurveyData().then(success => {
				if (success) {
					this.activeModal.close(sendData);
				}
			});
		}
	}

	createForm() {
		this.formulario = this.formBuild.group({
			title: [
				"",
				[
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100),
				],
			],
			description: [
				"",
				[
					Validators.required,
					Validators.minLength(10),
					Validators.maxLength(500),
				],
			],
			lang: ["", [Validators.required]],
			published: [false],
			visible: [false],
			destinationProfile: ["", [Validators.required]],
			image: ["", [Validators.required]],
		});
	}

	loadInitialSurveyData(){
		this.surveyTitle = this.survey.title;
		this.surveyDescription = this.survey.description;
		this.formulario.patchValue({
			title: this.survey.title,
			description: this.survey.description,
			lang: this.survey.idLanguage,
			published: this.survey.published ? true : false,
			visible: this.survey.visible === 1 ? true : false,
			destinationProfile: this.survey.destinationProfile,
			image: this.survey.image ? this.imagePipe.transform(this.survey.image, "encuestas")	: "",
		});
	}

	getUserAvatar(): string {
		return this.utils.getUserAvatar(this.survey.user.pictureUser);
	}

	getQuestionType(id: number): any | undefined {
		const questionType = this.questionsTypes.find(qt => qt.id === id);
		return questionType ? questionType : undefined;
	}

	loadQuestions(){
		this.surveysService.getAllQuestionsListBySurvey(this.idSurvey).subscribe(res => {
			if (res.data != null && res.data.length > 0) {
				this.questionsList = res.data;
			} else {
				this.newQuestion();
			}
		});
	}

	newQuestion(){
		let question : QuestionModel = new QuestionModel(
			0,
			this.idSurvey,
			1,
			this.translateService.instant('HEADER.OBJETO_NUEVAPREGUNTA'),
			1,
			this.questionsList.length + 1,
			"",
			"",
			5
		);
		//creamos y agregamos la opcion por defecto
		let option : OptionsModel = new OptionsModel(
			0,
			0,
			1,
			this.translateService.instant('ENCUESTAS.OPTIONNEW'),
		);
		question.options = [option];
		//agregamos la pregunta a la encuesta
		this.questionsList.push(question);
		this.onEditQuestion(true);
	}

	onQuestionClicked(index: number): void {
		// Solo cambia el estado si se hace clic en un componente diferente
		if (this.activeIndex !== index) {
		this.activeIndex = index;
		}
	}

	onDeleteQuestion(questionToDelete: any): void {
		this.questionsList = this.questionsList.filter(question => question !== questionToDelete);
		for (let index = 0; index < this.questionsList.length; index++) {
			const element = this.questionsList[index];
			element.ordinal = index + 1;
		}
	}

	onEditQuestion(edit: boolean): void {
		this.questionEdited = edit;
	}

	onMoveQuestion(change: string): void {
		let pos = change.split(':').map(Number);
		// Intercambiamos los elementos
		[this.questionsList[pos[0] - 1], this.questionsList[pos[1] - 1]] = [this.questionsList[pos[1] - 1], this.questionsList[pos[0] - 1]];
		//actualizamos el ordinal
		let ord1 = this.questionsList[pos[0] - 1].ordinal;
		let ord2 = this.questionsList[pos[1] - 1].ordinal;
		this.questionsList[pos[0] - 1].ordinal = ord2;
		this.questionsList[pos[1] - 1].ordinal = ord1;
		this.onEditQuestion(true);
	}

	editSurveyData(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			let survey: SurveyModel = new SurveyModel(
				this.idSurvey,
				this.surveyTitle,
				this.surveyDescription,
				this.formulario.value.lang,
				this.formulario.value.destinationProfile,
				this.formulario.value.published,
				this.formulario.value.visible,
			);
			survey.image = "";
			survey.questionsList = this.questionsList;
			//Si la encuesta no tiene imagen
			let image: File | string = "";
			if (this.survey.image == "" || this.survey.image == null) {
				if (typeof this.formulario.value.image === "object") image = this.formulario.value.image;
			} else {
				//Si la encuesta tiene imagen
				if (typeof this.formulario.value.image == "string" && this.formulario.value.image != "")
					survey.image = this.survey.image;
				else if (typeof this.formulario.value.image == "object") {
					survey.image = this.survey.image;
					image = this.formulario.value.image;
				} else if (typeof this.formulario.value.image == "string" && this.formulario.value.image == "") {
					survey.image = null;
				}
			}
			setTimeout(() => {
				this.surveysService.createUpdateSurvey(survey, image).subscribe(res => {
					if (res.data) {
						this.toaster.success(this.translateService.instant('ENCUESTAS.EDITOK'));
						resolve(true);
					} else {
						this.toaster.error(this.translateService.instant('ENCUESTAS.EDITKO'));
						resolve(false);
					}
				});
			}, 500);
		});
	}

	deleteSurvey(){
		this.surveysService.deleteSurvey(this.idSurvey).subscribe(res => {
			if(res.data){
				this.closeModal('delete');
			}
		});
	}

}
