import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/core/services/users/users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'src/app/core/services/login/login.service';
import { ModalCursosComponent } from 'src/app/shared/components/cursos/modal-cursos/modal-cursos.component';
import { UsersRegisterMapperService } from 'src/app/core/services/mapper/users-register-mapper.service';
import { UsersProfileValidationMapperService } from 'src/app/core/services/mapper/users-profile-validation-mapper.service';
import { UserRegisterModel } from 'src/app/core/models/users/user-register.model';
import { UserSettingsProfileModel } from 'src/app/core/models/users/user-settings-profile.model';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';

@Component({
    selector: 'app-body-tasks-options',
    templateUrl: './body-tasks-options.component.html',
    styleUrls: ['./body-tasks-options.component.scss']
})
export class BodyTasksOptionsComponent implements OnInit {
    colorHacerTareas: string;
    colorTodasTareas: string;
    userData: UserRegisterModel;
    usersSettingsProfileData: UserSettingsProfileModel;

    constructor(
        private modalService: NgbModal,
        public loginService: LoginService,
        private usersService: UsersService,
        private usersRegisterMapperService: UsersRegisterMapperService,
        private usersProfileValidationMapperService: UsersProfileValidationMapperService) { }

    ngOnInit() {
        this.colorHacerTareas = "darkgray"
        this.colorTodasTareas = "white"
        this.obtenerDatosUsuario();

    }

    private obtenerDatosUsuario() {

        this.usersService.getUserByIdUser(this.loginService.getUser().idUser)
            .subscribe(resp => {
                if (resp.data.users.nodes.length !== 0) {
                    // Mapeo los datos del usuario
                    this.userData = this.usersRegisterMapperService.transform(resp.data.users.nodes[0]);

                    // Los de las validaciones del usuario (si las tiene)
                    if (resp.data.users.nodes[0].usersProfileValidation.length > 0) {
                        // tslint:disable-next-line: max-line-length
                        this.userData.usersProfileValidation = this.usersProfileValidationMapperService.transform(resp.data.users.nodes[0].usersProfileValidation);
                    }
                }
            });
    }

    tareasParaHacer() {
        this.colorHacerTareas = "darkgray"
        this.colorTodasTareas = "white"
    }

    todasTareas() {
        this.colorHacerTareas = "white"
        this.colorTodasTareas = "darkgray"
    }

    verListadoCursos(opcion) {
        //$('.popover, .popover2').hide();
        // Voy a validar su acceso
        const resp = this.usersService.checkIfUserDataAreRegistered(this.userData, this.loginService.getProfile());

        if (!resp.result) {
            return false;
        }

        // Si soy un estudiante voy a ver que estoy validado
        if (this.loginService.esEstudiante()) {
            // const result = this.usersService.checkIfUserDataAreRegistered(this.loginService.getUser().idUser)
        }

        const modalRef = this.modalService.open(ModalCursosComponent,
            {
                scrollable: true,
                windowClass: MODAL_DIALOG_TYPES.W95,
            });

        // Lo registro en el log
        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Ver listado cursos - Sidebar`, 'OK');

        modalRef.componentInstance.opcion = opcion;

        modalRef.result.then((result) => {
        }, (reason) => {
        });
    }
}
