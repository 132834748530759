import { QuestionModel } from "./question.model";

export class SurveyModel{
	idSurvey: number;
	title: string;
	description: string;
	idLanguage: number;
	user: UserModel;
	creationDate: string;
	editDate: string;
	published: number;
	visible: number;
	questions: number;
	destinationProfile: number;
	image: string;
	questionsList?: QuestionModel[];

	constructor(
		idSurvey: number,
		title: string,
		description: string,
		idLanguage: number,
		destinationProfile: number,
		published?: number,
		visible?: number,
		questions?: number,
	){
		this.idSurvey = idSurvey || 0;
		this.title = title || "";
		this.description = description || "";
		this.idLanguage = idLanguage || 0;
		this.destinationProfile = destinationProfile || 2;
		this.published = published ? 1 : 0;
		this.visible = visible ? 1 : 0;
		this.questions = questions || 0;
	}
}

export interface UserModel {
	idUser: number;
	firstName: string;
	surname: string;
	pictureUser: string
}
