import { OptionsModel } from "./options.model";

export class QuestionModel{
	idSurveyQuestion: number;
	idSurvey: number;
	type: number;
	text: string;
	required: number;
	ordinal: number;
	initialTag: string;
	finalTag: string;
	likertOptions: number;
	options?: OptionsModel[];

	constructor(
		idSurveyQuestion: number,
		idSurvey: number,
		type: number,
		text: string,
		required: number,
		ordinal: number,
		initialTag: string,
		finalTag: string,
		likertOptions: number,
	){
		this.idSurveyQuestion = idSurveyQuestion || 0;
		this.idSurvey = idSurvey || 0;
		this.type = type || 0;
		this.text = text || "";
		this.required = required || 0;
		this.ordinal = ordinal || 0;
		this.initialTag = initialTag || "";
		this.finalTag = finalTag || "";
		this.likertOptions = likertOptions || 5;
	}
}
