
<div class="h-100" [ngClass]="showModalFormat ? userService.getColorByRol() : ''" style="overflow-y: auto;">

	<app-loading [isVisible]="cargando"></app-loading>
	<!-- COPILOT  -->
	<div>
		<ng-template class="w-100"  #step1>
			<div  class="copilot-custom copilop-accept" style="position: sticky;">
				<div class="row">
					<div class="col-12 flex justify-content-center align-items-center">
						<h3 class="title-accept row flex justify-content-center align-items-center">
							{{'TUTORIALINICIO.TUTORIAL_TITLE_01'| translate}}
						</h3>
					</div>
					<div class="col-12 row flex justify-content-center align-items-center">
							<p class="text-accept-help">
								{{'TUTORIALINICIO.TUTORIAL_TEXTO_01'| translate}}
							</p>
						</div>
				</div>
				<div  class="footer-btn">
					<div class="row  flex justify-content-center align-items-center">
						<div class="col-2">
							<button  class="btn btn-block btn-success btn-sm button-verde" (click)="nextStep(2)"  type="button">
								{{'TUTORIALINICIO.BOTONES.ACEPTAR'| translate}}
							</button>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
		<!-- <ng-template class="w-100"  #step3>
			<div  class="copilot-custom copilop-accept" style="position: sticky;">
				<div class="row row flex justify-content-center align-items-center">

					<div class="col-12 flex justify-content-center align-items-center">
						<h3 class="title-accept">
							{{'TUTORIALINICIO.TUTORIAL_TITLE_03'| translate}}

						</h3>
					</div>

					<div class="col-12 row flex justify-content-center align-items-center">
						<p class="text-accept-help">
								{{'TUTORIALINICIO.TUTORIAL_TEXTO_03'| translate}}
							</p>
					</div>

				</div>
				<div  class="footer-btn mb-2">
					<div class="row  flex justify-content-center align-items-center">
						<div class="col-2">
							<button  class="btn btn-block btn-success btn-sm button-verde" (click)="done()"  type="button">
									{{'TUTORIALINICIO.BOTONES.ACEPTAR'| translate}}
							</button>
						</div>
					</div>
				</div>
			</div>
		</ng-template> -->
		<ng-template class="w-100"  #step2>
			<div  class="copilot-custom copilop-info" style="position: sticky;">
				<div class="row flex justify-content-center align-items-center">

					<div class="col-12 row flex justify-content-center align-items-center">
						<h3 class="title-info">
								{{'TUTORIALINICIO.TUTORIAL_TITLE_02'| translate}}
						</h3>
					</div>
					<div class="col-12 row flex justify-content-center align-items-center">
						<p class="text-info-help">
								{{'TUTORIALINICIO.TUTORIAL_TEXTO_PUBLICAR'| translate}}
						</p>
					</div>

				</div>
				<div  class="footer-btn">
					<div class="row  flex justify-content-center align-items-center">
						<div class="col-2">
							<button  class="btn btn-block btn-themecolor btn-sm button-ok" (click)="helpCurso()"  type="button">
								{{'TUTORIALINICIO.BOTONES.ENTENDIDO'| translate}}
							</button>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
	<!-- END COPILOT  -->

	<div class="sticky-top sticky-top-header" *ngIf="showModalFormat">
		<div class="modal-header mr-4 ml-4 mt-4" align="center">
			<div class="btnGeneric-roleEditor d-flex align-items-center justify-content-center" (click)="activeModal.close('showListCourses')" [style.visibility]="id === 'nuevo' ? 'hidden' : 'visible' ">
                <mat-icon svgIcon="search"></mat-icon>
				{{'EDITARCURSO.COURSES' | translate}}
			</div>
			<div>
				<span class="textoFontSize1 colorTextoActivadoCursoGrafo font-weight-bold">{{ 'EDITARCURSO.CURSO' | translate }}</span>
			</div>
			<div>
				<button type="button" class="close" data-dismiss="modal" (click)="closeModal('')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
	</div>

	<div class="modal-body">

		<mat-progress-bar mode="determinate" value="{{progress}}" *ngIf="progress"></mat-progress-bar>

		<form [formGroup]="formCourse" #thisForm="ngForm"
			class="form-horizontal needs-validation mb-4" novalidate *ngIf="!cargando">

			<div class="row mt-4">
				<div class="col-12">
					<app-alert context="ModalEditarCurso"></app-alert>
				</div>
			</div>

			<div class="sticky-top">
				<div class="container">
					<div class="row flex justify-content-center mt-4 mt-md-2">
						<div class="col-12">
							<div class="row flex justify-content-center">
								<div class="col-sm-12 col-md-4 col-lg-4 col-xl-4  d-flex flex-column justify-content-between">
									<app-imgupload [isShowDelete]="true" formControlName="image"></app-imgupload>

									<div class="row flex mb-md-0 mb-4 mt-4" [ngClass]="{'justify-content-between': id!== 'nuevo'}">
											<div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-4 mt-sm-0">
													<div class="full-width">
															<select class="custom-select btn-block selectGeneric-roleEditor w-100"
																	id="validationCustom03" formControlName="lang1">
																	<option value="" [disabled]="true">{{
																			'EDITARCURSO.SELECCIONIDIOMA1' | translate }}</option>
																	<option *ngFor="let idioma of idiomas | sortBy:'asc':'language'"
																			[value]="idioma.idLanguage">{{ idioma.language }}</option>
															</select>
															<div *ngFor="let validation of validationMessages.lang1">
																	<div class="error-message mt-2"
																					*ngIf="formCourse.get('lang1')?.hasError(validation.type) && (formCourse.get('lang1')?.dirty || formCourse.get('lang1')?.touched || thisForm.submitted)">
																			{{validation.message}}</div>
															</div>
													</div>
											</div>
											<div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-4 mt-sm-0">
													<div class="full-width">
															<select class="custom-select btn-block selectGeneric-roleEditor w-100"
																	id="validationCustom05" formControlName="level">
																	<option value="" [disabled]="true">{{ 'EDITARCURSO.EDADESTIMADA' | translate }}</option>
																	<option *ngFor="let edad of edadEstimada" [value]="edad">{{ edad }}</option>
															</select>
													</div>
											</div>
									</div>




								</div>
								<div class="col-md-1 col-sm-12"></div>
								<div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
									<div class="form-row">
										<div class="full-width mb-3 mb-md-2 text-field">
											<input type="text" class="form-control inputGeneric-roleEditor"
												placeholder="{{ 'EDITARCURSO.TITLE' | translate }}"
												id="validationCustom01" formControlName="title">
                                            <div *ngFor="let validation of validationMessages.title">
                                                <div class="error-message mt-2"
                                                        *ngIf="formCourse.get('title')?.hasError(validation.type) && (formCourse.get('title')?.dirty || formCourse.get('title')?.touched || thisForm.submitted)">
                                                    {{validation.message}}</div>
                                            </div>
										</div>
										<div class="full-width mb-3 mb-md-2 textarea-field">
											<textarea class="form-control inputGeneric-roleEditor" style="height: 100px;"
												id="validationTextarea" formControlName="description"
												placeholder="{{ 'EDITARCURSO.DESCRIPCION' | translate }}"></textarea>
                                                <div *ngFor="let validation of validationMessages.description">
                                                    <div class="error-message mt-2"
                                                            *ngIf="formCourse.get('description')?.hasError(validation.type) && (formCourse.get('description')?.dirty || formCourse.get('description')?.touched || thisForm.submitted)">
                                                        {{validation.message}}</div>
                                                </div>
										</div>
										<div class="full-width mb-3 mb-sm-1 mb-md-1 ">
											<select style="width: -webkit-fill-available;"
												class="custom-select btn-block selectGeneric-roleEditor w-100"
												id="validationCustom02" formControlName="subject">
												<option value="" [disabled]="true">{{ 'EDITARCURSO.SELECCIONMATERIA' |
													translate }}</option>
												<option *ngFor="let materia of materias | sortBy:'asc':'subject'"
													[value]="materia.idSubject">{{ materia.subject }}</option>
											</select>
                                            <div *ngFor="let validation of validationMessages.subject">
                                                <div class="error-message mt-2"
                                                        *ngIf="formCourse.get('subject')?.hasError(validation.type) && (formCourse.get('subject')?.dirty || formCourse.get('subject')?.touched || thisForm.submitted)">
                                                    {{validation.message}}</div>
                                            </div>
										</div>

                                        <div class="full-width mb-3 mb-sm-1 mb-md-1 ">
                                            <div class="bottom-buttons d-flex flex-row justify-content-between pt-sm-2 mt-3 align-items-center">
                                                <div>
                                                    <button class="mr-2 btnDelete-roleEditor"
                                                        *ngIf="loginService.esAutor() && user.idUser === curso?.user?.idUser && id!='nuevo'"
                                                        (click)="borrarCurso()" type="button" [disabled]="saving">{{
                                                        'EDITARCURSO.ELIMINAR' | translate }}</button>
                                                    <button  (click)="savData()" class="btnSave" *ngIf="id=='nuevo'"
                                                        [disabled]="!formCourse.valid">{{ saving ? ('EDITARCURSO.SAVING' | translate) :
                                                        ('EDITARCURSO.GRABAR' | translate) }}</button>
                                                </div>
                                                <div copilot
                                                [copilot-template]="step2" [copilot-step]="2">
                                                    <mat-slide-toggle class="buttonPublicar"
                                                        *ngIf="id!='nuevo' && loginService.esAutor() && user.idUser === curso?.user?.idUser"
                                                        formControlName="published" (click)="publish($event)"
                                                        [labelPosition]="'before'"
                                                       >{{'EDITARCURSO.PUBLISH' | translate}}
                                                    </mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
									</div>
								</div>

								<div class="col-1 d-flex flex-column justify-content-center" *ngIf="curso">
									<div class="d-flex flex-column justify-content-center align-items-center text-center">
										<div class="avatar mb-2" [ngStyle]="{'background-image': getUserAvatar()}"></div>
										<span>{{curso.user.firstName}} {{curso.user.surname}}</span>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>

				<!-- GRAPHS	-->
				<!-- <div copilot [copilot-template]="step3" [copilot-step]="3"  class="col-1" ></div> -->
				<div class="container">

					<div *ngIf="id !='nuevo'" class="fondoMapas">
						<div class="form-control mt-4 divGrapos">
							<div class="row fondoMapas">
								<div class="col-12 my-2" align="center">
									<span class="textoFontSize1 colorTextoDesactivado font-weight-bold">{{'EDITARCURSO.GRAFOS' | translate }}</span>
								</div>
							</div>
                            <div>
                                <div class="mb-4 d-flex justify-content-between">
                                    <div class="d-flex justify-content-start align-items-start text-dark" >
                                        <div placement="top"  [ngClass]="{'active': graphView == 'gridList'}" class="circle-button mr-2" mat-icon-button (click)="graphView = 'gridList'" ngbTooltip="{{ 'CURSOS.VERMOSAICO' | translate }}">
                                            <mat-icon svgIcon="" class="icon-color-blue">apps</mat-icon>
                                        </div>
                                        <div placement="top"  [ngClass]="{'active': graphView == 'inlineList'}" class="circle-button ml-2" mat-icon-button (click)="graphView = 'inlineList'" ngbTooltip="{{ 'CURSOS.VERARBOL' | translate }}">
                                            <mat-icon svgIcon="lista" class="icon-color-blue"></mat-icon>
                                        </div>
                                    </div>
                                    <div
                                        class="circle-button mr-2"
                                        mat-icon-button
                                        ngbTooltip="{{ 'EDITARCURSO.ADDNEWGRAPH' | translate }}"
                                        *ngIf="loginService.esAutor() && user.idUser === curso?.user?.idUser && id!='nuevo'"
                                        placement="top" (click)="nuevoMapa()"
                                    >
                                        <mat-icon copilot [copilot-template]="step1" [copilot-step]="1" svgIcon="" class="icon-color-blue">add</mat-icon>
                                    </div>
                                </div>
                            </div>

                            <div>

                                <div [sortablejs]="courseTargets" [sortablejsOptions]="options" [ngClass]="graphView == 'gridList' ? 'row' : ''">
                                        <div
                                            (click)="verDetalleMapa(mapa.target.idTarget)"
                                            *ngFor="let mapa of courseTargets;let i=index"
                                            [ngClass]="graphView == 'gridList' ? 'col-6 col-md-2 pointer mb-2' : ''"
                                            [class.draggable]="loginService.esAutor() && user.idUser === curso?.user?.idUser ? true : false"
                                            [class.disabled]="loginService.esAutor() && user.idUser === curso?.user?.idUser ? false : true"
                                        >
                                            <!-- GRID LIST -->
                                            <div *ngIf="graphView == 'gridList'" >
                                                <div class="card form-group divCardGrafos">
                                                    <div class="img-container" ngbTooltip="{{mapa.target.description}}" placement="top">
                                                        <img [src]="mapa.target.backgroundImage | imagen:'mapas'"
                                                            (error)="updateUrl($event)" class="card-img-top"
                                                            alt="{{ mapa.target.tittle | titlecase}}" >
                                                        <img>
                                                    </div>
                                                    <div class="card-body">
                                                        <h6 class="card-title">{{ mapa.target.tittle | titlecase | truncate:[50,'...'] }}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- END GRID LIST -->

                                            <!-- LIST VIEW -->
                                            <div *ngIf="graphView !== 'gridList'">
                                                <div class="inline-list d-flex flex-row align-items-center mr-3 p-2 mb-2" ngbTooltip="{{mapa.target.description}}" placement="top">
                                                    <div class="square-image" [style.background-image]="getImageBackground(mapa.target.backgroundImage)"></div>
                                                    <h6 class="card-title ml-2">{{ mapa.target.tittle | titlecase | truncate:[20,'...'] }}</h6>
                                                </div>
                                            </div>
                                            <!-- END LIST VIEW -->

                                        </div>
                                </div>
                            </div>
						</div>
						<button class="" type="button" class="mt-4 btnGeneric text-button" (click)="nuevoMapa()"
							*ngIf="loginService.esAutor() && user.idUser === curso?.user?.idUser && id!='nuevo'">{{'EDITARCURSO.ADDNEWGRAPH' | translate}}</button>
					</div>
				</div>
                <!-- END GRAPHS -->
			</div>
			<app-loading [isVisible]="cargando"></app-loading>
		</form>
	</div>
</div>
