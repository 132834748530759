import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseModel } from 'src/app/core/models/courses';
import { CoursesService } from 'src/app/core/services/courses';
import { NodeService } from 'src/app/core/services/node/node.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { map } from 'rxjs/operators';
import { ModalEditarCursoMapaComponent } from '../../modal-editar-curso-mapa';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { LocalStorage } from 'src/app/core/utils';
import { TargetsService } from 'src/app/core/services/targets';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/utils/utils';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalAceptarCancelarComponent } from '../../../modal';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { ModalListadoEditoresComponent } from '../../modal-curso-edit-detail/modal-listado-editores/modal-listado-editores.component';
import { ModalEliminarEditorComponent } from '../../modal-curso-edit-detail/modal-eliminar-editor/modal-eliminar-editor.component';
import { User } from 'src/app/core/models/users/user.models';
import { LoginService } from 'src/app/core/services/login';
import { ModalEliminarCursoComponent } from '../../../modal/modal-eliminar-curso/modal-eliminar-curso.component';
import { ModalInformationCourseComponent } from '../../modal-information-course/modal-information-course.component';
import { AlertService } from 'src/app/core/services/shared';
import { PaisModel } from 'src/app/core/models/masters';

interface NavigatorWithConnection extends Navigator {
  connection?: {
    downlink: number;
  };
}

@Component({
  selector: 'app-detalles-cursos-editor',
  templateUrl: './detalles-cursos-editor.component.html',
  styleUrls: ['./detalles-cursos-editor.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DetallesCursosEditorComponent implements OnInit {

  idCourse: number = 0;
  curso: CourseModel = null; //datos del curso
  dataList: any[] = []; //listado de grafos
  cargando: boolean = false; //para spinner
  networkSpeed = 1; //velocidad de internet
  modeList: boolean = false; //para cambiar entre modo lista y modo grilla
  isMobile: boolean = false; //si el dispositivo es movil o escritorio
  selectedLabel = null; // Variable para guardar el elemento seleccionado
  idSelected: number = 0; // nodo = 1; quizSimple = 2; quizMulti = 3
  i_selected: number = null; //para saber el index del curso abierto
  j_selected: number = null; //para saber el index del target abierto

  //===== variables para editar nodos =====
  node: any;
  idCurso: number;
  idMapa: number;
  canEdit: boolean = false;
  course: CourseModel;
  isNewNode: boolean = false;
  //===== =====

  //===== variables para editar quizzes simples =====
  quiz: any;
  elements: any;
  //===== =====

  //===== variables para editar quizzes multiples =====
  quizFiles: any;
  quizzes: any;
  //===== =====

  user: User;

  constructor(
		public activeModal: NgbActiveModal,
		public coursesService: CoursesService,
		private nodeService: NodeService,
		private quizService: QuizzesService,
		private modalService: NgbModal,
		private localStorage: LocalStorage,
		public targetsService: TargetsService,
		public router: Router,
		private utils: Utils,    
		private formBuild: UntypedFormBuilder,
		private imagePipe: ImagenPipe,
		private toaster: ToasterService,
		public translateService: TranslateService,
		private toolsService: SigmaToolbarsService,
		private getDataService: GetDataService,
		public loginService: LoginService,
		private alertService: AlertService,
	) {}

  ngOnInit(){
    this.networkSpeed = this.getNetworkSpeed(); //primero verificamos la velocidad de internet
    this.getDataCourse(this.idCourse);
    this.user = this.loginService.getUser();
  }

  getNetworkSpeed() {
		const navigatorWithConnection = navigator as NavigatorWithConnection;
		if (navigatorWithConnection.connection) {
			return navigatorWithConnection.connection.downlink;
		} else {
			return 10; // No se pudo obtener la velocidad de conexión
		}
	}

  getUserAvatar(): string {
		return this.utils.getUserAvatar(this.user.pictureUser);
	}

  updateUrl(event: any) {
		event.target.src = "../../assets/images/no-image.png";
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

  getDataCourse(idCourse){
    this.cargando = true;
    this.coursesService.getCourseById(idCourse).subscribe(res => {
      if(res.data){
        this.curso = res.data.courses;
        this.getListadoGrafos(false);
      }
    })
  }

  getListadoGrafos(fromHide: boolean){
    this.cargando = true;
    this.coursesService.getListadoGrafosEditor(this.idCourse).subscribe(res => {
      if(res.data){
        this.dataList = res.data.children;
        if(fromHide){
          this.viewNodes(this.i_selected);
          if(this.j_selected != null){
            this.viewQuizzes(this.i_selected, this.j_selected);
          }
        }
        this.cargando = false;
      }
    })
  }

  /** ======================================================== */
  /** =============== INICIO LISTADO DE GRAFOS =============== */

  changeView(change: boolean){
    this.modeList = change;
    this.closeTree();
  }

  viewNodes(i: number){
		this.dataList[i].childrenVisible = !this.dataList[i].childrenVisible;
    for (let index = 0; index < this.dataList[i].children.length; index++) {
			this.dataList[i].children[index].childrenVisible = false;
		}
	}

  viewQuizzes(i: number, j: number){
    this.dataList[i].children[j].childrenVisible = !this.dataList[i].children[j].childrenVisible;
  }

  closeTree(){
		if(this.dataList != null && this.dataList.length > 0){
			for (let i = 0; i < this.dataList.length; i++) {
				this.dataList[i].childrenVisible = false;
				if(this.dataList[i].children != null && this.dataList[i].children.length > 0){
					for (let j = 0; j < this.dataList[i].children.length; j++) {
						this.dataList[i].children[j].childrenVisible = false;
					}
				}
			}
		}
	}

  viewData(indexTarget: number, indexNode: number, select: any){
    this.idSelected = 0; //Ocultamos el nodo que este abierto
    this.selectedLabel = select;
    this.i_selected = indexTarget;
    this.j_selected = select.type == 0 ? null : indexNode;
    //si type = 0 es un nodo
    if(select.type == 0){
      this.nodeService.getNode(select.id, this.idCourse, this.dataList[indexTarget].id)
        .pipe(map((res: any) => res.data[0]))
        .subscribe(res => {
          //obtenemos el curso y mostramos el nodo
          if(res){
            this.coursesService.getCourseById(this.idCourse)
              .subscribe(resp => {
                if(resp.data){
                  //===datos para el nodo===
                  this.node = res;
                  this.idCurso = this.idCourse;
                  this.idMapa = this.dataList[indexTarget].id;
                  this.canEdit = false;
                  this.course = resp.data.courses;
                  this.isNewNode = false;
                  //=== ===
                  this.idSelected = 1; //Para mostrar el nodo
                }
              })
          }
      })
    }
    //si type = 1 es un quiz simple
    else if(select.type == 1){
      this.quizService.getQuiz(select.id, this.idCourse, this.dataList[indexTarget].id)
        .subscribe(res => {
          if(res){
            this.quizService.getQuizFromTargetByModeList(this.idCourse, this.dataList[indexTarget].id, select.id)
              .subscribe(resp => {
                if(resp){
                  //===datos para el quiz simple===
                  this.quiz = {
                    ...res.quiz,
                    user: resp.data.user,
                    idOriginal: resp.data.idOriginal,
                    id: resp.data.id,
                    ordinal: resp.data.ordinal,
                    originalX: resp.data.originalX,
                    originalY: resp.data.originalY,
                    size: resp.data.size,
                    sizeQuiz: resp.data.sizeQuiz,
                    x: resp.data.x,
                    y: resp.data.y
                  };
                  this.elements = res.elements;
                  this.idCurso = this.idCourse;
                  this.idMapa = this.dataList[indexTarget].id;
                  //=== ===
                  this.idSelected = 2; //Para mostrar el quiz simple
                }
              })
          }
        })
    }
    //si type = 2 es un quiz multiple
    else if(select.type == 2){
      this.quizService.getQuizMultiple(select.id, this.idCourse, this.dataList[indexTarget].id)
			.subscribe(res => {
        if(res){
          this.quizService.getQuizFromTargetByModeList(this.idCourse, this.dataList[indexTarget].id, select.id)
          .subscribe(resp => {
            if(resp){
              //===datos para el quiz multiple===
              this.quiz = {
                ...res.quiz,
                user: resp.data.user,
                idOriginal: resp.data.idOriginal,
                id: resp.data.id,
                ordinal: resp.data.ordinal,
                originalX: resp.data.originalX,
                originalY: resp.data.originalY,
                size: resp.data.size,
                sizeQuiz: resp.data.sizeQuiz,
                x: resp.data.x,
                y: resp.data.y
              };
              this.elements = res.elements;
              this.quizFiles = res.quizFiles;
              this.quizzes = res.quizzes;
              this.idCurso = this.idCourse;
              this.idMapa = this.dataList[indexTarget].id;
              //=== ===
              this.idSelected = 3; //Para mostrar el quiz multiple
            }
          })
        }
      })
    }
  }

  hideData(hide: boolean){
    this.idSelected = 0; //para ocultar nodo o quiz
    this.selectedLabel = null;
    this.getListadoGrafos(hide);
  }

  verDetalleMapa(idMapa: number, translating: number) {
		if(translating == 0){
			const modalRef = this.modalService.open(ModalEditarCursoMapaComponent, {
				scrollable: true,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			});
			modalRef.componentInstance.id = idMapa;
			modalRef.componentInstance.curso = this.curso;
			modalRef.result.then(
				(result) => {
					if (result && result === "closeAll") {
						this.getDataCourse(this.idCourse);
						this.localStorage.setItem('lastTarget_Editor', idMapa);
						this.closeModal("Editar nodos mapa");
					} else if (result && result.msg === "fromCopy") {
						this.getDataCourse(result.idCurso);
					}
					else if (result) {
						this.getDataCourse(this.idCourse);
					}
				},
				(err) => {}
			);
		}
	}

  verNodosMapa(idTarget: any) {
    //primero obtenemos el target
    let target = null;
    this.targetsService.getCourseTargetByIdTarget(idTarget)
      .subscribe(res => {
        if(res.data){
          target = res.data.target;
          //si no es una prueba de control elminamos la variable global
          if(this.curso.project_type == 5){
            localStorage.setItem("proyectoControl", "true")
          } else{
            localStorage.removeItem("proyectoControl");
          }
          this.listaGrafosVisitados(target);
          // Verifica si la URL actual es la misma que la que intentas navegar
          const currentUrl = this.router.url;
          const targetUrl = `/course/${this.curso.idCourse}/graph/${target.idTarget}`;
          if (currentUrl === targetUrl) {
            localStorage.setItem('dontLoadMenu', 'true')
            this.utils.loadMenu = false;
            // Si la URL es la misma, realiza un reload
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate([`/course/${this.curso.idCourse}/graph/${target.idTarget}`])
                .then(() => {
                  this.closeModal("Editar nodos mapa");
                  this.activeModal.close("closeAll");
              });;
            });
          } else {
            localStorage.setItem('dontLoadMenu', 'true')
            this.utils.loadMenu = false;
            // Si la URL es diferente, navega normalmente
            this.router.navigate([`/course/${this.curso.idCourse}/graph/${target.idTarget}`])
                .then(() => {
                    this.closeModal("Editar nodos mapa");
                    this.activeModal.close("closeAll");
                });
          }
          let historialGrafos_editor = [];
          let localStoredHistory_editor = JSON.parse(
            localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHSHISTORY_EDITOR)
          );
          if (localStoredHistory_editor == null) {
            historialGrafos_editor.push(this.curso.idCourse);
          } else {
            if (localStoredHistory_editor.length > 15) {
              localStoredHistory_editor.shift(); // Elimina el primer elemento
            }
            localStoredHistory_editor.push(this.curso.idCourse);
          }
          historialGrafos_editor.push(this.curso.idCourse);
          this.localStorage.setItem(
            LOCALSTORAGESTRINGS.GRAPHSHISTORY_EDITOR,
            JSON.stringify(
              localStoredHistory_editor == null ? historialGrafos_editor : localStoredHistory_editor
            )
          );
          return;
        }
      })
	}

  listaGrafosVisitados(target: any) {
    // Obtenemos el array de los últimos grafos visitados.
    let graphVisited_Editor = JSON.parse(this.localStorage.getItem('graphVisited_Editor')) || [];
    const newElement = {
        title: target.tittle,
        idGrafo: target.idTarget,
        idCurso: this.curso.idCourse
    };
    // Validar si el nuevo elemento ya está en el array
    const isDuplicate = graphVisited_Editor.some(element =>
        element.idGrafo === newElement.idGrafo && element.idCurso === newElement.idCurso
    );
    // Si no es un duplicado, lo agregamos
    if (!isDuplicate) {
        // Si ya hay 10 elementos, eliminamos el ultimo.
        if (graphVisited_Editor.length >= 10) { // con esto determinamos el tamaño del array
            graphVisited_Editor.pop();
        }
        // Agregamos el nuevo elemento.
        graphVisited_Editor.unshift(newElement);
        // Actualizamos el local storage.
        this.localStorage.setItem('graphVisited_Editor', JSON.stringify(graphVisited_Editor));
    }
	}

  /** =============== FIN LISTADO DE GRAFOS =============== */
  /** ===================================================== */

}
