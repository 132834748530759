<nb-card class="no-edge-card mb-0 position-relative mx-sm-5 mx-2">
	<div class="closeModal">
		<nb-icon class="m-1 close-global" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>

	<nb-card-body>
		<h5 class="bright-green-color">
			{{ "GUIALOGIN.TITLE" | translate }}
		</h5>
		<h6><strong>{{ "GUIALOGIN.OPTION1TITLE" | translate }}</strong></h6>
		<p>{{ "GUIALOGIN.OPTION1TEXT" | translate }}</p>
		<h6><strong>{{ "GUIALOGIN.OPTION2TITLE" | translate }}</strong></h6>
		<p>{{ "GUIALOGIN.OPTION2TEXT" | translate }}</p>
		<h6><strong>{{ "GUIALOGIN.OPTION3TITLE" | translate }}</strong></h6>
		<p>{{ "GUIALOGIN.OPTION3TEXT" | translate }}</p>
	</nb-card-body>
</nb-card>
