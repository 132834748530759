<nb-card class="mb-0 no-edge-card" *ngIf="loginService.esProfesor()">
	<nb-card-header class="border-bottom-0 pb-0">
		<h2>{{ 'MENUACTIONS.TEACHERTITLE11' | translate }}</h2>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}"
			nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal()">
		</nb-icon>
	</div>
	<nb-card-body class="pt-0">
		<div class="d-flex p-4 w-100 flex-wrap justify-content-center align-content-center gap-5">
			<!-- PASAR LISTA -->
			<div class="item-style item-tiny" nbTooltip="{{ 'ASISTENCIA.SUBTITLE1' | translate }}"
				nbTooltipPlacement="top" (click)="pasarLista()" [ngClass]="{'nb-card-dark-overlay': timeLeft > 0}">
				<h6>{{ "ASISTENCIA.TITLE1" | translate }}</h6>
				<div class="item-image-tiny">
					<img src="../../../../../assets/images/control-panel/pasos.png" width="35" />
				</div>

				<div class="overlay-container" *ngIf="timeLeft > 0">
					<div class="overlay-text">
						{{timeLeftString}}
					</div>
				</div>
			</div>
			<!-- VER HISTORIAL -->
			<div class="item-style item-tiny" nbTooltip="{{ 'ASISTENCIA.TITLE2' | translate }}"
				nbTooltipPlacement="top" (click)="verHistorial()">
				<h6>{{ "ASISTENCIA.TITLE2" | translate }}</h6>
				<div class="item-image-tiny">
					<img src="../../../../../assets/images/control-panel/historial-de-asistencia.png" width="35" />
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>

<nb-card class="mb-0 p-4" *ngIf="loginService.esEstudiante()">
	<nb-card-header class="flex justify-content-between border-bottom-0">
		<div class="col-11 text-center">
			<h6>{{ "ASISTENCIA.TITLESTUDENT" | translate }}</h6>
			<h5 class="text-group">{{group.title}}</h5>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal()">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="mb-2 row justify-content-center gap-3">
			<button nbButton status="success" (click)="responderAsistencia(3)" *ngIf="presente">
				<nb-icon icon="checkmark-circle-2-outline"></nb-icon>
				Presente
			</button>
			<button nbButton status="warning" (click)="responderAsistencia(4)" *ngIf="!presente">
				<nb-icon icon="alert-triangle-outline"></nb-icon>
				Con retraso
			</button>
			<button nbButton status="danger" (click)="responderAsistencia(2)">
				<nb-icon icon="close-square-outline"></nb-icon>
				Ausente
			</button>
		</div>
	</nb-card-body>
</nb-card>
