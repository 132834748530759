export const MODAL_DIALOG_TYPES = {
    W30 : 'modal-dialog-30',
    W35 : 'modal-dialog-35',
    W40 : 'modal-dialog-40',
    W45 : 'modal-dialog-45',
    W50 : 'modal-dialog-50',
    W55 : 'modal-dialog-55',
    W60 : 'modal-dialog-60',
    W65: 'modal-dialog-65',
    W70: 'modal-dialog-70',
    W75: 'modal-dialog-75',
    W80: 'modal-dialog-80',
    W85: 'modal-dialog-85',
    W90: 'modal-dialog-90',
    W95: 'modal-dialog-95',
    W100 : 'modal-dialog-100',
    w100NEW : 'modal-dialog-100-nuevo'
}

export const MODAL_DIALOG_BORDER_TYPES = {
    W50_BORDER : 'modal-content-border',

}
