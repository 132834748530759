<div class='no-page-found-image'>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="mx-5 my-5">
                    <span class="mb-2 textoFontSize12 font-weight-bold" style="color: #FFFFFF">
                        {{ 'NOPAGEFOUND.HAOCURRIDOUNERROR' | translate }}
                    </span>
                    <hr>
                    <span class="mb-2 textoFontSize1" style="color: #FFFFFF">
                        {{ 'NOPAGEFOUND.LAPAGINANOEXISTE' | translate }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>