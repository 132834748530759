<nb-card class="mb-0">
	<nb-card-header class="border-bottom-0">
		<div class="col-11">
			<div>
				<!-- <h5>{{"TEXTSMODAL.ADDTEXTTOIDEA" | translate }}</h5> -->
				<h5>Cambia el orden en que se descubren las ideas</h5>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>
	<nb-card-body class="p-0">
			<div class="modal-body">
				<nb-card size="medium" class="mb-0 no-edge-card">
					<nb-card-body *ngIf="ideas">
						<ngx-sortable [items]="ideas" [name]="'Ideas'" [listStyle]="sortableStyles" (listSorted)="saveChanges($event)">
							<ng-template let-item="item">
									<div class="sortable-list-item">
										<strong> {{item.ordinalPower0}} | {{item.label}}</strong>
									</div>
						</ng-template>
          </ngx-sortable>
					</nb-card-body>
				</nb-card>
			</div>
	</nb-card-body>
	<nb-card-footer class="border-top-0">
	</nb-card-footer>
</nb-card>
