<div class="h-100 w-100 modal-body" id="node">
	<app-loading [isVisible]="isLoading"></app-loading>
		<div *ngIf="quiz && quiz.idMultiplexQuiz === 0" id="quiz" (mousemove)="showFiles(true)" (click)="showFiles(true)">
			<app-quiz-play-header  [modeAuto]="modeAuto" [hideBar]="hideBar" [quiz]="quiz" [idCurso]="idCurso" [idMapa]="idMapa" (close)="onClose()"></app-quiz-play-header>
			<div class="body-container px-4 hide-scrollbars" (scroll)="onScroll($event)" id="container">
				<div class="py-5 d-flex justify-content-center align-items-center">
					<button nbButton shape="round" outline status="info">{{indice + 1}} / {{sizeDeck}}</button>
				</div>
				<app-quiz-play-body [disabledButton]="disabledButton" [modeAuto]="modeAuto" [courseId]="idCurso" [graphId]="idMapa"
				[quiz]="quiz" [rawQuiz]="rawQuiz" [elements]="elements" [answered]="answered" [modeSocket]="false"
				(result)="onViewGif($event)"
				(notResponseGif)="hidenGif($event)"
				(answeredChange)="onAnswered()"
					(colorChange)="onColorChanged($event)">
				</app-quiz-play-body>
				<div *ngIf="nextButton" class="col-12 d-flex justify-content-center py-5">
					<button nbButton class="btn-themecolor" (click)="nextFlash()">{{'CURSOMODOAUTO.SIGUIENTE' | translate}}<nb-icon icon="arrow-forward-outline"></nb-icon></button>
				</div>
			</div>
		</div>
		<div *ngIf="quiz && quiz.idMultiplexQuiz === 1" id="quiz" (mousemove)="showFiles(true)" (click)="showFiles(true)">
			<app-quiz-play-multiple-header  [modeAuto]="modeAuto" [hideBar]="hideBar" [quiz]="quiz" [idCurso]="idCurso" [idMapa]="idMapa" (close)="onClose()"></app-quiz-play-multiple-header>
			<div class="body-container px-4 hide-scrollbars">
				<div class="py-5 d-flex justify-content-center align-items-center">
					<button nbButton shape="round" outline status="info">{{indice + 1}} / {{sizeDeck}}</button>
				</div>
				<app-quiz-play-multiple-body [disabledButton]="disabledButton" [modeAuto]="modeAuto"
						[courseId]="idCurso" [graphId]="idMapa" [quiz]="quiz"
						[elements]="elements" [answered]="answered"
						(result)="null"
						(notResponseGif)="hidenGif($event)"
						(answeredChange)="onAnswered()"
						(colorChange)="onColorChanged($event)">
				</app-quiz-play-multiple-body>
				<div *ngIf="nextButton" class="col-12 d-flex justify-content-center py-5">
					<button nbButton class="btn-themecolor" (click)="nextFlash()">{{'CURSOMODOAUTO.SIGUIENTE' | translate}}<nb-icon icon="arrow-forward-outline"></nb-icon></button>
				</div>
			</div>
		</div>
</div>
