<nb-card class="mb-0 no-edge-card background_themecolor modal-height-100-62">
	<nb-card-header class="px-3 py-0 flex flex-column border-bottom-0">

		<div class="row p-4 background_white">
			<div class="col-12 col-lg-6 modal-title px-2">
				<h4>{{'SOCKETQUIZPREVIEW.TITLE' | translate}} - {{group.title}}</h4>
			</div>

			<div class="col-12 col-lg-6 mt-3 mt-lg-0 pr-lg-5 d-flex align-items-center justify-content-end">
				<button nbButton class="btn-themecolor" size="small" class="mr-4">{{'SOCKETQUIZPREVIEW.PAUSE' | translate}}</button>
				<button nbButton class="btn-red" size="small" (click)="finalizeChallenge()">{{'SOCKETQUIZPREVIEW.FINALIZEQUIZZ' | translate}}</button>
			</div>
		</div>

		<div class="row px-3 py-2 mt-3">
			<div class="col-12 col-md-4 pb-3 pb-md-0">
				<div class="d-flex align-items-center justify-content-center justify-content-md-start h-100">
					<div *ngIf="cameFromCalendar == true" class="d-flex align-items-start gap-4">
						<h6>Este contenido fue asignado por el centro</h6>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-8">
				<div class="d-flex justify-content-between gap-4">
					<!-- TITULO -->
					<div class="d-flex flex-column align-items-center justify-content-center gap-1">
						<ng-container *ngIf="cameFromCalendar == false; else nameFromCalendar">
							<input type="text" nbInput class="form-control" fullWidth status="basic" fieldSize="small" [(ngModel)]="nameSession"
							style="text-align: center;" (ngModelChange)="this.nameSessionUpdate.next($event)">
							<label class="label-header m-0">
								Nombre del desafio
							</label>
						</ng-container>
						<ng-template #nameFromCalendar>
							<h6>{{ nameSessionFromCalendar }} </h6>
						</ng-template>
					</div>
					<!-- button for reporting modal -->
					<div class="d-flex align-items-start">
						<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
							nbTooltip="{{ 'INFORMES.REFRESH' | translate }}" nbTooltipPlacement="top">
							<div>
								<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
									(click)="setupReporting()">
									<nb-icon class="m-1" icon="refresh-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon>
								</button>
							</div>
							<label class="label-header m-0">
								{{ 'INFORMES.INFORME' | translate }}
							</label>
						</div>
					</div>
					<!-- ONLINE USER -->
					<div class="d-flex justify-content-center align-items-center" style="min-width: 70px;">
						<div class="pointer position-relative" [nbPopover]="templateRef" nbPopoverPlacement="bottom" (click)="prefillOfflineUsers()" [ngbTooltip]="'SOCKETQUIZPREVIEW.ONLINEUSERS' | translate">
							<span class="dot position-dot"></span>
							<nb-icon icon="people-outline" class="peopleOutline" [options]="{ animation: { type: 'zoom' } }"></nb-icon>
							<span>
								{{onlineUsers -1 }} / {{ usersInGroupCounter }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #templateRef>
			<nb-card [size]="'tiny'" accent="success" class="mb-0">
				<nb-card-header class="border-bottom-0">{{'SOCKETQUIZPREVIEW.ONLINESTUDENTS' | translate}}</nb-card-header>
				<nb-card-body>
					<div class="d-flex justify-content-center align-items-center h-100" *ngIf="usuariosOnline.length < 1">
						<p class="mb-0">
							{{'SOCKETQUIZPREVIEW.NOTONLINESTUDENTS' | translate}}
						</p>
					</div>
					<nb-list>
						<nb-list-item class="border-bottom-0 border-top-0" *ngFor="let user of usuariosOnline">
							<span class="dot"></span>{{user.firstName}} {{user.surname}}
						</nb-list-item>
					</nb-list>
				</nb-card-body>
			</nb-card>
			<nb-card [size]="'tiny'" accent="danger" class="mb-0 mt-1">
				<nb-card-header class="border-bottom-0">{{'SOCKETQUIZPREVIEW.OFFLINESTUDENTS' | translate}}</nb-card-header>
				<nb-card-body>
					<nb-list>
						<nb-list-item class="border-bottom-0 border-top-0" *ngFor="let user of usuariosOffline">
							<span class="dot-red"></span>{{user.firstName}} {{user.surname}}
						</nb-list-item>
					</nb-list>
				</nb-card-body>
			</nb-card>
		</ng-template>

	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal()" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>

	<nb-card-body class="position-static">
		<app-loading [isVisible]="cargando"></app-loading>
		<div class="d-flex w-100" *ngIf="showReport">
			<app-informes-centro
			[allDatasets]="allDatasets"
			[datasetsImported]="datasetsImported"
			[labelsImported]="labelsImported"
			[fromCalendar]="true">
			</app-informes-centro>
		</div>
		<div class="height-max w-100" *ngIf="!showReport">
			<div class="d-flex flex-column align-items-center justify-content-center h-100">
				<p class="mb-0">{{'LOADING.ESPERANDORESPUESTAS' | translate}}</p>
				<mat-spinner [diameter]="30"></mat-spinner>
			</div>
		</div>
	</nb-card-body>
</nb-card>
