import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbTooltipModule } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalStudentsListComponent } from '../modal-students-list/modal-students-list.component';
import { ModalTeachersListComponent } from '../modal-teachers-list/modal-teachers-list.component';
import { ModalCentrosComponent } from '../modal-centros/modal-centros.component';
import { FormsModule } from '@angular/forms';
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
	selector: "app-modal-create-structure",
	standalone: true,
	imports: [CommonModule, NbCardModule, NbIconModule, NbTooltipModule, TranslateModule, FormsModule, NbInputModule, NbButtonModule],
	templateUrl: "./modal-create-structure.component.html",
	styleUrls: ["./modal-create-structure.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})


export class ModalCreateStructureComponent {


	numberStudents: number = 0;
	numberTeachers: number = 0;
	creating: boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		public translateService: TranslateService,
		private modalService: NgbModal,
		private groupService: GruposService,
		private toaster: ToasterService,
	) {
		//this.inactivityService.startInactivityTimer();
	}

	createStructure() {
		this.creating = true
		this.groupService.createStructure(this.numberStudents, this.numberTeachers).subscribe((response) => {
			let errorCode = response.error.code;
			this.creating = false;
			if (errorCode == 0) {
				this.toaster.success(this.translateService.instant("STRUCTURE.STRUCTURECREATEDOK"));
				this.closeModal(response);
			}

			if (errorCode == 1 || errorCode == 2 || errorCode == 3) {
				this.toaster.error(response.error.msg
				);
				this.creating = false;
			}
		});
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	studentsList() {
		this.modalService.open(ModalStudentsListComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
	}

	teachersList(){
		this.modalService.open(ModalTeachersListComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
	}

	centersList(){
		this.modalService.open(ModalCentrosComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
	}
}
