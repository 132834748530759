import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-confirm-modal-tutorial.component',
    templateUrl: './confirm-modal-tutorial.component.html',
    styleUrls: ['./confirm-modal-tutorial.component.scss']
})
export class ConfirmModalTutorialComponent implements OnInit {
    mediaUrl: string;
    title: string;
    message: string;
    okButtonText?:string;
    koButtonText?:string;
    okButtonImage?:string;
    koButtonImage?:string

    constructor(
        public dialogRef: MatDialogRef<ConfirmModalTutorialComponent>,
        private translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string, picture: string, okButtonText?:string, koButtonText?:string, okButtonImage?:string, koButtonImage?:string }
    ) { }

    ngOnInit() {
       this.title = this.data.title;
       this.message = this.data.message;
       this.mediaUrl = this.data.picture;
       this.okButtonText = this.data.okButtonText || this.translateService.instant('TUTORIALINICIO.BOTONES.AUTOMATIC')
       this.koButtonText = this.data.koButtonText || this.translateService.instant('TUTORIALINICIO.BOTONES.OK')
       this.okButtonImage = this.data.okButtonImage || ''
       this.koButtonImage = this.data.koButtonImage || ''
    }

}
