<div class="fondoProfesor bg-autor" [ngClass]="userService.getColorByRol()">


<div class="container-fluid">


    <div  class="row d-flex justify-content-between m-0">
        <div></div>
        <div><h1 class="title m-0">{{'ASOCIACIONES.ASOCIACIONES' | translate }}</h1></div>
        <div>
            <button type="button" class="close" data-dismiss="modal" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="row">
      <div class="col-1 m-0 mb-2">
        <button *ngIf="canEdit"  ngbTooltip="{{'ASOCIACIONES.NEWASSOCIATION' | translate}}"  (click)="busquedaNodo()"
          class="btn btn btnDarkBlue d-flex justify-content-center align-items-center">
            <mat-icon svgIcon="link_salware"></mat-icon>
        </button>
      </div>
    </div>

    <div class="row d-flex justify-content-center align-content-center">
        <div class="col-12 divListInfo">

            <table class="table table-hover mt-0" >
                <thead>
                  <tr>
                    <th scope="col">
                      <span>
                        <mat-icon svgIcon="IdeaVerde"></mat-icon>
                      </span>
                      <span>
                        {{'ASOCIACIONES.IDEA' | translate }}
                      </span>
                    </th>
                    <th scope="col">
                      <span>
                        <mat-icon svgIcon="link_salware"></mat-icon>
                      </span>
                      <span>
                        {{'ASOCIACIONES.ASOCIACIADA' | translate }}
                      </span>
                    </th>
                    <th scope="col">
                      <span>
                        <mat-icon svgIcon="IdeaVerde"></mat-icon>
                      </span>
                      <span>
                        {{'ASOCIACIONES.CONIDEA' | translate }}
                      </span>
                    </th>
                    <th scope="col">
                      <span>
                        <mat-icon svgIcon="ideografo"></mat-icon>
                      </span>
                      <span>
                        {{'ASOCIACIONES.DELIDEOGRAFO' | translate }}
                      </span>
                    </th>
                    <th scope="col">
                      <span>
                        <mat-icon svgIcon="curso"></mat-icon>
                      </span>
                      <span>
                        {{'ASOCIACIONES.DELCURSO' | translate }}
                      </span>
                    </th>
                    <th scope="col">

                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of listAsst">
                    <th scope="row">
                        <div class="container h-100">
                            <div class="row h-100  align-items-center">
                                <div class="col-12 ">
                                    <img [src]="url+item.nodeOrg.image" class="img-fluid rounded-start" alt="{{item.nodeOrg.title}}"  (error)="updateUrl($event)">
                                </div>
                                <div class="col-12">
                                  <span class="text-center mt-1" >
                                    {{item.nodeOrg.title  | truncate:[10, '...']}}
                                  </span>
                                </div>
                              </div>
                        </div>
                    </th>

                    <td>
                        <div class="container h-100">
                            <div class="row h-100 d-flex align-items-center">
                                <span class="text-center text-dark" >
                                    {{item.label  | truncate:[10, '...']}}
                                  </span>
                            </div>
                        </div>
                    </td>

                    <td>
                      <div class="container  h-100 dataTable  pointer" (click)="openNode(item.nodeDes.idNode, item.target.idTarget, item.course.idCourse)">
                        <div class="row  h-100 align-items-center img-container">
                          <div class="col-5">
                            <div class="w-100">
                              <img  [src]="url+item.nodeDes.image" class="img-fluid rounded-start" alt="{{item.nodeDes.title}}"  (error)="updateUrl($event)">
                            </div>
                            <div class="w-100">
                              <span class="card-title pt-2">{{item.nodeDes.title  | truncate:[10, '...']}}</span>
                            </div>
                          </div>

                          <div class="col-6 ">
                              <span class="card-text"> {{item.nodeDes.description  | truncate:[100, '...']}}</span>
                          </div>
                        </div>
                      </div>


                    </td>

                    <td>
                      <div class="container h-100 dataTable  pointer" (click)="openGrafo(item.target.idTarget, item.course.idCourse)">
                        <div class="row h-100 align-items-center img-container">
                            <div class="col-5">
                                <div class="w-100 ">
                                    <img  [src]="item.target.image | imagen:'mapas'" class="img-fluid rounded-start" alt="{{item.target.image}}">
                                </div>
                                <div class="w-100 ">
                                    <span class="w-100 card-title card-title pt-2">{{item.target.title  | truncate:[10, '...']}}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <span class="card-text">
                                    {{item.target.description  | truncate:[100, '...']}}
                                </span>
                            </div>
                        </div>
                      </div>
                    </td>

                    <td>


                      <div class="container h-100 dataTable  pointer" (click)="openCursoEstudiante(item.course.idCourse)">
                        <div class="row h-100 align-items-center img-container">
                          <div class="col-5 flex align-items-center">
                            <div class="w-100">
                                <img  [src]="item.course.image | imagen:'cursos'" class="img-fluid rounded-start" alt="{{item.course.title}}">
                            </div>
                            <div class="w-100 pl-1">
                                <span class="w-100 card-title card-title">{{item.course.title  | truncate:[10, '...']}}</span>
                            </div>
                          </div>

                          <div class="col-6">
                              <span class="card-text">
                                {{item.course.description  | truncate:[100, '...']}}
                              </span>
                          </div>
                        </div>
                      </div>


                    </td>
                    <td *ngIf="canEdit && curremtUserId === item.idUser">

                      <div class="card-delete  dataTable  h-100">
                        <div class="row m-0 h-100 flex  align-items-center">
                          <div class="col p-0">
                            <span (click)="deleteAss(item)">
                              <mat-icon class="pointer hover" svgIcon="delete"></mat-icon>
                            </span>
                          </div>


                        </div>
                      </div>

                    </td>

                  </tr>
                </tbody>
              </table>
        </div>
    </div>
</div>
</div>
