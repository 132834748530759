export class UserProfileValidationModel {
    idUserProfileValidation: number;
    idUser: number;
    idUserStudentParent: number;
    idUserTeacherCenter: number;
    idUserEmployeerEnterprise: number;
    ip: string;
    uuid: string;
    temporaryPassword: string;
    messageSms: string;
    attempNumbers: number;
    sendDate: Date;
    sendDateString: string;
    validationDate: Date;
    validationDateString: string;
}



