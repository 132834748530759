<div>
	<app-loading [isVisible]="isLoading"></app-loading>
	<ng-container *ngIf="verTabla">
		<table id="myTable" class="table table-striped">
			<thead>
				<!--PRIMERA FILA: DATOS ESTUDIANTES-->
				<tr *ngFor="let row of rowStudent">
					<ng-container *ngFor="let celda of row; let i = index">
						<!--columna para ordenar bien los elementos al iniciar la tabla-->
						<th *ngIf="i == 0">1</th>
						<th [ngStyle]="{
														'background-color': celda.colorFondo,
														'text-align': 'center',
														'font-weight': '500',
														'color': celda.colorTexto}">
							<div *ngIf="i == 0">

							</div>
							<div *ngIf="i > 1 && !verFotos" class="avatar"
								[ngStyle]="{'background-image': getUserAvatar(celda.pictureUser)}"
								nbTooltip="{{celda.nameUser}}&nbsp;{{celda.surnameUser}}" nbTooltipPlacement="top"></div>
							<div *ngIf="i > 1 && verFotos" class="text-title-avatar" nbTooltip="{{celda.nameUser}}&nbsp;{{celda.surnameUser}}" nbTooltipPlacement="top">
								{{celda.nameUser}}&nbsp;{{celda.surnameUser}}
							</div>
						</th>
						<!--columna fantasma para poder buscar por nombre-->
						<!-- <th *ngIf="i == 0">-</th> -->
					</ng-container>
				</tr>
				<!--SEGUNDA FILA: PORCENTAJE DE ACIERTO-->
				<ng-container *ngIf="verAciertos">
					<tr *ngFor="let row of rowAciertos">
						<ng-container *ngFor="let celda of row; let i = index">
							<!--columna para ordenar bien los elementos al iniciar la tabla-->
							<th *ngIf="i == 0 && !verAciertos">2</th>
							<th [ngStyle]="{
															'background-color': '#b6c7fb',
															'text-align': 'center',
															'font-weight': '500',
															'color': '#ffffff',
															'max-width': '100px'
														}">
								<div *ngIf="i == 0">%&nbsp;{{'COMPETITION.ACIERTOS' | translate}}</div>
								<div *ngIf="i == 1">-</div>
								<div *ngIf="i > 1">{{ celda.porcentajeAciertosUser }}%</div>
							</th>
							<!--columna fantasma para poder buscar por nombre-->
							<!-- <th *ngIf="i == 0">-</th> -->
						</ng-container>
					</tr>
				</ng-container>
				<!--TERCERA FILA: TIEMPO TOTAL-->
				<tr *ngFor="let row of rowtiempo">
					<ng-container *ngFor="let celda of row; let i = index">
						<!--columna para ordenar bien los elementos al iniciar la tabla-->
						<th *ngIf="i == 0">3</th>
						<th [ngStyle]="{
															'background-color': celda.colorFondo,
															'text-align': 'center',
															'font-weight': '500',
															'color': celda.colorTexto
														}">
							<div *ngIf="i == 0">
								{{'GLOBALS.TOTALTIME' | translate}}
							</div>
							<div *ngIf="i == 1">
								-
							</div>
							<div *ngIf="i > 1" [title]="celda.tiempo">
								<p class="m-0">{{ formatoTiempo(celda.tiempo) }}</p>
							</div>
						</th>
						<!--columna fantasma para poder buscar por nombre-->
						<!-- <th *ngIf="i == 0">-</th> -->
					</ng-container>
				</tr>
			</thead>
			<tbody>
				<!--FILAS DE NODOS Y QUIZZES-->
				<tr *ngFor="let row of bodyTable" [ngClass]="{'sticky-row': row[1].isNode == 1 && !verAciertos, 'sticky-row-verAciertos': row[1].isNode == 1 && verAciertos  }">
					<ng-container *ngFor="let celda of row; let i = index">
						<!--columna para ordenar bien los elementos al iniciar la tabla-->
						<td *ngIf="i == 0">{{i + 4}}</td>
						<td *ngIf="i == 0" [ngStyle]="{
												'background-color': celda.colorFondo,
												'text-align': 'center',
												'font-weight': '500',
												'color': celda.colorTexto,
												'min-width': row[1] ? (!verActividades ? '120px' : '320px') : 'initial'
											}" [ngClass]="{'width-VerActividades': !verActividades, 'width-OcultarActividades': verActividades}">
							<div *ngIf="!verActividades">
								<p class="m-0" nbTooltip="{{celda.titleQuiz}}" nbTooltipPlacement="top">
									{{celda.titleQuiz}}
								</p>
							</div>
							<div *ngIf="verActividades">
								<p class="m-0">{{celda.titleQuiz}}</p>
							</div>
						</td>
						<!--columna fantasma para poder buscar por nombre-->
						<!-- <td *ngIf="i == 0">{{celda.titleQuiz}}</td> -->
						<td *ngIf="i == 1" [ngStyle]="{
												'background-color': '#b6c7fb',
												'text-align': 'center',
												'font-weight': '500',
												'color': '#ffffff',
												'max-width': '100px'
											}">
							<div *ngIf="celda.isNode == 1">
								{{ celda.titleQuiz }}
							</div>
							<div *ngIf="celda.isNode == 0">
								<div *ngIf="celda.porcentajeAciertoActividad < 100">
									&nbsp;{{ celda.porcentajeAciertoActividad }}%&nbsp;/&nbsp;{{ celda.totalRespuestas }}
								</div>
								<div *ngIf="celda.porcentajeAciertoActividad == 100">
									{{ celda.porcentajeAciertoActividad }}%&nbsp;/&nbsp;{{ celda.totalRespuestas }}
								</div>

							</div>
						</td>
						<td *ngIf="i > 1" [ngStyle]="{
																		'background-color': celda.colorFondo,
																		'text-align': 'center',
																		'font-weight': '500',
																		'color': celda.colorTexto
																	}" (click)="viewQuiz(celda)">
							<div *ngIf="celda.isNode == 1" [title]="celda.titleQuiz">
								<p class="m-0">{{ formatoTiempo(celda.titleQuiz) }}</p>
							</div>
							<div class="d-flex justify-content-center align-items-center flex-column gap-1" *ngIf="celda.isNode == 0"
								[title]="'--' + celda.nameUser + ' ' + celda.surnameUser + '--' + '\nAct: ' + celda.titleQuiz + '\nKnowledge: ' + celda.knowledge + '\nReps: ' + celda.repeticionesQuiz + '\nSOS: ' + celda.sos">
								<mat-icon *ngIf="celda.repeticionesQuiz > 0 && celda.colorFondo != '#61A1E9'" [svgIcon]="celda.result == 1 ? 'checknuevo' : 'equisCirculoBlanco'"></mat-icon>
								<mat-icon *ngIf="celda.repeticionesQuiz > 0 && celda.colorFondo == '#61A1E9'" svgIcon="act"></mat-icon>
								<div class="d-flex justify-content-center align-items-center flex-column gap-1"
									*ngIf="celda.repeticionesQuiz > 0 && verK">
									<p class="m-0 text-white" style="font-size: 12px; width: max-content;" >{{celda.knowledge}} / {{celda.repeticionesQuiz}}</p>
									<mat-icon class="custom-icon" *ngIf="celda.sos > 0 && verK" svgIcon="sos"></mat-icon>
								</div>
							</div>
						</td>
					</ng-container>
				</tr>
			</tbody>
		</table>
	</ng-container>
</div>
