import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AcceptDialogComponent } from '../accept-dialog/accept-dialog.component';
export interface AcceptDialogData {
  title?: string
  message: string
  url: string
}
export const PICTURE_URL = "imagen";
export const VID_URL = "video";
export const AUD_URL = "audio";
export const PDF_URL = "pdf";
export const TEXT_URL = "text";

@Component({
  selector: 'app-view-file-modal',
  templateUrl: './view-file-modal.component.html',
  styleUrls: ['./view-file-modal.component.scss']
})

export class ViewFileModalComponent implements OnInit {
  public DIR_VIDEO_URL = VID_URL;
  public DIR_AUDIO_URL = AUD_URL;
  public DIR_DOC_URL = PDF_URL;
  public DIR_PICTURE_URL = PICTURE_URL;
  public DIR_TEXT_URL = TEXT_URL;
  public title: string;
  public url: string;
  public message: string;
  isVideo: boolean = false;
  isAudio: boolean = false;
  isImagen: boolean = false;
  isPdf: boolean = false;

  constructor(
      public activeModal: NgbActiveModal
    ) {
   }

  ngOnInit() {
    if (this.title.toLowerCase() === this.DIR_VIDEO_URL.toLowerCase()) {
      this.isVideo = true;
    } else if(this.title.toLowerCase() === this.DIR_AUDIO_URL.toLowerCase()) {
      this.isAudio = true;
    } else if(this.title.toLowerCase() === this.DIR_PICTURE_URL.toLowerCase()) {
      this.isImagen = true;
    } else if(this.title.toLowerCase() === this.DIR_DOC_URL.toLowerCase()) {
      this.isPdf = true;
    }
  }
  closeModal(sendData?: any){
    this.activeModal.close(sendData);
  }

}
