import { Component, OnInit } from '@angular/core';

declare function init_plugins();

@Component({
    selector: 'app-home-layout',
    templateUrl: './home-layout.component.html',
    styleUrls: ['./home-layout.component.scss'],
    standalone: false
})
export class HomeLayoutComponent implements OnInit {

    constructor() {}

    ngOnInit() {
			init_plugins()
		}

}
