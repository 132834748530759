<div *ngIf="!familiaIdeas">
	<div class="px-4 py-3 flex justify-content-between">
		<mat-icon class="header-icon">cloud_upload</mat-icon>

		<button mat-icon-button (click)="onCloseModal()">
			<mat-icon>close</mat-icon>
		</button>
	</div>

	<div class="pt-2 pb-5 px-5">
		<div class="border border-primary p-5">
			<ng-container *ngIf="insert">
				<h5 class="mb-2">{{'QUIZHYPERMEDIA.CREATEFILE' | translate}}</h5>
				<div class="mb-3 flex">

					<div class="row">
						<ng-container *ngFor="let hypermedia of hypermediaNotFiles">
							<div class="col-2 col-sm-6 col-md-3 col-lg-2 col-xl-2">
								<button class="flex align-items-center hypermedia-button" mat-button
									(click)="onTypeSelected(hypermedia.type)">
									<mat-icon>{{hypermedia.icon}}</mat-icon>
									<p class="mb-0">{{hypermedia.name | translate}}</p>
								</button>
							</div>
						</ng-container>
						<div class="col-2 col-sm-6 col-md-3 col-lg-2 col-xl-2">
							<button (click)="grabarAudio()" class="flex align-items-center hypermedia-button" mat-button>
								<mat-icon>mic</mat-icon>
								<p class="mb-0">{{'QUIZZES.GRABAR' | translate}}</p>
							</button>
						</div>
						<div class="col-2 col-sm-6 col-md-3 col-lg-2 col-xl-2">
							<button (click)="grabarVideo()" class="flex align-items-center hypermedia-button" mat-button>
								<mat-icon>camera</mat-icon>
								<p class="mb-0">{{'QUIZZES.VIDEOCAMARA' | translate}}</p>
							</button>
						</div>
						<div class="col-2 col-sm-6 col-md-3 col-lg-2 col-xl-2">
							<button (click)="tomarFoto()" class="flex align-items-center hypermedia-button" mat-button>
								<mat-icon>camera</mat-icon>
								<p class="mb-0">{{'QUIZZES.FOTO' | translate}}</p>
							</button>
						</div>
					</div>
				</div>
			</ng-container>

			<h5 class="mb-2">{{'QUIZHYPERMEDIA.UPLOADFILE' | translate}}</h5>
			<div class="row flex">
				<ng-container *ngFor="let hypermedia of hypermediaFiles">
					<div class="col-2 col-sm-6 col-md-3 col-lg-2 col-xl-2" *ngIf="insert">
						<button class="flex align-items-center hypermedia-button" mat-button>
							<input [id]="hypermedia.type" type="file" [accept]="hypermedia.accept"
								(change)="onFileSelected($event, hypermedia.type)" />
							<label [for]="hypermedia.type" style="width: 100%; height: 100%; cursor: pointer;">
								<mat-icon>{{hypermedia.icon}}</mat-icon>
								<p class="mb-0">{{hypermedia.name | translate}}</p>
							</label>
						</button>
					</div>
					<ng-container *ngIf="!insert">
						<div class="col-2 col-sm-6 col-md-3 col-lg-2 col-xl-2">
							<button class="flex align-items-center hypermedia-button" mat-button
								(click)="onTypeSelected(hypermedia.type)">
								<mat-icon>{{hypermedia.icon}}</mat-icon>
								<p class="mb-0">{{hypermedia.name | translate}}</p>
							</button>
						</div>
					</ng-container>
				</ng-container>

			</div>

		</div>
	</div>
</div>


<div *ngIf="familiaIdeas">
	<div class="px-4 py-3 flex justify-content-between">
		<mat-icon>cloud_upload</mat-icon>

		<button mat-icon-button (click)="onCloseModal()">
			<mat-icon>close</mat-icon>
		</button>
	</div>

	<div class="pt-2 pb-5 px-5">
		<div class="border border-primary p-5">

			<h5 class="mb-2">{{'QUIZHYPERMEDIA.RESPONSETYPE' | translate}}</h5>
			<div class="row flex">
				<ng-container *ngFor="let hypermedia of hypermediasFamilia">
					<div class="col-2 col-sm-6 col-md-3 col-lg-2 col-xl-2">
						<button class="flex align-items-center hypermedia-button" mat-button
							(click)="onTypeSelected(hypermedia.type)">
							<!-- <input [id]="hypermedia.type" type="file" [accept]="hypermedia.accept" (click)="onTypeSelected(hypermedia.type)" /> -->
							<label [for]="hypermedia.type" style="width: 100%; height: 100%; cursor: pointer;">
								<mat-icon>{{hypermedia.icon}}</mat-icon>
								<p class="mb-0">{{hypermedia.name | translate}}</p>
							</label>
						</button>
					</div>
				</ng-container>
			</div>


		</div>
	</div>
</div>
