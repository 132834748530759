<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="flex border-bottom-0">
		<div class="col-10 modal-title">
			<h2 *ngIf="node.id !== 'temp'; else newNodeTitle">
				{{ "NODOS.NODE" | translate }}: {{ node.label }}
			</h2>
			<ng-template #newNodeTitle>
				<h2>
					{{ "NODOS.REGISTRARNODO" | translate }}
				</h2>
			</ng-template>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}"
			nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<!-- <div class="sticky-top-header w-100">
				<div class="tittle py-3">
					<div class="row d-flex justify-content-between align-items-center">
						<div class="col-12 text-center">
							<span *ngIf="node.id !== 'temp'; else newNodeTitle"
									class="font-weight-bold span-text-center">
								{{ 'NODOS.NODE' | translate }}: {{node.label}}
							</span>
							<ng-template #newNodeTitle>
								<span class="font-weight-bold span-text-center">
									{{'NODOS.REGISTRARNODO' | translate}}
								</span>
							</ng-template>
						</div>
					</div>
					<div data-dismiss="modal" ngbAutofocus (click)="closeModal()" class="close-button">
						<mat-icon>close</mat-icon>
					</div>
				</div>
			</div> -->

		<form [formGroup]="form">
			<div class="row">
				<div class="col-12 col-md-9">
					<div class="row">
						<!-- NODE IMAGE -->
						<div class="col-12 col-md-3 image-node">
							<app-imgupload [isShowDelete]="true" formControlName="image"></app-imgupload>
						</div>
						<!-- END NODE IMAGE -->
						<div class="col-12 col-md-7 mt-4 mt-md-0">
							<div class="row">
								<!-- TITLE -->
								<div class="col-12 mb-4">
									<div class="form-field row">
										<div class="col-8">
											<input #title nbInput fullWidth [placeholder]=" 'NODEFORMCOMPONENT.NODENAME' | translate "
												formControlName="name" [readonly]="!canEdit" maxlength="250" />
											<div *ngFor="let validation of validationMessages.title">
												<div class="text-danger mt-1"
													*ngIf="titleControl?.hasError(validation.type) && (titleControl?.dirty || titleControl?.touched)">
													{{ validation.message }}
												</div>
											</div>
										</div>
										<div class="col-2">
											<button type="button" class="btn btn-primary" (click)="generateQuizzes()">{{ "SALWIZAR.GENERATEACTIVITY" | translate }}</button>
										</div>
									</div>
								</div>
								<!-- END TITLE -->

								<!-- DESCRIPTION -->
								<div class="col-12">
									<div class="form-field">
										<textarea nbInput fullWidth rows="4" [placeholder]="'NODEFORMCOMPONENT.DESCRIPTION' | translate"
											formControlName="description" [readonly]="!canEdit" maxlength="500"></textarea>
									</div>
								</div>
								<!-- END DESCRIPTION  -->
							</div>
						</div>
						<div class="col-12 col-md-2 mt-4 mt-md-0">
							<div class="row gap-4">
								<!-- TOGGLE -->
								<div class="col-12 d-flex flex-column gap-3">
									<nb-toggle class="item-profile" *ngIf="canEdit" formControlName="published" (change)="publish($event)" nbTooltip='{{ "EDITARCURSO.TOGGLEONE" | translate }}' nbTooltipPlacement="top">
										<span class="text-center" style="line-height: 1rem; font-size: 12px;">
											{{
												form.get("published").value
												? ("EDITARCURSO.VISIBLE" | translate)
												: ("EDITARCURSO.NOVISIBLE" | translate)
											}}
										</span>
									</nb-toggle>
									<!-- <mat-slide-toggle *ngIf="canEdit" formControlName="published" (click)="publish($event)"></mat-slide-toggle> -->
									<nb-toggle class="item-profile" *ngIf="!canEdit" formControlName="published" nbTooltip='{{ "EDITARCURSO.TOGGLEONE" | translate }}' nbTooltipPlacement="top">
										<span class="text-center" style="line-height: 1rem; font-size: 12px;">
											{{
												form.get("published").value
												? ("EDITARCURSO.VISIBLE" | translate)
												: ("EDITARCURSO.NOVISIBLE" | translate)
											}}
										</span>
									</nb-toggle>

									<nb-toggle class="item-profile" nbTooltip='{{ "EDITARCURSO.TOGGLETWO" | translate }}' nbTooltipPlacement="top">
										<span class="text-center" style="line-height: 1rem; font-size: 12px;">
											{{ "EDITARCURSO.TEACHERSONLY" | translate }}
										</span>
									</nb-toggle>
									<!-- <mat-slide-toggle *ngIf="!canEdit" formControlName="published" ></mat-slide-toggle> -->
									<!-- END TOGGLE -->
								</div>

								<!-- POWERS -->
								<div class="col-12 d-flex">
									<button type="button" style="min-width: 120px;" size="small" nbButton [nbPopover]="templateRef" nbPopoverPlacement="bottom" disabled>
										{{ "EDITARCURSO.POWERS" | translate }}
									</button>
								</div>
								<!-- END POWERS -->

								<!-- DELETE -->
								<div class="col-12 d-flex flex-row">
									<button type="button" size="small" (click)="deleteNode()" *ngIf="loginService.esAutor()" nbButton status="danger">
										{{ "EDITARCURSO.ELIMINARIDEA" | translate }}
									</button>
								</div>
								<!-- END DELETE -->
							</div>
						</div>
					</div>
					<div class="row mt-4">
						<div class="col-12 container-files">
							<!-- YOUTUBE VIDEO-->
							<app-youtube-player [videoId]="videoId" [startSeconds]="startSeconds" [endSeconds]="endSeconds"
								*ngIf="youtubeSource"></app-youtube-player>
							<!-- END YOUTUBE VIDEO-->

							<!-- INSTAGRAM VIDEO -->
							<ng-container *ngIf="instagramSource">
								<iframe [src]="instagramSource" class="iframeVideo"></iframe>
							</ng-container>
							<!--END  INSTAGRAM VIDEO -->

							<!-- TIKTOK VIDEO -->
							<ng-container *ngIf="tiktokSource">
								<iframe [src]="tiktokSource" class="iframeVideo"></iframe>
							</ng-container>
							<!-- END TIKTOK VIDEO -->

							<!-- SPOTIFY AUDIO -->
							<ng-container *ngIf="spotifySource">
								<iframe [src]="spotifySource" class="iframeVideo"></iframe>
							</ng-container>
							<!-- END SPOTIFY AUDIO -->

							<!-- GENERIC MESSAGE -->
							<div *ngIf="format === '' && canEdit" class="d-flex no-data">
								<p>{{ "NODEDETAIL.SELECTFILE" | translate }}</p>
								<mat-icon svgIcon="arrow_forward"></mat-icon>
							</div>
							<!-- END GENERIC MESSAGE -->

							<!-- VIDEO -->
							<div class="full-video" *ngIf="videoSource">
								<video #videoElement controls [src]="videoSource | safe"></video>
							</div>
							<!-- END VIDEO -->

							<!-- IMAGE -->
							<div class="full-image" *ngIf="imageSource" [style.background-image]="'url(' + imageSource + ')'">
							</div>
							<!-- END IMAGE -->

							<!-- AUDIO -->
							<div class="h-100 flex align-items-start" *ngIf="audioSource">
								<audio class="w-100 d-block" [src]="audioSource | safe" controls></audio>
							</div>
							<!-- END AUDIO -->

							<!-- PDF -->
							<div *ngIf="pdfSource" class="h-100 p-0 d-flex align-items-center justify-content-center position-relative">
								<ng-container *ngIf="ext === 'docx'; else embedPdf">
									<ngx-doc-viewer [url]="pdfSource" viewer="office" class="w-100 h-100 position-relative"></ngx-doc-viewer>
								</ng-container>
								<ng-template #embedPdf>
									<embed *ngIf="sanitizedUrl" [src]="sanitizedUrl" class="w-100 h-100" />
								</ng-template>
							</div>
							<!-- END PDF -->

							<div *ngIf="format === 'text'" class="h-100">
								<app-node-detail-files-text-edit *ngIf="!node.rented" [(value)]="textSource"
									(valueChange)="saveText($event)"></app-node-detail-files-text-edit>
								<p *ngIf="node.rented" [innerHTML]="textSource"></p>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 col-md-3">
					<app-node-column-files [node]="node" [onSaveClicked$]="saveClicked$" [canEdit]="canEdit"
						(nodeFilesTypeChanged)="onNodeFilesTypeChanged($event)" (nodeFileAdded)="onNodeFileAdded($event)"
						(nodeFilePlayed)="onFilePlayed($event)">
					</app-node-column-files>
				</div>
			</div>
			<!-- POWERS -->
			<ng-template #templateRef>
				<ng-container *ngIf="canEdit || canEditPower">
					<div class="p-4">
						<div class="row mt-4 align-items-center">
							<div class="col-5">
								<!-- <mat-checkbox class="chkP2" formControlName="power3"></mat-checkbox> -->
								<nb-checkbox status="primary" formControlName="power3">
									<p class="chkP2">P<small>+3</small></p>
								</nb-checkbox>
							</div>
							<div class="col-7">
								<input nbInput fullWidth [placeholder]="'NODEDETAIL.ORDINAL' | translate" type="number"
									formControlName="ordinalPower3" maxlength="2" />
							</div>
						</div>

						<div class="row mt-3 align-items-center">
							<div class="col-5">
								<!-- <mat-checkbox class="chkP2" formControlName="power2">
							P<small>+2</small>

							</mat-checkbox> -->
								<nb-checkbox status="primary" formControlName="power2">
									<p class="chkP2">P<small>+2</small></p>
								</nb-checkbox>
							</div>
							<div class="col-7">
								<input nbInput fullWidth [placeholder]="'NODEDETAIL.ORDINAL' | translate" type="number"
									formControlName="ordinalPower2" maxlength="2" />
							</div>
						</div>

						<div class="row mt-3 align-items-center">
							<div class="col-5">
								<!-- <mat-checkbox class="chkP1" formControlName="power1">
							P<small>+1</small>
							</mat-checkbox> -->
								<nb-checkbox status="primary" formControlName="power1">
									<p class="chkP1">P<small>+1</small></p>
								</nb-checkbox>
							</div>
							<div class="col-7">
								<input nbInput fullWidth [placeholder]="'NODEDETAIL.ORDINAL' | translate" type="number"
									formControlName="ordinalPower1" maxlength="2" />
							</div>
						</div>

						<div class="row mt-3 align-items-center">
							<div class="col-5">
								<!-- <mat-checkbox class="chkP0" formControlName="power0">
							P<small>0</small>
							</mat-checkbox> -->
								<nb-checkbox status="primary" formControlName="power0">
									<p class="chkP0">P<small>0</small></p>
								</nb-checkbox>
							</div>
							<div class="col-7">
								<input nbInput fullWidth [placeholder]="'NODEDETAIL.ORDINAL' | translate" type="number"
									formControlName="ordinalPower0" maxlength="2" />
							</div>
						</div>

						<div class="row mt-3 align-items-center">
							<div class="col-5">
								<!-- <mat-checkbox class="chkPNegative1" formControlName="powerNegative1">
							P<small>-1</small>
							</mat-checkbox> -->
								<nb-checkbox status="primary" formControlName="powerNegative1">
									<p class="chkPNegative1">P<small>-1</small></p>
								</nb-checkbox>
							</div>
							<div class="col-7">
								<input nbInput fullWidth [placeholder]="'NODEDETAIL.ORDINAL' | translate" type="number"
									formControlName="ordinalPowerNegative1" maxlength="2" />
							</div>
						</div>

						<div class="row mt-3 align-items-center">
							<div class="col-5">
								<!-- <mat-checkbox class="chkPNegative2" formControlName="powerNegative2">
							P<small>-2</small>
							</mat-checkbox> -->
								<nb-checkbox status="primary" formControlName="powerNegative2">
									<p class="chkPNegative2">P<small>-2</small></p>
								</nb-checkbox>
							</div>
							<div class="col-7">
								<input nbInput fullWidth [placeholder]="'NODEDETAIL.ORDINAL' | translate" type="number"
									formControlName="ordinalPowerNegative2" maxlength="2" />
							</div>
						</div>

						<div class="row mt-3 align-items-center">
							<div class="col-5">
								<!-- <mat-checkbox class="chkPNegative2" formControlName="powerNegative3">
								P<small>-3</small>
								</mat-checkbox> -->
								<nb-checkbox status="primary" formControlName="powerNegative3">
									<p class="chkPNegative2">P<small>-3</small></p>
								</nb-checkbox>
							</div>
							<div class="col-7">
								<input nbInput fullWidth [placeholder]="'NODEDETAIL.ORDINAL' | translate" type="number"
									formControlName="ordinalPowerNegative3" maxlength="2" />
							</div>
						</div>
						<input type="hidden" formControlName="power" />

						<div *ngFor="let validation of validationMessages.power">
							<div class="text-danger mt-1" *ngIf="powerControl?.hasError(validation.type)">
								{{ validation.message }}
							</div>
						</div>
					</div>
				</ng-container>
			</ng-template>
			<!-- END POWERS -->
		</form>
	</nb-card-body>
</nb-card>
