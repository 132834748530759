import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-actividad-quiz-play-footer',
  templateUrl: './actividad-quiz-play-footer.component.html',
  styleUrls: ['./actividad-quiz-play-footer.component.scss']
})
export class ActividadQuizPlayFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
