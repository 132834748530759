<div class="bg-white h-100">
	<form [formGroup]="form" class="h-100">
		<!-- <quill-editor #editor [styles]="{height: '66.8vh'}" formControlName="content" (onEditorCreated)="setFocus($event)">
        </quill-editor> -->

		<editor id="editor" formControlName="content" (onEditorCreated)="setFocus($event)" apiKey="{{tinyEditorApiKey}}"
			[init]="options" class="h-100">
		</editor>
	</form>
</div>
