<div class="w-100 h-100">
    <div class="pb-4">
        <div >
						<app-actividad-quiz-play-body-info class="mt-4" [quiz]="quiz" [timer$]="time$"
							(timeEnded)="onTimeEnded($event)"> [stopTime]="stopTime"></app-actividad-quiz-play-body-info>
        </div>
        <div class="mt-4">
            <!-- <h4>{{'QUIZPLAY.DATA' | translate}}</h4> -->
            <app-actividad-quiz-play-body-data [questions]="questions"></app-actividad-quiz-play-body-data>
        </div>
        <div class="mt-4">
            <!-- <h4 class="mb-4">{{'QUIZPLAY.RESPONSE' | translate}}</h4> -->
            <app-actividad-quiz-play-body-option *ngIf="quiz"
            [options]="options"
            (saveOption)="onSaveOption($event)"
            (saveOptionText)="saveTxt($event)"
            (emitText)="emitText($event)"
            (saveOptionMultiple)="saveOptionMultiple($event)"
            [quiz]="quiz" [quizType]="quizType"
            [answered]="answered"></app-actividad-quiz-play-body-option>
        </div>

        <div *ngIf="!answered" class="col-12 d-flex justify-content-center solution-button">
            <button mat-icon-button matTooltip="Mostrar solución" matTooltipClass="black-tooltip" class="circle-button d-flex justify-content-center" (click)="discoverAnswer()" [disabled]="answered">
                <mat-icon svgIcon="done" class="icon-color-blue" [ngClass]=" answered? 'hidden': 'visible' "></mat-icon>
            </button>
        </div>
        <div *ngIf="!answered" class="col-12 col-12 d-flex justify-content-center solution-button">
            <span class="comprobar">{{'QUIZPLAY.CHECK' | translate}}</span>
        </div>


        <div class="mt-4" *ngIf="answered">
            <p><strong>{{'QUIZPLAY.ANSWER' | translate}}</strong></p>
            <app-quiz-play-body-answer [answers]="answers"></app-quiz-play-body-answer>
        </div>

        <div class="row  mt-4 flex justify-content-center">
            <div class="col-4 col-sm-12 col-md-4 col-lg-4  flex align-items-center justify-content-center" *ngIf="quizType == quizTypes.TEXT || quiz.idMultiplexQuiz === 1">
                <button type="button" class="btnDelete  flex align-items-center justify-content-center" *ngIf="answered" [disabled]="disabledButton" (click)="successOrNotSuccessful(false)">
                    <mat-icon class="icon-color-blue">close</mat-icon><span class="span-icon">&nbsp;{{'QUIZPLAY.ERROR' | translate}}</span>
                </button>
            </div>
            <div *ngIf="answered " class="col-4 col-sm-12 col-md-4 col-lg-4"></div>

            <div class="col-4 col-sm-12 col-md-4 col-lg-4  flex align-items-center justify-content-center" *ngIf="quizType == quizTypes.TEXT || quiz.idMultiplexQuiz === 1">
                <button type="button" class="btnPlayGreen-cardTarget  flex align-items-center justify-content-center" *ngIf="answered" [disabled]="disabledButton" (click)="successOrNotSuccessful(true)">
                    <mat-icon class="icon-color-blue">done</mat-icon><span class="span-icon">&nbsp;{{'QUIZPLAY.DONE' | translate}}</span>
                </button>
            </div>
        </div>



    </div>
</div>
