import { Component, Input, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/models/store.model';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import { ReportingService } from '../service/reporting.service';
import { takeUntil, first } from 'rxjs/operators';
import { Subject } from "rxjs";
import { TranslateService } from '@ngx-translate/core';
Chart.register(zoomPlugin);

@Component({
  selector: 'app-graficos',
  templateUrl: './graficos.component.html',
  styleUrls: ['./graficos.component.scss'],
})
export class GraficosComponent implements OnInit {
	@Input() dataCharts_node: any[];
	@Input() dataCharts_quiz: any[];
	chart: any = null;
	private destroy$ = new Subject();
	profile: any;
	listLabels = [];
	listData_node = [];
	listData_quiz = [];
	currentLabel = 0;
	cantidadEstudiantes = 100;

	colores_act = [];
	colores_ideas = [];
	lastSevenDays = [];
	dayNames = [];

	totalDays = 7;

	constructor(
		private store: Store<State>,
		private reportingService: ReportingService,
		private translateService: TranslateService,
	) {
		this.chart = null;
	}

	ngOnInit() {
		if(this.chart){
			this.chart.destroy();
		}
		this.totalDays = 7;
		setTimeout(() => {
			this.suscribeReportingService(this.totalDays),
			900
		})

		//obtenemos el perfil que esta activo
		this.store.select(store => store.selectedProfile).pipe(takeUntil(this.destroy$)).subscribe((selectedProfile) => {
			this.profile = selectedProfile['selectedProfile']
		});
  }

	ngOnDestroy(){
		if(this.chart){
			this.chart.destroy();
		}
		this.reportingService.dataChartSusbcribe.unsubscribe;
	}

	suscribeReportingService(totalDays) {
		this.reportingService.dataChartSusbcribe.pipe(first()).subscribe((res: any) => {
			this.dataCharts_node = res.data[0];
			this.dataCharts_quiz = res.data[1];
			this.dataCharts_node.sort((a, b) => (a.name > b.name) ? 1 : -1); //lo ordenamos alfabeticamente
			this.dataCharts_quiz.sort((a, b) => (a.name > b.name) ? 1 : -1); //lo ordenamos alfabeticamente
			this.listData_node = this.dividirArray(this.dataCharts_node);
			this.listData_quiz = this.dividirArray(this.dataCharts_quiz);
			this.totalDays = totalDays;
			if(totalDays == 7) {
				this.graficaTiempoTrabajo_7dias();
			} else {
				this.graficaTiempoTrabajo();
			}
		});
	}


	/* ===== FUNCIONES PARA NAVEGAR ENTRE LOS GRUPOS DE ESTUDIANTES ===== */

	anterior(){
		this.currentLabel = this.currentLabel - 1;
		this.actualizarChart(this.currentLabel);
		//this.chart.data.labels = this.listLabels[this.currentLabel];
		//this.chart.update();
	}

	siguiente(){
		this.currentLabel = this.currentLabel + 1;
		this.actualizarChart(this.currentLabel);
		//this.chart.data.labels = this.listLabels[this.currentLabel];
		//this.chart.update();
	}

	/* ========== ========== ========== ========== ========== */

	/* ===== FUNCION PARA ORDENAR LOS GRUPOS DE ESTUDIANTES ===== */
	ordenarChart(order){
		switch (order) {
			case 'A-Z':
				this.dataCharts_node.sort((a, b) => (a.name > b.name) ? 1 : -1);				
				this.dataCharts_quiz.sort((a, b) => (a.name > b.name) ? 1 : -1);
				break;
			case 'Z-A':
				this.dataCharts_node.sort((a, b) => (a.name < b.name) ? 1 : -1);
				this.dataCharts_quiz.sort((a, b) => (a.name < b.name) ? 1 : -1);
				break;
			case '9-0':
				this.dataCharts_node.sort((a, b) => (a.totalTime < b.totalTime) ? 1 : -1);
				this.dataCharts_quiz.sort((a, b) => (a.totalTime < b.totalTime) ? 1 : -1);
				break;
			case '0-9':
				this.dataCharts_node.sort((a, b) => (a.totalTime > b.totalTime) ? 1 : -1);
				this.dataCharts_quiz.sort((a, b) => (a.totalTime > b.totalTime) ? 1 : -1);
				break;
		}
		this.listData_node = this.dividirArray(this.dataCharts_node);
		this.listData_quiz = this.dividirArray(this.dataCharts_quiz);
		if(this.totalDays == 7) {
			this.graficaTiempoTrabajo_7dias();
		} else {
			this.graficaTiempoTrabajo();
		}
	}

	/* ===== FUNCION PARA ELEGIR RANGO DE TIEMPO ===== */
	changeRangeTime(range){
		switch (range) {
			case 'Siempre':
				this.totalDays = 0;
				break;
			case '7days':
				this.totalDays = 7;
				break;
			case 'month':
				this.totalDays = 30;
				break;
			case 'year':
				this.totalDays = 365;
				break;
		}
		this.listData_node = this.dividirArray(this.dataCharts_node);
		this.listData_quiz = this.dividirArray(this.dataCharts_quiz);
		this.suscribeReportingService(this.totalDays);
	}
	/* ========== ========== ========== ========== ========== */

	actualizarChart(pag){
		//aqui sabemos cuantas barras seran
		const datasets = [];
		//metemos primero el dataset de la linea del promedio
		let data_aux = [];
		let sumaLis = [];
		for (let index = 0; index < this.dataCharts_node.length; index++) {
			const element = this.dataCharts_node[index].mediaMovil / 1000 / 60;
			sumaLis.push(element);
		}
		const sum = sumaLis.reduce((acc, curr) => acc + curr, 0) / this.dataCharts_node.length; //aqui metemos el promedio para pintar la linea
		data_aux = Array(this.cantidadEstudiantes).fill(sum);;
		const datasetLineaProm =	{
				label: this.translateService.instant("GLOBALS.AVERAGE") + ' (' + sum.toFixed(1) + ' min)',
				data: data_aux,
				borderColor: 'rgba(255, 81, 58, 1)',
				backgroundColor: 'rgba(255, 81, 58, 0.2)',
				type: 'line',
				order: 0,
				fill: 'origin',
				pointRadius: 0,
			};
		datasets.push(datasetLineaProm);

		for (let index = 0; index < this.dataCharts_node[0].totalTimePerDay.length; index++) {
			//usamos otro for para obtener los datos por dia
			const data = [];
			for (let index2 = 0; index2 < this.listData_node[pag].length; index2++) {
				const element = this.listData_node[pag][index2];
				data.push((element.totalTimePerDay[index] / 1000/ 60 / 7).toFixed(1));
			}
			const day = this.dayNames[this.lastSevenDays[index].getDay()];
			const colorObject = this.colores_act.find(color => color.id === day);
			const label = colorObject.label
			const backgroundColor = colorObject.backgroundColor;
			const borderColor = colorObject.borderColor;
			const newDataset = {
				label: label,
				backgroundColor: backgroundColor,
				borderColor: borderColor,
				borderWidth: 1,
				data: data,
			};
			datasets.push(newDataset);
		}
		this.chart.data.datasets = datasets;
		this.chart.data.labels = this.listLabels[pag];
		this.chart.update();
	}

	private dividirArray(array) {
    var subarrays = [];
    for (var i = 0; i < array.length; i += this.cantidadEstudiantes) {
        subarrays.push(array.slice(i, i + this.cantidadEstudiantes));
    }
    return subarrays;
	}

	graficaTiempoTrabajo_7dias() {
			this.destroyAllCharts();
			if (this.chart) {
				this.chart.destroy();
			}
			this.currentLabel = 0;
			//primero introducimos a los estudiantes
			let labels = [];
			for (let index = 0; index < this.dataCharts_node.length; index++) {
				const element = this.dataCharts_node[index];
				//labels.push(element.name + ' ' + element.surname);
				labels.push(element.name);
			}
			this.listLabels = this.dividirArray(labels);

			//ahora obtenemos los dias para saber el orden de las barras
			const today = new Date();
			this.lastSevenDays = [];
			for (let i = 6; i >= 0; i--) {
					const date = new Date(today);
					date.setDate(today.getDate() - i);
					this.lastSevenDays.push(date);
			}
			this.dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

			//ahora obtenemos los colores por cada dia
			this.colores_act = [{
				id: 'Monday',
				label: this.translateService.instant("CHARTS.MONDAY"),
				backgroundColor: 'rgba(174, 174, 234, 0.6)',
				borderColor: 'rgb(174, 174, 234, 1)'
			},
			{
				id: 'Tuesday',
				label: this.translateService.instant("CHARTS.TUESDAY"),
				backgroundColor: 'rgba(133, 133, 224, 0.6)',
				borderColor: 'rgba(133, 133, 224, 1)',
			},
			{
				id: 'Wednesday',
				label: this.translateService.instant("CHARTS.WEDNESDAY"),
				backgroundColor: 'rgba(93, 93, 213, 0.6)',
				borderColor: 'rgba(93, 93, 213, 1)'
			},
			{
				id: 'Thursday',
				label: this.translateService.instant("CHARTS.THURSDAY"),
				backgroundColor: 'rgba(52, 52, 203, 0.6)',
				borderColor: 'rgba(52, 52, 203, 1)',
			},
			{
				id: 'Friday',
				label: this.translateService.instant("CHARTS.FRIDAY"),
				backgroundColor: 'rgba(42, 42, 162, 0.6)',
				borderColor: 'rgba(42, 42, 162, 1)',
			},
			{
				id: 'Saturday',
				label: this.translateService.instant("CHARTS.SATURDAY"),
				backgroundColor: 'rgba(178, 178, 178, 0.6)',
				borderColor: 'rgba(178, 178, 178, 1)'
			},
			{
				id: 'Sunday',
				label: this.translateService.instant("CHARTS.SUNDAY"),
				backgroundColor: 'rgba(102, 102, 102, 0.6)',
				borderColor: 'rgba(102, 102, 102, 1)'
			}];

			this.colores_ideas = [{
				id: 'Monday',
				label: this.translateService.instant("CHARTS.MONDAY"),
				backgroundColor: 'rgba(153, 214, 255, 0.6)',
				borderColor: 'rgb(153, 214, 255, 1)'
			},
			{
				id: 'Tuesday',
				label: this.translateService.instant("CHARTS.TUESDAY"),
				backgroundColor: 'rgba(102, 194, 255, 0.6)',
				borderColor: 'rgba(102, 194, 255, 1)',
			},
			{
				id: 'Wednesday',
				label: this.translateService.instant("CHARTS.WEDNESDAY"),
				backgroundColor: 'rgba(51, 173, 255, 0.6)',
				borderColor: 'rgba(51, 173, 255, 1)'
			},
			{
				id: 'Thursday',
				label: this.translateService.instant("CHARTS.THURSDAY"),
				backgroundColor: 'rgba(0, 153, 255, 0.6)',
				borderColor: 'rgba(0, 153, 255, 1)',
			},
			{
				id: 'Friday',
				label: this.translateService.instant("CHARTS.FRIDAY"),
				backgroundColor: 'rgba(0, 122, 204, 0.6)',
				borderColor: 'rgba(0, 122, 204, 1)',
			},
			{
				id: 'Saturday',
				label: this.translateService.instant("CHARTS.SATURDAY"),
				backgroundColor: 'rgba(178, 178, 178, 0.6)',
				borderColor: 'rgba(178, 178, 178, 1)'
			},
			{
				id: 'Sunday',
				label: this.translateService.instant("CHARTS.SUNDAY"),
				backgroundColor: 'rgba(102, 102, 102, 0.6)',
				borderColor: 'rgba(102, 102, 102, 1)'
			}]

			//aqui sabemos cuantas barras seran
			const datasets = [];

			//DATASETS PARA LAS ACTIVIDADES
			//metemos primero el dataset de la linea del promedio
			let data_aux = [];
			let sumaLis = [];
			for (let index = 0; index < this.dataCharts_quiz.length; index++) {
				const element = this.dataCharts_quiz[index].mediaMovil / 1000 / 60;
				sumaLis.push(element);
			}
			const sum = sumaLis.reduce((acc, curr) => acc + curr, 0) / this.dataCharts_quiz.length; //aqui metemos el promedio para pintar la linea
			data_aux = Array(this.cantidadEstudiantes).fill(sum);;
			const datasetLineaProm =	{
					label: this.translateService.instant("GLOBALS.AVERAGE") + ' (' + sum.toFixed(1) + ' min)',
					data: data_aux,
					borderColor: 'rgba(255, 191, 0, 1)',
					backgroundColor: 'rgba(255, 191, 0, 0.1)',
					type: 'line',
					order: 0,
					fill: 'origin',
					pointRadius: 0,
				};
			datasets.push(datasetLineaProm);
			//usamos otro for para obtener los datos por dia
			for (let index = 0; index < this.dataCharts_quiz[0].totalTimePerDay.length; index++) {
				const data = [];
				for (let index2 = 0; index2 < this.listData_quiz[0].length; index2++) {
					const element = this.listData_quiz[0][index2];
					data.push((element.totalTimePerDay[index] / 1000/ 60 / 7).toFixed(1));
				}
				const day = this.dayNames[this.lastSevenDays[index].getDay()];
				const colorObject = this.colores_act.find(color => color.id === day);
				const label = colorObject.label
				const backgroundColor = colorObject.backgroundColor;
				const borderColor = colorObject.borderColor;
				const newDataset = {
					label: label,
					backgroundColor: backgroundColor,
					borderColor: borderColor,
					borderWidth: 1,
					data: data,
				};
				datasets.push(newDataset);
			}

			//DATASETS PARA LAS IDEAS
			//metemos primero el dataset de la linea del promedio
			let data_aux2 = [];
			let sumaLis2 = [];
			for (let index = 0; index < this.dataCharts_node.length; index++) {
				const element = this.dataCharts_node[index].mediaMovil / 1000 / 60 * (-1);
				sumaLis2.push(element);
			}
			const sum2 = sumaLis2.reduce((acc, curr) => acc + curr, 0) / this.dataCharts_node.length; //aqui metemos el promedio para pintar la linea
			data_aux2 = Array(this.cantidadEstudiantes).fill(sum2);;
			const datasetLineaProm2 =	{
					label: this.translateService.instant("GLOBALS.AVERAGE") + ' (' + (sum2*(-1)).toFixed(1) + ' min)',
					data: data_aux2,
					borderColor: 'rgba(255, 191, 0, 1)',
					backgroundColor: 'rgba(255, 191, 0, 0.1)',
					type: 'line',
					order: 0,
					fill: 'origin',
					pointRadius: 0,
				};
			datasets.push(datasetLineaProm2);
			//usamos otro for para obtener los datos por dia
			for (let index = 0; index < this.dataCharts_node[0].totalTimePerDay.length; index++) {
				const data = [];
				for (let index2 = 0; index2 < this.listData_node[0].length; index2++) {
					const element = this.listData_node[0][index2];
					data.push((element.totalTimePerDay[index] / 1000/ 60 / 7*(-1)).toFixed(1));
				}
				const day = this.dayNames[this.lastSevenDays[index].getDay()];
				const colorObject = this.colores_ideas.find(color => color.id === day);
				const label = colorObject.label
				const backgroundColor = colorObject.backgroundColor;
				const borderColor = colorObject.borderColor;
				const newDataset = {
					label: label,
					backgroundColor: backgroundColor,
					borderColor: borderColor,
					borderWidth: 1,
					data: data,
				};
				datasets.push(newDataset);
			}
			var canvas = document.getElementById('myChart') as HTMLCanvasElement;
			var ctx = canvas.getContext('2d');
			if (this.chart) {
				this.chart.destroy();
			}
			this.chart = new Chart(ctx, {
				type: 'bar',
				data: {
					labels: this.listLabels[0], // Etiquetas para las barras
					datasets: datasets
				},
				options: {
					//indexAxis: 'y',
					responsive : true,
					plugins: {
						legend: {
							position: 'bottom',
						},
						filler: {
							propagate: true,
						},
						zoom: {
							zoom: {
								wheel: {
									enabled: true,
									//modifierKey: 'ctrl', // Se activa con la tecla Control
								},
								pinch: {
									enabled: false,
								},
								mode: 'x',
							},
							pan:{
								enabled: true,
								mode: 'x',
								scaleMode: 'x',
							},
						}
					},
					scales: {
						y: {
							stacked: true,
							title: {
								display: true,
								text: this.translateService.instant('INFORMES.AVERAGETIMEPERMINUTE'),
							},
						},
						x: {
							stacked: true,
							suggestedMin: 0,
							position: 'top',
							ticks: {
								maxRotation: 90,
								minRotation: 90
							}
						}
					},
				}
			});
		// Función para hacer zoom
		const zoomInChart = () => {
				this.chart.zoom(1.1); // Zoom in a 10%
		};
		// Función para hacer zoom out
		const zoomOutChart = () => {
				this.chart.zoom(0.9); // Zoom out a 10%
		};
		// Añadir event listeners a los botones
		document.getElementById('zoomInButton').addEventListener('click', zoomInChart);
		document.getElementById('zoomOutButton').addEventListener('click', zoomOutChart);
	}

	graficaTiempoTrabajo() {
		this.destroyAllCharts();
		if (this.chart) {
			this.chart.destroy();
		}
		this.currentLabel = 0;
		//primero introducimos a los estudiantes
		let labels = [];
		for (let index = 0; index < this.dataCharts_node.length; index++) {
			const element = this.dataCharts_node[index];
			//labels.push(element.name + ' ' + element.surname);
			labels.push(element.name);
		}
		this.listLabels = this.dividirArray(labels);

		//aqui sabemos cuantas barras seran
		const datasets = [];

		//DATASETS PARA LAS ACTIVIDADES
		//metemos primero el dataset de la linea del promedio
		let data_aux = [];
		let sumaLis = [];
		for (let index = 0; index < this.dataCharts_quiz.length; index++) {
			const element = this.dataCharts_quiz[index].totalTime / 1000 / 60;
			sumaLis.push(element);
		}
		const sum = sumaLis.reduce((acc, curr) => acc + curr, 0) / this.dataCharts_quiz.length; //aqui metemos el promedio para pintar la linea
		data_aux = Array(this.cantidadEstudiantes).fill(sum);
		const datasetLineaProm =	{
				label: this.translateService.instant("GLOBALS.AVERAGE") + ' (' + sum.toFixed(1) + ' min)',
				data: data_aux,
				borderColor: 'rgba(255, 191, 0, 1)',
				backgroundColor: 'rgba(255, 191, 0, 0.1)',
				type: 'line',
				order: 0,
				fill: 'origin',
				pointRadius: 0,
			};
		datasets.push(datasetLineaProm);

		const data = [];
		for (let index2 = 0; index2 < this.listData_quiz[0].length; index2++) {
			const element = this.listData_quiz[0][index2];
			data.push((element.totalTime / 1000/ 60).toFixed(1));
		}
		var label = "";
		if(this.totalDays == 30){
			label = "Ultimo mes";
		} else if(this.totalDays == 365){
			label = "Ultimo año";
		} else if(this.totalDays == 0){
			label = "Tiempo total";
		}
		const newDataset = {
			label: label,
			backgroundColor: 'rgba(0, 122, 204, 0.6)',
			borderColor: 'rgba(0, 122, 204, 1)',
			borderWidth: 1,
			data: data,
		};
		datasets.push(newDataset);

		//DATASETS PARA LAS IDEAS
		//metemos primero el dataset de la linea del promedio
		let data_aux2 = [];
		let sumaLis2 = [];
		for (let index = 0; index < this.dataCharts_node.length; index++) {
			const element = this.dataCharts_node[index].totalTime / 1000 / 60 * (-1);
			sumaLis2.push(element);
		}
		const sum2 = sumaLis2.reduce((acc, curr) => acc + curr, 0) / this.dataCharts_node.length; //aqui metemos el promedio para pintar la linea
		data_aux2 = Array(this.cantidadEstudiantes).fill(sum2);;
		const datasetLineaProm2 =	{
				label: this.translateService.instant("GLOBALS.AVERAGE") + ' (' + (sum2*(-1)).toFixed(1) + ' min)',
				data: data_aux2,
				borderColor: 'rgba(255, 191, 0, 1)',
				backgroundColor: 'rgba(255, 191, 0, 0.1)',
				type: 'line',
				order: 0,
				fill: 'origin',
				pointRadius: 0,
			};
		datasets.push(datasetLineaProm2);

		const data2 = [];
		for (let index2 = 0; index2 < this.listData_node[0].length; index2++) {
			const element = this.listData_node[0][index2];
			data2.push((element.totalTime / 1000/ 60 *(-1)).toFixed(1));
		}
		var label = "";
		if(this.totalDays == 30){
			label = "Ultimo mes";
		} else if(this.totalDays == 365){
			label = "Ultimo año";
		} else if(this.totalDays == 0){
			label = "Tiempo total";
		}
		const newDataset2 = {
			label: label,
			backgroundColor: 'rgba(42, 42, 162, 0.6)',
			borderColor: 'rgba(42, 42, 162, 1)',
			borderWidth: 1,
			data: data2,
		};
		datasets.push(newDataset2);

		var canvas = document.getElementById('myChart') as HTMLCanvasElement;
		var ctx = canvas.getContext('2d');
		if (this.chart) {
			this.chart.destroy();
		}
		this.chart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: this.listLabels[0], // Etiquetas para las barras
				datasets: datasets
			},
			options: {
				//indexAxis: 'y',
				responsive : true,
				animation: {
						duration: 500, // Duración de la animación
						easing: 'easeOutQuad' // Estilo de la animación
				},
				plugins: {
					legend: {
						position: 'bottom',
					},
					filler: {
						propagate: true,
					},
					zoom: {
						zoom: {
							wheel: {
								enabled: true,
								//modifierKey: 'ctrl', // Se activa con la tecla Control
							},
							pinch: {
								enabled: false,
							},
							mode: 'x',
						},
						pan:{
							enabled: true,
							mode: 'x',
							scaleMode: 'x',
						},
					}
				},
				scales: {
					y: {
						stacked: true,
						title: {
							display: true,
							text: this.translateService.instant('INFORMES.AVERAGETIMEPERMINUTE'),
						}
					},
					x: {
						stacked: true,
						suggestedMin: 0,
						position: 'top',
						ticks: {
							maxRotation: 90,
							minRotation: 90
						}
					}
				},
			}
		});
    // Función para hacer zoom
    const zoomInChart = () => {
			this.chart.zoom(1.1); // Zoom in a 10%
		};
		// Función para hacer zoom out
		const zoomOutChart = () => {
				this.chart.zoom(0.9); // Zoom out a 10%
		};
		// Añadir event listeners a los botones
		document.getElementById('zoomInButton').addEventListener('click', zoomInChart);
		document.getElementById('zoomOutButton').addEventListener('click', zoomOutChart);
  }

	destroyAllCharts() {
    const canvases = document.querySelectorAll('canvas');
    canvases.forEach(canvas => {
        const chart = Chart.getChart(canvas);
        if (chart) {
            chart.destroy();
        }
    });
	}

}
