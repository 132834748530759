
<div id="main-wrapper">
	<app-message-multi-tabs
		*ngIf="showMultiTabs"
		(onClose)="closeMessage($event)"
	></app-message-multi-tabs>
	<app-side-bar></app-side-bar>
	<div class="page-wrapper">
		<div class="container-fluid" style="padding: 0">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
