export class LoginModel {
    phone: string
    keyWord: string
    extension: string

    constructor(phone: string, keyWord: string, extension: string) {
        this.phone = phone;
        this.keyWord = keyWord;
        this.extension = extension
    }
}
