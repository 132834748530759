<nb-card class="mb-0 no-edge-card">
	<nb-card-header class="border-bottom-0">
		<div class="col-10 modal-title">
			<h2 class="title">{{ curso.courseTittle }}</h2>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="container" *ngIf="curso">
			<app-loading [isVisible]="cargando"></app-loading>

			<div class="row d-flex justify-content-between mt-6 mb-6" *ngIf="showModalFormat">

			</div>
			<div class="container m-2 p-2">
				<app-loading [isVisible]="cargando"></app-loading>
				<nb-card fullWidth size="large" [ngClass]="{ hideContaier: !isOpenInfoCourse }" *ngIf="!cargando">
					<nb-card-header class="flex justify-content-between">
						<div class="col-5"></div>
						<div class="col-2">
							<nb-icon class="icon-color-blue pointer text-right m-1 float-right" icon="info-outline"
								[options]="{ animation: { type: 'pulse' } }"></nb-icon>
						</div>
					</nb-card-header>
					<nb-card-body>
						<form [formGroup]="formulario" #thisForm="ngForm">
							<div class="row d-flex justify-content-center align-items-center p-2">
								<div class="col-3">
									<nb-card size="small" class="project-info pointer no-edge-card">
										<app-imgupload [isShowDelete]="true" formControlName="image"></app-imgupload>
									</nb-card>
								</div>

								<div class="col-5">
									<nb-card size="small" class="project-info pointer no-edge-card">
										<div class="form-group">
											<label for="exampleFormControlInput1">{{
												"EDITARCURSO.TITLE" | translate
												}}</label>
											<input nbInput fullWidth formControlName="title" type="text" class="form-control"
												id="exampleFormControlInput1" placeholder="Titulo" />
										</div>

										<div class="form-group">
											<label for="exampleFormControlTextarea1">{{
												"EDITARCURSO.DESCRIPCION" | translate
												}}</label>
											<textarea nbInput fullWidth formControlName="description" class="form-control"
												id="exampleFormControlTextarea1" rows="3"></textarea>
										</div>

										<div class="form-group float-right">
											<nb-toggle labelPosition="start" *ngIf="id != 'nuevo' && loginService.esAutor()"
												formControlName="isVisible" class="col-6">{{
												formulario.get("isVisible").value
												? ("EDITARCURSO.VISIBLE" | translate)
												: ("EDITARCURSO.NOVISIBLE" | translate)
												}}</nb-toggle>

											<nb-toggle labelPosition="start" *ngIf="id != 'nuevo' && loginService.esAutor()"
												formControlName="courseType" class="col-6">{{
												formulario.get("courseType").value
												? ("EDITARCURSO.PUBLIC" | translate)
												: ("EDITARCURSO.PRIVATE" | translate)
												}}</nb-toggle>
										</div>
									</nb-card>
								</div>
								<div class="col-3">
									<nb-card size="small" class="project-info pointer no-edge-card">
										<h6 class="text-center">
											{{ "MODALINFORMATIONCOURSE.AUTHOR" | translate }}:
										</h6>
										<div class="w-100 d-flex justify-content-center">
											<div class="text-center avatar-detail-course mb-2 d-flex align-content-center"
												[ngStyle]="{ 'background-image': getUserAvatar() }"></div>
										</div>
										<h6 class="text-center">
											{{ cursoDatos.user.name }}
											{{ cursoDatos.user.surname }}
										</h6>
										<div class="w-100 d-flex justify-content-center mb-1">
											<button nbButton class="btn-themecolor">
												{{ "EDITORS.EDITORS" | translate }}
											</button>
										</div>

										<nb-card-footer class="disabled-icon">
											<!--	<nb-icon icon="image-2" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
													<nb-icon icon="play-circle" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
													<nb-icon icon="linkedin" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
													<nb-icon icon="twitter" [options]="{ animation: { type: 'pulse' } }"></nb-icon>  -->
										</nb-card-footer>
									</nb-card>
								</div>
							</div>
						</form>
					</nb-card-body>
				</nb-card>

				<!-- TARJETA IDEOGRAFOS -->

				<nb-card fullWidth size="giant" class="no-edge-card">
					<nb-card-header class="flex">
						<div class="col-2 modal-title">
							<h2>{{ "EDITARCURSO.GRAFOS" | translate }}</h2>
						</div>
						<div class="col-4 d-flex justify-content-left align-content-left">
							<nb-actions size="medium">
								<nb-action *ngIf="graphView == 'inlineList'" icon="grid-outline"></nb-action>
								<nb-action *ngIf="graphView == 'gridList'" icon="list-outline"></nb-action>
								<nb-action *ngIf="loginService.esAutor() && id != 'nuevo'" icon="plus-circle-outline"></nb-action>
							</nb-actions>
						</div>
					</nb-card-header>
					<nb-card-body style="background-color: #edf1f7">
						<div class="row d-flex justify-content-center align-items-center">
							<div class="col-11">
								<div class="col-sm-6 col-md-12 col-12">
									<!-- GRID VIEW -->
									<div [sortablejs]="courseTargets" [sortablejsOptions]="options"
										[ngClass]="graphView == 'gridList' ? 'row' : ''">
										<div *ngFor="
														let mapa of courseTargets
															| sortBy : 'asc' : 'tittle'
													" (click)="verDetalleMapa(mapa.target.idTarget)" [ngClass]="
														graphView == 'gridList'
															? 'col-lg-2 col-md-3 col-sm-6 col-xs-12 pointer mb-2'
															: ''
													" title="{{ mapa.target.description }}">
											<div class="card form-group divCardGrafos" *ngIf="graphView == 'gridList'">
												<div class="img-container" ngbTooltip="{{ mapa.target.description }}" placement="top">
													<img [src]="
																	mapa.target.backgroundImage | imagen : 'mapas'
																" (error)="updateUrl($event)" class="card-img-top" alt="{{ mapa.target.tittle | titlecase }}" />
												</div>
												<div class="card-body">
													<h6 class="card-title">
														{{
														mapa.target.tittle
														| titlecase
														| truncate : [50, "..."]
														}}
													</h6>
												</div>
											</div>

											<!-- LIST VIEW -->
											<div *ngIf="graphView !== 'gridList'">
												<div class="inline-list d-flex flex-row align-items-center mr-3 p-2 mb-2"
													ngbTooltip="{{ mapa.target.description }}" placement="top">
													<div class="square-image" [style.background-image]="
																	getImageBackground(
																		mapa.target.backgroundImage
																	)
																"></div>
													<h6 class="card-title ml-2">
														{{
														mapa.target.tittle
														| titlecase
														| truncate : [50, "..."]
														}}
													</h6>
												</div>
											</div>
											<!-- END LIST VIEW -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</nb-card-body>
				</nb-card>
			</div>
		</div>
	</nb-card-body>

</nb-card>
