<nb-card class="mb-0">

	<div class="closeModal">
			<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
					(click)="closeModal()" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
			</nb-icon>
	</div>
	<nb-card-body>
			<div class="m-3 m-md-5 row d-flex justify-content-center">
					<div class="iframe-container">
							<iframe [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
					</div>
			</div>
	</nb-card-body>
	<nb-card-footer class="border-top-0">
	</nb-card-footer>
</nb-card>
