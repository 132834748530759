import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { LangUtils } from '../../utils/lang.utils';

@Injectable()
export class ToasterService {
	SUCCESS_TITTLE: string;
	ERROR_TITTLE: string;
	WARNING_TITTLE: string;
	INFO_TITTLE: string;


	constructor(private toastrService: NbToastrService, private lang: LangUtils, private translateService: TranslateService) {
		this.lang.setAppLang(['es', 'en', 'pt', 'de']);
		this.translateService.get("GLOBALS.SUCCESS").subscribe((res: string) => { this.SUCCESS_TITTLE = res; });
		this.translateService.get("GLOBALS.ERROR").subscribe((res: string) => { this.ERROR_TITTLE = res; });
		this.translateService.get("GLOBALS.WARNING").subscribe((res: string) => { this.WARNING_TITTLE = res; });
		this.translateService.get("GLOBALS.INFO").subscribe((res: string) => { this.INFO_TITTLE = res; });
	}

	public success(message: string) {
		this.showSnack(message, this.SUCCESS_TITTLE, { status: 'success' });
	}

	public error(message: string) {
		this.showSnack(message, this.ERROR_TITTLE, { status: 'danger' });
	}

	public info(message: string) {
		this.showSnack(message, this.INFO_TITTLE, { status: 'info' });
	}

	public warning(message: string) {
		this.showSnack(message, this.WARNING_TITTLE, { status: 'warning' });
	}

	private showSnack(message: string, tittle: string, status: object) {
		this.toastrService.show(
			message,
			tittle,
			status
		);
	}
}
