import { Component, OnInit, EventEmitter, Output } from '@angular/core';
// Models
import { QuizzesCourseModel } from 'src/app/core/models/quizzes/quizzes-course.model';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { QuizModel } from 'src/app/core/models/quizzes';

@Component({
  selector: 'app-footer-quizzes',
  templateUrl: './footer-quizzes.component.html',
  styleUrls: ['./footer-quizzes.component.scss']
})
export class FooterQuizzesComponent implements OnInit {

  @Output() mensajeEnviado:EventEmitter<string> = new EventEmitter<string>();
  @Output() quizEnviado:EventEmitter<any> = new EventEmitter<any>();

  id:string;
  quizzes:QuizzesCourseModel[];
  quizzesBloque:QuizzesCourseModel[];
  quiz:QuizModel;
  posIni;
  posEnd;
  cantQuizzes:number;
  widthQuiz:number;
  cargando = false;
  maxQuizzes:number;
  previus:boolean;
  next:boolean;
  widthPrevius:number;

  constructor(public quizzesService: QuizzesService) { }

  ngOnInit() {
    this.cargando = true;
    this.maxQuizzes=40;
    this.previus=false;
    this.next=false;
    // Obtengo los quizzes asociados al curso
    //this.obtenerDatosQuizzesCurso(159);
    // Obtengo los quizzes asociados al nodo
    //this.obtenerDatosQuizzesNode(151);
    //this.obtenerDatosQuizzesNode(159);
  }

   private async mostrarPregunta(id:number){
    this.mensajeEnviado.emit("Quiz: " + id);
    // Mod. A.G. 18/11/2020
    this.quiz = null
    /*.subscribe(({ data, loading }) => {
     this.quiz = data.quizzes.nodes;
     this.cargando = loading;
     });*/
     // Fin A.G.
     //this.quizEnviado.emit(this.quiz);
   }

  getColor(id) {
    switch (id) {
      case id=427:
        return 'rgb(255, 252, 56)';
      case id=427+3:
        return 'rgb(26, 177, 86)';
      case id=427+5:
        return 'rgb(26, 177, 86)';
      case id=465:
         return 'rgb(26, 177, 86)';
       case id=468:
          return 'rgb(26, 177, 86)';
      case id=427+7:
        return 'rgb(26, 177, 86)';
      case id=427+8:
        return 'rgb(109, 252, 48)';
       case id=427+20:
        return 'rgb(255, 11, 18)';
      default :
        return 'rgb(165, 165, 165)';
    }

    /*switch (id) {
        case id=1:
          return 'rgb(255, 252, 56)';
        case id=209:
          return 'rgb(26, 177, 86)';
        case id=220+5:
          return 'rgb(26, 177, 86)';
        case id=233:
           return 'rgb(26, 177, 86)';
         case id=220 +7:
            return 'rgb(26, 177, 86)';
        case id=220+4:
          return 'rgb(26, 177, 86)';
        case id=220+8:
          return 'rgb(109, 252, 48)';
         case id=220+2:
          return 'rgb(255, 11, 18)';
        default :
          return 'rgb(165, 165, 165)';
    }*/
  }

   getWidth() {
     let width = this.widthQuiz + "px";
     return width;
    }

    getWidthPrevius() {
        let width = this.widthPrevius + "px";
        return width;
       }

    getWidthNext() {
        let width = this.widthPrevius + "px";
        return width;
       }

    toPrevius(){
        this.calculateWidthQuizPrevius(this.quizzes);
    }

    toNext(){
        this.calculateWidthQuizNext(this.quizzes,this.posIni,this.posEnd);
    }

    calculateWidthQuizPrevius(quizzes:QuizzesCourseModel[]){
        var posEnd=this.posIni;
        var posIni=this.posIni-38;
        //this.widthPrevius=(document.getElementById("quizzes").clientWidth-1)/this.maxQuizzes;
        this.quizzesBloque=quizzes.slice(posIni,posEnd);
        this.cantQuizzes=this.quizzesBloque.length;
        this.widthQuiz =((document.getElementById("quizzes").clientWidth-1)/(this.cantQuizzes+2))
        //this.widthQuiz =(((document.getElementById("quizzes").clientWidth-1)-(2*this.widthPrevius))/(this.cantQuizzes))
        this.previus=true;
        this.next=true;
    }

    calculateWidthQuizNext(quizzes:QuizzesCourseModel[], posIni:number, posEnd:number){
        this.posEnd=posEnd;
        if(posEnd>this.maxQuizzes){
            if(posIni==0){
                this.quizzesBloque=quizzes.slice(posIni,38);
                this.posIni=38;
            }else if (this.posIni>=38){
                 var dif = quizzes.length-this.posIni;
                 if(dif<=38){
                    this.posIni=this.posIni;
                    this.quizzesBloque=quizzes.slice(this.posIni,quizzes.length);
                 }else{
                    this.posIni=posIni;
                    this.posEnd=posIni+38;
                    this.quizzesBloque=quizzes.slice(this.posIni,this.posEnd);
                 }
            }
            this.previus=true;
            this.next=true;
            this.cantQuizzes=this.quizzesBloque.length;
            this.widthPrevius=(document.getElementById("quizzes").clientWidth-1)/this.maxQuizzes;
            //this.widthQuiz =((document.getElementById("quizzes").clientWidth-1)/(this.cantQuizzes+2))
            this.widthQuiz =(((document.getElementById("quizzes").clientWidth-1)-(2*this.widthPrevius))/(this.cantQuizzes))
        }else{
            this.quizzesBloque=quizzes.slice(posIni,posEnd);
            this.cantQuizzes=this.quizzesBloque.length;
            //this.widthPrevius=(document.getElementById("quizzes").clientWidth-1)/this.maxQuizzes;
            this.widthQuiz =((document.getElementById("quizzes").clientWidth-1)/(this.cantQuizzes))
            //this.widthQuiz =(((document.getElementById("quizzes").clientWidth-1)-(2*this.widthPrevius))/(this.cantQuizzes))
        }
    }

}
