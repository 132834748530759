<!-- Modal para grabar un audio -->
<nb-card *ngIf="soloAudio" class="no-edge-card modal-max-height-100-65 mb-0">
	<nb-card-header class="border-bottom-0">
		<h5 *ngIf="!grabando">{{'MODALAUDIOMICRO.STARTRECORD' | translate}}</h5>
		<h5 *ngIf="grabando">{{'MODALAUDIOMICRO.LISTENING' | translate}}</h5>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="container">
			<ng-container *ngIf="!grabando">
				<div class="d-flex justify-content-center w-100">
					<div class="d-flex justify-content-center align-items-center">
						<img [src]="Recording" width="60" height="60" class="pointer" (click)="startRecording()"/>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="grabando">
				<div class="d-flex flex-column justify-content-center w-100">
					<div class="d-flex justify-content-center align-items-center">
						<img [src]="Stop" width="80px" height="80px" class="pointer" (click)="stopRecording()" />
					</div>
					<div class="d-flex justify-content-center align-items-center">
						<img src="/assets/images/dots_2.gif" width="50px" height="50px" class="loading-gif" alt="{{ 'LOADING.CARGANDO' | translate }}" />
					</div>
				</div>
			</ng-container>
			<div class="row mt-2">
				<div class="col-12 d-flex justify-content-center">
					<p>
						<span id="minutes">{{minutes}}</span>:<span id="seconds">{{seconds}}</span>
					</p>
				</div>
			</div>
		</div>
		<nb-card-footer class="border-top-0">
			<div class="container d-flex flex-column gap-4" *ngIf="audioFiles.length > 0">
				<div class="d-flex justify-content-center">
					<audio *ngFor="let audio of audioFiles" controls='true' [src]="audio"></audio>
				</div>
				<div class="d-flex flex justify-content-center align-items-center gap-5">
					<button type="button" nbButton class="btn-themecolor"
						[disabled]="audioFiles.length === 0? true: false"  (click)="aceptar()" >
						{{'MODALAUDIOMICRO.OK' | translate}}
					</button>
					<button type="button" nbButton class="btn-red"  (click)="salir()" >
						{{'MODALAUDIOMICRO.Cancel' | translate}}
					</button>
				</div>
			</div>
		</nb-card-footer>
	</nb-card-body>
</nb-card>

<!-- Modal para grabar un video -->
<nb-card *ngIf="soloVideo" class="no-edge-card modal-max-height-100-65 mb-0">
  <nb-card-header class="border-bottom-0">
    <div class="pr-5">
		<h5 *ngIf="!grabando">{{'MODALAUDIOMICRO.STARTRECORD' | translate}}</h5>
    	<h5 *ngIf="grabando">{{'MODALAUDIOMICRO.RECORDING' | translate}}</h5>
	</div>
  </nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
  <nb-card-body>
    <div class="container">
		<ng-container *ngIf="videoFiles.length > 0">
			<div class="d-flex justify-content-center">
				<div class="d-flex">
						<video class="d-flex w-100" style="max-height: 400px;" *ngFor="let audio of videoFiles" controls='true' [src]="audio" muted></video>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="videoFiles.length === 0">
				<div class="d-flex justify-content-center">
					<div class="d-flex">
						<video  class="d-flex w-100" style="max-height: 400px;" id="videoRef" autoplay  ></video>
					</div>
				</div>
			</ng-container>

			<div class="row mt-4">
				<div class="col-12 d-flex justify-content-center">
					<p class="m-0"><span id="minutes">{{minutes}}</span>:<span id="seconds">{{seconds}}</span></p>
				</div>
			</div>

			<ng-container *ngIf="!grabando">
				<div class="row mb-4">
					<div *ngIf="videoFiles.length > 0" class="col-12 d-flex flex-column align-items-center gap-3 mt-2">
						<mat-icon ngbTooltip="{{'MODALAUDIOMICRO.REPEATRECORD' | translate}}" (click)="reloadVid()" class="icon-mat-type vid_on pointer mt-2" color="red" style="color: #dc3545;">restart_alt</mat-icon>
						{{'MODALAUDIOMICRO.REPEATRECORD' | translate}}
					</div>
					<div *ngIf="videoFiles.length === 0" class="col-12 d-flex flex-column align-items-center gap-3 mt-2">
						<mat-icon ngbTooltip="{{'MODALAUDIOMICRO.STARTRECORD' | translate}}" (click)="startCamera()"
							class="icon-mat-type vid_on pointer mt-2" color="red" style="color: #dc3545;">radio_button_checked
						</mat-icon>
							{{'MODALAUDIOMICRO.STARTRECORD' | translate}}
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="grabando">
				<div class="row mb-4">
					<div class="col-12 d-flex flex-column align-items-center gap-3 mt-2">
						<mat-icon ngbTooltip="{{'MODALAUDIOMICRO.STOPRECORD' | translate}}" (click)="stopCamera()" class="icon-mat-type mic_on pointer mt-2" color="primary">stop</mat-icon>
						{{'MODALAUDIOMICRO.STOPRECORD' | translate}}
					</div>
					<div *ngIf="grabando" class="w-100 d-flex justify-content-center">
						<img src="/assets/images/dots_2.gif" width="50px" height="50px" class="loading-gif" alt="{{ 'LOADING.CARGANDO' | translate }}" />
					</div>
				</div>
			</ng-container>

			<nb-card-footer *ngIf="videoFiles.length > 0" class="border-top-0">
				<div class="d-flex justify-content-center align-items-center gap-5">
					<button type="button" nbButton class="btn-themecolor"
						[disabled]="videoFiles.length === 0? true: false"  (click)="aceptar()" >{{'MODALAUDIOMICRO.OK' | translate}}
					</button>
					<button type="button" nbButton class="btn-red" [disabled]="grabando" (click)="salir()" >
						{{'MODALAUDIOMICRO.Cancel' | translate}}
					</button>
				</div>
			</nb-card-footer>
		</div>
  </nb-card-body>
</nb-card>

<!-- modal para capturar una foto -->
<nb-card *ngIf="soloFoto" class="no-edge-card modal-max-height-100-65 mb-0">
	<nb-card-header class="border-bottom-0">
		<div class="pr-5">
			<h5>{{'MODALAUDIOMICRO.USECAMERA' | translate}}</h5>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>

	<nb-card-body>
		<div class="container">
			<div class="d-flex flex-column gap-3" *ngIf="webcamImage">
				<div class="d-flex justify-content-center align-items-center">
					<img class="card-img-top" [src]="webcamImage.imageAsDataUrl" alt="Card image cap">
				</div>
				<div class="d-flex justify-content-center align-items-center gap-5">
					<button nbButton size="small" class="btn-themecolor" (click)="savePicture();">
						{{'MODALAUDIOMICRO.SAVEPHOTO' | translate}}
					</button>

					<button nbButton size="small" class="btn-red" (click)="newFoto();">
						{{'MODALAUDIOMICRO.NEWPHOTO' | translate}}
					</button>

					<button ngbTooltip="{{'MODALAUDIOMICRO.CHANGECAMERAINFO' | translate}}" nbButton size="small"
						class="btn-themecolor" (click)="showNextWebcam(true);" [disabled]="!multipleWebcamsAvailable">
						{{'MODALAUDIOMICRO.CHANGECAMERA' | translate}}
					</button>

					<button type="button" nbButton size="small" class="btn-red" [disabled]="grabando" (click)="salir()">
						{{'MODALAUDIOMICRO.Cancel' | translate}}
					</button>
				</div>
			</div>

			<div class="d-flex flex-column gap-3" *ngIf="newPicture">
				<div class="d-flex justify-content-center align-items-center">
					<webcam [height]="500" [width]="500" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
						*ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
						[videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
						(initError)="handleInitError($event)"></webcam>
				</div>
				<div class="d-flex justify-content-center align-items-center gap-5">
					<button [disabled]="!showWebcam" nbButton size="small" class="btn-themecolor" (click)="triggerSnapshot();">
						{{'MODALAUDIOMICRO.TAKEPHOTO' | translate}}
					</button>

					<button *ngIf="showWebcam" nbButton size="small" (click)="toggleWebcam();">
							{{'MODALAUDIOMICRO.HIDECAMERA' | translate}}
					</button>
					<button *ngIf="!showWebcam" nbButton size="small" (click)="toggleWebcam();">
						{{'MODALAUDIOMICRO.SHOWCAMERA' | translate}}
					</button>

					<button ngbTooltip="{{'MODALAUDIOMICRO.CHANGECAMERAINFO' | translate}}" nbButton size="small"
						(click)="showNextWebcam(true);" [disabled]="!multipleWebcamsAvailable">
						{{'MODALAUDIOMICRO.CHANGECAMERA' | translate}}
					</button>

					<button type="button" nbButton size="small" class="btn-red" [disabled]="grabando" (click)="salir()">
						{{'MODALAUDIOMICRO.Cancel' | translate}}
					</button>
				</div>
			</div>

			<nb-card-footer class="border-top-0">
				<div class="d-flex flex-column gap-3">
					<div class="d-flex justify-content-center align-items-center">
						<div class="d-flex justify-content-center align-items-center gap-5">
							<div *ngIf="mediaDevices" class="d-flex flex-column gap-3">
								<div class="d-flex flex-column">
									<label for="exampleFormControlSelect1">{{'MODALAUDIOMICRO.MEDIADEVICES' | translate}}</label>
									<select [(ngModel)]="deviceId" class="form-control" id="exampleFormControlSelect1">
										<option *ngFor="let media of mediaDevices">{{media.label}}</option>
									</select>
								</div>
								<button ngbTooltip="{{'MODALAUDIOMICRO.CHANGECAMERAINFO' | translate}}" nbButton size="small"
									class="btn-themecolor" (click)="showNextWebcam(deviceId);">
									{{'MODALAUDIOMICRO.ACTIVE' | translate}}
								</button>
							</div>
						</div>
					</div>

					<div class="d-flex justify-content-center align-content-center">
						<input id="cameraSwitchCheckbox" type="checkbox" [(ngModel)]="allowCameraSwitch">
						<label for="cameraSwitchCheckbox">{{'MODALAUDIOMICRO.ALLOWCAMERASWITCH' | translate}}</label>
					</div>

					<div class="d-flex flex-column">
						<h4 *ngIf="errors.length > 0">{{'MODALAUDIOMICRO.MESSAGES' | translate}}</h4>
						<ul *ngFor="let error of errors">
							<li>{{error | json}}</li>
						</ul>
					</div>
				</div>
			</nb-card-footer>
		</div>
	</nb-card-body>
</nb-card>
