import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Esto es para reemplazar la palabra AppVersion por la versión de la app en el index.html
var html = document.documentElement.innerHTML;
document.documentElement.innerHTML = html.replace(/AppVersion/g, environment.version);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    // Registra el Service Worker solo si environment.foundation es true
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker
        .register('/ngsw-worker.js')
        .then((registration) => {
          console.log('Service Worker registrado con éxito: ', registration);
        })
        .catch((error) => {
          console.error('Error al registrar el Service Worker: ', error);
        });
    }
  })
  .catch((err) => console.error(err));