import { SigmaCanvasService } from './../../shared/components/nodes/sigma-canvas/sigma-canvas.service';
import { gEdge } from './../models/graph/gEdge.model';
import { DEFAULTFONTFAMILY } from '../models/masters/masters.enum';
import { Profiles } from './profiles.enum';
import { LoginService } from 'src/app/core/services/login';
import { sigma } from 'sigma';
import { EventEmitter, Output, Directive } from '@angular/core';
import { User } from '../models/users/user.models';
import { SIGMA_CONSTANTS } from './sigma-constants';
import { NodeCoverPipe } from 'src/app/shared/pipes/node-cover.pipe'
import { Dimensions } from '../services/graph/graph.service';
import { Utils } from './utils';
import { SigmaToolbarsService } from '../services/sigma-toolbars/sigma-toolbars.service';

declare var sigma: any;
declare var CustomShapes: any;
declare var $: any;
const imageQuiz = '../../../assets/images/icons/inte.svg';
const imageQuizGo = '../../../assets/images/icons/go.png';
const imageQuizGoal = '../../../assets/images/icons/goal.png';

const GRAPHMARGIN:number = 0.4

@Directive()
export class SigmaUtils {
	public sigma = new sigma();

	private graph = { nodes: [], edges: [], stickers: [] };
	private defaultEdgeSettings = { size: 1, color: "#000", label: "" };

	private forceAtlas2Params = {
		worker: false,
		outboundAttractionDistribution: true,
		gravity: 1,
		linLogMode: false,
		adjustSizes: true,
		edgeWeightInfluence: 0,
		scalingRatio: 1,
		strongGravityMode: false,
		slowDown: 1,
		barnesHutOptimize: false,
		barnesHutTheta: 0.5,
		startingIterations: 1,
		iterationsPerRender: 1,
	};

	private selection = [];

	public get selectedNodes(): any[] {
		return this.selection;
	}

	public constructor(
		container,
		private nodeCoverPipe: NodeCoverPipe,
		profile: string,
		private utils: Utils,
		private loginService?: LoginService,
		private toolbarService?: SigmaToolbarsService
	) {
		this.init(container);
		this.defineText(profile, loginService);
		this.defineImage();
		this.defineEvents();
	}

	private init(container) {
		this.sigma = new sigma({
			graph: this.graph,
			container: "graph-container",
			renderer: {
				container: container,
				type: "canvas",
			},
			settings: {
				minNodeSize: 1,
				maxNodeSize: 100,
				enableHovering: true,
				enableEdgeHovering: false,
				doubleClickEnabled: false,
				autoRescale: false,
				nodeHoverColor: "#90BBFB",
				defaultEdgeType: "branch",
				edgeLabelSize: "proportional",
				enableEdgeLabel: true,
				font: DEFAULTFONTFAMILY,
				zoomMax: 10,
				edgeLabelThreshold: 0.5,
				edgeHoverSizeRatio: 1.5,
				edgeHoverExtremities: true,
			},
		});

		this.customShapes();
	}

	public customShapes() {
		CustomShapes.init(this.sigma);
		this.refresh();
	}

	// ----------------------------------------------------------------------
	//   A C T I O N S
	// ----------------------------------------------------------------------
	public refresh() {
		if (this.sigma) {
			this.sigma.refresh();
		} else {
			console.error('this.sigma no está definido. Asegúrate de que se haya inicializado correctamente.');
		}
	}

	public addNode(aNode) {
		if (!this.nodeExists(aNode.id)) {
			if (aNode.nodeType === SIGMA_CONSTANTS.NODE_TYPE) {
				aNode.type = SIGMA_CONSTANTS.NODE_TYPE_CIRCLE;
				aNode.image = {
					url: this.nodeCoverPipe.transform(aNode.pictureNode),
					clip: SIGMA_CONSTANTS.IMAGE_CLIP,
					scale: SIGMA_CONSTANTS.IMAGE_SCALE,
				};
				aNode.url = this.nodeCoverPipe.transform(aNode.pictureNode);
			}

			this.sigma.graph.addNode(aNode);
			this.refresh();
		}
	}

	public updateSticker(aSticker) {
		this.sigma.graph.nodes(aSticker.id).size = aSticker.size;
		this.sigma.graph.nodes(aSticker.id).sizeImg = aSticker.sizeImg;
		this.sigma.graph.nodes(aSticker.id).link = aSticker.link;

		this.sigma.graph.nodes(aSticker.id).renewCache = true;

		this.refresh();
	}

	public async updateNode(aNode) {
		var id = aNode.id;
		this.sigma.graph.nodes(aNode.id).label = aNode.label;
		this.sigma.graph.nodes(aNode.id).backgroundColor = aNode.backgroundColor;
		this.sigma.graph.nodes(aNode.id).nodeSwlevel = aNode.nodeSwlevel;
		this.sigma.graph.nodes(aNode.id).quizSwlevel = aNode.quizSwlevel;
		this.sigma.graph.nodes(aNode.id).description = aNode.description;
		this.sigma.graph.nodes(aNode.id).quizTittle = aNode.quizTittle;
		this.sigma.graph.nodes(aNode.id).quizInstructions = aNode.quizInstructions;
		this.sigma.graph.nodes(aNode.id).duration = aNode.duration;
		this.sigma.graph.nodes(aNode.id).ordinal = aNode.ordinal;
		this.sigma.graph.nodes(aNode.id).power0 =
			aNode.ordinalPower0 > 0 ? true : false;
		this.sigma.graph.nodes(aNode.id).power1 =
			aNode.ordinalPower1 > 0 ? true : false;
		this.sigma.graph.nodes(aNode.id).power2 =
			aNode.ordinalPower2 > 0 ? true : false;
		this.sigma.graph.nodes(aNode.id).power3 =
			aNode.ordinalPower3 > 0 ? true : false;
		this.sigma.graph.nodes(aNode.id).powerNegative1 =
			aNode.ordinalPowerNegative1 > 0 ? true : false;
		this.sigma.graph.nodes(aNode.id).powerNegative2 =
			aNode.ordinalPowerNegative2 > 0 ? true : false;
		this.sigma.graph.nodes(aNode.id).powerNegative3 =
			aNode.ordinalPowerNegative3 > 0 ? true : false;
		this.sigma.graph.nodes(aNode.id).ordinalPower0 = aNode.ordinalPower0;
		this.sigma.graph.nodes(aNode.id).ordinalPower1 = aNode.ordinalPower1;
		this.sigma.graph.nodes(aNode.id).ordinalPower2 = aNode.ordinalPower2;
		this.sigma.graph.nodes(aNode.id).ordinalPower3 = aNode.ordinalPower3;
		this.sigma.graph.nodes(aNode.id).ordinalPowerNegative1 =
			aNode.ordinalPowerNegative1;
		this.sigma.graph.nodes(aNode.id).ordinalPowerNegative2 =
			aNode.ordinalPowerNegative2;
		this.sigma.graph.nodes(aNode.id).ordinalPowerNegative3 =
			aNode.ordinalPowerNegative3;

		if (this.sigma.graph.nodes(aNode.id).image && aNode.imageNode < 3) {
			//Tenemos que revisar si el vídeo que hay ahora es de Youtube o subido por el propio usuario
			this.sigma.graph.nodes(aNode.id).image.url = this.sigma.graph.nodes(
				aNode.id
			).url = this.nodeCoverPipe.transform(aNode.pictureNode);
			const values = await this.getImageDimensions(
				this.sigma.graph.nodes(aNode.id).image.url
			);
			this.sigma.graph.nodes(aNode.id).image.h = values.height;
			this.sigma.graph.nodes(aNode.id).image.w = values.width;
			let ratio = values.width / values.height;

			if (values.height !== values.width)
				if (ratio > 1.7) this.sigma.graph.nodes(aNode.id).image.scale = 3.5;
				else this.sigma.graph.nodes(aNode.id).image.scale = 2.4;
			else this.sigma.graph.nodes(aNode.id).image.scale = 1.4;
		} else if (aNode.imageNode > 2) {
			this.sigma.graph.nodes(aNode.id).image.h = 24;
			this.sigma.graph.nodes(aNode.id).image.w = 24;
			this.sigma.graph.nodes(aNode.id).image.scale = 1;

			switch (aNode.imageNode) {
				case 3:
					this.sigma.graph.nodes(aNode.id).image.url =
						SIGMA_CONSTANTS.IMAGE_AUDIO_DEFAULT;
					break;
				case 4:
					this.sigma.graph.nodes(aNode.id).image.url =
						SIGMA_CONSTANTS.IMAGE_PDF_DEFAULT;
					break;
				case 5:
					this.sigma.graph.nodes(aNode.id).image.url =
						SIGMA_CONSTANTS.IMAGE_TEXT_DEFAULT;
			}
		}

		if (
			aNode.type &&
			((aNode.type as string).toLowerCase() ===
				SIGMA_CONSTANTS.QUIZ_TYPE.toLowerCase() ||
				(aNode.type as string).toLowerCase() === SIGMA_CONSTANTS.QUIZ_NODE_TYPE)
		) {
			this.sigma.graph.nodes(aNode.id).idTemplate = aNode.idTemplate;
			this.sigma.graph.nodes(aNode.id).size =
				aNode.size || SIGMA_CONSTANTS.QUIZ_SMALL_SIZE;
			this.sigma.graph.nodes(aNode.id).color = aNode.color;
			this.sigma.graph.nodes(aNode.id).certifiedQuiz = aNode.certifiedQuiz;
		}

		if (aNode.id === SIGMA_CONSTANTS.NODE_TEMP) {
			id =
				aNode.nodeType === SIGMA_CONSTANTS.NODE_TYPE
					? "n" + Math.random()
					: "q" + Math.random();
			var n = {
				id: id,
				type:
					aNode.nodeType === SIGMA_CONSTANTS.NODE_TYPE
						? SIGMA_CONSTANTS.NODE_TYPE_CIRCLE
						: SIGMA_CONSTANTS.QUIZ_NODE_TYPE,
				nodeType: aNode.nodeType,
				idOriginal: aNode.idOriginal,
				originalColor: aNode.color,
				x: aNode.x,
				y: aNode.y,
				originalX: aNode.x,
				originalY: aNode.y,
				size: 8,
				label: aNode.label,
				image: {
					url: aNode.url,
					clip: SIGMA_CONSTANTS.IMAGE_CLIP,
					scale: SIGMA_CONSTANTS.IMAGE_SCALE,
				},
				url: aNode.url,
				backgroundColor: aNode.backgroundColor,
				nodeSwlevel: aNode.nodeSwlevel,
				description: aNode.descripction,
				idCourseCreation: aNode.idCourseCreation,
				idTargetCreation: aNode.idTargetCreation,
				color: aNode.color,
				paste: aNode.paste ? aNode.paste : false,
				duration: 0,
			};
		}

		this.sigma.graph.nodes(aNode.id).renewCache = true;
		this.dropNode(SIGMA_CONSTANTS.NODE_TEMP);
		this.refresh();
	}

	public dropNode(id) {
		if (this.nodeExists(id)) {
			if (id === SIGMA_CONSTANTS.NODE_TEMP) {
				this.sigma.graph.dropNode(id);
			} else {
				// 1. Drop edges
				this.sigma.graph.adjacentEdges(id).forEach((element) => {
					//this.dropEdgeForId(element.id);
					this.sigma.graph.edges(element.id).hidden = true;
					this.sigma.graph.edges(element.id).delete = true;
				});
				// 2. Drop Node
				//this.sigma.graph.dropNode(id);
				this.sigma.graph.nodes(id).hidden = true;
				this.sigma.graph.nodes(id).delete = true;
				if (this.sigma.graph.edges(id)) {
					this.sigma.graph.edges(id).delete = true;
				}
			}
			this.sigma.refresh();
		}
	}

	public dropEdgeForId(id) {
		this.sigma.graph.dropEdge(id);
	}

	public dropEdge(node1, node2, order = false) {
		if (!this.dropEdgeForConnection(node1, node2) && order) {
			this.dropEdgeForConnection(node2, node1);
		}
		this.sigma.refresh();
	}

	private dropEdgeForConnection(aNode1, aNode2): boolean {
		let cId = aNode1.id + "_" + aNode2.id;
		if (this.connectionExists(cId)) {
			this.dropEdgeForId(cId);
			return true;
		}
		return false;
	}

	public connectNodes(nId1, nId2, type = "branch", settings, response?: any) {
		if (!this.nodeExists(nId1) || !this.nodeExists(nId2)) {
			return;
		}
		var connectionType = SIGMA_CONSTANTS.NODES_NODES;

		if (nId1.includes("q") && nId2.includes("q")) {
			connectionType = SIGMA_CONSTANTS.QUIZZES_QUIZZES;
		}

		if (
			(nId1.includes("q") && nId2.includes("n")) ||
			(nId1.includes("n") && nId2.includes("q"))
		) {
			connectionType = SIGMA_CONSTANTS.QUIZZES_NODES;
		}

		this.sigma.graph.addEdge({
			id: (nId1 + "_" + nId2).toString(),
			source: nId1,
			target: nId2,
			type: type,
			connectionType: connectionType,
			size: settings.size > 0 ? settings.size : this.defaultEdgeSettings.size,
			color: settings.color ? settings.color : this.defaultEdgeSettings.color,
			label: settings.label ? settings.label : this.defaultEdgeSettings.label,
		});

		//Update nodes/quizzes edges in graph
		if (connectionType !== SIGMA_CONSTANTS.NODES_NODES && response)
			this.sigma.graph.nodes(nId1).edges = response.data.origin;
		this.sigma.refresh();
	}

	public connectNodesFromSelection(
		type = "branch",
		settings = this.defaultEdgeSettings
	) {
		if (this.selection.length <= 1) {
			return;
		}
		for (let i = 0; i < this.selection.length - 1; i++) {
			var node1 = this.selection[i];
			var node2 = this.selection[i + 1];
			this.connectNodes(node1.id, node2.id, type, settings);
		}
	}

	public dropNodesFromSelection() {
		for (let i = 0; i < this.selection.length; i++) {
			this.dropNode(this.selection[i].id);
		}
		this.selection = [];
	}

	public deleteGraph(sgraph) {
		if (sgraph.edges) {
			sgraph.edges.forEach((sedge) => this.dropEdgeForId(sedge.id));
		}

		if (sgraph.nodes) {
			sgraph.nodes.forEach((snode) => this.dropNode(snode.id));
		}
		this.sigma.refresh();
	}

	public addGraph(graph) {
		if (graph.nodes) {
			graph.nodes.forEach((snode) => {
				this.sigma.graph.addNode(snode);
				this.sigma.refresh();
			});
		}

		if (graph.stickers)
			graph.stickers.forEach((snode) => {
				snode.size =
					(snode.sizeImg / 10) * SIGMA_CONSTANTS.STICKER_DEFAULT_SIZE;
				this.sigma.graph.addNode(snode);
				this.sigma.refresh();
			});

		if (graph.edges) {
			graph.edges.forEach((sedge) => {
				//para que las lineas que conectan a los quizes sean de color #000
				let ntemp = false;
				for (let index = 0; index < graph.nodes.length; index++) {
					const element = graph.nodes[index];
					if (
						sedge.target === element.id &&
						this.checkNodeExistsInBd(sedge.source, graph.nodes)
					) {
						ntemp = true;
						break;
					}
				}

				if (sedge.connectionType === SIGMA_CONSTANTS.QUIZZES_NODES) {
					sedge.color = "#000";
					sedge.size = 0.75;
				} else if (sedge.connectionType === SIGMA_CONSTANTS.QUIZZES_QUIZZES) {
					sedge.color = "#000";
					sedge.size = 0.75;
				}
				if (ntemp) {
					this.sigma.graph.addEdge(sedge);
				}
				this.sigma.refresh();
			});
		}
		this.sigma.refresh();
	}

	/**
	 *
	 * @param idSource ID source to check
	 * @param nodeList Node list
	 * @returns True if the node/quiz exists; false, if not exists
	 */
	private checkNodeExistsInBd(idSource: any, nodeList: any[]): boolean {
		let nodeTemp = nodeList.filter((e) => e.id === idSource);
		if (!nodeTemp.length) return false;
		return true;
	}

	public nodeExists(nId) {
		if (this.sigma.graph.nodes(nId)) {
			return true;
		}

		return false;
	}

	public connectionExists(cId) {
		const edge = this.sigma.graph.edges(cId);
		if (edge && !edge.delete) {
			return true;
		}
		return false;
	}

	/**
	 *
	 * @param sigmaNode1
	 * @param sigmaNode2
	 * @returns id of the first sigma node of the relationship
	 */
	public checkConnection(sigmaNode1: any, sigmaNode2: any) {
		const node1Id: string =
			sigmaNode1.nodeType === SIGMA_CONSTANTS.NODE_TYPE
				? this.generateNodeId(sigmaNode1.idOriginal)
				: this.generateQuizId(sigmaNode1.idOriginal);
		const node2Id: string =
			sigmaNode2.nodeType === SIGMA_CONSTANTS.NODE_TYPE
				? this.generateNodeId(sigmaNode2.idOriginal)
				: this.generateQuizId(sigmaNode2.idOriginal);

		if (
			this.sigma.graph.edges(this.generateEdgeId(node1Id, node2Id)) &&
			!this.sigma.graph.edges(this.generateEdgeId(node1Id, node2Id)).delete
		) {
			return sigmaNode1.idOriginal;
		} else if (
			this.sigma.graph.edges(this.generateEdgeId(node2Id, node1Id)) &&
			!this.sigma.graph.edges(this.generateEdgeId(node2Id, node1Id)).delete
		) {
			return sigmaNode2.idOriginal;
		} else {
			return undefined;
		}
	}

	public deleteEdge(node1Id: string, node2Id: string, response?: any) {
		const edgeId: string = this.generateEdgeId(node1Id, node2Id);

		this.sigma.graph.edges(edgeId).hidden = true;
		this.sigma.graph.edges(edgeId).delete = true;
		this.sigma.graph.dropEdge(edgeId);

		if (response) this.sigma.graph.nodes(node1Id).edges = response.data.origin;

		this.sigma.refresh();
	}

	public updateEdge(id: string, response: any): void {
		const currentEdge: gEdge = this.sigma.graph.edges(id);

		currentEdge.color = response["color"];
		currentEdge.type = response["line"];
		currentEdge.label = response["label"];
		currentEdge.size = response["size"];

		this.sigma.refresh();
	}

	public generateNodeId(nodeOriginalId: number): string {
		return "n" + nodeOriginalId;
	}

	public generateQuizId(quizOriginalId: number): string {
		return "q" + quizOriginalId;
	}

	public generateStickerId(id: number): string {
		return String("s" + id);
	}

	public generateEdgeId(nodeFromId: string, nodeToId: string): string {
		return nodeFromId + "_" + nodeToId;
	}

	public edgeSetDelete(id) {
		if (this.connectionExists(id)) {
			this.sigma.graph.edges(id).hidden = true;
			this.sigma.graph.edges(id).delete = true;
		}
	}

	public nodeSetDelete(id) {
		if (this.nodeExists(id)) {
			this.sigma.graph.nodes(id).hidden = true;
			this.sigma.graph.nodes(id).delete = true;
		}
	}

	public setNodeColor(node, color) {
		node.color = color;
		this.sigma.refresh();
	}

	public restoreOriginalColor(node) {
		node.color = node.originalColor;
		this.sigma.refresh();
	}

	public changeAllNodeColors(color) {
		this.sigma.graph.nodes().forEach(function (n) {
			n.color = color;
		});
		this.sigma.graph.edges().forEach(function (n) {
			n.color = color;
		});
		this.sigma.refresh();
	}

	public restoreAllOriginalNodeColors() {
		this.sigma.graph.nodes().forEach(function (n) {
			if (n.nodeType === SIGMA_CONSTANTS.NODE_TYPE) n.color = n.originalColor;
		});
		this.sigma.refresh();
	}

	public resetNodesColor(node1, node2): void {
		node1.color = node1.originalColor;
		node2.color = node2.originalColor;
		this.sigma.refresh();
	}

	public centerGraph() {
		var bounds = sigma.utils.getBoundaries(
			this.sigma.graph,
			"read_cam0:",
			false
		);

		var cx = bounds.minX + (bounds.maxX - bounds.minX) / 2;
		var cy = bounds.minY + (bounds.maxY - bounds.minY) / 2;

		var width = bounds.maxX - bounds.minX + bounds.sizeMax * 2;
		var height = bounds.maxY - bounds.minY + bounds.sizeMax * 2;

		var rendererWidth = this.sigma.renderers[0].width;
		var rendererHeight = this.sigma.renderers[0].height;

		var ratio = Math.max(
			width / rendererWidth,
			height / rendererHeight + GRAPHMARGIN
		);

		if (width / rendererWidth >= height / rendererHeight) ratio += 0.5;
		else ratio += 0.2;

		if (!Number.isNaN(cx) && !Number.isNaN(cy)) {
			this.sigma.cameras[0].goTo({ x: cx, y: cy, angle: 0, ratio: ratio });
		}
		this.sigma.refresh();
	}

	public setZoomAnimation(ratio: number) {
		//SOLO REALIZAMOS LA ANIMACION DE ZOOM INICIAL SI ESTAMOS CON EL ROL ESTUDIANTE

		let s = this.sigma;
		s.cameras[0].goTo({
			x: this.sigma.camera.x,
			y: this.sigma.camera.y,
			angle: this.sigma.camera.angle,
			ratio: ratio,
		});
		this.sigma = s;
		this.sigma.refresh();
		var bounds = sigma.utils.getBoundaries(
			this.sigma.graph,
			"read_cam0:",
			false
		);
		var cx = bounds.minX + (bounds.maxX - bounds.minX) / 2;
		var cy = bounds.minY + (bounds.maxY - bounds.minY) / 2;
		var width = bounds.maxX - bounds.minX + bounds.sizeMax * 2;
		var height = bounds.maxY - bounds.minY + bounds.sizeMax * 2;
		var rendererWidth = this.sigma.renderers[0].width;
		var rendererHeight = this.sigma.renderers[0].height;
		var maxRatio = Math.max(
			width / rendererWidth,
			height / rendererHeight + GRAPHMARGIN
		);
		if (width / rendererWidth >= height / rendererHeight) maxRatio += 0.5;
		else maxRatio += 0.2;
		if (this.loginService.esEstudiante()) {
			let interval = setInterval(() => {
				this.zoomIn();
				ratio -= 0.1;
				if (ratio < maxRatio) {
					clearInterval(interval);
					this.centerGraph();
				}
			}, 8);
		} else {
			let interval = setInterval(() => {
				this.zoomIn();
				ratio -= 0.6;
				if (ratio < maxRatio) {
					clearInterval(interval);
					this.centerGraph();
				}
			}, 0.5);
		}
	}

	public adjustGraphToPrint(): void {
		var bounds = sigma.utils.getBoundaries(
			this.sigma.graph,
			"read_cam0:",
			false
		);

		var cx = bounds.minX + (bounds.maxX - bounds.minX) / 2;
		var cy = bounds.minY + (bounds.maxY - bounds.minY) / 2;

		var width = bounds.maxX - bounds.minX + bounds.sizeMax * 1.5;
		var height = bounds.maxY - bounds.minY + bounds.sizeMax * 1.5;

		var rendererWidth = this.sigma.renderers[0].width;
		var rendererHeight = this.sigma.renderers[0].height;

		var ratio = Math.max(width / rendererWidth, height / rendererHeight + 1.2);

		if (!Number.isNaN(cx) && !Number.isNaN(cy)) {
			this.sigma.cameras[0].goTo({
				x: cx + rendererWidth / 1.6,
				y: cy + rendererHeight / 1.8,
				angle: 0,
				ratio: ratio,
			});
		}
		this.sigma.refresh();
	}

	public getRectangle(width, height) {
		var bounds = sigma.utils.getBoundaries(
			this.sigma.graph,
			"read_cam0:",
			false
		);
		var widthVect = this.sigma.camera.cameraPosition(width, 0, true),
			heightVect = this.sigma.camera.cameraPosition(0, height, true),
			centerVect = this.sigma.camera.cameraPosition(
				width / 2,
				height / 2,
				true
			);
		var margin = 10 * this.sigma.camera.ratio;

		return {
			x1: this.sigma.camera.x - centerVect.x + bounds.sizeMax * margin,
			y1: this.sigma.camera.y - centerVect.y + bounds.sizeMax * margin,
			x2:
				this.sigma.camera.x -
				centerVect.x +
				widthVect.x -
				bounds.sizeMax * margin,
			y2:
				this.sigma.camera.y -
				centerVect.y +
				heightVect.y -
				bounds.sizeMax * margin,
			height: Math.sqrt(Math.pow(heightVect.x, 2) + Math.pow(heightVect.y, 2)),
			margin: bounds.sizeMax * 2,
		};
	}

	public pixelRatio() {
		return sigma.utils.getPixelRatio();
	}

	public rendererWidth() {
		return this.sigma.renderers[0].width;
	}

	public rendererHeight() {
		return this.sigma.renderers[0].height;
	}

	public boundaries() {
		return sigma.utils.getBoundaries(this.sigma.graph, "read_cam0:", false);
	}

	public allNodes() {
		return this.sigma.graph.nodes();
	}

	public allEdges() {
		return this.sigma.graph.edges();
	}

	public zoomOut() {
		var ratio = this.sigma.camera.ratio + 0.1;
		let s = this.sigma;
		s.cameras[0].goTo({
			x: this.sigma.camera.x,
			y: this.sigma.camera.y,
			angle: this.sigma.camera.angle,
			ratio: ratio,
		});
		this.sigma = s;
		this.sigma.refresh();
	}

	public zoomIn() {
		if(this.sigma) {
			var ratio = this.sigma.camera?.ratio - 0.1;
			if (this.sigma.camera && ratio !== undefined) {
				let s = this.sigma;
				s.camera.goTo({
		 			x: this.sigma.camera.x,
		 			y: this.sigma.camera.y,
		 			angle: this.sigma.camera.angle,
		 			ratio: ratio,
		 		});
		 		this.sigma = s;
		 		this.sigma.refresh();
			}
		}
	}

	public setCustomZoomAnimation(axisX, axisY, ratio) {
		let s = this.sigma;
		s.cameras[0].goTo({
			x: axisX,
			y: axisY,
			angle: this.sigma.camera.angle,
			ratio: ratio,
		});
		this.sigma = s;
		this.sigma.refresh();

		var bounds = sigma.utils.getBoundaries(
			this.sigma.graph,
			"read_cam0:",
			false
		);
		var cx = bounds.minX + (bounds.maxX - bounds.minX) / 2;
		var cy = bounds.minY + (bounds.maxY - bounds.minY) / 2;
		var width = bounds.maxX - bounds.minX + bounds.sizeMax * 2;
		var height = bounds.maxY - bounds.minY + bounds.sizeMax * 2;
		var rendererWidth = this.sigma.renderers[0].width;
		var rendererHeight = this.sigma.renderers[0].height;
		var maxRatio = Math.max(
			width / rendererWidth,
			height / rendererHeight + GRAPHMARGIN
		);
		if (width / rendererWidth >= height / rendererHeight) maxRatio += 0.5;
		else maxRatio += 0.2;
		if (this.loginService.esEstudiante()) {
			let interval = setInterval(() => {
				this.zoomIn();
				ratio -= 2;
				if (ratio < maxRatio) {
					clearInterval(interval);
				}
			}, 0.2);
		} else {
			let interval = setInterval(() => {
				this.zoomIn();
				ratio -= 0.5;
				if (ratio < maxRatio) {
					clearInterval(interval);
				}
			}, 0.5);
		}
	}

	// ---------------------------------------------------------------------
	//   T E M P    N O D E    (P O P O V E R)
	// ---------------------------------------------------------------------

	public addTempNode(node, cx, cy, author?: User) {
		this.dropNode(SIGMA_CONSTANTS.NODE_TEMP);
		var p = this.sigma.camera.cameraPosition(cx, cy);

		var px = p.x;
		var py = p.y;

		node.x = px;
		node.originalX = px;
		node.y = py;
		node.originalY = py;
		node.size = 0.1;
		node.originalColor = "#c9c9c9";
		node.color = "#c9c9c9";

		if (author) {
			node.user = author;
		}

		this.addNode(node);
		const temporalNodes = JSON.parse(localStorage.getItem('temporalNodes') || '[]');
		temporalNodes.push(node);
		localStorage.setItem('temporalNodes', JSON.stringify(temporalNodes));

		this.sigma.refresh();
	}

	// ----------------------------------------------------------------------
	//   S E L E C T I O N
	// ----------------------------------------------------------------------

	public emptySelection() {
		this.selection = [];
	}

	public selectionIncludes(node) {
		return this.selection.includes(node);
	}

	public dropElementFromSelection(node) {
		this.selection = this.selection.filter((ele) => {
			return ele !== node;
		});
	}

	// ----------------------------------------------------------------------
	//   [[  F O R C E A T L A S 2  ]]  L A Y O U T    O P T I M I Z E
	// ----------------------------------------------------------------------

	public layoutOptimize() {
		let s = this.sigma;

		//Start the ForceAtlas2 algorithm to optimize network layout
		s.startForceAtlas2({
			worker: false,
			slowDown: 1000,
			iterationsPerRender: 100,
			edgeWeightInfluence: 0,
			scalingRatio: 50,
			strongGravityMode: true,
		});

		//Set time interval to allow layout algorithm to converge on a good state
		var t = 0;
		var interval = setInterval(function () {
			t += 1;
			if (t >= 5) {
				clearInterval(interval);
				s.stopForceAtlas2();
			}
		}, 100);

		s.cameras[0].goTo({ x: 0, y: 0, angle: 0, ratio: 0.1 });

		this.sigma = s;

		this.sigma.refresh();
	}

	// ----------------------------------------------------------------------
	//   S N A P S H O T
	// ----------------------------------------------------------------------
	public snapshot() {
		this.centerGraph();
		return this.sigma.renderers[0].snapshot({ download: false });
	}

	// ----------------------------------------------------------------------
	//   L O A D   J S O N   F I L E
	// ----------------------------------------------------------------------

	public loadJSON(url, forceAtlas2Params = this.forceAtlas2Params) {
		sigma.parsers.json(url, this.sigma, function () {
			// this below adds x, y attributes as well as size = degree of the node
			var i,
				nodes = this.sigma.graph.nodes(),
				len = nodes.length;

			for (i = 0; i < len; i++) {
				nodes[i].x = Math.random();
				nodes[i].y = Math.random();
				// nodes[i].size = s.graph.degree(nodes[i].id);
				nodes[i].color = nodes[i].center ? "#333" : "#666";
			}

			// Refresh the display:
			this.sigma.refresh();

			//Start the ForceAtlas2 algorithm to optimize network layout
			this.sigma.startForceAtlas2(forceAtlas2Params);

			//Set time interval to allow layout algorithm to converge on a good state
			var t = 0;
			var interval = setInterval(function () {
				t += 1;
				if (t >= 20) {
					//30
					clearInterval(interval);
					this.sigma.stopForceAtlas2();
				}
			}, 100);
		});
		this.sigma.cameras[0].goTo({ x: 0, y: 0, angle: 0, ratio: 0.1 });
	}

	// ----------------------------------------------------------------------
	//    E V E N T S
	// ----------------------------------------------------------------------

	@Output() clickStageEvent = new EventEmitter<any>();
	private emitclickStageEvent(data) {
		this.clickStageEvent.emit(data);
	}
	public getemitclickStageEvent() {
		return this.clickStageEvent;
	}

	@Output() clickNodeEvent = new EventEmitter<any>();
	private emitclickNodeEvent(data) {
		this.clickNodeEvent.emit(data);
	}
	public getemitclickNodeEvent() {
		return this.clickNodeEvent;
	}

	@Output() doubleclickNodeEvent = new EventEmitter<any>();
	private emitdoubleclickNodeEvent(data) {
		this.doubleclickNodeEvent.emit(data);
	}
	public getemitdoubleclickNodeEvent() {
		return this.doubleclickNodeEvent;
	}

	@Output() overNodeEvent = new EventEmitter<any>();
	private emitoverNodeEvent(data) {
		this.overNodeEvent.emit(data);
	}
	public getemitoverNodeEvent() {
		return this.overNodeEvent;
	}

	@Output() outNodeEvent = new EventEmitter<any>();
	private emitoutNodeEvent(data) {
		this.outNodeEvent.emit(data);
	}
	public getemitoutNodeEvent() {
		return this.outNodeEvent;
	}

	@Output() dragEvent = new EventEmitter<any>();
	private emitDragEvent(data) {
		this.dragEvent.emit(data);
	}
	public getemitDragEvent() {
		return this.dragEvent;
	}

	@Output() dropEvent = new EventEmitter<any>();
	private emitDropEvent(data) {
		this.dropEvent.emit(data);
	}
	public getemitDropEvent() {
		return this.dropEvent;
	}

	@Output() startDragEvent = new EventEmitter<any>();
	private emitstartDragEvent(data) {
		this.startDragEvent.emit(data);
	}
	public getemitstartDragEvent() {
		return this.startDragEvent;
	}

	@Output() endDragEvent = new EventEmitter<any>();
	private emitendDragEvent(data) {
		this.endDragEvent.emit(data);
	}
	public getemitendDragEvent() {
		return this.endDragEvent;
	}

	@Output() doubleClickStageEvent = new EventEmitter<any>();
	private emitdoubleClickStageEvent(data) {
		this.doubleClickStageEvent.emit(data);
	}
	public getemitdoubleClickStageEvent() {
		return this.doubleClickStageEvent;
	}

	@Output() rightClickStageEvent = new EventEmitter<any>();
	private emitrightClickStageEvent(data) {
		this.rightClickStageEvent.emit(data);
	}
	public getemitrightClickStageEvent() {
		return this.rightClickStageEvent;
	}

	@Output() rightClickNodeEvent = new EventEmitter<any>();
	private emitrightClickNodeEvent(data) {
		this.rightClickNodeEvent.emit(data);
	}
	public getemitrightClickNodeEvent() {
		return this.rightClickNodeEvent;
	}
	@Output() mouseWheel = new EventEmitter<any>();
	private emitMouseWheelEvent(data) {
		this.mouseWheel.emit(data);
	}
	public getEmitMouseWheelEvent() {
		return this.emitMouseWheelEvent;
	}

	/**
	 * EDGE EVENTS
	 */

	@Output() clickEdgeEvent = new EventEmitter<any>();
	private emitclickEdgeEvent(data) {
		this.clickEdgeEvent.emit(data);
	}
	public getemitclickEdgeEvent() {
		return this.clickEdgeEvent;
	}

	@Output() overEdgeEvent = new EventEmitter<any>();
	private emitoverEdgeEvent(data) {
		this.overEdgeEvent.emit(data);
	}
	public getemitoverEdgeEvent() {
		return this.overEdgeEvent;
	}

	@Output() outEdgeEvent = new EventEmitter<any>();
	private emitoutEdgeEvent(data) {
		this.outEdgeEvent.emit(data);
	}
	public getemitoutEdgeEvent() {
		return this.outEdgeEvent;
	}

	/**
	 * END EDGE EVENTS
	 */

	private defineEvents() {
		// ---------------------------------
		// ******** DRAG AND DROP **********
		// ---------------------------------
		var dragListener = sigma.plugins.dragNodes(
			this.sigma,
			this.sigma.renderers[0]
		);

		dragListener.bind("startdrag", (e) => {
			this.emitstartDragEvent(e);
		});
		dragListener.bind("dragend", (e) => {
			this.emitendDragEvent(e);
		});
		dragListener.bind("drag", (e) => {
			this.emitDragEvent(e);
		});
		dragListener.bind("drop", (e) => {
			this.emitDropEvent(e);
		});

		// ----------------------------------
		// ******** OVERNODE OUTNODE ********
		// ----------------------------------
		this.sigma.bind("overNode", (e) => {
			this.emitoverNodeEvent(e);
		});
		this.sigma.bind("outNode", (e) => {
			this.emitoutNodeEvent(e);
		});

		// ---------------------------------
		// ******** NODES ********
		// ---------------------------------
		this.changeEvent("clickNode", (e) => {
			// Envía Evento a suscriptores
			this.emitclickNodeEvent(e);
		});
		this.changeEvent("doubleClickNode", (e) => {
			// Envía Evento a suscriptores
			this.emitdoubleclickNodeEvent(e);
		});
		this.changeEvent("rightClickNode", (e) => {
			// Envía Evento a suscriptores
			this.emitrightClickNodeEvent(e);
		});

		// ---------------------------------
		// ******** STAGE ********
		// ---------------------------------
		this.sigma.bind("doubleClickStage", (e) => {
			this.emitdoubleClickStageEvent(e);
		});
		this.sigma.bind("rightClickStage", (e) => {
			this.emitrightClickStageEvent(e);
		});
		this.changeEvent("clickStage", (e) => {
			this.emitclickStageEvent(e);
		});

		/**
		 * EDGES
		 */

		this.sigma.bind("clickEdge", (e) => this.emitclickEdgeEvent(e));
		this.sigma.bind("overEdge", (e) => this.emitoverEdgeEvent(e));
		this.sigma.bind("outEdge", (e) => this.emitoutEdgeEvent(e));
	}

	private changeEvent(name, func) {
		this.unbindEvent(name);
		this.sigma.bind(name, func);
	}

	private unbindAllEvents() {
		// OJO!

		var events = [
			"dragend",
			"outNode",
			"clickNode",
			"overNode",
			"clickStage",
			"clickEdge",
			"overEdge",
			"outEdge",
		];
		events.forEach((e) => {
			this.sigma.unbind(e);
		});
	}

	private unbindEvent(evName) {
		this.sigma.unbind(evName);
	}

	public getNodesOnDropEvent(e: any) {
		var eX, eY;
		var fnodes: [];

		if (e.data.node) {
			eX = e.data.node.x;
			eY = e.data.node.y;
		} else {
			return [];
		}

		var nodes = this.sigma.camera.quadtree.point(eX, eY);

		if (nodes.length) {
			fnodes = nodes.filter((element) => {
				return element.id !== e.data.node.id;
			});
		}
		// this.sigma.refresh();
		return fnodes;
	}

	public defineText(profile: string, loginService: LoginService) {
		var _cache = {};
		sigma.canvas.nodes.text = function (node, context, settings) {
			var prefix = settings("prefix") || "",
				size = node[prefix + "size"],
				x = node[prefix + "x"],
				y = node[prefix + "y"];
			let drawImage: boolean = false;

			// ---------------------------------
			//  Z - I N D E X
			// ---------------------------------

			context.save();

			// ------------------------------------
			// S H A D O W
			// ------------------------------------
			context.shadowColor = "rgb(0,0,0,0.1)"; //'#D5DBDB'; //'#DEDEDE';
			context.shadowBlur = 10;
			context.shadowOffsetX = 2;
			context.shadowOffsetY = 2;
			// -------------------------------------
			//  W H I T E    B A C K G R O U N D
			// -------------------------------------
			context.beginPath();
			context.rect(x - size, y - size * 0.5, size * 2, size * 1.1); //context.rect(x,y,width,height)
			context.closePath();

			context.lineJoin = "round";
			context.fillStyle = "white";
			context.fill();
			// ------------------------------------

			// ------------------------------------
			// S H A D O W      N O N E
			// ------------------------------------
			context.shadowBlur = 0;
			context.shadowOffsetX = 0;
			context.shadowOffsetY = 0;
			// ------------------------------------

			// ---------------------------------------------
			// N O D E    B O R D E R
			// ---------------------------------------------
			context.lineJoin = "miter";

			context.beginPath();

			context.rect(x - size, y - size * 0.5, size * 2, size * 1.1);

			if (node.backgroundColor) {
				context.fillStyle = node.backgroundColor;
				context.fill();
			}
			if (profile === Profiles.Author) {
				context.lineWidth = size / 20;
				context.strokeStyle = "#000";
			} else {
				context.lineWidth = 2; //size / 20;
				context.strokeStyle = node.color || settings("defaultNodeColor"); //Color del borde que viene del servidor
			}
			context.stroke();

			// ------------------------------------
			// T E X T
			// ------------------------------------

			let text: string = node.quizTittle
				? node.quizTittle
				: SIGMA_CONSTANTS.QUIZ_TYPE;
			let currentUser = loginService.getUser();

			if (profile !== Profiles.Student) {
				var fontSize = size / 2.2;
				y = y - size * 0.005;
				x = x - size * 0.005;

				context.fillStyle = "rgba(0,0,0)";
				context.strokeStyle = "rgba(255,255,255)";
				context.textAlign = "center";
				context.textBaseline = "middle";
				context.font = "bold " + fontSize + "px " + DEFAULTFONTFAMILY;

				//if (profile === Profiles.Author && currentUser.idUser === node.user.idUser)
				//Alex: se debería de buscar si el editor esta en la lista de editores posibles? si no hace falta dejarlo así o ponerlo como sea correcto
				if (profile === Profiles.Author) context.fillText(text, x, y);
				else drawImage = true;
			} else drawImage = true;

			if (drawImage) {
				y = y - size * 0.28;
				x = x - size * 0.3;

				if (!_cache[node.id]) {
					let img = new Image();
					if(node.ordinalType == 0){
						img.src = imageQuiz;
					} else if(node.ordinalType == 1){
						img.src = imageQuizGo;
					} else if(node.ordinalType == 2){
						img.src = imageQuizGoal;
					}					
					_cache[node.id] = img;
					img.onload = function () {
						context.drawImage(img, x, y, size / 2, size / 1.5);
					};
				} else{
					if(node.ordinalType == 0){
						context.drawImage(_cache[node.id], x, y, size / 2, size / 1.5);
					} else{
						context.drawImage(_cache[node.id], x - size * 0.68, y - size * 0.17, size * 1.95, size);
					}
				}
			}
			context.restore();
		};
	}

	public defineImage() {
		sigma.canvas.nodes.image = (function () {
			var _cache = {};

			var renderer = function (node, context, settings) {
				var args = arguments,
					prefix = settings("prefix") || "",
					x = node[prefix + "x"],
					y = node[prefix + "y"],
					size = node[prefix + "size"],
					height = 0,
					width = 0,
					ratio = 0;

				//Check imagen aspect ratio
				if (node.high && node.width) {
					ratio = node.width / node.high;

					if (ratio == 1) {
						height = size * 2;
						width = size * 2;
					} else {
						height = size;
						width = Math.ceil(size * ratio);
					}
				} else {
					height = size * 2;
					width = size * 2;
				}

				context.save();

				context.globalCompositeOperation = "destination-over"; //Sticker on the back

				if (!_cache[node.id]) {
					var img = new Image();
					img.src = node.url;
					_cache[node.id] = img;
					img.onload = function () {
						context.drawImage(
							img,
							x - width / 2,
							y - height / 2,
							width,
							height
						);
					};
				} else
					context.drawImage(
						_cache[node.id],
						x - width / 2,
						y - height / 2,
						width,
						height
					);

				context.restore();
			};

			return renderer;
		})();
	}

	// private defineSquare() {
	//     sigma.canvas.nodes.square = function (node, context, settings) {
	//         var prefix = settings('prefix') || '',
	//             size = node[prefix + 'size'];

	//         context.fillStyle = node.color || settings('defaultNodeColor');
	//         context.beginPath();
	//         context.rect(
	//             node[prefix + 'x'] - size,
	//             node[prefix + 'y'] - size,
	//             size * 2,
	//             size * 2
	//         );

	//         context.closePath();
	//         context.fill();
	//     };

	// }

	public overrideClear(stairs, margin = 0) {
		sigma.renderers.canvas.prototype.drawAxis = function () {
			this.drawAxis(margin);
		}.bind(this);

		if (stairs) {
			sigma.renderers.canvas.prototype.clear = function () {
				for (var k in this.contexts) {
					this.contexts[k].clearRect(0, 0, this.width, this.height);
				}

				this.drawAxis();
				return this;
			};
		} else {
			sigma.renderers.canvas.prototype.clear = function () {
				for (var k in this.contexts) {
					this.contexts[k].clearRect(0, 0, this.width, this.height);
				}
				return this;
			};
		}
	}

	public drawAxis(margin) {
		var rendererHeight = this.rendererHeight();
		var rendererWidth = this.rendererWidth();

		var canvas = document.getElementsByClassName("sigma-scene");

		if (canvas && canvas.length > 0) {
			var ctx: CanvasRenderingContext2D = (
				canvas[0] as HTMLCanvasElement
			).getContext("2d");

			ctx.strokeStyle = "#000000";

			ctx.lineWidth = 2;
			this.drawArrowhead(
				ctx,
				margin,
				rendererHeight - margin,
				margin,
				rendererHeight * 0.2
			); //margin);
			ctx.stroke();
			this.axisText(ctx, 0, rendererHeight / 2, "Level", true);
			this.drawArrowhead(
				ctx,
				margin,
				rendererHeight - margin,
				rendererWidth * 0.8,
				rendererHeight - margin
			);
			this.axisText(
				ctx,
				rendererWidth / 2,
				rendererHeight - margin,
				"Duration",
				false
			);
			ctx.stroke();
		}
	}

	public axisText(ctx, x, y, text, rotate) {
		var font, x, y;

		//Set font size before measuring
		font = 12;
		ctx.font = "bold " + font + "px " + DEFAULTFONTFAMILY + ", sans-serif";
		//Get width of text
		var metrics = ctx.measureText(text);
		//Set canvas dimensions

		//After a canvas resize, the context is reset. Set the font size again
		ctx.font = font + "px " + DEFAULTFONTFAMILY;
		//Set the drawing coordinates
		// x = font/2;
		// y = metrics.width/2;
		//Style
		ctx.fillStyle = "black";
		//ctx.textAlign = 'right';
		//ctx.textBaseline = "top";
		//Rotate the context and draw the text
		ctx.save();
		ctx.translate(x, y);
		if (rotate) {
			ctx.rotate(-Math.PI / 2);
		}
		ctx.fillText(text, 0, font);
		ctx.restore();
	}

	public drawArrowhead(context, fromx, fromy, tox, toy) {
		var x_center = tox;
		var y_center = toy;
		var r = 10;
		var angle;
		var x;
		var y;

		context.beginPath();

		angle = Math.atan2(toy - fromy, tox - fromx);
		x = r * Math.cos(angle) + x_center;
		y = r * Math.sin(angle) + y_center;

		context.moveTo(x, y);

		angle += (1 / 3) * (2 * Math.PI);
		x = r * Math.cos(angle) + x_center;
		y = r * Math.sin(angle) + y_center;

		context.lineTo(x, y);

		angle += (1 / 3) * (2 * Math.PI);
		x = r * Math.cos(angle) + x_center;
		y = r * Math.sin(angle) + y_center;

		context.lineTo(x, y);

		context.closePath();
		context.moveTo(fromx, fromy);
		context.lineTo(tox, toy);
		context.fill();
	}

	public getMousePos(canvas: any, evt: any) {
		var rect = canvas.getBoundingClientRect();
		return {
			x: evt.clientX - rect.left,
			y: evt.clientY - rect.top,
		};
	}

	private getImageDimensions(url: string): Promise<Dimensions> {
		return new Promise((resolve) => {
			let img = new Image();

			img.src = url;

			img.onload = function (e: any) {
				resolve({
					width: img.width,
					height: img.height,
				});
			};
			img.src = url;
		});
	}

	public incrementNodeSize(data) {
		data.size = 60;
		this.refresh();
	}
	public decrementNodeSize(data) {
		data.size = 30;
		this.refresh();
	}

	public showAllStickers() {
		this.sigma.graph.nodes().forEach(function (n) {
			if (!n.hidden) n.hidden = true;
		});
	}

	public nodeAnimation(value): any {
		let isNodeHight: boolean = false;

		return setInterval(() => {
			if (!isNodeHight) {
				this.incrementNodeSize(value);
			} else {
				this.decrementNodeSize(value);
			}
			isNodeHight = !isNodeHight;
		}, 800);
	}
}
