<div class="node-menu">

	<div #menuIzquierdo2>
		<ng-container>
			<div class="circle-button" (click)="openNav()">
				<mat-icon svgIcon="menu_close" class="icon-color-blue"
					ngbTooltip="{{ 'SIDEBARDERECHO.ABRIRMENU' | translate }}"></mat-icon>
			</div>
		</ng-container>
	</div>

	<div #mySidebar2 class="sidebar2" >
		<mat-icon class="icon-color-blue closebtn pointer" ngbTooltip="{{ 'SIDEBARDERECHO.CERRAR' | translate }}" (click)="closeNav()">close</mat-icon>

		  <!-- <a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer" (click)="showHidePads()" ngbTooltip="{{ padStatus  ? ('SIDEBARDERECHO.DESACTIVARMANDOS' | translate) :  ('SIDEBARDERECHO.ACTIVARMANDOS' | translate)}}">
			<mat-icon svgIcon="{{padStatus ? 'sports_esports_outlined' : 'sports_esports'}}" class="icon-color-blue ml-4"  ></mat-icon>
			<span class="ml-2"> &nbsp;{{ padStatus  ? ('SIDEBARDERECHO.DESACTIVARMANDOS' | translate) :  ('SIDEBARDERECHO.ACTIVARMANDOS' | translate)}} </span>
		</a> -->

		<a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer disabled" ngbTooltip="{{'PADS.GENERATEBOOK' | translate }}">
			<img style="width: 24px; height: 24px" class="icon-head icon-color-blue ml-4" src="assets/images/icons/libro_magico.png" />
			<span class="ml-2"> &nbsp;{{'PADS.GENERATEBOOK' | translate }} </span>
		</a>

		<a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer disabled" ngbTooltip="{{'SIDEBARDERECHO.COMPARTIR' | translate }}">
			<app-share-button class="ml-4"></app-share-button>
			<span class="ml-2"> &nbsp;{{'SIDEBARDERECHO.COMPARTIR' | translate }} </span>
		</a>

		<a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer disabled" ngbTooltip="{{'NODOS.MESSAGE' | translate }}">
			<app-report-button class="ml-4"></app-report-button>
			<span class="ml-2"> &nbsp;{{'NODOS.MESSAGE' | translate }} </span>
		</a>

		<a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer disabled" (click)="imprimir()" ngbTooltip="{{'NODOS.PRINT' | translate }}">
			<mat-icon class="icon-color-blue ml-4">print</mat-icon>
			<span class="ml-2"> &nbsp;{{'NODOS.PRINT' | translate }} </span>
		</a>

		<a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer disabled" (click)="showInfo()" ngbTooltip="{{'NODOS.DETAIL' | translate }}">
			<mat-icon class="icon-color-blue ml-4">info</mat-icon>
			<span class="ml-2"> &nbsp;{{'NODOS.DETAIL' | translate }} </span>
		</a>

		<a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer" (click)="showHideLabels()" ngbTooltip="{{ labelStatus ? ('SIDEBARDERECHO.DISABLELABELS' | translate) : ('SIDEBARDERECHO.ENABLELABELS' | translate)}}">
			<mat-icon class="icon-color-blue ml-4" svgIcon="bocadillo"></mat-icon>
			<span class="ml-2"> &nbsp;{{ labelStatus ? ('SIDEBARDERECHO.DISABLELABELS' | translate) : ('SIDEBARDERECHO.ENABLELABELS' | translate)}} </span>
		</a>

	</div>

</div>
