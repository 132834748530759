import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { Component, OnInit, Input} from '@angular/core';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { LoginService } from 'src/app/core/services/login/login.service';

declare var $: any;

@Component({
  selector: 'app-modal-reproducir-quizzes',
  templateUrl: './modal-reproducir-quizzes.component.html',
  styleUrls: ['./modal-reproducir-quizzes.component.scss']
})
export class ModalReproducirQuizzesComponent implements OnInit {
mensajeQuiz:string;
quizEnviado:any;
mostrar:boolean;
@Input() type:string;
@Input() node:any;
@Input() quiz:any;
@Input() idCurso:string;
@Input() idMapa:number;
@Input() currentGraph:any;
@Input() automatic:any;

constructor(private quizService: QuizzesService,
            public loginService: LoginService) {
                 this.quizService.automatic.subscribe((n) => {
                    this.automatic = n;
                  });
 }

ngOnInit() {
    this.automatic=JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.AUTOMATIC));
    if(this.type=="quiz"){
        this.quizService.setCurrentQuiz(this.node);
    }else{
        this.quizService.setCurrentNode(this.node);
    }
    this.currentGraph.idTarget=this.idMapa;
    this.currentGraph.idCourse=this.idCurso;
    this.quizService.setCurrentGraph(this.currentGraph);
    this.quizService.setAutomatic(this.automatic);
    this.quizService.setType(this.type);
    if(this.type=="quiz"){
        this.mostrarPregunta(this.node.idOriginal);
    }
}

public mostrarPregunta(id:number){
  }

  obtenerQuizAsociado(arrayQuizzes:any){
    var min = 0
    var max =arrayQuizzes.length -1
    var i = Math.floor(Math.random() * (max - min) + min);
    return arrayQuizzes[i];
   }

  //Evento desde el Foot
  mostrarQuiz(quiz:any){
    // this.bodyReproducir.showQuiz(quiz);
  }
}
