import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-barras-profesor',
  templateUrl: './barras-profesor.component.html',
  styleUrls: ['./barras-profesor.component.scss']
})
export class BarrasProfesorComponent implements OnInit{
	@Input() parametroID: number | null = null;
	@Input() consultaID: number | null = null;
	@Input() datasetsImported: any = null;
	@Input() labelsImported: any = null;

	chart: any = null;
	labels: any[] = [];
	datasets: any[] = [];

  constructor(
		private translateService: TranslateService,
	) { }

  ngOnInit() {
		if (this.chart) {
			this.chart.destroy();
		}
		if (this.consultaID !== null) {
			this.datasets = this.datasetsImported;
			this.labels = this.labelsImported;
    }
		setTimeout(() =>	{
			if(this.parametroID == 1 && this.consultaID != null){
				this.createChartAciertos();
			}
		}, 200);
  }

	createChartAciertos(){
		var canvas = document.getElementById('myChartControlProfesor') as HTMLCanvasElement;
		var ctx = canvas.getContext('2d');
		if (this.chart) {
			this.chart.destroy();
		}
		this.chart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: this.labels, // Etiquetas para las barras
				datasets: this.datasets
			},
			options: {
				//indexAxis: 'y',
				responsive : true,
				plugins: {
					legend: {
						display: false,
						position: 'right',
					},
					filler: {
						propagate: true,
					},
					zoom: {
						zoom: {
							wheel: {
								enabled: false,
								//modifierKey: 'ctrl', // Se activa con la tecla Control
							},
							pinch: {
								enabled: false,
							},
							mode: 'x',
						},
						pan:{
							enabled: true,
							mode: 'x',
							scaleMode: 'x',
						},
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								let label = context.dataset.label || '';
								if (label) {
									label += ': ';
								}
								label += context.raw + '%'; // Formatea los valores del tooltip como porcentajes
								return label;
							}
						}
					}
				},
				scales: {
					x: {
						display: true,
						stacked: false,
						border: {
							display: true,
							color: '#000', // Color del eje x
							width: 3 // Grosor del eje x
						},
						title: {
							display: false,
							text: this.translateService.instant('INFORMES.AVERAGETIMEPERMINUTE'),
						},
					},
					y: {
						stacked: false,
						suggestedMin: 0,
						position: 'top',
						border: {
							display: true,
							color: '#000', // Color del eje x
							width: 3 // Grosor del eje x
						},
						ticks: {
							callback: function(value) {
								return value + '%'; // Formatea los valores como porcentajes
							}
						}
					}
				},
			}
		});
	}

}
