import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Utils
import { LocalStorage } from 'src/app/core/utils';
import * as uuid from 'uuid';
import { environment } from 'src/environments/environment';

// Models
import { UserRegisterModel, UserProfileValidationModel, UserStudentParentModel,
         UserParentModel, UserStudentTeacherModel, UserTeacherModel, UserSettingsProfileModel } from 'src/app/core/models/users';

import { CenterModel } from 'src/app/core/models/masters';

// Services
import { TranslateService } from '@ngx-translate/core';
import { AlertService, TitleService } from 'src/app/core/services/shared';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';
import { MastersService } from 'src/app/core/services/masters';

// Mapper
import { UsersRegisterMapperService, UsersParentsMapperService, UsersStudentsParentsMapperService,
         UsersProfileValidationMapperService, CentersMapperService } from 'src/app/core/services/mapper';

// Components
import { ModalStudentCentersComponent } from '../modal-student-centers';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data-estudiante.component.html',
  styleUrls: ['../user-data-general/user-data-general.component.scss']
})
export class UserDataEstudianteComponent implements OnInit {

  public formularioDatosPadre: UntypedFormGroup;
  public formularioDatosCentro: UntypedFormGroup;
  public formularioDatosProfesores: UntypedFormGroup;

  validationMessages = {
    firstName: [],
    surname: [],
    mail: [],
    birthdate: [],
    mobile: []
  };

  verDetallePadre = false;
  verDetalleCentro = false;

  userData: UserRegisterModel;
  usersParentData: UserParentModel;

  usersProfileValidationData: UserProfileValidationModel[];

  parentsData: UserStudentParentModel[] = [];
  parentSelected = false;
  parentValidated = false;
  idParentSelected: number;

  centersData: CenterModel[] = [];
  centerData: CenterModel;
  centerValidated = false;
  centerSelected = false;
  centerTeacherSelected = false;
  idCenterSelected: number;

  teachersData: UserTeacherModel[] = [];
  teachersFilterData: UserRegisterModel[] = [];

  usersSettingsProfileData: UserSettingsProfileModel[];

  centers: any[] = [];

  mensajeEnvioValidacion: string;
  mensajeEnvioValidado: string;

  public loading = false;

  constructor(private formBuild: UntypedFormBuilder,
              public translateService: TranslateService,
              private titleService: TitleService,
              private alertService: AlertService,
              private localStorage: LocalStorage,
              private modalService: NgbModal,
              public loginService: LoginService,
              public usersService: UsersService,
              private mastersService: GetDataService,
              private usersRegisterMapperService: UsersRegisterMapperService,
              private usersParentsMapperService: UsersParentsMapperService,
              private centersMapperService: CentersMapperService,
              private usersStudentsParentsMapperService: UsersStudentsParentsMapperService,
              private usersProfileValidationMapperService: UsersProfileValidationMapperService,
              private activeModal: NgbActiveModal
              ) {

  }

  ngOnInit(): void {

    // Obtengo los maestros
    this.obtenerDatosMaestros();

    // Lanzo las traducciones
    this.traducirOpciones();

    // Cargo las validaciones del formulario
    this.cargarValidacionesFormulario();

    // Cargo los formularios
    this.crearFormularios();

    // Y obtengo la informacion del usuario
    this.obtenerDatosUsuario();
  }

  private obtenerDatosMaestros() {
      this.centers = this.mastersService.appCenters
  }

  private traducirOpciones() {
    // Recupero el lenguaje
    const lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
    this.translateService.use(lang);

    // Titulo pagina
    this.translateService.get('USERDATA.CONFIGURACIONESTUDIANTE').subscribe((res: string)  => {
      this.titleService.setHTMLTitle(res);
      this.titleService.setBarTitle(res);
    });
  }

  private cargarValidacionesFormulario() {

    this.translateService.get('VALIDACIONES.ELNOMBREESOBLIGATORIO').subscribe((res: string) => {
      this.validationMessages.firstName.push({type: 'required', message: res});
    });

    this.translateService.get('VALIDACIONES.LOSAPELLIDOSSONOBLIGATORIOS').subscribe((res: string) => {
        this.validationMessages.surname.push({type: 'required', message: res});
    });

    this.translateService.get('VALIDACIONES.ELTELEFONOMOVILESOBLIGATORIO').subscribe((res: string) => {
      this.validationMessages.mobile.push({type: 'required', message: res});
    });

    this.translateService.get('VALIDACIONES.ELTELEFONOMOVILNOESVALIDO').subscribe((res: string) => {
      this.validationMessages.mobile.push({type: 'pattern', message: res});
    });

    this.translateService.get('VALIDACIONES.LAFECHADENACIMIENTOESOBLIGATORIA').subscribe((res: string) => {
      this.validationMessages.birthdate.push({type: 'required', message: res});
    });
  }

  public mostrarMensajeAviso() {

    // Para poder hacer las comparaciones
    this.userData.usersProfileValidation = this.usersProfileValidationData;

    const resp = this.usersService.checkIfUserDataAreRegistered(this.userData, 'estudiante');

    if (resp.result !== true) {
        this.alertService.info(this.translateService.instant(resp.mensaje),
                               AlertService.AlertServiceContextValues.UserData);
    } else {
      this.alertService.clear();
    }
  }

  private crearFormularios() {

    // Datos padre
    this.formularioDatosPadre = this.formBuild.group({
      idUserStudentParent: [0],
      idUser: [0],
      firstName: ['', Validators.required],
      surname: ['', Validators.required],
      mail: [''],
      birthdate: [''],
      birthdateString: [''],
      mobile: new UntypedFormControl('', [
        Validators.required,
        // tslint:disable-next-line: max-line-length
        Validators.pattern('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})'),
      ]),
      creationDate: [null],
      creationDateString: [null]
    });

    // Datos centro
    this.formularioDatosCentro = this.formBuild.group({
      pictureUser: [''],
      idCenter: [''],
      centerName: [''],
      centerAddress: [''],
      centerCountry: [''],
      centerRegion: [''],
      centerMail: [''],
      centerPhone: [''],
      centerUrl: ['']
    });

    // Datos profesores
    this.formularioDatosProfesores = this.formBuild.group({
      idUser: [''],
      idCenter: [''],
      idTeacher: [''],
      creationDate: ['']
    });
  }

  private registrarUserSettingProfile() {

    // Y si era la primera vez que grababa algo siendo estudiante lo registro en users_settings_profile
    if (this.usersSettingsProfileData.length === 0 ||
        this.usersSettingsProfileData.filter(x => x.profile === 'estudiante').length === 0) {

      let userSettingProfile = new UserSettingsProfileModel();

      userSettingProfile.idUserSettingProfile = 0;
      userSettingProfile.idUser = this.userData.idUser;
      userSettingProfile.profile = 'estudiante';
    }
  }

  private obtenerDatosUsuario() {

    this.loading = true;

    this.usersService.getUserByIdUser(this.loginService.getUser().idUser)
                      .subscribe( resp => {
                        if (resp) {

                          // tslint:disable-next-line: max-line-length
                          //this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear formulario datos generales - Datos de usuario estudiante`, 'OK');

                          // Mapeo los datos del usuario
                          this.userData = this.usersRegisterMapperService.transform(resp.data.users.nodes[0]);

                          // Mapeo los datos del usuario-padre
                          if (resp.data.users.nodes[0].usersParents.length > 0) {
                            this.usersParentData = this.usersParentsMapperService.transform(resp.data.users.nodes[0].usersParents[0]);
                          }

                          // Los de las validaciones del usuario (si las tiene)
                          if (resp.data.users.nodes[0].usersProfileValidation.length > 0) {
                            // tslint:disable-next-line: max-line-length
                            this.usersProfileValidationData = this.usersProfileValidationMapperService.transform(resp.data.users.nodes[0].usersProfileValidation);
                          }

                          // Los de los padres del usuario (si los tiene)
                          if (resp.data.users.nodes[0].usersStudentsParents.length > 0) {

                            // tslint:disable-next-line: max-line-length
                            this.parentsData = this.usersStudentsParentsMapperService.transform(resp.data.users.nodes[0].usersStudentsParents);

                            this.parentsData.forEach(parent => {

                              if (this.usersProfileValidationData !== undefined) {
                                // tslint:disable-next-line: max-line-length
                                const parentValidations = this.usersProfileValidationData.filter(x => x.idUserStudentParent === parent.idUserStudentParent);

                                if (parentValidations.filter(x => x.validationDate !== undefined && x.validationDate != null).length > 0) {
                                  parent.validated = true;
                                } else {
                                  parent.validated = false;
                                }
                              } else {
                                parent.validated = false;
                              }
                            });
                          }

                          // Los de los centros del estudiante (si los tiene)
                          if (resp.data.users.nodes[0].usersStudentsCenters.length > 0) {

                            resp.data.users.nodes[0].usersStudentsCenters.forEach(centerData => {

                              if (centerData.center.length > 0) {
                                let center = centerData.center[0];

                                // tslint:disable-next-line: max-line-length
                                center.idUserStudentCenter = resp.data.users.nodes[0].usersStudentsCenters.find(x => x.idCenter === center.idCenter).idUserStudentCenter;

                                this.centersData.push(this.centersMapperService.transform(center));
                              }
                            });
                          }

                          // Los de los profesores del estudiante (si los tiene)
                          if (resp.data.users.nodes[0].usersStudentsTeachers.length > 0) {

                            resp.data.users.nodes[0].usersStudentsTeachers.forEach(teacherData => {

                              if (teacherData.teacher.length > 0) {
                                let teacher = teacherData.teacher[0];

                                // Y completo los datos
                                teacher.idUserStudentTeacher = teacherData.idUserStudentTeacher;
                                teacher.validationDate = teacherData.validationDate;
                                teacher.validationDateString = teacherData.validationDateString;

                                this.teachersData.push(teacher);
                              }
                            });
                          }

                          // Cargo los user settings profile para ver que menus van en gris
                          this.usersSettingsProfileData = resp.data.users.nodes[0].usersSettingsProfiles;

                          if (this.usersSettingsProfileData === undefined) {
                            this.usersSettingsProfileData = [];
                          }

                          // Muestro los datos de los centros
                          this.mostrarDatosCentro();

                          // Y el mensaje de aviso en funcion de si ha sido validado o no.
                          this.mostrarMensajeAviso();

                          this.loading = false;
                        } else {
                          // tslint:disable-next-line: max-line-length
                          this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.UserData);

                          // tslint:disable-next-line: max-line-length
                          //this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear formulario datos generales - Datos de usuario estudiante`, 'ERROR');
                        }
    }, (error) => {
      this.loading = false;

      // tslint:disable-next-line: max-line-length
      this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.UserData);

      // tslint:disable-next-line: max-line-length
      //this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear formulario datos generales - Datos de usuario estudiante - ERROR: ${error}`, 'ERROR');
    });
  }

  private resetearFormularioDatosPadre() {
    this.formularioDatosPadre.reset();

    this.formularioDatosPadre.controls.idUserStudentParent.setValue(0);
    this.formularioDatosPadre.controls.creationDateString.setValue(null);
    this.parentSelected = false;
  }

  private cargarDatosFormularioDatosPadre(parentData) {

    this.idParentSelected = parentData.idUserStudentParent;

    this.formularioDatosPadre.controls.idUserStudentParent.setValue(parentData.idUserStudentParent);
    this.formularioDatosPadre.controls.firstName.setValue(parentData.firstName);
    this.formularioDatosPadre.controls.surname.setValue(parentData.surname);
    this.formularioDatosPadre.controls.mail.setValue(parentData.mail);
    this.formularioDatosPadre.controls.birthdate.setValue(parentData.birthdateString);
    this.formularioDatosPadre.controls.birthdateString.setValue(parentData.birthdateString);
    this.formularioDatosPadre.controls.mobile.setValue(parentData.mobile);
    this.formularioDatosPadre.controls.creationDate.setValue(parentData.creationDate);
    this.formularioDatosPadre.controls.creationDateString.setValue(parentData.creationDateString);

    // Voy a ver si ha sido validado ya
    if (this.usersProfileValidationData !== undefined) {
      const parentValidations = this.usersProfileValidationData.filter(x => x.idUserStudentParent === parentData.idUserStudentParent);

      if (parentValidations.filter(x => x.validationDate !== undefined && x.validationDate != null).length > 0) {
        this.parentValidated = true;
      } else {
        this.parentValidated = false;
      }
    } else {
      this.parentValidated = false;
    }
  }

  public cargarDatosPadre() {
    var idPadre;

    this.verDetallePadre = true;

    // Limpio la informacion previa
    this.resetearFormularioDatosPadre();

    // Y cargo los datos
    if (idPadre !== undefined) {
      this.cargarDatosFormularioDatosPadre(this.parentsData.filter(x => x.idUserStudentParent === idPadre)[0]);

      if (this.usersProfileValidationData !== undefined) {
        const aviso = this.usersProfileValidationData.filter(x => x.idUserStudentParent === idPadre)[0];
        if (aviso !== undefined) {

          this.translateService.get('USERDATA.SELEENVIOUNAVISOPARATUVALIDACIONEL').subscribe((res: string) => {
            this.mensajeEnvioValidacion = `${res} ${aviso.sendDate}`;
          });

          if (aviso.validationDate !== null) {
            this.translateService.get('USERDATA.HAVALIDOELACCESOEL').subscribe((res: string) => {
              this.mensajeEnvioValidado = `${res} ${aviso.validationDate}`;
            });
          } else {
            this.mensajeEnvioValidado = '';
          }
        } else {
          this.mensajeEnvioValidacion = '';
          this.mensajeEnvioValidado = '';
        }

        this.parentSelected = true;
      } else {
        this.mensajeEnvioValidacion = '';
        this.mensajeEnvioValidado = '';
      }
    }
  }

  public eliminarPadre() {

    this.loading = true;

    let idUserStudentParent = this.formularioDatosPadre.value.idUserStudentParent;

    this.resetearFormularioDatosPadre();
  }

  public reenviarAviso() {
    this.enviarAvisoAprobacionPadre(this.formularioDatosPadre.controls.idUserStudentParent.value);
  }

  private enviarAvisoAprobacionPadre(idUserStudentParent) {

    this.loading = true;

    let userProfileValidation = new UserProfileValidationModel();

    userProfileValidation.idUserProfileValidation = 0;
    userProfileValidation.idUser = this.userData.idUser;
    userProfileValidation.idUserStudentParent = idUserStudentParent;
    userProfileValidation.uuid = uuid();

    this.translateService.get('USERDATA.SMSVALIDARESTUDIANTEPADRE').subscribe((res: string) => {
      userProfileValidation.messageSms = res.replace('{1}', `${this.userData.firstName} ${this.userData.surname}`)
                                            .replace('{2}', `${environment.domainUrl}/validate-profile/${userProfileValidation.uuid}`);
    });

    userProfileValidation.attempNumbers = 0;
  }

  public grabarDatosPadre() {

    if (this.formularioDatosPadre.invalid) {
      return;
    }

    // Recupero los datos del formulario
    this.loading = true;

    // Y restauramos los datos del usuario en base al formulario
    const idUser = this.userData.idUser;
    this.formularioDatosPadre.value.birthdateString = this.formularioDatosPadre.value.birthdate;

    let parentData = this.usersStudentsParentsMapperService.transform(this.formularioDatosPadre.value);

    parentData.idUser = idUser;
  }

  // Datos centros estudiantes/profesores
  private mostrarDatosCentro() {

    // Y cargo el primer centro de la lista
    if (this.centersData.length > 0) {
      this.cargarDatosCentro(this.centersData[0].idCenter);
    }
  }

  private cargarDatosFormularioDatosCentro() {

    this.idCenterSelected = this.centerData.idCenter;

    this.formularioDatosCentro.controls.idCenter.setValue(this.centerData.idCenter);
    this.formularioDatosCentro.controls.centerName.setValue(this.centerData.centerName);
    this.formularioDatosCentro.controls.centerAddress.setValue(this.centerData.centerAddress);
    this.formularioDatosCentro.controls.centerCountry.setValue(this.centerData.centerCountry);
    this.formularioDatosCentro.controls.centerRegion.setValue(this.centerData.centerRegion);
    this.formularioDatosCentro.controls.centerMail.setValue(this.centerData.centerMail);
    this.formularioDatosCentro.controls.centerPhone.setValue(this.centerData.centerPhone);
    this.formularioDatosCentro.controls.centerUrl.setValue(this.centerData.centerUrl);
  }

  public cargarDatosCentro(idCentro) {

    this.verDetalleCentro = true;

    // Y cargo los datos
    if (idCentro !== undefined) {
      this.centerData = this.centersData.find(x => x.idCenter === idCentro);

      this.cargarDatosFormularioDatosCentro();
    }
  }

  public asignarCentro() {
    const modalRef = this.modalService.open(ModalStudentCentersComponent,
    {
      scrollable: true,
      windowClass: MODAL_DIALOG_TYPES.W80
    });

    // Lo registro en el log
    //this.usersService.addUserLog(this.loginService.getUser().idUser, `Asignar centro estudiante - Datos de usuario estudiante`, 'OK');

    // Le paso los centros que ya tiene para que no salgan en el filtro
    modalRef.componentInstance.centersAssigned = this.centersData;
    modalRef.componentInstance.profile = 'estudiante';

    modalRef.result.then((result) => {

      switch (result.message) {
        case 'Centro asignado':

          // tslint:disable-next-line: max-line-length
          this.alertService.success(this.translateService.instant('CURSOS.ELCENTROSEHAASIGNADOCORRECTAMENTE'), AlertService.AlertServiceContextValues.UserData);

          // Y lo asigno al objeto que almacena los centros
          this.centersData.push(result.center);

          // Si no hay datos mostrados de centros cargo los de este ultimo
          if (!this.verDetalleCentro && this.centersData.length > 0) {
            this.cargarDatosCentro(this.centersData[0].idCenter);
          }

          // Y Registro el user setting profile
          this.registrarUserSettingProfile();

          break;
      }
    }, (reason) => {
    });
  }

  public desasignarCentro() {
    this.loading = true;
  }

  public mostrarBotonAsignarProfesor() {

    if (this.formularioDatosProfesores.controls.idTeacher.value !== '') {
      this.centerTeacherSelected = true;
    } else {
      this.centerTeacherSelected = false;
    }
  }

  public seleccionarProfesoresCentro() {

    if (this.formularioDatosProfesores.controls.idCenter.value !== '') {
      this.centerSelected = true;
    } else {
      this.centerSelected = false;
    }
  }

  public asignarProfesor() {

    if (this.formularioDatosProfesores.controls.idTeacher.value === '') {
      return;
    }

    let userStudentTeacher = new UserStudentTeacherModel();

    userStudentTeacher.idUserStudentTeacher = 0;
    userStudentTeacher.idStudent = this.loginService.getUser().idUser;
    userStudentTeacher.idTeacher = this.formularioDatosProfesores.controls.idTeacher.value.idUser;
  }

  public desasignarProfesor(idUserStudentTeacher) {

    if (idUserStudentTeacher === '') {
      return;
    }
  }

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }
}
