import { Component } from '@angular/core';

import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-finish-socket-courses',
  templateUrl: './modal-finish-socket-courses.component.html',
  styleUrls: ['./modal-finish-socket-courses.component.scss']
})
export class ModalFinishSocketCoursesComponent {

  constructor(
		public translateService: TranslateService,
    private activeModal: NgbActiveModal
		) {}

  finalizar() {
    this.activeModal.close('finalizar');
  }

  anular() {
    this.activeModal.close('anular');
  }

	cancelar() {
    this.activeModal.close(true);
  }

  closeModal(sendData?: any) {
    this.activeModal.close(true);
  }
}
