import { TranslateService } from '@ngx-translate/core';
import { UsersService } from './../../../core/services/users/users.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { LoginService } from 'src/app/core/services/login';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { ResultadoActividadesService } from '../datos-resultado-quizes/resultados-quizes-service/resultado-actividades.service';
import { ActividadQuizPlayComponent } from './quiz-play/actividad-quiz-play.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
  selector: 'app-actividades-buscar-component',
  templateUrl: './actividades-buscar.component.html',
  styleUrls: ['./actividades-buscar.component.scss']
})
export class ActividadesBuscarComponent implements OnInit {

  public identificadorCurso: string = '';
  idQuizBuscar: number;
  isLoading: boolean;
  quiz: any = null;
  resultados: any = null;

  constructor(
    private quizService: QuizzesService,
    private modalService: NgbModal,
    private loginService: LoginService,
    private resultadoActService: ResultadoActividadesService,
    public activeModal: NgbActiveModal,
		private toaster: ToasterService,
    public userService:UsersService,
    private translateService: TranslateService
  ){}

  ngOnInit() {}

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }

  buscarActividad(){
    if (this.verificarIdentificador(this.identificadorCurso)) {
      this.buscarQuizIdentificador();
    }

  }

  realizarActividad(res:any){
    const modalRef = this.modalService.open(ActividadQuizPlayComponent,
          {
              scrollable: true,
              windowClass: MODAL_DIALOG_TYPES.W100,
              backdrop: 'static'
          }
      );

      modalRef.componentInstance.quiz = { ...res.data.quiz, user: this.loginService.getUser(), idOriginal: res.data.quiz.idQuiz, id:  res.data.quiz.idQiz, originalX:  0, originalY:  0, size:  0, sizeQuiz:  0, x:  0, y:  0 };
      modalRef.componentInstance.elements = res.data.elements;
      modalRef.componentInstance.courseId = 0;
      modalRef.componentInstance.graphId = 0;

      modalRef.result.then(data => {
        this.resultadoActService.dataModelSuscrip.next(data);
      });
  }
  limpiar(){
    this.quiz = null;
    this.resultados = null;
  }
  buscarQuizIdentificador() {

    this.isLoading = true;
    this.quizService.getQuizBusqueda(this.idQuizBuscar).pipe(finalize(() => this.isLoading = false)).subscribe(res => {

      if(res.error.code === 1){
				this.toaster.error(this.translateService.instant('SEARCHACTIVITIES.NODATA'));
      }else{
        this.quiz =  res.data.quiz;
        this.resultados = res;
        this.realizarActividad(res);
      }

    },
    (err) =>  {
			this.toaster.error(this.translateService.instant('SEARCHACTIVITIES.NODATA'));

    });

  }

  verificarIdentificador(identificadorCurso: string) {
    identificadorCurso = identificadorCurso.trim();
    if ( identificadorCurso !== '' && identificadorCurso !== null && identificadorCurso !== undefined) {
      let temp = parseInt(identificadorCurso);
      if (!Number.isNaN(temp)) {
        this.idQuizBuscar = temp;
        return true;
      }
      else{
				this.toaster.error(this.translateService.instant('SEARCHACTIVITIES.NOVALIDDATA'));

        return false;
      }
    }
    else {
			this.toaster.error(this.translateService.instant('SEARCHACTIVITIES.NOVALIDID'));

      return false;
    }
  }

  limpiarNumero(identificadorCurso) {
    this.identificadorCurso = identificadorCurso.replace(/\D/g, '');
  }




}
