<app-loading [isVisible]="isLoading"></app-loading>
<table class="table  table-responsive " *ngIf="!isLoading">
	<thead>
		<tr class="bg-light header-table mr-2" *ngFor="let list of headTable; let i = index">
			<th scope="row" *ngFor="let head of list.row">
				<div class="adjust-th-center">
					<div class="data-text same-width">
						<div class="d-flex justify-content-center align-items-center" *ngIf="i === 0 && head.title == null">
							<div *ngIf="reportingChallenge">
								<img src="../../../../../assets/images/icons/ActividadCuadrado.svg" width="24" height="24"><span
								class="separador text-primary">/</span> <mat-icon svgIcon="AnadirActividadesMultiples"></mat-icon>
							</div>
							<div *ngIf="!reportingChallenge">
								<button nbButton (click)="ordenarTabla()" class="btn-themecolor" size="tiny">
									{{ filtroAzTabla }}
									<nb-icon *ngIf="filtroAzTabla == 'A-Z' && mostrarFlechita" icon="arrow-forward-outline"></nb-icon>
									<nb-icon *ngIf="filtroAzTabla == 'Z-A' && mostrarFlechita" icon="arrow-back-outline"></nb-icon>
								</button>
							</div>
						</div>
						<ng-template #FullNameTooltip>
							{{head.name}} <br>
						</ng-template>
						<div class="d-flex justify-content-center align-items-center " [ngbTooltip]="FullNameTooltip"
							placement="bottom">
							<div class="avatar text-center  mb-2" *ngIf="i === 0 && head.title != null"
								[ngStyle]="{'background-image': getUserAvatar(head.image)}"></div>
						</div>
						<div>
							<p class="text-center courseTittle">{{head.title}}</p>
						</div>
					</div>
				</div>
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let filas of bodyTable; let i = index" [ngClass]="{'sticky-row': filas.row[0].color === '#FFE7D5'}">
			<th *ngFor="let data of filas.row" scope="row" [ngStyle]="{
									'background-color': data.color !== null && i ? data.color : '#ffff',
									'text-align': 'center',
									'font-weight': '500',
									'color': data.color === '#FFE7D5' ? '#930F1F' : (data.color === '#FFC9AC' ? '#7A0920' : 'black')
								}" [ngClass]="{
									'empty': !i,
									'isChecked': data.isChecked,
									'isNotChecked': !data.isChecked
								}">
				<div class="data-text">
					<div class="d-flex justify-content-center align-items-center" *ngIf="data.title !== null">
						<span class="text-title">
							<div *ngIf="reportingChallenge">
								<ng-container *ngIf="data.title == '% de acierto'">
									<span class="d-flex align-items-center">% Aciertos
										<mat-icon svgIcon="IconoTickRevision2"></mat-icon>
									</span>
								</ng-container>
								<ng-container *ngIf="data.title != '% de acierto'">
									{{data.title | titlecase }}
								</ng-container>
							</div>
							<div *ngIf="!reportingChallenge">
								<ng-container *ngIf="data.title == '% de acierto'">
									<!--
									<span class="d-flex align-items-center">% Aciertos
										<mat-icon svgIcon="IconoTickRevision2"></mat-icon>
									</span>
									-->
									<button nbButton (click)="ordenarTabla2()" class="btn-themecolor" size="tiny">
										% {{'COMPETITION.ACIERTOS' | translate}}
										<nb-icon *ngIf="filtroAciertos == 'aciertosASC'" icon="arrow-forward-outline"></nb-icon>
										<nb-icon *ngIf="filtroAciertos == 'aciertosDEC'" icon="arrow-back-outline"></nb-icon>
									</button>
								</ng-container>
								<ng-container *ngIf="data.title == 'Tiempo total'">
									<button nbButton (click)="ordenarTabla3()" class="btn-themecolor" size="tiny">
										Tiempo total
										<nb-icon *ngIf="filtroTiempo == 'tiempoTotalASC'" icon="arrow-forward-outline"></nb-icon>
										<nb-icon *ngIf="filtroTiempo == 'tiempoTotalDEC'" icon="arrow-back-outline"></nb-icon>
									</button>
								</ng-container>
								<ng-container *ngIf="data.title != '% de acierto' && data.title != 'Tiempo total'">
									{{data.title | titlecase }}
								</ng-container>
							</div>
						</span>
						<!--
						<div *ngIf="i">
							<mat-icon *ngIf="data.isMultiplex === 0" svgIcon="AnadirActividad"></mat-icon><mat-icon *ngIf="data.isMultiplex === 1"
								svgIcon="AnadirActividadesMultiples"></mat-icon>
						</div>
						-->
					</div>
				</div>
				<div class="nota-media" *ngIf="!i">
					<span class="strong-text">{{data.texto}}</span>
				</div>
				<div *ngIf="data.texto  !== null && i" class="data-nota">
					<div *ngIf="!data.isChecked" class="d-flex justify-content-center align-items-center pointer">
						<mat-icon svgIcon="inte" (click)="viewQuiz(data)"></mat-icon>
					</div>
					<div *ngIf="data.isChecked">
						<a class="text-white font-weight-bold pointer-event" (click)="viewQuiz(data)">
							<div class="d-flex align-items-center justify-content-center" *ngIf="challengeOn">
								<mat-icon [svgIcon]="data.isChallengeResult === 2 ? 'checknuevo' : 'equisCirculoBlanco'"
									class="mr-1" *ngIf="data.isChallengeResult !== 0"></mat-icon>
								<span>{{data.texto}}</span>
							</div>
							<div class="d-flex align-items-center justify-content-center" *ngIf="!challengeOn">
								<mat-icon [svgIcon]="data.iconResult ? 'checknuevo' : 'equisCirculoBlanco'" class="mr-1"></mat-icon>
								<span>{{data.texto}}</span>
								<mat-icon *ngIf="data.sos == 1" svgIcon="sos" style="margin-left: 10px;"></mat-icon>
							</div>
						</a>
					</div>
				</div>

			</th>
		</tr>

	</tbody>
</table>
