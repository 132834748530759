import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
	InstagramVideo,
	SpotifyAudio,
	TiktokVideo,
} from "./../../../../../../core/models/shared/external-video.model";
import { HttpBackend, HttpClient, HttpEvent, HttpEventType, HttpHeaders } from "@angular/common/http";
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from "@angular/core";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	UntypedFormBuilder,
} from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxCopilotService } from "ngx-copilot";
import { finalize, Observable, Subject, Subscription } from "rxjs";
import { CourseModel } from "src/app/core/models/courses";
import { ModelIcono } from "src/app/core/models/courses/icono.model";
import { SigmaNode } from "src/app/core/models/graph/sigmaNode.model";
import { MateriaModel } from "src/app/core/models/masters";
import { NodeFilesModel } from "src/app/core/models/nodes";
import { NodeFilesFormat } from "src/app/core/models/nodes/node-files-format.model";
import { YoutubeVideo } from "src/app/core/models/shared/external-video.model";
import { GraphService } from "src/app/core/services/graph/graph.service";
import { LoginService } from "src/app/core/services/login";
import { MastersService } from "src/app/core/services/masters";
import { NodeService } from "src/app/core/services/node/node.service";
import { QuizzesService } from "src/app/core/services/quizzes";
import {
	AVAILABLE_TYPE_FILES,
	FILE_FORMATS,
} from "src/app/core/utils/file-formats";
import { SIGMA_CONSTANTS } from "src/app/core/utils/sigma-constants";
import { Utils } from "src/app/core/utils/utils";
import { FormsComponent } from "src/app/shared/components/forms/forms.component";
import { environment } from "src/environments/environment";
import { SigmaCanvasService } from "../../../sigma-canvas/sigma-canvas.service";
import { SigmaToolbarsService } from "src/app/core/services/sigma-toolbars/sigma-toolbars.service";
import { NodeCoverPipe } from "src/app/shared/pipes/node-cover.pipe";
import { API_KEY_CHAT_GTP, URL_TIKTOK } from "src/app/core/models/masters/masters.enum";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { SalwizarQuizzesComponent } from "src/app/shared/components/salwizar-quizzes/salwizar-quizzes.component";
import { LocalStorage } from "src/app/core/utils";
import { QuizUtils } from "../../../sigma-canvas/utils/quiz.utils";
import { ModalAceptarCancelarComponent } from "src/app/shared/components/modal";
import { take } from "rxjs/operators";

declare var window;

@Component({
	selector: "app-node-detail-info",
	templateUrl: "./node-detail-info.component.html",
	styleUrls: ["./node-detail-info.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class NodeDetailInfoComponent
	extends FormsComponent
	implements OnInit, OnDestroy
{
	@Input() node: any; // Modal public param
	@Input() idCurso: number;
	@Input() idMapa: number;
	@Input() canEdit: boolean = false;
	@Input() course: CourseModel; // Modal public param
	@Input() isNewNode: boolean;
	@Input() fromLista: boolean = false; //si vienes del modo lista
	@Output() closeInModeList = new EventEmitter<boolean>();
	@ViewChild("title") title: ElementRef;
	listPower: number[] = [3, 2, 1, 0, -1, -2, -3];
	showSaveButton: boolean = false;
	FILES_URL = environment.remoteFilesUrl + "nodeContent";
	private subscriptions: Subscription[] = [];
	subjects$: Observable<MateriaModel[]>;
	filteredSubjects$: Observable<MateriaModel[]>;
	saveClicked: Subject<any> = new Subject<any>();
	saveClicked$: Observable<any> = this.saveClicked.asObservable();
	validationMessages = {
		title: [],
		description: [],
		subject: [],
		level: [],
		power: [],
	};
	private isFormChanged: boolean = false;
	private patchValueSend = { emitEvent: false, onlySelf: true };
	AUDIO_TYPE = FILE_FORMATS.AUDIO_TYPE;
	IMAGE_TYPE = FILE_FORMATS.IMAGE_TYPE;
	PDF_TYPE = FILE_FORMATS.PDF_TYPE;
	TXT_TYPE = FILE_FORMATS.TXT_TYPE;
	VIDEO_TYPE = FILE_FORMATS.VIDEO_TYPE;

	videoId: string = "";
	startSeconds: number = null;
	endSeconds: number = null;
	imageToCrop: File | Blob | null = null;

	formats: NodeFilesFormat[] = [
		{
			id: 1,
			name: "NODEFORMCOMPONENT.VIDEO",
			type: this.VIDEO_TYPE,
			icon: "videocam",
			edit: false,
			operatorIcons: [],
			visible: true,
			accept: "video/mov",
			progress: 0,
		},
		{
			id: 2,
			name: "NODEFORMCOMPONENT.IMAGE",
			type: this.IMAGE_TYPE,
			icon: "crop_original",
			edit: false,
			operatorIcons: [],
			visible: true,
			accept: "image/*",
			progress: 0,
		},
		{
			id: 3,
			name: "NODEFORMCOMPONENT.AUDIO",
			type: this.AUDIO_TYPE,
			icon: "volume_up",
			edit: false,
			operatorIcons: [],
			visible: true,
			accept: "audio/*",
			progress: 0,
		},
		{
			id: 4,
			name: "NODEFORMCOMPONENT.FILE",
			type: this.PDF_TYPE,
			icon: "upload_file",
			edit: false,
			operatorIcons: [],
			visible: true,
			accept: AVAILABLE_TYPE_FILES.join(","),
			progress: 0,
		},
		{
			id: 5,
			name: "NODEFORMCOMPONENT.TEXT",
			type: this.TXT_TYPE,
			icon: "text_snippet",
			edit: false,
			operatorIcons: [],
			visible: true,
			progress: 0,
		},
	];

	public form: UntypedFormGroup;
	public progress: number = 0;
	private nextPowers: number[] = [];
	private MIN_POWER: number = -3;
	private MAX_POWER: number = 3;
	cargando: boolean;
	videoSource: string = null;
	imageSource: string = null;
	audioSource: string = null;
	pdfSource: string = null;
	textSource: string = null;
	youtubeSource: string = null;
	instagramSource: SafeResourceUrl = null;
	tiktokSource: SafeResourceUrl = null;
	spotifySource: SafeResourceUrl = null;
	imagen: string;
	format: string = "";
	saveClickedSubscription: Subscription;
	iconosAdicionados: ModelIcono[] = [];
	player: any;
	canEditPower: boolean = true; //Podemos editar los power de los nodos alquilados
	isLoadFile: boolean = true;
	ext: string = "";
	sanitizedUrl: SafeResourceUrl;

	private httpClient: HttpClient;
	constructor(
		public graphServ: GraphService,
		public nodeService: NodeService,
		public loginService: LoginService,
		public quizService: QuizzesService,
		private translateService: TranslateService,
		private mastersService: MastersService,
		public activeModal: NgbActiveModal,
		private fb: UntypedFormBuilder,
		private sigmaCanvasService: SigmaCanvasService,
		private copilot: NgxCopilotService,
		private utils: Utils,
		private cdr: ChangeDetectorRef,
		private st: SigmaToolbarsService,
		private nodeCoverPipe: NodeCoverPipe,
		private sanitizer: DomSanitizer,
		private toaster: ToasterService,
		private modalService: NgbModal,
		private localStorage: LocalStorage,
		public quizUtils: QuizUtils,
		handler: HttpBackend,
		public http: HttpClient,
	) {
		super(graphServ, nodeService, quizService);

		this.form = this.fb.group({
			name: new UntypedFormControl("", { validators: Validators.required }),
			description: new UntypedFormControl(""),
			level: new UntypedFormControl(0, { validators: Validators.required }),
			subject: new UntypedFormControl("", { validators: Validators.required }),
			pictureNode: new UntypedFormControl(""),
			imageNode: new UntypedFormControl(undefined),
			published: new UntypedFormControl(null),
			power3: new UntypedFormControl(false),
			ordinalPower3: new UntypedFormControl(0),
			power2: new UntypedFormControl(false),
			ordinalPower2: new UntypedFormControl(0),
			power1: new UntypedFormControl(false),
			ordinalPower1: new UntypedFormControl(0),
			power0: new UntypedFormControl(false),
			ordinalPower0: new UntypedFormControl(0),
			powerNegative1: new UntypedFormControl(false),
			ordinalPowerNegative1: new UntypedFormControl(0),
			powerNegative2: new UntypedFormControl(false),
			ordinalPowerNegative2: new UntypedFormControl(0),
			powerNegative3: new UntypedFormControl(false),
			ordinalPowerNegative3: new UntypedFormControl(0),
			power: new UntypedFormControl(0),
			ordinal: new UntypedFormControl(0),
			image: [{ value: "", disabled: false }],
		});
		this.httpClient = new HttpClient(handler);

	}

	/**
	 * Function to detect changes in models
	 */
	ngAfterContentChecked(): void {
		this.cdr.detectChanges();
	}

	ngOnInit() {
		super.ngOnInit();
		this.traducirOpciones();
		this.canEdit = this.isMine();
		this.form.valueChanges.subscribe((value) => {
			this.isFormChanged = true;
		});
		this.subscriptions.push(
			this.nodeService.deleteFile.subscribe((values) => {
				this.deleteFile(values);
			})
		);
		this.subscriptions.push(
			this.nodeService.videoYoutube.subscribe((values) => {
				this.onYoutubeVideoChanges(values);
			})
		);
		this.subscriptions.push(
			this.nodeService.videoInstagram.subscribe((values) => {
				this.onInstagramVideoChanges(values);
			})
		);
		this.subscriptions.push(
			this.nodeService.videoTiktok.subscribe((values) => {
				this.onTiktokVideoChanges(values);
			})
		);
		this.subscriptions.push(
			this.nodeService.spotifyAudio.subscribe((values) => {
				this.onSpotifyAudioChanges(values);
			})
		);
		if (this.isNewNode) this._selectTitleTextOnCreate();
	}

	/**
	 * Focus and select title text on node creation
	 */
	private _selectTitleTextOnCreate(): void {
		setTimeout(() => {
			this.title.nativeElement.select();
		}, 100);
	}

	ngOnDestroy(): void {
		if (this.subscriptions.length)
			this.subscriptions.forEach((v) => v.unsubscribe());
		this.st.changeUpdateGraph(true);
	}

	nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

	done = () => {
		let data = this.loginService.getDataTutorial();
		data.estudiante.cambiarContenido = true;
		this.loginService.updateDataLocalTutorial(data);
		this.copilot.removeWrapper();
	};

	initPosition = (o: any) => {
		this.copilot.checkInit(o);
	};

	get titleControl(): UntypedFormControl {
		if (this.form) {
			return this.form.get("name") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get descriptionControl(): UntypedFormControl {
		if (this.form) {
			return this.form.get("description") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get subjectControl(): UntypedFormControl {
		if (this.form) {
			return this.form.get("subject") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get levelControl(): UntypedFormControl {
		if (this.form) {
			return this.form.get("level") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get powerControl(): UntypedFormControl {
		if (this.form) {
			return this.form.get("power") as UntypedFormControl;
		} else {
			return null;
		}
	}

	// ----------------------------------------------
	//     D A T A    I N I T
	// ----------------------------------------------

	public readData(id) {
		if (id !== "temp") {
			let stringValue: string = "";
			this.titleControl.patchValue(this.node.label, this.patchValueSend);
			this.descriptionControl.patchValue(
				this.node.description,
				this.patchValueSend
			);
			this.levelControl.patchValue(
				this.node.nodeSwlevel
					? this.node.nodeSwlevel
					: this.course.courseSWLevel,
				this.patchValueSend
			);
			this.subjectControl.patchValue(this.node.subject, this.patchValueSend);
			this.form
				.get("pictureNode")
				.patchValue(this.node.pictureNode, this.patchValueSend);
			this.form
				.get("power3")
				.patchValue(
					this.node.ordinalPower3 === 0 || this.node.ordinalPower3 == null
						? false
						: true,
					this.patchValueSend
				);
			this.form
				.get("ordinalPower3")
				.patchValue(
					this.node.ordinalPower3 != 0 ? this.node.ordinalPower3 : undefined,
					this.patchValueSend
				);
			this.form
				.get("power2")
				.patchValue(
					this.node.ordinalPower2 === 0 || this.node.ordinalPower2 == null
						? false
						: true,
					this.patchValueSend
				);
			this.form
				.get("ordinalPower2")
				.patchValue(
					this.node.ordinalPower2 != 0 ? this.node.ordinalPower2 : undefined,
					this.patchValueSend
				);
			this.form
				.get("power1")
				.patchValue(
					this.node.ordinalPower1 === 0 || this.node.ordinalPower1 == null
						? false
						: true,
					this.patchValueSend
				);
			this.form
				.get("ordinalPower1")
				.patchValue(
					this.node.ordinalPower1 != 0 ? this.node.ordinalPower1 : undefined,
					this.patchValueSend
				);
			this.form
				.get("power0")
				.patchValue(
					this.node.ordinalPower0 === 0 || this.node.ordinalPower0 == null
						? false
						: true,
					this.patchValueSend
				);
			this.form
				.get("ordinalPower0")
				.patchValue(
					this.node.ordinalPower0 != 0 ? this.node.ordinalPower0 : undefined,
					this.patchValueSend
				);
			this.form
				.get("powerNegative1")
				.patchValue(
					this.node.ordinalPowerNegative1 === 0 ||
						this.node.ordinalPowerNegative1 == null
						? false
						: true,
					this.patchValueSend
				);
			this.form
				.get("ordinalPowerNegative1")
				.patchValue(
					this.node.ordinalPowerNegative1 != 0
						? this.node.ordinalPowerNegative1
						: undefined,
					this.patchValueSend
				);
			this.form
				.get("powerNegative2")
				.patchValue(
					this.node.ordinalPowerNegative2 === 0 ||
						this.node.ordinalPowerNegative2 == null
						? false
						: true,
					this.patchValueSend
				);
			this.form
				.get("ordinalPowerNegative2")
				.patchValue(
					this.node.ordinalPowerNegative2 != 0
						? this.node.ordinalPowerNegative2
						: undefined,
					this.patchValueSend
				);
			this.form
				.get("powerNegative3")
				.patchValue(
					this.node.ordinalPowerNegative3 === 0 ||
						this.node.ordinalPowerNegative3 == null
						? false
						: true,
					this.patchValueSend
				);
			this.form
				.get("ordinalPowerNegative3")
				.patchValue(
					this.node.ordinalPowerNegative3 != 0
						? this.node.ordinalPowerNegative3
						: undefined,
					this.patchValueSend
				);
			this.form.get("published").patchValue(this.node.published ? true : false);

			this.form
				.get("image")
				.patchValue(
					this.node.pictureNode
						? this.nodeCoverPipe.transform(this.node.pictureNode)
						: "",
					this.patchValueSend
				);

			if (this.node.imageNode) {
				switch (this.node.imageNode) {
					case 1:
						stringValue = this.VIDEO_TYPE;
						break;
					case 2:
						stringValue = this.IMAGE_TYPE;
						break;
					case 3:
						stringValue = this.AUDIO_TYPE;
						break;
					case 4:
						stringValue = this.PDF_TYPE;
						break;
					case 5:
						stringValue = this.TXT_TYPE;
				}
				this.format = stringValue;
				this.onFilePlayed(stringValue);
			}

			this.getPowers();
		}
	}

	/**
	 * Get the next ordinals power values
	 */
	private getPowers(): void {
		this.nodeService
			.getPowers(this.idCurso, this.idMapa, this.MAX_POWER, this.MIN_POWER)
			.subscribe((res) => {
				this.nextPowers = [...res];

				const powers = [
					this.form.get("powerNegative3").value,
					this.form.get("powerNegative2").value,
					this.form.get("powerNegative1").value,
					this.form.get("power0").value,
					this.form.get("power1").value,
					this.form.get("power2").value,
					this.form.get("power3").value,
				];

				const powerControls = [
					this.form.get("ordinalPowerNegative3"),
					this.form.get("ordinalPowerNegative2"),
					this.form.get("ordinalPowerNegative1"),
					this.form.get("ordinalPower0"),
					this.form.get("ordinalPower1"),
					this.form.get("ordinalPower2"),
					this.form.get("ordinalPower3"),
				];

				for (let i = 0; i < powers.length; i++) {
					if (!powers[i]) {
						powerControls[i].patchValue(
							this.nextPowers[i],
							this.patchValueSend
						);
					}
				}
			});
	}

	public save() {
		this.form.markAllAsTouched();

		const fv = this.form.value;

		if (
			!fv.power3 &&
			!fv.power2 &&
			!fv.power1 &&
			!fv.power0 &&
			!fv.powerNegative1 &&
			!fv.powerNegative2 &&
			!fv.powerNegative3
		) {
			this.powerControl.setErrors({ required: true });
			return false;
		}
		this.powerControl.reset();

		if (this.form.valid) {
			const tmpNode = { ...this.node };
			tmpNode.label = fv.name;
			tmpNode.tittle = fv.name;
			tmpNode.description = fv.description;
			tmpNode.nodesummary = fv.description;
			tmpNode.nodeSwlevel = fv.level;
			tmpNode.duration = 0;
			tmpNode.subject = fv.subject;
			tmpNode.pictureNode = this.node.pictureNode;
			tmpNode.imageNode = this.node.imageNode;

			fv.power3
				? (tmpNode.ordinalPower3 = fv.ordinalPower3)
				: (tmpNode.ordinalPower3 = 0);
			fv.power2
				? (tmpNode.ordinalPower2 = fv.ordinalPower2)
				: (tmpNode.ordinalPower2 = 0);
			fv.power1
				? (tmpNode.ordinalPower1 = fv.ordinalPower1)
				: (tmpNode.ordinalPower1 = 0);
			fv.power0
				? (tmpNode.ordinalPower0 = fv.ordinalPower0)
				: (tmpNode.ordinalPower0 = 0);
			fv.powerNegative1
				? (tmpNode.ordinalPowerNegative1 = fv.ordinalPowerNegative1)
				: (tmpNode.ordinalPowerNegative1 = 0);
			fv.powerNegative2
				? (tmpNode.ordinalPowerNegative2 = fv.ordinalPowerNegative2)
				: (tmpNode.ordinalPowerNegative2 = 0);
			fv.powerNegative3
				? (tmpNode.ordinalPowerNegative3 = fv.ordinalPowerNegative3)
				: (tmpNode.ordinalPowerNegative3 = 0);

			tmpNode.size = SIGMA_CONSTANTS.NODE_DEFAULT_SIZE;
			tmpNode.type = SIGMA_CONSTANTS.NODE_DEFAULT_TYPE;
			tmpNode.published = this.node.published;

			// Manda al backend el nodo a crear para crearlo en BD
			this.nodeService
				.createNode(this.idCurso, this.idMapa, tmpNode, null)
				.subscribe(
					(res) => {
						switch (res.type) {
							case HttpEventType.UploadProgress:
								this.progress = Math.round((res.loaded / res.total) * 100);
								break;
							case HttpEventType.Response:
								let nodesFiles;

								if (this.node.nodesFiles)
									nodesFiles = { ...this.node.nodesFiles };
								else nodesFiles = { idNodeFile: res.body.data.idNodesFile };

								this.node = { ...res.body.data, nodesFiles };
								this.nodeService.currentNode.next(this.node);

								//Llamada al endpoint para añadir la imagen del grafo si la hubiese
								if (fv.image instanceof File) {
									this.nodeService
										.updatePictureNode(this.node.idOriginal, fv.image)
										.subscribe(
											(result) => {
												//en el modo lista no cerramos modal, solo lo ocultamos
												if(!this.fromLista){
													this.activeModal.close();
												} else {
													this.closeInModeList.emit(true);
												}
												this.toaster.success(
													this.translateService.instant(
														"NODEFORMCOMPONENT.SAVED"
													)
												);
											},
											(err) => console.error("ERROR UPDATE: ", err)
										);
								} else if (!fv.image && this.node.pictureNode) {
									//Llamamos al endpoint para eliminar la imagen
									this.nodeService
										.deletePictureNode(this.node.idOriginal)
										.subscribe(
											(result) => {
												//en el modo lista no cerramos modal, solo lo ocultamos
												if(!this.fromLista){
													this.activeModal.close();
												} else {
													this.closeInModeList.emit(true);
												}
												this.toaster.success(
													this.translateService.instant(
														"NODEFORMCOMPONENT.SAVED"
													)
												);
												//como eliminamos la imagen ponemos una de las subidas como elemento del nodo
												/** OCULTO POR CAMBIOS DE CONCEPTOS. SE DEJA PARA FUTURO
												this.nodeService
													.inheritNodeImage(this.node.idOriginal)
													.subscribe( res => {});
												*/
											},
											(err) => {
												console.error("ERROR DELETE: ", err);
											}
										);
								} else if(!fv.image && !this.node.pictureNode){
									//ponemos una imagen de los elementos como imagen del nodo
									/** OCULTO POR CAMBIOS DE CONCEPTOS. SE DEJA PARA FUTURO
									this.nodeService
										.inheritNodeImage(this.node.idOriginal)
										.subscribe( res => {});
									*/
									//en el modo lista no cerramos modal, solo lo ocultamos
									if(!this.fromLista){
										this.activeModal.close();
									} else {
										this.closeInModeList.emit(true);
									}
								} else {
									//en el modo lista no cerramos modal, solo lo ocultamos
									if(!this.fromLista){
										this.activeModal.close();
									} else {
										this.closeInModeList.emit(true);
									}
								}
						}
					},
					(err) => {
						this.toaster.error(
							this.translateService.instant("NODEFORMCOMPONENT.ERROR")
						);
					}
				);
		}
	}

	traducirOpciones() {
		this.translateService
			.get("VALIDACIONES.TITLEREQUIRED")
			.subscribe((res: string) => {
				this.validationMessages.title.push({ type: "required", message: res });
			});

		this.translateService
			.get("VALIDACIONES.LEVELREQUIRED")
			.subscribe((res: string) => {
				this.validationMessages.level.push({ type: "required", message: res });
			});

		this.translateService
			.get("VALIDACIONES.SUBJECTREQUIRED")
			.subscribe((res: string) => {
				this.validationMessages.subject.push({
					type: "required",
					message: res,
				});
			});

		this.translateService
			.get("VALIDACIONES.POWERREQUIRED")
			.subscribe((res: string) => {
				this.validationMessages.power.push({ type: "required", message: res });
			});
	}

	closeModal(sendData?: any) {
		if (this.isFormChanged){
			this.save();
		} else{
			//en el modo lista no cerramos modal, solo lo ocultamos
			if(!this.fromLista){
				this.activeModal.close();
			} else {
				this.closeInModeList.emit(true);
			}
		}
	}

	/**
	 *
	 * @returns True or false,
	 */

	checkFiles(): boolean {
		let files = this.node.nodesFiles;
		if (
			files.audioFile == null &&
			files.pdfFile == null &&
			files.pictureFile == null &&
			files.textFile == null &&
			files.videoFile == null
		) {
			return false;
		}
		return true;
	}

	/**
	 * Publish or unpublish node
	 * @param $ev Click event in the switch component
	 */

	publish($ev) {
		$ev.preventDefault();
		$ev.stopImmediatePropagation();
		let currentValue: boolean = this.form.get("published").value;
		let modalMessage: string = currentValue
			? this.translateService.instant("NODEFORMCOMPONENT.PUBLISHMSG")
			: this.translateService.instant("NODEFORMCOMPONENT.UNPUBLISHMSG");
		let errorMessage: string = currentValue
			? this.translateService.instant("GENERAL.KOPUBLISH")
			: this.translateService.instant("GENERAL.KOUNPUBLISH");
		let okMessage: string = currentValue
			? this.translateService.instant("NODEDETAIL.NODEVISIBLE")
			: this.translateService.instant("NODEDETAIL.NODENOVISIBLE");

		this.mastersService
			.setPublishType("node", Number(this.node.idOriginal), currentValue)
			.subscribe(
				(result) => {
					if (result.data) {
						this.toaster.success(okMessage);
						this.form
							.get("published")
							.setValue(currentValue, this.patchValueSend); // Update form value
						if (currentValue) this.node.published = Date.now();
						else this.node.published = null;
					} else
						this.toaster.error(
							this.translateService.instant(
								"NODEFORMCOMPONENT.KOPUBLISHCONTENT"
							)
						);
				},
				(err) => {
					this.toaster.error(errorMessage);
				}
			);
	}

	allStringNumber(value: string) {
		let valueSplit = value.split(",");
		let isCorrect = true;
		for (let index = 0; index < valueSplit.length; index++) {
			const element = valueSplit[index];
			if (!Number.isInteger(parseInt(element))) {
				isCorrect = false;
				break;
			} else if (!(parseInt(element) > 0)) {
				isCorrect = false;
				break;
			}
		}
		if (isCorrect) {
			return isCorrect;
		}

		return false;
	}

	lastValueIsNumber(value: string) {
		let lastValue = value.substring(value.length - 1);
		let regexp = /^[0-9]$/g;
		let result = regexp.test(lastValue);
		if (result) {
			return result;
		}
		return false;
	}

	deleteNode(): void {
		if (!this.loginService.esAutor()) {
			return;
		}
		let node: SigmaNode = this.node as SigmaNode;

		this.sigmaCanvasService.deleteSigmaNode(node).subscribe(
			(res: any) => {
				this.toaster.success(this.translateService.instant("NODOS.DELETEOK"));
				this.closeModal();
				this.nodeService.delNode.next(true); // Send event to delete
			},
			(err) => {
				this.toaster.error(this.translateService.instant("NODOS.DELETENOK"));
				this.closeModal();
				this.nodeService.delNode.next(false); // Send event to delete
			}
		);
	}

	/* RIGHT COLUMN FILES */

	onNodeFilesTypeChanged(format: string) {
		this.format = format;
	}

	onNodeFileAdded({ type, content, operators, showResult }) {
		this.audioSource =
			this.videoSource =
			this.pdfSource =
			this.textSource =
			this.youtubeSource =
			this.imageSource =
			this.instagramSource =
			this.tiktokSource =
			this.spotifySource =
				"";

		if (type === FILE_FORMATS.TXT_TYPE) {
			this.uploadText(type, content, operators);
		} else {
			this.uploadFile(type, content, operators, showResult);
		}
	}

	private uploadText(type: string, text: string, operatorIcons: ModelIcono[]) {
		this.nodeService
			.postNodeFile(
				this.node.nodesFiles.idNodeFile,
				type + "Add",
				operatorIcons,
				undefined,
				text
			)
			.subscribe(
				(event) => {
					switch (event.type) {
						case HttpEventType.Response:
							const node = { ...this.node };
							if (!node.nodesFiles) node.nodesFiles = {};

							node.nodesFiles.txtFile = text;
							this.node = { ...node };
							this.nodeService.currentNode.next(this.node);
							break;
					}
				},
				(err) =>
					this.toaster.error(
						this.translateService.instant("NODEFORMCOMPONENT.FILEERROR")
					)
			);
	}

	private uploadFile(
		type: string,
		file: File,
		operatorIcons: ModelIcono[],
		showResult: boolean = true
	) {
		if(type == "video" && this.node.nodesFiles.audioFile == null){
			//Abrimos el modal para agregar el audio tambien
			const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30,
		});
		modalRef.componentInstance.mensaje = this.translateService.instant("NODEFORMCOMPONENT.AUDIOVIDEO");
		modalRef.result.then((result: boolean) => {
			if (result) {
				this.subirAudioVideo(type, file, operatorIcons, showResult);
			}
			if(file.name?.includes('.mov') || file.name?.includes('.MOV')){
				this.subirFichero(type, file, operatorIcons, showResult);
				this.subirFichero('videoImageMov', file, operatorIcons, showResult);
			}else{
				this.subirFichero(type, file, operatorIcons, showResult);
			}

		});
		} else{
			if(file.name?.includes('.mov') || file.name?.includes('.MOV')){
				this.subirFichero(type, file, operatorIcons, showResult);
				this.subirFichero('videoImageMov', file, operatorIcons, showResult);
			}else{
				this.subirFichero(type, file, operatorIcons, showResult);
			}
		}
	}

	private subirFichero(
		type: string,
		file: File,
		operatorIcons: ModelIcono[],
		showResult: boolean = true
	) {


		this.nodeService
			.postNodeFile(
				this.node.nodesFiles.idNodeFile,
				type + "Add",
				operatorIcons,
				file
			)
			.subscribe(
				(event: HttpEvent<any>) => {
					let progressVal: number = 0;
					switch (event.type) {
						case HttpEventType.UploadProgress:
							progressVal = Math.round((event.loaded / event.total) * 100);
							this.nodeService.currentUploadProgress.next({
								value: progressVal,
								type: type,
							});
							break;
						case HttpEventType.Response:
							const node = { ...this.node };
							if (!node.nodesFiles) {
								node.nodesFiles = {};
							}
							switch (type) {
								case FILE_FORMATS.AUDIO_TYPE:
									node.nodesFiles.audioFile = event.body.data.fileName;
									break;
								case FILE_FORMATS.IMAGE_TYPE:
									node.nodesFiles.pictureFile = event.body.data.fileName;
									break;
								case FILE_FORMATS.PDF_TYPE:
									node.nodesFiles.pdfFile = event.body.data.fileName;
									break;
								case FILE_FORMATS.VIDEO_TYPE:
									node.nodesFiles.videoFile = event.body.data.fileName;
									break;
								case FILE_FORMATS.VIDEO_PREVIEW_TYPE:
									node.nodesFiles.videoImage = event.body.data.fileName;
									break;
							}

							if (type === FILE_FORMATS.IMAGE_TYPE) {
								this.nodeService.currentNode.next(this.node);
								this.onFilePlayed(type);

								if (showResult)
									this.toaster.success(
										this.translateService.instant("NODEFORMCOMPONENT.FILESAVED")
									);
							} else {
								this.node = { ...node };
								if (this.node.imageNode === 1) {
									if (type == FILE_FORMATS.VIDEO_PREVIEW_TYPE) {
										this.node = {
											...node,
											pictureNode: event.body.data.fileName,
											imageNode: this.node.imageNode,
										};
									}
								}
								if (type == FILE_FORMATS.VIDEO_PREVIEW_TYPE)
									type = FILE_FORMATS.VIDEO_TYPE;

								this.onFilePlayed(type);
								this.nodeService.currentNode.next(this.node);

								if (showResult)
									this.toaster.success(
										this.translateService.instant("NODEFORMCOMPONENT.FILESAVED")
									);
							}

							this.nodeService.currentUploadProgress.next({
								value: 0,
								type: type,
							});
					}
				},
				(err) => {
					if (showResult)
						this.toaster.error(
							this.translateService.instant("NODEFORMCOMPONENT.FILEERROR")
						);
					this.nodeService.currentUploadProgress.next({ value: 0, type: type });
				}
			);
	}

	private subirAudioVideo(
		type: string,
		file: File,
		operatorIcons: ModelIcono[],
		showResult: boolean = true
	) {
		this.nodeService
			.extractAudioNodeFile(
				this.node.nodesFiles.idNodeFile,
				"videoAdd",
				operatorIcons,
				file
			)
			.subscribe(
				(event: HttpEvent<any>) => {
					let progressVal: number = 0;
					switch (event.type) {
						case HttpEventType.UploadProgress:
							progressVal = Math.round((event.loaded / event.total) * 100);
							this.nodeService.currentUploadProgress.next({
								value: progressVal,
								type: type,
							});
							break;
						case HttpEventType.Response:
							const node = { ...this.node };
							if (!node.nodesFiles) {
								node.nodesFiles = {};
							}
							node.nodesFiles.audioFile = event.body.data.fileName;

							if (type === FILE_FORMATS.IMAGE_TYPE) {
								this.nodeService.currentNode.next(this.node);
								this.onFilePlayed(type);

								if (showResult)
									this.toaster.success(
										this.translateService.instant("NODEFORMCOMPONENT.FILESAVED")
									);
							} else {
								this.node = { ...node };
								if (this.node.imageNode === 1) {
									if (type == FILE_FORMATS.VIDEO_PREVIEW_TYPE) {
										this.node = {
											...node,
											pictureNode: event.body.data.fileName,
											imageNode: this.node.imageNode,
										};
									}
								}
								if (type == FILE_FORMATS.VIDEO_PREVIEW_TYPE)
									type = FILE_FORMATS.VIDEO_TYPE;

								this.onFilePlayed(type);
								this.nodeService.currentNode.next(this.node);

								if (showResult)
									this.toaster.success(
										this.translateService.instant("NODEFORMCOMPONENT.FILESAVED")
									);
							}

							this.nodeService.currentUploadProgress.next({
								value: 0,
								type: type,
							});
					}
				},
				(err) => {
					if (showResult)
						this.toaster.error(
							this.translateService.instant("NODEFORMCOMPONENT.FILEERROR")
						);
					this.nodeService.currentUploadProgress.next({ value: 0, type: type });
				}
			);
	}

	//se quita porque ya no se selecciona así
	/*onFileSelected(format: NodeFilesFormat) {
        //QUENTAL
        this.nodeService.selectFilePreviewAsImage(this.node.nodesFiles.idNodeFile, format.id).subscribe((res: any) => {
            this.node = { ...this.node, pictureNode: res.data.name, imageNode: format.id };
            this.nodeService.currentNode.next(this.node);
        }, err => console.error(err));
    }*/

	/**
	 * Play the selected file into the central container to show a preview
	 * @param value
	 */
	onFilePlayed(value: string) {
		const nodesFiles: NodeFilesModel = this.node.nodesFiles as NodeFilesModel;
		this.audioSource =
			this.videoSource =
			this.pdfSource =
			this.textSource =
			this.youtubeSource =
			this.imageSource =
				"";
		switch (value) {
			case this.AUDIO_TYPE:
				this.audioSource = this.fileUrl(this.AUDIO_TYPE, nodesFiles.audioFile);
				break;
			case this.IMAGE_TYPE:
				this.imageSource = this.fileUrl(
					this.IMAGE_TYPE,
					nodesFiles.pictureFile
				);
				break;
			case this.PDF_TYPE:
				this.ext = this.fileUrl(this.PDF_TYPE, nodesFiles.pdfFile)
					.split(".")
					.pop();

				this.pdfSource = this.fileUrl(this.PDF_TYPE, nodesFiles.pdfFile);

				this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
					this.fileUrl(this.PDF_TYPE, nodesFiles.pdfFile)
				);
				break;
			case this.TXT_TYPE:
				this.textSource = nodesFiles.txtFile;
				break;
			case this.VIDEO_TYPE:
				//Puede ser vídeo youtube o vídeo normal subido por el usuario
				if (nodesFiles.videoYouTube) {
					this.youtubeSource = "";
					this.videoId = "";
					this.endSeconds = null;
					this.startSeconds = null;
					setTimeout(() => {
						this.videoId = this.utils.getYoutubeId(nodesFiles.videoYouTube);
						this.youtubeSource = nodesFiles.videoYouTube;
						this.startSeconds = nodesFiles.start
							? this.utils.hoursToSeconds(nodesFiles.start)
							: null;
						this.endSeconds = nodesFiles.end
							? this.utils.hoursToSeconds(nodesFiles.end)
							: null;
					}, 0);
				} else
					this.videoSource = this.fileUrl(
						this.VIDEO_TYPE,
						nodesFiles.videoFile
					);
				break;
			default:
				break;
		}
	}

	private fileUrl(type: string, fileName: string) {
		if (!fileName) {
			return undefined;
		}
		return `${this.FILES_URL}/${type}/${fileName}`;
	}

	public saveText(text: string) {
		if (this.canEdit) {
			this.saveClicked.next(text);
		}
	}

	private deleteFile(values) {
		const node = { ...this.node };
		if (!node.nodesFiles) {
			node.nodesFiles = {};
		}

		switch (values.type) {
			case FILE_FORMATS.AUDIO_TYPE:
				node.nodesFiles.audioFile = "";
				this.audioSource = "";
				break;
			case FILE_FORMATS.IMAGE_TYPE:
				node.nodesFiles.pictureFile = "";
				this.imageSource = "";
				break;
			case FILE_FORMATS.PDF_TYPE:
				node.nodesFiles.pdfFile = "";
				this.pdfSource = "";
				break;
			case FILE_FORMATS.VIDEO_TYPE:
				node.nodesFiles.videoFile = "";
				node.nodesFiles.videoImage = "";
				this.videoSource = "";
				break;
			case FILE_FORMATS.VIDEO_PREVIEW_TYPE:
				node.nodesFiles.videoImage = "";
				break;
		}

		this.node = node;
		this.nodeService.currentNode.next(this.node);
	}

	private onYoutubeVideoChanges(values: YoutubeVideo) {
		const node = { ...this.node };
		if (!node.nodesFiles) {
			node.nodesFiles = {};
		}

		if (values.isDelete) {
			node.nodesFiles.videoYouTube = "";
			node.nodesFiles.start = null;
			node.nodesFiles.end = null;
			this.youtubeSource = "";
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.url = "";
			videoFormat.start = null;
			videoFormat.end = null;
			videoFormat.isVideoYoutube = node.nodesFiles.isVideoYoutube = false;
			if (node.imageNode == 1) {
				node.imageNode = 0;
				node.pictureNode = "";
			}
		} else {
			this.youtubeSource = "";
			this.videoId = "";
			this.endSeconds = null;
			this.startSeconds = null;
			node.nodesFiles.videoYouTube = values.url;
			this.videoId = this.utils.getYoutubeId(values.url);
			this.youtubeSource = values.url;
			this.startSeconds = values.start
				? this.utils.hoursToSeconds(values.start)
				: null;
			this.endSeconds = values.end
				? this.utils.hoursToSeconds(values.end)
				: null;
			node.nodesFiles.start = values.start;
			node.nodesFiles.end = values.end;
			node.nodesFiles.imageYoutube = values.videoImage;

			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.url = values.url;
			videoFormat.start = values.start;
		}

		this.node = node;
		this.nodeService.currentNode.next(this.node);
	}

	private onInstagramVideoChanges(values: InstagramVideo) {
		const node = { ...this.node };
		if (!node.nodesFiles) {
			node.nodesFiles = {};
		}

		if (values.isDelete) {
			node.nodesFiles.videoInstagram = "";
			this.instagramSource = "";
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.url = "";
			videoFormat.isVideoInstagram = node.nodesFiles.isVideoInstagram = false;
		} else {
			this.instagramSource = "";
			node.nodesFiles.videoInstagram = values.url;
			this.instagramSource = this.sanitizer.bypassSecurityTrustResourceUrl(
				values.url + "embed"
			);
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			node.nodesFiles.imageInstagram = values.videoImage;
			videoFormat.url = values.url;
		}

		this.node = node;
		this.nodeService.currentNode.next(this.node);
	}

	private onTiktokVideoChanges(values: TiktokVideo) {
		const node = { ...this.node };
		if (!node.nodesFiles) {
			node.nodesFiles = {};
		}

		if (values.isDelete) {
			node.nodesFiles.videoTiktok = "";
			this.tiktokSource = "";
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.url = "";
			videoFormat.isVideoTiktok = node.nodesFiles.isVideoTiktok = false;
		} else {
			this.tiktokSource = "";
			node.nodesFiles.videoTiktok = values.url;
			const idTiktok = this.utils.getTiktokId(values.url);
			this.tiktokSource = this.sanitizer.bypassSecurityTrustResourceUrl(
				URL_TIKTOK + idTiktok
			);
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			node.nodesFiles.imageTiktok = values.videoImage;
			videoFormat.url = values.url;
		}

		this.node = node;
		this.nodeService.currentNode.next(this.node);
	}

	private onSpotifyAudioChanges(values: SpotifyAudio) {
		const node = { ...this.node };
		if (!node.nodesFiles) {
			node.nodesFiles = {};
		}

		if (values.isDelete) {
			node.nodesFiles.spotifyAudio = "";
			this.spotifySource = "";
			const videoFormat = this.formats.filter(
				(f) => f.type === this.AUDIO_TYPE
			)[0];
			videoFormat.url = "";
			videoFormat.isSpotifyAudio = node.nodesFiles.isSpotifyAudio = false;
		} else {
			this.spotifySource = "";
			node.nodesFiles.spotifyAudio = values.url;
			this.spotifySource = this.sanitizer.bypassSecurityTrustResourceUrl(
				values.url + "embed"
			);
			const videoFormat = this.formats.filter(
				(f) => f.type === this.AUDIO_TYPE
			)[0];
			node.nodesFiles.imageInstagram = values.videoImage;
			videoFormat.url = values.url;
		}

		this.node = node;
		this.nodeService.currentNode.next(this.node);
	}

	/* END RIGHT COLUMN FILES */

	isMine(): boolean {
		//SI HAS LOGRADO LLEGAR HASTA AQUI COMO EDITOR, PASANDO EL FILTRO DE PROYECTOS. SI ERES EDITOR DEL PROYECTO SE SUPONE QUE ERES EDITOR DEL GRAFO Y SUS NODOS Y ACTIVIDADES --30/06/2023--
		if (this.loginService.esAutor()) return true;
		return false;
	}

	fileLoaded(): void {
		this.isLoadFile = false;
	}

	generateQuizzes() {
		const modalRef = this.modalService.open(SalwizarQuizzesComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W50,
		});

		modalRef.componentInstance.node = this.node;

		modalRef.result.then(
			(data: any) => {
				let IaGeneratedQuizzes = JSON.parse(
					this.localStorage.getItem("IAGeneratedQuizzes")
				);
				IaGeneratedQuizzes.forEach((quiz) => {
					this.quizUtils.createQuizGenerated(quiz).subscribe((res) => {
						this.quizService
							.createEdge(
								`q${res.idOriginal}`,
								`n${quiz.linkTo}`,
								this.idCurso,
								this.idMapa
							)
							.pipe(finalize(() => (this.cargando = false)))
							.subscribe(
								(res: any) => {
									this.toaster.success(
										this.translateService.instant("NODOS.LINKOK")
									);

									// this.sigmaUtils.refresh();
									this.st.changeUpdateGraph(true);
								},
								(err) => {
									this.toaster.error(
										this.translateService.instant("NODOS.LINKNOK")
									);
								}
							);

						quiz.opciones.forEach((opt) => {
							this.quizService
								.createOrUpdateQuizElement(
									res.idOriginal,
									{
										idQuizzesDataElements: "",
										data: opt.text,
										elementType: 9,
										idQuiz: res.idOriginal,
										responseCheck: opt.correcto ? 1 : null,
										xPosition: 0,
										xSize: 0,
										yPosition: 0,
										ySize: 0,
									},
									undefined
								)
								.subscribe(
									(res) => {},
									(err) => console.error(err)
								);
						});

						// this.sigmaUtils.addNode(res);
						// if (
						// 	this.loginService.getUser().tutorialSW.estado === Estado.PENDIENTE
						// ) {
						// 	this.countHelp++;
						// 	this.countHelpQuiz++;
						// 	if (this.countHelp === 2) {
						// 		this.mostrarAyuda = true;
						// 	}
						// }
					});
				});
			},
			(reason) => {
				console.error("Can not select the sticker");
			}
		);
	}

	private dataURItoBlob(dataURI: string): Blob {
		const type: string = dataURI.split(";")[0].split(":")[1];
		const byteString = window.atob(dataURI.split(",")[1]);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([int8Array], { type: type });

		return blob;
	}

	async generateImage(event) {
		const endpoint = 'https://api.openai.com/v1/images/generations';
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${API_KEY_CHAT_GTP}`,
		});
		const body = {
			model: "dall-e-3",
			prompt: this.node.description,
			n: 1,
			size: '1024x1024',
		}
		try {
			const response = await this.httpClient
				.post<any>(endpoint, body,  { headers })
				.toPromise();
			if (response) {
				//Captura la URL de la imagen generada
			const imageUrl = response.data[0].url;

			this.mastersService.uploadimageiatoftp(
				this.node.idCourseCreation,
				this.node.idNodeTarget,
				this.node.idOriginal,
				imageUrl,
				1
			).subscribe((response) => {
				if (response) {
					//reload page
					window.location.reload();
				}

			});


			}
		} catch (error) {

			console.error("Error al hacer la consulta a ChatGPT:", error.message);
		}
		try {

			//console.log('Imagen subida exitosamente:', uploadResponse.data);
		} catch (error) {
			console.error('Error generando o subiendo la imagen:', error);
		}
	}
}
