 <nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="border-bottom-0">
		<div class="col-11">
			<h2>{{ 'SOS.MEETINGLISTTITTLE' | translate }}</h2>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeModal('')"></nb-icon>
	</div>
	<nb-card-body>
		<div class="row pt-3">
			<div class="col-md-6">
				<h5>{{ 'SOS.PETITIONSASKED' | translate }}</h5>
				<nb-tabset>
					<nb-tab class="px-0 py-4" tabTitle="{{ 'SOS.MYPETITIONS' | translate }}">
						<nb-list *ngIf="helpPetitions != null">
							<nb-list-item *ngFor="let helpPetition of helpPetitions">
								{{ helpPetition.tittle }}  |  {{obtenerDiasRestantes(helpPetition.creationDate)}} |
								<nb-icon icon="trash-2-outline" class="trash pointer ml-2"
				[options]="{ animation: { type: 'pulse' } }" (click)="deleteHelpPetition(helpPetition.idSos)"></nb-icon>
							</nb-list-item>
						</nb-list>
					</nb-tab>
					<nb-tab class="px-0 py-4" tabTitle="{{ 'SOS.OTHERSPETITIONS' | translate }}">
						<nb-list *ngIf="helpPetitions != null">
							<nb-list-item *ngFor="let helpPetition of helpPetitionsFromOthers" (click)="giveHelp(helpPetition)" class="btn-pressed">
								{{ helpPetition.tittle }} - {{obtenerDiasRestantes(helpPetition.creationDate)}}
							</nb-list-item>
						</nb-list>
					</nb-tab>

				</nb-tabset>
			</div>
			<div class="col-md-6">
				<h5>{{ 'SOS.HELPHISTORY' | translate }}</h5>
				<nb-tabset>
					<nb-tab class="px-0 py-4" tabTitle="{{ 'SOS.HELPRECEIVED' | translate }}">
						<nb-list *ngIf="helpPetitions != null">
							<nb-list-item *ngFor="let helpPetition of helpPetitionsReceived">
								{{ helpPetition.tittle }} - {{helpPetition.connectionDate | date : 'dd-MM-yyyy HH:mm'}}
							</nb-list-item>
						</nb-list>
					</nb-tab>
					<nb-tab class="px-0 py-4" tabTitle="{{ 'SOS.HELPGIVEN' | translate }}">
						<nb-list *ngIf="helpPetitions != null">
							<nb-list-item *ngFor="let helpPetition of helpPetitionsGiven">
								{{ helpPetition.tittle }} - {{helpPetition.connectionDate | date : 'dd-MM-yyyy HH:mm'}}
							</nb-list-item>
						</nb-list>
					</nb-tab>

				</nb-tabset>
			</div>
		</div>
	</nb-card-body>
</nb-card>


