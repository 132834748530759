<div class="pad-right-container">
  <!-- PAD RIGHT -->
  <div class="pad-buttons-right d-flex justify-content-center flex-column" [ngStyle]="{'visibility': isShow ? 'visible' : 'hidden' }">
      <!-- TOP CENTER BUTTON-->
      <div class="d-flex justify-content-center position-relative">
          <label class="label mb-2 top" *ngIf="isShowLabels">{{'PADSLABELS.FAST' | translate}}</label>
          <div class="pad-button pad-button-top" (click)="clickAction('up')">
              <mat-icon svgIcon="expand_less" class="icon-color-blue"></mat-icon>
          </div>
      </div>

      <!-- LEFT AND RIGHT BUTTONS-->

      <div class="d-flex justify-content-between align-items-center row-white">
          <div class="position-relative d-flex align-items-center">
              <label class="label mr-2 left" *ngIf="isShowLabels">{{'PADSLABELS.BACK' | translate}}</label>
              <div class="pad-button pad-button-left">
                  <mat-icon svgIcon="chevron_left" (click)="clickAction('back')" class="icon-color-blue"></mat-icon>
              </div>
          </div>

          <div class="position-relative">
              <div class="pad-button font-weight-bold"></div>
          </div>

          <div class="position-relative d-flex align-items-center">
              <label class="label ml-2 right" *ngIf="isShowLabels">{{'PADSLABELS.NEXT' | translate}}</label>
              <div class="pad-button pad-button-right">
                  <mat-icon svgIcon="chevron_right" (click)="clickAction('next')" class="icon-color-blue"></mat-icon>
              </div>

          </div>
          
          
      </div>

      <!-- BOTTOM CENTER BUTTON -->
      <div class="d-flex justify-content-center position-relative">
          <div class="pad-button pad-button-bottom" (click)="clickAction('down')">
              <mat-icon svgIcon="expand_more" class="icon-color-blue"></mat-icon>
          </div>
          <label class="label mt-2 bottom" *ngIf="isShowLabels">{{'PADSLABELS.SLOW' | translate}}</label>
      </div>

  </div>
  
</div>
