<div style="position: relative; height:100%; width:100%;" class="d-flex flex-column justify-content-center align-items-center">

	<!-- GRAFICA -->
  <canvas id="myChart"></canvas>
	<div class="position-icon-top">
		<mat-icon class="custom-icon" svgIcon="QuizSimple"></mat-icon>
	</div>

	<div class="position-icon-button">
		<mat-icon svgIcon="ideasvg"></mat-icon>
	</div>

	<!-- BOTONES DE ZOOM -->
	<div class="d-flex w-100 justify-content-end p-3">
		<button nbButton status="basic" size="small" shape="rectangle" id="zoomOutButton"><nb-icon icon="minimize-outline"></nb-icon></button>
		<button nbButton status="basic" size="small" shape="rectangle" id="zoomInButton"><nb-icon icon="maximize-outline"></nb-icon></button>
	</div>

	<!-- BOTONES DE NAVEGACION -->
	<div class="d-flex" *ngIf="currentLabel != 0">
		<div style="height: 30px; width: 30px; cursor: pointer;">
			<nb-icon ngbTooltip="{{ 'CURSOMODOAUTO.ANTERIOR' | translate }}" placement="right" icon="arrowhead-up-outline"
			(click)="anterior()" *ngIf="currentLabel != 0"></nb-icon>
		</div>
		<div style="height: 30px; width: 30px; cursor: pointer; margin-left: 5px;">
			<nb-icon ngbTooltip="{{ 'CURSOMODOAUTO.SIGUIENTE' | translate }}" placement="right" icon="arrowhead-down-outline"
			(click)="siguiente()" *ngIf="currentLabel != listLabels.length - 1 && listLabels.length != 0"></nb-icon>
		</div>
	</div>
</div>
