/*THEME COLOR*/
$primary-color: #3366ff;
$secondary-color: #f2f6ff;

/*OVERALL COLOR*/
$green-color: #05907E;
$bright-green-color: #4ac9b4;
$red-color: #ff3d71;
$blue-color: #091a7a;
$lighter-blue-color: #b6c7fb;
$yellow-color: #ffc94d;
$violet-color: #7F00FF;

/*STYLE TEXT*/
$text-color-primary: #222b45;
$text-color-secondary: #707070;
$link-color: #707070;
$form-color: #707070;

$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;


$footer-height: 155px;
$compact-header-height: 45px;
$left-bar-gray: #2f2f2f;
$blue-link: #0075E2;
