<nb-card class="no-edge-card mb-0 background_themecolor modal-height-100-62">

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-header class="px-3 py-0 flex flex-column border-bottom-0">
		<div class="row p-4 background_white gap">
			<div class="col-12 col-md-7 col-lg-8 modal-title px-2">
				<h4>{{'ENCUESTAS.TITLE' | translate}}</h4>
			</div>
		</div>
	</nb-card-header>

	<nb-card-body>
		<!-- VISTA TARJETAS -->
		<div *ngIf="!viewList" class="row background_white d-flex justify-content-center py-4 px-2 min-h-100">
			<div class="col-12">
				<app-loading [isVisible]="cargando"></app-loading>
				<div *ngIf="surveysList.length === 0">
					<nb-card class="no-edge-card">
						<nb-card-body>
							<h4 class="text-center">{{ 'CURSOS.FILTERNOTMATCH' | translate }}</h4>
						</nb-card-body>
					</nb-card>
				</div>
				<div *ngIf="surveysList.length !== 0">
					<div class="d-flex flex-wrap gap-5 justify-content-center">
						<div *ngFor="let survey of surveysList" class="card-surveys">
							<nb-card class="pointer mb-0 surveys-info" (click)="openSurvey(survey)">
								<nb-card-header class="pb-2 pl-0 pr-0 pt-0 border-bottom-0"
									nbTooltipPlacement="top">
									<div class="img-container" nbTooltip="{{survey.description}}" nbTooltipPlacement="top">
										<img [src]="'../../assets/images/no-image.png'" class="img-fluid">
									</div>
								</nb-card-header>
								<nb-card-body class="p-2 d-flex justify-content-center align-items-center">
									<p>{{ survey.title }}</p>
								</nb-card-body>
								<nb-card-footer class="p-2 d-flex justify-content-center align-items-center">
									<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;"
									[text]="survey.preguntas + ' Preguntas'"></nb-tag>
								</nb-card-footer>
							</nb-card>
						</div>
						<!--NUEVA ENCUESTA-->
						<div class="card-surveys">
							<nb-card class="pointer mb-0 new-survey" (click)="newSurvey()" ngbTooltip="{{ 'ENCUESTAS.NEW' | translate }}">
								<nb-card-body class="p-2 d-flex justify-content-center align-items-center">
									<nb-icon style="font-size: xxx-large;" class="icon-new" icon="plus-circle-outline"></nb-icon>
								</nb-card-body>
							</nb-card>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- VISTA MODO LISTA -->
		<div *ngIf="viewList" class="row min-h-100 gap-4">

		</div>
	</nb-card-body>

</nb-card>
