<div class="d-flex flex-column justify-content-center align-items-center p-4">

	<div class="w-100 d-flex justify-content-center m-2 ">
			<nb-icon class="text-center" icon="alert-triangle-outline"></nb-icon>
	</div>

	<div class="w-100">
			<p class="text-center">{{'MODALDELETECOURSE.DELETETEXT1' | translate}}</p>
			<p class="text-center">{{'MODALDELETECOURSE.DELETETEXT2' | translate}}</p>
	</div>

	<div class="row d-flex justify-content-center align-items-center gap-4 mt-4">
		<button nbButton class="btn-red" (click)="aceptar()">{{'MODALDELETECOURSE.DELETE' | translate}}</button>
		<button nbButton class="btn-themecolor" (click)="cancelar()">{{'MODALDELETECOURSE.NODELETE' | translate}}</button>
	</div>
</div>
