import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
// import { Subject } from 'src/app/core/models/courses';
import { ModelQuizFile, ModelSubQuizFile, QuizModel } from 'src/app/core/models/quizzes';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { DialogService } from 'src/app/core/services/shared/dialog.service';
import { ElementsSubQuizzesComponent } from './components/elements-sub-quizes/elements-sub-quizzes.component';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
// import { DialogService } from 'src/app/core/services/shared/dialog.service';

@Component({
  selector: 'app-quiz-edit-multiple-footer',
  templateUrl: './quiz-edit-multiple-footer.component.html',
  styleUrls: ['./quiz-edit-multiple-footer.component.scss'],
  styles: [`
    .close {
      font-size: 1.4rem;
      opacity: 0.1;
      transition: opacity 0.3s;
    }
    .nav-link:hover > .close {
      opacity: 0.8;
    }
  `],

})
export class QuizEditMultipleFooterComponent implements OnInit {

  @ViewChild(ElementsSubQuizzesComponent) private SubQuizzesComponent: ElementsSubQuizzesComponent;
  @Output() quizEdited: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateArrayElement: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateElementSize: EventEmitter<any> = new EventEmitter<any>();
  @Output() quizzAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateArrayDeleteElement: EventEmitter<any> = new EventEmitter<any>();

  public optionForm: UntypedFormGroup;
  options: UntypedFormGroup;

  public tabs: ModelSubQuizFile[] = [];
  public elementsSubQuiz: any = [];

  hideRequiredControl = new UntypedFormControl(false);
  floatLabelControl = new UntypedFormControl('auto');

  tryClose: Subject<boolean> = new Subject<boolean>();
  tryClose$: Observable<boolean> = this.tryClose.asObservable();

  @Input() quiz: QuizModel = new QuizModel();
  @Input() quizFiles: ModelQuizFile;
  @Input() quizzes: any;
  @Input() elements: any = [];
  @Input() courseId: number;
  @Input() graphId: number;
  subQuizsMultipleEdit: any;
  selected: any;

  counter: ModelSubQuizFile ;
   active: any;
  disabledTabs: boolean;
  constructor(private quizzesService: QuizzesService, private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private translateService: TranslateService,
		private toaster: ToasterService
    ) {
      this.optionForm = this.fb.group({
        subQuiz: this.fb.array([]),
        correctOptionId: null
    });

   }

  ngOnInit() {
    if(false){
      this.quizzesService.getSubQuizMultiEdit(Number(this.quiz.idOriginal)).subscribe(res => {
        this.subQuizsMultipleEdit = res;
       this.tabs = this.quizzes;
      },
      err =>
        console.log(err)
      );
   } else{
      this.updateTabs(this.quizzes)
   }
  }

  activeCrearTabs(arg0: boolean) {
    if (arg0) {
      this.disabledTabs = false;
    } else{
      this.disabledTabs = true;
    }
  }

  public onSaveData(data: any) {
    this.quizzesService.saveSubQuiz(data.idQuizMultiple, data).subscribe(res => {
      for (let index = 0; index < this.quizzes.length; index++) {
        const element = this.quizzes[index].quizMultiple as ModelSubQuizFile;
        if (element.idQuizMultiple === res.data.idQuizMultiple) {
          this.quizzes[index].quizMultiple = {... res.data};
          this.updateTabs(this.quizzes)
          break;
        }
      }
    }, err =>{
      console.log(err)
    });
	}

  updateTabs(quizzes: any){
    this.tabs = [];
    this.elements = [];
    for (let index = 0; index <quizzes.length; index++) {
      let quiz = quizzes[index].quizMultiple as ModelSubQuizFile;
      this.tabs.push(quiz);
    }
    this.elements = {...quizzes}
  }

  deleteAllQuizes(){
    this.tabs = []
    this.elements = [];
    this.quizzes = [];
  }

  updateElements(quizzes: any, index: number){
    this.elements = {...quizzes}
    this.SubQuizzesComponent.updateElement(this.elements[index].element)
  }

  updateElementsData(elements: any, index: number){
    this.SubQuizzesComponent.updateElementData(elements, index)
  }

  trackByFn(index, station) {
    return station.idQuizMultiple;
  }

  closeTab(event: MouseEvent, idQuizMultiple: number) {
    event.preventDefault();
    const dialogRef = this.dialogService.openAcceptDialog(false, undefined, this.translateService.instant('QUIZZES.DELETEQUIZ'));
		dialogRef.afterClosed().subscribe(result => {
			this.tryClose.next(result);
				if (result) {
						this.quizzesService.deleteSubQuiz(idQuizMultiple)
						.subscribe(res => {
							this.tryClose.next(false);
							this.toaster.success(this.translateService.instant('QUIZZES.SUBQUIZELIMINADO'));
							this.tabs = this.tabs.filter(id => id.idQuizMultiple !== idQuizMultiple);
							let indexQuiz = this.buscarIndex(idQuizMultiple);
							if (indexQuiz !== -1) {
								this.quizzes.splice(indexQuiz, 1);
							}
							event.stopImmediatePropagation();
						});
				}
				else {
					event.stopImmediatePropagation();
				}
		});
  }

  buscarIndex(idQuizMultiple: number): any {
    let indexArr = -1;
   for (let index = 0; index < this.quizzes.length; index++) {
     const element = this.quizzes[index].quizMultiple;
     if (element.idQuizMultiple === idQuizMultiple) {
        indexArr = index;
        break;
     }
   }
   if (indexArr !== -1) {
     return indexArr;
   }
   return -1;
  }

  addTab(event: MouseEvent) {
    event.preventDefault();
    this.quizzesService.addSubQuiz(this.quiz.idOriginal).subscribe(res => {
      let quizz = res.data.quizMultiple as ModelSubQuizFile;
      this.toaster.success(this.translateService.instant('QUIZZES.SUBQUIZAGREGADO'));
      this.quizzAdded.emit(res.data);
      // this.quizzes.push({...res.data});
      // this.tabs.push(element);
      this.active = quizz.idQuizMultiple;
      event.stopImmediatePropagation();
    }, err =>{
      console.error(err);
      event.stopImmediatePropagation();
    });
  }

  elementAdded(elementUpdateToArray){
    this.updateArrayElement.emit(elementUpdateToArray)
  }

  elementUpdatedSize(elementUpdateToArray){
    this.updateElementSize.emit(elementUpdateToArray)
  }

  deleteElement( elementDelete){
    this.updateArrayDeleteElement.emit(elementDelete);
  }

}
