import { UserParentModel } from './user-parent.model';
import { UserAuthorModel } from './user-author.model';
import { UserProfileValidationModel } from './user-profile-validation.model';

export class UserRegisterModel {
    idUser: number;
    nick: string;
    keyWord: string;
    firstName: string;
    surname: string;
    birthdate: Date;
    birthdateString: string;
    mail: string;
    mobile: string;
    pictureUser: string;
    idPreferredLanguage: number;
    salwareAdministration: boolean;
    creationDate: Date;
    creationDateString: string;
    profile: string;
    idGenre: string;
    idLanguageIso: string;
    validationDate: Date;
    validationDateString: string;
    fec_Create: string;
    isValid: string;
    // Completo los datos
    usersParent: UserParentModel[];
    usersAuthor: UserAuthorModel[];
    usersProfileValidation: UserProfileValidationModel[];
    // No es de BD
    sonValidated: boolean;
    extension: string
}
