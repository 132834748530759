import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { InformesCentroComponent } from '../../informes-centro/informes-centro.component';
import { ReportService } from 'src/app/core/services/report/report.service';
import { forkJoin } from 'rxjs';
import { SurveysService } from 'src/app/core/services/surveys/surveys.service';

interface NavigatorWithConnection extends Navigator {
  connection?: {
    downlink: number;
  };
}
@Component({
  selector: 'app-second-step-statistics',
  templateUrl: './second-step-statistics.component.html',
  styleUrls: ['./second-step-statistics.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SecondStepStatisticsComponent implements OnInit {

	consultas: any[] = [];
	tipoDatos: string;
	allDatasets: any[] = [];
	datasetsImported_1: any[] = [];
	datasetsImported_2: any[] = [];
	labelsImported_1: any[] = [];
	labelsImported_2: any[] = [];
	cargarCharts: boolean = false;
	selectedLabel = null;

	surveysList: any[] = [];
	realData: boolean = false;
	networkSpeed = 1;

	constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private reportService: ReportService,
		public surveysService: SurveysService,
	) { }

	ngOnInit() {
		if(this.tipoDatos != 'social'){
			this.getDataForAcademic();
		} else{
			this.getDataForSocial();
		}

		this.networkSpeed = this.getNetworkSpeed(); //primero verificamos la velocidad de internet
	}

	getDataForAcademic(){
		// Crear un array de observables
		const requests = [];
		if(!this.realData){
			for (let index = 0; index < 1; index++) {
				const element = this.consultas[0];
				// Pushear cada petición en el array de requests
				requests.push(this.reportService.getInformeCenter2(1, element.id));
			}
		} else{
			for (let index = 0; index < 1; index++) {
				const element = this.consultas[0];
				// Pushear cada petición en el array de requests
				requests.push(this.reportService.getInformeCenter(1, element.id));
			}
		}
		// Usar forkJoin para esperar a que todas las peticiones terminen
		forkJoin(requests).subscribe((responses: any[]) => {
				// Procesar las respuestas
				responses.forEach((res, index) => {
					this.allDatasets = res.data;
					if(this.consultas[0].id == 1){
						//profesores por nivel
						this.datasetsImported_1 = res.data[0].data;
						this.labelsImported_1 = [res.data[0]];
						//profesores por asignatura
						this.datasetsImported_2 = res.data[0].children[0].children[0].data;
						this.labelsImported_2 = [res.data[0].children[0].children[0]];
					} else if(this.consultas[0].id == 4){
						//grupos por nivel
						this.datasetsImported_1 = res.data[0].data;
						this.labelsImported_1 = [res.data[0]];
						//grupos por asignatura
						this.datasetsImported_2 = res.data[0].children[0].data;
						this.labelsImported_2 = [res.data[0].children[0]];
					} else if(this.consultas[0].id == 8){
						//estudiantes por grupo
						this.datasetsImported_1 = res.data[0].children[0].data;
						this.labelsImported_1 = [res.data[0].children[0]];
						//estudiantes por asignatura
						this.datasetsImported_2 = res.data[0].children[0].children[0].data;
						this.labelsImported_2 = [res.data[0].children[0].children[0]];
					} else if(this.consultas[0].id == 19){
						//asignaturas por nivel
						this.datasetsImported_1 = res.data[0].data;
						this.labelsImported_1 = [res.data[0]];
						//asignaturas por grupo
						this.datasetsImported_2 = res.data[0].children[0].data;
						this.labelsImported_2 = [res.data[0].children[0]];
					} else if(this.consultas[0].id == 10){//revisar
						//niveles por grupos
						this.datasetsImported_1 = res.data[0].data;
						this.labelsImported_1 = [res.data[0]];
						//niveles por asignaturas
						this.datasetsImported_2 = res.data[0].children[0].data;
						this.labelsImported_2 = [res.data[0].children[0]];
					} else if(this.consultas[0].id == 12){
						//padres por grupo
						this.datasetsImported_1 = res.data[0].children[0].data;
						this.labelsImported_1 = [res.data[0].children[0]];
						//padres por asignatura
						this.datasetsImported_2 = res.data[0].children[0].children[0].data;
						this.labelsImported_2 = [res.data[0].children[0].children[0]];
					} else if(this.consultas[0].id == 21){
						//estudiantes con bajo rendimiento por grupo
						this.datasetsImported_1 = res.data[0].children[0].data;
						this.labelsImported_1 = [res.data[0].children[0]];
						//estudiantes con bajo rendimiento por asignatura
						this.datasetsImported_2 = res.data[0].children[0].children[0].data;
						this.labelsImported_2 = [res.data[0].children[0].children[0]];
					}

				});
				// Una vez que todas las peticiones hayan terminado
				this.cargarCharts = true;
		});
	}

	getDataForSocial(){
		if(this.realData){
			this.surveysService.getAllSurveysForInformeReal().subscribe( res => {
				this.surveysList = res.data;
			});
		} else{
			this.surveysService.getAllSurveysForInforme().subscribe( res => {
				this.surveysList = res.data;
			});
		}
	}

	ngOnDestroy() {}

	closeModal(sendData?: any) {
		this.activeModal.close();
	}

	getNetworkSpeed() {
		const navigatorWithConnection = navigator as NavigatorWithConnection;
		if (navigatorWithConnection.connection) {
			return navigatorWithConnection.connection.downlink;
		} else {
			return 10; // No se pudo obtener la velocidad de conexión
		}
  }

	updateUrl(event: any) {
		event.target.src = "../../assets/images/no-image.png";
	}

	openChart(idConsulta: number){
		//elegimos que grafica ver al clicar
		let grafica = 1;
		switch (this.tipoDatos) {
			case "teacher":
				grafica = idConsulta == 1 ? 4 : 3;
				break;
			case "student":
				grafica = idConsulta == 8 ? 3 : 1;
				break;
			case "groups":
				grafica = idConsulta == 4 ? 3 : 2;
				break;
			case "levels":
				grafica = idConsulta == 10 ? 2 : 4;
				break;
			case "parents":
				grafica = idConsulta == 12 ? 1 : 3;
				break;
			case "subjects":
				grafica = idConsulta == 19 ? 2 : 4;
				break;
			case "failure":
				grafica = idConsulta == 21 ? 3 : 1;
				break;
		}
		const modalRef = this.modalService.open(
			InformesCentroComponent,
			{
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W90,
			}
		);
		modalRef.componentInstance.consultas = this.consultas;
		modalRef.componentInstance.parametroID = 1;
		modalRef.componentInstance.consultaID = idConsulta;
		modalRef.componentInstance.verBotonesGraficas = grafica;
		modalRef.componentInstance.allDatasets = this.allDatasets;
		const data_1 = [1, 4, 8, 19, 10, 12, 21];
		const data_2 = [2, 5, 9, 20, 11, 13, 22];
		if(data_1.includes(idConsulta)){
			modalRef.componentInstance.datasetsImported = this.datasetsImported_1;
			modalRef.componentInstance.labelsImported = this.labelsImported_1;
		} else if(data_2.includes(idConsulta)){
			modalRef.componentInstance.datasetsImported = this.datasetsImported_2;
			modalRef.componentInstance.labelsImported = this.labelsImported_2;
		}
		modalRef.result.then((result: string) => {
		});
	}

	openSurvey(survey: any) {
		if(this.realData){
			this.surveysService.getSocialInformeCenterReal(survey.idSurvey).subscribe(res => {
				this.allDatasets = res.data;
				this.datasetsImported_1 = res.data[0].data;
				this.labelsImported_1 = [res.data[0]];
				// Mueve la apertura del modal aquí
				const modalRef = this.modalService.open(InformesCentroComponent,
					{
						scrollable: true,
						windowClass: MODAL_DIALOG_TYPES.W90,
					}
				);
				modalRef.componentInstance.consultas = this.consultas;
				modalRef.componentInstance.parametroID = 0;
				modalRef.componentInstance.consultaID = 0;
				modalRef.componentInstance.verBotonesGraficas = 2;
				modalRef.componentInstance.allDatasets = this.allDatasets;
				modalRef.componentInstance.datasetsImported = this.datasetsImported_1;
				modalRef.componentInstance.labelsImported = this.labelsImported_1;
				modalRef.componentInstance.fromCalendar = false;
				modalRef.result.then((result: string) => {
						// Acciones si es necesario manejar el resultado
				});
			});
		} else{
			this.surveysService.getSocialInformeCenter(survey.idSurvey).subscribe(res => {
				this.allDatasets = res.data;
				this.datasetsImported_1 = res.data[0].data;
				this.labelsImported_1 = [res.data[0]];
				// Mueve la apertura del modal aquí
				const modalRef = this.modalService.open(InformesCentroComponent,
					{
						scrollable: true,
						windowClass: MODAL_DIALOG_TYPES.W90,
					}
				);
				modalRef.componentInstance.consultas = this.consultas;
				modalRef.componentInstance.parametroID = 0;
				modalRef.componentInstance.consultaID = 0;
				modalRef.componentInstance.verBotonesGraficas = 2;
				modalRef.componentInstance.allDatasets = this.allDatasets;
				modalRef.componentInstance.datasetsImported = this.datasetsImported_1;
				modalRef.componentInstance.labelsImported = this.labelsImported_1;
				modalRef.componentInstance.fromCalendar = false;
				modalRef.result.then((result: string) => {
						// Acciones si es necesario manejar el resultado
				});
			});
		}
	}

}
