import { Component, Input, OnInit, ViewEncapsulation, HostListener } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ReportService } from "src/app/core/services/report/report.service";

@Component({
    selector: "app-informe-admin",
    templateUrl: "./informe-admin.component.html",
    styleUrls: ["./informe-admin.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations : [
        trigger('accordion', [
      state('open', style({
        width: 'auto',
                transform: 'translateY(0)',
            overflow: 'initial',
                right: '10px'
      })),
      state('closed', style({
        width: '0',
                overflow: 'hidden',
            transform: 'translateY(-500px)',
                right: '-200px'
      })),
      transition('open <=> closed', [
        animate('0.3s ease-out')
      ]),
    ]),
    trigger('accordion2', [
      state('in', style({
        width: 'auto',
                transform: 'translateX(0px)',
            overflow: 'initial',
                left: '10px'
      })),
      state('out', style({
        width: '0',
                overflow: 'hidden',
            transform: 'translateX(-500px)',
                left: '-200px'
      })),
      transition('in <=> out', [
        animate('0.3s ease-in-out')
      ]),
    ])
    ]
})
export class InformeAdminComponent implements OnInit {
    @Input() allDatasets: any[] = [];
    @Input() datasetsImported: any;
    @Input() labelsImported: any;

    selectedLabel = null; // Variable para guardar el elemento seleccionado
    verBotonesGraficas: number = 0;
    consulta: number = 0;
    role: number = 0;

    isMobile: boolean = false;
    isMobile1: boolean = false;
    isMenuOpen: boolean = false;
    accordionOpen: boolean = false;
    accordionOpen2: boolean = false;

    constructor(
        public activeModal: NgbActiveModal,
        private translateService: TranslateService,
        private reportService: ReportService,
    ) { }

    ngOnInit() {
        this.isMobile = window.innerWidth < 1200;
        this.isMobile1 = window.innerWidth < 1350;
        this.verBotonesGraficas = 1;
        this.viewData(this.allDatasets[0].data, this.allDatasets[0]);
        this.viewChildren2(0);
    }

    ngOnDestroy() {
    }

    closeModal(sendData?: any) {
        this.activeModal.close();
    }

    @HostListener("window:resize", ["$event"])
    onResize(event: any): void {
        if (window.innerWidth <= 1200) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }

        if (window.innerWidth <= 1350) {
            this.isMobile1 = true;
        } else {
            this.isMobile1 = false;
        }
    }

    viewChildren2(i: number){
        this.allDatasets[i].childrenVisible = !this.allDatasets[i].childrenVisible;
        for (let index = 0; index < this.allDatasets[i].children.length; index++) {
            this.allDatasets[i].children[index].childrenVisible = false;
        }
    }
    viewChildren3(i: number, j: number){
        this.allDatasets[i].children[j].childrenVisible = !this.allDatasets[i].children[j].childrenVisible;
    }
    closeTree(){
        if(this.allDatasets != null && this.allDatasets.length > 0){
            for (let i = 0; i < this.allDatasets.length; i++) {
                this.allDatasets[i].childrenVisible = false;
                if(this.allDatasets[i].children != null && this.allDatasets[i].children.length > 0){
                    for (let j = 0; j < this.allDatasets[i].children.length; j++) {
                        this.allDatasets[i].children[j].childrenVisible = false;
                    }
                }
            }
        }
    }

    ocultarTodo(){
		//ocultamos la grafica de barras
		const div2 = document.getElementById("barras");
		if(div2){
			div2.style.display = "none";
		}
	}

    viewData(data: any, select: any){
        const aux = this.verBotonesGraficas;
        this.ocultarTodo();
        this.selectedLabel = select;
        this.labelsImported = [this.selectedLabel];
        this.datasetsImported = data;
        this.verBotonesGraficas = 0;
        setTimeout(() => {
            if(aux == 1){
                this.verBarras();
            }
		}, 100);
    }

    verBarras(){
        //mostramos la grafica de barras
        const div2 = document.getElementById("barras");
        if(div2){
            div2.style.display = "block";
        }
        this.verBotonesGraficas = 1;
		this.accordionOpen = false;
    }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
        this.accordionOpen = false;
        this.accordionOpen2 = false;
    }

    toggleAccordion() {
        this.isMenuOpen = false;
        this.accordionOpen = !this.accordionOpen;
        this.accordionOpen2 = false;
    }

    toggleAccordion2() {
        this.isMenuOpen = false;
        this.accordionOpen = false;
        this.accordionOpen2 = !this.accordionOpen2;
    }
}
