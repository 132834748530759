export const FOTOS = {
	ESTUDIANTES: [
		{id: 1, picture: "https://images.generated.photos/ejvvf3S4N_o9nEAIfIxZ38bhPP62W5m47innW6LEjYQ/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NzAyMjI4LmpwZw.jpg"},
		{id: 2, picture: "https://images.generated.photos/jRWHfkdCWcB6ZIKlE354KWHI30SDuKX7aDwBhLjj1kc/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/OTcwODU5LmpwZw.jpg"},
		{id: 3, picture: "https://images.generated.photos/FKYmBDjNFoT5Vd-xoN5HdhiHaRm_Deup-bxT1zxBbG4/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MTc3NzU3LmpwZw.jpg"},
		{id: 4, picture: "https://images.generated.photos/UWWxzjLLE6hDj2gkFLTZOW4OF5vZ69ytVjqhbL9DQ4Y/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjQ1ODQ3LmpwZw.jpg"},
		{id: 5, picture: "https://images.generated.photos/pHgnRFp13HMBvBeKXNq-x-d7rpx-Fc0FPQXGT5DyOj8/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDEwMTUyLmpwZw.jpg"},
		{id: 6, picture: "https://images.generated.photos/DW-eL0KMzvmqmQxES1w5Wxb0gwteAUBkLpMvX48xxfE/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MTAzODY1LmpwZw.jpg"},
		{id: 7, picture: "https://images.generated.photos/iD4kk9EKH4KqD893CPZDHGDybcZCvzQ6UelhAgpkdu8/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NDA2MTU5LmpwZw.jpg"},
		{id: 8, picture: "https://images.generated.photos/Frd_KP94eazRvQJXgzei8au44UwdzC3JMjWjbZndzmc/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/ODg0MDQxLmpwZw.jpg"},
		{id: 9, picture: "https://images.generated.photos/aBMve_9ohJXLlYN3tbrNpe5ipa7suUhbikTbhc_74GQ/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NjEwNjM3LmpwZw.jpg"},
		{id: 10, picture: "https://images.generated.photos/h8FItyIuq6AdPYUokoAh2xmVk1hBO4P2_pBvvafXw0A/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NTc1MjkzLmpwZw.jpg"},
		{id: 11, picture: "https://images.generated.photos/R4XM4gdpxMgBd3Xq1QmdTtfv2XRt6xFPrqIBcFN0vko/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/ODU5MjE2LmpwZw.jpg"},
		{id: 12, picture: "https://images.generated.photos/nhI83w57qIJukVaNMacOOYWX6FlWcwb0dkouroUS1Oo/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/ODA1Njc2LmpwZw.jpg"},
		{id: 13, picture: "https://images.generated.photos/DeDBHynWdv1FeQUR_Ai-3WcrAmSsmmrqi9ZXHPJrp6M/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NzQyOTY4LmpwZw.jpg"},
		{id: 14, picture: "https://images.generated.photos/qcF8JjN3LMPShS8zKiQmIe5i0ax6uwWwCRsyBsPmoQ0/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjAxOTU2LmpwZw.jpg"},
		{id: 15, picture: "https://images.generated.photos/Z1WTdMXD3ZuN-d8ZQql7wEuU_IalsfxLdK5JMOR_JHU/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjM3NTc0LmpwZw.jpg"},
		{id: 16, picture: "https://images.generated.photos/6FhyhD_lCEZFseJa1PSSo901sYAiupYv7kNmiY-NhLM/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NDg4NTM1LmpwZw.jpg"},
		{id: 17, picture: "https://images.generated.photos/fubYSxXEK7Ms1x4k40DQapz2KPbkY1ekgIc4zTUNj-o/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/ODI4MzI1LmpwZw.jpg"},
		{id: 18, picture: "https://images.generated.photos/zHAfKlsh0zrmLiqwGdBpz5o0-d8skujXXgLWey3DAlw/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NzM4MDM5LmpwZw.jpg"},
		{id: 19, picture: "https://images.generated.photos/5rdT81U0gw5EM43HOUrg6XHMePjhB35-ttfQ76C-9t8/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/ODk4NjIwLmpwZw.jpg"},
		{id: 20, picture: "https://images.generated.photos/ikjBd36TACkPFhp6HcRqoytyuXz40jLR4uoCZVhEWQo/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MzA0ODAwLmpwZw.jpg"},
		{id: 21, picture: "https://images.generated.photos/42JPMjfB1Rv26qiWv0q2HGCYfXqvfrl_R5Ef8TZPcd4/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MTczMTI5LmpwZw.jpg"},
		{id: 22, picture: "https://images.generated.photos/g0NvSMcWSum_63T3TK4dB3GvSF434S2bOtx-TG4anwU/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MTgyMDQwLmpwZw.jpg"},
		{id: 23, picture: "https://images.generated.photos/jIflFO2Hdc66UP8_gbqxUEPst6CEnzMQv9mHSoVYbxU/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDM4NjMyLmpwZw.jpg"},
		{id: 24, picture: "https://images.generated.photos/eh8_VqbLiC6gIW44PABCb-FFReKzwa_bNiCXGmFIINM/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MTYxNjYzLmpwZw.jpg"},
		{id: 25, picture: "https://images.generated.photos/JkGd50pjRL4Hxha5t94hlJyHWQNwOHRI8PSH1HGQNZ4/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NTk4ODM1LmpwZw.jpg"},
		{id: 26, picture: "https://images.generated.photos/U2Pc4W2JWUAZJrALZu31eCXQkuh-zZvt1yNvMqjcLe0/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDY1MDQ0LmpwZw.jpg"},
		{id: 27, picture: "https://images.generated.photos/dtr8ERVPVAD0GbGEKwUimcJhZhHtjCm2VW2WZvwpwG0/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NTcyMjY5LmpwZw.jpg"},
		{id: 28, picture: "https://images.generated.photos/WS7YZBNVstZzR8tknUN0sQauPou2xKvHLJug62yXaRM/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/Nzc3MzMwLmpwZw.jpg"},
		{id: 29, picture: "https://images.generated.photos/-vRUOPHG5g1FnXy6bmvTpgWCQ13HkEDRpWYZxn0V624/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjczNTM3LmpwZw.jpg"},
		{id: 30, picture: "https://images.generated.photos/LSfbWUWYQanr9zK3Gr57VEZHSDk__NFv-83g6N7xpE4/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDcyNzA1LmpwZw.jpg"},
	],

	PROFESORES: [
		{id: 1, picture: "https://images.generated.photos/7Ndu5IcjMxmQ2YsU7x7RVfVeOFdpnd-N9wmJxAyCWuo/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NjYzNTA2LmpwZw.jpg"},
		{id: 2, picture: "https://images.generated.photos/8xM0gjMDZe_w2EdYN_L-p0HFbC8ngWO9UyQRBuGRuTw/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/ODk2ODkzLmpwZw.jpg"},
		{id: 3, picture: "https://images.generated.photos/Kbw2Vj8UGH738cP6vq9duOTMPK99K5Vb57y24zftAVs/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjIzNTkwLmpwZw.jpg"},
		{id: 4, picture: "https://images.generated.photos/X6OMUnBl1rlOBnPgTN6LbCR8Jf81eUXbh0Bx0XTZE84/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/OTA0MTIyLmpwZw.jpg"},
		{id: 5, picture: "https://images.generated.photos/ZkfA_RmoIKxiL5-6l7BHV1mf9h2oLGRPcr_5VNpxS5k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MzYzNjc0LmpwZw.jpg"},
		{id: 6, picture: "https://images.generated.photos/lb6capv7v5ks3SDqg8Aa8OsF-UyWSLfzKliudFsIg58/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjY0MDUyLmpwZw.jpg"},
		{id: 7, picture: "https://images.generated.photos/EaK_N7mp83PlmZ6DVuyIl4YJ5CENKqBP4H43vdSdciw/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NDE3MTI5LmpwZw.jpg"},
		{id: 8, picture: "https://images.generated.photos/MLfoPliptvAPbGBchM0WD8plmjoxU39IK4sVW4obiyU/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/ODEyOTI1LmpwZw.jpg"},
		{id: 9, picture: "https://images.generated.photos/skWsFZT8RaiT2qieHiuB3rvitvJLFrwQmwO_xkTuZqA/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDk5MjE4LmpwZw.jpg"},
		{id: 10, picture: "https://images.generated.photos/6uAXw3d_MscdDzC0zbSNQ7Kt9Xdf5MEgE7yyWADkDhU/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/ODQ3MTIzLmpwZw.jpg"},
		{id: 11, picture: "https://images.generated.photos/CZdEXnaCYhtFqZPzySgeMDGegzHjtq3pG-KJ9jVQ0LQ/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/OTQ2NDk5LmpwZw.jpg"},
	],

	PADRES : [
		{id: 1, picture: "https://images.generated.photos/IzEFOGp1-qLBf7Ou_RYhmVillWxWAo5Nag-YsWyBjFM/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/Njg1NTU2LmpwZw.jpg"},
		{id: 2, picture: "https://images.generated.photos/8xM0gjMDZe_w2EdYN_L-p0HFbC8ngWO9UyQRBuGRuTw/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/ODk2ODkzLmpwZw.jpg"},
		{id: 3, picture: "https://images.generated.photos/ZkfA_RmoIKxiL5-6l7BHV1mf9h2oLGRPcr_5VNpxS5k/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MzYzNjc0LmpwZw.jpg"},
		{id: 4, picture: "https://images.generated.photos/ZYYgdLFoW38dB3drr7AQO1xMWxu450TJvhYO8ZVf1ks/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NzYxOTAwLmpwZw.jpg"},
		{id: 5, picture: "https://images.generated.photos/C0lMoaR3uXJdsqUyv6VFBRmKXCfls05BRyirDtyyMEs/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjMyMTcxLmpwZw.jpg"},
		{id: 6, picture: "https://images.generated.photos/pCwV_UeaDkMZhuw4aRsd5Pjt1GcJpWaDsvYZRe_L2yc/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/ODI3MzA5LmpwZw.jpg"},
		{id: 7, picture: "https://images.generated.photos/HNsVK8907p7TSP7aBPyfGdLbkI-IGVtXJ1fOlUpqYUk/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MTAwNjM0LmpwZw.jpg"},
		{id: 8, picture: "https://images.generated.photos/29jJnx0ZKkzJrYqA2lxbhGrbl4mABNWC4JrakhgMId0/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MTg1NDA2LmpwZw.jpg"},
		{id: 9, picture: "https://images.generated.photos/lb6capv7v5ks3SDqg8Aa8OsF-UyWSLfzKliudFsIg58/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjY0MDUyLmpwZw.jpg"},
		{id: 10, picture: "https://images.generated.photos/kYDGxt9HJtBPNTa1YCpgjdkgxM8rVeQb1wK0qdGyNXE/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NDY0ODk3LmpwZw.jpg"},
		{id: 11, picture: "https://images.generated.photos/QbeWlSAuUA3S3pTUDy0Gc2zDB2-_Pe_Ql70UHJZTAiU/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDIwOTI4LmpwZw.jpg"},
		{id: 12, picture: "https://images.generated.photos/ucxEKEP5RY78vbLo9tOhEdKOrOqW6CvQE8hDp6g9c5g/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NDI0Nzc2LmpwZw.jpg"},
		{id: 13, picture: "https://images.generated.photos/48cJDGEpIIzHebJt5EkWx2bKuLO9Td6rqUOQS-0Ev_o/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NTk1NTMyLmpwZw.jpg"},
		{id: 14, picture: "https://images.generated.photos/EllWEnvmsfCTOT9FEaQkN3TMzKTDs7KB2KanteUuJo4/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/OTI0MTQzLmpwZw.jpg"},
		{id: 15, picture: "https://images.generated.photos/vASWeDkNjO_a_rTOtb5idMFmcgdx7ghZCYBdAyOu6X8/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NzIxMTIyLmpwZw.jpg"},
		{id: 16, picture: "https://images.generated.photos/mDN9rOkdVvnxYKkrkaFTSg_rwMC2RvDYjtgodpp86xY/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NDcxNzkzLmpwZw.jpg"},
		{id: 17, picture: "https://images.generated.photos/_jrQjy92siB7i9Bs-XK4czBjlVzHnEEUYbxtfb4EjpQ/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/Mjc3MzQzLmpwZw.jpg"},
		{id: 18, picture: "https://images.generated.photos/zg98GpA7gSZDkHe9ujsk-aw_wss49GlL0lTtnqT--xI/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDI1NTc2LmpwZw.jpg"},
		{id: 19, picture: "https://images.generated.photos/o7f5i7z8avuFPpbm0XvqObrbrRO7Stq9XifsZX9Eayw/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/OTI5NDY2LmpwZw.jpg"},
		{id: 20, picture: "https://images.generated.photos/x5r0VNheD4UAccl4G0MIDkEV6DhK27bFwqkKv6QpmmQ/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/Njc2ODc4LmpwZw.jpg"},
		{id: 21, picture: "https://images.generated.photos/AbnJeADipVEc32X4wfumMUnS_ft4NEH4oTHhANCTygw/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/Mjg5NjE5LmpwZw.jpg"},
		{id: 22, picture: "https://images.generated.photos/8q01SX4JFUEQ8gy39ySWhdRfkem5dD_WL55CvhHIGhc/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjAxNjgyLmpwZw.jpg"},
		{id: 23, picture: "https://images.generated.photos/dFkIrpW9MOWyCsE0IneKkRvycqaAjUTG7cUp9a8OPYk/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/Nzk5NTQyLmpwZw.jpg"},
		{id: 24, picture: "https://images.generated.photos/OFe8iUz0G1gnxgfoKp3zfn9dglnr-YlbGsJiM9wgPHo/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/OTcyNzEyLmpwZw.jpg"},
		{id: 25, picture: "https://images.generated.photos/u5RTZ3nDyQkE6jxgiQ1Lzi-_5YTQQ102_OWyJl8l-nY/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/Nzc3NDk5LmpwZw.jpg"},
		{id: 26, picture: "https://images.generated.photos/MmgrtECwc2LJyil8BooXCeKUng5zMsj9C0T3SbxRjlw/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NDE2MTU0LmpwZw.jpg"},
		{id: 27, picture: "https://images.generated.photos/ill5BdiKnsNw8a2gl1uz8oezK0GcBiFSEjBY-F-oWrM/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NTM3MjM1LmpwZw.jpg"},
		{id: 28, picture: "https://images.generated.photos/r6G6WXSBvMp2gPFfOYzL2l3L7LyW3v9Maq5v5Y1CkgE/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MzYzNTE0LmpwZw.jpg"},
		{id: 29, picture: "https://images.generated.photos/opTkbPZN83YWbvHsd7jPTKn8RbY1oYEMXGpH5evCsQw/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NjM0MTIyLmpwZw.jpg"},
		{id: 30, picture: "https://images.generated.photos/fwwAv5W-QUJwGKGrLHMEWx0Dm-bDgEQhUUZdnuXEOmQ/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NTMxOTQ5LmpwZw.jpg"},
	]
}
