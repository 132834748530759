<div class="well">
    <div class="row mt-0 mb-3">
        <div class="col pull-right">
            <button type="button" class="close">&times;</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <!--<div [class.spinner]="loading">-->
            <!--form (ngSubmit)="onSubmit()" #dataForm="ngForm" [formGroup]="form"-->
            <form [formGroup]="form">
                <div class="form-group align-items-center row my-0">
                    <div class="col-md-4 col-sm-12">
                        <label for="name" class="col-form-label col-form-label-sm">
                            {{ 'NODEFORMCOMPONENT.NODENAME' | translate }}
                        </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <input class="form-control form-control-sm" type="text"
                            placeholder="{{ 'NODEFORMCOMPONENT.TYPENODENAME' | translate }}" formControlName="name">
                    </div>
                </div>
                <div class="form-group align-items-center row mt-1 mb-0">
                    <div class="col-md-4 col-sm-12">
                        <label for="description" class="col-form-label col-form-label-sm">
                            {{ 'NODEFORMCOMPONENT.DESCRIPTION' | translate }}
                        </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <div class="input-group input-group-sm mb-0">
                            <input class="form-control form-control-sm" type="text"
                                placeholder="{{ 'NODEFORMCOMPONENT.TYPEDESCRIPTION' | translate }}"
                                formControlName="description">
                            <div class="input-group-addon">
                                <button id="more" class="btn btn-ligh btn-sm" data-toggle="modal"
                                    data-target="#descModal" type="button">
                                    ...
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group align-items-center row mb-0 mt-1">
                    <div class="col-md-4 col-sm-12">
                        <label for="duration" class="col-form-label col-form-label-sm">
                            {{ 'NODEFORMCOMPONENT.DURATION' | translate }}
                        </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <input class="form-control form-control-sm" type="time" formControlName="duration">
                    </div>
                </div>
                <div class="form-group align-items-center row mb-0 mt-1">
                    <div class="col-md-4 col-sm-12">
                        <label for="level" class="col-form-label col-form-label-sm">
                            {{ 'NODEFORMCOMPONENT.LEVEL' | translate }}
                        </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <input class="form-control form-control-sm" type="number" formControlName="level">
                    </div>
                </div>
                <!---  ****** -->
                <!--- POWER -->
                <div class="row mt-3 power-box">
                    <div class="col-2">
                        <p class="pow"><b>POWER</b></p>
                        <div class="img-pow-container">
                            <!-- <button mat-mini-fab class="fab-secondary bolt-up " color="secondary">
                                <span class="">+</span>
                            </button>
                            <button mat-mini-fab class="fab-secondary" color="secondary" style="z-index: 1;">
                                <i class="active material-icons text-center" style="vertical-align: top">bolt</i>
                            </button>
                            <button mat-mini-fab class="fab-secondary bolt-down" color="secondary">
                                <span class="">-</span>
                            </button> -->

                            <app-power-component [disabled]="true"></app-power-component>
                        </div>
                    </div>
                    <div class="col-10 mt-2 text-right">
                        <div class="form-group row mb-0">
                            <div class="col-md-2 col-sm-12 col-md-2">
                                <label for="power2" class="col-form-label col-form-label-sm ">+2</label>
                            </div>
                            <div class="form-check col-md-8 col-sm-12 col-md-8">
                                <div class="col-form-label col-form-label-sm container-inputs-power">
                                    <input class="form-check-input float-left" type="checkbox" formControlName="power2"
                                        id="power2">
                                    <label class="form-check-label" for="power2"></label>
                                    <input class="form-control form-control-sm" type="number"
                                        formControlName="ordinalPower2">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <div class="col-md-2 col-sm-12 col-md-2">
                                <label for="power1" class="col-form-label col-form-label-sm by-0 mt-1">+1</label>
                            </div>
                            <div class="form-check col-md-8 col-sm-12 col-md-8">
                                <div class="col-form-label col-form-label-sm container-inputs-power">
                                    <input class="form-check-input float-left" type="checkbox" formControlName="power1"
                                        id="power1">
                                    <label class="form-check-label" for="power1"></label>
                                    <input class="form-control form-control-sm" type="number"
                                        formControlName="ordinalPower1">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <div class="col-md-2 col-sm-12 col-md-2">
                                <label for="power0" class="col-form-label col-form-label-sm ">0</label>
                            </div>
                            <div class="form-check col-md-8 col-sm-12 col-md-8">
                                <div class="col-form-label col-form-label-sm container-inputs-power">
                                    <input class="form-check-input float-left" type="checkbox" formControlName="power0"
                                        id="power0">
                                    <label class="form-check-label" for="power0"></label>
                                    <input class="form-control form-control-sm" type="number"
                                        formControlName="ordinalPower0">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <div class="col-md-2 col-sm-12 col-md-2">
                                <label for="powerNegative1"
                                    class="col-form-label col-form-label-sm by-0 mt-1">-1</label>
                            </div>
                            <div class="form-check col-md-8 col-sm-12 col-md-8">
                                <div class="col-form-label col-form-label-sm container-inputs-power">
                                    <input class="form-check-input float-left" type="checkbox"
                                        formControlName="powerNegative1" id="powerNegative1">
                                    <label class="form-check-label" for="powerNegative1"></label>
                                    <input class="form-control form-control-sm" type="number"
                                        formControlName="ordinalPowerNegative1">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <div class="col-md-2 col-sm-12 col-md-2">
                                <label for="powerNegative2" class="col-form-label col-form-label-sm ">-2</label>
                            </div>
                            <div class="form-check col-md-8 col-sm-12 col-md-8">
                                <div class="col-form-label col-form-label-sm container-inputs-power">
                                    <input class="form-check-input float-left" type="checkbox"
                                        formControlName="powerNegative2" id="powerNegative2">
                                    <label class="form-check-label" for="powerNegative2"></label>
                                    <input class="form-control form-control-sm" type="number"
                                        formControlName="ordinalPowerNegative2">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="form-group row mb-0 mt-1">
                    <div class="col-md-4 col-sm-12 col-md-4">
                        <label for="certificable" class="col-form-label col-form-label-sm by-0 mt-1">{{
                            'NODEFORMCOMPONENT.CERTIFICABLE' | translate }}</label>
                    </div>
                    <div class="form-check col-md-8 col-sm-12 col-md-8">
                        <div class="col-form-label col-form-label-sm">
                            <input class="form-check-input float-left" type="checkbox" formControlName="certificable"
                                id="certificable">
                            <label class="form-check-label" for="certificable"></label>
                        </div>
                    </div>
                </div>
                <div class="form-group align-items-center row mb-0 mt-0">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm" for="language1">
                            {{ 'NODEFORMCOMPONENT.LANGUAGE1' | translate }}
                        </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <select class="form-control form-control-sm" formControlName="language1">
                            <option>English</option>
                        </select>
                    </div>
                </div>
                <div class="form-group align-items-center row mb-0 mt-1">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm" for="language2">
                            {{ 'NODEFORMCOMPONENT.LANGUAGE2' | translate }}
                        </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <select class="form-control form-control-sm" formControlName="language2">
                            <option>English</option>
                        </select>
                    </div>
                </div>
                <div class="form-group align-items-center row mb-0 mt-1">
                    <label for="ordinal" class="col-sm-4 col-form-label col-form-label-sm">
                        {{ 'QUIZFORMCOMPONENT.ORDINAL' | translate }}
                    </label>
                    <div class="col-sm-8">
                        <input class="form-control form-control-sm" type="number" formControlName="ordinal">
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record && record.author">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm" for="author">
                            {{ 'NODEFORMCOMPONENT.AUTHOR' | translate }}
                        </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">
                            {{record.author.firstName + ' ' + record.author.surname}}
                        </label>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1">
                    <div class="col-md-4 col-sm-12">
                        <label for="video" class="col-form-label col-form-label-sm mt-1">
                            {{ 'NODEFORMCOMPONENT.VIDEO' | translate }}
                        </label>
                    </div>
                    <div class="form-check">
                        <div class="col-sm-6 col-form-label col-form-label-sm">
                            <input class="form-check-input float-left" type="checkbox" formControlName="video"
                                id="video">
                            <label class="form-check-label" for="video"></label>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-0">
                    <div class="col-md-4 col-sm-12">
                        <label for="image" class="col-form-label col-form-label-sm mt-1">
                            {{ 'NODEFORMCOMPONENT.IMAGE' | translate }}
                        </label>
                    </div>
                    <div class="form-check">
                        <div class="col-sm-6 col-form-label col-form-label-sm">
                            <input class="form-check-input float-left" type="checkbox" formControlName="image"
                                id="image">
                            <label class="form-check-label" for="image"></label>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-0">
                    <div class="col-md-4 col-sm-12">
                        <label for="audio" class="col-form-label col-form-label-sm mt-1">
                            {{ 'NODEFORMCOMPONENT.AUDIO' | translate }}
                        </label>
                    </div>
                    <div class="form-check">
                        <div class="col-sm-6 col-form-label col-form-label-sm">
                            <input class="form-check-input float-left" type="checkbox" formControlName="audio"
                                id="audio">
                            <label class="form-check-label" for="audio"></label>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-0">
                    <div class="col-md-4 col-sm-12">
                        <label for="pdf" class="col-form-label col-form-label-sm mt-1">
                            {{ 'NODEFORMCOMPONENT.PDF' | translate }}
                        </label>
                    </div>
                    <div class="form-check">
                        <div class="col-sm-6 col-form-label col-form-label-sm">
                            <input class="form-check-input float-left" type="checkbox" formControlName="pdf" id="pdf">
                            <label class="form-check-label" for="pdf"></label>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-0">
                    <div class="col-md-4 col-sm-12">
                        <label for="textfile" class="col-form-label col-form-label-sm mt-1">
                            {{ 'NODEFORMCOMPONENT.TEXT' | translate }}
                        </label>
                    </div>
                    <div class="form-check">
                        <div class="col-sm-6 col-form-label col-form-label-sm">
                            <input class="form-check-input float-left" type="checkbox" formControlName="textfile"
                                id="textfile">
                            <label class="form-check-label" for="textfile"></label>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record && record.creationDateString">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm" for="creation">
                            {{ 'NODEFORMCOMPONENT.CREATION' | translate }}
                        </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">{{record.creationDateString}}</label>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record && record.lastEditionDateString">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm" for="edition">
                            {{ 'NODEFORMCOMPONENT.EDITION' | translate }}
                        </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">{{record.lastEditionDateString}}</label>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record && record.idCourseCreation">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm" for="id">
                            {{ 'NODEFORMCOMPONENT.CREATIONCOURSE' | translate }}
                        </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">{{record.idCourseCreation}}</label>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record && record.idTargetCreation">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm" for="id">
                            {{ 'NODEFORMCOMPONENT.CREATIONGRAPHO' | translate }}
                        </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">{{record.idTargetCreation}}</label>
                    </div>
                </div>
                <div class="form-group row mb-0 mt-1" *ngIf="record && record.idOriginal">
                    <div class="col-md-4 col-sm-12">
                        <label class="col-form-label col-form-label-sm" for="id">
                            {{ 'NODEFORMCOMPONENT.ID' | translate }}
                        </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <label class="col-form-label col-form-label-sm data">{{record.idOriginal}}</label>
                    </div>
                </div>
                <!-- ********* -->
                <!--<div class="text-right">
        <button mat-button type="submit" (click)="finished($event)">{{ 'NODEFORMCOMPONENT.SAVE' | translate }}</button>
    </div>  -->
            </form>
        </div>
    </div>
</div>
<!--</div>-->

<!--<div [hidden]="!submitted">
        <mat-card>
            <mat-card-header>

                <mat-card-title-group>
                    <mat-card-title>
                        {{node.value.name}}
                    </mat-card-title>
                    <mat-card-subtitle>
                        {{node.value.description}}
                    </mat-card-subtitle>
                </mat-card-title-group>
            </mat-card-header>

            <mat-divider></mat-divider>

            <mat-card-content>
                <mat-list>
                    <mat-list-item>
                        <mat-icon>stairs</mat-icon>
                        {{node.value.level}}
                    </mat-list-item>
                    <mat-list-item>
                        <mat-icon>access_time</mat-icon>
                        {{node.value.duration}}
                    </mat-list-item>
                </mat-list>

                <div class="center">
                    <app-file-uploader (newBackground)="newBackground($event)" (newImage)="newImage($event)" [image]="img" [activeColor]="'orangered'" [baseColor]="'lightgray'"></app-file-uploader>
                </div>
            </mat-card-content>

            <button mat-button (click)="editNode()">PREVIOUS</button>
            <button mat-button (click)="finished($event)">NEXT</button>
        </mat-card>
    <div>-->
