<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="background_themecolor">
		<!-- Version web -->
		<div *ngIf="!isMobile" class="row justify-content-center">
			<!-- imagen del proyecto -->
			<div class="col-sm-8 col-md-3">
				<div class="w-100 d-flex justify-content-center">
					<div *ngIf="cursoDatos.image && networkSpeed >= 1" class="text-center image-detail-course d-flex align-content-center"
						[style.background-image]="'url(' + cursoDatos.image + ')'">
					</div>
					<div *ngIf="!cursoDatos.image || networkSpeed < 1" class="text-center image-detail-course d-flex align-content-center"
						[style.background-image]="'url(../../../../assets/images/no-image.png)'">
					</div>
				</div>
				<div class="footer d-flex justify-content-center align-items-center mt-2" *ngIf="!loginService.esProfesor() && loginService.getProfile() !== 'CENTRO'">
					<div class="averageKnowledge mr-1">{{cursoDatos.averageKnowledge }}</div>
					<div class="progress half-width">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
							aria-valuemin="0" aria-valuemax="100" [style.backgroundColor]="cursoDatos.barColor"
							[style.width]="cursoDatos.progress+'%'"></div>
					</div>
					<div class="averageKnowledge ml-1">{{cursoDatos.progress+'%'}}</div>
				</div>
			</div>
			<!-- descripcion del proyecto -->
			<div class="col-sm-10 col-md-6 container-description">
				<div class="cont-icon-info">
					<nb-icon class="m-1" icon="info-outline" [options]="{ animation: { type: 'pulse' } }"
						(click)="openInformationCourse()">
					</nb-icon>
				</div>

				<h4 class="text-center">{{cursoDatos.title}} </h4>

				<div class="my-4 cont-description">
					<p class="m-0">{{cursoDatos.description}}</p>
				</div>

				<div class="cont-tag-curso gap-3">
					<button class="tag-style">{{'MODALINFORMATIONCOURSE.LANG' | translate}}: {{cursoDatos.languageData}}</button>
					<button class="tag-style">{{'MODALINFORMATIONCOURSE.COUNTRY' | translate}}: {{cursoDatos.countryData}}</button>
					<button class="tag-style">{{'MODALINFORMATIONCOURSE.LEVEL' | translate}}: {{countryLevelName}}</button>
				</div>
			</div>
			<!-- autor del proyecto -->
			<div class="col-sm-8 col-md-3">
				<h6 class="text-center">{{'MODALINFORMATIONCOURSE.AUTHOR' | translate}}</h6>
				<div class="w-100 d-flex justify-content-center my-2">
					<div class="text-center avatar-detail-course mb-2 d-flex align-content-center" *ngIf="networkSpeed >= 1"
						[ngStyle]="{'background-image': getUserAvatar()}">
					</div>
					<div class="text-center avatar-detail-course mb-2 d-flex align-content-center" *ngIf="networkSpeed < 1"
						[ngStyle]="{'background-image': 'url(../../../../assets/images/no-image.png)'}">
					</div>
				</div>
				<h6 class="text-center">{{cursoDatos.user.name}} {{cursoDatos.user.surname}}</h6>
				<!-- <div class="disabled-icon">
					<nb-icon icon="image-2" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
					<nb-icon icon="play-circle" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
					<nb-icon icon="linkedin" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
					<nb-icon icon="twitter" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
				</div> -->
			</div>
		</div>

		<!-- Version movil -->
		<nb-reveal-card *ngIf="isMobile" class="mb-0">
			<nb-card-front>
				<nb-card size="tiny">
					<nb-card-header class="p-2">
						<h5 class="text-center">{{cursoDatos.title}}</h5>
					</nb-card-header>
					<nb-card-body>
						<div class="row">
							<!-- descripcion del proyecto -->
							<div class="col-4">
								<div class="w-100 d-flex justify-content-center">
									<div *ngIf="cursoDatos.image && networkSpeed >= 1" class="text-center image-detail-course-mobile d-flex align-content-center"
										[style.background-image]="'url(' + cursoDatos.image + ')'">
									</div>
									<div *ngIf="!cursoDatos.image || networkSpeed < 1" class="text-center image-detail-course-mobile d-flex align-content-center"
										[style.background-image]="'url(../../../../assets/images/no-image.png)'">
									</div>
								</div>
								<div class="footer d-flex justify-content-center align-items-center mt-2" *ngIf="!loginService.esProfesor()">
									<div class="averageKnowledge mr-1">{{cursoDatos.averageKnowledge }}</div>
									<div class="progress half-width">
										<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
											aria-valuemin="0" aria-valuemax="100" [style.backgroundColor]="cursoDatos.barColor"
											[style.width]="cursoDatos.progress+'%'"></div>
									</div>
									<div class="averageKnowledge ml-1">{{cursoDatos.progress+'%'}}</div>
								</div>
							</div>
							<div class="col-8 d-flex justify-content-center align-items-center">
								<p class="m-0">{{cursoDatos.description}}</p>
							</div>
						</div>
					</nb-card-body>
				</nb-card>
			</nb-card-front>

			<nb-card-back>
				<nb-card size="tiny">
					<nb-card-body>
						<div class="row">
							<!-- autor del proyecto -->
							<div class="col-4">
								<p class="text-center mb-0"><strong>{{'MODALINFORMATIONCOURSE.AUTHOR' | translate}}</strong></p>
								<div class="w-100 d-flex justify-content-center my-2">
									<div class="text-center avatar-detail-course-mobile mb-2 d-flex align-content-center" *ngIf="networkSpeed >= 1"
										[ngStyle]="{'background-image': getUserAvatar()}">
									</div>
									<div class="text-center avatar-detail-course-mobile mb-2 d-flex align-content-center" *ngIf="networkSpeed < 1"
										[ngStyle]="{'background-image': 'url(../../../../assets/images/no-image.png)'}">
									</div>
								</div>
								<p class="text-center mb-0"><strong>{{cursoDatos.user.name}} {{cursoDatos.user.surname}}</strong></p>
							</div>
							<div class="col-8 d-flex justify-content-center align-items-center">
								<div class="d-flex flex-column gap-3">
									<p class="mb-0"><strong>{{'MODALINFORMATIONCOURSE.LANG' | translate}}:</strong> {{cursoDatos.languageData}}</p>
									<p class="mb-0"><strong>{{'MODALINFORMATIONCOURSE.COUNTRY' | translate}}:</strong> {{cursoDatos.countryData}}</p>
									<p class="mb-0"><strong>{{'MODALINFORMATIONCOURSE.LEVEL' | translate}}:s</strong> {{countryLevelName}}</p>
								</div>
							</div>
						</div>
					</nb-card-body>
				</nb-card>
			</nb-card-back>
		</nb-reveal-card>

		<!-- TARJETA IDEOGRAFOS -->
		<div class="flex flex-row align-items-center gap-4 mt-3">
			<div class="modal-title">
				<h2 class="m-0">{{ 'EDITARCURSO.GRAFOS' | translate }}</h2>
			</div>

			<div class="pointer d-flex">
				<mat-icon svgIcon="graph" (click)="clickGraphIcon()"></mat-icon>
			</div>

			<nb-actions>
				<nb-action class="p-0" *ngIf="graphView == 'inlineList' && !actividadesFlash" nbTooltip="{{ 'CURSOS.VERMOSAICO' | translate }}" nbTooltipPlacement="left"
					icon="grid-outline" (click)="graphView = 'gridList'">
				</nb-action>

				<nb-action class="p-0" *ngIf="graphView == 'gridList' && !actividadesFlash" icon="list-outline" (click)="getTreeData(0)"
					nbTooltip="{{ 'CURSOS.VERARBOL' | translate }}" nbTooltipPlacement="left">
				</nb-action>

				<nb-action class="p-0" *ngIf="loginService.esAutor() && user.idUser === curso?.user?.idUser && id!='nuevo'"
					icon="plus-circle-outline" (click)="nuevoMapa()">
				</nb-action>
			</nb-actions>

			<div *ngIf="actividadesFlash" class="d-flex justify-content-center align-items-center">
				<button nbButton hero [status]="markedItems.length > 0 ? 'primary' : 'basic'" (click)="iniciarFlash()">
					{{ "GLOBALS.START" | translate }}
				</button>
			</div>

			<div *ngIf="loginService.esProfesor() && infoGrupo && inGroup == 0" class="d-flex justify-content-center align-items-center">
				<button nbButton hero status="success" (click)="asignarProyecto()">
					{{ "ALERTGROUP.ADDPROJECT" | translate }}
				</button>
			</div>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-body>
		<!-- SI NO HAY GRAFOS -->
		 <div *ngIf="courseTargets.length == 0">
			<h5 class="text-center pointer">
				{{ 'CURSOS.NOTGRAPHS' | translate }}
			</h5>
		 </div>
		<!-- GRID VIEW -->
		<div *ngIf="graphView == 'gridList'" class="row background_white d-flex justify-content-center py-4 px-0 min-h-100">
			<div class="col-12">
				<app-loading [isVisible]="cargando"></app-loading>
				<div class="row">
					<div *ngFor="let mapa of courseTargets | sortBy:'asc':'ordinal'" (click)="verDetalleMapa(mapa)"
						[ngClass]="graphView == 'gridList' ? 'col-xxl-1 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6' : ''">
						<nb-card size="small" class="pointer"
						[ngClass]="{'project-info-1': curso.project_type == 1, 'project-info-2': curso.project_type == 2, 'project-info-3': curso.project_type == 3, 'project-info-4': curso.project_type == 4, 'project-info-5': curso.project_type == 5}">
							<nb-card-header class="pb-2 pt-0 pl-0 pr-0 border-bottom-0">
								<div class="img-container" ngbTooltip="{{mapa.target.description}}" placement="top">
									<img [src]="mapa.target.backgroundImage | imagen:'mapas'" (error)="updateUrl($event)" class="img-fluid" *ngIf="networkSpeed >= 1">
									<img [src]="'../../assets/images/no-image.png'" class="img-fluid" *ngIf="networkSpeed < 1">
								</div>
							</nb-card-header>
							<nb-card-body class="p-2">
								<p *ngIf="!loginService.esProfesor()" class="courseTittle">{{ mapa.target.tittle }}</p>
								<p *ngIf="loginService.esProfesor()" class="courseTittle-teacher">{{ mapa.target.tittle }}</p>
							</nb-card-body>
							<nb-card-footer *ngIf="!loginService.esProfesor() && loginService.getProfile() !== 'CENTRO'" class="border-top-0">
								<div class="footer d-flex justify-content-between align-items-center mr-1 ml-1" *ngIf="!actividadesFlash">
									<div class="averageKnowledge mr-1">{{mapa.averageKnowledge }}</div>
									<div class="progress half-width">
										<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
											aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.backgroundColor]="mapa.barColor"
											[style.width]="mapa.progress+'%'"></div>
									</div>
									<div class="averageKnowledge ml-1">{{mapa.progress+'%'}}</div>
								</div>
								<div *ngIf="actividadesFlash" class="d-flex justify-content-center align-items-center">
									<button *ngIf="!mapa.isMarked" nbButton hero status="success" size="small">
										{{ "GLOBALS.CHECK" | translate }}
									</button>
									<button *ngIf="mapa.isMarked" nbButton hero status="danger" size="small">
										{{ "GLOBALS.UNCHECK" | translate }}
									</button>
								</div>
							</nb-card-footer>
						</nb-card>
					</div>
				</div>
			</div>
		</div>
		<!-- LIST VIEW -->
		<div *ngIf="graphView !== 'gridList'" class="row min-h-100">

			<div *ngIf="isMobile">
				<div class="d-flex align-items-center style-icon-menu" style="
					position: absolute;
					left: 0;
					top: 0;
					z-index: 999;">
					<nb-action icon="menu-outline" nbTooltip="{{ 'INFORMES.EXPANDIRVISTA' | translate }}"
						nbTooltipPlacement="top" (click)="toggleMenu()">
					</nb-action>
				</div>

				<div class="left-files" [ngClass]="{'show-files': isMenuOpen}">
					<div class="flex flex-column">
						<div class="closeModal">
							<nb-icon class="m-1 pointer" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
								(click)="toggleMenu()" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
							</nb-icon>
						</div>
						<h5 style="margin-right: 2rem;">
							{{ 'INFORMES.EXPANDIRVISTA' | translate }}
							<nb-icon icon="info-outline" class="pointer" nbTooltip="{{ 'CURSOS.INFOGRAPHIC' | translate }}"
								nbTooltipPlacement="top" (click)="open(dialog)" *ngIf="loginService.esEstudiante()">
							</nb-icon>
						</h5>
						<app-mat-tree-cursos [treeGraphs]="treeGraphs" [graphView]="graphView" (clickElement)="clickElement($event)"></app-mat-tree-cursos>
					</div>
				</div>
			</div>
			<div *ngIf="!isMobile;" class="col-12 col-xl-4 py-4 pl-4 pr-0 container-tree background_white">
				<div class="d-flex align-items-center pr-5">
					<h5>
						{{ 'INFORMES.EXPANDIRVISTA' | translate }}
						<nb-icon icon="info-outline" class="pointer" nbTooltip="{{ 'CURSOS.INFOGRAPHIC' | translate }}"
							nbTooltipPlacement="top" (click)="open(dialog)" *ngIf="loginService.esEstudiante()">
						</nb-icon>
					</h5>
				</div>
				<app-mat-tree-cursos [treeGraphs]="treeGraphs" (clickElement)="clickElement($event)"></app-mat-tree-cursos>
			</div>
			<div class="col py-4 pl-xl-4 pr-xl-0 container-tree background_white">
				<!-- BOTONES PARA IR CAMBIANDO ENTRE QUIZZES MULTIPLES COMO PROFESOR -->
				<div id="botones-multiples" *ngIf="!loginService.esEstudiante() && (quiz && quiz.idMultiplexQuiz === 1)"
						class="d-flex justify-content-center align-items-center flex-wrap">
					<button *ngFor="let hijo of listQM; let i = index"
									[ngClass]="{'boton-qM': true, 'boton-qM-selected': i === selectedButtonIndex}"
									[class.flex-grow-1]="true"
									(click)="selectButton(i, hijo.quizMultiple.idQuizMultiple)">
						{{ i + 1 }}
					</button>
				</div>
				<!-- END BOTONES PARA IR CAMBIANDO ENTRE QUIZZES MULTIPLES COMO PROFESOR -->
				<div class="w-100 height-max">
					<div class="d-flex align-items-center justify-content-center pt-5" *ngIf="isLoading">
						<!-- <mat-spinner [diameter]="50"></mat-spinner> -->
					</div>

					<!-- ROL EDITOR -->
					<div *ngIf="loginService.esAutor()">
						<!-- VISUALIZAR NODO -->
							<div *ngIf="node && !isLoading">
							</div>
						<!--FIN VISUALIZAR NODO -->
						<!-- VISUALIZAR QUIZ -->
							<div *ngIf="quiz && !isLoading">
								<div *ngIf="quiz && quiz.idMultiplexQuiz === 0" class="quiz-container" id="quiz">
									<div class="body-container p-3 pr-xl-4 hide-scrollbars" id="container">
										<!--
										<app-quiz-edit
										[courseId]="idSelectedCourse"
										[graphId]="idGraph"
										[quiz]="quiz"
										[elements]="elements">
										</app-quiz-edit>
										-->
									</div>
								</div>
							</div>
						<!--FIN VISUALIZAR QUIZ -->
					</div>

					<!-- ROL ESTUDIANTE Y PROFESOR -->
					<div *ngIf="!loginService.esAutor()">
						<!-- VISUALIZAR NODO -->
						<div (mousemove)="showFiles(true)" (click)="showFiles(true)" *ngIf="node && !isLoading">
							<app-node-detail-files [showInContainer]="true" [showFilesContainer]="isShowFiles" [node]="node"
								[onSaveClicked$]="saveClicked$" [canEdit]="false"
								(onHoverElements)="showFiles($event)"></app-node-detail-files>
						</div>
						<!--FIN VISUALIZAR NODO -->
						<!-- VISUALIZAR QUIZ -->
						<div *ngIf="quiz && !isLoading">
							<div *ngIf="quiz && quiz.idMultiplexQuiz === 0" class="quiz-container" id="quiz">
								<div class="body-container p-3 pr-xl-4 hide-scrollbars" id="container">
									<app-quiz-play-body [courseId]="idSelectedCourse" [graphId]="idGraph" [quiz]="quiz"
										[elements]="elements" [answered]="answered" [modeSocket]="false" [answeredBySocket]="false"
										(answeredChange)="onAnswered()">
									</app-quiz-play-body>
								</div>
							</div>
							<div *ngIf="quiz && quiz.idMultiplexQuiz === 1" class="quiz-container" id="quiz">
								<div class="body-container p-3 pr-xl-4 hide-scrollbars" id="container">
									<app-quiz-play-multiple-body [courseId]="idSelectedCourse" [graphId]="idGraph" [quiz]="quiz"
									[elements]="elements" [answered]="answered" [modeSocket]="false" [answeredBySocket]="false"
									(answeredChange)="onAnswered()">
									</app-quiz-play-multiple-body>
								</div>
							</div>
						</div>
						<!-- FIN VISUALIZAR QUIZ -->
					</div>

				</div>
			</div>

			<ng-template #dialog let-data let-ref="dialogRef">
				<nb-card class="position-relative">
					<nb-card-body>
						<div class="d-flex">
							<img src="assets/images/leyenda-ideografo.png" class="img-info">
						</div>
					</nb-card-body>
					<div class="closeModal">
						<nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left"
						 	[options]="{ animation: { type: 'zoom' } }" (click)="ref.close()">
						</nb-icon>
					</div>
				</nb-card>
			</ng-template>
		</div>
		<!-- END LIST VIEW -->
	</nb-card-body>
</nb-card>
