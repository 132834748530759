import { environment } from 'src/environments/environment';
import { Component, HostListener, OnInit } from '@angular/core';

const BGURL = '../../../../assets/images/'

@Component({
    selector: 'app-auth-layout',
    templateUrl: './auth-layout.component.html',
    styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
    bgAuth:string
		isMobile: boolean = false;

    constructor() {}

    ngOnInit() {
        this.bgAuth = `url(${BGURL}${environment.bgLogin})`;
				this.isMobile = window.innerWidth < 768;
    }

	@HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    if (window.innerWidth <= 768) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
