import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
	providedIn: "root",
})
export class PowerService {
	public powerActive: BehaviorSubject<Array<number>> = new BehaviorSubject([0]);
	public checksVisibility = new Subject();
	public actualLevel: number = 0;
	tmpObject: any = {};

	/**
	 *
	 */
	constructor() {
		let tmpObject: any = {};
		tmpObject.power0 = true;
		this.setPower(tmpObject);
	}

	public getPowerActive(): {} {
		return this.powerActive.asObservable();
	}
	public emitPowerActive(numberPower) {
		this.powerActive.next(numberPower);
		if (numberPower.length == 7) {
			this.actualLevel = numberPower[3];
		} else {
			this.actualLevel = numberPower[0];
		}
	}

	public emitChecksVisibility(visible) {
		this.checksVisibility.next(visible);
	}

	public getPower(): {} {
		return this.tmpObject;
	}

	public setPower(tmpObject: {}) {
		this.tmpObject = tmpObject;
	}

	public convertNumberArrayToPowerObject(powers: Array<number>) {
		let tmpObject: any = {};

		powers.forEach((e) => {
			switch (e) {
				case 3:
					tmpObject.power3 = true;
					this.setPower(tmpObject);
					break;
				case 2:
					tmpObject.power2 = true;
					this.setPower(tmpObject);
					break;
				case 1:
					tmpObject.power1 = true;
					this.setPower(tmpObject);
					break;
				case 0:
					tmpObject.power0 = true;
					this.setPower(tmpObject);
					break;
				case -1:
					tmpObject.powerNegative1 = true;
					this.setPower(tmpObject);
					break;
				case -2:
					tmpObject.powerNegative2 = true;
					this.setPower(tmpObject);
					break;
				case -3:
					tmpObject.powerNegative3 = true;
					this.setPower(tmpObject);
					break;
			}
		});
		return tmpObject;
	}
}

