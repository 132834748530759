<div class="quiz-container" id="quiz" *ngIf="quiz">
    <app-recordar-quiz-play-header [quiz]="quiz" [idCurso]="courseId" [idMapa]="graphId" (close)="onClose()"></app-recordar-quiz-play-header>

    <div class="body-container py-3 w-100 scroll-container" >
        <app-recordar-quiz-play-body [courseId]="courseId" [graphId]="graphId" [quiz]="quiz" [elements]="elements" (close)="onClose()"
            [respuestaEstudiante]="respuestaEstudiante"
            [answered]="true"
						[respuestaEstudiante]="respuestaEstudiante"
            (answeredChange)="onAnswered()"
            (colorChange)="onColorChanged($event)"></app-recordar-quiz-play-body>
    </div>
</div>
