import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStorage } from '../../utils';

@Injectable({
	providedIn: "root",
})
export class SosService {
	constructor(private http: HttpClient, private localStorage: LocalStorage) {}

	private _currentHelpPetition: number;

	public get currentHelpPetition(): number {
		return this._currentHelpPetition;
	}
	public set currentHelpPetition(value: number) {
		this._currentHelpPetition = value;
	}

	/**
	 * Función que crea un registro en la tabla sos para este nodo.
	 * @param idGroup ID del grupo
	 * @returns
	 */
	createHelpPetition(idCourse, idTarget, idNode): Observable<any> {
		const url = "node/createupdatesos";
		const body = {
			idCourse: idCourse,
			idTarget: idTarget,
			idNode: idNode,
		};
		return this.http.post<any>(url, body);
	}

	updateHelpPetition(data): Observable<any> {
		const url = "node/createupdatesos";
		const body = data;
		return this.http.post<any>(url, body);
	}

	getHelpPetitions() {
		const url = `node/getsoslist`;
		return this.http.get<any>(url);
	}

	getHelpPetitionById(id: number) {
		return this.http.get(`node/getsosbyid/${id}`);
	}

	userHasPendingPetitions() {
		const url = `user/isactivehelprequest`;
		return this.http.get<any>(url);
	}

	public setRoomName(name: string): void {
		this.localStorage.setItem("roomName", name);
	}

	public getRoomName(): string {
		return this.localStorage.getItem("roomName") as string;
	}

	deleteHelpPetition(id) {
		const url = `node/deletesosbyid/${id}`;
		return this.http.delete<any>(url);
	}

	deleteHelpPetitionByIdNode(idNode) {
		const url = `node/deletesosbyidnode/${idNode}`;
		return this.http.delete<any>(url);
	}
}
