<div class="sticky-top sticky-top-header">
    <div class="modal-header tittle" align="center">
        <div class="col-sm-12 align-self-center text-center">
            <span
                  class="textoFontSize1 font-weight-bold span-text-center">{{ 'PATTERNS.SELECTPATTERN' | translate }}</span>
        </div>
        <div class="" style="position: absolute;right: 10px;top: 0px;z-index: 1;">
            <button type="button" class="close pointer" data-dismiss="modal"
                    (click)="closeModal('')" style="color: white;">
                <span class="textoFontSize18 font-weight-bold span-text-center" aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="options">
        <div class="row container-icons">

            <mat-icon class="icon-head pointer" color="rgb(29,73,153)" data-toggle="tooltip"
                      data-placement="bottom" placement="bottom"
                      ngbTooltip="{{ 'PATTERNS.FAVORITOS' | translate }}" svgIcon="{{!isShowFavourites ? 'favorite' : 'favorito'}}" (click)="getFavourites()"></mat-icon>

            <mat-icon (click)="createPatterns()" ngbTooltip="{{ 'PATTERNS.CREATETEMPLATE' | translate }}" class=" pointer" svgIcon="template_add"></mat-icon>

        </div>
    </div>
</div>

<div class="modal-body pt-0 fondoEditarCurso">


    <div class="form-group row mt-0">

        <div class="col-md-4 col-12 lateral">
            <div class="sticky-top">

                <form name="form" #dataForm="ngForm" [formGroup]="form" (ngSubmit)="searchPatterns()"
                      class="form-horizontal">
                    <div class="form-group mb-3 mt-3 check-forms">
                        <div class="form-check mx-2 my-2">
                            <input class="form-check-input" type="checkbox" id="options" formControlName="options">
                            <label class="form-check-label" for="options">
                                Options
                            </label>
                        </div>
                        <div class="form-check mx-2 my-2">
                            <input class="form-check-input" type="checkbox" id="writable" formControlName="writable">
                            <label class="form-check-label" for="writable">
                                Writable
                            </label>
                        </div>
                    </div>
                    <div class="form-group mb-3 check-forms">
                        <div class="form-check mx-2 my-2">
                            <input class="form-check-input" type="checkbox" id="manual" formControlName="manual">
                            <label class="form-check-label" for="manual">
                                Manual correct
                            </label>
                        </div>
                        <div class="form-check mx-2 my-2">
                            <input class="form-check-input" type="checkbox" id="compu" formControlName="compu">
                            <label class="form-check-label" for="compu">
                                Compucorrect
                            </label>
                        </div>
                    </div>
                    <div class="form-group mb-3 check-forms">
                        <div class="form-check mx-2 my-2">
                            <input class="form-check-input" type="checkbox" id="noMulti" formControlName="noMulti">
                            <label class="form-check-label" for="noMulti">
                                No Multiplexed
                            </label>
                        </div>
                        <div class="form-check mx-2 my-2">
                            <input class="form-check-input" type="checkbox" id="multi" formControlName="multi">
                            <label class="form-check-label" for="multi">
                                Multiplexed
                            </label>
                        </div>
                    </div>
                    <!-- QUESTIONS -->
                    <div class="form-group mb-3 mt-3 check-forms">
                        <div class="row justify-content-center pt-3 pb-3">

                            <label class="col-12 col-sm-12 col-md-12 col-lg-12 bold text-center mb-2">#QUESTIONS</label>
                            <!-------------------- ICONOS 1 ------------------------------>
                            <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div class="mx-auto text-center">
                                    <mat-icon class="icon-orange">videocam</mat-icon>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <input class="form-control text-center" aria-hidden="false" type="number"
                                               formControlName="qVideo">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div class="mx-auto text-center">
                                    <mat-icon class="icon-orange" aria-hidden="false"
                                              aria-label="Example home icon">image</mat-icon>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <input class="form-control text-center" type="number" formControlName="qPicture">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div class="mx-auto text-center">
                                    <mat-icon class="icon-orange" aria-hidden="false"
                                              aria-label="Example home icon">volume_up</mat-icon>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <input class="form-control text-center" type="number" formControlName="qAudio">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div class="mx-auto text-center">
                                    <mat-icon class="icon-orange" aria-hidden="false"
                                              aria-label="Example home icon">picture_as_pdf</mat-icon>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                        <input class="form-control text-center" type="number" formControlName="qPdf">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div class="mx-auto text-center">
                                    <mat-icon class="icon-orange" aria-hidden="false"
                                              aria-label="Example home icon">subject</mat-icon>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                        <input class="form-control text-center" type="number" formControlName="qText">
                                    </div>
                                </div>

                            </div>
                            <!--------------------------------------------------------->
                        </div>
                        <div class="row justify-content-center pb-3">
                            <label class="col-12 col-sm-12 col-md-12 col-lg-12 bold text-center mb-2">#OPTIONS</label>
                            <!-------------------- ICONOS 2----------------------------->
                            <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div class="mx-auto text-center">
                                    <mat-icon class="icon-green" aria-hidden="false" aria-label="Example home icon">
                                        videocam</mat-icon>
                                </div>
                                <div class="row text-center">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <input class="form-control text-center" type="number" formControlName="oVideo">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div class="mx-auto text-center">
                                    <mat-icon class="icon-green" aria-hidden="false" aria-label="Example home icon">
                                        image</mat-icon>
                                </div>
                                <div class="row text-center">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <input class="form-control text-center" type="number" formControlName="oPicture">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div class="mx-auto text-center">
                                    <mat-icon class="icon-green" aria-hidden="false" aria-label="Example home icon">
                                        volume_up</mat-icon>
                                </div>
                                <div class="row text-center">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <input class="form-control text-center" type="number" formControlName="oAudio">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div class="mx-auto text-center">
                                    <mat-icon class="icon-green" aria-hidden="false" aria-label="Example home icon">
                                        picture_as_pdf</mat-icon>
                                </div>
                                <div class="row text-center">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12"></div>
                                    <input class="form-control text-center" type="number" formControlName="oPdf">
                                </div>
                            </div>

                            <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <div class="mx-auto text-center">
                                    <mat-icon class="icon-green" aria-hidden="false" aria-label="Example home icon">
                                        subject</mat-icon>
                                </div>
                                <div class="row text-center">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <input class="form-control text-center" type="number" formControlName="oText">
                                    </div>
                                </div>
                            </div>
                            <!--------------------------------------------------------->
                        </div>
                    </div>
                    <!-- LABEL Y BUSCAR -->
                    <div class="form-group mb-3 mt-3">
                        <div class="row">
                            <div class="input-group" style="margin: 0 15px;">
                                <input type="text" class="form-control" placeholder="Buscar nombre de plantilla" formControlName="label">
                                <div class="input-group-append">
                                    <button class="btn btn-primary" (click)="searchPatterns()" type="button" style="background-color: rgb(29, 73, 153)">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <!-- <input class="form-control col-11" type="text" formControlName="label">
                                <mat-icon class="col-1" color="primary" (click)="searchPatterns()" aria-hidden="false">search</mat-icon> -->
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-md-8 col-12 mt-3">

            <app-loading [isVisible]="cargando"></app-loading>

            <div *ngIf="!cargando && templates.length !== 0">

                <div class="row" style="margin-right: 0px;">

                    <div *ngFor="let template of templates | sortBy:'asc':'date'; index as i"
                         class="col-lg-4 col-md-6 col-sm-12 text-center" title="{{template.templateTittle}}">

                        <div *ngIf="template.idTemplate !== 0" class="card form-group pointer" (click)="closeModal(template)">
                            <img style="height: 135px;" src="../../../../assets/images/plantilla.png">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12"><span class="card-title textoFontSize08 colorTextoActivado font-weight-bold"> {{template.templateTittle || "Sin Nombre" }}</span></div>
                                    <div style="position: absolute;top: 0; right: -4px;">
                                        <mat-icon class="icon-head pointer" color="rgb(29,73,153)" data-toggle="tooltip"
                                                  data-placement="bottom" placement="bottom"
                                                  ngbTooltip="{{ 'PATTERNS.FAVORITOS' | translate }}" svgIcon="{{template.isFavorite ? 'favorito' : 'favorite'}}" (click)="setFavourite($event, template.idTemplate,i)"></mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style=" height: 210px;" *ngIf="template.idTemplate === 0" class="card form-group">
                            <div style="height: 155px;">
                                <img id="new" src="https://www.flaticon.com/svg/static/icons/svg/61/61183.svg">
                            </div>
                            <div class="card-body" style="height: 55px;">
                                <span class="card-title textoFontSize08 colorTextoActivado font-weight-bold">
                                    {{template.templateTittle}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="input-group pagination">
                <ngb-pagination [collectionSize]="templates.length" #numPages [maxSize]="10" [pageSize]="itemsPerPage" [(page)]="currentPage" (pageChange)="onPageChange(currentPage)"></ngb-pagination>

            </div>-->
            </div>

            <div *ngIf="!cargando && templates.length === 0"
                 class="alert alert-warning text-center mt-5 animated fadeIn faster">
                <h4 class="alert-heading">{{ 'PATTERNS.NOHAYREGISTROS' | translate }}</h4>
                <p>
                    <i class="fa fa-warning fa-2x"></i>
                </p>
            </div>



        </div>

    </div>
</div>
