import { Action } from '@ngrx/store';
import { loggedInUser } from "../models/user.model"

export enum ActionTypes {
  register = '[USER ACTION] register user',
}

export class register implements Action {
	readonly type = ActionTypes.register;
	constructor(public payload: loggedInUser) { }
}


export type ActionsUnion = register;
