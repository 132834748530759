<nb-card class="mb-0 no-edge-card">
	<nb-card-header class="border-bottom-0">
		<div class="col-10 ">
			<h4 class="p-2">
				{{'MODALINFORMATIONCOURSE.ID' | translate}} {{curso.idCourse}}
			</h4>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left"
			[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<app-loading [isVisible]="cargando"></app-loading>

		<nb-card class="no-edge-card mb-0">
			<div *ngIf="editarInfo">

				<div class="d-flex">
					<form [formGroup]="formulario" #thisForm="ngForm" class="d-flex flex-column justify-content-center align-content-center gap-4">
						<div class="d-flex flex-wrap justify-content-center gap-4">
							<div class="d-flex flex-column">
								<label>{{'MODALINFORMATIONCOURSE.TITLE' | translate}}</label>
								<input nbInput fullWidth fieldSize="small" value="{{curso.courseTittle}}">
							</div>

							<div class="d-flex flex-column">
								<label>{{'MODALINFORMATIONCOURSE.SUBJECT' | translate}}</label>
								<nb-select formControlName="subject" size="small">
									<nb-option *ngFor="let item of subjectsList" [value]="item.idSubject">{{item.subject}}</nb-option>
								</nb-select>
							</div>
						</div>

						<div class="d-flex flex-wrap justify-content-center gap-4">
							<div class="d-flex flex-column">
								<label>{{'MODALINFORMATIONCOURSE.AUTHOR' | translate}}</label>
								<input nbInput fullWidth fieldSize="small" disabled value="{{user.firstName + ' '+user.surname}}">
							</div>

							<div class="d-flex flex-column">
								<label>{{'MODALINFORMATIONCOURSE.COUNTRY' | translate}}</label>
								<nb-select formControlName="country" size="small">
									<nb-option *ngFor="let item of countriesList" [value]="item.idCountry">{{item.country}}</nb-option>
								</nb-select>
							</div>
						</div>

						<div class="d-flex flex-wrap justify-content-center gap-4">
							<div class="d-flex flex-column">
								<label>{{'MODALINFORMATIONCOURSE.OWNER' | translate}}</label>
								<input nbInput fullWidth fieldSize="small" disabled value="{{user.firstName + ' '+user.surname}}">
							</div>

							<div class="d-flex flex-column">
								<label>{{'MODALINFORMATIONCOURSE.ACTIVITIESSIZE' | translate}}</label>
								<input nbInput fullWidth fieldSize="small" formControlName="deckSize" placeholder="min 5, max 100" max="100" min="5">
							</div>
						</div>

						<div class="row d-flex justify-content-center align-items-center gap-4 mt-4">
							<button nbButton class="btn-red" (click)="borrarTodo()">{{'MODALINFORMATIONCOURSE.DELETE' | translate}}</button>
							<button nbButton class="btn-themecolor" (click)="guardarCambios()">{{'MODALINFORMATIONCOURSE.OK' | translate}}</button>
						</div>
					</form>
				</div>
			</div>

			<div *ngIf="!editarInfo">

				<div class="row d-flex justify-content-center align-content-center p-4">

					<div class="w-100 mb-2">
						<span><b>{{'MODALINFORMATIONCOURSE.TITLE' | translate}} :</b></span> <span
							class="ml-2 border-0">{{curso.courseTittle}}</span>
					</div>

					<div class="w-100 mb-2">
						<span><b>{{'MODALINFORMATIONCOURSE.SUBJECT' | translate}} :</b></span> <span
							class="ml-2 border-0">{{curso.subject.idSubject}}</span>
					</div>

					<div class="w-100 mb-2">
						<span><b>{{'MODALINFORMATIONCOURSE.AGE' | translate}} :</b> </span><span
							class="ml-2 border-0">{{curso.normalAge}}</span>
					</div>

					<div class="w-100 mb-2">
						<span><b>{{'MODALINFORMATIONCOURSE.AUTHOR' | translate}} :</b> </span> <span
							class="ml-2 border-0 ">{{curso.user.firstName}} {{curso.user.surname}}</span>
					</div>

					<div class="w-100 mb-2">
						<span><b>{{'MODALINFORMATIONCOURSE.COUNTRY' | translate}} :</b> </span> <span
							class="ml-2 border-0">{{curso.idCountry}}</span>
					</div>

					<div class="w-100 mb-2">
						<span><b>{{'MODALINFORMATIONCOURSE.PUBLISHED' | translate}} :</b> </span> <span
							class="ml-2 border-0">{{curso.published | date}}</span>
					</div>

					<div class="w-100 mb-2">
						<span><b>{{'MODALINFORMATIONCOURSE.OPINIONS' | translate}} :</b></span>
					</div>
				</div>
			</div>
		</nb-card>
	</nb-card-body>

</nb-card>
