import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { ExporarContenidoService } from 'src/app/core/services/explorar-contenido/exporar-contenido.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { OpenQuizPreviewComponent } from '../../open-quiz-preview/open-quiz-preview.component';

@Component({
  selector: 'app-quizes-ideografo',
  templateUrl: './quizes-ideografo.component.html',
  styleUrls: ['./quizes-ideografo.component.scss']
})

export class QuizesIdeografoComponent implements OnInit {
  @Input() ideoGrafoSelected: any = null;
  @Input() cursoSelected: any = null;
  quizes: any;
  cargando: boolean;
  resultsLength: number = 0
  isLoading: boolean;
  idCurso: any;
  idMapa: any;
  constructor(
    private quizService: QuizzesService,
    private explorarContService: ExporarContenidoService, private modalService: NgbModal) { }

  ngOnInit() {
    this.getQuizesIdeoGrafo();
  }
  getQuizesIdeoGrafo() {

    this.explorarContService.ideoGrafoActive.subscribe((res:any) => {
        let idCourse = res.curso;
        let idGrafo = res.ideografo;
        this.idMapa = idGrafo;
        this.idCurso = idCourse;
        this.explorarContService.getListadoQuizesIdeografos(idCourse, idGrafo).subscribe( res => {
        this.cargando = true;
          setTimeout(() => {
              this.cargando = false;
              this.quizes = res.data
              this.resultsLength = this.quizes.length
          }, 500);
        })
    })


  }

  abrirQuizPreview(quiz){

    if (quiz.idMultiplexQuiz) {

      this.quizService.getQuizMultipleCanvasQuizMultiple(quiz.idQuiz, this.idCurso, this.idMapa).pipe(finalize(() => this.isLoading = false)).subscribe(res => {
        const modalRef = this.modalService.open(OpenQuizPreviewComponent, {scrollable: true, windowClass: MODAL_DIALOG_TYPES.W100, backdrop: 'static' })
        modalRef.componentInstance.quiz = { ...res.quiz, user: quiz.user, idOriginal: quiz.idOriginal, id: quiz.idQuiz, originalX: quiz.originalX, originalY: quiz.originalY, size: quiz.size, sizeQuiz: quiz.sizeQuiz, x: quiz.x, y: quiz.y };;
        modalRef.componentInstance.elements = res.elements;
        modalRef.componentInstance.courseId = this.idCurso;
        modalRef.componentInstance.graphId = this.idMapa;
      });

    } else {
      this.quizService.getQuizSimpleCanvasQuizSimple(quiz.idQuiz, this.idCurso, this.idMapa).pipe(finalize(() => this.isLoading = false)).subscribe(res => {
        const modalRef = this.modalService.open(OpenQuizPreviewComponent, {scrollable: true, windowClass: MODAL_DIALOG_TYPES.W100, backdrop: 'static' })
        modalRef.componentInstance.quiz = { ...res.quiz, user: quiz.user, idOriginal: quiz.idOriginal, id: quiz.idQuiz, originalX: quiz.originalX, originalY: quiz.originalY, size: quiz.size, sizeQuiz: quiz.sizeQuiz, x: quiz.x, y: quiz.y };;
        modalRef.componentInstance.elements = res.elements;
        modalRef.componentInstance.courseId = this.idCurso;
        modalRef.componentInstance.graphId = this.idMapa;
      });
    }


  }


}
