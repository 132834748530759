import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-eliminar-curso',
  templateUrl: './modal-eliminar-curso.component.html',
  styleUrls: ['./modal-eliminar-curso.component.scss']
})
export class ModalEliminarCursoComponent implements OnInit {
  mensaje: string;

  constructor(
    public translateService: TranslateService,
              private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }


  aceptar() {
    this.activeModal.close(true);
  }

  cancelar() {
    this.activeModal.close(false);
  }

  closeModal(sendData?: any) {
    this.activeModal.close(false);
  }

}
