import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SigmaNode } from 'src/app/core/models/graph/sigmaNode.model';
import { QuizTypes } from 'src/app/core/models/quizzes/quiz-types.enum';
import { LoginService } from 'src/app/core/services/login';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { SigmaCanvasService } from '../../../sigma-canvas/sigma-canvas.service';
import { QuizUtils } from '../../../sigma-canvas/utils/quiz.utils';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

interface RepeTypes {
    pattern: number,
    descripcion: string
};

@Component({
  selector: 'app-quiz-edit-multiple-header',
  templateUrl: './quiz-edit-multiple-header.component.html',
  styleUrls: ['./quiz-edit-multiple-header.component.scss']
})
export class QuizEditMultipleHeaderComponent implements OnInit {
  @Input() quiz;
  @Input() elements: any[];
  @Input() courseId: number;
  @Input() graphId: number;
  @Input() fromLista: boolean = false; //si vienes del modo lista
	@Output() closeInModeList = new EventEmitter<boolean>();
  @Output() publishQuiz: EventEmitter<boolean> = new EventEmitter<boolean>();
  public form: UntypedFormGroup;
  public type: QuizTypes;

  repetitionList: RepeTypes[] = [
    {pattern: 1, descripcion: 'Muy fácil de recordar'},
    {pattern: 2, descripcion: 'Fácil de recordar'},
    {pattern: 3, descripcion: 'Dificultad intermedia de recuerdo'},
    {pattern: 4, descripcion: 'Difícil de recordar'},
    {pattern: 5, descripcion: 'Muy difícil de recordar'}
  ];

  constructor(
      public activeModal: NgbActiveModal,
      private quizService: QuizzesService,
      public loginService: LoginService,
      private translateService: TranslateService,
      private sigmaCanvasService: SigmaCanvasService,
      private fb: UntypedFormBuilder,
      private quizUtils: QuizUtils,
			private toaster: ToasterService
    ) {
      this.form = this.fb.group(
        {
            published : [false],
            certificadoQuiz: [false],
            pattern : [],
        }
      );
    }

  ngOnInit() {
    //this.getRepetitionTypes();
    this.form.get('pattern').setValue(this.quiz.defaultQuizPattern === 0 ? 3 : this.quiz.defaultQuizPattern);
    this.certificarControl.patchValue(this.quiz.certifiedQuiz === 1 ? true : false);
    this.publishedControl.patchValue(this.quiz.published ? true : false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.quiz && changes.quiz.currentValue) {
        this.type = changes.quiz.currentValue.quizType;
        this.publishedControl.patchValue(changes.quiz.currentValue.published ? true : false)
    }
  }

  showInfo(){}

  onCloseModal(sendData?: any):void{
    //en el modo lista no cerramos modal, solo lo ocultamos
    if(!this.fromLista){
      this.activeModal.close(false);
      this.quizService.currentQuiz.next(this.quiz);
    } else {
        this.closeInModeList.emit(true);
    }
  }

  deleteMultipleQuiz():void{
    if (!this.loginService.esAutor()) {
      return;
    }
    let quiz:SigmaNode = this.quiz as SigmaNode
    quiz.nodeType = 'Quiz'

    this.sigmaCanvasService.deleteSigmaNode(quiz).subscribe(
        (res: any) => {
            this.toaster.success(this.translateService.instant('QUIZZES.DELETEOK'));
            //en el modo lista no cerramos modal, solo lo ocultamos
            if(!this.fromLista){
                this.activeModal.close(false);
            } else {
                this.closeInModeList.emit(true);
            }
        },
        err => {
            this.toaster.error(this.translateService.instant('QUIZZES.DELETEKO'));
            //en el modo lista no cerramos modal, solo lo ocultamos
            if(!this.fromLista){
                this.activeModal.close(false);
            } else {
                this.closeInModeList.emit(true);
            }
        }
    );
  }

  get publishedControl(): UntypedFormControl {
    if (this.form) {
        return (this.form.get('published') as UntypedFormControl);
    } else {
        return null;
    }
  }

  publish($ev){
    $ev.preventDefault()
    $ev.stopImmediatePropagation()
    this.publishQuiz.emit(!this.form.get('published').value);
  }

  get certificarControl(): UntypedFormControl {
    if (this.form) {
        return (this.form.get('certificadoQuiz') as UntypedFormControl);
    } else {
        return null;
    }
  }

  certifiedQuiz($ev){
      $ev.preventDefault()
      $ev.stopImmediatePropagation()
      this.quizUtils.certificarQuiz().subscribe(value => {
          this.form.patchValue({certificadoQuiz: value })
      }, err => {
          this.form.patchValue({certificadoQuiz: false })
      })
  }

  get repetitionTypes(): UntypedFormControl {
    if (this.form) {
        return (this.form.get('pattern') as UntypedFormControl);
    } else {
        return null;
    }
  }

  getRepetitionTypes(){
    this.quizService.getRepetitionTypes().subscribe(res => {
        this.repetitionList = res.data;
        // this.repetitionTypes.patchValue(this.quiz.idQPattern);
    },
    (error) => {
        console.error(error);
    })
  }

  updateRepetition(){
    let formularioValue = this.form.value;
    this.quizService.updateRepetitionTypes(this.quiz.idQuiz, formularioValue.pattern).subscribe(res =>{
    },
    (error) =>{
        console.error(error);
    })
  }

}
