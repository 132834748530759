<div class="pointer">
	<div class="avatar" *ngIf="agentInfo" [ngStyle]="{ 'background-image': getUserAvatar() }" (click)="showMenuPanel()"
		nbTooltip="Tu agente de IA" nbTooltipPlacement="right">

	</div>
	<div *ngIf="showUserAccount" class="menu-card">
		<nb-card class="mb-0">
			<nb-card-body>
				<app-agent-gpt (close)="closeModal()"></app-agent-gpt>
			</nb-card-body>
		</nb-card>
	</div>
</div>
