
export interface availableProfiles{
		profileStudent: boolean;
		profileEditor: boolean;
		profileTeacher: boolean;
		profileParent: boolean;
		profileAdmin: boolean;
		profileCenter: boolean;
}

export const initialAvailableProfiles: availableProfiles = {
	profileStudent: false,
	profileEditor: false,
	profileTeacher: false,
	profileParent: false,
	profileAdmin: false,
	profileCenter: false
}

