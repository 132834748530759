<div class="w-100  animated fast fadeIn">
    <div class="footer-container">
      <div class="container">
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs ">
						<li *ngFor="let tab of tabs; let i = index; trackBy: trackByFn" [ngbNavItem]="tab.idQuizMultiple">
							<a ngbNavLink class="btn btn-outline-dark">
								<div class="d-flex justify-content-center ">
									<div class="col-10" >
										<span  ngbTooltip ="{{tab.quizTitle}}" *ngIf="tab.quizTitle !== ''" placement="right">
											{{tab.quizTitle | slicestring | truncate:[12, '...']}}
										</span>
										<span  ngbTooltip =" {{ 'QUIZZES.DIALOGS.SINTITULO' | translate }}" *ngIf="tab.quizTitle === ''" placement="right">
											{{ 'QUIZZES.DIALOGS.SINTITULO' | translate }}
										</span>
									</div>
										<div class="col-2">
										<!--
										<mat-icon (click)="closeTab($event, tab.idQuizMultiple)" ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" class="close-icon" >close</mat-icon>
										-->
										</div>
								</div>
							</a>
							<ng-template ngbNavContent *ngIf="quizzes.length > 0">
								<div class="body-elements">
									<div class="w-100  animated fast fadeIn">
										<app-sub-quizzes [subQuizzes]="tab" [elements]="elements" (saveData)="onSaveData($event)"></app-sub-quizzes>
									</div>
								</div>
								<div class="body-elements animated fast fadeIn">
											<app-elements-sub-quizzes (elementUpdatedSize)="elementUpdatedSize($event)" (elementAdded)="elementAdded($event)" (elementDelete)="deleteElement($event)" [elementsData]="elements[i]" [quizFiles]="quizFiles" ></app-elements-sub-quizzes>
								</div>
							</ng-template>
						</li>
						<!--
            <li class="nav-item add-element">
              <button class="btn btn-link  btn-outline-success flex align-items-center justify-content-center pointer" [disabled]=" tabs.length >= 10"  (click)="addTab($event)">
                  <mat-icon ngbTooltip ="{{ 'QUIZZES.AGREGAR' | translate }}" class="close-icon " >add</mat-icon>
              </button>
            </li>
						-->
          </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
</div>
