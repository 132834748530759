import { PdfService } from './../../../core/services/pdf.service';

import { CommonModule } from '@angular/common';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbButtonModule, NbCardModule, NbFormFieldModule, NbIconModule, NbSelectModule, NbSpinnerModule, NbTooltipModule, NbWindowRef, NbWindowService } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { API_KEY_CHAT_GTP } from 'src/app/core/models/masters/masters.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { LocalStorage } from 'src/app/core/utils';
import { ComponentsModule } from '../components.module';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
@Component({
	selector: "app-salwizar-quizzes",
	standalone: true,
	imports: [
		CommonModule,
		NbFormFieldModule,
		NbCardModule,
		NbIconModule,
		NbTooltipModule,
		NbSelectModule,
		NbButtonModule,
		FormsModule,
		ReactiveFormsModule,
		NbSpinnerModule,
		TranslateModule,
	],
	templateUrl: "./salwizar-quizzes.component.html",
	styleUrls: ["./salwizar-quizzes.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class SalwizarQuizzesComponent {
	private httpClient: HttpClient;
	salwizarForm: any;
	@ViewChild("inputFile") myInputVariable: ElementRef;
	pruebaGenerarIdeas: {
		Numero: number;
		Titulo_de_la_Idea: string;
		Cuerpo_Explicativo_de_la_Idea: string;
		Resumen: string;
		Coordenada_X: number;
		Coordenada_Y: number;
	}[];
	loading: boolean = false;
	PDFtoAnalize: string;
	generatedIdeas: any;
	constructor(
		private formBuild: FormBuilder,
		private activeModal: NgbActiveModal,
		public http: HttpClient,
		private modalService: NgbModal,
		private localStorage: LocalStorage,
		private pdf: PdfService,
		handler: HttpBackend,
		private toaster: ToasterService
	) {
		this.buildForm();
		this.httpClient = new HttpClient(handler);
	}

	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.generatedIdeas = JSON.parse(
			this.localStorage.getItem("IAGeneratedIdeas")
		);

		let node: any = JSON.parse(localStorage.getItem("nodeData"));
	}

	buildForm(): void {
		this.salwizarForm = this.formBuild.group({
			topic: [""],
			level: ["Preparatoria"],
			lang: ["Español"],
			extra: [""],
			options: [3],
			cant: [1],
		});
	}

	closeModal(sendData?: any): void {
		this.activeModal.close(sendData);
	}

	async generateQuizzes() {
		let node: any = JSON.parse(localStorage.getItem("nodeData"));
		let ideas = JSON.parse(this.localStorage.getItem("IAGeneratedIdeas"));

		let idea = ideas.find((idea) => idea.idOriginal === node.idOriginal);

		//let cuerpo = idea.Cuerpo_Explicativo_de_la_Idea;
		this.loading = true;
		const apiKey = API_KEY_CHAT_GTP;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${apiKey}`,
		});
		const formData = this.salwizarForm.value;

		const prompt = `Actua como un profesor de ${formData.nivel},
		el objetivo es crear los quizzes necesarios basados en la idea que se te van a proporcionar, estos quizzes estaran escritos
		en el idioma ${
			formData.lang
		}, pero solo el contenido de los mismos, los nombres de las propiedades mantenlos
		en español, en este caso la idea en la que te vas a basar para hacer cada quizz
		es la siguiente: ${JSON.stringify(
			idea
		)} vas a basar basar todos los quizzes en la idea proporcionada,
		debes generar un json que contendra multiples datos,
		el primero sera el numero de quizz, este numero sera el orden de los quizzes, ya que en un futuro podrian conectarse
		a modo de mapa mental por eso deben tener un orden establecido,
		el segundo sera el titulo del quiz, el tercero sera la pregunta a contestar en el quiz, y los dos
		ultimos seran coordenadas de ejes X, Y estas coodenadas estaran basadas en las coordenadas de la idea en la que se bas la pregunta,
		es decir los quizzes deben estar plasmados a los lados de dichas ideas por que las coordenadas seran parecidas a los
		de la idea en la que se basan con pequeñas variaciones de 20 a 30 pixeles para que aparezcan a sus lados,
		ademas de eso una propiedad mas llamada linkTo que contendra el idOrginal de la idea a la que se basa el quiz,
		los nombres de las propiedades seran
		las siguientes: Numero, Titulo, Pregunta, opciones, x, y, linkTo,
		vas a generar ${formData.cant} quizzes por cada idea, y ${
			formData.options
		} opciones de respuesta por cada quiz,
		el objeto de opciones tendra dos propiedades una llamada text que contiene el texto de la respuesta, y otra llamada correcto
		que contiene un booleano que indica si la respuesta es correcta o no,
		no le des formato markdown a la respuesta, quiero el JSON crudo el cual sera un array con los quizzes directamente dentro,
		es decir que no quiero que metas los quizzes dentro de ninguna propiedad previa`;
		const apiEndpoint = "https://api.openai.com/v1/chat/completions";
		const body = {
			model: "gpt-4o",
			messages: [
				{
					role: "system",
					content: prompt.replace(/(\r\n|\n|\r|\t)/gm, " "),
				},
			],
			max_tokens: 4096, // Cantidad máxima de tokens en la respuesta
			temperature: 0.7,
		};
		try {
			const respuesta = await this.httpClient
				.post<any>(apiEndpoint, body, { headers })
				.toPromise();
			if (respuesta) {
				this.loading = false;

				this.localStorage.setItem("IAGeneratedQuizzes", [
					respuesta.choices[0].message.content,
				]);

				let IaGeneratedQuizzes = JSON.parse(
					this.localStorage.getItem("IAGeneratedQuizzes")
				);

				this.calculateCircularPositions(
					IaGeneratedQuizzes,
					100,
					node.x,
					node.y
				);
				this.toaster.success(
					"Actividades Generadas",
				);
				this.closeModal("Quizzes Generados");
			}
		} catch (error) {
			this.loading = false;
			this.toaster.error(
				'Error al generar las actividades, por favor intenta de nuevo',
			);
			console.error("Error al hacer la consulta a ChatGPT:", error.message);
		}
	}

	calculateCircularPositions(
		quizzes,
		radius: number,
		initialX: number,
		initialY: number
	): any {
		const angleIncrement = (2 * Math.PI) / quizzes.length;

		quizzes.forEach((point, index) => {
			const angle = angleIncrement * index;
			point.x = initialX + radius * Math.cos(angle - Math.PI / 2); // start at the top
			point.y = initialY + radius * Math.sin(angle - Math.PI / 2);
		});

		this.localStorage.setItem("IAGeneratedQuizzes", JSON.stringify(quizzes));

		return quizzes;
	}
}
