import { HypermediaTypes } from "src/app/shared/components/nodes/quiz-hypermedia/hypermedia-types.enum";

export enum QuizElementTypes {
    Q_VIDEOS = 0,
    Q_PICTURES,
    Q_AUDIOS,
    Q_PDFS,
    Q_TEXTS,
    O_VIDEOS,
    O_PICTURES,
    O_AUDIOS,
    O_PDFS,
    O_TEXTS,
    A_VIDEOS,
    A_PICTURES,
    A_AUDIOS,
    A_PDFS,
    A_TEXTS,
    INPUT,

    Q_MICRO,
    A_MICRO,
    O_MICRO,
    Q_CAMERA,
    A_CAMERA,
    O_CAMERA,
    Q_VIDEOCAMARA,
    A_VIDEOCAMARA,
    O_VIDEOCAMARA
}
export enum QuizElementTypesAnswer {
    A_VIDEOS = 12,
    A_PICTURES,
    A_AUDIOS,
    A_PDFS,
    A_TEXTS
}
export function mapFileTypeToElementType(fileType: HypermediaTypes): QuizElementTypes {
    switch (fileType) {
        case HypermediaTypes.IMAGE:
            return QuizElementTypes.Q_PICTURES;
        case HypermediaTypes.VIDEO:
            return QuizElementTypes.Q_VIDEOS;
        case HypermediaTypes.AUDIO:
            return QuizElementTypes.Q_AUDIOS;
        case HypermediaTypes.PDF:
            return QuizElementTypes.Q_PDFS;
        case HypermediaTypes.MICRO:
            return QuizElementTypes.Q_MICRO;
        case HypermediaTypes.VIDEOCAM:
            return QuizElementTypes.Q_VIDEOCAMARA;
        default:
            return QuizElementTypes.Q_TEXTS;
    }
}
