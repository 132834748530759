import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// esto es para reemplazar la palabra AppVersion por la version de la app en el index.html
var html = document.documentElement.innerHTML
document.documentElement.innerHTML = html.replace(/AppVersion/g, environment.version)

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
