import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  public task = new Subject();

  constructor() { }

  public setTask(task:any) {
    this.task.next(task);
  }
}
