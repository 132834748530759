<div class="d-flex justify-content-center mt-5">
	<form [formGroup]="formulario" (ngSubmit)="aceptar()" style="max-width: 500px; width: 100%;">
		<div class="d-flex flex-column gap-3">
			<!-- OLDPASS -->
			<div class="d-flex flex-column w-100">
				<div class="d-flex position-relative">
					<input nbInput fullWidth class="form-control" formControlName="oldPassword"
						[type]="hidePassword ? 'password' : 'text'" placeholder="{{ 'CAMBIARPASS.OLDPASS' | translate }}">
					<button class="hide-show-password-position" type="button" mat-icon-button matSuffix
						(click)="hidePassword = !hidePassword" [attr.aria-pressed]="hidePassword">
						<mat-icon class="icon-password">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
					</button>
				</div>
				<div class="d-flex mt-2">
					<div *ngFor="let validation of validationMessages.oldPassword">
						<div class="error-message"
							*ngIf="oldPasswordControl?.hasError(validation.type) && (oldPasswordControl?.dirty || oldPasswordControl?.touched)">
							{{validation.message}}
						</div>
					</div>
				</div>
			</div>
			<!-- NEWPASS -->
			<div class="d-flex flex-column w-100">
				<div class="d-flex position-relative">
					<input nbInput fullWidth class="form-control" formControlName="newPassword"
						[type]="hidePassword2 ? 'password' : 'text'" placeholder="{{ 'CAMBIARPASS.NEWPASS' | translate }}">
					<button class="hide-show-password-position" type="button" mat-icon-button matSuffix
						(click)="hidePassword2 = !hidePassword2" [attr.aria-pressed]="hidePassword2">
						<mat-icon class="icon-password">{{hidePassword2 ? 'visibility_off' : 'visibility'}}</mat-icon>
					</button>
				</div>
				<div class="d-flex mt-2">
					<div *ngFor="let validation2 of validationMessages.newPassword">
						<div class="error-message"
							*ngIf="newPasswordControl?.hasError(validation2.type) && (newPasswordControl?.dirty || newPasswordControl?.touched)">
							{{validation2.message}}
						</div>
					</div>
				</div>
			</div>
			<!-- NEW PASS REPEAT-->
			<div class="d-flex flex-column w-100">
				<div class="d-flex position-relative">
					<input nbInput fullWidth class="form-control" formControlName="newPassword2"
						[type]="hidePassword3 ? 'password' : 'text'" (keyup)="validacionPass()"
						placeholder="{{ 'CAMBIARPASS.REPEATNEWPASS' | translate }}">
					<button class="hide-show-password-position" type="button" mat-icon-button matSuffix
						(click)="hidePassword3 = !hidePassword3" [attr.aria-pressed]="hidePassword3">
						<mat-icon class="icon-password">{{hidePassword3 ? 'visibility_off' : 'visibility'}}</mat-icon>
					</button>
				</div>
				<div class="d-flex flex-column mt-2">
					<div *ngFor="let validation3 of validationMessages.newPassword2">
						<div class="error-message"
							*ngIf="newPasswordControl2?.hasError(validation3.type) && (newPasswordControl2?.dirty || newPasswordControl2?.touched)">
							{{validation3.message}}
						</div>
					</div>
					<div>
						<div class="error-message" *ngIf="!passCoincide">
							{{ 'CAMBIARPASS.NOREPEATED' | translate }}
						</div>
					</div>
				</div>
			</div>
			<!-- ACCEPT AND CANCEL BUTTONS-->
			<div class="d-flex justify-content-center gap-3" [ngClass]="{'justify-content-end':disableCancel}">
				<div>
					<button nbButton class="btn-themecolor" size="small" type="submit">{{ 'ACEPTARCANCELAR.ACEPTAR' | translate }}</button>
				</div>
				<div *ngIf="!disableCancel">
					<button nbButton class="btn-red" size="small" (click)="cancelar()">{{ 'ACEPTARCANCELAR.CANCELAR' | translate }}</button>
				</div>
			</div>
		</div>
	</form>
</div>
