<div class="quiz-container" id="quiz" *ngIf="quiz">
    <!-- <app-hamburger-menu [menuItems]="menuItems" (clickFunction)="getActionFromMenu($event)" [position]="menuPosition"></app-hamburger-menu>
    <app-recordar-quiz-pad-buttons [isShow]="isShowPads" [isShowLabels]="isShowLabels" (action)="getAction($event)"></app-recordar-quiz-pad-buttons>
    <app-recordar-quiz-pad-controller [isShow]="isShowPads" [isShowLabels]="isShowLabels" (action)="getControllerAction($event)"></app-recordar-quiz-pad-controller> -->

    <app-recordar-quiz-play-multiple-header [quiz]="quiz" [idCurso]="courseId" [idMapa]="graphId" (close)="onClose()"></app-recordar-quiz-play-multiple-header>

    <div class="body-container py-3 w-100 mt-4">
        <app-recordar-quiz-play-multiple-body  (action)="getControllerAction($event)" [courseId]="courseId" 
        [graphId]="graphId" [quiz]="quiz" [elements]="elements" [answered]="true" (answeredChange)="onAnswered()" 
         [respuestaEstudiante]="respuestaEstudiante"
         (close)="onClose($event)"
        (colorChange)="onColorChanged($event)"></app-recordar-quiz-play-multiple-body>
    </div>
</div>
