import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/core/models/users/user.models';
import { LoginService } from 'src/app/core/services/login';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { UsersService } from 'src/app/core/services/users';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';


@Component({
  selector: 'app-image-profile',
  templateUrl: './image-profile.component.html',
  styleUrls: ['./image-profile.component.scss'],
  providers : [ImagenPipe]
})
export class ImageProfileComponent implements OnInit {

  @Input() image:string|null;
  public imageProfile:string = ''
  public progress: number = 0
  private _idUser:number;
	public userData: any;
	@Input() readOnlyMode: boolean = false;

	constructor(private userServices: UsersService,
		private loginService: LoginService,
		private translateService: TranslateService,
		private imagePipe: ImagenPipe,
		private toaster: ToasterService,) { }

  ngOnInit() {
		this.userData = this.loginService.getUser();
    if(this.image)
      this.imageProfile = this.imagePipe.transform(this.image, 'userAvatar')

    this._idUser = this.loginService.getUser().idUser
  }

  onFileSelected(event){
    const file = event.target.files[0];

    if(!file) return

    this.userServices.updateOrDeleteImageProfile(this.image,file).subscribe((event: HttpEvent<any>) => {

      switch(event.type){
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100)
          break
        case HttpEventType.Response:
          setTimeout(() => {
            this.progress = 0
            this.image = event.body.data
            this.imageProfile = this.imagePipe.transform(this.image, 'userAvatar')
            let user: User = this.loginService.getUser()
            user.pictureUser = this.image
            this.loginService.setUserLocalStorage(user)

            this.toaster.success(this.translateService.instant('IMAGEPROFILE.ADDIMAGEOK'))
          }, 500);
          break
      }
    }, err => { this.toaster.error(this.translateService.instant('IMAGEPROFILE.ADDIMAGEKO')) })
  }

  deleteProfileImage(){
    this.userServices.updateOrDeleteImageProfile(this.image,'').subscribe((event: HttpEvent<any>) => {
      switch(event.type){
        case HttpEventType.Response:
          this.toaster.success(this.translateService.instant('IMAGEPROFILE.REMOVEIMAGEOK'))
          this.image = ''
          this.progress = 0
          let user: User = this.loginService.getUser()
          user.pictureUser = this.image
          this.loginService.setUserLocalStorage(user)
          break;
      }
    }, err => { this.toaster.error(this.translateService.instant('IMAGEPROFILE.REMOVEIMAGEKO')) })
  }

}
