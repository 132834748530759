import { createReducer, on } from '@ngrx/store';
import { ActionTypes, ActionsUnion, updateUiState } from '../actions/ui-state.action';
import { initialUiState } from '../models/ui-state.model';

const _uiStateReducer = createReducer(
	initialUiState,
	on(updateUiState, (state, { uiState }) => ({ ...state, ...uiState }))
);

export function uiStateReducer(state = initialUiState, action: ActionsUnion) {
	switch (action.type) {
		case ActionTypes.register:
			return action.payload;

		default:
			return _uiStateReducer(state, action);
	}
}
