<!--div id="node" [ngStyle]="{'position':'absolute'}" cdkDrag-->
<div id="node">
    <app-loading [isVisible]="cargando"></app-loading>
    <app-head-nodes [node]="node" [type]="type" [idCurso]="idCurso" [idMapa]="idMapa"
        (newNode)="receiveNewNodeData($event)"></app-head-nodes>
    <div class="modal-body fondoEditarCurso mb-0 pb-0">
        <!-- A.G. ngIf -->
        <form class="form-horizontal">
            <div class="row mx-auto">
                <div class="col-lg-10 col-md-12 col-sm-12 form-control">
                    <div class="col-lg-12" align="center" *ngIf="inicial">
                        <div class="mt-3"> &nbsp;</div>
                        <div class="mt-3"> &nbsp;</div>
                        <div class="mt-3"> &nbsp;</div>
                        <div>
                            <mat-icon class="imagenInicial">cloud_upload</mat-icon>
                        </div>
                        <div *ngIf="mensaje=='subir'"><span>{{ 'NODOS.SUBIR' | translate }}</span></div>
                        <div *ngIf="mensaje=='mostrar'"><span>{{ 'NODOS.MOSTRAR' | translate }}</span></div>
                        <div *ngIf="mensaje=='noFormato'"><span>{{ 'NODOS.NOFORMATO' | translate }}</span></div>
                        <div *ngIf="mensaje=='noEscogido'"><span>{{ 'NODOS.NOESCOGIDO' | translate }}</span></div>
                    </div>
                    <div class="container-power">
                        <app-power-component></app-power-component>
                    </div>
                    <app-loading [isVisible]="cargando"></app-loading>

                    <video #videoElement *ngIf="format=='video' && !cargando && !inicial" controls>
                        <source [src]="videoSource|safe" />
                    </video>

                    <!--div class="col-lg-12 col-md-12 col-sm-12 p-0" style="height:500px;" *ngIf="format=='imagen' && !cargando && !inicial"-->
                    <div class="w-100 h-100 p-0" *ngIf="format=='imagen' && !cargando && !inicial">
                        <img #imgElement class="w-100 h-100 mx-auto img-fluid" [src]="imageSource|safe" />
                    </div>

                    <div class="w-100 h-100 p-0" *ngIf="format=='audio' && !cargando && !inicial">
                        <img #audioElement class="w-80 h-75 d-block mx-auto img-fluid"
                            src="../../assets/images/headphones.jpg" style="opacity: 0.2" />
                        <audio class="w-100 h-25 d-block" controls>
                            <source [src]="audioSource|safe">
                        </audio>
                    </div>

                    <div *ngIf="format=='pdf' && !cargando && !inicial"
                        class="w-100 h-100 p-0 embed-responsive embed-responsive-16by9">
                        <iframe #pdfElement class="embed-responsive-item" [src]="pdfSource|safe"
                            allowfullscreen></iframe>
                    </div>

                    <div *ngIf="format=='texto' && !cargando && !inicial"
                        class="w-100 h-100 p-0 embed-responsive embed-responsive-16by9">
                        <iframe #textoElement [src]="textSource|safe"></iframe>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="w-100 h-20 p-0 image-upload" id={{format.titulo}} *ngFor="let format of formats">
                        <div *ngIf="loginService.esAutor() && format.edit==false">
                            <!--div *ngIf="format.edit==false"-->
                            <div class="col  ml-auto"
                                style="display:flex; align-items:center; justify-content:center; padding: 20px;">
                                <input id="{{format.id}}" type="file" (change)="onFileSelected($event,format.titulo)" />
                                <label for="{{format.id}}">
                                    <mat-icon class="medios pointer" placement="top" ngbTooltip="{{format.titulo}}">
                                        {{format.formato}}</mat-icon>
                                </label>
                            </div>
                        </div>
                        <div *ngIf="loginService.esAutor() && format.edit==true">
                            <!--div *ngIf="format.edit==true"-->
                            <div class="col ml-2 mt-1">
                                <div class="float-right">
                                    <input id="{{format.id}}" type="file"
                                        (change)="onFileSelected($event,format.titulo)" />
                                    <label for="{{format.id}}">
                                        <mat-icon class="edit pointer" placement="top" ngbTooltip="{{'GLOBALS.EDIT' | translate}}">edit
                                        </mat-icon>
                                    </label>
                                </div>
                            </div>
                            <div class="col mt-3 ml-auto"
                                style="display:flex; align-items:center; justify-content:center">
                                <mat-icon class="medios pointer" placement="top" ngbTooltip="{{format.titulo}}"
                                    (click)="reproducirArchivo(format)">
                                    {{format.formato}}</mat-icon>
                            </div>
                        </div>
                        <div id="{{format.titulo}}" *ngIf="loginService.esEstudiante()"
                            [ngStyle]="{'background-image':getImagen(format),'background-size':'100% 100%','opacity':'0.9'}">
                            <div class="col ml-auto"
                                style="display:flex; align-items:center;justify-content:center;padding:20px;">
                                <label for="{{format.id}}" [ngStyle]="{'z-index':1}">
                                    <mat-icon class="medios pointer" placement="top" ngbTooltip="{{format.titulo}}"
                                        (click)="reproducirArchivo(format)">
                                        {{format.formato}}</mat-icon>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <app-footer-reproducir-quizzes></app-footer-reproducir-quizzes>
</div>
