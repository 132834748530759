<nb-card class="no-edge-card mb-0 background_themecolor modal-height-100-62">

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-header class="d-flex justify-content-center align-items-center">
    <button nbButton status="basic" size="large" shape="round" [ngClass]="{ 'active-button': questionTypeButton == 1 }"
		 (click)="changeQuestionType(1)">
		 <nb-icon icon="edit-2"></nb-icon>
		</button>
    <button nbButton status="basic" size="large" shape="round" [ngClass]="{ 'active-button': questionTypeButton == 2 }"
		 (click)="changeQuestionType(2)">
		 <nb-icon icon="radio-button-on"></nb-icon>
		</button>
    <button nbButton status="basic" size="large" shape="round" [ngClass]="{ 'active-button': questionTypeButton == 3 }"
		 (click)="changeQuestionType(3)">
		 <nb-icon icon="checkmark-square-2"></nb-icon>
		</button>
		<div>
			<nb-icon class="pointer" style="color: red;" icon="trash-2-outline"></nb-icon>
		</div>
	</nb-card-header>

	<nb-card-body class="justify-content-center">
		<div class="form-group">
			<textarea nbInput fullWidth id="exampleFormControlTextarea1" rows="3"
			maxlength="500" [(ngModel)]="question.title"></textarea>
		</div>
		<div class="mt-3">
			<ul *ngIf="questionTypeButton != 1 && question.type != 1 && question.options.length > 0">
				<li class="mb-2 d-flex" *ngFor="let option of question.options">
					<div class="col-9">
						<input nbInput fullWidth type="text" id="exampleFormControlInput1" class="pointer"
						placeholder="Titulo" maxlength="100" [(ngModel)]="option.text">
					</div>
					<div class="col-1 p-2">
						<nb-icon class="pointer" style="color: red;" icon="trash-2-outline" (click)="deleteOption(option)"></nb-icon>
					</div>
				</li>
			</ul>
			<div class="d-flex justify-content-center align-items-center" *ngIf="question.type != 1">
				<nb-icon style="font-size: xxx-large;" icon="plus-circle-outline" (click)="newOption()"></nb-icon>
			</div>
		</div>
	</nb-card-body>

</nb-card>
