<div class="sticky-top sticky-top-header">
    <div class="modal-header tittle" align="center">
        <div class="col-sm-10 align-self-center text-center">
            <span
                class="textoFontSize1 font-weight-bold span-text-center">{{ 'PATTERNS.PATTERNDRAFT' | translate }}</span>
        </div>
        <div class="col-sm-2 align-self-center text-center">
            <button type="button" class="close pointer" [ngStyle]="{'color':'rgb(29, 73, 153)'}" data-dismiss="modal"
                (click)="closeModal('')">
                <span class="textoFontSize18 font-weight-bold span-text-center" aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="options" align="center">
        <div class="row">
            <div class="col-0 col-sm-0 col-md-0 col-lg-9">
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-3">
                <div class="row align-items-center justify-content-center">
                    <div class="col-sm-4 col-4">
                        <button (click)="saveElements()" [ngStyle]="{'background-color':'#DEDEDE'}">
                            <mat-icon class="icon-head icon-size-medium pointer" color="primary" data-toggle="tooltip"
                                data-placement="bottom" placement="bottom"
                                ngbTooltip="{{ 'PATTERNS.GUARDAR' | translate }}" svgIcon="done"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-body pt-0 fondoEditarCurso">
    <div #myBounds class="container" id="container2">
        <div *ngFor="let element of elements"
        id="{{element.idTemplateElement}}" class="element-box {{element.style}}"
        [style.top.px]="element.yPosition" [style.left.px]="element.xPosition" [style.width.px]="element.xSize"
        [style.height.px]="element.ySize" ngDraggable ngResizable [bounds]="myBounds" [inBounds]="true" rzContainment="#container2" rzHandles="all" (endOffset)="onMoveEnd($event,element)" (rzStop)="onResizeStop($event, element)">
            <mat-icon aria-hidden="false">{{element.icon}}</mat-icon>
        </div>        
    </div>
</div>


