import { Component, OnDestroy, Output, EventEmitter, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, AbstractControl } from "@angular/forms";

// Utils
import { LocalStorage } from "src/app/core/utils";

// Services
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "src/app/core/services/shared";
import { LoginService } from "src/app/core/services/login";

// Models
import { DateAdapter } from "@angular/material/core";
import { RegisterService } from "src/app/core/services/register/register.service";
import { RegisterNickModel } from "src/app/core/models/users/register.model";
import { ROUTES_NAME } from "src/app/core/utils/routes-name";
import { Subscription } from "rxjs";
import { formsValidations } from "src/app/core/utils/forms-validations";
import { DialogService } from "src/app/core/services/shared/dialog.service";
import { CenterService } from "src/app/core/services/center/center.service";
import { LoginModel } from "src/app/core/models/users/login.model";
import { LOCALSTORAGESTRINGS } from "src/app/core/models/masters/localstorage.enum";
import { MatomoAnalyticsUtils } from "src/app/core/utils/matomo-analytics.utils";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToasterService } from "src/app/core/services/shared/toaster.service";

declare function init_plugins();

enum STEPS {
	STEP1 = "1",
	STEP2 = "2",
}


@Component({
	selector: "app-register-nick",
	styleUrls: ["./register-nick.component.scss"],
	templateUrl: "./register-nick.component.html",
	encapsulation: ViewEncapsulation.None,
})
export class RegisterNickComponent implements OnInit, OnDestroy {
	@Output() closeAux: EventEmitter<boolean> = new EventEmitter<boolean>();
	step2: UntypedFormGroup;
	step1: UntypedFormGroup;
	hidePassword: boolean = true;
	hidePasswordR: boolean = true;
	bgImage: string;
	lang: string;
	currentStep: string = STEPS.STEP1;
	validationMessages = {
		nick: [],
		keyword: [],
		repeatKeyWord: [],
		terms: [],
		paper: [],
		idSecurityQuestions: [],
		securityAnswer: [],
		idSecurityQuestions2: [],
		securityAnswer2: []
	};
	loading: boolean = false;
	loginRoute = ROUTES_NAME.LOGIN;
	loginImage:any;

	securityQuestions: { id: number; question: string }[] = [
    //{ id: 0, question: ""},
    { id: 1, question: this.translateService.instant("SECURITYQUESTIONS.QUESTION1")},
    { id: 2, question: this.translateService.instant("SECURITYQUESTIONS.QUESTION2")},
    { id: 3, question: this.translateService.instant("SECURITYQUESTIONS.QUESTION3")},
    { id: 4, question: this.translateService.instant("SECURITYQUESTIONS.QUESTION4")},
    { id: 5, question: this.translateService.instant("SECURITYQUESTIONS.QUESTION5")},
  ];

	roleAsignado: boolean = true;
	nickOk: boolean = false;

	logRegistro: String[] = [];

	private nickMaxLength: number = formsValidations.NICK_MAX_LENGTH;
	private nickMinLength: number = formsValidations.NICK_MIN_LENGTH;
	private passMaxLenght: number = formsValidations.PASS_MAX_LENGTH;
	private emailMaxLength: number = formsValidations.EMAIL_MAX_LENGTH;
	private emailPattern: string = formsValidations.EMAIL_PATTERN;
	private subs = new Subscription();
	private courseInitial: string;

	constructor(
		private router: Router,
		private formBuild: UntypedFormBuilder,
		public translateService: TranslateService,
		private titleService: TitleService,
		private localStorage: LocalStorage,
		private loginService: LoginService,
		private registerService: RegisterService,
		private dateAdapter: DateAdapter<Date>,
		private dialogService: DialogService,
		private centerService: CenterService,
		private ma: MatomoAnalyticsUtils,
		private toaster: ToasterService,
		public activeModal: NgbActiveModal,
	) { }

	ngOnInit(): void {
		this.load()
	}

	private load(){
		this.subs = this.centerService.getImageCenter().subscribe((img) => {
			this.bgImage = img;
		});
		this.crearInitialForm();
		this.step1.get('repeatKeyWord').setValidators([this.passwordMatchValidator.bind(this)]);
		this.getCenterInfo();
		init_plugins();
		this.traducirOpciones();

		// Y hago un logout
		this.loginService.logout(false);
	}

	crearInitialForm() {
		this.step1 = this.formBuild.group({
			nick: [
				"",
				{
					validators: [
						Validators.required,
						Validators.maxLength(this.nickMaxLength),
						Validators.minLength(this.nickMinLength),
						Validators.pattern(formsValidations.NICKPATTERN)
					],
				},
			],
			keyWord: [
				"",
				{
					validators: [
						Validators.required,
						Validators.maxLength(this.passMaxLenght),
						Validators.pattern(formsValidations.PASSWORDPATTERN),
					],
				},
			],
			fatherEmail: [
				"",
				{
					validators: [
						Validators.maxLength(this.emailMaxLength),
						Validators.pattern(this.emailPattern),
					],
				},
			],
			repeatKeyWord: [
				"",
				{
					validators: [
						Validators.required,
						Validators.maxLength(this.passMaxLenght),
						Validators.pattern(formsValidations.PASSWORDPATTERN),
					],
				},
			],
			terms: [false, Validators.requiredTrue],
			paper: [false, Validators.requiredTrue],
			profileEditor: [false],
			profileStudent: [true, Validators.required],
			profileTeacher: [false, Validators.required],
			profileParent: [false, Validators.required],
		});
	}

	passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const password = this.step1.get('keyWord').value;
    const repeatPassword = control.value;

    if (password !== repeatPassword) {
      return { passwordMismatch: true };
    }

    return null;
  }

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	validateRepeatPassword() {
    this.step1.get('repeatKeyWord').updateValueAndValidity();
  }


	nextStep() {
		if(this.step1.invalid){
			/** esto es para el log del registro */
			const now = new Date();
			const invalidControls = this.getInvalidControls(this.step1.controls);
			const strLog = now + ' --- Campos inválidos ingresados: ' + invalidControls + '.';
			this.logRegistro.push(strLog);
			/** */
			false;
		} else {
			/** esto es para el log del registro */
			const now = new Date();
			const strLog = now + ' --- Ingresó los siguientes datos: '
				+ 'nick: ' + this.step1.value.nick + ', '
				+ 'fatherEmail: ' + this.step1.value.fatherEmail + ', '
				+ 'terms: ' + this.step1.value.terms + ', '
				+ 'paper: ' + this.step1.value.paper + ', '
				+ 'profileEditor: ' + this.step1.value.profileEditor + ', '
				+ 'profileStudent: ' + this.step1.value.profileStudent + ', '
				+ 'profileTeacher: ' + this.step1.value.profileTeacher + ', '
				+ 'profileParent: ' + this.step1.value.profileParent + '.'
			this.logRegistro.push(strLog);
			/** */
			this.currentStep = STEPS.STEP2;
			this.crearFormulario();
		}
	}

	crearFormulario() {
		this.step2 = this.formBuild.group({
			idSecurityQuestions: ["", [Validators.required, Validators.pattern(formsValidations.QUESTION_PATTERN)]],
			securityAnswer: ["", [Validators.required, Validators.minLength(formsValidations.ANSWER_MIN_LENGTH)]],
			idSecurityQuestions2: ["", [Validators.required, Validators.pattern(formsValidations.QUESTION_PATTERN)]],
			securityAnswer2: ["", [Validators.required, Validators.minLength(formsValidations.ANSWER_MIN_LENGTH)]]
	});
	}

	get nickControl(): UntypedFormControl {
		if (this.step1) {
			return this.step1.get("nick") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get keywordControl(): UntypedFormControl {
		if (this.step1) {
			return this.step1.get("keyWord") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get repeatKeywordControl(): UntypedFormControl {
		if (this.step1) {
			return this.step1.get("repeatKeyWord") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get profileEditor(): UntypedFormControl {
		if (this.step1) {
			return this.step1.get("profileEditor") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get profileStudent(): UntypedFormControl {
		if (this.step1) {
			return this.step1.get("profileStudent") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get profileTeacher(): UntypedFormControl {
		if (this.step1) {
			return this.step1.get("profileTeacher") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get profileParent(): UntypedFormControl {
		if (this.step1) {
			return this.step1.get("profileParent") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get question1Control(): UntypedFormControl {
		if (this.step2) {
			return this.step2.get("idSecurityQuestions") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get answer1Control(): UntypedFormControl {
		if (this.step2) {
			return this.step2.get("securityAnswer") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get question2Control(): UntypedFormControl {
		if (this.step2) {
			return this.step2.get("idSecurityQuestions2") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get answer2Control(): UntypedFormControl {
		if (this.step2) {
			return this.step2.get("securityAnswer2") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get termsControl(): UntypedFormControl {
		if (this.step1) {
			return this.step1.get("terms") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get paperControl(): UntypedFormControl {
		if (this.step1) {
			return this.step1.get("paper") as UntypedFormControl;
		} else {
			return null;
		}
	}

	traducirOpciones() {
		// Recupero el lenguaje

		this.lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
		this.translateService.use(this.lang);
		this.dateAdapter.setLocale(this.lang);

		// Titulo pagina
		this.translateService
			.get("REGISTRO.REGISTRARNUEVOUSUARIO")
			.subscribe((res: string) => {
				this.titleService.setHTMLTitle(res);
			});

		this.translateService
			.get("VALIDACIONES.ELNICKESOBLIGATORIO")
			.subscribe((res: string) => {
				this.validationMessages.nick.push({
					type: "required",
					message: res,
				});
			});

		this.translateService
			.get("VALIDACIONES.LACONTRASENAESOBLIGATORIA")
			.subscribe((res: string) => {
				this.validationMessages.keyword.push({
					type: "required",
					message: res,
				});
			});

			this.translateService
			.get("VALIDACIONES.LASCONTRASENASNOCOINCIDEN")
			.subscribe((res: string) => {
				this.validationMessages.repeatKeyWord.push({
					type: "passwordMismatch",
					message: res,
				});
			});

		this.translateService
			.get("VALIDACIONES.MUSTACCEPTTERMS")
			.subscribe((res: string) => {
				this.validationMessages.terms.push({ type: "required", message: res });
			});

		this.translateService
			.get("VALIDACIONES.MUSTACCEPTTERMS")
			.subscribe((res: string) => {
				this.validationMessages.paper.push({ type: "required", message: res });
			});

		this.translateService
			.get("VALIDACIONES.PASSWORDPATTERN")
			.subscribe((res: string) => {
				this.validationMessages.keyword.push({ type: "pattern", message: res });
			});

			this.translateService
			.get("VALIDACIONES.NICKPATTERN")
			.subscribe((res: string) => {
				this.validationMessages.nick.push({ type: "pattern", message: res });
			});

			this.translateService
			.get("VALIDACIONES.NICKEXIST")
			.subscribe((res: string) => {
				this.validationMessages.nick.push({ type: "exist", message: res });
			});

			this.translateService
			.get("VALIDACIONES.QUESTIONOBLIGATORIA")
			.subscribe((res: string) => {
				this.validationMessages.idSecurityQuestions.push({ type: "required", message: res });
			});

			this.translateService
			.get("VALIDACIONES.ANSWEROBLIGATORIA")
			.subscribe((res: string) => {
				this.validationMessages.securityAnswer.push({ type: "required", message: res });
			});

			this.translateService
			.get("VALIDACIONES.QUESTIONOBLIGATORIA")
			.subscribe((res: string) => {
				this.validationMessages.idSecurityQuestions2.push({ type: "required", message: res });
			});

			this.translateService
			.get("VALIDACIONES.ANSWEROBLIGATORIA")
			.subscribe((res: string) => {
				this.validationMessages.securityAnswer2.push({ type: "required", message: res });
			});

	}

	registerUser() {
		if(this.step2.invalid){
			/** esto es para el log del registro */
			const now = new Date();
			const invalidControls = this.getInvalidControls(this.step2.controls);
			const strLog = now + ' --- Campos inválidos ingresados: ' + invalidControls + '.';
			this.logRegistro.push(strLog);
			/** */
			false;
		} else {
			this.loading = true;
			const fv1 = this.step1.value; //Formulario con el nick y contraseña
		const fv2 = this.step2.value; //Formulario con las preguntas de seguridad

		const userToRegister = new RegisterNickModel(
			fv1.nick,
			fv1.fatherEmail,
			fv1.keyWord,
			this.lang,
			fv2.idSecurityQuestions,
			fv2.securityAnswer,
			fv2.idSecurityQuestions2,
			fv2.securityAnswer2,
			fv1.profileEditor,
			fv1.profileStudent,
			fv1.profileTeacher,
			fv1.profileParent,
		);
		/** esto es para el log del registro */
		const now = new Date();
		const strLog = now + ' --- Ingresó los siguientes datos: '
			+ 'idSecurityQuestions: ' + this.step1.value.idSecurityQuestions + ', '
			+ 'securityAnswer: ' + this.step1.value.securityAnswer + ', '
			+ 'idSecurityQuestions2: ' + this.step1.value.idSecurityQuestions2 + ', '
			+ 'securityAnswer2: ' + this.step1.value.securityAnswer2 + ', '
		this.logRegistro.push(strLog);
		/** */

		this.registerService.nickRegisterUser(userToRegister).subscribe(
			(result) => {
				if (result.error.code === 0) {
					this.ma.event("submit", "sign_up", "Enviar");

					this.toaster.success(
						this.translateService.instant("REGISTRO.OKREGISTER")
					);
					/** esto es para el log del registro */
					const now = new Date();
					const strLog = now + ' --- Usuario registrado CORRECTAMENTE.'
					this.logRegistro.push(strLog);
					this.registerService.registrationErrorNotification(this.logRegistro, true).subscribe(res => {});
					/** */
					//Si todo sale correctamente, logueamos al usuario en la aplicación
					const login: LoginModel = new LoginModel(
						(fv1.nick as string).trim(),
						(fv1.keyWord as string).trim(),
						null
					);

					this.loginService.login(login).subscribe(
						(res) => {
							if (res.data.ok) {
								// Guardo el usuario en el localstorage
								this.loginService.setUserLocalStorage(res.data.userDTO);
								this.loginService.setToken(
									res.data.tokenType,
									res.data.tokenAcces
								);
								this.ma.setUserId(res.data.userDTO.idUser);
								// Y le redirecciono al grafo de entrada
								this.router.navigateByUrl(this.courseInitial); // Go to initial course

								this.ma.event("submit", "login", "Enviar"); //Matomo event
							} else {
								this.toaster.error(
									this.translateService.instant("LOGIN.ERRORALVALIDARSE")
								);
							}
							this.close();
						},
						(err) => {
							this.toaster.error(
								this.translateService.instant("LOGIN.ERRORALVALIDARSE")
							);
						}
					);
				} else {
					//Error al registar al usuario
					this.loading = false;
					this.toaster.error(result.error.msg);
					/** esto es para el log del registro */
					const now = new Date();
					const strLog = now + ' --- ERROR al registrar al usuario. Revisar la api con los datos registrados. Revisar en bbdd que no hayan datos zombie'
					this.logRegistro.push(strLog);
					this.registerService.registrationErrorNotification(this.logRegistro, false).subscribe(res => {});
					/** */
				}
			},
			(err) => {
				this.toaster.error(
					this.translateService.instant("ERROR.HAOCURRIDOUNERROR")
				);
				/** esto es para el log del registro */
				const now = new Date();
				const strLog = now + ' --- ERROR al registrar el usuario. Revisar bbdd, mail o telefono repetido o invalido.'
				this.logRegistro.push(strLog);
				this.registerService.registrationErrorNotification(this.logRegistro, false).subscribe(res => {});
				/** */
			}
		);
		}
	}

	showPrivacyDialog(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();
		this.dialogService.openPrivacyDialog();
	}

	showConditionsDialog(event: MouseEvent) {
		event.preventDefault();
		this.dialogService.openConditionsDialog();
	}
	closeModal(sendData?: any) {
		this.router.navigateByUrl(this.loginRoute);
	}

	onKeyUpCode(ev): void {
		//Cuando sean 6 dígitos, tenemos que enviar el código al servidor para poder registar y validar al usuario
		if (ev.target.value.length === 6) {
			//this.step3.get("code").disable();
			this.registerUser();
		}
	}

	private getCenterInfo(): void {
		if (this.centerService.currentConfig) {
			this.courseInitial = `course/${this.centerService.currentConfig.idCourse}/graph/${this.centerService.currentConfig.idTarget}`;
		} else {
			this.subs = this.centerService.centerConfig.subscribe((data) => {
				this.courseInitial = `course/${data.idCourse}/graph/${data.idTarget}`;
			});
		}
	}

	reset(){
		this.step1.reset()
		this.step2.reset()
		this.load()
		this.currentStep = STEPS.STEP1
	}

	close(): void {
		//this.activeModal.close();
		this.closeAux.emit(true);
	}

	validacionRole(){
		const fv1 = this.step1.value;
		this.validacionNick();
		if((!fv1.profileStudent && !fv1.profileTeacher && !fv1.profileParent) || !this.nickOk){
			this.roleAsignado = false;
		} else {
			this.roleAsignado = true;
		}
	}

	validacionNick(){
		const fv1 = this.step1.value;
		this.registerService
			.nickValidation(fv1.nick)
			.subscribe((res) => {
				if (res.error.msg === "NICK.EXIST") {
					/** esto es para el log del registro */
					const now = new Date();
					const strLog = now + ' --- Ha intentado poner un nick existente.'
					this.logRegistro.push(strLog);
					/** */
					this.toaster.error(
						this.translateService.instant(res.error.msg)
					);
				}
				this.nickOk = res.data;
				if((!fv1.profileStudent && !fv1.profileTeacher && !fv1.profileParent) || !this.nickOk){
					this.roleAsignado = false;
				} else {
					this.roleAsignado = true;
				}
			});
	}

	//AQUI SABEMOS QUE CAMPOS SON INVALIDOS Y LOS METEMOS EN UN STRING
	getInvalidControls(formControls: any) {
		let invalidControls = '';
		const controls = formControls;
		for (const controlName in controls) {
			if (controls.hasOwnProperty(controlName)) {
				if (controls[controlName].invalid) {
					const str = ", " + controlName
					//invalidControls = invalidControls + str;
					invalidControls === '' ? invalidControls = invalidControls + controlName : invalidControls = invalidControls + str;
				}
			}
		}
		return invalidControls;
	}

}
