<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="border-bottom-0">
		<div class="row">
			<div class="col-12 col-md-12">
				<div class="d-flex flex-wrap justify-content-between align-items-center">
					<h2>{{ "COMPETITION.COMPETITION" | translate }}</h2>
					<div class="container_actual-grupo">
						<ng-container *ngIf="group; else notGroup">
							<h5>{{ "COMPETITION.ACTUALGROUP" | translate }}: {{ group.title }}</h5>
						</ng-container>
						<ng-template #notGroup>
							<h5>{{ "COMPETITION.ACTUALGROUP" | translate }}: {{ "COMPETITION.NOGROUP" | translate }}</h5>
						</ng-template>
					</div>
				</div>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body style="overflow: hidden;">
		<div class="row">
			<div class="col-md-12 col-12">
				<nb-card class="no-edge-card">
					<nb-card-header>
						<div class="">
							<h6 class="m-0 text-center">{{ "COMPETITION.CREATEORJOINGAME" | translate }}</h6>

							<div class="create-buttons">
								<button nbButton size="small" class="btn-themecolor" (click)="createRoom()">
									{{ "COMPETITION.CREATEGAME" | translate }}
								</button>
								<div class="flex gap-3">
									<nb-form-field>
										<input [(ngModel)]="hexCode" fieldSize="small" (ngModelChange)="hexCode = hexCode.toUpperCase()" maxlength="6" type="text" id="codigo"
											nbInput fullWidth
											placeholder='{{ "COMPETITION.GAMECODE" | translate }}'>
										<button nbSuffix nbButton size="small" ghost ngbTooltip="{{ 'COMPETITION.CLICKTOPASTE' | translate }}" (click)="pasteCode()">
											<nb-icon icon="file-text-outline"
															 pack="eva"
															 attr.aria-label="pegar código">
											</nb-icon>
										</button>
									</nb-form-field>
									<button nbButton size="small" class="btn-themecolor" (click)="joinGame(hexCode)" [disabled]="hexCodeComplete === false">
										{{ "COMPETITION.INPUTGAMECODE" | translate }}
									</button>
								</div>

							</div>
						</div>
					</nb-card-header>
				</nb-card>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12 col-12">
				<nb-card class="no-edge-card">
					<nb-card-header class="border-bottom-0">
						<!-- <h6 class="m-0 text-center">{{ "COMPETITION.LISTOFGAMES" | translate }}</h6> -->
						<div *ngIf="!isMobile;" class="d-flex flex-column gap-2 mt-3">
							<!-- {{ "COMPETITION.GAMEFILTERS" | translate }} -->

							<div class="col-12 col-md-12 w-100">
								<div class="row d-flex justify-content-between align-items-center gap-3">
									<!-- <div class="d-flex gap-3 flex-wrap flex-md-row">
										<nb-select placeholder="Asignatura" size="small" (selectedChange)="filterGamesListBySubject($event)" class="example-full-width not-border">
											<nb-option value="0">{{ "COMPETITION.ALLSUBJECTS" | translate }}</nb-option>
											<nb-option *ngFor="let item of subjectsList" [value]="item.subject" >{{item.subject}}</nb-option>
										</nb-select>

										<nb-select placeholder="Modo de juego" size="small" (selectedChange)="filterGamesListByGameMode($event)">
											<nb-option value="all">Todos</nb-option>
											<nb-option value="0">1vs1</nb-option>
											<nb-option value="1">{{ "COMPETITION.TEAMS" | translate }}</nb-option>
										</nb-select>

										<button nbButton status="success" size="small" (click)="refreshActiveGames()" [disabled]="this.isLoading == true">
											{{"COMPETITION.REFRESHLIST" | translate }}<nb-icon icon="refresh-outline"></nb-icon>
										</button>
									</div> -->
									<button nbButton size="small" class="btn-themecolor" (click)="competitionResults()">
										{{ "COMPETITION.RESULTS" | translate }}
									</button>
								</div>
							</div>
						</div>
						<div *ngIf="isMobile;">
							<div class="d-flex align-items-center justify-content-between w-100 gap-2 mt-3">
								<button nbButton size="small" status="success" (click)="toggleFilters()">
									<nb-icon icon="funnel-outline"></nb-icon>
								</button>

								<button nbButton size="small" status="success" (click)="refreshActiveGames()" [disabled]="this.isLoading == true">
									<nb-icon icon="refresh-outline"></nb-icon>
								</button>

								<button nbButton size="small" status="success" (click)="competitionResults()">
									<nb-icon icon="archive-outline"></nb-icon>
								</button>
							</div>

							<div class="filtros" [ngClass]="{ 'show-filters': showElement }">
								<div class="item-filtros">
									<nb-select placeholder="Asignatura" size="small" (selectedChange)="filterGamesListBySubject($event)" class="example-full-width not-border">
										<nb-option value="0">{{ "COMPETITION.ALLSUBJECTS" | translate }}</nb-option>
										<nb-option *ngFor="let item of subjectsList" [value]="item.subject" >{{item.subject}}</nb-option>
									</nb-select>

									<nb-select placeholder="Modo de juego" size="small" (selectedChange)="filterGamesListByGameMode($event)">
										<nb-option value="all">Todos</nb-option>
										<nb-option value="0">1vs1</nb-option>
										<nb-option value="1">{{ "COMPETITION.TEAMS" | translate }}</nb-option>
										<!-- <nb-option value="2">{{ "COMPETITION.ALLVALL" | translate }}</nb-option> -->
									</nb-select>
								</div>
							</div>
						</div>
					</nb-card-header>
					<nb-card-body>
						<div class="row d-flex justify-content-center">
							<div class="col-md-12 col-12">
								<div class="loader" *ngIf="isLoading == true"></div>
								<ng-container *ngIf="activeGames.length >= 1; else noGames">
									 <!-- <nb-list *ngIf="activeGames">
										<nb-list-item *ngFor="let activeGame of activeGames" (click)="joinGame(activeGame.hex)"
											class="list-games-element">
											{{ activeGame.nameSession }} / {{activeGame.hex}} - {{ activeGame.onlineUsers }} / {{
											activeGame.maxUsers }} ({{activeGame.gameSubject}})
										</nb-list-item>
									</nb-list> -->
									<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 lobby-list-table">
										<ng-container matColumnDef="name">
											<th mat-header-cell *matHeaderCellDef> Nombre de la partida </th>
											<td mat-cell *matCellDef="let element"> {{element.nameSession}} ({{element.hex}}) </td>
										</ng-container>

										<ng-container matColumnDef="subject">
											<th mat-header-cell *matHeaderCellDef> <nb-select placeholder="Asignatura" size="small" (selectedChange)="filterGamesListBySubject($event)" class="example-full-width not-border">
												<nb-option value="0">{{ "COMPETITION.ALLSUBJECTS" | translate }}</nb-option>
												<nb-option *ngFor="let item of subjectsList" [value]="item.subject" >{{item.subject}}</nb-option>
											</nb-select> </th>
											<td mat-cell *matCellDef="let element"> {{element.gameSubject}} </td>
										</ng-container>

										<ng-container matColumnDef="mode">
											<th mat-header-cell *matHeaderCellDef>
												<nb-select placeholder="Modo de juego" size="small" (selectedChange)="filterGamesListByGameMode($event)">
													<nb-option value="all">Todos</nb-option>
													<nb-option value="0">1vs1</nb-option>
													<nb-option value="1">{{ "COMPETITION.TEAMS" | translate }}</nb-option>
													<!-- <nb-option value="2">{{ "COMPETITION.ALLVALL" | translate }}</nb-option> -->
												</nb-select>
											</th>
											<td mat-cell *matCellDef="let element">
												{{element.gameMode == 0 ? "1vs1" : "Equipos"}}
											</td>
										</ng-container>

										<ng-container matColumnDef="join">
											<th mat-header-cell *matHeaderCellDef>
												<button nbButton status="success" size="small" (click)="refreshActiveGames()" [disabled]="this.isLoading == true">
													<nb-icon icon="refresh-outline"></nb-icon>
												</button>
											</th>
											<td mat-cell *matCellDef="let element">
												<button nbButton status="primary" size="small" (click)="joinGame(element.hex)">
													{{ "COMPETITION.INPUTGAMECODE" | translate }}
												</button>
											</td>
										</ng-container>
										<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
										<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
									</table>
								</ng-container>
							</div>
						</div>

					<ng-template #noGames>
						<div class="d-flex justify-content-center align-items-center height-NOACTIVEGAMES">
							<p>{{ "COMPETITION.NOACTIVEGAMES" | translate }}</p>
						</div>
					</ng-template>
				</nb-card-body>
				</nb-card>
			</div>
		</div>
	</nb-card-body>
</nb-card>
