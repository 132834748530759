import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GruposService, SOCKETMESSAGES } from 'src/app/core/services/groups/grupos.service';
import { LoginService } from 'src/app/core/services/login';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalAsistenciaHistorialComponent } from '../../modal-asistencia-historial/modal-asistencia-historial.component';
import { ModalProfileActionsComponent } from '../modal-profile-actions.component';
import { Subject, Subscription, take, takeUntil } from 'rxjs';
import { ModalStudentWaitingComponent } from '../../grupos/modal-student-waiting/modal-student-waiting.component';
import { SocketService } from 'src/app/core/services/socket/socket-service.service';
import { MastersService } from 'src/app/core/services/masters';
import { LocalStorage } from 'src/app/core/utils';

@Component({
  selector: 'app-modal-alert-asistencia',
  templateUrl: './modal-alert-asistencia.component.html',
  styleUrls: ['./modal-alert-asistencia.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ModalAlertAsistenciaComponent implements OnInit  {
	idGrupo: number = 0;
	group: any;
	fechaLlamada: Date;
	private destroy$ = new Subject();
	private subscriptions: Subscription[] = [];
	showActInst = false;
	disableActivityButton: boolean = false;

	presente: boolean = true;
	timeLeft: number = 0;
	timeLeftString: string = '';

	constructor(
		public activeModal: NgbActiveModal,
		private groupService: GruposService,
		private toaster: ToasterService,
		public translateService: TranslateService,
		public loginService: LoginService,
		private modalService: NgbModal,
		private socketService: SocketService,
		private mastersService: MastersService,
		private localStorage: LocalStorage,
		private masterService: MastersService,
	){}

	ngOnInit() {
		//si somos estudiantes recibimos el llamado de asistencia, si somos profe revisamos si se ha llamado hace poco
		if(this.loginService.esEstudiante()){
			this.isPresente(this.fechaLlamada);
		} else{
			this.revisarAsistenciaPrevia()
		}
		//definimos variable para tutoriales
		if(this.loginService.esProfesor()){
			this.localStorage.setItem("idTutorialContext", 8);
			if(this.localStorage.getItem("TutorialesON") == "true"){
				this.masterService.openModalTutorial(8);
			}
		}
	}

	closeModal(sendData?: any) {
    this.activeModal.close(false);
  }

	isPresente(fecha: Date){
		// Define la fecha dada
		let givenDate: Date = new Date(fecha);
		// Obtén la fecha y hora actual
		let currentDate: Date = new Date();
		// Obtén los tiempos en milisegundos
		let timeGiven: number = givenDate.getTime();
		let timeCurrent: number = currentDate.getTime();
		// Calcula la diferencia en milisegundos
		let differenceInMilliseconds: number = timeCurrent - timeGiven;
		// Convierte la diferencia a minutos
		let differenceInMinutes: number = differenceInMilliseconds / (1000 * 60);
		this.presente = differenceInMinutes < 5.0 ? true : false;
	}

	revisarAsistenciaPrevia() {
    this.groupService
			.receiveCallAttendance(this.idGrupo, 3)
			.subscribe((resp) => {
				if (resp.data != null) {
					// Define la fecha dada
					let givenDate: Date = new Date(resp.data[0].fecha);
					// Obtén la fecha y hora actual
					let currentDate: Date = new Date();
					// Obtén los tiempos en milisegundos
					let timeGiven: number = givenDate.getTime();
					let timeCurrent: number = currentDate.getTime();
          // Calcula los 45 minutos en milisegundos
          let totalTime: number = 45 * 60 * 1000;
          // Calcula el tiempo restante en milisegundos
          let timeLeftInMilliseconds: number = totalTime - (timeCurrent - timeGiven);
					// Convierte la diferencia a minutos y redondea al siguiente entero
					this.timeLeft = Math.ceil(timeLeftInMilliseconds / (1000 * 60));
					this.timeLeftString = this.translateService.instant("ASISTENCIA.TIMELEFT") + this.timeLeft + 'min';
				}
			});
	}

	pasarLista(){
		if(this.timeLeft <= 0){
			this.groupService.createAttendance(this.idGrupo).subscribe(
				resp => {
					if(resp != null){
						//Creamos el log
						const bodyLog1 = {
							action: "asistencia",
							actionData: "Ha llamado a la Asistencia en el Grupo con id: " + this.idGrupo,
							startDate: new Date(),
							endingDate: null,
							role: this.loginService.getProfile(),
							idGroup: this.idGrupo,
						};
						this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
						this.toaster.success(this.translateService.instant("ASISTENCIA.ATTENDANCECALLOK"));
						this.closeModal();
					}
				}
			)
		}
	}

	verHistorial(){
		this.groupService.classAttendanceGroup(this.idGrupo, 0).subscribe(
			resp => {
				if(resp.data != null){
					this.closeModal();
					//Creamos el log
					const bodyLog1 = {
						action: "asistencia",
						actionData: "Ha entrado en el Historial de la Asistencia del Grupo con id: " + this.idGrupo,
						startDate: new Date(),
						endingDate: null,
						role: this.loginService.getProfile(),
						idGroup: this.idGrupo,
					};
					this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
					const modalRef = this.modalService.open(ModalAsistenciaHistorialComponent, {
						windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
					});
					modalRef.componentInstance.tablaCompleta = resp.data;
					modalRef.componentInstance.idGrupo = this.idGrupo;

					modalRef.result.then((result) => {
					});
				}
			}
		)
	}

	responderAsistencia(status: number){
		this.groupService.reponseAttendance(this.idGrupo, status).subscribe(
			resp => {
				if(resp != null){
					this.toaster.success(this.translateService.instant("ASISTENCIA.ATTENDANCEREPONSEOK"));
					this.closeModal();
					if(status == 3 || status == 4){
						//this.modalStudentWaiting(); //se comenta porque se ha deprecado la funcion de "ESTOY EN CLASE"
						this.activeModal.close("closeAll");
					}
				}
			}
		)
	}

	modalStudentWaiting(): void {
		this.groupService.getIfSessionExists(this.idGrupo)
		.pipe(takeUntil(this.destroy$), take(1))
		.subscribe((result) => {
			//this.cargando = false;
			this.showActInst = result.data.existSesion;
			//Opción para crear actividades instantáneas. Si es un profesor, se debe crear; si es un estudiante, tiene que poder entrar para conectarse con websocket
			//Si es estudiante, tengo que sacar el modal para esperar los movimientos del websocket
			let modal: NgbModalRef;
			const idGroup = this.idGrupo;
			const msgSubs = this.socketService.msgFromServer
				.pipe(takeUntil(this.destroy$))
				.subscribe((data) => {
					//Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
					const dataArray = data.split(":");
					const type = dataArray[0];
					const onlineUsers: number = parseInt(dataArray[1]);
					switch (type) {
						case SOCKETMESSAGES.USERSCOUNTER:
							if (this.loginService.esEstudiante()) {
								//Si es estudiante, debo abrir el modal de espera para que se vayan abriendo los quizzes según decida el profesor
								//this.cargando = false;
								this.showActInst = result.data.existSesion;
								this.groupService
									.getIfSessionExistsByUser(this.idGrupo)
									.pipe(takeUntil(this.destroy$), take(1))
									.subscribe((res) => {
										if (!res.data) {
											if (!modal) {
												modal = this.modalService.open(
													ModalStudentWaitingComponent,
													{
														scrollable: false,
														windowClass: `${MODAL_DIALOG_TYPES.W100} h-100`,
													}
												);
												modal.componentInstance.idGroup =
													this.idGrupo;
												modal.componentInstance.onlineUsers = onlineUsers;

												this.socketService.setcompetitionStarted(true);
												modal.result
													.then(
														() => {
															this.disableActivityButton = false;
															this.socketService.setcompetitionStarted(
																false
															);
															this.destroy$.next(true);
															this._removeSocket(idGroup);
															//this.initSocketToListenFroInstantActivity();
														},
														(err) => {}
													)
													.finally(() => {});
											}
										}
									});
							}
							break;
					}
				});
			this.subscriptions.push(msgSubs);
			this.socketService.createSocket(idGroup); //Creo el socket
			const openSocketSub = this.socketService.openSocket.subscribe(
				(ev) => {
					//Cuando se cree el socket, procedemos a guardar la sesion en el servidor
					this.groupService.createGroupSession(idGroup).subscribe(
						(result) => {
							//Si se guarda correctamente la sesion, tenemos que abrir el modal con los cursos en modo lista
							this.socketService.idSession = result.data.idSession;
							openSocketSub.unsubscribe();
						},
						(err) => {
							//modal.close()
						}
					);
				}
			);
			this.subscriptions.push(openSocketSub);
		});
	}

	private _removeSocket(idGroup) {
		this.socketService.removeSocket(); //Si ha habido error al guardar la sesión, tenemos que destruir el socket y cerrar el modal
		this.groupService.deleteGroupSession(idGroup).subscribe(); //Elimino la sesión de la bd
		this.subscriptions.forEach((s) => s.unsubscribe()); //Elimino las suscripciones a eventos
	}

}
