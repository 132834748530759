<div class="d-flex flex-column justify-content-center align-items-center">
    <div class="image-container">
        <mat-icon svgIcon="account_circle" class="no-image" *ngIf="!image"></mat-icon>
        <div *ngIf="image" class="circle-image" [style.background-image]="'url(' + imageProfile + ')'"></div>
        <mat-progress-bar class="mt-2" *ngIf="progress" value={{progress}}></mat-progress-bar>
    </div>
    <div class="d-flex flex-column align-items-center icons mt-3" style="gap: 5px;">
        <span>{{'IMAGEPROFILE.IMAGE' | translate}}</span>
        <div class="d-flex">
					<div class="edit-icon d-flex">
            <input class="pointer" type="file" accept="image/*" (change)="onFileSelected($event)" />
            <mat-icon class="pointer" svgIcon="edit" *ngIf="userData.mail != '' || userData.mobile != ''"></mat-icon>
					</div>
					<mat-icon class="pointer delete-img"
        		*ngIf="image"
          	(click)="deleteProfileImage()">delete
					</mat-icon>
				</div>
    </div>

</div>
