<div class="fondoProfesor" [ngClass]="userService.getColorByRol()" id="explorerContent">


    <div class="sticky-top sticky-top-header">
        <div class="modal-header mr-4 ml-4 mt-4" align="center">
            <div>
                <span class="textoFontSize1 colorTextoActivadoCursoGrafo font-weight-bold"></span>
            </div>
            <div>
                <span class="textoFontSize1 colorTextoActivadoCursoGrafo font-weight-bold">{{ 'INSTANTACTIVITIES.TITLE' | translate }}</span>
            </div>
            <div>
                <button type="button" class="close" data-dismiss="modal" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>



    <div class="modal-body">


    <div class="row flex align-items-start ">


        <div class="col-md-7 col-sm-12 mt-4 mt-sm-0">

              <div class="mat-elevation-z8">

                <div class="col-12" >
                    <div class="d-flex flex-column">
                        <label>{{ 'INSTANTACTIVITIES.FILTERLABEL' | translate }}</label>
                        <input nbInput class="form-control" fullWidth (keyup)="applyFilter($event)"
													placeholder="{{ 'INSTANTACTIVITIES.FILTERPLACEHOLDER' | translate }}" #input>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>

                    <!-- Name Column -->
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef>{{ 'INSTANTACTIVITIES.COURSETITLE' | translate }}</th>
                        <td mat-cell *matCellDef="let element"> {{element.title | titlecase | truncate : 20}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="idCountry">
                        <th mat-header-cell *matHeaderCellDef>{{ 'INSTANTACTIVITIES.COUNTRY' | translate }}</th>
                        <td mat-cell *matCellDef="let element" > {{element.idCountry}} </td>
                    </ng-container>
                    <!-- Name Column -->
                    <ng-container matColumnDef="autor">
                        <th mat-header-cell *matHeaderCellDef>{{ 'INSTANTACTIVITIES.AUTHOR' | translate }}</th>
                        <td mat-cell *matCellDef="let element"> {{ element.user }} </td>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail" >
                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" >
                        <div class="example-element-detail"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="w-100">
                                <div class="row flex align-items-center justify-content-center">
                                    <div class="col-6 col-sm-3" *ngFor="let ideografos of element.listIdeoGrafos; let i = index">
                                        <div class="card form-group" >
                                            <div class="img-container"  placement="top">
                                                <img [src]="ideografos.target.backgroundImage | imagen: 'expContenido' " class="card-img-top" placement="auto" ngbTooltip="{{ideografos.target.description}}" (error)="updateUrl($event)">
                                            </div>
                                            <div class="card-body">
                                                <p class="card-text">{{ideografos.target.tittle  | titlecase | truncate:[30, '...']}}</p>
                                                <a  (click)="verQuizes(ideografos, element)" class="badge badge-info pointer  flex align-items-center justify-content-center">
                                                    <mat-icon svgIcon="visibility"></mat-icon>
                                                    <span>{{ 'INSTANTACTIVITIES.SHOWQUIZZES' | translate }}</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                        class="example-element-row"
                        [class.example-expanded-row]="expandedElement === element"
                        (click)="expandedElement = expandedElement === element ? null : element">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    <tr class="mat-row" *matNoDataRow></tr>
                </table>
                <mat-paginator [length]="resultsLength" [pageSize]="5"></mat-paginator>
              </div>



        </div>
        <div class="col-md-5 col-sm-12">
            <div class="row">
                <div class="col-12">
                    <app-quizes-ideografo [ideoGrafoSelected]="ideoGrafoSelected" [cursoSelected]="cursoSelected"></app-quizes-ideografo>
                </div>

            </div>
           <div *ngIf="ideoGrafoSelected == null" class="pt-4 pt-sm-0">{{ 'INSTANTACTIVITIES.NOTGRAPHSELECTED' | translate }}</div>
        </div>

    </div>

    </div>


</div>
