import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, ViewEncapsulation, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { timer } from 'rxjs';
import { SpeechService } from 'src/app/core/services/speech-recognition/speech-recognition.service';
import { Stream } from 'stream';


@Component({
	selector: "audio-component",
	templateUrl: "./audio.component.html",
	styleUrls: ["./audio.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class AudioComponent implements OnInit {
	chunks = [];
	audioFiles = [];

	@Input() viewElement: boolean = false;
	@Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

	_second = 1000;
	_minute = this._second * 60;
	_hour = this._minute * 60;
	_day = this._hour * 24;
	end: any;
	now: any;
	day: any;
	hours: any;
	minutes: any | 0 = "00";
	seconds: any | 0 = "00";
	source = timer(0, 1000);
	clock: any;
	public grabando: boolean = true;
	files: File;

	Stop:string = "../../../../../../../assets/images/stop.gif"

	@Output() emitAudio: EventEmitter<File> = new EventEmitter<File>();
	@Output() sendTextofAudio: EventEmitter<string> = new EventEmitter<string>();

	mediaDevice: MediaDevices;
	public config: MediaRecorderOptions = { mimeType: "audio/webm" };
	public mediaStream: MediaStream = null;
	public mediaRecorder: MediaRecorder;
	public audio: MediaRecorder;
	public navigator: Navigator;
	public isUserSpeaking: boolean = false;
	constructor(
		private cd: ChangeDetectorRef,
		private dom: DomSanitizer,
		public speech: SpeechService
	) {}

	startService(): void {
		this.speech.text = "";
		this.speech.start();
		this.speech.error = false;
	}

	ngOnInit(): void {
		// this.mediaRecorder = new MediaRecorder( this.globalStream, this.config);
		// this.startRecording();+
	}
	ngAfterViewInit() {
		this.speech.init();
		this.now = new Date();
		this.clock = this.source.subscribe((t) => {
			this.end = new Date();
			this.showDate();
		});
		this.grabando = true;
		this.permisoMicro();
	}

	sendTextofAudioMetod() {
		this.speech._backEnabled.subscribe((res) => {
			if (res == true) {
				this.sendTextofAudio.emit(this.speech.text);
			}
		});
	}

	permisoMicro = () => {
		this.startService();
		navigator.mediaDevices
			.getUserMedia({ audio: true, video: false })
			.then((stream) => {
				this.mediaRecorder = new MediaRecorder(stream);
				this.mediaStream = stream;
				this.mediaRecorder.onstop = (e) => {
					var blob = new Blob(this.chunks, { type: "audio/mp3; codecs=opus" });
					const id = Math.floor(Math.random() * 10000);

					this.files = new File(this.chunks, `${id}-wav.mp3`, {
						type: "audio/mp3",
					});
					var audioURL = URL.createObjectURL(blob);
					this.emitAudio.emit(this.files);
					this.sendTextofAudioMetod();
					this.audioFiles.push(this.dom.bypassSecurityTrustUrl(audioURL));
					this.cd.detectChanges();
					stream.getTracks().forEach(function (track) {
						track.stop();
					});
				};
				this.mediaRecorder.ondataavailable = (e) => {
					this.chunks = [];
					this.chunks.push(e.data);
				};
				this.mediaRecorder.start();
			});
	};

	showDate = () => {
		let distance = this.end - this.now;
		this.day = Math.floor(distance / this._day);
		this.hours = Math.floor((distance % this._day) / this._hour);
		this.minutes = Math.floor((distance % this._hour) / this._minute);

		this.seconds = Math.floor((distance % this._minute) / this._second);
		if (parseInt(this.minutes) < 10) {
			this.minutes = "0" + this.minutes;
		}
		if (parseInt(this.seconds) < 10) {
			this.seconds = "0" + this.seconds;
		}
	};

	stopRecording = () => {
		this.end = new Date();
		this.clock.unsubscribe();
		this.speech.stop();
		this.mediaRecorder.stop();
		setTimeout(() => {
			this.grabando = false;
		}, 500);
	};

	deleteAll = () => {
		this.clock.unsubscribe();
		this.audioFiles.pop();
		this.grabando = false;
		this.viewElement = false;
		this.close.emit(this.viewElement);
	};

}
