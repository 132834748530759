<div class="modal-footer fondoEditarCurso">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <!--app-loading [isVisible]="cargando"></app-loading-->
        <div class="row" id="quizzes">
            <div *ngIf="previus" class="page-link pointer"
                style="background-color: rgb(159, 187, 241);color: rgb(1, 6, 15, 0.5)" aria-label="Previous"
                [ngStyle]="{'width':getWidthPrevius()}" (click)="toPrevius()">
                <mat-icon class="mt-2">navigate_before</mat-icon>
            </div>
            <!--div *ngFor="let quiz of quizzesBloque" class="page-link pointer"
                [ngStyle]="{'background-color':getColor(quiz),'width':getWidth()}"
                (click)="mostrarPregunta(quiz.idQuiz)"></div-->
            <div *ngFor="let quiz of quizzesBloque" class="page-link pointer"
                [ngStyle]="{'background-color':quiz.color,'width': quiz.width}" (click)="showQuiz(quiz.idQuiz)">
            </div>
            <div *ngIf="next" class="page-link pointer"
                style="background-color: rgb(159, 187, 241) ;color: rgb(1, 6, 15, 0.5)" aria-label="Next"
                [ngStyle]="{'width':getWidthNext()}" (click)="toNext()">
                <mat-icon class="mt-2">navigate_next</mat-icon>
            </div>
        </div>
    </div>
</div>
