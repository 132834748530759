export class ChangePasswordModel {
    idUser: number;
    token: string;
    keyWord: string;

    constructor(idUser: number, token: string, keyWord: string) {
        this.idUser = idUser;
        this.token = token;
        this.keyWord = keyWord;
    }
}
