	<!--===== MODO VISUALIZACION =====-->
	<!--==============================-->
	<nb-card class="container-question pointer mb-0">
		<nb-card-header class="border-bottom-0">
			<div class="row">
				<div class="col-12 modal-title">
					<h6>{{ question.text }}</h6>
				</div>
			</div>
		</nb-card-header>
		<!--========== PREGUNTA TIPO 1 - ESCALA LIKERT ==========-->
		<nb-card-body class="d-flex justify-content-center align-items-center" *ngIf="question.type == 1">
			<div class="d-flex gap-3 flex-md-row flex-column align-items-center justify-content-center w-100">
				{{ question.initialTag }}
				<div class="d-flex">
					<nb-radio-group [(ngModel)]="selectedOptionLikert" (ngModelChange)="emitSelectedResponse()" class="d-flex gap-3 flex-md-row flex-column"
					[name]="question.idSurveyQuestion">
						<nb-radio *ngFor="let option of likertOptions" [value]="option">
							{{ option }}
						</nb-radio>
					</nb-radio-group>
				</div>
				{{ question.finalTag }}
			</div>
		</nb-card-body>
		<!--========== PREGUNTA TIPO 2 - SELECCION UNICA ==========-->
		<nb-card-body class="d-flex align-items-center" *ngIf="question.type == 2">
			<div class="d-flex">
				<nb-radio-group [(ngModel)]="selectedOptionSingle" (ngModelChange)="emitSelectedResponse()"
				[name]="question.idSurveyQuestion">
					<nb-radio
						*ngFor="let option of question.options"
						[value]="option.idSurveyQuestionOption">
						{{ option.text }}
					</nb-radio>
				</nb-radio-group>
			</div>
		</nb-card-body>
		<!--========== PREGUNTA TIPO 3 - SELECCION MULTIPLE ==========-->
		<nb-card-body class="d-flex align-items-center" *ngIf="question.type == 3"
		[name]="question.idSurveyQuestion">
			<div class="d-flex flex-column gap-3">
				<div *ngFor="let option of question.options" class="d-flex">
					<nb-checkbox status="basic" [(ngModel)]="option.selected" (change)="emitSelectedResponse()">
						{{ option.text }}
					</nb-checkbox>
				</div>
			</div>
		</nb-card-body>
		<!--========== PREGUNTA TIPO 4 - RESPUESTA ABIERTA ==========-->
		<nb-card-body class="d-flex justify-content-center align-items-center" *ngIf="question.type == 4"
		[name]="question.idSurveyQuestion">
			<div class="d-flex">
				<textarea nbInput fullWidth rows="4" cols="50"
					[(ngModel)]="openResponse" (ngModelChange)="onOpenResponseChange($event)" class="form-control">
				</textarea>
			</div>
		</nb-card-body>
		<!--========== OPCION QUE INDICA SI LA PREGUNTA ES OBLIGATORIA ==========-->
		<nb-card-footer class="border-top-0" *ngIf="question.required == 1">
			<span class="error-message">
				*{{'ENCUESTAS.QUESTIONMANDATORY' | translate}}
			</span>
		</nb-card-footer>
	</nb-card>
