import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  constructor() { }

	private startTime: number;

  startTimer(): void {
    this.startTime = Date.now();
  }

  stopTimer(): number {
    if (this.startTime) {
      const endTime = Date.now();
      const elapsedTime = endTime - this.startTime;
      this.startTime = null;
      return elapsedTime;
    }
    return 0;
  }

}
