import { MatomoTracker } from "ngx-matomo";
import { Injectable } from "@angular/core";

@Injectable()
export class MatomoAnalyticsUtils {
	constructor(private matomoTracker: MatomoTracker) {}

	event(action: string, category: string, label: string): void {
		this.matomoTracker.trackEvent(category, action, label);
	}

	setUserId(id: number) {
		this.matomoTracker.setUserId(id.toString());
	}
}
