import { environment } from "./../environments/environment";
import { LocalStorage } from "./core/utils/local-storage";
import { Utils } from "./core/utils/utils";
import { CenterService } from "./core/services/center/center.service";
import { AfterViewInit, Component } from "@angular/core";
import { RegisterIconsService } from "src/app/core/services/shared/register-icons.service";
import { GetDataService } from "./core/services/get-data/get-data.service";
import { LangUtils } from "./core/utils";
import { LOCALSTORAGESTRINGS } from "./core/models/masters/localstorage.enum";
import { NavigationEnd, Router } from "@angular/router";
import { delay, filter, skip, take, takeUntil } from "rxjs/operators";
import { MatomoModule  } from "ngx-matomo";
import { LoginService } from "./core/services/login";
import { ToasterService } from "./core/services/shared/toaster.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { UsersService } from "./core/services/users";
import { Store } from "@ngrx/store";
import { State } from "./store/models/store.model";
import { loadProfiles } from "./store/actions/profiles.action";
import { availableProfiles } from "./store/models/profiles.model";
import { selectProfile } from "./store/actions/selected-profile.action";
import { InactivityService } from "./core/services/inactivity/inactivity.service";
import { interval } from 'rxjs';

interface NavigatorWithConnection extends Navigator {
  connection?: {
    downlink: number;
  };
}
const matomoIdLocalhost = 3;

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterViewInit {
	orderedProfilesArray: any[];
	userSet = false;
	slowConection = false;
	networkSpeed = 1;

	constructor(
		private iconService: RegisterIconsService,
		private dataService: GetDataService,
		private lang: LangUtils,
		private centerService: CenterService,
		private utils: Utils,
		private localStorage: LocalStorage,
		private router: Router,
		private loginService: LoginService,
		private toaster: ToasterService,
		private modalService: NgbModal,
		private translateService: TranslateService,
		public usersService: UsersService,
		private store: Store<State>,
		private inactivityService: InactivityService,
	) {
		//this.inactivityService.startInactivityTimer();
	}

	ngOnInit() {
		localStorage.removeItem("noConflict"); //con esto evitamos el error de jquery en las tablas de informes
		this.iconService.registerIcons();
		this.initApp();
		//se evalua directamente ya que puede variar la conexion
		interval(60000).subscribe(() => {
			this.networkSpeed = this.getNetworkSpeed();
      this.slowConection = this.networkSpeed < 1 ? true : false;
			this.localStorage.setItem("conectionVelocity", this.networkSpeed);
    });
	}

	getNetworkSpeed() {
		const navigatorWithConnection = navigator as NavigatorWithConnection;
		if (navigatorWithConnection.connection) {
			return navigatorWithConnection.connection.downlink;
		} else {
			return 10; // No se pudo obtener la velocidad de conexión
		}
  	}

	ngAfterViewInit() {
		// this.matomoInjector.init({
		// 	url: environment.matomoUrl,
		// 	id: environment.idMatomo,
		// });

		let referrer: string = window.location.href;

		this.router.events
			.pipe(
				filter((e) => e instanceof NavigationEnd),
				skip(1),
				delay(0)
			)
			// .subscribe((next) => {
			// 	this.matomoInjector.onPageChange({ referrer });
			// 	referrer = window.location.href;
			// });
	}

	private setInitialSelectedUser(orderedArray){
		var BreakException = {};
		if (!this.userSet) {
			try {
				orderedArray.forEach((element) => {
						if (element.idProfile == 2) {
							this.store.dispatch(new selectProfile({ selectedProfile: 'ESTUDIANTE' }));
							this.userSet = true
							throw BreakException;
						}
						if (element.idProfile == 3) {
							this.store.dispatch(new selectProfile({ selectedProfile: 'PROFESOR' }));
							this.userSet = true
							throw BreakException;
						}
						if (element.idProfile == 4) {
							this.store.dispatch(new selectProfile({ selectedProfile: 'PADRE' }));
							this.userSet = true
							throw BreakException;
						}
						if (element.idProfile == 1) {
							this.store.dispatch(new selectProfile({ selectedProfile: 'AUTOR' }));
							this.userSet = true
							throw BreakException;
						}
						if (element.idProfile == 5) {
							this.store.dispatch(new selectProfile({ selectedProfile: 'ADMIN' }));
							this.userSet = true
							throw BreakException;
						}
						if (element.idProfile == 6) {
							this.store.dispatch(
								new selectProfile({ selectedProfile: "CENTER" })
							);
							this.userSet = true;
							throw BreakException;
						}
					})
				} catch (e) {
					if (e !== BreakException) throw e;
				}
		}
	}

	private initApp() {
		// if (this.localStorage.getItem(LOCALSTORAGESTRINGS.LOADINITIALGRAPH) == null)
		if (JSON.parse(this.localStorage.getItem("loadInitialGraph")) == null) {
			this.localStorage.setItem("loadInitialGraph", JSON.stringify(false));
		}

		this.dataService.getAllLanguages().subscribe((data) => {
			const availableLangs = data.map((e) => e.idLanguageIso.toLowerCase());
			this.lang.setAppLang(availableLangs);
			this.dataService.appLanguages = data;
		});
		this.usersService.profilesList$.subscribe((userList) => {
			let profilesOfUSer: availableProfiles = {
				profileStudent: false,
				profileEditor: false,
				profileTeacher: false,
				profileParent: false,
				profileAdmin: false,
				profileCenter: false,
			}
			this.orderedProfilesArray = [];
				userList['data'].forEach(element => {
					if (element.idProfile == 2) {
						this.orderedProfilesArray[0] = element;
						profilesOfUSer.profileStudent = true;
					}
					if (element.idProfile == 3) {
						this.orderedProfilesArray[1] = element;
						profilesOfUSer.profileTeacher = true;
					}
					if (element.idProfile == 4) {
						this.orderedProfilesArray[2] = element;
						profilesOfUSer.profileParent = true;
					}
					if (element.idProfile == 1) {
						this.orderedProfilesArray[3] = element;
						profilesOfUSer.profileEditor = true;
					}
					if (element.idProfile == 5) {
						this.orderedProfilesArray[4] = element;
						profilesOfUSer.profileAdmin = true;
					}
					if (element.idProfile == 6) {
						this.orderedProfilesArray[5] = element;
						profilesOfUSer.profileCenter = true;
					}
				});

			this.setInitialSelectedUser(this.orderedProfilesArray)
			this.store.dispatch(new loadProfiles(profilesOfUSer));
		 });

		// this.usersService.getListUserProfile().subscribe((res: any) => {
		// 	this.usersService._listUserProfile = res.data

		// });

		this.dataService.getAllSubjects();
		this.dataService.getAllProjectsTypes();
		this.dataService.getAllCountries();
		// this.dataService
		// 	.getAllCenters()
		// 	.subscribe((res) => (this.dataService.appCenters = res));
		this.dataService.getAllLevels();
		this.dataService.getAllCommunities();
		this.utils.getPadsLabelsStatus();
		this.centerService.getCenterConfigByDomain();
		if (this.localStorage.getItem(LOCALSTORAGESTRINGS.GETACTIVITY))
			this.localStorage.removeItem(LOCALSTORAGESTRINGS.GETACTIVITY); //Si se queda esta variable 'pillada', hay que eliminarla para que nos deje interactuar con los botones en el grafo

		//Está escuchando el estado de la conexión; si está offline, mostramos mensaje al usuario y lo dejamos fuera de la sesión.
		this.utils.checkOnline$().subscribe(isOnline => {
			if(!isOnline)
			{
				this.modalService.dismissAll()
				this.loginService.logout(true)
				setTimeout(() => {
					this.toaster.info(this.translateService.instant('GLOBALS.CONECCTIONKO'))
				}, 500);

			}
		});
	}

}
