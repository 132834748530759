import { Component, OnInit } from '@angular/core';
import { finalize, take, takeUntil } from 'rxjs/operators';

import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { State } from 'src/app/store/models/store.model';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';

// Services
import { TranslateService } from '@ngx-translate/core';
import { IdiomaModel } from 'src/app/core/models/masters';
import { MastersService } from 'src/app/core/services/masters';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LocalStorage } from 'src/app/core/utils';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { DateAdapter } from '@angular/material/core';
import { NotificationService } from 'src/app/core/services/notification';

@Component({
  selector: 'app-modal-aceptar-cancelar',
  templateUrl: './modal-aceptar-cancelar.component.html',
  styleUrls: ['./modal-aceptar-cancelar.component.scss']
})
export class ModalAceptarCancelarComponent implements OnInit{

  	mensaje: string;
	optionalTitle: string;
	disableCancel: boolean;
	groupSelected: boolean = true;
	fromInforme: boolean = false;
	profile: any;
	traducirGrafo: boolean;
	form: UntypedFormGroup;
	lang: string;
	validationMessages = {
		lang: [],
	};
	notificationSound: boolean = false;

	private destroy$ = new Subject();
	languages$: Observable<IdiomaModel[]>;

	constructor(
			public translateService: TranslateService,
			private activeModal: NgbActiveModal,
			private store: Store<State>,
			private mastersService: MastersService,
			private formBuild: UntypedFormBuilder,
			private localStorage: LocalStorage,
			private dateAdapter: DateAdapter<Date>,
			private notificationService: NotificationService,
		) {
	}

	ngOnInit() {
		if(this.traducirGrafo){
			this.form = this.formBuild.group({
				lang: ["", Validators.required],
			})
			this.languages$ = this.mastersService.getAllLanguages();
			this.traducirOpciones();
		}
		this.store.select(store => store.selectedProfile).pipe(takeUntil(this.destroy$)).subscribe((selectedProfile) => {
			this.profile = selectedProfile['selectedProfile'];
		})
		// Reproducir el sonido de notificación
		if(this.notificationSound){
			// Solicitar permiso para mostrar notificaciones
			if (Notification.permission !== 'granted') {
				Notification.requestPermission();
			}
			this.notificationService.playNotificationSound();
		}		
	}

  aceptar() {
		if(this.traducirGrafo && this.form.valid){
			this.activeModal.close(this.form.value.lang);
		} else if(this.traducirGrafo && this.form.invalid){
			return
		}
    this.activeModal.close(true);
  }

  cancelar() {
    this.activeModal.close(false);
  }

  closeModal(sendData?: any) {
    this.activeModal.close(false);
  }

	sinGrupo(){
		this.activeModal.close("sinGrupo");
	}

	traducirOpciones() {
		// Recupero el lenguaje
		this.lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
		this.translateService.use(this.lang);
		this.dateAdapter.setLocale(this.lang);

		this.translateService
			.get("VALIDACIONES.LANG1REQUIRED")
			.subscribe((res: string) => {
				this.validationMessages.lang.push({ type: "required", message: res });
			});
	}

	get langControl(): UntypedFormControl {
		if (this.form) {
			return this.form.get("lang") as UntypedFormControl;
		} else {
			return null;
		}
	}

}
