export interface DataHistoryInterface {
    position: number;
    identificador: string;
    titulo: string;
    resultado: number;
  }
  
  export class DataHistoryClass {
    identificador: string;
    titulo: string;
    resultado: number | string;
    escrito?: number | string = undefined;
  }
  
  
  