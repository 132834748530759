import { Component, Input, OnDestroy, OnInit } from '@angular/core';

declare var YT;

@Component({
	selector: 'app-youtube-player',
	templateUrl: './youtube-player.component.html',
	styleUrls: ['./youtube-player.component.scss']
})
export class YoutubePlayerComponent implements OnInit, OnDestroy {

	@Input() videoId:string = ''
	@Input() startSeconds:number = null
	@Input() endSeconds:number = null
	@Input() height:any = 550

	player: any;

	constructor() {}

	ngOnInit() {
		if(this.videoId)
			this.player = new YT.Player('youtubePlayer', {
				height: this.height,
				width: '100%',
				videoId: '',
				events: {
						'onReady': (event) => { this.onPlayerReady(event); }
					}
			})
	}

	onPlayerReady(event){
		event.target.loadVideoById({videoId: this.videoId, 'startSeconds': this.startSeconds, 'endSeconds': this.endSeconds}) 
	}

	ngOnDestroy(): void {}
}
