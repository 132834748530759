
import { ActionTypes, ActionsUnion } from '../actions/graph-data.action';
import { initialGraphData } from '../models/graph-data.model';

export function graphDataReducer(
	state = initialGraphData,
	action: ActionsUnion
) {
	switch (action.type) {
		case ActionTypes.loadGraphData:
			return action.payload;

		default:
			return state;
	}
}
