<div class="actividades-container" [ngClass]="userService.getColorByRol()">
    <div class="sticky-top sticky-top-header">
        <div class="modal-header" align="center">
            <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{'SEARCHACTIVITIES.DO' | translate}}</span>
            <button type="button" class="close" data-dismiss="modal" (click)="closeModal('')">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
    </div>

    <div class="container">

        <div class="row flex justify-content-center align-items-center mt-4">
            <div class="col-12 input-group-btn mt-2 mb-2">
                <div class="container">
                    <div class="row flex justify-content-center align-items-center">
                        <div class="col-md-4 col-sm-6">
                            <label class="label-themecolor">{{'SEARCHACTIVITIES.DO' | translate}}</label>
                            <input type="text"
                            (keyup)="limpiarNumero(identificadorCurso)"
                            (keyup.enter)="buscarActividad()"
                            [(ngModel)]="identificadorCurso"  class="form-control inputGeneric inputBuscar">
                        </div>
                    </div>

                </div>


            </div>
            <div class="col-12 input-group-btn mt-2 mb-2">
                <div class="container">
                    <div class="row flex justify-content-center align-items-center">
                        <div class="col-md-4 col-sm-6">
                            <button (click)="buscarActividad()"  class="btn btn-block btn-themecolor btn-sm pointer flex justify-content-center align-items-center"
                                [disabled]="identificadorCurso.length > 0? false: true"
                            >
                                <mat-icon>search</mat-icon>
                                <span class="text-black">{{'SEARCHACTIVITIES.SEARCH' | translate}}</span>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
    </div>


    </div>
    <div class="container">
        <div class="row flex justify-content-center align-items-start mt-4">
            <div class="col-md-8 col-sm-12 ml-2">
                <app-datos-resultado-quizes style="border-radius: 4px !important ;"></app-datos-resultado-quizes>
            </div>
        </div>
    </div>
</div>
