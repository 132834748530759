<form class="container" [formGroup]="dataForm">
    <ng-container formArrayName="questions">
        <div class="row" *ngFor="let question of questionsArray.controls; let i = index; trackBy: trackBy;">
            <div class="col-12 d-flex align-items-center" [formGroupName]="i">
                <ng-container [ngSwitch]="questionsArrayType[i].value">
                    <div class="col-12 d-flex align-items-center" *ngSwitchCase="elementTypes.Q_TEXTS">
                        <div class="col-11 d-flex flex-column">
													<label>{{'QUIZZES.BODY.ENUNCIADO' | translate}} {{i + 1}}</label>
                        	<textarea nbInput fullWidth class="form-control" formControlName="data" (blur)="onTextBlur(i)" rows="4" maxlength="250"></textarea>
												</div>
                        <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                            <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                        </button>
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end container" *ngSwitchCase="elementTypes.Q_PICTURES">

                        <div ngResizable (rzStop)="onResizeStop($event,i)" [style.width]="questionsArrayWidth[i].value ? questionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="questionsArrayHeight[i].value ? questionsArrayHeight[i].value + 'px' : '100%'">
                            <img class="w-100 h-100" [src]="questionsArrayData[i].value| quizElements:'image' |safe" />
                        </div>

                        <mat-progress-bar mode="buffer" *ngIf="!questionsArrayData[i].value"></mat-progress-bar>
                        <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                            <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                        </button>
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.Q_VIDEOS">

                        <div ngResizable (rzStop)="onResizeStop($event,i)" [style.width]="questionsArrayWidth[i].value ? questionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="questionsArrayHeight[i].value ? questionsArrayHeight[i].value + 'px' : '100%'">
                            <video class="w-100 h-100" controls [src]="questionsArrayData[i].value | quizElements:'video' | safe" *ngIf="questionsArrayData[i].value">
                            </video>
                        </div>

                        <mat-progress-bar mode="buffer" *ngIf="!questionsArrayData[i].value"></mat-progress-bar>
                        <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                            <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                        </button>
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.Q_AUDIOS">
                        <audio class="w-100 d-block" controls [src]="questionsArrayData[i].value | quizElements:'audio' | safe" *ngIf="questionsArrayData[i].value">
                        </audio>
                        <mat-progress-bar mode="buffer" *ngIf="!questionsArrayData[i].value"></mat-progress-bar>
                        <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                            <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                        </button>
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.Q_PDFS">

                        <div ngResizable (rzStop)="onResizeStop($event,i)" [style.width]="questionsArrayWidth[i].value ? questionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="questionsArrayHeight[i].value ? questionsArrayHeight[i].value + 'px' : '100%'">
                            <iframe #pdfElement class="w-100 h-100" [src]="questionsArrayData[i].value | quizElements:'pdf' | safe" allowfullscreen *ngIf="questionsArrayData[i].value"></iframe>
                        </div>

                        <mat-progress-bar mode="buffer" *ngIf="!questionsArrayData[i].value"></mat-progress-bar>
                        <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                            <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                        </button>
                    </div>
                </ng-container>

                <div [ngClass]="{'h-100 flex justify-content-center align-items-end': questionsArrayType[i].value !== elementTypes.Q_TEXTS}">
                    <button mat-icon-button class="ml-2" *ngIf="questionsArray.controls.length > 1" (click)="deleteQuestion(i)">
                        <mat-icon class="mat-icon-white">delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</form>
