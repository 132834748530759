import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-conditions-dialog',
    templateUrl: './conditions-dialog.component.html',
    styleUrls: ['./conditions-dialog.component.scss']
})
export class ConditionsDialogComponent implements OnInit {
    template: string;

    constructor() {
    }

    ngOnInit() {
    }

}
