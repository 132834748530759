		<div class="justify-content-center d-flex mt-5">
			<form [formGroup]="formulario" (ngSubmit)="aceptar()" style="max-width: 500px; width: 100%;">
				<!-- PREFIX AND PHONE-->
				<div class="row ">
					<div class="col-12 form-group">
						<label for="prefix" class="label">{{ 'LOGIN.PREFIX' | translate }}</label>
						<nb-select fullWidth hero size="small" class="SelectForm" name="prefix" placeholder="{{ 'LOGIN.PREFIX' | translate }}"
							formControlName="prefix">
							<nb-option class="SelectFormOpt" *ngFor="let prefix of $phonePrefixes | async"
								value="{{prefix.dial_code}}">{{prefix.name_en}}
								<span><strong> {{prefix.dial_code}}</strong> </span>
							</nb-option>
						</nb-select>
						<div *ngFor="let validation of validationMessages.prefix" class="mt-2">
							<div class="error-message"
								*ngIf="prefixControl?.hasError(validation.type) && (prefixControl?.dirty || prefixControl?.touched)">
								{{validation.message}}</div>
						</div>
					</div>
					<div class="col-12 form-group">
						<label for="mobile" class="label">{{ 'USERDATA.TELEFONOMOVIL' | translate }}</label>
						<input type="text" nbInput fullWidth class="form-control" fieldSize="small" status="basic" (keyup)="verificarMovil()"
							placeholder="{{ 'USERDATA.TELEFONOMOVIL' | translate }}" name="mobile" formControlName="mobile"
							required>
						<div class="error-message" *ngIf="samePhone">
								{{ 'CAMBIARMOVIL.OTHERPHONE' | translate }}
						</div>
						<div *ngFor="let validation of validationMessages.mobile">
							<mat-error
								*ngIf="formulario.get('mobile')?.hasError(validation.type) && (formulario.get('mobile')?.dirty || formulario.get('mobile')?.touched)">
								<div class="error-message">{{validation.message}}</div>
							</mat-error>
						</div>
					</div>
				</div>
				<!-- ACCEPT AND CANCEL BUTTONS-->
				<div class="d-flex justify-content-center gap-3 mt-4" [ngClass] ="{'justify-content-end':disableCancel}" >
					<div>
						<button nbButton type="submit" class="btn-themecolor" size="small">{{ 'CAMBIARMOVIL.GETCODE' | translate	}}</button>
					</div>
					<div *ngIf="!disableCancel">
							<button nbButton (click)="cancelar()" class="btn-red" size="small">{{ 'ACEPTARCANCELAR.CANCELAR' | translate }}</button>
					</div>
				</div>
				<!-- INPUT CODE-->
				<div class="row">
					<div class="col-12 form-group" *ngIf="introCode">
						<label for="code" class="label">{{ 'CAMBIARMOVIL.ENTERVERIFICATIONCODE' | translate }}</label>
						<input type="text" formControlName="code" nbInput fullWidth appNumberInput status="basic"	placeholder="{{ 'CAMBIARMOVIL.VERIFICATIONCODE' | translate }}"
							(input)="validacionPin($event)" class="form-control" fieldSize="small">
						<div class="error-message">
							{{ 'CAMBIARMOVIL.ALERTLOGOUT' | translate }}
						</div>
					</div>
				</div>
			</form>
	</div>
