import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-message-multi-tabs',
  templateUrl: './message-multi-tabs.component.html',
  styleUrls: ['./message-multi-tabs.component.scss']
})
export class MessageMultiTabsComponent implements OnInit {
  @Output() onClose = new EventEmitter<boolean>()
  constructor() { }
  ngOnInit() {}

  useHere(){
    this.onClose.emit(true)
  }
}
