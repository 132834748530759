<app-loading [isVisible]="isLoading" ></app-loading>

<div class="w-100">
    <div class="pb-4">
        <div >
						<app-recordar-quiz-play-multiple-body-info class="mt-4" [quiz]="quiz" [timer$]="time$"
							(timeEnded)="onTimeEnded($event)" [stopTime]="stopTime"></app-recordar-quiz-play-multiple-body-info>
        </div>
        <div class="mt-4">
            <!-- <h4>{{'QUIZPLAY.DATA' | translate}}</h4> -->
            <app-recordar-quiz-play-multiple-body-data [questions]="questions"></app-recordar-quiz-play-multiple-body-data>
        </div>
        <div class="mt-4">
            <!-- <h4 class="mb-4">{{'QUIZPLAY.RESPONSE' | translate}}</h4> -->
            <app-recordar-quiz-play-multiple-body-option *ngIf="quiz" [options]="options" (saveOption)="onSaveOption($event)" (saveOptionMultiple)="saveOptionMultiple($event)"  (saveOptionText)="saveTxt($event)" [quiz]="quiz" [quizType]="quizType" [answered]="answered"
            [respuestaEstudiante]="respuestaEstudiante"></app-recordar-quiz-play-multiple-body-option>
        </div>

        <div class="mt-4" *ngIf="answered">
            <!-- <h4>{{'QUIZPLAY.ANSWER' | translate}}</h4> -->
            <p><strong>{{'QUIZPLAY.ANSWER' | translate}}</strong></p>
            <app-recordar-quiz-play-multiple-body-answer [answers]="answers"></app-recordar-quiz-play-multiple-body-answer>
        </div>

<!--
        <div class="row  mt-5 flex justify-content-between ">
            <div class="col-4 col-sm-12 col-md-4 col-lg-4  flex align-items-center justify-content-center">
                <button type="button" class="btnDelete  flex align-items-center justify-content-center" *ngIf="answered" [disabled]="disabledButton"

                (click)="clickAction('next')">
                    <mat-icon class="icon-color-blue">close</mat-icon><span class="span-icon">&nbsp;{{'QUIZPLAY.ERROR' | translate}}</span>
                </button>
            </div>
            <div   *ngIf="!answered" class="col-4 col-sm-12 col-md-4 col-lg-4 flex justify-content-center solution-button">
                <button mat-icon-button matTooltip="Mostrar solución" matTooltipClass="black-tooltip" class="circle-button" (click)="discoverAnswer()" [disabled]="answered">
                    <mat-icon svgIcon="done" class="icon-color-blue" ></mat-icon>
                </button>
            </div>
            <div class="col-4 col-sm-12 col-md-4 col-lg-4  flex align-items-center justify-content-center">
                <button type="button" class="btnPlayGreen-cardTarget  flex align-items-center justify-content-center" *ngIf="answered" [disabled]="disabledButton"
                 (click)="clickAction('next')">
                    <mat-icon class="icon-color-blue">done</mat-icon><span class="span-icon">&nbsp;{{'QUIZPLAY.DONE' | translate}}</span>
                </button>
            </div>
        </div> -->


        <div class="row d-flex justify-content-center mt-4 mb-2">

            <div class="col-6">
                <button type="button" class="btn btn-block btn-danger btn-sm  flex align-items-center justify-content-center" (click)="repetirOrCorrecto(false)">
                    <mat-icon class="icon-color-blue">close</mat-icon><span class="span-icon">&nbsp;Repetir</span>
                </button>
            </div>

            <div class="col-6">
                <button type="button" class="btn btn-block  btn-success btn-sm  flex align-items-center justify-content-center" (click)="repetirOrCorrecto(true)">
                    <mat-icon class="icon-color-blue">done</mat-icon><span class="span-icon">&nbsp;Correcto</span>
                </button>
            </div>

            <div class="col-12 mt-2">
                <div class="form-group">
                    <form [formGroup]="form">
                        <label for="exampleFormControlTextarea1">Comentarios del profesor</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" formControlName="coments"  rows="3"></textarea>
                    </form>

                </div>
            </div>

        </div>




    </div>
</div>
