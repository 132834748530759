import { OptionsNodeConnectModel } from './../sigma-canvas/sigma-canvas.model';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { NodeAsociarGlobal, NodeLinkType } from 'src/app/core/models/nodes/node-link-type.model';
import { LoginService } from 'src/app/core/services/login';
import { NodeService } from 'src/app/core/services/node/node.service';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { LocalStorage } from 'src/app/core/utils';
import { GRAPH_EDGE_TYPES } from 'src/app/core/utils/graph-edge-types';
import { Utils } from 'src/app/core/utils/utils';

@Component({
    selector: 'app-node-link',
    templateUrl: './node-link.component.html',
    styleUrls: ['./node-link.component.scss']
})
export class NodeLinkComponent implements OnInit {
    @Output() conectarNodo = new EventEmitter<any[]>();

    lines: NodeLinkType[] = [
        { type: GRAPH_EDGE_TYPES.LINE, name: 'NODOS.LINE' },
        { type: GRAPH_EDGE_TYPES.CURVE, name: 'NODOS.CURVE' },
        { type: GRAPH_EDGE_TYPES.ARROW, name: 'NODOS.ARROW' },
        { type: GRAPH_EDGE_TYPES.CURVED_ARROW, name: 'NODOS.ARROWCURVE' },
        { type: GRAPH_EDGE_TYPES.BRANCH, name: 'NODOS.BRANCH' },
    ];

    branchType = GRAPH_EDGE_TYPES.BRANCH;

    public color: string = "#000000";
		public textColor: string = '#FFFFFF';
    public datos: any[] = [];

    // HTML vars
    public nodeFrom = { id: '', idOriginal: 0, url: '', label: '', idNodeTarget: 0 , IdNodeTargetOrig: 0};
    public nodeTo = { id: '', idOriginal: 0, url: '', label: '',  idNodeTarget: 0, IdNodeTargetOrig: 0 };
    public options: OptionsNodeConnectModel;
    courseId: number;
    graphId: number;

    form: UntypedFormGroup;

    validationMessages = {
        label: [],
        line: [],
        size: []
    };

    isLoading: boolean = false;

	constructor(public modalService: NgbModal,
		private loginService: LoginService,
		private router: Router,
		public activeModal: NgbActiveModal,
		private fb: UntypedFormBuilder,
		private localStorage: LocalStorage,
		private translateService: TranslateService,
		private nodeService: NodeService,
		private toaster: ToasterService,
		private utils: Utils,
		) {
		this.form = this.fb.group({
			line: ['', Validators.required],
			label: ['', Validators.maxLength(35)],
			size: [5, Validators.required]
		});
	}

    ngOnInit() {
			// se comprueba si existe la variable local "conexionNodo"
			const conexionNodos = JSON.parse(localStorage.getItem('conexionNodos'));

			if (conexionNodos) {
					// Rellena el formulario con los valores de "conexionNodo"
					this.form.get('line').setValue(conexionNodos.line);
					this.form.get('label').setValue(conexionNodos.label);
					this.form.get('size').setValue(conexionNodos.size);
					this.color = conexionNodos.color;
			}
			this.traducirOpciones();
			if(this.options)
					this.fillForm()
    }

    private fillForm(): void{
        this.form.get('line').setValue(this.options.type)
        this.form.get('label').setValue(this.options.label)
        this.form.get('size').setValue(this.options.size)
        this.color = this.options.color

    }

    get labelControl() {
        return this.form.get('label') as UntypedFormControl;
    }

    get lineControl() {
        return this.form.get('line') as UntypedFormControl;
    }

    get sizeControl() {
        return this.form.get('size') as UntypedFormControl;
    }

    cancelar() {
        this.modalService.dismissAll();
    }

    conectar() {
			//Si conectamos los nodos creamos la variable local con los datos por si quiero unir otros con las mismas caracteristicas
			const conexionNodos = {
				label: this.labelControl.value,
        line: this.lineControl.value,
        size: this.sizeControl.value,
				color: this.color,
				idNodeDes: this.nodeTo.idOriginal,
				idNodeOri: this.nodeFrom.idOriginal,
			}
			localStorage.removeItem('conexionNodos');
			localStorage.setItem('conexionNodos', JSON.stringify(conexionNodos));

        this.form.markAllAsTouched();
        if (this.form.valid) {


            this.isLoading = true;
            let buscarnodo:boolean = JSON.parse(this.localStorage.getItem(LOCALSTORAGESTRINGS.SEARCHNODE))

            if(buscarnodo){

                let idNode_Course:number = JSON.parse(this.localStorage.getItem(LOCALSTORAGESTRINGS.COURSEID))
                let idNode_Target:number = JSON.parse(this.localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHID))
                let bodySend: NodeAsociarGlobal = {
                    idNodeDes: this.nodeTo.idOriginal,
                    IdNodeTargetDest: idNode_Target,
                    idNodeOri: this.nodeFrom.idOriginal,
                    idNodeCourseDest: idNode_Course,
                    idUser: 0,
                    label: this.labelControl.value,
                    type: this.lineControl.value,
                    size: this.sizeControl.value,
                    color:  this.color,
                    idNode_TargetOrig: this.nodeFrom.idNodeTarget,
                    idNode_TargetDest: this.nodeTo.idNodeTarget
                }
                this.nodeService.crearAsociacion(bodySend).subscribe((res:any) => {
                    if(res.data){
                        this.toaster.success(this.translateService.instant('NODOS.ASSOCIATEDOK'));
                        this.localStorage.setItem(LOCALSTORAGESTRINGS.SEARCHNODE, 'false');
                    }else {
                        this.toaster.error(this.translateService.instant('NODOS.ASSOCIATEDKO'));
                    }

                    this.router.navigate([`/course/${idNode_Course}/graph/${idNode_Target}`]);

                    this.activeModal.close()

                }, err => {
                    console.log(err)
                    this.toaster.error(this.translateService.instant('NODOS.ASSOCIATEDKO'));
                })
            }
            else {
                this.nodeService.createEdge(this.nodeFrom.idOriginal, this.nodeTo.idOriginal, this.courseId, this.graphId, this.lineControl.value, this.labelControl.value, this.sizeControl.value, this.color).pipe(finalize(() => this.isLoading = false)).subscribe(
                    (res: any) => {
                        if (res.error.code) {
                            this.toaster.error(this.translateService.instant('NODOS.LINKNOK'));
                        } else {
                            this.toaster.success(this.translateService.instant('NODOS.LINKOK'));

                            this.datos['line'] = this.lineControl.value;
                            this.datos['size'] = this.sizeControl.value;
                            this.datos['color'] = this.color;
                            this.datos['label'] = this.labelControl.value;
                            this.datos['from'] = this.nodeFrom;
                            this.datos['to'] = this.nodeTo;

                            const response = {
                                result: 'OK',
                                datos: this.datos
                            };
														// Verifica si la URL actual es la misma que la que intentas navegar
														const currentUrl = this.router.url;
														const targetUrl = `/course/${this.courseId}/graph/${this.graphId}`;
														if (currentUrl === targetUrl) {
															localStorage.setItem('dontLoadMenu', 'true');
															this.utils.loadMenu = false;
															// Si la URL es la misma, realiza un reload
															// this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
															// 	this.router.navigate([`/course/${this.courseId}/graph/${this.graphId}`])
															// 	.then(() => {
															// 		this.closeModal("closeAll");
															// 		this.activeModal.close("closeAll");
															// 	});;
															// });
															//window.location.reload();
														} else {
															localStorage.setItem('dontLoadMenu', 'true')
															this.utils.loadMenu = false;
															// Si la URL es diferente, navega normalmente
															this.router.navigate([`/course/${this.courseId}/graph/${this.graphId}`])
															.then(() => {
																this.closeModal("closeAll");
																this.activeModal.close("closeAll");
															});
														}
														this.closeModal(response);
                        }
                    },
                    err => {
                        this.toaster.error(this.translateService.instant('NODOS.LINKNOK'));
                    }
                );
            }

        }
    }

    changeConnectOrderItems() {
			var n1 = this.nodeFrom;
			this.nodeFrom = this.nodeTo;
			this.nodeTo = n1;
		}

    closeModal(sendData?: any) {
        this.activeModal.close(sendData);
    }

    traducirOpciones() {
        const lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
        this.translateService.use(lang);

        this.translateService.get('VALIDACIONES.NODELINKLABELREQUIRED').subscribe((res: string) => {
            this.validationMessages.label.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.NODELINKLABELMAXLENGTH').subscribe((res: string) => {
            this.validationMessages.label.push({ type: 'maxlength', message: res });
        });

        this.translateService.get('VALIDACIONES.NODELINKTYPEREQUIRED').subscribe((res: string) => {
            this.validationMessages.line.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.NODELINKSIZEREQUIRED').subscribe((res: string) => {
            this.validationMessages.size.push({ type: 'required', message: res });
        });
    }

		// Función para determinar si el color es claro u oscuro
		isLight(color: string): boolean {
			// Convertir el color a RGB
			const rgb = parseInt(color.substr(1), 16);
			const r = (rgb >> 16) & 0xff;
			const g = (rgb >> 8) & 0xff;
			const b = (rgb >> 0) & 0xff;
			// Calcular el brillo del color
			const brightness = (r * 299 + g * 587 + b * 114) / 1000;
			// Devolver true si el color es claro, false si es oscuro
			return brightness > 128;
		}

		// Actualizar el color de texto basado en el color seleccionado
		updateTextColor(): void {
			this.textColor = this.isLight(this.color) ? '#000000' : '#FFFFFF';
		}

}
