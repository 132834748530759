import { DemoValidationGuard } from 'src/app/core/guards/demo-validation.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthGuard, ProfileValidationGuard } from '.';

@NgModule({ declarations: [], imports: [CommonModule], providers: [
        AuthGuard,
        ProfileValidationGuard,
        DemoValidationGuard,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class GuardsModule { }
