<nb-card class="mb-0 no-edge-card">
	<nb-card-header class="border-bottom-0">
		<div class="d-flex align-items-center gap-4">
			<nb-icon class="people" icon="people"></nb-icon>
			<h6>{{ 'ALERTGROUP.GRUPOACTUAL' | translate }}: {{ group.title }}</h6>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="m-4 d-flex">
			<div class="d-flex justify-content-center">
				<span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ mensaje }}</span>
			</div>
		</div>
	</nb-card-body>
	<nb-card-footer class="border-top-0">
		<div class="d-flex justify-content-center gap-4" [ngClass] ="{'justify-content-end':disableCancel}" >
			<button nbButton size="small" (click)="aceptar()" class="btn-themecolor">{{ botonAceptar }}</button>

			<button nbButton size="small" (click)="cancelar()" class="btn-red">{{ 'ACEPTARCANCELAR.CANCELAR' | translate }}</button>
		</div>
	</nb-card-footer>
</nb-card>
