<div class="d-flex flex-column justify-content-between h-100">

    <div>

        <div class="element {{!e.showInMenu ? 'd-none' : ''}} {{e.customClass !== undefined? e.customClass: ''}}" *ngFor="let e of menu; let i = index"  placement="right-top" ngbTooltip="{{ e.title | translate }}" [ngClass]="{activeMenu: e.profile == activeMenu}" (click)="e.profile==='PADRE'||e.profile==='CENTRO' ? null : showSubmenu(e.profile)">
            <!-- ROLES -->
            <mat-icon *ngIf="i !== 0" svgIcon="{{e.icon}}" ></mat-icon>
            <mat-icon *ngIf="i === 0" svgIcon="{{e.icon}}" copilot
            [copilot-template]="step10" [copilot-step]="10"></mat-icon>
            <!-- END ROLES -->

            <!-- SUBMENU -->
            <div *ngIf="e.submenu" class="menu-items {{e.profile}}" [ngClass]="{active: e.profile == showMenuOptions}" >
                <div *ngFor="let v of e.submenu" class="submenu-item">
                    <a *ngIf="v.icon != ''" class="d-flex align-items-center mb-3 {{v.clickFunction ? '': 'disabled'}}" placement="right-top" ngbTooltip="{{ v.tooltipText | translate }}" (click)="v.clickFunction ? v.clickFunction($event) : null">
                        <mat-icon svgIcon="{{v.icon}}"></mat-icon>
                        <span *ngIf="v.numberPosition" [class.ml-2]="v.numberPosition">{{v.numberPosition}}.</span>
                        <span [ngClass]="v.numberPosition ? 'ml-1' : 'ml-2'">{{ v.title | translate }}</span>
                    </a>
                    <a *ngIf="v.icon == ''" class="d-flex align-items-center m-0"  >
                        <span class="m-0">{{ v.title | translate }}</span>
                    </a>

                    <div *ngIf="v.submenu" class="submenu-items">
                        <a *ngFor="let val of v.submenu" class="d-flex align-items-center mb-2"  placement="right-top" ngbTooltip="{{ val.title | translate }}" (click)="val.clickFunction($event)">
                            <mat-icon svgIcon="{{val.icon}}"></mat-icon>
                                <span class="ml-2">{{ val.title | translate }}</span>
                        </a>
                    </div>

                </div>

            </div>
            <!-- END SUBMENU -->
        </div>
    </div>
</div>
