<nb-card class="no-edge-card modal-height-100-62 profile-actions-main-card">
	<nb-card-header class="flex border-bottom-0">
		<div class="row align-items-center justify-content-center w-100">
			<h4>{{'MODALCONFIGSOCKETCOURSES.TITLE' | translate}}</h4>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<form [formGroup]="form" (submit)="saveConfig()"
			class="d-flex flex-column justify-content-center" #Form1="ngForm">
			<nb-card class="profile-actions-main-card no-edge-card">
				<nb-card-body class="p-0">
					<div class="modal-body container-config card-gray-bg p-4 p-sm-5">

						<nb-radio-group class="w-100" formControlName="option" name="option">

							<nb-radio value="1" class="p-3 bg-white text-center btn_desafio hide-radio btn_desafio-active">
								<h6 class="mb-3 color-red-text">{{'MODALCONFIGSOCKETCOURSES.SUBTITLE01' | translate}} - {{'MODALCONFIGSOCKETCOURSES.FREEORDER' | translate}}</h6>

								<div class="d-flex align-items-center justify-content-center flex-md-row flex-column">
									<p class="text-center mb-0">{{'MODALCONFIGSOCKETCOURSES.OPTION1' | translate}}</p>
									<nb-icon class="ml-2 golden-icon" icon="info"
										nbTooltip="{{'MODALCONFIGSOCKETCOURSES.INFO1' | translate}}"
										nbTooltipPlacement="top">
									</nb-icon>
								</div>

								<div class="mt-3">
									<div class="detail">
										<p class="green-tea">
											{{'MODALCONFIGSOCKETCOURSES.TIMECHALLENGE' | translate}}
										</p>
										<div class="d-flex pt-md-0 pt-2">
											<mat-icon class="mx-2">schedule</mat-icon>
											<input type="number" style="width: 50px;" appNumberInput formControlName="challengeTime" />
											<span class="ml-2 green-tea">{{'MODALCONFIGSOCKETCOURSES.MINUTES' | translate}}</span>
										</div>
									</div>
									<div *ngFor="let validation of validationMessages.minutes">
										<div class="error-message"
											*ngIf="challengeTimeControl?.hasError(validation.type)"> {{validation.message}}
										</div>
									</div>
								</div>

							</nb-radio>

							<nb-radio value="3" class="my-4 p-3 bg-white text-center btn_desafio hide-radio">
								<h6 class="mb-3 color-red-text">{{'MODALCONFIGSOCKETCOURSES.SUBTITLE01' | translate}} - {{'MODALCONFIGSOCKETCOURSES.SEQUENTIALORDER' | translate}}</h6>

								<div class="d-flex align-items-center justify-content-center flex-md-row flex-column">
									<p class="text-center m-0">{{'MODALCONFIGSOCKETCOURSES.OPTION3' | translate}}</p>
									<nb-icon class="ml-2 golden-icon" icon="info"
										nbTooltip="{{'MODALCONFIGSOCKETCOURSES.INFO3' | translate}}"
										nbTooltipPlacement="top">
									</nb-icon>
								</div>

								<div class="mt-3">
									<div class="flex col-12 align-items-center flex-md-row flex-column justify-content-center" style="gap: 20px;">
										<label for="synchronizedStudents"
												class="strong-label">{{'MODALCONFIGSOCKETCOURSES.SYNCHRONIZEDSTUDENTS' | translate}}
										</label>
										<nb-toggle formControlName="synchronizedStudents" checked="false"></nb-toggle>
									</div>

									<div class="flex col-12 align-items-center flex-md-row flex-column justify-content-center" style="gap: 20px;">
										<label for="randomOrder"
												class="strong-label">{{'MODALCONFIGSOCKETCOURSES.RANDOMORDER' | translate}}
										</label>
										<nb-toggle formControlName="randomOrder"></nb-toggle>
									</div>
								</div>
							</nb-radio>

							<nb-radio value="2" class="p-3 bg-white text-center btn_desafio hide-radio">
								<h6 class="mb-3 color-red-text">{{'MODALCONFIGSOCKETCOURSES.SUBTITLE02' | translate}}</h6>

								<div class="d-flex align-items-center justify-content-center flex-md-row flex-column">
									<p class="text-center m-0">{{'MODALCONFIGSOCKETCOURSES.OPTION2' | translate}}</p>
									<nb-icon class="ml-2 golden-icon" icon="info"
										nbTooltip="{{'MODALCONFIGSOCKETCOURSES.INFO2' | translate}}"
										nbTooltipPlacement="top">
									</nb-icon>
								</div>
							</nb-radio>

						</nb-radio-group>
					</div>

				</nb-card-body>
			</nb-card>

			<div class="flex justify-content-center pb-4">
				<button nbButton class="btn-themecolor" type="submit">
					<span>{{ 'MODALCONFIGSOCKETCOURSES.ACCEPT' | translate | titlecase }}</span>
				</button>
			</div>
		</form>
	</nb-card-body>
</nb-card>
