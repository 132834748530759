import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupModel } from 'src/app/core/models/groups/groups-model';
import { User } from 'src/app/core/models/users/user.models';
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { LoginService } from 'src/app/core/services/login';
import { Profiles } from 'src/app/core/utils/profiles.enum';
import { ModalEditarGrupoComponent } from '../modal-editar-grupo/modal-editar-grupo.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { interval, Subscription  } from 'rxjs';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
  selector: 'app-modal-listado-estudiantes',
  templateUrl: './modal-listado-estudiantes.component.html',
  styleUrls: ['./modal-listado-estudiantes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalListadoEstudiantesComponent implements OnInit {

  group: GroupModel;
  public users: User[] = [];
	resultFiltro: number = 0;
  public usersInscritos: User[] = [];
  filter: {
    idGrupo: number,
    filter: string
  }
  public formulario: UntypedFormGroup;
  cargando: boolean;
	profile: string;
	user: User;
	newGroup: boolean = false;
	private listadoEstudiantesInterval: Subscription;


  constructor(
    public activeModal: NgbActiveModal,
    private groupService: GruposService,
    private formBuild: UntypedFormBuilder,
    private loginService: LoginService,
    private translateService: TranslateService,
		private modalService: NgbModal,
		private toaster: ToasterService

  ) {
    this.formulario = this.formBuild.group({
      filtrado: [''],
  });

   }

  ngOnInit() {
    // this.getListadoEstudiantesInscribir();
		this.profile = this.loginService.getProfile();
		this.user = this.loginService.getUser();
		this.cargando = true;
		this.getListadoEstudiantes();
    this.startListadoEstudiantesInterval();
  }

	private startListadoEstudiantesInterval() {
		this.listadoEstudiantesInterval = interval(5000)
			.subscribe(() => {
				this.getListadoEstudiantes();
			});
	}

	ngOnDestroy() {
		if (this.listadoEstudiantesInterval) {
			this.listadoEstudiantesInterval.unsubscribe();
		}
	}

  filtrarEstudiantes(){
    const filtradoValue = this.formulario.value;
    if (this.formulario.value.filtrado.trim().length > 9) {
      this.cargando = true;
      this.groupService.filtrarEstudiantesBusqueda2(this.group.idGroup, filtradoValue).subscribe(res => {
        this.users = res.data
				this.resultFiltro = res.error.code;
        this.cargando = false;
      },
      err => console.log(err));
    }
  }

  getListadoEstudiantesInscribir() {
    // this.filter.idGrupo = this.group.idGroup;
    // this.filter.filter = '';

    this.groupService.getListadoEstudiantesInscribir(this.group.idGroup, '').subscribe((res:User[]) => {
      this.users = res


    },
    err => console.log(err));

  }

  getListadoEstudiantes() {
    this.groupService.getListEstudiantes(this.group.idGroup).subscribe((res:any) => {
      // if (condition) {

      // }
      this.usersInscritos = res.data;
			this.cargando = false;
			this.group.assignedStudents = res.data.length;
    });
  }

  closeModal(sendData?: any) {
		if (this.profile === Profiles.Teacher) {
			const selectedGroupInfo = {
				idGroup: this.group.idGroup,
				title: this.group.title,
				description: this.group.description,
				group: this.group,
				idUser: this.user.idUser,
				assignedProjects: this.group.assignedProjects,
				assignedStudents: this.group.assignedStudents
			}
			localStorage.removeItem('selectedGroupInfo');
			localStorage.setItem('selectedGroupInfo', JSON.stringify(selectedGroupInfo));
		}
    this.activeModal.close(sendData);
  }

  enviarInvitacion(userInvitar:User){
      this.cargando = true;
      this.groupService.getInvitarEstudiante(this.group.idGroup, userInvitar.idUser).subscribe(res => {
        let  elementIndex = this.users.findIndex((user => userInvitar.idUser == user.idUser));
        this.users[elementIndex].invitedToGroup = "T";
        this.toaster.success(this.translateService.instant('MODALLISTSTUDENTS.INVITATIONOK'));
        this.cargando = false;

      },
      err => console.log(err));
  }

  eliminarEstudiante(estudiante){

    this.cargando = true;
    this.groupService.eliminarEstudiante(this.group.idGroup, estudiante.idUser).subscribe(res => {
    this.toaster.success(this.translateService.instant('MODALLISTSTUDENTS.REMOVEDSTUDENT'));
    this.cargando = false;
    this.getListadoEstudiantes();

    },
    err => console.log(err));
  }

	terminarNuevoGrupo(grupo: GroupModel){
		const modalRef = this.modalService.open(ModalEditarGrupoComponent, { scrollable: false, windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` });
		modalRef.componentInstance.id = grupo.idGroup;
		modalRef.componentInstance.group = grupo;
		modalRef.componentInstance.terminarNuevoGrupo = true;

		modalRef.result.then((result) => {
			if (result !== '') {
				if (result === 'delete') {
					this.closeModal('delete');
				}
				else {
					this.group = result as GroupModel;
				}
			}
		}, (err) => { })
		this.closeModal('');
	}

}
