
<form class="container" [formGroup]="optionForm" *ngIf="QuizTypes.TEXT && quiz.idMultiplexQuiz === 1">
    <ng-container formArrayName="options">
        <div class="row"  >
            <ng-container [ngSwitch]="quizType">

                <p class="title">{{'QUIZZES.RESPONSETITLE' | translate}}</p>
                <div class="row center-aling w-100" *ngSwitchCase="QuizTypes.TEXT">

                    <ng-container *ngFor="let option of  optionsArray.controls; let i = index">
                        <ng-container class="col-12 w-100 m-0 p-0" *ngIf="option.value.type === O_TEXTS">
                            <form [formGroup]="optionFormTexto">
                                <div class="d-flex flex-column full-width">
                                    <label>{{'QUIZZES.RESPUESTA' | translate}}</label>
                                    <input nbInput class="form-control" fullWidth type="text" formControlName="answer" [readonly]="this.answered"  autocomplete="off" />
                                </div>
                            </form>
                        </ng-container>

                        <div class="" >
                            <!-- Inputs Ocultos -->
                            <input *ngIf="option.value.type === O_AUDIOS" type="file" id="{{i}}" accept="audio/mp3" (change)="onFileSelected(i, $event, O_AUDIOS)" hidden/>
                            <input *ngIf="option.value.type === O_VIDEOS" type="file" id="{{i}}" accept="video/mp4" (change)="onFileSelected(i, $event, O_VIDEOS)" hidden/>
                            <input *ngIf="option.value.type === O_PICTURES" type="file" id="{{i}}" accept="image/*" (change)="onFileSelected(i, $event, O_PICTURES)" hidden/>
                            <input *ngIf="option.value.type === O_PDFS" type="file" id="{{i}}" accept="application/pdf,application/vnd.ms-excel" (change)="onFileSelected(i, $event, O_PDFS)" hidden/>
                     </div>
                     <ng-container *ngIf="arrayFileView[i].view" class="center-aling">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-2 col-xl-2  container-file " >
                            <div class="row">

                                <div class="col-12 center-aling" style="padding-left: 0px; padding-right: 0px;">
                                    <img *ngIf="option.value.type === O_PICTURES" #imgElement class="file-container-view"   id="{{i}}" [src]='arrayFileView[i].url|safe' />
                                    <audio  *ngIf="arrayFileView[i].view && option.value.type === O_AUDIOS" class="file-container-view"   id="{{i}}" [src]='arrayFileView[i].url|safe' controls>
                                    </audio>
                                    <video *ngIf="arrayFileView[i].view && option.value.type === O_VIDEOS" class="file-container-view"   [src]='arrayFileView[i].url|safe' controls>
                                    </video>
                                    <iframe *ngIf="arrayFileView[i].view && option.value.type === O_PDFS" #pdfElement  class="file-container-view"  style="max-height: 70% !important;" [src]='arrayFileView[i].url|safe' allowfullscreen></iframe>
                                </div>
                                <div class="col-12 center-aling" style="padding-top:3px">
                                    <span *ngIf="option.value.type === O_VIDEOS">{{'QUIZZES.VIDEO' | translate}}</span>
                                    <span *ngIf="option.value.type === O_PICTURES">{{'QUIZZES.IMAGEN' | translate}}</span>
                                    <span *ngIf="option.value.type === O_AUDIOS">{{'QUIZZES.AUDIO' | translate}}</span>
                                    <span *ngIf="option.value.type === O_PDFS">{{'QUIZZES.DOCUMENTO' | translate}}</span>
                                    &nbsp;
                                    <mat-icon class="edit pointer" ngbTooltip="{{'GLOBALS.EDIT' | translate}}" placement="right" (click)="onFileSelectedReplace(i, option.value)">
                                        edit
                                    </mat-icon>
                                    <mat-icon  *ngIf="arrayFileView[i].view && option.value.type === O_AUDIOS" ngbTooltip="{{'QUIZZES.RECORDNEWAUDIO' | translate}}" class="edit pointer " (click)="grabarAudio(i, option.value)">mic</mat-icon>
                                    <mat-icon  *ngIf="arrayFileView[i].view && option.value.type === O_VIDEOS" ngbTooltip="{{'QUIZZES.RECORDNEWVIDEO' | translate}}" class="edit pointer" (click)="grabarVideo(i, option.value)">camera</mat-icon>
                                    <mat-icon  *ngIf="arrayFileView[i].view && option.value.type === O_PICTURES" ngbTooltip="{{'QUIZZES.NEWPHOTO' | translate}}" class="edit pointer" (click)="tomarFoto(i, option.value)">camera</mat-icon>

                                </div>


                            </div>

                        </div>
                    </ng-container>
                    <div >
                    <ng-container *ngIf="!arrayFileView[i].view"  class="center-aling">
                        <div class="row mt-4">

                        <div class="col-12">
                            <div class="row">
                                <ng-container *ngIf="option.value.type === O_VIDEOS">
                                    <div class="col-4">
                                        <div class="row">
                                            <div class="col-12 center-aling">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-icon  class="icon-mat-type pointer center-aling" id="mat-icon-{{i}}" (click)="openFile(i, option.value)" color="primary">videocam</mat-icon>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 mt-2 center-aling">
                                                <p class="card-text">
                                                    <span >{{'QUIZZES.VIDEO' | translate}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">

                                        <div class="row">
                                            <div class="col-12 center-aling">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-icon  class="icon-mat-type pointer " id="mat-icon-{{i}}" (click)="grabarVideo(i, option.value)" color="primary">camera</mat-icon>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 mt-2 center-aling">
                                                <p class="card-text">
                                                    <span>{{'QUIZZES.GRABAR' | translate}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="option.value.type === O_PICTURES">
                                    <div class="col-4">
                                        <div class="row">
                                            <div class="col-12 center-aling">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-icon  class="icon-mat-type pointer center-aling" id="mat-icon-{{i}}" (click)="openFile(i, option.value)" color="primary">image</mat-icon>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 mt-2 center-aling">
                                                <p class="card-text">
                                                    <span >{{'QUIZZES.IMAGEN' | translate}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">

                                        <div class="row">
                                            <div class="col-12 center-aling">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-icon  class="icon-mat-type pointer " id="mat-icon-{{i}}" (click)="tomarFoto(i, option.value)" color="primary">camera</mat-icon>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 mt-2 center-aling">
                                                <p class="card-text">
                                                    <span>{{'QUIZZES.FOTO' | translate}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>



                                <ng-container *ngIf="option.value.type === O_AUDIOS"
                                >

                                    <div class="col-4">
                                        <div class="row text-center">
                                            <div class="col-12">
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <mat-icon  class="icon-mat-type pointer red-icon" id="mat-icon-{{i}}" (click)="openFile(i, option.value)">volume_up</mat-icon>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 mt-2" style="padding: 0;">
                                                <span>{{'QUIZZES.ADDAUDIO' | translate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div  *ngIf="!viewAudio" class="col-4">

                                        <div class="row text-center">
                                            <div class="col-12">
                                                <div class="row ">
                                                    <div class="col-12">
                                                        <mat-icon  class="icon-mat-type pointer red-icon" id="mat-icon-{{i}}" (click)="_grabarAudio(i, option.value)">mic</mat-icon>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 mt-2">
                                                <span>{{'QUIZZES.RECAUDIO' | translate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewAudio" class="col">
                                        <recordar-audio-component [viewElement]="viewAudio" (close)="closeElement($event)"></recordar-audio-component>
                                    </div>

                                </ng-container>

                                <div  *ngIf="option.value.type === O_PDFS" class="col-sm-12 col-md-4 col-lg-3 col-xl-2 ">
                                    <div class="row">
                                        <div class="col-12  center-aling ">
                                            <mat-icon  class="icon-mat-type pointer" (click)="openFile(i, option.value)" color="primary">picture_as_pdf</mat-icon>
                                        </div>
                                        <div class="col-12 mt-2  center-aling ">
                                            <p class="card-text">
                                                <span>{{'QUIZZES.DOCUMENTO' | translate}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        </div>
                    </ng-container>
                    </div>
                    </ng-container>

                </div>

            </ng-container>
        </div>

  </ng-container>
</form>
