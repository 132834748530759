import { Injectable } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.worker.min.js';

@Injectable({
	providedIn: "root",
})
export class PdfService {
	constructor() {}

	async getPdfText(file: File): Promise<string> {
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);

		return new Promise((resolve, reject) => {
			reader.onload = async () => {
				const typedarray = new Uint8Array(reader.result as ArrayBuffer);
				const pdf = await pdfjsLib.getDocument({ data: typedarray }).promise;
				let text = "";

				for (let i = 1; i <= pdf.numPages; i++) {
					const page = await pdf.getPage(i);
					const content = await page.getTextContent();
					const pageText = content.items.map((item: any) => item.str).join(" ");
					text += `${pageText} `;
				}
				resolve(text.trim());
			};

			reader.onerror = (error) => reject(error);
		});
	}
}
