export enum HypermediaTypes {
    TEXT,
    VIDEO,
    IMAGE,
    AUDIO,
    PDF,
    CAMERA,
    VIDEOCAM,
    MICRO,
}
