import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalSocketCoursesComponent } from './../modal-socket-courses/modal-socket-courses.component';
import { SocketService } from './../../../../core/services/socket/socket-service.service';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { SocketQuizPreviewComponent } from './../../socket-quiz-preview/socket-quiz-preview.component';
import { UsersService } from './../../../../core/services/users/users.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GroupModel } from 'src/app/core/models/groups/groups-model';
import { CoursesService } from 'src/app/core/services/courses';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { LoginService } from 'src/app/core/services/login';
import { DynamicDatabase } from 'src/app/core/utils/treeView.utils';
import { ModalEditarGrupoComponent } from '../modal-editar-grupo/modal-editar-grupo.component';
import { ModalListadoEstudiantesComponent } from '../modal-listado-estudiantes/modal-listado-estudiantes.component';
import { ModalListadoCursosComponent } from '../modal-listado-cursos/modal-listado-cursos.component';
import { GruposService, SOCKETMESSAGES } from 'src/app/core/services/groups/grupos.service';
import { ReportingComponent } from '../../reporting/reporting.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalCursosGrupoComponent } from '../modal-cursos-grupo/modal-cursos-grupo.component';
import { Subject, Subscription } from 'rxjs';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';
import { IdiomaModel } from 'src/app/core/models/masters';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
  selector: 'app-grupos-opciones',
  templateUrl: './grupos-opciones.component.html',
  styleUrls: ['./grupos-opciones.component.scss']
})
export class GruposOpcionesComponent implements OnInit, OnDestroy {

  group: GroupModel;
  numberOfInvited = 0;
  invitacionesList: any;
	cargando = false;
	isStudent = false
	showActInst = false
	private subscriptions: Subscription[] = [];

	private destroy$ = new Subject();

	areThereProject = 0;

	public formGroup: UntypedFormGroup;
	idiomas: IdiomaModel[] = [];
	idUser: number;

  constructor(
    public translateService: TranslateService,
    public coursesService: CoursesService,
    public loginService: LoginService,
    public mastersService: GetDataService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public database: DynamicDatabase,
    public router: Router,
    public userService:UsersService,
    private groupService: GruposService,
		private socketService:SocketService,
    private fb: UntypedFormBuilder,
    private imagePipe: ImagenPipe,
    private getDataService: GetDataService,
		private toaster: ToasterService
  ) {
		this.formGroup = this.fb.group({
			image: [{ value: '', disabled: false }],
			title: ['', Validators.compose([Validators.required])],
			description: ['', Validators.compose([Validators.required])],
			langGroup: this.fb.group({
					lang1: [38, Validators.compose([Validators.required])]
			}),
			published : [false]
	})
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe())
		this.destroy$.next(true);
	}

  ngOnInit() {
		if(!this.isStudent){
			this.getInvitacionesGrupos();
			this.groupService.getListCursos(this.group.idGroup).subscribe(res => {
				this.areThereProject = res.data.length;
			})
			this.idUser = this.loginService.getUser().idUser
		}
		else
		{
			this.pachValues();
			this.obtenerDatosMaestros();
			//Si es estudiante, tenemos que comprobar si existe una actividad instantanea creada
			// this.groupService.getIfSessionExists(this.group.idGroup).pipe(finalize( () => this.cargando = false )).subscribe(result =>{
			// 	this.showActInst = result.data
			// })
		}
  }

	pachValues() {
    this.formGroup.patchValue({
      title: this.group.title || '',
      description: this.group.description || '',
      langGroup: {
          lang1: this.group.idLanguage || 0
      },
      image: this.group.imagen ? this.imagePipe.transform(this.group.imagen, 'group') : '',
  })
  }

	obtenerDatosMaestros() {
    this.getDataService.getAllLanguages().pipe(take(1)).subscribe(data => this.idiomas = data)
  }

  getInvitacionesGrupos() {
    this.groupService.getPeticionesProfesor(this.group.idGroup).pipe(finalize( () => this.cargando = false)).subscribe((res: any) => {
      this.numberOfInvited = res.data.length;
      this.invitacionesList  = res.data;
			this.cargando = false;
    },err => console.log(err))
  }

  aceptar(invitacion:any){
    this.groupService.aceptarInvitacion(this.group.idGroup,invitacion.idUser).subscribe((res: any) => {
      this.toaster.success(this.translateService.instant('GRUPOS.INVITATIONACEPTED'));
      this.getInvitacionesGrupos();
    },err => console.log(err))
  }

  rechazar(invitacion:any){

    this.groupService.rechazarInvitacion(this.group.idGroup, invitacion.idUser).subscribe((res: any) => {
      this.toaster.success(this.translateService.instant('GRUPOS.INVITATIONREJECTED'));
      this.getInvitacionesGrupos();

    },err => console.log(err))
  }

  closeModal(sendData?: any) {
    this.activeModal.close( sendData);
  }

  modificarGrupo(){
    const modalRef = this.modalService.open(ModalEditarGrupoComponent, { scrollable: false, windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` });
    modalRef.componentInstance.id = this.group.idGroup;
    modalRef.componentInstance.group = this.group;

    modalRef.result.then((result) => {
      if (result !== '') {
        if (result === 'delete') {
          this.closeModal('delete');
        }
        else {
          this.group = result as GroupModel;
        }
       }
    }, (err) => { })
  }

  listadoAlumnos(){
    const modalRef = this.modalService.open(ModalListadoEstudiantesComponent, { scrollable: false, windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` });
    modalRef.componentInstance.id = this.group.idGroup;
    modalRef.componentInstance.group = this.group;

    modalRef.result.then((result) => {
      this.getInvitacionesGrupos();
    }, (err) => { })
  }

  listadoCursos(){

		if(this.isStudent){
			const modalRef = this.modalService.open(ModalCursosGrupoComponent, { scrollable: false, windowClass: `${MODAL_DIALOG_TYPES.W95} h-100` });
			modalRef.componentInstance.id = this.group.idGroup;
			modalRef.componentInstance.group = this.group;
			modalRef.result.then( () => {})
		}

		else{
			const modalRef = this.modalService.open(ModalListadoCursosComponent, { scrollable: false, windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` });
			modalRef.componentInstance.id = this.group.idGroup;
			modalRef.componentInstance.group = this.group;
			modalRef.result.then(() => { this.getInvitacionesGrupos() })
		}
  }

  informesGrupo(){
    const modalRef = this.modalService.open(ReportingComponent, { scrollable: false, windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` });
    modalRef.componentInstance.idGrupo = this.group.idGroup;
    modalRef.componentInstance.group = this.group;

    modalRef.result.then((result) => {
      this.getInvitacionesGrupos();
    }, (err) => { })
  }

	instantActivity():void{
		if (this.group.assignedProjects === 0 && !this.loginService.esEstudiante) {
			this.toaster.error(
				this.translateService.instant("INFORMES.WITHOUTPROJECTS")
			);
		} else {
			this.groupService
				.getIfSessionExists(this.group.idGroup)
				.pipe(takeUntil(this.destroy$), take(1))
				.subscribe((result) => {
					this.cargando = false;
					this.showActInst = result.data.existSesion;
					if (this.showActInst === true) {
						//Opción para crear actividades instantáneas. Si es un profesor, se debe crear; si es un estudiante, tiene que poder entrar para conectarse con websocket
						//Si es estudiante, tengo que sacar el modal para esperar los movimientos del websocket
						let modal: NgbModalRef;
						const idGroup = this.group.idGroup;
						const msgSubs = this.socketService.msgFromServer
							.pipe(takeUntil(this.destroy$))
							.subscribe((data) => {
								//Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
								const dataArray = data.split(":");
								const type = dataArray[0];
								const onlineUsers: number = parseInt(dataArray[1]);
								switch (type) {
									case SOCKETMESSAGES.USERSCOUNTER:
										if (this.loginService.esEstudiante()) {
											//Si es estudiante, debo abrir el modal de espera para que se vayan abriendo los quizzes según decida el profesor
											this.groupService
												.getIfSessionExists(this.group.idGroup)
												.pipe(takeUntil(this.destroy$), take(1))
												.subscribe((result) => {
													this.cargando = false;
													this.showActInst = result.data.existSesion;
													if (this.showActInst && onlineUsers > 1) {
														this.groupService
															.getIfSessionExistsByUser(this.group.idGroup)
															.pipe(takeUntil(this.destroy$), take(1))
															.subscribe((res) => {
																if (!res.data) {
																	modal = this.modalService.open(
																		SocketQuizPreviewComponent,
																		{
																			scrollable: false,
																			windowClass: `${MODAL_DIALOG_TYPES.W100} h-100`,
																		}
																	);
																	modal.componentInstance.idGroup =
																		this.group.idGroup;
																	modal.componentInstance.onlineUsers =
																		onlineUsers;
																	modal.result
																		.then(
																			() => {
																				this.destroy$.next(true);
																				this._removeSocket(idGroup);
																			},
																			(err) => {}
																		)
																		.finally(() => {});
																}
															});
													} else {
														this.toaster.error(
															this.translateService.instant(
																"INSTANTACTIVITIES.NOHAYDESAFIOS"
															)
														);
													}
												});
										} else {
											modal = this.modalService.open(
												ModalSocketCoursesComponent,
												{
													scrollable: true,
													windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
												}
											); // Modal con el listado de cursos, para que el profesor pueda elegir los quizzes a realizar
											modal.componentInstance.group = this.group;
											modal.componentInstance.onlineUsers = onlineUsers;
											modal.result
												.then(
													() => {
														this.destroy$.next(true);
														this._removeSocket(idGroup);
													},
													(err) => {}
												)
												.finally(() => {});

											msgSubs.unsubscribe();
										}
										break;
								}
							});
						this.subscriptions.push(msgSubs);
						this.socketService.createSocket(idGroup); //Creo el socket
						const openSocketSub = this.socketService.openSocket.subscribe(
							(ev) => {
								//Cuando se cree el socket, procedemos a guardar la sesion en el servidor
								this.groupService.createGroupSession(idGroup).subscribe(
									(result) => {
										//Si se guarda correctamente la sesion, tenemos que abrir el modal con los cursos en modo lista
										this.socketService.idSession = result.data.idSession;
										openSocketSub.unsubscribe();
									},
									(err) => {
										//modal.close()
									}
								);
							}
						);
						this.subscriptions.push(openSocketSub);
					} else {
						this.toaster.error(
							this.translateService.instant("INSTANTACTIVITIES.NOHAYDESAFIOS")
						);
					}
				});
		}
	}

	private _removeSocket(idGroup){
		this.socketService.removeSocket() //Si ha habido error al guardar la sesión, tenemos que destruir el socket y cerrar el modal
		this.groupService.deleteGroupSession(idGroup).subscribe() //Elimino la sesión de la bd
		this.subscriptions.forEach(s => s.unsubscribe()) //Elimino las suscripciones a eventos
	}
}
