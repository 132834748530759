<div class="sticky-top">
    <div class="modal-header" align="center">
        <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'SETTINGS.AUTORTITLE' | translate }}</span>

        <button type="button" class="close" data-dismiss="modal" (click)="closeModal('')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>

<div class='fondoEditarCurso h-100' [ngClass]="userService.getColorByRol()">

    <div class="col-12">
        <app-alert context="UserData"></app-alert>
    </div>

    <form [formGroup]="formularioDatos" (ngSubmit)="grabarDatos()" #thisForm="ngForm" class="m-2 m-sm-5 p-4 card">

        <div class="row">
            <div class="col-sm-12" align="center">
                <span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'USERDATA.COMPLETATUPERFILCONLOSSIGUIENTESDATOS' | translate }}</span>
            </div>
        </div>

        <div class="row mt-4">

            <div class="col-sm-2"></div>

            <div class="col-sm-8">

                <div>
                    <div class="row">

                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">account_balance</i>
                        </div>

                        <div class="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.BICSWIFT' | translate }}</mat-label>
                                <input matInput formControlName="bic" mask="AAAAAAAAAAA">
                                <mat-error *ngFor="let validation of validationMessages.bic">
                                    <mat-error class="error-message break-line" *ngIf="formularioDatos.get('bic')?.hasError(validation.type) && (formularioDatos.get('bic')?.dirty || formularioDatos.get('bic')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group pt-1">

                        <div class="col-2" align="center"></div>

                        <div class="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.IBAN' | translate }}</mat-label>
                                <input matInput formControlName="iban" mask="AA00 0000 0000 00 0000000000">
                                <mat-error *ngFor="let validation of validationMessages.iban">
                                    <mat-error class="error-message break-line" *ngIf="formularioDatos.get('iban')?.hasError(validation.type) && (formularioDatos.get('iban')?.dirty || formularioDatos.get('iban')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-9">
                    </div>

                    <div class="col-md-3 col-sm-6" align="right">
                        <button class="botonLoginEntrar" type="submit">
                            <span>{{ 'USERDATA.GRABAR' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-sm-2"></div>
        </div>
    </form>

    <!-- <app-loading [isVisible]="loading"></app-loading> -->
</div>
