import { TemplateModel } from './../../../core/models/quizzes/template.model';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PatternFormComponent } from './pattern-form/pattern-form.component';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { LoginService } from 'src/app/core/services/login';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';


@Component({
    selector: 'app-patterns',
    templateUrl: './patterns.component.html',
    styleUrls: ['./patterns.component.scss']
})
export class PatternsComponent implements OnInit {
    public directionLinks: boolean = true;
    public autoHide: boolean = false;
    public responsive: boolean = true;
    isShowFavourites: boolean = false

    constructor(private activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private loginService: LoginService) {
    }

    public templates: TemplateModel[] = [];
    public templatesBackup: TemplateModel[] = [];

    public cargando: boolean;
    public quiz: any;
    public flagFavorites: boolean;
    public idUser: number;

    public form: UntypedFormGroup = new UntypedFormGroup({
        options: new UntypedFormControl(false),
        writable: new UntypedFormControl(false),
        manual: new UntypedFormControl(false),
        compu: new UntypedFormControl(false),
        noMulti: new UntypedFormControl(false),
        multi: new UntypedFormControl(false),
        qVideo: new UntypedFormControl(0),
        qPicture: new UntypedFormControl(0),
        qAudio: new UntypedFormControl(0),
        qPdf: new UntypedFormControl(0),
        qText: new UntypedFormControl(0),
        oVideo: new UntypedFormControl(0),
        oPicture: new UntypedFormControl(0),
        oAudio: new UntypedFormControl(0),
        oPdf: new UntypedFormControl(0),
        oText: new UntypedFormControl(0),
        label: new UntypedFormControl('')
    });

    ngOnInit() {
        this.cargando = true;
        this.flagFavorites = false;
        this.idUser = this.loginService.getUser().idUser;
        this._getTemplates()
        this.cargando = false;
        this.form.valueChanges.subscribe(() => { });
    }

    private _getTemplates() {


    }

    searchPatterns() {

    }

    public createPatterns() {
        const modalRef = this.modalService.open(PatternFormComponent,
            {
                scrollable: true,
                windowClass: MODAL_DIALOG_TYPES.W85
            });
        modalRef.componentInstance.quiz = this.quiz // --> VALID LINE
    }

    setFavourite(event, idTemplate: number, index: number) {
        event.stopPropagation();
    }

    getFavourites() {
        this.isShowFavourites = !this.isShowFavourites;
        this.isShowFavourites ? this.templates = this.templates.filter(e => e.isFavorite) : this.templates = this.templatesBackup
    }


    closeModal(sendData?: any) {
        this.activeModal.close(sendData);
    }

    public withValue() {
        if (this.form.value.options == true || this.form.value.writable == true || this.form.value.manual == true ||
            this.form.value.compu == true || this.form.value.noMulti == true || this.form.value.multi == true ||
            this.form.value.qVideo > 0 || this.form.value.qPicture > 0 || this.form.value.qAudio > 0 ||
            this.form.value.qPdf > 0 || this.form.value.qText > 0 || this.form.value.oVideo > 0 ||
            this.form.value.oPicture > 0 || this.form.value.oAudio > 0 || this.form.value.oPdf > 0 ||
            this.form.value.oText > 0 || this.form.value.label != '') {
            return true;
        } else {
            return false;
        }
    }
}
