<nb-card class="no-edge-card modal-max-height-100-65 mb-0">
	<nb-card-header class="border-bottom-0">
		<!-- Titulo del menu -->
		<div class="row">
			<div class="col-10">
				<h4>{{ "MENUACTIONS.MENU" | translate }} - {{ profileTitle }}</h4>
			</div>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-body class="pt-0">
		<!-- ============ MENU ESTUDIANTE =============== -->
		<div class="container" *ngIf="profile == 'ESTUDIANTE'">
			<div class="d-flex gap-5 pb-xl-3 pb-0">
				<div class="d-flex flex-column justify-content-center align-items-center gap-3">
					<!-- +++++++++ Bloque 1 ++++++++++++ -->
					<div class="d-flex gap-4 p-4 w-100 justify-content-center align-content-center background_themecolor">
						<!-- Info sobre el grupo -->
						<div class="d-flex justify-content-center align-items-center">
							<div *ngIf="selectedGroupInfo && profile === 'ESTUDIANTE'" class="d-flex flex-column gap-2 align-items-center justify-content-center">
								<div class="d-flex flex-column justify-content-center align-items-center">
									<!-- Titulo del grupo -->
									<h5 class="text-group m-0">
										{{ selectedGroupInfo.title }}
									</h5>
									<!-- Codigo del grupo -->
									<h6 class="m-0" style="font-size: 1rem;">
										{{ "ALERTGROUP.GRUPOACTUAL" | translate }}: {{selectedGroupInfo.group.identification | truncate : [50,"..."]}}
									</h6>
								</div>
								<div class="d-flex gap-3 justify-content-center align-items-center">
									<!-- boton calendario en version movil -->
									<div *ngIf="isMobile" class="d-flex justify-content-center align-items-center position-relative pointer"
										[nbPopover]="calendarTemplate" [nbPopoverPlacement]="position" [nbPopoverTrigger]="trigger">
										<nb-icon icon="calendar-outline"></nb-icon>
										<span class="notification-badge">
											{{ notificationCount }}
										</span>
									</div>
								</div>
								<!-- Boton para salir del grupo -->
								<button nbButton class="btn-red" size="small" (click)="noGroup()">
									{{ 'MODALGROUPSEDIT.SELFEDUCATE' | translate }}
									<nb-icon icon="log-out-outline"></nb-icon>
								</button>
							</div>
							<div *ngIf="!selectedGroupInfo" class="d-flex flex-column gap-2 justify-content-center align-items-center">
								<!-- boton calendario en version movil -->
								<div *ngIf="isMobile" class="d-flex justify-content-center align-items-center position-relative pointer"
									[nbPopover]="calendarTemplate" [nbPopoverPlacement]="position" [nbPopoverTrigger]="trigger">
									<nb-icon icon="calendar-outline"></nb-icon>
									<span class="notification-badge">
										{{ notificationCount }}
									</span>
								</div>
								<!-- Aviso que se muestra cuando no estamos en un grupo -->
								<div class="text-center">
									<p class="m-0" style="font-weight: 700;">
										{{ "ALERTGROUP.ENTERGROUP" | translate }}
										{{ selectedGroupInfo ? selectedGroupInfo.title : "" }}
									</p>
								</div>
							</div>
						</div>
						<!-- Calendario en version desktop -->
						<div *ngIf="!isMobile && selectedGroupInfo" class="d-flex">
							<nb-card class="mb-0 no-edge-card">
								<div [ngSwitch]="view" class="CalendarStyle">
									<mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" class="w-100" [viewDate]="viewDate"
										[events]="professorEvents" [locale]="locale" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
										(dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)"
										(eventTimesChanged)="eventTimesChanged($event)" [weekStartsOn]="1"
										[cellTemplate]="customCellTemplatee" [excludeDays]="excludeDays">
									</mwl-calendar-month-view>
									<ng-template #customCellTemplatee let-day="day" let-event="event" let-locale="locale">
										<div class="cal-cell-top">
											<span class="cal-day-badge" *ngIf="day.badgeTotal > 0">
												{{day.badgeTotal}}
											</span>
											<span class="cal-day-number">
												{{day.date | calendarDate : "monthViewDayNumber" : locale}}
											</span>
										</div>
									</ng-template>
								</div>
							</nb-card>
						</div>
					</div>
					<!-- +++++++++ Bloque 2 ++++++++++++ -->
					<div class="d-flex gap-3 flex-xl-row flex-wrap justify-content-center align-items-center" style="flex: 1">
						<!-- Grupo -->
						<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE6' | translate }}"
							nbTooltipPlacement="top" (click)="goModule('groups')"
							[ngClass]="{ pulsating: selectedGroupInfo == null }">
							<h6>{{ "MENUACTIONS.STUDENTTITLE6" | translate }}</h6>
							<div class="item-image-small">
								<img src="../../../../assets/images/control-panel/grupos.png" width="50" />
							</div>
						</div>
						<!-- Flecha que indica la ruta a seguir -->
						<nb-icon *ngIf="!isMobile" icon="arrow-forward-outline"></nb-icon>
						<!-- Estudiar -->
						<div class="item-style item-small" nbTooltip="{{ 'SIDEBAR.ESTUDIAR' | translate }}"
							nbTooltipPlacement="top" (click)="goModule('projects')">
							<h6>{{ "SIDEBAR.ESTUDIAR" | translate }}</h6>
							<div class="item-image-small">
								<img src="../../../../assets/images/control-panel/idea.png" width="35" />
							</div>
						</div>
						<!-- Flecha que indica la ruta a seguir -->
						<nb-icon *ngIf="!isMobile" icon="arrow-forward-outline"></nb-icon>
						<!-- Informe -->
						<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE6' | translate }}"
							nbTooltipPlacement="top" (click)="informesStudentGrupo()">
							<h6>{{ "MENUACTIONS.STUDENTTITLE8" | translate }}</h6>
							<div class="card-image">
								<div class="icon-option icon-informes pointer"></div>
							</div>
						</div>
						<!-- Flecha que indica la ruta a seguir -->
						<nb-icon *ngIf="!isMobile" icon="arrow-forward-outline"></nb-icon>
					</div>
					<!-- +++++++++ Opciones del panel derecho que solo se muestran en la version movil ++++++++++++ -->
					<div *ngIf="isMobile" class="d-flex gap-3 my-5 flex-xl-row flex-wrap justify-content-center">
						<!-- Desafios -->
						<div class="item-style item-tiny" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE7' | translate }}"
							nbTooltipPlacement="top" (click)="instantActivityStudent()"
							[ngClass]="{ 'disabled-card': disableActivityButton == true, 'alert-low-conection': networkSpeed < 1 }">
							<!-- Con buena conexion -->
							<div *ngIf="networkSpeed >= 1">
								<h6>{{ "MENUACTIONS.STUDENTTITLE7" | translate }}</h6>
								<div class="card-image">
									<div class="icon-option icon-desafios-icon pointer"></div>
								</div>
							</div>
							<!-- Con mala conexion -->
							<div *ngIf="networkSpeed < 1" class="alert-content">
								<h6>{{ "MENUACTIONS.STUDENTTITLE7" | translate }}</h6>
								<nb-icon icon="alert-triangle-outline"></nb-icon>
								<p class="icon-text">{{ 'GLOBALS.BLOCKEDSLOWCONECTION' | translate }}</p>
							</div>
						</div>
						<!-- Pensar creando -->
						<div class="item-style item-tiny" nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE18' | translate }}"
							nbTooltipPlacement="top" (click)="pensarCreando()">
							<h6>{{ "MENUACTIONS.TEACHERTITLE18" | translate }}</h6>
							<div class="item-image-tiny">
								<img src="../../../../assets/images/control-panel/idea.png" width="35" />
							</div>
						</div>
						<!-- Actividades Flash -->
						<div class="item-style item-tiny" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE3' | translate }}"
							nbTooltipPlacement="top" (click)="goModule('actividadesFlash')">
							<h6>{{ "MENUACTIONS.STUDENTTITLE3" | translate }}</h6>
							<div class="card-image">
								<div class="icon-option icon-recordar-baraja pointer"></div>
							</div>
						</div>
						<!-- Competir -->
						<div class="item-style item-tiny" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE4' | translate }}"
							nbTooltipPlacement="top" (click)="Competitions()"
							[ngClass]="{ 'disabled-card': noDataUser, 'alert-low-conection': networkSpeed < 1 }">
							<!-- Con buena conexion -->
							<div *ngIf="networkSpeed >= 1">
								<h6>{{ "MENUACTIONS.STUDENTTITLE4" | translate }}</h6>
								<div *ngIf="noDataUser == false" class="item-image-tiny">
									<img src="../../../../assets/images/control-panel/candidates.png" width="35" />
								</div>
								<!-- Registro sin datos -->
								<div *ngIf="noDataUser == true" class="danger-register">
									<nb-icon icon="alert-triangle-outline" status="danger"></nb-icon>
									<p>
										{{ "MENUACTIONS.REGISTERWITHDATA" | translate }}
									</p>
								</div>
							</div>
							<!-- Con mala conexion -->
							<div *ngIf="networkSpeed < 1" class="alert-content">
								<h6>{{ "MENUACTIONS.STUDENTTITLE4" | translate }}</h6>
								<nb-icon icon="alert-triangle-outline"></nb-icon>
								<p class="icon-text">{{ 'GLOBALS.BLOCKEDSLOWCONECTION' | translate }}</p>
							</div>
						</div>
						<!-- S O S -->
						<div class="item-style item-tiny" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE8' | translate }}"
							nbTooltipPlacement="top" (click)="sos()" [ngClass]="{ 'disabled-card': noDataUser }">
							<h6>{{ "MENUACTIONS.STUDENTTITLE5" | translate }}</h6>
							<div *ngIf="noDataUser == false" class="item-image-tiny">
								<img src="../../../../assets/images/control-panel/sos.png" width="35" />
							</div>
							<!-- Registro sin datos -->
							<div *ngIf="noDataUser == true" class="danger-register">
								<nb-icon icon="alert-triangle-outline" status="danger"></nb-icon>
								<p>
									{{ "MENUACTIONS.REGISTERWITHDATA" | translate }}
								</p>
							</div>
						</div>
					</div>
					<!-- +++++++++ Bloque 3 (footer) +++++++++++++ -->
					<div class="d-flex p-xl-5 p-3 w-100 flex-xl-row flex-wrap justify-content-center align-content-center background_themecolor"
						[ngClass]="isMobile ? 'gap-3' : 'gap-5'">
						<!-- Editar -->
						<div class="item-style item-tiny" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE1' | translate }}"
							nbTooltipPlacement="top" (click)="goModule('edit')" [ngClass]="{ 'disabled-card': noDataUser }">
							<h6>{{ "MENUACTIONS.STUDENTTITLE1" | translate }}</h6>
							<div *ngIf="noDataUser == false" class="item-image-tiny">
								<img src="../../../../assets/images/control-panel/compose.png" width="35" />
							</div>
							<!-- Registro sin datos -->
							<div *ngIf="noDataUser == true" class="danger-register">
								<nb-icon icon="alert-triangle-outline" status="danger"></nb-icon>
								<p>
									{{ "MENUACTIONS.REGISTERWITHDATA" | translate }}
								</p>
							</div>
						</div>
						<!-- proyectos -->
						<div class="item-style item-tiny" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE2' | translate }}"
							nbTooltipPlacement="top" (click)="explorarContenido()">
							<h6>{{ "MENUACTIONS.EXPLORE" | translate }}</h6>
							<div class="item-image-tiny">
								<img src="../../../../assets/images/control-panel/magnifying-glass.png" width="35" />
							</div>
						</div>
						<!-- Ajustes -->
						<div class="item-style item-tiny" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE9' | translate }}"
							nbTooltipPlacement="top" (click)="ajustes()">
							<h6>{{ "MENUACTIONS.STUDENTTITLE9" | translate }}</h6>
							<div class="item-image-tiny">
								<img src="../../../../assets/images/control-panel/gear.png" width="35" />
							</div>
						</div>
					</div>
				</div>
				<!-- ++++++++++ Opciones del panel derecho cuando estamos en la version desktop +++++++++++++ -->
				<div *ngIf="!isMobile" class="d-flex flex-column gap-3">
					<!-- Desafios -->
					<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE7' | translate }}"
						nbTooltipPlacement="top" (click)="instantActivityStudent()"
						[ngClass]="{ 'disabled-card': disableActivityButton == true, 'alert-low-conection': networkSpeed < 1 }">
						<!-- Con buena conexion -->
						<div *ngIf="networkSpeed >= 1">
							<h6>{{ "MENUACTIONS.STUDENTTITLE7" | translate }}</h6>
							<div class="card-image">
								<div class="icon-option icon-desafios-icon pointer"></div>
							</div>
						</div>
						<!-- Con mala conexion -->
						<div *ngIf="networkSpeed < 1" class="alert-content">
							<h6>{{ "MENUACTIONS.STUDENTTITLE7" | translate }}</h6>
							<nb-icon icon="alert-triangle-outline"></nb-icon>
							<p class="icon-text">{{ 'GLOBALS.BLOCKEDSLOWCONECTION' | translate }}</p>
						</div>
					</div>
					<!-- Pensar creando -->
					<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE18' | translate }}"
						nbTooltipPlacement="top" (click)="pensarCreando()">
						<h6>{{ "MENUACTIONS.TEACHERTITLE18" | translate }}</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/idea.png" width="50" />
						</div>
					</div>
					<!-- Actividades Flash -->
					<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE3' | translate }}"
						nbTooltipPlacement="top" (click)="goModule('actividadesFlash')">
						<h6>{{ "MENUACTIONS.STUDENTTITLE3" | translate }}</h6>
						<div class="card-image">
							<div class="icon-option icon-recordar-baraja pointer"></div>
						</div>
					</div>
					<!-- Competir -->
					<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE4' | translate }}"
						nbTooltipPlacement="top" (click)="Competitions()"
						[ngClass]="{ 'disabled-card': noDataUser, 'alert-low-conection': networkSpeed < 1 }">
						<!-- Con buena conexion -->
						<div *ngIf="networkSpeed >= 1">
							<h6>{{ "MENUACTIONS.STUDENTTITLE4" | translate }}</h6>
							<div *ngIf="noDataUser == false" class="item-image-small">
								<img src="../../../../assets/images/control-panel/candidates.png" width="50" />
							</div>
							<!-- Registro sin datos -->
							<div *ngIf="noDataUser == true" class="danger-register">
								<nb-icon icon="alert-triangle-outline" status="danger"></nb-icon>
								<p>
									{{ "MENUACTIONS.REGISTERWITHDATA" | translate }}
								</p>
							</div>
						</div>
						<!-- Con mala conexion -->
						<div *ngIf="networkSpeed < 1" class="alert-content">
							<h6>{{ "MENUACTIONS.STUDENTTITLE4" | translate }}</h6>
							<nb-icon icon="alert-triangle-outline"></nb-icon>
							<p class="icon-text">{{ 'GLOBALS.BLOCKEDSLOWCONECTION' | translate }}</p>
						</div>
					</div>
					<!-- S O S -->
					<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE8' | translate }}"
						nbTooltipPlacement="top" (click)="sos()" [ngClass]="{ 'disabled-card': noDataUser }">
						<h6>{{ "MENUACTIONS.STUDENTTITLE5" | translate }}</h6>
						<div *ngIf="noDataUser == false" class="item-image-small">
							<img src="../../../../assets/images/control-panel/sos.png" width="50" />
						</div>
						<!-- Registro sin datos -->
						<div *ngIf="noDataUser == true" class="danger-register">
							<nb-icon icon="alert-triangle-outline" status="danger"></nb-icon>
							<p>
								{{ "MENUACTIONS.REGISTERWITHDATA" | translate }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ============= MENU PROFESOR ================ -->
		<div class="d-flex justify-content-center" *ngIf="profile == 'PROFESOR'">
			<div class="d-flex gap-4 pb-xl-3 pb-0">
				<div class="d-flex flex-column justify-content-center align-items-center gap-3">
					<!-- +++++++++ Bloque 1 ++++++++++++ -->
					<div class="d-flex gap-4 p-4 w-100 justify-content-center align-content-center background_themecolor">
						<!-- Info sobre el grupo -->
						<div class="d-flex justify-content-center align-items-center">
							<div *ngIf="selectedGroupInfo && profile === 'PROFESOR'" class="d-flex flex-column gap-2 align-items-center justify-content-center">
								<div class="d-flex flex-column justify-content-center align-items-center">
									<!-- Titulo del grupo -->
									<h5 class="text-group m-0">
										{{ selectedGroupInfo.title }}
									</h5>
									<!-- Codigo del grupo -->
									<h6 class="m-0" style="font-size: 1rem;">
										{{ "ALERTGROUP.GRUPOACTUAL" | translate }}: {{selectedGroupInfo.group.identification | truncate : [50,"..."]}}
									</h6>
								</div>
								<div class="d-flex gap-3 justify-content-center align-items-center">
									<!-- Cantidad de alumnos en el grupo -->
									<div *ngIf="selectedGroupInfo" class="pointer position-relative d-flex justify-content-center align-items-end gap-2"
										[nbPopover]="templateRef" nbPopoverPlacement="bottom"
										[ngbTooltip]="'SOCKETQUIZPREVIEW.ONLINEUSERS' | translate" (click)="prefillOfflineUsers()">
										<span class="dot position-dot"></span>
										<nb-icon icon="people-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon>
										<span> {{ onlineUsers }} / {{ usersInGroupCounter }} </span>
									</div>
									<div *ngIf="isMobile" class="separator"></div>
									<!-- boton calendario en version movil -->
									<div *ngIf="isMobile" class="d-flex justify-content-center align-items-center position-relative pointer"
										[nbPopover]="calendarTemplate" [nbPopoverPlacement]="position" [nbPopoverTrigger]="trigger">
										<nb-icon icon="calendar-outline"></nb-icon>
										<span class="notification-badge">
											{{ notificationCount }}
										</span>
									</div>
								</div>
								<!-- Template para mostrar los alumnos online -->
								<ng-template #templateRef>
									<nb-card [size]="'tiny'" accent="success" class="mb-0 mt-1">
										<nb-card-header>{{"SOCKETQUIZPREVIEW.INCLASSSTUDENTS" | translate}}</nb-card-header>
										<nb-card-body>
											<nb-list>
												<nb-list-item *ngFor="let user of usuariosOnline">
													<span class="dot"></span>{{ user.firstName }}
													{{ user.surname }}
												</nb-list-item>
											</nb-list>
										</nb-card-body>
									</nb-card>

									<nb-card [size]="'tiny'" accent="danger" class="mb-0">
										<nb-card-header>{{"SOCKETQUIZPREVIEW.OUTCLASSSTUDENTS" | translate}}</nb-card-header>
										<nb-card-body>
											<nb-list>
												<nb-list-item *ngFor="let user of usuariosOffline">
													<span class="dot-red"></span>{{ user.firstName }}
													{{ user.surname }}
												</nb-list-item>
											</nb-list>
										</nb-card-body>
									</nb-card>
								</ng-template>
								<!-- Boton para salir del grupo -->
								<button nbButton class="btn-red" size="small" (click)="noGroup()">
									{{ 'MODALGROUPSEDIT.SELFEDUCATE' | translate }}
									<nb-icon icon="log-out-outline"></nb-icon>
								</button>
							</div>
							<div *ngIf="!selectedGroupInfo" class="d-flex flex-column gap-2 justify-content-center align-items-center">
								<!-- boton calendario en version movil -->
								<div *ngIf="isMobile" class="d-flex justify-content-center align-items-center position-relative pointer"
									[nbPopover]="calendarTemplate" [nbPopoverPlacement]="position" [nbPopoverTrigger]="trigger">
									<nb-icon icon="calendar-outline"></nb-icon>
									<span class="notification-badge">
										{{ notificationCount }}
									</span>
								</div>
								<!-- Aviso que se muestra cuando no estamos en un grupo -->
								<div class="text-center">
									<p class="m-0" style="font-weight: 700;">
										{{ "ALERTGROUP.ENTERGROUP" | translate }}
										{{ selectedGroupInfo ? selectedGroupInfo.title : "" }}
									</p>
								</div>
							</div>
						</div>
						<!-- Calendario en version desktop -->
						<div *ngIf="!isMobile" class="d-flex">
							<nb-card class="mb-0 no-edge-card">
								<div [ngSwitch]="view" class="CalendarStyle">
									<mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" class="w-100" [viewDate]="viewDate"
										[events]="professorEvents" [locale]="locale" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
										(dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)"
										(eventTimesChanged)="eventTimesChanged($event)" [weekStartsOn]="1"
										[cellTemplate]="customCellTemplatee" [excludeDays]="excludeDays">
									</mwl-calendar-month-view>
									<ng-template #customCellTemplatee let-day="day" let-event="event" let-locale="locale">
										<div class="cal-cell-top">
											<span class="cal-day-badge" *ngIf="day.badgeTotal > 0">
												{{day.badgeTotal}}
											</span>
											<span class="cal-day-number">
												{{day.date | calendarDate : "monthViewDayNumber" : locale}}
											</span>
										</div>
									</ng-template>
								</div>
							</nb-card>
						</div>
						<!-- Template del calendario en version movil -->
						<ng-template #calendarTemplate>
							<nb-card class="mb-0 no-edge-card">
								<div [ngSwitch]="view" class="CalendarStyle">
									<mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" class="w-100"
										[viewDate]="viewDate"
										[events]="professorEvents"
										[locale]="locale"
										[refresh]="refresh"
										[activeDayIsOpen]="activeDayIsOpen"
										(dayClicked)="dayClicked($event.day)"
										(eventClicked)="handleEvent('Clicked', $event.event)"
										(eventTimesChanged)="eventTimesChanged($event)"
										[weekStartsOn]="1"
										[cellTemplate]="customCellTemplatee"
										[excludeDays]="excludeDays">
									</mwl-calendar-month-view>
									<ng-template #customCellTemplatee let-day="day" let-event="event" let-locale="locale">
										<div class="cal-cell-top">
											<span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
											<span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
										</div>
									</ng-template>
								</div>
							</nb-card>
						</ng-template>
					</div>
					<!-- +++++++++ Bloque 2 +++++++++++++ -->
					<div class="d-flex gap-3 flex-xl-row flex-wrap justify-content-center align-items-center" [ngClass]="{ 'h-290': !isMobile }">
						<!-- Grupo -->
						<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE4' | translate }}"
							nbTooltipPlacement="top" (click)="goModule('groups')"
							[ngClass]="{ pulsating: selectedGroupInfo == null }">
							<h6>{{ "MENUACTIONS.TEACHERTITLE4" | translate }}</h6>
							<div class="item-image-small">
								<img src="../../../../assets/images/control-panel/grupos.png" width="50" />
							</div>
						</div>
						<!-- Flecha que indica la ruta a seguir -->
						<nb-icon *ngIf="!isMobile" icon="arrow-forward-outline"></nb-icon>
						<!-- asistencia -->
						<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE11' | translate }}"
							nbTooltipPlacement="top" (click)="asistenciaGrupo()">
							<h6>{{ "MENUACTIONS.TEACHERTITLE11" | translate }}</h6>
							<div class="item-image-small">
								<img src="../../../../assets/images/control-panel/list.png" width="40" />
							</div>
						</div>
						<!-- Flecha que indica la ruta a seguir -->
						<nb-icon *ngIf="!isMobile" icon="arrow-forward-outline"></nb-icon>
						<!-- Informe -->
						<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE5' | translate }}"
							nbTooltipPlacement="top" (click)="informesGrupo(false)">
							<h6>{{ "MENUACTIONS.TEACHERTITLE5" | translate }}</h6>
							<div class="card-image">
								<div class="icon-option icon-informes pointer"></div>
							</div>
						</div>
						<!-- Flecha que indica la ruta a seguir -->
						<nb-icon *ngIf="!isMobile" icon="arrow-forward-outline"></nb-icon>
					</div>
					<!-- +++++++++ Opciones del panel derecho que solo se muestran en la version movil ++++++++++++ -->
					<div *ngIf="isMobile" class="d-flex gap-3 my-5 flex-xl-row flex-wrap justify-content-center">
						<!-- Explicacion asociativa -->
						<div class="item-style item-tiny"
							nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE15' | translate }}" nbTooltipPlacement="top"
							(click)="explicacionAsociativa()">
							<h6>{{ "MENUACTIONS.TEACHERTITLE15" | translate }}</h6>
							<div class="item-image-tiny">
								<img src="../../../../assets/images/control-panel/profesor.png" width="30" />
							</div>
						</div>
						<!-- Desafios -->
						<div class="item-style item-tiny"
							nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE7' | translate }}" nbTooltipPlacement="top"
							(click)="instantActivity()" [ngClass]="{'disabled-card':disableInstantActivityButton, 'alert-low-conection': networkSpeed < 1}">
							<!-- Con buena conexion -->
							<div *ngIf="networkSpeed >= 1">
								<h6>{{ "MENUACTIONS.TEACHERTITLE7" | translate }}</h6>
								<div class="card-image">
									<div class="icon-option icon-desafios-icon pointer"></div>
								</div>
							</div>
							<!-- Con mala conexion -->
							<div *ngIf="networkSpeed < 1" class="alert-content">
								<h6>{{ "MENUACTIONS.TEACHERTITLE7" | translate }}</h6>
								<nb-icon icon="alert-triangle-outline"></nb-icon>
								<p class="icon-text">{{ 'GLOBALS.ALERTSLOWCONECTION' | translate }}</p>
							</div>
						</div>
						<!-- Clase invertida -->
						<div class="item-style item-tiny"
							nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE16' | translate }}" nbTooltipPlacement="top"
							(click)="informesGrupo(true)">
							<h6>{{ "MENUACTIONS.TEACHERTITLE16" | translate }}</h6>
							<div class="item-image-tiny">
								<img src="../../../../assets/images/control-panel/presentacion.png" width="30" />
							</div>
						</div>
						<!-- Practicar ideografo -->
						<div class="item-style item-tiny"
							nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE17' | translate }}" nbTooltipPlacement="top"
							(click)="practicar()">
							<h6>{{ "MENUACTIONS.TEACHERTITLE17" | translate }}</h6>
							<div class="item-image-tiny">
								<img src="../../../../assets/images/control-panel/desarrollador.png" width="30" />
							</div>
						</div>
						<!-- Control -->
						<div class="item-style item-tiny"
							nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE20' | translate }}" nbTooltipPlacement="top"
							(click)="pruebaNivel()">
							<h6>{{ "MENUACTIONS.TEACHERTITLE21" | translate }}</h6>
							<div class="item-image-tiny">
								<img src="../../../../assets/images/control-panel/practica.png" width="30" />
							</div>
						</div>
						<!-- ABP y casos -->
						<div class="item-style item-tiny"
							nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE19' | translate }}" nbTooltipPlacement="top"
							(click)="ABPyCasos()">
							<h6>{{ "MENUACTIONS.TEACHERTITLE19" | translate }}</h6>
							<div class="item-image-tiny">
								<img src="../../../../assets/images/control-panel/estudio.png" width="30" />
							</div>
						</div>
						<!-- Pensar creando -->
						<div class="item-style item-tiny"
							nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE18' | translate }}" nbTooltipPlacement="top"
							(click)="pensarCreando()">
							<h6>{{ "MENUACTIONS.TEACHERTITLE18" | translate }}</h6>
							<div class="item-image-tiny">
								<img src="../../../../assets/images/control-panel/idea.png" width="30" />
							</div>
						</div>
					</div>
					<!-- +++++++++ Bloque 3 (footer) +++++++++++++ -->
					<div class="d-flex p-xl-5 p-3 w-100 flex-xl-row flex-wrap justify-content-center align-content-center background_themecolor"
						[ngClass]="isMobile ? 'gap-3' : 'gap-5'">
						<!-- Editar -->
						<div class="item-style item-tiny" nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE14' | translate }}"
							nbTooltipPlacement="top" (click)="goModule('edit')" [ngClass]="{ 'disabled-card': !isEditor }">
							<h6>{{ "MENUACTIONS.TEACHERTITLE14" | translate }}</h6>
							<div class="item-image-tiny">
								<img src="../../../../assets/images/control-panel/compose.png" width="35" />
							</div>
						</div>
						<!-- proyectos -->
						<div class="item-style item-tiny" nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE13' | translate }}"
							nbTooltipPlacement="top" (click)="goModule('projects')">
							<h6>{{ "MENUACTIONS.TEACHERTITLE13" | translate }}</h6>
							<div class="item-image-tiny">
								<img src="../../../../assets/images/control-panel/magnifying-glass.png" width="35" />
							</div>
						</div>
						<!-- Ajustes -->
						<div class="item-style item-tiny" nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE10' | translate }}"
							nbTooltipPlacement="top" (click)="goModule('settings')">
							<h6>{{ "MENUACTIONS.TEACHERTITLE10" | translate }}</h6>
							<div class="item-image-tiny">
								<img src="../../../../assets/images/control-panel/gear.png" width="35" />
							</div>
						</div>
					</div>
				</div>
				<!-- ++++++++++ Opciones del panel derecho cuando estamos en la version desktop +++++++++++++ -->
				<div *ngIf="!isMobile" class="d-flex">
					<div class="donut-menu">
						<div class="menu-options d-flex flex-wrap" [ngClass]="{ menu_options_group: selectedGroupInfo == null }">
							<!-- Explicacion asociativa -->
							<div class="item-style item-donut_menu option"
								nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE15' | translate }}" nbTooltipPlacement="top"
								(click)="explicacionAsociativa()">
								<h6>{{ "MENUACTIONS.TEACHERTITLE15" | translate }}</h6>
								<div class="item-image-donut-menu">
									<img src="../../../../assets/images/control-panel/profesor.png" width="30" />
								</div>
							</div>
							<!-- Desafios -->
							<div class="item-style item-donut_menu option"
								nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE7' | translate }}" nbTooltipPlacement="top"
								(click)="instantActivity()" [ngClass]="{'disabled-card':disableInstantActivityButton, 'alert-low-conection': networkSpeed < 1}">
								<!-- Con buena conexion -->
								<div *ngIf="networkSpeed >= 1">
									<h6>{{ "MENUACTIONS.TEACHERTITLE7" | translate }}</h6>
									<div class="card-image">
										<div class="icon-option icon-desafios-icon pointer"></div>
									</div>
								</div>
								<!-- Con mala conexion -->
								<div *ngIf="networkSpeed < 1" class="alert-content">
									<h6>{{ "MENUACTIONS.TEACHERTITLE7" | translate }}</h6>
									<nb-icon icon="alert-triangle-outline"></nb-icon>
									<p class="icon-text">{{ 'GLOBALS.ALERTSLOWCONECTION' | translate }}</p>
								</div>
							</div>
							<!-- Clase invertida -->
							<div class="item-style item-donut_menu option"
								nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE16' | translate }}" nbTooltipPlacement="top"
								(click)="informesGrupo(true)">
								<h6>{{ "MENUACTIONS.TEACHERTITLE16" | translate }}</h6>
								<div class="item-image-donut-menu">
									<img src="../../../../assets/images/control-panel/presentacion.png" width="30" />
								</div>
							</div>
							<!-- Practicar ideografo -->
							<div class="item-style item-donut_menu option"
								nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE17' | translate }}" nbTooltipPlacement="top"
								(click)="practicar()">
								<h6>{{ "MENUACTIONS.TEACHERTITLE17" | translate }}</h6>
								<div class="item-image-donut-menu">
									<img src="../../../../assets/images/control-panel/desarrollador.png" width="30" />
								</div>
							</div>
							<!-- Control -->
							<div class="item-style item-donut_menu option"
								nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE20' | translate }}" nbTooltipPlacement="top"
								(click)="pruebaNivel()">
								<h6>{{ "MENUACTIONS.TEACHERTITLE21" | translate }}</h6>
								<div class="item-image-donut-menu">
									<img src="../../../../assets/images/control-panel/practica.png" width="30" />
								</div>
							</div>
							<!-- ABP y casos -->
							<div class="item-style item-donut_menu option"
								nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE19' | translate }}" nbTooltipPlacement="top"
								(click)="ABPyCasos()">
								<h6>{{ "MENUACTIONS.TEACHERTITLE19" | translate }}</h6>
								<div class="item-image-donut-menu">
									<img src="../../../../assets/images/control-panel/estudio.png" width="30" />
								</div>
							</div>
							<!-- Pensar creando -->
							<div class="item-style item-donut_menu option"
								nbTooltip="{{ 'MENUACTIONS.TEACHERSUBTITLE18' | translate }}" nbTooltipPlacement="top"
								(click)="pensarCreando()">
								<h6>{{ "MENUACTIONS.TEACHERTITLE18" | translate }}</h6>
								<div class="item-image-donut-menu">
									<img src="../../../../assets/images/control-panel/idea.png" width="30" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ================ MENU EDITOR ==================== -->
		<div class="container" *ngIf="profile == 'AUTOR'">
			<div class="d-flex flex-wrap gap-5 p-5 justify-content-center align-items-center">
				<!-- proyectos -->
				<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE2' | translate }}"
					nbTooltipPlacement="top" (click)="goModule('projects')">
					<h6>{{ "MENUACTIONS.EXPLORE" | translate }}</h6>
					<div class="item-image-small">
						<img src="../../../../assets/images/control-panel/magnifying-glass.png" width="50" />
					</div>
				</div>
				<!-- Editar -->
				<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.AUTHORSUBTITLE1' | translate }}"
					nbTooltipPlacement="top" (click)="goModule('edit')">
					<h6>{{ "MENUACTIONS.AUTHORTITLE1" | translate }}</h6>
					<div class="item-image-small">
						<img src="../../../../assets/images/control-panel/compose.png" width="50" />
					</div>
				</div>
				<!-- nuevo proyecto -->
				<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.AUTHORSUBTITLE2' | translate }}"
					nbTooltipPlacement="top" (click)="goModule('new')">
					<h6>{{ "MENUACTIONS.AUTHORTITLE2" | translate }}</h6>
					<div class="item-image-small">
						<img src="../../../../assets/images/control-panel/nuevo-proyecto.png" width="50" />
					</div>
				</div>
				<!-- nueva encuesta -->
				<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.AUTHORSUBTITLE2' | translate }}"
					nbTooltipPlacement="top" (click)="goModule('encuestas')">
					<h6>{{ "MENUACTIONS.AUTHORTITLE5" | translate }}</h6>
					<div class="item-image-small">
						<img src="../../../../assets/images/control-panel/online-survey.png" width="50" />
					</div>
				</div>
			</div>
		</div>
		<!-- =============  MENU PADRE =================== -->
		<div class="container" *ngIf="profile == 'PADRE'">
			<div class="d-flex flex-wrap gap-5 p-5 justify-content-center align-items-center">
				<!-- configuracion de padre -->
				<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.PARENTSUBTITLE1' | translate }}"
					nbTooltipPlacement="top" (click)="ajustes()">
					<h6>{{ "MENUACTIONS.PARENTTITLE1" | translate }}</h6>
					<div class="item-image-small">
						<img src="../../../../assets/images/control-panel/gear.png" width="50" />
					</div>
				</div>
				<!-- informe padre -->
				<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.STUDENTSUBTITLE6' | translate }}"
					nbTooltipPlacement="top" (click)="informesPadre()">
					<h6>{{ "MENUACTIONS.STUDENTTITLE8" | translate }}</h6>
					<div class="card-image">
						<div class="icon-option icon-informes pointer"></div>
					</div>
				</div>
			</div>
		</div>

		<!-- Template -->
		<ng-template #dialog let-data let-ref="dialogRef">
			<nb-card class="position-relative mb-0">
				<nb-card-body>
					<div class="d-flex flex-column container">
						<h6>{{ infoTitle }}</h6>
						<p>{{ infoText }}</p>
					</div>
				</nb-card-body>
				<div class="closeModal">
					<nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}"
						placement="left" [options]="{ animation: { type: 'zoom' } }" (click)="ref.close()">
					</nb-icon>
				</div>
			</nb-card>
		</ng-template>
	</nb-card-body>
</nb-card>
