import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import { AppAccessZone } from './app-access-zones.model';
//export const APIURL:string = 'https://api.salware.net'
//export const CDNURL:string = 'https://cdn.salware.net'
export const APIURL:string = 'https://salware.com'
export const APIURLLOCAL:string = 'http://localhost:8082'
export const IMAGESASSETS:string = '/assets/images/'
export const CAPTCHAAPIKEY:string = '6Lfkd20gAAAAABesT1OjJ8FzxyQkiRsZBl9V__H_'
export const DEFAULTMOBILEPREFIX:string = '+34'
export const DEFAULTFONTFAMILY:string = 'Poppins'
export const DEFAULTCLOSEPADS: number = 600000
export const DEMOUSER = { USER: '700000000', PASS : '12345678' }
export const TINYAPYKEY: string = 've3uuc3idtgi6w1an4rfakpqbubya9ih6gibkr8jd3fmpfbn'
export const URL_TIKTOK = "https://www.tiktok.com/embed/v2/"
export const API_KEY_CHAT_GTP = "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";
export const PINECONE_API_KEY = "de640230-af55-46b0-8dda-6c973a949b3f";

export const DEFAULT_TEACHER_PROMPT:string = 'Para evaluar tu decisión\nNo consideres faltas de ortografía\nNo consideres espacios ni signos de puntuación.\nLas respuestas con abreviaturas son equivalente a las respuestas sin abreviaturas.'

export enum UrlWebDomain{
	LOCAL = 'http://localhost:4200/',
	DEV = 'https://dev.salware.com',
	PRE = 'https://pre.salware.com',
	DEMO = 'https://demo.salware.com',
	TEST = 'https://test.salware.com',
	PRO = 'https://salware.com',
	PSYCAT = 'https://psycast.salware.com',
	FOUNDATION = 'https://app.fundacionsalware.org'
}

/**
 * Versiones de la app
 */
export enum AppVersions{
	PROD = '1.0.90',
	API = '4.04.78',
}


/**
 * Centros de la aplicación
 */
export enum DomainTypes{
    DEV = 'dev',
    LOCAL = 'local',
    SALWARE = 'salware',
    OMS = 'oms',
    FOUNDATION = 'fundacionsalware',
    PRE = 'pre',
    DEMO = 'demo',
	PSYCAST = 'psycast'
}


/**
 * Carpetas de las imágenes según el entorno en el que nos encontremos
 */
export enum ImageEnvironments{
    DEV = '/images-dev',
    PRO = '/images',
    DEMO = '/images-demo'
}


/**
 * Entornos de la api
 */
export enum ApiEnvironments{
    LOCAL = '/api/rest/',
    DEV = '/api-dev/rest/',
    TEST = '/api-dev-pruebas/rest/',
    PRO = '/api/rest/',
    DEMO = '/api-demo/rest/'
}

export enum WebSocketEnvironment{
	LOCAL = '/api/websocket/',
	DEV = '/api-dev/websocket/',
	TEST = '/api-dev-pruebas/websocket/',
	PRO = '/api/websocket/',
	DEMO = '/api-demo/websocket/'
}

/**
 * Imágenes de fondo del login según los centros creados
 */
export enum LoginImage{
    DEV = 'fondoLogin.png',
    PRO = 'fondoLogin.png',
    OMS = 'fondoLogin.png',
    PRE = 'fondoLogin.png',
    DEMO = 'fondoLogin.png'
}

/**
 * Direccion de documentos de politicas de privacidad según los centros creados
 */
export enum PrivacyPolicies{
	FOUNDATION = 'politica_de_privacidad_salware.pdf',
	PSYCAST = 'politica_de_privacidad_psycast.salware.pdf'
}

/**
 * Logos de salware según los centros creados
 */
export enum HomeLogo{
	FOUNDATION = 'logofundacionsalware.png',
	SALWARE = 'logo-azul.png'
}

/**
 * Registramos las zonas de la app donde vamos a poner restricciones
 */
export enum AppZones{
    QUIZMULTIPLEBUTTON = 'multipleQuizzesButton'
}


/**
 * Aqui tenemos las zonas con los ids de acceso en cada una de las zonas/botones que creamos
 */
export const AccessZones: AppAccessZone[] = [
    {
        id: 1,
        name: AppZones.QUIZMULTIPLEBUTTON,
        validUsers : [62,63,64,87] //Ids de los usuarios que tienen acceso a la zona
    }
]

export const nodePositionsCircular: any[] = [
	{
		numberOfIdeas: 5,
		positions: [
			{ x: 126, y: -487 },
			{ x: -19, y: -365 },
			{ x: 7, y: -156 },
			{ x: 280, y: -149 },
			{ x: 291, y: -372 }
		]
	},
	{
		numberOfIdeas: 10,
		positions: [
			{ x: 355, y: -685 },
			{ x: 189, y: -658 },
			{ x: 54, y: -551 },
			{ x: -3, y: -406 },
			{ x: 94, y: -223 },
			{ x: 271, y: -122 },
			{ x: 454, y: -185 },
			{ x: 580, y: -295 },
			{ x: 601, y: -452 },
			{ x: 506, y: -603 }
		]
	},
	{
		numberOfIdeas: 15,
		positions: [
			{ x: 355, y: -685 },
			{ x: 189, y: -658 },
			{ x: 54, y: -551 },
			{ x: -3, y: -406 },
			{ x: 94, y: -223 },
			{ x: 271, y: -122 },
			{ x: 454, y: -185 },
			{ x: 580, y: -295 },
			{ x: 601, y: -452 },
			{ x: 506, y: -603 },
			{ x: 355, y: -685 },
			{ x: 189, y: -658 },
			{ x: 54, y: -551 },
			{ x: -3, y: -406 },
			{ x: 94, y: -223 }
		]
	}
]


export const nodePositionsRectangular: any[] = [
	{
		numberOfIdeas: 5,
		positions: [
			{ x: 1, y: -593 },
			{ x: 1, y: -195 },
			{ x: 727, y: -195 },
			{ x: 727, y: -593 },
			{ x: 368, y: -593 },
		],
	},
	{
		numberOfIdeas: 10,
		positions: [
			{ x: 15, y: -537 },
			{ x: 15, y: -372 },
			{ x: 15, y: -195 },
			{ x: 264, y: -183 },
			{ x: 529, y: -183 },
			{ x: 724, y: -183 },
			{ x: 724, y: -362 },
			{ x: 724, y: -523 },
			{ x: 535, y: -535 },
			{ x: 271, y: -535 },
		],
	},
	{
		numberOfIdeas: 15,
		positions: [
			{ x: 15, y: -537 },
			{ x: 15, y: -372 },
			{ x: 15, y: -195 },
			{ x: 264, y: -183 },
			{ x: 529, y: -183 },
			{ x: 724, y: -183 },
			{ x: 724, y: -362 },
			{ x: 724, y: -523 },
			{ x: 535, y: -535 },
			{ x: 271, y: -535 },
			{ x: 15, y: -537 },
			{ x: 15, y: -372 },
			{ x: 15, y: -195 },
			{ x: 264, y: -183 },
			{ x: 529, y: -183 },
		],
	},
];

export const nodePositionsAscendentLinear: any[] = [
	{
		numberOfIdeas: 5,
		positions: [
			{ x: -168, y: -53 },
			{ x: 126, y: -200 },
			{ x: 421, y: -336 },
			{ x: 704, y: -489 },
			{ x: 989, y: -631 },
		],
	},
	{
		numberOfIdeas: 10,
		positions: [
			{ x: -298, y: -30 },
			{ x: -48, y: -130 },
			{ x: 172, y: -237 },
			{ x: 375, y: -339 },
			{ x: 559, y: -426 },
			{ x: 722, y: -507 },
			{ x: 877, y: -602 },
			{ x: 1025, y: -697 },
			{ x: 1147, y: -801 },
			{ x: 1304, y: -934 },
		],
	},
	{
		numberOfIdeas: 15,
		positions: [
			{ x: -298, y: -30 },
			{ x: -48, y: -130 },
			{ x: 172, y: -237 },
			{ x: 375, y: -339 },
			{ x: 559, y: -426 },
			{ x: 722, y: -507 },
			{ x: 877, y: -602 },
			{ x: 1025, y: -697 },
			{ x: 1147, y: -801 },
			{ x: 1304, y: -934 },
			{ x: -298, y: -30 },
			{ x: -48, y: -130 },
			{ x: 172, y: -237 },
			{ x: 375, y: -339 },
			{ x: 559, y: -426 },
		],
	},
];

