<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="border-bottom-0">
		<div class="col-10 modal-title">
			<h2>{{ "CENTROS.LISTCENTERS" | translate }}</h2>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left"
			[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-body>
		<div class='container'>
			<div class="d-flex">
				<app-alert context="UserData"></app-alert>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="d-flex mb-4">
						<input nbInput fullWidth status="basic" class="form-control" (keyup)="applyFilter($event.target.value)"
							placeholder="{{'TEACHERSETTINGS.FILTERCENTER' | translate}}">
					</div>

					<div class="mat-elevation-z8">
						<table mat-table [dataSource]="listCenters">
							<!-- Center Name -->
							<ng-container matColumnDef="centerName">
								<th mat-header-cell *matHeaderCellDef>{{'TEACHERSETTINGS.CENTERNAME' | translate}}</th>
								<td mat-cell *matCellDef="let element"> {{element.centerName}} </td>
							</ng-container>

							<!-- Province -->
							<ng-container matColumnDef="province">
								<th mat-header-cell *matHeaderCellDef>{{'TEACHERSETTINGS.PROVINCENAME' | translate}}</th>
								<td mat-cell *matCellDef="let element"> {{element.province}} </td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="setCenterToUser(row)"></tr>
						</table>
						<mat-paginator [pageSize]="[10]"></mat-paginator>
					</div>

					<div class="col-12 py-4 px-0 d-flex justify-content-center align-center">
						<button nbButton class="btn-themecolor" (click)="newCenterModal()">
							<span>{{'TEACHERSETTINGS.CENTERNOTEXISTS' | translate}}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>
