<div class="modal-header tittle" align="center">
    <div class="col-sm-10"></div>
    <div class="col-sm-2 align-self-center text-center">
        <button type="button" class="close pointer" [ngStyle]="{'color':'rgb(29, 73, 153)'}" data-dismiss="modal"
            (click)="closeModal()">
            <span class="textoFontSize18 font-weight-bold span-text-center" aria-hidden="true">&times;</span>
        </button>
    </div>
</div>

<div class="modal-body fondoEditarCurso">
    <form class="form-horizontal mb-4">
        <div class="col-lg-12 col-md-12 col-sm-12 form-control">
            <app-loading [isVisible]="cargando"></app-loading>
            <div class="col-lg-12" align="center" *ngIf="cargando || format==''">
                <div class="mt-5"> &nbsp;</div>
                <div>
                    <mat-icon class="imagenInicial">cloud_upload</mat-icon>
                </div>
                <div *ngIf="cargando"><span>{{ 'NODOS.MOSTRAR' | translate }}</span></div>
                <div *ngIf="format=='' && !cargando"><span>{{ 'NODOS.NOEXISTE' | translate }}</span></div>
                <div class="mb-5"> &nbsp;</div>
            </div>
            <video #videoElement class="w-100 h-100 p-0 d-flex justify-content-center align-items-center"
                *ngIf="format=='video' && !cargando" controls>
                <source [src]="videoSource|safe" />
            </video>

            <div  class="w-100 h-100 p-0" *ngIf="format=='imagen' && !cargando">
                <img #imgElement class="w-100 mx-auto img-fluid" [src]="imageSource|safe" style="height:500px"/>
            </div>

            <div  class="w-100 h-100 p-0" *ngIf="format=='audio' && !cargando">
                    <img #audioElement class="w-80 h-75 d-block mx-auto img-fluid" src="../../assets/images/headphones.jpg" style="opacity: 0.2"/>
                    <audio class="w-100" controls><source [src]="audioSource|safe"></audio>
            </div>

            <div *ngIf="format=='pdf' && !cargando" class="w-100 h-100 p-0 embed-responsive embed-responsive-16by9">
                <iframe #pdfElement class="embed-responsive-item" [src]="pdfSource|safe"></iframe>
            </div>

            <div *ngIf="format=='texto' && !cargando" class="w-100 h-100 p-0 embed-responsive embed-responsive-16by9">
                <iframe #textoElement [src]="textSource|safe"></iframe>
            </div>
        </div>
    </form>
</div>
