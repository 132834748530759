import { LoginService } from 'src/app/core/services/login';
import { ACTIONS } from 'src/app/core/utils/actions';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { PadsUtils } from 'src/app/core/utils/pads.utils';
import { QuizzesService } from 'src/app/core/services/quizzes';

@Component({
	selector: 'app-node-pad-controller',
	templateUrl: './node-pad-controller.component.html',
	styleUrls: ['./node-pad-controller.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class NodePadControllerComponent implements OnInit {

	@HostListener('window:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		this.getCurrentKeyboardKey(event.key)
	}

	@Input() isShow: boolean;
	@Input() isShowLabels:boolean
	@Input() idCurso: number;
	@Input() idTarget: number;
	@Input() idQuiz: number;
	@Output() action = new EventEmitter<string>()
  @Output() onHoverElement:EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(
		private padsUtils: PadsUtils,
		public loginService: LoginService,
		public quizzesService: QuizzesService,
		) { }

	botonOculto: boolean;
	selectedGroupInfo: any;
	idGroup: number = 0;

	ngOnInit() {
		//SOLO BUSCAMOS EL QUIZ QUE SIGUE SI ESTAMOS EN UN QUIZ. SI ESTAMOS EN UN NODO NO BUSCAMOS NADA
		if(this.idQuiz != null){
		//Obtenemos el idGroup
		this.selectedGroupInfo = JSON.parse(localStorage.getItem('selectedGroupInfo'));
		this.idGroup = this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0;
			this.quizzesService.showNextQuizSemiAuto(this.idCurso, this.idTarget, this.idQuiz, this.idGroup).subscribe(res => {
				this.botonOculto = res.data;
			}, err => {})
		}
	}

	clickAction(value:string):void{
		this.action.emit(value)
		this.padsUtils.vibratePad()
	}
    onEnterPad(value){
        this.onHoverElement.emit(value)
    }

	private getCurrentKeyboardKey(key:string): void{
		switch(key){
				case ACTIONS.ARROWUP:
					this.clickAction('up')
					break
				case ACTIONS.ARROWDOWN:
					this.clickAction('down')
					break;
				case ACTIONS.ARROWRIGHT:
					this.clickAction('next')
					break
				case ACTIONS.ARROWLEFT:
					this.clickAction('back')
					break
		}
	}

}
