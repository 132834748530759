import { Injectable } from '@angular/core';

// Service
import { MapperService } from './mapper.service';

// Model
import { CenterModel } from '../../models/masters';

@Injectable()
export class CentersMapperService extends MapperService<CenterModel, any> {

    public map(data: CenterModel): any {
      return {
        idCenter: data.idCenter,
        centerName: data.centerName,
        centerAddress: data.centerAddress,
        centerCountry: data.centerCountry,
        centerRegion: data.centerRegion,
        centerMail: data.centerMail,
        extension: data.extension,
        centerPhone: data.centerPhone,
        centerUrl: data.centerUrl,
        centerPicture: data.centerPicture
      };
    }
}
