import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, takeUntil } from 'rxjs/operators';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';
import { RecordarQuizPlayComponent } from '../../quiz-open/quiz-play/quiz-play.component';
import { ModalQuizesMultiplesComponent } from '../modal-quizes-multiples/modal-quizes-multiples.component';
import { ReportingService } from '../service/reporting.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { Subject } from "rxjs";
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/models/store.model';
import { LoginService } from 'src/app/core/services/login';

export interface EstudentReportingTable{
  name: string;
  image: string;
}

const NOIMAGE = "../../../../../assets/images/icons/account_circle.svg"

@Component({
  selector: 'app-estudiante-quizes-datos',
  templateUrl: './estudiante-quizes-datos.component.html',
  styleUrls: ['./estudiante-quizes-datos.component.scss'],
})
export class EstudianteQuizesDatosComponent implements OnInit {
  displayedColumns: string[] = [];
  // dataSource : []=  [];
  dataSource: MatTableDataSource<any>;

  public headTable: any [] = [];
  public bodyTable: any [] = [];
	public dataTable: any [] = [];
	public listUsers: any [] = [];

  isLoading: boolean;
  @Input() idGrafo?: any;
  @Input() idCourse?: any;
  @Input() idNode?: any;
  @Input() idGrupo: any;
	@Input() idHijo?: any;
	@Input() challengeOn?: boolean = JSON.parse(localStorage.getItem('challengeOn')) ? true : false;
	@Input() reportingChallenge?: boolean = false;
	@Input() listChallengeSelected?: number[] = [];
	@Input() listGraphSelected?: any[] = [];
	@Input() reportType?: string = '';
	@Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading: boolean;
	quizType: number;
	profile: any;
	private destroy$ = new Subject();

	profileTitles = {
		ESTUDIANTE: 'Estudiante',
		AUTOR: 'Editor',
		PROFESOR: 'Profesor',
		PADRE: 'Padre',
		ADMIN: 'Administrador'
	}
	profileTitle: string;
	filtroAzTabla: string = "A-Z";
	filtroAciertos: string = "";
	filtroTiempo: string = "";
	mostrarFlechita: boolean = true;

	constructor(private reportringService: ReportingService,
		private imagePipe: ImagenPipe,
		private quizService: QuizzesService,
		private modalService: NgbModal,
		private reportingService: ReportingService,
		private toaster: ToasterService,
		private translateService: TranslateService,
		private store: Store<State>,
		public loginService: LoginService,
	) { }

  ngOnInit() {
    this.suscribeReportingService();
		//obtenemos el perfil que esta activo
		this.store.select(store => store.selectedProfile).pipe(takeUntil(this.destroy$)).subscribe((selectedProfile) => {
			this.profile = selectedProfile['selectedProfile']
		});
		this.profile = this.loginService.getProfile();
		this.profileTitle = this.profileTitles[this.profile]
  }

	suscribeReportingService() {
		this.reportringService.reportingSusbcribe.subscribe((res: any) => {
			this.dataTable = res.bodyTable;
			this.headTable = [this.dataTable[0]];
			this.bodyTable = this.dataTable.slice(1);
			//USAMOS ESTAS LISTAS PARA ORDENAR DESPUES LA TABLA
			this.listUsers = [];
			let listUsersName = [];
			let listUsersId = [];
			let listAciertos = [];
			let listTiempoTotal = [];
			for (let index = 1; index < this.dataTable[0].row.length; index++) {
				const element = this.dataTable[0].row[index];
				const element2 = this.dataTable[1].row[index];
				const element3 = this.dataTable[2].row[index];
				listUsersName.push(element.title);
				listUsersId.push(element.idUser);

				listAciertos.push(parseFloat(element2.texto.replace('%', '')));
				const tiempoString: string = element3.title;
				// Dividir el string en horas, minutos y segundos
				const tiempoArray: string[] = tiempoString.split(':');
				// Convertir cada parte del tiempo a un número entero
				const horas: number = parseInt(tiempoArray[0], 10);
				const minutos: number = parseInt(tiempoArray[1], 10);
				const segundos: number = parseInt(tiempoArray[2], 10);
				// Calcular el tiempo total en segundos
				const tiempoTotalSegundos: number = horas * 3600 + minutos * 60 + segundos;
				listTiempoTotal.push(tiempoTotalSegundos);
			}
			for (let index = 0; index < listUsersId.length; index++) {
				const user = {
					name: listUsersName[index],
					id: listUsersId[index],
					aciertos: listAciertos[index],
					tiempoTotal: listTiempoTotal[index],
				};
				this.listUsers.push(user);
			}
		});
	}
	ordenarTabla() {
		let order = this.filtroAzTabla;
		if (order === '') {
			order = 'A-Z';
		} else if (order === 'A-Z') {
			order = 'Z-A';
		} else if (order === 'Z-A') {
			order = 'A-Z';
		}
		this.filtroAzTabla = order;
		this._ordenarTabla(order);
		//this.showElement = false;
		this.filtroAciertos = '';
		this.filtroTiempo = '';
		this.mostrarFlechita = true;
	}
	ordenarTabla2() {
		let order = this.filtroAciertos;
		if (order === '') {
			order = 'aciertosDEC';
		} else if (order === 'aciertosASC') {
			order = 'aciertosDEC';
		} else if (order === 'aciertosDEC') {
			order = 'aciertosASC';
		}
		this.filtroAciertos = order;
		this._ordenarTabla(order);
		//this.showElement = false;
		this.filtroTiempo = '';
		this.filtroAzTabla = 'A-Z'
		this.mostrarFlechita = false;
	}
	ordenarTabla3() {
		let order = this.filtroTiempo;
		if (order === '') {
			order = 'tiempoTotalDEC';
		} else if (order === 'tiempoTotalASC') {
			order = 'tiempoTotalDEC';
		} else if (order === 'tiempoTotalDEC') {
			order = 'tiempoTotalASC';
		}
		this.filtroTiempo = order;
		this._ordenarTabla(order);
		//this.showElement = false;
		this.filtroAciertos = '';
		this.filtroAzTabla = 'A-Z'
		this.mostrarFlechita = false;
	}
	/* ===== FUNCIONES PARA ORDENAR LOS GRUPOS DE ESTUDIANTES ===== */
	_ordenarTabla(order){
		switch (order) {
			case 'A-Z':
				this.listUsers.sort((a, b) => (a.name > b.name) ? 1 : -1);
				this.actualizarTabla(this.listUsers);
				break;
			case 'Z-A':
				this.listUsers.sort((a, b) => (a.name < b.name) ? 1 : -1);
				this.actualizarTabla(this.listUsers);
				break;
			case 'aciertosDEC':
				this.listUsers.sort((a, b) => (a.aciertos < b.aciertos) ? 1 : -1);
				this.actualizarTabla(this.listUsers);
				break;
			case 'aciertosASC':
				this.listUsers.sort((a, b) => (a.aciertos > b.aciertos) ? 1 : -1);
				this.actualizarTabla(this.listUsers);
				break;
			case 'tiempoTotalDEC':
				this.listUsers.sort((a, b) => (a.tiempoTotal < b.tiempoTotal) ? 1 : -1);
				this.actualizarTabla(this.listUsers);
				break;
			case 'tiempoTotalASC':
				this.listUsers.sort((a, b) => (a.tiempoTotal > b.tiempoTotal) ? 1 : -1);
				this.actualizarTabla(this.listUsers);
				break;
		}
	}
	/* ========== ========== ========== ========== ========== */

	actualizarTabla(listUsers: any []){
		this.isLoading = true;
		let rol = this.profileTitle === 'Estudiante' ? '2':'3';
		let usersOrder = [];
		for (let index = 0; index < listUsers.length; index++) {
			usersOrder.push(listUsers[index].id);
		}
		if(this.reportType == 'target'){
			this.reportingService
			.getDataTableFromGraphsSorted(this.idGrupo, this.listGraphSelected, rol, usersOrder)
			.subscribe(
				(result) => {
					//this.reportingService.reportingSusbcribe.next({ ...result.data });
					this.dataTable = result.data.bodyTable;
					this.headTable = [this.dataTable[0]];
					this.bodyTable = this.dataTable.slice(1);
					this.isLoading = false;
				},
				(err) =>
					this.toaster.error(
						this.translateService.instant("INFORMES.ERRORQUIZZES")
					)
			);
		} else if(this.reportType == 'node'){
			this.reportingService.getDataTableQuizes(this.idGrupo, this.idCourse, this.idGrafo, this.idNode, usersOrder)
			.subscribe(
				(res: any) => {
					this.dataTable = res.data.bodyTable;
					this.headTable = [this.dataTable[0]];
					this.bodyTable = this.dataTable.slice(1);
					this.isLoading = false;
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("INFORMES.ERRORQUIZZES")
					);
				}
			);
		} else if(this.reportType == 'challenge'){

		}
	}

  getUserAvatar(imagen):string{
    let image:string = ''
    imagen!== null && imagen !== ""? image = this.imagePipe.transform(imagen, 'userAvatar') : image = NOIMAGE
    return `url('${image}')`
  }


  viewQuiz(node: any){
		if(this.profile == 'PROFESOR'){
			if (node.isMultiplex === 1) {
				let storedGroupInfo = JSON.parse(localStorage.getItem('selectedGroupInfo'));
					const idGrupo = storedGroupInfo ? storedGroupInfo.idGroup : 0;
				this.quizService.getQuizInformMultipleHijos(node.idQuiz, node.idUser, idGrupo).pipe(finalize(() => this.isLoading = false)).subscribe((res:any) => {

					const modalRef = this.modalService.open(ModalQuizesMultiplesComponent, {scrollable: true, windowClass: MODAL_DIALOG_TYPES.W35, backdrop: 'static' })
					modalRef.componentInstance.quizesMultiplesList = res;
					modalRef.componentInstance.idUser = node.idUser;

					modalRef.result.then(res => {
						// Falta enviar la data para cambiar los valores y actualizar el backend
						this.suscribeReportingService();
						if(!this.reportingChallenge){
							this.reportingService.getDataTableQuizes(this.idGrupo, this.idCourse, this.idGrafo, this.idNode, []).subscribe(  (res:any) => {
								this.reportingService.reportingSusbcribe.next({ ...res.data})
								this.loading = false;
								this.reload.emit(true);
							})
						}
				})
				});
			} else {
				const curso = node.idCourse === 0 ? this.idCourse : node.idCourse;
				const grafo = node.idTarget === 0 ? this.idGrafo : node.idTarget;
				this.quizService.getQuiz(node.idQuiz, curso, grafo).subscribe(res => {

					if(res.quiz.quizType === 1){
						this.quizService.getQuizInforme(node.idQuiz, node.idUser).pipe(finalize(() => this.isLoading = false)).subscribe((res:any) => {
							const modalRef = this.modalService.open(RecordarQuizPlayComponent, {scrollable: true, windowClass: MODAL_DIALOG_TYPES.W100, backdrop: 'static' })
							modalRef.componentInstance.quiz = { ...res.quiz, user: res.quiz.user, idOriginal: res.quiz.idOriginal, id: res.quiz.idQuiz, originalX: res.quiz.originalX, originalY: res.quiz.originalY, size: res.quiz.size, sizeQuiz: res.quiz.sizeQuiz, x: res.quiz.x, y: res.quiz.y };;
							modalRef.componentInstance.elements = res.elementsQuestion;
							modalRef.componentInstance.respuestaEstudiante = res.elementsAnswer;
							modalRef.componentInstance.courseId = node.idCurso;
							modalRef.componentInstance.graphId = node.target;
							modalRef.componentInstance.isEvaluation = true;

							modalRef.result.then(res => {
									// Falta enviar la data para cambiar los valores y actualizar el backend
									// this.suscribeReportingService();
									// this.reportingService.getDataTableQuizes(this.idGrupo, this.idCourse, this.idGrafo, this.idNode, []).subscribe(  (res:any) => {
									// 	this.reportingService.reportingSusbcribe.next({ ...res.data})
									// 	this.loading = false;
									// })
									this.reload.emit(true);
						})
						});
					} else{
						this.toaster.error(this.translateService.instant("INFORMES.ONLYOPENANSWER")
						)
					}
				})
			}
		} else if(node.isMultiplex === 0 && this.profile != 'PROFESOR' && this.profile != 'PADRE'){
			const curso = node.idCourse === 0 ? this.idCourse : node.idCourse;
				const grafo = node.idTarget === 0 ? this.idGrafo : node.idTarget;
				this.quizService.getQuiz(node.idQuiz, curso, grafo).subscribe(res => {

					if(res.quiz.quizType === 1){
						this.quizService.getQuizInforme(node.idQuiz, node.idUser).pipe(finalize(() => this.isLoading = false)).subscribe((res:any) => {
							const modalRef = this.modalService.open(RecordarQuizPlayComponent, {scrollable: true, windowClass: MODAL_DIALOG_TYPES.W100, backdrop: 'static' })
							modalRef.componentInstance.quiz = { ...res.quiz, user: res.quiz.user, idOriginal: res.quiz.idOriginal, id: res.quiz.idQuiz, originalX: res.quiz.originalX, originalY: res.quiz.originalY, size: res.quiz.size, sizeQuiz: res.quiz.sizeQuiz, x: res.quiz.x, y: res.quiz.y };;
							modalRef.componentInstance.elements = res.elementsQuestion;
							modalRef.componentInstance.respuestaEstudiante = res.elementsAnswer;
							modalRef.componentInstance.courseId = node.idCurso;
							modalRef.componentInstance.graphId = node.target;
							modalRef.componentInstance.isEvaluation = true;

							modalRef.result.then(res => {
									// Falta enviar la data para cambiar los valores y actualizar el backend
									this.reload.emit(true);
						})
						});
					} else{
						this.toaster.error(this.translateService.instant("INFORMES.ONLYOPENANSWER")
						)
					}
				})
		} else if(this.profile == 'PADRE'){
			// si es un padre no debe hacer nada
		} else{
			this.toaster.error(this.translateService.instant("INFORMES.ONLYOPENANSWER"));
		}
  }


}
