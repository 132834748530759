<nb-card class="no-edge-card modal-max-height-100-65 mb-0">
<!--	<div class="overlay" *ngIf="!versionAPIisCorrect">
		<div class="popup-container">
			<app-pop-up-reload [isVisible]="!versionAPIisCorrect"></app-pop-up-reload>
		</div>
	</div>-->
	<nb-card-header class="border-bottom-0">
		<div class="row">
			<div class="col-10">
				<h4>
					{{ "MENUACTIONS.MENU" | translate }} - {{ profileTitle }}
				</h4>
			</div>
		</div>

		<div class="d-flex align-items-center justify-content-around gap-3 position-relative">
			<div class="d-flex flex-column justify-content-center align-items-center gap-2">
				<div>
					<div *ngIf="selectedGroupInfo && (profile === 'PROFESOR')">
						<div class="pointer position-relative d-flex justify-content-center align-items-end gap-2"
							[nbPopover]="templateRef" nbPopoverPlacement="bottom" [ngbTooltip]="'SOCKETQUIZPREVIEW.ONLINEUSERS' | translate"
							(click)="prefillOfflineUsers()">
							<span class="dot position-dot"></span>
							<nb-icon icon="people-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon>
							<span>
								{{onlineUsers }} / {{ usersInGroupCounter }}
							</span>
						</div>
					</div>
					<ng-template #templateRef>
						<nb-card [size]="'tiny'" accent="success" class="mb-0 mt-1">
							<nb-card-header>{{'SOCKETQUIZPREVIEW.INCLASSSTUDENTS' | translate}}</nb-card-header>
							<nb-card-body>
								<nb-list>
									<nb-list-item *ngFor="let user of usuariosOnline">
										<span class="dot"></span>{{user.firstName}} {{user.surname}}
									</nb-list-item>
								</nb-list>
							</nb-card-body>
						</nb-card>

						<nb-card [size]="'tiny'" accent="danger" class="mb-0">
							<nb-card-header>{{'SOCKETQUIZPREVIEW.OUTCLASSSTUDENTS' | translate}}</nb-card-header>
							<nb-card-body>
								<nb-list>
									<nb-list-item *ngFor="let user of usuariosOffline">
										<span class="dot-red"></span>{{user.firstName}} {{user.surname}}
									</nb-list-item>
								</nb-list>
							</nb-card-body>
						</nb-card>
					</ng-template>
				</div>
				<div class="text-center" *ngIf="selectedGroupInfo && (profile === 'ESTUDIANTE' || profile === 'PROFESOR')">
					<h6>{{ "ALERTGROUP.GRUPOACTUAL" | translate }}: {{ selectedGroupInfo.group.identification | truncate : [50, "..."] }}</h6>
					<h5 class="text-group">{{ selectedGroupInfo.title }} </h5>
				</div>
				<div *ngIf="selectedGroupInfo && (profile === 'ESTUDIANTE' || profile === 'PROFESOR')"
					class="order-md-2 order-md-1">
					<div class="d-flex align-items-center justify-content-center mt-3">
						<button nbButton class="btn-themecolor" size="small" (click)="noGroup()">
							<ng-container *ngIf="profile === 'ESTUDIANTE'">{{ 'MODALGROUPSEDIT.SELFSTUDY' | translate }}</ng-container>
							<ng-container *ngIf="profile === 'PROFESOR'">{{ 'MODALGROUPSEDIT.SELFEDUCATE' | translate }}</ng-container>
						</button>
					</div>
				</div>
				<div class="text-center" *ngIf="!selectedGroupInfo && (profile === 'ESTUDIANTE' || profile === 'PROFESOR')">
					<div class="icon-head">
						<div class="icon-option icon-groups disabled"></div>
					</div>
					<h6 class="mt-2">
						{{ "ALERTGROUP.ENTERGROUP" | translate }}
						{{ selectedGroupInfo ? selectedGroupInfo.title : '' }}
					</h6>
				</div>

				<div *ngIf="profile === 'PROFESOR' && isMobile" class="d-flex position-absolute" style="top: 0; right: 0;">
					<button nbButton size="small" [nbPopover]="calendarTemplate" [nbPopoverPlacement]="position" [nbPopoverTrigger]="trigger">
						<nb-icon class="m-0" icon="calendar-outline"></nb-icon>
						<span class="notification-badge">
							{{ notificationCount }}
						</span>
					</button>
				</div>
			</div>
			<div *ngIf="profile === 'PROFESOR' && !isMobile">
				<nb-card class="mb-0 no-edge-card">
					<div [ngSwitch]="view" class="CalendarStyle">
						<mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" class="w-100"
							[viewDate]="viewDate"
							[events]="professorEvents"
							[locale]="locale"
							[refresh]="refresh"
							[activeDayIsOpen]="activeDayIsOpen"
							(dayClicked)="dayClicked($event.day)"
							(eventClicked)="handleEvent('Clicked', $event.event)"
							(eventTimesChanged)="eventTimesChanged($event)"
							[weekStartsOn]="1"
							[cellTemplate]="customCellTemplatee"
							[excludeDays]="excludeDays">
						</mwl-calendar-month-view>
						<ng-template #customCellTemplatee let-day="day" let-event="event" let-locale="locale">
							<div class="cal-cell-top">
								<span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
								<span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
							</div>
						</ng-template>
					</div>
				</nb-card>
			</div>
		</div>
	</nb-card-header>

	<!-- Template del calendario para los profesores -->
	<ng-template #calendarTemplate>
		<nb-card class="mb-0 no-edge-card">
			<div [ngSwitch]="view" class="CalendarStyle">
				<mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" class="w-100"
					[viewDate]="viewDate"
					[events]="professorEvents"
					[locale]="locale"
					[refresh]="refresh"
					[activeDayIsOpen]="activeDayIsOpen"
					(dayClicked)="dayClicked($event.day)"
					(eventClicked)="handleEvent('Clicked', $event.event)"
					(eventTimesChanged)="eventTimesChanged($event)"
					[weekStartsOn]="1"
					[cellTemplate]="customCellTemplatee"
					[excludeDays]="excludeDays">
				</mwl-calendar-month-view>
				<ng-template #customCellTemplatee let-day="day" let-event="event" let-locale="locale">
					<div class="cal-cell-top">
						<span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
						<span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
					</div>
				</ng-template>
			</div>
		</nb-card>
	</ng-template>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-body>
		<div class="row d-flex justify-content-center cards-container">
			<div class="col-12">
				<!--               MENU ESTUDIANTE               -->
				<div *ngIf="profile == 'ESTUDIANTE'">
					<nb-card class="background_themecolor">
						<nb-card-body class="container">
							<div class="cards">
								<div class="card-item" (click)="goModule('groups')" [ngClass]="{'pulsating' : selectedGroupInfo == null}">
									<div class="card-image">
										<div class="icon-option icon-groups pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.STUDENTTITLE6" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.STUDENTSUBTITLE6" | translate }}
										</p>
									</div>
								</div>
							</div>
							<div class="cards" *ngIf="networkSpeed >= 1">
								<div class="card-item" (click)="modalStudentWaiting()">
									<div class="card-image">
										<div class="icon-option icon-student pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.INCLASS" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.INCLASSMSG" | translate }}
										</p>
									</div>
								</div>
							</div>
							<div class="cards" [ngClass]="{ 'disabled-card': noDataUser }">
								<div class="card-item" (click)="goModule('edit')">
									<div class="card-image">
										<div class="icon-option icon-author pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.STUDENTTITLE1" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.STUDENTSUBTITLE1" | translate }}
										</p>
										<div *ngIf="noDataUser == true" class="flex align-items-center justify-content-center">
											<div class="icon-option danger-icon-color pointer" [nbTooltip]="noDataTooltipMsg" nbTooltipStatus="danger"></div>
											<p class="danger-paragraph">
												{{ "MENUACTIONS.REGISTERWITHDATA" | translate }}
											</p>
										</div>

									</div>
								</div>
							</div>
							<div class="cards">
								<div class="card-item" (click)="goModule('projects')">
									<div class="card-image">
										<div
											class="icon-option icon-proyecto-muchos-grafos pointer"
										></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.STUDENTTITLE2" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.STUDENTSUBTITLE2" | translate }}
										</p>
									</div>
								</div>
							</div>
							<div class="cards">
								<div class="card-item" (click)="goModule('actividadesFlash')">
									<div class="card-image">
										<div class="icon-option icon-recordar-baraja pointer" style="width: 35px;"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.STUDENTTITLE3" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.STUDENTSUBTITLE3" | translate }}
										</p>
									</div>
								</div>
							</div>
							<!--DESAFIOS CON BUENA CONEXION-->
							<div class="cards" *ngIf="networkSpeed >= 1">
								<div class="card-item" (click)="instantActivityStudent()"
									[ngClass]="{ 'disabled-card': disableActivityButton == true }">
									<div class="card-image">
										<div class="icon-option icon-desafios-icon pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.STUDENTTITLE7" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.STUDENTSUBTITLE7" | translate }}
										</p>

									</div>
								</div>
							</div>
							<!--DESAFIOS CON MALA CONEXION-->
							<div class="cards" *ngIf="networkSpeed < 1">
								<div class="card-item alert-low-conection"
									[ngClass]="{ 'disabled-card': disableActivityButton == true }">
									<div class="alert-content w-100 px-2">
										<div class="card-image">
											<div class="icon-option icon-desafios-icon pointer"></div>
										</div>
										<div class="card-info2">
											<h6 class="card-title">
												{{ "MENUACTIONS.STUDENTTITLE7" | translate }}
											</h6>
											<p class="icon-text">{{ 'GLOBALS.BLOCKEDSLOWCONECTION' | translate }}</p>
										</div>
									</div>
								</div>
							</div>
							<div class="cards">
								<div class="card-item" (click)="informesStudentGrupo()">
									<div class="card-image">
										<div class="icon-option icon-informes pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.STUDENTTITLE8" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.STUDENTSUBTITLE6" | translate }}
										</p>
									</div>
								</div>
							</div>
							<!--COMPETIR CON BUENA CONEXION-->
							<div class="cards" *ngIf="networkSpeed >= 1" [ngClass]="{ 'disabled-card': noDataUser }">
								<div class="card-item " (click)="Competitions()">
									<div class="card-image">
										<div class="icon-option icon-competir pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.STUDENTTITLE4" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.STUDENTSUBTITLE4" | translate }}
										</p>
										<div *ngIf="noDataUser == true" class="flex align-items-center justify-content-center">
											<div class="icon-option danger-icon-color pointer" [nbTooltip]="noDataTooltipMsg" nbTooltipStatus="danger"></div>
											<p class="danger-paragraph">
												{{ "MENUACTIONS.REGISTERWITHDATA" | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
							<!--COMPETIR CON MALA CONEXION-->
							<div class="cards" *ngIf="networkSpeed < 1" [ngClass]="{ 'disabled-card': noDataUser }">
								<div class="card-item alert-low-conection">
									<div class="alert-content w-100 px-2">
										<div class="card-image">
											<div class="icon-option icon-competir pointer"></div>
										</div>
										<div class="card-info2">
											<h6 class="card-title">
												{{ "MENUACTIONS.STUDENTTITLE4" | translate }}
											</h6>
											<p class="icon-text">{{ 'GLOBALS.BLOCKEDSLOWCONECTION' | translate }}</p>
										</div>
									</div>
								</div>
							</div>
							<div class="cards" [ngClass]="{ 'disabled-card': noDataUser }">
								<div class="card-item" (click)="sos()">
									<div class="card-image">
										<div class="icon-option icon-sos pointer" style="width: 35px; height: 35px;"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.STUDENTTITLE5" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.STUDENTSUBTITLE8" | translate }}
										</p>
										<div *ngIf="noDataUser == true" class="flex align-items-center justify-content-center">
											<div class="icon-option danger-icon-color pointer" [nbTooltip]="noDataTooltipMsg" nbTooltipStatus="danger"></div>
											<p class="danger-paragraph">
												{{ "MENUACTIONS.REGISTERWITHDATA" | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="cards">
								<div class="card-item" (click)="pensarCreando()">
									<div class="card-image">
										<div
											class="icon-option icon-pensar-creando pointer" style="width: 35px; height: 35px;"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.TEACHERTITLE18" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.TEACHERSUBTITLE18" | translate }}
										</p>
									</div>
								</div>
							</div>
							<div class="cards">
								<div class="card-item" (click)="ajustes()">
									<div class="card-image">
										<div class="icon-option icon-settings pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.STUDENTTITLE9" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.STUDENTSUBTITLE9" | translate }}
										</p>
									</div>
								</div>
							</div>
							<!--- DEJAR COMENTADO. SE USA PARA TENER ACCESO A COMPONENTES EN DESARROLLO --->
							<!-- <div class="cards">
								<div class="card-item" (click)="pruebaEncuesta()">
								</div>
							</div> -->
						</nb-card-body>
					</nb-card>
				</div>
				<!--               MENU PROFESOR               -->
				<div class="" *ngIf="profile == 'PROFESOR'">
					<nb-card class="background_themecolor mb-4">
						<nb-card-header class="pl-2 pr-2 pt-2 pb-0">
							<div class="row">
								<div class="col-12">
									<h5 class="text-center">
										{{ "MENUACTIONS.TEACHERSUBDIVISION3" | translate }}
									</h5>
								</div>
							</div>
						</nb-card-header>
						<nb-card-body class="container container-teacher pt-3">
							<div class="cards cards-teacher p-2">
								<div class="card-item" (click)="goModule('groups')" [ngClass]="{'pulsating' : selectedGroupInfo == null}">
									<div class="card-image">
										<div class="icon-option icon-groups pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.TEACHERTITLE4" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.TEACHERSUBTITLE4" | translate }}
										</p>
									</div>
								</div>
							</div>
							<div class="cards cards-teacher p-2">
								<div class="card-item" (click)="asistenciaGrupo()">
									<div class="card-image">
										<div class="icon-option icon-asistencia pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.TEACHERTITLE11" | translate }}
										</h6>
										<p>{{ "MENUACTIONS.TEACHERSUBTITLE11" | translate }}</p>
									</div>
								</div>
							</div>
							<div class="cards cards-teacher p-2">
								<div class="card-item" (click)="informesGrupo(false)">
									<div class="card-image">
										<div class="icon-option icon-informes pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.TEACHERTITLE5" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.TEACHERSUBTITLE5" | translate }}
										</p>
									</div>
								</div>
							</div>
						</nb-card-body>
					</nb-card>
					<nb-card class="background_themecolor">
						<nb-card class="background_themecolor no-edge-card mb-0">
							<nb-card-header class="pl-2 pr-2 pt-2 pb-0">
								<div class="row">
									<div class="col-12">
										<h5 class="text-center">
											{{ "MENUACTIONS.TEACHERSUBDIVISION4" | translate }}
										</h5>
									</div>
								</div>
							</nb-card-header>
							<nb-card-body class="container container-teacher">
								<div class="cards cards-teacher">
									<div class="card-item position-relative">
										<nb-icon class="m-1 info-icon pointer" icon="info-outline"
											(click)="openInfo(dialog, 1)" nbTooltip="{{ 'GLOBALS.CLICKTOINFO' | translate }}" nbTooltipPlacement="top">
										</nb-icon>
										<div class="card-image" (click)="explicacionAsociativa()">
											<div
												class="icon-option icon-explicacion-asociativa pointer" style="width: 40px; height: 40px;"></div>
										</div>
										<div class="card-info" (click)="explicacionAsociativa()">
											<h6 class="card-title">
												{{ "MENUACTIONS.TEACHERTITLE15" | translate }}
											</h6>
											<p class="paragraph">
												{{ "MENUACTIONS.TEACHERSUBTITLE15" | translate }}
											</p>
										</div>
									</div>
								</div>
								<!--DESAFIOS CON BUENA CONEXION-->
								<div class="cards cards-teacher" [ngClass]="{'disabled-card':disableInstantActivityButton}" *ngIf="networkSpeed >= 1">
									<div class="card-item position-relative">
										<nb-icon class="m-1 info-icon pointer" icon="info-outline"
											(click)="openInfo(dialog, 2)" nbTooltip="{{ 'GLOBALS.CLICKTOINFO' | translate }}" nbTooltipPlacement="top">
										</nb-icon>
										<div class="card-image"  (click)="instantActivity()">
											<div class="icon-option icon-desafios-icon pointer"></div>
										</div>
										<div class="card-info"  (click)="instantActivity()">
											<h6 class="card-title">
												{{ "MENUACTIONS.TEACHERTITLE7" | translate }}
											</h6>
											<p class="paragraph">
												{{ "MENUACTIONS.TEACHERSUBTITLE7" | translate }}
											</p>
										</div>
									</div>
								</div>
								<!--DESAFIOS CON MALA CONEXION-->
								<div class="cards cards-teacher" [ngClass]="{'disabled-card':disableInstantActivityButton}" *ngIf="networkSpeed < 1">
									<div class="card-item alert-low-conection position-relative">
										<nb-icon class="m-1 info-icon pointer" icon="info-outline"
											(click)="openInfo(dialog, 2)" nbTooltip="{{ 'GLOBALS.CLICKTOINFO' | translate }}" nbTooltipPlacement="top">
										</nb-icon>
										<div class="alert-content w-100 px-2">
											<div class="card-image">
												<div class="icon-option icon-desafios-icon pointer"></div>
											</div>
											<div class="card-info2">
												<h6 class="card-title">
													{{ "MENUACTIONS.TEACHERTITLE7" | translate }}
												</h6>
												<p class="icon-text">{{ 'GLOBALS.BLOCKEDSLOWCONECTION' | translate }}</p>
											</div>
										</div>
									</div>
								</div>
								<div class="cards cards-teacher">
									<div class="card-item position-relative">
										<nb-icon class="m-1 info-icon pointer" icon="info-outline"
											(click)="openInfo(dialog, 3)" nbTooltip="{{ 'GLOBALS.CLICKTOINFO' | translate }}" nbTooltipPlacement="top">
										</nb-icon>
										<div class="card-image" (click)="informesGrupo(true)">
											<div
												class="icon-option icon-clase-invertida pointer" style="width: 40px; height: 40px;"></div>
										</div>
										<div class="card-info" (click)="informesGrupo(true)">
											<h6 class="card-title">
												{{ "MENUACTIONS.TEACHERTITLE16" | translate }}
											</h6>
											<p class="paragraph">
												{{ "MENUACTIONS.TEACHERSUBTITLE16" | translate }}
											</p>
										</div>
									</div>
								</div>
								<div class="cards cards-teacher">
									<div class="card-item position-relative">
										<nb-icon class="m-1 info-icon pointer" icon="info-outline"
											(click)="openInfo(dialog, 4)" nbTooltip="{{ 'GLOBALS.CLICKTOINFO' | translate }}" nbTooltipPlacement="top">
										</nb-icon>
										<div class="card-image" (click)="practicar()">
											<div
												class="icon-option icon-practicar-ideografo pointer" style="width: 40px; height: 40px;"></div>
										</div>
										<div class="card-info" (click)="practicar()">
											<h6 class="card-title">
												{{ "MENUACTIONS.TEACHERTITLE17" | translate }}
											</h6>
											<p class="paragraph">
												{{ "MENUACTIONS.TEACHERSUBTITLE17" | translate }}
											</p>
										</div>
									</div>
								</div>
							</nb-card-body>
						</nb-card>
						<nb-card class="background_themecolor no-edge-card mb-0">
							<nb-card-header class="pl-2 pr-2 pt-2 pb-0">
								<div class="row">
									<div class="col-12">
										<h5 class="text-center">
											{{ "MENUACTIONS.TEACHERSUBDIVISION5" | translate }}
										</h5>
									</div>
								</div>
							</nb-card-header>
							<nb-card-body class="container container-teacher">
								<div class="cards cards-teacher">
									<div class="card-item position-relative">
										<nb-icon class="m-1 info-icon pointer" icon="info-outline"
											(click)="openInfo(dialog, 5)" nbTooltip="{{ 'GLOBALS.CLICKTOINFO' | translate }}" nbTooltipPlacement="top">
										</nb-icon>
										<div class="card-image" (click)="pensarCreando()">
											<div
												class="icon-option icon-pensar-creando pointer" style="width: 35px; height: 35px;"></div>
										</div>
										<div class="card-info" (click)="pensarCreando()">
											<h6 class="card-title">
												{{ "MENUACTIONS.TEACHERTITLE18" | translate }}
											</h6>
											<p class="paragraph">
												{{ "MENUACTIONS.TEACHERSUBTITLE18" | translate }}
											</p>
										</div>
									</div>
								</div>
								<div class="cards cards-teacher">
									<div class="card-item position-relative">
										<nb-icon class="m-1 info-icon pointer" icon="info-outline"
											(click)="openInfo(dialog, 6)" nbTooltip="{{ 'GLOBALS.CLICKTOINFO' | translate }}" nbTooltipPlacement="top">
										</nb-icon>
										<div class="card-image" (click)="ABPyCasos()">
											<div
												class="icon-option icon-practicar-ideografo pointer" style="width: 40px; height: 40px;"></div>
										</div>
										<div class="card-info" (click)="ABPyCasos()">
											<h6 class="card-title">
												{{ "MENUACTIONS.TEACHERTITLE19" | translate }}
											</h6>
											<p class="paragraph">
												{{ "MENUACTIONS.TEACHERSUBTITLE19" | translate }}
											</p>
										</div>
									</div>
								</div>
							</nb-card-body>
						</nb-card>
					</nb-card>
					<nb-card class="background_themecolor mb-2">
						<nb-card-header class="pl-2 pr-2 pt-2 pb-0">
							<div class="row">
								<div class="col-12">
									<h5 class="text-center">
										{{ "MENUACTIONS.TEACHERSUBDIVISION6" | translate }}
									</h5>
								</div>
							</div>
						</nb-card-header>
						<nb-card-body class="container container-teacher">
							<!--
							<div class="cards cards-teacher disabled-card">
								<div class="card-item">
									<div class="card-image">
										<div class="icon-option icon-recordar-baraja pointer" style="width: 35px;"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.STUDENTTITLE3" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.STUDENTSUBTITLE3" | translate }}
										</p>
									</div>
								</div>
							</div>
							<div class="cards cards-teacher disabled-card">
								<div class="card-item">
									<div class="card-image">
										<div class="icon-option icon-sos pointer" style="width: 35px; height: 35px;"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.TEACHERTITLE12" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.TEACHERSUBTITLE12" | translate }}
										</p>
									</div>
								</div>
							</div>
							-->
							<div class="cards cards-teacher">
								<div class="card-item" (click)="goModule('projects')">
									<div class="card-image">
										<div
											class="icon-option icon-explorar-contenido pointer"
										></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.TEACHERTITLE13" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.TEACHERSUBTITLE13" | translate }}
										</p>
									</div>
								</div>
							</div>
							<div class="cards cards-teacher">
								<div class="card-item position-relative">
									<nb-icon class="m-1 info-icon pointer" icon="info-outline"
										(click)="openInfo(dialog, 7)" nbTooltip="{{ 'GLOBALS.CLICKTOINFO' | translate }}" nbTooltipPlacement="top">
									</nb-icon>
									<div class="card-image" (click)="pruebaNivel()">
										<div
											class="icon-option icon-practicar-ideografo pointer" style="width: 40px; height: 40px;"></div>
									</div>
									<div class="card-info" (click)="pruebaNivel()">
										<h6 class="card-title">
											{{ "MENUACTIONS.TEACHERTITLE21" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.TEACHERSUBTITLE20" | translate }}
										</p>
									</div>
								</div>
							</div>
							<div class="cards cards-teacher" [ngClass]="{ 'disabled-card': !isEditor }">
								<div class="card-item" (click)="goModule('edit')">
									<div class="card-image">
										<div class="icon-option icon-author pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.TEACHERTITLE14" | translate }}
										</h6>
										<p>{{ "MENUACTIONS.TEACHERSUBTITLE14" | translate }}</p>
									</div>
								</div>
							</div>
							<div class="cards cards-teacher">
								<div
									class="card-item"
									(click)="listadoCursos()"
									[ngClass]="{
										pulsating:
											selectedGroupInfo != null &&
											selectedGroupInfo.assignedProjects === 0
									}"
								>
									<div class="card-image">
										<div
											class="icon-option icon-proyecto-muchos-grafos pointer"
										></div>
										<div class="icon-option icon-flecha-derecha pointer"></div>
										<div class="icon-option icon-groups pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.TEACHERTITLE9" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.TEACHERSUBTITLE9" | translate }}
										</p>
									</div>
								</div>
							</div>
							<div class="cards cards-teacher">
								<div
									class="card-item"
									(click)="listadoAlumnos()"
									[ngClass]="{
										pulsating:
											selectedGroupInfo != null &&
											selectedGroupInfo.assignedStudents === 0
									}"
								>
									<div class="card-image">
										<div class="icon-option icon-student pointer"></div>
										<div class="icon-option icon-flecha-derecha pointer"></div>
										<div class="icon-option icon-groups pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.TEACHERTITLE8" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.TEACHERSUBTITLE8" | translate }}
										</p>
									</div>
								</div>
							</div>
							<div class="cards cards-teacher">
								<div class="card-item" (click)="goModule('settings')">
									<div class="card-image">
										<div class="icon-option icon-settings pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">{{ 'MENUACTIONS.TEACHERTITLE10' | translate }}</h6>
										<p class="paragraph">{{ 'MENUACTIONS.TEACHERSUBTITLE10' | translate }}</p>
									</div>
								</div>
							</div>
							<!--
							<div class="cards cards-teacher disabled-card">
								<div class="card-item">
									<div class="card-image">
										<div class="icon-option icon-planificar pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.TEACHERTITLE3" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.TEACHERSUBTITLE3" | translate }}
										</p>
									</div>
								</div>
							</div>
							-->
						</nb-card-body>
					</nb-card>
					<!-- <nb-card class="card-gray-bg ">
						<nb-card-body class="container">
							<div class="cards disabled-card">
								<div class="card-item" (click)="goModule('settings')">
									<div class="card-image">
										<div class="icon-option icon-settings pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">{{ 'MENUACTIONS.TEACHERTITLE10' | translate }}</h6>
										<p class="paragraph">{{ 'MENUACTIONS.TEACHERSUBTITLE10' | translate }}</p>
									</div>
								</div>
							</div>
						</nb-card-body>
					</nb-card> -->
				</div>
				<!--               MENU EDITOR               -->
				<div class="container background_themecolor" *ngIf="profile == 'AUTOR'">
					<div class="cards">
						<div class="card-item" (click)="goModule('projects')">
							<div class="card-image">
								<div
									class="icon-option icon-proyecto-muchos-grafos pointer"
								></div>
							</div>
							<div class="card-info">
								<h6 class="card-title">
									{{ "MENUACTIONS.TEACHERTITLE1" | translate }}
								</h6>
								<p class="paragraph">
									{{ "MENUACTIONS.TEACHERSUBTITLE1" | translate }}
								</p>
							</div>
						</div>
					</div>
					<div class="cards">
						<div class="card-item" (click)="goModule('edit')">
							<div class="card-image">
								<div class="icon-option icon-author pointer"></div>
							</div>
							<div class="card-info">
								<h6 class="card-title">
									{{ "MENUACTIONS.AUTHORTITLE1" | translate }}
								</h6>
								<p class="paragraph">
									{{ "MENUACTIONS.AUTHORSUBTITLE1" | translate }}
								</p>
							</div>
						</div>
					</div>
					<div class="cards">
						<div class="card-item" (click)="goModule('new')">
							<div class="card-image">
								<div class="icon-option icon-proyecto-muchos-grafos pointer position-relative">
									<nb-icon class="plus-editor" icon="plus-outline"></nb-icon>
								</div>
							</div>
							<div class="card-info">
								<h6 class="card-title">
									{{ "MENUACTIONS.AUTHORTITLE2" | translate }}
								</h6>
								<p class="paragraph">
									{{ "MENUACTIONS.AUTHORSUBTITLE2" | translate }}
								</p>
							</div>
						</div>
					</div>
					<div class="cards">
						<div class="card-item" (click)="goModule('encuestas')">
							<div class="card-image">
								<div class="icon-option icon-proyecto-muchos-grafos pointer position-relative">
									<nb-icon class="plus-editor" icon="plus-outline"></nb-icon>
								</div>
							</div>
							<div class="card-info">
								<h6 class="card-title">
									{{ "MENUACTIONS.AUTHORTITLE5" | translate }}
								</h6>
								<p class="paragraph">
									{{ "MENUACTIONS.AUTHORSUBTITLE2" | translate }}
								</p>
							</div>
						</div>
					</div>
					<!-- <div class="cards disabled-card">
						<div class="card-item" (click)="goModule('settings')">
							<div class="card-image">
								<div class="icon-option icon-settings pointer"></div>
							</div>
							<div class="card-info">
								<h6 class="card-title">{{ 'MENUACTIONS.AUTHORTITLE3' | translate }}</h6>
								<p class="paragraph">{{ 'MENUACTIONS.AUTHORSUBTITLE3' | translate }}</p>
							</div>
						</div>
					</div> -->
				</div>
				<!--               MENU PADRE               -->
				<div *ngIf="profile == 'PADRE'">
					<nb-card class="background_themecolor">
						<nb-card-body class="container">
							<div class="cards">
								<div class="card-item" (click)="ajustes()">
									<div class="card-image">
										<div class="icon-option icon-settings pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.PARENTTITLE1" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.PARENTSUBTITLE1" | translate }}
										</p>
									</div>
								</div>
							</div>
							<div class="cards">
								<div class="card-item" (click)="informesPadre()">
									<div class="card-image">
										<div class="icon-option icon-informes pointer"></div>
									</div>
									<div class="card-info">
										<h6 class="card-title">
											{{ "MENUACTIONS.STUDENTTITLE8" | translate }}
										</h6>
										<p class="paragraph">
											{{ "MENUACTIONS.STUDENTSUBTITLE6" | translate }}
										</p>
									</div>
								</div>
							</div>
						</nb-card-body>
					</nb-card>
				</div>
				<!-- 							MENU ADMIN 								-->
				<div class="" *ngIf="profile == 'ADMIN'">
						<nb-card class="background_themecolor">
							<nb-card-header>
								<div class="row">
									<div class="col-12">
										<h2 class="text-center">
											{{ "MENUACTIONS.DASHBOARDTITTLE" | translate }}

										</h2>
									</div>
								</div>
							</nb-card-header>
							<nb-card-body class="container">
								<div class="cards">
									<div class="card-item" (click)="openTable()">
										<div class="card-image">
											<div
												class="icon-option icon-classroom pointer"
											></div>
										</div>
										<div class="card-info">
											<h6 class="card-title">
												<!-- {{ "MENUACTIONS.TEACHERTITLE1" | translate }} -->
												{{ "MENUACTIONS.DASHBOARD1" | translate }}
											</h6>
											<p class="paragraph">
												<!-- {{ "MENUACTIONS.TEACHERSUBTITLE1" | translate }} -->
												({{ "MENUACTIONS.DASHBOARDSUB1" | translate }})
											</p>
										</div>
									</div>
								</div>

								<div class="cards">
									<div class="card-item" (click)="openTableIndividual()">
										<div class="card-image">
											<div
												class="icon-option icon-person pointer"
											></div>
										</div>
										<div class="card-info">
											<h6 class="card-title">
												<!-- {{ "MENUACTIONS.TEACHERTITLE1" | translate }} -->
												{{ "MENUACTIONS.DASHBOARD2" | translate }}
											</h6>
											<p class="paragraph">
												<!-- {{ "MENUACTIONS.TEACHERSUBTITLE1" | translate }} -->
												({{ "MENUACTIONS.DASHBOARDSUB2" | translate }})
											</p>
										</div>
									</div>
								</div>

								<div class="cards">
									<div class="card-item" (click)="openModalStructure()">
										<div class="card-image">
											<div
												class="icon-option icon-center pointer"
											></div>
										</div>
										<div class="card-info">
											<h6 class="card-title">
												<!-- {{ "MENUACTIONS.TEACHERTITLE1" | translate }} -->
												Estructura
											</h6>
											<p class="paragraph">
												<!-- {{ "MENUACTIONS.TEACHERSUBTITLE1" | translate }} -->

											</p>
										</div>
									</div>
								</div>
							</nb-card-body>
						</nb-card>

				</div>
				<!-- 							MENU CENTRO 							-->
				<!-- <div class="" *ngIf="profile == 'CENTRO'">
						<nb-card class="background_themecolor">
							<nb-card-header>
								<div class="row">
									<div class="col-12">
										<h2 class="text-center">
										</h2>
									</div>
								</div>
							</nb-card-header>
							<nb-card-body class="container">
								<div class="cards">
									<div class="card-item" (click)="openSchedules()">
										<div class="card-image">
											<div
												class="icon-option icon-calendar pointer"
											></div>
										</div>
										<div class="card-info">
											<h6 class="card-title">
												{{ "MENUACTIONS.QUALITYCONTROL" | translate }}
											</h6>
											<p class="paragraph">
												{{ "MENUACTIONS.QUALITYCONTROLSUBTITLE" | translate }}
											</p>
										</div>
									</div>
								</div>
								<div class="cards">
									<div class="card-item" (click)="openControlPanel()">
										<div class="card-image">
											<div
												class="icon-option icon-informes pointer"
											></div>
										</div>
										<div class="card-info">
											<h6 class="card-title">
												{{ "CENTROS.COMMANDCENTER" | translate }}
											</h6>
											<p class="paragraph">
												{{ "MENUACTIONS.REPORTSSUBTITLE" | translate }}
											</p>
										</div>
									</div>
								</div>
								<div class="cards">
									<div class="card-item" (click)="openModalStructure()">
										<div class="card-image">
											<div
												class="icon-option icon-center pointer"
											></div>
										</div>
										<div class="card-info">
											<h6 class="card-title">
												Estructura
											</h6>
											<p class="paragraph">
											</p>
										</div>
									</div>
								</div>
								<div class="cards">
									<div class="card-item" (click)="goModule('settings')">
										<div class="card-image">
											<div
												class="icon-option icon-settings pointer"
											></div>
										</div>
										<div class="card-info">
											<h6 class="card-title">
												{{ "SIDEBAR.SETTINGS" | translate }}
											</h6>
											<p class="paragraph">
											</p>
										</div>
									</div>
								</div>
							</nb-card-body>
						</nb-card>
				</div> -->
			</div>
		</div>
		<ng-template #dialog let-data let-ref="dialogRef">
			<nb-card class="position-relative mb-0">
				<nb-card-body>
					<div class="d-flex flex-column container">
						<h6>{{infoTitle}}</h6>
						<p>{{infoText}}</p>
					</div>
				</nb-card-body>
				<div class="closeModal">
					<nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}"
						placement="left" [options]="{ animation: { type: 'zoom' } }" (click)="ref.close()">
					</nb-icon>
				</div>
			</nb-card>
		</ng-template>
	</nb-card-body>
</nb-card>
