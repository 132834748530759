<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="flex border-bottom-0">
		<div class="col-10">
			<h4 *ngIf="id == 'nuevo'">{{ "GRUPOS.NUEVOGRUPO" | translate }}</h4>
			<h4 *ngIf="id != 'nuevo'">{{ group.title }}</h4>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}"
			nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body class="p-0">
		<app-loading [isVisible]="cargando"></app-loading>

		<nb-card class="no-edge-card background_themecolor p-4 mb-0">
			<form [formGroup]="formGroup" #thisForm="ngForm" novalidate class="form-horizontal">
				<div class="row">
					<div class="col-12">
						<app-alert context="ModalEditarCurso"></app-alert>
					</div>
				</div>
				<div class="sticky-top">
					<div class="container">
						<div class="row">
							<div class="col-md-5 col-sm-12">
								<app-imgupload formControlName="image" [isShowDelete]="true"></app-imgupload>
								<mat-error *ngFor="let validation of validationMessages.image">
									<mat-error class="error-message" *ngIf="formGroup.get('image')?.hasError(validation.type) && (formGroup.get('image')?.dirty ||
																			formGroup.get('image')?.touched || thisForm.submitted)">
										{{ validation.message }}
									</mat-error>
								</mat-error>
								<p class="text-center m-4">
									{{ "EDITARCURSO.AUTORES" | translate }}:
									{{ id == "nuevo" ? user?.firstName + " " + user?.surname : autor?.firstName + " " + autor?.surname }}
								</p>
							</div>
							<div class="col-md-7 col-sm-12">
								<div class="row mb-3 flex-lg-row flex-column">
									<div class="col-3">
										<nb-select placeholder="Seleccionar Nivel" formControlName="nivel" class="SelectForm style-small" hero>
											<nb-option class="SelectFormOpt" *ngFor="let nivel of countryLevels" [value]="nivel.countryLevel">{{ nivel.countryLevel }}</nb-option>
										</nb-select>
										<mat-error *ngFor="let validation of validationMessages.nivel">
											<mat-error class="error-message" *ngIf="formGroup.get('nivel')?.hasError(validation.type) && (formGroup.get('nivel')?.dirty || formGroup.get('nivel')?.touched || thisForm.submitted)">
												{{ validation.message }}
											</mat-error>
										</mat-error>
									</div>
									<div class="col-3">
										<nb-select placeholder="Seleccionar Letra" formControlName="letra" class="SelectForm style-small" hero>
											<nb-option class="SelectFormOpt" *ngFor="let letra of letras" [value]="letra">{{ letra }}</nb-option>
										</nb-select>
										<mat-error *ngFor="let validation of validationMessages.letra">
											<mat-error class="error-message" *ngIf="formGroup.get('letra')?.hasError(validation.type) && (formGroup.get('letra')?.dirty || formGroup.get('letra')?.touched || thisForm.submitted)">
												{{ validation.message }}
											</mat-error>
										</mat-error>
									</div>
									<div class="col-6">
										<nb-select fullWidth placeholder="Seleccionar Asignatura Principal" formControlName="idSubject" class="SelectForm" hero>
											<nb-option class="SelectFormOpt" *ngFor="let subject of subjects | sortBy:'asc':'subject'" [value]="subject.idSubject">{{ subject.subject }}</nb-option>
										</nb-select>
										<mat-error *ngFor="let validation of validationMessages.idSubject">
											<mat-error class="error-message" *ngIf="formGroup.get('idSubject')?.hasError(validation.type) && (formGroup.get('idSubject')?.dirty || formGroup.get('idSubject')?.touched || thisForm.submitted)">
												{{ validation.message }}
											</mat-error>
										</mat-error>
									</div>
								</div>
								<input type="text" nbInput class="form-control" formControlName="title" name="title" fullWidth
									placeholder="{{ 'GRUPOS.TITLE' | translate }}" maxlength="50" />
								<mat-error *ngFor="let validation of validationMessages.title">
									<mat-error class="error-message" *ngIf="
																		formGroup.get('title')?.hasError(validation.type) && (formGroup.get('title')?.dirty || formGroup.get('title')?.touched ||
																			thisForm.submitted)">
										{{ validation.message }}
									</mat-error>
								</mat-error>

								<!-- <mat-form-field class="full-width">
																<mat-label>{{ 'EDITARCURSO.TITLE' | translate }}</mat-label>
																<input matInput formControlName="title" name="title" placeholder="">

															</mat-form-field> -->

								<textarea class="mt-3 form-control" nbInput fullWidth formControlName="description" name="description" rows="3"
									placeholder="{{ 'EDITARCURSO.DESCRIPCION' | translate }}" maxlength="250">
								</textarea>
								<mat-error *ngFor="let validation of validationMessages.description">
									<mat-error class="error-message" *ngIf="formGroup.get('description') ?.hasError(validation.type) &&
																		(formGroup.get('description')?.dirty || formGroup.get('description')?.touched || thisForm.submitted)">
										{{ validation.message }}
									</mat-error>
								</mat-error>
								<!-- <mat-form-field class="full-width mt-2">
																<mat-label>{{ 'EDITARCURSO.DESCRIPCION' | translate }}</mat-label>
																<textarea matInput formControlName="description" name="description" rows="3"></textarea>
																<mat-error *ngFor="let validation of validationMessages.description">
																	<mat-error class="error-message"
																		*ngIf="formGroup.get('description')?.hasError(validation.type) && (formGroup.get('description')?.dirty || formGroup.get('description')?.touched || thisForm.submitted)">
																		{{validation.message}}</mat-error>
																</mat-error>
															</mat-form-field> -->

								<div class="row mb-2 mt-2">
									<div id="lang" formGroupName="langGroup" class="col-md-8 col-sm-12">
										<div class="row">
											<div class="col-6" *ngIf="false">
												<!-- PONERMOS EL NGIF EN FALSE PARA OCULTAR EL CAMPO PERO QUEDA PREDETERMINADO EN ESPAÑOL (MAYO 2023)-->
												<nb-select fullWidth placeholder="{{'EDITARCURSO.SELECCIONIDIOMA1' | translate }}" formControlName="lang1">
													<nb-option *ngFor="let idioma of idiomas | sortBy : 'asc' : 'language' " [value]="idioma.idLanguage">{{ idioma.language }}</nb-option>
												</nb-select>
												<mat-error *ngFor="let validation of validationMessages.lang1">
													<mat-error class="error-message" *ngIf="lang1Control?.hasError(validation.type) && (lang1Control?.dirty ||
																							lang1Control?.touched || thisForm.submitted)">
														{{ validation.message }}
													</mat-error>
												</mat-error>

												<!-- <mat-form-field class="full-width">
																				<mat-label>{{ 'EDITARCURSO.SELECCIONIDIOMA1' | translate }}</mat-label>
																				<select matNativeControl formControlName="lang1">
																					<option *ngFor="let idioma of idiomas | sortBy:'asc':'language'"
																						[ngValue]="idioma.idLanguage">{{ idioma.language }}</option>
																				</select>
																				<mat-error *ngFor="let validation of validationMessages.lang1">
																					<mat-error class="error-message"
																						*ngIf="lang1Control?.hasError(validation.type) && (lang1Control?.dirty || lang1Control?.touched || thisForm.submitted)">
																						{{validation.message}}</mat-error>
																				</mat-error>
																			</mat-form-field> -->
											</div>
										</div>
									</div>
								</div>

								<div class="d-flex gap-3">
									<button class="btn-red" *ngIf="id != 'nuevo' && !terminarNuevoGrupo" nbButton (click)="deleteGroup()"
										[disabled]="saving" size="small">
										{{ "EDITARCURSO.ELIMINAR" | translate }}
									</button>

									<button *ngIf="id != 'nuevo'" nbButton class="btn-themecolor" (click)="grabarGrupo()"
										[disabled]="saving" size="small">
										{{saving ? ("EDITARCURSO.SAVING" | translate) : ("EDITARCURSO.GRABAR" | translate)}}
									</button>

									<button *ngIf="id == 'nuevo'" nbButton class="btn-themecolor" (click)="grabarGrupo()"
										size="small" [disabled]="saving">
										{{saving ? ("EDITARCURSO.SAVING" | translate) : ("EDITARCURSO.CREAR" | translate)}}
									</button>

									<!-- <button class="mr-2" *ngIf="id!='nuevo'" mat-raised-button color="warn"
																										(click)="deleteGroup()" [disabled]="saving">{{ 'EDITARCURSO.ELIMINAR' | translate
																										}}</button> -->
									<!-- <button *ngIf="id!='nuevo'" mat-raised-button color="primary" (click)="grabarGrupo()"
																										[disabled]="saving">{{ saving ? ('EDITARCURSO.SAVING' | translate) :
																										('EDITARCURSO.GRABAR' | translate) }}</button>

																									<button *ngIf="id=='nuevo'" mat-raised-button color="primary" (click)="grabarGrupo()"
																										[disabled]="saving">{{ saving ? ('EDITARCURSO.SAVING' | translate) :
																										('EDITARCURSO.GRABAR' | translate) }}</button> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</nb-card>
		<nb-card-footer class="border-top-0 p-0">
			<div class="cards col-lg-3 col-md-6 col-12" *ngIf="id != 'nuevo'">
				<div class="card-item" (click)="listadoCursos()">
					<div class="card-image">
						<div class="icon-option icon-proyecto-muchos-grafos pointer"></div>
						<div class="icon-option icon-flecha-derecha pointer"></div>
						<div class="icon-option icon-groups pointer"></div>
					</div>
					<div class="card-info">
						<h6 class="card-title">
							{{ "MENUACTIONS.TEACHERTITLE9" | translate }}
						</h6>
						<p class="paragraph">
							{{ "MENUACTIONS.TEACHERSUBTITLE9" | translate }}
						</p>
					</div>
				</div>
			</div>
			<div class="cards col-lg-3 col-md-6 col-12" *ngIf="id != 'nuevo'">
				<div class="card-item" (click)="listadoAlumnos(false)">
					<div class="card-image">
						<div class="icon-option icon-student pointer"></div>
						<div class="icon-option icon-flecha-derecha pointer"></div>
						<div class="icon-option icon-groups pointer"></div>
					</div>
					<div class="card-info">
						<h6 class="card-title">
							{{ "MENUACTIONS.TEACHERTITLE8" | translate }}
						</h6>
						<p class="paragraph">
							{{ "MENUACTIONS.TEACHERSUBTITLE8" | translate }}
						</p>
					</div>
				</div>
			</div>
			<div class="cards col-lg-3 col-md-6 col-12" *ngIf="id != 'nuevo'">
				<div class="card-item" (click)="instantActivity()">
					<div class="card-image">
						<div class="icon-option icon-desafios-icon pointer"></div>
					</div>
					<div class="card-info">
						<h6 class="card-title">
							{{ "MENUACTIONS.TEACHERTITLE7" | translate }}
						</h6>
						<p class="paragraph">
							{{ "MENUACTIONS.TEACHERSUBTITLE7" | translate }}
						</p>
					</div>
				</div>
			</div>
			<div class="cards col-lg-3 col-md-6 col-12" *ngIf="id != 'nuevo'">
				<div class="card-item" (click)="informesGrupo()">
					<div class="card-image">
						<div class="icon-option icon-informes pointer"></div>
					</div>
					<div class="card-info">
						<h6 class="card-title">
							{{ "MENUACTIONS.TEACHERTITLE5" | translate }}
						</h6>
						<p class="paragraph">
							{{ "MENUACTIONS.TEACHERSUBTITLE5" | translate }}
						</p>
					</div>
				</div>
			</div>
		</nb-card-footer>
	</nb-card-body>

</nb-card>
