import { Injectable } from '@angular/core';
import { LocalStorage } from 'src/app/core/utils/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { LOCALSTORAGESTRINGS } from '../models/masters/localstorage.enum';

const DEFAULT_LANG:string = 'en'

export const APP_LANGUAGES = {
    EN : 'en',
    ES : 'es',
    PT : 'pt',
    DE : 'de'
}

@Injectable({
  providedIn: 'root'
})
export class LangUtils {

	constructor(private translateService:TranslateService, private localStorage: LocalStorage) {}

	/**
	 * Get the language from the local storage. If it doesn't have, I get it from the browser and store it
	 * @param availableLangs Param with available langs in app (ex: ['de','es','en'])
	 */
	public setAppLang(availableLangs:String[]):void{
		let lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG)
		if(!lang)
			lang = this.getNavigatorLang(availableLangs)

		this.localStorage.setItem(LOCALSTORAGESTRINGS.LANG,lang)
		this.translateService.use(lang)
	}

	/**
	 * If the language exists in the browser, return it, if not, return the default language
	 * @param availableLangsParam with available langs in app (ex: ['de','es','en'])
	 * @returns Lang string
	 */
	private getNavigatorLang(availableLangs:String[]):string{
		let navLang = navigator.language
		if(!navLang)
			return DEFAULT_LANG

		let splitedLang = navLang.split('-')[0]
		if(availableLangs.includes(splitedLang))
			return splitedLang.toLowerCase()
		else
			return DEFAULT_LANG
	}

}
