<form class="container" [formGroup]="infoForm">
	<div class="col-12 d-flex justify-content-end align-items-center">
		<span class="title-timechallenge">{{'MODALCONFIGSOCKETCOURSES.TIMECHALLENGE' | translate}}</span>
		<ngb-timepicker formControlName="duration" [seconds]="false" (change)="onTextBlur($event)"
			(click)="onTextBlur($event)"></ngb-timepicker>
		<!-- <mat-form-field appearance="standard" class="full-width">
					<input matInput type="time" formControlName="duration" (blur)="onTextBlur($event)" />
			</mat-form-field> -->
	</div>
	<div class="row">
		<div class="col-12">
			<div class="d-flex flex-column">
				<label>{{'QUIZZES.TITULO' | translate}}</label>
				<textarea nbInput fullWidth formControlName="title" (blur)="onTextBlur($event)" rows="2"
					(keydown)="keydown($event)" maxlength="250" class="form-control">
				</textarea>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<div class="d-flex flex-column">
				<label>{{'QUIZZES.INSTRUCCIONES' | translate}}</label>
				<textarea nbInput fullWidth formControlName="instructions" rows="4"
					(blur)="onTextBlur($event)" maxlength="250" class="form-control">
				</textarea>
			</div>
		</div>
	</div>
</form>
