import { Action } from '@ngrx/store';
import { availableProfiles } from "../models/profiles.model"

export enum ActionTypes {
	loadProfiles = '[Profiles ACTION] register profiles',
}

export class loadProfiles implements Action {
	readonly type = ActionTypes.loadProfiles;
	constructor(public payload: availableProfiles) { }
}


export type ActionsUnion = loadProfiles;
