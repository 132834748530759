<div class="bg-white">
    <form [formGroup]="form" >
        <quill-editor #editor style="height: 66.8vh;" formControlName="content" (onEditorCreated)="setFocus($event)"></quill-editor>
    </form>

    <div class="row d-flex justify-content-center">
        <!-- <div class="mt-2 mb-2 col-3">
            <button type="button" class="btn btn-primary btn-lg btn-block" (click)="closeModal(textSource)">{{ 'QUIZZES.GUARDAR' | translate}}</button>
        </div> -->
        <div class="mt-2 mb-2 col-3">
            <button type="button" class="btn btn-danger btn-lg btn-block" (click)="closeModal(textSource)">{{ 'QUIZZES.CERRAR' | translate}}</button>
        </div>
    </div>




</div>
