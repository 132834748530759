<nb-card [nbSpinner]="loading == true" nbSpinnerStatus="danger" nbSpinnerSize="giant"
	nbSpinnerMessage="{{ 'SALWIZAR.GENERATINNGQUIZZES' | translate }}" class="mb-0">
  <nb-card-header class="border-bottom-0" >
		<div class="col-11" >
			<div>
				<h5>{{ 'SALWIZAR.PROVIDESINSTRUCTIONS' | translate }}</h5>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}"
			nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="m-5 row d-flex">
			<div class="modal-body">
				<div *ngIf="salwizarForm">
					<form [formGroup]="salwizarForm" autocomplete="off">
						<div class="d-flex w-100 flex-column gap-3">
							<div class="row justify-content-center">
								<div class="col-12 d-flex flex-column w-100">
									<label for="select2">{{ 'FORM.LANGUAGE' | translate }}:</label>
									<nb-select formControlName="lang" id="select2" class="SelectForm" hero size="small" style="max-width: 100%;">
										<nb-option class="SelectFormOpt" value="Español">Spanish</nb-option>
										<nb-option class="SelectFormOpt" value="Ingles">English</nb-option>
										<nb-option class="SelectFormOpt" value="Frances">French</nb-option>
									</nb-select>
								</div>
							</div>
							<div class="row justify-content-center">
								<div class="col-12">
									<label>{{ 'SALWIZAR.NUMBEROFACTIVITIES' | translate }}:</label>
									<input type="number" formControlName="cant" min="1" max="10" nbInput fullWidth fieldSize="medium"
										class="form-control">
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</nb-card-body>
	<nb-card-footer class="border-top-0">
		<div class="mb-2 row justify-content-center">
			<div class="col-6 d-flex justify-content-center">
				<button nbButton status="primary" [disabled]="loading == true" size="small" (click)="generateQuizzes()">
					{{ 'SALWIZAR.GENERATEQUIZZES' | translate }}
				</button>
			</div>
		</div>
	</nb-card-footer>
</nb-card>
