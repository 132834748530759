<div class="sticky-top sticky-top-header">
        <div class="modal-header" align="center">
            <div class="col-1">
                <i class="material-icons ti-size-2">groups</i>
            </div>
            <div class="col-3">
                <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'TASK.RIVALGROUPSEARCH' | translate }}</span>
            </div>
            <div class="col-7 text-center">
                <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{task.nomTarea}}</span>
            </div>
            <div class="col-1 text-center">
                <button type="button" class="close pointer" [ngStyle]="{'color':'rgb(29, 73, 153)'}" data-dismiss="modal"
                    (click)="closeModal('')">
                    <span class="textoFontSize18" aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
</div>
