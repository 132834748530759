import { User } from "./../../../../core/models/users/user.models";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";

import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

// Models
import {
	CourseModel,
	CourseTargetModel,
	DetailCourseTargetModel,
} from "src/app/core/models/courses";
import { MateriaModel } from "src/app/core/models/masters";

// Services
import { TranslateService } from "@ngx-translate/core";
import { TargetsService } from "src/app/core/services/targets";
import { LoginService } from "src/app/core/services/login";
import { ModalAceptarCancelarComponent } from "../../modal";
import { ImagenPipe } from "src/app/shared/pipes/imagen.pipe";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { GetDataService } from "src/app/core/services/get-data/get-data.service";
import { environment } from "src/environments/environment";
import { ModelEditGrafo } from "src/app/core/models/graph/grafo-edit.model";
import {
	CursoData,
	TutotrialDataLocal,
} from "src/app/core/models/tuturial/tutorial.model";
import { NgxCopilotService } from "ngx-copilot";
import { LocalStorage } from "src/app/core/utils";
import { LOCALSTORAGESTRINGS } from "src/app/core/models/masters/localstorage.enum";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { Utils } from "src/app/core/utils/utils";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { DialogService } from "src/app/core/services/shared/dialog.service";

interface ViewTypes {
	viewType: number;
	description: string;
}

@Component({
	selector: "app-editar-curso-mapa",
	templateUrl: "./modal-editar-curso-mapa.component.html",
	styleUrls: ["./modal-editar-curso-mapa.component.scss"],
	encapsulation: ViewEncapsulation.None,
	providers: [ImagenPipe],
})
export class ModalEditarCursoMapaComponent implements OnInit {
	public form: UntypedFormGroup;
	progress: number = 0;
	id: string;
	user: User;
	curso: CourseModel;
	mapa: CourseTargetModel;
	idImage: string = "";
	target: DetailCourseTargetModel;
	saving: boolean = false;
	publishToggle: boolean = false;
	haveMultiple: boolean = false;
	listMultiples: any;
	configMultiples: any;
	screenView: number = 1;
	challengesExclusive: boolean;

	// Maestros
	materias: MateriaModel[] = [];
	cargando: boolean = true;

	validationMessages = {
		title: [],
		description: [],
		subject: [],
	};
	tutorialCurso: CursoData = {
		btnNuevo: false,
		btnPublicar: false,
		ideografo: true,
		editGrafo: false,
	};

	viewTypesList: ViewTypes[] = [
		{
			viewType: 1,
			description: this.translateService.instant("TARGETVIEWTYPESLIST.DESC1"),
		},
		{
			viewType: 2,
			description: this.translateService.instant("TARGETVIEWTYPESLIST.DESC2"),
		},
		{
			viewType: 3,
			description: this.translateService.instant("TARGETVIEWTYPESLIST.DESC3"),
		},
		{
			viewType: 4,
			description: this.translateService.instant("TARGETVIEWTYPESLIST.DESC4"),
		},
	];

	constructor(
		public router: Router,
		public translateService: TranslateService,
		private loginService: LoginService,
		public targetsService: TargetsService,
		private mastersService: GetDataService,
		public activeModal: NgbActiveModal,
		private fb: UntypedFormBuilder,
		private modalService: NgbModal,
		private imagePipe: ImagenPipe,
		private copilot: NgxCopilotService,
		private localStorageService: LocalStorage,
		private utils: Utils,
		private toaster: ToasterService,
		private localStorage: LocalStorage,
		private dialogService: DialogService,
	) {}

	ngOnInit() {
		this.cargando = true;
		this.user = this.loginService.getUser();
		this.materias = this.mastersService.appSubjects;
		this.generateForm();
		if (this.id !== "nuevo") {
			// Si no es nuevo busco sus datos
			this.getListMultiples();
			this._getTargetDetail();
		}
		this._translateText();
		if (this.loginService.comprobarTutorial()) {
			let canvasGrafoTutorial = JSON.parse(
				this.localStorageService.getItem(LOCALSTORAGESTRINGS.TUTORIALDATA)
			) as TutotrialDataLocal;
			this.tutorialCurso = canvasGrafoTutorial.curso;
			this.abrirTutorial();
		}
	}

	getListMultiples() {
		this.targetsService
			.getListMultiplesByTarget(this.curso.idCourse, parseInt(this.id))
			.subscribe((res) => {
				this.listMultiples = res.data;
				if (this.listMultiples != null && this.listMultiples.length != 0) {
					this.haveMultiple = true;
					this.targetsService
						.getListTargetQuizzesMultiplesActiveByTarget(
							this.target.idTarget,
							0
						)
						.subscribe((res) => {
							this.configMultiples = res.data;
						});
				}
			});
	}

	changeScreen(screen: number) {
		this.getListMultiples();
		this.screenView = screen;
	}

	abrirTutorial() {
		if (!this.tutorialCurso.editGrafo) {
			this.cargando = true;
			setTimeout(() => {
				this.cargando = false;
				this.copilot.next(9);
			}, 500);
		}
	}
	/*Re initialize in specify step*/
	done = () => {
		this.copilot.removeWrapper();
		let cursoTotorial = JSON.parse(
			this.localStorageService.getItem(LOCALSTORAGESTRINGS.TUTORIALDATA)
		) as TutotrialDataLocal;
		cursoTotorial.curso.editGrafo = true;
		this.loginService.updateDataLocalTutorial(cursoTotorial);
	};

	private _getTargetDetail() {
		this.cargando = true;
		this.targetsService.getTargetById(parseInt(this.id)).subscribe((result) => {
			this.target = result.data;
			this.form.patchValue({
				...result.data,
				image: result.data.backgroundImage
					? this.imagePipe.transform(result.data.backgroundImage, "mapas")
					: result.data.backgroundImage,
				published: this.target.published ? true : false,
				challenge: this.target.exclusive,
				viewType: this.target.viewType,
			});
			if (!this.isMine()) {
				this.form.disable();
			}
			this.cargando = false;
		});
		this.targetsService.registercountopengraph().subscribe((res) => {});
	}

	private _translateText() {
		this.translateService
			.get("VALIDACIONES.TITLEREQUIRED")
			.subscribe((res: string) => {
				this.validationMessages.title.push({ type: "required", message: res });
			});
		this.translateService
			.get("VALIDACIONES.DESCRIPTIONREQUIRED")
			.subscribe((res: string) => {
				this.validationMessages.description.push({
					type: "required",
					message: res,
				});
			});
		this.translateService
			.get("VALIDACIONES.SUBJECTREQUIRED")
			.subscribe((res: string) => {
				this.validationMessages.subject.push({
					type: "required",
					message: res,
				});
			});
	}

	generateForm() {
		this.form = this.fb.group({
			image: [{ value: "", disabled: false }],
			tittle: ["", Validators.required],
			description: ["", Validators.required],
			idSubject: [this.curso.subject.idSubject, Validators.required],
			viewType: [2, Validators.required],
			labels: [""],
			published: [false],
			challenge: [this.challengesExclusive],
		});
		this.cargando = false;
	}

	isMine(): boolean {
		/*if (this.loginService.esAutor() && this.user.idUser === this.curso.user.idUser)
            return true
        return false*/
		return true;
	}

	/**
	 * Publish or unpublish target
	 * @param $ev Click event in the switch component
	 */

	publish($ev) {
		$ev.preventDefault();
		$ev.stopImmediatePropagation();
		let currentValue: boolean = this.form.get("published").value;
		let modalMessage: string = currentValue
			? this.translateService.instant("EDITARCURSOMAPA.PUBLISHMSG")
			: this.translateService.instant("EDITARCURSOMAPA.UNPUBLISHMSG");
		let errorMessage: string = currentValue
			? this.translateService.instant("GENERAL.KOPUBLISH")
			: this.translateService.instant("GENERAL.KOUNPUBLISH");
		let okMessage: string = currentValue
			? this.translateService.instant("EDITARCURSOMAPA.GRAFOVISIBLE")
			: this.translateService.instant("EDITARCURSOMAPA.GRAFONOVISIBLE");
		this.mastersService
			.setPublishType("target", Number(this.id), currentValue)
			.subscribe(
				(result) => {
					if (result.data) {
						this.toaster.success(okMessage);
						this.form.get("published").setValue(currentValue); // Update form value
					} else
						this.toaster.error(
							this.translateService.instant("EDITARCURSOMAPA.KOPUBLISHCONTENT")
						);
				},
				(err) => {
					this.toaster.error(errorMessage);
				}
			);
	}

	/**
	 * Publish or unpublish target to Challenge mode
	 * @param $ev Click event in the switch component
	 */

	challenge($ev) {
		$ev.preventDefault();
		$ev.stopImmediatePropagation();
		let currentValue: boolean = this.form.get("challenge").value;
		let modalMessage: string = currentValue
			? this.translateService.instant("EDITARCURSOMAPA.PUBLISHCHALLENGEMSG")
			: this.translateService.instant("EDITARCURSOMAPA.UNPUBLISHCHALLENGEMSG");
		let errorMessage: string = currentValue
			? this.translateService.instant("GENERAL.KOCHALLENGE")
			: this.translateService.instant("GENERAL.KOUNCHALLENGE");
		let okMessage: string = currentValue
			? this.translateService.instant("GENERAL.OKCHALLENGE")
			: this.translateService.instant("GENERAL.OKUNCHALLENGE");
		//Open modal message alert to confirm the selection
		const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
		modalRef.componentInstance.mensaje = modalMessage;
		modalRef.result.then((result: boolean) => {
			if (result) {
				this.targetsService
					.setExclusiveGraph(Number(this.id), currentValue)
					.subscribe(
						(result) => {
							this.toaster.success(okMessage);
							this.challengesExclusive = currentValue;
							this.form.get("challenge").setValue(currentValue); // Update form value
						},
						(err) => {
							this.toaster.error(errorMessage);
						}
					);
			}
		});
	}

	grabarCursoMapa() {
		if (this.form.valid && this.changues()) {
			let image: File | string = "";
			this.saving = true;
			const v = this.form.value;
			let request: DetailCourseTargetModel = new DetailCourseTargetModel(
				v.tittle,
				v.description,
				v.idSubject,
				this.user.idUser,
				v.labels,
				v.viewType
			);
			request.exclusive = this.challengesExclusive;
			if (this.id !== "nuevo") {
				if(v.published && this.target.published === null){
					request.published = Date.now();
				}else if(!v.published){
					request.published = null;
				}else if(v.published && this.target.published !== null){
					request.published = this.target.published;
				}
				request.creationDate = this.target.creationDate;
				request.targetImage = this.target.targetImage !== null ? this.target.targetImage : null;
				request.idTarget = this.target.idTarget;
				//Si el target no tiene imagen
				if (
					this.target.backgroundImage == "" ||
					this.target.backgroundImage == null
				) {
					if (typeof v.image === "object") image = v.image;
				} else {
					//Si el curso tiene imagen
					if (typeof v.image == "string" && v.image != ""){
						request.backgroundImage = this.target.backgroundImage;
					}else if (typeof v.image == "object") {
						request.backgroundImage = this.target.backgroundImage;
						image = v.image;
					} else if (typeof v.image == "string" && v.image == "") {
						request.backgroundImage = null;
					}
				}
			} else {
				image = v.image;
				request.published = v.published ? Date.now() : null;
			}
			this.targetsService
				.setTarget(request, image, this.curso.idCourse)
				.subscribe(
					(event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.UploadProgress:
								this.progress = Math.round((event.loaded / event.total) * 100);
								break;
							case HttpEventType.Response:
								setTimeout(() => {
									this.progress = 0;
									this.saving = false;
									this.toaster.success(this.translateService.instant("EDITARCURSO.OKSAVE"));
									this.activeModal.close("ok");
								}, 500);
						}
					},
					(error) => {
						this.saving = false;
						this.toaster.error(
							this.translateService.instant("EDITARCURSO.KOSAVE")
						);
					}
				);
		}
	}

	deleteGraph() {
		if (!this.loginService.esAutor()) return;
		// if(this._isCurrentPageNode())
		if (false) {
			this.toaster.error(
				this.translateService.instant("EDITARCURSO.DELETECURRENTGRAPH")
			);
			return;
		}
		// Abro un modal preguntando si desea borrar el curso o no
		const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
		modalRef.componentInstance.mensaje = this.translateService.instant(
			"EDITARCURSOMAPA.DELETEGRAPH"
		);
		modalRef.result.then((result: boolean) => {
			if (result) {
				this.targetsService.deleteTarget(parseInt(this.id)).subscribe(
					(result) => {
						this.activeModal.close("Delete graph");
						this.toaster.success(
							this.translateService.instant("EDITARCURSOMAPA.OKDELETE")
						);
					},
					(err) => {
						this.toaster.error(
							this.translateService.instant("EDITARCURSOMAPA.KODELETE")
						);
					}
				);
			}
		});
	}

	closeModal(sendData?: any) {
		//Primero guardar la informacion del formulario si es valida
		if (this.curso !== undefined && this.form.valid && this.changues()) {
			this.grabarCursoMapa();
			this.activeModal.close(sendData);
		} else {
			//this.toaster.error(this.translateService.instant('EDITARCURSO.KOSAVE'))
			this.activeModal.close(sendData);
		}
	}

	listaGrafosVisitados() {
    // Obtenemos el array de los últimos grafos visitados.
    let graphVisited_Editor = JSON.parse(this.localStorage.getItem('graphVisited_Editor')) || [];
    const newElement = {
        title: this.form.value.tittle,
        idGrafo: this.id,
        idCurso: this.curso.idCourse
    };
    // Validar si el nuevo elemento ya está en el array
    const isDuplicate = graphVisited_Editor.some(element =>
        element.idGrafo === newElement.idGrafo && element.idCurso === newElement.idCurso
    );
    // Si no es un duplicado, lo agregamos
    if (!isDuplicate) {
        // Si ya hay 10 elementos, eliminamos el ultimo.
        if (graphVisited_Editor.length >= 10) { // con esto determinamos el tamaño del array
            graphVisited_Editor.pop();
        }
        // Agregamos el nuevo elemento.
        graphVisited_Editor.unshift(newElement);
        // Actualizamos el local storage.
        this.localStorage.setItem('graphVisited_Editor', JSON.stringify(graphVisited_Editor));
    }
	}


	// VER NODOS MAPA
	verNodosMapa() {
    // Si soy estudiante le mando al mapa directamente
    if (this.loginService.esAutor()) {
			this.listaGrafosVisitados();
        // Primero guardar la información del formulario si es válida
        if (this.curso !== undefined && this.form.valid && this.changues()) {
            this.grabarCursoMapa();
        }
        // Verifica si la URL actual es la misma que la que intentas navegar
        const currentUrl = this.router.url;
        const targetUrl = `/course/${this.curso.idCourse}/graph/${this.id}`;
        if (currentUrl === targetUrl) {
					localStorage.setItem('dontLoadMenu', 'true')
					this.utils.loadMenu = false;
					// Si la URL es la misma, realiza un reload
					this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
							this.router.navigate([`/course/${this.curso.idCourse}/graph/${this.id}`])
							.then(() => {
								this.closeModal("closeAll");
								this.activeModal.close("closeAll");
						});;
					});
        } else {
					localStorage.setItem('dontLoadMenu', 'true')
					this.utils.loadMenu = false;
					// Si la URL es diferente, navega normalmente
					this.router.navigate([`/course/${this.curso.idCourse}/graph/${this.id}`])
							.then(() => {
									this.closeModal("closeAll");
									this.activeModal.close("closeAll");
							});
        }
				let historialGrafos_editor = [];
				let localStoredHistory_editor = JSON.parse(
					localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHSHISTORY_EDITOR)
				);
				if (localStoredHistory_editor == null) {
					historialGrafos_editor.push(this.curso.idCourse);
				} else {
					if (localStoredHistory_editor.length > 15) {
						localStoredHistory_editor.shift(); // Elimina el primer elemento
					}
					localStoredHistory_editor.push(this.curso.idCourse);
				}
				historialGrafos_editor.push(this.curso.idCourse);
				this.localStorage.setItem(
					LOCALSTORAGESTRINGS.GRAPHSHISTORY_EDITOR,
					JSON.stringify(
						localStoredHistory_editor == null ? historialGrafos_editor : localStoredHistory_editor
					)
				);
        return;
    }
}


	private _isCurrentPageNode(): boolean {
		let urlValues: any[] = this.router.url.split("/");
		if (urlValues.includes("course")) {
			urlValues.shift();
			if (
				parseInt(urlValues[1]) === this.curso.idCourse &&
				parseInt(urlValues[3]) === parseInt(this.id)
			)
				return true;
		}
		return false;
	}

	changues(): boolean {
		const v = this.form.value as ModelEditGrafo;

		//se valida que haya algun cambio en alguno de los campos para guardarlosn en el caso de estar undefine, significa que es nuevo
		if(this.target === undefined) {
			return true;
		} else if (
			this.target.tittle !== v.tittle ||
			this.target.description !== v.description ||
			this.target.labels !== v.labels ||
			this.target.idSubject !== v.idSubject ||
			this.target.viewType !== v.viewType ||
			this.changueImagen(this.target.backgroundImage, v.image)
		) {
			return true;
		} else
			return false;
	}

	changueImagen(cpicture: string, image: any): boolean {
		const imgPicUrl = `${environment.imagesTargets}${cpicture}`;
		if (imgPicUrl === image || (cpicture === null && image === null)) {
			return false;
		}
		return true;
	}

	traducirGrafo(){
		// Abrimos un modal para seleccionar idioma y traducir el grafo
		const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
		modalRef.componentInstance.optionalTitle = this.translateService.instant("EDITARCURSOMAPA.TRANSLATE");
		modalRef.componentInstance.mensaje = this.translateService.instant("EDITARCURSOMAPA.ALERTTRANSLATE");
		modalRef.componentInstance.traducirGrafo = true;
		modalRef.result.then((result: string) => {
			if (result) {
				this.cargando = true;
				//bloqueamos el grafo
				this.targetsService.lockTargetForTranslate(this.target.idTarget).subscribe(res => {
					this.cargando = false;
					this.targetsService.translateTarget(result, this.target.idTarget).subscribe(resp => {
						this.toaster.success(
							this.translateService.instant("EDITARCURSOMAPA.OKTRANSLATE")
							);
					});
					this.activeModal.close('FromTranslate');

				})
			}
		});
	}

}
