import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-head-tasks',
  templateUrl: './head-tasks.component.html',
  styleUrls: ['./head-tasks.component.scss']
})
export class HeadTasksComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }

}
