<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="border-bottom-0">
		<div class="row">
			<div class="col-12">
				<h5 class="text-center">
					{{ "COMPETITION.COMPETITION" | translate }} - {{ "COMPETITION.LOBBYWAITING" | translate }}
				</h5>
			</div>
		</div>
		<div class="row justify-content-center mt-3">
			<div class="d-flex flex-column align-items-center justify-content-center">
				<h6>{{ "COMPETITION.GAMECODE" | translate }}: {{hex}}</h6>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="closeModal('manualClosed')">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="row justify-content-center">
			<ng-container *ngIf="isClockShown == false; else cancelButton">
				<div class="d-flex flex-column align-items-end justify-content-end" *ngIf="cameFromLobby === false">
					<div><button nbButton class="btn-themecolor" (click)="startClock()">{{ "COMPETITION.STARTGAME" | translate }}</button></div>
				</div>
			</ng-container>
			<ng-template #cancelButton>
				<div class="d-flex flex-column align-items-end justify-content-end" *ngIf="cameFromLobby === false">
					<div><button nbButton class="btn-red" (click)="cancelStartClock()">{{ "GLOBALS.CANCEL" | translate }}</button></div>
				</div>
			</ng-template>

			<div class="col-12 mt-2" *ngIf="isClockShown">
				<div class="d-flex flex-md-row flex-column align-items-center">
					<span class="title-time-remaining mr-0 mr-md-3">{{ "COMPETITION.GAMESTARTING" | translate }}:</span>
					<countdown #cd [config]="configClock" (event)="handleCountDownEvent($event)"
						class="counter-down mt-md-0 mt-2"></countdown>
				</div>
			</div>
		</div>
		<div class="row d-flex justify-content-center mt-4">
			<div class="col-md-12 col-12">
				<nb-card>
					<ng-container *ngIf="group != null; else sinGrupo">
						<nb-card-header>
							{{ "COMPETITION.USERSCONNECTED" | translate }}
						</nb-card-header>
						<nb-card-body>
							<div class="row d-flex justify-content-center">
								<div class="row col-md-12 col-12" *ngIf="usersOfTheGroup">
									<div class="row col-md-12 col-12" *ngIf="usersConnectedToTheGame">

										<div *ngFor="let user of usersConnectedToTheGame" mwlDraggable (dragEnd)="dragEnd(user)" class="card">
											<div class="avatar" [ngStyle]="{ 'background-image': getUserAvatar(user.pictureUser ? user.pictureUser : '') }"></div>
											<div class="card-body">
												<h5 class="card-title">{{user.firstName}} {{user.surname}}</h5>
											</div>
										</div>
									</div>
								</div>
							</div>
						</nb-card-body>
					</ng-container>
					<ng-template #sinGrupo>
						<nb-card-header>
							{{ "COMPETITION.USERSCONNECTED" | translate }}
						</nb-card-header>
						<nb-card-body>
							<div class="row d-flex justify-content-center cards-container">
								<div class="row col-md-12 col-12" *ngIf="usersConnectedToTheGame">
									<div *ngFor="let user of usersConnectedToTheGame" mwlDraggable (dragEnd)="dragEnd(user)" class="card"
										style="width: 8rem;">
										<div class="avatar" [ngStyle]="{ 'background-image': getUserAvatar(user.pictureUser) }"></div>
										<div class="card-body">
											<h5 class="card-title">{{user.firstName}} {{user.surname}}</h5>
										</div>
									</div>
								</div>
							</div>
						</nb-card-body>
					</ng-template>



				</nb-card>
				<!-- <nb-card *ngIf="cameFromLobby === false">
					<nb-card-header>
						{{ "COMPETITION.TEAMS" |
						translate }}
					</nb-card-header>
					<nb-card-body>
						<section class="equipos">
							<nb-card *ngFor="let team of teams">
								<nb-card-body *ngIf="team.usersInfo">
									<div *ngFor="let user of team.usersInfo" class="card-mini">
										<div class="avatar-mini" [ngStyle]="{ 'background-image': getUserAvatar(user.pictureUser) }"></div>
										<div class="card-body">
											<strong class="card-title">{{user.firstName}} {{user.surname}}</strong>
										</div>
									</div>
								</nb-card-body>
								<nb-card-footer>
									<h4>{{team.name}}</h4>
								</nb-card-footer>
							</nb-card>
						</section>
					</nb-card-body>
				</nb-card> -->
			</div>
		</div>
	</nb-card-body>
</nb-card>
