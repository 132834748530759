<div class="sticky-top">
    <div class="modal-header" align="center">
        <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'SETTINGS.STUDENTTITLE' | translate }}</span>

        <button type="button" class="close" data-dismiss="modal" (click)="closeModal('')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>

<div class='fondoEditarCurso' [ngClass]="usersService.getColorByRol()">

    <div class="col-12">
        <app-alert context="UserData"></app-alert>
    </div>

    <form [formGroup]="formularioDatosPadre" (ngSubmit)="grabarDatosPadre()" #thisFormPadres="ngForm" class="m-2 m-sm-5 p-4 card">

        <div class="row">
            <div class="col-sm-12" align="center">
                <span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'USERDATA.DATOSPADRES' | translate }}</span>
            </div>
        </div>

        <div class="row mt-4">

            <div class="col-md-4" align="center">

                <span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'USERDATA.PADRES' | translate }}</span>

                <div class="caja-blanca caja-blanca-small-height border border-dark">
                    <mat-list>
                        <mat-list-item *ngFor="let parent of parentsData" (click)="cargarDatosPadre(parent.idUserStudentParent)" [ngClass]="idParentSelected == parent.idUserStudentParent ? 'pestana-activa' : ''">
                            <i *ngIf="parent.validated" class="material-icons pr-2" title="{{ 'USERDATA.HAVALIDADOTUACCESO' | translate }}">check_circle_outline</i> {{ parent.firstName }} {{ parent.surname }}
                        </mat-list-item>
                    </mat-list>
                </div>

                <div class="caja-gris border border-dark">
                    <div class="row" style="margin: 0px;">
                        <div class="col-2 pointer boton-asignar">
                            <span (click)="cargarDatosPadre()" class="caja-gris-oscuro border border-dark material-icons ti-size-3" title="{{ 'USERDATA.REGISTRARPADRE' | translate }}">add</span>
                        </div>

                        <div class="col-2 pointer boton-asignar">
                            <span *ngIf="parentSelected && !parentValidated" (click)="eliminarPadre()" class="caja-gris-oscuro border border-dark material-icons ti-size-3" title="{{ 'USERDATA.REGISTRARPELIMINARADRE' | translate }}">remove</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-8 p-2">

                <div align="center">
                    <span *ngIf="parentsData.length === 0 && !verDetallePadre" class="textoFontSize09 colorTextoActivado font-weight-bold"> {{ 'USERDATA.NOHAYNINGUNPADREREGISTRADO' | translate }}</span>
                </div>

                <div *ngIf="parentsData.length > 0 || verDetallePadre">

                    <div class="row form-group">

                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">account_circle</i>
                        </div>

                        <div class="col-5">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.NOMBRE' | translate }}</mat-label>
                                <input matInput formControlName="firstName" required>
                                <mat-error *ngFor="let validation of validationMessages.firstName">
                                    <mat-error class="error-message" *ngIf="formularioDatosPadre.get('firstName')?.hasError(validation.type) && (formularioDatosPadre.get('firstName')?.dirty || formularioDatosPadre.get('firstName')?.touched || thisFormPadres.submitted)">{{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-5">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.APELLIDOS' | translate }}</mat-label>
                                <input matInput formControlName="surname" required>
                                <mat-error *ngFor="let validation of validationMessages.surname">
                                    <mat-error class="error-message" *ngIf="formularioDatosPadre.get('surname')?.hasError(validation.type) && (formularioDatosPadre.get('surname')?.dirty || formularioDatosPadre.get('surname')?.touched || thisFormPadres.submitted)">{{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">

                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">mail_outline</i>
                        </div>

                        <div class="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.EMAIL' | translate }}</mat-label>
                                <input matInput formControlName="mail">
                                <mat-error *ngFor="let validation of validationMessages.mail">
                                    <mat-error class="error-message" *ngIf="formularioDatosPadre.get('mail')?.hasError(validation.type) && (formularioDatosPadre.get('mail')?.dirty || formularioDatosPadre.get('mail')?.touched || thisFormPadres.submitted)">{{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">cake</i>
                        </div>

                        <div clasS="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.FECHANACIMIENTO' | translate }}</mat-label>

                                <input type="date" matInput formControlName="birthdate">

                                <mat-error *ngFor="let validation of validationMessages.birthdate">
                                    <mat-error class="error-message" *ngIf="formularioDatosPadre.get('birthdate')?.hasError(validation.type) && (formularioDatosPadre.get('birthdate')?.dirty || formularioDatosPadre.get('birthdate')?.touched || thisFormPadres.submitted)">{{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">

                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">phone</i>
                        </div>

                        <div class="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.TELEFONOMOVIL' | translate }}</mat-label>
                                <input matInput formControlName="mobile" required>

                                <mat-error *ngFor="let validation of validationMessages.mobile">
                                    <mat-error class="error-message" *ngIf="formularioDatosPadre.get('mobile')?.hasError(validation.type) && (formularioDatosPadre.get('mobile')?.dirty || formularioDatosPadre.get('mobile')?.touched || thisFormPadres.submitted)">{{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="parentSelected" class="row form-group mt-2">

                        <div class="col-2"></div>

                        <div class="col-10 mt-2">
                            <span class="textoFontSize09 colorTextoActivado">{{ mensajeEnvioValidacion }}</span>
                        </div>

                        <div class="col-2"></div>

                        <div class="col-10 mt-2">
                            <span class="textoFontSize09 colorTextoActivado">{{ mensajeEnvioValidado }}</span>
                        </div>
                    </div>

                    <div class="row form-group mt-2">

                        <div class="col-md-4 mt-2">
                            &nbsp;
                        </div>

                        <div class="col-md-4 mt-2">
                            <button *ngIf="parentSelected && !parentValidated" class="botonLoginRegistrar" type="button" (click)="reenviarAviso()">
                                <span>{{ 'USERDATA.REENVIARAVISO' | translate }}</span>
                            </button>
                        </div>

                        <div class="col-md-4 mt-2">
                            <button *ngIf="idParentSelected == null || !parentValidated" class="botonLoginEntrar" type="submit">
                                <span>{{ 'USERDATA.GRABAR' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <form [formGroup]="formularioDatosCentro" #thisFormCentros="ngForm" class="m-2 m-sm-5 p-4 card">

        <div class="row">
            <div class="col-sm-12" align="center">
                <span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'USERDATA.DATOSCENTROS' | translate }}</span>
            </div>
        </div>

        <div class="row mt-4">

            <div class="col-md-4" align="center">

                <span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'USERDATA.CENTROS' | translate }}</span>

                <div class="caja-blanca caja-blanca-height border border-dark">
                    <mat-list *ngIf="centersData.length > 0">
                        <mat-list-item *ngFor="let center of centersData" (click)="cargarDatosCentro(center.idCenter)" [ngClass]="idCenterSelected == center.idCenter ? 'pestana-activa' : ''">
                            {{ center.centerName }}
                        </mat-list-item>
                    </mat-list>
                </div>

                <div class="caja-gris border border-dark">
                    <div class="row" style="margin: 0px;">
                        <div class="col-2 pointer boton-asignar">
                            <span (click)="asignarCentro()" class="caja-gris-oscuro border border-dark material-icons ti-size-3" title="{{ 'USERDATA.ANADIRCENTRO' | translate }}">add</span>
                        </div>

                        <div class="col-2 pointer boton-asignar">
                            <span *ngIf="verDetalleCentro" (click)="desasignarCentro()" class="caja-gris-oscuro border border-dark material-icons ti-size-3" title="{{ 'USERDATA.DESASIGNARCENTRO' | translate }}">remove</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-md-4" align="center">

                <span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'USERDATA.CENTROS' | translate }}</span>

                <div class="caja-blanca caja-blanca-height borde-negro">
                    <mat-list>
                        <mat-list-item *ngFor="let center of centersData" (click)="cargarDatosCentro(center.idCenter)" [ngClass]="idCenterSelected == center.idCenter ? 'pestana-activa' : ''">
                            {{ center.centerName }}
                        </mat-list-item>
                    </mat-list>
                </div>

                <div class="row form-group">
                    <div class="col-sm-6" style="padding-left: 16px !important; padding-right: 1px !important;">
                        <span (click)="asignarCentro()" class="botonLoginRegistrar material-icons ti-size-2" title="{{ 'USERDATA.ANADIRCENTRO' | translate }}">add</span>
                    </div>

                    <div class="col-sm-6" style="padding-left: 1px !important; padding-right: 16px !important;">
                        <span *ngIf="verDetalleCentro" (click)="desasignarCentro()" class="botonLoginRegistrar material-icons ti-size-2" title="{{ 'USERDATA.DESASIGNARCENTRO' | translate }}">remove</span>
                    </div>
                </div>
            </div> -->

            <div class="col-md-8">

                <div align="center">
                    <span *ngIf="centersData.length === 0" class=" mt-4 textoFontSize09 colorTextoActivado font-weight-bold"> {{ 'USERDATA.NOTIENENINGUNCENTROASIGNADO' | translate }}</span>
                </div>

                <div *ngIf="verDetalleCentro">

                    <div class="row form-group my-3">

                        <div class="col-sm-4"></div>

                        <div class="col-sm-4" align="center">
                            <div class="card form-group">
                                <img *ngIf="centerData != undefined" [src]="centerData.centerPicture | imagen:'center'" class="card-img-top img-circle">
                            </div>
                        </div>

                        <div class="col-sm-4"></div>
                    </div>

                    <div class="row form-group mt-3">

                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">location_city</i>
                        </div>

                        <div class="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.NOMBRE' | translate }}</mat-label>
                                <input matInput formControlName="centerName" readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">map</i>
                        </div>

                        <div clasS="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.DIRECCION' | translate }}</mat-label>
                                <input matInput formControlName="centerAddress" readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">location_on</i>
                        </div>

                        <div clasS="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.PAIS' | translate }}</mat-label>
                                <input matInput formControlName="centerCountry" readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2"></i>
                        </div>

                        <div clasS="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.REGION' | translate }}</mat-label>
                                <input matInput formControlName="centerRegion" readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">

                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">mail_outline</i>
                        </div>

                        <div class="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.EMAIL' | translate }}</mat-label>
                                <input matInput formControlName="centerMail" readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">web</i>
                        </div>

                        <div clasS="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.URL' | translate }}</mat-label>
                                <input matInput formControlName="centerUrl" readonly>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <form [formGroup]="formularioDatosProfesores" #thisFormPadres="ngForm" class="m-2 m-sm-5 p-4 card">

        <div class="row">
            <div class="col-sm-12" align="center">
                <span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'USERDATA.DATOSPROFESORES' | translate }}</span>
            </div>
        </div>

        <div class="row mt-4">

            <div *ngIf="centersData.length == 0" class="col-12" align="center">
                <span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'USERDATA.NOTIENENINGUNCENTROASIGNADO' | translate }}</span>
            </div>

            <div *ngIf="centersData.length > 0" class="col-12">

                <form [formGroup]="formularioDatosProfesores" #thisFormPadres="ngForm">

                    <div class="row pt-4">

                        <div class="col-md-2"></div>

                        <div class="col-md-8 col-12">

                            <span class="textoFontSize09 colorTextoActivado">{{ 'USERDATA.SELECCIONEUNCENTRO' | translate }}</span>

                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.CENTROS' | translate }}</mat-label>
                                <mat-select (selectionChange)="seleccionarProfesoresCentro()" formControlName="idCenter" placeholder="{{ 'USERDATA.CENTROS' | translate }}">
                                    <mat-option *ngFor="let center of centersData" [value]="center.idCenter">
                                        [{{center.centerCountry}}]-{{center.centerName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-2"></div>
                    </div>

                    <div *ngIf="centerSelected" class="row pt-2">

                        <div class="col-md-2"></div>

                        <div class="col-md-8 col-12">

                            <span class="textoFontSize09 colorTextoActivado">{{ 'USERDATA.SELECCIONEPROFESOR' | translate }}</span>

                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.PROFESORES' | translate }}</mat-label>
                                <mat-select (selectionChange)="mostrarBotonAsignarProfesor($event)" formControlName="idTeacher" placeholder="{{ 'USERDATA.PROFESORES' | translate }}">
                                    <mat-option *ngFor="let teacher of teachersFilterData" [value]="teacher">
                                        {{teacher.firstName}} {{teacher.surname}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-2"></div>
                    </div>

                    <div *ngIf="centerTeacherSelected" class="row pt-2">

                        <div class="col-md-6"></div>

                        <div class="col-4" align="right">

                            <button (click)="asignarProfesor()" type="button" class="botonLoginRegistrar">{{ 'USERDATA.ASIGNARMEPROFESOR' | translate }}</button>
                        </div>

                        <div class="col-md-2"></div>
                    </div>

                    <div *ngIf="teachersData.length > 0" class="mt-5">

                        <div class="row">

                            <div class="col-md-2"></div>

                            <div class="col-md-8 col-12">

                                <span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'USERDATA.MISPROFESORES' | translate }}</span>
                            </div>

                            <div class="col-md-2"></div>
                        </div>

                        <div *ngFor="let teacher of teachersData" class="row mt-2">

                            <div class="col-md-2"></div>

                            <div class="col-md-6 col-8">
                                <span class="textoFontSize09 colorTextoActivado">{{ teacher.firstName }} {{ teacher.surname }}</span>
                            </div>

                            <div *ngIf="teacher.validationDate === null" class="col-md-4 col-4">
                                <span (click)="desasignarProfesor(teacher.idUserStudentTeacher)" class="textoFontSize09 colorTextoActivado pointer">{{ 'USERDATA.DESASIGNARMEPROFESOR' | translate }}</span>
                            </div>

                            <div *ngIf="teacher.validationDate !== null" class="col-md-4 col-4">
                                <span class="textoFontSize09 colorTextoActivado">{{ 'USERDATA.YAHASSIDOVALIDADO' | translate }}</span>
                            </div>

                            <div class="col-md-2"></div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </form>

    <!-- <app-loading [isVisible]="loading"></app-loading> -->
</div>
