<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="border-bottom-0">
		<div class="row">
			<div class="col-sm-12 col-md-6 modal-title">
				<h2>{{ "ASISTENCIA.HISTORIAL" | translate }} ({{convertDateToDDMMM(fechaInicio)}} - {{convertDateToDDMMM(fechaFin)}})</h2>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left"
			[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"></nb-icon>
	</div>
	<nb-card-body>
		<app-loading [isVisible]="cargando"></app-loading>

		<nb-card class="mb-0 no-edge-card">
			<nb-card-body>
				<div>
					<!--
						<div class="container mt-6 p-2">
							<label for="startDate">Fecha de inicio:</label>
							<input type="date" id="startDate" [(ngModel)]="fechaInicio">

							<label for="endDate">Fecha de fin:</label>
							<input type="date" id="endDate" [(ngModel)]="fechaFin">
						</div>
						-->
					<div class="d-flex gap-2 mb-3 justify-content-center">
						<button nbButton size="medium" outline shape="semi-round" status="info" (click)="semanaAnterior()">
								<nb-icon class="m-1" icon="arrow-back-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon>
								{{ 'ASISTENCIA.VERANTERIOR' | translate }}
						</button>
						<button nbButton size="medium" outline shape="semi-round" status="info" (click)="semanaSiguiente()"
										[disabled]="currentWeek == 0">
								{{ 'ASISTENCIA.VERSIGUIENTE' | translate }}
								<nb-icon class="m-1" icon="arrow-forward-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon>
						</button>
					</div>
					<div class="table-asistencia">
						<ng-container *ngIf="verTabla">
							<table id="myTable" class="table table-striped">
								<thead>
									<tr *ngFor="let cab of cabecera">
										<th>{{ "FORM.NAMEPH" | translate }}</th>
										<ng-container *ngFor="let dato of cab; let i = index">
											<th *ngIf="i > 0">{{ dato }}</th>
										</ng-container>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let asistencia of historialAsistencia">
										<td>
											<nb-icon class="m-1 pointer" icon="eye-outline"
												ngbTooltip="{{ 'CURSOMOTIVACIONES.DETALLES' | translate }}" placement="bottom"
												[options]="{ animation: { type: 'zoom' } }"
												(click)="estadisticasEstudiante(asistencia[0], asistencia[1])"></nb-icon>
											<p class="m-0 style-text-name">{{ asistencia[1] }}</p>
										</td>
										<ng-container *ngFor="let dato of asistencia; let i = index">
											<td *ngIf="i > 1"
												[ngClass]="{'bg-success': dato[0].status == '3', 'bg-warning': dato[0].status == '4', 'bg-danger': dato[0].status == '2', 'danger2': dato[0].status == '1', 'bg-info': dato[0].status == '5'}"
												(click)="asistenciaEstudiante(asistencia[0], asistencia[1], dato[0].status, dato[0].fecha, dato[0].motivo, dato)">
												<p class="m-0 style-text-asistencia">{{ dato[0].texto }}</p>
												<p class="m-0 style-text-asistencia" *ngIf="dato.length > 1">({{dato.length}})</p>
											</td>
										</ng-container>
									</tr>
								</tbody>
							</table>
						</ng-container>
					</div>
				</div>
			</nb-card-body>
		</nb-card>
	</nb-card-body>
</nb-card>
