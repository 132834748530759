<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="flex border-bottom-0">
		<div class="col-10">
			<h4>{{group.title}}</h4>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body class="p-0">
		<app-loading [isVisible]="cargando"></app-loading>

		<nb-card class="no-edge-card background_themecolor p-4">
			<form [formGroup]="formGroup" #thisForm="ngForm" novalidate class="form-horizontal">

				<div class="row mt-4">
					<div class="col-12">
						<app-alert context="ModalEditarCurso"></app-alert>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-12">
						<app-imgupload formControlName="image" [isShowDelete]="false"></app-imgupload>
					</div>

					<div class="col-12 col-sm-8 col-md-6 mt-4">
						<label for="title">Titulo del grupo</label>
						<input type="text" disabled nbInput formControlName="title" name="title" fullWidth readonly>

						<label for="description" class="mt-4">Descripcion del grupo</label>
						<textarea disabled nbInput fullWidth formControlName="description" name="description" rows="3" readonly></textarea>

						<div class="row mb-2 mt-2">
							<div id="lang" formGroupName="langGroup" class="col-md-8 col-sm-12">
								<div class="row">
									<div class="col-6" *ngIf="false">
										<!-- PONERMOS EL NGIF EN FALSE PARA OCULTAR EL CAMPO PERO QUEDA PREDETERMINADO EN ESPAÑOL (MAYO 2023)-->
										<nb-select fullWidth placeholder="{{ 'EDITARCURSO.SELECCIONIDIOMA1' | translate }}"
											formControlName="lang1">
											<nb-option *ngFor="let idioma of idiomas | sortBy:'asc':'language'"
												[value]="idioma.idLanguage">{{ idioma.language }}</nb-option>
										</nb-select>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</form>
		</nb-card>

		<nb-card-footer class="border-top-0">
			<div class="row d-flex justify-content-center">
				<div class="col-12 col-sm-10 col-md-8 col-lg-6">
					<div class="cards col-12 col-md-6">
						<div class="card-item" (click)="listadoCursos()">
							<div class="card-image">
								<div class="icon-option icon-proyecto-muchos-grafos pointer"></div>
							</div>
							<div class="card-info">
								<h6 class="card-title">{{ 'MENUACTIONS.STUDENTTITLE2b' | translate }}</h6>
								<p class="paragraph">{{ 'MENUACTIONS.STUDENTSUBTITLE2' | translate }}</p>
							</div>
						</div>
					</div>

					<div class="cards col-12 col-md-6">
						<div class="card-item" (click)="instantActivity()">
							<div class="card-image">
								<div class="icon-option icon-desafios-icon pointer"></div>
							</div>
							<div class="card-info">
								<h6 class="card-title">{{ 'MENUACTIONS.TEACHERTITLE7b' | translate }}</h6>
								<p class="paragraph">{{ 'MENUACTIONS.TEACHERSUBTITLE7' | translate }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nb-card-footer>
	</nb-card-body>
</nb-card>
