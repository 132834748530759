<nb-card class="no-edge-card mb-0 modal-height-100-62">
	<nb-card-header class="border-bottom-0 background_themecolor">
		<div class="container">
			<div class="px-2 px-sm-5 py-2">
				<div class="d-flex justify-content-between gap-4 container-item-small">
					<!-- Inteligencia artificial -->
					<div class="item-style item-small" (click)="showChatAssistant()">
						<h6>{{ "CENTROS.ARTIFICIALINTELLIGENCE" | translate }}</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/inteligencia artificial.png" width="70" alt='{{ "CENTROS.ARTIFICIALINTELLIGENCE" | translate }}'>
						</div>
					</div>
					<!-- Estructura -->
					<div class="item-style item-small" (click)="openModalStructure()">
						<h6>{{ "SIDEBAR.ESTRUCTURA" | translate }}</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/centros.png" width="100" alt='{{ "SIDEBAR.ESTRUCTURA" | translate }}'>
						</div>
					</div>
					<!-- Abandono -->
					<div class="item-style item-small" (click)="openStep2('failure')">
						<h6>{{ "CENTROS.ABANDONMENTSUCCESS" | translate }}</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/abandono-exito.png" width="70" alt='{{ "CENTROS.ABANDONMENTSUCCESS" | translate }}'>
						</div>
					</div>
					<!-- Calendario -->
					<div class="item-style item-small " (click)="openSchedules()" [ngClass]="assignedCenter == null ? 'item-disabled' : ''">
						<h6>{{ "SIDEBAR.CONTROLES" | translate }}</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/calendario.png" width="70" alt='{{ "SIDEBAR.CONTROLES" | translate }}'>
						</div>
					</div>
					<!-- Explorar -->
					<div class="item-style item-small" (click)="openExplore()">
						<h6>{{ "MENUACTIONS.EXPLORE" | translate }}</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/magnifying-glass.png" width="70" alt='{{ "MENUACTIONS.TEACHERSUBTITLE6" | translate }}'>
						</div>
					</div>
					<!-- Configuracion -->
					<div class="item-style item-small" (click)="openConfiguration()">
						<h6>{{ "SIDEBAR.SETTINGS" | translate }}</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/gear.png" width="70" alt='{{ "USERDATA.CONFIGURACION" | translate }}'>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-body class="px-0 px-sm-2">
		<div class="container">
			<div class="px-2 px-sm-5 h-325">
				<!-- ===============Fila superior================== -->
				<div class="d-flex justify-content-between flex-wrap gap-4 mt-2 h-30">
					<!-- ***Profesores*** -->
					<div class="item-style item-medium flex-column gap-0" (click)="openStep2('teacher')">
						<h6>{{ "TASK.PROFESORES" | translate }}</h6>
						<div class="d-flex gap-3 h-100">
							<!-- imagen -->
							<div class="d-flex" style="flex: 0 0 33%; max-width: 33%;">
								<div class="item-image">
									<img src="../../../../assets/images/control-panel/profesor.png" width="70" alt='{{ "TASK.PROFESORES" | translate }}'>
								</div>
							</div>
							<!-- grafico -->
							<div class="item-graphic" *ngIf="cargarCharts">
								<app-barras-profesor [parametroID]="1" [consultaID]="16" [datasetsImported]="datasetsImported[1]" [labelsImported]="labelsImported"></app-barras-profesor>
								<div class="layer"></div>
							</div>
						</div>
					</div>
					<!-- ***Grupos*** -->
					<div class="item-style item-medium" (click)="openStep2('groups')">
						<!-- texto e imagen -->
						<div class="d-flex flex-column" style="flex: 0 0 33%; max-width: 33%;">
							<h6>{{ "TASK.GROUPS" | translate }}</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/grupos.png" width="70" alt='{{ "TASK.GROUPS" | translate }}'>
							</div>
						</div>
						<!-- grafico -->
						<div class="item-graphic" *ngIf="cargarCharts">
							<app-torta-grupos [parametroID]="1" [consultaID]="17" [datasetsImported]="datasetsImported[2]" [labelsImported]="labelsImported"></app-torta-grupos>
							<div class="layer"></div>
						</div>
					</div>
					<!-- ***Alumnos*** -->
					<div class="item-style item-medium" (click)="openStep2('student')">
						<!-- grafico -->
						<div class="item-graphic" *ngIf="cargarCharts">
							<app-lista-alumnos [parametroID]="1" [consultaID]="18" [datasetsImported]="datasetsImported[3]" [labelsImported]="labelsImported"></app-lista-alumnos>
							<div class="layer"></div>
						</div>
						<!-- texto e imagen -->
						<div class="d-flex flex-column" style="flex: 0 0 33%; max-width: 33%;">
							<h6 class="text-right">{{ "GRUPOS.ALUMNOS" | translate }}</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/alumnos.png" width="70" alt='{{ "GRUPOS.ALUMNOS" | translate }}'>
							</div>
						</div>
					</div>
				</div>
				<!-- ===============Fila intermedia================== -->
				<div class="d-flex justify-content-between flex-wrap item-style-large gap-4 mt-4 h-30">
					<!-- ***ASIGNATURAS*** -->
					<div class="item-large flex-column gap-0" (click)="openStep2('subjects')">
						<h6>{{ "CURSOS.SUBJECTS" | translate }}</h6>
						<div class="d-flex gap-3 h-100">
							<!-- imagen -->
							<div class="d-flex" style="flex: 0 0 33%; max-width: 33%;">
								<div class="item-image">
									<img src="../../../../assets/images/control-panel/tasks.png" width="100" alt='{{ "GRUPOS.TAREAS" | translate }}'>
								</div>
							</div>
							<!-- grafico -->
							<div class="item-graphic" *ngIf="cargarCharts">
								<app-dispersion-asignaturas [parametroID]="1" [parametroID2]="2" [consultaID]="15" [datasetsImported]="datasetsImported[0]" [labelsImported]="labelsImported"></app-dispersion-asignaturas>
								<div class="layer"></div>
							</div>
						</div>
					</div>
					<!-- ***Grafica torta*** -->
					<div class="item-chart">
						<div class="text-absolute">
							<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;" [innerHTML]="texto1Centro.title"
							[style.background]="texto1Centro.backgroundColor" [style.color]="texto1Centro.color">
							</nb-tag>
							<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;" [innerHTML]="texto2Centro.title"
							[style.background]="texto2Centro.backgroundColor" [style.color]="texto2Centro.color">
							</nb-tag>
						</div>
						<div class="pie_chart" *ngIf="cargarCharts">
							<app-solar-centro [parametroID]="1" [consultaID]="14" [datasetsImported]="datasetsImported[0]" [labelsImported]="labelsImported"></app-solar-centro>
						</div>
					</div>
					<!-- ***Indicadores sociales*** -->
					<div class="item-large" (click)="openStep2('social')">
						<!-- grafico -->
						<!-- este comentario y la clase temporal se deben quitar cuando se sustituya la imagen por el grafico -->
						<div class="item-graphic clase-temporal">
							<img src="../../../../assets/images/control-panel/grafica_2.png">
							<div class="layer"></div>
						</div>
						<!-- texto e imagen -->
						<div class="d-flex flex-column" style="flex: 0 0 33%; max-width: 33%;">
							<h6 class="text-right">{{ "CENTROS.SOCIALINDICATORS" | translate }}</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/indicadores sociales.png" width="70" alt='{{ "CENTROS.SOCIALINDICATORS" | translate }}'>
							</div>
						</div>
					</div>
				</div>
				<!-- ===============Fila inferior================== -->
				<div class="d-flex justify-content-between flex-wrap gap-4 mt-4 h-30">
					<!-- ***Niveles*** -->
					<div class="item-style item-medium" (click)="openStep2('levels')">
						<!-- texto e imagen -->
						<div class="d-flex flex-column" style="flex: 0 0 33%; max-width: 33%;">
							<h6>{{ "EDITARCURSO.NIVELES" | translate }}</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/Niveles.png" width="70" alt='{{ "EDITARCURSO.NIVELES" | translate }}'>
							</div>
						</div>
						<!-- grafico -->
						<div class="item-graphic" *ngIf="cargarCharts">
							<app-radial-niveles [parametroID]="1" [consultaID]="14" [datasetsImported]="datasetsImported[0]" [labelsImported]="labelsImported"></app-radial-niveles>
							<div class="layer"></div>
						</div>
					</div>
					<!-- ***Cursos*** -->
					<div class="item-style item-medium" (click)="openProjects()">
						<!-- texto e imagen -->
						<div class="d-flex flex-column" style="flex: 0 0 33%; max-width: 33%;">
							<h6>{{ "EDITARCURSO.COURSES" | translate }}</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/Proyectos.png" width="70" alt='{{ "EDITARCURSO.COURSES" | translate }}'>
							</div>
						</div>
						<!-- grafico -->
						<!-- este comentario y la clase temporal se deben quitar cuando se sustituya la imagen por el grafico -->
						<div class="item-graphic clase-temporal">
							<img src="../../../../assets/images/control-panel/grafica_3.png">
							<div class="layer"></div>
						</div>
					</div>
					<!-- ***Padres*** -->
					<div class="item-style item-medium flex-column gap-0" (click)="openStep2('parents')">
						<h6 class="text-right">{{ "USERDATA.PADRES" | translate }}</h6>
						<div class="d-flex gap-3 h-100">
							<!-- grafico -->
							<div class="item-graphic" *ngIf="cargarCharts">
								<app-barras-padres [parametroID]="1" [consultaID]="18" [datasetsImported]="datasetsImported[3]" [labelsImported]="labelsImported"></app-barras-padres>
								<div class="layer"></div>
							</div>
							<!-- imagen -->
							<div class="d-flex" style="flex: 0 0 33%; max-width: 33%;">
								<div class="item-image">
									<img src="../../../../assets/images/control-panel/Padres.png" width="70" alt='{{ "USERDATA.PADRES" | translate }}'>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>
