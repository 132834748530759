<nb-card class="mb-0 no-edge-card">
	<nb-card-header class="border-bottom-0">
		<div class="d-flex pr-5">
			<h6>{{ "MODALGROUPSEDIT.SEARCHAGROUPTOJOIN" | translate }}</h6>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="d-flex">
			<form [formGroup]="formulario" #thisForm="ngForm" class="w-100">
				<input type="text" nbInput fullWidth placeholder="{{ 'MODALGROUPSEDIT.SEARCHGROUPS' | translate }}"
					name="fiilter" formControlName="filtrado" class="form-control" autocomplete="off" (keyup)="filtrarEstudiantes()" />
			</form>
		</div>
	</nb-card-body>
	<nb-card-footer class="border-top-0">
		<app-loading [isVisible]="cargando"></app-loading>
		<nb-list class="w-100" *ngIf="gruposListBuscar.length > 0">
			<nb-list-item *ngFor="let grupo of gruposListBuscar | sortBy : 'asc' : 'firstName'"
				class="d-flex gap-3 flex-wrap align-items-center">
				{{ grupo.title }}&nbsp;
				<button nbButton size="small" *ngIf="!cargando" class="btn-themecolor" (click)="solicitarUnirGrupo(grupo)">
					{{ 'MODALGROUPSEDIT.REQUESTJOIN' | translate }}
				</button>
			</nb-list-item>
		</nb-list>
	</nb-card-footer>
</nb-card>
