<h2 mat-dialog-title *ngIf="data.title && data.message !== 'FROM_REGISTER' && data.message !== 'FROM_REGISTER_MAIL'">{{data.title}}</h2>
<mat-dialog-content class="mat-typography" *ngIf="data.message !== 'FROM_REGISTER' && data.message !== 'FROM_REGISTER_MAIL'">
    {{data.message}}
</mat-dialog-content>
<mat-dialog-content align="end" *ngIf="data.messageArray !== []">
	<div class="col-12">
    <ul class="list-unstyled">
        <li *ngFor="let item of data.messageArray" class="paragraph">{{ item }}</li>
    </ul>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="data.message !== 'FROM_REGISTER' && data.message !== 'FROM_REGISTER_MAIL'">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial status="primary">{{ "GLOBALS.OK" | translate }}</button>
</mat-dialog-actions>

<h2 mat-dialog-title *ngIf="data.title && data.message === 'FROM_REGISTER'">{{ "RECUPERARCONTRASENA.OKTITLE" | translate }}</h2>
<mat-dialog-content class="mat-typography" *ngIf="data.message === 'FROM_REGISTER'">
	<h6>{{ "REGISTRO.PHONEUSED" | translate }}</h6>
	<p>{{ "REGISTRO.PHONEEXIST" | translate }} <b>{{data.title}}</b></p>
	<p>{{ "REGISTRO.YOUROPTIONS" | translate }}</p>
	<ul>
		<li>
			<p>{{ "REGISTRO.YOUCANLOGINPHONE" | translate }} <a href="javascript:void(0)" (click)="handleLoginClick()">{{ "GLOBALS.HERE" | translate }}</a></p>
		</li>
		<li>
			<p>{{ "REGISTRO.YOUCANRECOVERYPASSPHONE" | translate }} <a href="javascript:void(0)" (click)="handleRecoveryClick()">{{ "GLOBALS.HERE" | translate }}</a></p>
		</li>
		<li>
			<p>{{ "REGISTRO.YOUCANCHANGEPHONE" | translate }} <a href="javascript:void(0)" (click)="handleChangeClick()">{{ "GLOBALS.HERE" | translate }}</a></p>
		</li>
	</ul>
</mat-dialog-content>

<h2 mat-dialog-title *ngIf="data.title && data.message === 'FROM_REGISTER_MAIL'">{{ "RECUPERARCONTRASENA.OKTITLE" | translate }}</h2>
<mat-dialog-content class="mat-typography" *ngIf="data.message === 'FROM_REGISTER_MAIL'">
	<h6>{{ "REGISTRO.MAILUSED" | translate }}</h6>
	<p>{{ "REGISTRO.MAILEXIST" | translate }} <b>{{data.title}}</b></p>
	<p>{{ "REGISTRO.YOUROPTIONS" | translate }}</p>
	<ul>
		<li>
			<p>{{ "REGISTRO.YOUCANLOGINMAIL" | translate }} <a href="javascript:void(0)" (click)="handleLoginClick()">{{ "GLOBALS.HERE" | translate }}</a></p>
		</li>
		<li>
			<p>{{ "REGISTRO.YOUCANRECOVERYPASSMAIL" | translate }} <a href="javascript:void(0)" (click)="handleRecoveryClick()">{{ "GLOBALS.HERE" | translate }}</a></p>
		</li>
		<li>
			<p>{{ "REGISTRO.YOUCANCHANGEMAIL" | translate }} <a href="javascript:void(0)" (click)="handleChangeClick()">{{ "GLOBALS.HERE" | translate }}</a></p>
		</li>
	</ul>
</mat-dialog-content>
