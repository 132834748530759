import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModelSubQuizFile } from 'src/app/core/models/quizzes';
import { UtilsService } from 'src/app/core/services/shared/utils.service';

@Component({
  selector: 'app-sub-quizzes',
  templateUrl: './sub-quizzes.component.html',
  styleUrls: ['./sub-quizzes.component.scss']
})
export class SubQuizzesComponent implements OnInit {


  @Output() saveData: EventEmitter<any> = new EventEmitter<any>();
  @Input() elements: any = [];
  @Input() subQuizzes;
  public infoForm: UntypedFormGroup;
 
  public SubQuizModel: ModelSubQuizFile;

  constructor(private fb: UntypedFormBuilder, private utilsService: UtilsService
    
    ) {
    this.infoForm = this.fb.group({
        title: ['', Validators.required],
        duration: '',
        instructions: ''
      });
   }

  ngOnInit() {
    this.SubQuizModel = new ModelSubQuizFile();
    this.SubQuizModel.idQuiz = this.subQuizzes.idQuiz;
    this.SubQuizModel.duration =  this.subQuizzes.duration;
    this.SubQuizModel.creationDate = this.subQuizzes.creationDate;
    this.SubQuizModel.lastEditionDate = this.subQuizzes.lastEditionDate;
    this.SubQuizModel.quizInstructions = this.subQuizzes.quizInstructions;
    this.SubQuizModel.quizTitle = this.subQuizzes.quizTitle;
    this.SubQuizModel.idQuizMultiple = this.subQuizzes.idQuizMultiple;

      this.infoForm.get('title').patchValue(this.subQuizzes.quizTitle);
      this.infoForm.get('instructions').patchValue(this.subQuizzes.quizInstructions);
      this.infoForm.get('duration').patchValue(this.utilsService.minutesToTime(this.subQuizzes.duration));
  }


  updateData(subQuizzes: ModelSubQuizFile){
    this.SubQuizModel.duration =  subQuizzes.duration;
    this.SubQuizModel.quizInstructions =subQuizzes.quizInstructions;
    this.SubQuizModel.quizTitle = subQuizzes.quizTitle;
    this.infoForm.get('title').patchValue(subQuizzes.quizTitle);
    this.infoForm.get('instructions').patchValue(subQuizzes.quizInstructions);
    this.infoForm.get('duration').patchValue(this.utilsService.minutesToTime(subQuizzes.duration));
  }
  
  public onTextBlur(event: any) {
    if (event.target.value && this.infoForm.valid) {
        const dataSave = this.infoForm.value;

        this.SubQuizModel.duration = this.utilsService.timeToMinutes( dataSave.duration);
        this.SubQuizModel.quizInstructions =  dataSave.instructions;
        this.SubQuizModel.quizTitle =  dataSave.title;


        this.saveData.emit(this.SubQuizModel);
    }
}

}
