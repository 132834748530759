export class NodeElementsHistorialModel {
	idNodesElementsHistorial: number;
	idCourse: number;
	idTarget: number;
	idNode: number;
	idUser: number;
	fecha: Date;
	tiempoTotalNode: number;
	element: string;
	tiempoActualElement: number;
	tiempoTotalElement: number;
	totalVisitas: number;
	elementNew: string;
}
