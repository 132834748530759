import { NumberValueAccessor } from '@angular/forms';
import { SigmaNode } from './../graph/sigmaNode.model';
export class QuizModel {
    id: string;
    idOriginal: number;
    idQPattern: number;
    quizType: string;
    idAuthor: number;
    quizTittle: string;
    duration: number;
    ordinal: number;
    quizSwlevel: number;
    size: number;
    type: string;
    x: number;
    y: number;
    user: any;
    creationDate: number;
    writable: number;
    compuCorrect: number;
    multiplexed: number;
    lastEditionDate: number;
    quizInstructions: string;
    idTarget: number;
    idCourse: number;
    certifiedQuiz: number;
    template:any;
    published: number;
    idTemplate: number;
    sizeQuiz: string;
    result?: any;
    knowledge?: any;
    nextQuestion?: any;
    nodeType: any;
    isMultiplexed: number;
    idMultiplexQuiz?: any;
    idQuizFather?: any;
    answersText?: string;
    selectedOptions?: string;
    defaultQuizPattern: number;
		promptText: string;
		text1: string;
		text2: string;
}

export class ModelQuizFile{
    idQuizMultFile: number;
    audio1: string;
    audio2: string;
    doc1: string;
    doc2: string;
    fecMod: number;
    idQuiz: number;
    imagen1: string;
    imagen2: string;
    text1: string;
    text2: string;
    video1: string;
    video2: string;
}
export class ModelSubQuizFile {
    creationDate: any;
    duration: any;
    idQuiz: any;
    lastEditionDate: any;
    quizInstructions: any;
    quizTitle: any;
    idQuizMultiple:any
    /**
     *
     */
    constructor() {
        // this.modelSubQuizFile(Number(idQuiz));
    }

}

export class ModelQuizElement {
    data: string;
    elementType: number;
    idQuizMultiple: number;
    idQuizzesDataElements: number;
    responseCheck:number;
    xposition: number;
    xsize: number;
    yposition:number;
    ysize: number;
}


export enum TypleColorElement{
    GRANATE = 1,
    AZUL,
}

export interface NodeOnTopResponse{
    value: boolean,
    node?: SigmaNode | null
}

