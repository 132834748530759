import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuizTemplateElement } from 'src/app/core/models/quizzes/quiz-template-element.model';
import { TemplateElementType } from 'src/app/core/models/quizzes/template-element-type.model';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { TEMPLATE_ELEMENTS_TYPES } from 'src/app/core/utils/template-elements-types';
import { ModalReproducirComponent } from 'src/app/shared/components/modal-reproducir/modal-reproducir.component';

@Component({
    selector: 'app-recordar-quiz-play-element',
    templateUrl: './quiz-play-element.component.html',
    styleUrls: ['./quiz-play-element.component.scss']
})
export class RecordarQuizPlayElementComponent implements OnInit {
    @Input() element: QuizTemplateElement;
    @Input() editable: boolean = false;
    @Input() checked: boolean = false;
    @Input() compuCorrect: boolean = false;

    @Output() quizPlayElementChecked: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() quizPlayElementFileUploaded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() quizPlayElementSetDescription: EventEmitter<QuizTemplateElement> = new EventEmitter<QuizTemplateElement>();

    type: TemplateElementType;

    constructor(private modalService: NgbModal) { }

    ngOnInit() {
        this.type = TEMPLATE_ELEMENTS_TYPES[this.element.elementsType.idElementType];
    }

    optionChecked(event: any) {
        const value: boolean = event.checked;
        this.quizPlayElementChecked.next(value);
    }

    loadFile(fileEvent) {
        this.quizPlayElementFileUploaded.next(fileEvent);
    }

    elementClicked() {
        if (this.type.type !== 'text' && this.element.data) {
            const modalRef = this.modalService.open(ModalReproducirComponent,
                {
                    scrollable: true,
                    windowClass: MODAL_DIALOG_TYPES.W70
                })
            modalRef.componentInstance.element = this.element;
            modalRef.componentInstance.fileFrom = 'quizElement';
            modalRef.result.then((result) => {
            }, (reason) => {
            });
        }
    }

    setDescription() {
        this.quizPlayElementSetDescription.next(this.element);
    }
}
