import { style } from '@angular/animations';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SigmaNode } from 'src/app/core/models/graph/sigmaNode.model';
import { LoginService } from 'src/app/core/services/login';
import { StickersService } from 'src/app/core/services/stickers/stickers.service';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { SIGMA_CONSTANTS } from 'src/app/core/utils/sigma-constants';
import { ModalAceptarCancelarComponent } from 'src/app/shared/components/modal';
import { SigmaCanvasService } from '../sigma-canvas/sigma-canvas.service';
import html2canvas from 'html2canvas';
import { NbToastrService, NbComponentStatus } from '@nebular/theme';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';
import { url } from 'inspector';
declare var $: any;
const MINRANGE = 1
const MAXRANGE = 500

@Component({
	selector: "app-modal-add-text",
	templateUrl: "./modal-add-text.component.html",
	styleUrls: ["./modal-add-text.component.scss"],
})
export class ModalAddTextComponent implements OnInit {
	public textForm: UntypedFormGroup;
	private _file: any;
	public tempNode: any; // Temp node when create a new node
	public node: any; // Current node to edit
	public idCourse: number;
	public idGraph: number;
	private _validImageFormats: string[] = ["image/png", "image/jpeg"];

	fontOptions: { key: string; value: string }[] = [
		{ key: "Comic Sans", value: "comic sans ms" },
		{ key: "Arial", value: "Arial, Helvetica, sans-serif" },
		{ key: "TimesNewRoman", value: "Times New Roman, Times, serif" },
		{ key: "Georgia", value: "Georgia, serif" },
		{ key: "Garamond", value: "Garamond, serif" },
		{ key: "Courier New", value: "Courier New" },
		{ key: "Verdana", value: "Verdana, sans-serif" },
		{ key: "Brush Script MT", value: "Brush Script MT, cursive" },
		{ key: "Copperplate", value: "Copperplate, Papyrus, fantasy" },
	];

	validationMessages = { file: [], size: [], link: [] };
	saving: boolean = false;
	progress: number = 0;
	private _imageFile: File;
	generatingImage: boolean = false;
	disableCancel: boolean = false;

	constructor(
		private activeModal: NgbActiveModal,
		private translateService: TranslateService,
		private fb: UntypedFormBuilder,
		private stickerServices: StickersService,
		private loginService: LoginService,
		private modalService: NgbModal,
		private sigmaCanvasService: SigmaCanvasService,
		private toaster: ToasterService,
		private toastrService: NbToastrService,
		private toolsService: SigmaToolbarsService
	) {}

	@ViewChild("textContainer") textContainer: ElementRef;

	text: string = "";
	color: string = "#000000";
	fontSize: number = 16;
	generatedImage: string | null = null;

	generateImage() {
		this.generatingImage = true;
		const text = this.textForm.get("text").value;
		const color = this.textForm.get("color").value;
		const fontSize = this.textForm.get("fontSize").value;
		const font = this.textForm.get("font").value;

		const textDiv = this.textContainer.nativeElement;
		textDiv.style.color = color;
		textDiv.style.fontSize = fontSize + "px";
		textDiv.style.fontFamily = font;
		textDiv.style.height = fontSize + 20 + "px";
		textDiv.style.width = "fit-content";
		textDiv.style.padding = "10px";

		setTimeout(() => {
			html2canvas(textDiv, { scale: 3, backgroundColor: null }).then(
				(canvas) => {
					this.generatedImage = canvas.toDataURL("image/png");
					this._file = this.dataURLtoBlob(this.generatedImage);
					this.generatingImage = false;
				}
			);
		}, 100);
		//Si generamos el texto creamos la variable local con los datos por si quiero hacer otro con las mismas caracteristicas
		const textoGrafos = {
			text: this.textForm.get('text').value,
			color: this.textForm.get('color').value,
			font: this.textForm.get('font').value,
			fontSize: this.textForm.get('fontSize').value,
			url: this.textForm.get('url').value
		}
		localStorage.removeItem('textoGrafos');
		localStorage.setItem('textoGrafos', JSON.stringify(textoGrafos));
	}

	dataURLtoBlob(dataURL: string): Blob {
		const byteString = atob(dataURL.split(",")[1]);
		const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: mimeString });
	}

	ngOnInit() {
		if (this.node) {
			this.createEditionForm();
		} else {
			this.createForm();
			// se comprueba si existe la variable local "textoGrafos"
			const textoGrafos = JSON.parse(localStorage.getItem('textoGrafos'));
			if (textoGrafos) {
				// Rellena el formulario con los valores de "textoGrafos"
				this.textForm.get('text').setValue(textoGrafos.text);
				this.textForm.get('color').setValue(textoGrafos.color);
				this.textForm.get('font').setValue(textoGrafos.font);
				this.textForm.get('fontSize').setValue(textoGrafos.fontSize);
				this.textForm.get('url').setValue(textoGrafos.url);
			}
		}
	}

	createForm(): void {
		this.textForm = this.fb.group({
			text: ["", Validators.required],
			color: ["#000000"],
			font: ["Arial, Helvetica, sans-serif"],
			fontSize: [16],
			url: [""],
		});
	}

	createEditionForm(): void {
		let values = JSON.parse(this.node.jsonData);
		this.textForm = this.fb.group({
			text: [values.text],
			color: [values.color],
			font: [values.font],
			fontSize: [values.fontSize],
			url: [values.url? values.url : ''],
		});
	}

	changeFont(font) {
		this.textForm.get("font").setValue(font);
	}

	closeModal(sendData?: any): void {
		this.activeModal.close(sendData);
	}

	saveSticker(): void {
		if (this.textForm.valid) {
			this.saving = true;

			let value = {};

			let data = {
				nodeType: SIGMA_CONSTANTS.TEXT_TYPE,
				sizeImg: this.textForm.get("fontSize").value,
				type: SIGMA_CONSTANTS.STICKER_NODETYPE,
				xposition: this.tempNode ? this.tempNode.x : this.node.x,
				yposition: this.tempNode ? this.tempNode.y : this.node.y,
				link: this.textForm.get("url").value,
				jsonData: JSON.stringify(this.textForm.getRawValue()),
			};

			if (this.node)
				value = {
					...data,
					idImageTarget: this.node.idImageTarget,
					high: this.node.high,
					width: this.node.width,
					nameImg: this.node.nameImg,
				};
			else value = { ...data };

			this.stickerServices
				.createSticker(this.idCourse, this.idGraph, value, this._file)
				.subscribe(
					(event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.UploadProgress:
								this.progress = Math.round((event.loaded / event.total) * 100);
								break;
							case HttpEventType.Response:
								setTimeout(() => {
									this.progress = 0;
									this.saving = false;
									this.closeModal(event.body.data);
									if (this.node) {
										this.toolsService.changeUpdateGraph(true)
										//window.location.reload();
									}
								}, 500);
						}
					},
					(err) => {
						this.saving = false;
						console.error("Error saving the sticker: ", err);
					}
				);
		}
	}

	deleteSticker(): void {
		if (!this.loginService.esAutor()) {
			return;
		}

		// Abro un modal preguntando si desea borrar el curso o no
		const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});

		modalRef.componentInstance.mensaje = this.translateService.instant(
			"STICKERS.DELETESTICKERMSG"
		);

		modalRef.result.then((result: boolean) => {
			if (result) {
				let sticker: SigmaNode = this.node as SigmaNode;

				this.sigmaCanvasService.deleteSigmaNode(sticker).subscribe(
					(res: any) => {
						this.toastrService.show(
							this.translateService.instant("STICKERS.DELETEOK"),
							`Exito`,
							{ status: "success" }
						);
						this.toaster.success(
							this.translateService.instant("STICKERS.DELETEOK")
						);
						this.activeModal.close(false);
					},
					(err) => {
						this.toaster.error(
							this.translateService.instant("STICKERS.DELETEKO")
						);
						this.activeModal.close(false);
					}
				);
			}
		});
	}
}
