<div class="sticky-top sticky-top-header">
  <div class="modal-header" align="center">
      <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{'MODALRADAR.TITLE' | translate}}</span>
      <button type="button" class="close" data-dismiss="modal" (click)="closeModal('')">
      <span aria-hidden="true">&times;</span>
  </button>
  </div>
</div>
<div>
  <p class="text-center my-2">{{'MODALRADAR.DEMO' | translate}}</p>
  <img [src]="images[0]" class="img-fluid"/>
</div>

