import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface AcceptDialogData {
    title?: string,
    message: string,
    okButton?: boolean,
    koButton?: boolean
}

@Component({
    selector: 'app-accept-dialog',
    templateUrl: './accept-dialog.component.html',
    styleUrls: ['./accept-dialog.component.scss']
})
export class AcceptDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<AcceptDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AcceptDialogData) { }

    ngOnInit() { }

		closeModalTrue() {
      this.dialogRef.close(true);
    }

		closeModalFalse() {
      this.dialogRef.close(false);
    }

}
