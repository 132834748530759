<nb-card class="mb-0">
	<nb-card-header class="flex justify-content-between border-bottom-0">
		<div class="col-11">
			<div *ngIf="optionalTitle; else defaultTitle"> <h5>{{optionalTitle}}</h5></div>
			<ng-template #defaultTitle><h5>{{ 'ACEPTARCANCELAR.SELECCIONEOPCION' | translate }}</h5></ng-template>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal()" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="m-3 m-md-5 row d-flex justify-content-center">

			<div class="col-12 d-flex justify-content-center">
				<span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ mensaje }}</span>
			</div>
			<form [formGroup]="form" #Form1="ngForm" *ngIf="traducirGrafo" class="mt-2">
				<nb-select placeholder="{{ 'CURSOS.SELECCIONIDIOMA' | translate }}" fullWidth fieldSize="medium" class="SelectGlobal justify-content-center" hero shape="round" formControlName="lang">
					<nb-option class="SelectGlobalOption" *ngFor="let language of languages$ | async" [value]="language.idLanguageIso">
						{{ language.language | translate }}
					</nb-option>
				</nb-select>
				<div *ngFor="let validation of validationMessages.lang">
					<div class="error-message" *ngIf="langControl?.hasError(validation.type) && (langControl?.dirty || langControl?.touched || Form1.submitted)">
						{{ validation.message }}
					</div>
				</div>
			</form>

		</div>
	</nb-card-body>
	<nb-card-footer class="border-top-0">
		<div class="mb-2 row justify-content-center">
			<div class="col-5 d-flex justify-content-center" *ngIf="!groupSelected && fromInforme && profile === 'ESTUDIANTE'">
				<button nbButton class="btn-themecolor mr-1" (click)="sinGrupo()">{{ 'ACEPTARCANCELAR.SINGRUPO' | translate }}</button>
			</div>
			<div class="col-5 d-flex justify-content-center">
				<button nbButton class="btn-themecolor mr-1" (click)="aceptar()">{{ 'ACEPTARCANCELAR.ACEPTAR' | translate	}}</button>
			</div>
			<div class="col-5 d-flex justify-content-center" *ngIf="!disableCancel">
				<button nbButton class="btn-red" (click)="cancelar()">{{ 'ACEPTARCANCELAR.CANCELAR' | translate }}</button>
			</div>
		</div>
	</nb-card-footer>
</nb-card>
