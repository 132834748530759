<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="flex flex-column border-bottom-0">
		<div class="row">
			<div class="col-10 modal-title">
				<h4>{{ curso?.courseTittle }}</h4>
			</div>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}"
			nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-body class="p-0">
		<div *ngIf="curso">
			<app-loading [isVisible]="cargando"></app-loading>
			<!--===== INFO DEL CURSO =====-->
			<nb-card class="no-edge-card background_themecolor mb-0">
				<nb-card-body>
					<form [formGroup]="formulario" #thisForm="ngForm" class="row d-flex justify-content-center p-2">
						<!-- Imagen del proyecto -->
						<div class="col-12 col-lg-3">
							<nb-card class="project-info pointer background_themecolor no-edge-card mb-0 align-items-center">
								<div class="imagecontainer">
									<app-imgupload [isShowDelete]="true" formControlName="image" *ngIf="networkSpeed >= 1"></app-imgupload>
									<div class="text-center avatar-detail-course mb-2 d-flex align-content-center"
									[ngStyle]="{'background-image': 'url(../../../../assets/images/no-image.png)'}" *ngIf="networkSpeed < 1"></div>
								</div>
							</nb-card>
						</div>
						<!-- Titulo y descripcion -->
						<div class="col-12 col-lg-6 my-4 my-lg-0">
							<nb-card class="mb-0 background_themecolor pointer no-edge-card">
								<nb-card-header class="p-0">
									<nb-icon class="pointer position-absolute" style="top: 0; right: 15px;" icon="info-outline"
										[options]="{ animation: { type: 'pulse' } }" (click)="openInformationCourse()">
									</nb-icon>
									<div class="form-group">
										<label for="exampleFormControlInput1">{{ 'EDITARCURSO.TITLE' | translate }}</label>
										<input nbInput fullWidth formControlName="title" type="text" id="exampleFormControlInput1"
											placeholder="Titulo" maxlength="100" fieldSize="small" class="form-control">
									</div>
								</nb-card-header>

								<nb-card-body class="p-0">
									<div class="form-group">
										<label for="exampleFormControlTextarea1">{{ 'EDITARCURSO.DESCRIPCION' | translate }}</label>
										<textarea nbInput fullWidth formControlName="description" id="exampleFormControlTextarea1" rows="3"
											maxlength="500" fieldSize="small" class="form-control">
										</textarea>
									</div>
								</nb-card-body>
							</nb-card>
						</div>
						<!-- Datos del autor -->
						<div class="col-12 col-lg-3 my-4 my-lg-0">
							<nb-card class="mb-0 background_themecolor pointer no-edge-card">
								<h6 class="text-center mt-2">{{'MODALINFORMATIONCOURSE.AUTHOR' | translate}}:</h6>
								<div class="w-100 d-flex justify-content-center">
									<div class="text-center avatar-detail-course mb-2 d-flex align-content-center"
										[ngStyle]="{'background-image': getUserAvatar()}" *ngIf="networkSpeed >= 1"></div>
									<div class="text-center avatar-detail-course mb-2 d-flex align-content-center"
										[ngStyle]="{'background-image': 'url(../../../../assets/images/no-image.png)'}" *ngIf="networkSpeed < 1"></div>
								</div>
								<h6 class="text-center">{{cursoDatos.user.name}} {{cursoDatos.user.surname}}</h6>
								<div class="w-100 d-flex justify-content-center">
									<button nbButton class="btn-themecolor" size="small" (click)="listadoEditores()">{{'EDITORS.EDITORS' | translate}}</button>
								</div>
							</nb-card>
						</div>

						<div class="row w-100 mt-3 gap-3 justify-content-center">
							<!-- Opciones para hacer el proyecto visible y publico -->
							<div class="d-flex gap-4 justify-content-center flex-lg-row flex-column">
								<!-- visible -->
								<div class="d-flex flex-column gap-1">
									<nb-toggle class="item-profile" *ngIf="id!='nuevo' && loginService.esAutor()"
										formControlName="isVisible" (click)="makeVisibleCourse()">
									</nb-toggle>
									<span class="text-center" style="line-height: 1rem; font-size: 12px;">
										{{
											formulario.get('isVisible').value
											? ('EDITARCURSO.VISIBLE' | translate)
											: ('EDITARCURSO.NOVISIBLE' | translate)
										}}
									</span>
								</div>
								<!-- Publico -->
								<div class="d-flex flex-column gap-1">
									<nb-toggle class="item-profile" *ngIf="id!='nuevo' && loginService.esAutor()"
										formControlName="courseType" (click)="changeCourseType()">
									</nb-toggle>
									<span class="text-center" style="line-height: 1rem; font-size: 12px;">
										{{
											formulario.get('courseType').value
											? ('EDITARCURSO.PUBLIC' | translate)
											: ('EDITARCURSO.PRIVATE' | translate)
										}}
									</span>
								</div>
							</div>
							<!-- Configuracion -->
							<div class="flex gap-3 flex-lg-row flex-column">
								<!-- Pais -->
								<nb-select name="idCountry" placeholder="{{'FILTER.COUNTRY' | translate}}" formControlName="idCountry"
									(selectedChange)="searchLevels($event)" size="small" hero class="SelectForm">
									<nb-option *ngFor="let country of countriesList" value="{{ country.idCountry }}" class="SelectFormOpt">
										{{country.country_es }}
									</nb-option>
								</nb-select>
								<!-- nivel -->
								<nb-select name="idCountryLevel" placeholder="{{'FILTER.LEVEL' | translate}}" formControlName="idCountryLevel"
									size="small" hero class="SelectForm">
									<nb-option *ngFor="let level of levelsOfCountry" [value]="level.idCountryLevel" class="SelectFormOpt">
										{{ level.countryLevel}}
									</nb-option>
									<nb-option *ngIf="notCountryLevelsForThisCountry">
										{{'MODALINFORMATIONCOURSE.NOTLEVELSFORCOUNTRY' |translate}}
									</nb-option>
								</nb-select>
								<!-- idioma -->
								<nb-select name="lang1" placeholder="{{'FILTER.LANGUAGE' | translate}}" formControlName="lang1"
									size="small" hero class="SelectForm">
									<nb-option *ngFor="let lang of langList" [value]="lang.idLang" class="SelectFormOpt">
										{{ lang.nombre }}
									</nb-option>
								</nb-select>
								<!-- Tipo de proyecto -->
								<nb-select name="project_type" placeholder="{{'FILTER.PROJECTTYPE' | translate}}" formControlName="project_type"
									size="small" hero class="SelectForm">
									<nb-option *ngFor="let projectType of projectsTypes" [value]="projectType.id" class="SelectFormOpt">
										{{projectType.descripcion }}
									</nb-option>
								</nb-select>
							</div>
						</div>
					</form>
				</nb-card-body>
			</nb-card>
			<!--===== LISTA IDEOGRAFOS =====-->
			<nb-card class="no-edge-card mb-0">
				<nb-card-header class="border-bottom-0">
					<div class="d-flex align-items-center gap-4">
						<div class="modal-title">
							<h2>{{ 'EDITARCURSO.GRAFOS' | translate }}</h2>
						</div>
						<nb-actions size="small">
							<!-- Boton para cambiar al modo grilla -->
							<nb-action *ngIf="modeList" icon="grid-outline" nbTooltip="{{ 'CURSOS.VERMOSAICO' | translate }}"
								nbTooltipPlacement="top" (click)="changeView(false)">
							</nb-action>
							<!-- Boton para cambiar al modo lista -->
							<nb-action *ngIf="!modeList" icon="list-outline" nbTooltip="{{ 'CURSOS.VERARBOL' | translate }}"
								nbTooltipPlacement="top" (click)="changeView(true)">
							</nb-action>
							<!-- Boton para crear un nuevo grafo -->
							<nb-action *ngIf="id!='nuevo'" icon="plus-circle-outline" class="p-1 pl-3"
								nbTooltip="{{'EDITARCURSO.NUEVOGRAFO' | translate}}" nbTooltipPlacement="top" (click)="nuevoMapa(false)">
							</nb-action>
						</nb-actions>
					</div>
				</nb-card-header>
				<nb-card-body>
					<!-- SI NO HAY GRAFOS -->
					<div *ngIf="dataList.length == 0">
						<h5 class="text-center pointer">
							{{ 'CURSOS.NOTGRAPHS' | translate }}
						</h5>
					</div>
					<!-- VISTA MODO GRILLA -->
					<div *ngIf="!modeList" class="d-flex justify-content-center min-h-100">
						<div *ngIf="dataList.length > 0" class="d-flex flex-column gap-5 w-100">
							<div class="d-flex flex-wrap justify-content-center gap-5 w-100" [sortablejs]="dataList" [sortablejsOptions]="options">
								<div *ngFor="let grafo of dataList | sortBy:'asc':'ordinal' | paginate: { itemsPerPage: 16, currentPage: page }"
									class="card-projects" [class.draggable]="true" [class.disabled]="false">
									<nb-card size="small" class="pointer mb-0" *ngIf="grafo.type == 0"
										[ngClass]="{'project-info-1': curso.project_type == 1, 'project-info-2': curso.project_type == 2, 'project-info-3': curso.project_type == 3, 'project-info-4': curso.project_type == 4, 'project-info-5': curso.project_type == 5}">
										<nb-card-header class="pb-2 pt-0 pl-0 pr-0 border-bottom-0" (click)="verNodosMapa(grafo.id)">
											<!--ocultamos la imagen si la conexion es lenta-->
											<div class="img-container">
												<img [src]="grafo.image | imagen:'mapas'" (error)="updateUrl($event)" class="img-fluid"
													*ngIf="networkSpeed >= 1">
												<img [src]="'../../assets/images/no-image.png'" class="img-fluid" *ngIf="networkSpeed < 1">
											</div>
										</nb-card-header>
										<nb-card-body class="p-2" (click)="verNodosMapa(grafo.id)">
											<p class="paragraph courseTittle">{{ grafo.title | truncate:[50, '...'] }}</p>
										</nb-card-body>
										<nb-card-footer class="flex flex-row justify-content-end pt-0 pl-1 pr-1 pb-1 border-top-0">
											<!-- Opcion para ir a la configuracion -->
											<div class="icon-option icon-settings pointer" nbTooltip="{{ 'GRUPOS.AJUSTES' | translate }}"
												(click)="verDetalleMapa(grafo.id, grafo.type)">
											</div>
										</nb-card-footer>
									</nb-card>
									<!-- *** grafos con traduccion en curso *** -->
									<div [ngClass]="{'nb-card-overlay1': curso.project_type == 1, 'nb-card-overlay2': curso.project_type == 2}">
										<nb-card size="small" class="pointer mb-0" *ngIf="grafo.type == 1"
											[ngClass]="{'project-info-1': curso.project_type == 1, 'project-info-2': curso.project_type == 2, 'project-info-3': curso.project_type == 3, 'project-info-4': curso.project_type == 4, 'project-info-5': curso.project_type == 5}">
											<nb-card-header class="pb-2 pt-0 pl-0 pr-0 border-bottom-0">
												<!--ocultamos la imagen si la conexion es lenta-->
												<div class="img-container">
													<img [src]="grafo.image | imagen:'mapas'" (error)="updateUrl($event)" class="img-fluid"
														*ngIf="networkSpeed >= 1">
													<img [src]="'../../assets/images/no-image.png'" class="img-fluid" *ngIf="networkSpeed < 1">
												</div>
											</nb-card-header>
											<nb-card-body class="p-2">
												<p class="paragraph courseTittle">{{ grafo.title | truncate:[50, '...'] }}</p>
											</nb-card-body>
										</nb-card>
										<div class="overlay-content" *ngIf="grafo.type == 1">
											<h6 class="icon-text">{{ 'GLOBALS.TRANSLATEINPROCESS' | translate }}</h6>
										</div>
									</div>
								</div>
							</div>
							<!-- Controles de paginación -->
							<div class="d-flex justify-content-center align-items-center">
								<pagination-controls (pageChange)="page = $event" previousLabel="" nextLabel=""></pagination-controls>
							</div>
						</div>
					</div>
					<!-- VISTA MODO LISTA -->
					<div *ngIf="modeList" class="row min-h-100">
						<!--LISTA-->
						<div class="col-4 py-4 pl-4 pr-0 container-tree background_white">
							<div class="tree pr-4">
								<!--Primer nivel (IDEOGRAFOS)-->
								<ul *ngIf="dataList.length > 0">
									<li *ngFor="let target of dataList; let i = index"  style="border-bottom: 1px solid hsla(0, 0%, 50%, 0.25);">
										<div class="d-flex pointer py-2">
											<div class="d-flex align-content-center" (click)="viewNodes(i)">
												<nb-icon icon="chevron-right-outline" status="basic" *ngIf="!target.childrenVisible"></nb-icon>
												<nb-icon icon="chevron-down-outline" status="basic" *ngIf="target.childrenVisible"></nb-icon>
												<mat-icon svgIcon="ideografo" *ngIf="curso.project_type == 1"></mat-icon>
												<mat-icon class="custom-icon_green2" svgIcon="ideografo" *ngIf="curso.project_type == 2"></mat-icon>
												<mat-icon class="custom-icon_red2" svgIcon="ideografo" *ngIf="curso.project_type == 3"></mat-icon>
												<mat-icon class="custom-icon_yellow2" svgIcon="ideografo" *ngIf="curso.project_type == 4"></mat-icon>
												<mat-icon class="custom-icon_violet2" svgIcon="ideografo" *ngIf="curso.project_type == 5"></mat-icon>
												<span> {{ target.title }} </span>
											</div>
										</div>
										<!--Segundo nivel (NODOS Y QUIZZES SUELTOS)-->
										<ul class="py-1 pl-4" [ngStyle]="{'display': target.childrenVisible ? 'block' : 'none'}">
											<div *ngIf="target.children.length > 0">
												<li *ngFor="let nodos of target.children; let j = index" style="border-bottom: 1px solid hsla(0, 0%, 50%, 0.25);">
													<div class="d-flex pointer py-2 gap-3 justify-content-between" [class.selected]="selectedLabel === nodos">
														<div class="d-flex align-content-center" (click)="viewQuizzes(i, j)">
															<nb-icon icon="chevron-right-outline" status="basic"
																*ngIf="!nodos.childrenVisible && nodos.type == 0"></nb-icon>
															<nb-icon icon="chevron-down-outline" status="basic"
																*ngIf="nodos.childrenVisible && nodos.type == 0"></nb-icon>
															<mat-icon svgIcon="ideasvg" *ngIf="nodos.type == 0"></mat-icon>
															<mat-icon svgIcon="act" *ngIf="nodos.type == 1"></mat-icon>
															<mat-icon svgIcon="QuizMulti" *ngIf="nodos.type == 2"></mat-icon>
															<span> {{ nodos.title }} </span>
														</div>
														<div (click)="viewData(i, j, nodos)" class="d-flex justify-content-center align-item-center gap-2">
															<nb-icon icon="eye-outline" status="basic" *ngIf="selectedLabel === nodos"></nb-icon>
															<nb-icon icon="eye-off-2-outline" status="basic" *ngIf="selectedLabel !== nodos"></nb-icon>
														</div>
													</div>
													<!--Tercer nivel (QUIZZES)-->
													<ul class="py-1 pl-4" [ngStyle]="{'display': nodos.childrenVisible ? 'block' : 'none'}">
														<div *ngIf="nodos.children.length > 0">
															<li *ngFor="let quizzes of nodos.children; let k = index;" style="border-bottom: 1px solid hsla(0, 0%, 50%, 0.25);">
																<div class="d-flex pointer py-2 gap-3 justify-content-between" [class.selected]="selectedLabel === quizzes">
																	<div class="d-flex align-content-center">
																		<mat-icon svgIcon="act" *ngIf="quizzes.type == 1"></mat-icon>
																		<mat-icon svgIcon="QuizMulti" *ngIf="quizzes.type == 2"></mat-icon>
																		<span>
																			{{ quizzes.title }}
																		</span>
																	</div>
																	<div class="d-flex justify-content-center align-item-center gap-2">
																		<!-- flechas para cambiar ordinal -->
																		<div>
																			<nb-icon icon="arrow-up-outline" status="basic" nbTooltip="{{'TREECOMPONENT.SUBIRPOSICION' | translate}}"
																				*ngIf="k > 0" (click)="changeOrdinalQuiz(nodos.id, i, j, k , 'up')"></nb-icon>
																			<nb-icon icon="arrow-down-outline" status="basic" nbTooltip="{{'TREECOMPONENT.BAJARPOSICION' | translate}}"
																				*ngIf="k < nodos.children.length - 1" (click)="changeOrdinalQuiz(nodos.id, i, j, k , 'down')"></nb-icon>
																		</div>
																		<!-- icono para editar el quiz -->
																		<div>
																			<nb-icon icon="eye-outline" status="basic" *ngIf="selectedLabel === quizzes"></nb-icon>
																			<nb-icon icon="eye-off-2-outline" status="basic" *ngIf="selectedLabel !== quizzes" nbTooltip="{{'TREECOMPONENT.EDITARQUIZ' | translate}}" (click)="viewData(i, j, quizzes)"></nb-icon>
																		</div>
																	</div>
																</div>
															</li>
														</div>
														<!--Crear nueva Actividad-->
														<div class="d-flex align-items-center my-2 pointer" (click)="newQuiz(i, j, target.id, nodos.id)">
															<mat-icon svgIcon="add"></mat-icon>
															<span>{{'TREECOMPONENT.NEWQUIZ' | translate}}</span>
														</div>
													</ul>
												</li>
											</div>
											<!--Crear nueva Idea-->
											<div class="d-flex align-items-center my-2 pointer" (click)="newNode(i, target.id)">
												<mat-icon svgIcon="add"></mat-icon>
												<span>{{'TREECOMPONENT.NEWNODE' | translate}}</span>
											</div>
										</ul>
									</li>
								</ul>
							</div>
						</div>
						<!--DETALLES-->
						<div class="col-xl-8 pl-xl-4 py-0 pr-0 container-tree">
							<div class="background_white h-100 py-4 pl-3 pr-0">
								<div class="w-100 height-max">
									<!--editar nodo-->
									<div *ngIf="idSelected == 1">
										<app-node-detail-info [node]="node" [idCurso]="idCurso" [idMapa]="idMapa" [canEdit]="canEdit" [course]="course"
											[isNewNode]="isNewNode" [fromLista]="true" (closeInModeList)="hideData($event)"></app-node-detail-info>
									</div>
									<!--editar quiz simple-->
									<div *ngIf="idSelected == 2">
										<app-quiz-edit [quiz]="quiz" [elements]="elements" [courseId]="idCurso" [graphId]="idMapa" [fromLista]="true"
											(closeInModeList)="hideData($event)"></app-quiz-edit>
									</div>
									<!--editar quiz multiple-->
									<div *ngIf="idSelected == 3">
										<app-quiz-multiple-edit [quiz]="quiz" [elements]="elements" [courseId]="idCurso" [graphId]="idMapa"
											[quizFiles]="quizFiles" [quizzes]="quizzes" [fromLista]="true"
											(closeInModeList)="hideData($event)"></app-quiz-multiple-edit>
									</div>
								</div>
							</div>
						</div>
					</div>
				</nb-card-body>
			</nb-card>
		</div>
	</nb-card-body>
</nb-card>
