<nb-card class="mb-0 no-edge-card">
	<nb-card-header class="flex flex-column border-bottom-0">
		<div class="row">
			<div class="col-md-6 modal-title">
				<h2>{{'CURSOS.TITLE' | translate}}</h2>
			</div>
		</div>

		<div class="row">
			<div class="col-sm-12 col-md-8 mt-4">
				<form [formGroup]="formulario" class="search-form" #thisForm="ngForm">
					<input type="search" autocomplete="off" nbInput fullWidth fieldSize="medium" name="fiilter"
						placeholder="{{ 'CURSOS.SEARCH' | translate }}" formControlName="filtrado" autocomplete="off"
						(keyup.enter)="filtrarCursosLupa()">
					<nb-icon class="icon-color-blue closebtn pointer text-right m-1 float-right search-icon" icon="search"
						[options]="{ animation: { type: 'pulse' } }"></nb-icon>
				</form>
			</div>

			<div class="col-sm-12 col-md-4 mt-4 item-profile d-flex">
				<nb-actions size="medium">
					<nb-action *ngIf="viewList" icon="grid-outline" nbTooltip="{{ 'CURSOS.VERMOSAICO' | translate }}"
						nbTooltipPlacement="left" (click)="changeView(false)"></nb-action>
					<nb-action *ngIf="!viewList" icon="list-outline" nbTooltip="{{ 'CURSOS.VERARBOL' | translate }}"
						nbTooltipPlacement="left" (click)="changeView(true)"></nb-action>
					<!-- <button class="element m-1" mat-icon-button (click)="ngOnInit();">
						<mat-icon class="icon-color-blue " svgIcon="cursolibro"></mat-icon>
					</button> -->
				</nb-actions>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<!-- VISTA MODO GRILLA -->
		<div class="row d-flex justify-content-center cards-container">
			<div class="col-sm-12 col-md-12 col-12">
				<app-loading [isVisible]="cargando"></app-loading>

				<div *ngIf="!cargando && cursos.length !== 0">

					<div class="row" *ngIf="!viewList && cursos">
						<div *ngFor="let curso of cursos | sortBy:'asc':'courseTittle'" (click)="verDetalleCurso(curso.idCourse)"
							class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">

							<nb-card size="small" class="project-info pointer">
								<nb-card-header class="p-2">
									<div class="img-container" ngbTooltip="{{curso.description}}" placement="top">
										<img [src]="curso.cpicture | imagen:'cursos'" class="img-fluid" [alt]="curso.courseTittle"
											(error)="updateUrl($event)">
									</div>
								</nb-card-header>

								<nb-card-body class="p-2">
									<p class="courseTittle">{{ curso.courseTittle | titlecase | truncate:[60, '...'] |
										uppercase}}
									</p>
								</nb-card-body>

								<nb-card-footer>
									<div class="footer d-flex justify-content-between align-items-center mr-1 ml-1">
										<div class="averageKnowledge mr-1">
											{{curso.averageKnowledge }}
										</div>
										<div class="progress half-width">
											<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
												aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
												[style.backgroundColor]="curso.barColor" [style.width]="curso.progress+'%'">
											</div>
										</div>
										<div class="averageKnowledge ml-1">{{curso.progress+'%'}}</div>
									</div>
								</nb-card-footer>
							</nb-card>

						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- VISTA MODO ARBOL -->
		<div class="row tree" *ngIf="viewList">

			<div class="col-sm-12 col-md-4 background-tree">
				<h5 class="text-title font-weight-bold">
					{{ 'INFORMES.EXPANDIRVISTA' | translate }}
				</h5>
				<app-mat-tree-cursos [idCourse]="idCourse" [treeCourses]="treeCourses" (clickElement)="clickElement($event)"
					(createNewElement)="createNewElement($event)"></app-mat-tree-cursos>
			</div>

			<div class="col-sm-12 col-md-8 py-4" style="overflow: hidden;">
				<div class="row justify-content-center">
					<div class="w-100 col-sm-12 col-md-10 mb-3">
						<h5 class="text-title text-center font-weight-bold">
							{{ 'INFORMES.TABLA_DATOS_QUIZES' | translate }}
						</h5>
					</div>
				</div>
				<div class="w-100">
					<!-- CONTAINER CON LAS OPCIONES SELECCIONADAS EN LA COLUMNA DE LA IZQUIERDA-->

					<div class="d-flex align-items-center justify-content-center pt-5" *ngIf="isLoading && !idCourse">
						<!--   <mat-spinner [diameter]="50" ></mat-spinner>           -->
					</div>

					<!-- VISUALIZAR NODO -->
					<div (mousemove)="showFiles(true)" (click)="showFiles(true)" *ngIf="node && !isLoading">
						<app-node-detail-files [showInContainer]="true" [showFilesContainer]="isShowFiles" [node]="node"
							[onSaveClicked$]="saveClicked$" [canEdit]="false" (fileSelected)="onFileSelected($event)"
							(onHoverElements)="showFiles($event)"></app-node-detail-files>
					</div>
					<!--FIN VISUALIZAR NODO -->

					<!-- VISUALIZAR QUIZ -->

					<div *ngIf="quiz && !isLoading">
						<!-- SIMPLE QUIZ -->
						<div *ngIf="quiz && quiz.idMultiplexQuiz === 0" class="quiz-container" id="quiz"
							(mousemove)="showFiles(true)" (click)="showFiles(true)">
							<div class="body-container p-3 hide-scrollbars" id="container">
								<app-quiz-play-body [disabledButton]="disabledButton" [courseId]="idSelectedCourse" [graphId]="idGraph"
									[quiz]="quiz" [elements]="elements" [answered]="answered" [modeAuto]="true" [modeSocket]="false"
									(result)="onViewGif($event)" (notResponseGif)="hidenGif($event)" (answeredChange)="onAnswered()">
								</app-quiz-play-body>
							</div>
						</div>
						<!-- END SIMPLE QUIZ -->

						<!-- MULTIPLE QUIZ -->
						<div *ngIf="quiz && quiz.idMultiplexQuiz === 1" class="quiz-container" id="quiz"
							(mousemove)="showFiles(true)" (click)="showFiles(true)">
							<div class="body-container p-3 hide-scrollbars">
								<app-quiz-play-multiple-body [disabledButton]="disabledButton" [courseId]="idSelectedCourse"
									[graphId]="idGraph" [quiz]="quiz" [elements]="elements" [answered]="answered" [modeAuto]="true"
									(result)="onViewGif($event)" (notResponseGif)="hidenGif($event)" (answeredChange)="onAnswered()">
								</app-quiz-play-multiple-body>
							</div>
						</div>
						<!-- END MULTIPLE QUIZ -->
					</div>

					<!-- FIN VISUALIZAR QUIZ -->

				</div>
			</div>
		</div>
		<!-- FIN VISTA MODO ARBOL -->
	</nb-card-body>
</nb-card>
