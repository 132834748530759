import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbAccordionModule, NbCardModule, NbFormFieldModule, NbIconModule, NbInputModule, NbSelectModule, NbWindowService, NbWindowRef, NbButtonModule } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { SalwizarDesignIdeasComponent } from '../../salwizar-design-ideas/salwizar-design-ideas.component';
import { LocalStorage } from 'src/app/core/utils';
import { SigmaCanvasService } from '../../nodes/sigma-canvas/sigma-canvas.service';
import { SigmaUtils } from 'src/app/core/utils/sigma-utils';

@Component({
	selector: "app-modal-generated-ideas",
	standalone: true,
	imports: [
		CommonModule,
		NbCardModule,
		NbIconModule,
		NbSelectModule,
		NbFormFieldModule,
		NbInputModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		NbAccordionModule,
		NbButtonModule,
	],
	templateUrl: "./modal-generated-ideas.component.html",
	styleUrls: ["./modal-generated-ideas.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class ModalGeneratedIdeasComponent {
	generatedIdeas: any;
	initialPositions: any;
	selectedForm: string;
	radioSelected: number;
	private sigmaUtils: SigmaUtils;
	isLoading: boolean = false;
	AllIdeasAdded: boolean = false;
	constructor(
		private activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private windowService: NbWindowService,
		private localStorage: LocalStorage,
		private sigmaCanvasService: SigmaCanvasService,
	) {}

	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.generatedIdeas = JSON.parse(
			this.localStorage.getItem("IAGeneratedIdeas")
		);

		this.selectedForm = "circle";
		this.radioSelected = 100;
		this.checkIFAlreadyAdded();
		this.checkIfAllIdeasAdded();
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
		this.checkIFAlreadyAdded();
		this.checkIfAllIdeasAdded();
	}

	checkIFAlreadyAdded() {
		let alreadyExistingNodes = JSON.parse(
			this.localStorage.getItem("nodesInCanvas")
		);
		// we going to run the this.generatedIdeas to add a property calles "alreadyAdded" if we find the idea in the alreadyExistingNodes
		this.generatedIdeas.forEach((idea, i) => {
			this.generatedIdeas[i].alreadyAdded = alreadyExistingNodes.some(
				(node) => node.label === idea.Titulo_de_la_Idea
			);
		});

	}

	closeModal(sendData?: any): void {
		this.activeModal.close(sendData);
	}

	updateGeneratedIdeas(i, event) {
		this.generatedIdeas[i].Cuerpo_Explicativo_de_la_Idea = event.target.value;
		this.localStorage.setItem(
			"IAGeneratedIdeas",
			JSON.stringify(this.generatedIdeas)
		);
		this.generatedIdeas = JSON.parse(
			this.localStorage.getItem("IAGeneratedIdeas")
		);
	}

	addSingleIdea(idea, e) {
		e.preventDefault();
		e.stopPropagation();
		this.isLoading = true;
		this.initialPositions = JSON.parse(this.localStorage.getItem("mouseClick"));
		this.calculateCircularPositions(
			this.generatedIdeas,
			this.radioSelected,
			this.initialPositions.x,
			this.initialPositions.y
		);

		this.localStorage.setItem("selectedIAIdea", JSON.stringify(idea));
		this.sigmaCanvasService.setPreviewNode("single");

		setTimeout(() => {
			this.checkIFAlreadyAdded();
			this.isLoading = false;
		}, 500);
	}

	openDesignIdeasModal() {
		this.modalService.dismissAll();
		this.initialPositions = JSON.parse(this.localStorage.getItem("mouseClick"));

		this.calculateCircularPositions(
			this.generatedIdeas,
			this.radioSelected,
			this.initialPositions.x,
			this.initialPositions.y
		);

		this.checkIfAllIdeasAdded();

		this.sigmaCanvasService.setPreviewNode(this.selectedForm);
	}

	checkIfAllIdeasAdded() {
		this.AllIdeasAdded = this.generatedIdeas.every((idea) => idea.alreadyAdded == true);
	}

	calculateCircularPositions(
		ideas,
		radius: number,
		initialX: number,
		initialY: number
	): any {
		const angleIncrement = (2 * Math.PI) / ideas.length;

		ideas.forEach((point, index) => {
			const angle = angleIncrement * index;
			point.x = initialX + radius * Math.cos(angle - Math.PI / 2); // start at the top
			point.y = initialY + radius * Math.sin(angle - Math.PI / 2);
		});

		this.localStorage.setItem("IAGeneratedIdeas", JSON.stringify(ideas));

		return ideas;
	}
}
