import { CompetitionsService } from './../../../core/services/competitions/competitions.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NbButtonModule, NbCardModule, NbFormFieldModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsModule } from '../components.module';
import { FormsModule } from '@angular/forms';
import { ModalAceptarCancelarComponent } from '../modal/modal-aceptar-cancelar/modal-aceptar-cancelar.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { SocketService } from 'src/app/core/services/socket/socket-service.service';
import { GruposService, SOCKETMESSAGES } from 'src/app/core/services/groups/grupos.service';
import { Subject, Subscription, take, takeUntil } from 'rxjs';
import { LoginService } from 'src/app/core/services/login';
import { SocketQuizPreviewComponent } from '../socket-quiz-preview/socket-quiz-preview.component';
import { ChallengesHostWaitingComponent } from '../challenges-host-waiting/challenges-host-waiting.component';
import { GroupModel } from 'src/app/core/models/groups/groups-model';
import { User } from 'src/app/core/models/users/user.models';
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from "@angular/cdk/clipboard";
@Component({
	selector: "app-modal-input-room-code",
	standalone: true,
	imports: [
		CommonModule,
		NbCardModule,
		NbIconModule,
		NbInputModule,
		NbButtonModule,
		FormsModule,
		NbFormFieldModule,
	],
	templateUrl: "./modal-input-room-code.component.html",
	styleUrls: ["./modal-input-room-code.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalInputRoomCodeComponent {
	@Input() group: GroupModel;
	isLoading = false;
	hexCode = "";
	user: User;
	gameIsAlreadyStarted: boolean = false;
	showActInst = false;
	private destroy$ = new Subject();

	private subscriptions: Subscription[] = [];
	hexCodeComplete: boolean = false;
	usersInGroupCounter: any;
	usersInGroup: any;
	usersInGroupAux: any;
	constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private socketService: SocketService,
		private groupService: GruposService,
		public loginService: LoginService,
		public translateService: TranslateService,
		private competitionsService: CompetitionsService,
		private clipboard: Clipboard
	) {}

	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.user = this.loginService.getUser();
		this.competitionsService.getCompetitionStarted().subscribe((res) => {
			this.gameIsAlreadyStarted = res;
		});
	}

	closeModal(sendData?: any) {
		//this.destroy$.next(true);
		this.activeModal.close(sendData);
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
	}

	ngAfterViewInit(): void {
		if (this.group != null) {
			this.groupService
				.getListEstudiantes(this.group.idGroup)
				.subscribe((members) => {
					this.usersInGroupCounter = members.data.length;
					this.usersInGroup = members.data;
					this.usersInGroupAux = members.data;
				});
		}
	}

	prepareSocketToJoinRoom() {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.

		let modal: NgbModalRef;
		let modal2: NgbModalRef;
		this.socketService.msgFromServer.subscribe((data) => {
			//Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
			const dataArray = data.split(":");
			const type = dataArray[0];
			const onlineUsers: number = parseInt(dataArray[1]);
			switch (type) {
				case SOCKETMESSAGES.USERSCOUNTER:
					if (this.loginService.esEstudiante()) {
						//Si es estudiante, debo abrir el modal de espera para que se vayan abriendo los quizzes según decida el profesor
						this.groupService
							.getIfSessionExists(
								this.group ? this.group.idGroup : 0,
								this.hexCode
							)
							.pipe(takeUntil(this.destroy$), take(1))
							.subscribe((result) => {
								if(result.data.idSession != 0){
									return;
								}
								this.showActInst = result.data.existSesion;
								if (
									this.showActInst &&
									onlineUsers > 1 &&
									!this.gameIsAlreadyStarted
								) {
									modal = this.modalService.open(SocketQuizPreviewComponent, {
										scrollable: false,
										windowClass: `${MODAL_DIALOG_TYPES.W100} h-90`,
									});
									modal.componentInstance.idGroup = this.group
										? this.group.idGroup
										: 0;
									modal.componentInstance.onlineUsers = onlineUsers;
									this.competitionsService.setCompetitionStarted(true);

									modal.result
										.then(
											() => {},
											(err) => {}
										)
										.finally(() => {
											//Si se cierra el modal, procedemos a eliminar la sesión y el socket
											this.competitionsService.setCompetitionStarted(false);
											setTimeout(() => {
												this._removeSocket(this.group ? this.group.idGroup : 0);
											}, 300);
										});

									modal2 = this.modalService.open(
										ChallengesHostWaitingComponent,
										{
											scrollable: false,
											windowClass: `${MODAL_DIALOG_TYPES.W100} h-90`,
										}
									);
									modal2.componentInstance.hex = this.hexCode;
									modal2.componentInstance.usersOfTheGroup =
										this.usersInGroupAux;
									modal2.componentInstance.group = this.group;
									modal2.componentInstance.cameFromLobby = true;

									modal2.result.then((result: boolean) => {
										this.competitionsService
											.getCompetitionSession(this.hexCode)
											.subscribe((data) => {
												let {
													idCompetitionSession,
													creationDate,
													idGroup,
													idSession,
													idUser,
													nameSession,
													teams,
													roomProps,
													teacher,
												} = data.data;
												let modifyRoomProps = JSON.parse(roomProps);
												let userInAnyTeam = teams.some(team => team.users.includes(this.user.idUser));

												if (!userInAnyTeam) {
													teams.forEach((team) => {
														if (team.name == "Sin Equipo") {
															team.users.push(this.user.idUser);
														}
													});
												}
												//if user is in modifyRoomProps.connectedUsers, remove it
												let userIndex =
													modifyRoomProps.connectedUsers.findIndex(
														(user) => user.id === this.user.idUser
													);
												if (userIndex > -1) {
													modifyRoomProps.connectedUsers.splice(userIndex, 1);
												}

												roomProps = JSON.stringify(modifyRoomProps);

												this.competitionsService
													.updateCompetitionSession(
														idCompetitionSession,
														creationDate,
														idGroup,
														idSession,
														idUser,
														teacher,
														nameSession,
														teams,
														roomProps
													)
													.subscribe((result) => {});
											});

										this.competitionsService.setCompetitionStarted(false);
										//this._removeSocket(this.group ? this.group.idGroup : 0);
										//this.destroy$.next(true);
									});
								}
							});
					}
					break;
			}
		});
	}

	ngDoCheck(): void {
		//Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
		//Add 'implements DoCheck' to the class.
		this.hexCodeComplete = this.hexCode.length === 6;
	}

	joinRoom() {
		this.prepareSocketToJoinRoom();
		setTimeout(() => {
			const hex = this.hexCode;
			const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30,
			});
			let modalMessage = `${this.translateService.instant(
				"COMPETITION.YOUWILLJOINGAME"
			)}`;
			//let modalMessage = `Te vas a unir a esta partida estas seguro?`;
			modalRef.componentInstance.mensaje = modalMessage;

			modalRef.result.then((result: boolean) => {
				if (result) {
					this.competitionsService
						.getCompetitionSession(hex)
						.subscribe((data) => {
							let {
								idCompetitionSession,
								creationDate,
								idGroup,
								idSession,
								idUser,
								nameSession,
								teams,
								roomProps,
								teacher,
							} = data.data;
							let modifyRoomProps = JSON.parse(roomProps);

							//push user to connected user only if it is not already in the list
							let userAlreadyInList = modifyRoomProps.connectedUsers.find(
								(user) => user.id === this.user.idUser
							);
							if (!userAlreadyInList) {
								modifyRoomProps.connectedUsers.push({
									firstName: this.user.firstName,
									id: this.user.idUser,
									picture: this.user.pictureUser,
									surname: this.user.surname,
								});
							}

							roomProps = JSON.stringify(modifyRoomProps);

							this.competitionsService
								.updateCompetitionSession(
									idCompetitionSession,
									creationDate,
									idGroup,
									idSession,
									idUser,
									teacher,
									nameSession,
									teams,
									roomProps
								)
								.subscribe((result) => {
									this.socketService.createSocketForPlayRoom(hex); //Creo el socket
									const openSocketSub = this.socketService.openSocket.subscribe(
										(ev) => {
											//Cuando se cree el socket, procedemos a guardar la sesion en el servidor

											this.groupService
												.getIfSessionExists(
													this.group ? this.group.idGroup : 0,
													hex
												)
												.subscribe((res) => {
													if (res.data) {
														this.groupService
															.deleteGroupSession(
																this.group ? this.group.idGroup : 0
															)
															.subscribe((res) => {
																this.groupService
																	.createGroupSession(
																		this.group ? this.group.idGroup : 0,
																		hex
																	)
																	.subscribe(
																		(result) => {
																			//Si se guarda correctamente la sesion, tenemos que abrir el modal con los cursos en modo lista
																			this.socketService.idSession =
																				result.data.idSession;
																			openSocketSub.unsubscribe();
																		},
																		(err) => {
																			//modal.close()
																		}
																	);
															});
													} else {
														this.groupService
															.createGroupSession(
																this.group ? this.group.idGroup : 0,
																hex
															)
															.subscribe(
																(result) => {
																	//Si se guarda correctamente la sesion, tenemos que abrir el modal con los cursos en modo lista
																	this.socketService.idSession =
																		result.data.idSession;
																	openSocketSub.unsubscribe();
																},
																(err) => {
																	//modal.close()
																}
															);
													}
												});
										}
									);
									this.subscriptions.push(openSocketSub);
								});
						});
				}
			});
		}, 100);
	}

	pasteCode() {
		navigator.clipboard.readText().then((text) => {
			this.hexCode = text;
		});
	}

	private _removeSocket(idGroup) {
		this.socketService.removeSocket(); //Si ha habido error al guardar la sesión, tenemos que destruir el socket y cerrar el modal
		this.groupService.deleteGroupSession(idGroup).subscribe(); //Elimino la sesión de la bd
		this.subscriptions.forEach((s) => s.unsubscribe()); //Elimino las suscripciones a eventos
	}
}
