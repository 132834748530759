<div class="sticky-top sticky-top-header">
<div class="modal-header tittle" align="center">
    <div class="col-sm-1">
        <!--mat-icon class="icon-tittle icon-size-small pointer" (click)="save()">save</mat-icon-->
    </div>
    <div class="col-sm-7 align-self-center text-center">
        <span *ngIf="quiz && quiz.text" class="textoFontSize08 font-weight-bold span-text-center">{{ 'NODOS.GRAFO' | translate }}:
            {{quiz.text}}</span>
    </div>
    <div class="col-sm-2 align-self-center text-center">
        <button type="button" class="close pointer" [ngStyle]="{'color':'rgb(29, 73, 153)'}" data-dismiss="modal"
            (click)="closeModal('')">
            <span class="textoFontSize18 font-weight-bold span-text-center"  aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<div class="options" align="center">
    <div class="row mx-auto">
        <div class="col-0 col-sm-0 col-md-8 col-lg-8">
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <div class="row align-items-center justify-content-center">
                <div class="col-sm-2 col-2">
                    <mat-icon class="icon-head icon-size-medium pointer" color="primary" data-toggle="tooltip"
                        data-placement="top" title="Solicitar explicación" svgIcon="askforhelp"></mat-icon>
                </div>
                <div class="col-sm-2 col-2">
                    <mat-icon class="icon-head icon-size-medium pointer">menu_book</mat-icon>
                </div>
                <!--div class="col-sm-1 col-1" (click)="closeModal('')">
                    <mat-icon class="icon-head icon-size-medium pointer">reduce_capacity</mat-icon>
                </div-->
                <!--div class="col-sm-1 col-1">
                    <mat-icon class="icon-head icon-size-medium pointer">publish</mat-icon>
                </div-->
                <div class="col-sm-2 col-2">
                    <!--mat-icon class="icon-head icon-size-medium pointer" (click)="mostrarRedesSociales()">share
                    </mat-icon>
                    <div class="row" style="position: absolute; z-index: 1">
                        <button *ngFor="let btn of buttons" mat-mini-fab
                            [ngStyle]="{'background-color': getColor(btn.id)}" (click)="enviarRedesSociales(btn.id)">
                            <a class="enlace_desactivado" target="_blank"><i class={{btn.icon}}></i></a>
                        </button>
                    </div-->
                    <button mat-icon-button [matMenuTriggerFor]="menuRedesSociales">
                        <mat-icon class="icon-head icon-size-medium pointer" ngbTooltip="{{'QUIZZES.SHARE' | translate }}" >share
                        </mat-icon>
                    </button>
                    <mat-menu #menuRedesSociales="matMenu">
                        <div class="row mx-auto align-items-center justify-content-center">
                            <div class="col-lg-3" *ngFor="let btn of buttons ;let i = index">
                                <button *ngIf="i!=4" class="button-redes mt-2 pointer"
                                    [ngStyle]="{'background-color': btn.color}" (click)="enviarRedesSociales(btn.id)">
                                    <a class="enlace_desactivado" target="_blank"><i class="{{btn.icon}}"
                                            style="font-size:24px; color: white"></i></a>
                                </button>
                                <button *ngIf="i==4" class="mt-2" [ngStyle]="{'color': btn.color}">
                                    <mat-icon class="button-salware pointer" svgIcon={{btn.icon}}
                                        (click)="seleccionarDestinatario()"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-menu>
                </div>
                <div class="col-sm-2 col-2">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon class="icon-head icon-size-medium pointer" ngbTooltip="{{'QUIZZES.MESSAGE' | translate }}" >sms</mat-icon>
                    </button>
                    <mat-menu class="w-100" #menu="matMenu">
                        <button mat-menu-item>
                            <mat-icon>comment</mat-icon>
                            <span>{{'QUIZZES.COMENTARIO' | translate }}</span>
                        </button>
                        <button mat-menu-item>
                            <mat-icon>error_outline</mat-icon>
                            <span>{{'QUIZZES.ERROR' | translate }}</span>
                        </button>
                        <button mat-menu-item>
                            <mat-icon>flag</mat-icon>
                            <span>{{'QUIZZES.REPORTE' | translate }}</span>
                        </button>
                    </mat-menu>
                </div>
                <div class="col-sm-2 col-2">
                    <mat-icon class="icon-head icon-size-medium pointer" (click)="imprimir()" ngbTooltip="{{'QUIZZES.PRINT' | translate }}" >print</mat-icon>
                    <!--button
                                        [useExistingCss]="true"
                                        printSectionId="quiz"
                                        styleSheetFile=""
                                        ngxPrint><mat-icon class="icon-head pointer">print</mat-icon>
                                        </button-->
                    <!--button ngxPrintItemButton divID="quiz">
                                            <mat-icon class="icon-head pointer">print</mat-icon>
                                        </button-->
                </div>
                <!--div class="col-sm-1 col-1"-->
                    <!--mat-icon class="icon-head pointer" ngbPopover={{informacionQuiz}}
                                        triggers="mouseenter:mouseleave" popoverTitle="{{'QUIZZES.INFORMACION'|translate}}">format_italic</mat-icon-->
                    <!--mat-icon class="icon-head icon-size-medium pointer">format_italic</mat-icon-->
                <!--/div-->
                <div class="col-sm-2 col-2">
                    <mat-icon class="icon-head icon-size-medium pointer">visibility</mat-icon>
                </div>
            </div>
        </div>
</div>
</div>
</div>
