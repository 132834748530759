import { Injectable } from '@angular/core';

const AUDIOFILE: string = '../../../../assets/audio/ring.mp3';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  playNotificationSound() {
    const audio = new Audio(AUDIOFILE);
    audio.loop = false;
    audio.play().catch((error) => console.error('Error al reproducir el sonido:', error));
  }

}