<nb-card class="mb-0 no-edge-card">
	<nb-card-body>
		<div style="width: 500px;">
			<div class="d-flex flex-column gap-4">
				<div class="d-flex flex-wrap justify-content-between gap-3">
					<div class="d-flex flex-column">
						<label for="select2">{{ 'SALWIZAR.SHAPE' | translate }}:</label>
						<nb-select [(ngModel)]="selectedForm" id="select2" class="SelectForm" hero size="small">
							<nb-option class="SelectFormOpt" value="circle">{{ 'SALWIZAR.CIRCULAR' | translate }}</nb-option>
							<!-- <option value="rectangle">Rectangulo</option>
							<option value="triangle">Triangulo</option>
							<option value="line">Linea Ascendente</option> -->
						</nb-select>
					</div>

					<div class="d-flex flex-column">
						<label for="myRange">Radio: {{radioSelected}}</label>
						<input [(ngModel)]="radioSelected" type="range" nbInput fullWidth fieldSize="medium" class="form-control" min="400" max="800" value="100" id="myRange">
					</div>
				</div>

				<nb-accordion *ngIf="generatedIdeas" multi>
					<nb-accordion-item *ngFor="let idea of generatedIdeas; let i = index">
						<nb-accordion-item-header>
							<div class="d-flex justify-content-between align-items-center gap-3">
								<h6>{{ idea.Titulo_de_la_Idea | slice:0:30 }}</h6>
								<div>
									<ng-container *ngIf="idea.alreadyAdded == true; else greenButton">
										<button nbButton shape="round" size="small" status="danger">
											<nb-icon icon="minus-circle-outline"></nb-icon>
										</button>
									</ng-container>
									<ng-template #greenButton>
										<button nbButton shape="round" size="small" status="success"
											(click)="addSingleIdea(idea,$event)" [disabled]="isLoading">
											<nb-icon icon="plus-circle-outline"></nb-icon>
										</button>
									</ng-template>
								</div>
							</div>
						</nb-accordion-item-header>
						<nb-accordion-item-body>
							<textarea class="form-control" rows="5" [value]="idea.Cuerpo_Explicativo_de_la_Idea"
								(keyup)="updateGeneratedIdeas(i,$event)">{{idea.Cuerpo_Explicativo_de_la_Idea}}</textarea>
							<!-- {{idea.Cuerpo_Explicativo_de_la_Idea}} -->
						</nb-accordion-item-body>
					</nb-accordion-item>
				</nb-accordion>
			</div>
		</div>
	</nb-card-body>
	<nb-card-footer class="border-top-0">
		<div class="mb-2 row justify-content-center">
			<div class="col-12 d-flex justify-content-between">
				<ng-container *ngIf="AllIdeasAdded == false; else disabledAddAll">
					<button nbButton class="mr-1 btn-themecolor" (click)="openDesignIdeasModal()">
						{{ 'SALWIZAR.ADDALL' | translate }}
					</button>
				</ng-container>
				<ng-template #disabledAddAll>
					<button nbButton disabled>
						{{ 'SALWIZAR.ADDALL' | translate }}
					</button>
				</ng-template>
				<button nbButton status="danger" (click)="closeModal()">
					{{ 'FORM.FINISH' | translate }}
				</button>
			</div>
		</div>
	</nb-card-footer>
</nb-card>
