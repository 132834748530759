import { ErrorModel } from "./../users/login-response.model";

export class ResponseCourseTargets {
	error: ErrorModel;
	data: CourseTargetModel[];
	status: number;
}

export class ResponseCourseTargetDetail {
	error: ErrorModel;
	data: DetailCourseTargetModel;
	status: number;
}

export class CourseTargetModel {
	idCourseTarget: number;
	idCourse: number;
	idTarget: number;
	ordinal: number;
	ordinalExclusive: number;
	maxNumColumns: number; // DUDA
	target: DetailCourseTargetModel;
	creationDate: string;
	creationDateString: string;
	averageKnowledge: any;
	barColor: any;
	progress: any;
	isExclusive: boolean;
	translating: number;
	cours: any;
}

export class DetailCourseTargetModel {
	idTarget: number;
	tittle: string;
	description: string;
	orderNumber: number; // DUDA
	targetSwlevel: number;
	idSubject: number;
	idAuthor: number;
	labels: string;
	certifiedQuizzesToGo: number;
	backgroundImage: string;
	creationDate: number;
	creationDateString: string;
	editDate: string;
	editDateString: string;
	published: number;
	exclusive: boolean;
	viewType: number;
	targetImage: string;
	zoomX: number;
	zoomY: number;
	zoomRatio: number;

	constructor(
		title: string,
		description: string,
		idSubject: number,
		idAuthor: number,
		labels?: string,
		viewType?: number,
		idTarget?: number,
		backgroundImage?: string,
		certifiedQuizzesToGo?: number,
		exclusive?: boolean,
		targetImage?: string,
		zoomX?: number,
		zoomY?: number,
		zoomRatio?: number
	) {
		this.idTarget = idTarget || 0;
		this.tittle = title || "";
		this.description = description || "";
		this.idSubject = idSubject || 0;
		this.labels = labels || "";
		this.idAuthor = idAuthor || 0;
		this.creationDate = Date.now();
		this.backgroundImage = backgroundImage || null;
		this.exclusive = exclusive || false;
		this.viewType = viewType || 2;
		this.targetImage = targetImage || null;
		this.zoomX = zoomX || 0;
		this.zoomY = zoomY || 0;
		this.zoomRatio = zoomRatio || 0;
	}
}
