import { Component, OnInit } from '@angular/core';

import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
// Services
import { TranslateService } from '@ngx-translate/core';
import { IdiomaModel } from 'src/app/core/models/masters';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: "app-modal-youtube-iframe",
	templateUrl: "./modal-youtube-iframe.component.html",
	styleUrls: ["./modal-youtube-iframe.component.scss"],
})
export class ModalYoutubeIframeComponent implements OnInit {
	mensaje: string;
	optionalTitle: string;
	disableCancel: boolean;
	groupSelected: boolean = true;
	fromInforme: boolean = false;
	profile: any;
	traducirGrafo: boolean;
	form: UntypedFormGroup;
	lang: string;
	videoUrl: SafeResourceUrl = "";
	validationMessages = {
		lang: [],
	};

	private destroy$ = new Subject();
	languages$: Observable<IdiomaModel[]>;

	constructor(
		public translateService: TranslateService,
		private activeModal: NgbActiveModal,
		private sanitizer: DomSanitizer
	) {}

	ngOnInit() {

	}

	setVideoUrl(url: string) {
		const embedUrl = url.replace("watch?v=", "embed/");
		this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
	}

	closeModal(sendData?: any) {
		this.activeModal.close(false);
	}
}
