import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    OnDestroy,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { QuizModel } from 'src/app/core/models/quizzes';
import { QuizElementTypes } from 'src/app/core/models/quizzes/quiz-element-types.enum';
import { MastersService } from 'src/app/core/services/masters';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { DialogService } from 'src/app/core/services/shared/dialog.service';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';
import { QuizEditBodyComponent } from './components/quiz-edit-body/quiz-edit-body.component';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { LocalStorage } from 'src/app/core/utils';

@Component({
    selector: 'app-quiz-edit',
    templateUrl: './quiz-edit.component.html',
    styleUrls: ['./quiz-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class QuizEditComponent implements OnInit, OnDestroy {

    @ViewChild(QuizEditBodyComponent)
    private quizEditBodyComponent: QuizEditBodyComponent;

		private destroy$ = new Subject();

    @Input() courseId: number;
    @Input() graphId: number;
    @Input() currentGraph: any;
    @Input() quiz;
    @Input() elements;
    @Input() btnPublicarTutorial = false;
    @Input() fromLista: boolean = false; //si vienes del modo lista
	@Output() closeInModeList = new EventEmitter<boolean>();
    @Output() newQuiz = new EventEmitter<QuizModel>();

    private questionTypes: QuizElementTypes[] = [
        QuizElementTypes.Q_TEXTS,
        QuizElementTypes.Q_AUDIOS,
        QuizElementTypes.Q_PDFS,
        QuizElementTypes.Q_PICTURES,
        QuizElementTypes.Q_VIDEOS
    ];

    isLoading: boolean = false;

    saveClicked: Subject<any> = new Subject<any>();
    saveClicked$: Observable<any> = this.saveClicked.asObservable();

    tryClose: Subject<boolean> = new Subject<boolean>();
    tryClose$: Observable<boolean> = this.tryClose.asObservable();
    answered: any;
    quizQuestions:any[] = []
	fromIA: boolean = false;

	constructor(private activeModal: NgbActiveModal,
		private quizService: QuizzesService,
		private toaster: ToasterService,
		private translateService: TranslateService,
		private dialog: MatDialog,
		private dialogService: DialogService,
		private mastersService: MastersService,
		private st: SigmaToolbarsService,
		private localStorage: LocalStorage,
	) { }

	ngOnDestroy(): void {
		this.st.changeUpdateGraph(true)
		this.destroy$.next(true);
	}

	ngOnInit() {
        console.log(this.quiz);
		this.quizQuestions = this.elements.filter( e => this.questionTypes.includes(e.elementType) )
		if(localStorage.getItem("lastQuizTypeSaved") && this.quiz.quizType == 0){
			this.quiz.quizType = JSON.parse(this.localStorage.getItem("lastQuizTypeSaved"));
		}
	}

	changeFromIA(fromIA){
		this.fromIA = fromIA;
	}

	onQuizTypeChanged(type) {

		//Si hay opciones de respuesta se muestra el modal
		if (this.quizEditBodyComponent.options.length > 0 && type != 4 && !this.fromIA) {
			const dialogRef = this.dialogService.openAcceptDialog(false, undefined, this.translateService.instant('QUIZZES.CAMBIARTIPO'));
			dialogRef.afterClosed().subscribe(result => {
				if (result) {//En caso de que se confirme se eliminan todas las opciones y se cambia el tipo de quiz
					this.changueQuizType(type);
					this.quizEditBodyComponent.onDeleteOptions()
				}
			});
		} else {
			this.changueQuizType(type);
		}

	}
    changueQuizType(type) {
			this.quiz.quizType = type;
			this.quizService.createQuiz(this.courseId, this.graphId, this.quiz).pipe(takeUntil(this.destroy$), take(1)).subscribe(
					res => {
							//this.quiz = { ...res.data }
							this.quizService.setCurrentQuiz(res.data);
							//this.quiz = res.data
							this.toaster.success(this.translateService.instant('QUIZFORMCOMPONENT.SAVED'));
					},
					err => {
							console.error(err);
							this.toaster.error(this.translateService.instant('QUIZFORMCOMPONENT.ERROR'));
					}
			);
    }

    onQuizOrdinalTypeChanged(type) {
        const title1 = "¿Para qué aprender esto? ¿Qué utilidad tiene?";
        const title2 = "¿Qué has aprendido?";
        const inst = "Grábate explicándolo en video";
        if(type == 0){
            //Quiz Ordinario
            this.quiz.quizTittle = this.quiz.quizTittle === title1 || this.quiz.quizTittle === title2 ? " " : this.quiz.quizTittle;
            this.quiz.quizInstructions = this.quiz.quizInstructions === inst ? " " : this.quiz.quizInstructions;
        } else if(type == 1){
            //Quiz Motivadora
            this.quiz.quizTittle = title1;
            this.quiz.quizInstructions = inst;
        } else if(type == 2){
            //Quiz Recuerdo Activo
            this.quiz.quizTittle = title2;
            this.quiz.quizInstructions = inst;
        }
        this.quizService.createQuiz(this.courseId, this.graphId, this.quiz, type).pipe(takeUntil(this.destroy$), take(1)).subscribe(
                res => {
                        this.quiz = { ...res.data }
                        this.quizService.setCurrentQuiz(res.data);
                        this.quiz = res.data
                        this.toaster.success(this.translateService.instant('QUIZFORMCOMPONENT.SAVED'));
                },
                err => {
                        console.error(err);
                        this.toaster.error(this.translateService.instant('QUIZFORMCOMPONENT.ERROR'));
                }
        );
	}

    closeModal(sendData?: any) {
        //guardamos el tipo de quiz que se guardo
        localStorage.setItem("lastQuizTypeSaved", this.quiz.quizType);
        //en el modo lista no cerramos modal, solo lo ocultamos
        if(!this.fromLista){
            this.activeModal.close(sendData);
        } else {
            this.closeInModeList.emit(true);
        }
    }

    save() {
        this.saveClicked.next(true);
    }

    onQuizEdited(quiz) {
        this.quiz = { ...quiz };
        this.quizService.setCurrentQuiz(this.quiz);
    }

    onQuestionsChange(values){
        this.quizQuestions = values
    }

    onPublishQuiz(currentValue:boolean):void{
        //Open modal message alert to confirm the selection
        let okMessage:string = currentValue ? this.translateService.instant('GENERAL.OKPUBLISH') : this.translateService.instant('GENERAL.OKUNPUBLISH')
        let errorMessage:string = currentValue ? this.translateService.instant('GENERAL.KOPUBLISH') : this.translateService.instant('GENERAL.KOUNPUBLISH')

        this.quiz = {...this.quiz, published: currentValue ? Date.now(): null}
        this.quizService.currentQuiz.next(this.quiz);

        this.mastersService.setPublishType('quiz',Number(this.quiz.idOriginal),currentValue).subscribe(result => {
            if(result.data)
                this.toaster.success(okMessage)
            else
                this.toaster.error(this.translateService.instant('QUIZFORMCOMPONENT.KOPUBLISHCONTENT'))
        }, err => {
            this.toaster.error(errorMessage)
        })
    }

    onAnswered() {
        this.answered = true;
    }

    onClose() {
        if (this.answered) {
            //en el modo lista no cerramos modal, solo lo ocultamos
            if(!this.fromLista){
                this.activeModal.close();
            } else {
                this.closeInModeList.emit(true);
            }
            return;
        }

        this.tryClose.next(true);

        const dialogRef = this.dialogService.openAcceptDialog(
			true,
			undefined,
			this.translateService.instant('QUIZZES.CLOSEQUESTION'),
			[],
			this.translateService.instant('QUIZZES.EXIT'),
			this.translateService.instant('QUIZZES.RESPONSE')
		);

        dialogRef.afterClosed().subscribe(result => {
            this.tryClose.next(result);

            if (result) {
                this.tryClose.next(false);
                //en el modo lista no cerramos modal, solo lo ocultamos
                if(!this.fromLista){
                    this.activeModal.close();
                } else {
                    this.closeInModeList.emit(true);
                }
            }
        });
    }
}
