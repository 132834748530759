import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { QuizElementTypes } from 'src/app/core/models/quizzes/quiz-element-types.enum';

enum lang{
    SPANISH = 1,
    ENGLISH = 2
}

@Component({
    selector: 'app-quiz-play-multiple-body-data',
    templateUrl: './quiz-play-multiple-body-data.component.html',
    styleUrls: ['./quiz-play-multiple-body-data.component.scss'],
    encapsulation : ViewEncapsulation.None
})
export class QuizPlayMultipleBodyDataComponent implements OnInit {
    @Input() questions: any[];

    flagL1:string = "../../../../../../../assets/images/flags/es.png"
    flagL2:string = "../../../../../../../assets/images/flags/en.png"
    audio:string = "../../../../../../../assets/images/icons/volume_up.svg"

    public dataForm: UntypedFormGroup;

		isAudioPlaying = false;

    constructor(private fb: UntypedFormBuilder) {
        this.dataForm = this.fb.group({
            questions: this.fb.array([])
        });
    }

    ngOnInit() {
        this.questionsArray.clear();
        this.questions.sort((a,b) => a.language - b.language) //Order by language - 1st -> Spanish / 2nd -> English
        this.questions.forEach(q => {
            if(q.data) this.addQuestion(q)
        });
    }

    audioPlay(i){
        let audioPlayer: HTMLElement | any = document.getElementById(i)
        audioPlayer.play();
    }

		playAudio(i: number) {
			const audioPlayer: HTMLAudioElement | null = document.getElementById(`${i}`) as HTMLAudioElement;
			if (audioPlayer) {
				this.isAudioPlaying = true;
				audioPlayer.play();

				// Cuando se complete la reproducción, cambiar isAudioPlaying a false
				audioPlayer.onended = () => {
					this.isAudioPlaying = false;
				};
			}
		}

		playAudioWithSpeed(i: number, speed: number) {
			const audioPlayer: HTMLAudioElement | null = document.getElementById(`${i}`) as HTMLAudioElement;
			if (audioPlayer) {
				audioPlayer.playbackRate = speed;
				this.playAudio(i);
			}
		}

    public get questionsArray(): UntypedFormArray {
        return this.dataForm.get('questions') as UntypedFormArray;
    }

    public get questionsArrayData(): UntypedFormControl[] {
        return this.questionsArray.controls.map(q => q.get('data') as UntypedFormControl);
    }

    public get questionsArrayType(): UntypedFormControl[] {
        return this.questionsArray.controls.map(q => q.get('type') as UntypedFormControl);
    }

    public get questionsArrayWidth(): UntypedFormControl[] {
        return this.questionsArray.controls.map(q => q.get('width') as UntypedFormControl);
    }

    public get questionsArrayBackground(): UntypedFormControl[] {
        return this.questionsArray.controls.map(q => q.get('background') as UntypedFormControl);
    }

    public get questionsArrayHeight(): UntypedFormControl[] {
        return this.questionsArray.controls.map(q => q.get('height') as UntypedFormControl);
    }

    public get questionsArrayLanguage(): UntypedFormControl[] {
        return this.questionsArray.controls.map(q => q.get('language') as UntypedFormControl);
    }

    public get elementTypes() {
        return QuizElementTypes;
    }

    private addQuestion(question: any): void {
        const newQuestion: UntypedFormGroup = this.createQuestion(question);
        this.questionsArray.push(newQuestion);
    }

    private createQuestion(question: any): UntypedFormGroup {
        return this.fb.group({
            id: { value: question.idQuizzesDataElements, disabled: true },
            data: { value: question.data, disabled: true },
            type: { value: question.elementType, disabled: true },
            order: { value: question.yPosition, disabled: true },
            width: question ? question.xSize : 0,
            height: question ? question.ySize : 0,
            background: question.background || '',
            language: question.language || lang.SPANISH
        });
    }
}
