<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="flex flex-column border-bottom-0">
		<div class="row">
			<div class="col-10 modal-title">
				<h4>{{ curso?.courseTittle }}</h4>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}"
			nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body class="p-0">
		<div *ngIf="curso">
			<app-loading [isVisible]="cargando"></app-loading>

			<nb-card class="no-edge-card background_themecolor mb-0">
				<nb-card-body>
					<form [formGroup]="formulario" #thisForm="ngForm" class="row d-flex justify-content-center p-2">
						<div class="col-12 col-lg-3">
							<nb-card class="project-info pointer background_themecolor no-edge-card mb-0 align-items-center">
								<div class="imagecontainer">
									<app-imgupload [isShowDelete]="true" formControlName="image" *ngIf="networkSpeed >= 1"></app-imgupload>
									<div class="text-center avatar-detail-course mb-2 d-flex align-content-center"
									[ngStyle]="{'background-image': 'url(../../../../assets/images/no-image.png)'}" *ngIf="networkSpeed < 1"></div>
								</div>
							</nb-card>
						</div>
						<div class="col-12 col-lg-7 my-4 my-lg-0">
							<nb-card class="mb-0 background_themecolor pointer no-edge-card ">
								<nb-card-header style="padding: 0;">
									<div class="row d-flex justify-content-end icons-positions">
										<nb-icon class="icon-color-blue pointer text-right m-1 float-right z-index-999" icon="info-outline"
											[options]="{ animation: { type: 'pulse' } }" (click)="openInformationCourse()"></nb-icon>
									</div>
									<div class="form-group">
										<label for="exampleFormControlInput1">{{ 'EDITARCURSO.TITLE' | translate }}</label>
										<input nbInput fullWidth formControlName="title" type="text" id="exampleFormControlInput1"
											placeholder="Titulo" maxlength="100">
									</div>
								</nb-card-header>

								<nb-card-body style="padding: 0;">
									<div class="form-group">
										<label for="exampleFormControlTextarea1">{{ 'EDITARCURSO.DESCRIPCION' | translate }}</label>
										<textarea nbInput fullWidth formControlName="description" id="exampleFormControlTextarea1" rows="3"
											maxlength="500"></textarea>
									</div>
								</nb-card-body>
							</nb-card>
						</div>
						<div class="col-12 col-lg-2 my-4 my-lg-0">
							<nb-card class="mb-0 background_themecolor pointer no-edge-card">

								<h6 class="text-center mt-2">{{'MODALINFORMATIONCOURSE.AUTHOR' | translate}}:</h6>
								<div class="w-100 d-flex justify-content-center">
									<div class="text-center avatar-detail-course mb-2 d-flex align-content-center"
										[ngStyle]="{'background-image': getUserAvatar()}" *ngIf="networkSpeed >= 1"></div>
									<div class="text-center avatar-detail-course mb-2 d-flex align-content-center"
										[ngStyle]="{'background-image': 'url(../../../../assets/images/no-image.png)'}" *ngIf="networkSpeed < 1"></div>
								</div>
								<h6 class="text-center">{{cursoDatos.user.name}} {{cursoDatos.user.surname}}</h6>
								<div class="w-100 d-flex justify-content-center">
									<button nbButton class="btn-themecolor" (click)="listadoEditores()">{{'EDITORS.EDITORS' | translate}}</button>
								</div>
							</nb-card>
						</div>
						<div class="row mt-4 cont-opt justify-content-center">
							<div class="flex cont-opt justify-content-center flex-lg-row flex-column">
								<nb-toggle labelPosition="start" *ngIf="id!='nuevo' && loginService.esAutor()"
									formControlName="isVisible" (click)="makeVisibleCourse()">{{ formulario.get('isVisible').value
									? ('EDITARCURSO.VISIBLE' |
									translate) : ('EDITARCURSO.NOVISIBLE' | translate) }}</nb-toggle>

								<nb-toggle labelPosition="start" *ngIf="id!='nuevo' && loginService.esAutor()"
									formControlName="courseType" (click)="changeCourseType()">{{
									formulario.get('courseType').value ? ('EDITARCURSO.PUBLIC' |
									translate) : ('EDITARCURSO.PRIVATE' | translate) }}</nb-toggle>

								<!-- <ng-select name="idCountry" placeholder="pais" formControlName="idCountry" (selectedChange)="searchLevels($event)"
										>
										<ng-option  *ngFor="let country of countriesList" value="{{ country.idCountry }}">{{
											country.country_es }}</ng-option>
								 </ng-select> -->

							</div>
							<div class="flex cont-opt flex-lg-row flex-column">
								<nb-select name="idCountry" placeholder="{{'FILTER.COUNTRY' | translate}}" formControlName="idCountry"
									(selectedChange)="searchLevels($event)">
									<nb-option *ngFor="let country of countriesList" value="{{ country.idCountry }}">{{
										country.country_es }}
									</nb-option>
								</nb-select>

								<nb-select name="idCountryLevel" placeholder="{{'FILTER.LEVEL' | translate}}" formControlName="idCountryLevel">
									<nb-option *ngFor="let level of levelsOfCountry" [value]="level.idCountryLevel">{{ level.countryLevel
										}}</nb-option>
									<nb-option *ngIf="notCountryLevelsForThisCountry">{{'MODALINFORMATIONCOURSE.NOTLEVELSFORCOUNTRY' |
										translate}}</nb-option>
								</nb-select>

								<nb-select name="lang1" placeholder="{{'FILTER.LANGUAGE' | translate}}" formControlName="lang1">
									<nb-option *ngFor="let lang of langList" [value]="lang.idLang">{{ lang.nombre }}
									</nb-option>
								</nb-select>

								<nb-select name="project_type" placeholder="{{'FILTER.PROJECTTYPE' | translate}}" formControlName="project_type">
									<nb-option *ngFor="let projectType of projectsTypes" [value]="projectType.id">{{
										projectType.descripcion }}
									</nb-option>
								</nb-select>
							</div>
						</div>
					</form>
				</nb-card-body>
			</nb-card>

            <!--===== LISTA IDEOGRAFOS =====-->
            <nb-card>
                <nb-card-header>
                    <div class="d-flex align-items-center gap-4">
                        <div class="modal-title">
                            <h2>{{ 'EDITARCURSO.GRAFOS' | translate }}</h2>
                        </div>
                        <nb-actions size="small">
                            <!-- Boton para cambiar al modo grilla -->
                            <nb-action *ngIf="modeList" icon="grid-outline" nbTooltip="{{ 'CURSOS.VERMOSAICO' | translate }}"
                            nbTooltipPlacement="top" (click)="changeView(false)">
                            </nb-action>
                            <!-- Boton para cambiar al modo lista -->
                            <nb-action *ngIf="!modeList" icon="list-outline" nbTooltip="{{ 'CURSOS.VERARBOL' | translate }}"
                            nbTooltipPlacement="top" (click)="changeView(true)">
                            </nb-action>
							<!-- Boton para crear un nuevo grafo -->
							<nb-action *ngIf="id!='nuevo'" icon="plus-circle-outline" class="p-1 pl-3" nbTooltip="{{'EDITARCURSO.NUEVOGRAFO' | translate}}"
							nbTooltipPlacement="top" (click)="nuevoMapa(false)">
							</nb-action>
                        </nb-actions>
                    </div>
                </nb-card-header>
                <nb-card-body>
                    <!-- VISTA MODO GRILLA -->
                    <div *ngIf="!modeList" class="row background_white d-flex justify-content-center py-4 px-2 min-h-100">
                        <div class="col-12">
                            <div *ngIf="dataList.length == 0"></div>
                            <div *ngIf="dataList.length > 0">
                                <div class="d-flex flex-wrap gap-5 justify-content-center">
                                    <div *ngFor="let grafo of dataList">
                                        <nb-card size="small" class="pointer" *ngIf="grafo.type == 0"
                                            [ngClass]="{'project-info-1': curso.project_type == 1, 'project-info-2': curso.project_type == 2, 'project-info-3': curso.project_type == 3, 'project-info-4': curso.project_type == 4, 'project-info-5': curso.project_type == 5}">
                                            <nb-card-header class="pb-2 pt-0 pl-0 pr-0 border-bottom-0" (click)="verNodosMapa(grafo.id)">
                                                <!--ocultamos la imagen si la conexion es lenta-->
                                                <div class="img-container">
                                                    <img [src]="grafo.image | imagen:'mapas'" (error)="updateUrl($event)" class="img-fluid" *ngIf="networkSpeed >= 1">
                                                    <img [src]="'../../assets/images/no-image.png'" class="img-fluid" *ngIf="networkSpeed < 1">
                                                </div>
                                            </nb-card-header>
                                            <nb-card-body class="p-2" (click)="verNodosMapa(grafo.id)">
                                                <p class="paragraph courseTittle">{{ grafo.title | truncate:[50, '...'] }}</p>
                                            </nb-card-body>
                                            <nb-card-footer class="flex flex-row justify-content-end pt-0 pl-1 pr-1 pb-1 border-top-0">
                                                <div class="icon-option icon-settings pointer" nbTooltip="{{ 'GRUPOS.AJUSTES' | translate }}"
                                                (click)="verDetalleMapa(grafo.id, grafo.type)"></div>
                                            </nb-card-footer>
                                        </nb-card>
                                        <!-- *** grafos con traduccion en curso *** -->
                                        <div [ngClass]="{'nb-card-overlay1': curso.project_type == 1, 'nb-card-overlay2': curso.project_type == 2}">
                                            <nb-card size="small" class="pointer" *ngIf="grafo.type == 1"
                                                [ngClass]="{'project-info-1': curso.project_type == 1, 'project-info-2': curso.project_type == 2, 'project-info-3': curso.project_type == 3, 'project-info-4': curso.project_type == 4, 'project-info-5': curso.project_type == 5}">
                                                <nb-card-header class="pb-2 pt-0 pl-0 pr-0 border-bottom-0">
                                                    <!--ocultamos la imagen si la conexion es lenta-->
                                                    <div class="img-container">
                                                        <img [src]="grafo.image | imagen:'mapas'" (error)="updateUrl($event)" class="img-fluid" *ngIf="networkSpeed >= 1">
                                                        <img [src]="'../../assets/images/no-image.png'" class="img-fluid" *ngIf="networkSpeed < 1">
                                                    </div>
                                                </nb-card-header>
                                                <nb-card-body class="p-2">
                                                    <p class="paragraph courseTittle">{{ grafo.title | truncate:[50, '...'] }}</p>
                                                </nb-card-body>
                                            </nb-card>
                                            <div class="overlay-content" *ngIf="grafo.type == 1">
                                                <h6 class="icon-text">{{ 'GLOBALS.TRANSLATEINPROCESS' | translate }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- VISTA MODO LISTA -->
                    <div *ngIf="modeList" class="row min-h-100 gap-4">
                        <!--LISTA-->
                        <div class="col-12 col-xl-4 container-tree background_white">
                            <!--Primer nivel (IDEOGRAFOS)-->
                            <ul *ngIf="dataList.length > 0" class="style-list py-1 px-4" style="list-style: none;">
                                <li *ngFor="let target of dataList; let i = index">
                                    <div class="d-flex pointer py-1 px-3 gap-2">
                                        <div (click)="viewNodes(i)">
                                            <nb-icon icon="chevron-right-outline" status="basic"
                                                *ngIf="!target.childrenVisible && target.children.length > 0"></nb-icon>
                                            <nb-icon icon="chevron-down-outline" status="basic"
                                                *ngIf="target.childrenVisible && target.children.length > 0"></nb-icon>
                                            <mat-icon svgIcon="ideografo" *ngIf="curso.project_type == 1"></mat-icon>
                                            <mat-icon class="custom-icon_green2" svgIcon="ideografo" *ngIf="curso.project_type == 2"></mat-icon>
                                            <mat-icon class="custom-icon_red2" svgIcon="ideografo" *ngIf="curso.project_type == 3"></mat-icon>
                                            <mat-icon class="custom-icon_yellow2" svgIcon="ideografo" *ngIf="curso.project_type == 4"></mat-icon>
                                            <mat-icon class="custom-icon_violet2" svgIcon="ideografo" *ngIf="curso.project_type == 5"></mat-icon>
                                            <span> {{ target.title }} </span>
                                        </div>
                                    </div>
                                    <!--Segundo nivel (NODOS Y QUIZZES SUELTOS)-->
                                    <ul *ngIf="target.children.length > 0" class="py-1 px-4" style="list-style: none;" [ngStyle]="{'display': target.childrenVisible ? 'block' : 'none'}">
                                        <li *ngFor="let nodos of target.children; let j = index">
                                            <div class="d-flex pointer py-1 px-3 gap-2" [class.selected]="selectedLabel === nodos">
                                                <div (click)="viewQuizzes(i, j)">
                                                    <nb-icon icon="chevron-right-outline" status="basic"
                                                        *ngIf="!nodos.childrenVisible && nodos.children.length > 0 && nodos.type == 0"></nb-icon>
                                                    <nb-icon icon="chevron-down-outline" status="basic"
                                                        *ngIf="nodos.childrenVisible && nodos.children.length > 0 && nodos.type == 0"></nb-icon>
                                                    <mat-icon svgIcon="ideasvg" *ngIf="nodos.type == 0"></mat-icon>
                                                    <mat-icon svgIcon="act" *ngIf="nodos.type == 1"></mat-icon>
                                                    <mat-icon svgIcon="QuizMulti" *ngIf="nodos.type == 2"></mat-icon>
                                                    <span> {{ nodos.title }} </span>
                                                </div>
                                                <div (click)="viewData(i, j, nodos)">
                                                    <nb-icon icon="eye-outline" status="basic" *ngIf="selectedLabel === nodos"></nb-icon>
                                                    <nb-icon icon="eye-off-2-outline" status="basic" *ngIf="selectedLabel !== nodos"></nb-icon>
                                                </div>
                                            </div>
                                            <!--Tercer nivel (QUIZZES)-->
                                            <ul *ngIf="nodos.children.length > 0" class="py-1 px-8" style="list-style: none;" [ngStyle]="{'display': nodos.childrenVisible ? 'block' : 'none'}">
                                                <li *ngFor="let quizzes of nodos.children">
                                                    <div class="d-flex pointer py-1 px-3 gap-2" [class.selected]="selectedLabel === quizzes">
                                                        <mat-icon svgIcon="act" *ngIf="quizzes.type == 1"></mat-icon>
                                                        <mat-icon svgIcon="QuizMulti" *ngIf="quizzes.type == 2"></mat-icon>
                                                        <div>
                                                            {{ quizzes.title }}
                                                        </div>
                                                        <div (click)="viewData(i, j, quizzes)">
                                                            <nb-icon icon="eye-outline" status="basic" *ngIf="selectedLabel === quizzes"></nb-icon>
                                                            <nb-icon icon="eye-off-2-outline" status="basic" *ngIf="selectedLabel !== quizzes"></nb-icon>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <!--DETALLES-->
                        <div class="col py-4 pl-xl-4 pr-xl-0 container-tree background_white">
                            <!--editar nodo-->
                            <div *ngIf="idSelected == 1">
                                <app-node-detail-info
                                [node]="node" [idCurso]="idCurso" [idMapa]="idMapa" [canEdit]="canEdit" [course]="course" [isNewNode]="isNewNode" [fromLista]="true"
                                (closeInModeList)="hideData($event)"
                                ></app-node-detail-info>
                            </div>
                            <!--editar quiz simple-->
                            <div *ngIf="idSelected == 2">
                                <app-quiz-edit
                                [quiz]="quiz" [elements]="elements" [courseId]="idCurso" [graphId]="idMapa" [fromLista]="true"
                                (closeInModeList)="hideData($event)"
                                ></app-quiz-edit>
                            </div>
                            <!--editar quiz multiple-->
                            <div *ngIf="idSelected == 3">
                                <app-quiz-multiple-edit
                                [quiz]="quiz" [elements]="elements" [courseId]="idCurso" [graphId]="idMapa" [quizFiles]="quizFiles" [quizzes]="quizzes" [fromLista]="true"
                                (closeInModeList)="hideData($event)"
                                ></app-quiz-multiple-edit>
                            </div>
                        </div>
                    </div>
                </nb-card-body>
            </nb-card>
		</div>
	</nb-card-body>
</nb-card>
