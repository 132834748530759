<div class="modal-body fondoEditarCurso" align="center">
    <table class="table fondoBlanco">
        <thead>
            <tr>
                <th scope="col" class="text-center">
                    <span
                        class="textoFontSize07 colorTextoActivado font-weight-bold">{{ 'TASK.REMITENTE' | translate }}</span>
                </th>
                <th scope="col" class="text-center">
                    <span
                        class="textoFontSize07 colorTextoActivado font-weight-bold">{{ 'TASK.TEMA' | translate }}</span>
                </th>
                <th scope="col" class="text-center">
                    <span
                        class="textoFontSize07 colorTextoActivado font-weight-bold">{{ 'TASK.EXPLICACION' | translate }}/{{ 'TASK.PREGUNTA' | translate }}</span>
                </th>
                <th scope="col" class="text-center">
                    <span
                        class="textoFontSize07 colorTextoActivado font-weight-bold">{{ 'TASK.GRAFO' | translate }}</span>
                </th>
                <th scope="col" class="text-center">
                    <span
                        class="textoFontSize07 colorTextoActivado font-weight-bold">{{ 'TASK.CURSO' | translate }}</span>
                </th>
                <th scope="col" class="text-center">
                    <span
                        class="textoFontSize07 colorTextoActivado font-weight-bold">{{ 'TASK.FECHALIMITE' | translate }}</span>
                </th>
                <th scope="col" class="text-center">
                    <span
                        class="textoFontSize07 colorTextoActivado font-weight-bold">{{ 'TASK.OBSEVACIONES' | translate }}</span>
                </th>
                <th scope="col" class="text-center">
                    <span
                        class="textoFontSize07 colorTextoActivado font-weight-bold">{{ 'TASK.ESTADO' | translate }}</span>
                </th>
                <th scope="col" class="text-center">
                 <button type="button" class="btn border border-secondary" data-toggle="button" [ngStyle]="{'color':'rgb(33, 76, 152)'}"
                         [disabled]="tasksCompetition.length==0" (click)="competition(option, tasksCompetition)">
                        <mat-icon [ngStyle]= "{'cursor': (tasksCompetition.length>0) ? 'pointer':''}" svgIcon="couple" ngbTooltip="{{'TASK.TOCOMPETE'|translate}}"></mat-icon>
                    </button>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let task of tasks">
                <td><img src={{task.img}} ngbTooltip={{task.nombre}} /></td>
                <td>
                    <div class="text-center"
                        [ngStyle]="{'border-style': 'solid','border-color':getColor(task.estado)}">
                        <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{task.tema}}</span>
                    </div>
                </td>
                <td><img src={{task.imgExplicacion}} ngbTooltip={{task.explicacion}} [ngStyle]="{'width':'100px','height': '60px'}"/></td>
                <td><img src={{task.imgGrafo}} ngbTooltip={{task.nomGrafo}} [ngStyle]="{'width':'100px','height': '60px'}"/></td>
                <td><img src={{task.imgCurso}} ngbTooltip={{task.nomCurso}} [ngStyle]="{'width':'100px','height': '60px'}"/></td>
                <td>
                    <div class="text-center">
                        <span [ngStyle]="{'color':getColor(task.estado)}"
                            class="textoFontSize07 font-weight-bold">{{task.fechaLimite}}</span>
                    </div>
                    <!--mat-form-field appearance="fill">
                        <span [ngStyle]="{'color':getColor(remitente.estado)}"
                        class="textoFontSize07 font-weight-bold">{{remitente.fechaLimite}}</span>
                        <input [ngStyle]="{'color':getColor(remitente.estado)}" matInput [matDatepicker]="picker"
                               (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event, remitente)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field-->
                </td>
                <td><span class="textoFontSize07 colorTextoActivado font-weight-bold">{{task.observacion}}</span>
                </td>
                <td>
                    <div class="estado d-flex justify-content-center" [ngStyle]="{'background-color':getColor(task.estado)}">
                            <span class="textoFontSize1 colorTextoActivado font-weight-bold mt-3">{{task.estado}}</span>
                    </div>
                </td>
                 <td>
                    <div class="estado d-flex justify-content-center pointer">
                         <mat-checkbox (change)="checkTask(task)"></mat-checkbox>
                    </div>
                 </td>
            </tr>
        </tbody>
    </table>
</div>
