import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbTooltipModule } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { MastersService } from 'src/app/core/services/masters';

@Component({
	selector: "app-modal-tutorial-manager",
	standalone: true,
	imports: [CommonModule, NbCardModule, NbIconModule, NbTooltipModule, TranslateModule, FormsModule, NbInputModule, NbButtonModule],
	templateUrl: "./modal-tutorial-manager.component.html",
	styleUrls: ["./modal-tutorial-manager.component.scss"],
})

export class ModalTutorialManagerComponent implements OnInit{

	contextList: any[] = [];
	videoList: any[] = [];
	selectedContext: any = null;
	step: number = 1;
	videosDisponibles: any[] = [];
	videoTitle: string = '';
	videoUrl: string = '';
	isValidUrl: boolean = false;
	isValidTitle: boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		public translateService: TranslateService,
		private modalService: NgbModal,
		private groupService: GruposService,
		private toaster: ToasterService,
		private masterService: MastersService,
	) {}

	ngOnInit() {
		this.loadContextList();
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	backToStep1(){
		this.step = 1;
	}

	loadContextList(){
		this.masterService.getContextListForTutorial().subscribe( res => {
			if(res.data){
				this.contextList = res.data;
				console.log(this.contextList);
			}
		})
	}

	loadVideoList(context: any){
		this.selectedContext = context;
		this.videoList = context.videoList;
	}

	getVideoId(url: string): string {
		const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([^?&]+)/;
		const match = url.match(regExp);
		return match && match[1] ? match[1] : '';
	}

	verVideosDisponibles(context: any){
		this.masterService.getVideosAvailablesForContext(context.idTutorialContext).subscribe( res => {
			if(res.data){
				this.videosDisponibles = res.data;
				this.step = 2;
				console.log(this.videosDisponibles);
			}
		})
	}

	onDeleteVideo(video){
		this.masterService.deleteTutorialContextVideo(this.selectedContext.idTutorialContext, video.idTutorialVideo).subscribe( res => {
			if(res.data > 0){
				this.loadContextList();
			}
		})
	}

	onAddVideo(video){
		this.masterService.createTutorialContextVideo(this.selectedContext.idTutorialContext, video.idTutorialVideo).subscribe( res => {
			if(res.data){
				this.verVideosDisponibles(this.selectedContext);
				this.loadContextList();
			}
		})
	}

	goToStep3(){
		this.step = 3;
	}

	backToStep2(){
		this.step = 2;
	}

	validateTitle() {
		if(this.videoTitle && this.videoTitle.length > 5){
			this.isValidTitle = true;
		} else {
			this.isValidTitle = false;
		}
	}	

	validateUrl() {
		const cleanedUrl = this.cleanUrl(this.videoUrl);
		const youtubeRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?)|(shorts\/))\??(v=)?([^#\&\?]*).*/;
		const match = cleanedUrl.match(youtubeRegex);
		this.isValidUrl = youtubeRegex.test(this.videoUrl);
	}
	cleanUrl(url: string): string {
		return url.replace(/app=desktop&/, '');
	}

	saveUrl() {
		console.log(this.isValidUrl);
		console.log(this.isValidTitle);
		if (this.isValidUrl && this.isValidTitle) {
		  console.log('Título:', this.videoTitle);
		  console.log('URL válida:', this.videoUrl);
		}
	}
	  
}
