import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModalTextEditService  {
    public textoEdit = new Subject();
    public checksVisibility = new Subject();
    tmpObject: string = ''

    /**
     *
     */
    constructor() {
    }
    public emitTextActive(textEdit: string) {
        this.textoEdit.next(textEdit)
    }

    public getText(): string {
        return this.tmpObject;
    }

    public setText(tmpObject: string) {
        this.tmpObject = tmpObject;
    }

  

}

