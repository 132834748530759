<nb-card class="mb-0">
	<nb-card-header class="flex">
		<div class="col-8 modal-title">
			<h2>
				{{ 'INFORMES.INFORME' | translate }}{{ titulo }}
		</h2>
		</div>
		<!-- <div class="col-2 download-excel">
			<span *ngIf="downloadIcon" (click)="downloadExcel()" class="badge badge-pill col-12"><mat-icon svgIcon="icon-excel"></mat-icon></span>
			<span *ngIf="downloadIcon" class="mt-2">{{'INFORMES.EXPORTEXCEL' | translate}}</span>
	</div> -->
		<div class="col-4">
			<nb-icon class="icon-color-blue closebtn pointer text-right m-1 float-right" icon="close-circle-outline"
				[options]="{ animation: { type: 'pulse' } }"
				(click)="closeModal('close')"></nb-icon><!-- <mat-icon class="icon-color-blue closebtn pointer text-right m-1 float-right" ngbTooltip="{{ 'GLOBALS.CLOSE' | translate }}" (click)="closeModal('')">close</mat-icon> -->
		</div>
	</nb-card-header>
	<nb-card-body>
		<nb-card class="no-edge-card">
			<nb-card-body>
				<app-loading [isVisible]="loading"></app-loading>
				<div class="">
					<div class="modal-body" *ngIf="!isLoading">
						<div class="container">
							<div class="row flex justify-content-center align-items-start">
									<!-- 2.	Listado de curso, con sus grafos y nodos en modo árbol -->
								<div class="col-3">

									<label>{{'SOCKETQUIZPREVIEW.CHOOSESELECTION' | translate}}</label>
									<div class="mb-4 d-flex filter-buttons">
										<button nbButton status="basic" type="button" class="d-flex align-items-center mr-2 " (click)="getMasterDataCourses()">
											<mat-icon svgIcon="graph" [ngbTooltip]="'SOCKETQUIZPREVIEW.ONLYGRAPHS' | translate"></mat-icon>
										</button>
										<button nbButton status="basic" type="button" class="d-flex align-items-center " (click)="getChallenges()">
											<mat-icon svgIcon="desafios_icon" [ngbTooltip]="'SOCKETQUIZPREVIEW.EXCLUSIVEGRAPHS' | translate"></mat-icon>
										</button>

										<!-- <button nbButton class="btn-themecolor" class="ml-2" (click)="generateGraphsReport()" *ngIf="showReportButton">{{ reportGenerated == true ? reportTextButtonRefresh : reportTextButton }}</button> -->
									</div>

									<!-- MODO LISTA CURSOS -->
									<ng-container *ngIf="selectedFilterMode === filterModeList.LIST_MODE">

										<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
											<mat-tree-node class="flex align-items-center " *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="20" >
												<button mat-icon-button matTreeNodeToggle
																[attr.aria-label]="'Toggle ' + node.name" (click)="resourse(node)">
														<mat-icon class="mat-icon-rtl-mirror">
														{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
														</mat-icon >
														<!-- <mat-icon svgIcon="{{}}"></mat-icon> -->
														<img src="../../../../assets/images/icons/{{node.icon == 'curso' ? 'ProyectoComoMuchosGrafos' : node.icon}}.svg" alt="" height="24" width="24">
												</button>
												<p class="ml-3 mt-3">{{node.name}}</p><span class="badge ml-1 badge-danger">{{node.pendingQuizzes == 0? '':node.pendingQuizzes}}</span>
												<!-- <nb-checkbox class="ml-2 mt-1" *ngIf="node.type === 'GRAFO'" (checkedChange)="toggleChallenge($event, node, selectedType.GRAPH)"></nb-checkbox> -->
											</mat-tree-node>
											<mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [ngClass]="{'selected': selectedNode === node}"
												(click)="node.isSelected = !node.isSelected">
												<button mat-icon-button (click)="resourse(node)">
													<mat-icon svgIcon="ideasvg"></mat-icon>
													{{node.name}}<span class="badge ml-1 badge-danger">{{node.pendingQuizzes == 0? '':node.pendingQuizzes }}</span>
												</button>
											</mat-tree-node>
										</mat-tree>
									</ng-container>

									<ng-container *ngIf="selectedFilterMode === filterModeList.CHALLENGE_MODE">
										<div class="challenge-list">
											<div *ngFor="let challenge of challenges" class="d-flex">
												<nb-checkbox (checkedChange)="toggleChallenge($event, challenge, selectedType.CHALLENGE)">
													<span>{{challenge.nameSession}}</span>
												</nb-checkbox>
											</div>
										</div>
									</ng-container>
									<h5 class="text-title font-weight-bold" *ngIf="dataSource.data.length === 0 && challenges.length === 0">
											{{ 'INFORMES.NOHAYCONTENIDO' | translate }}
									</h5>
								</div>

								<!-- 3.	Tabla con los datos de los quizzes -->
								<div class="col-9 table-datod">
										<div class="w-100">
												<h6 class="font-weight-bold">
														{{ 'INFORMES.TABLA_DATOS_QUIZES' | translate }}
												</h6>
										</div>
										<app-loading [isVisible]="loading"></app-loading>
								<div class="w-100 container-data-table" >
											<table class="table  table-responsive ">
												<thead >
													<tr  class="bg-light header-table mr-2" style="line-height: 5rem;" >


														<th  scope="col" *ngFor="let head of headTable; let i = index" >

															<div class="adjust-th-center">
																<div class="data-text same-width">
																	<div class="d-flex justify-content-center align-items-center" *ngIf="i === 0" >
																		<img src="../../../../../assets/images/icons/ActividadCuadrado.svg" width="24" height="24"><span class="separador text-primary">/</span> <mat-icon svgIcon="AnadirActividadesMultiples" ></mat-icon>
																	</div>
																	<ng-template #FullNameTooltip>
																		{{head.name}} <br>
																		{{head.surname}} <br>
																	</ng-template>
																	<div *ngIf="i !== 0" class=" mt-3 d-flex justify-content-center align-items-center " [ngbTooltip]="FullNameTooltip" placement="bottom">
																		<div class="avatar text-center  mb-2" [ngStyle]="{'background-image': getUserAvatarInfo(head.image)}"></div>
																	</div>
																	<div >
																		<p class="text-center mb-0">{{head.name}}</p>
																		<!-- <p class="text-center">{{head.surname}}</p> -->
																	</div>
																</div>
															</div>
														</th>
													</tr>
												</thead>
												<tbody *ngIf="bodyTable!= undefined">
													<tr *ngFor="let filas of bodyTable; let i = index" >
														<th *ngFor="let data of filas.row" scope="row"
														[ngStyle]="{'color':'black','background-color' : data.color !== null && i ? data.color: '#ffff','text-align':'center', 'font-weight':'500'}"
														[ngClass]="!i ? 'empty' : (data.isChecked ? 'isChecked' : 'isNotChecked')"
														>
														<div class="data-text">
															<div class="d-flex justify-content-center align-items-center" *ngIf="data.title !== null" >
																<span class="text-title"  ngbTooltip="{{data.title}}" placement="right">
																	 <div class="" *ngIf="data.title == '% de acierto' else defaultTitle">
																		<span>% Aciertos <mat-icon svgIcon="IconoTickRevision2" ></mat-icon></span>
																	 </div>

																	 <ng-template #defaultTitle>
																		 {{data.title | titlecase }}
																	 </ng-template>

																</span>
																<!-- <div *ngIf="i">
																	<mat-icon *ngIf="data.isMultiplex === 0" svgIcon="AnadirActividad" ></mat-icon><mat-icon *ngIf="data.isMultiplex === 1" svgIcon="AnadirActividadesMultiples" ></mat-icon>
																</div> -->
															</div>
														</div>
														<div class="nota-media" *ngIf="!i">
															<span class="strong-text">{{data.texto}}</span>
														</div>
														<div  *ngIf="data.texto  !== null && i" class="data-nota">
															<div *ngIf="!data.isChecked"  class="d-flex justify-content-center align-items-center pointer">
																	<mat-icon svgIcon="inte"></mat-icon>
															</div>
															<div *ngIf="data.isChecked">
																<a class="text-dark font-weight-bold">
																	<div class="d-flex align-items-center justify-content-center">
																		<mat-icon [svgIcon]="data.iconResult ? 'IconoTickRevision2' : 'IconoXRevision'" class="mr-1"></mat-icon>
																		<span>{{data.texto}}</span>
																	</div>
																</a>
															</div>
														</div>
														</th>
													</tr>

												</tbody>
											</table>
											<!-- <table class="table table-bordered">
												<thead>
													<th  scope="col" class="bg-light header-table mr-2"  *ngFor="let head of headTable; let i = index">
														<div class="data-text">
															<div class="avatar d-flex justify-content-center align-items-center" *ngIf="i === 0" >
																<mat-icon svgIcon="inte" ></mat-icon>
															</div>
															<div class="avatar mb-2" *ngIf="i !== 0" [ngStyle]="{'background-image': getUserAvatar(head.image)}"></div>
															<div >
																<h6 class="font-weight-bold text-center">{{head.name}}</h6>
															</div>
														</div>
													</th>


												</thead>
												<tbody *ngIf="bodyTable!= undefined" >
													<tr *ngFor="let body of bodyTable[0].row"  [ngStyle]="{'background-color': body.color}">
														<td >
															{{body.title}}
														</td>
														<td>
															{{body.texto}}
														</td>
													</tr>

												</tbody>
											</table> -->

												<!-- <app-estudiante-quizes-datos  [idGrupo]="idGrupo" [idCourse]="idCourse" [idGrafo]="idGrafo" [idNode]="idNode"></app-estudiante-quizes-datos> -->
										</div>
								</div>
								<div *ngIf="usersInscritos">
									<h5 class="text-title font-weight-bold" *ngIf="usersInscritos.length === 0">
										{{ 'INFORMES.NOHAYESTUDIANTES' | translate }}
									</h5>
								</div>
							</div>
						</div>
					</div>

					<div class="d-flex justify-content-center mt-4" *ngIf="isLoading">
							<mat-spinner [diameter]="50"></mat-spinner>
					</div>
				</div>
			</nb-card-body>
		</nb-card>
	</nb-card-body>

</nb-card>
