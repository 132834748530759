import { element } from "protractor";
import { CommonModule, } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, HostListener } from "@angular/core";
import {
	NbButtonModule,
	NbCardModule,
	NbIconModule,
	NbListModule,
	NbSpinnerModule,
} from "@nebular/theme";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslatePipe, TranslateService } from "@ngx-translate/core";
import { CompetitionsService } from "src/app/core/services/competitions/competitions.service";
import { GruposService } from "src/app/core/services/groups/grupos.service";
import { LoginService } from "src/app/core/services/login";
import { ReportingService } from "../reporting/service/reporting.service";
import { ImagenPipe } from "../../pipes/imagen.pipe";
import { MatIconModule } from "@angular/material/icon";
import { take } from "rxjs";
import { User } from "src/app/core/models/users/user.models";
import { UsersService } from "src/app/core/services/users";
import { MastersService } from "src/app/core/services/masters";
const NOIMAGE = "../../../../../assets/images/icons/account_circle.svg";
@Component({
	selector: "app-modal-competition-results",
	standalone: true,
	imports: [
		CommonModule,
		NbCardModule,
		NbIconModule,
		NbButtonModule,
		NbListModule,
		NbSpinnerModule,
		MatIconModule,
		TranslateModule,
	],
	templateUrl: "./modal-competition-results.component.html",
	styleUrls: ["./modal-competition-results.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class ModalCompetitionResultsComponent {
	// input named minimal to handle the minimal modal hiding of some elements
	// depending on the minimal value
	@Input() minimal: boolean = false;
	@Input() reportingIdGroup: number = 0;
	isLoading: boolean = false;
	isMobile: boolean = false;
	isMenuOpen: boolean = false;
	gamesHistory: any = [];
	teamsOfGame: any;
	group: any;
	tableData: any;
	dataOfSelectedGame: any = {};
	public headTable: any[] = [];
	public bodyTable: any[] = [];
	public dataTable: any[] = [];
	cabecera: any;
	user: User;
	gameSelected: string = "";
	idGroup: number = 0;
	top3: any;
	isNaN: Function = Number.isNaN;
	totalHeadTable: unknown[];
	totalBodyTable: unknown[];
	is1vs1: boolean = false;
	isTeams: boolean = false;
	constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private groupService: GruposService,
		public loginService: LoginService,
		public translateService: TranslateService,
		private competitionsService: CompetitionsService,
		private reporting: ReportingService,
		private imagePipe: ImagenPipe,
		private usersService: UsersService,
		private mastersService: MastersService,
	) {}

	ngOnInit(): void {
		this.user = this.loginService.getUser();
		this.isLoading = true;
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.competitionsService.getCompetitionSessionList().subscribe((res) => {
			this.isLoading = false;
			res.data.forEach((element) => {
				let roomProps = JSON.parse(element.roomProps);
				let competitionDate = new Date(element.creationDate);
				let formattedDate = competitionDate.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }).replace(',', ' (');
				let userParticipated = this.determineIfUerIsInSomeOfTheTeams(
					element.teams
				);
				if (roomProps.gameIsActive == false && userParticipated) {
					this.gamesHistory.push({
						nameSession: roomProps.nameRoom,
						hex: roomProps.roomCode,
						onlineUsers: roomProps.connectedUsers.length,
						maxUsers: roomProps.maxUsers,
						gameSubject: roomProps.gameSubject,
						idGroup: element.idGroup,
						date: formattedDate+ ' )',
					});
				}
				//filter the games based on the reportingIdGroup if theres one
				if(this.reportingIdGroup != 0){
					this.gamesHistory = this.gamesHistory.filter((game) => game.idGroup == this.reportingIdGroup);
				}

				//invert the array to show the last games first
				this.gamesHistory = this.gamesHistory.reverse();
			});
		});

		this.isMobile = window.innerWidth < 1200;
	}

	determineIfUerIsInSomeOfTheTeams(teams: any[]) {
		let userIsInSomeTeam = false;

		if (teams.length === 0) {
			return false;
		}

		teams.forEach((team: { users: any[] }) => {
			if (team.users.includes(this.user.idUser)) {
				userIsInSomeTeam = true;
			}
		});

		return userIsInSomeTeam;
	}

	getGameData(hex: string) {
		this.isLoading = true;
		this.gameSelected = hex;
		this.competitionsService
			.getCompetitionSession(hex)
			.subscribe(({ data }) => {
				console.log('danny ocean',data);
				let roomProps = JSON.parse(data.roomProps);
				this.is1vs1 = roomProps.gameMode == 0;
				this.isTeams = roomProps.gameMode == 1;
				this.idGroup = data.idGroup;
				this.isLoading = false;
				this.dataOfSelectedGame = data;
				//if this.idGroup is equal to 0 then remove the first two elements of the array data.teams
				if (this.idGroup === 0) {
					data.teams.shift();
					data.teams.shift();
				}
				this.teamsOfGame = data.teams;
				this.reporting
					.getDataTableFromChallenges(
						this.dataOfSelectedGame.idGroup,
						[],
						false,
						[hex]
					)
					.pipe(take(1))
					.subscribe((data) => {
						//create inmutable copy of the data to avoid changes in the original data
						const headTableCopy = Array.from(data.data.headTable);
						const bodyTableCopy = Array.from(data.data.bodyTable);
						this.totalHeadTable = Array.from(data.data.headTable);
						this.totalBodyTable = Array.from(data.data.bodyTable);
						this.headTable = headTableCopy;

						//this.totalHeadTable.splice(3)


						this.bodyTable = bodyTableCopy;

						//get top 3 of the group based on teamsOfGame[0].bodyTable[0].row[1].texto trim the % symbol and parse to
						//number then sort the array and get the top 3 and put the idUser and the percentage in the array
						//avoid the first element of the array because it is the header of the table
						if (this.idGroup === 0) {
							this.top3 = this.getTop3(data.data.bodyTable[1].row);
							//for each member of the top3 array get the user data and put it in a new propperty of the object
							//called user
							this.top3.forEach((element) => {
								this.usersService
									.getUserByIdUserV2(element.idUser)
									.subscribe((res) => {
										element.user = res;
									});
							});
						} else {
							this.top3 = this.getTop3Teams(
								data.data.bodyTable[1].row,
								this.teamsOfGame
							);
						}

						this.dataTable = bodyTableCopy;
						this.cabecera = headTableCopy;
						this.bodyTable = bodyTableCopy;

						this.teamsOfGame.forEach((team, i) => {
							// Create a copy of headTableCopy and bodyTableCopy for each team
							let teamHeadTable = [...headTableCopy];
							let teamBodyTable = [...bodyTableCopy.slice(1)];

							team.cabecera = teamHeadTable;
							team.bodyTable = teamBodyTable;
							// Get all other teams
							let otherTeams = [...this.teamsOfGame];
							otherTeams.splice(i, 1); // Remove the current team

							// Flatten the users of the other teams into a single array
							let usersInOtherTeams = otherTeams.flatMap(
								(otherTeam) => otherTeam.users
							);
							//Execute a function for each user in the other teams
							let { headTable, bodyTable } = this.removeAllInfoFromUser(
								usersInOtherTeams,
								teamHeadTable,
								teamBodyTable
							);

							team.cabecera = headTable;
							team.bodyTable = bodyTable;
						});

						// if the game is 1vs1 then we need to remove all the teams except the first last one
						if (this.is1vs1) {
							this.teamsOfGame = this.teamsOfGame.slice(-1)
						}

						if(this.isTeams && this.idGroup != 0){
							this.teamsOfGame = this.teamsOfGame.slice(0, -1);

						}
					});
			});

		//Creamos el log
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha seleccionado la competicion con id " + hex + ".",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.reportingIdGroup,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	removeAllInfoFromUser(
		usersToRemove: number[],
		cabecera: any[],
		bodyTable: any[]
	) {
		// Create copies of cabecera and bodyTable
		let header = [...cabecera];
		let body = bodyTable.map((element) => ({
			...element,
			row: [...element.row],
		}));

		header = header.filter(
			(element) => !usersToRemove.includes(element.idUser)
		);
		body = body.map((element) => {
			element.row = element.row.filter(
				(row) => !usersToRemove.includes(row.idUser)
			);
			return element;
		});
		return { headTable: header, bodyTable: body };
	}

	getUserAvatar(imagen): string {
		let image: string = "";
		imagen !== null && imagen !== ""
			? (image = this.imagePipe.transform(imagen, "userAvatar"))
			: (image = NOIMAGE);
		return `url('${image}')`;
	}

	getTop3(rawData) {
		let lastPercentage = null;
		let uniqueScores = 0;
		return rawData
			.slice(1)
			.map((element) => {
				return {
					idUser: element.idUser,
					percentage: parseFloat(element.texto?.trim().replace("%", "")),
				};
			})
			.sort((a, b) => b.percentage - a.percentage)
			.map((element) => {
				if (element.percentage !== lastPercentage) {
					uniqueScores++;
					lastPercentage = element.percentage;
				}
				element.position = uniqueScores;
				return element;
			})
			.slice(0, 3);
	}

	getTop3Teams(rawData, teamsOfGame) {
		//in teamsOfGame we have the teams of the game including the users of each team
		//in rawData we have the percentage individually of each user
		//now we need to get the percentage of the team based on the percentage of the users if they are included in the team
		//sum the percentage of the users and then divide by the number of users in the team
		//when we have the data whe gonna put ir on the top3 array that now contains the teams with the percentage
		let lastPercentage = null;
		let uniqueScores = 0;
		let top3 = [];
		teamsOfGame.forEach((team) => {
			let percentage = 0;
			team.users.forEach((user) => {
				let userPercentage = rawData.find((element) => element.idUser === user);
				if (userPercentage === undefined) {
					return;
				} else {
					//limit to 2 decimals
					percentage += parseFloat(
						userPercentage.texto?.trim().replace("%", "")
					);
				}
			});
			percentage = percentage / team.users.length;

			this.totalHeadTable.push({name: team.name, percentage: percentage});
			top3.push({
				teamName: team.name,
				percentage: percentage,
			});
		});
		return top3
			.sort((a, b) => b.percentage - a.percentage)
			.map((element) => {
				if (element.percentage !== lastPercentage) {
					uniqueScores++;
					lastPercentage = element.percentage;
				}
				element.position = uniqueScores;
				return element;
			})
			.slice(0, 3);
	}

	closeModal(sendData?: any) {
		//this.destroy$.next(true);
		this.activeModal.close(sendData);
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any): void {
		if (window.innerWidth <= 1200) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	}

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
	}
}
