<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="border-bottom-0">
		<!-- Nombre del grupo -->
		<div class="row">
			<div class="col-10">
				<h4>{{group.title}}</h4>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'pulse' } }" (click)="closeModal('')"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<app-loading [isVisible]="cargando"></app-loading>
		<div class="container">
			<div class="d-flex flex-column gap-3">
				<div class="d-flex justify-content-between align-items-center w-100 gap-3 flex-md-row flex-column padding-header">
					<!-- Buscador -->
					<form [formGroup]="formulario" class="w-100" #thisForm="ngForm">
						<div class="input-container">
							<nb-form-field [formGroup]="formulario" class="w-100">
								<input type="search" nbInput fullWidth fieldSize="small" class="form-control"
									placeholder="{{ 'TOTALHISTORYUSER.FILTER' | translate }}" formControlName="filtrado">
								<button nbSuffix nbButton ghost size="small" class="pointer">
									<nb-icon *ngIf="!formulario.get('filtrado')?.value" icon="search" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
									<nb-icon *ngIf="formulario.get('filtrado')?.value" (click)="clearSearch()" icon="close-outline"></nb-icon>
								</button>
							</nb-form-field>
						</div>
					</form>
					<!-- Copiar o seleccionar todos -->
					<div class="d-flex gap-2 w-100 justify-content-end align-items-center">
						<button nbButton size="medium" status="primary" [disabled]="selectedEmails === ''" (click)="copyToClipboard()">
							{{ 'GLOBALS.COPY' | translate }}
						</button>
						<p class="m-0">{{ 'MODALGROUPSEDIT.SELECTALL' | translate }}</p>
						<nb-checkbox class="d-flex" status="primary" [(ngModel)]="selectAllChecked"  labelPosition="left" (change)="toggleSelectAll($event)"></nb-checkbox>
					</div>
				</div>
				<!-- Listado de estudiantes -->
				<ul class="list-group" *ngIf="users.length > 0">
					<li *ngFor="let user of users | sortBy:'asc':'name'"
						class="list-group-item d-flex gap-2 justify-content-between align-items-center">
						<!-- Nombre del estudiante -->
						<div class="nameStyle">
							<p>{{user.name}}</p>
						</div>
						<!-- Correo -->
						<div class="d-flex justify-content-center" style="flex: 1;">
							<div class="text-danger" *ngIf="!user.hasEmail">
								<nb-icon icon="alert-triangle-outline" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
							</div>
							<div *ngIf="user.hasEmail">
								<p class="m-0">{{user.mail}}</p>
							</div>
						</div>
						<!-- Boton para seleccionar -->
						<div class="w-auto">
							<nb-checkbox status="primary" [(ngModel)]="user.selected" (change)="updateSelectedEmails()"></nb-checkbox>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</nb-card-body>
</nb-card>
