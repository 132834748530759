<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="border-bottom-0">
		<div class="col-10 modal-title">
			<ng-container *ngIf="isEdit; else notEdit">
				<h2>{{ "CENTROS.EDITCENTER" | translate }}</h2>
			</ng-container>
			<ng-template #notEdit>
				<h2>{{ "CENTROS.DARDEALTACENTRO" | translate }}</h2>
			</ng-template>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left"
			[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-body>
		<div class="container">
			<div class="d-flex">
				<app-alert context="ModalCentro"></app-alert>
			</div>

			<form [formGroup]="formularioDatos" #thisForm="ngForm" class="d-flex flex-column">

				<div class="row">
					<!-- DATA -->
					<div class="col-12">
						<div class="d-flex flex-column gap-3">
							<div class="row">
								<!-- IMAGE -->
								<div class="col-12 d-flex justify-content-center align-items-center">
									<app-imgupload [isShowDelete]="true" formControlName="image"></app-imgupload>
								</div>
							</div>

							<div class="row">
								<div class="col-1">
									<i class="material-icons ti-size-2">location_city</i>
								</div>
								<div class="col-11">
									<div class="d-flex flex-column">
										<label>{{ 'USERDATA.NOMBRE' | translate }}</label>
										<input nbInput fullWidth status="basic" class="form-control"
											formControlName="centerName" fieldSize="small">
										<div *ngFor="let validation of validationMessages.centerName">
											<div class="error-message"
												*ngIf="formularioDatos.get('centerName')?.hasError(validation.type) && (formularioDatos.get('centerName')?.dirty || formularioDatos.get('centerName')?.touched || thisForm.submitted)">
												{{validation.message}}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-1">
									<i class="material-icons ti-size-2">map</i>
								</div>
								<div clasS="col-11">
									<div class="d-flex flex-column">
										<label>{{ 'USERDATA.DIRECCION' | translate }}</label>
										<input nbInput fullWidth status="basic" class="form-control"
											formControlName="centerAddress" fieldSize="small">
										<div *ngFor="let validation of validationMessages.centerAddress">
											<div class="error-message"
												*ngIf="formularioDatos.get('centerAddress')?.hasError(validation.type) && (formularioDatos.get('centerAddress')?.dirty || formularioDatos.get('centerAddress')?.touched || thisForm.submitted)">
												{{validation.message}}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-1">
									<i class="material-icons ti-size-2">location_on</i>
								</div>
								<div clasS="col-11">
									<div class="d-flex flex-column">
										<label>{{'MODALNEWCENTER.COUNTRY' | translate}}</label>
										<nb-select formControlName="country" (selectedChange)="getRegions($event)" class="SelectForm" hero
											size="small"
											placeholder="{{'MODALNEWCENTER.COUNTRY' | translate}}" style="max-width: 100%;">
											<nb-option class="SelectFormOpt" *ngFor="let country of allCountries"
												[value]="country.idCountry">{{country.country_es}}
											</nb-option>
										</nb-select>
										<!-- <div *ngFor="let validation of validationMessages.province">
											<div class="error-message"
												*ngIf="formularioDatos.get('province')?.hasError(validation.type) && (formularioDatos.get('province')?.dirty || formularioDatos.get('province')?.touched || thisForm.submitted)">
												{{validation.message}}
											</div>
										</div> -->
									</div>
								</div>


							</div>

							<div class="row" *ngIf="allRegions.length > 0">
								<div class="col-1">
									<i class="material-icons ti-size-2">location_on</i>
								</div>
								<div clasS="col-11" >
									<div class="d-flex flex-column">
										<label>{{'MODALNEWCENTER.PROVINCE' | translate}}</label>
										<nb-select formControlName="province" class="SelectForm" hero
											size="small"
											placeholder="{{'MODALNEWCENTER.PROVINCE' | translate}}" style="max-width: 100%;">
											<nb-option class="SelectFormOpt" *ngFor="let region of allRegions"
												[value]="region.idCountryRegion">{{region.regionName}}
											</nb-option>
										</nb-select>
										<!-- <div *ngFor="let validation of validationMessages.province">
											<div class="error-message"
												*ngIf="formularioDatos.get('province')?.hasError(validation.type) && (formularioDatos.get('province')?.dirty || formularioDatos.get('province')?.touched || thisForm.submitted)">
												{{validation.message}}
											</div>
										</div> -->
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-1">
									<i class="material-icons ti-size-2">mail_outline</i>
								</div>
								<div class="col-11">
									<div class="d-flex flex-column">
										<label>{{ 'USERDATA.EMAIL' | translate }}</label>
										<input nbInput fullWidth status="basic" class="form-control"
											formControlName="centerMail" fieldSize="small">
										<div *ngFor="let validation of validationMessages.centerMail">
											<div class="error-message"
												*ngIf="formularioDatos.get('centerMail')?.hasError(validation.type) && (formularioDatos.get('centerMail')?.dirty || formularioDatos.get('centerMail')?.touched || thisForm.submitted)">
												{{validation.message}}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-1">
									<i class="material-icons ti-size-2">phone</i>
								</div>
								<div class="col-11">
									<div class="d-flex flex-column">
										<label>{{'MODALNEWCENTER.PHONE' | translate}}</label>
										<div class="d-flex justify-content-between flex-wrap gap-3">
											<div class="d-flex">
												<nb-select class="SelectForm" hero size="small" formControlName="extension">
													<nb-option value="">{{ 'LOGIN.PREFIX' | translate }}</nb-option>
													<nb-option class="SelectFormOpt" *ngFor="let prefix of $phonePrefixes | async"
														value="{{prefix.dial_code}}">{{prefix.name_en}}
														<span><strong>{{prefix.dial_code}}</strong> </span></nb-option>
												</nb-select>
												<div *ngFor="let validation of validationMessages.extension">
													<div class="error-message"
														*ngIf="formularioDatos.get('extension')?.hasError(validation.type) && (formularioDatos.get('extension')?.dirty || formularioDatos.get('extension')?.touched || thisForm.submitted)">
														{{validation.message}}
													</div>
												</div>
											</div>
											<div clasS="d-flex">
												<div class="d-flex flex-column">
													<input nbInput fullWidth status="basic" class="form-control"
														formControlName="centerPhone" appNumberInput fieldSize="small">
													<div *ngFor="let validation of validationMessages.centerPhone">
														<div class="error-message"
															*ngIf="formularioDatos.get('centerPhone')?.hasError(validation.type) && (formularioDatos.get('centerPhone')?.dirty || formularioDatos.get('centerPhone')?.touched || thisForm.submitted)">
															{{validation.message}}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-1">
									<i class="material-icons ti-size-2">web</i>
								</div>
								<div clasS="col-11">
									<div class="d-flex flex-column">
										<label>{{ 'USERDATA.URL' | translate }}</label>
										<input nbInput fullWidth status="basic" class="form-control"
											formControlName="centerUrl" fieldSize="small">
										<div *ngFor="let validation of validationMessages.centerUrl">
											<div class="error-message"
												*ngIf="formularioDatos.get('centerUrl')?.hasError(validation.type) && (formularioDatos.get('centerUrl')?.dirty || formularioDatos.get('centerUrl')?.touched || thisForm.submitted)">
												{{validation.message}}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-1">
									<i class="material-icons ti-size-2">description</i>
								</div>
								<div clasS="col-11">
									<div class="d-flex flex-column">
										<label>{{'MODALNEWCENTER.DESC' | translate}}</label>
										<textarea nbInput fullWidth status="basic" class="form-control"
											formControlName="remarks" fieldSize="small">
										</textarea>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- SEND BUTTON -->
				<div class="d-flex justify-content-center mt-4">
					<ng-container *ngIf="isEdit; else elseTemplate">
						<button nbButton (click)="crearCentro()" class="btn-themecolor">
							{{ 'CENTROS.UPDATECENTER' | translate}}
						</button>
					</ng-container>
					<ng-template #elseTemplate>
						<button nbButton (click)="crearCentro()" class="btn-themecolor">
							{{ 'CENTROS.CREARCENTRO' | translate}}
						</button>
					</ng-template>



				</div>
			</form>
			<app-loading [isVisible]="loading"></app-loading>
		</div>
	</nb-card-body>
</nb-card>
