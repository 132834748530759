<ng-container [ngbTooltip]="operator.name | translate" placement="bottom">
    <mat-icon *ngIf="operator.type !== 'abstract'; else svg">
        <mat-icon id={{operator.icon}} class="flexItemsItem">{{operator.icon}}
        </mat-icon>
    </mat-icon>
    <ng-template #svg>
        <mat-icon id={{operator.icon}} class="flexItemsItem" svgIcon={{operator.icon}} class="icon-abstract">
        </mat-icon>
    </ng-template>
</ng-container>
