import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
@Component({
  selector: 'app-node-detail-transparent-header',
  templateUrl: './node-detail-transparent-header.component.html',
  styleUrls: ['./node-detail-transparent-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NodeDetailTransparentHeaderComponent implements OnInit {

  @Input() modeAuto: boolean = false;
  @Input() nomQuiz: string;
  @Input() nomNode: string;
  @Input() nomGrafo: string;
  @Input() type: string;
  @Input() node;
  @Input() course;
  @Input() idCurso: number;
  @Input() idMapa: number;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
  closeModal(sendData?: any) { this.activeModal.close(sendData); }

}
