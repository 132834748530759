<div class="quiz-container" id="quiz" *ngIf="quiz">
    <app-hamburger-menu [menuItems]="menuItems" (clickFunction)="getActionFromMenu($event)" [position]="menuPosition"></app-hamburger-menu>
    <app-quiz-pad-buttons [isShow]="isShowPads" [isShowLabels]="isShowLabels" (action)="getAction($event)"></app-quiz-pad-buttons>
    <app-quiz-pad-controller [isShow]="isShowPads" [isShowLabels]="isShowLabels" (action)="getControllerAction($event)"></app-quiz-pad-controller>

    <app-quiz-play-header [quiz]="quiz" [idCurso]="courseId" [idMapa]="graphId" (close)="onClose()"></app-quiz-play-header>

    <div class="body-container py-3 w-100 mt-4">
        <app-quiz-play-body [courseId]="courseId" [graphId]="graphId" [quiz]="quiz" [elements]="elements" [answered]="answered" (answeredChange)="onAnswered()" (colorChange)="onColorChanged($event)"></app-quiz-play-body>
    </div>
</div>
