import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-we',
  templateUrl: './dialog-we.component.html',
  styleUrls: ['./dialog-we.component.scss']
})
export class DialogWeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
