import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LoginService } from '../login';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: "root",
})
export class CompetitionsService {
	constructor(private loginService: LoginService, private http: HttpClient) {}
	private roomCode = new BehaviorSubject<string>("");
	public competitionStarted: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);


	public getRoomCode() {
		return this.roomCode.asObservable();
	}

	public setRoomCode(code: string) {
		this.roomCode.next(code);
	}

	public getCompetitionStarted() {
		return this.competitionStarted.asObservable();
	}

	public setCompetitionStarted(value: boolean): void {
		this.competitionStarted.next(value);
	}


	generateRandomHexCode() {
		const newRoomCode = Math.floor(Math.random() * 16777215).toString(16);
		this.roomCode.next(newRoomCode.toUpperCase());
		return newRoomCode.toUpperCase();
	}

	createCompetitionSession(
		idUser: number,
		idGroup: number,
		hex: String,
		isTeacher,
		nameSession,
		teams,
		roomProps
	): Observable<any> {
		const url = `group/createupdatecompetitionsession`;
		const body = {
			idUser: idUser,
			idGroup: idGroup,
			idSession: hex,
			isTeacher: isTeacher,
			nameSession: nameSession,
			teams: teams,
			roomProps: roomProps,
		};
		return this.http.post<any>(url, body);
	}

	updateCompetitionSession(
		idCompetitionSession: number,
		creationDate: string,
		idGroup: number,
		idSession: string,
		idUser: number,
		isTeacher,
		nameSession,
		teams,
		roomProps
	): Observable<any> {
		const url = `group/createupdatecompetitionsession`;
		const body = {
			idCompetitionSession: idCompetitionSession,
			creationDate: creationDate,
			idGroup: idGroup,
			idSession: idSession,
			idUser: idUser,
			isTeacher: isTeacher,
			nameSession: nameSession,
			teams: teams,
			roomProps: roomProps,
		};
		return this.http.post<any>(url, body);
	}

	getCompetitionSessionList(): Observable<any> {
		const url = `group/getcompetitionsessionlist`;
		return this.http.get(url);
	}

	getCompetitionSession(idSession: any) {
		const url = `group/getcompetitionsessionbyidsession/${idSession}`;
		return this.http.get<any>(url);
	}
}
