import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-dispersion-asignaturas',
  templateUrl: './dispersion-asignaturas.component.html',
  styleUrls: ['./dispersion-asignaturas.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DispersionAsignaturasComponent  implements OnInit{
	@Input() parametroID: number | null = null;
	@Input() parametroID2: number | null = null;
	@Input() consultaID: number | null = null;
	@Input() datasetsImported: any = null;
	@Input() labelsImported: any = null;

	chart: any = null;
	labels: any[] = [];
	datasets: any[] = [];
	dataTrendLine: any[] = [];

	parametros: any[] = [
		{ id: 1, title: this.translateService.instant("CENTRO.ACIERTOS")},
		{ id: 2, title: this.translateService.instant("CENTRO.CONOCIMIENTO")},
		{ id: 3, title: this.translateService.instant("CENTRO.TRABAJO")},
		{ id: 4, title: this.translateService.instant("CENTRO.CONSTANCIA")},
		{ id: 5, title: this.translateService.instant("CENTRO.CARGA")},
		{ id: 6, title: this.translateService.instant("CENTRO.SOCIALIZACION")},
	];

	constructor(
		private translateService: TranslateService,
	) {}

	ngOnInit() {
		if (this.chart) {
			this.chart.destroy();
		}
		if (this.consultaID !== null) {
			//this.datasets = this.datasetsImported;
			this.labels = this.labelsImported;
			//this.labels = this.obtenerLabels(this.datasetsImported, this.parametroID - 1);
			this.datasets = this.obtenerLabelsConEstructura(this.datasetsImported, this.parametroID - 1, this.parametroID2 - 1);
    }
		setTimeout(() =>	{
			this.createChart();
		}, 200);
  }

	obtenerLabels(dataSets, index) {
		const filteredData = dataSets
		.map(info => info.data[index])
		.filter(value => value !== null);
    return dataSets.map(info => info.label);
	}
	obtenerLabelsConEstructura(dataSets, indexX, indexY) {
		const label = dataSets
				.map(info => info.label)
				.filter(value => value !== null);
    const ejeX = dataSets
        .map(info2 => info2.data[indexX])
        .filter(value2 => value2 !== null);
		const ejeY = dataSets
				.map(info3 => info3.data[indexY])
				.filter(value3 => value3 !== null);
    //const backgroundColor = filteredData.map(value => this.colorKnowledge(value));
		let data: any[] = [];
		for (let index = 0; index < ejeX.length; index++) {
			const l = label[index];
			const x = ejeX[index];
			const y = ejeY[index];
			let dataset = {
				label: l,
				data: [{
					x: x,
					y: y
				}],
				backgroundColor: this.colorKnowledge(x),
				pointRadius: 4 // Tamaño de los puntos
			}
			data.push(dataset);
			//datos para la linea de tendencia
			this.dataTrendLine.push({x: x, y: y});
		}
    return data;
	}
	colorKnowledge(valor: number): string {
		let color = "rgba(154, 155, 156, 1)"; // gris con 50% de transparencia por defecto
		if(this.parametroID == 1){
			if (valor == 0) color = "rgba(154, 155, 156, 1)"; // #9A9B9C
			else if (valor > 0 && valor < 11) color = "rgba(251, 13, 28, 1)"; // #FB0D1C
			else if (valor < 21) color = "rgba(252, 92, 32, 1)"; // #FC5C20
			else if (valor < 31) color = "rgba(252, 154, 39, 1)"; // #FC9A27
			else if (valor < 41) color = "rgba(254, 229, 51, 1)"; // #FEE533
			else if (valor < 51) color = "rgba(255, 253, 56, 1)"; // #FFFD38
			else if (valor < 61) color = "rgba(212, 253, 53, 1)"; // #D4FD35
			else if (valor < 71) color = "rgba(156, 253, 50, 1)"; // #9CFD32
			else if (valor < 81) color = "rgba(41, 248, 46, 1)"; // #29F82E
			else if (valor < 91) color = "rgba(42, 213, 45, 1)"; // #2AD52D
			else if (valor < 101) color = "rgba(26, 175, 84, 1)"; // #1AAF54
		} else if(this.parametroID == 2){
			if (valor == 0) color = "rgba(154, 155, 156, 1)"; // #9A9B9C
			else if (valor > 0 && valor < 1.1) color = "rgba(251, 13, 28, 1)"; // #FB0D1C
			else if (valor < 2.1) color = "rgba(252, 92, 32, 1)"; // #FC5C20
			else if (valor < 3.1) color = "rgba(252, 154, 39, 1)"; // #FC9A27
			else if (valor < 4.1) color = "rgba(254, 229, 51, 1)"; // #FEE533
			else if (valor < 5.1) color = "rgba(255, 253, 56, 1)"; // #FFFD38
			else if (valor < 6.1) color = "rgba(212, 253, 53, 1)"; // #D4FD35
			else if (valor < 7.1) color = "rgba(156, 253, 50, 1)"; // #9CFD32
			else if (valor < 8.1) color = "rgba(41, 248, 46, 1)"; // #29F82E
			else if (valor < 9.1) color = "rgba(42, 213, 45, 1)"; // #2AD52D
			else if (valor < 10.1) color = "rgba(26, 175, 84, 1)"; // #1AAF54
		}
		return color;
	}

	calculateTrendline(data: any[]) {
		const n = data.length;
		let sumX = 0, sumY = 0, sumXY = 0, sumXX = 0;
		data.forEach(point => {
			sumX += point.x;
			sumY += point.y;
			sumXY += point.x * point.y;
			sumXX += point.x * point.x;
		});
		const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
		const intercept = (sumY - slope * sumX) / n;
		// Genera los puntos para la línea de tendencia
		const xMin = Math.min(...data.map(point => point.x));
		const xMax = Math.max(...data.map(point => point.x));
		return [
			{ x: xMin, y: slope * xMin + intercept },
			{ x: xMax, y: slope * xMax + intercept }
		];
	}

	createChart(){
		var canvas = document.getElementById('myChartControlAsignaturas') as HTMLCanvasElement;
		var ctx = canvas.getContext('2d');
		// Guarda la referencia al contexto del componente en `self`
		const self = this;
		// Calcula la línea de tendencia
		const trendline = this.calculateTrendline(this.dataTrendLine);
		if (this.chart) {
			this.chart.destroy();
		}
		this.chart = new Chart(ctx, {
			type: 'scatter',
			data: {
				labels: this.labels,
				datasets: [
					...this.datasets,
					{
						label: 'Línea de tendencia', // Dataset para la línea de tendencia
						data: trendline,
						borderColor: 'rgba(255, 99, 132, 1)', // Color de la línea de tendencia
						borderWidth: 1,
						showLine: true, // Mostrar como línea en lugar de puntos
						fill: false,
						pointRadius: 0 // No mostrar puntos en la línea de tendencia
					}
				]
			},
			options: {
				scales: {
					x: {
						type: 'linear',
						position: 'bottom',
						border: {
							display: true,
							color: '#000', // Color del eje x
							width: 3 // Grosor del eje x
						},
						title: {
							display: false,
						},
						ticks: {
							callback: function(value) {
								return value; // Formatea los valores como porcentajes
							}
						}
					},
					y: {
						stacked: false,
						//suggestedMin: 0,
						position: 'top',
						border: {
							display: true,
							color: '#000', // Color del eje x
							width: 3 // Grosor del eje x
						},
						title: {
							display: false,
						},
						ticks: {
							callback: function(value) {
								return value; // Formatea los valores como porcentajes
							}
						}
					}
				},
				plugins: {
					legend: {
						display: false // Oculta los labels
					},
					tooltip: {
						callbacks: {
							label: function(tooltipItem) {
								const label = tooltipItem.dataset.label || ''; // Nombre del label
								const xValue = tooltipItem.parsed.x;
								const yValue = tooltipItem.parsed.y;
								return [
									label,        // Primera línea: el label
									self.parametros.find(p => p.id === self.parametroID).title + ': ' + xValue,     // Segunda línea: el valor de X
									self.parametros.find(p => p.id === self.parametroID2).title + ': ' + yValue      // Tercera línea: el valor de Y
								];
							}
						},
					}
				}
			}
		});
	}

}
