import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-quiz-edit-template-text-editor',
    templateUrl: './quiz-edit-template-text-editor.component.html',
    styleUrls: ['./quiz-edit-template-text-editor.component.scss']
})
export class QuizEditTemplateTextEditorComponent implements OnInit {
    @Input() text: string;

    auxText: string;

    constructor(private activeModal: NgbActiveModal) { }

    ngOnInit() {
        this.auxText = this.text;
    }

    close() {
        this.activeModal.close();
    }

    save() {
        this.activeModal.close(this.auxText);
    }
    setFocus(editor) {
        editor.focus()
    }
}
