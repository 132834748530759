import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';
import { Subscription } from 'rxjs';
import { QuizElementTypes } from 'src/app/core/models/quizzes/quiz-element-types.enum';
import { QuizTypes } from 'src/app/core/models/quizzes/quiz-types.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { HypermediaTypes } from '../../../quiz-hypermedia/hypermedia-types.enum';
import { QuizHypermediaComponent } from '../../../quiz-hypermedia/quiz-hypermedia.component';

@Component({
    selector: 'app-quiz-edit-body-option',
    templateUrl: './quiz-edit-body-option.component.html',
    styleUrls: ['./quiz-edit-body-option.component.scss']
})
export class QuizEditBodyOptionComponent implements OnInit, OnChanges {
    @Input() quizType: QuizTypes;
    @Input() options: any[];
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saveOption: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteOption: EventEmitter<number> = new EventEmitter<number>();
    @Output() deleteOptions: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() multipleSave: EventEmitter<any[]> = new EventEmitter<any[]>();

    public optionForm: UntypedFormGroup;
    private lastOptionValueChanges: Subscription;
    public O_VIDEOS =  QuizElementTypes.O_VIDEOS;
    public O_PICTURES = QuizElementTypes.O_PICTURES;
    public O_AUDIOS = QuizElementTypes.O_AUDIOS;
    public O_PDFS = QuizElementTypes.O_PDFS;
    public O_TEXTS = QuizElementTypes.O_TEXTS;


    constructor(private fb: UntypedFormBuilder, private modalService: NgbModal) {
        this.optionForm = this.fb.group({
            options: this.fb.array([]),
            correctOptionId: null
        });
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {


        //Carga de valores por defecto al crear un quiz
        if (changes && changes.quizType && !changes.quizType.currentValue.length) {
            this.loadOptions();
        }

        //Carga de valores
        if (changes && changes.options && changes.options.currentValue && changes.options.currentValue.length > 0) {

            this.optionsArray.clear();

            this.options.forEach(a => {
                this.addOption(a);
            });

            if (this.optionsArray.controls.filter(c => c.get('isCorrect').value).length > 0) {
                this.correctOptionControl.patchValue(this.optionsArray.controls.filter(c => c.get('isCorrect').value)[0].get('auxId').value);
            }

            if (this.quizType !== QuizTypes.TEXT)
                this.addOption();
        }
    }



    public get optionsArray(): UntypedFormArray {
        return this.optionForm.get('options') as UntypedFormArray;
    }

    public get optionsArrayData(): UntypedFormControl[] {
        return this.optionsArray.controls.map(q => q.get('data') as UntypedFormControl);
    }

    public get optionsArrayType(): UntypedFormControl[] {
        return this.optionsArray.controls.map(q => q.get('type') as UntypedFormControl);
    }

    public get optionsArrayWidth(): UntypedFormControl[] {
        return this.optionsArray.controls.map(q => q.get('width') as UntypedFormControl);
    }

    public get optionsArrayHeight(): UntypedFormControl[] {
        return this.optionsArray.controls.map(q => q.get('height') as UntypedFormControl);
    }

    public get correctOptionControl(): UntypedFormControl {
        return this.optionForm.get('correctOptionId') as UntypedFormControl;
    }

    public get QuizTypes() {
        return QuizTypes;
    }

    public get elementTypes() {
        return QuizElementTypes;
    }

    public onTextBlur(index: number, onUpdate?:boolean) {
        if (this.optionsArray.controls[index].get('originalData').value !== this.optionsArray.controls[index].get('data').value || onUpdate) {
            this.saveOption.emit({ element: { ...this.optionsArray.controls[index].value } });
        }
    }

    private onCorrectChange(index: number) {
        const controlValue = this.optionsArray.controls[index].value;

        if (controlValue.id) {
            this.saveOption.emit({ element: { ...controlValue, isCorrect: !controlValue.isCorrect } });
        }
    }

    private loadOptions() {
        this.optionsArray.clear();
        this.correctOptionControl.setValue(null);

        switch (this.quizType) {
            case QuizTypes.TEXT:
                this.addOption();
                if (this.options.length <= 0)
                    this.saveOption.emit({ element: { ...(this.optionsArray.controls[0] as UntypedFormGroup).getRawValue() } });
                break;
            case QuizTypes.SINGLE:
                this.addOption();
                this.addOption();
                break;
            case QuizTypes.MULTIPLE:
                this.addOption();
                this.addOption();
                break;
            default:
                break;
        }

        if (this.quizType !== QuizTypes.TEXT) {
            for (let i = 0; i < this.optionsArray.controls.length; i++) {
                this.optionsArray.controls[i].get('data').setValidators([Validators.required]);
            }
        }
    }

    private addOption(option?: any): UntypedFormGroup {
        const newOption: UntypedFormGroup = this.createOption(this.quizType === QuizTypes.TEXT, option);
        this.optionsArray.push(newOption);

        if (this.quizType !== QuizTypes.TEXT) {
            if (this.lastOptionValueChanges) {
                this.lastOptionValueChanges.unsubscribe();
            }

            newOption.get('isCorrect').valueChanges.subscribe(value => {
                this.onCorrectChange(this.optionsArray.controls.findIndex(c => c.get('auxId').value === newOption.get('auxId').value));
            });

            this.lastOptionValueChanges = newOption.get('data').valueChanges.subscribe(value => {
                if (value) {
                    newOption.get('data').setValidators([Validators.required]);
                    this.addOption();
                }
            });
        }

        return newOption;
    }

    public onDeleteClick(index: number): void {
        const id: number = this.optionsArray.controls[index].get('id').value;
        if (id) {
            this.deleteOption.emit(id);
        } else {
            this.optionsArray.removeAt(index);
        }
    }

    private createOption(disabled: boolean, option?: any): UntypedFormGroup {
        const order: number = option ?
            option.yPosition :
            this.optionsArray.controls.length > 0 ?
                Math.max.apply(null, this.optionsArray.controls.map(c => c.get('order').value)) + 1 :
                1;

        return this.fb.group({
            id: option ? option.idQuizzesDataElements : '',
            auxId: this.optionsArray.controls.length,
            data: { value: option ? option.data : '', disabled: disabled },
            originalData: option ? option.data : '',
            type: option ? option.elementType : QuizElementTypes.O_TEXTS,
            isCorrect: { value: option ? !!option.responseCheck : false, disabled: (!option || !option.idQuizzesDataElements) },
            order: order,
            width: option ? option.xSize : 0,
            height: option ? option.ySize : 0
        });
    }

    // Marca la correcta como isCorrect y desmarca las demás
    public onSingleSelect(index: number) {
        for (let i = 0; i < this.optionsArray.controls.length; i++) {
            this.optionsArray.controls[i].get('isCorrect').patchValue(index === i, { emitEvent: false });
        }
        this.multipleSave.emit((this.optionsArray.value as any[]).filter(a => a.id));
    }

    public onOpenHypermedia(event: MouseEvent, index: number, insert: boolean = true) {

        event.stopPropagation();
        const currentControl = this.optionsArray.controls[index] as UntypedFormGroup;

        const modalRef = this.modalService.open(QuizHypermediaComponent,
            {
                scrollable: true,
                windowClass: MODAL_DIALOG_TYPES.W95
            }
        );
        modalRef.componentInstance.insert = insert;
        modalRef.componentInstance.mostrarMic = insert;
        modalRef.componentInstance.fileSelected.subscribe((data: any) => {
            modalRef.close();
            const type: HypermediaTypes = data.type;

            //const id = currentControl.get('id').value;
            if (this.quizType === QuizTypes.TEXT && type !== HypermediaTypes.TEXT) {
                let elementNew =  this.addOption();
                elementNew.get('type').patchValue(this.mapFileTypeToElementType(type));
                this.saveOption.emit({ element: { ...elementNew.getRawValue() }, file: data.file });

            } else if (this.quizType !== QuizTypes.TEXT ) {
                currentControl.get('data').patchValue('');
                currentControl.get('type').patchValue(this.mapFileTypeToElementType(type));
                this.saveOption.emit({ element: { ...currentControl.getRawValue() }, file: data.file });

            }

        });
    }

    private mapFileTypeToElementType(fileType: HypermediaTypes): QuizElementTypes {
        switch (fileType) {
            case HypermediaTypes.IMAGE:
                return QuizElementTypes.O_PICTURES;
            case HypermediaTypes.VIDEO:
                return QuizElementTypes.O_VIDEOS;
            case HypermediaTypes.AUDIO:
                return QuizElementTypes.O_AUDIOS;
            case HypermediaTypes.PDF:
                return QuizElementTypes.O_PDFS;
            default:
                return QuizElementTypes.O_TEXTS;
        }
    }

    closeModal(sendData?: any) {
        this.close.next(true);
    }

    public onResizeStop(event:IResizeEvent, index:number){

        //Set the width and the height of the image
        let width:number = event.size.width
        let height:number = event.size.height

        //Update the height and the width
        this.optionsArray.controls[index].get('width').setValue(width)
        this.optionsArray.controls[index].get('height').setValue(height)

        //Call to function to update the data in the backend
        this.onTextBlur(index, true)
    }
}
