<div class="imagecontainer" >
    <div *ngIf="!imgUrl" [ngSwitch]="isEdit" class="noImage">
        <div *ngSwitchCase="true">
            <img src="../../../../assets/images/photo.png" />
            <p class="ml-3">{{"IMAGEUPLOADCOMPONENT.UPLOADIMAGE" | translate}}</p>
            <input class="pointer" type="file" accept="image/*" />
        </div>
        <div *ngSwitchCase="false">
            <img src="../../../../assets/images/no-image.png" class="no-image" />
        </div>

    </div>
    <div *ngIf="imgUrl" class="image" [style.background-image]="'url(' + imgUrl + ')'">
        <div class="delete pointer" (click)="deleteImage()" *ngIf="isEdit" [style.visibility]="isShowDelete ? 'visible' : 'hidden'">
						<mat-icon class="pointer color-red-text" nbTooltip="{{'IMAGEUPLOADCOMPONENT.DELETEIMAGE' | translate}}" nbTooltipPlacement="top">delete</mat-icon>
        </div>
    </div>
</div>
