import { Component, OnInit, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import { NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ModelIcono } from 'src/app/core/models/courses/icono.model';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalComponent } from '../../modal/modal.component';
import { ModalReproducirNodoComponent } from '../modal-reproducir-nodo/modal-reproducir-nodo.component';

@Component({
  selector: 'app-modal-registrar-nodo',
  templateUrl: './modal-registrar-nodo.component.html',
  styleUrls: ['./modal-registrar-nodo.component.scss']
})
export class ModalRegistrarNodoComponent implements OnInit {
    id: string;
    tittle:string;
    description:string;
    labels:string;
    senmotemos:ModelIcono[]=[];
    assostatics:ModelIcono[]=[];
    temporaries:ModelIcono[]=[];
    abstracts :ModelIcono[]=[];
    formats:any[]=[];

    iconosSenmotemo:ModelIcono[]=[];
    iconosAssostatic:ModelIcono[]=[];
    iconosTemporary:ModelIcono[]=[];
    iconosAbstractPdf:ModelIcono[]=[];
    iconosAbstractText:ModelIcono[]=[];

    selectedOptionSend:boolean=false;
    selectedOptionImage:boolean=false;
    selectedOptionVolume:boolean=false;
    selectedOptionPdf:boolean=false;
    selectedOptionText:boolean=false;

  constructor(public modalService: NgbModal,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.obtenerIconos();
  }

  cancelar() {
    this.modalService.dismissAll();
  }

  registrar() {
    this.modalService.dismissAll();
  }

  adicionarIconoSenmotemo(icono){
    if (this.iconosSenmotemo.length<60 && this.selectedOptionSend){
        document.getElementById("areaSenmotemo").style.backgroundColor="rgb(0, 8, 50)";
        this.iconosSenmotemo.push(icono);
    }
  }

  adicionarIconoAssostatic(icono){
    if (this.iconosAssostatic.length<60 &&  this.selectedOptionImage){
        document.getElementById("areaAssostatic").style.backgroundColor="rgb(68, 127, 171)";
        this.iconosAssostatic.push(icono);
    }
  }

  adicionarIconoTemporary(icono){
    if (this.iconosTemporary.length<60 && this.selectedOptionVolume){
        document.getElementById("areaTemporary").style.backgroundColor="rgb(103, 173, 207)";
        this.iconosTemporary.push(icono);
    }
  }

  adicionarIconoAbstract(icono){
    if (this.iconosAbstractPdf.length<60 && (this.selectedOptionPdf)){
        document.getElementById("areaAbstractPdf").style.backgroundColor="rgb(180, 222, 243)";
       this.iconosAbstractPdf.push(icono);
    }
    if (this.iconosAbstractText.length<60 && (this.selectedOptionText)){
        document.getElementById("areaAbstractText").style.backgroundColor="rgb(180, 222, 243)";
       this.iconosAbstractText.push(icono);
    }
  }

  elegirOpcion(titulo){
    if(titulo=="video"){
        this.selectedOptionSend=true;
    }
    if(titulo=="imagen"){
        this.selectedOptionImage=true;
    }
    if(titulo=="audio"){
        this.selectedOptionVolume=true;
    }
    if(titulo=="pdf"){
        this.selectedOptionPdf=true;
    }
    if(titulo=="texto"){
        this.selectedOptionText=true;
    }
  }


  reproducirFormato(idCurso: number){
    const modalRef = this.modalService.open(ModalReproducirNodoComponent,
        {
          scrollable: true,
          windowClass: MODAL_DIALOG_TYPES.W100
        })
        modalRef.componentInstance.id = idCurso;
        modalRef.result.then((result) => {
          }, (reason) => {
        });
  }

  obtenerIconos(){
    //Aqui debemos llamar a los servicios
    this.formats=[{titulo:"video",formato:"send"},{titulo:"imagen",formato:"image"},
    {titulo:"audio",formato:"volume_up"},{titulo:"pdf",formato:"picture_as_pdf"},
    {titulo:"texto",formato:"text_snippet"}];

    this.senmotemos=[{id:1,nombre:"eye",src:"../../../../../assets/images/nodes/eye.png", tipo:""},
    {id:2,nombre:"ear",src:"../../../../../assets/images/nodes/ear.png", tipo:""},
    {id:3,nombre:"nose",src:"../../../../../assets/images/nodes/nose.png", tipo:""},
    {id:4,nombre:"hand",src:"../../../../../assets/images/nodes/hand.png", tipo:""},
    {id:5,nombre:"voice",src:"../../../../../assets/images/nodes/voice.png", tipo:""},
    {id:6,nombre:"heart",src:"../../../../../assets/images/nodes/heart.png", tipo:""},
    {id:7,nombre:"arrow_down",src:"../../../../../assets/images/nodes/arrow_down.png", tipo:""},
    {id:8,nombre:"arrow_up",src:"../../../../../assets/images/nodes/arrow_up.png", tipo:""}];

    this.assostatics=[{id:1,nombre:"person",src:"../../../../../assets/images/nodes/person.png", tipo:""},
    {id:2,nombre:"planet",src:"../../../../../assets/images/nodes/planet.png", tipo:""},
    {id:3,nombre:"lyrics",src:"../../../../../assets/images/nodes/lyrics.png", tipo:""},
    {id:4,nombre:"signs",src:"../../../../../assets/images/nodes/signs.png", tipo:""},
    {id:5,nombre:"run",src:"../../../../../assets/images/nodes/run.png", tipo:""},
    {id:6,nombre:"tools",src:"../../../../../assets/images/nodes/tools.png", tipo:""},
    {id:7,nombre:"finger",src:"../../../../../assets/images/nodes/finger.png", tipo:""},
    {id:8,nombre:"arrows",src:"../../../../../assets/images/nodes/twoarrows.png", tipo:""}];

    this.temporaries=[{id:1,nombre:"clock",src:"../../../../../assets/images/nodes/clock_yellow.png", tipo:""},
    {id:2,nombre:"bumeran",src:"../../../../../assets/images/nodes/bumeran.png", tipo:""},
    {id:3,nombre:"arrow",src:"../../../../../assets/images/nodes/arrow.png", tipo:""},
    {id:4,nombre:"camera",src:"../../../../../assets/images/nodes/camera.png", tipo:""},
    {id:5,nombre:"more",src:"../../../../../assets/images/nodes/more.png", tipo:""}];

    this.abstracts=[{id:1,nombre:"sing",src:"../../../../../assets/images/nodes/sing.png", tipo:""},
    {id:2,nombre:"column",src:"../../../../../assets/images/nodes/column.png", tipo:""},
    {id:3,nombre:"summation",src:"../../../../../assets/images/nodes/summation.png", tipo:""},
    {id:4,nombre:"algoritmo",src:"../../../../../assets/images/nodes/algoritmo.png", tipo:""},
    {id:5,nombre:"innovation",src:"../../../../../assets/images/nodes/innovation.png", tipo:""},
    {id:6,nombre:"lyrics",src:"../../../../../assets/images/nodes/lyrics_m.png", tipo:""},
    {id:7,nombre:"check",src:"../../../../../assets/images/nodes/check.png", tipo:""},
    {id:8,nombre:"foco",src:"../../../../../assets/images/nodes/foco.png", tipo:""},
    {id:9,nombre:"police",src:"../../../../../assets/images/nodes/police.png",tipo:""},
    {id:10,nombre:"function",src:"../../../../../assets/images/nodes/function.png", tipo:""},
    {id:11,nombre:"music",src:"../../../../../assets/images/nodes/music.png", tipo:""},
    {id:12,nombre:"waves",src:"../../../../../assets/images/nodes/waves.png", tipo:""},
    {id:13,nombre:"force",src:"../../../../../assets/images/nodes/force.png", tipo:""},
    {id:14,nombre:"justice",src:"../../../../../assets/images/nodes/justice.png", tipo:""},
    {id:15,nombre:"red",src:"../../../../../assets/images/nodes/red.png", tipo:""},
    {id:16,nombre:"integral",src:"../../../../../assets/images/nodes/integral.png", tipo:""}]
    }

    closeModal(sendData?: any) {
        this.activeModal.close(sendData);
      }
}
