export class OptionsModel{
	idSurveyQuestionOption: number;
	idSurveyQuestion: number;
	ordinal: number;
	text: string;
	selected?: boolean;

	constructor(
		idSurveyQuestionOption: number,
		idSurveyQuestion: number,
		ordinal: number,
		text: string,
	){
		this.idSurveyQuestionOption = idSurveyQuestionOption || 0;
		this.idSurveyQuestion = idSurveyQuestion || 0;
		this.ordinal = ordinal || 0;
		this.text = text || "";
	}
}
