import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MenuHamburger } from 'src/app/core/models/shared/menu-hamburger.model';

@Component({
  selector: 'app-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HamburgerMenuComponent implements OnInit {

  @Input() menuItems: MenuHamburger[]
  @Input() position: string
  @Output() clickFunction: EventEmitter<string> = new EventEmitter<string>()
  isOpenMenu:boolean = false

  constructor() { }

  ngOnInit() {
  }

  clickElement(value:string): void{
    this.clickFunction.emit(value)
  }

}
