export class MenuRol {
    title: string;
    icon:  string;
    profile: string;
    showInMenu: boolean;
    clickFunction: any | null;
    submenu?: MenuRol[];
    customClass?:string;
    tooltipText?:string;
    numberPosition?:number

    constructor(title: string, icon:string, profile:string, showInMenu: boolean, clickFunction?:any, submenu?:MenuRol[], customClass?:string, tooltipText?:string, numberPosition?:number){
        this.title = title
        this.icon = icon
        this.profile = profile
        this.showInMenu = showInMenu
        this.clickFunction = clickFunction || null
        this.submenu = submenu || []
        this.customClass = customClass || ''
        this.tooltipText = tooltipText || title
        this.numberPosition = numberPosition || null
    }
}
