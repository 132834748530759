<nb-card class="mb-0 no-edge-card">
	<nb-card-header class="flex justify-content-between border-bottom-0">
		<div class="col-11">
			<!-- <div> <h5>{{ 'ACEPTARCANCELAR.SELECCIONEOPCION' | translate }}</h5></div> -->
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal()" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>
	<nb-card-body>
	<div class="m-2 row d-flex">

			<div class="col-12 d-flex justify-content-center">
				<span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'QUIZSOCKETPREVIEW.ENDCHALLENGEMODAL' | translate	}}</span>
			</div>
		</div>

	</nb-card-body>
	<nb-card-footer class="border-top-0">
		<div class="mb-2 row justify-content-center">
			<div class="col-12 row" align="center">
				<div class="col-6"><button nbButton (click)="cancelar()" class="mr-1 btn-themecolor">{{ 'ACEPTARCANCELAR.CANCELAR' | translate	}}</button></div>
				<!-- <div class="col-4"><button nbButton (click)="anular()" class="mr-1 btn-red">{{ 'SOCKETQUIZPREVIEW.ANULAR' | translate	}}</button></div> -->
				<div class="col-6"><button nbButton (click)="finalizar()" class="mr-1 btn-red">{{ 'SOCKETQUIZPREVIEW.FINALIZE' | translate	}}</button></div>
			</div>
		</div>
	</nb-card-footer>
</nb-card>
