import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import Quill from 'quill'
import { Subscription } from 'rxjs';
import { TINYAPYKEY } from 'src/app/core/models/masters/masters.enum';
// Load WIRISplugins.js dynamically
const jsDemoImagesTransform = document.createElement('script');
jsDemoImagesTransform.type = 'text/javascript';
jsDemoImagesTransform.src = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
document.head.appendChild(jsDemoImagesTransform);
@Component({
	selector: "app-node-detail-files-text-edit",
	templateUrl: "./node-detail-files-text-edit.component.html",
	styleUrls: ["./node-detail-files-text-edit.component.scss"],
})
export class NodeDetailFilesTextEditComponent implements OnInit, OnDestroy {
	@Input() value: string = "";
	@Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
	modules: {};
	form: UntypedFormGroup;
	valueChangesSubscription: Subscription;
	tinyEditorApiKey: string = TINYAPYKEY;

	public options: Object = {
		selector: "#editor",
		height: '100%', // Root for resources
		suffix: ".min",
		plugins: [
			"advlist",
			"autolink",
			"lists",
			"link",
			"image",
			"charmap",
			"preview",
			"anchor",
			"searchreplace",
			"visualblocks",
			"code",
			"fullscreen",
			"insertdatetime",
			"media",
			"table",
			"help",
			"wordcount",
			"tiny_mce_wiris",
		],
		external_plugins: { tiny_mce_wiris: 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js' },
		toolbar:
			"undo redo | blocks | " +
			"bold italic backcolor | alignleft aligncenter " +
			"alignright alignjustify | bullist numlist outdent indent | " +
			"removeformat | help" +
			"tiny_mce_wiris_formulaEditor | tiny_mce_wiris_formulaEditorChemistry",
		draggable_modal: true,
		extended_valid_elements: "*[.*]",
		content_style:
			"body { font-size:16px }",
		language: "es",
	};

	constructor(private fb: UntypedFormBuilder) {
		this.modules = {
			toolbar: [
				[{ header: [1, 2, false] }],
				["bold", "italic", "underline"],
				["image", "code-block"],
			],
		};
	}

	ngOnInit() {
		this.form = this.fb.group({
			content: this.value,
		});

		this.valueChangesSubscription = this.form
			.get("content")
			.valueChanges.subscribe((value) => this.valueChange.emit(value));
	}

	ngOnDestroy(): void {
		this.valueChangesSubscription.unsubscribe();
	}

	setFocus(editor) {
		editor.focus();
	}
}
