<form class="container" [formGroup]="optionForm" *ngIf="QuizTypes.TEXT">
    <ng-container formArrayName="options">
        <div class="row">

            <ng-container [ngSwitch]="quizType">
                <!-- QUIZZ DE INTRODUCIR DATOS -->
                <div class="col-12" *ngSwitchCase="QuizTypes.TEXT">
                    <div class="col-12 d-flex align-items-center">
                        <div class="col-11 d-flex flex-column">
													<label>{{'QUIZZES.RESPUESTA' | translate}}</label>
                        	<textarea nbInput fullWidth class="form-control" autocomplete="off" rows="4" maxlength="250"></textarea>
												</div>
                        <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, 1 , false)">
                            <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                        </button>
                    </div>
                </div>

								<div class="col-12" *ngSwitchCase="QuizTypes.IA">
									<div class="col-12 d-flex align-items-center">
											<div class="col-11 d-flex flex-column">
												<label>{{'QUIZZES.RESPUESTA' | translate}}</label>
												<textarea nbInput fullWidth class="form-control" autocomplete="off" rows="4" maxlength="250"></textarea>
											</div>
											<button mat-icon-button matSuffix (click)="onOpenHypermedia($event, 1 , false)">
													<mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
											</button>
									</div>
							</div>

                <div class="row-icon" *ngSwitchCase="QuizTypes.TEXT">
                    <div *ngFor="let option of  optionsArray.controls; let i = index" (click)="onDeleteClick(i)" title="{{'GLOBALS.DELETE' | translate}}">
                        <div class="items">
                            <mat-icon title="{{'GLOBALS.DELETE' | translate}}" class="col1" class *ngIf="option.value.type === O_VIDEOS"  color="primary">videocam</mat-icon>
                            <mat-icon title="{{'GLOBALS.DELETE' | translate}}" class="col1" *ngIf="option.value.type === O_PICTURES"  color="primary">image</mat-icon>
                            <mat-icon title="{{'GLOBALS.DELETE' | translate}}" class="col1"  *ngIf="option.value.type === O_AUDIOS"  color="primary">volume_up</mat-icon>
                            <mat-icon title="{{'GLOBALS.DELETE' | translate}}" class="col1"  *ngIf="option.value.type === O_PDFS"  color="primary">picture_as_pdf</mat-icon>
                            <span *ngIf="option.value.type === O_VIDEOS">
                                {{'QUIZZES.VIDEO' | translate}}
                                <mat-icon class="mat-icon-white">delete</mat-icon>
                            </span>
                            <span *ngIf="option.value.type === O_PICTURES">
                                {{'QUIZZES.IMAGEN' | translate}}
                                <mat-icon class="mat-icon-white">delete</mat-icon>
                            </span>
                            <span *ngIf="option.value.type === O_AUDIOS">
                                {{'QUIZZES.AUDIO' | translate}}
                                <mat-icon class="mat-icon-white">delete</mat-icon>
                            </span>
                            <span *ngIf="option.value.type === O_PDFS">
                                {{'QUIZZES.DOCUMENTO' | translate}}
                                <mat-icon class="mat-icon-white">delete</mat-icon>
                            </span>
                        </div>

                    </div>

                </div>

            </ng-container>
        </div>

  </ng-container>
</form>


<form class="container" [formGroup]="optionForm">
    <ng-container formArrayName="options">
        <div class="row" *ngFor="let option of optionsArray.controls; let i = index;">
            <ng-container [ngSwitch]="quizType">

                <!-- QUIZZES DE SELECCIÓN ÚNICA -->
                <div class="col-12 flex align-items-center" [formGroupName]="i" *ngSwitchCase="QuizTypes.SINGLE">

                    <nb-radio-group [formControl]="correctOptionControl" name="option">
                        <nb-radio [disabled]="!option.get('id').value" [value]="option.get('auxId').value" (click)="onSingleSelect(i)"></nb-radio>
                    </nb-radio-group>

                    <ng-container [ngSwitch]="optionsArrayType[i].value">
                        <div class="col-12 d-flex align-items-center" *ngSwitchCase="elementTypes.O_TEXTS">
                            <div class="col-11 d-flex flex-column">
															<label>{{'QUIZZES.RESPUESTA' | translate}} {{i + 1}}</label>
                            	<textarea nbInput fullWidth class="form-control" [name]="'option' + i" formControlName="data" (blur)="onTextBlur(i)" rows="4" maxlength="250"></textarea>
														</div>
                            <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                                <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                            </button>
                        </div>

                        <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_PICTURES">

                            <div ngResizable (rzStop)="onResizeStop($event,i)" [style.width]="optionsArrayWidth[i].value ? optionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="optionsArrayHeight[i].value ? optionsArrayHeight[i].value + 'px' : '100%'">
                                <img class="w-100 h-100" [src]="optionsArrayData[i].value| quizElements:'image' |safe" />
                            </div>

                            <mat-progress-bar mode="buffer" *ngIf="!optionsArrayData[i].value"></mat-progress-bar>
                            <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                                <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                            </button>
                            <div class="h-100 flex justify-content-center align-items-end">
                                <button mat-icon-button class="ml-2" *ngIf="(i + 1) > 1 && (optionsArray.controls.length - 1) !== i" (click)="onDeleteClick(i)">
                                    <mat-icon class="mat-icon-white">delete</mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_VIDEOS">

                            <div ngResizable (rzStop)="onResizeStop($event,i)" [style.width]="optionsArrayWidth[i].value ? optionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="optionsArrayHeight[i].value ? optionsArrayHeight[i].value + 'px' : '100%'">
                                <video class="w-100 h-100" controls [src]="optionsArrayData[i].value | quizElements:'video' | safe" *ngIf="optionsArrayData[i].value">
                                </video>
                            </div>

                            <mat-progress-bar mode="buffer" *ngIf="!optionsArrayData[i].value"></mat-progress-bar>
                            <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                                <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                            </button>
                            <div class="h-100 flex justify-content-center align-items-end">
                                <button mat-icon-button class="ml-2" *ngIf="(i + 1) > 1 && (optionsArray.controls.length - 1) !== i" (click)="onDeleteClick(i)">
                                    <mat-icon class="mat-icon-white">delete</mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_AUDIOS">
                            <audio class="w-100 d-block" controls [src]="optionsArrayData[i].value | quizElements:'audio' | safe" *ngIf="optionsArrayData[i].value">
                            </audio>
                            <mat-progress-bar mode="buffer" *ngIf="!optionsArrayData[i].value"></mat-progress-bar>
                            <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                                <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                            </button>
                            <div class="h-100 flex justify-content-center align-items-end">
                                <button mat-icon-button class="ml-2" *ngIf="(i + 1) > 1 && (optionsArray.controls.length - 1) !== i" (click)="onDeleteClick(i)">
                                    <mat-icon class="mat-icon-white">delete</mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_PDFS">
                            <div ngResizable (rzStop)="onResizeStop($event,i)" [style.width]="optionsArrayWidth[i].value ? optionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="optionsArrayHeight[i].value ? optionsArrayHeight[i].value + 'px' : '100%'">
                                <iframe #pdfElement class="w-100 h-100" [src]="optionsArrayData[i].value | quizElements:'pdf' | safe" allowfullscreen *ngIf="optionsArrayData[i].value"></iframe>
                            </div>
                            <mat-progress-bar mode="buffer" *ngIf="!optionsArrayData[i].value"></mat-progress-bar>
                            <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                                <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                            </button>
                            <div class="h-100 flex justify-content-center align-items-end">
                                <button mat-icon-button class="ml-2" *ngIf="(i + 1) > 1 && (optionsArray.controls.length - 1) !== i" (click)="onDeleteClick(i)">
                                    <mat-icon class="mat-icon-white">delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="optionsArrayType[i].value === elementTypes.O_TEXTS">
                        <button mat-icon-button class="ml-2" *ngIf="(i + 1) > 1 && (optionsArray.controls.length - 1) !== i" (click)="onDeleteClick(i)">
                            <mat-icon class="mat-icon-white">delete</mat-icon>
                        </button>
                    </div>
                </div>

                <!-- QUIZZES MULTIPLES -->
                <div class="col-12 flex align-items-center" [formGroupName]="i" *ngSwitchCase="QuizTypes.MULTIPLE">
                    <nb-checkbox status="primary" formControlName="isCorrect"
											(blur)="onTextBlur(i)">
                    </nb-checkbox>

                    <ng-container [ngSwitch]="optionsArrayType[i].value">
                        <div class="col-12 d-flex align-items-center" *ngSwitchCase="elementTypes.O_TEXTS">
                            <div class="col-11 d-flex flex-column">
															<label>{{'QUIZZES.RESPUESTA' | translate}} {{i + 1}}</label>
                            	<textarea nbInput fullWidth class="form-control" [name]="'option' + i" matInput formControlName="data" (blur)="onTextBlur(i)" rows="4" maxlength="250"></textarea>
														</div>
                            <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                                <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                            </button>
                        </div>

                        <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_PICTURES">
                            <div ngResizable (rzStop)="onResizeStop($event,i)" [style.width]="optionsArrayWidth[i].value ? optionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="optionsArrayHeight[i].value ? optionsArrayHeight[i].value + 'px' : '100%'">
                                <img class="w-100 h-100" [src]="optionsArrayData[i].value| quizElements:'image' |safe" />
                            </div>
                            <mat-progress-bar mode="buffer" *ngIf="!optionsArrayData[i].value"></mat-progress-bar>
                            <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                                <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                            </button>
                            <div>
                                <button mat-icon-button class="ml-2" *ngIf="(i + 1) > 1 && (optionsArray.controls.length - 1) !== i" (click)="onDeleteClick(i)">
                                    <mat-icon class="mat-icon-white">delete</mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_VIDEOS">
                            <div ngResizable (rzStop)="onResizeStop($event,i)" [style.width]="optionsArrayWidth[i].value ? optionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="optionsArrayHeight[i].value ? optionsArrayHeight[i].value + 'px' : '100%'">
                                <video class="w-100 h-100" controls [src]="optionsArrayData[i].value | quizElements:'video' | safe" *ngIf="optionsArrayData[i].value">
                                </video>
                            </div>
                            <mat-progress-bar mode="buffer" *ngIf="!optionsArrayData[i].value"></mat-progress-bar>
                            <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                                <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                            </button>
                            <div class="h-100 flex justify-content-center align-items-end">
                                <button mat-icon-button class="ml-2" *ngIf="(i + 1) > 1 && (optionsArray.controls.length - 1) !== i" (click)="onDeleteClick(i)">
                                    <mat-icon class="mat-icon-white">delete</mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_AUDIOS">
                            <audio class="w-100 d-block" controls [src]="optionsArrayData[i].value | quizElements:'audio' | safe" *ngIf="optionsArrayData[i].value">
                            </audio>
                            <mat-progress-bar mode="buffer" *ngIf="!optionsArrayData[i].value"></mat-progress-bar>
                            <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                                <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                            </button>
                            <div class="h-100 flex justify-content-center align-items-end">
                                <button mat-icon-button class="ml-2" *ngIf="(i + 1) > 1 && (optionsArray.controls.length - 1) !== i" (click)="onDeleteClick(i)">
                                    <mat-icon class="mat-icon-white">delete</mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_PDFS">
                            <div ngResizable (rzStop)="onResizeStop($event,i)" [style.width]="optionsArrayWidth[i].value ? optionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="optionsArrayHeight[i].value ? optionsArrayHeight[i].value + 'px' : '100%'">
                                <iframe #pdfElement class="w-100 h-100" [src]="optionsArrayData[i].value | quizElements:'pdf' | safe" allowfullscreen *ngIf="optionsArrayData[i].value"></iframe>
                            </div>
                            <mat-progress-bar mode="buffer" *ngIf="!optionsArrayData[i].value"></mat-progress-bar>
                            <button mat-icon-button matSuffix (click)="onOpenHypermedia($event, i)">
                                <mat-icon class="mat-icon-white" color="primary">cloud_upload</mat-icon>
                            </button>
                            <div class="h-100 flex justify-content-center align-items-end">
                                <button mat-icon-button class="ml-2" *ngIf="(i + 1) > 1 && (optionsArray.controls.length - 1) !== i" (click)="onDeleteClick(i)">
                                    <mat-icon class="mat-icon-white">delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <div *ngIf="optionsArrayType[i].value === elementTypes.O_TEXTS">
                        <button mat-icon-button class="ml-2" *ngIf="(i + 1) > 1 && (optionsArray.controls.length - 1) !== i" (click)="onDeleteClick(i)">
                            <mat-icon class="mat-icon-white">delete</mat-icon>
                        </button>
                    </div>
                </div>

            </ng-container>
        </div>
    </ng-container>
</form>
