import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { gNode } from 'src/app/core/models/graph/gNode.model';


@Component({
  selector: 'app-node-forms',
  templateUrl: './node-form.component.html',
  styleUrls: ['./node-form.component.scss']
})
export class NodeFormsComponent implements OnInit {

  public node: UntypedFormGroup;

  public submitted = false;

  public editName = false;

  public img: string;

  @Input() nodeData : gNode;
  @Output() send = new EventEmitter<boolean>();
  @Output() newNode = new EventEmitter<gNode>();

  constructor() { }

  
  ngOnInit() {

    if (this.nodeData){

        this.node = new UntypedFormGroup({
          name: new UntypedFormControl(this.nodeData.label, Validators.required),
          description: new UntypedFormControl(this.nodeData.description),
          level: new UntypedFormControl(this.nodeData.nodeSwlevel),
          duration: new UntypedFormControl(this.minutesToTime(this.nodeData.duration))
        });

        this.img = this.nodeData.url;
    } else {
        this.node = new UntypedFormGroup({
          name: new UntypedFormControl('', Validators.required),
          description: new UntypedFormControl(''),
          level: new UntypedFormControl(0),
          duration: new UntypedFormControl('00:00')
        });
    }
  }

  public onSubmit(){
    this.submitted = !this.submitted;
  }

  public editNode(){
    this.submitted = !this.submitted;
  }

  newImage($event) {
    if (this.nodeData) {
      this.nodeData.url = $event;
    }
  }

  newBackground($event) {
    if (this.nodeData) {
      this.nodeData.backgroundColor = $event;
    }
  }


  public minutesToTime( min = 0 ) {
    var h = Math.trunc(min/60).toString();
    var m = Math.trunc(min % 60).toString();
    

    if (m.length < 2) {
      m = '0' + m;
    }

    if (h.length < 2) {
      h = '0' + h;
    }

    return h + ':' + m;
  }

  public durationToMinutes() {
    var t =  this.node.value.duration.split(':');
    return (t[0] * 60) + t[1];
  }

  public finished($event){
    if (this.nodeData) {
      this.nodeData.label = this.node.value.name;
      this.nodeData.description = this.node.value.description;
      this.nodeData.nodeSwlevel = this.node.value.level;
      this.nodeData.duration = this.durationToMinutes();

      this.newNode.emit(this.nodeData);
    }
    
    this.send.emit(true);
  }

}


