export const formsValidations = {
    USER_MIN_AGE: 5,
    PASSWORD_MIN_LENGTH: 8,
    NICK_MAX_LENGTH: 20,
		NICK_MIN_LENGTH: 5,
    NAME_MAX_LENGTH: 25,
    SURNAME_MAX_LENGTH: 50,
    EMAIL_MAX_LENGTH: 50,
    PASS_MAX_LENGTH: 50,
    EMAIL_PATTERN: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,20}$',
    PASSWORDPATTERN: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/,
    NICKPATTERN: /^.{6,}$/,
    MOBILE_MAX_LENGTH: 15,
    MOBILE_MIN_LENGTH: 5,
    MOBILE_PATTERN: '^[0-9]*$',
    URL_PATTERN : '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
		QUESTION_PATTERN: '^[0-9]*$',
		ANSWER_MIN_LENGTH: 1,

}
