import { LOCALSTORAGESTRINGS } from "src/app/core/models/masters/localstorage.enum";
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxCopilotService } from "ngx-copilot";
import { environment } from "src/environments/environment";
import { TutotrialDataLocal } from "../../models/tuturial/tutorial.model";
import { User } from "../../models/users/user.models";
import { LoginService } from "../../services/login";
import { LocalStorage } from "../../utils/local-storage";
import { Utils } from "../../utils/utils";
import { GraphService } from "../../services/graph/graph.service";
import { TargetsService } from "../../services/targets";
import { SigmaToolbarsService } from "../../services/sigma-toolbars/sigma-toolbars.service";
@Component({
	selector: "app-side-bar",
	templateUrl: "./side-bar.component.html",
	styleUrls: ["./side-bar.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class SideBarComponent implements OnInit, OnDestroy {
	public arrayValues: number[] = [];
	public usuario: User;

	cpPosition: any;
	sidebarData: boolean;
	urlTutorial: string = environment.tutorial;
	graphVisited_Student: [] = [];
	graphVisited_Editor: [] = [];
	lastTarget: number;
	lastTarget_Editor: number;

	public constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private modalService: NgbModal,
		public translateService: TranslateService,
		public loginService: LoginService,
		private localStorage: LocalStorage,
		private copilot: NgxCopilotService,
		private localStorageService: LocalStorage,
		private utils: Utils,
		private graphServ: GraphService,
		public targetsService: TargetsService,
		public toolsService: SigmaToolbarsService
	) {
		this.activatedRoute.params.subscribe((params) => {
			// De esta forma cada vez que cambia el parametro lo actualizo
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		});
	}
	public ngOnInit() {
		this._traducirOpciones();
		this.tutorialDisponible();
		// obtenemos la info del grafo visto anteriormente
		this.obtainLastTargetsVisited();
	}

	obtainLastTargetsVisited() {
		this.graphVisited_Student =
			JSON.parse(this.localStorage.getItem("graphVisited_Student")) || [];
		this.graphVisited_Editor =
			JSON.parse(this.localStorage.getItem("graphVisited_Editor")) || [];
		this.lastTarget = JSON.parse(this.localStorage.getItem("lastTarget")); //idTarget del grafo actual
		this.lastTarget_Editor = JSON.parse(
			this.localStorage.getItem("lastTarget_Editor")
		);
	}

	ngOnDestroy(): void {}

	tutorialDisponible() {
		if (this.loginService.comprobarTutorial()) {
			let canvasGrafoTutorial = JSON.parse(
				this.localStorageService.getItem(LOCALSTORAGESTRINGS.TUTORIALDATA)
			) as TutotrialDataLocal;
			this.sidebarData = canvasGrafoTutorial.sideBarData;
			this.abrirTutorial();
		} else {
			this.sidebarData = true;
		}
	}
	abrirTutorial() {
		if (!this.sidebarData) {
			setTimeout(() => {
				this.initPosition(10);
			}, 500);
		}
	}
	/*Re initialize in specify step*/
	initPosition = (stepNumber: any) => {
		this.copilot.checkInit(stepNumber);
	};
	nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

	/*Finish*/
	done = () => {
		this.copilot.removeWrapper();
		let cursoTotorial = JSON.parse(
			this.localStorageService.getItem(LOCALSTORAGESTRINGS.TUTORIALDATA)
		) as TutotrialDataLocal;
		cursoTotorial.sideBarData = true;
		this.loginService.updateDataLocalTutorial(cursoTotorial);
	};

	private _traducirOpciones(): void {
		const lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
		this.translateService.use(lang);
	}

	logout(): void {
		this.loginService.logout(true);
	}

	verGrafoAnterior() {
		let targetList: { idGrafo: any; idCurso: any; title: any }[] =
			JSON.parse(this.localStorage.getItem("graphVisited_Student")) || [];
		let previousTarget: any;
		if (targetList.length > 0) {
			let pos = 0;
			//miramos si el elemento esta en la lista y mostramos el grafo anterior (el siguiente en la lista)
			for (let index = 0; index < targetList.length; index++) {
				const element = targetList[index];
				if (this.lastTarget === element.idGrafo) {
					pos = index + 1;
					break;
				}
			}
			let havePrevious = targetList[pos] ? true : false;
			if (pos < targetList.length) {
				previousTarget = targetList[pos];
				//abrimos el grafo
				this.graphServ.getGraphInfo(previousTarget.idGrafo).subscribe((res) => {
					const viewType = res.data.viewType;
					this.graphServ.changedefaultTypeView(res.data.viewType);
					this.router
						.navigateByUrl(
							`/course/${previousTarget.idCurso}/graph/${previousTarget.idGrafo}`
						)
						.then((res) => {
							setTimeout(() => {
								localStorage.setItem("dontLoadMenu", "true");
								this.localStorage.setItem("lastTarget", previousTarget.idGrafo);
								this.utils.loadMenu = false;

								this.toolsService.changeUpdateGraph(true);
								this.obtainLastTargetsVisited();
								//window.location.reload();
								// this.sigmaCanvasService.setTriggerInitSigma(true);
							}, 100);
						});
					this.modalService.dismissAll();
				});
				this.targetsService.registercountopengraph().subscribe((res) => {});
			}
		}
	}

	verGrafoSiguiente() {
		let targetList: { idGrafo: any; idCurso: any; title: any }[] =
			JSON.parse(this.localStorage.getItem("graphVisited_Student")) || [];
		let nextTarget: any;
		if (targetList.length > 0) {
			let pos = 0;
			//miramos si el elemento esta en la lista, si esta mostramos el grafo siguiente (anterior en la lista), si no (o es el segundo) mostramos el primero de la lista
			for (let index = 0; index < targetList.length; index++) {
				const element = targetList[index];
				if (this.lastTarget === element.idGrafo) {
					pos = index - 1;
					break;
				}
			}
			if (pos >= 0) {
				nextTarget = targetList[pos];
				//abrimos el grafo
				this.graphServ.getGraphInfo(nextTarget.idGrafo).subscribe((res) => {
					const viewType = res.data.viewType;
					this.graphServ.changedefaultTypeView(res.data.viewType);
					this.router
						.navigateByUrl(
							`/course/${nextTarget.idCurso}/graph/${nextTarget.idGrafo}`
						)
						.then((res) => {
							setTimeout(() => {
								localStorage.setItem("dontLoadMenu", "true");
								this.localStorage.setItem("lastTarget", nextTarget.idGrafo);
								this.utils.loadMenu = false;
								//window.location.reload();
								this.toolsService.changeUpdateGraph(true);
								this.obtainLastTargetsVisited();
								// this.sigmaCanvasService.setTriggerInitSigma(true);
							}, 100);
						});
					this.modalService.dismissAll();
				});
				this.targetsService.registercountopengraph().subscribe((res) => {});
			}
		}
	}

	verGrafoAnteriorEditor() {
		let targetList: { idGrafo: any; idCurso: any; title: any }[] =
			JSON.parse(this.localStorage.getItem("graphVisited_Editor")) || [];
		let previousTarget: any;
		if (targetList.length > 0) {
			let pos = 0;
			//miramos si el elemento esta en la lista y mostramos el grafo anterior (el siguiente en la lista)
			for (let index = 0; index < targetList.length; index++) {
				const element = targetList[index];
				if (this.lastTarget_Editor === element.idGrafo) {
					pos = index + 1;
					break;
				}
			}
			let havePrevious = targetList[pos] ? true : false;
			if (pos < targetList.length) {
				previousTarget = targetList[pos];
				//abrimos el grafo
				this.graphServ.getGraphInfo(previousTarget.idGrafo).subscribe((res) => {
					const viewType = res.data.viewType;
					this.graphServ.changedefaultTypeView(res.data.viewType);
					this.router
						.navigateByUrl(
							`/course/${previousTarget.idCurso}/graph/${previousTarget.idGrafo}`
						)
						.then((res) => {
							setTimeout(() => {
								localStorage.setItem("dontLoadMenu", "true");
								this.localStorage.setItem(
									"lastTarget_Editor",
									previousTarget.idGrafo
								);
								this.utils.loadMenu = false;
								this.toolsService.changeUpdateGraph(true);
								this.obtainLastTargetsVisited();
								//window.location.reload();
								// this.sigmaCanvasService.setTriggerInitSigma(true);
							}, 100);
						});
					this.modalService.dismissAll();
				});
				this.targetsService.registercountopengraph().subscribe((res) => {});
			}
		}
	}

	verGrafoSiguienteEditor() {
		let targetList: { idGrafo: any; idCurso: any; title: any }[] =
			JSON.parse(this.localStorage.getItem("graphVisited_Editor")) || [];
		let nextTarget: any;
		if (targetList.length > 0) {
			let pos = 0;
			//miramos si el elemento esta en la lista, si esta mostramos el grafo siguiente (anterior en la lista), si no (o es el segundo) mostramos el primero de la lista
			for (let index = 0; index < targetList.length; index++) {
				const element = targetList[index];
				if (this.lastTarget_Editor === element.idGrafo) {
					pos = index - 1;
					break;
				}
			}
			if (pos >= 0) {
				nextTarget = targetList[pos];
				//abrimos el grafo
				this.graphServ.getGraphInfo(nextTarget.idGrafo).subscribe((res) => {
					const viewType = res.data.viewType;
					this.graphServ.changedefaultTypeView(res.data.viewType);
					this.router
						.navigateByUrl(
							`/course/${nextTarget.idCurso}/graph/${nextTarget.idGrafo}`
						)
						.then((res) => {
							setTimeout(() => {
								localStorage.setItem("dontLoadMenu", "true");
								this.localStorage.setItem(
									"lastTarget_Editor",
									nextTarget.idGrafo
								);
								this.utils.loadMenu = false;
								this.toolsService.changeUpdateGraph(true);
								this.obtainLastTargetsVisited();
								//window.location.reload();
								// this.sigmaCanvasService.setTriggerInitSigma(true);
							}, 100);
						});
					this.modalService.dismissAll();
				});
				this.targetsService.registercountopengraph().subscribe((res) => {});
			}
		}
	}
}
