import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PointNodeService {
  public pointNodeActive = new Subject();
  public currentPos: any;
  public nextBackNode = { avanzar: false, regresar: false}

  constructor() { }

  public get pointCurrentNode(): any {
    return this.currentPos;
  }
  public set pointCurrentNode(value:any) {
    this.currentPos = value;
  }

  public emitNextPointNodeActive(nextBackNode) {
      this.pointNodeActive.next(nextBackNode);
  }

	private nowIndexSource = new BehaviorSubject<number>(0);
  nowIndex$ = this.nowIndexSource.asObservable();

  setNowIndex(value: number) {
    this.nowIndexSource.next(value);
  }

}
