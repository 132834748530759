<mat-dialog-content class="mat-typography" style="padding: 2rem 3rem;">
    <div class="sqs-block-content">

        <p class="" style="white-space:pre-wrap;"><strong>Política de cookies:</strong></p>
        <p class="" style="white-space:pre-wrap;">Cookie es un fichero que se descarga en su ordenador al acceder a
            determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar
            información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información
            que
            contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario. El navegador
            del
            usuario memoriza cookies en el disco duro solamente durante la sesión actual ocupando un espacio de memoria
            mínimo y no perjudicando al ordenador. Las cookies no contienen ninguna clase de información personal
            específica, y la mayoría de las mismas se borran del disco duro al finalizar la sesión de navegador (las
            denominadas cookies de sesión).</p>
        <p class="" style="white-space:pre-wrap;">La mayoría de los navegadores aceptan como estándar a las cookies y,
            con
            independencia de las mismas, permiten o impiden en los ajustes de seguridad las cookies temporales o
            memorizadas.</p>
        <p class="" style="white-space:pre-wrap;">Sin su expreso consentimiento, mediante la activación de las cookies
            en su
            navegador,&nbsp;<em>Asociación Psycast </em>no enlazará en las cookies los datos memorizados con sus datos
            personales proporcionados en el momento del registro.</p>
        <p class="" style="white-space:pre-wrap;"><strong>¿Qué tipos de cookies utiliza esta página web?</strong></p>
        <p class="" style="white-space:pre-wrap;">1.&nbsp;&nbsp;&nbsp;&nbsp; Cookies técnicas: Son aquéllas que permiten
            al
            usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes
            opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos,
            identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un
            pedido,
            realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un
            evento,
            utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de videos o
            sonido
            o compartir contenidos a través de redes sociales.</p>
        <p class="" style="white-space:pre-wrap;">2.&nbsp;&nbsp;&nbsp;&nbsp; Cookies de personalización: Son aquéllas
            que
            permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en
            función
            de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador
            a
            través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.</p>
        <p class="" style="white-space:pre-wrap;">3.&nbsp;&nbsp;&nbsp;&nbsp; Cookies de análisis: Son aquéllas que bien
            tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la
            medición
            y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza
            su
            navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.
        </p>
        <p class="" style="white-space:pre-wrap;">4.&nbsp;&nbsp;&nbsp;&nbsp; Cookies publicitarias: Son aquéllas que,
            bien
            tratadas por nosotros o por terceros, nos permiten gestionar de la forma más eficaz posible la oferta de los
            espacios publicitarios que hay en la página web, adecuando el contenido del anuncio al contenido del
            servicio
            solicitado o al uso que realice de nuestra página web. Para ello podemos analizar sus hábitos de navegación
            en
            Internet y podemos mostrarle publicidad relacionada con su perfil de navegación.</p>
        <p class="" style="white-space:pre-wrap;">5.&nbsp;&nbsp;&nbsp;&nbsp; Cookies de publicidad comportamental: Son
            aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su
            caso,
            el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio
            solicitado.
            Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación
            continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar
            publicidad
            en función del mismo.</p>
        <p class="" style="white-space:pre-wrap;"><strong>Cookies de terceros</strong>: La Web de&nbsp;<em>Psycast
            </em>puede utilizar servicios de terceros que, por cuenta de&nbsp;<em>Psycast</em>, recopilaran información
            con
            fines estadísticos, de uso del Site por parte del usuario y para la prestación de otros servicios
            relacionados
            con la actividad del Website y otros servicios de Internet.</p>
        <p class="" style="white-space:pre-wrap;">En particular, este sitio Web utiliza Squarespace Analytics y Google
            Analytics, un servicio analítico de web prestado por éstas compañías, Squarespace con domicilio en los
            Estados
            Unidos 8 Clarkson St, New York, NY 10014 y Google, Inc. con domicilio en los Estados Unidos con sede central
            en
            1600 Amphitheatre Parkway, Mountain View, California 94043. Para la prestación de estos servicios, estos
            utilizan cookies que recopilan la información, incluida la dirección IP del usuario, que será transmitida,
            tratada y almacenada por Google en los términos fijados en la Web Google.com. Incluyendo la posible
            transmisión
            de dicha información a terceros por razones de exigencia legal o cuando dichos terceros procesen la
            información
            por cuenta de Google.</p>
        <p class="" style="white-space:pre-wrap;"><strong>El Usuario acepta expresamente, por la utilización de este
                Site,
                el tratamiento de la información recabada en la forma y con los fines anteriormente
                mencionados.</strong>&nbsp;Y asimismo reconoce conocer la posibilidad de rechazar el tratamiento de
            tales
            datos o información rechazando el uso de Cookies mediante la selección de la configuración apropiada a tal
            fin
            en su navegador. Si bien esta opción de bloqueo de Cookies en su navegador puede no permitirle el uso pleno
            de
            todas las funcionalidades del Website.</p>
        <p class="" style="white-space:pre-wrap;">Puede usted permitir, bloquear o eliminar las cookies instaladas en su
            equipo mediante la configuración de las opciones del navegador instalado en su ordenador: <a
                href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647"><strong>Google
                    Chrome</strong></a>, <a
                href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9"><strong>Internet
                    Explorer</strong></a>, <a
                href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"><strong>Mozilla
                    Firefox</strong></a>, <a href="http://support.apple.com/kb/ph5042"><strong>Safari</strong></a></p>
        <p class="" style="white-space:pre-wrap;">Si tiene dudas sobre esta política de cookies, puede contactar
            con&nbsp;<em>Psycast</em>&nbsp;en&nbsp;info@psycast.es</p>
        <p class="" style="white-space:pre-wrap;">&nbsp;</p>
        <p class="" style="white-space:pre-wrap;">&nbsp;</p>


    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
</mat-dialog-actions>
