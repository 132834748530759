
import { ActionTypes, ActionsUnion } from '../actions/profiles.action';
import { initialAvailableProfiles } from '../models/profiles.model';

export function profilesReducer(state = initialAvailableProfiles, action: ActionsUnion) {
  switch (action.type) {
    case ActionTypes.loadProfiles:
      return action.payload;

    default:
			return state;
  }
}
