export const ACTIONS = {
    ZOOMIN : 'zoomIn',
    ZOOMOUT : 'zoomOut',
    CENTERGRAPH : 'centerGraph',
    CLOSEMODAL : 'closeModal',
    STAIRS : 'stairs',
    HELP : 'help',
    ACTIVITIES : 'activities',
    CLOSE : 'close',
    CHECK : 'check',
    IDEA : 'idea',
    UP : 'up',
    DOWN : 'down',
    BACK : 'back',
    NEXT : 'next',
    ARROWUP : 'ArrowUp',
    ARROWDOWN : 'ArrowDown',
    ARROWRIGHT : 'ArrowRight',
    ARROWLEFT : 'ArrowLeft',
    SHOWHIDEPADS : 'showHidePads',
    SHOWHIDELABELS : 'showHideLabels',
    PRINT : 'print',
		CHALLENGE3: 'challengeCase3'
}
