<div (click)="handleClick()" [ngClass]="{'select-question': editMode }">
	<!--===== MODO VISUALIZACION =====-->
	<!--==============================-->
	<nb-card *ngIf="!editMode" (click)="changeMode()" class="container-question pointer mb-0">
		<nb-card-header class="border-bottom-0">
			<div class="row">
				<div class="col-10 modal-title">
					<h6>{{ question.text }}</h6>
				</div>
			</div>
		</nb-card-header>
		<!--========== PREGUNTA TIPO 1 - ESCALA LIKERT ==========-->
		<nb-card-body class="d-flex justify-content-center align-items-center" *ngIf="question.type == 1">
			<div class="d-flex gap-3 flex-md-row flex-column align-items-start align-items-md-center justify-content-center w-100">
				{{ question.initialTag }}

				<div *ngFor="let option of likertOptions" class="d-flex flex-md-column align-items-center">
					<div class="text-center" style="min-width: 18px;">
						<label>{{ option }}</label>
					</div>
					<div class="pl-md-0 pl-2">
						<input type="radio" />
						<label class="m-0"></label>
					</div>
				</div>

				{{ question.finalTag }}
			</div>
		</nb-card-body>
		<!--========== PREGUNTA TIPO 2 - SELECCION UNICA ==========-->
		<nb-card-body class="d-flex align-items-center" *ngIf="question.type == 2">
			<div class="d-flex">
				<nb-radio-group [(ngModel)]="option">
					<nb-radio
						*ngFor="let option of question.options"
						[value]="option.value">
						{{ option.text }}
					</nb-radio>
				</nb-radio-group>
			</div>
		</nb-card-body>
		<!--========== PREGUNTA TIPO 3 - SELECCION MULTIPLE ==========-->
		<nb-card-body class="d-flex align-items-center" *ngIf="question.type == 3">
			<div class="d-flex flex-column gap-3">
				<div *ngFor="let option of question.options" class="d-flex">
					<nb-checkbox status="basic">
						{{ option.text }}
					</nb-checkbox>
				</div>
			</div>
		</nb-card-body>
		<!--========== PREGUNTA TIPO 4 - RESPUESTA ABIERTA ==========-->
		<nb-card-body class="d-flex justify-content-center align-items-center" *ngIf="question.type == 4">
			<div class="d-flex">
				<textarea nbInput fullWidth rows="4" cols="50"
					[(ngModel)]="openResponseDefault" class="form-control">
				</textarea>
			</div>
		</nb-card-body>
		<!--========== OPCION QUE INDICA SI LA PREGUNTA ES OBLIGATORIA ==========-->
		<nb-card-footer class="border-top-0" *ngIf="question.required == 1">
			<span class="error-message">
				*{{'ENCUESTAS.QUESTIONMANDATORY' | translate}}
			</span>
		</nb-card-footer>
	</nb-card>
	<!--===== MODO EDICION =====-->
	<!--========================-->
	<nb-card *ngIf="editMode" class="container-question mb-0">
		<!--========== TITULO Y TIPO DE PREGUNTA ==========-->
		<nb-card-header class="border-bottom-0">
			<div class="d-flex flex-column gap-4">
				<nb-select name="type" placeholder="{{'ENCUESTAS.QUESTIONTYPE' | translate}}" [(ngModel)]="question.type"
					(ngModelChange)="onEditQuestion()" class="SelectForm">
					<nb-option *ngFor="let type of questionsTypes" [value]="type.id" class="SelectFormOpt">
						{{ type.title }}
					</nb-option>
				</nb-select>

				<textarea nbInput fullWidth [(ngModel)]="question.text" rows="3" maxlength="500" (focus)="selectText($event)"
					(ngModelChange)="onEditQuestion()">
				</textarea>
			</div>
		</nb-card-header>
		<!--========== PREGUNTA TIPO 1 - ESCALA LIKERT ==========-->
		<nb-card-body *ngIf="question.type == 1 && question.type != 4">
			<div class="d-flex flex-wrap gap-3 justify-content-between">
				<div class="d-flex justify-content-center align-items-end">
					<div class="d-flex justify-content-center align-items-center gap-3">
						<p class="m-0">{{ 'ENCUESTAS.FROMONETO' | translate }}</p>
						<nb-select name="type" hero [(ngModel)]="question.likertOptions" (ngModelChange)="onEditQuestion()" class="SelectForm SelectFormNumber">
							<nb-option *ngFor="let num of numbers" [value]="num" class="SelectFormOpt">
								{{ num }}
							</nb-option>
						</nb-select>
					</div>
				</div>
				<div class="d-flex justify-content-center align-items-center gap-4">
					<div class="d-flex flex-column">
						<label>{{ 'ENCUESTAS.TAGINICIAL' | translate }}</label>
						<input nbInput fullWidth [(ngModel)]="question.initialTag" type="text" maxlength="100"
						(ngModelChange)="onEditQuestion()">
					</div>
					<div class="d-flex flex-column">
						<label>{{ 'ENCUESTAS.TAGFINAL' | translate }}</label>
						<input nbInput fullWidth [(ngModel)]="question.finalTag" type="text" maxlength="100"
						(ngModelChange)="onEditQuestion()">
					</div>
				</div>
			</div>
		</nb-card-body>
		<!--========== RESTO DE PREGUNTAS ==========-->
		<nb-card-body *ngIf="question.type != 1 && question.type != 4">
			<div class="d-flex flex-column gap-3 justify-content-center align-items-center">
				<div *ngFor="let option of question.options">
					<div class="d-flex justify-content-center align-items-center gap-3">
						<input nbInput fullWidth type="text" id="exampleFormControlInput1" placeholder="Op" maxlength="100"
							[(ngModel)]="option.text" (focus)="selectText($event)">

						<nb-icon class="question-icons pointer" icon="close-outline" ngbTooltip="{{ 'GLOBALS.UNCHECK' | translate }}"
							(click)="deleteOption(option)">
						</nb-icon>
					</div>
				</div>
				<div class="d-flex justify-content-center align-items-center">
					<nb-icon class="question-icons pointer" icon="plus-circle-outline"
						ngbTooltip="{{ 'ENCUESTAS.OPTIONNEW' | translate }}" (click)="newOption()">
					</nb-icon>
				</div>
			</div>
		</nb-card-body>
		<!--========== OPCIONES DE LA PREGUNTA ==========-->
		<nb-card-footer class="mt-3">
			<div class="d-flex gap-4 justify-content-end align-items-center">
				<nb-icon class="question-icons pointer" icon="eye-off-outline" ngbTooltip="{{ 'GLOBALS.PREVIEW' | translate }}"
					(click)="viewPreview()">
				</nb-icon>

				<nb-icon class="question-icons pointer" icon="arrow-up-outline" ngbTooltip="{{ 'GLOBALS.UP' | translate }}"
				*ngIf="question.ordinal > 1" (click)="changeOrdinal(question.ordinal, question.ordinal - 1)">
				</nb-icon>
				<nb-icon class="question-icons pointer" icon="arrow-down-outline" ngbTooltip="{{ 'GLOBALS.DOWN' | translate }}"
				*ngIf="question.ordinal < totalQuestions" (click)="changeOrdinal(question.ordinal, question.ordinal + 1)">
				</nb-icon>

				<nb-icon class="question-icons pointer" icon="trash-2-outline" ngbTooltip="{{ 'GLOBALS.DELETE' | translate }}"
					(click)="onDelete()">
				</nb-icon>

				<nb-toggle labelPosition="start" [(ngModel)]="obligatory" (ngModelChange)="onToggleChange($event)" class="item-profile">
					{{'GLOBALS.MANDATORY' | translate}}
				</nb-toggle>
			</div>
		</nb-card-footer>
	</nb-card>
</div>
