import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { LoginService } from "src/app/core/services/login";
import { QuizzesService } from "src/app/core/services/quizzes";
import { QuizEditComponent } from "../../quiz-edit/quiz-edit.component";
import { SigmaCanvasService } from "../sigma-canvas.service";
import { SigmaCanvasUtils } from "./canvas.utils";
import { Estado } from 'src/app/core/models/tuturial/tutorial.model';
import { map } from "rxjs/operators";
import { SIGMA_CONSTANTS } from 'src/app/core/utils/sigma-constants';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

declare var $
@Injectable({
    providedIn: 'root'
  })
export class QuizUtils {

	constructor(private loginService: LoginService,
		private sigmaCanvasService: SigmaCanvasService,
		private scUtils: SigmaCanvasUtils,
		private modalService: NgbModal,
		private quizService: QuizzesService,
		private translateService: TranslateService,
		private toaster: ToasterService
	) { }

    public editQuiz(quiz: any, countHelpQuiz:number):Promise<boolean> {

        return new Promise((resolve, error) => {

            this.scUtils.hidePopWindows()
            this.quizService.getQuiz(quiz.idOriginal, quiz.idCourseCreation, quiz.idTargetCreation).subscribe(res => {
                const modalRef = this.modalService.open(QuizEditComponent,
                    {
                        scrollable: true,
                        windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`
                    }
                );

								this.quizService.setCurrentQuiz(res.quiz);

                modalRef.componentInstance.quiz = { ...res.quiz, user: quiz.user, idOriginal: quiz.idOriginal, id: quiz.id, ordinal: quiz.ordinal, originalX: quiz.originalX, originalY: quiz.originalY, size: quiz.size, sizeQuiz: quiz.sizeQuiz, x: quiz.x, y: quiz.y, ordinalType: res.ordinalType };
                modalRef.componentInstance.elements = res.elements;
                modalRef.componentInstance.courseId = this.sigmaCanvasService.courseId;
                modalRef.componentInstance.graphId = this.sigmaCanvasService.graphId;

                if ( this.loginService.getUser().tutorialSW.estado === Estado.PENDIENTE) {
                    if (countHelpQuiz >= 1)
                        modalRef.componentInstance.btnPublicarTutorial = true;
                }


                modalRef.result.then().finally(() => resolve(true));
            });
        })

    }

    public createQuiz():Observable<any>{
        this.scUtils.hidePopWindows()

        var tempSigmaNode = this.sigmaCanvasService.sigmaUtils.sigma.graph.nodes('temp');
        tempSigmaNode.nodeType = SIGMA_CONSTANTS.QUIZ_TYPE;
        tempSigmaNode.type = SIGMA_CONSTANTS.QUIZ_NODE_TYPE;
        tempSigmaNode.quizTittle = ' ';
        tempSigmaNode.isMultiplexed = 0;
        tempSigmaNode.published = Date.now();

        return this.quizService.createQuiz(this.sigmaCanvasService.courseId, this.sigmaCanvasService.graphId, tempSigmaNode).pipe(map((res: any) => res.data));
    }

		public createQuizGenerated(quizData):Observable<any>{
			this.scUtils.hidePopWindows();
			var tempSigmaNode = quizData;
			tempSigmaNode.nodeType = SIGMA_CONSTANTS.QUIZ_TYPE;
			tempSigmaNode.type = SIGMA_CONSTANTS.QUIZ_NODE_TYPE;
			tempSigmaNode.quizTittle = quizData.Titulo;
			tempSigmaNode.isMultiplexed = 0;
			tempSigmaNode.published = Date.now();
			tempSigmaNode.x = quizData.x
			tempSigmaNode.y = quizData.y;
			tempSigmaNode.quizInstructions = quizData.Pregunta;
			tempSigmaNode.quizType = 2;
			tempSigmaNode.durations = 10;

			return this.quizService
				.createQuiz(
					this.sigmaCanvasService.courseId,
					this.sigmaCanvasService.graphId,
					tempSigmaNode
				)
				.pipe(map((res: any) => res.data));
		}

    public certificarQuiz(): Observable<boolean>{

        return new Observable((o) => {
            var e:any = JSON.parse(localStorage.getItem('nodeData'));
            if (e.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE) {
                let idQuiz = e.idOriginal;
                let idCurso = e.idCourseCreation;
                let idGrafo = e.idTargetCreation;

                this.quizService.certificarQuiz(idQuiz, idGrafo, idCurso ).subscribe((res: any) => {
                    this.toaster.success(this.translateService.instant(res.error.msg));
                    this.sigmaCanvasService.sigmaUtils.updateNode({...e, certifiedQuiz: res.data.certifiedQuiz});
                    let value: boolean = res.data.certifiedQuiz ? true : false
                    o.next(value)
                },
                    err => o.error(false)
                )

            } else {
                this.toaster.success(this.translateService.instant('SIGMACOMPONENT.ONLYCERTIFIEDQUIZZES'));
            }

        })
    }

}
