<div *ngIf="!viewQuiz" class="pad-left-container" [ngClass]="isShow ? 'show-pad' : 'hide-pad'">
	<!-- Opcion para ver el informe -->
	<div *ngIf="loginService.esProfesor() && idCurso != 0 && idMapa != 0" class="d-flex align-items-center flex-column">
		<div class="d-flex" (click)="verInforme()">
			<div class="icon-option icon-informes pointer"></div>
		</div>
		<div class="d-flex not-showTitle-mobile">
			<p class="info-course">
				{{('MENUACTIONS.TEACHERTITLE5' | translate)}}
			</p>
		</div>
	</div>
	<!-- Boton para cambiar al modo lista -->
	<div class="d-flex align-items-center flex-column">
		<div class="flex" (click)="emitshowModalCourse('verLista')">
			<nb-icon icon="list" class="pointer"></nb-icon>
		</div>
		<div class="d-flex not-showTitle-mobile">
			<p class="info-course">
				{{('CURSOS.VERARBOL' | translate)}}
			</p>
		</div>
	</div>
</div>

