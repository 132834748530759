
<nb-card class="no-edge-card">
	<nb-card-header class="flex justify-content-between">
		<div><h5>{{ 'USERCARDDATA.TITTLE' | translate }}</h5></div>
		<div></div>

		<nb-icon class="icon-color-blue closebtn pointer text-right m-1 float-right" icon="close-circle-outline"
							[options]="{ animation: { type: 'pulse' } }" (click)="closeModal('')"></nb-icon>
	</nb-card-header>
	<nb-card-body >
		<div class="row d-flex justify-content-center cards-container">
			<div class="col-sm-6 col-md-12 col-12">
				<form [formGroup]="sendCommentsForm" (ngSubmit)="sendComments()" #thisForm="ngForm">
					<div class="row d-flex justify-content-center align-items-center p-2">

						<div class="col-12">

							<div class="card-container">
								<img class="round" [src]="getUserAvatar(user.pictureUser)" alt="user" />
								<h3><strong>	<span>{{user.firstName}} {{user.surname}}</span></strong></h3>

								<div class="info-item">
									<label for="name" class="label ml-2">Identificacion:</label>
									<h6>{{user.identificacion}}</h6>
								</div>

								<div class="info-item">
									<label for="name" class="label ml-2">{{ 'USERDATA.EMAIL' | translate }}:</label>
									<h6>{{user.correo}}</h6>
								</div>

								<div class="info-item">
									<label for="name" class="label ml-2">{{ 'USERDATA.TELEFONOMOVIL' | translate }}:</label>
									<h6> + {{user.extension}} {{user.mobile}}</h6>
								</div>

								<div class="info-item">
									<label for="name" class="label ml-2">{{ 'USERDATA.CENTRO' | translate }}:</label>
									<h6>{{user.centro}}</h6>
								</div>


								<div class="skills">
									<h6>Envia un correo a {{user.firstName}}</h6>
									<div class="col-sm-12 ">
										<div class="form-group">
											<label for="comentary">{{ 'USERDATA.COMMENTARY' | translate }}</label>
											<textarea nbInput fullWidth formControlName="comentary" class="form-control" id="comentary" rows="3"></textarea>
									</div>
									</div>

								<div class="row justify-content-between">

									<div class="col-sm-2 mb-2 mt-4">
										<button  nbButton class="btn-themecolor" type="submit" ><span>{{ 'USERDATA.SEND' | translate }}</span></button>

									</div>
								</div>
								</div>
							</div>

						</div>

					</div>


				</form>
			</div>
		</div>
	</nb-card-body>
</nb-card>
