<div class="pad-center-container" [ngClass]="isShow ? 'show-pad' : 'hide-pad'">
	<div class="cont-pad-center">
		<div class="position-relative d-flex align-items-center ml-2 mr-2">
			<div class="text-center" (click)="goModule(gotoText)">
				<mat-icon svgIcon="salware" style="cursor: pointer;"></mat-icon>
			</div>
			<div class="mt-8p not-show-mobile">
				<p class="info-course">{{('PADSLABELS.SALWARE' | translate)}}</p>
			</div>
		</div>
		<div class="not-show-mobile"><mat-icon class="width-flechas" svgIcon="flecha_fina_white"></mat-icon></div>
		<div class="position-relative d-flex align-items-center mr-2">
			<div class="text-center" (click)="emitshowModalCourse('gridList')">
				<mat-icon svgIcon="proyecto-3-grafos" style="cursor: pointer;"></mat-icon>
			</div>
			<div class="mt-8p not-show-mobile">
				<p class="info-course">{{courseName}}</p>
			</div>
		</div>
		<div class="not-show-mobile"><mat-icon class="width-flechas" svgIcon="flecha_fina_white"></mat-icon></div>
		<div class="position-relative d-flex align-items-center ml-2 mr-2">
			<div class="text-center" (click)="viewAll()">
				<mat-icon svgIcon="graph" style="cursor: pointer;"></mat-icon>
			</div>
			<div class="mt-8p not-show-mobile">
				<p class="info-course">{{graphTitle}}</p>
			</div>
		</div>
		<div class="not-show-mobile"><mat-icon class="width-flechas" svgIcon="flecha_fina_white"></mat-icon></div>
		<div class="position-relative d-flex align-items-center ml-2 mr-2">
			<div class="text-center">
				<mat-icon svgIcon="IdeaVerde" (click)="viewNodes()" class="icon-color-blue round-icon"
					style="cursor: pointer;"></mat-icon>
			</div>
			<div class="mt-8p not-show-mobile">
				<ng-container *ngIf="alertCloseQuiz; else nodeTitle">
					<p class="info-course">{{lessPowerNodeTittle | truncate:[40, '...']}}</p>
				</ng-container>
				<ng-template #nodeTitle>
					<p class="info-course">{{('PADSLABELS.IDEAS' | translate)}}</p>
				</ng-template>
			</div>

		</div>
		<div class="not-show-mobile"><mat-icon class="width-flechas" svgIcon="flecha_fina_white"></mat-icon></div>
		<div class="position-relative d-flex align-items-center ml-2 mr-2">
			<div class="text-center">
				<mat-icon svgIcon="actividad_barra" (click)="viewQuizzes()" class="icon-actividad-barra ml-2"
					style="cursor: pointer;"></mat-icon>
			</div>
			<div class="mt-8p not-show-mobile">
				<ng-container *ngIf="alertCloseQuiz; else quizTitle">

					<ng-container *ngIf="nodeView; else notNode">

						<p class="info-course">{{lessOrdinalQuizzTittle | titlecase | truncate:[40, '...']}}</p>
					</ng-container>
					<ng-template #notNode>
						<p class="info-course">{{quizTitleBar | titlecase | truncate:[40, '...']}}</p>
					</ng-template>
				</ng-container>
				<ng-template #quizTitle>
					<p class="info-course">{{('PADSLABELS.ACTIVIDADES' | translate)}}</p>
				</ng-template>

			</div>
		</div>
	</div>
</div>
