import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  public reportingSusbcribe = new  Subject<({data})>();
	public dataChartSusbcribe = new Subject<({data})>();

  constructor(private http: HttpClient) { }

  getReportingByGroupId(idGroup: number, idProfile: string){
    return this.http.get<any>(`group/list-courses-groups/${idGroup}/${idProfile}`);
  }

	getReportingParent(idGroup: number, idUser: string){
    return this.http.get<any>(`group/list-courses-parent/${idGroup}/${idUser}`);
  }

	getDataTableQuizes(idGrupo: any, idCourse: any, idGrafo: any, idNode: any, usersOrder: number[]): Observable<any>{
		const body = {
			idGroup: idGrupo,
			idTargetList: [idGrafo],
			usersOrder: usersOrder,
		}
		return this.http.post(`group/gettable-quizzes/${idGrupo}/${idCourse}/${idGrafo}/${idNode}` ,body)
	}

	getDataTableQuizesStudent(idGrupo: any, idCourse: any, idGrafo: any, idNode: any, usersOrder: number[]): Observable<any>{
		const body = {
			idGroup: idGrupo,
			idTargetList: [idGrafo],
			usersOrder: usersOrder,
		}
		return this.http.post(`group/gettable-quizzes-estudiante/${idGrupo}/${idCourse}/${idGrafo}/${idNode}` ,body)
	}

	getDataTableQuizesParent(idGrupo: any, idCourse: any, idGrafo: any, idNode: any, idUser: any) {
    return this.http.get<any>(`group/gettable-quizzes-padre/${idGrupo}/${idCourse}/${idGrafo}/${idNode}/${idUser}`);
  }

	downloadExcel(dataTable: any): Observable<Blob> {
		return this.http.post(`report/exportExcel`, dataTable, { responseType: 'blob' });
	}

	//para quizzes normales
	getDataTableFromChallenges(idGroup:number, idSessionList: number[], challengeMode: boolean = false, idCompSessionList?: string[] ): Observable<any>{
		const url = 'group/gettable-quizzes-challenge'
		const body = {
			idGroup: idGroup,
			idSessionList : idSessionList,
			idCompSessionList: idCompSessionList,
			profile: "",
			challengeMode: challengeMode
		}
		return this.http.post(url, body)
	}
	//para controles de el calendario
	getDataTableFromControls(idGroup:number, idSessionList: number[], challengeMode: boolean = false, idCompSessionList?: string[] ): Observable<any>{
		const url = "group/gettable-quizzes-control";
		const body = {
			idGroup: idGroup,
			idSessionList: idSessionList,
			idCompSessionList: idCompSessionList,
			profile: "",
			challengeMode: challengeMode,
		};
		return this.http.post(url, body);
	}

	getDataTableFromChallengesStudent(idGroup:number, idSessionList: number[]): Observable<any>{
		const url = 'group/gettable-quizzes-challenge'
		const body = {
			idGroup: idGroup,
			idSessionList : idSessionList,
			profile: "Student"
		}
		return this.http.post(url, body)
	}

	getDataTableFromGraphs(idGroup:number, idTargetList: number[], rol: string): Observable<any>{
		const body = {
			idGroup: idGroup,
			idTargetList: idTargetList
		}
		return this.http.post(`group/gettable-quizzes-targets/${rol}` ,body)
	}

	getDataTableFromGraphsSorted(idGroup:number, idTargetList: number[], rol: string, usersOrder: number[]): Observable<any>{
		const body = {
			idGroup: idGroup,
			idTargetList: idTargetList,
			usersOrder: usersOrder,
		}
		return this.http.post(`group/gettable-quizzes-targets-sorted/${rol}` ,body)
	}

	getDataTableFromGraphsByUser(idGroup:number, idTargetList: number[], idUser: string): Observable<any>{
		const body = {
			idGroup: idGroup,
			idTargetList: idTargetList
		}
		return this.http.post(`group/gettable-quizzes-targets-user/${idUser}` ,body)
	}

	getInformeWorkingTimeForTarget(idGroup:number, idTargetList: number[], rol: string, days: number): Observable<any>{
		const body = {
			idGroup: idGroup,
			idTargetList: idTargetList,
			numberDaysReport: days
		}
		return this.http.post(`group/getInformeWorkingTimeForTarget/${rol}` ,body)
	}

	getInformeWorkingTimeForNode(idGroup:number, idTargetList: number[], rol: string, days: number, idNode:number): Observable<any>{
		const body = {
			idGroup: idGroup,
			idTargetList: idTargetList,
			numberDaysReport: days
		}
		return this.http.post(`group/getInformeWorkingTimeForNode/${rol}/${idNode}` ,body)
	}
	/*
	=========================================
	===== ENDPOINTS INFORMES DATA TABLE =====
	=========================================
	*/

	informeDataTableTargets(idGroup:number, idTargetList: number[], rol: number, usersOrder: number[]): Observable<any>{
		const url = 'report/informeDataTableTargets'
		const body = {
			idGroup: idGroup,
			idTargetList: idTargetList,
			profile: rol,
			usersOrder: usersOrder,
			numberDaysReport: 0
		}
		return this.http.post(url, body)
	}
	informeDataTableChallenges(idGroup:number, idSessionList: number[], challengeMode: boolean = false, usersOrder?: number[], idCompSessionList?: string[] ): Observable<any>{
		const url = 'report/informeDataTableChallenges'
		const body = {
			idGroup: idGroup,
			idSessionList : idSessionList,
			idCompSessionList: idCompSessionList,
			profile: "",
			challengeMode: challengeMode,
			usersOrder: usersOrder,
		}
		return this.http.post(url, body)
	}
	informeDataTableControl(idGroup:number, idSessionList: number[] ): Observable<any>{
		const url = "report/informeDataTableControl";
		const body = {
			idGroup: idGroup,
			idSessionList: idSessionList,
			profile: "",
			challengeMode: true,
		};
		return this.http.post(url, body);
	}
}
