import { PdfService } from './../../../core/services/pdf.service';

import { CommonModule } from '@angular/common';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbButtonModule, NbCardModule, NbFormFieldModule, NbIconModule, NbSelectModule, NbSpinnerModule, NbTooltipModule, NbWindowRef, NbWindowService } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { API_KEY_CHAT_GTP } from 'src/app/core/models/masters/masters.enum';
import { ModalGeneratedIdeasComponent } from './modal-generated-ideas/modal-generated-ideas.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { LocalStorage } from 'src/app/core/utils';
import { ComponentsModule } from '../components.module';
import { SigmaCanvasService } from '../nodes/sigma-canvas/sigma-canvas.service';
@Component({
	selector: "app-salwizar-contenido",
	standalone: true,
	imports: [
		CommonModule,
		NbFormFieldModule,
		NbCardModule,
		NbIconModule,
		NbTooltipModule,
		NbSelectModule,
		NbButtonModule,
		FormsModule,
		ReactiveFormsModule,
		NbSpinnerModule,
		TranslateModule,
	],
	templateUrl: "./salwizar-contenido.component.html",
	styleUrls: ["./salwizar-contenido.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class SalwizarContenidoComponent {
	private httpClient: HttpClient;
	salwizarForm: any;
	@ViewChild("inputFile") myInputVariable: ElementRef;
	pruebaGenerarIdeas: {
		Numero: number;
		Titulo_de_la_Idea: string;
		Cuerpo_Explicativo_de_la_Idea: string;
		Resumen: string;
		Coordenada_X: number;
		Coordenada_Y: number;
	}[];
	loading: boolean = false;
	PDFtoAnalize: string;
	fileSelected: boolean = false;
	enableGenerateButton: boolean = false;
	constructor(
		private formBuild: FormBuilder,
		private activeModal: NgbActiveModal,
		public http: HttpClient,
		private modalService: NgbModal,
		private localStorage: LocalStorage,
		private pdf: PdfService,
		private sigmaCanvasService: SigmaCanvasService,
		handler: HttpBackend
	) {
		this.buildForm();
		this.salwizarForm.get("topic").valueChanges.subscribe((value) => {
			if (value !== "") {
				this.enableGenerateButton = true;
			}
			if(value === "") {
				this.enableGenerateButton = false;
			}
		});
		this.httpClient = new HttpClient(handler);
	}

	buildForm(): void {
		this.salwizarForm = this.formBuild.group({
			topic: [""],
			level: ["Primaria"],
			lang: ["Español"],
			extra: [""],
			words: [200],
			cant: [5],
		});

	}

	closeModal(sendData?: any): void {
		this.activeModal.close(sendData);
	}

	clearFile(): void {
		this.salwizarForm.get("topic").setValue("");
		this.fileSelected = false;
	}

	onFileSelected(event: any): void {
		this.fileSelected = true;
		this.enableGenerateButton = true;
		this.salwizarForm.get("topic").value = event.target.files[0].name;
		const file: File = event.target.files[0];

		if (file) {
			this.pdf.getPdfText(file).then(
				(text: string) => {
					this.PDFtoAnalize = text;
				},
				(error) => {
					console.error(error);
				}
			);
		}
	}

	convertFileToBase64(file: File): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve((reader.result as string).split(",")[1]);
			reader.onerror = (error) => reject(error);
		});
	}

	async generateProposals() {
		this.loading = true;
		const pdfUrl = "http://www.bnm.me.gov.ar/giga1/documentos/EL002315.pdf";
		const apiKey = API_KEY_CHAT_GTP;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${apiKey}`,
		});
		const formData = this.salwizarForm.value
		const prompt = `Actua como un profesor de el nivel educativo: ${formData.level},
		el objetivo es desarrollar las diferentes ideas necesarias para explicar un tema, estas ideas estaran escritas
		en el idioma ${formData.lang}, pero solo el contenido de las mismas, los nombres de las propiedades mantenlos
		en español, en este caso el tema en cuestion es el siguiente: ${formData.topic} encuentra material educativo acerca de el tema para generar las ideas,
		debes generar un json que contendra multimples datos,
		el primero sera el numero de idea, este numero sera el orden de las ideas, ya que en un futuro podrian conectarse
		a modo de mapa mental por eso deben tener un orden establecido,
		el segundo sera el titulo de la idea, el tercero sera el cuerpo explicativo de la idea el cual tendra el numero de palabras
		que consideres necesario para explicar perfectamente la idea en cuestion de acuerdo al nivel educativo mencionado anteriormente, el cuarto sera un breve resumen de la idea, los nombres de las propiedades seran
		las siguientes: Numero, Titulo_de_la_Idea, Cuerpo_Explicativo_de_la_Idea, Resumen, la cantidad de ideas que consideres necesarias para la
		explicación de el tema, estas ideas seran temas explicativos de el tema,
		ya que luego haremos peqeños quizzes sobre los temas explicados, por lo que las ideas deben ser claras y concisas, ademas ten en cuenta
		las siguientes instrucciones: ${formData.extra},
		no le des formato markdown a la respuesta, quiero el JSON crudo el cual sera un array con las ideas directamente dentro,
		es decir que no quiero que metas las ideas dentro de ninguna propiedad previa`;

		const promptWithPDF = `Actua como un profesor de el nivel educativo: ${formData.level},
		el objetivo es desarrollar las diferentes ideas necesarias para explicar un tema, estas ideas estaran escritas
		en el idioma ${formData.lang}, pero solo el contenido de las mismas, los nombres de las propiedades mantenlos
		en español, en este caso el tema en cuestion lo vas a traer de la informacion que se encuentra en el siguiente
		texto: ${this.PDFtoAnalize} intenta basar todas las ideas solo el el texto proporcionado,
		debes generar un json que contendra multimples datos,
		el primero sera el numero de idea, este numero sera el orden de las ideas, ya que en un futuro podrian conectarse
		a modo de mapa mental por eso deben tener un orden establecido,
		el segundo sera el titulo de la idea, el tercero sera el cuerpo explicativo de la idea el cual tendra el numero de palabras
		que consideres necesario para explicar perfectamente la idea en cuestion de acuerdo al nivel educativo mencionado anteriormente, el cuarto sera un breve resumen de la idea, los nombres de las propiedades seran
		las siguientes: Numero, Titulo_de_la_Idea, Cuerpo_Explicativo_de_la_Idea, Resumen, la cantidad de ideas que consideres necesarias para la
		explicación de el tema, estas ideas seran temas explicativos de el tema,
		ya que luego haremos peqeños quizzes sobre los temas explicados, por lo que las ideas deben ser claras y concisas, ademas ten en cuenta
		las siguientes instrucciones: ${formData.extra},
		no le des formato markdown a la respuesta, quiero el JSON crudo el cual sera un array con las ideas directamente dentro,
		es decir que no quiero que metas las ideas dentro de ninguna propiedad previa`;
		const apiEndpoint = "https://api.openai.com/v1/chat/completions";
		const body = {
			model: "gpt-4o",
			messages: [
				{
					role: "system",
					content: this.fileSelected
						? promptWithPDF.replace(/(\r\n|\n|\r|\t)/gm, " ")
						: prompt.replace(/(\r\n|\n|\r|\t)/gm, " "),
				},
			],
			max_tokens: 4096, // Cantidad máxima de tokens en la respuesta
			temperature: 0.7,
		};
		try {
			const respuesta = await this.httpClient
				.post<any>(apiEndpoint, body, { headers })
				.toPromise();
			if (respuesta) {
				this.loading = false;
				this.localStorage.setItem("IAGeneratedIdeas", [
					respuesta.choices[0].message.content,
				]);
				this.modalService.dismissAll();
				this.sigmaCanvasService.setOpenDesignIdeasWindow(true);
				// const modalRef = this.modalService.open(ModalGeneratedIdeasComponent, {
				// 	scrollable: true,
				// 	windowClass: MODAL_DIALOG_TYPES.W50,
				// });
			}
		} catch (error) {
			console.error("Error al hacer la consulta a ChatGPT:", error.message);
		}
	}
}
