<nb-card class="mb-0" *ngIf="loginService.esProfesor()">
	<nb-card-header class="flex justify-content-between border-bottom-0">
		<div class="col-11">
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal()"></nb-icon>
	</div>
	<nb-card-body>
		<nb-card class="background_themecolor mb-4">
			<nb-card-body class="container container-teacher">
				<div class="cards cards-teacher p-2">
					<div class="card-item" (click)="pasarLista()" [ngClass]="{'nb-card-dark-overlay': timeLeft > 0}">
						<div class="card-info">
							<h6 class="card-title">
								{{ "ASISTENCIA.TITLE1" | translate }}
							</h6>
							<p>{{ "ASISTENCIA.SUBTITLE1" | translate }}</p>
						</div>
						<div class="overlay-container" *ngIf="timeLeft > 0">
							<div class="overlay-text">
								{{timeLeftString}}
							</div>
						</div>
					</div>
				</div>
				<div class="cards cards-teacher p-2">
					<div class="card-item" (click)="verHistorial()">
						<div class="card-info">
							<h6 class="card-title">
								{{ "ASISTENCIA.TITLE2" | translate }}
							</h6>
							<p class="paragraph">
							</p>
						</div>
					</div>
				</div>
			</nb-card-body>
		</nb-card>
	</nb-card-body>
</nb-card>

<nb-card class="mb-0 p-4" *ngIf="loginService.esEstudiante()">
	<nb-card-header class="flex justify-content-between border-bottom-0">
		<div class="col-11 text-center">
			<h6>{{ "ASISTENCIA.TITLESTUDENT" | translate }}</h6>
			<h5 class="text-group">{{group.title}}</h5>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal()"></nb-icon>
	</div>
	<nb-card-body>
		<div class="mb-2 row justify-content-center gap-3">
			<button nbButton status="success" (click)="responderAsistencia(3)" *ngIf="presente">
				<nb-icon icon="checkmark-circle-2-outline"></nb-icon>
				Presente
			</button>
			<button nbButton status="warning" (click)="responderAsistencia(4)" *ngIf="!presente">
				<nb-icon icon="alert-triangle-outline"></nb-icon>
				Con retraso
			</button>
			<button nbButton status="danger" (click)="responderAsistencia(2)">
				<nb-icon icon="close-square-outline"></nb-icon>
				Ausente
			</button>
		</div>
	</nb-card-body>
</nb-card>
