<div class="modal-header fondoEditarCurso" align="center">
    <!--form class="form-horizontal"-->
        <div class="col-lg-1 col-md-1 col-sm-1">
            <button type="button" class="btn border border-secondary" data-toggle="button"
                [ngStyle]="{'color':'rgb(33, 76, 152)'}">
                <mat-icon class="pointer" svgIcon="profesores" ngbTooltip="{{'TASK.PROFESORES'|translate}}"></mat-icon>
            </button>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1">
            <button type="button" class="btn border border-secondary" data-toggle="button"
                [ngStyle]="{'color':'rgb(33, 76, 152)'}">
                <mat-icon class="pointer" svgIcon="alumnos" ngbTooltip="{{'TASK.ALUMNOS'|translate}}">
                </mat-icon>
            </button>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-8">
            <!--div class="row mx-auto align-items-center justify-content-center"-->
                <div class="btn-group btn-group-toggle border border-secondary p-1" data-toggle="buttons">
                    <div>
                        <label class="btn btn-light border border-secondary pointer m-0"
                            [ngStyle]="{'background-color':colorHacerTareas}" (click)="tareasParaHacer()">
                            <input type="radio" name="options" id="hacer" autocomplete="off">
                            <span class="textoFontSize07 colorTextoActivado font-weight-bold">
                                {{'TASK.HACER'|translate}}
                            </span>
                        </label>
                    </div>
                    <div>
                        <label class="btn btn-light border border-secondary pointer m-0"
                            [ngStyle]="{'background-color':colorTodasTareas}" (click)="todasTareas()">
                            <input type="radio" name="options" id="todas" autocomplete="off">
                            <span class="textoFontSize07 colorTextoActivado font-weight-bold">
                                {{'TASK.TODAS'|translate}}
                            </span>
                        </label>
                    </div>
                </div>
            <!--/div-->
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1">
                <button type="button" [ngStyle]="{'color':'rgb(33, 76, 152)'}" (click)="verListadoCursos('competir')">
                    <mat-icon class="borrar pointer" svgIcon="add" ngbTooltip="{{'TASK.CREARTAREA'|translate}}">
                    </mat-icon>
                </button>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1">
            <button type="button" [ngStyle]="{'color':'rgb(33, 76, 152)'}">
                <mat-icon class="borrar pointer" svgIcon="delete" ngbTooltip="{{'TASK.ELIMINAR'|translate}}">
                </mat-icon>
            </button>
    </div>
    <!--/form-->
</div>

