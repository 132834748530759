import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/core/models/users/user.models';
import { ChatService } from 'src/app/core/services/chat/chat.service';
import { LoginService } from 'src/app/core/services/login';

@Component({
    selector: "app-chat-assistant",
    templateUrl: "./chat-assistant.component.html",
    styleUrls: ["./chat-assistant.component.scss"],
})
export class ChatAssistantComponent implements OnInit {
    user: User;
    messages: { sender: string; text: string }[] = [];
    userInput = "";
    context = "";
    loading = false; // Add loading variable

    constructor(
        private chatService: ChatService,
        private loginService: LoginService,
        public activeModal: NgbActiveModal
    ) {
        this.user = this.loginService.getUser();
    }

    ngOnInit(): void {
        this.initializeChat();
        this.loadMessageHistory(); // Load message history from localStorage
    }

    async initializeChat(): Promise<void> {
        this.loading = true; // Set loading to true
        // Query Pinecone for all embeddings related to the user
        const embeddingsResponse = await this.chatService.queryEmbeddings(
            this.user.idUser,
            ""
        );
        const embeddings = embeddingsResponse.matches.map((match) => ({
            text: match.metadata.text,
            embedding: match.values,
        }));

        // Create an initial context for ChatGPT with all relevant messages
        this.context =
            "Hola, soy tu asistente virtual. Aquí está el contexto de nuestras conversaciones anteriores: tenlo en cuenta mas no menciones esto en el primer mensaje solo saludame\n";
        embeddings.forEach((message) => {
            this.context += `- ${message.text}\n`;
        });

        // Send the initial context to ChatGPT
        const response = await this.chatService.sendMessage(
            this.user.idUser,
            "",
            this.context
        );
        const chatMessage = { sender: "chatgpt", text: response.response };
        this.messages.push(chatMessage);
        this.loading = false; // Set loading to false
        this.saveMessageHistory(); // Save message history to localStorage
    }

    cosineSimilarity(vecA: number[], vecB: number[]): number {
        const dotProduct = vecA.reduce((sum, a, idx) => sum + a * vecB[idx], 0);
        const magnitudeA = Math.sqrt(vecA.reduce((sum, a) => sum + a * a, 0));
        const magnitudeB = Math.sqrt(vecB.reduce((sum, b) => sum + b * b, 0));
        return dotProduct / (magnitudeA * magnitudeB);
    }

    async sendMessage(): Promise<void> {
        if (this.userInput.trim() && !this.loading) {
            // Check if not loading
            this.loading = true; // Set loading to true
            const userMessage = { sender: "user", text: this.userInput };
            this.messages.push(userMessage);

            // Consultar embeddings similares
            const similarMessagesResponse = await this.chatService.queryEmbeddings(
                this.user.idUser,
                this.userInput
            );
            const similarMessages = similarMessagesResponse.matches.map((match) => ({
                text: match.metadata.text,
                embedding: match.values,
            }));

            // Generar embedding del mensaje del usuario
            const userEmbeddingResponse = await this.chatService.queryEmbeddings(
                this.user.idUser,
                this.userInput
            );
            const userEmbedding = userEmbeddingResponse.matches;

            // Calcular similitud del coseno y encontrar mensajes relevantes
            let relevantInfo = "";
            similarMessages.forEach((message) => {
                const similarity = this.cosineSimilarity(
                    userEmbedding,
                    message.embedding
                );
                console.log(`Similarity with "${message.text}": ${similarity}`);
                if (similarity > 0.8) {
                    // Umbral de similitud
                    relevantInfo += `\n- ${message.text}`;
                }
            });

            // Generar respuesta de ChatGPT
            let prompt = this.userInput;
            if (relevantInfo) {
                prompt += `\n\nRecuerda que el usuario mencionó anteriormente: ${relevantInfo}`;
            }

            // Actualizar el contexto con el nuevo mensaje y la respuesta de ChatGPT
            this.context += `\nUsuario: ${this.userInput}`;
            const response = await this.chatService.sendMessage(
                this.user.idUser,
                prompt,
                this.context
            );
            const chatMessage = { sender: "chatgpt", text: response.response };
            this.messages.push(chatMessage);

            // Actualizar el contexto con la respuesta de ChatGPT
            this.context += `\nChatGPT: ${response.response}`;

            this.userInput = "";
            this.loading = false; // Set loading to false
            this.saveMessageHistory(); // Save message history to localStorage
        }
    }

    saveMessageHistory(): void {
        localStorage.setItem('chatMessages', JSON.stringify(this.messages));
    }

    loadMessageHistory(): void {
        const savedMessages = localStorage.getItem('chatMessages');
        if (savedMessages) {
            this.messages = JSON.parse(savedMessages);
        }
    }

    closeModal(sendData?: any) {
        this.activeModal.close();
    }
}
