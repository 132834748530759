export class IReporting {
    id: string;
    name: string;
    icon: string;
    children?: IReporting[];
    type: string;
    idCourse: number = 0;
    idTarget: number = 0;
    pendingQuizzes: number = 0;
		progressPercentage: number = 0;
		colorBar: string;
}

export const TypeChlidrenReporting = {
    COURSE: "COURSE",
    GRAFO: "GRAFO",
    NODE: "NODE"
}
