<nb-card  class="mb-0 no-edge-card">
	<ng-container *ngIf="loading == true; else form">
		<nb-card-body>
			<nb-progress-bar [value]="value" [status]="status" [displayValue]="true"></nb-progress-bar>
			<h5 style="font-weight: bold; text-align: center;">Generando contenido con IA</h5>
			<p style="text-align: center; font-style: italic;">Puede tardar unos segundos</p>
		</nb-card-body>
	</ng-container>

	<ng-template #form>
		<nb-card-header class="border-bottom-0">
			<div class="col-11">
				<div class="d-flex">
					<h5>Contenido de el ideografo</h5>
				</div>
			</div>
		</nb-card-header>

		<div class="closeModal">
			<nb-icon class="m-1" icon="checkmark-circle-2"
				[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"
				nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}" nbTooltipPlacement="left">
			</nb-icon>
		</div>

		<nb-card-body>
			<div class="container">
				<div *ngIf="salwizarForm">
					<form [formGroup]="salwizarForm" autocomplete="off">

						<div class="d-flex w-100 gap-4 justify-content-between flex-column flex-sm-row">
							<div class="mb-0 mb-sm-4 w-100">
								<label for="topic">{{ 'SALWIZAR.INDICATESTOPIC' | translate }}:</label>
								<input type="text" nbInput fullWidth hero fieldSize="medium" class="form-control" formControlName="topic" [disabled]="fileSelected">
							</div>
						</div>

						<div class="d-flex w-100 gap-4 justify-content-between flex-column flex-sm-row">
							<div class="form-group d-flex flex-column w-100 position-relative">
								<label for="file">Carga archivos (material de apoyo para generar el contenido):</label>
								<input type="file" formControlName="topic" (change)="onFileSelected($event)"
									style="line-height: 1.9rem; font-size: 12px;">
								<nb-icon *ngIf="fileSelected == true" icon="trash-2-outline" status="danger" (click)="clearFile()" style="position: absolute; top: 0; right: 0; cursor: pointer;"></nb-icon>
							</div>
						</div>

						<div class="d-flex w-100 justify-content-center">
							<div class="form-group w-100 d-flex flex-column">
								<nb-select name="idCountry" placeholder="{{'FILTER.COUNTRY' | translate}}" formControlName="idCountry"
								(selectedChange)="searchLevels($event)">
								<nb-option *ngFor="let country of countriesList" value="{{ country.idCountry }}">{{
									country.country_es }}
								</nb-option>
							</nb-select>
							</div>
						</div>

						<div class="d-flex w-100 justify-content-center">
							<div class="form-group w-100 d-flex flex-column">
								<nb-select name="idCountryLevel" placeholder="{{'FILTER.LEVEL' | translate}}" formControlName="idCountryLevel">
									<nb-option *ngFor="let level of levelsOfCountry" [value]="level.countryLevel">{{ level.countryLevel
										}}</nb-option>
									<nb-option *ngIf="notCountryLevelsForThisCountry">{{'MODALINFORMATIONCOURSE.NOTLEVELSFORCOUNTRY' |
										translate}}</nb-option>
								</nb-select>
							</div>
						</div>

						<div class="d-flex w-100 justify-content-center">
							<div class="form-group w-100 d-flex flex-column">
								<label for="lang">{{ 'FORM.LANGUAGE' | translate }}:</label>
								<nb-select formControlName="lang" class="SelectForm" hero size="medium" id="lang" style="max-width: 100%;">
									<nb-option value="Español" class="SelectFormOpt">Spanish</nb-option>
									<nb-option value="Ingles" class="SelectFormOpt">English</nb-option>
									<nb-option value="Frances" class="SelectFormOpt">French</nb-option>
								</nb-select>
							</div>
						</div>

						<div class="d-flex w-100 justify-content-center">
							<div class="form-group d-flex flex-column w-100">
								<label for="extra">{{ 'SALWIZAR.ADDITIONALINSTRUCTIONS' | translate }}:</label>
								<textarea formControlName="extra" id="extra" nbInput fullWidth fieldSize="medium" class="form-control"></textarea>
							</div>
						</div>

					</form>
				</div>
			</div>
		</nb-card-body>
		<nb-card-footer class="border-top-0">
			<div class="d-flex justify-content-center form-group">
				<button nbButton status="primary" [disabled]="!enableGenerateButton" size="small" (click)="generateProposals()">
					{{ 'SALWIZAR.SHOWPROPOSALS' | translate }}
				</button>
			</div>
		</nb-card-footer>
	</ng-template>
</nb-card>
