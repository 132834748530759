import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/core/services/shared/utils.service';
import { SIGMA_CONSTANTS } from 'src/app/core/utils/sigma-constants';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-quiz-edit-body-info',
    templateUrl: './quiz-edit-body-info.component.html',
    styleUrls: ['./quiz-edit-body-info.component.scss'],
})
export class QuizEditBodyInfoComponent implements OnInit {
    @Input() quiz: any;
    @Output() saveData: EventEmitter<any> = new EventEmitter<any>();

    public infoForm: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder, private utilsService: UtilsService) {
        this.infoForm = this.fb.group({
            title: ['', Validators.compose([Validators.required])],
            duration: '',
            instructions: ''
        });
    }

    ngOnInit() {
        this.infoForm.get('title').patchValue(this.quiz.quizTittle);
        this.infoForm.get('instructions').patchValue(this.quiz.quizInstructions);
        this.infoForm.get('duration').setValue(this.utilsService.minutesToTimeBootstrap(this.quiz.duration));
    }

		formatDuration(){
			let duration = this.infoForm.get('duration').value;
			let formattedDuration = ` ${duration.hour.toString().length == 1 ? '0' : ''}${duration.hour}:${duration.minute}`
			this.infoForm.get('duration').setValue(formattedDuration);
		}

		refillDuration = () => {
			this.infoForm.get('duration').setValue(this.utilsService.minutesToTimeBootstrap(this.quiz.duration));
		}

    public onTextBlur(event: any) {
				this.formatDuration();
        if (this.infoForm.valid) {
            this.infoForm.get('title').setValue(this.infoForm.get('title').value.substring(0, SIGMA_CONSTANTS.QUIZ_TEXT_MAXLENGTH))
            this.saveData.emit(this.infoForm.value);
						this.refillDuration();
        }
    }

    keydown(e){
        if (e.which < 0x20) { return; }
        if(e.target.value.length >= SIGMA_CONSTANTS.QUIZ_TEXT_MAXLENGTH){
            e.preventDefault();
            e.stopPropagation();
            this.infoForm.get('title').setValue(e.target.value.substring(0, SIGMA_CONSTANTS.QUIZ_TEXT_MAXLENGTH))
        }
    }
}
