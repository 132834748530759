import {
	InstagramVideo,
	SpotifyAudio,
	TiktokVideo,
} from "./../../../../../../core/models/shared/external-video.model";
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxCopilotService } from "ngx-copilot";
import { Observable, Subscription } from "rxjs";
import { finalize, first } from "rxjs/operators";
import { ModelIcono } from "src/app/core/models/courses/icono.model";
import { NodeFilesModel } from "src/app/core/models/nodes";
import { NodeFilesFormat } from "src/app/core/models/nodes/node-files-format.model";
import { Operator } from "src/app/core/models/operators/operator.model";
import { Estado } from "src/app/core/models/tuturial/tutorial.model";
import { LoginService } from "src/app/core/services/login";
import { NodeService } from "src/app/core/services/node/node.service";
import { OperatorsService } from "src/app/core/services/operators/operators.service";
import { SnapshotService } from "src/app/core/services/snapshot/snapshot.service";
import {
	AVAILABLE_TYPE_FILES,
	FILE_FORMATS,
} from "src/app/core/utils/file-formats";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { YoutubeVideo } from "src/app/core/models/shared/external-video.model";
import { Utils } from "src/app/core/utils/utils";
import { saveAs } from "file-saver";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { DownloadFileModel } from "../node-detail-files/components/node-detail-files-uploader/node-detail-files-uploader.component";
import { ToasterService } from "src/app/core/services/shared/toaster.service";

@Component({
	selector: "app-node-column-files",
	templateUrl: "./node-column-files.component.html",
	styleUrls: ["./node-column-files.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class NodeColumnFilesComponent implements OnInit, OnDestroy {
	@Input() modeAuto: boolean = false;
	@Input() inicial;
	@Input() node;
	@Input() mensaje: string;
	@Input() onSaveClicked$: Observable<any>;
	@Input() canEdit: boolean = false;
	@Output() nodeFilesTypeChanged: EventEmitter<string> =
		new EventEmitter<string>();
	@Output() nodeFileAdded: EventEmitter<any> = new EventEmitter<any>();
	@Output() nodeFilePlayed: EventEmitter<any> = new EventEmitter<any>();

	FILES_URL = environment.remoteFilesUrl + "nodeContent";

	AUDIO_TYPE = FILE_FORMATS.AUDIO_TYPE;
	IMAGE_TYPE = FILE_FORMATS.IMAGE_TYPE;
	PDF_TYPE = FILE_FORMATS.PDF_TYPE;
	TXT_TYPE = FILE_FORMATS.TXT_TYPE;
	VIDEO_TYPE = FILE_FORMATS.VIDEO_TYPE;

	cargando: boolean;
	videoSource: any;
	imageSource: string;
	audioSource: string;
	pdfSource: string;
	textSource: string;
	imagen: string;
	format: string;
	saveClickedSubscription: Subscription;

	formats: NodeFilesFormat[] = [
		{
			id: 1,
			name: "NODEFORMCOMPONENT.VIDEO",
			type: this.VIDEO_TYPE,
			icon: "videocam",
			edit: false,
			operatorIcons: [],
			visible: true,
			accept: "video/mp4",
			progress: 0,
			fileName: "",
			idNodeFile: 0,
			isVideoYoutube: false,
			isVideoInstagram: false,
			isVideoTiktok: false,
		},
		{
			id: 2,
			name: "NODEFORMCOMPONENT.IMAGE",
			type: this.IMAGE_TYPE,
			icon: "crop_original",
			edit: false,
			operatorIcons: [],
			visible: true,
			accept: "image/*",
			progress: 0,
			fileName: "",
			idNodeFile: 0,
		},
		{
			id: 3,
			name: "NODEFORMCOMPONENT.AUDIO",
			type: this.AUDIO_TYPE,
			icon: "volume_up",
			edit: false,
			operatorIcons: [],
			visible: true,
			accept: "audio/*",
			progress: 0,
			fileName: "",
			idNodeFile: 0,
			isSpotifyAudio: false,
		},
		{
			id: 4,
			name: "NODEFORMCOMPONENT.FILE",
			type: this.PDF_TYPE,
			icon: "upload_file",
			edit: false,
			operatorIcons: [],
			visible: true,
			accept: AVAILABLE_TYPE_FILES.join(","),
			progress: 0,
			fileName: "",
			idNodeFile: 0,
		},
		{
			id: 5,
			name: "NODEFORMCOMPONENT.TEXT",
			type: this.TXT_TYPE,
			icon: "text_snippet",
			edit: false,
			operatorIcons: [],
			visible: true,
			progress: 0,
			idNodeFile: 0,
		},
	];

	iconosAdicionados: ModelIcono[] = [];

	@ViewChild("videoElement")
	videoPlayer;
	converOk: boolean;
	constructor(
		public loginService: LoginService,
		private nodeService: NodeService,
		private modalService: NgbModal,
		private copilot: NgxCopilotService,
		private snapshotService: SnapshotService,
		private toaster: ToasterService,
		private operatorsService: OperatorsService,
		private translateService: TranslateService,
		private utils: Utils
	) {}

	ngOnInit() {
		this.loadFiles(this.node);
		this.saveClickedSubscription = this.onSaveClicked$.subscribe((res) => {
			this.saveText(res);
		});

		this.nodeService.currentUploadProgress.subscribe((res) => {
			this.formats.forEach((format) => {
				if (format.type === res.type) format.progress = res.value;
			});
		});
		if (
			this.loginService.getUser().tutorialSW.estado === Estado.PENDIENTE &&
			!this.loginService.getDataTutorial().estudiante.cambiarContenido
		) {
			setTimeout(() => {
				this.initPosition(8);
			}, 500);
		}
		if (this.loginService.esEstudiante()) this._playFirstFile();
		this.nodeService.currentNode.subscribe((n) => {
			this.node = n;
			this.loadFiles(this.node);
		});
	}

	nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

	done = () => {
		let data = this.loginService.getDataTutorial();
		data.estudiante.cambiarContenido = true;
		this.loginService.updateDataLocalTutorial(data);
		this.copilot.removeWrapper();
	};

	initPosition = (o: any) => {
		this.copilot.checkInit(o);
	};
	ngOnDestroy(): void {
		this.saveClickedSubscription.unsubscribe();
	}

	cargarIconos(formatType: string, file?: File, text?: string) {
		this.agruparIconosAdicionados(formatType, [], [], [], [], file, text);
	}

	agruparIconosAdicionados(
		formatType: string,
		iconosSenmotemo: Operator[],
		iconosAssostatic: Operator[],
		iconosTemporary: Operator[],
		iconosAbstract: Operator[],
		file?: File,
		text?: string
	) {
		const operatorIcons: Operator[] = [];

		for (var val of iconosSenmotemo) {
			operatorIcons.push(val);
		}
		for (var val of iconosAssostatic) {
			operatorIcons.push(val);
		}
		for (var val of iconosTemporary) {
			operatorIcons.push(val);
		}
		for (var val of iconosAbstract) {
			operatorIcons.push(val);
		}

		const actualFormat = this.formats.filter((f) => f.type === formatType)[0];
		actualFormat.operatorIcons = [...operatorIcons];

		this.nodeFileAdded.emit({
			type: actualFormat.type,
			content: file ? file : text,
			operators: actualFormat.operatorIcons,
		});
	}

	private _playFirstFile() {
		const nodesFiles: NodeFilesModel = this.node.nodesFiles as NodeFilesModel;
		let type: string = "";

		if (nodesFiles.videoFile) type = "video";
		else if (nodesFiles.pictureFile) type = "picture";
		else if (nodesFiles.audioFile) type = "audio";
		else if (nodesFiles.pdfFile) type = "pdf";
		else if (nodesFiles.txtFile) type = "text";

		if (type) this.reproducirArchivo(type);
	}

	private loadFiles(node) {
		const nodeFiles: NodeFilesModel = node.nodesFiles as NodeFilesModel;

		if (nodeFiles.pictureFile) {
			const imageFormat = this.formats.filter(
				(f) => f.type === this.IMAGE_TYPE
			)[0];
			imageFormat.edit = true;
			imageFormat.fileName = nodeFiles.pictureFile || "";
			imageFormat.background =
				"url(" + this.fileUrl(this.IMAGE_TYPE, nodeFiles.pictureFile) + ")";
			imageFormat.idNodeFile = nodeFiles.idNodeFile;
			imageFormat.operatorIcons = nodeFiles.pictureOperadores
				? this.operatorsService
						.getOperators()
						.filter((o) =>
							nodeFiles.pictureOperadores.some((p) => p.id === o.id)
						)
				: [];
		} else {
			const imageFormat = this.formats.filter(
				(f) => f.type === this.IMAGE_TYPE
			)[0];
			imageFormat.edit = false;
			imageFormat.fileName = "";
			imageFormat.background = "";
			imageFormat.idNodeFile = nodeFiles.idNodeFile;
		}

		if (nodeFiles.videoFile) {
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.edit = true;
			videoFormat.fileName = nodeFiles.videoFile || "";
			videoFormat.idNodeFile = nodeFiles.idNodeFile;
			videoFormat.operatorIcons = nodeFiles.videoOperadores
				? this.operatorsService
						.getOperators()
						.filter((o) => nodeFiles.videoOperadores.some((p) => p.id === o.id))
				: [];
		} else {
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.edit = false;
			videoFormat.fileName = "";
			videoFormat.idNodeFile = nodeFiles.idNodeFile;
		}

		if (nodeFiles.videoImage) {
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.fileName += "|" + nodeFiles.videoImage;
			videoFormat.idNodeFile = nodeFiles.idNodeFile;
			videoFormat.background =
				"url(" +
				this.fileUrl(FILE_FORMATS.VIDEO_PREVIEW_TYPE, nodeFiles.videoImage) +
				")";
		} else {
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.edit = nodeFiles.videoFile ? true : false;
			videoFormat.background = "";
			videoFormat.idNodeFile = nodeFiles.idNodeFile;
		}

		if (nodeFiles.audioFile) {
			const audioFormat = this.formats.filter(
				(f) => f.type === this.AUDIO_TYPE
			)[0];
			audioFormat.edit = true;
			audioFormat.background = "url(assets/images/nodes/audio.jpg)";
			audioFormat.fileName = nodeFiles.audioFile || "";
			audioFormat.idNodeFile = nodeFiles.idNodeFile;
			audioFormat.isSpotifyAudio = nodeFiles.spotifyAudio ? true : false;
			audioFormat.operatorIcons = nodeFiles.audioOperadores
				? this.operatorsService
						.getOperators()
						.filter((o) => nodeFiles.audioOperadores.some((p) => p.id === o.id))
				: [];
		} else {
			const audioFormat = this.formats.filter(
				(f) => f.type === this.AUDIO_TYPE
			)[0];
			audioFormat.edit = false;
			audioFormat.fileName = "";
			audioFormat.background = "";
			audioFormat.idNodeFile = nodeFiles.idNodeFile;
		}

		if (nodeFiles.videoYouTube) {
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.end = nodeFiles.end;
			videoFormat.url = nodeFiles.videoYouTube;
			videoFormat.background =
				"url(" +
				this.fileUrl(FILE_FORMATS.VIDEO_PREVIEW_TYPE, nodeFiles.imageYoutube) +
				")";
			videoFormat.idNodeFile = nodeFiles.idNodeFile;
			videoFormat.start = nodeFiles.start;
			videoFormat.edit = true;
			videoFormat.isVideoYoutube = true;
		}

		if (nodeFiles.videoInstagram) {
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.url = nodeFiles.videoInstagram;
			videoFormat.background =
				"url(" +
				this.fileUrl(
					FILE_FORMATS.VIDEO_PREVIEW_TYPE,
					nodeFiles.imageInstagram
				) +
				")";
			videoFormat.idNodeFile = nodeFiles.idNodeFile;
			videoFormat.edit = true;
			videoFormat.isVideoInstagram = true;
		}

		if (nodeFiles.videoTiktok) {
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.url = nodeFiles.videoTiktok;
			videoFormat.background =
				"url(" +
				this.fileUrl(FILE_FORMATS.VIDEO_PREVIEW_TYPE, nodeFiles.imageTiktok) +
				")";
			videoFormat.idNodeFile = nodeFiles.idNodeFile;
			videoFormat.edit = true;
			videoFormat.isVideoTiktok = true;
		}

		if (nodeFiles.pdfFile) {
			const pdfFormat = this.formats.filter((f) => f.type === this.PDF_TYPE)[0];
			pdfFormat.edit = true;
			pdfFormat.background = this.fileUrl(this.PDF_TYPE, nodeFiles.pdfFile);
			pdfFormat.fileName = nodeFiles.pdfFile || "";
			pdfFormat.idNodeFile = nodeFiles.idNodeFile;
			pdfFormat.operatorIcons = nodeFiles.pdfOperadores
				? this.operatorsService
						.getOperators()
						.filter((o) => nodeFiles.pdfOperadores.some((p) => p.id === o.id))
				: [];
		} else {
			const pdfFormat = this.formats.filter((f) => f.type === this.PDF_TYPE)[0];
			pdfFormat.edit = false;
			pdfFormat.background = "";
			pdfFormat.fileName = "";
			pdfFormat.idNodeFile = nodeFiles.idNodeFile;
		}

		if (nodeFiles.txtFile) {
			const txtFormat = this.formats.filter((f) => f.type === this.TXT_TYPE)[0];
			txtFormat.edit = true;
			txtFormat.background = nodeFiles.txtFile;
			txtFormat.idNodeFile = nodeFiles.idNodeFile;
			txtFormat.operatorIcons = nodeFiles.txtOperadores
				? this.operatorsService
						.getOperators()
						.filter((o) => nodeFiles.txtOperadores.some((p) => p.id === o.id))
				: [];
		}
	}

	onFileSelected({ event, format, fileDevice }) {
		this.iconosAdicionados = [];
		this.videoSource = "";
		this.imageSource = "";
		this.audioSource = "";
		this.pdfSource = "";
		this.textSource = "";

		const actualFormat: NodeFilesFormat = this.formats.filter(
			(f) => f.id === format.id
		)[0];
		this.format = format.type;
		this.inicial = false;
		if (fileDevice !== undefined) {
			var file = fileDevice;
		} else {
			var file = event.target.files[0];
		}
		actualFormat.edit = false;
		if (file.files) {
			var reader = new FileReader();
			reader.readAsDataURL(file.files);
			reader.onload = async (event: any) => {
				switch (actualFormat.type) {
					case this.VIDEO_TYPE:
						if (
							file.files.type == "video/mp4" ||
							file.files.type == "video/webm"
						) {
							this.videoSource = await (<FileReader>event.target).result;
							//SnapShop del video
							this.cargarIconos(actualFormat.type, file.files);
							actualFormat.edit = true;
							this.node.videoFile = file.files.name;
							this.nodeService.setCurrentNode(this.node);

							var previewReader = new FileReader();
							previewReader.onload = (e) => {
								this.snapshotService.processSnapshotVideo(
									previewReader.result,
									file.files.type
								);
								this.snapshotService.previewBlob$
									.pipe(first())
									.subscribe((res) => {
										this.addPreview(res as File, false);
									});
							};
							previewReader.readAsArrayBuffer(file.files);
						}
						break;
					case this.AUDIO_TYPE:
						if (
							file.files.type == "audio/mpeg" ||
							file.files.type == "audio/mp3"
						) {
							this.audioSource = event.target.result;
							this.cargarIconos(actualFormat.type, file.files);
							actualFormat.background = "url(assets/images/nodes/audio.jpg)";
							actualFormat.edit = true;
							this.node.audioFile = file.files.name;
							this.nodeService.setCurrentNode(this.node);
						} else {
							//this.cargando = false;
							this.inicial = true;
							this.mensaje = "noFormato";
						}
						break;
					default:
						break;
				}

				this.nodeFilesTypeChanged.emit(actualFormat.type);
			};
		} else if (file.file) {
			var reader = new FileReader();
			reader.readAsDataURL(file.file);
			reader.onload = async (event: any) => {
				switch (actualFormat.type) {
					case this.IMAGE_TYPE:
						if (
							file.file.type == "image/jpeg" ||
							file.file.type == "image/png"
						) {
							let filePNG: File = new File([file.file], file.file.name, {
								type: "image/png",
							});
							this.cargarIconos(actualFormat.type, filePNG);
							this.imageSource = event.target.result;
							actualFormat.background = "url(" + event.target.result + ")";
							actualFormat.edit = true;
							this.node.pictureFile = file.file.name;
							this.nodeService.setCurrentNode(this.node);
						} else {
							this.inicial = true;
							this.mensaje = "noFormato";
						}
						break;
					default:
						break;
				}

				this.nodeFilesTypeChanged.emit(actualFormat.type);
			};
		} else if (file) {
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = async (event: any) => {
				switch (actualFormat.type) {
					case this.VIDEO_TYPE:
						if (file.type == "video/mp4" || file.type == "video/webm") {
							this.videoSource = await (<FileReader>event.target).result;
							//SnapShop del video
							this.cargarIconos(actualFormat.type, file);
							actualFormat.edit = true;
							this.node.videoFile = file.name;
							this.nodeService.setCurrentNode(this.node);
						} else {
							//this.cargando = false;
							this.inicial = true;
							this.mensaje = "noFormato";
						}
						break;
					case this.IMAGE_TYPE:
						if (file.type == "image/jpeg" || file.type == "image/png") {
							let filePNG: File;
							filePNG = new File([file], file.name, { type: "image/png" });
							this.cargarIconos(actualFormat.type, filePNG);
							this.imageSource = event.target.result;

							actualFormat.background = "url(" + event.target.result + ")";
							actualFormat.edit = true;
							this.node.pictureFile = file.name;
							this.nodeService.setCurrentNode(this.node);
						} else {
							this.inicial = true;
							this.mensaje = "noFormato";
						}
						break;
					case this.AUDIO_TYPE:
						if (file.type == "audio/mpeg" || file.type == "audio/mp3") {
							this.audioSource = event.target.result;
							this.cargarIconos(actualFormat.type, file);
							actualFormat.background = "url(assets/images/nodes/audio.jpg)";
							actualFormat.edit = true;
							this.node.audioFile = file.name;
							this.nodeService.setCurrentNode(this.node);
						} else {
							//this.cargando = false;
							this.inicial = true;
							this.mensaje = "noFormato";
						}
						break;
					case this.PDF_TYPE:
						if (AVAILABLE_TYPE_FILES.includes(file.type)) {
							this.pdfSource = event.target.result;
							this.cargarIconos(actualFormat.type, file);
							actualFormat.background = this.pdfSource;
							format.edit = true;
							this.node.pdfFile = file.name;
							this.nodeService.setCurrentNode(this.node);
						} else {
							this.inicial = true;
							this.mensaje = "noFormato";
						}
						break;
					default:
						break;
				}

				this.nodeFilesTypeChanged.emit(actualFormat.type);
			};

			if (file.type == "video/mp4" || file.type == "video/webm") {
				var previewReader = new FileReader();
				previewReader.onload = (e) => {
					this.snapshotService.processSnapshotVideo(
						previewReader.result,
						file.type
					);
					this.snapshotService.previewBlob$.pipe(first()).subscribe((res) => {
						this.addPreview(res as File, false);
					});
				};
				previewReader.readAsArrayBuffer(file);
			}
		} else {
			//this.cargando = false;
			this.inicial = true;
			this.mensaje = "noEscogido";
		}
	}

	reproducirArchivo(type: string) {
		this.inicial = false;
		this.format = type;
		this.nodeFilesTypeChanged.emit(type);
		this.nodeFilePlayed.emit(type);
	}

	private fileUrl(type: string, fileName: string) {
		if (!fileName) return undefined;
		return `${this.FILES_URL}/${type}/${fileName}`;
	}

	public saveText(text: string) {
		const txtFormat = this.formats.filter((f) => f.type === this.TXT_TYPE)[0];
		txtFormat.background = text;
		this.cargarIconos(this.TXT_TYPE, undefined, text);
	}

	onVideoPreview(event) {
		const file: File = event.target.files[0];
		this.addPreview(file);
	}

	private addPreview(file: File, showResult: boolean = true) {
		const reader = new FileReader();
		const actualFormat = this.formats.filter(
			(f) => f.type === FILE_FORMATS.VIDEO_TYPE
		)[0];
		reader.readAsDataURL(file);
		reader.onload = async (event: any) => {
			actualFormat.background = "url(" + event.target.result + ")";
		};

		this.nodeFileAdded.emit({
			type: FILE_FORMATS.VIDEO_PREVIEW_TYPE,
			content: file,
			showResult,
		});
	}

	onFileDelete(values: any): void {
		const actualFormat: NodeFilesFormat = this.formats.filter(
			(f) => f.type === values.type
		)[0];
		let type = values.type;
		let valueTypeNumber: number = 0;
		if (values.type == "pdf") type = "doc";
		if (values.type == "picture") type = "image";

		if (values.type == "video") values.fileName = values.fileName.split("|")[0];
		else if (values.type == "videoImage")
			values.fileName = values.fileName.split("|")[1];

		switch (values.type) {
			case this.VIDEO_TYPE:
				valueTypeNumber = 1;
				break;
			case this.IMAGE_TYPE:
				valueTypeNumber = 2;
				break;
			case this.AUDIO_TYPE:
				valueTypeNumber = 3;
				break;
			case this.PDF_TYPE:
				valueTypeNumber = 4;
				break;
		}

		this.nodeService
			.deleteFileFromNode(type, values.idNodeFile, values.fileName)
			.subscribe(
				(result) => {
					this.nodeService.deleteFile.next({
						type: values.type,
						idNodeFile: values.idNodeFile,
						fileName: values.fileName,
					});
					this.toaster.success(
						this.translateService.instant("NODOS.DELETEFILEOK")
					);
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("NODOS.DELETEFILEKO")
					);
				}
			);
	}

	onYoutubeVideo(value: YoutubeVideo): void {
		this.nodeService
			.postVideoYoutube(
				value.idNodeFile,
				value.url,
				value.start,
				value.end,
				value.nameFileVideo,
				value.isDelete,
				value.videoImage
			)
			.subscribe(
				(result) => {
					if (value.isDelete) {
						const videoFormat = this.formats.filter(
							(f) => f.type === this.VIDEO_TYPE
						)[0];
						videoFormat.url = "";
						videoFormat.start = null;
						videoFormat.end = null;
						videoFormat.isVideoYoutube = false;
						this.toaster.success(
							this.translateService.instant("YOUTUBEVIDEO.DELETED")
						);
					} else if (value.url) {
						const videoFormat = this.formats.filter(
							(f) => f.type === this.VIDEO_TYPE
						)[0];
						videoFormat.url = value.url;
						videoFormat.start = value.start;
						videoFormat.end = value.end;
						this.toaster.success(
							this.translateService.instant("YOUTUBEVIDEO.UPDATED")
						);
						value.videoImage = result.data.imageVideo;
					} else {
						const videoFormat = this.formats.filter(
							(f) => f.type === this.VIDEO_TYPE
						)[0];
						videoFormat.url = value.url;
						videoFormat.start = value.start;
						videoFormat.end = value.end;
						this.toaster.success(
							this.translateService.instant("YOUTUBEVIDEO.ADDED")
						);
						value.videoImage = result.data.imageVideo;
					}

					this.nodeService.videoYoutube.next(value);
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("YOUTUBEVIDEO.ERRORVIDEO")
					);
				}
			);
	}

	onInstagramVideo(value: InstagramVideo) {
		this.nodeService
			.postVideoInstagram(
				value.idNodeFile,
				value.url,
				value.videoImage,
				value.isDelete
			)
			.subscribe((result) => {
				if (value.isDelete) {
					const videoFormat = this.formats.filter(
						(f) => f.type === this.VIDEO_TYPE
					)[0];
					videoFormat.url = "";
					videoFormat.isVideoInstagram = false;
					this.toaster.success(
						this.translateService.instant("INSTAGRAMVIDEO.DELETED")
					);
				} else if (value.url) {
					const videoFormat = this.formats.filter(
						(f) => f.type === this.VIDEO_TYPE
					)[0];
					videoFormat.url = value.url = result.data.url;
					this.toaster.success(
						this.translateService.instant("INSTAGRAMVIDEO.UPDATED")
					);
					value.videoImage = result.data.videoImage;
				} else {
					const videoFormat = this.formats.filter(
						(f) => f.type === this.VIDEO_TYPE
					)[0];
					videoFormat.url = value.url = result.data.url;
					this.toaster.success(
						this.translateService.instant("INSTAGRAMVIDEO.ADDED")
					);
					value.videoImage = result.data.videoImage;
				}

				this.nodeService.videoInstagram.next(value);
			});
	}

	onTiktokVideo(value: TiktokVideo) {
		this.nodeService
			.postVideoTiktok(
				value.idNodeFile,
				value.url,
				value.videoImage,
				value.isDelete
			)
			.subscribe((result) => {
				if (value.isDelete) {
					const videoFormat = this.formats.filter(
						(f) => f.type === this.VIDEO_TYPE
					)[0];
					videoFormat.url = "";
					videoFormat.isVideoTiktok = false;
					this.toaster.success(
						this.translateService.instant("TIKTOKVIDEO.DELETED")
					);
				} else if (value.url) {
					const videoFormat = this.formats.filter(
						(f) => f.type === this.VIDEO_TYPE
					)[0];
					videoFormat.url = value.url;
					this.toaster.success(
						this.translateService.instant("TIKTOKVIDEO.UPDATED")
					);
					value.videoImage = result.data.videoImage;
				} else {
					const videoFormat = this.formats.filter(
						(f) => f.type === this.VIDEO_TYPE
					)[0];
					videoFormat.url = value.url;
					this.toaster.success(
						this.translateService.instant("TIKTOKVIDEO.ADDED")
					);
					value.videoImage = result.data.videoImage;
				}

				this.nodeService.videoTiktok.next(value);
			});
	}

	onSpotifyAudio(value: SpotifyAudio){
		this.nodeService
		.postSpotifyAudio(
			value.idNodeFile,
			value.url,
			value.isDelete
		)
		.subscribe((result) => {
			if(result.error.code === 1){
				this.node.nodesFiles.spotifyAudio = value.url;
				this.toaster.success(this.translateService.instant("SPOTIFYAUDIO.ADDED"));
			} else if(result.error.code === 0){
				this.node.nodesFiles.spotifyAudio = null;
				this.toaster.success(this.translateService.instant("SPOTIFYAUDIO.DELETED"));
			}
			//this.nodeService.spotifyAudio.next(value);
		});
	}

	/**
	 * Función para descargar un fichero
	 * @param value Valores del output
	 */
	onFileDownload(value: DownloadFileModel): void {
		let type: string = "";
		let bytes: number = null;
		const fileName = value.fileName.split("|")[0];

		document.body.style.cursor = "progress"; //
		this.toaster.success(
			this.translateService.instant("NODOS.DOWNLOADFILEOK")
		);

		switch (value.type) {
			case "picture":
				type = "pictureDown";
				break;
			case "video":
				type = "videoDown";
				break;
			case "audio":
				type = "audioDown";
				break;
			case "pdf":
				type = "pdfDown";
				break;
		}

		this.nodeService
			.downloadFile(fileName, type)
			.pipe(finalize(() => (document.body.style.cursor = "default")))
			.subscribe(
				(event: HttpEvent<any>) => {
					switch (event.type) {
						case HttpEventType.DownloadProgress:
							bytes = event.loaded * 0.000001; //event.total
							break;
						case HttpEventType.Response:
							saveAs(
								this.dataURItoBlob(event.body.data, event.body.contentType),
								fileName
							);
					}
				},
				(error) => {
					this.toaster.error(
						this.translateService.instant("NODOS.DOWNLOADFILEKO")
					);
				}
			);
	}

	private dataURItoBlob(dataURI: string, type): Blob {
		const byteString = window.atob(dataURI);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([int8Array], { type: type });

		return blob;
	}
}
