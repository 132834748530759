import { TranslateService } from '@ngx-translate/core';
import { PaisModel, MateriaModel } from 'src/app/core/models/masters';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseModel, UserModel } from 'src/app/core/models/courses';
import { CoursesService } from 'src/app/core/services/courses';
import { LoginService } from 'src/app/core/services/login';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
	selector: 'app-modal-information-course',
	templateUrl: './modal-information-course.component.html',
	styleUrls: ['./modal-information-course.component.scss']
})
export class ModalInformationCourseComponent implements OnInit {
	curso: CourseModel;
	editarInfo: boolean = false;
	public formulario: UntypedFormGroup;
	user: UserModel;

	countriesList: PaisModel[] = this.getDataService.appCountries;
	subjectsList: MateriaModel[] = this.getDataService.appSubjects;
	cargando: boolean;

	constructor(
		public activeModal: NgbActiveModal,
		private formBuild: UntypedFormBuilder,
		public loginService: LoginService,
		public courseService: CoursesService,
		public getDataService: GetDataService,
		public translateService: TranslateService,
		private toaster: ToasterService

	) {
		this.formulario = this.formBuild.group({
			title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
			description: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(500)]],
			subject: ['', [Validators.required]],
			level: [''],
			lang1: ['', [Validators.required]],
			published: [false],
			country: [],
			deckSize: [5, [Validators.required, Validators.min(5), Validators.max(100)]]
		})

		this.user = this.loginService.getUser();

	}

	ngOnInit() {
		this.formulario.patchValue({
			title: this.curso.courseTittle,
			description: this.curso.description,
			subject: this.curso.subject.idSubject,
			level: this.curso.courseSWLevel,
			lang1: this.curso.language.idLanguage,
			published: this.curso.published ? true : false,
			country: this.curso.idCountry,
			deckSize: this.curso.sonsNumber
		})
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	borrarTodo() {
		this.closeModal('borrarcurso')
	}

	guardarCambios() {
		const formValue = this.formulario.value;
		this.courseService.saveInfoCourse(formValue, this.curso.idCourse).subscribe(resp => {
			this.toaster.success(this.translateService.instant('EDITARCURSO.OKSAVE'));
			this.activeModal.close('Curso modificado');
		},
			error => {
				console.log(error);
				this.toaster.error(this.translateService.instant('EDITARCURSO.KOSAVE'));
			})

		return;
	}

}
