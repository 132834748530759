import { Component, ElementRef, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuRol } from 'src/app/core/models/shared/menu-rol.model';
import { LoginService } from 'src/app/core/services/login';
import { MastersService } from 'src/app/core/services/masters';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';

@Component({
  selector: 'app-sidebarmenu',
  templateUrl: './sidebarmenu.component.html',
  styleUrls: ['./sidebarmenu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarmenuComponent implements OnInit {

    menu: MenuRol[] = []
    menuSeleccionado: string;
    showMenuOptions: string = '';
    activeMenu:string = ''
    private subs = new Subscription

    @HostListener('document:click', ['$event'])
    clickout(event) {
      if(!this.eRef.nativeElement.contains(event.target)) {
        this.showSubmenu('')
      }
      else
        this.toolsService.setHideElements()
    }

  constructor(private masterService: MastersService, private loginService: LoginService, private toolsService: SigmaToolbarsService, private eRef: ElementRef) {
    this.subs = this.toolsService.hideMenuLateral.subscribe(val => {
        this.showSubmenu('')
    })
  }

  ngOnInit() {
    this._profileChanged();
    this._loadSideBar()
  }

  private _profileChanged(): void {
    this.loginService.currentProfile.subscribe(val => {
        let currentProfile;
        !val ? currentProfile = this.loginService.getUser().profile : currentProfile = val.profile
        this.activeMenu = currentProfile
    })
  }

  private _loadSideBar():void {
    this.menu = this.masterService.getMenuRol()
  }

  showSubmenu(className:string): void{

    if(this.activeMenu != className && className !== '')
        this.activeMenu = ''

    if(this.showMenuOptions === className)
        this.showMenuOptions = ''
    else
        this.showMenuOptions = className
  }

}
