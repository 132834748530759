<div class="h-100 w-100 file-uploader">
    <mat-button-toggle type="button" class="h-100 w-100" id={{format.name}} [style.background]="format.background" [style.backgroundSize]="'cover'" [style.backgroundRepeat]="'no-repeat'" [style.backgroundPosition]="'center'" (click)="playFile()">
        <div class="w-100 h-100" style="position: absolute;" [innerHtml]="format.background" *ngIf="format.type === TXT_TYPE"></div>
        <div class="w-100 h-100 flex flex-column align-items-center justify-content-center">
						<div class="w-100 h-100 flex flex-column align-items-center justify-content-center"
							*ngIf="format.type === PDF_TYPE && format.background" >
							<!-- <ng-container *ngIf="ext === 'docx'; else embedPdf">
								<ngx-doc-viewer [url]="format.background"
									viewer="office" class="w-100 h-100" ></ngx-doc-viewer>
							</ng-container>
							<ng-template #embedPdf >
								<embed *ngIf="sanitizedUrl" [src]="sanitizedUrl" class="w-100 h-100"  />
							</ng-template> -->

							<nb-icon style="font-size: 2rem;" icon="file-text-outline"></nb-icon>
							<h6>PDF</h6>
            </div>
						<mat-icon class="medios pointer" ngbTooltip="{{format.name | translate}}" placement="top" style="z-index: 10"
							*ngIf="format.type !== AUDIO_TYPE || (format.type === AUDIO_TYPE && !node.nodesFiles.spotifyAudio)">
							{{format.icon}}
						</mat-icon>
						<div *ngIf="format.type === AUDIO_TYPE && node.nodesFiles.spotifyAudio">
							<mat-icon class="medios pointer" style="z-index: 10" svgIcon="spotify"></mat-icon>
							<h6 style="color: green; line-height: 1.2;">Spotify</h6>
						</div>
        </div>
    </mat-button-toggle>
</div>
