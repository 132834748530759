<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="flex border-bottom-0">
		<div class="row align-items-center w-100">
			<div class="col-12 col-sm-10 col-md-8">
				<h4>{{'MODALLISTSTUDENTS.ASSIGNSTUDENTS' | translate}} {{group.title}}</h4>
			</div>
			<div class="col-12 col-sm-12 col-md-3 text-right mt-md-0 mt-2" *ngIf="newGroup">
				<button nbButton class="btn-themecolor" size="small" (click)="terminarNuevoGrupo(group)" class="mr-1">{{ "FORM.FINISH" | translate }}</button>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'pulse' } }" (click)="closeModal('')"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<app-loading [isVisible]="cargando"></app-loading>

			<nb-card class="no-edge-card">
				<div class="row m-1 justify-content-center">
					<div class="col-md-5 col-sm-12 col-12 p-0">
						<form [formGroup]="formulario" class="searchBar w-100" #thisForm="ngForm">
							<div class="form-group">
								<div class="input-container">
									<input type="search" nbInput fullWidth status="basic" class="form-control"
									placeholder="{{ 'MODALLISTSTUDENTS.SEARCHSTUDENTSTOASSIGN' | translate }}" name="fiilter" name="filter"
									formControlName="filtrado" autocomplete="off" (keyup)="filtrarEstudiantes()">
									<mat-icon class="search-icon">search</mat-icon>
								</div>
							</div>
						</form>
						<ul class="list-group" *ngIf="users.length > 0">

							<li style="height: auto; gap: 10px;" *ngFor="let user of users | sortBy:'asc':'firstName'"
								class="list-group-item list-group-item-action d-flex justify-content-between align-items-center flex-column flex-sm-row">
								<div>
									{{user.firstName}}&nbsp;{{user.surname}}
								</div>

								<div style="color: red;" placement="right" *ngIf="user.invitedToGroup == 'T'">
									({{'MODALLISTSTUDENTS.WAITACCEPT' | translate}})
								</div>

								<div>
									<!-- <span (click)="enviarInvitacion(user)" [ngbTooltip]="'MODALLISTSTUDENTS.SENDINVITATION' | translate" placement="left"
										*ngIf="user.invitedToGroup == 'F'" class="badge badge-pill">
										<mat-icon>person_add</mat-icon>
									</span> -->
									<button nbButton size="small" *ngIf="user.invitedToGroup == 'F'" class="btn-themecolor" (click)="enviarInvitacion(user)">
										{{ 'MODALLISTSTUDENTS.SENDINVITATION' | translate }}
									</button>
									<!-- <span [ngbTooltip]="'MODALLISTSTUDENTS.INVITATIONSENT' | translate" placement="left"
										*ngIf="user.invitedToGroup == 'T'" class="badge-disabled badge-pill">
										<mat-icon>schedule_send</mat-icon>
									</span> -->
								</div>

							</li>
						</ul>
						<!-- <div *ngIf="users.length === 0 && formulario.value.filtrado.length !== 10" class="alert alert-info" role="alert">{{'MODALLISTSTUDENTS.TYPEIDENTIFICATION' |
							translate}}</div> -->
						<div *ngIf="resultFiltro === 2" class="alert alert-info" role="alert" style="background-color: rgb(140, 0, 0); color: white;">{{'MODALLISTSTUDENTS.USEREXIST' |
							translate}} </div>
						<div *ngIf="resultFiltro === 1" class="alert alert-info" role="alert" style="background-color: rgb(140, 0, 0); color: white;">{{'MODALLISTSTUDENTS.IDNOEXIST' |
							translate}} </div>
					</div>

					<div class="col-md-1 col-sm-12 col-12 flex justify-content-center align-items-center my-md-0 my-4">
						<!-- <nb-icon class="arrow" icon="arrow-circle-right" status="basic"> </nb-icon> -->
						<div class="vertical-line"></div>
					</div>

					<div class="col-md-5 col-sm-12 col-12 p-0">
						<div class="w-100 position-relative d-flex justify-content-center form-group">
							<h3>{{ 'MODALLISTSTUDENTS.ASSIGNEDSTUDENTS' | translate }}</h3>
						</div>
						<ul class="list-group" *ngIf="usersInscritos.length > 0">
							<li *ngFor="let user of usersInscritos| sortBy:'asc':'firstName'"
								class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
								{{user.firstName}}&nbsp;{{user.surname}}
								<span (click)="eliminarEstudiante(user)" class="badge badge-pill" >
									<mat-icon class="pointer color-red-text" ngbTooltip="{{ 'MODALLISTSTUDENTS.UNASSIGNSTUDENT' | translate }}" placement="left">delete</mat-icon>
								</span>
							</li>
						</ul>
						<!--<div *ngIf="usersInscritos.length === 0" class="alert alert-info" role="alert">
							{{'MODALLISTSTUDENTS.EMPTYLIST' | translate}}</div>-->
					</div>
				</div>
			</nb-card>
	</nb-card-body>
</nb-card>
