import { Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/core/services/login';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';
import { PadsUtils } from 'src/app/core/utils/pads.utils';

@Component({
	selector: 'app-node-pad-buttons',
	templateUrl: './node-pad-buttons.component.html',
	styleUrls: ['./node-pad-buttons.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class NodePadButtonsComponent implements OnInit {

	@Input() isShow: boolean
	@Input() isShowLabels:boolean
	@Input() isTeacher?: boolean
	@Input() viewQuiz?: boolean = false;
	@Output() action = new EventEmitter<string>()
    @Output() onHoverElement:EventEmitter<boolean> = new EventEmitter<boolean>();

	_topButton: string = ''
	_leftButton: string = ''
	_rightButton: string = 'check_graph'
	_bottomButton: string = 'assets/images/icons/idea-act.png'
	viewCopilotPadLeft: boolean;
	courseId: number;
	graphId: number;

	constructor() { }

	ngOnInit() {}

	clickAction(value:string){
		this.action.emit(value)
	}

    onEnterPad(value){
        this.onHoverElement.emit(value)
    }

}
