<nb-card class="no-edge-card modal-max-height-100-65 mb-0 px-3 py-4" *ngIf="step == 1">
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="row d-flex justify-content-center cards-container">
			<div class="col-12">
				<nb-card class="background_themecolor mb-0 p-4">
					<nb-card-header>
						<div class="row">
							<div class="col-12">
								<h3 class="text-center">
									{{ 'TUTORIAL.TUTORIALMANAGER' | translate }}
								</h3>
							</div>
						</div>
					</nb-card-header>
					<nb-card-body class="row m-1 justify-content-center">
						<div class="col-6">
							<ul *ngIf="contextList.length > 0">
								<li *ngFor="let context of contextList" class="d-flex mb-2 border pointer" [class.active]="context === selectedContext">
									<div class="col-8" (click)="loadVideoList(context)">
										<p>{{context.context}}</p>
									</div>
									<div class="col-4" (click)="loadVideoList(context)">
										(Videos: {{context.videoList.length}})
									</div>
								</li>
							</ul>
						</div>
						<div class="col-6">
							<div style="text-align: center;" *ngIf="selectedContext">
								<nb-icon class="pointer card-img-top" icon="plus-circle-outline" nbTooltip="Agregar Video" (click)="verVideosDisponibles(selectedContext)"></nb-icon>
							</div>
							<ul *ngIf="videoList.length > 0">
							  <li *ngFor="let video of videoList" class="d-flex mb-2 border">
								<div class="row">
									<div class="card" style="width: 18rem;">
									  <!-- Miniatura -->
									  <img 
										class="card-img-top" 
										[src]="'https://img.youtube.com/vi/' + getVideoId(video.urlVideo) + '/hqdefault.jpg'" 
										alt="{{video.title}}">
									  <div class="card-body d-flex align-items-center justify-content-between">
										<!-- Título del video -->
										<p class="card-text">{{ video.title }}</p>
										<!-- Iconos -->
										<a [href]="video.urlVideo" target="_blank" class="text-decoration-none">
										  <nb-icon class="pointer" icon="monitor-outline" nbTooltip="Abrir Video"></nb-icon>
										</a>
										<nb-icon class="pointer" icon="trash-2-outline" nbTooltip="Eliminar Video" (click)="onDeleteVideo(video)"></nb-icon>
									  </div>
									</div>
									<div class="d-flex align-items-center">
										<nb-icon class="pointer" icon="arrow-up-outline" nbTooltip="Subir una posición"></nb-icon>
										<nb-icon class="pointer" icon="arrow-down-outline" nbTooltip="Bajar una posición"></nb-icon>
									</div>
								</div>
							  </li>
							</ul>
						  </div>						  
					</nb-card-body>
				</nb-card>
			</div>
		</div>
	</nb-card-body>
</nb-card>

<nb-card class="no-edge-card modal-max-height-100-65 mb-0 px-3 py-4" *ngIf="step == 2">
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="backToStep1()" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-header>
		<div class="row">
			<div class="col-12">
				<h3 class="text-center">
					Videos disponibles
				</h3>
			</div>
		</div>
	</nb-card-header>
	<nb-card-body>
		<button nbButton status="info" (click)="goToStep3()">
			¿No encuentras el vídeo? Ingresalo aquí
		</button>
		<ul *ngIf="videosDisponibles.length > 0">
			<li *ngFor="let video of videosDisponibles" class="mb-2 border">
				<div class="card" style="width: 18rem;">
				<!-- Miniatura -->
				<img 
					class="card-img-top" 
					[src]="'https://img.youtube.com/vi/' + getVideoId(video.urlVideo) + '/hqdefault.jpg'" 
					alt="{{video.title}}">
				<div class="card-body d-flex align-items-center justify-content-between">
					<!-- Título del video -->
					<p class="card-text">{{ video.title }}</p>
					<!-- Iconos -->
					<a [href]="video.urlVideo" target="_blank" class="text-decoration-none">
					<nb-icon class="pointer" icon="monitor-outline" nbTooltip="Abrir Video"></nb-icon>
					</a>
					<nb-icon class="pointer" icon="plus-circle-outline" nbTooltip="Agregar Video" (click)="onAddVideo(video)"></nb-icon>
				</div>
				</div>
			</li>
		</ul>
		<p *ngIf="videosDisponibles.length === 0">No hay videos disponibles.</p>
	</nb-card-body>
</nb-card>

<nb-card class="no-edge-card modal-max-height-100-65 mb-0 px-3 py-4" *ngIf="step == 3">
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="backToStep2()" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-header>
		<div class="row">
			<div class="col-12">
				<h3 class="text-center">
					Inserta la url de YouTube
				</h3>
			</div>
		</div>
	</nb-card-header>
	<nb-card-body class="row">		
		<div class="col-6 mb-2">
			<label class="text-color-secondary">Título</label>
			<input nbInput status="basic" fullWidth fieldSize="medium" shape="round" placeholder="Vídeo Tutorial de prueba" [(ngModel)]="videoTitle" (ngModelChange)="validateTitle()"/>
		</div>
		<div class="col-6 mb-2">
			<label class="text-color-secondary">Url</label>
			<input nbInput status="basic" fullWidth fieldSize="medium" shape="round" placeholder="https://www.youtube.com/..." [(ngModel)]="videoUrl" (ngModelChange)="validateUrl()"/>
			<div *ngIf="videoUrl && !isValidUrl" class="text-danger mt-1">
				Por favor, ingresa una URL válida de YouTube.
			</div>
			<div *ngIf="isValidUrl" class="text-success mt-1">
				¡URL válida!
			</div>
		</div>
	</nb-card-body>
	<nb-card-footer>
		<button nbButton status="info" [disabled]="!isValidTitle || !isValidUrl" (click)="saveUrl()">
			Guardar vídeo
		</button>
	</nb-card-footer>
</nb-card>