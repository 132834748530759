
<div class="d-flex flex-column align-items-center justify-content-center">
    <div *ngIf="grabando" class="d-flex justify-content-center">
      <p class="text-break card-text font-weight-bold"><span id="minutes">{{minutes}}</span>:<span id="seconds">{{seconds}}</span></p>
    </div>
    <div class="d-flex justify-content-center flex-column">
        <div class="Container-audio-quiz" *ngIf="!grabando && audioFiles">
          <audio *ngFor="let audio of audioFiles" controls='true' [src]="audio"></audio>
					<mat-icon (click)="deleteAll()" class="stop pointer position-absolute" >delete</mat-icon>
        </div>
        <div *ngIf="grabando">
          <img src="/assets/images/dots_2.gif" width="30px" height="30px" class="loading-gif" alt="{{ 'LOADING.CARGANDO' | translate }}" />
        </div>
    </div>
		<div *ngIf="grabando" class="d-flex justify-content-center flex-column align-items-center">
			<img [src]="Stop" width="40px" height="40px" class="pointer" (click)="stopRecording()" />
			<span class="font-weight-bold">{{'MODALAUDIOMICRO.STOPRECORD' | translate}}</span>
    </div>
</div>
