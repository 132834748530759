<div class="pad-left-container" [ngStyle]="{'visibility': isShow ? 'visible' : 'hidden' }">

    <div class="pad-buttons-left d-flex justify-content-center flex-column">
        <!-- TOP CENTER BUTTON -->
        <div class="d-flex justify-content-center position-relative" (mouseenter)="onEnterPad(true)" (mouseleave)="onEnterPad(false)">
            <label class="label top" *ngIf="isShowLabels">{{'PADSLABELS.IDEA' | translate}}</label>
            <div class="circle-button" (click)="clickAction('idea')" [ngbTooltip]="!isShowLabels ? ('PADSLABELS.IDEA' | translate) : ''">
                <img style="width: 18px; height: 29px;" src="assets/images/icons/idea.png" />
            </div>
        </div>

        <!-- LEFT AND RIGHT BUTTONS -->

        <div class="d-flex justify-content-between align-items-center" (mouseenter)="onEnterPad(true)" (mouseleave)="onEnterPad(false)">
            <div class="position-relative d-flex align-items-center">
                <label class="label left" *ngIf="isShowLabels">{{'PADSLABELS.HELP' | translate}}</label>
                <div class="circle-button disabled" (click)="clickAction('help')" [ngbTooltip]="!isShowLabels ? ('PADSLABELS.HELP' | translate) : ''">
                    <mat-icon svgIcon="sos" class="icon-color-blue"></mat-icon>
                </div>
            </div>
            <div class="position-relative d-flex align-items-center">
                <label class="label right" *ngIf="isShowLabels" (click)="clickAction('close')" data-click="yes">{{'PADSLABELS.GRAPH' | translate}}</label>
                <div class="circle-button" (click)="clickAction('close')" [ngbTooltip]="!isShowLabels ? ('PADSLABELS.GRAPH' | translate) : ''">
                    <mat-icon svgIcon="graph" class="icon-color-blue"></mat-icon>
                </div>
            </div>

        </div>

        <!-- BOTTOM CENTER BUTTON -->
        <div class="d-flex justify-content-center position-relative" (mouseenter)="onEnterPad(true)" (mouseleave)="onEnterPad(false)">
            <div class="circle-button" (click)="clickAction('check')" [ngbTooltip]="!isShowLabels ? ('PADSLABELS.CHECK' | translate) : ''">
                <mat-icon svgIcon="done" class="icon-color-blue"></mat-icon>
            </div>
            <label class="label bottom" *ngIf="isShowLabels">{{'PADSLABELS.CHECK' | translate}}</label>
        </div>
    </div>
</div>
