<div class="sticky-top">
    <div class="modal-header" align="center">
        <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'SETTINGS.FATHERTITLE' | translate }}</span>

        <button type="button" class="close" data-dismiss="modal" (click)="closeModal('')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>

<div class='fondoEditarCurso'>

    <div class="col-12">
        <app-alert context="UserData"></app-alert>
    </div>

    <form [formGroup]="formularioDatos" (ngSubmit)="grabarDatos()" #thisForm="ngForm" class="m-5 p-4 card">

        <div class="row">
            <div class="col-sm-12" align="center">
                <span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'USERDATA.COMPLETATUPERFILCONLOSSIGUIENTESDATOS' | translate }}</span>
            </div>
        </div>

        <div class="row mt-4">

            <div class="col-sm-2"></div>

            <div class="col-sm-8">

                <div class="row">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">credit_card</i>
                    </div>

                    <div class="col-4">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.TARJETACREDITO' | translate }}</mat-label>
                            <input matInput formControlName="creditCard" mask="0000 0000 0000 0000">
                            <mat-error *ngFor="let validation of validationMessages.creditCard">
                                <mat-error class="error-message break-line" *ngIf="formularioDatos.get('creditCard')?.hasError(validation.type) && (formularioDatos.get('creditCard')?.dirty || formularioDatos.get('creditCard')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.CADUCIDADMMDD' | translate }}</mat-label>
                            <input matInput formControlName="expirationDateCard" mask="00/00">
                            <mat-error *ngFor="let validation of validationMessages.expirationDateCard">
                                <mat-error class="error-message break-line" *ngIf="formularioDatos.get('expirationDateCard')?.hasError(validation.type) && (formularioDatos.get('expirationDateCard')?.dirty || formularioDatos.get('expirationDateCard')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.CVV' | translate }}</mat-label>
                            <input matInput formControlName="cvv" mask="000">
                            <mat-error *ngFor="let validation of validationMessages.cvv">
                                <mat-error class="error-message break-line" *ngIf="formularioDatos.get('cvv')?.hasError(validation.type) && (formularioDatos.get('cvv')?.dirty || formularioDatos.get('cvv')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-9">
                    </div>

                    <div class="col-3" align="right">
                        <button class="botonLoginEntrar" type="submit">
                            <span>{{ 'USERDATA.GRABAR' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-sm-2"></div>
        </div>
    </form>

    <form [formGroup]="formularioDatosHijo" #thisFormPadres="ngForm" class="pt-4" class="m-5 p-4 card">

        <div class="row">
            <div class="col-sm-12" align="center">
                <span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'USERDATA.DATOSHIJOS' | translate }}</span>
            </div>
        </div>

        <div class="row mt-4">

            <div class="col-md-4" align="center">

                <div *ngIf="sonsData.length > 0">
                    <span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'USERDATA.HIJOS' | translate }}</span>

                    <div class="my-2 caja-blanca caja-blanca-height borde-negro">
                        <mat-list>
                            <mat-list-item *ngFor="let son of sonsData" (click)="mostrarDatosHijo(son.idUser)" [ngClass]="idSonSelected == son.idUser ? 'pestana-activa' : ''">
                                {{ son.firstName }} {{ son.surname }}
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </div>

            <div *ngIf="sonsData.length === 0" align="center" class="col-md-12">
                <span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'USERDATA.NOHAYNINGUNHIJOREGISTRADO' | translate }}</span>
            </div>

            <div *ngIf="verDetalleHijo" class="col-md-8">

                <div class="row form-group my-3">

                    <div class="col-sm-4"></div>

                    <div class="col-sm-4">
                        <div class="card form-group" align="center">
                            <img *ngIf="sonData != undefined" [src]="sonData.pictureUser | imagen:'users'" class="card-img-top img-circle">
                        </div>
                    </div>

                    <div class="col-sm-4"></div>
                </div>

                <div class="row form-group mt-3">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">account_circle</i>
                    </div>

                    <div class="col-5">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.NOMBRE' | translate }}</mat-label>
                            <input matInput formControlName="firstName" readonly>
                        </mat-form-field>
                    </div>

                    <div class="col-5">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.APELLIDOS' | translate }}</mat-label>
                            <input matInput formControlName="surname" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row form-group">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">mail_outline</i>
                    </div>

                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.EMAIL' | translate }}</mat-label>
                            <input matInput formControlName="mail" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">cake</i>
                    </div>

                    <div clasS="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.FECHANACIMIENTO' | translate }}</mat-label>
                            <input type="date" matInput formControlName="birthdate" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row form-group">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">phone</i>
                    </div>

                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.TELEFONOMOVIL' | translate }}</mat-label>
                            <input matInput formControlName="mobile" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="!sonValidated" class="row form-group">

                    <div class="col-4"></div>

                    <div class="col-4">
                        <button class="botonLoginRegistrar">{{ 'USERDATA.ESMIHIJO' | translate }}</button>
                    </div>
                </div>

                <div *ngIf="sonValidated" class="row form-group">

                    <div class="col-4"></div>

                    <div class="col-4" align="center">
                        <span class="textoFontSize09 colorTextoActivado">{{ 'USERDATA.YAHASIDOVALIDADO' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <!-- <app-loading [isVisible]="loading"></app-loading> -->
</div>