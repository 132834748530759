<div class="modal-header">
    {{ 'QUIZZES.QUESTIONTEXT' | translate }}
    <button type="button" class="close" data-dismiss="modal" aria-label="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <quill-editor #editor style="height: 60vh;" [(ngModel)]="auxText" (onEditorCreated)="setFocus($event)"></quill-editor>
</div>
<div class="modal-footer border-0 pt-0">
    <button mat-flat-button color="primary" type="button" class="mr-3" (click)="save()">{{'NODEFORMCOMPONENT.SAVE' | translate}}</button>
    <button mat-flat-button type="button" (click)="close()">{{'NODEFORMCOMPONENT.VOLVER' | translate}}</button>
</div>
