<div *ngIf="quiz" class="w-100 h-100">

	<ng-container *ngIf="quiz.quizType == 0; else quizBody">
		<div class="flex">
			<div class="alert alert-primary col-3">
				<p class="mb-0">{{'QUIZZES.SELECTQUESTION' | translate}}</p>
			</div>
		</div>
	</ng-container>

	<ng-template #quizBody>
		<div>
			<app-quiz-edit-body-info (saveData)="onSaveInfo($event)" [quiz]="quiz"></app-quiz-edit-body-info>
		</div>
		<div class="mt-4">
			<h4>{{'QUIZZES.BODY.ENUNCIADO' | translate}}</h4>
			<app-quiz-edit-body-data [questions]="questions" (saveData)="onSaveData($event)"
				(deleteData)="onDeleteData($event)"></app-quiz-edit-body-data>
		</div>
		<div class="mt-4">
			<h4>{{'QUIZZES.RESPUESTA' | translate}} <span class="h6">{{'QUIZZES.RESPONSEDETAIL' | translate}}</span></h4>
			<app-quiz-edit-body-option [options]="options" [quizType]="quiz.quizType" (saveOption)="onSaveOption($event)"
				(deleteOption)="onDeleteOption($event)" (multipleSave)="onSaveOptions($event)"></app-quiz-edit-body-option>
		</div>
		<div *ngIf="quiz" class="mt-4">
			<div *ngIf="quiz.quizType == 4 || quiz.quizType == 1" class="col-12 flex ">
				<h4>{{'CURSOMODOAUTO.EXPLICACION' | translate}}</h4>
				<div class="ml-3">
					<button nbButton size="large" class="btn btn-primary" [ngClass]="{'selected-button': quiz.quizType == 1}"
						[disabled]="quiz.quizType == 1" (click)="onQuizTypeSelected(1)">
						{{'QUIZZES.CORRECTEDBYTEACHER' | translate}}
						<nb-icon *ngIf="quiz.quizType == 1" icon="checkmark-circle-2"></nb-icon>
					</button>
				</div>
				<div class="ml-3">
					<button nbButton size="large" class="btn btn-primary" [ngClass]="{'selected-button': quiz.quizType == 4}"
					[disabled]="quiz.quizType == 4" (click)="onQuizTypeSelected(4)">
						{{'QUIZZES.CORRECTEDBYIA' | translate}}
						<nb-icon *ngIf="quiz.quizType == 4" icon="checkmark-circle-2"></nb-icon>
					</button>
				</div>
			</div>

			<app-quiz-edit-body-answer [answers]="answers" (saveAnswer)="onSaveAnswer($event)"
				(deleteAnswer)="onDeleteAnswer($event)" (activarIA)="activarIA($event)"></app-quiz-edit-body-answer>
		</div>
	</ng-template>

</div>
