import { GetDataService } from './../../core/services/get-data/get-data.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { TablaInformeService } from "../../core/services/tabla-informe.service";
import { AlertService } from "src/app/core/services/shared";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatLegacyPaginator as MatPaginator, MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatTableDataSource as MatTableDataSource, MatTableModule as MatTableModule } from '@angular/material/table';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { LiveAnnouncer } from "@angular/cdk/a11y";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { UsersService } from "src/app/core/services/users";
import { UserCardInfoComponent } from "src/app/shared/components/user-card-info/user-card-info.component";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { AdminHistory } from 'src/app/core/models/users/user.models';

export interface TableStructure {
	fecha: string;
	perfiles: string;
	nombre: string;
	identificacion: string;
	correo: string;
	mobile: string;
	centro: string;
	accesos_diarios: number;
	proyectos: number;
	ideografos: number;
	ideas: number;
	actividades: number;
	videos_subidos: number;
	imagenes_subidas: number;
	audios_subidos: number;
	documentos_subidos: number;
	textos_subidos: number;
	desafios: number;
	ideografos_abiertos: number;
	ideas_abiertas: number;
	actividades_resp: number;
	pictureUser: string;
}

interface Center {
	idCenter: number;
	idUser: number;
	nameCenter: string;
}

interface Profile {
	idProfile: number;
	nameProfile: string;
}

@Component({
	selector: "app-table-informe-individual",
	templateUrl: "./table-informe-individual.component.html",
	styleUrls: ["./table-informe-individual.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class TableInformeIndividualComponent implements OnInit {

	displayedColumns: string[] = [
		'fecha',
		'nombre',
		'identificacion',
		'correo',
		'mobile',
		'centro',
		'accesos_diarios',
		'proyectos',
		'ideografos',
		'ideas',
		'actividades',
		'videos_subidos',
		'imagenes_subidas',
		'audios_subidos',
		'documentos_subidos',
		'textos_subidos',
		'desafios',
		'ideografos_abiertos',
		'ideas_abiertas',
		'actividades_resp',
		'pictureUser'
	];

	profilesList: Profile[] = [
		{ idProfile: 1, nameProfile: 'Estudiante' },
		{ idProfile: 2, nameProfile: 'Profesor' },
		{ idProfile: 3, nameProfile: 'Padre' },
		{ idProfile: 4, nameProfile: 'Editor' },
		{ idProfile: 5, nameProfile: 'Todos' }
	];

	centers: Center[] = [];
	formDate: UntypedFormGroup;
	data: [];
	currentDate = new Date();
	dateNow = "";
	showTabla = false;
	today = new Date();
	year = this.today.getFullYear();
	month = this.today.getMonth() + 1; // Los meses en JavaScript son 0-11
	day = this.today.getDate();
	formattedDate = `${this.year}-${this.month.toString().padStart(2, '0')}-${this.day.toString().padStart(2, '0')}`;
	dataSource: MatTableDataSource<unknown>;
	auxDataSource: MatTableDataSource<unknown>;
	private destroy$ = new Subject();
	uniqueUserIds: number[];

	allCenters: any;
	groupsOfTeachers: any[] = [];
	p: number = 1;
	public cargando: boolean = false; // para el spinner
	idCenter: number;
	profilesTable: number[];
	nameCenter: string;

	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	selectedProfile: string;
	uniqueUserIdsTeachersOnly: number[];
	adminHistoryData: { uniqueUserIds: number[]; center: string; };

	constructor(
		private fb: UntypedFormBuilder,
		private service: TablaInformeService,
		private alertService: AlertService,
		public translateService: TranslateService,
		private _liveAnnouncer: LiveAnnouncer,
		public usersService: UsersService,
		private dialog: MatDialog,
		public activeModal: NgbActiveModal,
		public GetDataService: GetDataService,
		public gruposService: GruposService

	) { }

	ngOnInit() {
		this.createForm();
		this.getCenters();
		this.selectedProfile = 'Todos';
	}

	ngOnDestroy() {
		this.destroy$.next(true);
	}

	createForm() {
		this.formDate = this.fb.group({
			desde: [this.formattedDate],
			hasta: [this.formattedDate],
			search: [''],
			profile: [''],
			center: ['Todos']
		});
	}

	/**
	 * Obtiene los estudiantes de los grupos de los profesores seleccionados
	 * y los suma al listado de Ids de usuarios para luego hacer la peticion
	 *
	 * @memberof TableInformeIndividualComponent
	 */
	getStudentsIdList() {
		let processedGroups = 0;
		let studentsIds = [];
		this.groupsOfTeachers.forEach(group => {
			this.gruposService.getListEstudiantes(group).pipe(takeUntil(this.destroy$), take(1)).subscribe((res: any) => {
				processedGroups++;
				if (res) {
					studentsIds.push(...res.data.map(item => item.idUser));
				}
				if (processedGroups === this.groupsOfTeachers.length) {
					this.uniqueUserIds = [...this.uniqueUserIds, ...studentsIds];
					this.filterDate();
				}
			}
			);
		});
	}
	/**
	 * Se ejecuta al seleccionar un centro
	 * Obtiene los grupos del centro seleccionado y los guarda en un array
	 * al terminar llama al metodo que obtiene los estudiantes de los grupos
	 *
	 * @param {*} idCenter
	 * @memberof TableInformeIndividualComponent
	 */
	selectCenter(idCenter) {
		if (idCenter === 0) {
			this.uniqueUserIds = this.uniqueUserIdsTeachersOnly;
			this.idCenter = 0;
			this.filterDate();
			return;
		}

		if(this.profilesTable === undefined)
			this.profilesTable = [5];

		let centerSelecteds = this.filterArrayByIdCenter(this.allCenters, idCenter);
		this.nameCenter = centerSelecteds[0].nameCenter.toString().toLowerCase();
		this.idCenter = centerSelecteds[0].idCenter;
		const filteredData = this.auxDataSource.data.filter((item: any) => {
			// Verifica si el campo 'profiles' incluye el filtro 'profiles' Y el campo 'centro' incluye el filtro 'nameCenter'.
			return (
				item.profiles.toString().toLowerCase().includes(this.profilesTable) &&
				item.centro.toString().toLowerCase().includes(this.nameCenter)
			  );
		});
		this.dataSource = new MatTableDataSource(filteredData);
		setTimeout(() => {
			this.dataSource.sort = this.sort;
			this.dataSource.paginator = this.paginator;
		}, 100);

	}

	selectProfile = (profiles) => {
			this.profilesTable = profiles;
			const filteredData = this.auxDataSource.data.filter((item: any) => {
			// Verifica si el campo 'profiles' incluye el filtro 'profiles' Y el campo 'centro' incluye el filtro 'nameCenter'.
			this.nameCenter = this.nameCenter === undefined ? "" : this.nameCenter;
			return (
				item.profiles.toString().toLowerCase().includes(this.profilesTable) &&
				item.centro.toString().toLowerCase().includes(this.nameCenter)
			  );
			});
			this.dataSource = new MatTableDataSource(filteredData);
			setTimeout(() => {
				this.dataSource.sort = this.sort;
				this.dataSource.paginator = this.paginator;
			}, 100);

	};

	/**
	 *Obtener los centros para llenar el select de el filtro.
	 *
	 * @memberof TableInformeIndividualComponent
	 */
	getCenters() {
		this.GetDataService.getActiveCenters().pipe(takeUntil(this.destroy$), take(1))
			.subscribe((res: any) => {
				if (res) {
					this.allCenters = res.data;
					this.centers = this.removeDuplicateCenters(res.data);
					this.centers.push({ idCenter: 0, idUser: 0, nameCenter: 'Todos' });
					this.uniqueUserIds = this.getUniqueUserIds(res.data);
					this.uniqueUserIdsTeachersOnly = this.getUniqueUserIds(res.data);
				}
			});
	}

	/** Announce the change in sort state for assistive technology. */
	announceSortChange(sortState: Sort) {
		// This example uses English messages. If your application supports
		// multiple language, you would internationalize these strings.
		// Furthermore, you can customize the message to add additional
		// details about the values being sorted.
		if (sortState.direction) {
			this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
		} else {
			this._liveAnnouncer.announce('Sorting cleared');
		}
	}


	/**
	 *hacec la petición al endpoint para obtener los datos de la tabla
	 *
	 * @memberof TableInformeComponent
	 */
	filterDate() {
		this.cargando = true; //mostramos spinner
		this.dataSource = new MatTableDataSource([]);
		const desde = new Date(this.formDate.get("desde").value);
		const hasta = new Date(this.formDate.get("hasta").value);
		let desdeFormat = this.formatearFecha(desde);
		let hastaFormat = this.formatearFecha(hasta);
		//cambiar todos por lo que venga seleccionado en el combo de centro
		let center = 'Todos';
		if(this.idCenter != undefined && this.idCenter != 0)
			center = this.idCenter.toString();

		this.adminHistoryData = {
			uniqueUserIds: this.uniqueUserIds,
			center: center
		  };

		//se envian las fechas y el objeto para el post this.adminHistory con el centro y el array de roles
		this.service.getDataIndividual(desdeFormat, hastaFormat, this.adminHistoryData).pipe(takeUntil(this.destroy$), take(1))
			.subscribe((res: any) => {
				if (res) {
					try {
						this.data = res.data;
						this.dataSource = new MatTableDataSource(res.data);
						this.auxDataSource = this.dataSource;
						setTimeout(() => {
							this.dataSource.sort = this.sort;
							this.dataSource.paginator = this.paginator;
						}, 100);

						this.showTabla = true;
						this.formDate.get("search").setValue('');
					} catch (error) {
						this.alertService.error(
							this.translateService.instant("NOPAGEFOUND.HAOCURRIDOUNERROR")
						);
						this.showTabla = false;
					}
				}
				this.cargando = false; //ocultamos spinner
			});
	}

	/**
	 *formate la fecha en formato dd-MM-yyyy para enviarlo al endpoint
	 *
	 * @param {*} date
	 * @return formatted date
	 * @memberof TableInformeComponent
	 */
	formatearFecha(date) {
		var d = new Date(date),
			month = ("0" + (d.getMonth() + 1)).slice(-2),
			day = ("0" + d.getUTCDate()).slice(-2),
			year = d.getFullYear();
		return [year, month, day].join("-");
	}

	/**
	 *Epoxrtar a excel los datos de la tabla
	 *
	 * @param {MatTableDataSource<any>} dataSource
	 * @memberof TableInformeComponent
	 */
	exportToXLS = (dataSource: MatTableDataSource<any>) => {
		const data = dataSource.data;
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		const blobData: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
		saveAs(blobData, `${this.formatearFecha(this.formDate.get("desde").value)}_${this.formatearFecha(this.formDate.get("hasta").value)}.xlsx`);
	};

	/**
	 *filtra los datos de la tabla
	 *
	 * @param {MatTableDataSource<any>} dataSource
	 * @param {*} event
	 * @memberof TableInformeComponent
	 */
	filterDataSource = (event) => {

		//if the key is enter ignore it
		if (event.keyCode === 13) {
			return;
		}

		const filterValue = this.formDate.get('search').value.trim().toLowerCase();

		if (filterValue === '') {
			this.filterDate();
		}

		if (filterValue.length >= 3) {
			const filteredData = this.auxDataSource.data.filter((item: any) => {
				let match = false;
				Object.values(item).forEach((value: any) => {
					if (value != null && value != undefined && value.toString().toLowerCase().includes(filterValue)) {
						match = true;
					}
				});
				return match;
			});
			this.dataSource = new MatTableDataSource(filteredData);
			setTimeout(() => {
				this.dataSource.sort = this.sort;
				this.dataSource.paginator = this.paginator;
			}, 100);
		} else {
			// Si el valor del filtro es menor a 3 caracteres

		}
	};

	closeModal(sendData?: any) {
		this.activeModal.close();
	}

	clearFilter() {
		this.filterDate();
	}

	openUserCard(user) {
		const dialogRef: MatDialogRef<any> = this.dialog.open(UserCardInfoComponent, {
			panelClass: MODAL_DIALOG_TYPES.W80,
			width: '40%',
		});
		dialogRef.componentInstance.user = user;
	}

	getUniqueUserIds(centers: Center[]): number[] {
		const userIdsSet = new Set<number>();

		for (const center of centers) {
			userIdsSet.add(center.idUser);
		}

		return Array.from(userIdsSet);
	}

	removeDuplicateCenters(data: Center[]): Center[] {
		const uniqueCentersMap = new Map<number, Center>();

		for (const item of data) {
			if (!uniqueCentersMap.has(item.idCenter)) {
				uniqueCentersMap.set(item.idCenter, item);
			}
		}

		return Array.from(uniqueCentersMap.values());
	}

	filterArrayByIdCenter(inputArray: any[], idCenterToFilter: number): any[] {
		const filteredItems = inputArray.filter(item => item.idCenter === idCenterToFilter);
		const userMap = new Map<number, any[]>();

		for (const item of filteredItems) {
			if (userMap.has(item.idUser)) {
				userMap.get(item.idUser).push(item);
			} else {
				userMap.set(item.idUser, [item]);
			}
		}

		const filteredArray = Array.from(userMap.values())
			.reduce((acc, items) => [...acc, ...items], []);

		return filteredArray;
	}

	getTotalOfColumn(columnName: string) {
		let datos = this.dataSource.data as TableStructure[];
		let dataToFilter = datos.map(t => t[columnName]);
    	return dataToFilter.reduce((acc, value) => acc + value, 0);
  }


}
