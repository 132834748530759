export class QuizzesCourseModel {
    idNodeQuiz:number;
    idCourseQuiz: number;
    idCourse: number;
    idTarget: number;
    idQuiz: number;
    idNode:number;
    score:number;
    color:string;
    width:string;
    ordinal:number;
}
