import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-guia-login',
  templateUrl: './guia-login.component.html',
  styleUrls: ['./guia-login.component.scss']
})
export class GuiaLoginComponent {

	constructor(
		private dialogRef: NbDialogRef<any>,
	){}

	closeModal(sendData?: any) {
		this.dialogRef.close(sendData);
	}

}
