<nb-card class="mb-0 no-edge-card">
	<nb-card-body>
		<div style="width: 500px;">
			<div class="d-flex flex-column gap-4">
				<div class="d-flex flex-wrap justify-content-between gap-3">

					<ng-container *ngIf="selectedShape == 0; else conForma">
						<h6>Sin forma especifica</h6>
					</ng-container>
					<ng-template #conForma>
						<h6>Forma seleccionada: {{selectedShape}}</h6>
					</ng-template>

					<button nbButton (click)="nuevoMapa()">Nuevo Mapa</button>

					<div class="d-flex flex-row justify-content-center">
						<h6>Agregar todas</h6>
						<a (click)="openDesignIdeasModal()"
							class="col-1 p-0 badge pointer flex align-items-center"
							style="margin-left: 1em;"
							ngbTooltip="Agregar idea al grafo" placement="left">
							<nb-icon class="arrow" icon="arrow-circle-right" status="basic"></nb-icon>
						</a>
					</div>

					<!-- <div class="d-flex flex-column">
						<label for="select2">{{ 'SALWIZAR.SHAPE' | translate }}:</label>
						<nb-select [(ngModel)]="selectedForm" id="select2" class="SelectForm" hero size="small">
							<nb-option class="SelectFormOpt" value="circle">{{ 'SALWIZAR.CIRCULAR' | translate }}</nb-option>

						</nb-select>
					</div>

					<div class="d-flex flex-column">
						<label for="myRange">Radio: {{radioSelected}}</label>
						<input [(ngModel)]="radioSelected" type="range" nbInput fullWidth fieldSize="medium" class="form-control" min="400" max="800" value="100" id="myRange">
					</div> -->
				</div>

				<nb-accordion *ngIf="generatedIdeas" multi>
					<nb-accordion-item *ngFor="let idea of generatedIdeas; let i = index">
						<nb-accordion-item-header style="justify-content: space-between;">

							<div class="d-flex justify-content-between align-items-center gap-3">
								<h6>{{ idea.Titulo_de_la_Idea | slice:0:30 }}</h6>


							</div>
							<!-- <a (click)="addSingleIdea(idea,$event,idea.alreadyAdded)"
											class="col-1 p-0 badge pointer flex align-items-center"
											style="margin-right: 3em;"
											ngbTooltip="Agregar idea a1l grafo" placement="left">
											<nb-icon class="arrow" [icon]="idea.alreadyAdded == true ? 'arrow-circle-left' : 'arrow-circle-right'"  status="basic"> </nb-icon>
								</a> -->
						</nb-accordion-item-header>
						<nb-accordion-item-body>
							<textarea class="form-control" rows="5" [value]="idea.Cuerpo_Explicativo_de_la_Idea"
								(keyup)="updateGeneratedIdeas(i,$event)">{{idea.Cuerpo_Explicativo_de_la_Idea}}</textarea>
							<!-- {{idea.Cuerpo_Explicativo_de_la_Idea}} -->
						</nb-accordion-item-body>
					</nb-accordion-item>
				</nb-accordion>
			</div>
		</div>
	</nb-card-body>
	<!-- <nb-card-footer class="border-top-0">
		<div class="mb-2 row justify-content-center">
			<div class="col-12 d-flex justify-content-between">
				<ng-container *ngIf="AllIdeasAdded == false; else disabledAddAll">
					<button nbButton class="mr-1 btn-themecolor" (click)="openDesignIdeasModal()">
						{{ 'SALWIZAR.ADDALL' | translate }}
					</button>
				</ng-container>
				<ng-template #disabledAddAll>
					<button nbButton disabled>
						{{ 'SALWIZAR.ADDALL' | translate }}
					</button>
				</ng-template>
				<button nbButton status="danger" (click)="closeModal()">
					{{ 'FORM.FINISH' | translate }}
				</button>
			</div>
		</div>
	</nb-card-footer> -->
</nb-card>
