import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SurveyModel } from '../../models/surveys/survey.model';
import { SurveyStackModel } from '../../models/surveys/surveyStack.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SurveysService {

  constructor(
		private http: HttpClient,
		public translateService: TranslateService,
	) { }

	getAllSurveys() {
		const url = `survey/allSurveysList`;
		return this.http.get<any>(url);
	}

	getAllSurveysForEditor() {
		const url = `survey/allSurveysListForEditor`;
		return this.http.get<any>(url);
	}

	getAllSurveysForInforme() {
		const url = `survey/allSurveysListForInforme`;
		return this.http.get<any>(url);
	}

	getAllSurveysForInformeReal() {
		const url = `survey/allSurveysListForInformeReal`;
		return this.http.get<any>(url);
	}

	getAllQuestionsListBySurvey(idSurvey: number) {
		const url = `survey/questionsListBySurvey/${idSurvey}`;
		return this.http.get<any>(url);
	}

	createUpdateSurvey(survey: SurveyModel, file?: File | string): Observable<any> {
		let form: FormData = new FormData();
		form.append("surveys", JSON.stringify(survey));
		form.append("files", file);
		const url = `survey/createUpdateSurvey`;
		return this.http.post<any>(url, form);
	}

	deleteSurvey(idSurvey: number) {
		const url = `survey/deleteSurvey/${idSurvey}`;
		return this.http.delete<any>(url);
	}

	getSurveyToAnswer(idSurvey: number) {
		const url = `survey/getSurveyToAnswer/${idSurvey}`;
		return this.http.get<any>(url);
	}

	getSocialInformeCenter(idSurvey: number) {
		const url = `survey/xxxsocialInformeCenter/${idSurvey}`;
		return this.http.get<any>(url);
	}

	getSocialInformeCenterReal(idSurvey: number) {
		const url = `survey/socialInformeCenter/${idSurvey}`;
		return this.http.get<any>(url);
	}

	getSocialInformeCenterByEvent(idSurvey: number, idEvent: number) {
		const url = `survey/socialInformeCenterByEvent/${idSurvey}/${idEvent}`;
		return this.http.get<any>(url);
	}

	createUpdateSurveyStack(stack: SurveyStackModel[]) {
		const url = `survey/createUpdateSurveyStack`;
		return this.http.post<any>(url, stack);
	}

}
