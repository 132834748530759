import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NbActionsModule, NbCardModule, NbFormFieldModule, NbIconModule, NbSelectModule, NbTooltipModule } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
	selector: "app-modal-students-list",
	standalone: true,
	imports: [
		CommonModule,
		NbCardModule,
		NbIconModule,
		NbTooltipModule,
		NbSelectModule,
		NbActionsModule,
		NbFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
	],
	templateUrl: "./modal-students-list.component.html",
	styleUrls: ["./modal-students-list.component.scss"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalStudentsListComponent {
	formulario: UntypedFormGroup;
	constructor(
		public activeModal: NgbActiveModal,
		private formBuild: FormBuilder,
		public translateService: TranslateService
	) {
		//this.inactivityService.startInactivityTimer();
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.formulario = this.formBuild.group({ filtrado: [""] });
	}

	selectedOrder = 1;
	iconSelected = false;
	historyFilterActive: boolean;
	orderList = [
		{
			nombre: "A - Z",
			idOrder: 1,
		},
		{
			nombre: "Z - A",
			idOrder: 2,
		},
		{
			nombre: "Más recientes",
			idOrder: 3,
		},
		{
			nombre: "Más antiguos",
			idOrder: 4,
		},
		{
			nombre: "Editados Recientemente",
			idOrder: 5,
		},
	];

	students = [
		{
			nombre: "Ana",
			apellido: "García",
			grado: "Primero",
			avatar: "https://i.pravatar.cc/150?img=44",
			color: "#FC9A27",
		},
		{
			nombre: "Carlos",
			apellido: "Hernández",
			grado: "Segundo",
			avatar: "https://i.pravatar.cc/150?img=2",
			color: "#FEE533",
		},
		{
			nombre: "María",
			apellido: "Martínez",
			grado: "Tercero",
			avatar: "https://i.pravatar.cc/150?img=46",
			color: "#FFFD38",
		},
		{
			nombre: "Luis",
			apellido: "López",
			grado: "Cuarto",
			avatar: "https://i.pravatar.cc/150?img=4",
			color: "#D4FD35",
		},
		{
			nombre: "Elena",
			apellido: "González",
			grado: "Quinto",
			avatar: "https://i.pravatar.cc/150?img=5",
			color: "#9CFD32",
		},
		{
			nombre: "Juan",
			apellido: "Rodríguez",
			grado: "Sexto",
			avatar:
				"https://images.generated.photos/xJVVONGJNYWODbSmKwzx68q6W8N12cf-pD7xDLWzUpM/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NjI4OTIyLmpwZw.jpg",
			color: "#FC5C20",
		},
		{
			nombre: "Sofía",
			apellido: "Pérez",
			grado: "Primero",
			avatar: "https://i.pravatar.cc/150?img=7",
			color: "#FEE533",
		},
		{
			nombre: "Miguel",
			apellido: "Sánchez",
			grado: "Segundo",
			avatar: "https://i.pravatar.cc/150?img=8",
			color: "#FFFD38",
		},
		{
			nombre: "Laura",
			apellido: "Ramírez",
			grado: "Tercero",
			avatar:
				"https://images.generated.photos/g7GpFLf-1VdP_7Aq0c_raB1OXPvdL18rcEOR-ioYwDw/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjA0ODIzLmpwZw.jpg",
			color: "#D4FD35",
		},
		{
			nombre: "Javier",
			apellido: "Torres",
			grado: "Cuarto",
			avatar:
				"https://images.generated.photos/DskxFaX7b5WSaK-2y1RmfAXSj7v_QuWpRVwLHjdikQ0/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDk2NzMzLmpwZw.jpg",
			color: "#FC9A27",
		},
		{
			nombre: "Lucía",
			apellido: "Flores",
			grado: "Quinto",
			avatar: "https://api.dicebear.com/8.x/personas/svg?seed=Mittens",
			color: "#FEE533",
		},
		{
			nombre: "Pedro",
			apellido: "Vargas",
			grado: "Sexto",
			avatar:
				"https://images.generated.photos/agFmszcvGUYOEbM9mL5GFGlx2k5-cNCPPoNgrwGzxOQ/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjcwNjUwLmpwZw.jpg",
			color: "#FFFD38",
		},
		{
			nombre: "Isabel",
			apellido: "Ríos",
			grado: "Primero",
			avatar:
				"https://images.generated.photos/1qdtgbcus5adChhrEOrLlJKtx-nhX5ewN5nG8521c2Q/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/OTQ3MTAzLmpwZw.jpg",
			color: "#D4FD35",
		},
		{
			nombre: "Alberto",
			apellido: "Ortega",
			grado: "Segundo",
			avatar:
				"https://images.generated.photos/vkwmr4x0JBzHYxXOkvTcRdQeBDLu4ZmyL_ySvPg1Gbk/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/OTM1MjQyLmpwZw.jpg",
			color: "#9CFD32",
		},
		{
			nombre: "Marta",
			apellido: "Morales",
			grado: "Tercero",
			avatar:
				"https://images.generated.photos/YFDiPrNqozV1Gnv8zUACfNWwyGutko9RmrfvYyopqz4/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDAzMjI4LmpwZw.jpg",
			color: "#FC9A27",
		},
		{
			nombre: "Francisco",
			apellido: "Cruz",
			grado: "Cuarto",
			avatar:
				"https://images.generated.photos/NONNxWQEu_sR1x0CjOszhFxwXOkvxdQ5QgennVZhfuI/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NjEyMTEwLmpwZw.jpg",
			color: "#FEE533",
		},
		{
			nombre: "Julia",
			apellido: "Delgado",
			grado: "Quinto",
			avatar:
				"https://images.generated.photos/uR_othc3mqYXD5Y4N3B0zKTMRWrKrbK5cPr4FNc8PZY/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NjM2OTM1LmpwZw.jpg",
			color: "#FFFD38",
		},
		{
			nombre: "Antonio",
			apellido: "Castillo",
			grado: "Sexto",
			avatar:
				"https://images.generated.photos/_-1rAj1yzlHY4PIYBPONse6n6rtfeCWekYhX2pWPRf4/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/Nzk1NzQwLmpwZw.jpg",
			color: "#D4FD35",
		},
		{
			nombre: "Gabriela",
			apellido: "Suárez",
			grado: "Primero",
			avatar:
				"https://images.generated.photos/rif1GMtATVrlrJNJbflKHUE5mtg9-BCnLCZpRPnT5P8/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/Nzk5MTk2LmpwZw.jpg",
			color: "#9CFD32",
		},
		{
			nombre: "Fernando",
			apellido: "Ramos",
			grado: "Segundo",
			avatar:
				"https://images.generated.photos/vMzxIs29uGYAutSAGVwlRZGKRqPDPJrH5fW2eHA27Qw/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NjA0NzA4LmpwZw.jpg",
			color: "#FC5C20",
		},
		{
			nombre: "Patricia",
			apellido: "Aguilar",
			grado: "Tercero",
			avatar:
				"https://images.generated.photos/sQtKSivyoyYLu1keQGG-Y9ugqtJdTlKbIly_Qx-S_Z4/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MTI3NTk4LmpwZw.jpg",
			color: "#FEE533",
		},
		{
			nombre: "Ricardo",
			apellido: "Ortiz",
			grado: "Cuarto",
			avatar:
				"https://images.generated.photos/GGX7zmntqwj_n14ORfdXGx9MB3z9sffFr3Fa0eHhxiA/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MTQ2ODExLmpwZw.jpg",
			color: "#FFFD38",
		},
		{
			nombre: "Natalia",
			apellido: "Santos",
			grado: "Quinto",
			avatar:
				"https://images.generated.photos/yOylItg02L3AmWoyy6fijsn7uXj68zAq-0lAyVm-F30/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MTMwMDY3LmpwZw.jpg",
			color: "#D4FD35",
		},
		{
			nombre: "Manuel",
			apellido: "Reyes",
			grado: "Sexto",
			avatar:
				"https://images.generated.photos/XLGhDEGDRJfoevmb5OUVWoEqlacXO0AVgJqOO-N9ucE/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/ODc2NTk3LmpwZw.jpg",
			color: "#9CFD32",
		},
		{
			nombre: "Sandra",
			apellido: "Ruiz",
			grado: "Primero",
			avatar:
				"https://images.generated.photos/b1llKURdJ4NbOJJ-DtGHCzzIB9Irfh4257YsMq9Q674/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/Mzc0MDEzLmpwZw.jpg",
			color: "#FEE533",
		},
		{
			nombre: "Hugo",
			apellido: "Herrera",
			grado: "Segundo",
			avatar:
				"https://images.generated.photos/pW52WcWLkx7GQPmmD9v8QA0aLK2CmYBnp3tD2nqx2u8/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NDUyMzk4LmpwZw.jpg",
			color: "#FFFD38",
		},
		{
			nombre: "Cristina",
			apellido: "Medina",
			grado: "Tercero",
			avatar:
				"https://images.generated.photos/vbNcpFgweUtpTa3V5caEvbWUe9L8xfwp0I-UHXltEtc/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDQzODgyLmpwZw.jpg",
			color: "#D4FD35",
		},
		{
			nombre: "David",
			apellido: "Chávez",
			grado: "Cuarto",
			avatar:
				"https://images.generated.photos/BKXIRBcWEqwSqkehSE0KdfWFbUhgmZFkzjOuvG26qKo/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/Nzg1OTg5LmpwZw.jpg",
			color: "#FC9A27",
		},
		{
			nombre: "Paula",
			apellido: "Vega",
			grado: "Quinto",
			avatar:
				"https://images.generated.photos/MF8ngVu4YjVVxEqJ5th3-OFDi4VP03PYQgl0q02_epY/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/Mzk1NTU2LmpwZw.jpg",
			color: "#FEE533",
		},
		{
			nombre: "Raúl",
			apellido: "Romero",
			grado: "Sexto",
			avatar:
				"https://images.generated.photos/lymk44oGSjG_qnto3oVXXTGPa6xdv5NvrljIX0rolEA/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/Njc3MTE3LmpwZw.jpg",
			color: "#FFFD38",
		},
		{
			nombre: "Mónica",
			apellido: "Correa",
			grado: "Primero",
			avatar:
				"https://images.generated.photos/Ck4l_8oph0RMsKXz3wkPGv2NJZ-8B1yUEx0MzMUyKHo/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/Mzc3MjAxLmpwZw.jpg",
			color: "#D4FD35",
		},
		{
			nombre: "Iván",
			apellido: "Cano",
			grado: "Segundo",
			avatar:
				"https://images.generated.photos/8kJofxpBhU8gXiIeCOHNRKjepascK6mMNjT60Z2gREw/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjQyNDE4LmpwZw.jpg",
			color: "#9CFD32",
		},
		{
			nombre: "Eva",
			apellido: "Campos",
			grado: "Tercero",
			avatar:
				"https://images.generated.photos/gF09Pvoz12eKGHN6PaJCtccZG91xXWItacXCvsooZbo/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDk1MDI1LmpwZw.jpg",
			color: "#FC5C20",
		},
		{
			nombre: "Sergio",
			apellido: "Peña",
			grado: "Cuarto",
			avatar:
				"https://images.generated.photos/yuGsX6_Uz2uFFzicljC3OlFf-dyYjePTdxybLZJLUyk/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDQ0NTM0LmpwZw.jpg",
			color: "#FEE533",
		},
		{
			nombre: "Beatriz",
			apellido: "Mendoza",
			grado: "Quinto",
			avatar:
				"https://images.generated.photos/h7QLdSsk4QeZFs8BG1go5pX2qVEcw9NjgYWIa6z74vo/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/OTk2ODU1LmpwZw.jpg",
			color: "#FFFD38",
		},
		{
			nombre: "Alejandro",
			apellido: "Luna",
			grado: "Sexto",
			avatar:
				"https://images.generated.photos/a_ndJcIrDqcMuac0doCQYW_d_idRAXY__1G01VEZNNc/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjQ4MTYyLmpwZw.jpg",
			color: "#D4FD35",
		},
		{
			nombre: "Clara",
			apellido: "Iglesias",
			grado: "Primero",
			avatar:
				"https://images.generated.photos/7J929kv7cmgNBElmxHkUxl4crHPI92a6t0XOs1lqbQE/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NTQ3NDgyLmpwZw.jpg",
			color: "#9CFD32",
		},
		{
			nombre: "Pablo",
			apellido: "Silva",
			grado: "Segundo",
			avatar:
				"https://images.generated.photos/b4b2QDHyYWblBtD3stBxnFdDlbLvae5DnV7_njsATaI/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NDE1Njk5LmpwZw.jpg",
			color: "#FC5C20",
		},
		{
			nombre: "Angela",
			apellido: "Castro",
			grado: "Tercero",
			avatar:
				"https://images.generated.photos/PyufLt6USAcbaJ7OZGNmj13O66--UD6d4R8Fy8gaiFU/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjIyMzcwLmpwZw.jpg",
			color: "#FEE533",
		},
		{
			nombre: "Marcos",
			apellido: "Vargas",
			grado: "Cuarto",
			avatar:
				"https://images.generated.photos/BLRmSqrh1BzbVcTwN5-IaIdZ3DMp_yhIL0BB0AqN52E/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDc0NjEzLmpwZw.jpg",
			color: "#FFFD38",
		},
		{
			nombre: "Verónica",
			apellido: "Román",
			grado: "Quinto",
			avatar:
				"https://images.generated.photos/gKEXhz_BjsU6iSvGrYsSFDKka8y_xwc4SsmGpKx7g4s/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/OTY4OTkzLmpwZw.jpg",
			color: "#D4FD35",
		},
		{
			nombre: "Joaquín",
			apellido: "Fuentes",
			grado: "Sexto",
			avatar:
				"https://images.generated.photos/zv0KMpv25ahZG8FNkDNMljyKHpkgPKmEcIlB66l-s1g/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/OTQ5NzU2LmpwZw.jpg",
			color: "#9CFD32",
		},
		{
			nombre: "Alicia",
			apellido: "Escobar",
			grado: "Primero",
			avatar:
				"https://images.generated.photos/oYvF-3nKK7DlwFVylRjoayuooasCcvSHrhIch3CkJmQ/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NzkwOTM5LmpwZw.jpg",
			color: "#FEE533",
		},
		{
			nombre: "Andrés",
			apellido: "Ponce",
			grado: "Segundo",
			avatar:
				"https://images.generated.photos/0Vf2KV1-yFcgL5Qwc41lxefXxj8nEmxjNGD5PBzxzyc/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MzgyNzYxLmpwZw.jpg",
			color: "#FFFD38",
		},
		{
			nombre: "Rocío",
			apellido: "Gil",
			grado: "Tercero",
			avatar:
				"https://images.generated.photos/1Wl4xQ5sloBG4nuqxw372cK5FQ4mcyL8y9bU31xO3x4/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MzcxNzU5LmpwZw.jpg",
			color: "#D4FD35",
		},
		{
			nombre: "Eduardo",
			apellido: "Navarro",
			grado: "Cuarto",
			avatar:
				"https://images.generated.photos/1oZif5iyB0GdJWUNQovNNzStEHNmRa2xKgXdXCohj2w/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/NzY1MDM4LmpwZw.jpg",
			color: "#9CFD32",
		},
		{
			nombre: "Noelia",
			apellido: "Campos",
			grado: "Quinto",
			avatar:
				"https://images.generated.photos/PyufLt6USAcbaJ7OZGNmj13O66--UD6d4R8Fy8gaiFU/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjIyMzcwLmpwZw.jpg",
			color: "#FC5C20",
		},
		{
			nombre: "Gustavo",
			apellido: "León",
			grado: "Sexto",
			avatar:
				"https://images.generated.photos/JhsxskA2JClpxxE6XNExFZBxQvrVDfBVnV1zb50OVzE/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/OTUxNjk0LmpwZw.jpg",
			color: "#FEE533",
		},
		{
			nombre: "Irene",
			apellido: "Guzmán",
			grado: "Primero",
			avatar:
				"https://images.generated.photos/DCvmOzh92RVdhRoFQDmWBYvA-vP1HcvSRZZzlRdcPGE/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/OTQ4OTk1LmpwZw.jpg",
			color: "#FFFD38",
		},
		{
			nombre: "Óscar",
			apellido: "Moya",
			grado: "Segundo",
			avatar:
				"https://images.generated.photos/_q9-1YpcXneHLBXPqkxZiiTd0C-XbyWrfk_EW0vwaKs/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MTY0MDI1LmpwZw.jpg",
			color: "#D4FD35",
		},
	];

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	changeOrder(order: any) {
	}
	historyFilter() {}

	openFiltersPanel() {}
	closeFiltersPanel() {}
}
