<nb-card class="mb-0">
	<nb-card-header class="flex justify-content-between border-bottom-0">
		<div class="col-11">
			<div> <h5>{{ 'ACTIVIDADESFLASH.AJUSTES' | translate }}</h5></div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal()" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="m-3 d-flex">
			<form [formGroup]="formulario" class="d-flex flex-column">
				<div class="d-flex justify-content align-items-center gap-4">
					<span>{{ "ACTIVIDADESFLASH.SIZEDECK" | translate }} (max {{ maxSize }})</span>
					<input style="max-width: 5rem;" nbInput fieldSize="small" class="form-control"
						type="number" min="1" [max]="maxSize" formControlName="sizeDeck" [value]="formulario.controls['sizeDeck'].value"/>
				</div>

				<div class="d-flex justify-content align-items-center">
					<span class="text-center" style="color: #B7181F; font-size: 12px;">{{ "ACTIVIDADESFLASH.ALERTMINSIZE" | translate }}</span>
				</div>

				<div class="d-flex justify-content align-items-center gap-4 mt-4">
					<span>{{ "ACTIVIDADESFLASH.INCLUDESMALL" | translate }}</span>
					<nb-toggle labelPosition="end" formControlName="includeSmall"></nb-toggle>
				</div>
			</form>
		</div>
	</nb-card-body>
	<nb-card-footer class="border-top-0 m-4">
		<div class="d-flex justify-content-center gap-5 align-items-center">
			<button *ngIf="formulario.value.sizeDeck > 9 && formulario.value.sizeDeck <= maxSize" nbButton (click)="aceptar()" class="btn-themecolor">
				{{ 'ACEPTARCANCELAR.ACEPTAR' | translate }}
			</button>
			<button *ngIf="formulario.value.sizeDeck <= 9 || formulario.value.sizeDeck > maxSize" nbButton class="btn-themecolor">
				{{ 'ACEPTARCANCELAR.ACEPTAR' | translate }}
			</button>

			<button nbButton class="btn-red" (click)="cancelar()">
				{{ 'ACEPTARCANCELAR.CANCELAR' | translate}}
			</button>
		</div>
	</nb-card-footer>
</nb-card>
