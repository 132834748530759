export class SurveyStackModel{
	idSurveysStack: number;
	idUser: number;
	idEvent: number;
	anonymous: number;
	idSurvey: number;
	idSurveyQuestion: number;
	questionType: number;
	answerLikert: number;
	answerSimple: number;
	answerMulti: string;
	answerText: string;
	answerDate: string;

	constructor(
		idSurveysStack: number,
		idEvent: number,
		anonymous: number,
		idSurvey: number,
		idSurveyQuestion: number,
		questionType: number,
		answerLikert: number,
		answerSimple: number,
		answerMulti: string,
		answerText: string,
	){
		this.idSurveysStack = idSurveysStack || 0;
		this.idEvent = idEvent || 0;
		this.anonymous = anonymous || 0;
		this.idSurvey = idSurvey || 0;
		this.idSurveyQuestion = idSurveyQuestion || 0;
		this.questionType = questionType || 0;
		this.answerLikert = answerLikert || 0;
		this.answerSimple = answerSimple || 0;
		this.answerMulti = answerMulti || "";
		this.answerText = answerText || "";
	}
}
