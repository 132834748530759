<nb-card class="mb-0 no-edge-card background_themecolor modal-height-100-62">
	<nb-card-header class="border-bottom-0">
		<div class="row">
			<!-- BUTTON ONLY & EXCLUSIVE -->
			<div class="col-4" [ngClass]="{'left-files': !isMenuOpen}">
				<div class="d-flex">
					<div class="d-flex align-items-start" *ngIf="hotView == false" [ngClass]="{'pulsating': stepGuide == 1}">
						<!--BOTON FANTASMA (para que no salga seleccionado el primer boton)-->
						<div style="width: 0px; position: absolute; top: -500px;">
							<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"></button>
						</div>
						<!-- BOTONES AGRUPADOS -->
						<div *ngIf="isMobile" class="d-flex align-items-start">
							<!-- BOTON QUE AGRUPA LAS OPCIONES -->
							<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
								nbTooltip="{{ 'SOCKETQUIZPREVIEW.SEEOPTIONS' | translate }}" nbTooltipPlacement="top">
								<div>
									<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
										(click)="toggleAccordion3()">
										<nb-icon icon="more-horizontal-outline"></nb-icon>
									</button>
								</div>
								<label class="label-header m-0">
									{{ 'SOCKETQUIZPREVIEW.SEEOPTIONS' | translate }}
								</label>
							</div>

							<div [@accordion3]="accordionOpen3 ? 'see' : 'hide'" class="accordion-content-options d-flex align-items-start gap-4">
								<!-- ONLYGRAPHS -->
								<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
									nbTooltip="{{ 'SOCKETQUIZPREVIEW.ONLYGRAPHS' | translate }}" nbTooltipPlacement="top"
									*ngIf="(this.profileTitle === 'Profesor' || this.profileTitle === 'Estudiante')">
									<div>
										<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
											(click)="getMasterDataCourses()" [ngClass]="{ 'active-button': verBotonG1 == 1 }">
											<mat-icon svgIcon="graph"></mat-icon>
										</button>
									</div>
									<label class="label-header m-0">
										{{ 'SOCKETQUIZPREVIEW.ONLYGRAPHS' | translate }}
									</label>
								</div>
								<!-- EXCLUSIVEGRAPHS -->
								<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
									nbTooltip="{{ 'SOCKETQUIZPREVIEW.EXCLUSIVEGRAPHS' | translate }}" nbTooltipPlacement="top"
									*ngIf="this.profileTitle === 'Profesor' && !claseInvertida">
									<div>
										<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
											(click)="getChallenges()" [ngClass]="{ 'active-button': verBotonG1 == 2 }">
											<mat-icon id="desafios_icon" svgIcon="desafios_icon"></mat-icon>
										</button>
									</div>
									<label class="label-header m-0">
										{{ 'SOCKETQUIZPREVIEW.EXCLUSIVEGRAPHS' | translate }}
									</label>
								</div>
								<!-- INFORME -->
								<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
									nbTooltip="{{ 'SOCKETQUIZPREVIEW.SEECOMPETITIONS' | translate }}" nbTooltipPlacement="top"
									*ngIf="this.profileTitle === 'Profesor' && !claseInvertida">
									<div>
										<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
											(click)="switchCompetitionsReport()" [ngClass]="{ 'active-button': verBotonG1 == 3 }">
											<mat-icon id="icon-competir" svgIcon="couple"></mat-icon>
										</button>
									</div>
									<label class="label-header m-0">
										{{ 'SOCKETQUIZPREVIEW.SEECOMPETITIONS' | translate }}
									</label>
								</div>
								<!-- SELECCIONAR HIJO Y GRUPO -->
								<div class="d-flex flex-column gap-3" *ngIf="this.profileTitle === 'Padre'">
									<nb-select class="SelectForm" hero size="small" placeholder="Selecciona un hijo" [(ngModel)]="selectedChildId"
										(selectedChange)="onChildSelected($event)">
										<nb-option class="SelectFormOpt" *ngFor="let child of childrenList" [value]="child.idUser">
											{{child.firstName}}
										</nb-option>
									</nb-select>
									<nb-select class="SelectForm" hero size="small" placeholder="Selecciona un grupo" (ngModelChange)="onGroupSelected($event)"
										*ngIf="selectedChildGroups.length > 0" [(ngModel)]="selectedGroupId">
										<nb-option class="SelectFormOpt" *ngFor="let group of selectedChildGroups" [value]="group.idGroup">
											{{group.title}}
										</nb-option>
									</nb-select>
								</div>
							</div>
						</div>
						<!-- BOTONES DESPLEGADOS -->
						<div *ngIf="!isMobile" class="d-flex align-items-start">
							<!-- ONLYGRAPHS -->
							<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
								nbTooltip="{{ 'SOCKETQUIZPREVIEW.ONLYGRAPHS' | translate }}" nbTooltipPlacement="top"
								*ngIf="(this.profileTitle === 'Profesor' || this.profileTitle === 'Estudiante')">
								<div>
									<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
										(click)="getMasterDataCourses()" [ngClass]="{ 'active-button': verBotonG1 == 1 }">
										<mat-icon svgIcon="graph"></mat-icon>
									</button>
								</div>
								<label class="label-header m-0">
									{{ 'SOCKETQUIZPREVIEW.ONLYGRAPHS' | translate }}
								</label>
							</div>
							<!-- EXCLUSIVEGRAPHS -->
							<div class="d-flex align-items-center justify-content-center filter-buttons flex-column px-5" style="gap: 5px;"
								nbTooltip="{{ 'SOCKETQUIZPREVIEW.EXCLUSIVEGRAPHS' | translate }}" nbTooltipPlacement="top" *ngIf="this.profileTitle === 'Profesor' && !claseInvertida">
								<div>
									<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
										(click)="getChallenges()" [ngClass]="{ 'active-button': verBotonG1 == 2 }">
										<mat-icon id="desafios_icon" svgIcon="desafios_icon"></mat-icon>
									</button>
								</div>
								<label class="label-header m-0">
									{{ 'SOCKETQUIZPREVIEW.EXCLUSIVEGRAPHS' | translate }}
								</label>
							</div>
							<!-- INFORME -->
							<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
								nbTooltip="{{ 'SOCKETQUIZPREVIEW.SEECOMPETITIONS' | translate }}" nbTooltipPlacement="top" *ngIf="this.profileTitle === 'Profesor' && !claseInvertida">
								<div>
									<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
										(click)="switchCompetitionsReport()" [ngClass]="{ 'active-button': verBotonG1 == 3 }">
										<mat-icon id="icon-competir" svgIcon="couple"></mat-icon>
									</button>
								</div>
								<label class="label-header m-0">
									{{ 'SOCKETQUIZPREVIEW.SEECOMPETITIONS' | translate }}
								</label>
							</div>
							<!-- SELECCIONAR HIJO Y GRUPO -->
							<div class="d-flex flex-column gap-3">
								<nb-select class="SelectForm" hero size="small" placeholder="Selecciona un hijo" [(ngModel)]="selectedChildId" (selectedChange)="onChildSelected($event)"
									*ngIf="this.profileTitle === 'Padre'">
									<nb-option class="SelectFormOpt" *ngFor="let child of childrenList" [value]="child.idUser">
										{{child.firstName}}
									</nb-option>
								</nb-select>
								<nb-select class="SelectForm" hero size="small" placeholder="Selecciona un grupo" (ngModelChange)="onGroupSelected($event)"
									*ngIf="selectedChildGroups.length > 0 && this.profileTitle === 'Padre'" [(ngModel)]="selectedGroupId">
									<nb-option class="SelectFormOpt" *ngFor="let group of selectedChildGroups" [value]="group.idGroup">
										{{group.title}}
									</nb-option>
								</nb-select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- BOTONES -->
			<div class="col px-4" [ngClass]="{'col-xl-8': isMenuOpen}">
				<div class="d-flex align-items-start justify-content-between gap-4 mr-3">
					<!-- TIPOS DE INFORMES -->
					<div *ngIf="listGraphSelected.length > 0" class="d-flex" [ngClass]="{'pulsating': stepGuide == 3}">
						<!-- BOTONES AGRUPADOS -->
						<div *ngIf="isMobile" class="d-flex">
							<!-- BOTON QUE AGRUPA LOS TIPO DE INFORMES -->
							<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
								nbTooltip="{{ 'INFORMES.REPORTTYPES' | translate }}" nbTooltipPlacement="top">
								<div>
									<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
										(click)="toggleAccordion()">
										<nb-icon icon="keypad-outline"></nb-icon>
									</button>
								</div>
								<label class="label-header m-0">
									{{ 'INFORMES.REPORTTYPES' | translate }}
								</label>
							</div>

							<div [@accordion]="accordionOpen ? 'open' : 'closed'" class="accordion-content d-flex gap-4">
								<!-- BOTON INFORME TABLA RESULTADOS -->
								<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
									nbTooltip="{{ 'INFORMES.RESULTSTABLEREPORT' | translate }}" nbTooltipPlacement="top">
									<div>
										<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
											(click)="cambiarInforme(2)" [ngClass]="{ 'active-button': verBotonG2 == 2 }">
											<div class="card-image">
												<div class="icon-option icon-informes pointer"></div>
											</div>
										</button>
									</div>
									<label class="label-header m-0">
										{{ 'INFORMES.RESULTSTABLEREPORT' | translate }}
									</label>
								</div>
								<!-- BOTON INFORME TIEMPOS DE TRABAJO -->
								<div *ngIf="!reportingChallenge && (this.profileTitle === 'Profesor' || this.profileTitle === 'Estudiante')"
									class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
									nbTooltip="{{ 'INFORMES.WORKTIMESREPORT' | translate }}" nbTooltipPlacement="top">
									<div>
										<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
											(click)="cambiarInforme(1)" [ngClass]="{ 'active-button': verBotonG2 == 1 }">
											<nb-icon icon="bar-chart-outline"></nb-icon>
										</button>
									</div>
									<label class="label-header m-0">
										{{ 'INFORMES.WORKTIMESREPORT' | translate }}
									</label>
								</div>
							</div>
						</div>
						<!-- BOTONES DESPLEGADOS -->
						<div *ngIf="!isMobile" class="d-flex gap-4">
							<!-- BOTON INFORME TABLA RESULTADOS -->
							<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
								nbTooltip="{{ 'INFORMES.RESULTSTABLEREPORT' | translate }}" nbTooltipPlacement="top">
								<div>
									<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
										(click)="cambiarInforme(2)" [ngClass]="{ 'active-button': verBotonG2 == 2 }">
										<div class="card-image">
											<div class="icon-option icon-informes pointer"></div>
										</div>
									</button>
								</div>
								<label class="label-header m-0">
									{{ 'INFORMES.RESULTSTABLEREPORT' | translate }}
								</label>
							</div>
							<!-- BOTON INFORME TIEMPOS DE TRABAJO -->
							<div *ngIf="!reportingChallenge && (this.profileTitle === 'Profesor' || this.profileTitle === 'Estudiante')"
								class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
								nbTooltip="{{ 'INFORMES.WORKTIMESREPORT' | translate }}" nbTooltipPlacement="top">
								<div>
									<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
										(click)="cambiarInforme(1)" [ngClass]="{ 'active-button': verBotonG2 == 1 }">
										<nb-icon icon="bar-chart-outline"></nb-icon>
									</button>
								</div>
								<label class="label-header m-0">
									{{ 'INFORMES.WORKTIMESREPORT' | translate }}
								</label>
							</div>
						</div>
					</div>
					<!-- FILTROS -->
					<div class="d-flex">
						<!-- BOTONES AGRUPADOS -->
						<div *ngIf="isMobile || (!isMobile && isMenuOpen)" class="d-flex align-items-start justify-content-end position-relative">
							<div [@accordion2]="accordionOpen2 ? 'in' : 'out'" class="accordion-content-filtros d-flex gap-4">
								<!--	FILTROS INFORME TABLA RESULTADOS -->
								<div *ngIf="!informeChallenge && informeType == 2 && showFullscreen" class="d-flex gap-2 flex-column">
									<div class="d-flex gap-4 align-items-start justify-content-end">
										<!--	BOTON VER ACTIVIDADES -->
										<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
											nbTooltip="{{ getVerActividadesLabel() }}" nbTooltipPlacement="top" *ngIf="this.profileTitle === 'Profesor'">
											<div>
												<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
													(click)="changeVerActividades()">
													<nb-icon *ngIf="!verActividades" icon="expand-outline"></nb-icon>
													<nb-icon *ngIf="verActividades" icon="collapse-outline"></nb-icon>
												</button>
											</div>
											<label class="label-header m-0">
												{{ getVerActividadesLabel() }}
											</label>
										</div>
										<!--	BOTON VER % ACIERTO -->
										<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
											nbTooltip="{{ getVerAciertoLabel() }}" nbTooltipPlacement="top">
											<div>
												<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
													(click)="changeVerAcierto()">
													<nb-icon icon="percent-outline"></nb-icon>
												</button>
											</div>
											<label class="label-header m-0">
												{{ getVerAciertoLabel() }}
											</label>
										</div>
										<!--	BOTON VER FOTOS -->
										<div class="d-flex align-items-center justify-content-start filter-buttons flex-column" style="gap: 5px;"
											nbTooltip="{{ getVerFotosLabel() }}" nbTooltipPlacement="top" *ngIf="this.profileTitle === 'Profesor'">
											<div>
												<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
													(click)="changeVerFotos()">
													<mat-icon svgIcon="user"></mat-icon>
												</button>
											</div>
											<label class="label-header m-0">
												{{ getVerFotosLabel() }}
											</label>
										</div>
										<!--	BOTON VER K -->
										<div class="d-flex align-items-center justify-content-start filter-buttons flex-column" style="gap: 5px;"
											nbTooltip="{{ getVerKLabel() }}" nbTooltipPlacement="top">
											<div>
												<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
													(click)="changeVerK()">
													<h6>K</h6>
												</button>
											</div>
											<label class="label-header m-0">
												{{ getVerKLabel() }}
											</label>
										</div>
									</div>

									<div class="separador-vertical"></div>

									<div class="d-flex gap-4 align-items-start">
										<!--	BOTON ORDENAR POR NOMBRE -->
										<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
											nbTooltip="{{ 'INFORMES.SORTNAME' | translate }}" nbTooltipPlacement="top" *ngIf="this.profileTitle === 'Profesor'">
											<div>
												<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="ordenarTabla_AZ()"
													[ngClass]="{ 'active-button': verBotonG3 == 1 }">
													{{ filtroAzTabla }}
												</button>
											</div>
											<label class="label-header m-0">
												{{ 'INFORMES.SORTNAME' | translate }}
											</label>
										</div>
										<!--	BOTON ORDENAR POR ACIERTOS -->
										<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
											nbTooltip="{{ 'INFORMES.SORTHITS' | translate }}" nbTooltipPlacement="top">
											<div>
												<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="ordenarTabla_Aciertos()"
													[ngClass]="{ 'active-button': verBotonG3 == 2 }">
													<nb-icon icon="done-all-outline"></nb-icon>
													<nb-icon *ngIf="filtroAciertos == 'aciertosASC'" icon="arrow-forward-outline"></nb-icon>
													<nb-icon *ngIf="filtroAciertos == 'aciertosDEC'" icon="arrow-back-outline"></nb-icon>
												</button>
											</div>
											<label class="label-header m-0">
												{{ 'INFORMES.SORTHITS' | translate }}
											</label>
										</div>
										<!--	BOTON ORDENAR POR TIEMPO TOTAL -->
										<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
											nbTooltip="{{ 'INFORMES.SORTTOTALTIME' | translate }}" nbTooltipPlacement="top">
											<div>
												<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="ordenarTabla_Tiempo()"
													[ngClass]="{ 'active-button': verBotonG3 == 3 }">
													<!--{{'GLOBALS.TOTALTIME' | translate}}-->
													<nb-icon icon="clock-outline"></nb-icon>
													<nb-icon *ngIf="filtroTiempo == 'tiempoTotalASC'" icon="arrow-forward-outline"></nb-icon>
													<nb-icon *ngIf="filtroTiempo == 'tiempoTotalDEC'" icon="arrow-back-outline"></nb-icon>
												</button>
											</div>
											<label class="label-header m-0">
												{{ 'INFORMES.SORTTOTALTIME' | translate }}
											</label>
										</div>
										<!-- EXPORT EXCEL -->
										<div class="d-flex align-items-center filter-buttons flex-column" style="gap: 5px;"
											*ngIf="this.profileTitle === 'Profesor' && informeType == 2" nbTooltip="{{ 'INFORMES.EXPORT' | translate }}" nbTooltipPlacement="top">
											<div>
												<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
													(click)="downloadExcel()">
													<mat-icon svgIcon="icon-excel"></mat-icon>
												</button>
											</div>
											<label class="label-header m-0">
												{{ 'INFORMES.EXPORT' | translate }}
											</label>
										</div>
									</div>
								</div>
								<!--	FILTROS INFORME TIEMPOS DE TRABAJO -->
								<div *ngIf="!informeChallenge && this.profileTitle === 'Profesor' && informeType == 1 && showFullscreen" class="d-flex gap-2 flex-column">
									<!--	BOTON ORDENAR ULTIMOS 7 DIAS -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
											nbTooltip="{{ 'INFORMES.VIEW7DAYS' | translate }}" nbTooltipPlacement="top">
											<div>
												<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="changeRangeTime('7days')"
													[ngClass]="{ 'active-button': verBotonG5 == 1 }">
													{{ 'INFORMES.DAY' | translate }}
												</button>
											</div>
											<label class="label-header m-0">
												{{ 'INFORMES.VIEW7DAYS' | translate }}
											</label>
									</div>
									<!--	BOTON ORDENAR ULTIMO MES -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
											nbTooltip="{{ 'INFORMES.SEELASTMONTH' | translate }}" nbTooltipPlacement="top">
											<div>
												<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="changeRangeTime('month')"
													[ngClass]="{ 'active-button': verBotonG5 == 2 }">
													{{ 'INFORMES.MONTH' | translate }}
												</button>
											</div>
											<label class="label-header m-0">
												{{ 'INFORMES.SEELASTMONTH' | translate }}
											</label>
									</div>
									<!--	BOTON ORDENAR ULTIMO AÑO -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
											nbTooltip="{{ 'INFORMES.SEELASTYEAR' | translate }}" nbTooltipPlacement="top">
											<div>
												<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="changeRangeTime('year')"
													[ngClass]="{ 'active-button': verBotonG5 == 3 }">
													{{ 'INFORMES.Year' | translate }}
												</button>
											</div>
											<label class="label-header m-0">
												{{ 'INFORMES.SEELASTYEAR' | translate }}
											</label>
									</div>
									<!-- EXPORT EXCEL -->
									<div class="d-flex align-items-center filter-buttons flex-column" style="gap: 5px;"
												*ngIf="this.profileTitle === 'Profesor' && informeType == 2" nbTooltip="{{ 'INFORMES.EXPORT' | translate }}" nbTooltipPlacement="top">
											<div>
												<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
													(click)="downloadExcel()">
													<mat-icon svgIcon="icon-excel"></mat-icon>
												</button>
											</div>
											<label class="label-header m-0">
												{{ 'INFORMES.EXPORT' | translate }}
											</label>
									</div>

									<div class="separador-vertical"></div>

									<!--	BOTON ORDENAR POR NOMBRE -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
											nbTooltip="{{ 'INFORMES.SORTNAME' | translate }}" nbTooltipPlacement="top">
											<div>
												<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="ordenarChart()"
													[ngClass]="{ 'active-button': verBotonG4 == 1 }">
													{{ filtroAz }}
												</button>
											</div>
											<label class="label-header m-0">
												{{ 'INFORMES.SORTNAME' | translate }}
											</label>
									</div>
									<!--	BOTON ORDENAR POR TIEMPO PROMEDIO -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
											nbTooltip="{{ 'INFORMES.AVERAGETIMEPERMINUTE' | translate }}" nbTooltipPlacement="top">
											<div>
												<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="ordenarChart2()"
													[ngClass]="{ 'active-button': verBotonG4 == 2 }">
													{{ filtro90 }}
												</button>
											</div>
											<label class="label-header m-0">
												{{ 'INFORMES.AVERAGETIMEPERMINUTE' | translate }}
											</label>
									</div>
								</div>
							</div>
							<!-- BOTON QUE AGRUPA LOS TIPO DE FILTROS -->
							<div *ngIf="!informeChallenge && informeType == 2 && showFullscreen"
								class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
								nbTooltip="{{ 'FILTER.FILTER' | translate }}" nbTooltipPlacement="top">
								<div>
									<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
										(click)="toggleAccordion2()">
										<nb-icon icon="funnel-outline"></nb-icon>
									</button>
								</div>
								<label class="label-header m-0">
									{{ 'FILTER.FILTER' | translate }}
								</label>
							</div>
							<!-- BOTON QUE AGRUPA LOS TIPO DE FILTROS -->
							<div *ngIf="!informeChallenge && this.profileTitle === 'Profesor' && informeType == 1 && showFullscreen"
								class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
								nbTooltip="{{ 'FILTER.FILTER' | translate }}" nbTooltipPlacement="top">
								<div>
									<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
										(click)="toggleAccordion2()">
										<nb-icon icon="funnel-outline"></nb-icon>
									</button>
								</div>
								<label class="label-header m-0">
									{{ 'FILTER.FILTER' | translate }}
								</label>
							</div>
						</div>
						<!-- BOTONES DESPLEGADOS -->
						<div *ngIf="!isMenuOpen && !isMobile" class="d-flex gap-4">
							<!--	FILTROS INFORME TABLA RESULTADOS -->
							<div *ngIf="!informeChallenge && informeType == 2 && showFullscreen" class="d-flex gap-4">
									<!--	BOTON VER ACTIVIDADES -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
										nbTooltip="{{ getVerActividadesLabel() }}" nbTooltipPlacement="top" *ngIf="this.profileTitle === 'Profesor'">
										<div>
											<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
												(click)="changeVerActividades()">
												<nb-icon *ngIf="!verActividades" icon="expand-outline"></nb-icon>
												<nb-icon *ngIf="verActividades" icon="collapse-outline"></nb-icon>
											</button>
										</div>
										<label class="label-header m-0">
											{{ getVerActividadesLabel() }}
										</label>
									</div>
									<!--	BOTON VER % ACIERTO -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
										nbTooltip="{{ getVerAciertoLabel() }}" nbTooltipPlacement="top">
										<div>
											<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
												(click)="changeVerAcierto()">
												<nb-icon icon="percent-outline"></nb-icon>
											</button>
										</div>
										<label class="label-header m-0">
											{{ getVerAciertoLabel() }}
										</label>
									</div>
									<!--	BOTON VER FOTOS -->
									<div class="d-flex align-items-center justify-content-start filter-buttons flex-column" style="gap: 5px;"
										nbTooltip="{{ getVerFotosLabel() }}" nbTooltipPlacement="top" *ngIf="this.profileTitle === 'Profesor'">
										<div>
											<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
												(click)="changeVerFotos()">
												<mat-icon svgIcon="user"></mat-icon>
											</button>
										</div>
										<label class="label-header m-0">
											{{ getVerFotosLabel() }}
										</label>
									</div>
									<!--	BOTON VER K -->
									<div class="d-flex align-items-center justify-content-start filter-buttons flex-column" style="gap: 5px;"
										nbTooltip="{{ getVerKLabel() }}" nbTooltipPlacement="top">
										<div>
											<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
												(click)="changeVerK()">
												<h6>K</h6>
											</button>
										</div>
										<label class="label-header m-0">
											{{ getVerKLabel() }}
										</label>
									</div>

									<div class="separador-vertical"></div>

									<!--	BOTON ORDENAR POR NOMBRE -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
										nbTooltip="{{ 'INFORMES.SORTNAME' | translate }}" nbTooltipPlacement="top" *ngIf="this.profileTitle === 'Profesor'">
										<div>
											<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="ordenarTabla_AZ()"
												[ngClass]="{ 'active-button': verBotonG3 == 1 }">
												{{ filtroAzTabla }}
											</button>
										</div>
										<label class="label-header m-0">
											{{ 'INFORMES.SORTNAME' | translate }}
										</label>
									</div>
									<!--	BOTON ORDENAR POR ACIERTOS -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
										nbTooltip="{{ 'INFORMES.SORTHITS' | translate }}" nbTooltipPlacement="top">
										<div>
											<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="ordenarTabla_Aciertos()"
												[ngClass]="{ 'active-button': verBotonG3 == 2 }">
												<nb-icon icon="done-all-outline"></nb-icon>
												<nb-icon *ngIf="filtroAciertos == 'aciertosASC'" icon="arrow-forward-outline"></nb-icon>
												<nb-icon *ngIf="filtroAciertos == 'aciertosDEC'" icon="arrow-back-outline"></nb-icon>
											</button>
										</div>
										<label class="label-header m-0">
											{{ 'INFORMES.SORTHITS' | translate }}
										</label>
									</div>
									<!--	BOTON ORDENAR POR TIEMPO TOTAL -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
										nbTooltip="{{ 'INFORMES.SORTTOTALTIME' | translate }}" nbTooltipPlacement="top">
										<div>
											<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="ordenarTabla_Tiempo()"
												[ngClass]="{ 'active-button': verBotonG3 == 3 }">
												<!--{{'GLOBALS.TOTALTIME' | translate}}-->
												<nb-icon icon="clock-outline"></nb-icon>
												<nb-icon *ngIf="filtroTiempo == 'tiempoTotalASC'" icon="arrow-forward-outline"></nb-icon>
												<nb-icon *ngIf="filtroTiempo == 'tiempoTotalDEC'" icon="arrow-back-outline"></nb-icon>
											</button>
										</div>
										<label class="label-header m-0">
											{{ 'INFORMES.SORTTOTALTIME' | translate }}
										</label>
									</div>
									<!-- EXPORT EXCEL -->
									<div class="d-flex align-items-center filter-buttons flex-column" style="gap: 5px;"
										*ngIf="this.profileTitle === 'Profesor' && informeType == 2" nbTooltip="{{ 'INFORMES.EXPORT' | translate }}" nbTooltipPlacement="top">
										<div>
											<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
												(click)="downloadExcel()">
												<mat-icon svgIcon="icon-excel"></mat-icon>
											</button>
										</div>
										<label class="label-header m-0">
											{{ 'INFORMES.EXPORT' | translate }}
										</label>
									</div>
							</div>
							<!--	FILTROS INFORME TIEMPOS DE TRABAJO -->
							<div *ngIf="!informeChallenge && this.profileTitle === 'Profesor' && informeType == 1 && showFullscreen" class="d-flex gap-4">
									<!--	BOTON ORDENAR ULTIMOS 7 DIAS -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
										nbTooltip="{{ 'INFORMES.VIEW7DAYS' | translate }}" nbTooltipPlacement="top">
										<div>
											<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="changeRangeTime('7days')"
												[ngClass]="{ 'active-button': verBotonG5 == 1 }">
												{{ 'INFORMES.DAY' | translate }}
											</button>
										</div>
										<label class="label-header m-0">
											{{ 'INFORMES.VIEW7DAYS' | translate }}
										</label>
									</div>
									<!--	BOTON ORDENAR ULTIMO MES -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
										nbTooltip="{{ 'INFORMES.SEELASTMONTH' | translate }}" nbTooltipPlacement="top">
										<div>
											<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="changeRangeTime('month')"
												[ngClass]="{ 'active-button': verBotonG5 == 2 }">
												{{ 'INFORMES.MONTH' | translate }}
											</button>
										</div>
										<label class="label-header m-0">
											{{ 'INFORMES.SEELASTMONTH' | translate }}
										</label>
									</div>
									<!--	BOTON ORDENAR ULTIMO AÑO -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
										nbTooltip="{{ 'INFORMES.SEELASTYEAR' | translate }}" nbTooltipPlacement="top">
										<div>
											<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="changeRangeTime('year')"
												[ngClass]="{ 'active-button': verBotonG5 == 3 }">
												{{ 'INFORMES.Year' | translate }}
											</button>
										</div>
										<label class="label-header m-0">
											{{ 'INFORMES.SEELASTYEAR' | translate }}
										</label>
									</div>
									<!-- EXPORT EXCEL -->
									<div class="d-flex align-items-center filter-buttons flex-column" style="gap: 5px;"
											*ngIf="this.profileTitle === 'Profesor' && informeType == 2" nbTooltip="{{ 'INFORMES.EXPORT' | translate }}" nbTooltipPlacement="top">
										<div>
											<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
												(click)="downloadExcel()">
												<mat-icon svgIcon="icon-excel"></mat-icon>
											</button>
										</div>
										<label class="label-header m-0">
											{{ 'INFORMES.EXPORT' | translate }}
										</label>
									</div>

									<div class="separador-vertical"></div>

									<!--	BOTON ORDENAR POR NOMBRE -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
										nbTooltip="{{ 'INFORMES.SORTNAME' | translate }}" nbTooltipPlacement="top">
										<div>
											<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="ordenarChart()"
												[ngClass]="{ 'active-button': verBotonG4 == 1 }">
												{{ filtroAz }}
											</button>
										</div>
										<label class="label-header m-0">
											{{ 'INFORMES.SORTNAME' | translate }}
										</label>
									</div>
									<!--	BOTON ORDENAR POR TIEMPO PROMEDIO -->
									<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
										nbTooltip="{{ 'INFORMES.AVERAGETIMEPERMINUTE' | translate }}" nbTooltipPlacement="top">
										<div>
											<button nbButton status="basic" size="small" class="d-flex align-items-center" (click)="ordenarChart2()"
												[ngClass]="{ 'active-button': verBotonG4 == 2 }">
												{{ filtro90 }}
											</button>
										</div>
										<label class="label-header m-0">
											{{ 'INFORMES.AVERAGETIMEPERMINUTE' | translate }}
										</label>
									</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left"></nb-icon>
	</div>

	<div *ngIf="loading" class="d-flex justify-content-center align-items-center loading">
		<div class="loaderBar"></div>
	</div>

	<nb-card-body class="position-static" style="padding-top: 0px;">
		<ng-container *ngIf="!showCompetitionsResults; else defaultReporting">
			<div *ngIf="usersInscritos" class="row min-h-100">
				<!-- 2.	Listado de curso, con sus grafos y nodos en modo árbol -->
				<div class="container-tree background_white col-4 py-4 pl-4 pr-0" [ngClass]="{'left-files': !isMenuOpen, 'show-menu-lateral-mobil': isMobile}">

					<!-- MODO LISTA CURSOS -->
					<ng-container *ngIf="selectedFilterMode === filterModeList.LIST_MODE">
						<mat-tree class="pr-4 tree" [dataSource]="dataSource" [treeControl]="treeControl" [ngClass]="{'pulsating': stepGuide == 2}">
							<mat-tree-node class="d-flex align-items-center my-2 py-2" *matTreeNodeDef="let node; when: hasChild"
								matTreeNodePadding [matTreeNodePaddingIndent]="0">

								<!-- barra de progreso -->
								<div class="d-flex align-items-center pointer" style="width: 80px;"
									*ngIf="node.type === 'GRAFO' && claseInvertida">
									<div class="progress half-width">
										<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
											aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" [style.backgroundColor]="node.colorBar"
											[style.width]="node.progressPercentage * 100 + '%'"></div>
									</div>
									<div class="averageKnowledge ml-1">
										<b>{{ node.progressPercentage * 100 | number:'1.0-0'}}%</b>
									</div>
								</div>
								<!-- ---------- -->

								<nb-checkbox class="mt-1 pointer" *ngIf="node.type === 'GRAFO'"
									(checkedChange)="toggleChallenge($event, node, selectedType.GRAPH)"></nb-checkbox>
								<mat-icon class="mat-icon-rtl-mirror pointer" matTreeNodeToggle
									[attr.aria-label]="'Toggle ' + node.name" (click)="resourse(node)">
									{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
								</mat-icon>
								<button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
									(click)="resourse(node)">
									<img
										src="../../../../assets/images/icons/{{node.icon == 'curso' ? 'ProyectoComoMuchosGrafos' : node.icon}}.svg"
										alt="" height="20" width="20">
								</button>
								<span class="node_name pointer" matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
									(click)="resourse(node)">{{node.name}}</span><span
									class="badge ml-1 badge-danger">{{node.pendingQuizzes == 0?
									'':node.pendingQuizzes}}</span>
								<nb-icon icon="eye-outline" *ngIf="node.type === 'GRAFO' && claseInvertida"
									(click)="verGrafo(node.idCourse, node.id)"
									nbTooltip="{{ 'EDITARCURSOMAPA.IRALGRAFO' | translate }}"></nb-icon>
								<!--
								<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
									*ngIf="node.type === 'GRAFO' && claseInvertida" (click)="verGrafo(node.idCourse, node.id)">
									<mat-icon svgIcon="graph" [ngbTooltip]="'EDITARCURSOMAPA.IRALGRAFO' | translate"></mat-icon>
								</button>
								-->
							</mat-tree-node>
							<mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding
								[ngClass]="{'selected': selectedNode === node}" (click)="node.isSelected = !node.isSelected">
								<button mat-icon-button>
									<mat-icon svgIcon="ideasvg"></mat-icon>
									{{node.name}}<span class="badge ml-1 badge-danger">{{node.pendingQuizzes == 0? '':node.pendingQuizzes
										}}</span>
								</button>
							</mat-tree-node>
						</mat-tree>
					</ng-container>

					<!-- MODO LISTA DESAFIOS -->
					<ng-container *ngIf="selectedFilterMode === filterModeList.CHALLENGE_MODE">
						<div class="challenge-list" [ngClass]="{'pulsating': stepGuide == 2}">
							<div *ngFor="let challenge of challenges" class="d-flex">
								<nb-checkbox (checkedChange)="toggleChallenge($event, challenge, selectedType.CHALLENGE)">
									<span *ngIf="editValue !== challenge.idSession">{{challenge.nameSession}}</span>
									<input nbInput [(ngModel)]="challenge.nameSession" *ngIf="editValue === challenge.idSession"
										(ngModelChange)="nameSessionChanged($event)">
								</nb-checkbox>
								<div class="edit-buttons ml-2">
									<mat-icon class="pe" placement="right-top"
										[ngbTooltip]="editValue === challenge.idSession ? ('INFORMES.SAVE' | translate) : ('INFORMES.EDIT' | translate)"
										(click)="editSaveSession(challenge)">{{editValue === challenge.idSession ? 'save' :
										'edit'}}</mat-icon>
									<mat-icon class="pe color-red-text" placement="right-top"
										ngbTooltip="{{ 'INFORMES.DELETE' | translate }}"
										(click)="deleteChallenge(challenge)">delete</mat-icon>
								</div>
							</div>
						</div>
					</ng-container>

					<!-- NO HAY CONTENIDO -->
					<h6 class="text-title font-weight-bold pr-4 d-flex justify-content-center align-items-center m-0 h-100"
						*ngIf="dataSource.data.length === 0 && challenges.length === 0 && stepGuide > 1">
						{{ 'INFORMES.NOHAYCONTENIDO' | translate }}
					</h6>
				</div>

				<!-- BOTON QUE OCULTA EL LISTADO -->
				<div class="cont-icon-hide" [ngClass]="{'hide-section': !isMenuOpen}" (click)="toggleMenu()">
					<mat-icon class="pointer">
						{{ isMenuOpen ? 'arrow_left' : 'arrow_right' }}
					</mat-icon>
				</div>

				<!-- 3.	Tabla con los datos de los quizzes -->
				<div class="table-datod col-12 py-0 pl-0 pr-0 container-tree" [ngClass]="{'col-xl-8 pl-xl-4': isMenuOpen }">
					<div class="background_white h-100 py-4 pl-4 pr-0">
						<div class="d-flex flex-column container-data-table gap-3">
							<!--	INFORME TIEMPOS DE TRABAJO -->
							<div id="myBarChart" class="w-100 h-100 px-5" style="display: none; cursor: grab;"
								[ngClass]="{ 'graficoBarras': !isMenuOpen }">
								<app-graficos></app-graficos>
							</div>
							<!--	INFORME TABLA RESULTADOS -->
							<div id="infoTable" class="w-100" style="display: none;">
								<!--
								<app-estudiante-quizes-datos [idGrupo]="idGrupo" [idCourse]="idCourse" [idGrafo]="idGrafo" [idNode]="idNode"
								[idHijo]="idHijo" [challengeOn]="challengeOn" [reportingChallenge]="reportingChallenge"
								[listChallengeSelected]="listChallengeSelected" [listGraphSelected]="listGraphSelected" [reportType]="reportType" (reload)="reload($event)"></app-estudiante-quizes-datos>
								-->
								<div *ngIf="dataTable.length > 0">
									<app-tabla
										[dataTable]="dataTable"
										[reportType]="reportType"
										[idGrupo]="idGrupo"
										[idCourse]="idCourse"
										[idGrafo]="idGrafo"
										[listGraphSelected]="listGraphSelected"
										[listChallengeSelected]="listChallengeSelected"
										[verActividades]="verActividades"
										[verAciertos]="verAciertos"
										[verFotos]="verFotos"
										[verK]="verK"
										(reload)="reload($event)">
									</app-tabla>
								</div>
							</div>
							<!--	CUANDO NO HA SELECCIONADO NADA -->
							<h6 class="text-title mx-5 text-center d-flex justify-content-center align-items-center m-0 h-100"
								*ngIf="showMessage && !isMobile">
								{{ 'INFORMES.NOHAYOPCIONACTIVA' | translate }}
							</h6>
							<!--	CUANDO NO HAY ESTUDIANTES INSCRITOS -->
							<h6 class="text-title d-flex justify-content-center align-items-center m-0 h-100"
								*ngIf="usersInscritos.length === 0 && !isMobile && this.profileTitle !== 'Padre'">
								{{ 'INFORMES.NOHAYESTUDIANTES' | translate }}
							</h6>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-template #defaultReporting>
			<app-modal-competition-results [minimal]="true" [reportingIdGroup]="idGrupo"></app-modal-competition-results>
		</ng-template>


	</nb-card-body>
</nb-card>
