
        <!-- <form [formGroup]="node" (ngSubmit)="onSubmit()">-->
        <form [formGroup]="node" (ngSubmit)="onSubmit()">
            <div class="form-group row my-1">
                <label for="name" class="col-sm-4 col-form-label col-form-label-sm">{{ 'NODEFORMCOMPONENT.NODENAME' | translate }}</label>
                <div class="col-sm-8">
                    <input class="form-control form-control-sm"
                        type="text"
                        placeholder="{{ 'NODEFORMCOMPONENT.TYPENODENAME' | translate }}"
                        formControlName="name">
                </div>               
            </div>    
            <div class="form-group row my-1"> 
                <label for="description" class="col-sm-4 col-form-label col-form-label-sm">{{ 'NODEFORMCOMPONENT.DESCRIPTION' | translate }}</label>
                <div class="col-sm-8">
                    <div class="input-group input-group-sm my-1">
                        <input class="form-control form-control-sm"
                            type="text"
                            placeholder="{{ 'NODEFORMCOMPONENT.TYPEDESCRIPTION' | translate }}"
                            formControlName="description">
                            <div class="input-group-addon">                            
                                <span class="input-group-text" id="inputGroup-sizing-sm">...</span>
                            </div>
                    </div>
                </div>
            </div>         
            <div class="form-group row my-1"> 
                <label for="duration" class="col-sm-4 col-form-label col-form-label-sm">{{ 'NODEFORMCOMPONENT.DURATION' | translate }}</label>             
                <div class="col-sm-8">
                    <input class="form-control form-control-sm"
                        type="time"
                        formControlName="duration">
                    </div>
            </div>  
            <div class="form-group row my-1">               
                <label for="level" class="col-sm-4 col-form-label col-form-label-sm">{{ 'NODEFORMCOMPONENT.LEVEL' | translate }}</label>
                <div class="col-sm-8">               
                    <input class="form-control form-control-sm"
                        type="number"
                        formControlName="level">
                </div>
            </div> 
            <!--- POWER --->
            <h3 >QUIERO MAS POWER</h3>
            <div class="form-group row my-1">               
                <div class="form-check">
                    <h3 >QUIERO MAS POWER</h3>
                    <div class="col-sm-6 col-form-label col-form-label-sm">
                        <input class="form-check-input" type="checkbox" value="" id="chkPowerLevel+2"> 
                        <input class="form-check-input" type="number" value="" id="txtPowerLevel+2">   
                        <label class="form-check-label" for="chkPowerLevel+2">POWER</label>
                    </div>          
                </div>
            </div> 
            <!---  ****** -->
            <div class="form-group row my-1"> 
                <div class="form-check">
                    <div class="col-sm-6 col-form-label col-form-label-sm">
                        <input class="form-check-input" type="checkbox" value="" id="certifiable">  
                        <label class="form-check-label" for="certifiable">{{ 'NODEFORMCOMPONENT.CERTIFIABLE' | translate }}</label>
                    </div>          
                </div>
            </div>                 
            <div class="form-group row my-1"> 
                <label class="col-sm-4 col-form-label col-form-label-sm">{{ 'NODEFORMCOMPONENT.LANGUAGE1' | translate }}</label>             
                <div class="col-sm-8">
                    <select class="form-control form-control-sm">
                        <option>English</option>
                      </select>
                </div>
            </div>  
            <div class="form-group row my-1"> 
                <label class="col-sm-4 col-form-label col-form-label-sm">{{ 'NODEFORMCOMPONENT.LANGUAGE2' | translate }}</label>             
                <div class="col-sm-8">
                    <select class="form-control form-control-sm">
                        <option>English</option>
                      </select>
                </div>
            </div>    
            <div class="form-group row my-1"> 
                <label class="col-sm-4 col-form-label col-form-label-sm">{{ 'NODEFORMCOMPONENT.AUTHOR' | translate }}</label>             
                <label class="col-sm-8 col-form-label col-form-label-sm"><b>Ana García</b></label>                    
            </div>  
            <div class="form-group row my-1"> 
                <div class="form-check">
                    <div class="col-sm-6 col-form-label col-form-label-sm">
                        <input class="form-check-input" type="checkbox" value="" id="video">  
                        <label class="form-check-label" for="video">{{ 'NODEFORMCOMPONENT.VIDEO' | translate }}</label>
                    </div>          
                </div>
            </div>  
            <div class="form-group row my-1"> 
                <div class="form-check">
                    <div class="col-sm-6 col-form-label col-form-label-sm">
                        <input class="form-check-input" type="checkbox" value="" id="image">  
                        <label class="form-check-label" for="image">{{ 'NODEFORMCOMPONENT.IMAGE' | translate }}</label>
                    </div>          
                </div>
            </div>  
            <div class="form-group row my-1"> 
                <div class="form-check">
                    <div class="col-sm-6 col-form-label col-form-label-sm">
                        <input class="form-check-input" type="checkbox" value="" id="audio">  
                        <label class="form-check-label" for="audio">{{ 'NODEFORMCOMPONENT.AUDIO' | translate }}</label>
                    </div>          
                </div>
            </div> 
            <div class="form-group row my-1"> 
                <div class="form-check">
                    <div class="col-sm-6 col-form-label col-form-label-sm">
                        <input class="form-check-input" type="checkbox" value="" id="pdf">  
                        <label class="form-check-label" for="pdf">{{ 'NODEFORMCOMPONENT.PDF' | translate }}</label>
                    </div>          
                </div>
            </div> 
            <div class="form-group row my-1"> 
                <div class="form-check">
                    <div class="col-sm-6 col-form-label col-form-label-sm">
                        <input class="form-check-input" type="checkbox" value="" id="text">  
                        <label class="form-check-label" for="text">{{ 'NODEFORMCOMPONENT.TEXT' | translate }}</label>
                    </div>          
                </div>
            </div> 
            <div class="form-group row my-1"> 
                <label class="col-sm-4 col-form-label col-form-label-sm">{{ 'NODEFORMCOMPONENT.CREATION' | translate }}</label>             
                <label class="col-sm-8 col-form-label col-form-label-sm"><b>01/02/2020</b></label>                    
            </div>  
            <div class="form-group row my-1"> 
                <label class="col-sm-4 col-form-label col-form-label-sm">{{ 'NODEFORMCOMPONENT.EDITION' | translate }}</label>             
                <label class="col-sm-8 col-form-label col-form-label-sm"><b>10/03/2020</b></label>                    
            </div>  
            <div class="form-group row my-1"> 
                <label class="col-sm-4 col-form-label col-form-label-sm">{{ 'NODEFORMCOMPONENT.ID' | translate }}</label>             
                <label class="col-sm-8 col-form-label col-form-label-sm"><b>123456</b></label>                    
            </div>  
            <!-- ********* -->
            <div class="form-group row my-2"> 
                <button mat-button type="submit">{{ 'NODEFORMCOMPONENT.NEXT' | translate }}</button>
            </div>    
        </form>
       
   

    <!--<div [hidden]="!submitted"> 
        <mat-card>    
            <mat-card-header>  
               
                <mat-card-title-group>
                    <mat-card-title>
                        {{node.value.name}}            
                    </mat-card-title>
                    <mat-card-subtitle>
                        {{node.value.description}}
                    </mat-card-subtitle>
                </mat-card-title-group>
            </mat-card-header>    

            <mat-divider></mat-divider>

            <mat-card-content>  
                <mat-list>
                    <mat-list-item>
                        <mat-icon>stairs</mat-icon>
                        {{node.value.level}}
                    </mat-list-item>  
                    <mat-list-item>
                        <mat-icon>access_time</mat-icon>
                        {{node.value.duration}}
                    </mat-list-item>   
                </mat-list>             
                
                <div class="center">
                    <app-file-uploader (newBackground)="newBackground($event)" (newImage)="newImage($event)" [image]="img" [activeColor]="'orangered'" [baseColor]="'lightgray'"></app-file-uploader>
                </div>
            </mat-card-content>

            <button mat-button (click)="editNode()">PREVIOUS</button>
            <button mat-button (click)="finished($event)">NEXT</button>
        </mat-card>       
    <div>-->

