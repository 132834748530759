import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.scss']
})
export class ModalImageComponent implements OnInit {

  images: Array<string> = []
  currentImage: number = 0
  baseUrl:string = '../../../../assets/images/demo_images/'

  public option:string
  public title:string

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {



    switch(this.option){
      case 'emojis':
        this.images = [`${this.baseUrl}emojis.png`]
        break
      case 'share':
        this.images = [ `${this.baseUrl}share.png`]
        break
      case 'print':
        this.images = [ `${this.baseUrl}print.png` ]

    }
  }


  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }

  changeImage(){
    if(this.currentImage != this.images.length -1)
      this.currentImage++
  }

}
