import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UserModel } from 'src/app/core/models/courses';
import { PrefixCountry } from 'src/app/core/models/shared/prefixCountry.model';
import { LoginService } from 'src/app/core/services/login';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { UsersService } from 'src/app/core/services/users';

@Component({
	selector: 'app-invitar-padre',
	templateUrl: './invitar-padre.component.html',
	styleUrls: ['./invitar-padre.component.scss']
})

export class InvitarPadreComponent implements OnInit {
	@Output() action: EventEmitter<number> = new EventEmitter<number>();

  public formularioDatos: UntypedFormGroup;
	users: UserModel[] = [];
	validationMessages = {
    mail: [],
		mobile: [],
		prefix: [],
  };

	private _maxLength: number = 15;
	$phonePrefixes: Observable<PrefixCountry[]>;

	constructor(
    private formBuild: UntypedFormBuilder,
		public userService: UsersService,
    private translateService: TranslateService,
		private toaster: ToasterService,
		public loginService: LoginService,
	) {
		this.formularioDatos = this.formBuild.group({
			mail: [''],
			mobile: new UntypedFormControl("", [Validators.maxLength(this._maxLength)]),
			prefix: [""],
		});
	}

	ngOnInit() {
		// Cargo las validaciones del formulario
    this.cargarValidacionesFormulario();
		this.$phonePrefixes = this.loginService.getPhonePrefix();
	}

	enviarInvitacion(){
		if (this.formularioDatos.invalid) {
			this.toaster.error(this.translateService.instant("ERROR.HAOCURRIDOUNERROR"));
      return;
    } else {
			const campos = this.formularioDatos.value;
			this.userService.invitationoutsidefather(campos.mail, campos.mobile, campos.prefix).subscribe(res => {
				if(res.error.msg === "PARENTS.INVITATIONSEND"){
					this.back();
					this.toaster.success(this.translateService.instant("MODALLISTSTUDENTS.INVITATIONSENT"));
				} else {
					this.toaster.error(this.translateService.instant("ERROR.HAOCURRIDOUNERROR"));
				}
			});
		}
	}

	back(){
		// mandamos el numero de la pantalla de nuevo padre
		this.formularioDatos = this.formBuild.group({
			mail: [''],
		});
		this.action.emit(1);
	}

	private cargarValidacionesFormulario() {
		this.translateService.get('VALIDACIONES.ELEMAILESOBLIGATORIO').subscribe((res: string) => {
			this.validationMessages.mail.push({type: 'required', message: res});
		});
		this.translateService.get("VALIDACIONES.ELTELEFONOMOVILESOBLIGATORIO").subscribe((res: string) => {
			this.validationMessages.mobile.push({ type: "required", message: res });
		});
		this.translateService.get("VALIDACIONES.MAXLENGTH").subscribe((res: string) => {
			this.validationMessages.mobile.push({
				type: "maxlength",
				message: res.replace("{1}", `${this._maxLength}`),
			});
		});
  }

}
