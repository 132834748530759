import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagenPipe } from './imagen.pipe';
import { ProfileSettingsPipe } from './profile-settings.pipe';
import { SafePipe } from './safe.pipe';
import { SortByPipe } from './sort-by.pipe';
import { TruncatePipe } from './truncate.pipe';
import { LanguageNamePipe } from './language-name.pipe';
import { NodeCoverPipe } from './node-cover.pipe';
import { SliceStringPipe } from './slice-string.pipe';
import { QuizElementsPipe } from './quiz-elements.pipe';

@NgModule({
    declarations: [
        ImagenPipe,
        ProfileSettingsPipe,
        SafePipe,
        SortByPipe,
        TruncatePipe,
        LanguageNamePipe,
        NodeCoverPipe,
        SliceStringPipe,
        QuizElementsPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ImagenPipe,
        ProfileSettingsPipe,
        SafePipe,
        SortByPipe,
        TruncatePipe,
        LanguageNamePipe,
        SliceStringPipe,
        QuizElementsPipe
    ]
})
export class PipesModule { }
