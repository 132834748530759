<nb-card class="no-edge-card mb-0 pr-0 col-12 h-100" accent="primary">
	<div class="closeModal">
		<nb-icon class="m-1 close-global" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body style="padding-right: 2rem;">
		<app-loading [isVisible]="loading"></app-loading>
		<div class="d-flex flex-column h-100">
			<div class="row">
				<img height="140" [src]="loginImage | imagen : 'logoLogin'" *ngIf="loginImage" />
			</div>
			<h1 class="bright-green-color">
				{{ "LOGIN.CREATEUNACUENTA" | translate }}
			</h1>
			<!-- FIRST STEP-->
			<form [formGroup]="step1" (ngSubmit)="nextStep()" #Form1="ngForm" *ngIf="currentStep === '1'" class="form1">
				<div>
					<div class="row mb-3">
						<!--<label class="col-12 text-color-secondary" for="username">{{ 'LOGIN.ACCEDERNAME' | translate }}</label>-->
						<div class="col-12 mb-2">
							<input nbInput status="basic" fullWidth fieldSize="medium" shape="round" class="form-control-login" type="nick"
								formControlName="nick" placeholder="{{ 'REGISTRO.NICKEJEMPLO' | translate }}"
								(input)="validacionNick()" />
							<div *ngFor="let validation of validationMessages.nick">
								<div class="error-message" *ngIf="nickControl?.hasError(validation.type) &&
									(nickControl?.dirty ||
										nickControl?.touched ||
										Form1.submitted)">
									{{ validation.message }}
								</div>
							</div>
						</div>
					</div>

					<div class="row mb-3">
						<!--<label class="col-12 text-color-secondary" for="username">{{ 'LOGIN.ACCEDEREMAIL1' | translate }}</label>-->
						<div class="col-12 mb-2">
							<input nbInput status="basic" fullWidth fieldSize="medium" shape="round" class="form-control-login" type="nick"
								formControlName="fatherEmail" placeholder="{{ 'REGISTRO.EMAILEJEMPLO' | translate }}" />
						</div>
					</div>

					<div class="row mb-3">
						<!--<label class="col-12 text-color-secondary" for="username">{{ 'LOGIN.INGRESACONTRASENA' | translate }}</label>-->
						<div class="col-12">
							<input nbInput status="basic" fullWidth shape="round" fieldSize="medium" class="form-control-login"
								[type]="hidePassword ? 'password' : 'text'" formControlName="keyWord"
								placeholder="{{ 'USERDATA.CREATEPASSWORD' | translate }}" />
							<div *ngFor="let validation of validationMessages.keyword" class="mt-2">
								<div class="error-message" *ngIf="keywordControl?.hasError(validation.type) &&
									(keywordControl?.dirty ||
										keywordControl?.touched ||
										Form1.submitted)">
									{{ validation.message }}
								</div>
							</div>
							<button class="hide_button_nick" type="button" mat-icon-button matSuffix
								(click)="hidePassword = !hidePassword" [attr.aria-pressed]="hidePassword">
								<mat-icon class="icon-password">{{hidePassword ? "visibility_off" : "visibility"}}</mat-icon>
							</button>
						</div>
					</div>

					<div class="row mb-3">
						<!--<label class="col-12 text-color-secondary" for="username">{{ 'LOGIN.INGRESACONTRASENA' | translate }}</label>-->
						<div class="col-12">
							<input nbInput status="basic" fullWidth shape="round" fieldSize="medium" class="form-control-login"
								[type]="hidePassword ? 'password' : 'text'" formControlName="repeatKeyWord"
								placeholder="{{ 'CAMBIARPASS.REPEATNEWPASS' | translate }}" (input)="validateRepeatPassword()" />
							<div *ngFor="let validation of validationMessages.repeatKeyWord" class="mt-2">
								<div class="error-message" *ngIf="
													repeatKeywordControl?.hasError(validation.type) &&
														(repeatKeywordControl?.dirty ||
														repeatKeywordControl?.touched)">
									{{ validation.message }}
								</div>
							</div>
							<button class="hide_button_nick" type="button" mat-icon-button matSuffix
								(click)="hidePassword = !hidePassword" [attr.aria-pressed]="hidePassword">
								<mat-icon class="icon-password">{{hidePassword ? "visibility_off" : "visibility"}}</mat-icon>
							</button>
						</div>
					</div>
				</div>

				<!--PROFILES-->
				<div class="profile">
					<div class="item-profile">
						<nb-toggle labelPosition="end" formControlName="profileStudent" (change)="validacionRole()"></nb-toggle>
						<span class="text-color-secondary" nbTooltip="{{ 'LOGIN.STUDENT' | translate }}" nbTooltipPlacement="bottom"
							nbButton status="primary">{{ "LOGIN.STUDENT" | translate }}</span>
					</div>
					<!--<div class="item-profile">
						<nb-toggle labelPosition="end" formControlName="profileTeacher" (change)="validacionRole()"></nb-toggle>
						<span nbTooltip="{{ 'LOGIN.TITLETEACHER' | translate }}" nbTooltipPlacement="bottom"
							nbTooltipStatus="primary" nbButton status="primary">{{ "LOGIN.TEACHER" | translate }}</span>
					</div>
					<div class="item-profile">
						<nb-toggle labelPosition="end" formControlName="profileParent" (change)="validacionRole()"></nb-toggle>
						<span nbTooltip="{{ 'LOGIN.PARENT' | translate }}" nbTooltipPlacement="bottom" nbTooltipStatus="primary"
							nbButton status="primary">{{ "LOGIN.PARENT" | translate }}</span>
					</div>-->
				</div>

				<!-- TERMS AND CONDITIONS -->
				<div id="terminos">
					<div class="row justify-content-center">
						<div class="col-12">
							<nb-checkbox class="checkbox-login" formControlName="terms">
								{{ "REGISTRO.ACCEPTNICKCONDITIONS" | translate }}
							</nb-checkbox>
							<div *ngFor="let validation of validationMessages.terms">
								<div class="error-message" *ngIf="termsControl?.hasError(validation.type) && (termsControl?.dirty || Form1.submitted)">
									{{ validation.message }}
								</div>
							</div>
						</div>
						<div id="papel" class="col-12">
							<nb-checkbox class="checkbox-login" formControlName="paper">
								{{ "REGISTRO.ESCRIBEENPAPEL" | translate }}
							</nb-checkbox>
							<div *ngFor="let validation of validationMessages.paper">
								<div class="error-message" *ngIf="termsControl?.hasError(validation.type) && (termsControl?.dirty || Form1.submitted)">
									{{ validation.message }}
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- NEXT BUTTON-->
				<div class="row">
					<div class="col-12 py-4 d-flex justify-content-center">
						<button nbButton class="d-block w-100 button-primary" type="submit" [class.disabled]="!roleAsignado">
							<span>{{ "REGISTRO.NEXT" | translate }}</span>
						</button>
					</div>
				</div>
			</form>

			<!-- SECOND STEP-->
			<form [formGroup]="step2" (ngSubmit)="registerUser()" #Form2="ngForm" *ngIf="currentStep === '2'">
				<!-- SECURITY QUESTIONS -->
				<div class="row">
					<label class="col-12 text-color-secondary">{{ 'REGISTRO.QUESTION1' | translate }}</label>
					<div class="col-12">
						<nb-select fullWidth fieldSize="medium" class="SelectGlobal" hero shape="round" formControlName="idSecurityQuestions"
							id="idSecurityQuestions">
							<nb-option class="SelectGlobalOption" *ngFor="let question of securityQuestions" [value]="question.id">
								{{ question.question }}
							</nb-option>
						</nb-select>
						<div *ngFor="let validation of validationMessages.idSecurityQuestions">
							<div class="error-message" *ngIf="
									question1Control?.hasError(validation.type) &&
									(question1Control?.dirty ||
										question1Control?.touched ||
										Form2.submitted)
								">
								{{ validation.message }}
							</div>
						</div>
					</div>

					<div class="col-12 mt-4">
						<input nbInput shape="round" fieldSize="medium" fullWidth class="form-control-login" type="text"
							formControlName="securityAnswer" placeholder="{{ 'REGISTRO.MYANSWER1' | translate }}" />
						<div *ngFor="let validation of validationMessages.securityAnswer">
							<div class="error-message" *ngIf="
									answer1Control?.hasError(validation.type) &&
									(answer1Control?.dirty ||
										answer1Control?.touched ||
										Form2.submitted)
								">
								{{ validation.message }}
							</div>
						</div>
					</div>
				</div>

				<div class="row mt-5">
					<label class="col-12 text-color-secondary">{{ 'REGISTRO.QUESTION2' | translate }}</label>
					<div class="col-12">
						<nb-select fullWidth fieldSize="medium" class="SelectGlobal" hero shape="round" formControlName="idSecurityQuestions2"
							id="idSecurityQuestions2">
							<nb-option class="SelectGlobalOption" *ngFor="let question of securityQuestions" [value]="question.id">
								{{ question.question }}
							</nb-option>
						</nb-select>
						<div *ngFor="let validation of validationMessages.idSecurityQuestions">
							<div class="error-message" *ngIf="
									question2Control?.hasError(validation.type) &&
									(question2Control?.dirty ||
										question2Control?.touched ||
										Form2.submitted)
								">
								{{ validation.message }}
							</div>
						</div>
					</div>

					<div class="col-12 mt-4">
						<input nbInput shape="round" fullWidth fieldSize="medium" class="form-control-login" type="text" type="text"
							formControlName="securityAnswer2" placeholder="{{ 'REGISTRO.MYANSWER2' | translate }}" />
						<div *ngFor="let validation of validationMessages.securityAnswer2">
							<div class="error-message" *ngIf="
														answer2Control?.hasError(validation.type) &&
														(answer2Control?.dirty ||
															answer2Control?.touched ||
															Form2.submitted)
													">
								{{ validation.message }}
							</div>
						</div>
					</div>
				</div>
				<!-- SUBMIT BUTTON -->
				<div class="row">
					<div class="col-12 py-5 d-flex justify-content-center">
						<button nbButton class="d-block w-100 button-primary" type="submit">
							{{ "REGISTRO.REGISTRARNUEVOUSUARIO" | translate }}
						</button>
					</div>
				</div>
			</form>

			<!-- END SECOND STEP-->
		</div>
	</nb-card-body>
</nb-card>
