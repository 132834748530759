<nb-card class="no-edge-card mb-0 background_themecolor modal-height-100-62" (click)="closeFiltersPanel()">
	<nb-card-header class="px-3 py-0 flex flex-column border-bottom-0">

		<div class="row p-4 background_white gap">
			<div class="col-12 col-md-7 col-lg-8 modal-title px-2">
				<h4>{{'USERDATA.ESTUDIANTES' | translate}}</h4>
			</div>
		</div>

		<!-- Filtros -->
		<div class="row px-3 py-2 mt-3">
			<div class="col-12 col-xl-8">
				<!-- Version web -->
				<div  class="d-flex align-items-center gap-4">
					<div class="d-flex flex-column align-items-center">
						<nb-select name="lang1" class="SelectForm" [(selected)]="selectedOrder" placeholder="small" hero
							size="small" (selectedChange)="changeOrder($event)">
							<nb-option class="SelectFormOpt" *ngFor="let order of orderList" [value]="order.idOrder">
								{{ order.nombre}}
							</nb-option>
						</nb-select>
					</div>

					<nb-actions size="small">
						<nb-action icon="clock-outline" [ngClass]="{'selected-icons': historyFilterActive}" nbTooltip="Historial"
							nbTooltipPlacement="top" (click)="historyFilter()"></nb-action>

						<nb-action icon="funnel-outline" [ngClass]="{'selected-icons': iconSelected}"
							nbPopoverPlacement="bottom" nbTooltip="Filtros" nbTooltipPlacement="top" nbPopoverTrigger="noop"
							(click)="openFiltersPanel()"></nb-action>

						<ng-template #filtersTemplate>
							<nb-card class="m-0 background_themecolor" style="box-shadow: 0 0.5rem 1rem 0 rgba(0,0,0,.4);">
								<nb-card-header class="border-bottom-0">
									<p>{{'FILTER.FILTER' | translate}}</p>
									<div class="d-flex justify-content-center">
										<button nbButton size="small" class="btn-themecolor" type="button"
											(click)="clearFilters();selectLang.handleClearClick();selectSubject.handleClearClick();selectCountry.handleClearClick();clearFiltersList(4);clearFilters();">
											<span>{{'FILTER.CLEARFILTER' | translate}}</span>
										</button>
									</div>
								</nb-card-header>
							</nb-card>
						</ng-template>

					</nb-actions>
				</div>
			</div>
			<!-- Buscador -->
			<div *ngIf="formulario" class="col-12 col-xl-4 d-flex align-items-center">
				<nb-form-field [formGroup]="formulario" class="w-100">
					<input type="search" nbInput fullWidth fieldSize="small" class="form-control"
						placeholder="{{ 'CURSOS.SEARCH' | translate }}" formControlName="filtrado">
					<button nbSuffix nbButton ghost size="small">
						<nb-icon class="pointer" icon="search" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
					</button>
				</nb-form-field>
			</div>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
		 (click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>

	<nb-card-body>
		<!-- VISTA MODO GRILLA -->
		<div  class="row background_white d-flex justify-content-center py-4 px-2 min-h-100">
			<div class="col-12">
				<div *ngIf="students.length === 0">
					<nb-card class="no-edge-card">
						<nb-card-body>
							<h4 class="text-center">{{ 'CURSOS.FILTERNOTMATCH' | translate }}</h4>
						</nb-card-body>
					</nb-card>
				</div>
				<div *ngIf="students.length !== 0">
				<div class="d-flex flex-wrap gap-5 justify-content-center">
					<div *ngFor="let student of students" class="card-projects">
						<nb-card size="timy" class="mb-0" [ngStyle]="{'background-color': student.color}">
							<nb-card-header class="border-bottom-0">
								<p class="courseTittle">
									{{student.nombre}} {{student.apellido}}
								</p>
							</nb-card-header>
							<nb-card-body class="p-0">
								<div class="d-flex justify-content-center">
									<img [src]="student.avatar" alt="Student Avatar" width="150" height="150">
								</div>
							</nb-card-body>
							<nb-card-footer class="border-top-0">
								<p class="courseTittle" nbTooltip="{{student.grado}}" nbTooltipPlacement="top">
									{{student.grado}}
								</p>
							</nb-card-footer>
						</nb-card>
					</div>
				</div>

				</div>
			</div>
		</div>

	</nb-card-body>

</nb-card>
