import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import * as countdown from 'countdown';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { LoginService } from 'src/app/core/services/login/login.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TargetsService } from 'src/app/core/services/targets';
import { QuizzesstackService } from 'src/app/core/services/quizzesstack/quizzesstack.service';
import { ModalReproducirNodoComponent } from 'src/app/shared/components/sigma-canvas/modal-reproducir-nodo/modal-reproducir-nodo.component';
import { QuizModel } from 'src/app/core/models/quizzes';
import { TEMPLATE_ELEMENTS_TYPES } from 'src/app/core/utils/template-elements-types';
import { QuizTemplateElement } from 'src/app/core/models/quizzes/quiz-template-element.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { QuizElementTypes } from 'src/app/core/models/quizzes/quiz-element-types.enum';
import { QuizTypes } from 'src/app/core/models/quizzes/quiz-types.enum';
import { QuizElement } from 'src/app/core/models/quizzes/quiz-element.model';
import { QuizElementsPipe } from 'src/app/shared/pipes/quiz-elements.pipe';
import { environment } from 'src/environments/environment';
import { ActividadQuizPlayBodyOptionComponent } from '../quiz-play-body-option/actividad-quiz-play-body-option.component';
import { DataHistoryClass, DataHistoryInterface } from 'src/app/shared/components/datos-resultado-quizes/interface/datos-resultado.interface';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

export interface Time {
    hours: number,
    minutes: number,
    seconds: number;
}

@Component({
	selector: "app-actividad-quiz-play-body",
	templateUrl: "./actividad-quiz-play-body.component.html",
	styleUrls: ["./actividad-quiz-play-body.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ActividadQuizPlayBodyComponent implements OnInit, OnDestroy {
	@Input() quiz: any;
	@Input() courseId: number;
	@Input() graphId: number;
	@Input() elements: any[];

	@Input() modeAuto: boolean = false;
	@Input() disabledButton: boolean = false;
	@Input() answered: boolean;
	@Output() answeredChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output() colorChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() result: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() dataTableResponse: EventEmitter<DataHistoryClass> =
		new EventEmitter<DataHistoryInterface>();
	dataResponse: DataHistoryClass;
	@Input() discoverAnswer$: Observable<boolean>;
	@Input() evaluate$: Observable<boolean>;
	@Input() playNext$: Observable<boolean>;
	@Input() tryClose$: Observable<boolean>;
	isLoading: boolean = false;

	time: Time;
	iniBlock: boolean;
	countSeconds: number;
	timerId: number = null;
	template: any;

	arrayQuiz: any[] = [];
	isResponse: boolean;
	userQuiz: any;
	automatic: boolean;
	node: any;
	currentGraph: any;
	type: any;
	nextNodo: any;
	variablesPublicUtils: any;

	public quizType: number;

	elementsQP: QuizTemplateElement[] = [];
	elementsCorrect: QuizTemplateElement[] = [];
	optionCheckedId: number[] = [];
	// ============================================================
	private timeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public time$: Observable<any> = this.timeSubject.asObservable();
	public questions: any[] = [];
	public options: any[] = [];
	public answers: any[] = [];
	//==============================================================
	//EXTENSIONES DE FICHEROS
	quizElementsPipe: QuizElementsPipe = new QuizElementsPipe();
	@ViewChild(ActividadQuizPlayBodyOptionComponent)
	optionManualComponent: ActividadQuizPlayBodyOptionComponent;

	public filesUploads = {
		answersImagen: "",
		answersDocs: "",
		answersAudio: "",
		answersVideo: "",
		answersText: "",
	};
	public arrayFileView = [];
	public imagenExt: any[] = ["png", "gif", "jpg", "jpeg"];
	public docExt: any[] = ["pdf"];
	public audioExt: any[] = ["mp3"];
	public videoExt: any[] = ["mp4", "mov", "flv", "webm"];
	//==============================================================

	files: File[] = [];

	private openTime: number;
	private questionTypes: QuizElementTypes[] = [
		QuizElementTypes.Q_TEXTS,
		QuizElementTypes.Q_AUDIOS,
		QuizElementTypes.Q_PDFS,
		QuizElementTypes.Q_PICTURES,
		QuizElementTypes.Q_VIDEOS,
	];

	private optionTypes: QuizElementTypes[] = [
		QuizElementTypes.O_TEXTS,
		QuizElementTypes.O_AUDIOS,
		QuizElementTypes.O_PDFS,
		QuizElementTypes.O_PICTURES,
		QuizElementTypes.O_VIDEOS,
	];

	private answerTypes: QuizElementTypes[] = [
		QuizElementTypes.A_TEXTS,
		QuizElementTypes.A_AUDIOS,
		QuizElementTypes.A_PDFS,
		QuizElementTypes.A_PICTURES,
		QuizElementTypes.A_VIDEOS,
	];

	@ViewChild(ActividadQuizPlayBodyOptionComponent)
	optionsComponent: ActividadQuizPlayBodyOptionComponent;
	opcionesMarcadas: any = "";
	textRespuesta: string;
	stopTime: boolean = false;
	constructor(
		public quizService: QuizzesService,
		public loginService: LoginService,
		public modalService: NgbModal,
		public activeModal: NgbActiveModal,
		public targetsService: TargetsService,
		public quizzesStackService: QuizzesstackService,
		private translateService: TranslateService,
		private toaster: ToasterService
	) {}

	ngOnInit() {
		this.quizType = Number(
			this.quiz.isMultiplexed === 1 ? 1 : this.quiz.quizType
		);
		this.openTime = Date.now();
		if (this.elements !== undefined) {
			this.questions = this.elements
				.filter((e) => this.questionTypes.includes(e.elementType))
				.sort((a, b) => a.yPosition - b.yPosition);

			this.options = this.elements
				.filter((e) => this.optionTypes.includes(e.elementType))
				.sort((a, b) => a.yPosition - b.yPosition);

			this.answers = this.elements
				.filter((e) => this.answerTypes.includes(e.elementType))
				.sort((a, b) => a.yPosition - b.yPosition);
		}

		this.showClock(this.quiz);

		this.quizService.checkQuiz.subscribe(() => {
			this.discoverAnswer();
		});

		// let hasVScroll = document.body.scrollHeight > document.body.clientHeight;
	}

	ngOnDestroy() {
		if (this.timerId) {
			clearInterval(this.timerId);
		}
	}

	emitAudioFile($event: File) {
		this.files.push($event);
	}

	private getAnswerFiles(): any {
		return this.files;
	}
	discoverAnswer() {
		if (!this.answered) {
			this.answered = true;
			this.answeredChange.emit(true);
			this.stopTime = true;
			this.isResponse = false;

			if (this.timerId) {
				clearInterval(this.timerId);
			}

			if (
				+this.quiz.quizType === QuizTypes.MULTIPLE ||
				+this.quiz.quizType === QuizTypes.SINGLE
			) {
				const checked = (
					this.optionsComponent.optionForm.getRawValue().options as any[]
				)
					.filter((o) => o.checked)
					.map((o) => o.id)
					.sort((a, b) => a - b);
				const correctAnswers = this.options
					.filter((o) => o.responseCheck)
					.map((o) => o.idQuizzesDataElements)
					.sort((a, b) => a - b);
				// this.opcionesMarcadas = checked;

				for (let index = 0; index < checked.length; index++) {
					const element = checked[index];
					if (index === 0) {
						this.opcionesMarcadas += JSON.stringify(element);
					} else {
						this.opcionesMarcadas += "," + JSON.stringify(element);
					}
				}
				const result =
					JSON.stringify(checked) === JSON.stringify(correctAnswers);

				this.evaluateResponse(result);
			}
		}
	}

	evaluationNegative() {
		this.answeredChange.emit(true);
		this.isResponse = false;
		if (this.timerId) {
			clearInterval(this.timerId);
		}
		this.toaster.success(this.translateService.instant("QUIZZES.MANUALNOK"));
		this.evaluateResponse(false);
	}

	evaluationPositive() {
		this.answeredChange.emit(true);
		this.isResponse = false;
		if (this.timerId) {
			clearInterval(this.timerId);
		}
		this.toaster.success(this.translateService.instant("QUIZZES.MANUALOK"));
		this.evaluateResponse(true);
	}

	evaluateResponse(result: boolean, texto?) {
		this.result.emit(result);
		// this.quizTypes.TEXT === +this.quiz.quizType && !this.modeAuto? this.closeModal(): '';
		this.disabledButton = true;
		const files = this.getAnswerFiles();
		this.quiz.answersText = this.getTextRespuestaEscrita();

		this.quiz.selectedOptions = this.opcionesMarcadas;
		this.quizzesStackService
			.createQuizzesStack(
				this.quiz,
				this.loginService.getUser(),
				this.courseId,
				this.graphId,
				this.openTime,
				result,
				files,
				this.filesUploads
			)
			.subscribe((res) => {
				if (this.quiz.compuCorrect) {
					this.toaster.success(
						this.translateService.instant("QUIZZES.ANSWERSAVED")
					);
				}
				this.colorChange.emit(res.data);

				this.dataResponse = new DataHistoryClass();
				this.dataResponse.identificador = this.quiz.idQuiz;

				if (texto === undefined) {
					this.dataResponse.escrito = result ? "Acertado" : "Fallado";
				} else {
					this.dataResponse.escrito = texto;
				}
				this.dataResponse.titulo = this.quiz.quizTittle;

				this.dataTableResponse.emit(this.dataResponse);
				// this.closeModal();
			});
	}
	getTextRespuestaEscrita() {
		return this.textRespuesta;
	}
	nextQuiz(quiz) {
		/*this.userQuiz.idQuizOriginal=quiz.idQuiz;
        var index = this.arrayQuiz.findIndex(function(el){
              return el.idQuiz == quiz.idQuiz
        });*/

		//Verificar si es el ultimo quiz
		//Invocar al array de nodos ordenado
		//Verificar si el nodo es el ultimo
		//Si no es ultimo sigues al siguiente
		//Si es el ultimo cierra el modal.

		if (
			this.automatic &&
			!this.isLastNode(this.node) &&
			index == this.arrayQuiz.length - 1
		) {
			this.nextNode(this.nextNodo);
		} else {
			this.userQuiz.idQuizOriginal = quiz.idQuiz;
			var index = this.arrayQuiz.findIndex(function (el) {
				return el.idQuiz == quiz.idQuiz;
			});

			if (index != -1 && index < this.arrayQuiz.length - 1) {
				var nexQuiz = this.arrayQuiz[index + 1];
			} else {
				this.closeModal();
			}
		}
	}

	showQuiz(quiz: any) {
		//this.cargando=false;
		this.answered = false;
		this.answeredChange.emit(false);
		//this.isDisabled=(this.isDisabled==true)?false:true;
		this.quiz = quiz;
		this.template = undefined;

		this.elementsQP = [...quiz.template]
			.filter((e) => e.elementsType.idElementType <= 9)
			.map((e) => ({
				idTemplateElement: e.idTemplateElement,
				idTemplate: e.idTemplate,
				elementsType: e.elementsType,
				xPosition: e.xPosition,
				yPosition: e.yPosition,
				xSize: e.xSize,
				ySize: e.ySize,
				style: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].name,
				icon: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].icon,
				data: e.templateElementQuizz[0]
					? e.templateElementQuizz[0].data
					: undefined,
				responseCheck: e.templateElementQuizz[0]
					? e.templateElementQuizz[0].responseCheck
					: undefined,
			}));

		this.elementsCorrect = [...quiz.template]
			.filter((e) => e.elementsType.idElementType > 9)
			.map((e) => ({
				idTemplateElement: e.idTemplateElement,
				idTemplate: e.idTemplate,
				elementsType: e.elementsType,
				xPosition: e.xPosition,
				yPosition: e.yPosition,
				xSize: e.xSize,
				ySize: e.ySize,
				style: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].name,
				icon: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].icon,
				data: e.templateElementQuizz[0]
					? e.templateElementQuizz[0].data
					: undefined,
			}));

		this.template = {
			idTemplate: this.elementsQP[0]
				? this.elementsQP[0].idTemplate
				: undefined,
			idQuiz: quiz.template.idQuiz,
			templateTittle: quiz.quizTittle,
			quizInstructions: quiz.quizInstructions,
			writable: quiz.writable,
			compuCorrect: quiz.compuCorrect,
			multiplexed: quiz.multiplexed,
			elementsQP: this.elementsQP,
			elementsCorrect: this.elementsCorrect,
		};

		this.showClock(this.quiz);

		if (!this.template) {
			if (this.timerId) {
				clearInterval(this.timerId);
			}

			// var lienzo = this.get2dCanvas(this.clockCanvas.nativeElement);
			// if (lienzo) {
			//     lienzo.clearRect(0, 0, 300, 150);
			// }
		}
	}

	private showClock(quiz: QuizModel) {
		if (quiz && quiz.duration) {
			if (this.timerId) {
				clearInterval(this.timerId);
			}
			this.iniBlock = false;
			let date = new Date();
			date.setSeconds(date.getSeconds() + quiz.duration);
			this.timeSubject.next({ time: this.time, max: this.countSeconds });
			// this.timerId = countdown(date, timeStamp => {
			//     this.time = timeStamp;
			//     this.timeSubject.next({ time: this.time, max: this.countSeconds })

			//     if (!this.iniBlock) {
			//         this.iniBlock = true;
			//         this.countSeconds = this.time.seconds + this.time.minutes * 60;
			//     }

			//     if (this.time.minutes === 0 && this.time.seconds === 0) {
			//         clearInterval(this.timerId);
			//         this.discoverAnswer();
			//     }

			// }, countdown.HOURS | countdown.MINUTES | countdown.SECONDS)
		}
	}

	onTimeEnded(event) {
		clearInterval(this.timerId);
		this.discoverAnswer();
	}

	// private getAnswerFiles(): any {
	//     let temp = this.elementsQP.filter(e => TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].isOption && e.file).map(e => e.file);
	//     return temp
	// }

	nextNode(nextNode) {
		const modalRef = this.modalService.open(ModalReproducirNodoComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W65,
		});
		modalRef.componentInstance.node = nextNode;
		modalRef.componentInstance.currentGraph = this.currentGraph;
		modalRef.componentInstance.type = "node";
		modalRef.result.then(
			(result) => {
			},
			(reason) => {}
		);
	}

	isLastNode(node) {
		var nodes = this.currentGraph.nodes.filter(
			(node) => node.nodeType == "Node"
		);
		var array = nodes.sort(function (a, b) {
			return a["idOriginal"] - b["idOriginal"];
		});

		var n = array[array.length - 1];
		if (n.idOriginal == node.idOriginal) {
			return true;
		} else {
			var pos = 0;
			for (n of array) {
				pos = pos + 1;
				if (n.idOriginal == node.idOriginal) {
					this.nextNodo = array[pos];
					return false;
				}
			}
		}
	}

	calculateAchieveKnowledge(quiz: any) {}

	closeModal(sendData?: any) {
		this.activeModal.close();
	}

	onQuizPlayElementChecked(value: boolean, idTemplateElement: number) {
		if (value) {
			this.optionCheckedId = [idTemplateElement, ...this.optionCheckedId];
		} else {
			this.optionCheckedId = [
				...this.optionCheckedId.filter((e) => e !== idTemplateElement),
			];
		}
	}

	onQuizPlayElementFileUploaded(element, eventFile) {
		element.data = URL.createObjectURL(eventFile.target.files[0]);
		element.file = eventFile.target.files[0];
	}

	onQuizPlayElementSetDescription(element: QuizTemplateElement) {
		// const modalRef = this.modalService.open(QuizEditTemplateTextEditorComponent,
		//     {
		//         scrollable: true,
		//         windowClass: MODAL_DIALOG_TYPES.W95,
		//         backdrop: 'static'
		//     }
		// );
		// modalRef.componentInstance.text = element.data;
		// modalRef.result.then(
		//     (result) => {
		//         element.data = result;
		//         element.file = result;
		//     },
		//     (reason) => {
		//     });
	}

	resumeInterval() {
		let date = new Date();
		date.setSeconds(
			date.getSeconds() + this.time.seconds + this.time.minutes * 60
		);

		this.timerId = countdown(
			date,
			(timeStamp) => {
				this.time = timeStamp;

				this.timeSubject.next({ time: this.time, max: this.countSeconds });

				if (!this.iniBlock) {
					this.iniBlock = true;
					this.countSeconds = this.time.seconds + this.time.minutes * 60;
				}

				if (this.time.minutes === 0 && this.time.seconds === 0) {
					clearInterval(this.timerId);
					this.answered = true;
					this.answeredChange.emit(true);
					this.discoverAnswer();
				}
			},
			countdown.HOURS | countdown.MINUTES | countdown.SECONDS
		);
	}

	saveTxt(text) {
		this.filesUploads.answersText = text;
	}

	public saveOptionMultiple(option: any) {
		option.index =
			this.quiz.idMultiplexQuiz === 1 ? option.index : option.index;
		const element: QuizElement = this.elementToQuizElement(option.element);
		this.optionManualComponent.isLoading = true;
		this.quizzesStackService
			.anserwQuizElement(this.quiz.idOriginal, element, option.file)
			.subscribe(
				(res) => {
					const extResFile = this.getFileExtension(res.data);
					const i = this.imagenExt.filter((data) => data == extResFile);
					let url = `${environment.quizzesContent}`;

					if (i.length > 0) {
						if (this.filesUploads.answersImagen === "") {
							this.filesUploads.answersImagen = res.data;
							this.optionManualComponent.arrayFileView[option.index].url =
								this.optionManualComponent.arrayFileView[option.index].url +
								res.data;
							this.optionManualComponent.arrayFileView[option.index].view =
								true;
						} else {
							if (this.optionManualComponent.arrayFileView[option.index].view) {
								this.replaceValue(
									this.filesUploads.answersImagen,
									res.data,
									"picture/",
									option.index,
									url
								);
							} else {
								this.filesUploads.answersImagen = this.addValueToArray(
									this.filesUploads.answersImagen,
									res.data,
									option.index,
									url
								);
							}
						}
					} else {
						const i = this.docExt.filter((data) => data == extResFile);
						if (i.length > 0) {
							if (this.filesUploads.answersDocs === "") {
								this.filesUploads.answersDocs = res.data;
								this.optionManualComponent.arrayFileView[
									this.quiz.idMultiplexQuiz === 1
										? option.index + 1
										: option.index
								].url =
									this.optionManualComponent.arrayFileView[option.index].url +
									res.data;
								this.optionManualComponent.arrayFileView[
									this.quiz.idMultiplexQuiz === 1
										? option.index + 1
										: option.index
								].view = true;
							} else {
								if (
									this.optionManualComponent.arrayFileView[option.index].view
								) {
									this.replaceValue(
										this.filesUploads.answersDocs,
										res.data,
										"pdf/",
										option.index,
										url
									);
								} else {
									this.filesUploads.answersDocs = this.addValueToArray(
										this.filesUploads.answersDocs,
										res.data,
										option.index,
										url
									);
								}
							}
						} else {
							const i = this.audioExt.filter((data) => data == extResFile);
							if (i.length > 0) {
								if (this.filesUploads.answersAudio === "") {
									this.filesUploads.answersAudio = res.data;
									this.optionManualComponent.arrayFileView[option.index].url =
										this.optionManualComponent.arrayFileView[option.index].url +
										res.data;
									this.optionManualComponent.arrayFileView[option.index].view =
										true;
								} else {
									if (
										this.optionManualComponent.arrayFileView[option.index].view
									) {
										this.replaceValue(
											this.filesUploads.answersAudio,
											res.data,
											"audio/",
											option.index,
											url
										);
									} else {
										this.filesUploads.answersAudio = this.addValueToArray(
											this.filesUploads.answersAudio,
											res.data,
											option.index,
											url
										);
									}
								}
							} else {
								const i = this.videoExt.filter((data) => data == extResFile);
								if (i.length > 0) {
									if (this.filesUploads.answersVideo === "") {
										this.filesUploads.answersVideo = res.data;
										this.optionManualComponent.arrayFileView[option.index].url =
											this.optionManualComponent.arrayFileView[option.index]
												.url + res.data;
										this.optionManualComponent.arrayFileView[
											option.index
										].view = true;
									} else {
										if (
											this.optionManualComponent.arrayFileView[option.index]
												.view
										) {
											this.replaceValue(
												this.filesUploads.answersVideo,
												res.data,
												"video/",
												option.index,
												url
											);
										} else {
											this.filesUploads.answersVideo = this.addValueToArray(
												this.filesUploads.answersVideo,
												res.data,
												option.index,
												url
											);
										}
									}
								}
							}
						}
					}
					this.optionManualComponent.isLoading = false;
				},
				(err) => console.error(err)
			);
	}

	public onSaveOption(option: any) {
		option.index =
			this.quiz.idMultiplexQuiz === 1 ? option.index : option.index;
		const element: QuizElement = this.elementToQuizElement(option.element);
		// this.optionManualComponent.isLoading = true;
		// this.isLoading = true;
		this.files.push(option.file);
		this.optionManualComponent.arrayFileView[option.index].url =
			option.element.data;
		this.optionManualComponent.arrayFileView[option.index].view = true;
		// this.quizzesStackService.anserwQuizElement(this.quiz.idOriginal, element, option.file).subscribe(
		//     res => {
		//         const extResFile = this.getFileExtension(res.data);
		//         const i = this.imagenExt.filter(data => data == extResFile);
		//         let url = `${environment.localUrl}${environment.quizzesContent}`;

		//         if (i.length > 0) {
		//             if (this.filesUploads.answersImagen === '') {
		//                 this.filesUploads.answersImagen = res.data;
		//                 this.optionManualComponent.arrayFileView[option.index].url =  this.optionManualComponent.arrayFileView[option.index].url+res.data;
		//                 this.optionManualComponent.arrayFileView[option.index].view = true;
		//             }
		//             else {
		//                 if(this.optionManualComponent.arrayFileView[option.index].view){
		//                     this.replaceValue(this.filesUploads.answersImagen, res.data, 'picture/', option.index, url);
		//                 }
		//                 else {
		//                     this.filesUploads.answersImagen = this.addValueToArray( this.filesUploads.answersImagen, res.data, option.index, url)
		//                 }
		//             }
		//         }
		//         else{
		//             const i = this.docExt.filter(data => data == extResFile);
		//             if (i.length > 0)  {
		//                 if (this.filesUploads.answersDocs === '') {
		//                     this.filesUploads.answersDocs = res.data;
		//                      if(this.quiz.idMultiplexQuiz === 1) {
		//                         this.optionManualComponent.arrayFileView[option.index].url =  url+'pdf/'+res.data;
		//                         this.optionManualComponent.arrayFileView[option.index].view = true;
		//                      }
		//                      else {
		//                         this.optionManualComponent.arrayFileView[option.index].url = this.optionManualComponent.arrayFileView[option.index].url+res.data;
		//                      }

		//                 }
		//                 else {
		//                     if(this.optionManualComponent.arrayFileView[option.index].view){
		//                         this.replaceValue(this.filesUploads.answersDocs, res.data, 'pdf/', option.index, url);
		//                     }
		//                     else {
		//                         this.filesUploads.answersDocs = this.addValueToArray( this.filesUploads.answersDocs, res.data, option.index, url)

		//                     }
		//                 }
		//             }
		//             else{
		//                 const i = this.audioExt.filter(data => data == extResFile);
		//                 if (i.length > 0)  {
		//                     if (this.filesUploads.answersAudio === '') {
		//                         this.filesUploads.answersAudio = res.data;
		//                         this.optionManualComponent.arrayFileView[option.index].url =  this.optionManualComponent.arrayFileView[option.index].url+res.data;
		//                         this.optionManualComponent.arrayFileView[option.index].view = true;

		//                     }
		//                     else {
		//                         if(this.optionManualComponent.arrayFileView[option.index].view){
		//                             this.replaceValue(this.filesUploads.answersAudio, res.data, 'audio/', option.index, url);
		//                         }
		//                         else {
		//                             this.filesUploads.answersAudio = this.addValueToArray( this.filesUploads.answersAudio, res.data, option.index, url)
		//                         }

		//                     }
		//                 }
		//                 else {
		//                     const i = this.videoExt.filter(data => data == extResFile);
		//                     if (i.length > 0)  {
		//                         if (this.filesUploads.answersVideo === '') {
		//                             this.filesUploads.answersVideo = res.data;
		//                             this.optionManualComponent.arrayFileView[option.index].url =  this.optionManualComponent.arrayFileView[option.index].url+res.data;
		//                             this.optionManualComponent.arrayFileView[option.index].view = true;

		//                         }
		//                         else {
		//                             if(this.optionManualComponent.arrayFileView[option.index].view){
		//                                 this.replaceValue(this.filesUploads.answersVideo, res.data, 'video/', option.index, url);
		//                             }
		//                             else {
		//                                 this.filesUploads.answersVideo = this.addValueToArray( this.filesUploads.answersVideo, res.data, option.index, url)
		//                             }

		//                         }
		//                     }
		//                 }
		//             }
		//         }
		//         this.optionManualComponent.isLoading = false;

		//     },
		//     err => console.error(err)
		// );
	}
	addValueToArray(
		answersImagen: string,
		data: any,
		index: any,
		url: string
	): string {
		answersImagen = answersImagen + "," + data;
		this.optionManualComponent.arrayFileView[index].url =
			this.optionManualComponent.arrayFileView[index].url + data;
		this.optionManualComponent.arrayFileView[index].view = true;
		return answersImagen;
	}
	replaceValue(
		answersArrays: string,
		data: any,
		type: string,
		index: number,
		url: string
	) {
		let arrayDeCadenas = answersArrays.split(",");
		arrayDeCadenas[arrayDeCadenas.length - 1] = data;
		this.optionManualComponent.arrayFileView[index].url = "";
		this.optionManualComponent.arrayFileView[index].url = url + type + data;
		if (type === "picture/") {
			this.filesUploads.answersImagen = "";
			this.filesUploads.answersImagen = arrayDeCadenas.toString();
		} else if (type === "video/") {
			this.filesUploads.answersVideo = "";
			this.filesUploads.answersVideo = arrayDeCadenas.toString();
		} else if (type === "audio/") {
			this.filesUploads.answersAudio = "";
			this.filesUploads.answersAudio = arrayDeCadenas.toString();
		} else if (type === "pdf/") {
			this.filesUploads.answersDocs = "";
			this.filesUploads.answersDocs = arrayDeCadenas.toString();
		}
	}

	//Obtener extencion del archivo
	getFileExtension(filename) {
		return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
	}

	private elementToQuizElement(element: any): QuizElement {
		const quizElement: QuizElement = {
			idQuizzesDataElements: element.id,
			data: element.data,
			idQuiz: this.quiz.idOriginal,
			elementType: element.type,
			responseCheck: +element.isCorrect,
			xPosition: 0,
			xSize: 0,
			yPosition: element.order,
			ySize: 0,
		};

		return quizElement;
	}

	successOrNotSuccessful(succes: boolean) {
		this.answeredChange.emit(succes);
		this.isResponse = false;
		if (this.timerId) {
			clearInterval(this.timerId);
		}

		succes
			? this.toaster.success(this.translateService.instant("QUIZZES.MANUALOK"))
			: this.toaster.success(
					this.translateService.instant("QUIZZES.MANUALNOK")
			  );
		this.evaluateResponse(succes);
	}

	public get quizTypes() {
		return QuizTypes;
	}

	emitText(event: string) {
		this.textRespuesta = event;
	}
}
