import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalAsistenciaEstudianteComponent } from './modal-asistencia-estudiante/modal-asistencia-estudiante.component';

declare var $: any;

@Component({
  selector: 'app-modal-asistencia-historial',
  templateUrl: './modal-asistencia-historial.component.html',
  styleUrls: ['./modal-asistencia-historial.component.scss']
})
export class ModalAsistenciaHistorialComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$ = new Subject();
	lang: string = 'es';
  cargando: boolean;
	tablaCompleta: any[] = [];
	cabecera: any[] = [];
  historialAsistencia: any[] = [];
	idGrupo: number;
  dataTable: any;
	currentWeek: number = 0;
	verTabla: boolean = true;

  fechaInicio: string = '';
  fechaFin: string = '';
	fechaDiaSiguiente: string = '';

  constructor(
		public activeModal: NgbActiveModal,
		public translateService: TranslateService,
		private groupService: GruposService,
		private modalService: NgbModal,
		) {}

  ngOnInit() {
		//con esto evitamos el error de jquery en la tabla
		if(!JSON.parse(localStorage.getItem("noConflict"))){
		localStorage.setItem("noConflict", "false");
		}
		// asignamos los arrays correspondientes para la cabecera y el cuerpo de la tabla
		this.cabecera = this.tablaCompleta.slice(0, 1);
		this.historialAsistencia = this.tablaCompleta.slice(1);
		this.fechaInicio = this.historialAsistencia[0][2][0].fecha.substring(0, 10);
    this.fechaFin = this.historialAsistencia[0][this.historialAsistencia[0].length - 1][0].fecha.substring(0, 10);
		let fecha = new Date(this.fechaFin);
		fecha.setDate(fecha.getDate() + 1);
		this.fechaDiaSiguiente = fecha.toISOString().substring(0, 10);
		/*
		const today = new Date();
    this.fechaInicio = this.formatDate(today);
    this.fechaFin = this.formatDate(today);
    this.filtrarPorFecha(this.historialAsistencia);
		*/
		this.lang = JSON.parse(localStorage.getItem("language"));
	}

  ngAfterViewInit() {
		if(!JSON.parse(localStorage.getItem("noConflict"))){
			$.noConflict();
			localStorage.setItem("noConflict", "true");
		}
		/*
    this.dataTable = $('#myTable').DataTable({
			layout: {
        topStart: {
            buttons: ['excel', 'pdf', 'print']
        }
    },
			pageLength: 50,
			language: {
        "sLengthMenu": this.translateService.instant('ASISTENCIA.LENGTHMENU'),
        "sZeroRecords": this.translateService.instant('ASISTENCIA.ZERORECORDS'),
        "sEmptyTable": this.translateService.instant('ASISTENCIA.EMPTYTABLE'),
        "sInfo": "",
        "sInfoEmpty": "",
        "sInfoFiltered": "",
        "sInfoPostFix": "",
        "sSearch": this.translateService.instant('ASISTENCIA.SEARCH'),
        "sUrl": "",
        "sInfoThousands": ",",
        "sLoadingRecords": this.translateService.instant('ASISTENCIA.LOADINGRECORDS'),
        "oPaginate": {
            "sFirst": this.translateService.instant('ASISTENCIA.FIRST'),
            "sLast": this.translateService.instant('ASISTENCIA.LAST'),
            "sNext": this.translateService.instant('ASISTENCIA.NEXT'),
            "sPrevious": this.translateService.instant('ASISTENCIA.PREVIOUS')
        },
        "oAria": {
            "sSortAscending": this.translateService.instant('ASISTENCIA.SORTASCENDING'),
            "sSortDescending": this.translateService.instant('ASISTENCIA.SORTDESCENDING'),
        }
      }
		});
		*/
		this.actualizarTabla();
  }

  ngOnDestroy() {
    // Limpia y destruye la tabla DataTable al salir del componente
    if (this.dataTable) {
      this.dataTable.destroy();
    }
  }

  closeModal(sendData?: any) {
		if (this.dataTable) {
      this.dataTable.destroy();
    }
    this.destroy$.next(true);
    this.activeModal.close(sendData);
  }

	formatDate(date: Date): string {
    // Formatear la fecha a YYYY-MM-DD
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

	filtrarPorFecha(data: any[]): any[] {
    if (!this.fechaInicio || !this.fechaDiaSiguiente) {
      return data;
    }
    return data.filter(item => {
      const fechaItem = new Date(item[4]);
      const fechaInicio = new Date(this.fechaInicio);
      const fechaFin = new Date(this.fechaDiaSiguiente);
      return fechaItem >= fechaInicio && fechaItem <= fechaFin;
    });
  }

	semanaAnterior(){
		if (this.dataTable) {
      this.dataTable.destroy();
    }
		this.verTabla = false;
		this.tablaCompleta = [];
		this.cabecera = [];
		this.historialAsistencia = [];
		this.currentWeek++;
		this.groupService.classAttendanceGroup(this.idGrupo, this.currentWeek).subscribe(
			resp => {
				this.tablaCompleta = resp.data;
				this.cabecera = this.tablaCompleta.slice(0, 1);
				this.historialAsistencia = this.tablaCompleta.slice(1);
				this.fechaInicio = this.historialAsistencia[0][2][0].fecha.substring(0, 10);
				this.fechaFin = this.historialAsistencia[0][this.historialAsistencia[0].length - 1][0].fecha.substring(0, 10);
				let fecha = new Date(this.fechaFin);
				fecha.setDate(fecha.getDate() + 1);
				this.fechaDiaSiguiente = fecha.toISOString().substring(0, 10);
				this.actualizarTabla();
			})
			setTimeout(() => {
				this.verTabla = true;
			}, 100)
	}

	semanaSiguiente(){
		if (this.dataTable) {
      this.dataTable.destroy();
    }
		this.verTabla = false;
		this.tablaCompleta = [];
		this.cabecera = [];
		this.historialAsistencia = [];
		this.currentWeek--;
		this.groupService.classAttendanceGroup(this.idGrupo, this.currentWeek).subscribe(
			resp => {
				this.tablaCompleta = resp.data;
				this.cabecera = this.tablaCompleta.slice(0, 1);
				this.historialAsistencia = this.tablaCompleta.slice(1);
				this.fechaInicio = this.historialAsistencia[0][2][0].fecha.substring(0, 10);
				this.fechaFin = this.historialAsistencia[0][this.historialAsistencia[0].length - 1][0].fecha.substring(0, 10);
				let fecha = new Date(this.fechaFin);
				fecha.setDate(fecha.getDate() + 1);
				this.fechaDiaSiguiente = fecha.toISOString().substring(0, 10);
				this.actualizarTabla();
			})
		setTimeout(() => {
			this.verTabla = true;
		}, 100)
	}

	semanaActual(){
		this.verTabla = false;
		this.tablaCompleta = [];
		this.cabecera = [];
		this.historialAsistencia = [];
		if (this.dataTable) {
      this.dataTable.destroy();
    }
		this.currentWeek = 0;
		this.groupService.classAttendanceGroup(this.idGrupo, 0).subscribe(
			resp => {
				this.tablaCompleta = resp.data;
				this.cabecera = this.tablaCompleta.slice(0, 1);
				this.historialAsistencia = this.tablaCompleta.slice(1);
				this.fechaInicio = this.historialAsistencia[0][2][0].fecha.substring(0, 10);
				this.fechaFin = this.historialAsistencia[0][this.historialAsistencia[0].length - 1][0].fecha.substring(0, 10);
				let fecha = new Date(this.fechaFin);
				fecha.setDate(fecha.getDate() + 1);
				this.fechaDiaSiguiente = fecha.toISOString().substring(0, 10);
				this.actualizarTabla();
			})
		setTimeout(() => {
			this.verTabla = true;
		}, 100)
	}

	actualizarTabla() {
		if (this.dataTable) {
			setTimeout(() => {
				// Vuelve a crear la tabla con los datos actualizados
				this.dataTable = $('#myTable').DataTable({
					layout: {
						topStart: {
								buttons: ['excel', 'pdf', 'print']
						}
				},
					pageLength: 100,
					searching: false, // Desactiva la barra de búsqueda
					paging: false, // Desactiva la paginación
					language: {
						"sLengthMenu": this.translateService.instant('ASISTENCIA.LENGTHMENU'),
						"sZeroRecords": this.translateService.instant('ASISTENCIA.ZERORECORDS'),
						"sEmptyTable": this.translateService.instant('ASISTENCIA.EMPTYTABLE'),
						"sInfo": "",
						"sInfoEmpty": "",
						"sInfoFiltered": "",
						"sInfoPostFix": "",
						"sSearch": this.translateService.instant('ASISTENCIA.SEARCH'),
						"sUrl": "",
						"sInfoThousands": ",",
						"sLoadingRecords": this.translateService.instant('ASISTENCIA.LOADINGRECORDS'),
						"oPaginate": {
								"sFirst": this.translateService.instant('ASISTENCIA.FIRST'),
								"sLast": this.translateService.instant('ASISTENCIA.LAST'),
								"sNext": this.translateService.instant('ASISTENCIA.NEXT'),
								"sPrevious": this.translateService.instant('ASISTENCIA.PREVIOUS')
						},
						"oAria": {
								"sSortAscending": this.translateService.instant('ASISTENCIA.SORTASCENDING'),
								"sSortDescending": this.translateService.instant('ASISTENCIA.SORTDESCENDING'),
						}
					}
				});
			}, 100);
		} else{
			setTimeout(() => {
				// Vuelve a crear la tabla con los datos actualizados
				this.dataTable = $('#myTable').DataTable({
					layout: {
						topStart: {
								buttons: ['excel', 'pdf', 'print']
						}
				},
					pageLength: 100,
					searching: false, // Desactiva la barra de búsqueda
					paging: false, // Desactiva la paginación
					language: {
						"sLengthMenu": this.translateService.instant('ASISTENCIA.LENGTHMENU'),
						"sZeroRecords": this.translateService.instant('ASISTENCIA.ZERORECORDS'),
						"sEmptyTable": this.translateService.instant('ASISTENCIA.EMPTYTABLE'),
						"sInfo": "",
						"sInfoEmpty": "",
						"sInfoFiltered": "",
						"sInfoPostFix": "",
						"sSearch": this.translateService.instant('ASISTENCIA.SEARCH'),
						"sUrl": "",
						"sInfoThousands": ",",
						"sLoadingRecords": this.translateService.instant('ASISTENCIA.LOADINGRECORDS'),
						"oPaginate": {
								"sFirst": this.translateService.instant('ASISTENCIA.FIRST'),
								"sLast": this.translateService.instant('ASISTENCIA.LAST'),
								"sNext": this.translateService.instant('ASISTENCIA.NEXT'),
								"sPrevious": this.translateService.instant('ASISTENCIA.PREVIOUS')
						},
						"oAria": {
								"sSortAscending": this.translateService.instant('ASISTENCIA.SORTASCENDING'),
								"sSortDescending": this.translateService.instant('ASISTENCIA.SORTDESCENDING'),
						}
					}
				});
			 }, 100)

		}
	}

	getClassForCell(value: string): string {
		switch (value) {
			case '3':
				return 'bg-success';
			case '4':
				return 'bg-warning';
			case '2':
				return 'bg-danger';
			case '1':
				return 'bg-danger';
			default:
				return '';
		}
	}

	asistenciaEstudiante(idUser: number, name: string, status: number, fecha: string, motivo: string, dato: any[]){
		const modalRef = this.modalService.open(ModalAsistenciaEstudianteComponent, {
			scrollable: true,
			windowClass: `${MODAL_DIALOG_TYPES.W30} h-100`,
		})
		modalRef.componentInstance.idUser = idUser;
		modalRef.componentInstance.idGrupo = this.idGrupo;
		modalRef.componentInstance.name = name;
		modalRef.componentInstance.fecha = fecha;
		modalRef.componentInstance.status = status;
		modalRef.componentInstance.motivo = motivo;
		modalRef.componentInstance.screen = 1;
		modalRef.componentInstance.dato = dato;
		modalRef.result.then((result: boolean) => {
			this.semanaActual();
		});
	}

	estadisticasEstudiante(idUser: number, name: string){
		this.groupService.summary(this.idGrupo, idUser, this.fechaInicio, this.fechaDiaSiguiente).subscribe(
			res => {
				if(res){
					const modalRef = this.modalService.open(ModalAsistenciaEstudianteComponent, {
						scrollable: true,
						windowClass: `${MODAL_DIALOG_TYPES.W30} h-100`,
					})
					modalRef.componentInstance.idUser = idUser;
					modalRef.componentInstance.idGrupo = this.idGrupo;
					modalRef.componentInstance.name = name;
					modalRef.componentInstance.screen = 2;
					modalRef.componentInstance.fechaInicio = this.fechaInicio;
					modalRef.componentInstance.fechaFin = this.fechaDiaSiguiente;
					modalRef.componentInstance.summary = res.data;
					modalRef.result.then((result: boolean) => {
						this.semanaActual();
					});
				}
			}
		)
	}

	convertDateToDDMMM(dateString: string): string {
    let months: string[] = [];
    switch (this.lang) {
        case 'es':
            months = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];
            break;
        case 'en':
            months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
            break;
        case 'de':
            months = ["jan", "feb", "mär", "apr", "mai", "jun", "jul", "aug", "sep", "okt", "nov", "dez"];
            break;
        case 'pt':
            months = ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"];
            break;
        case 'it':
            months = ["gen", "feb", "mar", "apr", "mag", "giu", "lug", "ago", "set", "ott", "nov", "dic"];
            break;
        case 'fr':
            months = ["jan", "fév", "mar", "avr", "mai", "jun", "jul", "aoû", "sep", "oct", "nov", "déc"];
            break;
        default:
            throw new Error(`Unsupported language: ${this.lang}`);
    }
    const date = new Date(dateString);
		date.setDate(date.getDate() + 1);
    const day = date.getDate();
    const month = months[date.getMonth()];
    return `${day}${month}`;
	}

}
