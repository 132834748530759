
<table class="table  table-responsive ">
	<thead >
		<tr  class="bg-light header-table mr-2">


			<th  scope="col" *ngFor="let head of headTable; let i = index">

					<div class="data-text">
						<div class="d-flex justify-content-center align-items-center" *ngIf="i === 0" >
							<mat-icon svgIcon="AnadirActividad" ></mat-icon><span class="separador text-primary">/</span><mat-icon svgIcon="AnadirActividadesMultiples" ></mat-icon>
						</div>
						<ng-template #FullNameTooltip>
							{{head.name}} <br>
							{{head.surname}} <br>
						</ng-template>
						<div class="d-flex justify-content-center align-items-center " [ngbTooltip]="FullNameTooltip" placement="bottom">
							<div class="avatar text-center  mb-2" *ngIf="i !== 0" [ngStyle]="{'background-image': getUserAvatar(head.image)}"></div>
						</div>
						<div >
							<p class="text-center mb-0">{{head.name}}</p>
							<!-- <p class="text-center">{{head.surname}}</p> -->
						</div>
						<!-- <div >

						</div> -->
					</div>


			</th>

		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let filas of bodyTable; let i = index" >
			<th *ngFor="let data of filas.row" scope="row"
			[ngStyle]="{'color':'black','background-color' : data.color !== null && i ? data.color: '#ffff','text-align':'center', 'font-weight':'500'}"
			[ngClass]="!i ? 'empty' : (data.isChecked ? 'isChecked' : 'isNotChecked')"
			>
			<div class="data-text">
				<div class="d-flex justify-content-center align-items-center" *ngIf="data.title !== null" >
					<span class="text-title"  ngbTooltip="{{data.title}}" placement="right">
						<div class="" *ngIf="data.title == '% de acierto' else defaultTitle">
						 <span>% Aciertos <mat-icon svgIcon="IconoTickRevision2" ></mat-icon></span>
						</div>

						<ng-template #defaultTitle>
						 {{data.title | titlecase }}
						</ng-template>

				 </span>
					<!-- <div *ngIf="i">
						<mat-icon *ngIf="data.isMultiplex === 0" svgIcon="AnadirActividad" ></mat-icon><mat-icon *ngIf="data.isMultiplex === 1" svgIcon="AnadirActividadesMultiples" ></mat-icon>
					</div> -->

				</div>

			</div>
			<div class="nota-media" *ngIf="!i">
				<span>{{data.texto}}</span>
			</div>
			<div  *ngIf="data.texto  !== null && i" class="data-nota">
				<div *ngIf="!data.isChecked"  class="d-flex justify-content-center align-items-center pointer">
						<mat-icon svgIcon="inte" (click)="viewQuiz(data)"></mat-icon>
				</div>
				<div *ngIf="data.isChecked">
					<a class="text-dark font-weight-bold pointer-event" (click)="viewQuiz(data)"  >
						<div class="d-flex align-items-center justify-content-center" *ngIf="challengeOn">
							<mat-icon [svgIcon]="data.isChallengeResult === 2 ? 'IconoTickRevision2' : 'IconoXRevision'" class="mr-1" *ngIf="data.isChallengeResult !== 0"></mat-icon>
							<span>{{data.texto}}</span>
						</div>
						<div class="d-flex align-items-center justify-content-center" *ngIf="!challengeOn">
							<mat-icon [svgIcon]="data.iconResult ? 'IconoTickRevision2' : 'IconoXRevision'" class="mr-1"></mat-icon>
							<span>{{data.texto}}</span>
						</div>

					</a>
				</div>
			</div>


			</th>
		</tr>

	</tbody>
</table>
