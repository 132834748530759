import { SocketService } from './socket/socket-service.service';
import { ImagenPipe } from './../../shared/pipes/imagen.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// Services
import { AlertService, TitleService, DomainRoutingService } from 'src/app/core/services/shared';
import {
    UsersRegisterMapperService, UsersStudentsParentsMapperService,
    UsersAuthorsMapperService, UsersParentsMapperService, UsersCentersMapperService,
    UsersEmployeersMapperService, UsersProfileValidationMapperService, UsersSettingsProfileMapperService,
    CentersMapperService
} from 'src/app/core/services/mapper';
import { LoginService } from 'src/app/core/services/login';

// Utils
import { DomainRouting } from 'src/app/core/utils';
import { RegisterService } from './register/register.service';
import { ToasterService } from './shared/toaster.service';

@NgModule({ declarations: [], imports: [CommonModule], providers: [
        { provide: DomainRouting, useClass: DomainRoutingService },
        AlertService,
        TitleService,
        LoginService,
        CentersMapperService,
        UsersRegisterMapperService,
        UsersAuthorsMapperService,
        UsersParentsMapperService,
        UsersCentersMapperService,
        UsersEmployeersMapperService,
        UsersStudentsParentsMapperService,
        UsersSettingsProfileMapperService,
        UsersProfileValidationMapperService,
        RegisterService,
        ToasterService,
        ImagenPipe,
        SocketService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreServiceModule { }
