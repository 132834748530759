import { Component, OnInit } from '@angular/core';

// Services
import { TranslateService } from '@ngx-translate/core';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';

// Utils
import { LocalStorage } from 'src/app/core/utils';

declare function 	init_plugins()

@Component({
  selector: 'app-no-page-found',
  templateUrl: './no-page-found.component.html',
  styleUrls: ['./no-page-found.component.scss']
})
export class NoPageFoundComponent implements OnInit {

  public constructor(public translateService: TranslateService,
                     private localStorage: LocalStorage) {
  }

  public ngOnInit() {

    const lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
    this.translateService.use(lang);

		init_plugins()
  }
}
