<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="d-flex flex-column gap-1">
		<div class="row">
			<div class="col-12">
				<h5>
					Preview version Beta asistente IA Salware
				</h5>
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="d-flex flex-column align-items-center justify-content-center">

			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="chat-container">
			<div class="messages">
					<div *ngFor="let message of messages" [ngClass]="{'user-message': message.sender === 'user', 'chatgpt-message': message.sender === 'chatgpt'}">
							{{ message.text }}
					</div>
			</div>
			<div *ngIf="loading" class="spinner-container">
					<div class="spinner"></div>
			</div>
			<div class="input-container">
					<input [(ngModel)]="userInput" (keyup.enter)="sendMessage()" placeholder="Escribe tu mensaje..." [disabled]="loading">
					<button (click)="sendMessage()" [disabled]="loading">Enviar</button>
			</div>
	</div>
	</nb-card-body>

</nb-card>
