
<nb-card>
	<nb-card-header>Seleccione una opción</nb-card-header>
	<nb-card-body>
		<div style="display: flex; flex-direction: column; gap: 10px;">
			<button nbButton status="primary" size="large" (click)="closeModal('single')">Crear idea suelta</button>
			<button nbButton status="primary" size="large" (click)="closeModal('all')">

				Crear todas las ideas IA <nb-icon icon="loader-outline" ></nb-icon>
			</button>
		</div>

	</nb-card-body>
	<nb-card-footer>

		<button nbButton status="basic" (click)="closeModal()" >Cancelar</button>
	</nb-card-footer>
</nb-card>
