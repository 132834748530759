<nb-card class="no-edge-card">
	<nb-card-header class="flex border-bottom-0 position-relative">
		<div class="col-12 col-sm-10 col-md-10 text-center text-sm-left text-md-left">
			<h5>{{ "USERDATA.CONTACTTITLE" | translate }}</h5>
		</div>
		<div class="closeModal">
			<nb-icon class="m-1" icon="close-circle"
				[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
			</nb-icon>
		</div>
	</nb-card-header>
	<nb-card-body>
		<div class="row d-flex justify-content-center cards-container">
			<div class="col-12 col-md-12">
				<form
					[formGroup]="sendCommentsForm"
					(ngSubmit)="sendComments()"
					#thisForm="ngForm"
				>
					<div class="row d-flex justify-content-center align-items-center p-2">
						<div class="col-12 col-md-12">
							<div class="col-12 col-md-12 form-group" *ngIf="false">
								<label for="name" class="label">{{
									"USERDATA.NOMBRE" | translate
								}}</label>
								<input
									type="text"
									nbInput
									fullWidth
									status="basic"
									placeholder="{{ 'USERDATA.NOMBRE' | translate }}"
									name="name"
									formControlName="name"
									readonly
								/>
								<div *ngFor="let validation of validationMessages.name">
									<mat-error
										*ngIf="
											sendCommentsForm.get('name')?.hasError(validation.type) &&
											(sendCommentsForm.get('name')?.dirty ||
												sendCommentsForm.get('name')?.touched ||
												thisForm.submitted)
										"
									>
										<div class="error-message">{{ validation.message }}</div>
									</mat-error>
								</div>
							</div>

							<div class="col-12 col-md-12 form-group" *ngIf="false">
								<label for="email" class="label">{{
									"USERDATA.EMAIL" | translate
								}}</label>
								<input
									type="email"
									nbInput
									fullWidth
									status="basic"
									placeholder="{{ 'USERDATA.EMAIL' | translate }}"
									name="email"
									formControlName="email"
									readonly
								/>
								<div *ngFor="let validation of validationMessages.email">
									<mat-error
										*ngIf="
											sendCommentsForm
												.get('email')
												?.hasError(validation.type) &&
											(sendCommentsForm.get('email')?.dirty ||
												sendCommentsForm.get('email')?.touched ||
												thisForm.submitted)
										"
									>
										<div class="error-message">{{ validation.message }}</div>
									</mat-error>
								</div>
							</div>

							<div class="col-12 col-md-12 flex" *ngIf="false">
								<div class="col-sm-6 pl-0">
									<label for="extension" class="label">{{
										"LOGIN.PREFIX" | translate
									}}</label>
									<nb-select
										fullWidth
										name="extension"
										placeholder="{{ 'LOGIN.PREFIX' | translate }}"
										formControlName="extension"
									>
										<nb-option
											*ngFor="let prefix of $phonePrefixes | async"
											value="{{ prefix.dial_code }}"
											>{{ prefix.name_en }}
											<span
												><strong> {{ prefix.dial_code }}</strong>
											</span></nb-option
										>
									</nb-select>
									<!-- <div *ngFor="let validation of validationMessages.prefix" class="mt-2">
											<div class="error-message"
												*ngIf="prefixControl?.hasError(validation.type) && (prefixControl?.dirty || prefixControl?.touched || thisForm.submitted)">
												{{validation.message}}</div>
										</div> -->
								</div>

								<div class="col-sm-6 pr-0" *ngIf="false">
									<label for="phone" class="label">{{
										"USERDATA.TELEFONOMOVIL" | translate
									}}</label>
									<input
										type="text"
										nbInput
										fullWidth
										status="basic"
										placeholder="{{ 'USERDATA.TELEFONOMOVIL' | translate }}"
										name="phone"
										formControlName="phone"
										readonly
									/>
									<!--	<div *ngFor="let validation of validationMessages.phone">
											<mat-error
												*ngIf="sendCommentsForm.get('phone')?.hasError(validation.type) && (sendCommentsForm.get('phone')?.dirty || sendCommentsForm.get('phone')?.touched || thisForm.submitted)">
												<div class="error-message">{{validation.message}}</div>
											</mat-error>
										</div> -->
								</div>
							</div>

							<div class="col-12 col-md-12">
								<div class="form-group">
									<label for="comentary">{{
										"USERDATA.COMMENTARY" | translate
									}}</label>
									<textarea
										nbInput
										fullWidth
										formControlName="comentary"
										class="form-control"
										id="comentary"
										rows="3"
									></textarea>
								</div>
							</div>

							<div class="row justify-content-between">
								<div class="col-12 col-md-12">
									<label for="name" class="label label-contact">
										{{ "USERDATA.ORCONTACTWHITUS" | translate }} <br />
										{{ "USERDATA.PHONE" | translate }}:
										<a href="tel:+34623569819">+34 623569819</a>
									</label>
								</div>

								<div class="col-12 col-md-12 d-flex justify-content-end">
									<button nbButton class="btn-themecolor" type="submit">
										<span>{{ "USERDATA.SEND" | translate }}</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</nb-card-body>
</nb-card>
