import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { LoginService } from 'src/app/core/services/login';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';

@Component({
  selector: 'app-alert-darkProject',
  templateUrl: './alert-darkProject.component.html',
  styleUrls: ['./alert-darkProject.component.scss']
})
export class AlertDarkProjectComponent implements OnInit {

	constructor(
		public activeModal: NgbActiveModal,
		public translateService: TranslateService,
		public loginService: LoginService,
	){}

	ngOnInit() {}

	closeModal(sendData?: any) {
    this.activeModal.close(false);
  }

	asignar(){
		this.activeModal.close("add");
	}

	ver(){
		this.activeModal.close("open");
	}

}
