import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SurveysService } from 'src/app/core/services/surveys/surveys.service';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { EncuestasEditComponent } from '../encuestas-edit/encuestas-edit.component';
import { MatomoAnalyticsUtils } from 'src/app/core/utils/matomo-analytics.utils';
import { SurveyModel } from 'src/app/core/models/surveys/survey.model';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
  selector: 'app-encuestas-listado',
  templateUrl: './encuestas-listado.component.html',
  styleUrls: ['./encuestas-listado.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class EncuestasListadoComponent implements OnInit {

	viewList: boolean = false;
	cargando = false;

	surveysList: any[] = [];

	constructor(
		public activeModal: NgbActiveModal,
		public surveysService: SurveysService,
		private modalService: NgbModal,
		private ma: MatomoAnalyticsUtils,
		public translateService: TranslateService,
		private toaster: ToasterService,
	){}

	ngOnInit(): void{
		this.surveysService.getAllSurveys().subscribe(res => {
			this.surveysList = res.data;
		});
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	openSurvey(survey: any){
		this.ma.event("click", "view_item", "Curso");
		const modalRef = this.modalService.open(EncuestasEditComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
		modalRef.componentInstance.id = survey.idEncuestas;
		modalRef.componentInstance.survey = survey;
		modalRef.result.then(
			(resp) => {
				this.surveysService.getAllSurveys().subscribe(res => {
					this.surveysList = res.data;
				});
			},
			(err) => {}
		);
	}

	newSurvey(){
		let survey: SurveyModel = new SurveyModel(
			0,
			this.translateService.instant("ENCUESTAS.NEWTITLE"),
			this.translateService.instant("ENCUESTAS.NEWDESCRIPTION"),
			38,
			null,
		);
		this.surveysService.createUpdateSurvey(survey).subscribe(res => {
			if(res.data){
				this.toaster.success(this.translateService.instant('ENCUESTAS.CREATEOK'));
				this.ma.event("click", "view_item", "Curso");
				const modalRef = this.modalService.open(EncuestasEditComponent, {
					scrollable: false,
					windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
				});
				modalRef.componentInstance.id = res.data.idEncuestas;
				modalRef.componentInstance.survey = res.data;
				modalRef.result.then(
					(resp) => {
						this.surveysService.getAllSurveys().subscribe(res => {
							this.surveysList = res.data;
						});
					},
					(err) => {}
				);
			} else{
				this.toaster.error(this.translateService.instant('ENCUESTAS.CREATEKO'));
			}
		});
	}

}
