<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="border-bottom-0 pb-0">
		<div class="d-flex flex-column gap-2 modal-title">
			<h2>{{ "ASISTENCIA.HISTORIAL" | translate }}</h2>
			<!-- Nombre del grupo -->
			<!-- <div class="d-flex flex-column align-items-center justify-content-center gap-2">
				<h4>{{ "COMPETITION.ACTUALGROUP" | translate }}: </h4>
			</div> -->
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left"
			[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"></nb-icon>
	</div>
	<nb-card-body>
		<app-loading [isVisible]="cargando"></app-loading>
		<div class="d-flex flex-column gap-2">
			<!-- Opciones del header -->
			<div class="d-flex flex-column flex-md-row gap-3 align-items-center justify-content-between">
				<!-- Rango de fecha -->
				<div class="d-flex align-items-center justify-content-center">
					<span><strong>Desde:</strong> {{convertDateToDDMMM(fechaInicio)}} <strong>Hasta:</strong> {{convertDateToDDMMM(fechaFin)}}</span>
				</div>
				<!-- Navegacion -->
				<div class="btn-group gap-3">
					<div class="btn pointer" (click)="semanaAnterior()">
						<nb-icon icon="arrow-back-outline" nbTooltip="{{ 'ASISTENCIA.VERANTERIOR' | translate }}"
							nbTooltipPlacement="top">
						</nb-icon>
					</div>
					<div class="btn pointer" (click)="semanaActual()">
						<nb-icon icon="home-outline" nbTooltip="{{ 'CENTROS.TODAY' | translate }}"
							nbTooltipPlacement="top">
						</nb-icon>
					</div>
					<div class="btn pointer" (click)="semanaSiguiente()">
						<nb-icon icon="arrow-forward-outline" nbTooltip="{{ 'ASISTENCIA.VERSIGUIENTE' | translate }}"
							nbTooltipPlacement="top">
						</nb-icon>
					</div>
				</div>
				<!-- Botones de descarga -->
				<div class="botones-descarga"></div>
			</div>
			<!-- Tabla de asistencia -->
			<div class="table-asistencia" #tableContainer>
				<ng-container *ngIf="verTabla">
					<table id="myTable" class="table table-striped">
						<thead>
							<tr *ngFor="let cab of cabecera">
								<th>{{ "FORM.NAMEPH" | translate }}</th>
								<ng-container *ngFor="let dato of cab; let i = index">
									<th *ngIf="i > 0">{{ formatFechaCabecera(dato) }}</th>
								</ng-container>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let asistencia of historialAsistencia">
								<td>
									<nb-icon class="pointer" icon="eye-outline"
										nbTooltip="{{ 'CURSOMOTIVACIONES.DETALLES' | translate }}" nbTooltipPlacement="right"
										(click)="estadisticasEstudiante(asistencia[0], asistencia[1])">
									</nb-icon>
									<p class="m-0 style-text-name">
										{{ asistencia[1] }}
									</p>
								</td>
								<ng-container *ngFor="let dato of asistencia; let i = index">
									<td *ngIf="i > 1"
										[ngClass]="{'bg-success': dato[0].status == '3', 'bg-warning': dato[0].status == '4', 'bg-danger': dato[0].status == '2', 'danger2': dato[0].status == '1', 'bg-info': dato[0].status == '5'}">
										<nb-icon *ngIf="dato[0].status != '0'" class="icon-asistenciaEstudiante" icon="eye-outline"
											nbTooltip="Ver la cantidad de asistencias del estudiante en el día" nbTooltipPlacement="right"
											(click)="asistenciaEstudiante(asistencia[0], asistencia[1], dato[0].status, dato[0].fecha, dato[0].motivo, dato)">
										</nb-icon>
										<p class="m-0 style-text-asistencia">{{ adjustToUserTime(dato[0].texto) }}</p>
									</td>
								</ng-container>
							</tr>
						</tbody>
					</table>
				</ng-container>
			</div>
		</div>
	</nb-card-body>
</nb-card>
