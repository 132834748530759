<div>
	<form [formGroup]="form">
		<nb-card class="project-info pointer">
			<app-loading [isVisible]="isLoading"></app-loading>
			<nb-card-body>
				<app-alert context="UserData"></app-alert>
				<nb-card class="no-edge-card mb-0">
					<nb-card-header>
						<div class="flex">
							<div class="col-8">
								<h6>{{ 'QUIZZES.CRITERIAFORIACORRECTION' | translate }}</h6>
							</div>
							<div class="col-2">
								<button nbButton class="btn-themecolor" (click)="descargarPDF()"><span>{{ 'QUIZZES.AYUDA' | translate }}</span></button>
							</div>
							<div class="col-2">
								<nb-icon class="icon-color-blue closebtn pointer text-right m-1 float-right" icon="close-circle-outline"
									[options]="{ animation: { type: 'pulse' } }" (click)="closeModal()"></nb-icon>
							</div>
						</div>
					</nb-card-header>
					<nb-card-body>
						<div class="col-12">
							<div class="row">
								<textarea rows="12" nbInput fullWidth placeholder="Textarea" name="promptText"
									formControlName="promptText" required></textarea>
							</div>
						</div>
					</nb-card-body>
				</nb-card>
			</nb-card-body>
		</nb-card>
	</form>
</div>
