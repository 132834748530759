<div class="modal-body fondoEditarCurso" align="center">
    <form name="form" #dataForm="ngForm" [formGroup]="form" class="form-horizontal">
        <div *ngIf="progress" class="plantilla col-lg-11 col-md-11 mt-3">
            <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0"
                 aria-valuemax="100">100%</div>
        </div>
        <div *ngIf="!progress" class="row">
            <div *ngIf="!elements" class="plantilla col-lg-11 col-md-11 mt-3">
                <span
                      class="textoFontSize1 colorTextoActivado font-weight-bold">{{'QUIZZES.MENSAJEINICIAL' | translate }}</span>
            </div>
            <div *ngIf="elements" class="plantilla col-lg-11 col-md-11">
                <div class="col-12 col-sm-4 col-md-4 col-lg-4 mx-auto">
                    <input *ngIf="template && !template.tittle" type="text" class="form-control fondoBlanco"
                           id="templateTittle" value="{{template.templateTittle}}"
                           placeholder="{{ 'PATTERNS.TITTLETEMPLATE' | translate }}" formControlName="templateTittle">

                    <input *ngIf="template && template.tittle" type="text" class="form-control fondoBlanco"
                           id="templateTittle" value="{{template.templateTittle}}"
                           placeholder="{{ 'PATTERNS.TITTLETEMPLATE' | translate }}" [disabled]="template.tittle"
                           formControlName="templateTittle">
                </div>
                <div class="col-12 col-sm-4 col-md-4 col-lg-4 mx-auto mt-2">
                    <textarea *ngIf="template && template.quizInstructions" class="form-control fondoBlanco"
                              id="quizInstructions" rows="3" value="{{template.quizInstructions}}"
                              placeholder="{{ 'PATTERNS.MESSAGE' | translate }}"
                              formControlName="quizInstructions"></textarea>

                    <textarea *ngIf="template && !template.quizInstructions" class="form-control fondoBlanco"
                              id="quizInstructions" rows="3" value="{{template.quizInstructions}}"
                              placeholder="{{ 'PATTERNS.MESSAGE' | translate }}"
                              [disabled]="!template.quizInstructions"></textarea>
                </div>
                <div style="position: relative;" class="container p-0 mt-2" id="boundary">
                    <div *ngFor="let element of elements" style="position: absolute; z-index: 10;"
                         [style.top.px]="element.yPosition" [style.left.px]="element.xPosition"
                         [style.width.px]="element.xSize" [style.height.px]="element.ySize"
                         class="rectangle element-box {{element.style}}"
                         [ngStyle]="{'background-size' : 'cover',
                         'opacity': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'0.5':'1'}" id="#{{element.idTemplateElement}}">
                        <select *ngIf="(element.elementType > 4  && element.elementType < 10)"
                                attr.id="Select{{element.idTemplateElement}}" class="form-select result form-select-sm"
                                aria-label=".form-select-sm example" (change)="onChange($event, element)">
                            <option disabled>This option is: </option>
                            <option value="true">Correct</option>
                            <option value="false">Wrong</option>
                        </select>
                        <input
                               *ngIf="(0<=element.elementType  && element.elementType<4) || (5<=element.elementType  && element.elementType<9) || (10<=element.elementType  && element.elementType<14)"
                               class="input-text" id="{{element.idTemplateElement}}" type="file"
                               (change)="cargarRecurso($event,element)" />
                        <label for="{{element.idTemplateElement}}">
                            <mat-icon *ngIf="element.elementType == 0" aria-hidden="false" placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.VIDEO' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="element.elementType == 1" aria-hidden="false" placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.IMAGE' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="element.elementType == 2" aria-hidden="false" placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.AUDIO' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="element.elementType == 3" aria-hidden="false" placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.PDF' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="element.elementType == 4" aria-hidden="false" placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.TEXT' | translate }}" id="more" data-toggle="modal"
                                      data-target="#descModal" (click)="getDescription(element)">{{element.icon}}
                            </mat-icon>
                            <div *ngIf="element.elementType == 5" style="position: relative;">
                                <mat-icon aria-hidden="false" placement="top"
                                          [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                          ngbTooltip="{{'QUIZFORMCOMPONENT.VIDEO' | translate }}">{{element.icon}}
                                </mat-icon>
                            </div>
                            <mat-icon *ngIf="element.elementType == 6" aria-hidden="false" placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.IMAGE' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="element.elementType == 7" aria-hidden="false" placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.AUDIO' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="element.elementType == 8" aria-hidden="false" placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.PDF' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="(element.elementType == 9 && template.writable==1)" aria-hidden="false"
                                      placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.TEXT' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="(element.elementType == 9 && template.writable==0)" aria-hidden="false"
                                      placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.TEXT' | translate }}" id="more" data-toggle="modal"
                                      data-target="#descModal" (click)="getDescription(element)">{{element.icon}}
                            </mat-icon>

                            <mat-icon *ngIf="element.elementType == 10" aria-hidden="false" placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.VIDEO' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="element.elementType == 11" aria-hidden="false" placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.IMAGE' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="element.elementType == 12" aria-hidden="false" placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.AUDIO' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="element.elementType == 13" aria-hidden="false" placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.PDF' | translate }}">{{element.icon}}
                            </mat-icon>
                            <mat-icon *ngIf="element.elementType == 14" aria-hidden="false" placement="top"
                                      [ngStyle]="{'cursor': ((5<=element.elementType && element.elementType<=9) && template.writable==1)?'':'pointer'}"
                                      ngbTooltip="{{'QUIZFORMCOMPONENT.TEXT' | translate }}" id="more" data-toggle="modal"
                                      data-target="#descModal" (click)="getDescription(element)">{{element.icon}}
                            </mat-icon>
                        </label>
                    </div>
                </div>
                <div class="modal fade w-50 max-vh-20" data-keyboard="false" data-backdrop="static" id="descModal"
                     tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" cdkDrag>
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                {{ 'QUIZZES.QUESTIONTEXT' | translate }}
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                        (click)="closeModalText()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body well">
                                <div class="row p-1 m-1">
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                                        <label>{{'QUIZZES.ESTILOFUENTE'|translate }}</label>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                                        <select class="col-lg-12 col-12" matNativeControl
                                                (change)="showFondStyle($event)">
                                            <option value="0">{{'QUIZZES.SELECCIONAOPCION'|translate}}</option>
                                            <option *ngFor="let fontStyle of fontStyles" [value]="fontStyle">
                                                {{fontStyle}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                                        <label>{{'QUIZZES.TAMANOFUENTE'|translate}}</label>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                                        <select class="col-lg-12 col-12" matNativeControl
                                                (change)="showFontSize($event)">
                                            <option value="0">{{'QUIZZES.SELECCIONAOPCION'|translate}}</option>
                                            <option *ngFor="let fontSize of fontSizes" [value]="fontSize">{{fontSize}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <textarea rows="5" class="form-control" #desc value="{{description}}"
                                          [ngStyle]="{'font-style':fontStyle, 'font-size': fontSize}"></textarea>
                                <div class="text-right well">
                                    <!--button mat-flat-button color="primary" class="mt-1 col-sm-3" data-dismiss="modal" type="submit"
                                     (click)="saveQuestion(desc.value)">{{ 'QUIZZES.SAVE' | translate }}</button-->
                                    <!--button mat-flat-button color="primary" class="mt-1 col-sm-3" data-dismiss="modal"
                                        type="submit">{{ 'QUIZZES.SAVE' | translate }}</button-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
