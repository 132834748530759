import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-report-button',
  templateUrl: './report-button.component.html',
  styleUrls: ['./report-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
