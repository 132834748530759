<div style="position: relative;" class="d-flex h-100 w-100 flex-column justify-content-center align-items-center">
	<div class="icono-back pointer" (click)="backStep()">
		<nb-icon icon="undo-outline" *ngIf="step > 1" [options]="{ animation: { type: 'zoom' } }">
		</nb-icon>
		<p *ngIf="step > 1">Volver a {{title1}}</p>
	</div>
	<!-- GRAFICA -->
	<!-- <h4 *ngIf="parametroID == 0">{{ labelsImported[0].label}}</h4> -->
  <canvas id="myChart2"></canvas>
</div>
