import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { PrefixCountry } from 'src/app/core/models/shared/prefixCountry.model';
import { LoginService } from 'src/app/core/services/login';
import { HttpClient } from '@angular/common/http';
import { ImagenPipe } from '../../pipes/imagen.pipe';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

const NOIMAGE = "../../../../../assets/images/icons/account_circle.svg"

@Component({
	selector: 'user-card-info.component',
	templateUrl: './user-card-info.component.html',
	styleUrls: ['./user-card-info.component.scss']
})
export class UserCardInfoComponent implements OnInit {

	user: any;
	sendCommentsForm: UntypedFormGroup;
	private _maxLength: number = 15
	$phonePrefixes: Observable<PrefixCountry[]>
	validationMessages = {
    comentary: []
  };


	constructor(
		public loginService: LoginService,
		private formBuild: UntypedFormBuilder,
		public translateService: TranslateService,
		private http: HttpClient,
		private toaster: ToasterService,
		private imagePipe: ImagenPipe,
		private dialog: MatDialog,
		) {
		this.sendCommentsForm = this.formBuild.group({
			comentary: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(500)]]
		});
	}

	ngOnInit() {
	}

	closeModal(sendData?: any) {
		this.dialog.closeAll()
	}

	getUserAvatar(imagen):string{
    let image:string = ''
    imagen!== null && imagen !== ""? image = this.imagePipe.transform(imagen, 'userAvatar') : image = NOIMAGE
    return image
  }

	sendFormData(data):Observable<any> {
		return this.http.post<any>("user/supportEmail/userDirectEmail",data)
	}

	showStatus() {
	}

	sendComments() {
		if (this.sendCommentsForm.valid) {
			let data = this.sendCommentsForm.value
			data.email = this.user.correo;
			this.sendFormData(data).subscribe(res => {
				this.toaster.success(this.translateService.instant(res.error.msg));
				this.closeModal(true)
			})
		}
	}


}
