<nb-card class="no-edge-card mb-0 background_themecolor modal-height-100-62">
	<nb-card-header class="px-3 py-0 flex flex-column border-bottom-0">
		<!-- Titulo de la pantalla -->
		<div class="row p-4 background_white">
			<div class="col-12 col-md-7 col-lg-8 modal-title px-2">
				<h4>{{'ENCUESTAS.TITLE' | translate}}</h4>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<!-- VISTA TARJETAS -->
		<div *ngIf="!viewList" class="row background_white d-flex justify-content-center py-4 px-2 min-h-100">
			<div class="d-flex justify-content-center w-100">
				<div class="col-12">
					<app-loading [isVisible]="cargando"></app-loading>
					<div class="d-flex w-100">
						<div class="d-flex w-100">
							<div *ngIf="surveysList.length !== 0" class="d-flex flex-wrap justify-content-center w-100 gap-4">
								<!--ENCUESTAS CREADAS-->
								<div *ngFor="let survey of surveysList; let i = index" class="card-surveys">
									<nb-card class="pointer mb-0 surveys-info" (click)="openSurvey(survey)" nbTooltip="{{ survey.title }}">
										<nb-card-header class="border-bottom-0 p-0 header-select">
											<div class="d-flex justify-content-center align-items-center h-100">
												<img [src]="survey.image | imagen:'encuestas'" class="img-fluid" (error)="updateUrl($event)" *ngIf="networkSpeed >= 1">
												<img [src]="'../../assets/images/no-image.png'" class="img-fluid" *ngIf="networkSpeed < 1">
											</div>
										</nb-card-header>
										<nb-card-body class="p-2">
											<div class="d-flex flex-column justify-content-center align-items-center">
												<h6 class="text-center surveys-title">
													{{ survey.title }}
												</h6>
												<p class="m-0 surveys-descriptions text-center">
													{{ survey.description }}
												</p>
											</div>
										</nb-card-body>
										<nb-card-footer class="border-top-0 text-center pt-0">
											<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;"
												[text]="survey.questions + ' Preguntas'">
											</nb-tag>
										</nb-card-footer>
									</nb-card>
								</div>
								<!--NUEVA ENCUESTA-->
								<nb-card class="pointer mb-0 new-survey" (click)="newSurvey()" nbTooltip="{{ 'ENCUESTAS.NEWTITLE' | translate }}">
									<nb-card-body>
										<div class="d-flex justify-content-center align-items-center h-100">
											<nb-icon style="font-size: xxx-large;" class="icon-new" icon="plus-circle-outline"></nb-icon>
										</div>
									</nb-card-body>
								</nb-card>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- VISTA MODO LISTA -->
		<!-- <div *ngIf="viewList" class="row min-h-100 gap-4">

		</div> -->
	</nb-card-body>
</nb-card>
