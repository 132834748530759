<div class='fondoEditarCurso mt-2'>

    <div class="col-12">
        <app-alert context="UserData"></app-alert>
    </div>

    <div class="row pb-5">
        <div *ngIf="verPestanaDatos" (click)="activarPestana('Datos')" class="col-3" align="center">
            <div [ngClass]="pestanaDatosActiva ? 'opcion-activa' : 'opcion-no-activa'">
                <span class="textoFontSize1" style="top: 5px; position: relative;">{{ 'USERDATA.PESTANADATOS' | translate }}</span>
            </div>
        </div>

        <div *ngIf="verPestanaPadres" (click)="activarPestana('Padres')" class="col-3" align="center">
            <div [ngClass]="pestanaPadresActiva ? 'opcion-activa' : 'opcion-no-activa'">
                <span class="textoFontSize1" style="top: 5px; position: relative;">{{ 'USERDATA.PESTANAPADRES' | translate }}</span>
            </div>
        </div>

        <div *ngIf="verPestanaHijos" (click)="activarPestana('Hijos')" class="col-3" align="center">
            <div [ngClass]="pestanaHijosActiva ? 'opcion-activa' : 'opcion-no-activa'">
                <span class="textoFontSize1" style="top: 5px; position: relative;">{{ 'USERDATA.PESTANAHIJOS' | translate }}</span>
            </div>
        </div>

        <div *ngIf="verPestanaCentros" (click)="activarPestana('Centros')" class="col-3" align="center">
            <div [ngClass]="pestanaCentrosActiva ? 'opcion-activa' : 'opcion-no-activa'">
                <span class="textoFontSize1" style="top: 5px; position: relative;">{{ 'USERDATA.PESTANACENTROS' | translate }}</span>
            </div>
        </div>

        <div *ngIf="verPestanaProfesores" (click)="activarPestana('Profesores')" class="col-3" align="center">
            <div [ngClass]="pestanaProfesoresActiva ? 'opcion-activa' : 'opcion-no-activa'">
                <span class="textoFontSize1" style="top: 5px; position: relative;">{{ 'USERDATA.PESTANAPROFESORES' | translate }}</span>
            </div>
        </div>
    </div>

    <form *ngIf="verPestanaDatos && pestanaDatosActiva" [formGroup]="formularioDatosGenerales" (ngSubmit)="grabarDatosGenerales()" #thisForm="ngForm" class="pb-2">

        <div class="row p-4">

            <div class="col-sm-3" align="center">

                <div class="row">

                    <div class="card form-group">
                        <img *ngIf="userData != undefined" (click)="cambiarFoto()" [src]="userData.pictureUser | imagen:'users'" class="card-img-top img-circle">
                    </div>
                </div>
            </div>

            <div class="col-sm-9">

                <div class="row">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">account_circle</i>
                    </div>

                    <div class="col-5">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.NOMBRE' | translate }}</mat-label>
                            <input matInput formControlName="firstName" required>
                            <mat-error *ngFor="let validation of validationMessages.firstName">
                                <mat-error class="error-message" *ngIf="formularioDatosGenerales.get('firstName')?.hasError(validation.type) && (formularioDatosGenerales.get('firstName')?.dirty || formularioDatosGenerales.get('firstName')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-5">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.APELLIDOS' | translate }}</mat-label>
                            <input matInput formControlName="surname" required>
                            <mat-error *ngFor="let validation of validationMessages.surname">
                                <mat-error class="error-message" *ngIf="formularioDatosGenerales.get('surname')?.hasError(validation.type) && (formularioDatosGenerales.get('surname')?.dirty || formularioDatosGenerales.get('surname')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">mail_outline</i>
                    </div>

                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.EMAIL' | translate }}</mat-label>
                            <input matInput formControlName="mail">
                            <mat-error *ngFor="let validation of validationMessages.mail">
                                <mat-error class="error-message" *ngIf="formularioDatosGenerales.get('mail')?.hasError(validation.type) && (formularioDatosGenerales.get('mail')?.dirty || formularioDatosGenerales.get('mail')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">cake</i>
                    </div>

                    <div class="col-10">
                        <mat-form-field *ngIf="loginService.esEstudiante()" class="full-width">
                            <mat-label>{{ 'USERDATA.FECHANACIMIENTO' | translate }}</mat-label>

                            <input type="date" matInput formControlName="birthdate" required>

                            <mat-error *ngFor="let validation of validationMessages.birthdate">
                                <mat-error class="error-message" *ngIf="formularioDatosGenerales.get('birthdate')?.hasError(validation.type) && (formularioDatosGenerales.get('birthdate')?.dirty || formularioDatosGenerales.get('birthdate')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="!loginService.esEstudiante()" class="full-width">
                            <mat-label>{{ 'USERDATA.FECHANACIMIENTO' | translate }}</mat-label>

                            <input type="date" matInput formControlName="birthdate">

                            <mat-error *ngFor="let validation of validationMessages.birthdate">
                                <mat-error class="error-message" *ngIf="formularioDatosGenerales.get('birthdate')?.hasError(validation.type) && (formularioDatosGenerales.get('birthdate')?.dirty || formularioDatosGenerales.get('birthdate')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">phone</i>
                    </div>

                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.TELEFONOMOVIL' | translate }}</mat-label>
                            <input matInput formControlName="mobile" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">wc</i>
                    </div>

                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.GENERO' | translate }}</mat-label>
                            <mat-select formControlName="idGenre" placeholder="{{ 'USERDATA.GENERO' | translate }}">
                                <mat-option *ngFor="let genre of genres" [value]="genre.idGenre">
                                    {{genre.genreName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="loginService.esAutor()">
                    <div class="row">

                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">account_balance</i>
                        </div>

                        <div class="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.BICSWIFT' | translate }}</mat-label>
                                <input matInput formControlName="bic" mask="AAAAAAAAAAA">
                                <mat-error *ngFor="let validation of validationMessages.bic">
                                    <mat-error class="error-message break-line" *ngIf="formularioDatosGenerales.get('bic')?.hasError(validation.type) && (formularioDatosGenerales.get('bic')?.dirty || formularioDatosGenerales.get('bic')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group pt-1">

                        <div class="col-2" align="center"></div>

                        <div class="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.IBAN' | translate }}</mat-label>
                                <input matInput formControlName="iban" mask="AA00 0000 0000 00 0000000000">
                                <mat-error *ngFor="let validation of validationMessages.iban">
                                    <mat-error class="error-message break-line" *ngIf="formularioDatosGenerales.get('iban')?.hasError(validation.type) && (formularioDatosGenerales.get('iban')?.dirty || formularioDatosGenerales.get('iban')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div *ngIf="loginService.esPadre()">
                    <div class="row">

                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">credit_card</i>
                        </div>

                        <div class="col-4">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.TARJETACREDITO' | translate }}</mat-label>
                                <input matInput formControlName="creditCard" mask="0000 0000 0000 0000">
                                <mat-error *ngFor="let validation of validationMessages.creditCard">
                                    <mat-error class="error-message break-line" *ngIf="formularioDatosGenerales.get('creditCard')?.hasError(validation.type) && (formularioDatosGenerales.get('creditCard')?.dirty || formularioDatosGenerales.get('creditCard')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-3">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.CADUCIDADMMDD' | translate }}</mat-label>
                                <input matInput formControlName="expirationDateCard" mask="00/00">
                                <mat-error *ngFor="let validation of validationMessages.expirationDateCard">
                                    <mat-error class="error-message break-line" *ngIf="formularioDatosGenerales.get('expirationDateCard')?.hasError(validation.type) && (formularioDatosGenerales.get('expirationDateCard')?.dirty || formularioDatosGenerales.get('expirationDateCard')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-3">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.CVV' | translate }}</mat-label>
                                <input matInput formControlName="cvv" mask="000">
                                <mat-error *ngFor="let validation of validationMessages.cvv">
                                    <mat-error class="error-message break-line" *ngIf="formularioDatosGenerales.get('cvv')?.hasError(validation.type) && (formularioDatosGenerales.get('cvv')?.dirty || formularioDatosGenerales.get('cvv')?.touched || thisForm.submitted)">{{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div *ngIf="loginService.esCentro()">
                    <div class="row">

                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">add_business</i>
                        </div>

                        <div class="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.CENTRO' | translate }}</mat-label>
                                <mat-select formControlName="idCenter" placeholder="{{ 'USERDATA.CENTRO' | translate }}">
                                    <mat-option *ngFor="let center of centers" [value]="center.idCenter">
                                        [{{center.centerCountry}}]-{{center.centerName}}
                                    </mat-option>
                                </mat-select>

                            </mat-form-field>
														<div class="form-field full-width">
															<ng-select formControlName="idCenter" placeholder="{{ 'USERDATA.CENTRO' | translate }}">
																<ng-option *ngFor="let center of centers" [value]="center.idCenter">
																	[{{center.centerCountry}}]-{{center.centerName}}</ng-option>
														 </ng-select>
														</div>

                        </div>
                    </div>
                </div>

                <div *ngIf="loginService.esEmpleador()">
                    <div class="row">

                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">location_city</i>
                        </div>

                        <div class="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.EMPRESA' | translate }}</mat-label>
                                <mat-select formControlName="idEnterprise" placeholder="{{ 'USERDATA.EMPRESA' | translate }}">
                                    <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.idEnterprise">
                                        [{{enterprise.country}}]-{{enterprise.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-9">
                    </div>

                    <div class="col-3" align="right">
                        <button class="botonLoginEntrar" type="submit">
                            <span>{{ 'USERDATA.GRABAR' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <form *ngIf="verPestanaPadres && pestanaPadresActiva" [formGroup]="formularioDatosPadreEstudiante" (ngSubmit)="grabarDatosPadreUsuario()" #thisFormPadres="ngForm">

        <div class="row py-4">

            <div class="col-md-3" align="center">

                <div *ngIf="parentsData.length > 0">
                    <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'USERDATA.PADRES' | translate }}</span>

                    <mat-list class="mt-2 caja-blanca">
                        <mat-list-item *ngFor="let parent of parentsData" (click)="cargarDatosPadre(parent.idUserStudentParent)">
                            <i *ngIf="parent.validated" class="material-icons pr-2" title="{{ 'USERDATA.HAVALIDADOTUACCESO' | translate }}">check_circle_outline</i> {{ parent.firstName }} {{ parent.surname }}
                        </mat-list-item>
                    </mat-list>

                    <button (click)="cargarDatosPadre()" class="botonLoginEntrar my-4" type="button">
                        <span>{{ 'USERDATA.REGISTRARPADRE' | translate }}</span>
                    </button>
                </div>

                <span *ngIf="parentsData.length === 0" class="textoFontSize09 colorTextoActivado font-weight-bold"> {{ 'USERDATA.NOHAYNINGUNPADREREGISTRADO' | translate }}</span>
            </div>

            <div class="col-md-9">

                <span class="textoFontSize09 colorTextoActivado"></span>

                <div class="row form-group">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">account_circle</i>
                    </div>

                    <div class="col-5">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.NOMBRE' | translate }}</mat-label>
                            <input matInput formControlName="firstName" required>
                            <mat-error *ngFor="let validation of validationMessages.firstName">
                                <mat-error class="error-message" *ngIf="formularioDatosPadreEstudiante.get('firstName')?.hasError(validation.type) && (formularioDatosPadreEstudiante.get('firstName')?.dirty || formularioDatosGenerales.get('firstName')?.touched || thisFormPadres.submitted)">{{validation.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-5">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.APELLIDOS' | translate }}</mat-label>
                            <input matInput formControlName="surname" required>
                            <mat-error *ngFor="let validation of validationMessages.surname">
                                <mat-error class="error-message" *ngIf="formularioDatosPadreEstudiante.get('surname')?.hasError(validation.type) && (formularioDatosPadreEstudiante.get('surname')?.dirty || formularioDatosGenerales.get('surname')?.touched || thisFormPadres.submitted)">{{validation.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row form-group">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">mail_outline</i>
                    </div>

                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.EMAIL' | translate }}</mat-label>
                            <input matInput formControlName="mail">
                            <mat-error *ngFor="let validation of validationMessages.mail">
                                <mat-error class="error-message" *ngIf="formularioDatosPadreEstudiante.get('mail')?.hasError(validation.type) && (formularioDatosPadreEstudiante.get('mail')?.dirty || formularioDatosGenerales.get('mail')?.touched || thisFormPadres.submitted)">{{validation.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">cake</i>
                    </div>

                    <div clasS="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.FECHANACIMIENTO' | translate }}</mat-label>

                            <input type="date" matInput formControlName="birthdate">

                            <mat-error *ngFor="let validation of validationMessages.birthdate">
                                <mat-error class="error-message" *ngIf="formularioDatosGenerales.get('birthdate')?.hasError(validation.type) && (formularioDatosGenerales.get('birthdate')?.dirty || formularioDatosGenerales.get('birthdate')?.touched || thisFormPadres.submitted)">{{validation.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row form-group">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">phone</i>
                    </div>

                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.TELEFONOMOVIL' | translate }}</mat-label>
                            <input matInput formControlName="mobile">
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="parentSelected" class="row form-group mt-2">

                    <div class="col-2"></div>

                    <div class="col-10 mt-2">
                        <span class="textoFontSize1 colorTextoActivado">{{ mensajeEnvioValidacion }}</span>
                    </div>

                    <div class="col-2"></div>

                    <div class="col-10 mt-2">
                        <span class="textoFontSize1 colorTextoActivado">{{ mensajeEnvioValidado }}</span>
                    </div>
                </div>

                <div class="row form-group mt-2">

                    <div class="col-md-4 mt-2">
                        <button *ngIf="parentSelected && !parentValidated" class="botonLoginRegistrar" type="button" (click)="eliminarPadre()">
                                <span>{{ 'USERDATA.ELIMINAR' | translate }}</span>
                            </button>
                    </div>

                    <div class="col-md-4 mt-2">
                        <button *ngIf="parentSelected && !parentValidated" class="botonLoginRegistrar" type="button" (click)="reenviarAviso()">
                            <span>{{ 'USERDATA.REENVIARAVISO' | translate }}</span>
                        </button>
                    </div>

                    <div class="col-md-4 mt-2">
                        <button *ngIf="!parentValidated" class="botonLoginEntrar" type="submit">
                                <span>{{ 'USERDATA.GRABAR' | translate }}</span>
                            </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <form *ngIf="verPestanaCentros && pestanaCentrosActiva" [formGroup]="formularioDatosCentroEstudiante" #thisFormCentros="ngForm">

        <div class="row py-4">

            <div class="col-md-3" align="center">

                <div *ngIf="centersData.length > 0">
                    <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'USERDATA.CENTROS' | translate }}</span>

                    <mat-list class="my-2 caja-blanca">
                        <mat-list-item *ngFor="let center of centersData" (click)="cargarDatosCentro(center.idCenter)">
                            {{ center.centerName }}
                        </mat-list-item>
                    </mat-list>
                </div>

                <button (click)="asignarCentroEstudiante()" class="botonLoginEntrar mt-2" type="button">
                    <span>{{ 'USERDATA.ANADIRCENTRO' | translate }}</span>
                </button>
            </div>

            <div class="col-md-9">

                <div align="center">
                    <span *ngIf="centersData.length === 0" class="textoFontSize09 colorTextoActivado font-weight-bold"> {{ 'USERDATA.NOTIENEINGUNCENTROASIGNADO' | translate }}</span>
                </div>

                <div *ngIf="verDetalleCentroEstudiante">

                    <div class="row form-group">

                        <div class="col-sm-4"></div>

                        <div class="col-sm-4">
                            <div class="card form-group">
                                <img *ngIf="centerData != undefined" [src]="centerData.centerPicture | imagen:'center'" class="card-img-top img-circle">
                            </div>
                        </div>

                        <div class="col-sm-4"></div>
                    </div>

                    <div class="row form-group mt-3">

                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">location_city</i>
                        </div>

                        <div class="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.NOMBRE' | translate }}</mat-label>
                                <input matInput formControlName="centerName" readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">map</i>
                        </div>

                        <div clasS="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.DIRECCION' | translate }}</mat-label>
                                <input matInput formControlName="centerAddress" readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">location_on</i>
                        </div>

                        <div clasS="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.PAIS' | translate }}</mat-label>
                                <input matInput formControlName="centerCountry" readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2"></i>
                        </div>

                        <div clasS="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.REGION' | translate }}</mat-label>
                                <input matInput formControlName="centerRegion" readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">

                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">mail_outline</i>
                        </div>

                        <div class="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.EMAIL' | translate }}</mat-label>
                                <input matInput formControlName="centerMail" readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-2" align="center">
                            <i class="material-icons ti-size-2">web</i>
                        </div>

                        <div clasS="col-10">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'USERDATA.URL' | translate }}</mat-label>
                                <input matInput formControlName="centerUrl" readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-8" align="center"></div>

                        <div clasS="col-4">
                            <button (click)="desasignarCentroEstudiante()" class="botonLoginRegistrar mt-2" type="button">
                                <span>{{ 'USERDATA.DESASIGNARCENTRO' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <form *ngIf="verPestanaHijos && pestanaHijosActiva" [formGroup]="formularioDatosHijoPadre" #thisFormPadres="ngForm">

        <div class="row py-4">

            <div class="col-md-3" align="center">

                <div *ngIf="sonsData.length > 0">
                    <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'USERDATA.HIJOS' | translate }}</span>

                    <mat-list class="mt-2 caja-blanca">
                        <mat-list-item *ngFor="let son of sonsData" (click)="cargarDatosHijo(son.idUser)">
                            {{ son.firstName }} {{ son.surname }}
                        </mat-list-item>
                    </mat-list>
                </div>

                <span *ngIf="sonsData.length === 0" class="textoFontSize09 colorTextoActivado font-weight-bold"> {{ 'USERDATA.NOHAYNINGUNHIJOREGISTRADO' | translate }}</span>
            </div>

            <div *ngIf="verDetalleHijoPadre" class="col-md-9">

                <div class="row form-group">

                    <div class="col-sm-4"></div>

                    <div class="col-sm-4">
                        <div class="card form-group">
                            <img *ngIf="sonData != undefined" [src]="sonData.pictureUser | imagen:'users'" class="card-img-top img-circle">
                        </div>
                    </div>

                    <div class="col-sm-4"></div>
                </div>

                <div class="row form-group mt-3">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">account_circle</i>
                    </div>

                    <div class="col-5">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.NOMBRE' | translate }}</mat-label>
                            <input matInput formControlName="firstName" readonly>
                        </mat-form-field>
                    </div>

                    <div class="col-5">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.APELLIDOS' | translate }}</mat-label>
                            <input matInput formControlName="surname" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row form-group">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">mail_outline</i>
                    </div>

                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.EMAIL' | translate }}</mat-label>
                            <input matInput formControlName="mail" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">cake</i>
                    </div>

                    <div clasS="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.FECHANACIMIENTO' | translate }}</mat-label>
                            <input type="date" matInput formControlName="birthdate" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row form-group">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">phone</i>
                    </div>

                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.TELEFONOMOVIL' | translate }}</mat-label>
                            <input matInput formControlName="mobile" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="!sonValidated" class="row form-group">

                    <div class="col-4"></div>

                    <div class="col-4">
                        <button class="botonLoginRegistrar">{{ 'USERDATA.ESMIHIJO' | translate }}</button>
                    </div>
                </div>

                <div *ngIf="sonValidated" class="row form-group">

                    <div class="col-4"></div>

                    <div class="col-4" align="center">
                        <span class="textoFontSize1 colorTextoActivado">{{ 'USERDATA.YAHASIDVALIDADO' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <app-loading [isVisible]="loading"></app-loading>
</div>
