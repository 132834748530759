<input #myInput1 type="file" [id]="AUDIO_TYPE" accept="audio/mp3" (change)="onFileSelected($event)" hidden />
<input #myInput2 type="file" [id]="VIDEO_TYPE" accept="video/mp4" (change)="onFileSelected($event)" hidden />
<input #myInput3 type="file" [id]="IMAGE_TYPE" accept="image/*" (change)="onFileSelected($event)" hidden />
<input #myInput4 type="file" [id]="PDF_TYPE" accept="application/pdf,application/vnd.ms-excel"
	(change)="onFileSelected($event)" hidden />


<app-loading [isVisible]="loading"></app-loading>

<div class="container">

	<div class="d-flex" style="width: 3rem;">
		<button type="button" nbButton status="basic" size="tiny">
			<mat-icon *ngIf="viewFull" ngbTooltip="{{ 'QUIZZES.ENCOGER' | translate }}" class="pointer expant"
				(click)="viewFull = false">expand_less</mat-icon>
			<mat-icon *ngIf="!viewFull" ngbTooltip="{{ 'QUIZZES.EXPANDIR' | translate }}" class="pointer expant"
				(click)="viewFull = true">expand_more</mat-icon>
		</button>
	</div>

	<ng-container *ngIf="viewFull">
		<div class="">

			<div class="row">
				<div class="col-12 col-md-6">
					<form [formGroup]="infoForm">
						<div class="d-flex flex-column gap-1 my-3">
							<label class="mb-0">{{ 'QUIZZES.TITULO' | translate }}</label>
							<input nbInput fullWidth fieldSize="small" class="form-control" type="text"
								formControlName="title" (blur)="onTextBlur($event)" (keydown)="keydown($event)" />
						</div>
					</form>
				</div>
			</div>

			<div class="row">

				<div class="idioma col-12 col-md-6 p-2">
					<div class="d-flex justify-content-center">
						<h5 class="mb-0">{{ 'QUIZZES.IDIOMA1' | translate }}</h5>
					</div>

					<div class="card-group">

						<div class="card d-flex justify-content-center">
							<div class="d-flex justify-content-center align-items-center">
								<div *ngIf="quizFiles.video1 !== null && quizFiles.video1 !== ''"
									(click)='previewContentMedia(mediaViewUrl+DIR_VIDEO_URL+"/"+quizFiles.video1, DIR_VIDEO_URL)'>
									<button nbButton status="basic" size="tiny">
										<span class="d-flex justify-content-center align-items-center">
											{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}
											<mat-icon ngbTooltip="{{ 'QUIZZES.VIDEO' | translate }}">videocam</mat-icon>
										</span>
									</button>
								</div>

								<mat-icon *ngIf="quizFiles.video1 === null || quizFiles.video1 === ''"
									ngbTooltip="{{ 'QUIZZES.VIDEO' | translate }}" class="icon-hypermedia  align-items pointer"
									(click)="attachment(VIDEO_TYPE, 1)">videocam</mat-icon>
							</div>

							<div class="card-body d-flex justify-content-center">
								<button *ngIf="quizFiles.video1 === null || quizFiles.video1 === ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ADJUNTAR' | translate }}"
										placement="right" (click)="attachment(VIDEO_TYPE, 1)">attach_file</mat-icon>
								</button>
								<button *ngIf="quizFiles.video1 !== null && quizFiles.video1 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer"
										ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
										(click)="attachment(VIDEO_TYPE, 1)">published_with_changes</mat-icon>
								</button>
								<button *ngIf="quizFiles.video1 !== null && quizFiles.video1 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
										placement="right" (click)="deleteFile(VIDEO_TYPE, 1, quizFiles.video1)">delete</mat-icon>
								</button>
							</div>
						</div>

						<div class="card pr-1 pt-2 flex justify-content-center">
							<div class="d-flex justify-content-center align-items-center">
								<div *ngIf="quizFiles.imagen1 !== null && quizFiles.imagen1 !== ''"
									(click)='previewContentMedia(mediaViewUrl+DIR_PICTURE_URL+"/"+quizFiles.imagen1, DIR_PICTURE_URL)'
									class="row">
									<button nbButton status="basic" size="tiny">
										<span class="d-flex justify-content-center align-items-center">
											{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}
											<mat-icon ngbTooltip="{{ 'QUIZZES.IMAGEN' | translate }}">imagen</mat-icon>
										</span>
									</button>
								</div>
								<mat-icon *ngIf="quizFiles.imagen1 === null || quizFiles.imagen1 === ''"
									ngbTooltip="{{ 'QUIZZES.IMAGEN' | translate }}" class="icon-hypermedia  align-items pointer"
									(click)="attachment(IMAGE_TYPE, 1)">image</mat-icon>
							</div>
							<div class="card-body d-flex justify-content-center">
								<button *ngIf="quizFiles.imagen1 === null || quizFiles.imagen1 === ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ADJUNTAR' | translate }}"
										placement="right" (click)="attachment(IMAGE_TYPE, 1)">attach_file</mat-icon>
								</button>
								<button *ngIf="quizFiles.imagen1 !== null && quizFiles.imagen1 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer"
										ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
										(click)="attachment(IMAGE_TYPE, 1)">published_with_changes</mat-icon>
								</button>
								<button *ngIf="quizFiles.imagen1 !== null && quizFiles.imagen1 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
										placement="right" (click)="deleteFile(IMAGE_TYPE, 1,  quizFiles.imagen1)">delete</mat-icon>
								</button>
							</div>
						</div>

						<div class="card pr-1 pt-2 flex justify-content-center">

							<div class="d-flex justify-content-center align-items-center">
								<div *ngIf="quizFiles.audio1 !== null && quizFiles.audio1 !== ''"
									(click)='previewContentMedia(mediaViewUrl+DIR_AUDIO_URL+"/"+quizFiles.audio1, DIR_AUDIO_URL)'
									class="row">
									<button nbButton status="basic" size="tiny">
										<span class="d-flex justify-content-center align-items-center">
											{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}
											<mat-icon ngbTooltip="{{ 'QUIZZES.AUDIO' | translate }}">volume_up</mat-icon>
										</span>
									</button>
								</div>
								<mat-icon *ngIf="quizFiles.audio1 === null || quizFiles.audio1 === ''"
									ngbTooltip="{{ 'QUIZZES.AUDIO' | translate }}" class="icon-hypermedia align-items pointer"
									(click)="attachment(AUDIO_TYPE, 1)">volume_up</mat-icon>
							</div>
							<div class="card-body d-flex justify-content-center">
								<button *ngIf="quizFiles.audio1 === null || quizFiles.audio1 === ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ADJUNTAR' | translate }}"
										placement="right" (click)="attachment(AUDIO_TYPE, 1)">attach_file</mat-icon>
								</button>
								<button *ngIf="quizFiles.audio1 === null || quizFiles.audio1 === ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer"
										ngbTooltip="{{ 'NODEFORMCOMPONENT.GRABARAUDIO' | translate }}" placement="right"
										(click)="attachment(AUDIO_TYPE, 1)">mic</mat-icon>
								</button>
								<button *ngIf="quizFiles.audio1 !== null && quizFiles.audio1 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer"
										ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
										(click)="attachment(AUDIO_TYPE, 1)">published_with_changes</mat-icon>
								</button>
								<button *ngIf="quizFiles.audio1 !== null && quizFiles.audio1 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
										placement="right" (click)="deleteFile(AUDIO_TYPE, 1, quizFiles.audio1)">delete</mat-icon>
								</button>
							</div>
						</div>

						<div class="card pr-1 pt-2 flex justify-content-center">
							<div class="d-flex justify-content-center align-items-center">
								<p *ngIf="quizFiles.text1 !== null && quizFiles.text1 !== ''"
									[innerHTML]="quizFiles.text1 | truncate: [15]">
								</p>
								<mat-icon *ngIf="quizFiles.text1 === null  || quizFiles.text1 === ''"
									ngbTooltip="{{ 'QUIZZES.EDITAR' | translate }}" class="icon-hypermedia  align-items pointer"
									(click)="openQuillEditor(1)">subject</mat-icon>
							</div>
							<div class="card-body d-flex justify-content-center">
								<button *ngIf="quizFiles.text1 === null  || quizFiles.text1 === ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.EDITAR' | translate }}"
										placement="right" (click)="openQuillEditor(1)">edit</mat-icon>
								</button>
								<button *ngIf="quizFiles.text1 !== null && quizFiles.text1 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.EDITAR' | translate }}"
										placement="right" (click)="openQuillEditor(1)">edit</mat-icon>
								</button>
								<button *ngIf="quizFiles.text1 !== null && quizFiles.text1 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
										placement="right" (click)="deleteTextQuillEditor(1)">delete</mat-icon>
								</button>
							</div>
						</div>

						<div class="card  pr-1 pt-2 flex justify-content-center">
							<div class="d-flex justify-content-center align-items-center">
								<div *ngIf="quizFiles.doc1 !== null && quizFiles.doc1 !== ''"
									(click)='previewContentMedia(mediaViewUrl+"pdf/"+quizFiles.doc1, "pdf")' class="row">
									<button nbButton status="basic" size="tiny">
										<span class="d-flex justify-content-center align-items-center">
											{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}
											<mat-icon ngbTooltip="{{ 'QUIZZES.DOCUMENTO' | translate }}">insert_drive_file</mat-icon>
										</span>
									</button>
								</div>
								<!-- <iframe *ngIf="quizFiles.doc1 !== null && quizFiles.doc1 !== ''" class="card-img-top" #pdfElement  class="file-container-view"   [src]='mediaViewUrl+"pdf/"+quizFiles.doc1 |safe' allowfullscreen></iframe> -->
								<mat-icon *ngIf="quizFiles.doc1 === null || quizFiles.doc1 === ''"
									ngbTooltip="{{ 'QUIZZES.DOCUMENTO' | translate }}" class="icon-hypermedia align-items pointer"
									(click)="attachment(PDF_TYPE, 1)">insert_drive_file</mat-icon>
							</div>
							<div class="card-body d-flex justify-content-center">
								<button *ngIf="quizFiles.doc1 === null || quizFiles.doc1 === ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ADJUNTAR' | translate }}"
										placement="right" (click)="attachment(PDF_TYPE, 1)">attach_file</mat-icon>
								</button>
								<button *ngIf="quizFiles.doc1 !== null && quizFiles.doc1 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer"
										ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
										(click)="attachment(PDF_TYPE, 1)">published_with_changes</mat-icon>
								</button>
								<button *ngIf="quizFiles.doc1 !== null && quizFiles.doc1 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
										placement="right" (click)="deleteFile(PDF_TYPE, 1, quizFiles.doc1)">delete</mat-icon>
								</button>
							</div>
						</div>

					</div>

				</div>

				<div class="idioma col-md-6 p-2">
					<div class="d-flex justify-content-center">
						<h5 class="mb-0">{{ 'QUIZZES.IDIOMA2' | translate }}</h5>
					</div>
					<div class="align-items-end card-group  pt-2">

						<div class="card pr-1 pt-2">
							<div class="d-flex justify-content-center align-items-center">
								<div *ngIf="quizFiles.video2 !== null && quizFiles.video2 !== ''"
									(click)='previewContentMedia(mediaViewUrl+DIR_VIDEO_URL+"/"+quizFiles.video2, DIR_VIDEO_URL)'
									class="row">
									<button nbButton status="basic" size="tiny">
										<span class="d-flex justify-content-center align-items-center">
											{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}
											<mat-icon ngbTooltip="{{ 'QUIZZES.VIDEO' | translate }}" class="lng2">videocam</mat-icon>
										</span>
									</button>
								</div>

								<mat-icon *ngIf="quizFiles.video2 === null || quizFiles.video2 === ''"
									ngbTooltip="{{ 'QUIZZES.VIDEO' | translate }}" class="icon-hypermedia  align-items pointer lng2"
									(click)="attachment(VIDEO_TYPE, 2)">videocam</mat-icon>

							</div>
							<div class="card-body d-flex justify-content-center">
								<button *ngIf="quizFiles.video2 === null || quizFiles.video2 === ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ADJUNTAR' | translate }}"
										placement="right" (click)="attachment(VIDEO_TYPE, 2)">attach_file</mat-icon>
								</button>
								<button *ngIf="quizFiles.video2 !== null && quizFiles.video2 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2"
										ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
										(click)="attachment(VIDEO_TYPE, 2)">published_with_changes</mat-icon>
								</button>
								<button *ngIf="quizFiles.video2 !== null && quizFiles.video2 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
										placement="right" (click)="deleteFile(VIDEO_TYPE, 2, quizFiles.video2)">delete</mat-icon>
								</button>
							</div>
						</div>

						<div class="card pr-1 pt-2">
							<div class="d-flex justify-content-center align-items-center">
								<!-- <img  *ngIf="quizFiles.imagen2 !== null && quizFiles.imagen2 !== ''" class="card-img-top" width="259" height="180" [src]='mediaViewUrl+DIR_PICTURE_URL+"/"+quizFiles.imagen2|safe'> -->
								<div *ngIf="quizFiles.imagen2 !== null && quizFiles.imagen2 !== ''"
									(click)='previewContentMedia(mediaViewUrl+DIR_PICTURE_URL+"/"+quizFiles.imagen2, DIR_PICTURE_URL)'
									class="row">
									<button nbButton status="basic" size="tiny">
										<span class="d-flex justify-content-center align-items-center">
											{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}
											<mat-icon ngbTooltip="{{ 'QUIZZES.IMAGEN' | translate }}" class="lng2">image</mat-icon>
										</span>
									</button>
								</div>
								<mat-icon *ngIf="quizFiles.imagen2 === null || quizFiles.imagen2 === ''"
									ngbTooltip="{{ 'QUIZZES.IMAGEN' | translate }}" class="icon-hypermedia align-items pointer lng2"
									(click)="attachment(IMAGE_TYPE, 2)">image</mat-icon>
							</div>
							<div class="card-body d-flex justify-content-center">
								<button *ngIf="quizFiles.imagen2 === null || quizFiles.imagen2 === ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ADJUNTAR' | translate }}"
										placement="right" (click)="attachment(IMAGE_TYPE, 2)">attach_file</mat-icon>
								</button>
								<button *ngIf="quizFiles.imagen2 !== null && quizFiles.imagen2 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2"
										ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
										(click)="attachment(IMAGE_TYPE, 2)">published_with_changes</mat-icon>
								</button>
								<button *ngIf="quizFiles.imagen2 !== null && quizFiles.imagen2 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
										placement="right" (click)="deleteFile(IMAGE_TYPE, 2,  quizFiles.imagen2)">delete</mat-icon>
								</button>
							</div>
						</div>

						<div class="card pr-1  pt-2">

							<div class="col-12 d-flex justify-content-center align-items-center">
								<!-- <audio *ngIf="quizFiles.audio2 !== null && quizFiles.audio2 !== ''" class="card-img-top" width="259" height="180" [src]='mediaViewUrl+DIR_AUDIO_URL+"/"+quizFiles.audio2|safe' controls>
                                </audio> -->
								<div *ngIf="quizFiles.audio2 !== null && quizFiles.audio2 !== ''"
									(click)='previewContentMedia(mediaViewUrl+DIR_AUDIO_URL+"/"+quizFiles.audio2, DIR_AUDIO_URL)'
									class="row">
									<button nbButton status="basic" size="tiny">
										<span class="d-flex justify-content-center align-items-center">
											{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}
											<mat-icon class="lng2" ngbTooltip="{{ 'QUIZZES.AUDIO' | translate }}">volume_up</mat-icon>
										</span>
									</button>
								</div>
								<mat-icon *ngIf="quizFiles.audio2 === null || quizFiles.audio2 === ''"
									ngbTooltip="{{ 'QUIZZES.AUDIO' | translate }}" class="icon-hypermedia align-items pointer lng2"
									(click)="attachment(AUDIO_TYPE, 2)">volume_up</mat-icon>
							</div>
							<div class="card-body d-flex justify-content-center ">
								<button *ngIf="quizFiles.audio2 === null || quizFiles.audio2 === ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ADJUNTAR' | translate }}"
										placement="right" (click)="attachment(AUDIO_TYPE, 2)">attach_file</mat-icon>
								</button>
								<button *ngIf="quizFiles.audio2 === null || quizFiles.audio2 === ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2"
										ngbTooltip="{{ 'NODEFORMCOMPONENT.GRABARAUDIO' | translate }}" placement="right"
										(click)="attachment(AUDIO_TYPE, 2)">mic</mat-icon>
								</button>
								<button *ngIf="quizFiles.audio2 !== null && quizFiles.audio2 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2"
										ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
										(click)="attachment(AUDIO_TYPE, 2)">published_with_changes</mat-icon>
								</button>
								<button *ngIf="quizFiles.audio2 !== null && quizFiles.audio2 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
										placement="right" (click)="deleteFile(AUDIO_TYPE, 2, quizFiles.audio2)">delete</mat-icon>
								</button>
							</div>
						</div>

						<div class="card pr-1 pt-2 flex justify-content-center">
							<div class="col-12 d-flex justify-content-center align-items-center">
								<p *ngIf="quizFiles.text2 !== null && quizFiles.text2 !== ''"
									[innerHTML]="quizFiles.text2 | truncate: [15]">
								</p>
								<mat-icon *ngIf="quizFiles.text2 === null  || quizFiles.text2 === ''"
									ngbTooltip="{{ 'QUIZZES.EDITAR' | translate }}" class="icon-hypermedia  align-items pointer lng2"
									(click)="openQuillEditor(2)">subject</mat-icon>
							</div>
							<div class="card-body d-flex justify-content-center">
								<button *ngIf="quizFiles.text2 === null  || quizFiles.text2 === ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.EDITAR' | translate }}"
										placement="right" (click)="openQuillEditor(2)">edit</mat-icon>
								</button>
								<button *ngIf="quizFiles.text2 !== null && quizFiles.text2 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.EDITAR' | translate }}"
										placement="right" (click)="openQuillEditor(2)">edit</mat-icon>
								</button>
								<button *ngIf="quizFiles.text2 !== null && quizFiles.text2 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
										placement="right" (click)="deleteTextQuillEditor(2)">delete</mat-icon>
								</button>
							</div>
						</div>

						<div class="card pr-1 pt-2 flex justify-content-center">
							<div class="col-12 d-flex justify-content-center align-items-center">
								<div *ngIf="quizFiles.doc2 !== null && quizFiles.doc2 !== ''"
									(click)='previewContentMedia(mediaViewUrl+"pdf/"+quizFiles.doc2, "pdf")' class="row">
									<button nbButton status="basic" size="tiny">
										<span class="d-flex justify-content-center align-items-center">
											{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}
											<mat-icon ngbTooltip="{{ 'QUIZZES.DOCUMENTO' | translate }}" class="lng2">insert_drive_file</mat-icon>
										</span>
									</button>
								</div>
								<!-- <iframe *ngIf="quizFiles.doc2 !== null && quizFiles.doc2 !== ''" class="card-img-top" #pdfElement  class="file-container-view"   [src]='mediaViewUrl+"pdf/"+quizFiles.doc2 |safe' allowfullscreen></iframe> -->
								<mat-icon *ngIf="quizFiles.doc2 === null || quizFiles.doc2 === ''"
									ngbTooltip="{{ 'QUIZZES.DOCUMENTO' | translate }}" class="icon-hypermedia align-items pointer lng2"
									(click)="attachment(PDF_TYPE, 2)">insert_drive_file</mat-icon>
							</div>

							<div class="card-body d-flex justify-content-center">
								<button *ngIf="quizFiles.doc2 === null || quizFiles.doc2 === ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ADJUNTAR' | translate }}"
										placement="right" (click)="attachment(PDF_TYPE, 2)">attach_file</mat-icon>
								</button>
								<button *ngIf="quizFiles.doc2 !== null && quizFiles.doc2 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2"
										ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
										(click)="attachment(PDF_TYPE, 2)">published_with_changes</mat-icon>
								</button>
								<button *ngIf="quizFiles.doc2 !== null && quizFiles.doc2 !== ''" type="button"
									class="btn btn-link  btn-sm">
									<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
										placement="right" (click)="deleteFile(PDF_TYPE, 2, quizFiles.doc2)">delete</mat-icon>
								</button>
							</div>
						</div>

					</div>

				</div>

			</div>

		</div>
	</ng-container>

	<div class="container">
		<div class="row  mt-5 ml-4">
			<!--
			<div class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 flex">
				<div class="w-100 flex align-content-center align-items-center">
					<form [formGroup]="form">
						<nb-toggle formControlName="modo" (change)="modoManualAuto()">
							<ng-container *ngIf="!modo" class="animated fast fadeIn">
								{{'QUIZZES.BOTONAUTO' | translate}} &nbsp; &nbsp;
							</ng-container>
							<ng-container *ngIf="modo" class="animated fast fadeIn">
								{{'QUIZZES.BOTONMANUAL' | translate}} &nbsp; &nbsp;
							</ng-container>
						</nb-toggle>
					</form>
					<mat-icon [ngbTooltip]="tipContent" class="pointer">info</mat-icon>
				</div>
			</div>
			-->
			<div class="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 flex justify-content-center align-items-center">
				<div class="col-3 flex justify-content-center align-items-center animated fast fadeIn" *ngIf="quizzes.length == 0">
					<button class="btn btn-primary btn-block"	(click)="crearSubQuizzes()">
						{{ 'QUIZZES.CREARQUIZZES' | translate }}
					</button>
				</div>
				<!--
				<div class="col-3 flex justify-content-center align-items-center animated fast fadeIn" *ngIf="quizzes.length > 0">
					<button class="btn btn-danger btn-block" (click)="deleteAllQuizes()">
						{{ 'QUIZZES.ELIMINARTODOS' | translate }}
					</button>
				</div>
				-->
			</div>
		</div>
	</div>

</div>

<ng-template #tipContent class="animated fast fadeIn">
	<p><b>{{'QUIZZES.BOTONMANUAL' | translate}}:</b> {{'QUIZZES.TOOLTIPMANUAL' | translate}}</p>
	<p><b>{{'QUIZZES.BOTONAUTO' | translate}}:</b> {{'QUIZZES.TOOLTIPAUTO' | translate}}</p>
</ng-template>
