<nb-card class="no-edge-card mb-0">
	<div class="container">
		<nb-card-header class="row border-bottom-0">
			<div class="col-12 col-md-8">
				<h5 *ngIf="profile === 'ESTUDIANTE'">
					{{ "PARENTS.ADDPARENT" | translate }}
				</h5>
				<h5 *ngIf="profile === 'PADRE'">
					{{ "PARENTS.ADDCHILD" | translate }}
				</h5>
			</div>
			<div class="col-12 col-md-4 pt-md-0 pt-3 d-flex justify-content-end">
				<div class="dropdown">
					<button nbButton size="small" (click)="back()" *ngIf="profile === 'ESTUDIANTE'">
						{{ "PARENTS.BACKTOPARENTS" | translate }}
					</button>
					<button nbButton size="small" (click)="back()" *ngIf="profile === 'PADRE'">
						{{ "PARENTS.BACKTOCHILDREN" | translate }}
					</button>
				</div>
			</div>
		</nb-card-header>
		<nb-card-body>
			<div class="row my-5 opciones justify-content-center">
				<div class="col-12 col-md-8">
					<div class="w-100 position-relative d-flex justify-content-center">
						<form [formGroup]="formulario" class="searchBar w-100" #thisForm="ngForm">
							<div class="form-group">
								<div class="input-container">
									<input type="search" nbInput fullWidth status="basic"
										placeholder="{{ 'PARENTS.SEARCHTOASSIGN' | translate }}" name="fiilter" name="filter"
										formControlName="filter" autocomplete="off" (keyup)="filtrarPadres()"
										*ngIf="profile === 'ESTUDIANTE'" />
									<input type="search" nbInput fullWidth status="basic" placeholder="{{'PARENTS.SEARCHCHILDTOASSIGN' | translate }}"
										name="fiilter" name="filter" formControlName="filter" autocomplete="off" (keyup)="filtrarPadres()"
										*ngIf="profile === 'PADRE'" />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="m-4 flex justify-content-center">
				<div class="column1 bordered-column">
					<ul class="list-group" *ngIf="users != null">
						<li *ngFor="let user of users | sortBy:'asc':'firstName'"
							class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
							<div>{{ user.firstName }}&nbsp;{{ user.surname }}&nbsp;</div>
							<div style="color: red" *ngIf="user.invitedFromChild === 1 || user.invitedFromParent === 1">
								({{ "MODALLISTSTUDENTS.WAITACCEPT" | translate }})
							</div>
							<div>
								<span (click)="enviarInvitacion(user)" [nbTooltip]="'MODALLISTSTUDENTS.SENDINVITATION' | translate"
									placement="top" *ngIf="user.invitedFromChild === 0 && user.invitedFromParent === 0"
									class="badge badge-pill">
									<nb-icon class="icon-color-blue closebtn pointer text-right m-1 float-right" icon="person-add"
										[options]="{ animation: { type: 'pulse' } }" style="font-size: 1.5rem !important">
									</nb-icon>
								</span>
								<span (click)="cancelarInvitacion(user)" [nbTooltip]="'MODALLISTSTUDENTS.CANCELINVITATION' | translate" nbTooltipPlacement="left"
									*ngIf="
											user.invitedFromChild === 1 ||
											user.invitedFromParent === 1 " class="badge badge-pill">
									<nb-icon class="icon-color-blue closebtn pointer text-right m-1 float-right" icon="person-delete"
										[options]="{ animation: { type: 'pulse' } }" style="font-size: 1.5rem !important; color: darkred">
									</nb-icon>
								</span>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</nb-card-body>
	</div>
</nb-card>
