import { Action } from '@ngrx/store';
import { selectedProfile } from "../models/selected-profile.model"

export enum ActionTypes {
	selectProfile = '[selectedProfile ACTION] select profile',
}

export class selectProfile implements Action {
	readonly type = ActionTypes.selectProfile;
	constructor(public payload: selectedProfile) { }
}


export type ActionsUnion = selectProfile;
