<div>
    <div class="px-4 py-3 flex justify-content-between">
        <mat-icon class="header-icon">cloud_upload</mat-icon>

        <button mat-icon-button (click)="onCloseModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="pt-2 pb-5 px-5">
        <div class="border border-primary p-5">
           

            <!-- <h5 class="mb-2">Elegir fichero</h5> -->
                <div class="row">
                    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <h5 class="mb-2">{{ 'QUIZZES.ELEGIRFICHERO1' | translate }}</h5>

                        <div class="row  align-items-end idioma mr-2 pt-2">
                            <div *ngIf="quizFiles.imagen1" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 animated fast fadeIn">
                                <div class="row ">

                                    <div class="col-12">
                                        <div class="card" >
                                            <img  class="card-img-top" [src]='mediaViewUrl+DIR_PICTURE_URL+"/"+quizFiles.imagen1|safe'>
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">
                                        <div class="car-body">
                                            <button class="btn btn-link pointer" (click)="selectFile(quizFiles.imagen1, IMG, IDIOMA1)">
                                                <p class="text-center">
                                                    <mat-icon class="mat-icon-white" ngbTooltip ="{{ 'QUIZZES.ELEGIRFICHERO' | translate }}" color="secondary" >add_link</mat-icon>
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                    
                                </div>
                               
                               
                            </div>

                            <div  *ngIf="quizFiles.audio1" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 animated fast fadeIn">

                                <div class="row ">
                                    <div class="col-12">
                                        <div class="card" >
                                            <audio class="card-img-top" [src]='mediaViewUrl+DIR_AUDIO_URL+"/"+quizFiles.audio1|safe' controls>
                                            </audio>                                    
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">
                                        <div class="car-body">
                                            <button class="btn btn-link pointer" (click)="selectFile(quizFiles.audio1, AUD, IDIOMA1)">
                                                <p class="text-center">
                                                    <mat-icon class="mat-icon-white" ngbTooltip ="{{ 'QUIZZES.ELEGIRFICHERO' | translate }}" color="secondary" >add_link</mat-icon>
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div *ngIf="quizFiles.video1" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 animated fast fadeIn">

                                <div class="row ">
                                    <div class="col-12">
                                        <div class="card" >
                                            <video class="card-img-top"  class="file-container-view"   [src]='mediaViewUrl+DIR_VIDEO_URL+"/"+quizFiles.video1|safe' controls>
                                            </video>
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">
                                        <div class="car-body">
                                            <button class="btn btn-link pointer" (click)="selectFile(quizFiles.video1, VID, IDIOMA1)">
                                                <p class="text-center">
                                                    <mat-icon class="mat-icon-white" ngbTooltip ="{{ 'QUIZZES.ELEGIRFICHERO' | translate }}" color="secondary" >add_link</mat-icon>
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="quizFiles.doc1" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 animated fast fadeIn">

                                <div class="row ">
                                    <div class="col-12">
                                        <div class="card" >
                                            <iframe class="card-img-top" #pdfElement  class="file-container-view"   [src]='mediaViewUrl+"pdf/"+quizFiles.doc1 |safe' allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">
                                        <div class="car-body">
                                            <button class="btn btn-link pointer" (click)="selectFile(quizFiles.doc1, DOC, IDIOMA1)">
                                                <p class="text-center">
                                                    <mat-icon class="mat-icon-white" ngbTooltip ="{{ 'QUIZZES.ELEGIRFICHERO' | translate }}" color="secondary" >add_link</mat-icon>
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="quizFiles.text1" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 animated fast fadeIn">

                                <div class="row ">
                                    <div class="col-12">
                                        <div class="card" >
                                            <p  [innerHTML]="quizFiles.text1" class="card-img-top" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">
                                        <div class="car-body">
                                            <button class="btn btn-link pointer" (click)="selectFile(quizFiles.text1, TEXT, IDIOMA1)">
                                                <p class="text-center">
                                                    <mat-icon class="mat-icon-white" ngbTooltip ="{{ 'QUIZZES.ELEGIRFICHERO' | translate }}" color="secondary" >add_link</mat-icon>
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                        </div>

                    
                    </div>


                    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <h5 class="mb-2">{{ 'QUIZZES.ELEGIRFICHERO2' | translate }}</h5>

                        <div class="row  align-items-end idioma pt-2">

                            <div *ngIf="quizFiles.imagen2" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 animated fast fadeIn">
                                <div class="row ">

                                    <div class="col-12">
                                        <div class="card" >
                                            <img  class="card-img-top" [src]='mediaViewUrl+DIR_PICTURE_URL+"/"+quizFiles.imagen2|safe'>
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">
                                        <div class="car-body">
                                            <button class="btn btn-link pointer" (click)="selectFile(quizFiles.imagen2, IMG, IDIOMA2)">
                                                <p class="text-center">
                                                    <mat-icon class="mat-icon-white" ngbTooltip ="{{ 'QUIZZES.ELEGIRFICHERO' | translate }}" color="secondary" >add_link</mat-icon>
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                    
                                </div>
                               
                               
                            </div>

                            <div *ngIf="quizFiles.audio2" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 animated fast fadeIn">

                                <div class="row ">
                                    <div class="col-12">
                                        <div class="card" >
                                            <audio class="card-img-top" [src]='mediaViewUrl+DIR_AUDIO_URL+"/"+quizFiles.audio2|safe' controls>
                                            </audio>                                    
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">
                                        <div class="car-body">
                                            <button class="btn btn-link pointer" (click)="selectFile(quizFiles.audio2, AUD, IDIOMA2)">
                                                <p class="text-center">
                                                    <mat-icon class="mat-icon-white" ngbTooltip ="{{ 'QUIZZES.ELEGIRFICHERO' | translate }}" color="secondary" >add_link</mat-icon>
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="quizFiles.video2" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 animated fast fadeIn">

                                <div class="row ">
                                    <div class="col-12">
                                        <div class="card" >
                                            <video class="card-img-top"  class="file-container-view"   [src]='mediaViewUrl+DIR_VIDEO_URL+"/"+quizFiles.video2|safe' controls>
                                            </video>
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">
                                        <div class="car-body">
                                            <button class="btn btn-link pointer" (click)="selectFile(quizFiles.video2, VID, IDIOMA2)">
                                                <p class="text-center">
                                                    <mat-icon class="mat-icon-white" ngbTooltip ="{{ 'QUIZZES.ELEGIRFICHERO' | translate }}" color="secondary" >add_link</mat-icon>
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="quizFiles.doc2" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 animated fast fadeIn">

                                <div class="row ">
                                    <div class="col-12">
                                        <div class="card" >
                                            <iframe class="card-img-top" #pdfElement  class="file-container-view"   [src]='mediaViewUrl+"pdf/"+quizFiles.doc2 |safe' allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">
                                        <div class="car-body">
                                            <button class="btn btn-link pointer" (click)="selectFile(quizFiles.doc2, DOC, IDIOMA2)">
                                                <p class="text-center">
                                                    <mat-icon class="mat-icon-white" ngbTooltip ="{{ 'QUIZZES.ELEGIRFICHERO' | translate }}" color="secondary" >add_link</mat-icon>
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="quizFiles.text2" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 animated fast fadeIn">

                                <div class="row ">
                                    <div class="col-12">
                                        <div class="card" >
                                            <p  [innerHTML]="quizFiles.text2" class="card-img-top" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">
                                        <div class="car-body">
                                            <button class="btn btn-link pointer" (click)="selectFile(quizFiles.text2, TEXT, IDIOMA2)">
                                                <p class="text-center">
                                                    <mat-icon class="mat-icon-white" ngbTooltip ="{{ 'QUIZZES.ELEGIRFICHERO' | translate }}" color="secondary" >add_link</mat-icon>
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                        </div>

                    </div>

                </div>
               
        </div>
    </div>
</div>