<div *ngIf="screen == 1">
  <nb-card class="mb-0">
    <nb-card-header class="flex justify-content-between border-bottom-0">
      <div class="col-11">
        <h5>{{ name }}</h5>
				<h6>{{fechaCorta}}</h6>
      </div>
    </nb-card-header>
    <div class="closeModal">
      <nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left"
        [options]="{ animation: { type: 'zoom' } }" (click)="closeModal()"></nb-icon>
    </div>
		<nb-card-body>
			<nb-tabset>
				<nb-tab *ngFor="let item of dato; let i = index" [tabTitle]="item.texto">
					<div class="container">
						<form [formGroup]="forms[i]" (ngSubmit)="justify(i)">
							<div class="col-12">
								<div class="form-group">
									<label>Status:</label>
									<select class="form-control" formControlName="status">
										<option value="1">{{ 'ASISTENCIA.PENDIENTE' | translate }}</option>
										<option value="2">{{ 'ASISTENCIA.AUSENTE' | translate }}</option>
										<option value="3">{{ 'ASISTENCIA.PRESENTE' | translate }}</option>
										<option value="4">{{ 'ASISTENCIA.CONRETRASO' | translate }}</option>
										<option value="5">{{ 'ASISTENCIA.JUSTIFICADO' | translate }}</option>
									</select>
								</div>
								<div class="form-group">
									<label>{{ 'ASISTENCIA.MOTIVO' | translate }}:</label>
									<textarea class="form-control" formControlName="motivo" rows="5" maxlength="250"></textarea>
								</div>
							</div>
							<div class="row">
								<div class="col-12 py-4 d-flex justify-content-center">
									<button nbButton class="d-block w-100 btn-themecolor" type="submit" [class.disabled]="!roleAsignado">
										<span>{{ "MISDATOS.SAVE" | translate }}</span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</nb-tab>
			</nb-tabset>
		</nb-card-body>

  </nb-card>
</div>

<div *ngIf="screen == 2">
	<nb-card class="mb-0">
		<nb-card-header class="flex justify-content-between border-bottom-0">
			<div class="col-11">
				<h5>{{ name }} - {{ 'ASISTENCIA.ESTADISTICAS' | translate }}</h5>
			</div>
		</nb-card-header>
		<div class="closeModal">
			<nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left"
				[options]="{ animation: { type: 'zoom' } }" (click)="closeModal()"></nb-icon>
		</div>
		<nb-card-body>
			<div class="row">
				<div class="d-flex col-lg-6 col-12 gap-1 flex-column">
					<label class="m-0" for="startDate">{{ 'ASISTENCIA.FECHAINICIO' | translate }}:</label>
					<input type="date" id="startDate" nbInput class="form-control" fullWidth status="basic" fieldSize="small"
						[(ngModel)]="fechaInicio" (ngModelChange)="validarFechas()">
				</div>

				<div class="d-flex col-lg-6 col-12 gap-1 flex-column">
					<label class="m-0" for="endDate">{{ 'ASISTENCIA.FECHAFIN' | translate }}:</label>
					<input type="date" id="endDate" nbInput class="form-control" fullWidth status="basic" fieldSize="small"
						[(ngModel)]="fechaFin" (ngModelChange)="validarFechas()">
				</div>
			</div>
			<div class="container mt-4">
				<nb-card class="background_themecolor mb-0">
					<nb-card-body class="d-flex flex-wrap gap-4 justify-content-center align-items-center">
						<div class="cards cards-teacher p-2">
							<div class="card-item bg-danger">
								<div class="card-info">
									<h6>
										{{ 'ASISTENCIA.PENDIENTEPORRESPONDER' | translate }}
									</h6>
									<p>{{summary.totalPending}}/{{summary.totalRecords}}</p>
								</div>
							</div>
						</div>
						<div class="cards cards-teacher p-2">
							<div class="card-item bg-danger">
								<div class="card-info">
									<h6>
										{{ 'ASISTENCIA.AUSENTE' | translate }}
									</h6>
									<p>{{summary.totalAbsent}}/{{summary.totalRecords}}</p>
								</div>
							</div>
						</div>
						<div class="cards cards-teacher p-2">
							<div class="card-item bg-success">
								<div class="card-info">
									<h6>
										{{ 'ASISTENCIA.PRESENTE' | translate }}
									</h6>
									<p>{{summary.totalPresent}}/{{summary.totalRecords}}</p>
								</div>
							</div>
						</div>
						<div class="cards cards-teacher p-2">
							<div class="card-item bg-warning">
								<div class="card-info">
									<h6>
										{{ 'ASISTENCIA.CONRETRASO' | translate }}
									</h6>
									<p>{{summary.totalLate}}/{{summary.totalRecords}}</p>
								</div>
							</div>
						</div>
						<div class="cards cards-teacher p-2">
							<div class="card-item bg-info">
								<div class="card-info">
									<h6 class="card-title">
										{{ 'ASISTENCIA.JUSTIFICADO' | translate }}
									</h6>
									<p>{{summary.totalJustified}}/{{summary.totalRecords}}</p>
								</div>
							</div>
						</div>
					</nb-card-body>
				</nb-card>
			</div>
		</nb-card-body>
	</nb-card>
</div>
