<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{titulo}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <ng-content select=".modal-body"></ng-content>
    <ng-content select=".modal-footer"></ng-content>
</ng-template>
