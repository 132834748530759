import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LABELS, LABELS_ACIERTOS, LABELS_TRABAJO } from './lista-mini_constants'
import { FOTOS } from 'src/app/shared/components/informes-centro/lista/lista_constants';

@Component({
  selector: 'app-lista-mini',
  templateUrl: './lista-mini.component.html',
  styleUrls: ['./lista-mini.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ListaMiniComponent {
	@Input() parametroID: number | null = null;
	@Input() consultaID: number | null = null;
	@Input() datasetsImported: any = null;
	@Input() labelsImported: any = null;

	labels: any[] = [];
	datasets: any[] = [];

	fotosEstudiantes: string[] = [];
	fotosProfesores: string[] = [];
	fotosPadres: string[] = [];

	constructor(){}

	ngOnInit(){
		if (this.consultaID !== null) {
			this.datasets = this.datasetsImported;
			this.labels = this.labelsImported;
		}
		//obtenemos las fotos de los estudiantes
		for (let index = 0; index < FOTOS.ESTUDIANTES.length; index++) {
			const element = FOTOS.ESTUDIANTES[index];
			this.fotosEstudiantes.push(element.picture);
		}
		this.fotosEstudiantes.sort(() => Math.random() - 0.5);
		//obtenemos las fotos de los profesores
		for (let index = 0; index < FOTOS.PROFESORES.length; index++) {
			const element = FOTOS.PROFESORES[index];
			this.fotosProfesores.push(element.picture);
		}
		this.fotosProfesores.sort(() => Math.random() - 0.5);
		//obtenemos las fotos de los padres
		for (let index = 0; index < FOTOS.PADRES.length; index++) {
			const element = FOTOS.PADRES[index];
			this.fotosPadres.push(element.picture);
		}
		this.fotosPadres.sort(() => Math.random() - 0.5);
	}

	colorKnowledge(valor: number): string {
    let color = "grey";
    if (valor == 0) color =  "#9A9B9C";
    else if (valor > 0 && valor < 11) color = "#FB0D1C";
    else if (valor < 21) color = "#FC5C20";
    else if (valor < 31) color = "#FC9A27";
    else if (valor < 41) color = "#FEE533";
    else if (valor < 51) color = "#FFFD38";
    else if (valor < 61) color = "#D4FD35";
    else if (valor < 71) color = "#9CFD32";
    else if (valor < 81) color = "#29F82E";
    else if (valor < 91) color = "#2AD52D";
    else if (valor < 101) color = "#1AAF54";
    return color;
  }

	convertirMinutos(minutos: number): string {
    const horas = Math.floor(minutos / 60);
    const minutosRestantes = minutos % 60;
    return `${horas}h ${minutosRestantes}min`;
  }
}
