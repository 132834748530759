<nb-card class="mb-0 no-edge-card" *ngIf="loginService.esProfesor()">
	<nb-card-header class="border-bottom-0 pb-0">
		<h2>{{ 'MENUACTIONS.TEACHERTITLE18' | translate }}</h2>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}"
			nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal()">
		</nb-icon>
	</div>
	<nb-card-body class="pt-0">
		<div class="d-flex p-4 w-100 flex-wrap justify-content-center align-content-center gap-5">
			<!-- DUPLICAR PROYECTO -->
			<div class="item-style item-tiny" nbTooltip="{{ 'PENSARCREANDO.CREARTAREA' | translate }}"
				nbTooltipPlacement="top" (click)="crearTarea()">
				<h6>{{ "PENSARCREANDO.CREARTAREA" | translate }}</h6>
				<div class="item-image-tiny">
					<img src="../../../../../assets/images/control-panel/duplicar-proyecto.png" width="35" />
				</div>
			</div>
			<!-- VER PROYECTO -->
			<div class="item-style item-tiny" nbTooltip="{{ 'PENSARCREANDO.VERTAREA' | translate }}"
				nbTooltipPlacement="top" (click)="verTareas()">
				<h6>{{ "PENSARCREANDO.VERTAREA" | translate }}</h6>
				<div class="item-image-tiny">
					<img src="../../../../../assets/images/control-panel/ver-proyecto.png" width="35" />
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>
