import { Injectable } from "@angular/core";

const AUDIOFILE:string = '../../../assets/audio/click-pad.mp3'

@Injectable({
    providedIn: 'root'
  })
export class PadsUtils {
    constructor() {}

    public vibratePad():void{

        let supportsVibrate = "vibrate" in navigator; //Check

        if(supportsVibrate && this._isMobile())
            window.navigator.vibrate(200)

        else{
            var audio = new Audio(AUDIOFILE);
            audio.play();
        }

    }

    private _isMobile(): boolean{
        if (/ Android | webOS | iPhone | iPad | iPod | BlackBerry | IEMobile | Opera Mini /i.test (navigator.userAgent)) {
            return true
        }
        return
    }


}
