<div class="quiz-container" id="quiz">
	<app-quiz-edit-multiple-header [quiz]="quiz" [courseId]="courseId" [graphId]="graphId" [fromLista]="fromLista"
		(quizTypeChanged)="onQuizTypeChanged($event)" (close)="closeModal($event)" (publishQuiz)="onPublishQuiz($event)" (closeInModeList)="onCloseInModeList($event)">
	</app-quiz-edit-multiple-header>

	<div class="container">
		<app-quiz-edit-multiple-body (saveTitle)="saveTitle($event)" (updateFooter)="updateFooter($event)"
			(activeCrearTabs)="activeCrearTabs($event)" [courseId]="courseId" [graphId]="graphId" [quiz]="quiz"
			[quizFiles]="quizFiles" [elements]="elements" [quizzes]="quizzes" (onQuizSupEdited)="onQuizSupEdited($event)"
			(quizEdited)="onQuizEdited($event)" (publishQuiz)="onPublishQuiz($event)"></app-quiz-edit-multiple-body>
	</div>

	<div class="footer w-100">

		<div class="d-flex justify-content-end  animated fast fadeIn">
			<div class="col-2" *ngIf="quizzes.length > 0">
				<button class="btn btn-danger btn-block" (click)="deleteAllQuizes()">Eliminar todos</button>
			</div>
		</div>

		<app-quiz-edit-multiple-footer (quizzAdded)="quizzAdded($event)" (updateElementSize)="updateElementSize($event)"
			(updateArrayElement)="updateArrayElements($event)" (updateArrayDeleteElement)="updateArrayDeleteElement($event)"
			(quizEdited)="onQuizEdited($event)" [courseId]="courseId" [quizFiles]="quizFiles" [quizzes]="quizzes"
			[graphId]="graphId" [quiz]="quiz"></app-quiz-edit-multiple-footer>

	</div>

</div>
