<form class="container" [formGroup]="dataForm">
	<ng-container formArrayName="questions">
		<div class="row my-4 align-items-center" *ngFor="let question of questionsArray.controls; let i = index;"
			[class.separator]="i > 0 && questionsArrayLanguage[i].value != questionsArrayLanguage[i-1].value "
			[formGroupName]="i"
			[ngClass]="{'lng1': questionsArrayLanguage[i].value === 1, 'lng2' : questionsArrayLanguage[i].value === 2 }">

			<!-- <div class="col-2 col-sm-1" [ngSwitch]="questionsArrayType[i].value">
				<img [src]="questionsArrayLanguage[i].value == 2 ?
                audio
                : ''" class="img-fluid icon-img" />
				<div *ngSwitchCase="elementTypes.Q_TEXTS">
					<mat-icon *ngIf="questionsArrayLanguage[i].value === 2" class="pointer icon-img-blue "
						(click)="audioPlay(i+1)">volume_up</mat-icon>
					<mat-icon *ngIf="questionsArrayLanguage[i].value === 1" class="pointer icon-img-red "
						(click)="audioPlay(i+1)">volume_up</mat-icon>
				</div>
			</div> -->

			<div class="col-12 col-md-12 d-flex align-items-center justify-content-center">
				<ng-container [ngSwitch]="questionsArrayType[i].value">
					<div *ngSwitchCase="elementTypes.Q_TEXTS">
						<div [innerHTML]="questionsArrayData[i].value"
							[ngStyle]="{'background': '#'+questionsArrayBackground[i].value }">
						</div>
					</div>

					<div [ngStyle]="{'border': '1px solid #'+questionsArrayBackground[i].value }"
						class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.Q_PICTURES">
						<img class="img-fluid"
							[style.width]="questionsArrayWidth[i].value ? questionsArrayWidth[i].value + 'px' : 'fit-content'"
							[style.height]="questionsArrayHeight[i].value ? questionsArrayHeight[i].value + 'px' : '100%'"
							[src]="questionsArrayData[i].value| quizElements:'image' |safe" *ngIf="questionsArrayData[i].value" />
					</div>

					<div [ngStyle]="{'border': '1px solid #'+questionsArrayBackground[i].value }"
						class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.Q_VIDEOS">
						<video class="w-100 mw-100"
							[style.width]="questionsArrayWidth[i].value ? questionsArrayWidth[i].value + 'px' : 'fit-content'"
							[style.height]="questionsArrayHeight[i].value ? questionsArrayHeight[i].value + 'px' : '100%'" controls
							[src]="questionsArrayData[i].value | quizElements:'video' | safe" *ngIf="questionsArrayData[i].value">
						</video>
					</div>

<!--					<div [ngStyle]="{'border': '1px solid #'+questionsArrayBackground[i].value }"
						class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.Q_AUDIOS">
						<audio class="w-100 d-block" controls id="{{i}}"
							[src]="questionsArrayData[i].value | quizElements:'audio' | safe" *ngIf="questionsArrayData[i].value">
						</audio>
					</div>
-->
					<div [ngStyle]="{'border': '1px solid #'+questionsArrayBackground[i].value}"
						class="w-100 h-100 " *ngSwitchCase="elementTypes.Q_AUDIOS">
						<button class="btn btn-primary btn-normal" (click)="playAudioWithSpeed(i, 1)" [disabled]="isAudioPlaying" [ngStyle]="{'background-color':questionsArrayLanguage[i].value===1?'#800000':'#3a86ff', 'border-color':questionsArrayLanguage[i].value===1?'#800000':'#3a86ff' }">
							<nb-icon icon="volume-up-outline" style="font-size: 50px;"></nb-icon>
						</button>
						<button class="btn btn-primary btn-normal" style="margin-left: 20px;" (click)="playAudioWithSpeed(i, 0.5)" [disabled]="isAudioPlaying"  [ngStyle]="{'background-color':questionsArrayLanguage[i].value===1?'#800000':'#3a86ff', 'border-color':questionsArrayLanguage[i].value===1?'#800000':'#3a86ff' }">
							<mat-icon svgIcon="turtle"></mat-icon>
						</button>
						<audio id="{{i}}" [src]="questionsArrayData[i].value | quizElements:'audio' | safe" #audioPlayer></audio>
					</div>


					<div [ngStyle]="{'border': '1px solid #'+questionsArrayBackground[i].value }"
						class="w-100 h-100 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.Q_PDFS">
						<iframe #pdfElement class="w-100 h-100"
							[style.width]="questionsArrayWidth[i].value ? questionsArrayWidth[i].value + 'px' : 'fit-content'"
							[style.height]="questionsArrayHeight[i].value ? questionsArrayHeight[i].value + 'px' : '100%'"
							[src]="questionsArrayData[i].value | quizElements:'pdf' | safe" allowfullscreen
							*ngIf="questionsArrayData[i].value" style="min-height: 920px;"></iframe>
					</div>
				</ng-container>
			</div>
		</div>
	</ng-container>
</form>
