import { loggedInUser } from "./../../../../store/models/user.model";
import { NodeService } from "./../../../../core/services/node/node.service";
import { CourseListModel } from "./interface/modal-cursos-listado";
import { MatomoAnalyticsUtils } from "./../../../../core/utils/matomo-analytics.utils";
import { CourseTargetModel } from "./../../../../core/models/courses/course-target.model";
import { TargetsService } from "./../../../../core/services/targets/targets.service";
import { LOCALSTORAGESTRINGS } from "src/app/core/models/masters/localstorage.enum";
import { UsersService } from "../../../../core/services/users/users.service";
import { AuthorModel } from "../../../../core/models/masters/author-response.model";
import { ReportingComponent } from "../../reporting/reporting.component";
import {
	ChangeDetectionStrategy,
	Component,
	HostListener,
	OnInit,
	ViewChild,
	TemplateRef,
	ViewEncapsulation,
	ElementRef,
} from "@angular/core";

import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

// Models
import { FilterCourseModel, CourseModel } from "src/app/core/models/courses";

// Services
import { TranslateService } from "@ngx-translate/core";
import { CoursesService } from "src/app/core/services/courses";
import { LoginService } from "src/app/core/services/login";
import { AlertService } from "src/app/core/services/shared";

import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
} from "@angular/forms";
import {
	startWith,
	finalize,
	takeUntil,
	take,
	debounceTime,
} from "rxjs/operators";
import { SliceStringPipe } from "src/app/shared/pipes/slice-string.pipe";
import { Router } from "@angular/router";

import { ModalCursoDetailComponent } from "../modal-curso-detail/modal-curso-detail.component";
import { ModalCursoEditDetailComponent } from "../modal-curso-edit-detail/modal-curso-edit-detail.component";
import { QuizzesService } from "src/app/core/services/quizzes";
import { RecordarQuizPlayComponent } from "../../quiz-open/quiz-play/quiz-play.component";
import { RecordarQuizPlayMultipleComponent } from "../../quiz-open/quiz-play-multiple/quiz-play-multiple.component";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { DEFAULTCLOSEPADS } from "src/app/core/models/masters/masters.enum";
import { Utils } from "src/app/core/utils/utils";
import { ModalCelebrationComponent } from "../../modal-celebration/modal-celebration.component";
import { environment } from "src/environments/environment";
import { SigmaToolbarsService } from "src/app/core/services/sigma-toolbars/sigma-toolbars.service";
import { User } from "src/app/core/models/users/user.models";
import { Profiles } from "src/app/core/utils/profiles.enum";
import { MastersService } from "src/app/core/services/masters";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { GetDataService } from "src/app/core/services/get-data/get-data.service";
import { MateriaModel, PaisModel } from "src/app/core/models/masters";
import { NbPopoverDirective, NbWindowService, NbDialogService } from "@nebular/theme";
import { LocalStorage } from "src/app/core/utils";
import { ModalAceptarCancelarComponent } from "../../modal/modal-aceptar-cancelar/modal-aceptar-cancelar.component";
import { SosService } from "./../../../../core/services/sos/sos.service";
import { MeetingsComponent } from "src/app/pages/meetings/meetings.component";
import { ProjectTypeModel } from "src/app/core/models/masters/project-type.model";
import { GruposService } from "src/app/core/services/groups/grupos.service";
import { element } from "protractor";
import { GuiaProyectosComponent } from "./guia-proyectos/guia-proyectos.component";
import { AlertDarkProjectComponent } from "./alert-darkProject/alert-darkProject.component";

interface NavigatorWithConnection extends Navigator {
  connection?: {
    downlink: number;
  };
}

const USERTYPE = {
	STUDENT: "estudiante",
	EDITOR: "editor",
	TEACHER: "profesor",
};

const ELEMENTTYPE = {
	COURSE: "COURSE",
	GRAPH: "GRAPH",
	NODE: "NODE",
	QUIZ: "QUIZ",
};

const URLCELEBRATIONS: string = environment.celebrations;
@Component({
	selector: "app-modal-cursos-listado",
	templateUrl: "./modal-cursos-listado.component.html",
	styleUrls: ["./modal-cursos-listado.component.scss"],
	providers: [SliceStringPipe],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default,
})
export class ModalCursosListadoComponent implements OnInit {
	@ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
	@ViewChild("toggleGroupProjects") toggleGroupProjects: ElementRef;
	filtroInicial: string;
	screen: string;
	viewList: boolean = false;
	formulario: UntypedFormGroup;
	viewType = { nodes: false, quizzes: true };
	isLoading: boolean = true;
	filtro: FilterCourseModel = new FilterCourseModel();
	cargando = false;
	authorControl: UntypedFormControl = new UntypedFormControl("");

	isMyCourses: UntypedFormControl = new UntypedFormControl(true);
	trasversalProjects: boolean = false;
	activateHelpList: UntypedFormControl = new UntypedFormControl(false);
	filteredOptions: Observable<AuthorModel[]>;
	authors: AuthorModel[] = [];
	options: any;
	opcion: string;
	modoTraerNodo: any;
	modoTraerActividad: any;
	actividadesFlash: boolean = false;
	ModoAuto: any;
	ordenSeleccionado: number[] = [0];
	buscarNodo = false;
	courseTargets: CourseTargetModel[] = [];
	curso: CourseModel;
	mouseInterval: any = "";
	isShowFiles: boolean = true;
	isShowPads: boolean = false;
	isShowLabels: boolean = false;
	viewQuiz: boolean = false;
	idCourse: number = null;
	idSelectedCourse: number = null;
	idGraph: number = null;
	quiz: any = null;
	elements: any[] = [];
	answered: boolean = false;
	selectedGroupInfo: any;
	pendingSelectGroup: boolean;
	disableInstantActivityButton: boolean = false;
	profile: any;
	iconSelected = false;

	saveClicked: Subject<any> = new Subject<any>();
	saveClicked$: Observable<any> = this.saveClicked.asObservable();

	node: any = null;
	user: User = null;
	private destroy$ = new Subject();
	profileEditor: boolean;
	profileStudent: boolean;
	profileTeacher: boolean;

	isStudent: boolean;
	idGroup: number;
	rol: string;
	showElement: boolean = false;
	buttonText: string = "Filtros";
	isMobile: boolean = false;
	isMenuOpen: boolean = false;
	loggedInUser: any;

	cameFromCalendar: boolean = false;
	projectType: number = 0;
	langList = [
		{
			nombre: this.translateService.instant("CURSOAUTORES.TODOS"),
			idLang: null,
		},
		{
			nombre: "Español",
			idLang: 38,
		},
		{
			nombre: "Ingles",
			idLang: 36,
		},
		{
			nombre: "Alemán",
			idLang: 31,
		},
		{
			nombre: "Francés",
			idLang: 46,
		},
		{
			nombre: "Italiano",
			idLang: 71,
		},
		{
			nombre: "Portugués",
			idLang: 127,
		},
	];

	orderList = [
		{
			nombre: "A - Z",
			idOrder: 1,
		},
		{
			nombre: "Z - A",
			idOrder: 2,
		},
		{
			nombre: "Más recientes",
			idOrder: 3,
		},
		{
			nombre: "Más antiguos",
			idOrder: 4,
		},
		{
			nombre: "Editados Recientemente",
			idOrder: 5,
		},
	];

	filtersList = [
		{
			nombre: "Tipo de proyecto",
			id: 1,
			active: false,
			value: null,
		},
		{
			nombre: "Idioma",
			id: 2,
			active: false,
			value: null,
		},
		{
			nombre: "Asignatura",
			id: 3,
			active: false,
			value: null,
		},
		{
			nombre: "País",
			id: 4,
			active: false,
			value: null,
		},
		{
			nombre: "Nivel",
			id: 5,
			active: false,
			value: null,
		},
		{
			nombre: "Tranversal",
			id: 6,
			active: false,
			value: null,
		},
	];

	recurrentFiltersList = [
		{
			nombre: "Tipo de proyecto",
			id: 1,
			active: false,
			value: null,
		},
		{
			nombre: "mis proyectos",
			id: 2,
			active: false,
			value: null,
		},
		{
			nombre: "ordering",
			id: 3,
			active: false,
			value: null,
		},

		{
			nombre: "idioma",
			id: 4,
			active: false,
			value: null,
		},
		{
			nombre: "asignatura",
			id: 5,
			active: false,
			value: null,
		},
		{
			nombre: "pais",
			id: 6,
			active: false,
			value: null,
		},
		{
			nombre: "nivel",
			id: 7,
			active: false,
			value: null,
		},
	];

	projectTypeList = [
		{
			id: null,
			descripcion: this.translateService.instant("CURSOAUTORES.TODOS"),
		},
		{
			id: 1,
			descripcion: this.translateService.instant("EDITARCURSO.ESTUDIO"),
		},
		{
			id: 2,
			descripcion: this.translateService.instant("EDITARCURSO.PRACTICO"),
		},
		{
			id: 3,
			descripcion: this.translateService.instant("EDITARCURSO.PISA"),
		},
		/*
		{
			id: 4,
			descripcion: this.translateService.instant("MENUACTIONS.TEACHERTITLE18"),
		},
		*/
		{
			id: 5,
			descripcion: this.translateService.instant("EDITARCURSO.CONTROL"),
		},
	];

	subjectColors: { [key: string]: string } = {};
	subjectsList: MateriaModel[] = this.getDataService.appSubjects;
	subject_all: MateriaModel = {
		idSubject: null,
		subject: this.translateService.instant("CURSOAUTORES.TODOS"),
		description: null,
		idHumanKnowledge: null,
		humanKnowledgeName: null,
		acronym: null,
		color: null,
	};
	countriesList: PaisModel[] = this.getDataService.appCountries;
	country_all: PaisModel = {
		idCountry: null,
		country: "All",
		fieldOrder: null,
		idCountry_ISO3166: null,
		internetDomain: null,
		centers: null,
		countriesRegions: null,
		country_de: "Alle",
		country_es: "Todos",
		country_pt: "Todos",
	};
	filteredCursos: CourseModel[] = [];
	notCountryLevelsForThisCountry: boolean = false;
	levelsOfCountry: any[] = [];
	filterCountrySelected: boolean = false;
	selectedProjectType = null;
	selectedLang = null;
	selectedSubject = null;
	selectedCountry = null;
	selectedLevelCountry = null;
	selectedOrder = 1;
	fromButtom: boolean;
	historyFilterActive: boolean;
	listQM: any[];
	selectedButtonIndex: number = 0;
	idCurso: number = 0;
	infoGrupo: any;
	activeOrder = 0;
	activeFiltering: boolean = false;
	coursesOfProjectActive: boolean = false;

	fromSticker_proyecto: boolean = false; //viene true cuando quieres copiar el enlace de un proyecto
	fromSticker_grafo: boolean = false; //viene true cuando quierer copiar el enlace de un grafo
	parentModal: any; //se usa para que no cierre modales anteriores cuando sea necesario
	networkSpeed = 1;
	courseSubjects: any[] = [];
	preventGoToDetail: boolean = false;

	cursos: CourseModel[] = [];
	auxCursos: CourseModel[] = [];
	transCursos: CourseModel[];
	cursosHistorial: CourseModel[];
	treeCourses: CourseListModel[] = [];
	auxListCourses: CourseListModel[] = [];
	transListCourses: CourseListModel[];
	listCursosHistorial: CourseListModel[];
	myCourses: boolean = false;
	coursesInGroup: boolean = true;
	levelFromCalendar;
	subjectFromCalendar;

	constructor(
		public translateService: TranslateService,
		public coursesService: CoursesService,
		public loginService: LoginService,
		private alertService: AlertService,
		private modalService: NgbModal,
		public activeModal: NgbActiveModal,
		public router: Router,
		public userService: UsersService,
		private formBuild: UntypedFormBuilder,
		private quizService: QuizzesService,
		private ma: MatomoAnalyticsUtils,
		public targetsService: TargetsService,
		private utils: Utils,
		private nodeService: NodeService,
		private st: SigmaToolbarsService,
		private MastersService: MastersService,
		private toaster: ToasterService,
		public getDataService: GetDataService,
		private localStorage: LocalStorage,
		private windowService: NbWindowService,
		private SosService: SosService,
		private groupService: GruposService,
		private dialogService: NbDialogService
	) {
		this.formulario = this.formBuild.group({ filtrado: [""] });
		this.setupSearchListener();
		this.loggedInUser = this.loginService.getUser();
		this.subjectsList.unshift(this.subject_all);
		this.countriesList.unshift(this.country_all);
	}

	ngOnInit(): void {
		this.networkSpeed = this.getNetworkSpeed(); //primero verificamos la velocidad de internet
		this.user = JSON.parse(localStorage.getItem("user"));
		this.infoGrupo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		this.getScreen(); // con esto sabemos en que pantalla estamos actualmente
		if (this.loginService.esAutor()) {
			this.projectTypeList.splice(4,0,
				{
					id: 4,
					descripcion: this.translateService.instant(
						"MENUACTIONS.TEACHERTITLE18"
					),
				},
				// {
				// 	id: 5,
				// 	descripcion: this.translateService.instant("EDITARCURSO.CONTROL"),
				// }
			);
		}
		switch (this.loginService.getProfile()) {
			case "ESTUDIANTE":
				this.rol = USERTYPE.STUDENT;
				localStorage.removeItem("fromEditor");
				//localStorage.removeItem("selectedGroupInfo");
				break;
			case "PROFESOR":
				this.rol = USERTYPE.TEACHER;
				break;
			case "AUTOR":
				this.rol = USERTYPE.EDITOR;
				this.coursesInGroup = false; //para que se vean todos los proyectos como editor
				this.myCourses = true;
				break;
			case "CENTRO":
				this.rol = USERTYPE.TEACHER;
				this.infoGrupo = null;
				break;
			default:
				break;
		}
		this.isStudent =
			this.loginService.getProfile() === Profiles.Student ? true : false;

		this.userService
			.getListUserProfile()
			.pipe(takeUntil(this.destroy$), take(1))
			.subscribe((res: any) => {
				res.data.forEach((element) => {
					if (element.idProfile == 1) {
						this.profileEditor = true;
					}
					if (element.idProfile == 2) {
						this.profileStudent = true;
					}
					if (element.idProfile == 3) {
						this.profileTeacher = true;
					}
				});
			});
		this.isMyCourses.patchValue(this.loginService.esAutor());
		if (this.actividadesFlash) {
			this.cursosActividadesFlash();
			this.getDataToTree();
		} else if (!this.actividadesFlash && this.viewList) {
			this.getDataToTree();
		} else if (!this.actividadesFlash && !this.viewList) {
			if (this.filtroInicial == "proyectosCentro") {
				this.filtrarCursosCentro();
				this.getDataToTreeCenter();
			} else {
				this.filtrarCursos();
				this.getDataToTree();
			}
		}
		this.isMobile = window.innerWidth < 1200;
		let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		//this.idGroup = storedGroupInfo ? storedGroupInfo.idGroup : 0;
		if (storedGroupInfo) {
			this.pendingSelectGroup = false;
		}
	}

	ngAfterViewInit() {
		if (this.toggleGroupProjects) {
			this.toggleGroupProjects.nativeElement.checked =
				this.coursesOfProjectActive;
		}
	}

	ngOnDestroy() {
		this.destroy$.next(true);
	}

	getNetworkSpeed() {
		const navigatorWithConnection = navigator as NavigatorWithConnection;
		if (navigatorWithConnection.connection) {
			return navigatorWithConnection.connection.downlink;
		} else {
			return 10; // No se pudo obtener la velocidad de conexión
		}
	}

	setupSearchListener() {
		this.formulario
			.get("filtrado")
			.valueChanges.pipe(debounceTime(800)) //TIEMPO QUE TENEMOS PARA ESCRIBIR EN EL FILTRO EN MILISEGUNDOS
			.subscribe(() => {
				this.filtrarPorTitulo();
			});
	}

	filtrarPorTitulo() {
		let searchTerm;
		if (this.formulario.get("filtrado").value != "") {
			searchTerm = this.normalizeText(
				this.formulario.get("filtrado").value.toLowerCase()
			);
		}
		this.cursos = [...this.auxCursos];
		this.treeCourses = [...this.auxListCourses];
		this.filterCourses();
		if (searchTerm) {
			this.cursos = this.cursos.filter((curso) =>
				this.normalizeText(curso.courseTittle.toLowerCase()).includes(
					searchTerm
				)
			);
			this.treeCourses = this.treeCourses.filter((curso) =>
				this.normalizeText(curso.name.toLowerCase()).includes(searchTerm)
			);
		}
	}

	// Función para normalizar el texto eliminando tildes
	normalizeText(text) {
		return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
	}

	trasversalProject() {
		//this.trasversalProjects = !this.trasversalProjects;
		if (this.trasversalProjects) {
			this.cursos = this.cursos.filter((curso) => {
				this.transCursos = this.cursos;
				return curso.courseSubjects.length > 1;
			});
			this.treeCourses = this.treeCourses.filter((curso) => {
				this.transListCourses = this.treeCourses;
				return curso.courseSubjects.length > 1;
			});
		} else {
			this.checkValue();
		}
	}

	applyFilters(value, filter) {
		// Si es 4 intenta mostrar pensar creando, por eso falla
		if(!this.loginService.esAutor() && value == 4) {
			value = 5;
		}
		switch (filter) {
			case 0:
				this.filtersList[0].value = value;
				break;
			case 1:
				this.filtersList[1].value = value;
				break;
			case 2:
				this.filtersList[2].value = value;
				break;
			case 3:
				this.filtersList[3].value = value;
				break;
			case 4:
				this.filtersList[4].value = value;
				break;
			case 5:
				this.filtersList[5].value = value.target.checked;
				break;
			default:
				break;
		}
		this.activeFiltering = true;
		this.filterCourses();
		this.filtrarPorTitulo();
	}

	filterCourses(optionalCoursesToFilter?) {
		/** Para el mosaico */
		let batchToFilter;
		if (optionalCoursesToFilter != null) {
			batchToFilter = optionalCoursesToFilter;
		} else if (
			this.coursesOfProjectActive == true ||
			this.historyFilterActive == true
		) {
			batchToFilter = this.cursosHistorial;
		} else {
			batchToFilter = this.auxCursos;
		}
		this.cursos = batchToFilter.filter((curso) => {
			return (
				(!this.filtersList[0].value ||
					curso.project_type === this.filtersList[0].value) &&
				(!this.filtersList[1].value ||
					curso.language.idLanguage === this.filtersList[1].value) &&
				(!this.filtersList[2].value ||
					curso.courseSubjects.some(
						(subject) => subject.idSubject === this.filtersList[2].value
					)) &&
				(!this.filtersList[3].value ||
					curso.idCountry === this.filtersList[3].value) &&
				(!this.filtersList[4].value ||
					curso.countryLevel === this.filtersList[4].value) &&
				(!this.filtersList[5].value || curso.courseSubjects.length > 1)
			);
		});
		/** Para el modo lista */
		let batchToFilter2;
		if (optionalCoursesToFilter != null) {
			batchToFilter2 = optionalCoursesToFilter;
		} else if (
			this.coursesOfProjectActive == true ||
			this.historyFilterActive == true
		) {
			batchToFilter2 = this.listCursosHistorial;
		} else {
			batchToFilter2 = this.auxListCourses;
		}
		this.treeCourses = batchToFilter2.filter((curso) => {
			return (
				(!this.filtersList[0].value ||
					curso.project_type === this.filtersList[0].value) &&
				(!this.filtersList[1].value ||
					curso.idLanguage === this.filtersList[1].value) &&
				(!this.filtersList[2].value ||
					curso.idSubject === this.filtersList[2].value) &&
				(!this.filtersList[3].value ||
					curso.idCountry === this.filtersList[3].value) &&
				(!this.filtersList[4].value ||
					curso.countryLevel === this.filtersList[4].value) &&
				(!this.filtersList[5].value || curso.courseSubjects.length > 1)
			);
		});
		if (this.activeOrder != 0) {
			this.changeOrder(this.activeOrder);
		}
	}

	historyFilter() {
		if (this.historyFilterActive == true) {
			this.cursos = [];
			this.cursos = this.auxCursos;
			this.treeCourses = [];
			this.treeCourses = this.auxListCourses;
			this.historyFilterActive = !this.historyFilterActive;
			this.clearFilters();
			return;
		} else {
			this.clearFilters();
		}

		this.historyFilterActive = !this.historyFilterActive;
		if (this.loginService.esAutor()) {
			let localStoredHistory_editor = JSON.parse(
				localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHSHISTORY_EDITOR)
			);
			this.cursos = [];
			this.cursos = this.auxCursos
				.filter((curso) => localStoredHistory_editor.includes(curso.idCourse))
				.sort((a, b) => {
					const indexA = localStoredHistory_editor.indexOf(a.idCourse);
					const indexB = localStoredHistory_editor.indexOf(b.idCourse);
					return indexB - indexA;
				});
			this.treeCourses = [];
			this.treeCourses = this.auxListCourses
				.filter((curso) => localStoredHistory_editor.includes(curso.idCourse))
				.sort((a, b) => {
					const indexA = localStoredHistory_editor.indexOf(a.idCourse);
					const indexB = localStoredHistory_editor.indexOf(b.idCourse);
					return indexB - indexA;
				});
		} else {
			let localStoredHistory = JSON.parse(
				localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHSHISTORY)
			);
			this.cursos = [];
			this.cursos = this.auxCursos
				.filter((curso) => localStoredHistory.includes(curso.idCourse))
				.sort((a, b) => {
					const indexA = localStoredHistory.indexOf(a.idCourse);
					const indexB = localStoredHistory.indexOf(b.idCourse);
					return indexB - indexA;
				});
			this.treeCourses = [];
			this.treeCourses = this.auxListCourses
				.filter((curso) => localStoredHistory.includes(curso.idCourse))
				.sort((a, b) => {
					const indexA = localStoredHistory.indexOf(a.idCourse);
					const indexB = localStoredHistory.indexOf(b.idCourse);
					return indexB - indexA;
				});
		}
		this.cursosHistorial = this.cursos;
		this.listCursosHistorial = this.treeCourses;
	}

	changeOrder(order) {
		switch (order) {
			case 1:
				if (this.viewList) {
					this.treeCourses = this.treeCourses.sort((a, b) =>
						a.name.localeCompare(b.name)
					);
				} else {
					this.cursos.sort((a, b) =>
						a.courseTittle.localeCompare(b.courseTittle)
					);
				}
				break;
			case 2:
				if (this.viewList) {
					this.treeCourses = this.treeCourses.sort((a, b) =>
						b.name.localeCompare(a.name)
					);
				} else {
					this.cursos = this.cursos.sort((a, b) =>
						b.courseTittle.localeCompare(a.courseTittle)
					);
				}
				break;
			case 3:
				if (this.viewList) {
					this.treeCourses = this.treeCourses.sort(
						(a, b) =>
							new Date(b.creationDate).getTime() -
							new Date(a.creationDate).getTime()
					);
				} else {
					this.cursos = this.cursos.sort(
						(a, b) =>
							new Date(b.creationDate).getTime() -
							new Date(a.creationDate).getTime()
					);
				}
				break;
			case 4:
				if (this.viewList) {
					this.treeCourses = this.treeCourses.sort(
						(a, b) =>
							new Date(a.creationDate).getTime() -
							new Date(b.creationDate).getTime()
					);
				} else {
					this.cursos = this.cursos.sort(
						(a, b) =>
							new Date(a.creationDate).getTime() -
							new Date(b.creationDate).getTime()
					);
				}
				break;
			case 5:
				if (this.viewList) {
					this.treeCourses = this.treeCourses.sort(
						(a, b) =>
							new Date(b.editDate).getTime() - new Date(a.editDate).getTime()
					);
				} else {
					this.cursos = this.cursos.sort(
						(a, b) =>
							new Date(b.editDate).getTime() - new Date(a.editDate).getTime()
					);
				}

				break;
			default:
				break;
		}
		this.activeOrder = order;
	}

	clearFilters() {
		this.formulario.setValue({ filtrado: [""] });
		if (!this.filtroInicial) {
		}
		this.selectedProjectType = null;
		this.selectedLang = null;
		this.selectedSubject = null;
		this.selectedCountry = null;
		this.selectedLevelCountry = null;
		this.filtersList[0].value = null;
		this.trasversalProjects = false;
		this.cursos = this.auxCursos;
		this.treeCourses = this.auxListCourses;

		this.filtersList[0].value = null;
		this.filtersList[1].value = null;
		this.filtersList[2].value = null;
		this.filtersList[3].value = null;
		this.filtersList[4].value = null;
		this.filtersList[5].value = false;
		setTimeout(() => {
			this.applyFilters("ESP", 3);
			if (this.filtroInicial == "explicacionAsociativa") {
				this.applyFilters(1, 0);
			}
			if (this.filtroInicial == "abpCasos") {
				this.applyFilters(2, 0);
			}
			if (this.filtroInicial == "pensarCreando") {
				this.applyFilters(2, 0);
				this.coursesInGroup = true;
			}
			if (this.filtroInicial == "pensarCreando(ver)") {
				this.applyFilters(4, 0);
				this.coursesInGroup = true;
			}
			if (this.filtroInicial == "pruebaNivel") {
				this.applyFilters(5, 0);
			}
		}, 50);
		if (this.viewList) {
			this.treeCourses = this.auxListCourses;
		}
		this.activeFiltering = false;
	}

	clearFiltersList(filter) {
		switch (filter) {
			case 1:
				this.filtersList[0].value = null;
				this.selectedProjectType = null;
				break;
			case 2:
				this.filtersList[1].value = null;
				this.selectedLang = null;
				break;
			case 3:
				this.filtersList[2].value = null;
				this.selectedSubject = null;
				break;
			case 4:
				this.filtersList[3].value = null;
				this.selectedCountry = null;
				break;
			case 5:
				this.filtersList[4].value = null;
				this.selectedLevelCountry = null;
				break;
			case 6:
				this.filtersList[5].value = false;
				//this.selectedLevelCountry = null;
				break;
			default:
				break;
		}
		this.filterCourses();
	}

	searchLevels(country) {
		let country_aux = country == "" || country == null ? "ESP" : country;
		this.levelsOfCountry = [];
		const level_all = {
			idCountry: null,
			idCountryLevel: 0,
			countryLevel: this.translateService.instant("CURSOAUTORES.TODOS"),
			contryLevelColor: "bcbcbc",
		};

		this.coursesService
			.getCountryLevelsByCountry(country_aux)
			.subscribe((countryLevels) => {
				this.filterCountrySelected = true;
				if (countryLevels.data.length == 0) {
					this.notCountryLevelsForThisCountry = true;
					this.formulario.patchValue({ idCountryLevel: 0 });
				} else {
					this.notCountryLevelsForThisCountry = false;
					this.formulario.patchValue({
						idCountryLevel: countryLevels.data[0].idCountryLevel,
					});
				}
				this.levelsOfCountry = countryLevels.data;
				this.levelsOfCountry.unshift(level_all);
			});
	}

	openFiltersPanel() {
		this.fromButtom = true;
		if (this.popover.isShown == false) {
			this.iconSelected = !this.iconSelected;
			this.popover.show();
		}
	}

	closeFiltersPanel() {
		if (this.popover.isShown && this.fromButtom == false) {
			this.iconSelected = !this.iconSelected;
			this.popover.hide();
		}
		this.fromButtom = false;
	}

	obtenerDatosMaestros() {
		this.filteredOptions = this.authorControl.valueChanges.pipe(
			startWith(""),
			map((value) => (typeof value === "string" ? value : value.name)),
			map((name) => this._filter(name))
		);
	}
	private _filter(name: string): AuthorModel[] {
		if (name) {
			this.isMyCourses.setValue(false);
			this.isMyCourses.disable();
			return this.authors.filter(
				(author) => author.name.toLowerCase().indexOf(name.toLowerCase()) === 0
			);
		} else {
			this.isMyCourses.enable();
			return this.authors.slice();
		}
	}

	filtrarCursos() {
		if (this.cameFromCalendar == true) {
			return;
		}
		this.cargando = true;
		const filtradoValue = this.formulario.value;

		if (this.modoTraerActividad || this.modoTraerNodo) {
			const idGrupo = this.infoGrupo ? this.infoGrupo.idGroup : 0;
			this.coursesService
				.filtradoCursosNew2(
					filtradoValue.filtrado,
					false,
					this.rol,
					idGrupo,
					this.screen
				)
				.subscribe(
					(res) => {
						this.cursos = [];
						for (let index = 0; index < res.length; index++) {
							const element = res[index];
							if (
								this.filtroInicial != "pensarCreando(ver)" &&
								(element.isPensarCreando === 0 ||
									(element.user.idUser === this.loggedInUser.idUser &&
										element.isPensarCreando === 1))
							) {
								this.cursos.push(element);
							} else if (
								this.filtroInicial == "pensarCreando(ver)" &&
								element.isPensarCreando === 1
							) {
								this.cursos.push(element);
							}
						}
						this.auxCursos = this.cursos;

						this.cargando = false;
					},
					(err) => {
						console.log(err);
						this.cargando = false;
					}
				);
		} else {
			//revisar el form y el check y enviarlo
			const myCourses = this.isMyCourses.value;
			let idGrupo = 0;
			if (this.loginService.getProfile() != "CENTRO" && this.loginService.getProfile() != "AUTOR") {
				idGrupo = this.infoGrupo ? this.infoGrupo.idGroup : 0;
			}
			this.coursesService
				.filtradoCursosNew2(
					filtradoValue.filtrado,
					this.loginService.esAutor() ? true : myCourses,
					this.rol,
					idGrupo,
					this.screen
				)
				.subscribe(
					(res) => {
						this.cursos = [];
						for (let index = 0; index < res.length; index++) {
							const element = res[index];
							if (
								this.filtroInicial != "pensarCreando(ver)" &&
								(element.isPensarCreando === 0 ||
									(element.user.idUser === this.loggedInUser.idUser &&
										element.isPensarCreando === 1))
							) {
								this.cursos.push(element);
							} else if (
								this.filtroInicial == "pensarCreando(ver)" &&
								(element.isPensarCreando === 1 ||
									(element.user.idUser === this.loggedInUser.idUser &&
										element.isPensarCreando === 1))
							) {
								this.cursos.push(element);
							}
						}
						this.auxCursos = this.cursos;
						this.cargando = false;

						if (this.infoGrupo == null || this.idGroup == 0) {
							this.coursesInGroup = false;
						} else {
							this.coursesInGroup = true;
							/**
							this.getListadoCursosGrupo(true);
							*/
						}
						this.auxCursos = this.cursos;
						this.applyFilters("ESP", 3);
						if (this.filtroInicial == "explicacionAsociativa") {
							this.applyFilters(1, 0);
						}
						if (this.filtroInicial == "abpCasos") {
							this.applyFilters(2, 0);
						}
						if (this.filtroInicial == "pensarCreando") {
							this.applyFilters(2, 0);
							this.coursesInGroup = true;
						}
						if (this.filtroInicial == "pensarCreando(ver)") {
							this.applyFilters(4, 0);
							this.coursesInGroup = true;
						}
						if (this.filtroInicial == "pruebaNivel") {
							this.applyFilters(5, 0);
						}
						this.selectedCountry = "ESP";
						this.filtersList[3].value = "ESP";
						this.searchLevels("ESP");
						setTimeout(() => {
							this.cargando = false;
						}, 600);

						for (let index = 0; index < this.cursos.length; index++) {
							let element = this.cursos[index];
							element.averageKnowledge = element.averageKnowledge.toFixed(1);
						}
					},
					(err) => {
						console.log(err);
						this.cargando = false;
					}
				);
		}
	}

	filtrarCursosCentro() {
		this.cargando = true;
		let idCenter: number = JSON.parse(
			this.localStorage.getItem(LOCALSTORAGESTRINGS.CONFIGSITE)
		).idCenter;
		this.coursesService.filtradoCursosCentro(idCenter).subscribe(
			(res) => {
				this.cursos = [];
				for (let index = 0; index < res.length; index++) {
					const element = res[index];
					if (
						this.filtroInicial != "pensarCreando(ver)" &&
						(element.isPensarCreando === 0 ||
							(element.user.idUser === this.loggedInUser.idUser &&
								element.isPensarCreando === 1))
					) {
						this.cursos.push(element);
					} else if (
						this.filtroInicial == "pensarCreando(ver)" &&
						(element.isPensarCreando === 1 ||
							(element.user.idUser === this.loggedInUser.idUser &&
								element.isPensarCreando === 1))
					) {
						this.cursos.push(element);
					}
				}
				this.auxCursos = this.cursos;
				this.cargando = false;
				if (this.infoGrupo == null || this.idGroup == 0) {
					this.coursesInGroup = false;
				} else {
					this.coursesInGroup = true;
				}
				this.auxCursos = this.cursos;
				this.applyFilters("ESP", 3);
				this.selectedCountry = "ESP";
				this.filtersList[3].value = "ESP";
				this.searchLevels("ESP");
				setTimeout(() => {
					this.cargando = false;
				}, 600);
				for (let index = 0; index < this.cursos.length; index++) {
					let element = this.cursos[index];
					element.averageKnowledge = element.averageKnowledge.toFixed(1);
				}
			},
			(err) => {
				console.log(err);
				this.cargando = false;
			}
		);
	}

	fillArrayWithSubjectsNames(batchOfIds) {
		let subjects = [];
		batchOfIds.forEach((id) => {
			const subject = this.subjectsList.find(
				(subject) => subject.idSubject === id
			);
			if (!subjects.some((s) => s.name === subject.subject)) {
				subjects.push({
					name: subject.subject,
					color: this.generateHexCode(),
				});
			}
		});
		return subjects;
	}

	generateHexCode() {
		const letters = "89ABCDEF";
		let color = "#";
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 8)];
		}
		return color;
	}

	// Método para obtener el color de una materia
	public getSubjectColor(subject: string): string {
		// Si el color para la materia ya fue generado, se devuelve
		if (this.subjectColors[subject]) {
			return this.subjectColors[subject];
		}
		// Si no, se genera un nuevo color, se almacena y se devuelve
		const newColor = this.generateHexCode();
		this.subjectColors[subject] = newColor;
		return newColor;
	}

	cursosActividadesFlash() {
		this.cargando = true;
		let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		this.idGroup = storedGroupInfo ? storedGroupInfo.idGroup : 0;
		this.coursesService
			.getCoursesListResolved(this.idGroup)
			.subscribe((res) => {
				this.cursos = [];
				for (let index = 0; index < res.data.length; index++) {
					const element = res.data[index];
					if (element.isPensarCreando === 0) {
						this.cursos.push(element);
					}
				}
				this.auxCursos = this.cursos;
				this.cargando = false;
				if (this.infoGrupo == null || this.idGroup == 0) {
					this.coursesInGroup = false;
				} else {
					this.coursesInGroup = true;
				}
				this.auxCursos = this.cursos;
				this.applyFilters("ESP", 3);
				if (this.filtroInicial == "explicacionAsociativa") {
					this.applyFilters(1, 0);
				}
				if (this.filtroInicial == "abpCasos") {
					this.applyFilters(2, 0);
				}
				if (this.filtroInicial == "pensarCreando") {
					this.applyFilters(2, 0);
					this.coursesInGroup = true;
				}
				if (this.filtroInicial == "pensarCreando(ver)") {
					this.applyFilters(4, 0);
					this.coursesInGroup = true;
				}
				if (this.filtroInicial == "pruebaNivel") {
					this.applyFilters(5, 0);
				}
				this.selectedCountry = "ESP";
				this.filtersList[3].value = "ESP";
				this.searchLevels("ESP");
				setTimeout(() => {
					this.cargando = false;
				}, 600);
				for (let index = 0; index < this.cursos.length; index++) {
					let element = this.cursos[index];
					element.averageKnowledge = element.averageKnowledge.toFixed(1);
				}
			}),
			(err) => {
				console.log(err);
				this.cargando = false;
			};
	}

	verDetalleCurso(curso) {
		if (this.preventGoToDetail == false) {
			if (this.fromSticker_proyecto) {
				this.openCursoEstudiante(curso.idCourse, curso.inGroup);
			} else {
				this.popover.hide();
				if (this.actividadesFlash) {
					this.openCursoEstudiante(curso.idCourse, 1);
				} else if (!this.actividadesFlash && this.opcion === "editar") {
					this.openCursoEditor(curso.idCourse);
				} else if (
					!this.actividadesFlash &&
					this.opcion !== "editar" &&
					curso.inGroup == 1
				) {
					this.openCursoEstudiante(curso.idCourse, 1);
				} else if (
					!this.actividadesFlash &&
					this.opcion !== "editar" &&
					curso.inGroup == 0 &&
					this.loginService.esProfesor()
				) {
					const modalRef = this.modalService.open(AlertDarkProjectComponent, {
						scrollable: true,
						windowClass: MODAL_DIALOG_TYPES.W30,
					});
					modalRef.result.then((result) => {
						if (result == "open") {
							this.openCursoEstudiante(curso.idCourse, 0);
						} else if (result == "add") {
							this.groupService
								.agregarCurso(this.infoGrupo.idGroup, curso.idCourse)
								.subscribe((res) => {
									if (res) {
										this.toaster.success(
											this.translateService.instant(
												"MODALLISTCOURSES.ADDEDCOURSE"
											)
										);
										this.openCursoEstudiante(curso.idCourse, curso.inGroup);
									}
								});
						}
					});
				} else if (
					!this.actividadesFlash &&
					this.opcion !== "editar" &&
					curso.inGroup == 0 &&
					(this.loginService.esEstudiante() || this.loginService.esCentro())
				) {
					this.openCursoEstudiante(curso.idCourse);
				}
			}
		}
	}

	abrirListadoQuizes(idCurso) {
		this.coursesService.recordarQuizesListado(idCurso).subscribe((res: any) => {
			if (res.data.length > 0) {
				if (res.data.length < 20) {
					this.toaster.success(this.translateService.instant("CURSOS.ERROR1"));
				} else {
					this.ModoAuto = res.data;
					let indice = 0;
					this.openModalModeAuto(indice);
				}
			} else
				this.toaster.success(this.translateService.instant("CURSOS.ERROR1"));
		});
	}

	openModalModeAuto(indice) {
		if (indice < this.ModoAuto.length - 1) {
			if (
				this.ModoAuto[indice].idQuiz !== this.ModoAuto[indice].idQuizOriginal
			) {
				this.quizService
					.getQuizMultipleCanvasQuizMultiple(
						this.ModoAuto[indice].idQuiz,
						this.ModoAuto[indice].idCourse,
						this.ModoAuto[indice].idTarget
					)
					.pipe(finalize(() => (this.isLoading = false)))
					.subscribe((res: any) => {
						const modalRef = this.modalService.open(
							RecordarQuizPlayMultipleComponent,
							{
								scrollable: true,
								windowClass: MODAL_DIALOG_TYPES.W100,
								backdrop: "static",
							}
						);
						modalRef.componentInstance.quiz = {
							...res.quiz,
							user: res.quiz.user,
							idOriginal: res.quiz.idOriginal,
							id: res.quiz.idQuiz,
							originalX: res.quiz.originalX,
							originalY: res.quiz.originalY,
							size: res.quiz.size,
							sizeQuiz: res.quiz.sizeQuiz,
							x: res.quiz.x,
							y: res.quiz.y,
						};
						modalRef.componentInstance.elements = res.elements;
						modalRef.componentInstance.listQuiz = this.ModoAuto;

						modalRef.result.then(
							(res) => {
								if (res < this.ModoAuto.length - 1) {
									this.openModalModeAuto(res);
								} else
									this.toaster.success(
										this.translateService.instant("CURSOS.OKALLQUIZZES")
									);
							},
							(err) => {
								this.toaster.success(
									this.translateService.instant("CURSOS.OKALLQUIZZES")
								);
							}
						);
					});
			} else {
				this.quizService
					.getQuiz(
						this.ModoAuto[indice].idQuiz,
						this.ModoAuto[indice].idCourse,
						this.ModoAuto[indice].idTarget
					)
					.pipe(finalize(() => (this.isLoading = false)))
					.subscribe((res: any) => {
						const modalRef = this.modalService.open(RecordarQuizPlayComponent, {
							scrollable: true,
							windowClass: MODAL_DIALOG_TYPES.W100,
							backdrop: "static",
						});
						modalRef.componentInstance.quiz = {
							...res.quiz,
							user: res.quiz.user,
							idOriginal: res.quiz.idOriginal,
							id: res.quiz.idQuiz,
							originalX: res.quiz.originalX,
							originalY: res.quiz.originalY,
							size: res.quiz.size,
							sizeQuiz: res.quiz.sizeQuiz,
							x: res.quiz.x,
							y: res.quiz.y,
						};
						modalRef.componentInstance.elements = res.elements;
						modalRef.componentInstance.listQuiz = this.ModoAuto;

						modalRef.result.then(
							(res) => {
								if (res < this.ModoAuto.length - 1) {
									this.openModalModeAuto(res);
								} else {
									this.toaster.success(
										this.translateService.instant("CURSOS.OKALLQUIZZES")
									);
								}
							},
							(err) => {
								this.toaster.success(
									this.translateService.instant("CURSOS.OKALLQUIZZES")
								);
							}
						);
					});
			}
		} else
			this.toaster.success(
				this.translateService.instant("CURSOS.OKALLQUIZZES")
			);
	}

	openCursoEstudiante(idCurso: any, inGroup?: number) {
		if (
			(this.fromSticker_grafo && this.fromSticker_proyecto) ||
			(!this.fromSticker_grafo && !this.fromSticker_proyecto)
		) {
			this.ma.event("click", "view_item", "Curso");
			const modalRef = this.modalService.open(ModalCursoDetailComponent, {
				scrollable: false,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			});

			modalRef.componentInstance.cameFromCalendar = this.cameFromCalendar;
			modalRef.componentInstance.id = idCurso;
			modalRef.componentInstance.modoTraerNodo = this.modoTraerNodo;
			modalRef.componentInstance.modoTraerActividad = this.modoTraerActividad;
			modalRef.componentInstance.profileTeacher = this.profileTeacher;
			//mandamos el idGroup cuando se tenga seleccionada la opcion de Proyectos del grupo
			if (this.coursesOfProjectActive) {
				modalRef.componentInstance.idGroup = this.idGroup;
			} else {
				modalRef.componentInstance.idGroup = 0;
			}
			modalRef.componentInstance.actividadesFlash = this.actividadesFlash;
			modalRef.componentInstance.fromSticker_grafo = this.fromSticker_grafo;
			modalRef.componentInstance.filtroInicial = this.filtroInicial;
			modalRef.componentInstance.infoGrupo = this.infoGrupo;
			modalRef.componentInstance.inGroup = inGroup ? inGroup : 0;

			modalRef.result.then(
				(result) => {
					if (this.cameFromCalendar == true) {
						this.closeModal(result);
					}
					switch (result) {
						case "":
							// si no devuelven nada no hacemos nada
							//this.coursesOfProjectActive = false;
							if (this.toggleGroupProjects) {
								this.toggleGroupProjects.nativeElement.checked =
									this.coursesOfProjectActive;
							}
							break;
						case "Curso borrado":
							// tslint:disable-next-line: max-line-length
							this.alertService.success(
								this.translateService.instant(
									"CURSOS.ELCURSOSEHABORRADOCORRECTAMENTE"
								),
								AlertService.AlertServiceContextValues.ModalCurso
							);
							break;
						case "Curso modificado":
							// tslint:disable-next-line: max-line-length
							this.alertService.success(
								this.translateService.instant(
									"CURSOS.ELCURSOSEHAMODIFICADOCORRECTAMENTE"
								),
								AlertService.AlertServiceContextValues.ModalCurso
							);
							break;
						case "Abrir mapa":
							// Esta opcion se produce cuando un estudiante ha seleccionado un mapa, hay que cerrar todas las modales
							this.closeModal(result);
							break;
						case "Editar nodos mapa":
							// Esta opcion se produce cuando un autor ha seleccionado editar los nodos, hay que cerrar todas las modales
							this.closeModal(result);
							break;
						case "Ver como lista":
							// Esta opcion se produce cuando un estudiante ha seleccionado ver como lista desde la pantalla de grafos
							this.viewList = true;
							this.getDataToTree();
							break;
						default:
							// Esta opcion se produce cuando se esta obteniendo la url del grafo seleccionado para los stickers
							if (this.fromSticker_grafo) {
								this.closeModal(result);
							}
							break;
					}
					// Refresco el listado
					if (this.actividadesFlash) {
						this.cursosActividadesFlash();
					} else if (!this.actividadesFlash && this.viewList) {
						this.getDataToTree();
					} else if (!this.actividadesFlash && !this.viewList) {
						this.filtrarCursos();
					}
				},
				(reason) => {}
			);
		} else {
			// Esta opcion se produce cuando se esta obteniendo la url del proyecto seleccionado para los stickers
			const toSticker = {
				msg: "url",
				idCurso: idCurso,
			};
			this.closeModal(toSticker);
		}
	}

	closeModal(sendData?: any) {
		if (this.localStorage.getItem("fromEditor")) {
			this.MastersService.cambiarPerfil(Profiles.Author); // regresamos al perfil de editor si venimos de ese menu
			localStorage.removeItem("fromEditor");
		}
		this.popover.hide();
		this.activeModal.close(sendData);
		this.st.changeUpdateGraph(true);
	}

	close() {
		this.activeModal.close();
		this.st.changeUpdateGraph(true);
	}

	openCursoEditor(idCurso: number) {
		const modalRef = this.modalService.open(ModalCursoEditDetailComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});

		modalRef.componentInstance.id = idCurso;
		modalRef.result.then(
			(result) => {
				switch (result) {
					case "Curso borrado":
						// tslint:disable-next-line: max-line-length
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHABORRADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Curso modificado":
						// tslint:disable-next-line: max-line-length
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHAMODIFICADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Abrir mapa":
						// Esta opcion se produce cuando un estudiante ha seleccionado un mapa, hay que cerrar todas las modales
						this.closeModal(result);
						break;
					case "Editar nodos mapa":
						// Esta opcion se produce cuando un autor ha seleccionado editar los nodos, hay que cerrar todas las modales
						this.closeModal(result);
						break;
				}

				// Refresco el listado
				this.filtrarCursos();
				this.getDataToTree();
			},
			(reason) => {}
		);
	}

	updateUrl(event: any) {
		event.target.src = "../../assets/images/no-image.png";
	}

	nuevoCurso() {
		//const modalRef = this.modalService.open(ModalEditarCursoComponent, { scrollable: true, windowClass: `${MODAL_DIALOG_TYPES.W100} ${MODAL_DIALOG_TYPES.w100NEW}` });
		//modalRef.componentInstance.clickSidebar = true;
		//modalRef.componentInstance.id = 'nuevo';
		//modalRef.result.then((result) => { this.filtrarCursos(); }, (reason) => { });

		//localStorage.setItem('dontLoadMenu', 'true')
		this.utils.loadMenu = false;
		this.MastersService.nuevoCurso(Profiles.Author);
		this.activeModal.close();

		//this.coursesService.createProjectAutomatically().subscribe(res => {
		//	this.router.navigate([`/course/${res.data.idCourse}/graph/${res.data.idTarget}`]);
		//	window.location.reload()
		//})
	}

	checkValue() {
		const filtradoValue = this.formulario.value;
		this.treeCourses = [];
		this.getDataToTree();
		const idGrupo = this.infoGrupo ? this.infoGrupo.idGroup : 0;
		this.coursesService
			.filtradoCursosNew2(
				filtradoValue.filtrado,
				this.isMyCourses.value,
				this.rol,
				idGrupo,
				this.screen
			)
			.subscribe(
				(res) => {
					this.cursos = [];
					for (let index = 0; index < res.length; index++) {
						const element = res[index];
						if (
							element.isPensarCreando === 0 ||
							(element.user.idUser === this.loggedInUser.idUser &&
								element.isPensarCreando === 1)
						) {
							this.cursos.push(element);
						}
					}
					this.auxCursos = this.cursos;
					this.changeOrder(this.activeOrder);
					this.filterCourses(res);
					this.cargando = false;
				},
				(err) => {
					console.log(err);
					this.cargando = false;
				}
			);
		this.authorControl.setValue("");
		this.authorControl.disable();
	}

	helpListToggle(event) {
		if (event == false) {
			this.treeCourses = this.auxListCourses;
			setTimeout(() => {
				this.applyFilters("ESP", 3);
				if (this.filtroInicial == "explicacionAsociativa") {
					this.applyFilters(1, 0);
				}
				if (this.filtroInicial == "abpCasos") {
					this.applyFilters(2, 0);
				}
				if (this.filtroInicial == "pensarCreando") {
					this.applyFilters(2, 0);
					this.coursesInGroup = true;
				}
				if (this.filtroInicial == "pensarCreando(ver)") {
					this.applyFilters(4, 0);
					this.coursesInGroup = true;
				}
				if (this.filtroInicial == "pruebaNivel") {
					this.applyFilters(5, 0);
				}
				this.selectedCountry = "ESP";
				this.filtersList[3].value = "ESP";
				this.searchLevels("ESP");
				this.cargando = false;
			}, 100);
		} else if (event == true) {
			this.coursesService.coursesWithHelpAsked().subscribe((result) => {
				this.treeCourses = result;
				this.cargando = false;
			});
		}
	}

	changeView(list) {
		this.viewList = list;
		if (this.actividadesFlash) {
			this.cursosActividadesFlash();
			this.cargando = this.cursos.length ? false : true;
		}
		/*
		if (this.viewList) {
			this.isLoading = false;
			this.getDataToTree();
			this.cargando = this.treeCourses.length ? false : true;
		} else if (this.actividadesFlash) {
			this.cursosActividadesFlash();
			this.cargando = this.cursos.length ? false : true;
		} else if (!this.actividadesFlash) {
			this.filtrarCursos();
			this.cargando = this.cursos.length ? false : true;
		}
			*/
	}

	getDataToTree() {
		if (this.cameFromCalendar == true) {
			this.coursesService
				.coursesListByCalendar(this.projectType)
				.subscribe((result) => {
					if(this.subjectFromCalendar != null){
						result = result.filter(course => course.subject.idSubject == this.subjectFromCalendar);
					}

					if(this.levelFromCalendar != null){
						result = result.filter(
							(course) =>
								course.countryLevel == this.levelFromCalendar.idCountryLevel
						);
					}

					this.auxListCourses = result;
					this.treeCourses = result;
					for (let index = 0; index < result.length; index++) {
						const element = result[index];
						this.cursos.push(element);
					}

					this.cargando = false;
				});
		} else {
			let idGrupo = 0;
			if (this.loginService.getProfile() != "CENTRO" && this.loginService.getProfile() != "AUTOR") {
				idGrupo = this.infoGrupo ? this.infoGrupo.idGroup : 0;
			}
			const datos = JSON.parse(
				this.localStorage.getItem(LOCALSTORAGESTRINGS.USER)
			);
			const filter: string = this.formulario.value.filtrado;
			let rol: string =
				datos.profile.toLowerCase() === "autor"
					? "editor"
					: datos.profile.toLowerCase() === "centro"
					? "profesor"
					: datos.profile.toLowerCase();
			if (!this.treeCourses.length && !this.actividadesFlash) {
				this.coursesService
					.coursesListMode(
						filter,
						rol,
						this.isMyCourses.value,
						idGrupo,
						this.screen
					)
					.subscribe((result) => {
						this.auxListCourses = result;
						this.treeCourses = result;
						setTimeout(() => {
							this.applyFilters("ESP", 3);
							if (this.filtroInicial == "explicacionAsociativa") {
								this.applyFilters(1, 0);
							}
							if (this.filtroInicial == "abpCasos") {
								this.applyFilters(2, 0);
							}
							if (this.filtroInicial == "pensarCreando") {
								this.applyFilters(2, 0);
								this.coursesInGroup = true;
							}
							if (this.filtroInicial == "pensarCreando(ver)") {
								this.applyFilters(4, 0);
								this.coursesInGroup = true;
							}
							if (this.filtroInicial == "prueba") {
								this.applyFilters(5, 0);
							}
							this.selectedCountry = "ESP";
							this.filtersList[3].value = "ESP";
							this.searchLevels("ESP");
						}, 100);
						this.cargando = false;
					});
			} else if (!this.treeCourses.length && this.actividadesFlash) {
				this.coursesService
					.coursesListModeForFlash(filter, this.isMyCourses.value)
					.subscribe((result) => {
						this.auxListCourses = result;
						this.treeCourses = result;
						setTimeout(() => {
							this.applyFilters("ESP", 3);
							if (this.filtroInicial == "explicacionAsociativa") {
								this.applyFilters(1, 0);
							}
							if (this.filtroInicial == "abpCasos") {
								this.applyFilters(2, 0);
							}
							if (this.filtroInicial == "pensarCreando") {
								this.applyFilters(2, 0);
								this.coursesInGroup = true;
							}
							if (this.filtroInicial == "pensarCreando(ver)") {
								this.applyFilters(4, 0);
								this.coursesInGroup = true;
							}
							if (this.filtroInicial == "pruebaNivel") {
								this.applyFilters(5, 0);
							}
							this.selectedCountry = "ESP";
							this.filtersList[3].value = "ESP";
							this.searchLevels("ESP");
						}, 100);
						this.cargando = false;
					});
			}
		}
	}

	getDataToTreeCenter() {
		let idCenter: number = JSON.parse(
			this.localStorage.getItem(LOCALSTORAGESTRINGS.CONFIGSITE)
		).idCenter;
		this.coursesService.coursesListModeCenter(idCenter).subscribe((result) => {
			this.auxListCourses = result;
			this.treeCourses = result;
			setTimeout(() => {
				this.applyFilters("ESP", 3);
				this.selectedCountry = "ESP";
				this.filtersList[3].value = "ESP";
				this.searchLevels("ESP");
			}, 100);
			this.cargando = false;
		});
	}

	filtrarCursosLupa() {
		const filtradoValue = this.formulario.value;
		if (filtradoValue.filtrado.length >= 3) {
			if (this.viewList) {
				this.treeCourses = [];
				this.getDataToTree();
			} else {
				this.cargando = true;
				if (this.loginService.esAutor()) {
					if (this.activeFiltering == false) {
						const idGrupo = this.infoGrupo ? this.infoGrupo.idGroup : 0;
						this.coursesService
							.filtradoCursosNew2(
								filtradoValue.filtrado,
								true,
								this.rol,
								idGrupo,
								this.screen
							)
							.subscribe(
								(res) => {
									this.cursos = [];
									for (let index = 0; index < res.length; index++) {
										const element = res[index];
										if (
											element.isPensarCreando === 0 ||
											(element.user.idUser === this.loggedInUser.idUser &&
												element.isPensarCreando === 1)
										) {
											this.cursos.push(element);
										}
									}
									this.cargando = false;
								},
								(err) => {
									console.log(err);
									this.cargando = false;
								}
							);
					} else {
						this.cursos = this.cursos.filter((course: any) => {
							return course.courseTittle
								.toLowerCase()
								.includes(filtradoValue.filtrado.toLowerCase());
						});
						this.cargando = false;
					}
				} else {
					const myCourses = this.isMyCourses.value;
					if (this.activeFiltering == false) {
						let idGrupo = 0;
						if (this.loginService.getProfile() != "CENTRO") {
							idGrupo = this.infoGrupo ? this.infoGrupo.idGroup : 0;
						}
						this.coursesService
							.filtradoCursosNew2(
								filtradoValue.filtrado,
								myCourses,
								this.rol,
								idGrupo,
								this.screen
							)
							.subscribe(
								(res) => {
									this.cursos = [];
									for (let index = 0; index < res.length; index++) {
										const element = res[index];
										if (
											element.isPensarCreando === 0 ||
											(element.user.idUser === this.loggedInUser.idUser &&
												element.isPensarCreando === 1)
										) {
											this.cursos.push(element);
										}
									}
									this.cargando = false;
								},
								(err) => {
									console.log(err);
									this.cargando = false;
								}
							);
					} else {
						this.cursos = this.cursos.filter((course: any) => {
							const filtradoLowerCase = filtradoValue.filtrado
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.toLowerCase();

							return (
								course.courseTittle
									.normalize("NFD")
									.replace(/[\u0300-\u036f]/g, "")
									.toLowerCase()
									.includes(filtradoLowerCase) ||
								course.idCourse
									.toString()
									.normalize("NFD")
									.replace(/[\u0300-\u036f]/g, "")
									.toLowerCase()
									.includes(filtradoLowerCase)
							);
						});
						this.cargando = false;
					}
				}
			}
		} else if (filtradoValue.filtrado.length === 0) {
			this.filtrarCursos();
		}
	}

	// Función para filtrar un array de objetos basado en una propiedad llamada 'courseTitle'.

	filterByCourseTitle(array: any[], courseTittle: string): any[] {
		// Filtra el array y devuelve solo los objetos que coincidan con el título del curso.

		return array.filter((obj) => obj.courseTittle.includes(courseTittle));
	}

	verDetalleMapa(idGrafo: number, idCourse: number) {
		if (this.modoTraerNodo) localStorage.setItem("modoTraerNodo", "true");
		else if (this.modoTraerActividad)
			localStorage.setItem(LOCALSTORAGESTRINGS.GETACTIVITY, "true");

		this.buscarNodo = JSON.parse(localStorage.getItem("buscarActividad"));
		this.router.navigateByUrl(`/course/${idCourse}/graph/${idGrafo}`);
		this.closeModal("Abrir mapa");
		//this.close();
	}

	//Función que recibe los valores del elemento seleccionado en el listado de los cursos
	clickElement(element: CourseListModel) {
		const isAuthor: boolean = this.loginService.esAutor();

		//De forma provisional, sólo las acciones se contemplan desde el rol de estudiante para poder visualizar los nodos y actividades
		if (!isAuthor && !this.actividadesFlash) {
			this.node = this.quiz = this.idSelectedCourse = null;
			this.answered = false;

			switch (element.type) {
				case ELEMENTTYPE.COURSE:
					break;
				case ELEMENTTYPE.GRAPH:
					break;
				case ELEMENTTYPE.NODE:
					if (this.activateHelpList.value == true) {
						let helpPetition;
						this.SosService.getHelpPetitions().subscribe(
							(data) => {
								helpPetition = data.data.sosListSkilled.filter(
									(item) => item.idNode === element.idNode
								);
								const modalRef = this.modalService.open(
									ModalAceptarCancelarComponent,
									{
										scrollable: true,
										windowClass: MODAL_DIALOG_TYPES.W30,
									}
								);

								modalRef.componentInstance.mensaje =
									this.translateService.instant("SOS.YOUGONNAGIVEHELP");

								modalRef.result.then((result: boolean) => {
									if (result == true) {
										let updateData = {
											...helpPetition[0],
											idTutor: this.loggedInUser.idUser,
											helped: true,
										};
										this.SosService.updateHelpPetition(updateData).subscribe(
											(result) => {
												if (result.data) {
													this.SosService.setRoomName(
														updateData.idTutor +
															"-" +
															updateData.idNode +
															"-" +
															updateData.idUser
													);
													this.SosService.currentHelpPetition =
														updateData.idSos;
													this.windowService.open(MeetingsComponent, {
														title: ` ${this.translateService.instant(
															"SOS.GIVINGHELPON"
														)}: ${helpPetition[0].tittle}`,
													});

													setTimeout(() => {
														this.SosService.getHelpPetitionById(
															helpPetition[0].idSos
														).subscribe((res: any) => {
															if (res.data.connected == false) {
																const modalRef = this.modalService.open(
																	ModalAceptarCancelarComponent,
																	{
																		scrollable: true,
																		windowClass: MODAL_DIALOG_TYPES.W30,
																	}
																);

																modalRef.componentInstance.mensaje =
																	this.translateService.instant(
																		"SOS.USERNOTCONNECTING"
																	);
															}
														});
													}, 35000);
												} else
													this.toaster.error(
														this.translateService.instant("SOS.PETITIONEXISTS")
													);
											},
											(err) => {
												this.toaster.error("errorMessage");
											}
										);
									}
								});
							},
							(error) => {
								console.log(error);
							}
						);
					} else {
						this.isLoading = true;
						this.idSelectedCourse = element.idCourse;
						this.nodeService
							.getNode(element.idNode, element.idCourse, element.idTarget)
							.pipe(finalize(() => (this.isLoading = false)))
							.subscribe((result) => {
								this.node = result.data[0];
							});
					}
					break;
				case ELEMENTTYPE.QUIZ:
					this.isLoading = true;
					this.idSelectedCourse = element.idCourse;
					this.getQuiz(
						element.idQuiz,
						element.idCourse,
						element.idTarget,
						element.multi
					);
					break;
			}
		} else if (this.actividadesFlash) {
			this.verDetalleCurso(element.idCourse);
		}
	}

	private getQuiz(
		idQuiz: number,
		idCourse: number,
		idTarget: number,
		isMultiplexed: boolean
	) {
		this.idCurso = idCourse;
		this.idGraph = idTarget;
		if (isMultiplexed && this.loginService.esEstudiante()) {
			let quizM = this.quizService
				.getNextQuizMultiple(idCourse, idTarget, this.idGroup, idQuiz)
				.subscribe((res) => {
					const idQuizM = res.data.idOriginal;
					this.quizService
						.getQuizMultipleCanvasQuizMultiple(idQuizM, idCourse, idTarget)
						.pipe(finalize(() => (this.isLoading = false)))
						.subscribe((res) => {
							this.quiz = res.quiz;
							this.elements = res.elements;
						});
				});
		} else if (isMultiplexed && this.loginService.esProfesor()) {
			// cuando sea profe traemos todos los hijos del quiz padre y mostramos el hijo 1
			let quizM = this.quizService
				.getQuizMultiple(idQuiz, idCourse, idTarget)
				.subscribe((res) => {
					this.listQM = res.quizzes;
					const idQuizM = res.quizzes[0].quizMultiple.idQuizMultiple;
					this.quizService
						.getQuizMultipleCanvasQuizMultiple(idQuizM, idCourse, idTarget)
						.pipe(finalize(() => (this.isLoading = false)))
						.subscribe((res) => {
							this.quiz = res.quiz;
							this.elements = res.elements;
						});
				});
		} else if (!isMultiplexed) {
			this.quizService
				.getQuiz(idQuiz, idCourse, idTarget)
				.pipe(finalize(() => (this.isLoading = false)))
				.subscribe((res: any) => {
					this.quiz = {
						...res.quiz,
						user: res.quiz.user,
						idOriginal: res.quiz.idOriginal,
						id: res.quiz.idQuiz,
						originalX: res.quiz.originalX,
						originalY: res.quiz.originalY,
						size: res.quiz.size,
						sizeQuiz: res.quiz.sizeQuiz,
						x: res.quiz.x,
						y: res.quiz.y,
					};
					this.elements = res.elements;
				});
		}
	}

	selectButton(index: number, idQuizM: number) {
		this.isLoading = true;
		this.selectedButtonIndex = index;
		this.quizService
			.getQuizMultipleCanvasQuizMultiple(idQuizM, this.idCurso, this.idGraph)
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe((res) => {
				this.quiz = res.quiz;
				this.elements = res.elements;
			});
	}

	//Función que recibe valores para crear un nuevo elemento y añadirlo a la colección
	// createNewElement(newElement: NEWELEMENTTYPES){}
	// createUpdateGraph(idCurso: number) {}
	// createUpdateNode(){}
	// createUpdateQuiz(){}
	// viewGraph(){}

	// FUNCIONES DE LOS COMPONENTES DE QUIZ Y NODO

	showFiles(value) {
		let interval: number = 1;
		if (value) clearInterval(this.mouseInterval);

		this.mouseInterval = setInterval(() => {
			interval++;
			if (interval === 4) {
				this.isShowFiles = false;
				this.isShowPads = false;
				this.isShowLabels = false;
				clearInterval(this.mouseInterval);
			}
		}, DEFAULTCLOSEPADS / 3);

		this.isShowFiles = true; //Change variable value

		//QUENTAL
		if (this.viewQuiz) {
			if (this.utils.padsStatus.showPadsQuiz) this.isShowPads = true;
			if (this.utils.labelsStatus.showLabelsQuiz) this.isShowLabels = true;
		} else {
			if (this.utils.padsStatus.showPadsNode) this.isShowPads = true;
			if (this.utils.labelsStatus.showLabelsNode) this.isShowLabels = true;
		}
	}

	onViewGif(result: boolean) {
		let url: string = "";

		//Si result es true, gif ok; si es false, gif KO
		const numRandon = this.getRandomInt(1, 40);
		const numRandonNeg = this.getRandomInt(1, 19);

		if (result) url = URLCELEBRATIONS + "/positive/image-" + numRandon + ".gif";
		else url = URLCELEBRATIONS + "/negative/image-" + numRandonNeg + ".gif";

		//Mostrar un modal con el gif y que se cierre en 3 segundos o lo haga el usuario
		const modalRef = this.modalService.open(ModalCelebrationComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W80 + " celebration-modal-window",
			backdropClass: "celebration-modal-backdrop",
		});

		modalRef.componentInstance.url = url;

		modalRef.result.then(
			(res) => {
				modalRef.close();
			},
			(err) => {}
		);

		setTimeout(() => {
			modalRef.close();
		}, 3000);
	}

	hidenGif(event) {}

	private getRandomInt(min, max) {
		return Math.floor(Math.random() * (max - min)) + min;
	}

	onAnswered() {
		this.answered = true;
		this.quizService.setQuizGotResponse(this.quiz);
	}

	// FIN FUNCIONES DE LOS COMPONENTES DE QUIZ Y NODO

	toggleFilters() {
		this.showElement = !this.showElement;
		this.buttonText = this.showElement ? "Cerrar" : "Filtros";
	}

	@HostListener("window:resize", ["$event"])
	onResize(event: any): void {
		if (window.innerWidth <= 1200) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	}

	informesStudentGrupo() {
		this.checkSelectedGroup("", true);
		if (this.pendingSelectGroup) {
			return;
		} else {
			this.setSelectedGroupInfo();
			const modalRef = this.modalService.open(ReportingComponent, {
				scrollable: false,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			});
			modalRef.componentInstance.idGrupo = this.selectedGroupInfo.idGroup;
			modalRef.componentInstance.group = this.selectedGroupInfo.group;
		}
	}

	checkSelectedGroup(moduleToOpen?: string, fromInforme?: boolean) {
		this.setSelectedGroupInfo();
		if (this.selectedGroupInfo) {
			this.pendingSelectGroup = false;
		} else {
			const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30,
			});
			modalRef.componentInstance.disableCancel = true;
			modalRef.componentInstance.groupSelected = this.selectedGroupInfo
				? true
				: false;
			modalRef.componentInstance.fromInforme = fromInforme;

			modalRef.componentInstance.optionalTitle =
				this.profile === "ESTUDIANTE"
					? this.translateService.instant(
							"ALERTCHALLENGENODE.SELECTGROUPTITLEESU"
					  )
					: this.translateService.instant(
							"ALERTCHALLENGENODE.SELECTGROUPTITLE"
					  );

			modalRef.componentInstance.mensaje =
				this.profile === "ESTUDIANTE"
					? this.translateService.instant(
							"ALERTCHALLENGENODE.SELECTGROUPMESSAGESTUDENTE"
					  )
					: this.translateService.instant(
							"ALERTCHALLENGENODE.SELECTGROUPMESSAGE"
					  );

			modalRef.result.then((result: any) => {
				this.disableInstantActivityButton = false;
				if (result === true) {
					this.profile = this.loginService.getProfile();
					this.MastersService.openGroups(this.profile);
				} else if (result === "sinGrupo") {
					const modalRef = this.modalService.open(ReportingComponent, {
						scrollable: false,
						windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
					});
					modalRef.componentInstance.idGrupo = 0;
				}
			});
			this.pendingSelectGroup = true;
		}
	}

	setSelectedGroupInfo() {
		let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		if (storedGroupInfo) {
			if (storedGroupInfo.idUser != this.user.idUser) {
				localStorage.removeItem("selectedGroupInfo");
			}
		}
		this.selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);
	}

	changeCoursesInGroup(check) {
		this.coursesInGroup = check.target.checked;
	}

	changeMyCourses(check) {
		this.myCourses = check.target.checked;
	}

	getListadoCursosGrupo(val) {
		this.formulario.reset(); //limpiamos el formulario para no combinar formularios
		//this.checkValue();
		this.coursesOfProjectActive = !this.coursesOfProjectActive;
		if (this.coursesOfProjectActive == true) {
			if (this.infoGrupo !== null) {
				if (this.viewList) {
					this.groupService
						.getListCursos(this.infoGrupo.idGroup)
						.subscribe((res: any) => {
							this.treeCourses = this.treeCourses.filter((obj) =>
								res.data.map((item) => item.idCourse).includes(obj.idCourse)
							);
							this.auxListCourses = this.treeCourses;
							this.changeOrder(this.activeOrder);
							this.applyFilters("ESP", 3);
							if (this.filtroInicial == "explicacionAsociativa") {
								this.applyFilters(1, 0);
							}
							if (this.filtroInicial == "abpCasos") {
								this.applyFilters(2, 0);
							}
							if (this.filtroInicial == "pensarCreando") {
								this.applyFilters(2, 0);
								this.coursesInGroup = true;
							}
							if (this.filtroInicial == "pensarCreando(ver)") {
								this.applyFilters(4, 0);
								this.coursesInGroup = true;
							}
							this.selectedCountry = "ESP";
							this.filtersList[3].value = "ESP";
							this.searchLevels("ESP");
							setTimeout(() => {
								this.cargando = false;
							}, 600);
						});
				} else {
					this.groupService
						.getListCursos(this.infoGrupo.idGroup)
						.subscribe((res: any) => {
							this.cursos = [];
							for (let index = 0; index < res.data.length; index++) {
								const element = res.data[index];
								if (
									element.isPensarCreando === 0 ||
									(element.user.idUser === this.loggedInUser.idUser &&
										element.isPensarCreando === 1)
								) {
									this.cursos.push(element);
								}
							}
							this.auxCursos = this.cursos;
							this.changeOrder(this.activeOrder);
							this.applyFilters("ESP", 3);
							if (this.filtroInicial == "explicacionAsociativa") {
								this.applyFilters(1, 0);
							}
							if (this.filtroInicial == "abpCasos") {
								this.applyFilters(2, 0);
							}
							if (this.filtroInicial == "pensarCreando") {
								this.applyFilters(2, 0);
								this.coursesInGroup = true;
							}
							if (this.filtroInicial == "pensarCreando(ver)") {
								this.applyFilters(4, 0);
								this.coursesInGroup = true;
							}
							this.selectedCountry = "ESP";
							this.filtersList[3].value = "ESP";
							this.searchLevels("ESP");
							setTimeout(() => {
								this.cargando = false;
							}, 600);
						});
				}
			}
		} else {
			this.checkValue();
		}
	}
	preventDetalle(e) {
		this.preventGoToDetail = true;
		setTimeout(() => {
			this.preventGoToDetail = false;
		}, 500);
	}

	open(dialog: TemplateRef<any>) {
		this.dialogService.open(dialog);
	}

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
	}

	showGuiaModal() {
		this.dialogService.open(GuiaProyectosComponent, {
			dialogClass: MODAL_DIALOG_TYPES.W50,
		});
	}

	getScreen() {
		switch (this.filtroInicial) {
			case "explorar":
				this.screen = "explorar";
				break;
			case "explicacionAsociativa":
				this.screen = "explicacion";
				break;
			case "practicar":
				this.screen = "practica";
				break;
			case "pensarCreando":
				this.screen = "pensar(crear)";
				break;
			case "pensarCreando(ver)":
				this.screen = "pensar(ver)";
				break;
			case "abpCasos":
				this.screen = "abp";
				break;
			case "pruebaNivel":
				this.screen = "prueba";
				break;
			default:
				this.screen = "explorar";
				break;
		}
	}
}
