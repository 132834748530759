<div class="sticky-top sticky-top-header">
    <!-- HEADER SUPERIOR (AZUL) -->
    <div class="modal-header tittle" align="center">

        <div class="col-sm-12 align-self-center text-center">
            <span class="textoFontSize08 font-weight-bold span-text-center">{{ 'PATTERNS.CREACION' | translate }}</span>
        </div>
        <div class="col-sm-2 align-self-center text-center" style="position: absolute;right: 0;top: 0;">
            <button type="button" class="close pointer" [ngStyle]="{'color':'rgb(29, 73, 153)'}" data-dismiss="modal"
                (click)="closeModal('')">
                <span class="textoFontSize18 font-weight-bold span-text-center" style="color:white"
                    aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="modal-body pt-0 fondoEditarCurso">
    <form name="form" #thisForm="ngForm" [formGroup]="form" (ngSubmit)="createTemplate()" class="form-horizontal">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="my-2 mt-2">                        
                        <label for="templateTittle">
                            <span class="textoFontSize09">{{'PATTERNS.PATTERNLABEL' | translate}}</span>
                        </label>                        
                    </div>                    
                    <input class="form-control" type="text" id="templateTittle" formControlName="templateTittle" style="text-align: left;"/>                                       

                    <mat-error *ngFor="let validation of validationMessages.templateTitle" class="mt-2">
                        <mat-error class="error-message"
                            *ngIf="form.get('templateTittle')?.hasError(validation.type) && (form.get('templateTittle')?.dirty || form.get('templateTittle')?.touched || thisForm.submitted)">
                            {{validation.message}}</mat-error>
                    </mat-error>  

                </div>

                <div class="col-lg-12 col-md-12 col-12 mt-3">
                    <div class="my-2" >
                        <label for="quizTitle">
                            <span class="textoFontSize09">{{'PATTERNS.QUIZTITLE' | translate}}</span>
                        </label>
                        <mat-icon class="ml-2 icon-size-large pointer" [ngStyle]="{'color':'rgb(33, 76, 152)','font-size':' 20px'}" ngbTooltip="{{ 'PATTERNS.QUIZTITLE' | translate }}">error_outline</mat-icon>
                    </div>
                    <input class="form-control" type="text" id="quizTitle" formControlName="quizTitle" style="text-align: left;"/>                    
                </div>

                <div class="col-lg-12 col-md-12 col-12 mt-3">
                    <div class="my-2" >
                        <label for="quizInstructions">
                            <span class="textoFontSize09">{{'PATTERNS.QUIZINSTRUCTIONS' | translate}}</span>
                        </label>
                        <mat-icon class="ml-2 icon-size-large pointer" [ngStyle]="{'color':'rgb(33, 76, 152)','font-size':' 20px'}" ngbTooltip="{{ 'PATTERNS.QUIZINSTRUCTIONS' | translate }}">error_outline</mat-icon>
                    </div>
                    <textarea id="quizInstructions" class="form-control" rows="5" formControlName="quizInstructions" style="text-align: left;"></textarea>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12" style="margin-top: 20px;">
                <div class="row px-5" style="display: flex;">
                    <div class="form-group col-sm-11 col-md-11" [ngStyle]="{'background-color':'white'}" style=" padding: 15px 20px 10px;">
                        <div>
                            
                            <mat-radio-group aria-label="Select an option" formControlName="writable" (change)="changeRadio($event, 'writable')">
                                <mat-radio-button value="0" class="mb-1">{{'PATTERNS.OPTIONS' | translate}}</mat-radio-button>
                                <mat-radio-button value="1">{{'PATTERNS.WRITABLE' | translate}}</mat-radio-button>
                              </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-sm-1" style="text-align: end;margin-top: 25px;">
                        <mat-icon class="icon-size-large pointer"
                            [ngStyle]="{'color':'rgb(33, 76, 152)','font-size':' 20px'}" ngbTooltip="{{ 'PATTERNS.OPTIONSWRITABLE' | translate }}">error_outline</mat-icon>
                    </div>
                </div>
                <div class="row px-5">
                    <div class="form-group col-sm-11 col-md-11" [ngStyle]="{'background-color':'white'}" style=" padding: 15px 20px 10px;">
                        <div>
                            <mat-radio-group aria-label="Select an option" formControlName="compuCorrect" (change)="changeRadio($event, 'compuCorrect')">
                                <mat-radio-button value="0" class="mb-1">{{'PATTERNS.MANUALCORRECT' | translate}}</mat-radio-button>
                                <mat-radio-button value="1">{{'PATTERNS.COMPUCORRECT' | translate}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-sm-1" style="text-align: end;margin-top: 25px;">
                        <mat-icon class="icon-size-large pointer"
                            [ngStyle]="{'color':'rgb(33, 76, 152)','font-size':' 20px'}" ngbTooltip="{{ 'PATTERNS.MANUALCOMPUCORRECT' | translate }}">error_outline</mat-icon>
                    </div>
                </div>
                <div class="row px-5">
                    <div class="form-group col-sm-11 col-md-11" [ngStyle]="{'background-color':'white'}" style=" padding: 15px 20px 10px;">
                        <div>
                            <mat-radio-group aria-label="Select an option" formControlName="multiplexed">
                                <mat-radio-button value='0' class="mb-1">{{'PATTERNS.NOMULTIPLEXED' | translate}}</mat-radio-button>
                                <mat-radio-button value='1'>{{'PATTERNS.MULTIPLEXED' | translate}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-sm-1" style="text-align: end;margin-top: 25px;">
                        <mat-icon class="icon-size-large pointer"
                            [ngStyle]="{'color':'rgb(33, 76, 152)','font-size':' 20px'}" ngbTooltip="{{ 'PATTERNS.MULTIPLEXEDINFO' | translate }}">error_outline</mat-icon>
                    </div>
                    
                </div>
                <div class="row px-5">
                    <mat-error *ngFor="let validation of validationMessages.options">
                        <mat-error class="error-message"
                            *ngIf="form.get('writable')?.hasError(validation.type) && (form.get('writable')?.dirty || form.get('writable')?.touched || thisForm.submitted)">
                            {{validation.message}}</mat-error>
                    </mat-error>  
                </div>
               
            </div> 
        </div>
        <div class="form-group mb-3 mt-3 check-forms">
            <div class="row">
                <div class="col-lg-6 col-md-6 ">
                    <div class="row justify-content-center">
                        <label class="col-12 col-sm-12 col-md-12 col-lg-12 bold text-center mb-2">#{{
                            'PATTERNS.QUESTIONS' | translate }}</label>
                        <!-------------------- ICONOS 1 ------------------------------>
                        <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2">
                            <div class="mx-auto">
                                <mat-icon class="icon-orange" aria-hidden="false" aria-label="Example home icon">
                                    videocam</mat-icon>
                            </div>
                            <input class="form-control" type="number" formControlName="qVideos" (ngModelChange)="enterValue($event, 'qVideos')">
                        </div>
                        <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <div class="row m-0">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <mat-icon class="icon-orange" aria-hidden="false" aria-label="Example home icon">
                                        image</mat-icon>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <input class="form-control" type="number" formControlName="qPictures"
                                           (ngModelChange)="enterValue($event, 'qPictures')">
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <div class="row m-0">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <mat-icon class="icon-orange" aria-hidden="false" aria-label="Example home icon">
                                        volume_up</mat-icon>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <input class="form-control" type="number" formControlName="qAudios"
                                           (ngModelChange)="enterValue($event, 'qAudios')">
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <div class="row m-0">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <mat-icon class="icon-orange" aria-hidden="false" aria-label="Example home icon">
                                        picture_as_pdf</mat-icon>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <input class="form-control" type="number" formControlName="qPdfs"
                                           (ngModelChange)="enterValue($event, 'qPdfs')">
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <div class="row m-0">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <mat-icon class="icon-orange" aria-hidden="false" aria-label="Example home icon">
                                        subject</mat-icon>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <input class="form-control" type="number" formControlName="qTexts"
                                           (ngModelChange)="enterValue($event, 'qTexts')">
                                </div>
                            </div>
                        </div>
                        <!--------------------------------------------------------->
                        <mat-error  *ngIf="questions" class="error-message textoFontSize08">{{ 'PATTERNS.QUESTIONMESSAGE' | translate }}</mat-error>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 ">
                    <div class="row justify-content-center">
                        <label class="col-12 col-sm-12 col-md-12 col-lg-12 bold text-center mb-2">#{{ 'PATTERNS.OPTION'
                            | translate }}</label>
                        <!-------------------- ICONOS 2----------------------------->
                        <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <div class="row m-0">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <mat-icon class="icon-green" aria-hidden="false" aria-label="Example home icon">
                                        videocam</mat-icon>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <input class="form-control" type="number" formControlName="oVideos"
                                           (ngModelChange)="enterValue($event, 'oVideos')">
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <div class="row m-0">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <mat-icon class="icon-green" aria-hidden="false" aria-label="Example home icon">
                                        image</mat-icon>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <input class="form-control" type="number" formControlName="oPictures"
                                           (ngModelChange)="enterValue($event, 'oPictures')">
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <div class="row m-0">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <mat-icon class="icon-green" aria-hidden="false" aria-label="Example home icon">
                                        volume_up</mat-icon>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <input class="form-control" type="number" formControlName="oAudios"
                                           (ngModelChange)="enterValue($event, 'oAudios')">
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <div class="row m-0">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <mat-icon class="icon-green" aria-hidden="false" aria-label="Example home icon">
                                        picture_as_pdf</mat-icon>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <input class="form-control" type="number" formControlName="oPdfs"
                                           (ngModelChange)="enterValue($event, 'oPdfs')">
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <div class="row m-0">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <mat-icon class="icon-green" aria-hidden="false" aria-label="Example home icon">
                                        subject</mat-icon>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <input class="form-control" type="number" formControlName="oTexts"
                                           (ngModelChange)="enterValue($event, 'oTexts')">
                                </div>
                            </div>
                        </div>
                        <!--------------------------------------------------------->
                        <mat-error  *ngIf="options"  class="error-message textoFontSize08">{{ 'PATTERNS.OPTIONSMESSAGE' | translate }}</mat-error>
                    </div>
                </div>
            </div>
            <!-------------------- ICONOS 3----------------------------->
            <div class="col-lg-6 col-md-6 " style="left: -1%; max-width: 51%">
                <div class="row justify-content-center">
                    <label class="col-12 col-sm-12 col-md-12 col-lg-12 bold text-center mb-2">#{{ 'PATTERNS.ANSWERS'
                        | translate }}</label>
                    <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                        <div class="row m-0">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <mat-icon class="icon-black" aria-hidden="false" aria-label="Example home icon">
                                    videocam</mat-icon>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <input class="form-control" type="number" formControlName="aVideos"
                                       (ngModelChange)="enterValue($event, 'aVideos')">
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                        <div class="row m-0">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <mat-icon class="icon-black" aria-hidden="false" aria-label="Example home icon">
                                    image</mat-icon>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <input class="form-control" type="number" formControlName="aPictures"
                                       (ngModelChange)="enterValue($event, 'aPictures')">
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                        <div class="row m-0">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <mat-icon class="icon-black" aria-hidden="false" aria-label="Example home icon">
                                    volume_up</mat-icon>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <input class="form-control" type="number" formControlName="aAudios"
                                       (ngModelChange)="enterValue($event, 'aAudios')">
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                        <div class="row m-0">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <mat-icon class="icon-black" aria-hidden="false" aria-label="Example home icon">
                                    picture_as_pdf</mat-icon>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <input class="form-control" type="number" formControlName="aPdfs"
                                       (ngModelChange)="enterValue($event, 'aPdfs')">
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                        <div class="row m-0">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <mat-icon class="icon-black" aria-hidden="false" aria-label="Example home icon">
                                    subject</mat-icon>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <input class="form-control" type="number" formControlName="aTexts"
                                       (ngModelChange)="enterValue($event, 'aTexts')">
                            </div>
                        </div>
                    </div>
                    <mat-error  *ngIf="answers"  class="error-message textoFontSize08">{{ 'PATTERNS.ANSWERSMESSAGE' | translate }}</mat-error>
                </div>
            </div>
            <!--------------------------------------------------------->
            <div class="row" style="float: right;margin: 20px;">
                <button mat-raised-button type="submit">Crear Plantilla</button>
            </div>
        </div>
    </form>
</div>
