<div class="modal-body fondoEditarCurso" align="center">
    <app-loading [isVisible]="cargando"></app-loading>
    <form class="form-horizontal">
        <div *ngIf="templates.length==0" class="row">
            <div class="col-lg-2 col-md-2 col-12" style="width: 20px;height:20px">
                <canvas id="canvas"></canvas>
            </div>
            <div class="col-lg-10 col-md-10 col-0">
            </div>
        </div>
        <div *ngIf="templates.length>0" class="row">
            <div class="col-lg-2 col-md-2 col-12" style="width: 20px;height:20px">
                <canvas id="canvas"></canvas>
            </div>
            <div class="col-lg-10 col-md-10 col-0">
            </div>
        </div>
        <div class="row">
            <!--div class="col-lg-1 col-md-1 col-12">
                <canvas id="canvas"></canvas>
            </div-->
            <!--div class="col-lg-11 col-md-11 col-12 mt-2">
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-2">
                        <canvas id="canvas"></canvas>
                    </div>
                    <div class="plantilla col-lg-10 col-md-10 col-10">
                        <span
                            class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'QUIZZES.MENSAJEINICIAL' | translate }}</span>
                    </div>
                </div>
            </div-->
            <div *ngIf="templates.length==0" class="plantilla col-lg-11 col-md-11 col-12 mt-2">
                <div class="row">
                    <!--div class="col-lg-2 col-md-2 col-2">
                        <canvas id="canvas"></canvas>
                    </div-->
                    <div class="plantilla col-lg-12 col-md-12 col-12">
                        <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'QUIZZES.MENSAJEINICIAL' |
                            translate }}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="templates.length>0" class="plantilla col-lg-11 col-md-11 col-12 mt-2">
                <div class="row">
                    <!--div class="col-lg-2 col-md-2 col-2">
                        <canvas id="canvas"></canvas>
                    </div-->
                    <div class="plantilla col-lg-10 col-md-10 col-10">
                        <app-plantilla-reproducir-quiz *ngIf="!cargando" [templates]="templates" [answer]="answer"
                            [isRevealed]="isRevealed" [quiz]="quiz"></app-plantilla-reproducir-quiz>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-md-1 col-12 mt-lg-4 mt-md-4 mt-0 pl-0 pr-4">
                <div *ngIf="templates.length > 0 && templates[0].compuCorrect==0" class="row m-0">
                    <div class="col-lg-12 col-md-12 col-3 mt-lg-5 mt-md-5 mt-0" (click)="discoverAnswer()"
                        [ngClass]="{'disable': isRevealed}">
                        <mat-icon *ngIf="!isRevealed" class="icon-size-large pointer" style="color:rgb(196, 150, 47)">
                            visibility
                        </mat-icon>
                        <mat-icon *ngIf="isRevealed" class="icon-size-large pointer"
                            style="color:rgb(196, 150, 47,0.5)">
                            visibility
                        </mat-icon>
                    </div>
                    <div class="col-lg-12 col-md-12 col-3 mt-lg-5 mt-md-5 mt-0" (click)="evaluationNegative()"
                        [ngClass]="{'disable': !isRevealed}">
                        <mat-icon *ngIf="isRevealed" class="icon-size-large pointer" style="color:rgb(198, 70, 19)">
                            close</mat-icon>
                        <mat-icon *ngIf="!isRevealed" class="icon-size-large pointer"
                            style="color:rgb(198, 70, 19,0.5)">close</mat-icon>
                    </div>
                    <div class="col-lg-12 col-md-12 col-3 mt-lg-5 mt-md-5 mt-0" (click)="evaluationPositive()"
                        [ngClass]="{'disable': !isRevealed}">
                        <mat-icon *ngIf="isRevealed" class="icon-size-large pointer" style="color:rgb(96, 133, 76)">done
                        </mat-icon>
                        <mat-icon *ngIf="!isRevealed" class="icon-size-large pointer"
                            style="color:rgb(96, 133, 76,0.5)">done</mat-icon>
                    </div>
                    <div class="col-lg-12 col-md-12 col-3 mt-lg-5 mt-md-5 mt-0" (click)="nextQuiz(quiz)"
                        [ngClass]="{'disable': !isRevealed}">
                        <mat-icon *ngIf="isRevealed" class="icon-size-large pointer" style="color:rgb(32, 80, 154)">
                            arrow_forward
                        </mat-icon>
                        <mat-icon *ngIf="!isRevealed" class="icon-size-large pointer"
                            style="color:rgb(32, 80, 154,0.5)">arrow_forward
                        </mat-icon>
                    </div>
                </div>
                <div *ngIf="templates.length > 0 && templates[0].compuCorrect==1" class="row m-0">
                    <div class="col-lg-12 col-md-12 col-3 mt-lg-5 mt-md-5 mt-0">
                        <mat-icon class="icon-size-large pointer"></mat-icon>
                    </div>
                    <div class="col-lg-12 col-md-12 col-3 mt-lg-5 mt-md-5 mt-0" (click)="discoverAnswer()"
                        [ngClass]="{'disable': isRevealed}">
                        <mat-icon *ngIf="!isRevealed" class="icon-size-large pointer" style="color:rgb(196, 150, 47)">
                            visibility
                        </mat-icon>
                        <mat-icon *ngIf="isRevealed" class="icon-size-large pointer"
                            style="color:rgb(196, 150, 47,0.5)">
                            visibility
                        </mat-icon>
                    </div>
                    <div *ngIf="automatic" class="col-lg-12 col-md-12 col-3 mt-lg-5 mt-md-5 mt-0"
                        (click)="nextQuiz(quiz)" [ngClass]="{'disable': !isRevealed}">
                        <mat-icon *ngIf="isRevealed" class="icon-size-large pointer" style="color:rgb(32, 80, 154)">
                            arrow_forward
                        </mat-icon>
                        <mat-icon *ngIf="!isRevealed" class="icon-size-large pointer"
                            style="color:rgb(32, 80, 154,0.5)">arrow_forward
                        </mat-icon>
                    </div>
                    <div class="col-lg-12 col-md-12 col-3 mt-lg-5 mt-md-5 mt-0">
                        <mat-icon class="icon-size-large pointer"></mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
