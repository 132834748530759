<div class="power-selector" [formGroup]="powerGroup">
    <mat-checkbox #btn3 formControlName="3" class="chkP2" (change)="toggleSelection($event,3)">
        P<small>+3</small>
    </mat-checkbox>

    <mat-checkbox #btn2 formControlName="2" class="chkP2" (change)="toggleSelection($event,2)">
        P<small>+2</small>
    </mat-checkbox>

    <mat-checkbox #btn1 formControlName="1" class="chkP1" (change)="toggleSelection($event,1)">
        P<small>+1</small>
    </mat-checkbox>

    <mat-checkbox #btn0 formControlName="0" class="chkP0" (change)="toggleSelection($event,0)">
        P<small>0</small>
    </mat-checkbox>

    <mat-checkbox #btnNegative1 formControlName="-1" class="chkPNegative1"
                  (change)="toggleSelection($event,-1)">P<small>-1</small>
    </mat-checkbox>

    <mat-checkbox #btnNegative2 formControlName="-2" class="chkPNegative2"
                  (change)="toggleSelection($event,-2)">P<small>-2</small>
    </mat-checkbox>

    <mat-checkbox #btnNegative3 formControlName="-3" class="chkPNegative2"
                  (change)="toggleSelection($event,-3)">P<small>-3</small>
    </mat-checkbox>
</div>
