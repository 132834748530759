import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

// Graph Model
import { gNode } from '../../models/graph/gNode.model';
import { LoginService } from '../login/login.service';
import { HttpClient } from '@angular/common/http';
import { QuizModel } from '../../models/quizzes';
import { SIGMA_CONSTANTS } from '../../utils/sigma-constants';
import { NodeCoverPipe } from 'src/app/shared/pipes/node-cover.pipe';
import { Utils } from '../../utils/utils';

export interface Dimensions{
    height: number,
    width: number
}
@Injectable({
	providedIn: "root",
})
export class GraphService {
	public defaultTypeView = new BehaviorSubject<number>(0);

	public bgImage = new BehaviorSubject<string>('');

	constructor(
		private loginService: LoginService,
		private http: HttpClient,
		private nodeCoverPipe: NodeCoverPipe,
		private utils: Utils
	) {}

	public graph;
	public newNodeId = new Subject();

	private getImageDimensions(url: string): Observable<Dimensions> {
		return new Observable((observer) => {
			let img = new Image();

			img.onload = (e: any) => {
				observer.next({
					width: img.width,
					height: img.height,
				});
			};
			img.src = url;
		});
	}

	/**
     *
     * @param idCurso
     * @param idMapa
     * @param idUser
     * @returns
     *
        URL (TIPO GET):
        IdCourse: id del del curso
        IdGraph: id del grafo
        IdUser: id del usuario
        api-dev-pruebas/rest/node/getFlickNode/courses/{idCourse}/graphs/{idGraph}/user/{idUser}

     */

	public changedefaultTypeView(typeView: number): void {
		this.defaultTypeView.next(typeView);
	}

	public getFlickNode(idCurso: number, idMapa: number) {
		return this.http.get<any>(
			`node/getFlickNode/courses/${idCurso}/graphs/${idMapa}`
		);
	}

	public getGraphInfo(courseId: number) {
		return this.http.get<any>(`target/graphs/${courseId}`);
	}

	public getNodesFromCourseGraph(
		courseId: number,
		graphId: number
	): Observable<any> {
		return this.http
			.get<any>(`node/courses/${courseId}/graphs/${graphId}/nodes/estudiante`)
			.pipe(
				map((res) =>
					(res.data.nodos as gNode[]).map((n) => {
						return {
							...n,
							originalX: n.x,
							originalY: n.y,
							color: "#D7DBDC",
							originalColor: "#D7DBDC",
							type: "circle",
							image: {
								url: this.nodeCoverPipe.transform(n.pictureNode),
								clip: SIGMA_CONSTANTS.IMAGE_CLIP,
								scale: SIGMA_CONSTANTS.IMAGE_SCALE,
								h: SIGMA_CONSTANTS.HEIGHT,
								w: SIGMA_CONSTANTS.WIDTH,
							},
							url: this.nodeCoverPipe.transform(n.pictureNode),
							size: SIGMA_CONSTANTS.NODE_SIZE,
							pages: n.pages,
							power3: n.ordinalPower3 > 0 ? true : false,
							power2: n.ordinalPower2 > 0 ? true : false,
							power1: n.ordinalPower1 > 0 ? true : false,
							power0: n.ordinalPower0 > 0 ? true : false,
							powerNegative1: n.ordinalPowerNegative1 > 0 ? true : false,
							powerNegative2: n.ordinalPowerNegative2 > 0 ? true : false,
							powerNegative3: n.ordinalPowerNegative3 > 0 ? true : false,
						};
					})
				),
				map((res: gNode[]) => {
					res.forEach((v) => {
						this.getImageDimensions(v.image.url).subscribe((values) => {
							v.image.h = values.height;
							v.image.w = values.width;
							let ratio = values.width / values.height;

							if (values.height !== values.width)
								if (ratio > 1.7) v.image.scale = 3.5;
								else v.image.scale = 2.4;
						});

						if (v.imageNode > 2) {
							v.image.scale = 1;
							switch (v.imageNode) {
								case 3:
									v.image.url = SIGMA_CONSTANTS.IMAGE_AUDIO_DEFAULT;
									break;
								case 4:
									v.image.url = SIGMA_CONSTANTS.IMAGE_PDF_DEFAULT;
									break;
								case 5:
									v.image.url = SIGMA_CONSTANTS.IMAGE_TEXT_DEFAULT;
									break;
							}
						}
					});

					let nodes: gNode[] = [];
					if (this.loginService.esAutor()) {
						nodes = [...res];
						const edges: any[] = [
							...res.map((n) => (n.edges ? (n.edges as any[]) : [])).flat(),
						];
						return { nodes: [...res], edges: edges };
					} else {
						res.forEach((node: any) => {
							if (node.published !== null) {
								nodes.push(node);
							}
						});
						const edges: any[] = [
							...nodes.map((n) => (n.edges ? (n.edges as any[]) : [])).flat(),
						];
						return { nodes: [...nodes], edges: edges };
					}
				})
			);
	}

	public getQuizzesFromCourseGraphRoleStudent(
		courseId: number,
		graphId: number,
		idGroup: Number
	) {
		//return this.http.get<any>(`quizze/courses/${courseId}/graphs/${graphId}/quizzes/rolestudent`)
		return this.http
			.get<any>(
				`quizze/getQuizzesFromGraphRoleStudentV4/${courseId}/${graphId}/${idGroup}/rolestudent`
			)

			.pipe(
				map((res) =>
					(res.data as QuizModel[]).map((q) => ({
						...q,
						originalX: q.x,
						originalY: q.y,
						type: "text",
						size:
							q.sizeQuiz === "large" || this.loginService.esAutor()
								? SIGMA_CONSTANTS.QUIZ_SIZE
								: SIGMA_CONSTANTS.QUIZ_SMALL_SIZE,
					}))
				),
				map((res: any[]) => {
					let nodes: any[] = [];
					if (this.loginService.esAutor()) {
						nodes = [...res];
						const edges: any[] = [
							...res.map((n) => (n.edges ? (n.edges as any[]) : [])).flat(),
						];
						return { nodes: [...res], edges: edges };
					} else {
						res.forEach((node) => {
							if (node.published !== null) {
								nodes.push(node);
							}
						});
						const edges: any[] = [
							...nodes.map((n) => (n.edges ? (n.edges as any[]) : [])).flat(),
						];
						return { nodes: [...nodes], edges: edges };
					}
				})
			);
	}
	/**
	 * GET QUIZZES FROM GRAPH
	 * @param courseId
	 * @param graphId
	 * @returns
	 */
	public getQuizzesFromCourseGraph(
		courseId: number,
		graphId: number
	): Observable<any> {
		return this.http
			.get<any>(
				`quizze/courses/${courseId}/graphs/${graphId}/quizzes/roleeditor`
			)
			.pipe(
				map((res) =>
					(res.data as QuizModel[]).map((q) => {
						return {
							...q,
							originalX: q.x,
							originalY: q.y,
							type: "text",
							size:
								q.sizeQuiz === "large" || this.loginService.esAutor()
									? SIGMA_CONSTANTS.QUIZ_SIZE
									: SIGMA_CONSTANTS.QUIZ_SMALL_SIZE,
						};
					})
				),
				map((res: any[]) => {
					let nodes: any[] = [];
					if (this.loginService.esAutor()) {
						nodes = [...res];
						const edges: any[] = [
							...res.map((n) => (n.edges ? (n.edges as any[]) : [])).flat(),
						];
						return { nodes: [...res], edges: edges };
					} else {
						res.forEach((node) => {
							if (node.published !== null) {
								nodes.push(node);
							}
						});
						const edges: any[] = [
							...nodes.map((n) => (n.edges ? (n.edges as any[]) : [])).flat(),
						];
						return { nodes: [...nodes], edges: edges };
					}
				})
			);
	}

	// *** N O D E ***
	public emptygNode() {
		return {
			id: "temp",
			label: "",
			x: 0,
			y: 0,
			originalX: 0,
			originalY: 0,
			size: 1,
			originalColor: "#D7DBDC",
			color: "#D7DBDC",
			description: "",
			nodeType: "Node",
			nodeSwlevel: 0,
			duration: 0,
			delete: false,
			text: "",
			Number: 0,
			ordinalPower0: 0,
		};
	}

	public saveBackgroundImage(graphId: number, file: File) {
		const form: FormData = new FormData();
		form.append("files", file);

		return this.http.post(`target/graphs/${graphId}/targetImage`, form, {
			reportProgress: true,
			observe: "events",
		});
	}
}
