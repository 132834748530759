
	<form [formGroup]="formularioDatos" (ngSubmit)="enviarInvitacion()" #thisForm="ngForm">
		<nb-card class="no-edge-card mb-0">
			<div class="container">
				<nb-card-header class="row d-flex flex-wrap-reverse">
					<div class="col-12 col-md-9 modal-title">
						<h5>{{'PARENTS.INVITE' | translate}}</h5>
					</div>
					<div class="col-12 col-md-3 d-flex justify-content-end mb-3 mb-md-0">
						<div class="dropdown">
							<button nbButton size="small" (click)="back()">
								{{'PARENTS.BACKTOPARENTS' | translate}}
							</button>
						</div>
					</div>
				</nb-card-header>
				<nb-card-body class="row">
					<div class="col-12">
						<div class="row">
							<div class="col-12 col-md-6 form-group">
								<label for="mail" class="label">{{ 'USERDATA.EMAIL' | translate }}</label>
								<input type="email" nbInput fullWidth status="basic"
									placeholder="{{ 'REGISTRO.EMAILEJEMPLO' | translate }}" name="mail" formControlName="mail" required>
								<div *ngFor="let validation of validationMessages.mail">
									<mat-error
										*ngIf="formularioDatos.get('mail')?.hasError(validation.type) && (formularioDatos.get('mail')?.dirty || formularioDatos.get('mail')?.touched || thisForm.submitted)">
										<div class="error-message">{{validation.message}}</div>
									</mat-error>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-md-6 form-group">
									<label for="prefix" class="label">
										{{"LOGIN.PREFIX" | translate}}
									</label>
									<nb-select fullWidth name="prefix" hero class="SelectForm" placeholder="{{ 'LOGIN.PREFIX' | translate }}" formControlName="prefix">
										<nb-option class="SelectFormOpt" *ngFor="let prefix of $phonePrefixes | async"value="{{ prefix.dial_code }}">{{ prefix.name_en }}
											<span>
												<strong>{{ prefix.dial_code }}</strong>
											</span>
										</nb-option>
									</nb-select>
									<div *ngFor="let validation of validationMessages.prefix">
										<mat-error
											*ngIf="formularioDatos.get('prefix')?.hasError(validation.type) && (formularioDatos.get('prefix')?.dirty || formularioDatos.get('prefix')?.touched || thisForm.submitted)">
											<div class="error-message">{{validation.message}}</div>
										</mat-error>
									</div>
							</div>
							<div class="col-12 col-md-6 form-group">
									<label for="mobile" class="label">
										{{"USERDATA.TELEFONOMOVIL" | translate}}
									</label>
									<input type="text" nbInput fullWidth class="form-control" status="basic" placeholder="{{ 'USERDATA.TELEFONOMOVIL' | translate }}"	name="mobile"	formControlName="mobile"/>
									<div *ngFor="let validation of validationMessages.mobile">
										<mat-error
											*ngIf="formularioDatos.get('mobile')?.hasError(validation.type) && (formularioDatos.get('mobile')?.dirty || formularioDatos.get('mobile')?.touched || thisForm.submitted)">
											<div class="error-message">{{validation.message}}</div>
										</mat-error>
									</div>
							</div>
						</div>
					</div>
				</nb-card-body>
				<nb-card-footer class="border-top-0 row">
					<div class="col-12 d-flex justify-content-end">
						<button nbButton size="small" class="btn-themecolor" type="submit">
							{{ 'PARENTS.SENDINVITATION' | translate	}}
						</button>
					</div>
				</nb-card-footer>
			</div>
		</nb-card>
	</form>
