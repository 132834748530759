import { SIGMA_CONSTANTS } from "src/app/core/utils/sigma-constants";
import { Options } from "vis-network/standalone";
export interface NodeConnectModel {
	id: string;
	idOriginal: number;
	url: string;
	label: string;
}

export interface OptionsNodeConnectModel {
	id: string;
	type: string;
	label: string;
	size: number;
	color: string;
}

export let initialOptions: Options = {
	autoResize : false,
	interaction:{
		dragNodes:true,
		dragView: true,
		hideEdgesOnDrag: false,
		hideEdgesOnZoom: false,
		hideNodesOnDrag: false,
		hover: true,
		hoverConnectedEdges: true,
		keyboard: {
			enabled: true,
			speed: {x: 10, y: 10, zoom: 0.02},
			bindToWindow: true,
			autoFocus: true,
		},
		multiselect: true,
		navigationButtons: false,
		selectable: true,
		selectConnectedEdges: true,
		tooltipDelay: 300,
		zoomSpeed: 0.6,
		zoomView: true
	},
	manipulation: {
		enabled: false,
	},
	nodes: {
		brokenImage: SIGMA_CONSTANTS.IMAGE_DEFAULT,
		shape: "box",
		size: 20,
		font: {
			size: 15,
			color: "#ffffff",
		},
		borderWidth: 2,
	},
	edges: {
		color: "#000000",
		width: 2,
		selectionWidth: 0,
		smooth: {
			enabled: true,
			type: "dynamic",
			roundness: 0.5,
		},
		arrowStrikethrough: false,
	},
	groups: {
		diamonds: {
			color: { background: "red", border: "white" },
			shape: "diamond",
		},
		circles: {
			color: { background: "blue", border: "black" },
			shape: "circle",
		},
		dotsWithLabel: {
			label: "I'm a dot!",
			shape: "dot",
			color: "cyan",
		},
		mints: { color: "rgb(0,255,140)" },
		icons: {
			shape: "icon",
			icon: {
				face: "FontAwesome",
				code: "fa-question",
				size: 50,
				color: "red",
			},
		},
		circle_red: {
			shape: "circle",
			color: { background: "red", border: "yellow" },
		},
		images: {
			unselected: SIGMA_CONSTANTS.IMAGE_DEFAULT,
			shape: "circularImage",
		},
		sticker: {
			shape: "image",
		},
		quiz: {
			shape: "image",
			image: SIGMA_CONSTANTS.IMAGE_ACT_DEFAULT,
			borderwidth: 4,
			font: { color: "#eeeeee" },
			color: {
				border: "#406897",
				background: "#6AAFFF",
			},
			shapeProperties: {
        useBorderWithImage: true,
      },
		},

	},
	physics: {
		enabled: false,
	},
}
