<div class="title-text">
    <span *ngIf="node.id !== 'temp'; else newNodeTitle">{{ 'NODOS.NODE' | translate }}: {{node.label}}</span>
    <ng-template #newNodeTitle>
        <span class="textoFontSize08 font-weight-bold span-text-center">
            {{'NODOS.REGISTRARNODO' | translate}}
        </span>
    </ng-template>
</div>
<button *ngIf="!modeAuto" data-dismiss="modal" (click)="closeModal('')">
    <span class="textoFontSize18 font-weight-bold span-text-center" aria-hidden="true">&times;</span>
</button>
