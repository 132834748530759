		<div class="justify-content-center d-flex mt-5">
			<form [formGroup]="formulario" (ngSubmit)="aceptar()" style="max-width: 500px; width: 100%;">
				<!-- MAIL INPUT-->
				<div class="row ">
					<div class="col-12 form-group">
						<label for="mail" class="label">{{ 'USERDATA.EMAIL' | translate }}</label>
						<input type="text" nbInput fullWidth class="form-control" fieldSize="small" status="basic" (keyup)="verificarMail()"
							placeholder="{{ 'USERDATA.EMAIL' | translate }}" name="mail" formControlName="mail"
							required>
						<div class="error-message" *ngIf="sameMail">
								{{ 'CAMBIARMAIL.OTHERMAIL' | translate }}
						</div>
						<div *ngFor="let validation of validationMessages.mail">
							<mat-error
								*ngIf="formulario.get('mail')?.hasError(validation.type) && (formulario.get('mail')?.dirty || formulario.get('mail')?.touched)">
								<div class="error-message">{{validation.message}}</div>
							</mat-error>
						</div>
					</div>
				</div>
				<!-- ACCEPT AND CANCEL BUTTONS-->
				<div class="d-flex justify-content-center gap-3 mt-4" [ngClass] ="{'justify-content-end':disableCancel}" >
					<div>
							<button nbButton class="btn-themecolor" type="submit" size="small">{{ 'USERDATA.GRABAR' | translate	}}</button>
					</div>
					<div *ngIf="!disableCancel">
							<button nbButton (click)="cancelar()" class="btn-red" size="small">{{ 'ACEPTARCANCELAR.CANCELAR' | translate }}</button>
					</div>
				</div>
				<!-- INPUT CODE-->
				<div class="row">
					<div class="col-12 form-group" *ngIf="introCode">
						<label for="code" class="label">{{ 'CAMBIARMOVIL.ENTERVERIFICATIONCODE' | translate }}</label>
						<input type="text" formControlName="code" nbInput fullWidth class="form-control" fieldSize="small" appNumberInput status="basic"	placeholder="{{ 'CAMBIARMOVIL.VERIFICATIONCODE' | translate }}"
							(input)="validacionPin($event)">

					</div>
				</div>
			</form>
	</div>
