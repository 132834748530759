import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Operator } from 'src/app/core/models/operators/operator.model';
import { OperatorsService } from 'src/app/core/services/operators/operators.service';

@Component({
    selector: 'app-modal-operadores',
    templateUrl: './modal-operadores.component.html',
    styleUrls: ['./modal-operadores.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModalOperadoresComponent implements OnInit {

    id: string;
    tittle: string;
    description: string;
    labels: string;
    senmotemos: Operator[] = [];
    assostatics: Operator[] = [];
    temporaries: Operator[] = [];
    abstracts: Operator[] = [];
    formats: any[] = [];
    iconosIzquierda: any[] = [];
    cantIconos: number = 5;

    iconosSenmotemo: Operator[] = [];
    iconosAssostatic: Operator[] = [];
    iconosTemporary: Operator[] = [];
    iconosAbstract: Operator[] = [];

    iconosAdicionados: any[] = [];

    private operators: Operator[] = [];
    showType: string = 'abstract';
    visibleOperators: Operator[] = [];

    constructor(public modalService: NgbModal, public activeModal: NgbActiveModal, private operatorsService: OperatorsService) { }

    ngOnInit() {
        this.operators = this.operatorsService.getOperators();
        this.visibleOperators = this.getIconsByType(this.showType);
    }

    getIconsByType(type: string) {
        return this.operators.filter(o => o.type === type).sort((a, b) => a.order - b.order);
    }

    cancelar() {
        this.modalService.dismissAll();
    }

    registrar() {
        this.modalService.dismissAll();
    }

    adicionarIconoSenmotemo(icono: Operator): boolean {
        if (this.iconosSenmotemo.includes(icono)) {
            this.iconosSenmotemo.splice(this.iconosSenmotemo.indexOf(icono), 1);
            return true;
        } else {

            if (this.cantidadIconos() < this.cantIconos) {
                this.iconosSenmotemo.push(icono);
                return true;
            } else {
                return false;
            }
        }
    }

    adicionarIconoAssostatic(icono: Operator): boolean {
        if (this.iconosAssostatic.includes(icono)) {
            this.iconosAssostatic.splice(this.iconosAssostatic.indexOf(icono), 1);
            return true;
        } else {
            if (this.cantidadIconos() < this.cantIconos) {
                this.iconosAssostatic.push(icono);
                return true;
            } else {
                return false;
            }
        }
    }

    adicionarIconoTemporary(icono: Operator): boolean {
        if (this.iconosTemporary.includes(icono)) {
            this.iconosTemporary.splice(this.iconosTemporary.indexOf(icono), 1);
            return true;
        } else {
            if (this.cantidadIconos() < this.cantIconos) {
                this.iconosTemporary.push(icono);
                return true;
            } else {
                return false;
            }
        }
    }

    adicionarIconoAbstract(icono: Operator): boolean {
        if (this.iconosAbstract.includes(icono)) {
            this.iconosAbstract.splice(this.iconosAbstract.indexOf(icono), 1);
            return true;
        } else {
            if (this.cantidadIconos() < this.cantIconos) {
                this.iconosAbstract.push(icono);
                return true;
            } else {
                return false;
            }
        }
    }

    get oneIconSelected() {
        return (this.iconosSenmotemo.length > 0 || this.iconosAssostatic.length > 0 || this.iconosTemporary.length > 0 || this.iconosAbstract.length > 0);
    }

    adicionarIconos(iconosSenmotemo: Operator[], iconosAssostatic: Operator[], iconosTemporary: Operator[], iconosAbstract: Operator[]) {
        const response = {
            result: 'OK',
            iconosSenmotemo,
            iconosAssostatic,
            iconosTemporary,
            iconosAbstract
        };
        this.closeModal(response);
    }

    cantidadIconos() {
        return this.iconosSenmotemo.length + this.iconosAssostatic.length + this.iconosTemporary.length + this.iconosAbstract.length
    }

    closeModal(sendData?: any) {
        this.activeModal.close(sendData);
    }

    showOperators(type: string) {
        this.showType = type;
        this.visibleOperators = this.getIconsByType(this.showType);
    }

    onOperatorClicked(operator: Operator) {
        let result: boolean = false;

        switch (this.showType) {
            case 'abstract':
                result = this.adicionarIconoAbstract(operator);
                break;
            case 'temporary':
                result = this.adicionarIconoTemporary(operator);
                break;
            case 'assostatic':
                result = this.adicionarIconoAssostatic(operator);
                break;
            case 'senmotemo':
                result = this.adicionarIconoSenmotemo(operator);
                break;
        }
        if (result) {
            operator.checked = !operator.checked;
        }
    }
}
