<nb-card class="mb-0 no-edge-card">
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" data-dismiss="modal" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>

	<nb-card-body>
		<form [formGroup]="form" (ngSubmit)="postVideo()" #thisForm="ngForm">
			<div class="row form-group py-3" align="left">
				<div class="col-12">
						<div align="center">
								<h3 class="mb-4">{{'YOUTUBEVIDEO.TITLE' | translate}}</h3>
						</div>

						<div class="form-row">
							<div class="full-width mb-3">
								<div class="row">
									<div class="col-md-12">
										<div class="col space-form">
											<input type="text" nbInput fullWidth placeholder="{{'YOUTUBEVIDEO.LINK' | translate}}" name="url" formControlName="url">
											<div *ngFor="let validation of validationMessages.url">
												<div class="error-message"
														*ngIf="urlControl?.hasError(validation.type) && (urlControl?.dirty || urlControl?.touched || thisForm.submitted)">
														{{validation.message}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="form-row">
							<div class="full-width mb-3">
								<div class="row">

									<div class="col-md-6">
										<div class="col space-form d-flex justify-content-center">
											<input type="text" nbInput placeholder="{{'YOUTUBEVIDEO.START' | translate}}" name="start" formControlName="start" pattern="^[0-999]?\d:[0-5]?\d:[0-5]\d$">
											<div *ngFor="let validation of validationMessages.start">
												<div class="error-message"
														*ngIf="startControl?.hasError(validation.type) && (startControl?.dirty || startControl?.touched || thisForm.submitted)">
														{{validation.message}}</div>
											</div>
										</div>
									</div>

									<div class="col-md-6">
										<div class="col space-form d-flex justify-content-center">
											<input type="text" nbInput placeholder="{{'YOUTUBEVIDEO.END' | translate}}" name="end" formControlName="end" pattern="^[0-999]?\d:[0-5]?\d:[0-5]\d$">
											<div *ngFor="let validation of validationMessages.end">
												<div class="error-message"
														*ngIf="endControl?.hasError(validation.type) && (endControl?.dirty || endControl?.touched || thisForm.submitted)">
														{{validation.message}}</div>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
						<div class="row justify-content-center align-items-center">
							<p style="color: darkred;">{{'YOUTUBEVIDEO.AVISO' | translate}}</p>
						</div>

						<div class="row form-group mt-2 mb-3 justify-content-center">
							<div class="col-md-6 d-flex justify-content-center" *ngIf="format.url">
								<button nbButton status="warning" (click)="delete()">
										<span>{{'YOUTUBEVIDEO.DELETE' | translate}}</span>
								</button>
							</div>

							<div class="col-md-6 d-flex justify-content-center">
								<button nbButton class="btn-themecolor" type="submit">
									<span>{{'YOUTUBEVIDEO.OK' | translate}}</span>
								</button>
							</div>
						</div>

				</div>

			</div>
		</form>
	</nb-card-body>
</nb-card>
