<nb-card class="mb-0 min-vh-99 no-edge-card">
	<nb-card-header class="border-bottom-0">
		<div class="d-flex justify-content-between gap-3 flex-wrap-reverse ml-md-5 pl-md-3">
			<div class="d-flex">
				<ng-container *ngIf="isLoading; else resolveTemplate">
					<h2 class="text-left">{{'STUDENTWAITING.TITLE' | translate}}</h2>
				</ng-container>
				<ng-template #resolveTemplate>
					<h2 class="text-left">{{'STUDENTWAITING.RESOLVEACTIVITY' | translate}}</h2>
				</ng-template>
			</div>
			<div class="d-flex mr-5">
				<div class="flex justify-content-end align-items-center">
					<span class="dot"></span>
					<nb-icon icon="people-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon>
					<span>
						{{onlineUsers}}
					</span>
				</div>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="close('manual')">
		</nb-icon>
	</div>
	<nb-card-body class="p-0 p-md-3">
		<div class="container">
			<div class="height-max w-100" *ngIf="isLoading">
				<div class="d-flex flex-column align-items-center justify-content-center h-100">
					<p class="mb-0">{{'STUDENTWAITING.WAITINGACTIVITY' | translate}}</p>
					<mat-spinner [diameter]="30"></mat-spinner>
				</div>
			</div>

			<ng-container *ngIf="selectedType === 'order_mod_list' && !isLoading">
				<div class="row justify-content-md-end justify-content-center mb-3">
					<div class="clock flex align-items-center flex-md-row flex-column">
						<span class="title-time-remaining mr-2">{{'SOCKETQUIZPREVIEW.CHALLENGEENDS' | translate}}</span>
						<countdown *ngIf="configCountDown" #cd [config]="configCountDown" (event)="handleCountDownEvent($event)" class="counter-down"></countdown>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-md-4">
						<h5 class="text-title font-weight-bold">
							{{ 'INFORMES.EXPANDIRVISTA' | translate }}
						</h5>
						<app-mat-tree-cursos [lastQuizStack]="lastQuizStack" [idGroup]="idGroup" [treeCourses]="listQuizzes"
							(clickElement)="clickElement($event)" [type]="'onlyQuizzes'"></app-mat-tree-cursos>
					</div>
					<div class="col-12 col-md-8">

						<div *ngIf="quiz && !multiDesafio" class="quiz-container" id="quiz">
							<div class="body-container py-3 hide-scrollbars" id="container">
								<app-quiz-play-body [selectedType]="selectedType" [quiz]="quiz" [idGroup]="idGroup"
									[courseId]="idCourse" [graphId]="idTarget" [elements]="elements" [answered]="answered"
									[modeSocket]="true" [answeredBySocket]="false" [disabledButton]="false"
									(answeredChange)="onAnswered()" (onTimeEnd)="timeEnd($event)"
									(onSaveResponse)="saveResponse($event)">
								</app-quiz-play-body>
							</div>
						</div>

						<div *ngIf="quiz && multiDesafio" class="quiz-container" id="quiz">
							<div class="body-container py-3 hide-scrollbars" id="container">
								<app-quiz-play-multiple-body [selectedType]="selectedType" [quiz]="quiz" [idGroup]="idGroup"
									[courseId]="idCourse" [graphId]="idTarget" [elements]="elements" [answered]="answered"
									[modeSocket]="true" [answeredBySocket]="false" [disabledButton]="false"
									(answeredChange)="onAnswered()" (onTimeEnd)="timeEnd($event)"
									(onSaveResponse)="saveResponse($event)">
								</app-quiz-play-multiple-body>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="selectedType !== 'order_mod_list' && !isLoading">
				<!-- SIMPLE QUIZ -->

				<div *ngIf="quiz && !multiDesafio" class="quiz-container" id="quiz">
					<div class="body-container hide-scrollbars" id="container">
						<app-quiz-play-body [selectedType]="selectedType" [quiz]="quiz" [idGroup]="idGroup" [courseId]="idCourse"
							[graphId]="idTarget" [elements]="elements" [answered]="answered" [modeSocket]="true"
							[answeredBySocket]="answeredBySocket" [option]="option" (answeredChange)="onAnswered()"
							(onTimeEnd)="timeEnd($event)" (onSaveResponse)="saveResponse($event)">
						</app-quiz-play-body>
					</div>
				</div>
				<div *ngIf="quiz && multiDesafio" class="quiz-container" id="quiz">
					<div class="body-container py-3 hide-scrollbars" id="container">
						<app-quiz-play-multiple-body [selectedType]="selectedType" [quiz]="quiz" [idGroup]="idGroup" [courseId]="idCourse"
						[graphId]="idTarget" [elements]="elements" [answered]="answered" [modeSocket]="true"
						[answeredBySocket]="answeredBySocket" [option]="option" (answeredChange)="onAnswered()"
						(onTimeEnd)="timeEnd($event)" (onSaveResponse)="saveResponse($event)">
						</app-quiz-play-multiple-body>
					</div>
				</div>
				<!-- END SIMPLE QUIZ -->
			</ng-container>
			<ng-container *ngIf="selectedType === 'surveys' && !isLoading">
				<div class="quiz-container" id="survey">
					<div class="body-container hide-scrollbars" id="container">
						<app-surveys-answer
						[survey]="survey"
						[idEvent]="idEvent"
						[anonymous]="anonymous"
						(answeredSurvey)="answeredSurvey()">
						</app-surveys-answer>
					</div>
				</div>
			</ng-container>

			<div class="row justify-content-center">
				<div *ngIf="quiz" class="text-center font-weight-bold">
					<div *ngIf="currentPosition && arrayQuizzesLength">
						{{currentPosition + '/' + arrayQuizzesLength}}
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>
	<nb-card-footer *ngIf="quiz" class="border-top-0">
		<div class="row justify-content-center">
			<div class="my-3">
				<div>
					<button nbButton size="medium" class="btn-themecolor" *ngIf="option === 3 || option === 4"
						(click)="nextQuiz(true)">{{buttonText}}
					</button>
				</div>
				<div *ngIf="selectedType === 'order_mod_list' && !isLoading">
					<button nbButton size="medium" fullWidth (click)="endChallenge()">
						{{'QUIZSOCKETPREVIEW.ENDCHALLENGEBUTTON' | translate}}
					</button>
				</div>
			</div>
		</div>
	</nb-card-footer>
</nb-card>
