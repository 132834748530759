import { AgentService } from 'src/app/core/services/agents/agent.service';

import { Utils } from "./../../../utils/utils";
import {
	Component,
	ElementRef,
	HostListener,
	OnInit,
	Optional,
	Output,
	ViewEncapsulation,
	EventEmitter
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { User } from "src/app/core/models/users/user.models";
import { LoginService } from "src/app/core/services/login";
import { UserDataGeneralComponent } from "src/app/pages/user-data/user-data-general/user-data-general.component";
import { ImagenPipe } from "src/app/shared/pipes/imagen.pipe";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { UsersService } from "src/app/core/services/users";
import { Profiles } from "src/app/core/utils/profiles.enum";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ModalProfileActionsComponent } from "src/app/shared/components/modal-profile-actions/modal-profile-actions.component";
import { Store } from "@ngrx/store";
import { State } from "src/app/store/models/store.model";
import { availableProfiles } from "src/app/store/models/profiles.model";
import { MeetingsComponent } from "src/app/pages/meetings/meetings.component";
import { LocalStorage } from "src/app/core/utils";
import { MastersService } from "src/app/core/services/masters";
import { ControlPanelComponent } from '../../../../shared/components/control-panel/control-panel.component';
import {
	NbWindowRef
} from "@nebular/theme";
import { ChatAssistantComponent } from "src/app/shared/components/chat-assistant/chat-assistant.component";
import { ControlPanelAdminComponent } from "src/app/shared/components/control-panel-admin/control-panel-admin.component";
const NOIMAGE = "../../../../../assets/images/icons/account_circle.svg";
const GLADOS = "../../../../../assets/images/glados.png";
@Component({
    selector: "app-agent-display",
    templateUrl: "./agent-display.component.html",
    styleUrls: ["./agent-display.component.scss"],
    providers: [ImagenPipe],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AgentDisplayComponent implements OnInit {
	showUserAccount: boolean = false;

	@Output() _showUserAccount: EventEmitter<boolean> = new EventEmitter<boolean>();
	user: User;
	profiles = Profiles;
	inside: boolean;
	actualProfile: string;
	private destroy$ = new Subject();
	profilesOfUser: availableProfiles;
	comeFromProfile: string;
	tutorialesActivos: boolean = false;
	agentInfo: any;

	@HostListener("document:mousedown", ["$event"])
	onGlobalClick(event): void {
		if (!this.eRef.nativeElement.contains(event.target)) {
			this.showUserAccount = false;
			this.destroy$.next(true);
		}
	}

	constructor(
		private loginService: LoginService,
		private modalService: NgbModal,
		private imagePipe: ImagenPipe,
		private utils: Utils,
		public usersService: UsersService,
		private eRef: ElementRef,
		private localStorage: LocalStorage,
		private agentService : AgentService,
		@Optional() private NbWindowRef: NbWindowRef
	) {
		this.user = this.loginService.getUser();

		this.localStorage.setItem("TutorialesON", this.tutorialesActivos);
	}

	ngOnInit() {
		this.agentService.getAgentByIdUser(this.user.idUser).subscribe(data => {

			this.loginService.setCurrentAgent(data);
		});

		this.loginService.currentAgent$.subscribe( agent => {
			this.agentInfo = agent;

	});
	}

	ngOnDestroy() {
		this.destroy$.next(true);
	}

	showMenuPanel() {
		if (this.NbWindowRef) {
			this.NbWindowRef.close();
		}
		if (!this.showUserAccount) {
			this._showUserAccount.emit(true);
			this.showUserAccount = !this.showUserAccount;
		} else {
			this._showUserAccount.emit(false);
			this.showUserAccount = !this.showUserAccount;
			this.destroy$.next(true);
		}
	}

	getUserAvatar(): string {
		return this.utils.getAgentAvatar(this.agentInfo.profilePhotoUrl);
	}

	closeModal() {
		this.showUserAccount = false;
		this._showUserAccount.emit(false);
		this.destroy$.next(true);
	}
}
