<nb-card class="no-edge-card modal-max-height-100-65 mb-0 px-3 py-4">
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="row d-flex justify-content-center cards-container">
			<div class="col-12">
				<nb-card class="background_themecolor mb-0 p-4">
					<nb-card-header>
						<div class="row">
							<div class="col-12">
								<h2 class="text-center">
									{{'CENTROS.WHATDATA' | translate}}
								</h2>
							</div>
						</div>
					</nb-card-header>
					<nb-card-body class="container">
						<div class="cards">
							<div class="card-item" (click)="centersList()">
								<div class="card-image">
									<div class="icon-option icon-center pointer"></div>
								</div>
								<div class="card-info">
									<h6 class="card-title">
										{{'CENTROS.SEECENTERS' | translate}}
									</h6>
								</div>
							</div>
						</div>

						<div class="cards">
							<div class="card-item" (click)="teachersList()">
								<div class="card-image">
									<div class="menu-icon-teacher pointer"></div>
								</div>
								<div class="card-info">
									<h6 class="card-title">
										{{'CENTROS.SEETEACHERS' | translate}}
									</h6>
								</div>
							</div>
						</div>

						<div class="cards">
							<div class="card-item" (click)="studentsList()">
								<div class="card-image">
									<div class="menu-icon-student pointer"></div>
								</div>
								<div class="card-info">
									<h6 class="card-title">
										{{'CENTROS.SEESTUDENTS' | translate}}
									</h6>
								</div>
							</div>
						</div>
					</nb-card-body>
				</nb-card>
			</div>
		</div>
	</nb-card-body>
</nb-card>
