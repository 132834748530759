import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GroupModel } from "src/app/core/models/groups/groups-model";
import {
	CourseModel,
	DetailCourseTargetModel,
} from "src/app/core/models/courses";
import { User } from "src/app/core/models/users/user.models";
import { GruposService } from "src/app/core/services/groups/grupos.service";
import { LoginService } from "src/app/core/services/login";
import { Profiles } from "src/app/core/utils/profiles.enum";
import { TargetsService } from "src/app/core/services/targets";
import { CoursesService } from "src/app/core/services/courses";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { LocalStorage } from "src/app/core/utils";
import { LOCALSTORAGESTRINGS } from "src/app/core/models/masters/localstorage.enum";
import { Utils } from "src/app/core/utils/utils";
import { GraphService } from "src/app/core/services/graph/graph.service";
import { Router } from "@angular/router";
import { debounceTime } from "rxjs";

interface NavigatorWithConnection extends Navigator {
  connection?: {
    downlink: number;
  };
}

@Component({
	selector: "app-modal-listado-cursos",
	templateUrl: "./modal-listado-cursos.component.html",
	styleUrls: ["./modal-listado-cursos.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ModalListadoCursosComponent implements OnInit {
	group: GroupModel;
	public coursesList: any[] = [];
	public coursesList_aux: any[] = [];
	public cursosGrupo: any[] = [];
	public targetSelected: any[] = [];
	filter: {
		idGrupo: number;
		filter: string;
	};
	public formulario: UntypedFormGroup;
	cargando: boolean;

	profile: string;
	user: User;
	newGroup: boolean = false;
	screenView: number = 1;
	listMultiples: any;
	configMultiples: any;
	target: DetailCourseTargetModel;
	curso: CourseModel;
	cameFromCompetitions: boolean = false;
	cameFromCenters: boolean = false;
	idCenter: number;
	networkSpeed = 1;
	idEvent: number;

	constructor(
		public activeModal: NgbActiveModal,
		private groupService: GruposService,
		private formBuild: UntypedFormBuilder,
		private loginService: LoginService,
		private translateService: TranslateService,
		public targetsService: TargetsService,
		public coursesService: CoursesService,
		private toaster: ToasterService,
		private localStorage: LocalStorage,
		private utils: Utils,
		private graphServ: GraphService,
		private modalService: NgbModal,
		public router: Router
	) {
		this.formulario = this.formBuild.group({filtrado: [""]});
		this.setupSearchListener();
	}

	ngOnInit() {
		this.networkSpeed = this.getNetworkSpeed(); //primero verificamos la velocidad de internet
		this.profile = this.loginService.getProfile();
		this.user = this.loginService.getUser();
		this.getListadoCursos();
		this.filtrarCursos();
	}

	getNetworkSpeed() {
		const navigatorWithConnection = navigator as NavigatorWithConnection;
		if (navigatorWithConnection.connection) {
			return navigatorWithConnection.connection.downlink;
		} else {
			return 10; // No se pudo obtener la velocidad de conexión
		}
  }

	filtrarCursos() {
		this.cargando = true;
		//const filtradoValue = this.formulario.value;

		if(this.cameFromCenters == true){
			this.groupService
				.filtrarTargetsCenter("",
					this.idCenter
				)
				.subscribe(
					(res: any) => {
						this.coursesList = res.data;
						this.coursesList_aux = res.data;
						this.cargando = false;
					},
					(err) => console.log(err)
				);
		}
		else if (this.cameFromCompetitions === true) {
			this.groupService
				.filtrarTargetsCompetition(
					//filtradoValue,
					'',
					this.group ? this.group.idGroup : 0
				)
				.subscribe(
					(res: any) => {
						this.coursesList = res.data;
						this.coursesList_aux = res.data;
						this.cargando = false;
					},
					(err) => console.log(err)
				);
		} else {
			this.groupService
				.filtrarCursosBusqueda(
					this.group.idGroup,
					'',
					//filtradoValue,
					this.group.idProfessor
				)
				.subscribe(
					(res: any) => {
						this.coursesList = res.data;
						this.coursesList_aux = res.data;
						this.cargando = false;
					},
					(err) => console.log(err)
				);
		}
	}

	setupSearchListener() {
		this.formulario
			.get("filtrado")
			.valueChanges.pipe(debounceTime(800)) //TIEMPO QUE TENEMOS PARA ESCRIBIR EN EL FILTRO EN MILISEGUNDOS
			.subscribe(() => {
				this.filtrarPorTitulo();
			});
	}

	filtrarPorTitulo(){
		let searchTerm;
		if(this.formulario.get('filtrado').value != ''){
			searchTerm = this.normalizeText(this.formulario.get('filtrado').value.toLowerCase());
		}
    this.coursesList = [...this.coursesList_aux];
    if (searchTerm) {
			this.coursesList = this.coursesList.filter(curso =>
					this.normalizeText(curso.courseTitle.toLowerCase()).includes(searchTerm)
			);
    }
	}
	// Función para normalizar el texto eliminando tildes
	normalizeText(text) {
		return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
	}

	getListadoCursos() {
		this.cargando = true;
		if (this.cameFromCompetitions === true) {
			this.groupService
				.targetListCompetition("", this.group ? this.group.idGroup : 0)
				.subscribe((res: any) => {
					this.cursosGrupo = res.data;
					this.cargando = false;
				});
		}else if(this.cameFromCenters == true){
			this.groupService
				.targetAsignedToEvent(this.idEvent)
				.subscribe((res: any) => {
					this.cursosGrupo = res.data;
					this.cargando = false;
				});
		}else{
			this.groupService
				.targetListAsignedToGroup(this.group.idGroup, "")
				.subscribe((res: any) => {
					if (this.cameFromCompetitions) {
						this.cursosGrupo = [];
					} else {
						this.cursosGrupo = res.data;
					}

					this.cargando = false;
					this.group.assignedProjects = res.data.length;
				});
		}

	}

	closeModal(sendData?: any) {
		if (this.profile === Profiles.Teacher) {
			const selectedGroupInfo = {
				idGroup: this.group.idGroup,
				title: this.group.title,
				description: this.group.description,
				group: this.group,
				idUser: this.user.idUser,
				assignedProjects: this.group.assignedProjects,
				assignedStudents: this.group.assignedStudents,
			};
			localStorage.removeItem("selectedGroupInfo");
			localStorage.setItem(
				"selectedGroupInfo",
				JSON.stringify(selectedGroupInfo)
			);
		}
		this.activeModal.close(sendData);
	}

	agregarCurso(course) {
		this.cargando = true;
		this.groupService
			.agregarCurso(this.group.idGroup, course.idCourse)
			.subscribe(
				(res) => {
					this.toaster.success(
						this.translateService.instant("MODALLISTCOURSES.ADDEDCOURSE")
					);
					this.cargando = false;
					this.getListadoCursos();
					this.filtrarCursos();
				},
				(err) => console.log(err)
			);
	}

	agregarTarget(course, grafo) {
		this.cargando = true;

		if (this.cameFromCompetitions === true || this.cameFromCenters == true) {
			this.toaster.success("Se ha asignado el contenido al la competición");
			this.closeModal({ course: course, grafo: grafo });
		} else {
			this.groupService
				.agregarTarget(this.group.idGroup, course.idCourse, grafo.idTarget)
				.subscribe(
					(res) => {
						this.toaster.success(
							this.translateService.instant("MODALLISTCOURSES.ADDEDTARGET")
						);
						this.cargando = false;
						this.getListadoCursos();
						this.filtrarCursos();
					},
					(err) => console.log(err)
				);
		}
	}

	eliminarCurso(curso) {

		this.cargando = true;
		this.groupService
			.eliminarCurso(this.group.idGroup, curso.idCourse)
			.subscribe(
				(res) => {
					// let  elementIndex = this.users.findIndex((user => userInvitar.idUser == user.idUser));
					// this.users[elementIndex].invitedToGroup = "T";
					this.toaster.success(
						this.translateService.instant("MODALLISTCOURSES.REMOVEDCOURSE")
					);
					this.cargando = false;
					this.getListadoCursos();
					this.filtrarCursos();
				},
				(err) => console.log(err)
			);
	}

	eliminarGrafo(curso, grafo) {
		if (this.cameFromCompetitions === true) {
			this.targetSelected = [];
		} else {
			this.cargando = true;
			this.groupService
				.eliminarGrafo(this.group.idGroup, curso.idCourse, grafo.idTarget)
				.subscribe(
					(res) => {
						// let  elementIndex = this.users.findIndex((user => userInvitar.idUser == user.idUser));
						// this.users[elementIndex].invitedToGroup = "T";
						this.toaster.success(
							this.translateService.instant("MODALLISTCOURSES.REMOVEDTARGET")
						);
						this.cargando = false;
						this.getListadoCursos();
						this.filtrarCursos();
					},
					(err) => console.log(err)
				);
		}
	}

	editarMultiples(idCourse: number, idTarget: number) {
		this.cargando = true;
		this.getListMultiples(idCourse, idTarget);
	}

	getListMultiples(idCourse: number, idTarget: number) {
		this.targetsService
			.getListMultiplesByTarget(idCourse, idTarget)
			.subscribe((res) => {
				this.listMultiples = res.data;
				if (this.listMultiples != null) {
					this.targetsService
						.getListTargetQuizzesMultiplesActiveByTarget(
							idTarget,
							this.group.idGroup
						)
						.subscribe((res) => {
							this.configMultiples = res.data;
							this.targetsService
								.getTargetById(idTarget)
								.subscribe((result) => {
									this.target = result.data;
									this.coursesService
										.getCourseById(idCourse)
										.subscribe((result) => {
											this.curso = result.data.courses;
											this.screenView = 2;
											this.cargando = false;
										});
								});
						});
				}
			});
	}

	changeScreen(screen: number) {
		this.screenView = screen;
	}

	updateUrl(event: any) {
		event.target.src = "../../assets/images/no-image.png";
	}

	abrirGrafo(idCurso: any, idGrafo: any, titleGrafo: any) {
		//this.localStorage.removeItem('lastNodeElement');

		this.listaGrafosVisitados(idCurso, idGrafo, titleGrafo);

		this.localStorage.setItem("lastTarget", idGrafo);
		this.localStorage.removeItem("fromEditor");
		localStorage.setItem("dontLoadMenu", "true"); //Este provoca que no se muestre el menú al cambiar de perfil
		this.utils.loadMenu = false;
		this.graphServ.getGraphInfo(idGrafo).subscribe((res) => {
			this.graphServ.changedefaultTypeView(res.data.viewType);
			this.router
				.navigateByUrl(`/course/${idCurso}/graph/${idGrafo}`)
				.then((resp) => {
					setTimeout(() => {
						this.localStorage.removeItem("dontLoadMenu");
						localStorage.setItem("dontLoadMenu", "true");
						this.utils.loadMenu = false;
						//window.location.reload(); // SE COMENTA ESTA LINEA PORQUE RECARGABA LA PAGINA Y ME CAMBIABA EL ROL
						// this.sigmaCanvasService.setTriggerInitSigma(true);
					}, 100);
				});
			if (this.loginService.esAutor()) {
				let historialGrafos_editor = [];
				let localStoredHistory_editor = JSON.parse(
					localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHSHISTORY_EDITOR)
				);
				if (localStoredHistory_editor == null) {
					historialGrafos_editor.push(idCurso);
				} else {
					if (localStoredHistory_editor.length > 15) {
						localStoredHistory_editor.shift(); // Elimina el primer elemento
					}
					localStoredHistory_editor.push(idCurso);
				}
				historialGrafos_editor.push(idCurso);
				this.localStorage.setItem(
					LOCALSTORAGESTRINGS.GRAPHSHISTORY_EDITOR,
					JSON.stringify(
						localStoredHistory_editor == null
							? historialGrafos_editor
							: localStoredHistory_editor
					)
				);
			} else {
				let historialGrafos = [];
				let localStoredHistory = JSON.parse(
					localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHSHISTORY)
				);
				if (localStoredHistory == null) {
					historialGrafos.push(idCurso);
				} else {
					if (localStoredHistory.length > 15) {
						localStoredHistory.shift(); // Elimina el primer elemento
					}
					localStoredHistory.push(idCurso);
				}
				historialGrafos.push(idCurso);
				this.localStorage.setItem(
					LOCALSTORAGESTRINGS.GRAPHSHISTORY,
					JSON.stringify(
						localStoredHistory == null ? historialGrafos : localStoredHistory
					)
				);
			}

			this.modalService.dismissAll();
			this.closeModal(true);
			//this.activeModal.close();
		});
		this.targetsService.registercountopengraph().subscribe((res) => {});
	}

	listaGrafosVisitados(idCurso: any, idGrafo: any, titleGrafo: any) {
		// Obtenemos el array de los últimos grafos visitados.
		let graphVisited_Student =
			JSON.parse(this.localStorage.getItem("graphVisited_Student")) || [];
		const newElement = {
			title: titleGrafo,
			idGrafo: idGrafo,
			idCurso: idCurso,
		};
		// Validar si el nuevo elemento ya está en el array
		const isDuplicate = graphVisited_Student.some(
			(element) =>
				element.idGrafo === newElement.idGrafo &&
				element.idCurso === newElement.idCurso
		);
		// Si no es un duplicado, lo agregamos
		if (!isDuplicate) {
			// Si ya hay 10 elementos, eliminamos el ultimo.
			if (graphVisited_Student.length >= 10) {
				// con esto determinamos el tamaño del array
				graphVisited_Student.pop();
			}
			// Agregamos el nuevo elemento.
			graphVisited_Student.unshift(newElement);
			// Actualizamos el local storage.
			this.localStorage.setItem(
				"graphVisited_Student",
				JSON.stringify(graphVisited_Student)
			);
		}
	}

	viewTargets(curso){
		curso.targetVisible = !curso.targetVisible;
	}
}
