<form class="w-100 h-100" [formGroup]="infoForm">
    <div class="row justify-content-end" *ngIf="loginService.esEstudiante() || challengeOn || !modePractice">
				<div class="col-3 mt-2 d-flex justify-content-end" *ngIf="showClock == true">
					<countdown #cd [config]="config" (event)="handleCountDownEvent($event)" class="counter-down"></countdown>
        </div>
    </div>
    <div class="row mt-3">
        <div class="cont-small col-12">
            <h2>{{infoForm.get('instructions').value}}</h2>
        </div>
    </div>
</form>
