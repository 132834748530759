import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-celebration',
  templateUrl: './modal-celebration.component.html',
  styleUrls: ['./modal-celebration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalCelebrationComponent implements OnInit {

    public url:string = ''


    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() {}


    closeModal(sendData?: any) {
      this.activeModal.close(sendData);
    }

}
