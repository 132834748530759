<nb-card class="mb-0">
	<nb-card-header class="border-bottom-0">
		<div class="col-11">
			<div>
				<h5>{{"TEXTSMODAL.ADDTEXTTOIDEA" | translate }}</h5>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}"
			nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="m-5 row d-flex">

			<div class="modal-body">
				<div *ngIf="textForm">
					<form [formGroup]="textForm" autocomplete="off">

						<div class="row justify-content-center row">
							<div class="col-sm-12 col-md-6 mt-4">
								<input nbInput status="primary" fullWidth fieldSize="medium" class="form-control" formControlName="text"
									id="textInput" placeholder="{{'TEXTSMODAL.TEXTTOBEADDED' | translate }}" />
							</div>
							<div class="col-sm-12 col-md-6 mt-4">
								<div class="form-control">
									<label class="m-0 pr-2" for="colorPicker">{{'TEXTSMODAL.COLOR' | translate }}:</label>
									<input type="color" formControlName="color" id="colorPicker">
								</div>

							</div>

							<div class="col-sm-12 col-md-6 mt-4">
								<nb-select fullWidth name="font" formControlName="font" (selectionChange)="changeFont($event.value)">
									<nb-option *ngFor="let font of fontOptions" [value]="font.value" value="{{ font.value }}">
										<span [style.font-family]="font.value">{{font.key}}</span></nb-option>
								</nb-select>

							</div>
							<div class="col-sm-12 col-md-6 mt-4">
								<input nbInput status="primary" fullWidth fieldSize="medium" class="form-control" type="number"
									formControlName="fontSize" id="fontSize" placeholder="Tamaño de Fuente" />
							</div>

							<div class="col-sm-12 col-md-12 mt-4">
								<input nbInput status="primary" fullWidth class="form-control" type="url"
									formControlName="url" id="rul" placeholder="https://..." />
							</div>
						</div>

					</form>

					<div class="text-container">
						<div>
							<p id="text-container" #textContainer>{{ textForm.get('text').value }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>
	<nb-card-footer class="border-top-0">
		<div class="mb-2 row justify-content-center">
			<div class="col-6 d-flex justify-content-center">
				<button nbButton class="mr-1 btn-themecolor" (click)="generateImage()">{{'TEXTSMODAL.PREVIEW' | translate }}</button>
			</div>

			<div class="col-6 d-flex justify-content-center" *ngIf="generatedImage">
				<button nbButton (click)="saveSticker()"
					[style.background-color]="!generatedImage ? 'gray' : 'blue'" class="btn-themecolor">{{'TEXTSMODAL.ADD' | translate }}</button>
			</div>
		</div>
	</nb-card-footer>
</nb-card>
