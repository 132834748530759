import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginService } from 'src/app/core/services/login';

@Injectable({
  providedIn: 'root'
})
export class InfoEstudianteService {

  constructor(
    private http: HttpClient, private loginService: LoginService
  ) { }



  // @GetMapping("/list-courses-student/{idUser}")


  getDataInfoCursosQuizesGrafro(param?: string){
    const tareasBean = {
      param: param? param: null,
    }
    return this.http.post(`course/list-courses-student`, tareasBean);
  }


  // @GetMapping("/gettable-quizzes-student/{idUser}/{idCourse}/{idTarget}/{idNode}/{param}")

  getDataTable(idCourse:number, idTarget: number, idNode: number, param?: string){

    return this.http.get(`course/gettable-quizzes-student/${idCourse}/${idTarget}/${idNode}/${param}`);
  }


}
