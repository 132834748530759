<div class="modal-body fondoEditarCurso" align="center">
    <form class="form-horizontal">
        <div class="row mx-auto">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="col-sm-8 mt-2 d-flex justify-content-between">
                        <!--div class="col-sm-4"-->
                            <button [ngStyle]="{'color':'rgb(33, 76, 152)'}">
                                <mat-icon class="pointer" svgIcon="profesores" ngbTooltip="{{'TASK.PROFESORES'|translate}}"></mat-icon>
                            </button>
                        <!--/div-->
                        <!--div class="col-sm-4"-->
                            <button [ngStyle]="{'color':'rgb(33, 76, 152)'}">
                                <mat-icon class="pointer" svgIcon="alumnos" ngbTooltip="{{'TASK.ALUMNOS'|translate}}"></mat-icon>
                            </button>
                        <!--/div-->
                        <!--div class="col-sm-4"-->
                            <button [ngStyle]="{'color':'rgb(33, 76, 152)'}">
                                <mat-icon class="pointer" svgIcon="favorite" ngbTooltip="{{'TASK.FAVORITOS'|translate}}"></mat-icon>
                            </button>
                        <!--/div-->
                </div>
                <div class="col-sm-8 border border-secondary mt-2 p-2">
                    <span
                        class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'TASK.MISPROFESORES' | translate }}</span>
                    <div class="fondoBlanco mt-2 mb-2">
                        <div *ngFor="let profesor of profesores">
                            {{profesor.nombre}}
                        </div>
                    </div>
                    <span
                        class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'TASK.GRUPOS' | translate }}</span>
                    <div class="fondoBlanco mt-2 mb-2">
                        <div *ngFor="let grupo of grupos">
                            {{grupo.nombre}}
                        </div>
                    </div>
                    <span
                        class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'TASK.NOMBRE' | translate }}</span>
                    <input type="text" class="form-control mt-2">
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 mt-2">
                <div class="col-sm-12 border border-secondary p-2">
                    <div class="row" *ngFor="let alumno of alumnos">
                        <div class="col-sm-3">
                            <img class="avatar avatar-16 img-circle" src={{alumno.img}}/>
                        </div>
                        <div class="col-sm-9">
                            <div class="row">
                                <div class="col-sm-8">
                                    <span
                                        class="textoFontSize1 colorTextoActivado font-weight-bold">{{alumno.nombre}}
                                    </span>
                                </div>
                                <div class="col-sm-4">
                                    <span
                                        class="textoFontSize07 colorTextoActivado font-weight-bold">{{alumno.fecha}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mt-2">
                    <div class="row">
                        <div class="col-sm-10 p-0">
                            <textarea class="form-control" rows="1"></textarea>
                        </div>
                        <div class="col-sm-2 mx-auto align-items-center justify-content-center">
                            <button [ngStyle]="{'color':'rgb(33, 76, 152)'}">
                                <mat-icon class="enviar pointer" svgIcon="send" ngbTooltip="{{'TASK.ENVIAR'|translate}}" (click)="compartirTareas()"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
