<!-- <nb-card *ngIf="selectedOption == null">
	<nb-card-header>Seleccione una opción</nb-card-header>
	<nb-card-body>
		<div class="options-container">
			<nb-card (click)="selectOption('manual')" class="option-card" [ngClass]="{'selected': selectedOption === 'manual'}">
				<nb-card-body>
					<h2>Seleccionar forma manualmente</h2>
					<p>Elija y configure la forma usted mismo</p>
				</nb-card-body>
			</nb-card>
			<nb-card (click)="selectOption('ai')" class="option-card" [ngClass]="{'selected': selectedOption === 'ai'}">
				<nb-card-body>
					<h2>Proponer forma con IA</h2>
					<p>Deje que la IA sugiera la mejor forma para usted</p>
				</nb-card-body>
			</nb-card>
		</div>
	</nb-card-body>
	<nb-card-footer>

		<button nbButton status="basic" (click)="cancel()">Cancelar</button>
	</nb-card-footer>
</nb-card> -->

<nb-card *ngIf="selectedOption == null && salwizarIsOpen == false && shapeGenerationStarted == false" >
	<nb-card-header>

		<div style="display: flex; justify-content: space-between; align-items: center;">
			<h5>Seleccione una opción</h5>
			<button nbButton status="primary" style="margin-right: 2em;" (click)="openSalwizarContenido()">Quiero ayuda por IA</button>
		</div>

	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2"
			[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"
			nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="options-container">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Opciones </th>
          <td mat-cell *matCellDef="let element" (click)="setSelectedShape(element.number,true)" [ngClass]="{'selected-row': selectedShape === element}"> {{element.description}} </td>
        </ng-container>

        <!-- Shape Column -->
        <ng-container matColumnDef="shape">
          <th mat-header-cell *matHeaderCellDef> Dibujo </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'selected-row': selectedShape == element}">
            <img [src]="element.shape" alt="Shape" width="50" height="50">
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
	</nb-card-body>
	<nb-card-footer>
		<button nbButton status="primary" (click)="confirm()" [disabled]="selectedShape == null">Guardar selección.</button>
		<!-- <button nbButton status="basic" (click)="cancel()">Cancelar</button> -->
	</nb-card-footer>
</nb-card>

<nb-card *ngIf="shapeGenerationStarted == true">
	<nb-card-header>Seleccione una opción</nb-card-header>
	<nb-card-body>
		<ng-container *ngIf="loading == true; else proposals">
			<nb-progress-bar [value]="value" [status]="status" [displayValue]="true"></nb-progress-bar>
			<h5 style="font-weight: bold; text-align: center;">Generando las formas con IA</h5>
			<p style="text-align: center; font-style: italic;">Puede tardar unos segundos</p>
		</ng-container>
		<ng-template #proposals>
			<div class="options-container">
				<nb-card class="dialog-card">
					<nb-card-header class="text-center">
						<h4 class="mb-0">Propuestas de forma del Ideografo</h4>
					</nb-card-header>

					<nb-card-body>
						<div class="content-grid">
							<!-- Headers -->
							<div class="grid-headers">
								<div class="header">OPCIONES</div>
								<div class="header">DIBUJO</div>
								<div class="header">JUSTIFICACIÓN</div>
							</div>

							<!-- Row 1 -->
							<div class="grid-row" *ngFor="let shape of ProposedShapes">
								<div class="option">
									<nb-radio-group [(ngModel)]="shapeSelected" (ngModelChange)="setSelectedShape(shape.codigo)">
										<nb-radio [value]="shape.codigo">
											{{shape.Tipo_Forma}}
										</nb-radio>
									</nb-radio-group>

								</div>
								<div class="drawing">
									<nb-icon icon="star-outline" class="custom-icon"></nb-icon>
								</div>
								<div class="justification">
									<p>{{shape.Razon}}</p>
									<button nbButton outline size="small" status="primary">Ver ideas</button>
								</div>
							</div>


						</div>
					</nb-card-body>
				</nb-card>

			</div>
		</ng-template>



	</nb-card-body>
	<nb-card-footer>
		<button nbButton status="primary" (click)="closeModal()" [disabled]="loading == true">Guardar selección.</button>
		<button nbButton status="primary" (click)="openDesignIdeasModal()" [disabled]="loading == true">Crear ideas</button>
	</nb-card-footer>
</nb-card>
