<div class="container mt-4" style="overflow-x: auto;" *ngIf="ideoGrafoSelected !== null">

    <ul class="list-group">
        <li class="list-group-item list-group-item-info  d-flex justify-content-between align-items-center">
            <strong>{{'IDEOGRAPHQUIZZES.COURSE' | translate}} : </strong><span>{{cursoSelected.title}}</span>
        </li>
        <li class="list-group-item list-group-item-info d-flex justify-content-between align-items-center">
            <strong>{{'IDEOGRAPHQUIZZES.COURSE' | translate}} : </strong><span>{{ideoGrafoSelected.target.tittle}}</span>
        </li>
        <li class="list-group-item list-group-item-success d-flex justify-content-between align-items-center">
          <strong>{{'IDEOGRAPHQUIZZES.QUIZZES' | translate}} : </strong>
          <span class="badge badge-primary badge-pill">{{resultsLength}}</span>
        </li>
    </ul>

    <mat-list class="mat-list-het">
        <div class="row"  *ngIf="!cargando">
            <mat-list-item class="col-6 col-sm-4 pointer quiz-item animated fast fadeIn" *ngFor="let quiz of quizes  | sortBy:'asc':'quizTittle'"
            ngbTooltip="{{quiz.quizTittle}}" (click)="abrirQuizPreview(quiz);"
            >
                <mat-icon mat-list-icon svgIcon="inte"></mat-icon>
                <div mat-line class="mat-line_q">{{quiz.quizTittle}}</div>
                <div mat-line class="mat-line_n"> {{quiz.nodeTitle | titlecase | truncate: 15}}</div>
            </mat-list-item>
        </div>
        <div *ngIf="cargando" class="flex align-items-center justify-content-center mt-4 mb-2">
            <mat-spinner></mat-spinner>
        </div>
    </mat-list>

</div>
