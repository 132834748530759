import { UsersService } from './../../../core/services/users/users.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaisModel } from 'src/app/core/models/masters';
import { ExporarContenidoService } from 'src/app/core/services/explorar-contenido/exporar-contenido.service';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { environment } from 'src/environments/environment';
import { ICoursesExplorarContenido, IdeografosExplorarContenidoInterface } from './interfaces/explorar-contenido.interface';

declare var window
@Component({
  selector: 'app-explorar-contenido',
  templateUrl: './explorar-contenido.component.html',
  styleUrls: ['./explorar-contenido.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ExplorarContenidoComponent  implements AfterViewInit {

  displayedColumns: string[] = ["title", 'idCountry', 'autor'];

  dataSource: MatTableDataSource<ICoursesExplorarContenido>;
  public urlImageCourse = `${environment.imagesTargets}` ;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  expandedElement: IdeografosExplorarContenidoInterface | null;
  ideoGrafoSelected: any = null;
  cursoSelected: any;
  paises:any;
  resultsLength: number = 0;
  isLoadingResults = false;
  isRateLimitReached = false;

  buscarNodo = false;


  constructor(private explorarContService: ExporarContenidoService, private getDataService: GetDataService,
    private activeModal: NgbActiveModal, public userService: UsersService) {
    this.obtenerCursosPublicados();
  }
  obtenerCursosPublicados() {

    this.explorarContService.getListadoCursosConGrafos().subscribe( (res: ICoursesExplorarContenido[]) => {
      this.dataSource = new MatTableDataSource(res);
      this.resultsLength = res.length;
      this.isLoadingResults = false;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, err => console.error(err))
  }

  ngAfterViewInit() {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  close(){
    this.activeModal.close();
  }

  verQuizes(grafoSeleted, cursoSelected){
    this.ideoGrafoSelected = {...grafoSeleted};
    this.cursoSelected = {...cursoSelected};
    this.explorarContService.emitClickIdeoGrafo({ideografo: this.ideoGrafoSelected.target.idTarget, curso: this.cursoSelected.idCourse })
    //Mandamos al usuario al final de la pantalla para ver la selección
    let myElement = document.getElementById("explorerContent");

    window.scrollTo(0,myElement.scrollHeight);

  }

  updateUrl(event: any) {
    event.target.src = '../../assets/images/no-image.png'
  }

}
