<div class="container">
    <div class="form-group row">
        <div class="col-md-10 col-12">
            <div class="form-group row">
                <label class="col-2">{{ 'NODOS.TITULO' | translate }}</label>
                <div class="col-10">
                    <input type="text" class="form-control" required [(ngModel)]="tittle">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-2">{{ 'NODOS.DESCRIPCION' | translate }}</label>
                <div class="col-10">
                    <input type="text" class="form-control" required [(ngModel)]="description">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-2">{{ 'NODOS.ETIQUETAS' | translate }}</label>
                <div class="col-10">
                    <textarea type="text" class="form-control" required [(ngModel)]="labels"></textarea>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-2 col-12">
                    <div *ngFor="let format of formats" class="row">
                        <div id="izquierda" class="izquierda">
                            <div class="image-upload">
                                <label for="file-input">
                                    <mat-icon id="izquierda" class="izquierda" title={{format.titulo}}>
                                        {{format.formato}}</mat-icon>
                                </label>
                                <input id="file-input" type="file" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="row">
                        <div id="centro" class="centro" (click)="elegirOpcionSend()">
                            <div *ngIf="selectedOptionSend" class="row">
                                <div *ngFor="let icon of iconosSenmotemos" class="col-2">
                                    <img id="icono" class="icono" src={{icon.src}}>
                                </div>
                            </div>
                        </div>
                        <div id="centro" class="centro" (click)="elegirOpcionVolume()">
                            <div *ngIf="selectedOptionVolume" class="row">
                                <div *ngFor="let icon of iconosSenmotemos" class="col-2">
                                    <img id="icono" class="icono" src={{icon.src}}>
                                </div>
                            </div>
                        </div>
                        <div id="centro" class="centro" (click)="elegirOpcionPicture()">
                            <div *ngIf="selectedOptionPicture" class="row">
                                <div *ngFor="let icon of iconosSenmotemos" class="col-2">
                                    <img id="icono" class="icono" src={{icon.src}}>
                                </div>
                            </div>
                        </div>
                        <div id="centro" class="centro" (click)="elegirOpcionImage()">
                            <div *ngIf="selectedOptionImage" class="row">
                                <div *ngFor="let icon of iconosSenmotemos" class="col-2">
                                    <img id="icono" class="icono" src={{icon.src}}>
                                </div>
                            </div>
                        </div>
                        <div id="centro" class="centro" (click)="elegirOpcionText()">
                            <div *ngIf="selectedOptionText" class="row">
                                <div *ngFor="let icon of iconosSenmotemos" class="col-2">
                                    <img id="icono" class="icono" src={{icon.src}}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="row">
                        <div class="row">
                            <div #senmotemo id="senmotemo" class="senmotemo">
                                <div class="row-md-4">
                                    <div id="titulo-claro" class="titulo-claro">SENMOTEMO</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2" *ngFor="let senmotemo of senmotemos">
                                        <img class="icono" (click)="adicionarIconoSenmotemos(senmotemo)"
                                            title={{senmotemo.nombre}} src={{senmotemo.src}}>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div id="assostatic" class="assostatic">
                                <div class="row-md-4">
                                    <div id="titulo-claro" class="titulo-claro">ASSOSTATIC</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2" *ngFor="let assostatic of assostatics">
                                        <img id="icono" class="icono" (click)="adicionarIconoAssostatics(assostatic)"
                                            title={{assostatic.nombre}} src={{assostatic.src}}>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div id="temporary" class="temporary">
                                <div class="row-md-4">
                                    <div id="titulo-oscuro" class="titulo-oscuro">TEMPORARY</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2" *ngFor="let temporary of temporaries">
                                        <img id="icono" class="icono" (click)="adicionarIconoTemporaries(temporary)"
                                            title={{temporary.nombre}} src={{temporary.src}}>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div id="abstract" class="abstract">
                                <div class="row-md-4">
                                    <div id="titulo-oscuro" class="titulo-oscuro">ABSTRACT</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2" *ngFor="let abstract of abstracts">
                                        <img id="icono" class="icono" (click)="adicionarIconoAbstracts(abstract)"
                                            title={{abstract.nombre}} src={{abstract.src}}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 col-12">
            <div class="row" (click)="reproducirFormato()">
                <div id="menu-derecha" class="menu-derecha">
                    <img id="menu-derecha" class="menu-derecha" src="../../../../../assets/images/nodes/button-play.png">
                </div>
            </div>
            <div class="row">
                <div id="menu-derecha" class="menu-derecha">
                    <img id="menu-derecha" class="menu-derecha" src="../../../../../assets/images/flags/en.png">
                </div>
            </div>
            <div class="row">
                <div id="menu-derecha" class="menu-derecha">
                    <img id="menu-derecha" class="menu-derecha" src="../../../../../assets/images/nodes/clock.png">
                </div>
            </div>
            <div class="row">
                <div id="menu-derecha" class="menu-derecha">
                    <img id="menu-derecha" class="menu-derecha" src="../../../../../assets/images/nodes/statistics.png">
                </div>
            </div>
            <div class="row">
                <div id="menu-derecha" class="menu-derecha">
                    <img id="menu-derecha" class="menu-derecha" src="../../../../../assets/images/nodes/author.png">
                </div>
            </div>
            <div class="row">
                <div id="menu-derecha" class="menu-derecha">
                    <img id="menu-derecha" class="menu-derecha" src="../../../../../assets/images/nodes/deleteNode.png">
                </div>
            </div>
        </div>
    </div>

    <app-modal #modalReproducirFormato titulo="{{ 'NODOS.PANTALLADENODO' | translate }}">
        <div class="modal-body">
            <!-- <app-reproducir-nodo></app-reproducir-nodo> -->
        </div>
    </app-modal>
