<form class="w-100 h-100 mt-4" [formGroup]="infoForm">
    <div class="row mb-2" >
        <div class="col-12">
            <!-- <h4>Titulo</h4> -->
            <!-- <mat-form-field appearance="standard" class="full-width">
                <mat-label>Título</mat-label>
                <input matInput type="text" formControlName="title" />
            </mat-form-field> -->
            <!-- <p >{{infoForm.get('title').value}}</p> -->
        </div>

        <!-- <div class="col-2"></div> -->

        <!-- <div class="col-2">
            <div style="width: 20px;height:20px">
                <canvas #canvas></canvas>
            </div>
        </div> -->
        <div class="col timer-canvas">
            <canvas #canvas></canvas>
                <!-- <canvas #canvas></canvas> -->
        </div>
    </div>
    <div class="row">
        <div class="col">
            <!-- <mat-form-field appearance="standard" class="full-width">
                <mat-label>Instrucciones</mat-label>
                <input matInput type="text" formControlName="instructions" />
            </mat-form-field> -->
            <!-- <h4>Instrucciones</h4> -->
            <p><strong>{{infoForm.get('instructions').value}}</strong></p>
        </div>
    </div>
</form>
