<div class="file-container">

    <label style="z-index: 100;" class="uploader" ondragover="return false;"
    [class.loaded]="loaded"
    [style.outlineColor]="dragging ? activeColor : baseColor"
    (dragenter)="handleDragEnter()"
    (dragleave)="handleDragLeave()"
    (drop)="handleDrop($event)"
    [style.backgroundColor] = "backgroundColor? backgroundColor : '#f4f4f4'">

    <i class="icon icon-upload"
        [style.color]="dragging
            ? ((imageSrc.length > 0) ? overlayColor : activeColor)
            : ((imageSrc.length > 0) ? overlayColor : baseColor)"></i>

    <img style="z-index: 100;"
        [src]="imageSrc"
        (load)="handleImageLoad()"
        [class.loaded]="imageLoaded"/>

       <span *ngIf="!imageLoaded">Arrastre una imagen aqui</span>

    </label>

         <input type="file" name="file" accept="image/*"
          (change)="handleInputChange($event)"
          class="cancel-btn"
          style="z-index: 100;">


        <button  (click)="cancel()" class="cancel-btn btn btn-danger btn-sm pointer mt-4"  style="z-index: 900;">Cancelar</button>



</div>


