<div>
              <div class="form-group row">
                <div class="form-group col">
                            <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" >Texto</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" id="texto" required name="texto">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" >Materia</label>
                                    <div class="col-sm-10">
                                       <select id="materia" class="form-control" required>
                                          <option *ngFor="let materia of materias" [value]="materia">{{materia}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" >Nivel</label>
                                    <div class="col-sm-10">
                                        <select id="nivel" class="form-control" required>
                                            <option *ngFor="let nivel of niveles" [value]="nivel">{{nivel}}</option>
                                        </select>
                                    </div>
                               </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" >Idioma</label>
                                    <div class="col-sm-10">
                                        <select id="idioma" class="form-control" required>
                                            <option *ngFor="let idioma of idiomas" [value]="idioma">{{idioma}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" >País</label>
                                    <div class="col-sm-10">
                                        <select id="pais" class="form-control" required>
                                            <option *ngFor="let pais of paises" [value]="pais">{{pais}}</option>
                                        </select>
                                    </div>
                               </div>
                               <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" >Región</label>
                                    <div class="col-sm-10">
                                        <select id="region" class="form-control" required>
                                            <option *ngFor="let region of regiones" [value]="region">{{region}}</option>
                                        </select>
                                    </div>
                                </div>
                </div>
                <div *ngIf="cursosRealizados.length > 0" class="form-group col">
                    <div class="form-group row">
                        <div *ngFor="let curso of cursosRealizados" class="col-3">
                              <mat-card>
                                <mat-card-content>
                                     <img mat-card-image src="https://i.pinimg.com/originals/05/81/3a/05813a15b0a66e8cea6dea6ea909469b.jpg">
                                     <p>{{curso}}</p>
                                </mat-card-content>
                              </mat-card>
                        </div>
                    </div>
                </div>
               </div>
      </div>
      <div>
        <button type="button" class="btn btn-secondary btn-block" (click)="cancelar()">
                Cancelar
        </button>
        <button type="button" class="btn btn-primary btn-block" (click)="registrar()">
            Registrar
        </button>

      </div>
