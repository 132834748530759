import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NodeFilesFormat } from 'src/app/core/models/nodes/node-files-format.model';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
	selector: 'app-modal-instagram-video',
	templateUrl: './modal-instagram-video.component.html',
	styleUrls: ['./modal-instagram-video.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ModalInstagramVideoComponent implements OnInit {
	public format: NodeFilesFormat;

	validationMessages = {
		url: []
	};

	form:UntypedFormGroup

	constructor(private fb: UntypedFormBuilder,
		public activeModal: NgbActiveModal,
		public translateService: TranslateService,
		private toaster: ToasterService
	) {
		this.form = this.fb.group({
			url: ['', Validators.required],
			isDelete: [0],
			idNodeFile: [0]
		});
	}

	traducirOpciones():void{
		this.translateService.get('INSTAGRAMVIDEO.LINKREQUIRED').subscribe((res: string) => {
				this.validationMessages.url.push({ type: 'required', message: res });
		});
	}

	get urlControl() {
		if (this.form) {
				return (this.form.get('url') as UntypedFormControl);
		} else {
				return null;
		}
	}

	ngOnInit() {
		this.traducirOpciones()
		this.getFormatValues()
	}

	postVideo(){
		if(this.form.valid){
			const regExp = /((?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:p|reel|reels)\/([^/?#&]+)).*/
			const match = this.form.get('url').value.match(regExp);

			if (match && match[2].length == 11) {
				this.form.get('idNodeFile').setValue(this.format.idNodeFile)
				this.form.get('url').setValue(match[1])
				this.closeModal(this.form.value)
			} else {
				this.toaster.error(this.translateService.instant('INSTAGRAMVIDEO.ERRORID'))
			}
		}
	}

	getFormatValues(){
		this.form.get('url').setValue(this.format.url)
	}

	delete(){
		if(this.form.valid){
			this.form.get('isDelete').setValue(1)
			this.form.get('idNodeFile').setValue(this.format.idNodeFile)
			this.closeModal(this.form.value)
		}
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

}
