import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SurveyModel } from 'src/app/core/models/surveys/survey.model';
import { SurveyStackModel } from 'src/app/core/models/surveys/surveyStack.model';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { SurveysService } from 'src/app/core/services/surveys/surveys.service';

@Component({
  selector: 'app-surveys-answer',
  templateUrl: './surveys-answer.component.html',
  styleUrls: ['./surveys-answer.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SurveysAnswerComponent implements OnInit {
	@Input() survey: SurveyModel = null;
	@Input() idEvent: number = 0;
	@Input() anonymous: number = 0
	@Output() answeredSurvey: EventEmitter<boolean> = new EventEmitter<boolean>();

	cargando: boolean = false;
	listAnswers: any[] = [];
	allOk: boolean = false; //esta variable determina si se han respondido todas las preguntas obligatorias
	showMessage: boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		public surveysService: SurveysService,
		private toaster: ToasterService,
		public translateService: TranslateService,
	){}

	ngOnInit(): void{
		//creamos el listado de respuestas
		for (let index = 0; index < this.survey.questionsList.length; index++) {
			const element = this.survey.questionsList[index];
			let q = {
				idSurveyQuestion: element.idSurveyQuestion,
				answer: "",
				ok: element.required == 0 ? true : false,
			};
			this.listAnswers.push(q);
		}
	}

	closeModal(sendData?: any) {
		//this.activeModal.close(sendData);
		this.answeredSurvey.emit(true);
	}

	onResponseSelected(response: any) {
    const existingResponseIndex = this.listAnswers.findIndex((answer: any) => answer.idSurveyQuestion === response.idSurveyQuestion);
    if (existingResponseIndex !== -1) {
      // Si el objeto con el mismo id ya existe, lo reemplazamos
      this.listAnswers[existingResponseIndex] = response;
    } else {
      // Si no existe, lo agregamos
      this.listAnswers.push(response);
    }
		this.allOk = this.listAnswers.every(element => element.ok === true); //si es true ya podemos enviar la respuesta
	}

	sendResponse(){
		let stackList = [];
		if(this.allOk){
			for (let index = 0; index < this.listAnswers.length; index++) {
				const element = this.listAnswers[index];
				let stack: SurveyStackModel = new SurveyStackModel(
					0,
					this.idEvent,
					this.anonymous,
					this.survey.idSurvey,
					element.idSurveyQuestion,
					this.survey.questionsList[index].type,
					0,
					0,
					"",
					"",
				);
				switch (this.survey.questionsList[index].type) {
					case 1: //escala lineal
						stack.answerLikert = Number(element.answer);
						break;
					case 2: //seleccion unica
						stack.answerSimple = Number(element.answer);
						break;
					case 3: //seleccion multiple
						stack.answerMulti = element.answer;
						break;
					case 4: //respuesta abierta
						stack.answerText = element.answer;
						break;
				}
				stackList.push(stack);
			}
			this.surveysService.createUpdateSurveyStack(stackList).subscribe(res => {
				if(res){
					this.toaster.success(this.translateService.instant('ENCUESTAS.SENDANSWEROK'));
					this.closeModal();
				} else{
					this.toaster.error(this.translateService.instant('ENCUESTAS.SENDANSWERFAIL'));
				}
			});
		} else{
			this.showMessage = true;
		}
	}

}
