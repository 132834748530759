import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-radial-niveles',
  templateUrl: './radial-niveles.component.html',
  styleUrls: ['./radial-niveles.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class RadialNivelesComponent implements OnInit{
	@Input() parametroID: number | null = null;
	@Input() consultaID: number | null = null;
	@Input() datasetsImported: any = null;
	@Input() labelsImported: any = null;

	chart: any = null;
	labels: any[] = [];
	datasets: any[] = [];

  constructor(
		private translateService: TranslateService,
	) { }

  ngOnInit() {
		if (this.consultaID !== null) {
			//this.datasets = this.datasetsImported;
			//this.labels = this.labelsImported;
			this.labels = this.obtenerLabels(this.datasetsImported, 0);
			this.datasets = [this.obtenerLabelsConEstructura(this.datasetsImported, 0)];
    }
		setTimeout(() =>	{
			if(this.parametroID == 1 && this.consultaID != null){
				this.createChartAciertos();
			}
		}, 200);
  }

	createChartAciertos(){
		var canvas = document.getElementById('myChartControlNiveles') as HTMLCanvasElement;
		var ctx = canvas.getContext('2d');
		if (this.chart) {
			this.chart.destroy();
		}
		this.chart = new Chart(ctx, {
			type: 'radar',
			data: {
				labels: this.labels,
				datasets: this.datasets
			},
			options: {
				responsive: true,
				scales: {
					r: { // 'r' hace referencia a las escalas radiales en un gráfico radar
						min: 0,  // Establecer el valor mínimo del eje en 0
						max: 100, // Establecer el valor máximo del eje en 100
						ticks: {
							stepSize: 50, // Define los pasos de 25 en 25
							callback: function(value) {
								return value + '%'; // Mostrar el valor con un símbolo de porcentaje
							},
							font: {
								size: 10, // Tamaño del texto de los ticks
							},
						},
						grid: {
								color: 'rgba(0, 0, 0, 0.5)',  // Color de la cuadrícula (círculos radiales)
								lineWidth: 0.5, // Grosor de las líneas de la cuadrícula
						},
					}
				},
				plugins: {
					legend: {
						display: false,
						position: 'right',
					},
					filler: {
						propagate: true,
					},
					zoom: {
						zoom: {
							wheel: {
								enabled: false,
							},
							pinch: {
								enabled: false,
							},
							mode: 'x',
						},
						pan: {
							enabled: true,
							mode: 'x',
							scaleMode: 'x',
						},
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								let label = context.dataset.label || '';
								if (label) {
									label += ': ';
								}
								label += context.raw + '%'; // Formatea los valores del tooltip como porcentajes
								return label;
							}
						}
					}
				},
			}
		});
	}

	obtenerLabels(dataSets, index) {
		const filteredData = dataSets
		.map(info => info.data[index])
		.filter(value => value !== null);
    return dataSets.map(info => '');
	}
	obtenerLabelsConEstructura(dataSets, index) {
    const filteredData = dataSets
        .map(info => info.data[index])
        .filter(value => value !== null);
    const backgroundColor = filteredData.map(value => this.colorKnowledge(value));
    return {
        label: '',
        data: filteredData,
        backgroundColor: backgroundColor,
    };
	}
	colorKnowledge(valor: number): string {
		let color = "rgba(154, 155, 156, 0.5)"; // gris con 50% de transparencia por defecto
		if (valor == 0) color = "rgba(154, 155, 156, 0.5)"; // #9A9B9C
		else if (valor > 0 && valor < 11) color = "rgba(251, 13, 28, 0.5)"; // #FB0D1C
		else if (valor < 21) color = "rgba(252, 92, 32, 0.5)"; // #FC5C20
		else if (valor < 31) color = "rgba(252, 154, 39, 0.5)"; // #FC9A27
		else if (valor < 41) color = "rgba(254, 229, 51, 0.5)"; // #FEE533
		else if (valor < 51) color = "rgba(255, 253, 56, 0.5)"; // #FFFD38
		else if (valor < 61) color = "rgba(212, 253, 53, 0.5)"; // #D4FD35
		else if (valor < 71) color = "rgba(156, 253, 50, 0.5)"; // #9CFD32
		else if (valor < 81) color = "rgba(41, 248, 46, 0.5)"; // #29F82E
		else if (valor < 91) color = "rgba(42, 213, 45, 0.5)"; // #2AD52D
		else if (valor < 101) color = "rgba(26, 175, 84, 0.5)"; // #1AAF54
		return color;
	}

}
