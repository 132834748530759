import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UtilsService {
    public constructor() { }

    getDateFormatted(getDate: Date) {

        // adjust 0 before single digit date
        const date = ('0' + getDate.getDate()).slice(-2);

        // current month
        const month = ('0' + (getDate.getMonth() + 1)).slice(-2);

        // current year
        const year = getDate.getFullYear();

        let hours = ('0' + (getDate.getUTCHours())).slice(-2);
        let minutes = ('0' + (getDate.getUTCMinutes())).slice(-2);
        let seconds = ('0' + (getDate.getUTCSeconds())).slice(-2);

        // prints date in YYYY-MM-DD format
        const fecha = `${year}-${month}-${date}T${hours}:${minutes}:${seconds}.000+00:00`;

        return fecha;
    }

    public minutesToTime(minutes: number = 0) {
        let h: string = Math.trunc(minutes / 60).toString();
        let m: string = Math.trunc(minutes % 60).toString();
        if (m.length < 2) {
            m = '0' + m;
        }
        if (h.length < 2) {
            h = '0' + h;
        }
        return h + ':' + m;
    }

		public minutesToTimeBootstrap(minutes: number = 0) {
			let h = Math.trunc(minutes / 60);
			let m = Math.trunc(minutes % 60);

			return {hour: h, minute: m}
	}

    public timeToMinutes(time: string) {
        const timeParts: string[] = time.split(':');
        return (parseInt(timeParts[0]) * 60) + parseInt(timeParts[1]);
    }

    public getWindowOrientation(): string{
        let value: string = ''

        if (window.matchMedia("(orientation: portrait)").matches) {
            value = 'portrait'
        }

        if (window.matchMedia("(orientation: landscape)").matches) {
            value = 'landscape'
        }

        return value
    }

		public isMobileDevice(): boolean {
			return window.matchMedia("(max-width: 600px)").matches;
		}

		private _scrollMeasureTreeActivities: number = 0;

		public get scrollMeasureTreeActivities(): number {
			return this._scrollMeasureTreeActivities;
		}
		public set scrollMeasureTreeActivities(value: number) {
			this._scrollMeasureTreeActivities = value;
		}
}
