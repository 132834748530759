<div class="pointer">
	<div class="avatar" [ngStyle]="{ 'background-image': getUserAvatar() }" (click)="showMenuPanel()"
		nbTooltip="{{ 'SIDEBAR.TUSDATOS' | translate }}" nbTooltipPlacement="right">
		<div *ngIf="actualProfile == 'ESTUDIANTE'" class="status-icon-student"></div>
		<div *ngIf="actualProfile == 'PROFESOR'" class="status-icon-teacher"></div>
		<div *ngIf="actualProfile == 'PADRE'" class="status-icon-parent"></div>
		<div *ngIf="actualProfile == 'AUTOR'" class="status-icon-editor"></div>
		<div *ngIf="actualProfile == 'ADMIN'" class="status-icon-admin"></div>
		<div *ngIf="actualProfile == 'CENTRO'" class="status-icon-center"></div>
	</div>
	<div *ngIf="showUserAccount">
		<nb-card class="menu-card">
			<nb-card-body>
				<div>
					<h6>{{ user.firstName }}</h6>
				</div>
				<div>
					<p class="userId m-0">ID: {{ user.identification }}</p>
				</div>
				<div class="d-flex align-items-center" (click)="onToggleTutorialChange()"
					[ngClass]="{ 'disable-tutorials': !tutorialesActivos }">
					<span>Tutoriales</span>
					<!-- Icono animado de youtube -->
					<dotlottie-player src="https://lottie.host/466c0d63-bcd0-4911-9ceb-1822ec78ebac/hpaPfd47al.lottie" background="transparent" speed="1" style="width: 50px; height: 50px" loop autoplay></dotlottie-player>
				</div>
				<nb-list *ngIf="profilesOfUser">
					<!-- <nb-list-item (click)="showModalProfileActions()" >
							<nb-icon icon="keypad-outline" class="mr-2"></nb-icon>
							Menu
						</nb-list-item> -->
					<nb-list-item (click)="setProfile(profiles.Student)" *ngIf="profilesOfUser.profileStudent == true"
						[ngClass]="{ 'active-menu-item': actualProfile == 'ESTUDIANTE' }" style="padding-left: 0px">
						<div class="col-5">
							<div class="mr-2 menu-icon-student"></div>
						</div>
						<div>{{ "LOGIN.STUDENT" | translate }}</div>
					</nb-list-item>
					<nb-list-item (click)="setProfile(profiles.Teacher)" *ngIf="profilesOfUser.profileTeacher == true"
						[ngClass]="{ 'active-menu-item': actualProfile == 'PROFESOR' }" style="padding-left: 0px">
						<div class="col-5">
							<div class="mr-2 menu-icon-teacher"></div>
						</div>
						<div>{{ "LOGIN.TEACHER" | translate }}</div>
					</nb-list-item>
					<nb-list-item (click)="showControlPanelParent()" *ngIf="profilesOfUser.profileParent == true"
						[ngClass]="{ 'active-menu-item': actualProfile == 'PADRE' }" style="padding-left: 0px">
						<div class="col-5">
							<div class="mr-2 menu-icon-parent"></div>
						</div>
						<div>{{ "LOGIN.PARENT" | translate }}</div>
					</nb-list-item>
					<nb-list-item (click)="setProfile(profiles.Author)" *ngIf="profilesOfUser.profileEditor == true"
						[ngClass]="{ 'active-menu-item': actualProfile == 'AUTOR' }" style="padding-left: 0px">
						<div class="col-5">
							<div class="mr-2 menu-icon-editor"></div>
						</div>
						<div>{{ "LOGIN.EDITOR" | translate }}</div>
					</nb-list-item>
					<nb-list-item (click)="showControlPanel()" *ngIf="profilesOfUser.profileCenter == true"
						[ngClass]="{ 'active-menu-item': actualProfile == 'CENTRO' }" style="padding-left: 0px">
						<div class="col-5">
							<div class="mr-2 menu-icon-center"></div>
						</div>
						<div>{{ "LOGIN.CENTER" | translate }}</div>
					</nb-list-item>
					<nb-list-item (click)="showControlPanelAdmin()" *ngIf="profilesOfUser.profileAdmin == true"
						[ngClass]="{ 'active-menu-item': actualProfile == 'ADMIN' }" style="padding-left: 0px">
						<div class="col-5">
							<div class="mr-2 menu-icon-admin"></div>
						</div>
						<div>{{ "Admin" }}</div>
					</nb-list-item>
					<nb-list-item (click)="showGeneralSettings()" ngbTooltip="{{ 'SIDEBAR.SETTINGS' | translate }}"
						style="padding-left: 0px">
						<div class="col-5">
							<div class="mr-2 menu-icon-settings"></div>
						</div>
						<div>{{ "SETTINGS.GENERALTITLE" | translate }}</div>
					</nb-list-item>


					<!-- <nb-list-item (click)="showChatAssistant()" ngbTooltip="{{ 'SIDEBAR.SETTINGS' | translate }}"
						style="padding-left: 0px">
						<div class="col-5">
							<div class="mr-2 menu-icon-settings"></div>
						</div>
						<div>Asistente IA</div>
					</nb-list-item> -->

					<!-- <nb-list-item
					(click)="sos()"
					ngbTooltip="SOS"
					style="padding-left: 0px"
				>
					<div class="col-5">
						<nb-icon icon="video-outline" class="mr-2"></nb-icon>
					</div>
					<div>SOS</div>
				</nb-list-item> -->

					<nb-list-item (click)="logout()" ngbTooltip="{{ 'SIDEBAR.LOGOUT' | translate }}" style="padding-left: 0px">
						<div class="col-5">
							<div class="mr-2 menu-icon-power"></div>
						</div>
						<div>{{ "SIDEBAR.LOGOUT" | translate }}</div>
					</nb-list-item>


				</nb-list>
			</nb-card-body>
		</nb-card>
	</div>
</div>
