import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NodeFilesFormat } from 'src/app/core/models/nodes/node-files-format.model';
import { FILE_FORMATS } from 'src/app/core/utils/file-formats';
import { environment } from 'src/environments/environment';

@Component({
	selector: "app-node-detail-files-player",
	templateUrl: "./node-detail-files-player.component.html",
	styleUrls: ["./node-detail-files-player.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class NodeDetailFilesPlayerComponent implements OnInit {
	@Input() format: NodeFilesFormat;
	@Input() node: any;
	@Output() filesUploaderPlayFile: EventEmitter<string> =
		new EventEmitter<string>();

	TXT_TYPE = FILE_FORMATS.TXT_TYPE;
	PDF_TYPE = FILE_FORMATS.PDF_TYPE;
	AUDIO_TYPE = FILE_FORMATS.AUDIO_TYPE;
	FILES_URL = environment.remoteFilesUrl + "nodeContent";
	ext: string = "";
	sanitizedUrl: SafeResourceUrl;
	pdfSource: string;
	constructor(private sanitizer: DomSanitizer) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (this.format && this.fileUrl(this.PDF_TYPE, this.format.background) !== undefined) {
			this.ext = this.fileUrl(this.PDF_TYPE, this.format.background)
				.split(".")
				.pop();

		if(this.ext != "" || this.ext != undefined){
			this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.format.background)
		}
		}

		// You can also use categoryId.previousValue and
		// categoryId.firstChange for comparing old and new values
	}


	private fileUrl(type: string, fileName: string) {
		if (!fileName) return undefined;
		return `${this.FILES_URL}/${type}/${fileName}`;
	}

	playFile() {
		this.filesUploaderPlayFile.emit(this.format.type);
	}
}
