
    <div class="w-100">

        <div class="container mt-4">
            <h4>{{ 'QUIZZES.DATOS' | translate }}</h4>
            <div  class="row align-items-end mb-2"  >
                <div class="d-flex align-items-center justify-content-center" *ngFor="let datos of Datos; let i = index">

                    <div class="row"  *ngIf="elementTypes.Q_PICTURES === datos.elementType">
                        <div class="col-12  d-flex align-items-center justify-content-center ">
                            <div ngResizable
                                (rzStop)="onResizeStop($event, i, datos)"
                                [style.width]="datos.xsize !== 0 ? datos.xsize + 'px' : 'fit-content'"
                                [style.height]=" datos.ysize !== 0 ?  datos.ysize + 'px' : '100%'"
                            >
                                <img  class="w-100 h-100"
                                [src]='mediaViewUrl+DIR_PICTURE_URL+"/"+datos.data|safe'
                                >
                            </div>
                        </div>
                        <div class="col-12  d-flex align-items-center justify-content-center ">
                            <button mat-icon-button="" matsuffix="" class="mat-icon-button mat-button-base">
                                <span class="mat-button-wrapper">
                                    <mat-icon class="mat-icon-white btn-link pointer mat-icon notranslate mat-secondary material-icons mat-icon-no-color" color="secondary" role="img" aria-hidden="true" (click)="deleteElement(datos, true, false, false)">
                                        delete
                                    </mat-icon>
                                </span>

                            </button>
                        </div>

                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end" *ngIf="elementTypes.Q_AUDIOS === datos.elementType && datos.data">
                        <audio [src]='mediaViewUrl+DIR_AUDIO_URL+"/"+datos.data|safe' controls></audio>
                        <button mat-icon-button matSuffix >
                            <mat-icon class="mat-icon-white btn-link pointer" ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" (click)="deleteElement(datos, true, false, false)">delete</mat-icon>
                        </button>
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end container" *ngIf="elementTypes.Q_VIDEOS === datos.elementType && datos.data">
                        <div ngResizable
                            (rzStop)="onResizeStop($event, i, datos)"
                            [style.width]="datos.xsize !== 0 ? datos.xsize + 'px' : 'fit-content'"
                            [style.height]=" datos.ysize !== 0 ?  datos.ysize + 'px' : '100%'"
                        >
                            <video  class="w-100 h-100"  [src]='mediaViewUrl+DIR_VIDEO_URL+"/"+datos.data|safe' controls>
                            </video>
                        </div>
                        <button mat-icon-button matSuffix >
                            <mat-icon class="mat-icon-white btn-link pointer" ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" (click)="deleteElement(datos, true, false, false)">delete</mat-icon>
                        </button>
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end container" *ngIf="elementTypes.Q_PDFS === datos.elementType && datos.data">
                        <div ngResizable
                        (rzStop)="onResizeStop($event, i, datos)"
                        [style.width]="datos.xsize !== 0 ? datos.xsize + 'px' : 'fit-content'"
                        [style.height]=" datos.ysize !== 0 ?  datos.ysize + 'px' : '100%'"
                        >
                            <iframe #pdfElement class="w-100 h-100" [src]='mediaViewUrl+"pdf/"+datos.data |safe' allowfullscreen></iframe>
                        </div>
                        <button mat-icon-button matSuffix >
                            <mat-icon class="mat-icon-white btn-link pointer" ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" (click)="deleteElement(datos, true, false, false)">delete</mat-icon>
                        </button>
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end container" *ngIf="elementTypes.Q_TEXTS === datos.elementType && datos.data">
                        <div [innerHTML]="datos.data"   style="max-width: 350px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                        </div>
                        <button mat-icon-button matSuffix >
                            <mat-icon class="mat-icon-white btn-link pointer" ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" (click)="deleteElement(datos, true, false, false)">delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 mt-2"  >
                <div class="col-12 flex align-items-center justify-content-center ">
                    <button mat-icon-button matSuffix  [disabled]="disabelNuevoEnDatos" (click)="addElementToDatos()">
                        <mat-icon class="mat-icon-white addElement" ngbTooltip ="{{ 'QUIZZES.AGREGARELEMENTO' | translate }}" color="secondary" >cloud_upload</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="container mt-4">

            <div class="col-12 w-100">
                <h4>{{ 'QUIZZES.RESPUESTAS' | translate }}</h4>
            </div>
            <div class="row">
                <div class="col-2 col-sm-6 col-md-4 col-lg-2 col-xl-2 flex align-items-center justify-content-center " *ngFor="let datos of Respuestas" >
                    <div class="col-12 pointer" *ngIf="elementTypes.O_PICTURES === datos.elementType">
                        <div class="col-12 flex align-items-center justify-content-center ">
                            <button mat-icon-button matSuffix >
                                <mat-icon class="mat-icon-white icon-select" ngbTooltip ="{{ 'QUIZZES.HYPERMEDIA.IMAGEN' | translate }}" color="secondary">imagen</mat-icon>
                            </button>
                        </div>
                        <div class="col-12 flex align-items-center justify-content-center ">
                            <button mat-icon-button matSuffix >
                                <mat-icon class="mat-icon-white" ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary"(click)="deleteElement(datos, false, true, false)">delete</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="elementTypes.O_VIDEOS === datos.elementType">

                        <div class="col-12 flex align-items-center justify-content-center ">
                            <button mat-icon-button matSuffix >
                                <mat-icon  class="mat-icon-white icon-select" ngbTooltip="{{ 'QUIZZES.HYPERMEDIA.VIDEO' | translate }}">videocam</mat-icon>
                            </button>
                        </div>
                        <div class="col-12 flex align-items-center justify-content-center ">
                            <button mat-icon-button matSuffix >
                                <mat-icon class="mat-icon-white " ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" (click)="deleteElement(datos, false, true, false)">delete</mat-icon>
                            </button>
                        </div>

                    </div>

                    <div class="col-12" *ngIf="elementTypes.O_PDFS === datos.elementType">

                        <div class="col-12 flex align-items-center justify-content-center ">
                            <button mat-icon-button matSuffix >
                                <mat-icon  class="mat-icon-white icon-select" ngbTooltip="{{ 'QUIZZES.HYPERMEDIA.PDF' | translate }}">insert_drive_file</mat-icon>
                            </button>
                        </div>
                        <div class="col-12 flex align-items-center justify-content-center ">
                            <button mat-icon-button matSuffix >
                                <mat-icon class="mat-icon-white " ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" (click)="deleteElement(datos, false, true, false)">delete</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="elementTypes.O_TEXTS === datos.elementType">

                        <div class="col-12 flex align-items-center justify-content-center ">
                            <button mat-icon-button matSuffix >
                                <mat-icon  class="mat-icon-white icon-select" ngbTooltip="{{ 'QUIZZES.HYPERMEDIA.TEXT' | translate }}">subject</mat-icon>
                            </button>
                        </div>
                        <div class="col-12 flex align-items-center justify-content-center ">
                            <button mat-icon-button matSuffix   style="color: gray;"  (click)="deleteElement(datos, false, true, false)">
                                <mat-icon class="mat-icon-white " ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" >delete</mat-icon>
                            </button>
                        </div>

                    </div>

                    <div class="col-12" *ngIf="elementTypes.O_AUDIOS === datos.elementType">
                        <div class="col-12 flex align-items-center justify-content-center ">
                            <button mat-icon-button matSuffix >
                                <mat-icon  class="mat-icon-white icon-select" ngbTooltip="{{ 'QUIZZES.HYPERMEDIA.AUDIO' | translate }}">volume_up</mat-icon>
                            </button>
                        </div>
                        <div class="col-12 flex align-items-center justify-content-center ">
                            <button mat-icon-button matSuffix >
                                <mat-icon class="mat-icon-white " ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" (click)="deleteElement(datos, false, true, false)">delete</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="elementTypes.O_MICRO === datos.elementType">
                        <div class="col-12 flex align-items-center justify-content-center ">
                            <button mat-icon-button matSuffix >
                                <mat-icon  class="mat-icon-white icon-select" ngbTooltip="{{ 'QUIZZES.HYPERMEDIA.MICRO' | translate }}">micro</mat-icon>
                            </button>
                        </div>
                        <div class="col-12 flex align-items-center justify-content-center ">
                            <button mat-icon-button matSuffix >
                                <mat-icon class="mat-icon-white " ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" (click)="deleteElement(datos, false, true, false)">delete</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="elementTypes.O_VIDEOCAMARA === datos.elementType">
                        <div class="col-12 flex align-items-center justify-content-center ">
                            <button mat-icon-button matSuffix >
                                <mat-icon  class="mat-icon-white icon-select" ngbTooltip="{{ 'QUIZZES.HYPERMEDIA.VIDEOCAM' | translate }}">camera</mat-icon>
                            </button>
                        </div>
                        <div class="col-12 flex align-items-center justify-content-center ">
                            <button mat-icon-button matSuffix >
                                <mat-icon class="mat-icon-white " ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" (click)="deleteElement(datos, false, true, false)">delete</mat-icon>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 mt-2"  >
                <div class="col-12 flex align-items-center justify-content-center ">
                    <button mat-icon-button matSuffix  [disabled]="disabelNuevoEnRes" (click)="addElementToRespuestas()">
                        <mat-icon class="mat-icon-white addElement" ngbTooltip ="{{ 'QUIZZES.AGREGARELEMENTO' | translate }}" color="secondary" >cloud_upload</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="container mt-4">
            <h4>{{ 'QUIZZES.SOLUCIONES' | translate }}</h4>
            <div class="row align-items-end mb-2">

                <div class="d-flex align-items-center" *ngFor="let datos of Soluciones; let i = index" >

                    <div class="w-100 h-100 flex justify-content-between align-items-end container" *ngIf="elementTypes.A_PICTURES === datos.elementType">
                        <div ngResizable
                        (rzStop)="onResizeStop($event, i, datos)"
                        [style.width]="datos.xsize !== 0 ? datos.xsize + 'px' : 'fit-content'"
                        [style.height]=" datos.ysize !== 0 ?  datos.ysize + 'px' : '100%'"
                        >
                            <img  class="w-100 h-100"
                            [src]='mediaViewUrl+DIR_PICTURE_URL+"/"+datos.data|safe'
                            >
                        </div>
                        <button mat-icon-button matSuffix >
                            <mat-icon class="mat-icon-white btn-link pointer" ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" (click)="deleteElement(datos, false, false, true)">delete</mat-icon>
                        </button>
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end" *ngIf="elementTypes.A_AUDIOS === datos.elementType">
                        <audio   [src]='mediaViewUrl+DIR_AUDIO_URL+"/"+datos.data|safe' controls>
                        </audio>
                        <button mat-icon-button matSuffix >
                            <mat-icon class="mat-icon-white btn-link pointer" ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" (click)="deleteElement(datos, false, false, true)">delete</mat-icon>
                        </button>
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end container" *ngIf="elementTypes.A_VIDEOS === datos.elementType">
                        <div ngResizable
                        (rzStop)="onResizeStop($event, i, datos)"
                        [style.width]="datos.xsize !== 0 ? datos.xsize + 'px' : 'fit-content'"
                        [style.height]=" datos.ysize !== 0 ?  datos.ysize + 'px' : '100%'"
                        >
                            <video  class="w-100 h-100"  [src]='mediaViewUrl+DIR_VIDEO_URL+"/"+datos.data|safe' controls>
                            </video>
                        </div>
                        <button mat-icon-button matSuffix >
                            <mat-icon class="mat-icon-white btn-link pointer" ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" (click)="deleteElement(datos, false, false, true)">delete</mat-icon>
                        </button>
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end container" *ngIf="elementTypes.A_PDFS === datos.elementType">
                        <div ngResizable
                        (rzStop)="onResizeStop($event, i, datos)"
                        [style.width]="datos.xsize !== 0 ? datos.xsize + 'px' : 'fit-content'"
                        [style.height]=" datos.ysize !== 0 ?  datos.ysize + 'px' : '100%'"
                         >
                            <iframe #pdfElement class="w-100 h-100" [src]='mediaViewUrl+"pdf/"+datos.data |safe' allowfullscreen></iframe>
                        </div>
                        <button mat-icon-button matSuffix >
                            <mat-icon class="mat-icon-white btn-link pointer" ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" (click)="deleteElement(datos, false, false, true)">delete</mat-icon>
                        </button>
                    </div>

                    <div class="w-100 h-100 flex justify-content-between align-items-end container" *ngIf="elementTypes.A_TEXTS === datos.elementType">
                        <div [innerHTML]="datos.data"   style="max-width: 350px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                        </div>
                        <button mat-icon-button matSuffix >
                            <mat-icon class="mat-icon-white btn-link pointer" ngbTooltip ="{{ 'QUIZZES.ELIMINAR' | translate }}" color="secondary" (click)="deleteElement(datos, false, false, true)">delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 mt-2"  >
                <div class="col-12 flex align-items-center justify-content-center ">
                    <button mat-icon-button matSuffix  [disabled]="disabelNuevoEnSol" (click)="addElementTSoluciones()">
                        <mat-icon class="mat-icon-white addElement" ngbTooltip ="{{ 'QUIZZES.AGREGARELEMENTO' | translate }}" color="secondary" >cloud_upload</mat-icon>
                    </button>
                </div>
            </div>

        </div>
    </div>



