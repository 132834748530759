import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { LoginService } from 'src/app/core/services/login';
import { LocationStrategy } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { LocalStorage } from '../../utils';

declare function init_plugins();

@Component({
    selector: 'app-base-layout',
    templateUrl: './base-layout.component.html',
    styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent implements OnInit {

    showMultiTabs:boolean = false

    constructor(private localStorage:LocalStorage,  private location: LocationStrategy, private loginService:LoginService) {
        // preventing back button in browser
        history.pushState(null, null, window.location.href);
        this.location.onPopState(() => {
            history.pushState(null, null, window.location.href);
        });
    }

    ngOnInit() {
        this.localStorage.setItem(LOCALSTORAGESTRINGS.WINDOW, Math.random())
        init_plugins();
    }

    @HostListener('window:storage', ['$event'])
    storageHandle($ev){
        this.showMultiTabs = true
    }

    closeMessage($event){
        this.localStorage.setItem(LOCALSTORAGESTRINGS.WINDOW, Math.random())
        this.showMultiTabs = false
    }

    logout(): void { this.loginService.logout(true); }

}
