<nb-card class="no-edge-card modal-max-height-100-65 mb-0 px-3 py-4">
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="row d-flex justify-content-center cards-container">
			<div class="col-12">
				<nb-card class="background_themecolor mb-0 p-4">
					<nb-card-header>
						<div class="row">
							<div class="col-12">
								<h3 class="text-center">
									{{ 'STRUCTURE.FILLSTRUCTUREDATA' | translate }}
								</h3>
							</div>
						</div>
					</nb-card-header>
					<nb-card-body class="container">
						<form (submit)="createStructure()">
							<div class="form-group">
								<label for="numberOfStudents">{{ 'STRUCTURE.NUMBERSTUDENTS' | translate }}</label>
								<input type="number"  [(ngModel)]="numberStudents" nbInput fullWidth hero fieldSize="medium" class="form-control" id="numberOfStudents" name="numberOfStudents">
							</div>
							<div class="form-group">
								<label for="numberOfTeachers">{{ 'STRUCTURE.NUMBERTEACHERS' | translate }}</label>
								<input type="number"  [(ngModel)]="numberTeachers" nbInput fullWidth hero fieldSize="medium" class="form-control" id="numberOfTeachers" name="numberOfTeachers">
							</div>
							<div>
								<button class="form-control" type="submit" nbButton status="primary" [disabled]="creating == true">{{ 'STRUCTURE.CREATESTRUCTURE' | translate }}</button>
							</div>
						</form>
					</nb-card-body>
				</nb-card>
			</div>
		</div>
	</nb-card-body>
</nb-card>
