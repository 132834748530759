export class UserValidationModel {
    idUser: number;
    ip: string;
    uuid: string;
    temporaryPassword: string;
    newUser: number;
    messageSMS: string;
    attempNumbers: number;
    sendDate: Date;
    sendDateString: string;
    validationDate: Date;
    validationDateString: string;
}
