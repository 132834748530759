

<div class="card text-center">
    <div class="card-header ">
        <div class="col-12 row justify-content-center">
            <h4>{{title}}</h4>
        </div>
        <div class="col-12 row justify-content-center">
            <h6 class="pointer btn-link" (click)="closeModal('')">X</h6>
        </div>
    </div>
    <div class="card-body ">
       <div class="col-12">
           <div style="width: 100% !important; height: 100% !important;" *ngIf="isVideo" >
                <video   width="420" height="340"  [src]='url | safe' controls></video>
           </div>
           <div  style="width: 100% !important; height: 100% !important;" *ngIf="isImagen" >
                <img  [src]='url | safe' class="card-img-top" alt="...">
            </div>
            <div  style="width: 100% !important; height: 100% !important;" *ngIf="isAudio"  >
                <audio [src]='url | safe' controls>
                </audio>
            </div>
            <div  style="width: 100% !important; height: 100% !important;" *ngIf="isPdf"  >
                <iframe #pdfElement [src]='url |safe'  width="400" height="500" allowfullscreen></iframe>
            </div>
       </div> 
       <div class="col-12">
            <p class="card-text">{{message}}</p>
       </div>
       <div class="row flex align-items-center justify-content-center mt-2">
            <div class="col-2">
                <button type="button" class="btn btn-outline-info btn-block" (click)="closeModal('')" >Cerrar</button>
            </div>
       </div>
    </div>
</div>
