export class UserStudentParentModel {
    idUserStudentParent: number;
    idUser: number;
    firstName: string;
    surname: string;
    birthdate: string;
    birthdateString: string;
    mail: string;
    mobile: string;
    creationDate: Date;
    creationDateString: string;
    validated: boolean;
}
