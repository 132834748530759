<ul style="list-style-type: none;" class="p-0 m-0 d-flex align-items-center h-100">
	<li *ngFor="let label of labels; let i = index">
		<nb-card class="no-edge-card mb-0" *ngIf="i < 1">
			<nb-card-body class="p-0">
				<div class="d-flex flex-wrap gap-1 justify-content-center">
					<div *ngFor="let dataset of datasets; let j = index"
						[ngStyle]="{'display': dataset.data[i] != 0 ? 'block' : 'none'}">
						<nb-card class="mb-0" [ngStyle]="{'background-color': colorKnowledge(dataset.data[i])}">
							<!-- <nb-card-header class="border-header">
								<div class="img-container">
									<img [src]="fotosEstudiantes[j + 1]" class="img-fluid">
								</div>
							</nb-card-header> -->
							<nb-card-body style="padding: 0.5rem 1rem;"></nb-card-body>
						</nb-card>
					</div>
				</div>
			</nb-card-body>
		</nb-card>
	</li>
</ul>
