export interface TutorialTemplateModel {
    idTutorialTemplate: number;
    idTemplate
}

export enum Estado {
    PENDIENTE = 0,
    FINALIZADO,
    SALTADO,
    NO_MOSTRAR
}


export interface TutotrialDataLocal {
    estudiante: EstData;
    idUser: number;
    curso: CursoData;
    canvasGrafo: CanvasData;
    quizData: QuizData;
    sideBarData: boolean;
}

export interface  EstData{
    cambiarContenido: boolean;
}

export interface  CursoData{
    btnNuevo: boolean;
    ideografo: boolean;
    btnPublicar: boolean;
    editGrafo: boolean;
}

export interface  CanvasData{
    crearActividad: boolean;
    nodoNodo: boolean;
    quizQuiz: boolean;
}

export interface  QuizData{
    btnPublicar: boolean;
    quizQuiz: boolean;
}