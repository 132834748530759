<form class="w-100 h-100" [formGroup]="infoForm">
    <div class="row justify-content-end">
        <div class="col-6 d-flex justify-content-center align-items-end" *ngIf="loginService.esEstudiante()">
					<p class="numero-hijo" *ngIf="quiz.idMultiplexQuiz === 1">{{titulo}}</p>
        </div>
				<div class="col-3 mt-3 pr-5 d-flex justify-content-end" *ngIf="loginService.esEstudiante()">
					<countdown #cd [config]="config" (event)="handleCountDownEvent($event)" class="counter-down" *ngIf="showClock == true"></countdown>
				</div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <h2>{{infoForm.get('instructions').value}}</h2>
        </div>
    </div>
</form>
