
 <div class="row m-0">
    <div *ngIf="grabando" class="col-12 d-flex justify-content-center">
      <p class="text-break card-text font-weight-bold"><span id="minutes">{{minutes}}</span>:<span id="seconds">{{seconds}}</span></p>
    </div>
    <div class="col-12 d-flex justify-content-center">
        <div *ngIf="!grabando && audioFiles">
          <audio  *ngFor="let audio of audioFiles" controls='true' [src]="audio"></audio>
        </div>
        <div *ngIf="grabando">
          <img src="/assets/images/dots_2.gif" width="50px" height="50px" class="loading-gif" alt="{{ 'LOADING.CARGANDO' | translate }}" />
        </div>
    </div>
    <div class="col-12 d-flex justify-content-center">
      <mat-icon *ngIf="grabando" class="stop pointer" (click)="stopRecording()">stop</mat-icon>
      <mat-icon *ngIf="!grabando && audioFiles" (click)="deleteAll()" class="stop pointer" >delete</mat-icon>
    </div>
    

  </div>
  