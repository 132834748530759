import { Component, Input, OnInit, ViewEncapsulation, HostListener } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ReportService } from "src/app/core/services/report/report.service";

@Component({
	selector: "app-informes-centro",
	templateUrl: "./informes-centro.component.html",
	styleUrls: ["./informes-centro.component.scss"],
	encapsulation: ViewEncapsulation.None,
	animations : [
		trigger('accordion', [
      state('open', style({
        width: 'auto',
				transform: 'translateY(0)',
  			overflow: 'initial',
				right: '10px'
      })),
      state('closed', style({
        width: '0',
				overflow: 'hidden',
  			transform: 'translateY(-500px)',
				right: '-200px'
      })),
      transition('open <=> closed', [
        animate('0.3s ease-out')
      ]),
    ]),
    trigger('accordion2', [
      state('in', style({
        width: 'auto',
				transform: 'translateX(0px)',
  			overflow: 'initial',
				left: '10px'
      })),
      state('out', style({
        width: '0',
				overflow: 'hidden',
  			transform: 'translateX(-500px)',
				left: '-200px'
      })),
      transition('in <=> out', [
        animate('0.3s ease-in-out')
      ]),
    ])
	]
})
export class InformesCentroComponent implements OnInit {
	@Input() allDatasets: any[] = [];
	@Input() datasetsImported: any;
	@Input() labelsImported: any;
	@Input() fromCalendar: boolean = false;
	parametroID: number | null = null;
	consultaID: number | null = null;
	parametroID2: number = 2;

	allLabels: any[] = [];
	selectedLabel = null; // Variable para guardar el elemento seleccionado

	label_grupos: any[];
	label_asignaturas: any[];
	label_niveles: any[];

	isMobile: boolean = false;
	isMobile1: boolean = false;
	isMenuOpen: boolean = false;
	accordionOpen: boolean = false;
	accordionOpen2: boolean = false;

	parametros: any[] = [
		{ id: 1, title: this.translateService.instant("CENTRO.ACIERTOS")},
		{ id: 2, title: this.translateService.instant("CENTRO.CONOCIMIENTO")},
		{ id: 3, title: this.translateService.instant("CENTRO.TRABAJO")},
		{ id: 4, title: this.translateService.instant("CENTRO.CONSTANCIA")},
		{ id: 5, title: this.translateService.instant("CENTRO.CARGA")},
		{ id: 6, title: this.translateService.instant("CENTRO.SOCIALIZACION")},
	];
	parametros_aux: any[] = [];
	consultas: any[] = [];

	verBotonesGraficas: number = 0;
	textoCentro: string = '0.0% ' + this.translateService.instant("GLOBALS.AVERAGE");

	constructor(
		public activeModal: NgbActiveModal,
		private translateService: TranslateService,
		private reportService: ReportService,
	) { }

	ngOnInit() {
		if(this.fromCalendar){
			this.consultas = null;
			this.parametroID = 0;
			this.consultaID = 0;
			this.verBotonesGraficas = 2;
		}
		this.parametros_aux = this.parametros.filter(parametro => parametro.id !== this.parametroID);
		this.onConsultaChange();

		this.isMobile = window.innerWidth < 1200;
		this.isMobile1 = window.innerWidth < 1350;
	}

	ngOnDestroy() {
	}

	closeModal(sendData?: any) {
		this.activeModal.close();
	}

	@HostListener("window:resize", ["$event"])
	onResize(event: any): void {
		if (window.innerWidth <= 1200) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}

		if (window.innerWidth <= 1350) {
			this.isMobile1 = true;
		} else {
			this.isMobile1 = false;
		}
	}

	onParametroChange() {
		const aux = this.verBotonesGraficas;
		//this.ocultarTodo();
		//this.consultaID = null; // Resetear el siguiente select
		setTimeout(() => {
			this.verBotonesGraficas = aux == 0 ? 1 : aux;
			this.changeLabel(this.selectedLabel, 0);
		}, 10);
		//this.verBotonesGraficas = 0;
		this.parametros_aux = this.parametros.filter(parametro => parametro.id !== this.parametroID);
		this.parametroID2 = this.parametros_aux[0].id;
	}

	onConsultaChange() {
		this.closeTree();
		switch (this.consultaID) {
			case 0:
				//indicadores sociales
				this.datasetsImported = this.allDatasets[0].data;
				this.labelsImported = [this.allDatasets[0]];
			case 1:
				//profesores por nivel
				this.datasetsImported = this.allDatasets[0].data;
				this.labelsImported = [this.allDatasets[0]];
				//this.verBotonesGraficas = 4;
				break;
			case 2:
				//profesores por asignatura
				this.datasetsImported = this.allDatasets[0].children[0].children[0].data;
				this.labelsImported = [this.allDatasets[0].children[0].children[0]];
				//this.verBotonesGraficas = 3;
				//desplegamos el arbol
				this.allDatasets[0].childrenVisible = true;
				this.allDatasets[0].children[0].childrenVisible = true;
				break;
			case 4:
				//grupos por nivel
				this.datasetsImported = this.allDatasets[0].data;
				this.labelsImported = [this.allDatasets[0]];
				//this.verBotonesGraficas = 3;
				break;
			case 5:
				//grupos por asignatura
				this.datasetsImported = this.allDatasets[0].children[0].data;
				this.labelsImported = [this.allDatasets[0].children[0]];
				//this.verBotonesGraficas = 2;
				//desplegamos el arbol
				this.allDatasets[0].childrenVisible = true;
				break;
			case 8:
				//estudiantes por grupo
				this.datasetsImported = this.allDatasets[0].children[0].data;
				this.labelsImported = [this.allDatasets[0].children[0]];
				//this.verBotonesGraficas = 3;
				//desplegamos el arbol
				this.allDatasets[0].childrenVisible = true;
				break;
			case 9:
				//estudiantes por asignatura
				this.datasetsImported = this.allDatasets[0].children[0].children[0].data;
				this.labelsImported = [this.allDatasets[0].children[0].children[0]];
				//this.verBotonesGraficas = 1;
				//desplegamos el arbol
				this.allDatasets[0].childrenVisible = true;
				this.allDatasets[0].children[0].childrenVisible = true;
				break;
			case 19:
				//asignaturas por nivel
				this.datasetsImported = this.allDatasets[0].data;
				this.labelsImported = [this.allDatasets[0]];
				//this.verBotonesGraficas = 2;
				break;
			case 20:
				//asignaturas por grupo
				this.datasetsImported = this.allDatasets[0].children[0].data;
				this.labelsImported = [this.allDatasets[0].children[0]];
				//this.verBotonesGraficas = 4;
				//desplegamos el arbol
				this.allDatasets[0].childrenVisible = true;
				break;
			case 10:
				//niveles por grupos
				this.datasetsImported = this.allDatasets[0].data;
				this.labelsImported = [this.allDatasets[0]];
				//this.verBotonesGraficas = 2;
				break;
			case 11:
				//niveles por asignaturas
				this.datasetsImported = this.allDatasets[0].children[0].data;
				this.labelsImported = [this.allDatasets[0].children[0]];
				//this.verBotonesGraficas = 4;
				//desplegamos el arbol
				this.allDatasets[0].childrenVisible = true;
				break;
			case 12:
				//padres por grupo
				this.datasetsImported = this.allDatasets[0].children[0].data;
				this.labelsImported = [this.allDatasets[0].children[0]];
				//this.verBotonesGraficas = 1;
				//desplegamos el arbol
				this.allDatasets[0].childrenVisible = true;
				break;
			case 13:
				//padres por asignatura
				this.datasetsImported = this.allDatasets[0].children[0].children[0].data;
				this.labelsImported = [this.allDatasets[0].children[0].children[0]];
				//this.verBotonesGraficas = 3;
				//desplegamos el arbol
				this.allDatasets[0].childrenVisible = true;
				this.allDatasets[0].children[0].childrenVisible = true;
				break;
			case 21:
				//estudiantes con bajo rendimiento por grupo
				this.datasetsImported = this.allDatasets[0].children[0].data;
				this.labelsImported = [this.allDatasets[0].children[0]];
				//this.verBotonesGraficas = 3;
				//desplegamos el arbol
				this.allDatasets[0].childrenVisible = true;
				break;
			case 22:
				//estudiantes con bajo rendimiento por asignatura
				this.datasetsImported = this.allDatasets[0].children[0].children[0].data;
				this.labelsImported = [this.allDatasets[0].children[0].children[0]];
				//this.verBotonesGraficas = 1;
				//desplegamos el arbol
				this.allDatasets[0].childrenVisible = true;
				this.allDatasets[0].children[0].childrenVisible = true;
				break;
			default:
				break;
		}
		this.viewData(this.datasetsImported, this.labelsImported[0])
	}

	viewChildren2(i: number){
		this.allDatasets[i].childrenVisible = !this.allDatasets[i].childrenVisible;
		for (let index = 0; index < this.allDatasets[i].children.length; index++) {
			this.allDatasets[i].children[index].childrenVisible = false;
		}
	}
	viewChildren3(i: number, j: number){
		this.allDatasets[i].children[j].childrenVisible = !this.allDatasets[i].children[j].childrenVisible;
	}
	closeTree(){
		if(this.allDatasets != null && this.allDatasets.length > 0){
			for (let i = 0; i < this.allDatasets.length; i++) {
				this.allDatasets[i].childrenVisible = false;
				if(this.allDatasets[i].children != null && this.allDatasets[i].children.length > 0){
					for (let j = 0; j < this.allDatasets[i].children.length; j++) {
						this.allDatasets[i].children[j].childrenVisible = false;
					}
				}
			}
		}
	}

	viewData(data: any, select: any){
		const aux = this.verBotonesGraficas;
		this.ocultarTodo();
		this.selectedLabel = select;
		this.labelsImported = [this.selectedLabel];
		this.datasetsImported = data;
		setTimeout(() => {
			if(aux == 0){
				this.verLista();
			} else if(aux == 1){
				this.verLista();
			} else if(aux == 2){
				this.verBarras();
			} else if(aux == 3){
				this.verSolar();
			} else if(aux == 4){
				this.verRadial();
			} else if(aux == 5){
				this.verDispersion();
			}
		}, 10);
	}

	verLista(){
		//mostramos la lista
		const div = document.getElementById("lista");
		if(div){
			div.style.display = "block";
		}
		//ocultamos la grafica de barras
		const div2 = document.getElementById("barras");
		if(div2){
			div2.style.display = "none";
		}
		//ocultamos la grafica solar
		const div3 = document.getElementById("solar");
		if(div3){
			div3.style.display = "none";
		}
		//ocultamos la grafica radial
		const div4 = document.getElementById("radial");
		if(div4){
			div4.style.display = "none";
		}
		//ocultamos la grafica de dispersion
		const div5 = document.getElementById("dispersion");
		if(div5){
			div5.style.display = "none";
		}
		this.verBotonesGraficas = 1;
		this.accordionOpen = false;
	}

	verBarras(){
		//ocultamos la lista
		const div = document.getElementById("lista");
		if(div){
			div.style.display = "none";
		}
		//mostramos la grafica de barras
		const div2 = document.getElementById("barras");
		if(div2){
			div2.style.display = "block";
		}
		//ocultamos la grafica solar
		const div3 = document.getElementById("solar");
		if(div3){
			div3.style.display = "none";
		}
		//ocultamos la grafica radial
		const div4 = document.getElementById("radial");
		if(div4){
			div4.style.display = "none";
		}
		//ocultamos la grafica de dispersion
		const div5 = document.getElementById("dispersion");
		if(div5){
			div5.style.display = "none";
		}
		this.verBotonesGraficas = 2;
		this.accordionOpen = false;
	}

	verSolar(){
		//ocultamos la lista
		const div = document.getElementById("lista");
		if(div){
			div.style.display = "none";
		}
		//ocultamos la grafica de barras
		const div2 = document.getElementById("barras");
		if(div2){
			div2.style.display = "none";
		}
		//mostramos la grafica solar
		const div3 = document.getElementById("solar");
		if(div3){
			div3.style.display = "block";
		}
		//ocultamos la grafica radial
		const div4 = document.getElementById("radial");
		if(div4){
			div4.style.display = "none";
		}
		//ocultamos la grafica de dispersion
		const div5 = document.getElementById("dispersion");
		if(div5){
			div5.style.display = "none";
		}
		this.verBotonesGraficas = 3;
		this.accordionOpen = false;
	}

	verRadial(){
		//ocultamos la lista
		const div = document.getElementById("lista");
		if(div){
			div.style.display = "none";
		}
		//ocultamos la grafica de barras
		const div2 = document.getElementById("barras");
		if(div2){
			div2.style.display = "none";
		}
		//ocultamos la grafica solar
		const div3 = document.getElementById("solar");
		if(div3){
			div3.style.display = "none";
		}
		//mostramos la grafica radial
		const div4 = document.getElementById("radial");
		if(div4){
			div4.style.display = "block";
		}
		//ocultamos la grafica de dispersion
		const div5 = document.getElementById("dispersion");
		if(div5){
			div5.style.display = "none";
		}
		this.verBotonesGraficas = 4;
		this.accordionOpen = false;
	}

	verDispersion(){
		//ocultamos la lista
		const div = document.getElementById("lista");
		if(div){
			div.style.display = "none";
		}
		//ocultamos la grafica de barras
		const div2 = document.getElementById("barras");
		if(div2){
			div2.style.display = "none";
		}
		//ocultamos la grafica solar
		const div3 = document.getElementById("solar");
		if(div3){
			div3.style.display = "none";
		}
		//ocultamos la grafica radial
		const div4 = document.getElementById("radial");
		if(div4){
			div4.style.display = "none";
		}
		//mostramos la grafica de dispersion
		const div5 = document.getElementById("dispersion");
		if(div5){
			div5.style.display = "block";
		}
		this.verBotonesGraficas = 5;
		this.accordionOpen = false;
	}

	ocultarTodo(){
		this.verBotonesGraficas = 0;
		//ocultamos la lista
		const div = document.getElementById("lista");
		if(div){
			div.style.display = "none";
		}
		//ocultamos la grafica de barras
		const div2 = document.getElementById("barras");
		if(div2){
			div2.style.display = "none";
		}
		//ocultamos la grafica solar
		const div3 = document.getElementById("solar");
		if(div3){
			div3.style.display = "none";
		}
		//ocultamos la grafica radial
		const div4 = document.getElementById("radial");
		if(div4){
			div4.style.display = "none";
		}
		//ocultamos la grafica de dispersion
		const div5 = document.getElementById("dispersion");
		if(div5){
			div5.style.display = "none";
		}
	}

	changeLabel(label: any, index: number){
		const aux = this.verBotonesGraficas;
		this.ocultarTodo();
		this.selectedLabel = label;
		this.labelsImported = [label.title];
		this.datasetsImported = this.allDatasets[index].data;
		setTimeout(() => {
			if(aux == 0){
				this.verLista();
			} else if(aux == 1){
				this.verLista();
			} else if(aux == 2){
				this.verBarras();
			} else if(aux == 3){
				this.verSolar();
			} else if(aux == 4){
				this.verRadial();
			} else if(aux == 5){
				this.verDispersion();
			}
		}, 10);
	}

	calcularPromedioCentral(){
		let aciertosPromedio = 0;
		let total = 0;
		if(this.datasetsImported && this.datasetsImported[0].length > 0){
			for (let index = 0; index < this.datasetsImported[0].length; index++) {
				const element = this.datasetsImported[0][index];
				total = total + this.datasetsImported[0][index].data[0];
			}
			aciertosPromedio = total / this.datasetsImported[0].length;
			this.textoCentro = aciertosPromedio.toFixed(1)+ '% ' + this.translateService.instant("GLOBALS.AVERAGE");
		}
	}

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
		this.accordionOpen = false;
		this.accordionOpen2 = false;
	}

	toggleAccordion() {
		this.isMenuOpen = false;
    this.accordionOpen = !this.accordionOpen;
		this.accordionOpen2 = false;
  }

	toggleAccordion2() {
		this.isMenuOpen = false;
		this.accordionOpen = false;
    this.accordionOpen2 = !this.accordionOpen2;
  }
}
