import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalStorage } from 'src/app/core/utils';
import { environment } from 'src/environments/environment';
import { DataHistoryClass, DataHistoryInterface } from '../interface/datos-resultado.interface';

@Injectable({
  providedIn: 'root'
})
export class ResultadoActividadesService {

  dataModel:  DataHistoryClass[] = []
  dataModelSuscrip =  new Subject();
  public  ELEMENT_DATA: DataHistoryInterface[] = [];


  constructor(private localStorageService: LocalStorage) { }



  getDataResultado() {
    return this.dataLocalStorageResulQuizes();
  }

  updateDataHistory(xs: DataHistoryClass) {
    this.dataModel.push(xs);
    this.localStorageService.setItem(LOCALSTORAGESTRINGS.HISTORYDATAACTIVITIES, JSON.stringify(this.dataModel));
  }


  dataLocalStorageResulQuizes() {
    const temp = this.localStorageService.getItem(LOCALSTORAGESTRINGS.HISTORYDATAACTIVITIES);

    if ((temp === "" || temp === null || temp === undefined) ) {

        this.crearDataDefault();
    }
    else {
      const fecha_ = this.localStorageService.getItem(LOCALSTORAGESTRINGS.HISTORYDATEACTIVITIES);
      let date = new Date(fecha_)
      let hoy = new Date()
      this.dataModel =   JSON.parse(temp);
      let tiempoPasado= +hoy - +date
      let segs = 1000;
      let mins = segs * 60;
      let hours = mins * 60;
      let days = hours * 24;
      let months = days * 30.416666666666668;
      let years = months * 12;
      //calculo
      let anos = Math.floor(tiempoPasado / years);

      tiempoPasado = tiempoPasado - (anos * years);
      let meses = Math.floor(tiempoPasado / months)

      tiempoPasado = tiempoPasado - (meses * months);
      let dias = Math.floor(tiempoPasado / days)

      tiempoPasado = tiempoPasado - (dias * days);
      let horas = Math.floor(tiempoPasado / hours)

      tiempoPasado = tiempoPasado - (horas * hours);
      let minutos = Math.floor(tiempoPasado / mins)

      tiempoPasado = tiempoPasado - (minutos * mins);
      let segundos = Math.floor(tiempoPasado / segs)

      if (horas >= 24 ) {
        this.crearDataDefault();
      }

      return this.dataModel;
    }

  }


  crearDataDefault() {
     this.dataModel = []
     let date = new Date();

      this.localStorageService.setItem(LOCALSTORAGESTRINGS.HISTORYDATEACTIVITIES, date)
      this.localStorageService.setItem(LOCALSTORAGESTRINGS.HISTORYDATAACTIVITIES, this.dataModel)
    return this.dataModel;
  }

}

