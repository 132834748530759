<div class="modal-header">
        <h5 class="modal-title">{{ 'NODOS.REGISTRARNODO' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal('DATOS')">
                <mat-icon>close</mat-icon>
        </button>
</div>
<div class="modal-body">
        <div class="container">
                <div class="form-group row">
                    <div class="col-md-10">
                        <div class="form-group row">
                            <label class="col-2">{{ 'NODOS.TITULO' | translate }}</label>
                            <div class="col-10">
                                <input type="text" class="form-control" required [(ngModel)]="tittle">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2">{{ 'NODOS.DESCRIPCION' | translate }}</label>
                            <div class="col-10">
                                <input type="text" class="form-control" required [(ngModel)]="description">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2">{{ 'NODOS.ETIQUETAS' | translate }}</label>
                            <div class="col-10">
                                <textarea type="text" class="form-control" required [(ngModel)]="labels"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-2">
                                <!--div class="row"-->
                                    <div class="menu-izquierda-contenedor">
                                        <div *ngFor="let format of formats" class="menu-izquierda">
                                             <div class="image-upload" >
                                                <label for="file-input">
                                                    <mat-icon class="menu-izquierda" title={{format.titulo}} (click)="elegirOpcion(format.titulo)">
                                                              {{format.formato}}</mat-icon>
                                                </label>
                                              <input id="file-input" type="file" />
                                             </div>
                                        </div>
                                    </div>
                                <!--/div-->
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div id="areaSenmotemo" class="centro">
                                     <div class="iconos-contenedor-area">
                                        <div *ngIf="selectedOptionSend" class="row">
                                            <div *ngFor="let icon of iconosSenmotemo" class="col-1">
                                                <img id="icono" class="icono" src={{icon.src}}>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="areaAssostatic" class="centro">
                                     <div class="iconos-contenedor-area">
                                        <div *ngIf="selectedOptionImage" class="row">
                                            <div *ngFor="let icon of iconosAssostatic" class="col-1">
                                                <img id="icono" class="icono" src={{icon.src}}>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="areaTemporary" class="centro">
                                      <div class="iconos-contenedor-area">
                                        <div *ngIf="selectedOptionVolume" class="row">
                                            <div *ngFor="let icon of iconosTemporary" class="col-1">
                                                <img id="icono" class="icono" src={{icon.src}}>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="areaAbstractPdf" class="centro">
                                       <div class="iconos-contenedor-area">
                                        <div *ngIf="selectedOptionPdf" class="row">
                                            <div *ngFor="let icon of iconosAbstractPdf" class="col-1">
                                                <img id="icono" class="icono" src={{icon.src}}>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="areaAbstractText" class="centro">
                                     <div class="iconos-contenedor-area">
                                        <div *ngIf="selectedOptionText" class="row">
                                            <div *ngFor="let icon of iconosAbstractText" class="col-1">
                                                <img id="icono" class="icono" src={{icon.src}}>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                    <div class="row">
                                        <div #senmotemo class="senmotemo">
                                            <div class="row-md-4">
                                                <div id="titulo-claro" class="titulo-claro">SENMOTEMO</div>
                                            </div>
                                            <div class="iconos-contenedor">
                                              <div class="row">
                                                <div *ngFor="let senmotemo of senmotemos" class="col-1">
                                                    <img class="icono" (click)="adicionarIconoSenmotemo(senmotemo)"
                                                        title={{senmotemo.nombre}} src={{senmotemo.src}}>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                        <div id="assostatic" class="assostatic">
                                            <div class="row-md-4">
                                                <div id="titulo-claro" class="titulo-claro">ASSOSTATIC</div>
                                            </div>
                                            <div class="iconos-contenedor">
                                            <div class="row">
                                                <div *ngFor="let assostatic of assostatics"  class="col-1">
                                                    <img id="icono" class="icono" (click)="adicionarIconoAssostatic(assostatic)"
                                                        title={{assostatic.nombre}} src={{assostatic.src}}>
                                                </div>
                                            </div>
                                           </div>
                                        </div>
                                        <div id="temporary" class="temporary">
                                            <div class="row-md-4">
                                                <div id="titulo-oscuro" class="titulo-oscuro">TEMPORARY</div>
                                            </div>
                                            <div class="iconos-contenedor">
                                            <div class="row">
                                                <div *ngFor="let temporary of temporaries"  class="col-1">
                                                    <img id="icono" class="icono" (click)="adicionarIconoTemporary(temporary)"
                                                        title={{temporary.nombre}} src={{temporary.src}}>
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div id="abstract" class="abstract">
                                            <div class="row-md-4">
                                                <div id="titulo-oscuro" class="titulo-oscuro">ABSTRACT</div>
                                            </div>
                                            <div class="iconos-contenedor">
                                            <div class="row">
                                                <div *ngFor="let abstract of abstracts" class="col-1">
                                                    <img id="icono" class="icono" (click)="adicionarIconoAbstract(abstract)"
                                                        title={{abstract.nombre}} src={{abstract.src}}>
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                         <div class="menu-derecha-contenedor">
                            <div id="menu-derecha" class="menu-derecha" (click)="reproducirFormato(1)">
                                <img id="menu-derecha" class="menu-derecha" src="../../../../../assets/images/nodes/button-play.png">
                            </div>
                            <div id="menu-derecha" class="menu-derecha">
                                <img id="menu-derecha" class="menu-derecha" src="../../../../../assets/images/flags/en.png">
                            </div>
                            <div id="menu-derecha" class="menu-derecha">
                                <img id="menu-derecha" class="menu-derecha" src="../../../../../assets/images/nodes/clock.png">
                            </div>
                            <div id="menu-derecha" class="menu-derecha">
                                <img id="menu-derecha" class="menu-derecha" src="../../../../../assets/images/nodes/statistics.png">
                            </div>
                            <div id="menu-derecha" class="menu-derecha">
                                <img id="menu-derecha" class="menu-derecha" src="../../../../../assets/images/nodes/author.png">
                            </div>
                            <div id="menu-derecha" class="menu-derecha">
                                <img id="menu-derecha" class="menu-derecha" src="../../../../../assets/images/nodes/deleteNode.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</div>
