<form class="container mt-4 mb-4" [formGroup]="answerForm">

    <h4><strong>{{'QUIZPLAY.ANSWER' | translate}}</strong></h4>

    <ng-container formArrayName="answers">
        <div class="row" *ngFor="let answer of answersArray.controls; let i = index;">
            <div class="col d-flex align-items-center" [formGroupName]="i">
                <ng-container [ngSwitch]="answersArrayType[i].value">
                    <div *ngSwitchCase="elementTypes.A_TEXTS" class="row mt-2">
                        <p [ngStyle]="{'background': '#'+questionsArrayBackground[i].value }" class="mb-3" [innerHTML]="answersArrayData[i].value">{{answersArrayData[i].value}}</p>
                    </div>

                    <div [ngStyle]="{'border': '1px solid #'+questionsArrayBackground[i].value }" class="w-100 h-100 flex justify-content-between align-items-end mt-2" *ngSwitchCase="elementTypes.A_PICTURES">
                        <img class="mx-auto img-fluid" [style.width]="answersArrayWidth[i].value ? answersArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="answersArrayHeight[i].value ? answersArrayHeight[i].value + 'px' : '100%'" [src]="answersArrayData[i].value| quizElements:'image' |safe" *ngIf="answersArrayData[i].value" />
                    </div>

                    <div [ngStyle]="{'border': '1px solid #'+questionsArrayBackground[i].value }" class="w-100 h-100 flex justify-content-between align-items-end mt-2" *ngSwitchCase="elementTypes.A_VIDEOS">
                        <video class="w-100 mw-100" [style.width]="answersArrayWidth[i].value ? answersArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="answersArrayHeight[i].value ? answersArrayHeight[i].value + 'px' : '100%'" controls [src]="answersArrayData[i].value | quizElements:'video' | safe" *ngIf="answersArrayData[i].value">
                        </video>
                    </div>

<!--                    <div [ngStyle]="{'border': '1px solid #'+questionsArrayBackground[i].value }" class="w-100 h-100 flex align-items-center justify-content-center align-items-end mt-2" *ngSwitchCase="elementTypes.A_AUDIOS">
                        <mat-icon class="pointer" onclick="document.getElementById('playerA').play()">volume_up</mat-icon>
                        <audio class="w-100 d-block" id="playerA" controls [src]="answersArrayData[i].value | quizElements:'audio' | safe" *ngIf="answersArrayData[i].value">
                        </audio>
                    </div>
-->
										<div [ngStyle]="{'border': '1px solid #'+questionsArrayBackground[i].value }" class="w-100 h-100 " *ngSwitchCase="elementTypes.A_AUDIOS">
											<button class="btn btn-primary btn-normal" (click)="playAudioWithSpeed('playerA', 1)" [disabled]="isAudioPlaying">
												<nb-icon icon="volume-up-outline" style="font-size: 50px;"></nb-icon>
											</button>
											<button class="btn btn-primary btn-normal" style="margin-left: 20px;" (click)="playAudioWithSpeed('playerA', 0.5)" [disabled]="isAudioPlaying">
												<mat-icon svgIcon="turtle"></mat-icon>
											</button>
											<audio id="playerA" [src]="answersArrayData[i].value | quizElements:'audio' | safe" #audioPlayer></audio>
										</div>


                    <div [ngStyle]="{'border': '1px solid #'+questionsArrayBackground[i].value }" class="w-100 h-100 flex justify-content-between align-items-end mt-2" *ngSwitchCase="elementTypes.A_PDFS">
                        <iframe #pdfElement class="w-100 h-100" [style.width]="answersArrayWidth[i].value ? answersArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="answersArrayHeight[i].value ? answersArrayHeight[i].value + 'px' : '100%'" [src]="answersArrayData[i].value | quizElements:'pdf' | safe" allowfullscreen *ngIf="answersArrayData[i].value" style="min-height: 920px;"></iframe>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>


    <!-- <div class="row  mt-5 flex justify-content-end">
        <div class="col-4 col-sm-12 col-md-4 col-lg-4  flex align-items-center justify-content-center">
            <button type="button" class="btnDelete  flex align-items-center justify-content-center" (click)="activeModal.close()">
                <mat-icon class="icon-color-blue">close</mat-icon><span class="span-icon">&nbsp;{{'QUIZPLAY.CLOSE' | translate}}</span>
            </button>
        </div>
    </div> -->
</form>
