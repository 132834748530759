import { HypermediaItem } from "./hypermedia-item.model";
import { HypermediaTypes } from "./hypermedia-types.enum";

export const hypermedias: HypermediaItem[] = [
    {
        name: 'HYPERMEDIA.TEXT',
        type: HypermediaTypes.TEXT,
        isFile: false,
        icon: 'subject'
    },
    {
        name: 'HYPERMEDIA.VIDEO',
        type: HypermediaTypes.VIDEO,
        isFile: true,
        accept: "video/mp4",
        icon: 'videocam'
    },
    {
        name: 'HYPERMEDIA.IMAGE',
        type: HypermediaTypes.IMAGE,
        isFile: true,
        accept: "image/*",
        icon: 'image'
    },
    {
        name: 'HYPERMEDIA.AUDIO',
        type: HypermediaTypes.AUDIO,
        isFile: true,
        accept: "audio/*",
        icon: 'volume_up'
    },
    {
        name: 'HYPERMEDIA.PDF',
        type: HypermediaTypes.PDF,
        isFile: true,
        accept: "application/pdf",
        icon: 'picture_as_pdf'
    },
];

export const hypermediasFamiliaIdeas: HypermediaItem[] = [
    {
        name: 'HYPERMEDIA.TEXT',
        type: HypermediaTypes.TEXT,
        isFile: false,
        icon: 'subject'
    },
    {
        name: 'HYPERMEDIA.VIDEO',
        type: HypermediaTypes.VIDEO,
        isFile: true,
        accept: "video/mp4",
        icon: 'videocam'
    },
    {
        name: 'HYPERMEDIA.IMAGE',
        type: HypermediaTypes.IMAGE,
        isFile: true,
        accept: "image/*",
        icon: 'image'
    },
    {
        name: 'HYPERMEDIA.AUDIO',
        type: HypermediaTypes.AUDIO,
        isFile: true,
        accept: "audio/*",
        icon: 'volume_up'
    },
    {
        name: 'HYPERMEDIA.PDF',
        type: HypermediaTypes.PDF,
        isFile: true,
        accept: "application/pdf",
        icon: 'picture_as_pdf'
    },
    {
        name: 'HYPERMEDIA.MICRO',
        type: HypermediaTypes.MICRO,
        isFile: true,
        accept: "audio/mp3; codecs=opus",
        icon: 'micro'
    },
    {
        name: 'HYPERMEDIA.VIDEOCAM',
        type: HypermediaTypes.VIDEOCAM,
        isFile: true,
        accept: "video/webm",
        icon: 'camera'
    }
];