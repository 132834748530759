import { User } from './user.models';
export class LoginResponse {
    data: LoginResponseModel
    error: ErrorModel
    status: number
}

export interface ErrorModel{
    code: number,
    msg: string
}

interface LoginResponseModel{
	ok:         boolean;
	tokenAcces: string;
	tokenType:  string;
	userDTO:    User;
}
