import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AlertService {

    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;

    public static readonly AlertServiceContextValues = {
        None: 'None',
        Login: 'Login',
        LoginModal: 'LoginModal',
        RegistrarEmpleado: 'RegistrarEmpleado',
        ModalCurso: 'ModalCurso',
        ModalCursoLenguaje: 'ModalCursoLenguaje',
        ModalEditarCurso: 'ModalEditarCurso',
        ModalEditarCursoMapa: 'ModalEditarCursoMapa',
        NotificationDetail: 'NotificationDetail',
        UserData: "UserData",
        ModalEstudianteCentro: "ModalEstudianteCentro",
        ModalCentro: "ModalCentro",
        ValidarClave: 'ValidarClave',
        ValidarPassword: 'ValidarPassword',
        ValidarPerfil: 'ValidarPerfil',
        simpleAlert: 'SimpleAlert'
    };

    public constructor( private router: Router ) {
        // clear alert message on route change
        router.events.subscribe( event => {
            if (event instanceof NavigationStart) {

                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next(false);
                }
            }
        });
    }

    public clear() {
        this.sendMesage(null);
    }

    public success(message: string,
                   context = AlertService.AlertServiceContextValues.None,
                   keepAfterNavigationChange = false) {
        this.sendMesage({ type: 'success', text: message, context }, keepAfterNavigationChange);
    }

    public error(message: string,
                 context = AlertService.AlertServiceContextValues.None,
                 keepAfterNavigationChange = false) {
        this.sendMesage({ type: 'error', text: message, context }, keepAfterNavigationChange);
    }

    public warning(message: string,
                   context = AlertService.AlertServiceContextValues.None,
                   keepAfterNavigationChange = false) {
        this.sendMesage({ type: 'warning', text: message, context }, keepAfterNavigationChange);
    }

    public info(message: string,
                context = AlertService.AlertServiceContextValues.None,
                keepAfterNavigationChange = false) {
        this.sendMesage({ type: 'info', text: message, context }, keepAfterNavigationChange);
    }

    public getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    private sendMesage(message: any, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;

        this.subject.next(message);
    }
}
