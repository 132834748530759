<div class="hamburger-menu" [ngStyle]="position">

    <div class="circle-button mb-3" (click)="isOpenMenu = !isOpenMenu">
        <mat-icon svgIcon="menu_close" class="icon-color-blue" *ngIf="!isOpenMenu"></mat-icon>
        <mat-icon class="icon-color-blue" *ngIf="isOpenMenu">close</mat-icon>
    </div>
    
    <div class="header-options" *ngIf="isOpenMenu">    
        <div class="d-flex flex-column">    
            <div *ngFor="let v of menuItems" class="d-flex align-items-center element mt-1 mb-1" (click)="clickElement(v.clickFunction)" ngbTooltip="{{ v.text | translate }}">                
                <button mat-icon-button>
                    <mat-icon class="icon-color-blue" data-toggle="tooltip" data-placement="top" svgIcon="{{v.icon}}" ></mat-icon>
                </button>                
                <span class="ml-2">{{ v.text | translate }}</span>
            </div>    
        </div>
    </div>
</div>

