import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { LoginService } from 'src/app/core/services/login';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
  selector: 'app-modal-alert-pensar-creando',
  templateUrl: './modal-alert-pensar-creando.component.html',
  styleUrls: ['./modal-alert-pensar-creando.component.scss']
})
export class ModalAlertPensarCreandoComponent implements OnInit {

	idGrupo: number = 0;
	group: any;

	constructor(
		public activeModal: NgbActiveModal,
		public translateService: TranslateService,
		public loginService: LoginService,
	){}

	ngOnInit() {}

	closeModal(sendData?: any) {
    this.activeModal.close(false);
  }

	crearTarea(){
		this.activeModal.close("crear");
	}

	verTareas(){
		this.activeModal.close("ver");
	}

}
