import { tap, map } from 'rxjs/operators';
import { ImagenPipe } from './../../../shared/pipes/imagen.pipe';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
const CREATEUPDATESTICKER = 'target/createUpdateImageTarget'
@Injectable({
  providedIn: 'root'
})
export class StickersService {

  constructor(private http:HttpClient, private imagePipe: ImagenPipe) { }

  /**
   *
   * @returns
   */
  loadStickers(idCourse:number, idGraph: number):Observable<any>{
    return this.http.get<any>(`target/courses/${idCourse}/graphs/${idGraph}/imagesTarget`).pipe(
        map(
            r => (r.data).map(
                e => {
                    return(
                        {
                            ...e,
                            url:this.imagePipe.transform(e.nameImg, 'stickers'),
                            id:String("s"+e.idImageTarget),
                            x:e.xposition,
                            y:e.yposition,
                            size:e.sizeImg,
                            idOriginal:e.idImageTarget
                        }
                    )
                }
            )
        )

    )
  }


  /**
   *
   * @param idCourse
   * @param idGraph
   * @param sticker
   * @returns
   */
  createSticker(idCourse:number,idGraph: number, data: any, file:File | null): Observable<any> {
    let form: FormData = new FormData()

    const vSticker = {
      idCourse: idCourse,
      idTarget: idGraph,
      nameImg : file ? file.name : data.nameImg,
      nodeType: data.nodeType,
      sizeImg: data.sizeImg,
      type: data.type,
      xposition: data.xposition,
      yposition: data.yposition,
      idImageTarget: data.idImageTarget || 0,
      high : data.high || null,
      width : data.width || null,
			link: data.link || '',
			jsonData: data.jsonData || ''
    }

    form.append('imageTarget', JSON.stringify(vSticker))
    if(file)
      form.append('files', file)

    return this.http.post<any>(CREATEUPDATESTICKER,form, {
      reportProgress: true,
      observe: 'events'
    })
  }


  /**
   *
   * @param id
   * @returns
   */
  deleteSticker(id:number): Observable<any> {
    return this.http.delete<any>(`target/deleteImageTarget/${id}`)
  }

  updateSticker(idImage:number, size:number, x:number, y:number){
    let url = `/editImageTarget/${idImage}`
    let body = {
      size: size,
      xposition: x,
      ypisition: y
    }
    return this.http.put<any>(url, body)

  }
}
