<nb-card class="no-edge-card mb-0 modal-height-100-62" id="quiz">
	<nb-card-body>
		<div class="container">
			<app-quiz-edit-header [btnPublicarTutorial]="btnPublicarTutorial" [quiz]="quiz" [idCurso]="courseId" [idMapa]="graphId"
				(quizTypeChanged)="onQuizTypeChanged($event)" (close)="closeModal($event)" (publishQuiz)="onPublishQuiz($event)">
			</app-quiz-edit-header>

			<app-quiz-edit-body [courseId]="courseId" [graphId]="graphId" [quiz]="quiz" [elements]="elements"
				(quizTypeChanged)="onQuizTypeChanged($event)" (fromIA)="changeFromIA($event)" (quizEdited)="onQuizEdited($event)"
				(answeredChange)="onAnswered()" (questionsChange)="onQuestionsChange($event)">
			</app-quiz-edit-body>
		</div>
	</nb-card-body>
</nb-card>
