<nb-card class="no-edge-card mb-0">
	<app-loading [isVisible]="cargando"></app-loading>
	<nb-card-header class="flex justify-content-between">
		<div class="col-11">
			<h5>{{"TOTALHISTORYUSER.INDIVIDUALDATA" | translate }}</h5>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="row">
			<div class="col-12">
				<div class="container">
					<form [formGroup]="formDate" (keydown.enter)="$event.preventDefault()">
						<div class="row mt-4">
							<div class="col-lg-6">
								<div class="row">
									<div class="col-12 col-md-6 d-flex flex-column">
										<label>{{"TOTALHISTORYUSER.DATEFROM" | translate }}</label>
										<input type="date" nbInput class="form-control" fullWidth status="basic" fieldSize="small" formControlName="desde" />
									</div>

									<div class="col-12 col-md-6 d-flex flex-column mt-3 mt-md-0">
										<label>{{"TOTALHISTORYUSER.DATETO" | translate }}</label>
										<input type="date" nbInput class="form-control" fullWidth status="basic" fieldSize="small" formControlName="hasta" />

										<div>
											<button nbButton class="btn-themecolor mt-3" size="small" (click)="filterDate()">
												{{"TOTALHISTORYUSER.SEARCH" | translate }}
											</button>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-6">
								<div class="row justify-content-lg-end mt-lg-0 mt-3">
									<div class="col-12 col-md-6 d-flex flex-column align-items-center" *ngIf="dataSource" (click)="exportToXLS(dataSource)">
										<label>{{"TOTALHISTORYUSER.DOWNLOADEXCEL" | translate }}</label>
										<span>
											<mat-icon svgIcon="icon-excel"></mat-icon>
										</span>
									</div>
								</div>
							</div>
						</div>

						<div class="row mt-4" *ngIf="dataSource">

							<div class="col-md-4">
								<div class="buttonIn d-flex flex-column" style="position: relative;">
									<label>{{ "TOTALHISTORYUSER.FILTER" | translate }}</label>
									<input formControlName="search" type="search" nbInput class="form-control" fullWidth status="basic" fieldSize="small"
											(keyup)="filterDataSource($event)">
									<nb-icon icon="search-outline" style="position: absolute; top: 55%; right: 10px; color: #007bff;"></nb-icon>
								</div>
							</div>

							<div class="col-md-4">
								<!-- <mat-form-field>
									<mat-label>{{ "TOTALHISTORYUSER.CENTERNAME" |
										translate }}</mat-label>
									<mat-select formControlName="center" (selectionChange)="selectCenter($event.value)">
										<mat-option *ngFor="let center of centers" [value]="center.idCenter">
											{{center.nameCenter}}
										</mat-option>
									</mat-select>

								</mat-form-field> -->

								<div class="d-flex flex-column">
									<label>{{ "TOTALHISTORYUSER.CENTERNAME" | translate }}</label>
									<nb-select formControlName="center" class="SelectForm" hero size="small">
										<nb-option class="SelectFormOpt" *ngFor="let center of centers" [value]="center.idCenter">
											{{center.nameCenter}}
										</nb-option>
									</nb-select>
								</div>
							</div>

							<div class="col-md-4">
								<div class="d-flex flex-column">
									<label>{{ "TOTALHISTORYUSER.PERFILES" | translate }}</label>
									<nb-select formControlName="profile" class="SelectForm" hero size="small" (selectionChange)="selectProfile($event.value)" multiple>
										<nb-option class="SelectFormOpt" *ngFor="let profile of profilesList"
											[value]="profile.idProfile">{{profile.nameProfile}}</nb-option>
									</nb-select>
								</div>
							</div>

						</div>
					</form>
				</div>
				<div class="container">
					<ng-container>
						<div class="row">
							<div class="col-12" *ngIf="dataSource" style="overflow-x: auto">
								<table mat-table [dataSource]="dataSource" class=" total-data-table" matSort
									(matSortChange)="announceSortChange($event)">

									<!--- Note that these columns can be defined in any order.
												The actual rendered columns are set as a property on the row definition" -->

									<!-- Position Column -->
									<ng-container matColumnDef="nombre">
										<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by nombre"> nombre
										</th>
										<td mat-cell *matCellDef="let element" (click)="openUserCard(element)">
											<p class="enlace-adornado">{{element.nombreApellido}}</p>
										</td>
										<td mat-footer-cell *matFooterCellDef> </td>
									</ng-container>

									<!-- Position Column -->
									<ng-container matColumnDef="identificacion">
										<th mat-header-cell *matHeaderCellDef mat-sort-header
											sortActionDescription="Sort by identificacion"> Identificacion </th>
										<td mat-cell *matCellDef="let element"> {{element.identificacion}} </td>
										<td mat-footer-cell *matFooterCellDef> </td>
									</ng-container>

									<!-- Position Column -->
									<ng-container matColumnDef="correo">
										<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by correo"> {{ "TOTALHISTORYUSER.CORREO" |
											translate }}
										</th>
										<td mat-cell *matCellDef="let element"> {{element.correo}} </td>
										<td mat-footer-cell *matFooterCellDef> </td>
									</ng-container>

									<!-- Position Column -->
									<ng-container matColumnDef="mobile">
										<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by mobile">
											{{ "TOTALHISTORYUSER.TELEFONO" |
											translate }} </th>
										<td mat-cell *matCellDef="let element"> {{element.mobile}} </td>
										<td mat-footer-cell *matFooterCellDef> </td>
									</ng-container>

									<!-- Position Column -->
									<ng-container matColumnDef="centro">
										<th mat-header-cell *matHeaderCellDef mat-sort-header
											sortActionDescription="Sort by centro"> {{ "TOTALHISTORYUSER.CENTERNAME" |
											translate }} </th>
										<td mat-cell *matCellDef="let element"> {{element.centro}} </td>
										<td mat-footer-cell *matFooterCellDef> </td>
									</ng-container>

									<!-- Position Column -->
									<ng-container matColumnDef="fecha">
										<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by fecha"> {{
											"TOTALHISTORYUSER.FECHA" | translate }} </th>
										<td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>Totales:</strong> </td>
									</ng-container>

									<!-- accesos_diarios Column -->
									<ng-container matColumnDef="accesos_diarios">
										<th mat-header-cell *matHeaderCellDef mat-sort-header
											sortActionDescription="Sort by accesos_diarios">
											{{ "TOTALHISTORYUSER.ACCESOS_DIARIOS" | translate }} </th>
										<td mat-cell *matCellDef="let element"> {{element.accesos_diarios}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('accesos_diarios')}}</strong>
										</td>
									</ng-container>

									<!-- proyectos Column -->
									<ng-container matColumnDef="proyectos">
										<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by proyectos"> {{
											"TOTALHISTORYUSER.PROYECTOS" | translate }} </th>
										<td mat-cell *matCellDef="let element"> {{element.proyectos}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('proyectos')}}</strong> </td>
									</ng-container>

									<!-- ideografos Column -->
									<ng-container matColumnDef="ideografos">
										<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ideografos"> {{
											"TOTALHISTORYUSER.IDEOGRAFOS" | translate }} </th>
										<td mat-cell *matCellDef="let element"> {{element.ideografos}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('ideografos')}}</strong> </td>
									</ng-container>
									<!-- idaes Column -->
									<ng-container matColumnDef="ideas">
										<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ideas"> {{
											"TOTALHISTORYUSER.IDEAS" | translate }} </th>
										<td mat-cell *matCellDef="let element"> {{element.ideas}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('ideas')}}</strong> </td>
									</ng-container>

									<!-- actividades Column -->
									<ng-container matColumnDef="actividades">
										<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by actividades">
											{{ "TOTALHISTORYUSER.ACTIVIDADES" | translate }} </th>
										<td mat-cell *matCellDef="let element"> {{element.actividades}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('actividades')}}</strong> </td>
									</ng-container>

									<!-- videos_subidos Column -->
									<ng-container matColumnDef="videos_subidos">
										<th mat-header-cell *matHeaderCellDef mat-sort-header
											sortActionDescription="Sort by videos_subidos"> {{ "TOTALHISTORYUSER.VIDEOS_SUBIDOS" | translate
											}} </th>
										<td mat-cell *matCellDef="let element"> {{element.videos_subidos}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('videos_subidos')}}</strong></td>
									</ng-container>

									<!-- Positimagenes_subidasion Column -->
									<ng-container matColumnDef="imagenes_subidas">
										<th mat-header-cell *matHeaderCellDef mat-sort-header
											sortActionDescription="Sort by imagenes_subidas"> {{ "TOTALHISTORYUSER.IMAGENES_SUBIDAS" |
											translate }} </th>
										<td mat-cell *matCellDef="let element"> {{element.imagenes_subidas}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('imagenes_subidas')}}</strong>
										</td>
									</ng-container>

									<!-- audios_subidos Column -->
									<ng-container matColumnDef="audios_subidos">
										<th mat-header-cell *matHeaderCellDef mat-sort-header
											sortActionDescription="Sort by audios_subidos"> {{ "TOTALHISTORYUSER.AUDIOS_SUBIDOS" | translate
											}} </th>
										<td mat-cell *matCellDef="let element"> {{element.audios_subidos}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('audios_subidos')}}</strong></td>
									</ng-container>

									<!-- documentos_subidos Column -->
									<ng-container matColumnDef="documentos_subidos">
										<th mat-header-cell *matHeaderCellDef mat-sort-header
											sortActionDescription="Sort by documentos_subidos"> {{ "TOTALHISTORYUSER.DOCUMENTOS_SUBIDOS" |
											translate }} </th>
										<td mat-cell *matCellDef="let element"> {{element.documentos_subidos}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('documentos_subidos')}}</strong>
										</td>
									</ng-container>

									<!-- textos_subidos Column -->
									<ng-container matColumnDef="textos_subidos">
										<th mat-header-cell *matHeaderCellDef mat-sort-header
											sortActionDescription="Sort by textos_subidos"> {{ "TOTALHISTORYUSER.TEXTOS_SUBIDOS" | translate
											}} </th>
										<td mat-cell *matCellDef="let element"> {{element.textos_subidos}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('textos_subidos')}}</strong></td>
									</ng-container>

									<!-- desafios Column -->
									<ng-container matColumnDef="desafios">
										<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by desafios"> {{
											"TOTALHISTORYUSER.DESAFIOS" | translate }} </th>
										<td mat-cell *matCellDef="let element"> {{element.desafios}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('desafios')}}</strong> </td>
									</ng-container>

									<!-- ideografos_abiertos Column -->
									<ng-container matColumnDef="ideografos_abiertos">
										<th mat-header-cell *matHeaderCellDef mat-sort-header
											sortActionDescription="Sort by ideografos_abiertos"> {{ "TOTALHISTORYUSER.IDEOGRAFOS_ABIERTOS" |
											translate }} </th>
										<td mat-cell *matCellDef="let element"> {{element.ideografos_abiertos}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('ideografos_abiertos')}}</strong>
										</td>
									</ng-container>

									<!-- ideas_abiertas Column -->
									<ng-container matColumnDef="ideas_abiertas">
										<th mat-header-cell *matHeaderCellDef mat-sort-header
											sortActionDescription="Sort by ideas_abiertas"> {{ "TOTALHISTORYUSER.IDEAS_ABIERTAS" | translate
											}}. </th>
										<td mat-cell *matCellDef="let element"> {{element.ideas_abiertas}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('ideas_abiertas')}}</strong> </td>
									</ng-container>

									<!-- actividades_resp Column -->
									<ng-container matColumnDef="actividades_resp">
										<th mat-header-cell *matHeaderCellDef mat-sort-header
											sortActionDescription="Sort by actividades_resp"> {{ "TOTALHISTORYUSER.ACTIVIDADES_RESP" |
											translate }} </th>
										<td mat-cell *matCellDef="let element"> {{element.actividades_resp}} </td>
										<td mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('actividades_resp')}}</strong>
										</td>
									</ng-container>

									<!-- actividades_resp Column -->
									<ng-container matColumnDef="pictureUser">
										<th class="d-none" mat-header-cell *matHeaderCellDef mat-sort-header
											sortActionDescription="Sort by pictureUser"> {{ "EDITARCURSOMAPA.IMAGEN" |
											translate }} </th>
										<td class="d-none" mat-cell *matCellDef="let element"> {{element.pictureUser}} </td>
										<td class="d-none" mat-footer-cell *matFooterCellDef> <strong>{{getTotalOfColumn('pictureUser')}}</strong>
										</td>
									</ng-container>

									<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
									<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
									<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
								</table>

								<mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons
									aria-label="Select page of periodic elements">
								</mat-paginator>

							</div>

							<div class="col-12 flex justify-content-center align-content-center mt-5" *ngIf="!dataSource">
								<div class="col-md-6 no-data-message">
									<p>{{"TOTALHISTORYUSER.NODATATOSHOW" | translate }}.</p>
									<p>{{"TOTALHISTORYUSER.PRESSTOSEARCH" | translate }}.</p>
								</div>

							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>
