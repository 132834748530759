import { Component, OnDestroy, Output, EventEmitter, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

// Services
import { TranslateService } from "@ngx-translate/core";
import { RegisterService } from "src/app/core/services/register/register.service";
import { ROUTES_NAME } from "src/app/core/utils/routes-name";
import { Subscription } from "rxjs";
import { formsValidations } from "src/app/core/utils/forms-validations";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize, take } from 'rxjs/operators';
import { DialogService } from "src/app/core/services/shared/dialog.service";
import { ChangePasswordService } from "src/app/core/services/change-password/change-password.service";
import { ChangePasswordModel } from "src/app/core/models/users/change-password.model";
import { LoginModel } from "src/app/core/models/users/login.model";
import { LoginService } from "src/app/core/services/login";
import { MatomoAnalyticsUtils } from "src/app/core/utils/matomo-analytics.utils";

declare function init_plugins();

enum STEPS {
	STEP1 = "1",
	STEP2 = "2",
}

@Component({
	selector: "app-recuperar-pass-nick",
	styleUrls: ["./recuperar-pass-nick.component.scss"],
	templateUrl: "./recuperar-pass-nick.component.html",
	encapsulation: ViewEncapsulation.None,
})
export class RecuperarPassNickComponent implements OnInit, OnDestroy {
	@Output() closeAux: EventEmitter<boolean> = new EventEmitter<boolean>();
	step2: UntypedFormGroup;
	step1: UntypedFormGroup;
	hidePassword: boolean = true;
	hidePasswordR: boolean = true;
	bgImage: string;
	lang: string;
	currentStep: string = STEPS.STEP1;
	validationMessages = {
		nick: [],
		keyword: [],
		repeatKeyWord: [],
		terms: [],
		paper: [],
		idSecurityQuestions: [],
		securityAnswer: [],
		idSecurityQuestions2: [],
		securityAnswer2: []
	};
	loading: boolean = false;
	loginRoute = ROUTES_NAME.LOGIN;
	validaterepetpassw2: boolean = false;

	securityQuestions: { id: number; question: string }[] = [
    //{ id: 0, question: ""},
    { id: 1, question: this.translateService.instant("SECURITYQUESTIONS.QUESTION1")},
    { id: 2, question: this.translateService.instant("SECURITYQUESTIONS.QUESTION2")},
    { id: 3, question: this.translateService.instant("SECURITYQUESTIONS.QUESTION3")},
    { id: 4, question: this.translateService.instant("SECURITYQUESTIONS.QUESTION4")},
    { id: 5, question: this.translateService.instant("SECURITYQUESTIONS.QUESTION5")},
  ];

	roleAsignado: boolean = true;
	nickOk: boolean = false;
	private token: string;
	private idUser: number;

	private nickMaxLength: number = formsValidations.NICK_MAX_LENGTH;
	private nickMinLength: number = formsValidations.NICK_MIN_LENGTH;
	private passMaxLenght: number = formsValidations.PASS_MAX_LENGTH;
	private emailMaxLength: number = formsValidations.EMAIL_MAX_LENGTH;
	private emailPattern: string = formsValidations.EMAIL_PATTERN;
	private subs = new Subscription();
	private courseInitial: string;

	constructor(
		private formBuild: UntypedFormBuilder,
		public translateService: TranslateService,
		private registerService: RegisterService,
		private toaster: ToasterService,
		public activeModal: NgbActiveModal,
		private router: Router,
		private dialogService: DialogService,
		private changePasswordService: ChangePasswordService,
		private loginService: LoginService,
		private ma: MatomoAnalyticsUtils,
	) { }

	ngOnInit(): void {
		this.crearFormularioInicial();

		this.traducirOpciones();
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	closeModal(sendData?: any) {
		//this.router.navigateByUrl(this.loginRoute);
		this.activeModal.close();
	}

	crearFormularioInicial() {
		this.step1 = this.formBuild.group({
			nick: ["",
				{
					validators: [
						Validators.required,
						Validators.maxLength(this.nickMaxLength),
						Validators.minLength(this.nickMinLength),
						Validators.pattern(formsValidations.NICKPATTERN)
					],
				},
			],
			idSecurityQuestions: ["", [Validators.required, Validators.pattern(formsValidations.QUESTION_PATTERN)]],
			securityAnswer: ["", [Validators.required, Validators.minLength(formsValidations.ANSWER_MIN_LENGTH)]],
			idSecurityQuestions2: ["", [Validators.required, Validators.pattern(formsValidations.QUESTION_PATTERN)]],
			securityAnswer2: ["", [Validators.required, Validators.minLength(formsValidations.ANSWER_MIN_LENGTH)]]
		});
	}

	get nickControl(): UntypedFormControl {
		if (this.step1) {
			return this.step1.get("nick") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get question1Control(): UntypedFormControl {
		if (this.step1) {
			return this.step1.get("idSecurityQuestions") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get answer1Control(): UntypedFormControl {
		if (this.step1) {
			return this.step1.get("securityAnswer") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get question2Control(): UntypedFormControl {
		if (this.step1) {
			return this.step1.get("idSecurityQuestions2") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get answer2Control(): UntypedFormControl {
		if (this.step1) {
			return this.step1.get("securityAnswer2") as UntypedFormControl;
		} else {
			return null;
		}
	}

	validateUser(){
		const fv1 = this.step1.value;
		this.subs.add(this.registerService.nickSecurityQuestionsAnswerValidation(fv1.nick, fv1.idSecurityQuestions, fv1.securityAnswer, fv1.idSecurityQuestions2, fv1.securityAnswer2)
    	.subscribe((res) => {
        if (res.error.code === 1) {
            this.toaster.error(this.translateService.instant(res.error.msg));
        } else {
            this.nextStep();
        }
    	})
		);
	}

	nextStep() {
    if(this.step1.invalid){
        return;
    } else {
        this.currentStep = STEPS.STEP2;
        this.crearFormulario();
    }
	}

	crearFormulario() {
		this.step2 = this.formBuild.group({
			keyWord: ["",	{validators: [Validators.required, Validators.maxLength(this.passMaxLenght), Validators.pattern(formsValidations.PASSWORDPATTERN)],},],
			repeatKeyWord: ["",	{validators: [Validators.required, Validators.maxLength(this.passMaxLenght), Validators.pattern(formsValidations.PASSWORDPATTERN)],},],
	});
	}

	get keywordControl(): UntypedFormControl {
		if (this.step2) {
			return this.step2.get("keyWord") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get repeatKeywordControl(): UntypedFormControl {
		if (this.step2) {
			return this.step2.get("repeatKeyWord") as UntypedFormControl;
		} else {
			return null;
		}
	}

	validateRepeatPassword() {
    if (this.step2) {
			this.validaterepetpassw2 = false;
      let pass1: string = this.step2.value.keyWord;
			let pass2: string = this.step2.value.repeatKeyWord;

			if (pass1.toLowerCase() == pass2.toLowerCase()) {
				this.validaterepetpassw2 = true;
			}
    }
	}

	traducirOpciones() {
		this.translateService
			.get("VALIDACIONES.LACONTRASENAESOBLIGATORIA")
			.subscribe((res: string) => {
				this.validationMessages.keyword.push({
					type: "required",
					message: res,
				});
			});

		this.translateService
			.get("VALIDACIONES.LACONTRASENAESOBLIGATORIA")
			.subscribe((res: string) => {
				this.validationMessages.repeatKeyWord.push({ type: "required", message: res });
			});

			this.translateService
			.get("VALIDACIONES.PASSWORDPATTERN")
			.subscribe((res: string) => {
				this.validationMessages.keyword.push({ type: "pattern", message: res });
			});

			this.translateService
			.get("VALIDACIONES.PASSWORDPATTERN")
			.subscribe((res: string) => {
				this.validationMessages.repeatKeyWord.push({ type: "pattern", message: res });
			});
	}

	cambiarContrasena() {
		if(this.step2.invalid){
			this.toaster.error(this.translateService.instant("RECUPERARCONTRASENA.RECUPERARCONTRASENA"));
		} else{
			//this.toaster.success(this.translateService.instant("RECUPERARCONTRASENA.RECUPERARCONTRASENA"));
			const fv:any = this.step2.value;
			this.registerService.getUserByNick(this.step1.value.nick).subscribe((res) => {
				if (res.error.code === 0) {
					this.idUser = res.data.idUser;
					this.token = res.data.token;
					const changePassword: ChangePasswordModel = new ChangePasswordModel(this.idUser, this.token, fv.keyWord);
					this.changePasswordService.changePassword(changePassword).pipe().subscribe(
							resp => {
									if (resp.error && resp.error.code !== 0) {
											this.dialogService.openConfirmDialog(false, this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), this.translateService.instant('CAMBIARPASS.NOK'));
									} else {
											const dialog = this.dialogService.openConfirmDialog(true, this.translateService.instant('CAMBIARPASS.CAMBIARPASS'), this.translateService.instant('CAMBIARPASS.OK'));
											this.activeModal.close();
											//Si todo sale correctamente, logueamos al usuario en la aplicación
											const login: LoginModel = new LoginModel(
												(this.step1.value.nick as string).trim(),
												(fv.keyWord as string).trim(),
												"+57"
											);
											this.loginService.login(login).subscribe(
												(res) => {
													if (res.data.ok) {
														// Guardo el usuario en el localstorage
														this.loginService.setUserLocalStorage(res.data.userDTO);
														this.loginService.setToken(
															res.data.tokenType,
															res.data.tokenAcces
														);
														this.ma.setUserId(res.data.userDTO.idUser);
														// Y le redirecciono al grafo de entrada
														this.router.navigateByUrl(this.courseInitial); // Go to initial course

														this.ma.event("submit", "login", "Enviar"); //Matomo event
													} else {
														this.toaster.error(
															this.translateService.instant("LOGIN.ERRORALVALIDARSE")
														);
													}
												},
												(err) => {
													this.toaster.error(
														this.translateService.instant("LOGIN.ERRORALVALIDARSE")
													);
												}
											);
									}
							},
							err => {
									this.toaster.error(this.translateService.instant('CAMBIARPASS.NOK'));
							}
					);
				}
			});
		}
	}

}
