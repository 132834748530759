<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="flex flex-column border-bottom-0">
		<!-- Titulo de la pantalla -->
		<div class="row">
			<div class="col-10 modal-title">
				<h4>{{ survey?.title }}</h4>
			</div>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}"
			nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-body class="padding-mobile">
		<div class="container">
			<nb-tabset fullWidth>
				<!-- Preguntas -->
				<nb-tab class="padding-mobile background_themecolor" tabTitle="{{ 'PATTERNS.QUESTIONS' | translate }}" tabIcon="layers-outline" responsive>
					<div *ngIf="!viewList" class="d-flex flex-column gap-4 mb-5 mb-md-0">
						<app-loading [isVisible]="cargando"></app-loading>
						<!-- Titulo y descripcion de la encuesta -->
						<nb-card class="container-question mb-0">
							<nb-card-body>
								<div class="d-flex flex-column gap-3">
									<!-- Titulo -->
									<div class="form-group">
											<label for="exampleFormControlInput1">{{ 'NODOS.TITULO' | translate }}</label>
											<input nbInput fullWidth [(ngModel)]="surveyTitle" type="text" id="exampleFormControlInput1"
												placeholder="Titulo" maxlength="100" class="form-control">
									</div>
									<!-- Descripcion -->
									<div class="form-group">
											<label for="exampleFormControlTextarea1">{{ 'EDITARCURSO.DESCRIPCION' | translate }}</label>
											<textarea nbInput fullWidth [(ngModel)]="surveyDescription" id="exampleFormControlTextarea1" rows="3"
												maxlength="500" class="form-control">
											</textarea>
									</div>
								</div>
							</nb-card-body>
						</nb-card>
						<!-- TARJETAS PREGUNTAS -->
						<div *ngIf="questionsList.length !== 0" class="d-flex flex-column gap-4">
							<div *ngFor="let question of questionsList; let i = index" class="d-flex">
								<app-surveys-question-edit
									[question]="question"
									[editMode]="activeIndex === i"
									[totalQuestions]="questionsList.length"
									(clicked)="onQuestionClicked(i)"
									(deleteQuestion)="onDeleteQuestion($event)"
									(editQuestion)="onEditQuestion($event)"
									(moveQuestion)="onMoveQuestion($event)"
									class="w-100">
								</app-surveys-question-edit>
							</div>
						</div>
					</div>
					<!-- Panel flotante -->
					<div class="floating-panel gap-4 justify-content-center align-items-center">
						<!-- Agregar pregunta -->
						<nb-icon class="pointer" icon="plus-circle-outline"
							(click)="newQuestion()" nbTooltip="{{ 'HEADER.OBJETO_NUEVAPREGUNTA' | translate }}">
						</nb-icon>
						<!-- Eliminar la encuesta -->
						<nb-icon class="pointer" icon="trash-2-outline" nbTooltip="Eliminar encuesta"
							(click)="deleteSurvey()">
						</nb-icon>
					</div>
				</nb-tab>
				<!-- Configuraciones -->
				<nb-tab class="padding-mobile background_themecolor" tabTitle="configuración" tabIcon="settings-outline" responsive>
					<div *ngIf="survey">
						<app-loading [isVisible]="cargando"></app-loading>
						<form [formGroup]="formulario" #thisForm="ngForm" class="d-flex flex-column gap-5 mb-5 mb-md-0">
							<!-- Banner para la encuesta -->
							<div class="d-flex">
								<div class="imagecontainer" nbTooltip="Carga un banner para la encuesta">
									<app-imgupload [isShowDelete]="true" formControlName="image" *ngIf="networkSpeed >= 1"></app-imgupload>
									<div class="text-center avatar-detail-course mb-2 d-flex align-content-center"
									[ngStyle]="{'background-image': 'url(../../assets/images/no-image.png)'}" *ngIf="networkSpeed < 1"></div>
								</div>
							</div>
							<!-- Opcion para ser visible o privada la encuesta -->
							<div class="d-flex flex-wrap justify-content-center gap-5">
								<nb-toggle labelPosition="start" class="item-profile" *ngIf="id!='nuevo' && loginService.esAutor()"
									formControlName="visible">{{ formulario.get('visible').value
									? ('EDITARCURSO.VISIBLE' | translate) : ('EDITARCURSO.NOVISIBLE' | translate) }}
								</nb-toggle>
								<nb-toggle labelPosition="start" class="item-profile" *ngIf="id!='nuevo' && loginService.esAutor()"
									formControlName="published">{{
									formulario.get('published').value ? ('EDITARCURSO.PUBLIC' | translate) : ('EDITARCURSO.PRIVATE' | translate) }}
								</nb-toggle>
							</div>
							<!-- Idioma y rol de la encuesta -->
							<div class="d-flex flex-wrap justify-content-center gap-5">
								<div class="d-flex flex-column">
									<label>Idioma de la encuesta</label>
									<nb-select name="lang" class="SelectForm" placeholder="{{'FILTER.LANGUAGE' | translate}}" formControlName="lang">
										<nb-option class="SelectFormOpt" *ngFor="let lang of langList" [value]="lang.idLang">
											{{ lang.nombre }}
										</nb-option>
									</nb-select>
								</div>

								<div class="d-flex flex-column">
									<label>Rol para la encuesta</label>
									<nb-select name="destinationProfile" class="SelectForm" formControlName="destinationProfile">
										<nb-option class="SelectFormOpt" *ngFor="let profile of profileList" [value]="profile.id">
											{{ profile.title }}
										</nb-option>
									</nb-select>
								</div>
							</div>
							<!-- Autor de la encuesta -->
							<div class="d-flex flex-column gap-3">
								<h6 class="text-center mt-2">{{'MODALINFORMATIONCOURSE.AUTHOR' | translate}}:</h6>
								<div class="w-100 d-flex justify-content-center">
									<div class="text-center avatar-detail mb-2 d-flex align-content-center"
										[ngStyle]="{'background-image': getUserAvatar()}"></div>
								</div>
								<h6 class="text-center">{{survey.user.firstName}} {{survey.user.surname}}</h6>
							</div>
						</form>
						<!-- Panel flotante -->
						<div class="floating-panel gap-4 justify-content-center align-items-center">
							<!-- Eliminar la encuesta -->
							<nb-icon class="pointer" icon="trash-2-outline" nbTooltip="Eliminar encuesta"
								(click)="deleteSurvey()">
							</nb-icon>
						</div>
					</div>
				</nb-tab>
			</nb-tabset>
		</div>
	</nb-card-body>
</nb-card>
