export class UserStudentTeacherModel {
    idUserStudentTeacher: number;
    idStudent: number;
    idTeacher: number;
    creationDate: Date;
    creationDateString: string;
    validationDate: Date;
    validationDateString: string;
    unvalidateDate: Date;
    unvalidateDateString: string;
}
