<nb-card class="mb-0 no-edge-card">
	<nb-card-header class="flex border-bottom-0">
		<div class="col-10 modal-title">
			<h2>{{ 'NODOS.CONECTARNODO' | translate }}</h2>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<form [formGroup]="form" (submit)="conectar()">
			<nb-card-body>

				<div class="form-group p-2">
					<p class="col-12 paragraph mb-4">{{ 'NODOS.TITLE' | translate }}</p>
					<div class="row">
						<div class="col-5 text-right" style="padding-right: 20px;">
							<div class="d-flex flex-column align-items-end">
								<img [ngbTooltip]="nodeFrom.label" placement="bottom" title="{{nodeFrom.label}}"
									src="{{nodeFrom.url}}" alt="Avatar" class="avatar">
								<span class="mt-2">{{nodeFrom.label}}</span>
							</div>
						</div>
						<div class="col-2 d-flex justify-content-center align-items-center">
							<button mat-raised-button (click)="changeConnectOrderItems()" mat-icon-button data-toggle="tooltip"
								data-placement="top" title="" type="button">
								<mat-icon class="mat" color="primary">sync_alt</mat-icon>
							</button>
						</div>
						<div class="col-5 text-left" style="padding-left: 20px;">
							<div class="d-flex flex-column align-items-start">
								<img ngbTooltip="{{nodeTo.label}}" placement="bottom" title="{{nodeTo.label}}" src="{{nodeTo.url}}"
									alt="Avatar" class="avatar">
								<span class="mt-2">{{nodeTo.label}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="form-group row align-items-center text-left text-lg-right">
						<label class="col-lg-2 m-0 label">{{'NODOS.TIPODELINEA' | translate }}</label>
						<div class="col-lg-10">
							<select id="linea" class="custom-select w-100" required formControlName="line">
								<option value="''">{{'NODOS.LINEAPLACEHOLDER' | translate }}</option>
								<option *ngFor="let line of lines" [value]="line.type">{{line.name | translate}}</option>
							</select>

							<!-- <nb-select fullWidth id="linea" name="linea" placeholder="{{'NODOS.LINEAPLACEHOLDER' | translate }}"
										required formControlName="line">
												<nb-option *ngFor="let line of lines" value="{{line.type}}">{{line.name | translate}}</nb-option>
										</nb-select> -->

							<div *ngFor="let validation of validationMessages.line" class="text-left">
								<div class="text-danger mt-1"
									*ngIf="lineControl?.hasError(validation.type) && (lineControl?.dirty || lineControl?.touched)">
									{{validation.message}}
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="lineControl.value">
						<div class="form-group row align-items-center text-left text-lg-right">
							<label class="col-lg-2 m-0 label">{{'NODOS.TEXTO'|translate }}</label>
							<div class="col-lg-10">
								<input nbInput fullWidth status="basic" type="text" class="form-control" required
									formControlName="label">

								<div *ngFor="let validation of validationMessages.label" class="text-left">
									<div class="text-danger mt-1"
										*ngIf="labelControl?.hasError(validation.type) && (labelControl?.dirty || labelControl?.touched)">
										{{validation.message}}
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="lineControl.value !== branchType" class="form-group row align-items-center text-left text-lg-right">
							<label class="col-lg-2 m-0 label">{{'NODOS.TAMAÑO'|translate }}</label>
							<div class="col-lg-2">
								<input nbInput fullWidth status="basic" type="number" class="form-control" formControlName="size">

								<div *ngFor="let validation of validationMessages.size" class="text-left">
									<div class="text-danger mt-1"
										*ngIf="sizeControl?.hasError(validation.type) && (sizeControl?.dirty || sizeControl?.touched)">
										{{validation.message}}
									</div>
								</div>
							</div>
						</div>
						<div class="form-group row align-items-center text-left text-lg-right">
							<label class="col-lg-2 m-0 label">{{'NODOS.COLOR'|translate }}</label>
							<div style="position: relative; display: inline-block;" class="col-lg-2">
								<input class="w-100 pointer" nbInput fullWidth status="basic" [style.background]="color" [cpOKButton]="false" [cpSaveClickOutside]="true"
									[cpPosition]="'top'" [(colorPicker)]="color" (colorPickerChange)="updateTextColor()" readonly="readonly" />
								<div class="text-center" [style.color]="textColor"
									style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; pointer-events: none; display: flex; justify-content: center; align-items: center;">
									{{ color }}
								</div>
							</div>

						</div>
					</div>
				</div>



			</nb-card-body>
			<nb-card-footer>
				<div class="form-group row mb-1">
					<div class="col">
						<div class="text-right px-3 py-0">
							<button nbButton class="btn-themecolor mr-3" type="submit"
								[disabled]="isLoading">{{'NODOS.CONECTAR' | translate}}</button>
							<button nbButton status="basic" (click)="cancelar()">{{'NODOS.CANCELAR' | translate}}</button>
						</div>
					</div>
				</div>
			</nb-card-footer>
		</form>
	</nb-card-body>
</nb-card>
