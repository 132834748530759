<div *ngIf="!isMobile; else noBackground" class="auth-background" [style.backgroundImage]="bgAuth">
  <div class="auth-container">
    <div class="auth-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #noBackground>
  <div class="auth-container">
    <div class="auth-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>
