import { Component, Input, HostListener, ElementRef} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-imgupload',
  templateUrl: './imgupload.component.html',
  styleUrls: ['./imgupload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ImguploadComponent,
      multi: true
    }
  ]
})
export class ImguploadComponent implements ControlValueAccessor {
  imgUrl:string;
  file:File;
  onChange: Function
  isEdit: boolean

  @Input() image: string
  @Input() isShowDelete: boolean
	@Input() css:string;

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    const reader:FileReader = new FileReader()
    const file = event && event.item(0);
    this.file = file;
    this.onChange(file)

    reader.onloadend = (event: any) => {
      this.imgUrl = event.target.result;
    }
    reader.readAsDataURL(this.file);
  }

  constructor(private host: ElementRef<HTMLInputElement>, private sanitizer: DomSanitizer) {
    this.isEdit = true
  }

  writeValue( value: string ) {
    this.imgUrl = value
  }

  registerOnChange( fn: Function ) {
    this.onChange = fn;
  }

  registerOnTouched( fn: Function ) {}

  deleteImage(){
    this.imgUrl = ''
    this.onChange('')
  }

  setDisabledState( isDisabled : boolean ) : void {
    if(isDisabled)
      this.isEdit = false
  }

	sanitizeCss(){
		return this.sanitizer.bypassSecurityTrustStyle(this.css)
	}

}
