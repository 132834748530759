import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SigmaNode } from 'src/app/core/models/graph/sigmaNode.model';
import { NodeOnTopResponse } from 'src/app/core/models/quizzes';
import { NodeService } from 'src/app/core/services/node/node.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { StickersService } from 'src/app/core/services/stickers/stickers.service';
import { SIGMA_CONSTANTS } from 'src/app/core/utils/sigma-constants';
import { SigmaUtils } from 'src/app/core/utils/sigma-utils';

@Injectable({
	providedIn: "root",
})
export class SigmaCanvasService {
	public activeOrdinals = [1];
	private _zoomX: number = 0;
	private _zoomY: number = 0;
	private _zoomRatio: number = 0;

	private _courseId: number;
	private _graphId: number;
	private _sigmaUtils: SigmaUtils;
	private _course: any;
	public _previewNode: Subject<string> = new BehaviorSubject<string>("none");
	public _nextEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		true
	);
	public _backEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);

	public _openDesignIdeasWindow: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	public _showDiscoverButton: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(true);

	public discoverModeOn: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	public triggerInitSigma: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	displayedNodes: any[] = [];
	public countNodes: any[] = [];

	public getOpenDesignIdeasWindow() {
		return this._openDesignIdeasWindow.asObservable();
	}

	public setOpenDesignIdeasWindow(value: boolean): void {
		this._openDesignIdeasWindow.next(value);
	}

	public getPreviewNode() {
		return this._previewNode.asObservable();
	}

	public setPreviewNode(value: string): void {
		this._previewNode.next(value);
	}

	public getNextEnabled() {
		return this._nextEnabled.asObservable();
	}

	public setNextEnabled(value: boolean): void {
		this._nextEnabled.next(value);
	}

	public getBackEnabled() {
		return this._backEnabled.asObservable();
	}

	public setBackEnabled(value: boolean): void {
		this._backEnabled.next(value);
	}

	public getDiscoverModeOn() {
		return this.discoverModeOn.asObservable();
	}

	public setDiscoverModeOn(value: boolean): void {
		this.discoverModeOn.next(value);
	}

	public getTriggerInitSigma() {
		return this.triggerInitSigma.asObservable();
	}

	public setTriggerInitSigma(value: boolean): void {
		this.triggerInitSigma.next(value);
	}

	public getShowDiscoverButton() {
		return this._showDiscoverButton.asObservable();
	}

	public setShowDiscoverButton(value: boolean): void {
		this._showDiscoverButton.next(value);
	}

	public _isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);

	public getIsLoading() {
		return this._isLoading.asObservable();
	}

	public setIsLoading(value: boolean): void {
		this._isLoading.next(value);
	}

	constructor(
		private quizService: QuizzesService,
		private nodeService: NodeService,
		private stickerService: StickersService
	) {}

	get zoomX(): number {
		return this._zoomX;
	}

	set zoomX(value: number) {
		this._zoomX = value;
	}

	get zoomY(): number {
		return this._zoomY;
	}

	set zoomY(value: number) {
		this._zoomY = value;
	}

	get zoomRatio(): number {
		return this._zoomRatio;
	}

	set zoomRatio(value: number) {
		this._zoomRatio = value;
	}

	get courseId(): number {
		return this._courseId;
	}

	set courseId(value: number) {
		this._courseId = value;
	}

	get graphId(): number {
		return this._graphId;
	}

	set graphId(value: number) {
		this._graphId = value;
	}

	get sigmaUtils(): SigmaUtils {
		return this._sigmaUtils;
	}

	set sigmaUtils(value: SigmaUtils) {
		this._sigmaUtils = value;
	}

	public get course(): any {
		return this._course;
	}
	public set course(value: any) {
		this._course = value;
	}

	/**
	 * Conecta dos quizzes o un quiz y un nodo.
	 * @param sigmaNode1 un nodo del grafo. Puede ser un nodo o un quiz.
	 * @param sigmaNode2 un nodo del grafo. Puede ser un nodo o un quiz.
	 * @returns un observable de la llamada a la creación de la conexión. Si es correcta la pinta en el grafo. Si no es correcta devuelve error.
	 */
	public connectQuizzes(
		sigmaNode1: SigmaNode,
		sigmaNode2: SigmaNode
	): Observable<any> {
		let checkVal: boolean = false;

		let node1: string =
			sigmaNode1.nodeType === SIGMA_CONSTANTS.NODE_TYPE
				? sigmaNode2.id
				: sigmaNode1.id;
		let node2: string = node1 === sigmaNode1.id ? sigmaNode2.id : sigmaNode1.id;
		let edgeSettings = { size: 0.75, color: "#000000" };

		//Tenemos que revisar si el quiz que vamos a mover sobre otro, ya tiene una conexión con un nodo, para que sea destino y no origen
		if (
			sigmaNode1.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE &&
			sigmaNode2.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE
		) {
			if (sigmaNode1.edges) {
				sigmaNode1.edges.forEach((e) => {
					if (e.source.indexOf("q") >= 0 && e.target.indexOf("n") >= 0)
						checkVal = true;
				});
			}

			if (checkVal) {
				node2 = sigmaNode1.id;
				node1 = sigmaNode2.id;
			}
		}

		return this.quizService
			.createEdge(node1, node2, this.courseId, this.graphId)
			.pipe(
				tap((res) => {
					//Tengo que actualizar los edges de los quizzes/nodes que hayamos unido en el grafo
					this.sigmaUtils.connectNodes(node1, node2, "line", edgeSettings, res);
					this.sigmaUtils.refresh();
				})
			);
	}

	/**
	 * Comprueba si existe la conexión entre los dos nodos/quizes. Si existe comprueba si existe algún quiz en la conexión. Finalmente borra la conexión.
	 * @param sigmaNode1 un nodo del grafo. Puede ser un nodo o un quiz.
	 * @param sigmaNode2 un nodo del grafo. Puede ser un nodo o un quiz.
	 * @returns un observable de la llamada a la eliminación de la conexión. Si es correcto lo borra del grafo. Si no es correcto devuelve error.
	 */
	public deleteEdge(sigmaNode1: SigmaNode, sigmaNode2: SigmaNode) {
		const from: number = this.sigmaUtils.checkConnection(
			sigmaNode1,
			sigmaNode2
		);
		if (from) {
			const node1: SigmaNode =
				sigmaNode1.idOriginal === from ? sigmaNode1 : sigmaNode2;
			const node2: SigmaNode =
				sigmaNode2.idOriginal !== from ? sigmaNode2 : sigmaNode1;
			if (
				sigmaNode1.nodeType === SIGMA_CONSTANTS.NODE_TYPE &&
				sigmaNode2.nodeType === SIGMA_CONSTANTS.NODE_TYPE
			) {
				return this.deleteNodeEdge(node1, node2);
			} else if (
				sigmaNode1.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE ||
				sigmaNode2.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE
			) {
				return this.deleteQuizEdge(node1, node2);
			}
		} else {
			return throwError("");
		}
	}

	private deleteNodeEdge(node1: SigmaNode, node2: SigmaNode) {
		return this.nodeService
			.deleteEdge(
				node1.idOriginal,
				node2.idOriginal,
				this.courseId,
				this.graphId
			)
			.pipe(
				tap((res) => {
					this.sigmaUtils.deleteEdge(node1.id, node2.id);
					this.sigmaUtils.refresh();
				})
			);
	}

	private deleteQuizEdge(node1: SigmaNode, node2: SigmaNode) {
		return this.quizService
			.deleteEdge(node1.id, node2.id, this.courseId, this.graphId)
			.pipe(
				tap((res) => {
					this.sigmaUtils.deleteEdge(node1.id, node2.id, res);
					this.sigmaUtils.refresh();
				})
			);
	}

	/**
	 * Calcula si un nodo se encuentra actualmente encima de otro.
	 * @param currentNode
	 * @param nodeBehind
	 * @returns true si currentNode está encima de nodeBehind. false en caso contrario.
	 */
	public isNodeOnTopOfAnother(
		currentNode: SigmaNode,
		nodesBehind: SigmaNode[]
	): NodeOnTopResponse {
		let dx: number = 0,
			dy: number = 0;
		let value: boolean = false;
		let node: SigmaNode = null;

		if (!nodesBehind.length) return { value: false };

		nodesBehind.forEach((n) => {
			dx = Math.abs(n.x - currentNode.x);
			dy = Math.abs(n.y - currentNode.y);
			if (dx < n.size * 2 && dy < n.size * 1.1 && !n.delete && !n.hidden) {
				value = true;
				node = n;
			}
		});

		if (value) return { value: true, node: node };
		else return { value: false };
	}

	public deleteSigmaNode(sigmaNode: SigmaNode): Observable<any> {
		if (sigmaNode.nodeType === SIGMA_CONSTANTS.NODE_TYPE) {
			return this.deleteNode(sigmaNode.idOriginal);
		} else if (sigmaNode.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE) {
			return this.deleteQuiz(sigmaNode.idOriginal);
		} else if (
			sigmaNode.nodeType === SIGMA_CONSTANTS.STICKER_TYPE ||
			sigmaNode.nodeType === SIGMA_CONSTANTS.TEXT_TYPE
		)
			return this.deleteSticker(sigmaNode.idImageTarget);
	}

	private deleteNode(nodeOriginalId: number): Observable<any> {
		return this.nodeService
			.deleteNode(nodeOriginalId, this.courseId, this.graphId)
			.pipe(
				tap((res) => {
					this.sigmaUtils.dropNode(
						this.sigmaUtils.generateNodeId(nodeOriginalId)
					);
					this.sigmaUtils.refresh();
				})
			);
	}

	private deleteQuiz(quizOriginalId: number): Observable<any> {
		return this.quizService
			.deleteQuiz(quizOriginalId, this.courseId, this.graphId)
			.pipe(
				tap((res) => {
					this.sigmaUtils.dropNode(
						this.sigmaUtils.generateQuizId(quizOriginalId)
					);
					this.sigmaUtils.refresh();
				})
			);
	}

	private deleteSticker(id: number): Observable<any> {
		//QUENTAL
		return this.stickerService.deleteSticker(id).pipe(
			tap((res) => {
				this.sigmaUtils.dropNode(this.sigmaUtils.generateStickerId(id));
				this.sigmaUtils.refresh();
			})
		);
	}

	public nextDiscoverStep() {
		this.displayedNodes = [];

		let lastOrdinal = this.activeOrdinals.slice(-1)[0];
		this.activeOrdinals.push(lastOrdinal + 1);
		const nodes = this.sigmaUtils.allNodes();
		for (const number of this.activeOrdinals) {
			let nodesIds: any[] = [];
			this.countNodes = [];
			const nodesWithMatchingOrdinalPower0 = nodes.filter((node) => {
				if (node.nodeType === "Node") {
					this.countNodes.push(node.id);
				}
				if (node.nodeType === "Node" && node.ordinalPower0 === number) {
					nodesIds.push(node.id);
				}
				return (
					node.ordinalPower0 === number ||
					// node.edges.some((edge) => nodesIds.includes(edge.target)) ||
					// (node.nodeType === "Quiz" && node.edges.length == 0) ||
					node.nodeType === "Sticker"
				);
			});
			nodesWithMatchingOrdinalPower0.map((node) => {
				if (node.nodeType === "Node" && node.hidden == false) {
					this.displayedNodes.push(node.id);
				}
				node.hidden = false;
			});
		}
	}

	public beforeDiscoverStep() {
		let removedOrdinal = this.activeOrdinals.pop();
		const nodes = this.sigmaUtils.allNodes();
		this.displayedNodes.pop();
		this.sigmaUtils.allNodes().map((node) => {
			if (node.ordinalPower0 == removedOrdinal) node.hidden = true;
			if (node.nodeType == "Quiz") {
				if (
					node.edges.some((edge) => !this.displayedNodes.includes(edge.target))
				) {
					node.hidden = true;
				}
			}
		});
	}

	public savePosition(
		id: number,
		x: number,
		y: number,
		type: string,
		idUser?: number,
		obj?: any
	) {
		if (type === SIGMA_CONSTANTS.NODE_TYPE) {
			this.nodeService
				.saveNodePos(id, x, y, this.courseId, this.graphId)
				.subscribe(
					(res) => res,
					(err) => console.error(err)
				);
		} else if (type === SIGMA_CONSTANTS.QUIZ_TYPE) {
			this.quizService
				.saveQuizPos(id, x, y, this.courseId, this.graphId, idUser)
				.subscribe(
					(res) => res,
					(err) => console.error(err)
				);
		} else if (type === SIGMA_CONSTANTS.STICKER_TYPE) {
			obj.xposition = x;
			obj.yposition = y;
			this.stickerService
				.createSticker(this.courseId, this.graphId, obj, null)
				.subscribe(
					(res) => res,
					(err) => console.error(err)
				);
		} else if (type === SIGMA_CONSTANTS.TEXT_TYPE) {
			obj.xposition = x;
			obj.yposition = y;
			this.stickerService
				.createSticker(this.courseId, this.graphId, obj, null)
				.subscribe(
					(res) => res,
					(err) => console.error(err)
				);
		}
	}
}
