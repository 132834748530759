import { SIGMA_CONSTANTS } from 'src/app/core/utils/sigma-constants';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'src/app/core/services/login';
import { NodeService } from 'src/app/core/services/node/node.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { QuizEditInfoComponent } from '../quiz-edit-info/quiz-edit-info.component';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { QuizTypes } from 'src/app/core/models/quizzes/quiz-types.enum';
import { QuizOrdinalTypes } from 'src/app/core/models/quizzes/quiz-ordinal-types.enum';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SigmaNode } from 'src/app/core/models/graph/sigmaNode.model';
import { NgxCopilotService } from 'ngx-copilot';
import { QuizUtils } from '../../../sigma-canvas/utils/quiz.utils';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { SigmaCanvasService } from '../../../sigma-canvas/sigma-canvas.service';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

declare var $: any;

interface RepeTypes {
    pattern: number,
    descripcion: string
};

@Component({
    selector: 'app-quiz-edit-header',
    templateUrl: './quiz-edit-header.component.html',
    styleUrls: ['./quiz-edit-header.component.scss']
})
export class QuizEditHeaderComponent implements OnInit, OnChanges {
    public form: UntypedFormGroup;
    @Input() btnPublicarTutorial: boolean;
    @Input() quiz: any;
    @Input() idCurso: number;
    @Input() idMapa: number;
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() quizTypeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() quizOrdinalTypeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() publishQuiz: EventEmitter<boolean> = new EventEmitter<boolean>();

    public type: QuizTypes;
    public ordinalType: QuizOrdinalTypes;


    repetitionList: RepeTypes[] = [
        {pattern: 1, descripcion: this.translateService.instant('REPETITIONLIST.DESC1')},
        {pattern: 2, descripcion: this.translateService.instant('REPETITIONLIST.DESC2')},
        {pattern: 3, descripcion: this.translateService.instant('REPETITIONLIST.DESC3')},
        {pattern: 4, descripcion: this.translateService.instant('REPETITIONLIST.DESC4')},
        {pattern: 5, descripcion: this.translateService.instant('REPETITIONLIST.DESC5')}
    ];

    public formRepetition: UntypedFormGroup;


    constructor(
        private  copilot:  NgxCopilotService,
        public activeModal: NgbActiveModal,
        public loginService: LoginService,
        private modalService: NgbModal,
        private nodeService: NodeService,
        private quizService: QuizzesService,
		private toaster: ToasterService,
        private translateService: TranslateService,
        private fb: UntypedFormBuilder,
        private sigmaCanvasService: SigmaCanvasService,
        public quizUtils: QuizUtils
    ) {
        this.nodeService.currentNode.subscribe((n) => this.quiz = n);
        this.quizService.currentQuiz.subscribe((q) => { this.quiz = q });

        this.form = this.fb.group(
            {
                level: [0, Validators.required],
                duration: ['00:00', Validators.required],
                ordinal: [0, Validators.required],
                published : [false],
                certificadoQuiz: [false],
                pattern : []
            }
        );
    }

    ngOnInit() {
        //this.getRepetitionTypes();
        this.form.get('pattern').setValue(this.quiz.defaultQuizPattern === 0 ? 3 : this.quiz.defaultQuizPattern);
        this.publishedControl.patchValue(this.quiz.published ? true : false)
        this.certificarControl.patchValue(this.quiz.certifiedQuiz === 1 ? true : false)

    }
    /*Next Step*/
    nextStep = (stepNumber:any) =>  this.copilot.next(stepNumber);

    done = () => {
        this.copilot.removeWrapper()
    }

    initPosition = (o: any) => {
        this.copilot.checkInit(o)
    };
    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.quiz && changes.quiz.currentValue) {
            this.type = changes.quiz.currentValue.quizType;
            this.ordinalType = changes.quiz.currentValue.ordinalType;
            this.publishedControl.patchValue(changes.quiz.currentValue.published ? true : false)
        }
    }

    public get quizType() {
        return QuizTypes;
    }

    public get quizOrdinalType() {
        return QuizOrdinalTypes;
    }

    public onQuizTypeSelected(event: MatButtonToggleChange) {
        this.quizTypeChanged.emit(event.value);
    }

    public onQuizOrdinalTypeSelected(event: MatButtonToggleChange) {
        this.quizOrdinalTypeChanged.emit(event.value);
    }

    public onCloseModal(sendData?: any) {
        this.quizService.currentQuiz.next(this.quiz);
        this.close.emit(this.quiz)
    }

    showInfo() {
        const modalRef = this.modalService.open(QuizEditInfoComponent,
            {
                scrollable: false,
                windowClass: MODAL_DIALOG_TYPES.W65
            }
        );
        modalRef.componentInstance.quiz = this.quiz;
        modalRef.componentInstance.courseId = this.idCurso;
        modalRef.componentInstance.graphId = this.idMapa;
    }

    deleteQuiz(){
        if (!this.loginService.esAutor()) {
            return;
        }
        let quiz:SigmaNode = this.quiz as SigmaNode
        quiz.nodeType = 'Quiz'

        this.sigmaCanvasService.deleteSigmaNode(quiz).subscribe(
            (res: any) => {
                this.toaster.success(this.translateService.instant('QUIZZES.DELETEOK'));
                this.activeModal.close(true);
            },
            err => {
                this.toaster.error(this.translateService.instant('QUIZZES.DELETEKO'));
                this.activeModal.close(false);
            }
        )

    }


    get publishedControl(): UntypedFormControl {
        if (this.form) {
            return (this.form.get('published') as UntypedFormControl);
        } else {
            return null;
        }
    }

    publish($ev){
        $ev.preventDefault()
        $ev.stopImmediatePropagation()
        this.publishQuiz.emit(!this.form.get('published').value);
    }

    get certificarControl(): UntypedFormControl {
        if (this.form) {
            return (this.form.get('certificadoQuiz') as UntypedFormControl);
        } else {
            return null;
        }
    }

    certifiedQuiz($ev){
        $ev.preventDefault()
        $ev.stopImmediatePropagation()
        this.quizService.certificarQuiz(this.quiz.idQuiz, this.idMapa, this.idCurso).subscribe(res => {
            this.toaster.success(this.translateService.instant(res.error.msg));
            this.sigmaCanvasService.sigmaUtils.updateNode({...this.quiz, certifiedQuiz: res.data.certifiedQuiz, type: SIGMA_CONSTANTS.QUIZ_NODE_TYPE});
            let value: boolean = res.data.certifiedQuiz ? true : false
            this.form.patchValue({certificadoQuiz: value })
        }, err => {
            this.form.patchValue({certificadoQuiz: false })
        })
    }

    getRepetitionTypes(){
        this.quizService.getRepetitionTypes().subscribe(res => {
            this.repetitionList = res.data;
        },
        (error) => {
            console.error(error);
        })
    }

    get repetitionTypes(): UntypedFormControl {
        if (this.form) {
            return (this.form.get('pattern') as UntypedFormControl);
        } else {
            return null;
        }
    }

    updateRepetition(){
        let formularioValue = this.form.value;
        this.quizService.updateRepetitionTypes(this.quiz.idQuiz, formularioValue.pattern).subscribe(res =>{
        },
        (error) =>{
            console.error(error);
        })
    }


}
