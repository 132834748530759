
<div *ngIf="cursos" class="card text-center">
    <div class="card-header">
        <h5 class="card-title">{{ 'COPILOTTUTORIAL.TUTORIAL' | translate }}</h5>
    </div>
    <div class="card-body">
        <div id="carouselExampleFade3" class="carousel slide carousel-fade" data-ride="carousel">
            <div class="carousel-inner">

                <div class="carousel-item active">
                    <img src="./../../../../assets/images/copilot/cursos-copilot.jpg" class="img-fluid" 
                    style="width: 350px !important; height: 250px;"
                    alt="">
                    <div class="m-4">
                        <div class="w-100 flex justify-content-center aling-items-center mt-2 mb-2">
                            <mat-icon svgIcon="book" class="icon-color-blue" [ngbTooltip]="'SIGMAMENU.SHOWCOURSE' | translate"></mat-icon>
                        </div>
                        <p class="texto  card-text">
                            {{ 'COPILOTTUTORIAL.CURSO_CONCEPTO' | translate }}
                        </p>
                    </div> 
                    
                </div>
            </div>


            <a class="carousel-control-prev" href="#carouselExampleFade3" role="button" data-slide="prev">
                <mat-icon svgIcon="chevron_left"  aria-hidden="true" style="color: #224d98;" [ngbTooltip]="'SIGMAMENU.SHOWCOURSE' | translate"></mat-icon>
                <span class="sr-only">{{ 'COPILOTTUTORIAL.ATRAS' | translate }}</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleFade3" role="button" data-slide="next">
                <mat-icon svgIcon="chevron_right"  aria-hidden="true" style="color: #224d98;" [ngbTooltip]="'SIGMAMENU.SHOWCOURSE' | translate"></mat-icon>
                <span class="sr-only">{{ 'COPILOTTUTORIAL.SIGUIENTE' | translate }}</span>
            </a>
          </div>
        
          <div class="container">
              <div class="row  flex justify-content-center aling-items-center">
                <div class="col-2  col-md-4  col-sm-4">
                    <button (click)="done()" type="button"  class="btn btn-block btn-primary btn-sm">{{ 'COPILOTTUTORIAL.SALTAR_TUTORIAL' | translate }}</button>
                </div>
              </div>
          </div>
       
    </div>


</div>

<div *ngIf="tutoId4" class="card text-center">

    <div id="carouselExampleFade2" class="carousel slide carousel-fade" data-ride="carousel">
        <div class="carousel-inner">

            <div class="carousel-item active">
            
                <div class="flex justify-content-center aling-items-center mt-4 animated fast fadeIn">
                    <div class="card mb-3" style="max-width: 840px;">
                        <div class="row no-gutters">
                        <div class="col-md-12">
                                <div class="card-body">
                                    <h5 class="card-title">{{ 'COPILOTTUTORIAL.TUTORIAL' | translate }}</h5>
            
                                    <div class="flex justify-content-center aling-items-center">
                                        <img width="350px" height="350px" src="./../../../../assets/images/copilot/nodo-copilot.jpg"  class="img-fluid" alt="">
                                    </div>
                                    <div class="m-4">
                                        <div class="flex justify-content-center aling-items-center mt-2 mb-2">
                                            <mat-icon svgIcon="book" class="icon-color-blue" [ngbTooltip]="'SIGMAMENU.SHOWCOURSE' | translate"></mat-icon>
                                        </div>
                                        <p class="texto  card-text">
                                            Dispones de distintas herramientas multimedia para ayudarte a construir tu idea.
                                        </p>
                                        <p class="texto  card-text"> 
                                            (video, imagen, audio, documento o texto, si el editor los ha creado)
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <a class="carousel-control-prev" href="#carouselExampleFade2" role="button" data-slide="prev">
            <mat-icon svgIcon="chevron_left"  aria-hidden="true" style="color: #224d98;" [ngbTooltip]="'SIGMAMENU.SHOWCOURSE' | translate"></mat-icon>
            <span class="sr-only">{{ 'COPILOTTUTORIAL.ATRAS' | translate }}</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleFade2" role="button" data-slide="next">
            <mat-icon svgIcon="chevron_right"  aria-hidden="true" style="color: #224d98;" [ngbTooltip]="'SIGMAMENU.SHOWCOURSE' | translate"></mat-icon>
            <span class="sr-only">{{ 'COPILOTTUTORIAL.SIGUIENTE' | translate }}</span>
        </a>
      </div>

      <div class="container">
        <div class="row  flex justify-content-center aling-items-center mb-4">
          <div class="col-2  col-md-4  col-sm-4">
              <button (click)="done()" type="button"  class="btn btn-block btn-primary btn-sm">{{ 'COPILOTTUTORIAL.SALTAR_TUTORIAL' | translate }}</button>
          </div>
        </div>
    </div>


</div>

<div *ngIf="padsCopilot" class="card text-center">
    <div class="card-header">
        <h5 class="card-title">{{ 'COPILOTTUTORIAL.TUTORIAL' | translate }}</h5>
    </div>
    <div class="card-body">


        <div id="carouselExampleFadePasd" class="carousel slide carousel-fade" data-ride="carousel">
            <div class="carousel-inner">

                <div class="carousel-item active">
                    <img src="./../../../../assets/images/copilot/grafo-pads.jpg" class="img-fluid" 
                    style="width: 350px !important; height: 250px;"
                    alt="">
                    <div class="m-4">
                        <div class="w-100 flex justify-content-center aling-items-center mt-2 mb-2">
                            <mat-icon svgIcon="graph" class="icon-color-blue" [ngbTooltip]="'SIGMAMENU.SHOWCOURSE' | translate"></mat-icon>
                        </div>
                        <p class="texto  card-text">
                            {{ 'COPILOTTUTORIAL.IDEOGRAFO_CONCEPTO' | translate }}
                        </p>
                    </div> 
                    
                </div>

                <div class="carousel-item">
                    <img src="./../../../../assets/images/copilot/grafo-pads-auto.jpg" class="img-fluid" 
                        style="width: 350px !important; height: 250px;"
                        alt="">
                    <div class="m-4">
                        <div class=" w-100 flex justify-content-center aling-items-center mt-2 mb-2">
                            <mat-icon svgIcon="automatic" class="icon-color-blue" [ngbTooltip]="'SIGMAMENU.SHOWCOURSE' | translate"></mat-icon>
                        </div>
                        <p class="texto  card-text">
                            {{ 'COPILOTTUTORIAL.MODO_AUTO' | translate }}
                        </p>
                    </div> 
                </div>
                
                <div class="carousel-item">
                    <img src="./../../../../assets/images/copilot/grafo-pads-filtrado-nodos-quizzes.jpg" class="img-fluid" 
                    style="width: 350px !important; height: 250px;"
                    alt="">
                
                    <div class="m-4">
                        <div class="w-100 flex justify-content-center aling-items-center mt-2 mb-2">
                            
                            <img src="./../../../../assets/images/icons/inte+excla.png" style="height: 18px;">
                            <h6>
                                <span class="texto card-text">&nbsp;</span>
                            </h6>
                        </div>
                        <p class="texto card-text">
                            {{ 'COPILOTTUTORIAL.INTEEXCLA' | translate }}

                        </p>
                    </div>  
                </div>

                <div class="carousel-item">
                    <img src="./../../../../assets/images/copilot/grafo-pads-filtrado-page.jpg" class="img-fluid" 
                    style="width: 350px !important; height: 250px;"
                    alt="">
                  
                    <div class="m-4">
                        <div class="w-100 flex justify-content-center aling-items-center mt-2 mb-2">
                            <mat-icon svgIcon="chevron_left" class="icon-color-blue" [ngbTooltip]="'SIGMAMENU.SHOWCOURSE' | translate"></mat-icon>
                            <mat-icon svgIcon="chevron_right" class="icon-color-blue" [ngbTooltip]="'SIGMAMENU.SHOWCOURSE' | translate"></mat-icon>
                            <h6>
                                <span class="texto card-text">&nbsp;</span>
                            </h6>
                        </div>
                        <p class="texto card-text">
                            Puede que el ideografo tenga diferentes páginas para ir avanzando más fácilmente, 
                            aquí puedes navegar por ellas
                        </p>
                    </div>  
                </div>

                <div class="carousel-item">
                    <img src="./../../../../assets/images/copilot/grafo-pads-filtrado-power.jpg" class="img-fluid" 
                    style="width: 350px !important; height: 250px;"
                    alt="">
                  
                    <div class="m-4">
                        <div class="w-100 flex justify-content-center aling-items-center mt-2 mb-2">
                            <mat-icon svgIcon="expand_less" class="icon-color-blue" [ngbTooltip]="'SIGMAMENU.SHOWCOURSE' | translate"></mat-icon>
                            <mat-icon svgIcon="expand_more" class="icon-color-blue" [ngbTooltip]="'SIGMAMENU.SHOWCOURSE' | translate"></mat-icon>
                            <h6>
                                <span class="texto card-text">&nbsp;</span>
                            </h6>
                        </div>
                        <p class="texto card-text">
                            Aquí puedes subir o bajar la velocidad de avance, que llamamos POTENCIA hasta que encuentres tu ritmo cómodo.
                            <br> 
                            Cuando esté la escalera: Para ver cuántas potencias hay en el curso, puedes abrir la escalera.
                        </p>
                    </div>  
                </div>
            
            </div>


            <a class="carousel-control-prev" href="#carouselExampleFadePasd" role="button" data-slide="prev">
                <mat-icon svgIcon="chevron_left"  aria-hidden="true" style="color: #224d98;" [ngbTooltip]="'SIGMAMENU.SHOWCOURSE' | translate"></mat-icon>
                <span class="sr-only">{{ 'COPILOTTUTORIAL.ATRAS' | translate }}</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleFadePasd" role="button" data-slide="next">
                <mat-icon svgIcon="chevron_right"  aria-hidden="true" style="color: #224d98;" [ngbTooltip]="'SIGMAMENU.SHOWCOURSE' | translate"></mat-icon>
                <span class="sr-only">{{ 'COPILOTTUTORIAL.SIGUIENTE' | translate }}</span>
            </a>
          </div>
        
          <div class="container">
              <div class="row  flex justify-content-center aling-items-center">
                <div class="col-2  col-md-4  col-sm-4">
                    <button (click)="done()" type="button"  class="btn btn-block btn-primary btn-sm">{{ 'COPILOTTUTORIAL.SALTAR_TUTORIAL' | translate }}</button>
                </div>
              </div>
          </div>
       
    </div>
</div>

