<div
     style="position: absolute; z-index: 10;"
     [style.top.px]="element.yPosition"
     [style.left.px]="element.xPosition"
     [style.width.px]="element.xSize"
     [style.height.px]="element.ySize"
     class="rectangle element-box {{element.style}}"
     id="#{{element.idTemplateElement}}">

    <div class="w-100 h-100 d-flex justify-content-center align-items-center pointer" *ngIf="!element.data && !compuCorrect && type.isOption; else showData">
        <div>
            <input *ngIf="type.type !== 'text'" class="input-text" id="{{element.idTemplateElement}}" type="file" (change)="loadFile($event)" [disabled]="!editable" />
            <label class="w-100 h-100 d-flex align-items-center justify-content-center pointer" for="{{element.idTemplateElement}}">
                <mat-icon aria-hidden="false" placement="bottom" [ngbTooltip]="type.description | translate" (click)="(type.isOption &&  type.type === 'text') ? setDescription(element) : ''">
                    {{element.icon}}
                </mat-icon>
            </label>
        </div>
    </div>

    <ng-template #showData>
        <div class="w-100 h-100" style="background-size: cover; background-color: #FFF"
             [ngStyle]="{'background-image': type.type === 'image' ? 'url(' + (element.data  | imagen:'quizElement') + ')' : ''}" (click)="elementClicked()">
            <div class="edit-icon">
                <div *ngIf="type.isOption && compuCorrect">
                    <mat-checkbox [checked]="checked" [disabled]="!editable" (change)="optionChecked($event)" (click)="$event.stopPropagation()"></mat-checkbox>
                </div>
            </div>

            <ng-container [ngSwitch]="type.type">
                <ng-container *ngSwitchCase="'video'">
                    <video class="w-100 mw-100 mh-100" #videoElement controls controlsList="nodownload" [src]="element.data | imagen:'quizElement' | safe">
                    </video>
                </ng-container>
                <ng-container *ngSwitchCase="'text'">
                    <div class="w-100 h-100 p-2" style="overflow: auto;">
                        <p [innerHTML]="element.data"></p>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'audio'">
                    <div class="h-100 p-0 d-flex align-items-end">
                        <audio class="w-100 d-block" [src]="element.data | imagen:'quizElement' | safe" controls controlsList="nodownload">
                        </audio>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'pdf'">
                    <div class="h-100 p-0 d-flex">
                        <iframe class="w-100 h-100" [src]="element.data | imagen:'quizElement' |safe" allowfullscreen></iframe>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-template>
</div>
