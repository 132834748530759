<mat-dialog-content class="mat-typography">
    {{message}}
</mat-dialog-content>

<div class="row justify-content-between">
    <div class="col-4">
        <mat-dialog-actions >
            <button mat-button [mat-dialog-close]="false">{{koButtonText}}</button>
        </mat-dialog-actions>
    </div>
    <div class="col-4">
        <mat-dialog-actions align="end">
            <button mat-button [mat-dialog-close]="true">
                <mat-icon [svgIcon]="okButtonImage" class="mr-2"></mat-icon>
                <span>{{okButtonText}}</span>
            </button>
        </mat-dialog-actions>
    </div>
  </div>

