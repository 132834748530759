<div class="sticky-top">
    <div class="modal-header" align="center">
        <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'SETTINGS.EMPLOYERTITLE' | translate }}</span>

        <button type="button" class="close" data-dismiss="modal" (click)="closeModal('')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>

<div class='fondoEditarCurso'>

    <div class="col-12">
        <app-alert context="UserData"></app-alert>
    </div>

    <form [formGroup]="formularioDatos" (ngSubmit)="grabarDatos()" #thisForm="ngForm" class="m-5 p-4 card">

        <div class="row">
            <div class="col-sm-12" align="center">
                <span class="textoFontSize09 colorTextoActivado font-weight-bold">{{ 'USERDATA.COMPLETATUPERFILCONLOSSIGUIENTESDATOS' | translate }}</span>
            </div>
        </div>

        <div class="row mt-4">

            <div class="col-sm-2"></div>

            <div class="col-sm-8">

                <div class="row">

                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">location_city</i>
                    </div>

                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'USERDATA.EMPRESA' | translate }}</mat-label>
                            <mat-select formControlName="idEnterprise" placeholder="{{ 'USERDATA.EMPRESA' | translate }}">
                                <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.idEnterprise">
                                    [{{enterprise.country}}]-{{enterprise.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-9">
                    </div>

                    <div class="col-3" align="right">
                        <button class="botonLoginEntrar" type="submit">
                            <span>{{ 'USERDATA.GRABAR' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-sm-2"></div>
        </div>
    </form>

    <!-- <app-loading [isVisible]="loading"></app-loading> -->
</div>