import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

// Services
import { UsersService } from 'src/app/core/services/users';
import { LoginService } from 'src/app/core/services/login';


@Injectable()
export class ProfileValidationGuard implements CanActivate {
    public constructor(private router: Router, private usersService: UsersService, private loginService: LoginService) { }

    public canActivate() {

        const user = this.loginService.getUser();

        if (user.idUser !== 0) {

            const userData = user;

            const perfil = this.loginService.getProfile();

            // Y ahora compruebo si ha sido validado
            const res = this.usersService.checkIfUserDataAreRegistered(userData, perfil);

            if (res.result !== true) {
                // Y lo mando a los datos de usuario
                this.router.navigate([`/user-data/${perfil}`]);
            } else {
                return true;
            }
        } else {
            return false;
        }

    }
}
