<nb-card class="mb-0 no-edge-card">
	<nb-card-header class="border-bottom-0">
		<div class="row">
			<div class="col-11 col-md-11 modal-title">
				<h2>Ingresa un codigo para ingresar a la partida</h2>
			</div>

		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle"
			[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"></nb-icon>
	</div>
	<nb-card-body>
		<div class="container">

			<div class="container mt-6 p-2">
				<div class="row d-flex justify-content-center cards-container">
					<div class="col-md-12 col-12">

						<nb-card >
							<nb-card-body class="example-items-rows">

								<nb-form-field>
									<label for="codigo"></label>
									<input [(ngModel)]="hexCode" (ngModelChange)="hexCode = hexCode.toUpperCase()" type="text" id="codigo" nbInput fullWidth
										placeholder="CODIGO">
									<button nbSuffix nbButton ghost (click)="pasteCode()">
										<nb-icon icon="file-text-outline"
														 pack="eva"
														 attr.aria-label="pegar código">
										</nb-icon>
									</button>
								</nb-form-field>

								<button class="mt-2" nbButton class="btn-themecolor" (click)="joinRoom()" [disabled]="hexCodeComplete === false">Ingresar</button>
							</nb-card-body>
						</nb-card>

					</div>

				</div>

			</div>
		</div>
	</nb-card-body>
</nb-card>








