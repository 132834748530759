import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-alert-flash',
  templateUrl: './modal-alert-flash.component.html',
  styleUrls: ['./modal-alert-flash.component.scss']
})
export class ModalAlertFlashComponent implements OnInit {

	formulario: UntypedFormGroup;
	maxSize: number;
	maxBigQuizzes: number;
	maxAllQuizzes: number;

  constructor(
		public translateService: TranslateService,
		private activeModal: NgbActiveModal,
		private fb: UntypedFormBuilder
		){
			this.formulario = this.fb.group({
				sizeDeck: [1],
				includeSmall: [false],
				resp: [false],
			});
			this.formulario.get('includeSmall').valueChanges.subscribe((value) => {
				this.actualizarMaxSize(value);
			});
		}

	ngOnInit(){
		this.maxSize = this.maxBigQuizzes;
		this.sizePredetermined();
	}

  aceptar() {
		this.formulario.value.resp = true;
    this.activeModal.close(this.formulario);
  }

  cancelar() {
    this.activeModal.close(this.formulario);
  }

  closeModal(sendData?: any) {
    this.activeModal.close(this.formulario);
  }

  sizePredetermined() {
	this.formulario.get('sizeDeck').setValue(this.maxBigQuizzes);
  }


	actualizarMaxSize(includeSmall: boolean) {
    this.maxSize = includeSmall ? this.maxAllQuizzes : this.maxBigQuizzes;
  }
}
