<div class="modal-body fondoEditarCurso" align="center">
    <form name="form" #dataForm="ngForm" [formGroup]="form" (ngSubmit)="getUser()">
        <div class="row mx-auto p-3">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="row p-2">
                    <div class="col-12">
                        <span
                            class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'TASK.SEARCHINGFOR' | translate }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">account_circle</i>
                    </div>
                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'TASK.NAME' | translate }}</mat-label>
                            <input matInput formControlName="name" required>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">location_city</i>
                    </div>
                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'TASK.CENTER' | translate }}</mat-label>
                            <input matInput formControlName="center" required>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">location_on</i>
                    </div>
                    <div class="col-10">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'TASK.LOCATION' | translate }}</mat-label>
                            <input matInput formControlName="location" required>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2" align="center">
                        <i class="material-icons ti-size-2">pin_drop</i>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'TASK.DISTANCE' | translate }}</mat-label>
                            <input matInput formControlName="distance" required>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'TASK.UNITS' | translate }}</mat-label>
                            <mat-select formControlName="unit">
                                <mat-option *ngFor="let unit of units" [value]="unit">{{unit}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row p-1">
                    <div class="col-1 p-1" align="center">
                        <i class="material-icons ti-size-2">date_range</i>
                    </div>
                    <div class="col-2">
                        <mat-form-field class="full-width">
                            <input matInput formControlName="date" required>
                        </mat-form-field>
                    </div>
                    <div class="col-1 p-1" align="center">
                        <i class="material-icons ti-size-2">av_timer</i>
                    </div>
                    <div class="col-2">
                        <mat-form-field class="full-width">
                            <input matInput formControlName="time" required>
                        </mat-form-field>
                    </div>
                    <div class="col-1 p-1" align="center">
                        <i class="material-icons ti-size-2">schedule</i>
                    </div>
                    <div class="col-2">
                        <mat-form-field class="full-width">
                            <input matInput formControlName="schedule" required>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <button class="btn border border-secondary w-60" (click)="selectNow()">
                            <span class="textoFontSize08">{{ 'TASK.NOW' | translate }}</span>
                        </button>
                    </div>
                </div>
                <div class="row p-1">
                    <div class="col-6">
                        <button type="button" class="btn border border-secondary" (click)="random()"
                            [ngStyle]="{'color':'rgb(33, 76, 152)'}">
                            <i class="material-icons ti-size-2 pointer"
                                ngbTooltip="{{'TASK.RANDOM'|translate}}">published_with_changes</i>
                        </button>
                    </div>
                    <div class="col-6">
                        <button type="submit" class="btn border border-secondary"
                            [ngStyle]="{'color':'rgb(33, 76, 152)'}">
                            <i class="material-icons ti-size-2 pointer"
                                ngbTooltip="{{'TASK.SEARCH'|translate}}">search</i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="col-12 p-1">
                    <span
                        class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'TASK.ACCEPTEDBY' | translate }}</span>
                </div>
                <div *ngIf="users" class="col-12 border border-secondary p-2">
                    <div class="row pointer" *ngFor="let alumno of alumnos" (click)="competitionTask()">
                        <div class="col-2">
                            <img class="avatar avatar-16 img-circle" src={{alumno.img}} />
                        </div>
                        <div class="col-10">
                            <div class="row">
                                <div class="col-8">
                                    <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{alumno.nombre}}
                                    </span>
                                </div>
                                <div class="col-4">
                                    <span class="textoFontSize07 colorTextoActivado font-weight-bold">{{alumno.fecha}}
                                    </span>
                                </div>
                            </div>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
