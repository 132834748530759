import { SigmaCanvasService } from "./../sigma-canvas/sigma-canvas.service";
import { Router } from "@angular/router";
import { SIGMA_CONSTANTS } from "../../../../core/utils/sigma-constants";
import { ACTIONS } from "src/app/core/utils/actions";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { environment } from "src/environments/environment";
import { Utils } from "../../../../core/utils/utils";
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewEncapsulation,
	HostListener,
} from "@angular/core";
import {
	NgbModal,
	NgbActiveModal,
	NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { ModelIcono } from "src/app/core/models/courses/icono.model";
import { gNode, ObjetoNodoAuto } from "src/app/core/models/graph/gNode.model";
import { LoginService } from "src/app/core/services/login";
import { NodeService } from "src/app/core/services/node/node.service";
import { QuizzesService } from "src/app/core/services/quizzes";
import { SnapshotService } from "src/app/core/services/snapshot/snapshot.service";
import { MateriaModel } from "src/app/core/models/masters";
import { Observable, Subject, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { FILE_FORMATS } from "src/app/core/utils/file-formats";
import { finalize, map } from "rxjs/operators";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { DialogService } from "src/app/core/services/shared/dialog.service";
import { ModalRadarComponent } from "src/app/shared/components/modal-radar/modal-radar.component";
import { PowerService } from "src/app/core/services/power/power.service";
import { LOCALSTORAGESTRINGS } from "src/app/core/models/masters/localstorage.enum";
import { DEFAULTCLOSEPADS } from "src/app/core/models/masters/masters.enum";
import { ModalCelebrationComponent } from "src/app/shared/components/modal-celebration/modal-celebration.component";
import { SigmaToolbarsService } from "src/app/core/services/sigma-toolbars/sigma-toolbars.service";
import {
	GruposService,
	SOCKETMESSAGES,
} from "src/app/core/services/groups/grupos.service";
import { ModalSocketCoursesComponent } from "../../grupos/modal-socket-courses/modal-socket-courses.component";
import { SocketService } from "src/app/core/services/socket/socket-service.service";
import { MastersService } from "src/app/core/services/masters";
import { Profiles } from "src/app/core/utils/profiles.enum";
import { ModalAceptarCancelarComponent } from "../../modal";
import { ModalProfileActionsComponent } from "../../modal-profile-actions/modal-profile-actions.component";
import { TargetsService } from "src/app/core/services/targets";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { GraphService } from "src/app/core/services/graph/graph.service";
import { ModalAlertAsistenciaComponent } from "../../modal-profile-actions/modal-alert-asistencia/modal-alert-asistencia.component";
import { LocalStorage } from "src/app/core/utils";

declare var $: any;
declare var document;
interface Time {
	hours: number;
	minutes: number;
	seconds: number;
}

@Component({
	selector: "app-node-mode-auto",
	templateUrl: "./app-node-mode-auto.component.html",
	styleUrls: ["./app-node-mode-auto.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class NodeModeAutoComponent implements OnInit, OnDestroy {
	// A.G.
	@Input() idCurso: number;
	@Input() idMapa: number;
	@Input() type: string;
	@Input() listQuiz = [];
	@Input() currentGraph: any;
	@Input() elements: any[];
	@Input() node; // :gnode
	@Input() quiz;
	@Input() rawQuiz;
	@Input() viewQuiz: boolean;
	@Input() soloQuiz: boolean;
	@Input() soloNodos: boolean;
	@Input() autoTodo: boolean;
	@Input() firstQuiz: number;
	@Input() allItems: ObjetoNodoAuto;
	@Input() adjustWindow: boolean = false;
	@Input() hideBar = false;
	@Input() course;
	@Output() newNode = new EventEmitter<gNode>();

	isFooterOpen = true;
	answered: boolean = false;
	result: boolean;
	disabledButton: boolean = false;
	modeAuto: boolean = true;
	mouseInterval: any = "";

	localPower: number = 0;

	discoverAnswer: Subject<boolean> = new Subject<boolean>();
	discoverAnswer$: Observable<boolean> = this.discoverAnswer.asObservable();

	evaluate: Subject<boolean> = new Subject<boolean>();
	evaluate$: Observable<boolean> = this.evaluate.asObservable();

	playNext: Subject<boolean> = new Subject<boolean>();
	playNext$: Observable<boolean> = this.playNext.asObservable();

	tryClose: Subject<boolean> = new Subject<boolean>();
	tryClose$: Observable<boolean> = this.tryClose.asObservable();

	subjects$: Observable<MateriaModel[]>;

	saveClicked: Subject<any> = new Subject<any>();
	saveClicked$: Observable<any> = this.saveClicked.asObservable();

	nomGrafo: string;
	nomNode: string;

	cargando: boolean;
	inicial: boolean;

	mensaje: string;
	format: string;
	automatic: any = false;
	isShowFiles: boolean = true;

	intervalId;
	time: Time;
	countSeconds: number;
	timerId: number = null;
	private send = false;

	private _newColor: string;

	verGif: any;
	files: File[] = [];

	showSaveButton: boolean = false;
	isLoading: boolean = false;
	canEdit: boolean = false;

	isShowPads: boolean = true;
	isShowLabels: boolean = false;

	padStatus: boolean = false;
	labelStatus: boolean = false;

	public posArrayNodo; // Siempre se empieza por el nodo 0
	public posArrayQuiz = 0; // Siempre se empieza por el nodo 0

	nextNodo: boolean = false;
	backList: boolean = false;

	showArrow = false;
	arrowIcon = "north";

	urlCelebrations: string = environment.celebrations;

	action: string;
	private subscriptions: Subscription[] = [];

	showUserAccount: boolean = false;
	private destroy$ = new Subject();
	public cursosGrupo: any[] = [];
	isProjectInGroup: boolean = false;
	isIdeographInGroup: boolean = false;
	graphTitle: any;
	courseTitle: any;
	selectedGroupInfo: any;
	idGroup: number = 0;
	nodos: any;
	listQM: any[];
	selectedButtonIndex: number = 0;

	/**
	 *
	 */
	constructor(
		public modalService: NgbModal,
		public activeModal: NgbActiveModal,
		public loginService: LoginService,
		private nodeService: NodeService,
		private quizService: QuizzesService,
		public snapshotService: SnapshotService,
		private toaster: ToasterService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private powerServ: PowerService,
		private utils: Utils,
		private st: SigmaToolbarsService,
		private socketService: SocketService,
		private groupService: GruposService,
		private MastersService: MastersService,
		private targetsService: TargetsService,
		public router: Router,
		private graphServ: GraphService,
		private sigmaCanvasService: SigmaCanvasService,
		private localStorage: LocalStorage,
		private masterService: MastersService,
	) {
		this.nodeService.currentNode.subscribe((n) => (this.node = n));
		this.quizService.currentGraph.subscribe((g) => (this.currentGraph = g));
		var subscribe = this.quizService.loading.subscribe((n: boolean) => {
			this.cargando = n;
			if (this.cargando == false && loginService.esEstudiante()) {
				subscribe.unsubscribe();
			}
		});
	}

	// Retorna un entero aleatorio entre min (incluido) y max (excluido)
	// ¡Usando Math.round() te dará una distribución no-uniforme!

	getRandomInt(min, max) {
		return Math.floor(Math.random() * (max - min)) + min;
	}

	ngOnInit() {
		this.selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);
		this.graphServ
			.getNodesFromCourseGraph(this.idCurso, this.idMapa)
			.subscribe((res) => {
				this.nodos = res.nodes;
				this.nodos.sort((a, b) => a.ordinalPower0 - b.ordinalPower0); //lo ordenamos por ordinal
				this.posArrayNodo = this.nodos.findIndex(
					(node) => node.idOriginal === this.node.idOriginal
				);
			});

		this.projectInGroup();
		this.ideoGraphInGroup();
		if (this.loginService.esEstudiante() && !this.IsFullScreen()) {
			document.documentElement.requestFullscreen().catch((err) => {
				console.log("err");
			});
		}
		this.node = { ...this.node };

		this.nodeService.setCurrentNode(this.node);
		this.localPower = this.convertObjectToNumber(this.powerServ.getPower());

		this.canEdit = this.loginService.esAutor();

		this.posArrayQuiz = this.firstQuiz;

		// A.G.
		this.currentGraph.idTarget = this.idMapa;
		this.currentGraph.idCourse = this.idCurso;
		this.targetsService.getTargetById(this.idMapa).subscribe((res) => {
			if (res.data) {
				this.graphTitle = res.data.tittle;
			}
		});
		this.graphTitle = this.currentGraph.name;
		this.courseTitle = this.course.courseTittle;
		this.quizService.setCurrentGraph(this.currentGraph);
		this.quizService.setType(this.type);
		this.quizService.setExecuteAutomatic(false);
		if (this.type == SIGMA_CONSTANTS.NODE_TYPE.toLowerCase()) {
			this.quizService.setCurrentNode(this.node);
		}
		this.automatic = JSON.parse(
			localStorage.getItem(LOCALSTORAGESTRINGS.AUTOMATIC)
		);

		this.inicial = true;
		if (this.loginService.esAutor()) {
			//this.mensaje = "Por favor suba los archivos de estudio";
			this.mensaje = "subir";
		}
		if (this.loginService.esEstudiante()) {
			//this.mensaje = "Aquí se mostrará sus archivos de estudio";
			this.mensaje = "mostrar";
		}
		if (this.loginService.esProfesor()) {
			if (this.quiz.idQuizFather && this.quiz.idQuizFather != 0) {
				// traemos todos los hijos del quiz padre
				let quizM = this.quizService
					.getQuizMultiple(this.quiz.idQuizFather, this.idCurso, this.idMapa)
					.subscribe((res) => {
						this.listQM = res.quizzes;
					});
			}
		}

		this._checkPadLabelStatus();

		setTimeout(() => {
			//Ocultamos files, pads y labels a los 3 segundos para dejar visible el contenido;
			this.isShowFiles = false;
			this.isShowLabels = false;
			this.isShowPads = false;
		}, DEFAULTCLOSEPADS);

		this.sigmaCanvasService.setShowDiscoverButton(false);
		//Creamos el log
		if(this.viewQuiz){
			//definimos variable para tutoriales
			let idTutorialContext = 0;
			let lastSelectedProfile = this.localStorage.getItem("lastSelectedProfile");
			if (this.loginService.esEstudiante()) {
				idTutorialContext = 80;
			} else if(lastSelectedProfile === "PROFESOR"){
				idTutorialContext = 48;
			} else if(lastSelectedProfile === "ESTUDIANTE"){
				idTutorialContext = 79;
			}
			this.localStorage.setItem("idTutorialContext", idTutorialContext);
			if(this.localStorage.getItem("TutorialesON") == "true"){
				this.masterService.openModalTutorial(idTutorialContext);
			}
			let actData = this.quiz.idMultiplexQuiz == 0 ? "Ha entrado en la Actividad con id: " + this.quiz.idOriginal : "Ha entrado en la Actividad Multiple con idPadre: " + this.quiz.idQuizFather;
			const bodyLog1 = {
				action: "actividades",
				actionData: actData,
				startDate: new Date(),
				endingDate: null,
				role: this.loginService.getProfile(),
				idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
			};
			this.MastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
		} else{
			//definimos variable para tutoriales
			let idTutorialContext = 0;
			let filtroInicial = this.localStorage.getItem("filtroInicial");
			let lastSelectedProfile = this.localStorage.getItem("lastSelectedProfile");
			if (filtroInicial == "explicacionAsociativa" && this.loginService.esProfesor()) {
				idTutorialContext = 18;
			}
			if (filtroInicial == "abpCasos" && this.loginService.esProfesor()) {
			}
			if (filtroInicial == "pensarCreando" && this.loginService.esProfesor()) {
			}
			if (filtroInicial == "pensarCreando(ver)" && this.loginService.esProfesor()) {
			}
			if (filtroInicial == "pruebaNivel" && this.loginService.esProfesor()) {
			}
			if(filtroInicial == "" && lastSelectedProfile === "PROFESOR"){
				idTutorialContext = 47;
			} else if(filtroInicial == "" && lastSelectedProfile === "ESTUDIANTE"){
				idTutorialContext = 78;
			}
			this.localStorage.setItem("idTutorialContext", idTutorialContext);
			if(this.localStorage.getItem("TutorialesON") == "true"){
				this.masterService.openModalTutorial(idTutorialContext);
			}
			const bodyLog1 = {
				action: "ideas",
				actionData: "Ha entrado en la Idea con id: " + this.node.idOriginal,
				startDate: new Date(),
				endingDate: null,
				role: this.loginService.getProfile(),
				idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
			};
			this.MastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
		}
	}

	private _checkPadLabelStatus(): void {
		if (this.viewQuiz) {
			this.isShowLabels = this.labelStatus =
				this.utils.labelsStatus.showLabelsQuiz;
			this.isShowPads = this.padStatus = this.utils.padsStatus.showPadsQuiz;
		} else {
			this.isShowLabels = this.labelStatus =
				this.utils.labelsStatus.showLabelsNode;
			this.isShowPads = this.padStatus = this.utils.padsStatus.showPadsNode;
		}
	}

	toggleFooter() {
		this.isFooterOpen = !this.isFooterOpen;
	}

	showFiles(value) {
		let interval: number = 1;
		if (value) clearInterval(this.mouseInterval);

		this.mouseInterval = setInterval(() => {
			interval++;
			if (interval === 4) {
				this.isShowFiles = false;
				this.isShowPads = false;
				this.isShowLabels = false;
				clearInterval(this.mouseInterval);
			}
		}, DEFAULTCLOSEPADS / 3);

		this.isShowFiles = true; //Change variable value

		//QUENTAL
		if (this.viewQuiz) {
			if (this.utils.padsStatus.showPadsQuiz) this.isShowPads = true;
			if (this.utils.labelsStatus.showLabelsQuiz) this.isShowLabels = true;
		} else {
			if (this.utils.padsStatus.showPadsNode) this.isShowPads = true;
			if (this.utils.labelsStatus.showLabelsNode) this.isShowLabels = true;
		}
	}

	onDiscoverAnswer() {
		this.discoverAnswer.next(true);
	}

	onEvaluate(result: boolean) {
		this.evaluate.next(result);
	}

	onPlayNext() {
		this.playNext.next(true);
	}

	onAnswered() {
		this.answered = true;
	}

	onColorChanged(newColor: string) {
		this._newColor = newColor;
	}

	onViewGif(result: boolean) {
		if (this.utils.showGifResponse == true) {
			let url: string = "";

			//Si result es true, gif ok; si es false, gif KO
			const numRandon = this.getRandomInt(1, 40);
			const numRandonNeg = this.getRandomInt(1, 19);

			if (result)
				url = this.urlCelebrations + "/positive/image-" + numRandon + ".gif";
			else
				url = this.urlCelebrations + "/negative/image-" + numRandonNeg + ".gif";

			//Mostrar un modal con el gif y que se cierre en 3 segundos o lo haga el usuario
			const modalRef = this.modalService.open(ModalCelebrationComponent, {
				windowClass: "celebration-modal-window",
			});

			modalRef.componentInstance.url = url;

			modalRef.result.then(
				(res) => {
					modalRef.close();
				},
				(err) => {}
			);

			setTimeout(() => {
				modalRef.close();
			}, 3000);
		} else {
			this.utils.showGifResponse = true;
		}
	}

	hidenGif(event) {}

	closeNode() {
		//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
		if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
			this.llamadoAsistencia();
		};
		this.activeModal.close(this.node.idOriginal);
		//window.location.reload();
	}

	onClose() {
		if (this.loginService.esProfesor()) {
			this.activeModal.close();
			//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
			if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
				this.llamadoAsistencia();
			};
		} else {
			if (this.answered) {
				this.activeModal.close(this._newColor);
				//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
				if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
					this.llamadoAsistencia();
				};
				return;
			}

			this.tryClose.next(true);

			const dialogRef = this.dialogService.openAcceptDialog(
				true,
				"¿Salir de la actividad?",
				this.translateService.instant("QUIZZES.CLOSEQUESTION"),
				[],
				this.translateService.instant("QUIZZES.EXIT"),
				this.translateService.instant("QUIZZES.RESPONSE")
			);

			dialogRef.afterClosed().subscribe((result) => {
				this.tryClose.next(result);

				if (result) {
					this.tryClose.next(false);
					this.activeModal.close();
				}
			});
		}
	}

	////////////////////////////////////////////////
	////////////////////////////////////////////////

	changeStatus($event) {
		this.send = $event;
	}

	receiveNewNodeData($event) {
		this.newNode.emit($event);
	}

	cancelar() {
		this.modalService.dismissAll();
	}

	registrar() {
		this.modalService.dismissAll();
	}

	reproducirFormato(formato: string) {
		this.format = formato;
	}

	closeModal(sendData?: any) {
		//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
		if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
			this.llamadoAsistencia();
		};
		this.activeModal.close(this.node);
		//this.st.changeUpdateGraph(true)
	}

	ngOnDestroy() {
		if (this.loginService.esEstudiante() && this.IsFullScreen()) {
			document.exitFullscreen();
		}
		if (this.timerId) {
			clearInterval(this.timerId);
		}
		//this.st.changeUpdateGraph(true)
		this.sigmaCanvasService.setShowDiscoverButton(true);
	}

	IsFullScreen() {
		return document.fullscreenEnabled && document.fullscreenElement !== null;
	}

	save() {
		this.saveClicked.next(true);
	}

	onNodeFilesTypeChanged(format: string) {
		this.showSaveButton = format === FILE_FORMATS.TXT_TYPE;
	}

	onNodeSave(data: any) {
		const node: gNode = { ...data.node };
		node.size = 20;
		node.type = SIGMA_CONSTANTS.NODE_DEFAULT_TYPE;
		this.isLoading = true;
		// Manda al backend el nodo a crear para crearlo en BD
		this.nodeService
			.createNode(this.idCurso, this.idMapa, node, data.image)
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe(
				(res) => {
					switch (res.type) {
						case HttpEventType.Response:
							let nodesFiles;
							if (this.node.nodesFiles) {
								nodesFiles = { ...this.node.nodesFiles };
							} else {
								nodesFiles = { idNodeFile: res.body.data.idNodesFile };
							}

							this.node = { ...res.body.data, nodesFiles };
							this.nodeService.currentNode.next(this.node);

							this.newNode.next(this.node);
							this.toaster.success(
								this.translateService.instant("NODEFORMCOMPONENT.SAVED")
							);

							break;
					}
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("NODEFORMCOMPONENT.ERROR")
					);
				}
			);
	}

	//RETURN OUTPUT COMPONENT FUNCTION
	onNodeFileAdded({ type, content, operators, showResult }) {
		if (type === FILE_FORMATS.TXT_TYPE) {
			this.uploadText(type, content, operators);
		} else {
			this.uploadFile(type, content, operators, showResult);
		}
	}

	private uploadFile(
		type: string,
		file: File,
		operatorIcons: ModelIcono[],
		showResult: boolean = true
	) {
		this.isLoading = true;
		this.nodeService
			.postNodeFile(
				this.node.nodesFiles.idNodeFile,
				type + "Add",
				operatorIcons,
				file
			)
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe(
				(event: HttpEvent<any>) => {
					let progressVal: number = 0;

					switch (event.type) {
						case HttpEventType.UploadProgress:
							progressVal = Math.round((event.loaded / event.total) * 100);
							this.nodeService.currentUploadProgress.next({
								value: progressVal,
								type: type,
							});
							break;
						case HttpEventType.Response:
							const node = { ...this.node };
							if (!node.nodesFiles) {
								node.nodesFiles = {};
							}

							switch (type) {
								case FILE_FORMATS.AUDIO_TYPE:
									node.nodesFiles.audioFile = event.body.data.fileName;
									break;
								case FILE_FORMATS.IMAGE_TYPE:
									node.nodesFiles.pictureFile = event.body.data.fileName;
									break;
								case FILE_FORMATS.PDF_TYPE:
									node.nodesFiles.pdfFile = event.body.data.fileName;
									break;
								case FILE_FORMATS.VIDEO_TYPE:
									node.nodesFiles.videoFile = event.body.data.fileName;
									break;
								case FILE_FORMATS.VIDEO_PREVIEW_TYPE:
									node.nodesFiles.videoImage = event.body.data.fileName;
									break;
							}

							this.node = { ...node };
							this.nodeService.currentNode.next(this.node);

							if (showResult)
								this.toaster.success(
									this.translateService.instant("NODEFORMCOMPONENT.FILESAVED")
								);

							this.nodeService.currentUploadProgress.next({
								value: 0,
								type: type,
							});
					}
				},
				(err) => {
					if (showResult)
						this.toaster.error(
							this.translateService.instant("NODEFORMCOMPONENT.FILEERROR")
						);
					this.nodeService.currentUploadProgress.next({ value: 0, type: type });
				}
			);
	}

	private uploadText(type: string, text: string, operatorIcons: ModelIcono[]) {
		this.isLoading = true;
		this.nodeService
			.postNodeFile(
				this.node.nodesFiles.idNodeFile,
				type + "Add",
				operatorIcons,
				undefined,
				text
			)
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe(
				(event) => {
					switch (event.type) {
						case HttpEventType.Response:
							const node = { ...this.node };
							if (!node.nodesFiles) node.nodesFiles = {};

							node.nodesFiles.txtFile = text;
							this.node = { ...node };
							this.nodeService.currentNode.next(this.node);
							this.toaster.success(
								this.translateService.instant("NODEFORMCOMPONENT.FILESAVED")
							);
							break;
					}
				},
				(err) =>
					this.toaster.error(
						this.translateService.instant("NODEFORMCOMPONENT.FILEERROR")
					)
			);
	}

	next() {
		this.action = "next";
		//if(this.nodeView == true )
		if (this.viewQuiz && !this.answered) {
			const dialogRef = this.dialogService.openAcceptDialog(
				true,
				undefined,
				this.translateService.instant("QUIZZES.CLOSEQUESTION"),
				[],
				this.translateService.instant("QUIZZES.EXIT"),
				this.translateService.instant("QUIZZES.RESPONSE")
			);

			dialogRef.afterClosed().subscribe((result) => {
				this.tryClose.next(result);

				if (result) {
					this.viewTrue();
				} else {
					this.tryClose.next(false);
				}
			});
		} else {
			this.viewTrue();
		}
	}

	viewTrue() {
		if (!this.viewQuiz) {
			this.posArrayNodo++;
			this.getNodo();
		} else {
			this.backList = false;
			if (this.soloQuiz) {
				this.answered = false;
				this.posArrayQuiz++;
				// this.quizRespondido() === true? this.getQuiz(): ''; //Pendiente
				this.disabledButton = false;
				this.getQuiz();
			} else if (this.soloNodos) {
				this.posArrayNodo++;
				this.getNodo();
			} else if (this.autoTodo) {
				this.answered = false;
				this.getCursoCompleto();
			}
		}
	}

	getCursoCompleto() {
		//Obtenemos el idGroup

		this.selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);
		this.idGroup = this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0;

		if (this.posArrayQuiz === undefined) this.posArrayQuiz = 0;

		//let quiz = this.listQuiz[this.posArrayNodo].quiz[this.posArrayQuiz];
		this.quizService
			.getNextQuizSemiAuto(
				this.idCurso,
				this.idMapa,
				this.quiz.idQuizFather === 0
					? this.quiz.idQuiz
					: this.quiz.idQuizFather,
				this.idGroup,
				this.action
			)
			.subscribe((res) => {
				this.quiz = res.data;
				if (this.quiz !== null) {
					this.viewQuiz = false;
					this.isLoading = true;

					if (this.quiz.isMultiplexed === 0) {
						//se obtiene el detalle de un quiz simple para ejecutarlo
						this.quizService
							.getQuizSimpleCanvasQuizSimple(
								this.quiz.idOriginal,
								this.idCurso,
								this.idMapa
							)
							.pipe(finalize(() => (this.isLoading = false)))
							.subscribe((res) => {
								this.quiz = res.quiz;
								this.elements = res.elements;
								this.viewQuiz = true;
								this.posArrayQuiz++;
								this._checkPadLabelStatus();
							});
					} else {
						//se obtiene el quiz hijo de un quiz multiple para ejecutarlo
						this.quizService
							.getQuizMultipleCanvasQuizMultiple(
								this.quiz.idOriginal,
								this.idCurso,
								this.idMapa
							)
							.pipe(finalize(() => (this.isLoading = false)))
							.subscribe((res) => {
								this.quiz = res.quiz;
								this.elements = res.elements;
								this.viewQuiz = true;
								this.posArrayQuiz++;
								this._checkPadLabelStatus();
							});
					}
				} else {
					this.closeModal("");

				}
			});
	}

	getNodo() {
		//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
		if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
			this.llamadoAsistencia();
		};
		this.isLoading = true;
		let nodo = this.nodos[this.posArrayNodo];
		this.viewQuiz = true;

		if (nodo !== undefined) {
			this.nodeService
				.getNode(nodo.idOriginal, this.idCurso, this.idMapa)
				.pipe(
					map((res: any) => res.data[0]),
					finalize(() => (this.isLoading = false))
				)
				.subscribe((res) => {
					this.node = res;
					this.viewQuiz = false;
					this._checkPadLabelStatus();
				});
		} else {
			//this.toaster.success(this.translateService.instant('CURSOMODOAUTO.CURSOTERMINADO'));
			this.router.navigateByUrl(`/course/${this.idCurso}/graph/${this.idMapa}`);
			this.activeModal.close("Abrir mapa");
			this.modalService.dismissAll();
			window.location.reload();
		}
	}

	getQuiz() {
		//Obtenemos el idGroup
		this.selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);
		this.idGroup = this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0;
		//let quiz = this.listQuiz[this.posArrayQuiz];
		this.quizService
			.getNextQuizSemiAuto(
				this.idCurso,
				this.idMapa,
				this.quiz.idQuiz,
				this.idGroup,
				this.action
			)
			.subscribe((res) => {
				this.quiz = res.data;

				if (this.quiz !== undefined) {
					//this.viewQuiz = false;
					this.isLoading = true;
					this.quiz = "";

					if (this.quiz.isMultiplexed === 0) {
						//se obtiene el detalle de un quiz simple para ejecutarlo
						this.quizService
							.getQuizSimpleCanvasQuizSimple(
								this.quiz.idOriginal,
								this.idCurso,
								this.idMapa
							)
							.pipe(finalize(() => (this.isLoading = false)))
							.subscribe((res) => {
								this.quiz = res.quiz;
								this.elements = res.elements;
								this.viewQuiz = true;
								this._checkPadLabelStatus();
								// this.posArrayQuiz++;
							});
					} else {
						//se obtiene el quiz hijo de un quiz multiple para ejecutarlo
						this.quizService
							.getQuizMultipleCanvasQuizMultiple(
								this.quiz.idOriginal,
								this.idCurso,
								this.idMapa
							)
							.pipe(finalize(() => (this.isLoading = false)))
							.subscribe((res) => {
								this.quiz = res.quiz;
								this.elements = res.elements;
								this.viewQuiz = true;
								this._checkPadLabelStatus();
								// this.posArrayQuiz++;
							});
					}
				} else if (!this.backList) {
					//this.toaster.success(this.translateService.instant('CURSOMODOAUTO.CURSOTERMINADO'));
					this.closeModal("");
				} else if (this.backList) {
					this.toaster.error(
						this.translateService.instant("Ha salido del modo automatico")
					);
					this.closeModal("");
				}
			});
	}

	back() {
		this.action = "back";
		if (this.viewQuiz && !this.answered) {
			const dialogRef = this.dialogService.openAcceptDialog(
				true,
				undefined,
				this.translateService.instant("QUIZZES.CLOSEQUESTION"),
				[],
				this.translateService.instant("QUIZZES.EXIT"),
				this.translateService.instant("QUIZZES.RESPONSE")
			);

			dialogRef.afterClosed().subscribe((result) => {
				this.tryClose.next(result);

				if (result) {
					this.viewTrueBack();
				} else {
					this.tryClose.next(false);
				}
			});
		} else {
			this.viewTrueBack();
		}
	}
	viewTrueBack() {
		this.backList = true;
		if (!this.viewQuiz) {
			this.posArrayNodo--;
			this.getNodo();
		} else {
			if (this.soloQuiz) {
				if (this.backList) this.posArrayQuiz--;
				this.getQuiz();
			} else if (this.soloNodos) {
				this.posArrayNodo--;
				this.getNodo();
			} else if (this.autoTodo) {
				//this.getBackCursoCompleto();
				this.answered = false;
				this.getBackCursoCompleto_v2();
			}
		}
	}
	getBackCursoCompleto_v2() {
		//Obtenemos el idGroup
		this.selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);
		this.idGroup = this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0;
		if (this.posArrayQuiz === undefined) {
			this.posArrayQuiz = 0;
		}
		//let quiz = this.listQuiz[this.posArrayNodo].quiz[this.posArrayQuiz];
		this.quizService
			.getNextQuizSemiAuto(
				this.idCurso,
				this.idMapa,
				this.quiz.idQuiz,
				this.idGroup,
				this.action
			)
			.subscribe((res) => {
				this.quiz = res.data;
				if (this.quiz !== null) {
					this.viewQuiz = false;
					this.isLoading = true;
					if (this.quiz.nodeType === "Quiz") {
						if (this.quiz.isMultiplexed === 0) {
							//se obtiene el detalle de un quiz simple para ejecutarlo
							this.quizService
								.getQuizSimpleCanvasQuizSimple(
									this.quiz.idOriginal,
									this.idCurso,
									this.idMapa
								)
								.pipe(finalize(() => (this.isLoading = false)))
								.subscribe((res) => {
									this.quiz = res.quiz;
									this.elements = res.elements;
									this.viewQuiz = true;
									this.posArrayQuiz++;
									this._checkPadLabelStatus();
								});
						} else {
							//se obtiene el quiz hijo de un quiz multiple para ejecutarlo
							this.quizService
								.getQuizMultipleCanvasQuizMultiple(
									this.quiz.idOriginal,
									this.idCurso,
									this.idMapa
								)
								.pipe(finalize(() => (this.isLoading = false)))
								.subscribe((res) => {
									this.quiz = res.quiz;
									this.elements = res.elements;
									this.viewQuiz = true;
									this.posArrayQuiz++;
									this._checkPadLabelStatus();
								});
						}
					} else {
						//this.toaster.success(this.translateService.instant('CURSOMODOAUTO.CURSOTERMINADO'));
						this.closeModal("");
					}
				} else {
					//this.toaster.success(this.translateService.instant('CURSOMODOAUTO.CURSOTERMINADO'));
					this.closeModal("");
				}
			});
	}
	getBackCursoCompleto() {
		if (this.viewQuiz) {
			this.posArrayQuiz = this.listQuiz[this.posArrayNodo].quiz.findIndex(
				(quiz) => quiz.idOriginal === this.quiz.idQuiz
			);
			if (this.posArrayQuiz === -1) {
				//Si es el primer quiz del nodo actual, ir al nodo.
				this.mostrarNodo();
			} else {
				if (this.posArrayQuiz === 0) {
					//Si es el primer quiz del nodo actual, ir al nodo.
					this.mostrarNodo();
				} else {
					this.posArrayQuiz--;
					let quiz = this.listQuiz[this.posArrayNodo].quiz[this.posArrayQuiz];

					if (quiz != undefined) {
						this.viewQuiz = false;
						this.isLoading = true;
						if (quiz.isMultiplexed === 0) {
							//se obtiene el detalle de un quiz simple para ejecutarlo
							this.quizService
								.getQuizSimpleCanvasQuizSimple(
									quiz.idOriginal,
									this.idCurso,
									this.idMapa
								)
								.pipe(finalize(() => (this.isLoading = false)))
								.subscribe((res) => {
									this.quiz = res.quiz;
									this.elements = res.elements;
									this.viewQuiz = true;
									this.posArrayQuiz++;
									this._checkPadLabelStatus();
								});
						} else {
							//se obtiene el quiz hijo de un quiz multiple para ejecutarlo
							this.quizService
								.getQuizMultipleCanvasQuizMultiple(
									quiz.idOriginal,
									this.idCurso,
									this.idMapa
								)
								.pipe(finalize(() => (this.isLoading = false)))
								.subscribe((res) => {
									this.quiz = res.quiz;
									this.elements = res.elements;
									this.viewQuiz = true;
									this._checkPadLabelStatus();
								});
						}
					}
				}
			}
		} else {
			if (this.posArrayNodo === 0 || this.posArrayNodo < 0) {
				this.posArrayNodo = 0;
				this.toaster.error(
					this.translateService.instant("CURSOMODOAUTO.SALIRMODOAUTO")
				);
				this.closeModal(""); //Si es el primer nodo, cerrar el modal
			} else {
				//Ir al ultimo quiz del nodo anterior si existe
				this.posArrayNodo--;
				if (this.listQuiz[this.posArrayNodo].quiz.length > 0) {
					//Comprobamos si el nodo anterior tiene quiz.
					let quiz =
						this.listQuiz[this.posArrayNodo].quiz[
							this.listQuiz[this.posArrayNodo].quiz.length - 1
						]; //Ultimo quiz del nodo anterior
					if (quiz !== undefined) {
						this.isLoading = true;
						if (quiz.isMultiplexed === 0) {
							//se obtiene el detalle de un quiz simple para ejecutarlo
							this.quizService
								.getQuizSimpleCanvasQuizSimple(
									quiz.idOriginal,
									this.idCurso,
									this.idMapa
								)
								.pipe(finalize(() => (this.isLoading = false)))
								.subscribe((res) => {
									this.quiz = res.quiz;
									this.elements = res.elements;
									this.viewQuiz = true;
									this.posArrayQuiz++;
									this._checkPadLabelStatus();
								});
						} else {
							//se obtiene el quiz hijo de un quiz multiple para ejecutarlo
							this.quizService
								.getQuizMultipleCanvasQuizMultiple(
									quiz.idOriginal,
									this.idCurso,
									this.idMapa
								)
								.pipe(finalize(() => (this.isLoading = false)))
								.subscribe((res) => {
									this.quiz = res.quiz;
									this.elements = res.elements;
									this.viewQuiz = true;
									this.posArrayQuiz =
										this.listQuiz[this.posArrayNodo].quiz.length - 1;
									this._checkPadLabelStatus();
								});
						}
					}
				} else {
					//this.posArrayNodo--;
					this.mostrarNodo();
				}
			}
		}
	}

	mostrarNodo() {
		this.viewQuiz = false;
		let nodo = this.listQuiz[this.posArrayNodo];
		this.viewQuiz = true;
		if (nodo !== undefined) {
			this.nodeService
				.getNode(nodo.idOriginal, this.idCurso, this.idMapa)
				.pipe(
					map((res: any) => res.data[0]),
					finalize(() => (this.isLoading = false))
				)
				.subscribe((res) => {
					this.node = res;
					this.posArrayQuiz = 0;
					this.posArrayQuiz = 0;
					this.nextNodo = false;
					this.viewQuiz = false;
					this._checkPadLabelStatus();
				});
		} else {
			//this.toaster.success(this.translateService.instant('CURSOMODOAUTO.CURSOTERMINADO'));
			this.closeModal("");
		}
	}

	///Verifica que la pregunta se ha respondido para avanzar al siguiente
	quizRespondido() {
		if (this.answered) {
			return true; // Pregunta ya respondida
		}
		const dialogRef = this.dialogService.openAcceptDialog(
			true,
			undefined,
			this.translateService.instant("QUIZZES.CLOSEQUESTION"),
			[],
			this.translateService.instant("QUIZZES.EXIT"),
			this.translateService.instant("QUIZZES.RESPONSE")
		);
		dialogRef.afterClosed().subscribe((result) => {
			this.tryClose.next(result);

			if (result) {
				return true; //avanzar sin responder
			} else {
				return false; //Responder pregunta
			}
		});
	}

	showPads(value: boolean): void {
		this.isShowPads = value;
		this._savePadLabelStatus();
	}
	showLabels(value: boolean): void {
		this.isShowLabels = value;
		this._savePadLabelStatus();
	}

	getActionNodePad(value: string) {
		switch (value) {
			case ACTIONS.CLOSEMODAL:
				this.closeModal("");
				break;
			case ACTIONS.STAIRS:
				this.modalService.open(ModalRadarComponent, {
					scrollable: false,
					windowClass: MODAL_DIALOG_TYPES.W50,
				});
				break;
			case ACTIONS.HELP:
				break;
			case ACTIONS.ACTIVITIES:
				this.playQuizzesFromNode();
				break;
			case ACTIONS.CHALLENGE3:
				this.openChallenge3();
				break;
		}
	}

	openChallenge3() {
		//PRIMERO OBTENEMOS LA INFO DEL GRUPO
		let selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);
		if (selectedGroupInfo == null) {
			//SI NO TENEMOS NINGUN GRUPO SELECCIONADO ABRIMOS ALERT PARA QUE SELECCIONE UN GRUPO
			const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30,
			});
			modalRef.componentInstance.optionalTitle = this.translateService.instant(
				"ALERTCHALLENGENODE.SELECTGROUPTITLE"
			);
			modalRef.componentInstance.mensaje = this.translateService.instant(
				"ALERTCHALLENGENODE.SELECTGROUPMESSAGE"
			);

			modalRef.result.then((result: boolean) => {
				if (result) {
					this.MastersService.openGroups(Profiles.Teacher);
				}
			});
			this.closeNode();
		}
		//		else if(selectedGroupInfo.assignedProjects == 0) {
		//			//SI NO TENEMOS NINGUN PROYECTO O ESTUDIANTE ASIGNADO AL GRUPO ABRIMOS ALERT PARA QUE LOS AGREGUE
		//			const modalRef = this.modalService.open(ModalAceptarCancelarComponent,
		//				{
		//					scrollable: true,
		//					windowClass: MODAL_DIALOG_TYPES.W45
		//				});
		//			modalRef.componentInstance.optionalTitle = this.translateService.instant('ALERTCHALLENGENODE.ASSIGNPROJECTTITLE')
		//			modalRef.componentInstance.mensaje = this.translateService.instant('ALERTCHALLENGENODE.ASSIGNPROJECTMESSAGE')
		//
		//			modalRef.result.then((result: boolean) => {
		//				if(result){
		//					const modalRef = this.modalService.open(ModalProfileActionsComponent, {scrollable: false, windowClass: MODAL_DIALOG_TYPES.W80 } );
		//					modalRef.componentInstance.profile = this.loginService.getProfile()
		//					this.showUserAccount = false;
		//					this.destroy$.next(true);
		//				}
		//			});
		//			this.closeNode();
		//		}
		else if (!this.isIdeographInGroup) {
			//SI EL IDEOGRAFO NO PERTENECE AL GRUPO SELECCIONADO ABRIMOS ALERT PARA QUE LO ASIGNE
			const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30,
			});
			modalRef.componentInstance.optionalTitle = this.translateService.instant(
				"ALERTCHALLENGENODE.ASSIGNGRAPHTITLE"
			);
			modalRef.componentInstance.mensaje = this.translateService.instant(
				"ALERTCHALLENGENODE.ASSIGNGRAPHMESSAGE"
			);

			modalRef.result.then((result: boolean) => {
				if (result) {
					this.groupService
						.agregarTarget(selectedGroupInfo.idGroup, this.idCurso, this.idMapa)
						.subscribe(
							(res) => {
								this.toaster.success(
									this.translateService.instant("MODALLISTCOURSES.ADDEDTARGET")
								);
								this.projectInGroup();
								this._openChallenge();
							},
							(err) => console.log(err)
						);
				}
			});
		} else {
			this._openChallenge();
		}
	}

	private _openChallenge() {
		let selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);
		let modal: NgbModalRef;
		const idGroup = selectedGroupInfo.idGroup;
		this.groupService.sendMsgToRoomClients(idGroup,
			'act_about_to_start',
			idGroup
		).subscribe();
		const msgSubs = this.socketService.msgFromServer.subscribe((data) => {
			//Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
			const dataArray = data.split(":");
			const type = dataArray[0];
			const onlineUsers: number = parseInt(dataArray[1]);
			switch (type) {
				case SOCKETMESSAGES.USERSCOUNTER:
					modal = this.modalService.open(ModalSocketCoursesComponent, {
						scrollable: true,
						windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
					}); // Modal con el listado de cursos, para que el profesor pueda elegir los quizzes a realizar
					modal.componentInstance.group = selectedGroupInfo.group;
					modal.componentInstance.onlineUsers = onlineUsers;
					modal.componentInstance.fromNode = true;
					modal.componentInstance.idCurso = this.idCurso;
					modal.componentInstance.idGrafo = this.idMapa;
					modal.componentInstance.idNodo = this.node.idOriginal;
					modal.result
						.then(
							() => {},
							(err) => {}
						)
						.finally(() => {
							this._removeSocket(idGroup);
						});
					msgSubs.unsubscribe();
					break;
			}
		});
		this.subscriptions.push(msgSubs);
		this.socketService.createSocket(idGroup); //Creo el socket
		const openSocketSub = this.socketService.openSocket.subscribe((ev) => {
			//Cuando se cree el socket, procedemos a guardar la sesion en el servidor
			this.groupService.createGroupSession(idGroup, null, true).subscribe(
				(result) => {
					//Si se guarda correctamente la sesion, tenemos que abrir el modal con los cursos en modo lista
					this.socketService.idSession = result.data.idSession;
					openSocketSub.unsubscribe();
				},
				(err) => {
					modal.close();
				}
			);
		});
		this.subscriptions.push(openSocketSub);
		this.closeNode();
	}

	projectInGroup() {
		let selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);
		if (selectedGroupInfo != null) {
			const idProject = this.idCurso;
			const idGroup = selectedGroupInfo.idGroup;
			this.groupService
				.targetListAsignedToGroup(idGroup, "")
				.subscribe((res: any) => {
					this.cursosGrupo = res.data;
					//MIRAMOS EN EL LISTADO DE PROYECTOS SI EL ACTUAL ESTA DENTRO DEL LISTADO
					for (let index = 0; index < this.cursosGrupo.length; index++) {
						if (this.cursosGrupo[index].idCourse === idProject) {
							this.isProjectInGroup = true;
							break;
						}
					}
				});
		}
	}

	ideoGraphInGroup() {
		let selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);
		if (selectedGroupInfo != null) {
			const idProject = this.idCurso;
			const idGrafo = this.idMapa;
			const idGroup = selectedGroupInfo.idGroup;
			this.groupService
				.targetListAsignedToGroup(idGroup, "")
				.subscribe((res: any) => {
					this.cursosGrupo = res.data;
					//MIRAMOS EN EL LISTADO DE PROYECTOS SI EL ACTUAL ESTA DENTRO DEL LISTADO
					for (let index = 0; index < this.cursosGrupo.length; index++) {
						if (this.cursosGrupo[index].idCourse === idProject) {
							const element = this.cursosGrupo[index].targetList;
							for (let index2 = 0; index2 < element.length; index2++) {
								if (element[index2].idTarget === idGrafo) {
									this.isIdeographInGroup = true;
									break;
								}
							}
							if (this.ideoGraphInGroup) {
								break;
							}
						}
					}
				});
		}
	}

	private _removeSocket(idGroup) {
		this.socketService.removeSocket(); //Si ha habido error al guardar la sesión, tenemos que destruir el socket y cerrar el modal
		this.groupService.deleteGroupSession(idGroup).subscribe(); //Elimino la sesión de la bd
		this.subscriptions.forEach((s) => s.unsubscribe()); //Elimino las suscripciones a eventos
	}

	getActionQuizPad(value: string): void {
		switch (value) {
			case ACTIONS.CLOSE:
				this.closeModal("");
				break;
			case ACTIONS.HELP:
				break;
			case ACTIONS.CHECK:
				this.checkQuizSolution();
				break;
			case ACTIONS.IDEA:
				this.getNodeFromQuiz();
				break;
		}
	}

	getControllerAction(value: string): void {
		switch (value) {
			case ACTIONS.UP:
				this.up();
				break;
			case ACTIONS.DOWN:
				this.down();
				break;
			case ACTIONS.BACK:
				this.back();
				break;
			case ACTIONS.NEXT:
				this.next();
				break;
		}
	}

	up() {
		if (this.localPower < 4) {
			this.localPower++;
			let elements = this.getCurrentPowerNodes(this.localPower);
			if (elements.length) {
				this.viewQuiz = false;
				let nodo = elements[0];
				this.viewQuiz = true;
				if (nodo !== undefined) {
					this.nodeService
						.getNode(nodo.idOriginal, this.idCurso, this.idMapa)
						.pipe(
							map((res: any) => res.data[0]),
							finalize(() => (this.isLoading = false))
						)
						.subscribe((res) => {
							this.node = res;
							this.posArrayQuiz = 0;
							this.posArrayQuiz = 0;
							this.nextNodo = false;
							this.viewQuiz = false;
							this._checkPadLabelStatus();
						});
				}
			}
		}
	}

	down() {
		if (this.localPower > -4) {
			this.localPower--;
			let elements = this.getCurrentPowerNodes(this.localPower);
			if (elements.length) {
				this.viewQuiz = false;
				let nodo = elements[0];
				this.viewQuiz = true;
				if (nodo !== undefined) {
					this.nodeService
						.getNode(nodo.idOriginal, this.idCurso, this.idMapa)
						.pipe(
							map((res: any) => res.data[0]),
							finalize(() => (this.isLoading = false))
						)
						.subscribe((res) => {
							this.node = res;
							this.posArrayQuiz = 0;
							this.posArrayQuiz = 0;
							this.nextNodo = false;
							this.viewQuiz = false;
							this._checkPadLabelStatus();
						});
				}
			}
		}
	}

	private convertObjectToNumber(obj: any): number {
		let value = 0;
		if ("power0" in obj) value = obj.power0 ? 0 : null;
		else if ("power1" in obj) value = obj.power1 ? 1 : null;
		else if ("power2" in obj) value = obj.power2 ? 2 : null;
		else if ("power3" in obj) value = obj.power3 ? 3 : null;
		else if ("powerNegative1" in obj) value = obj.powerNegative1 ? -1 : null;
		else if ("powerNegative2" in obj) value = obj.powerNegative2 ? -2 : null;
		else if ("powerNegative3" in obj) value = obj.powerNegative3 ? -3 : null;

		return value;
	}

	private getCurrentPowerNodes(power: number): any[] {
		let values: any[] = [];
		switch (power) {
			case -3:
				values = this.allItems.explicacionesPowerMinus3;
				break;
			case -2:
				values = this.allItems.explicacionesPowerMinus2;
				break;
			case -1:
				values = this.allItems.explicacionesPowerMinus1;
				break;
			case 0:
				values = this.allItems.explicacionesPower0;
				break;
			case 1:
				values = this.allItems.explicacionesPower1;
				break;
			case 2:
				values = this.allItems.explicacionesPower2;
				break;
			case 3:
				values = this.allItems.explicacionesPower3;
				break;
		}
		return values;
	}

	/**
	 * Si estoy en un nodo, debo obtener los quizzes asociados a él, reemplazar el contenido de los inputs y navegar sólo por esos quizzes
	 */
	private playQuizzesFromNode(): void {
		if (!this.viewQuiz) {
			this.quizService
				.getQuizzesFromNode(this.node.idOriginal, this.idCurso, this.idMapa)
				.subscribe((r) => {
					if (r.quizzes.length) {
						this.type = SIGMA_CONSTANTS.QUIZ_TYPE.toLowerCase();
						this.listQuiz = [];
						r.quizzes.forEach((v) => {
							v.quiz.idOriginal = v.quiz.idQuiz;
							this.listQuiz.push(v.quiz);
						});

						this.node = "";
						this.quiz = "";
						this.viewQuiz = true;
						this.answered = false;
						this.soloQuiz = true;
						this.soloNodos = false;
						this.autoTodo = false;
						this.firstQuiz = 0;
						this.posArrayQuiz = 0;
						this.getQuiz();
					}
				});
		} else {
			//Show message
		}
	}

	/**
	 * Si estoy en un quiz, cierro modal y abro el nodo al que corresponda este quiz
	 */
	private getNodeFromQuiz(): void {
		if (this.viewQuiz) {
			let idQuiz: number = null;
			if ("idOriginal" in this.quiz) idQuiz = this.quiz.idOriginal;
			else idQuiz = this.quiz.idQuiz;

			this.quizService
				.getNodeFromQuiz(
					idQuiz,
					this.idCurso,
					this.idMapa,
					this.quiz.idMultiplexQuiz
				)
				.subscribe((r) => {
					if (r) {
						this.nodeService
							.getNode(r.idNode, this.idCurso, this.idMapa)
							.pipe(
								map((res: any) => res.data[0]),
								finalize(() => (this.isLoading = false))
							)
							.subscribe((res) => {
								this.node = res;
								this.quiz = "";
								this.viewQuiz = false;
								this._checkPadLabelStatus();
							});
					}
				});
		}
	}

	checkQuizSolution(): void {
		this.quizService.checkQuiz.next(true);
	}

	@HostListener("scroll")
	public asd(): void {
	}

	// @HostListener('document:mousewheel', ['$event'])
	// onDocumentMousewheelEvent(event) {

	// 	const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
	// 	const windowHeight = window.innerHeight;
	// 	const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);

	// 	if (scrollPosition > windowHeight) {
	// 		this.showArrow = true;
	// 		this.arrowIcon = 'north';
	// 	} else {
	// 		this.showArrow = false;
	// 		this.arrowIcon = 'south';
	// 	}

	// 	if (scrollPosition + windowHeight >= documentHeight) {
	// 		this.showArrow = true;
	// 		this.arrowIcon = 'south';
	// 	}
	// }

	scrollToTop() {
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}

	private _savePadLabelStatus(): void {
		if (this.viewQuiz) {
			this.utils.labelsStatus.showLabelsQuiz = this.labelStatus =
				this.isShowLabels;
			this.utils.padsStatus.showPadsQuiz = this.padStatus = this.isShowPads;
		} else {
			this.utils.labelsStatus.showLabelsNode = this.labelStatus =
				this.isShowLabels;
			this.utils.padsStatus.showPadsNode = this.padStatus = this.isShowPads;
		}
		this.utils.savePadsLabelsStatus(LOCALSTORAGESTRINGS.PADSLOCALCATEGORY);
		this.utils.savePadsLabelsStatus(LOCALSTORAGESTRINGS.LABELSLOCALCATEGORY);
	}

	selectButton(index: number, idQuizM: number) {
		this.isLoading = true;
		this.quiz = undefined;
		this.elements = undefined;
		this.selectedButtonIndex = index;
		this.quizService
			.getQuizMultipleCanvasQuizMultiple(idQuizM, this.idCurso, this.idMapa)
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe((res) => {
				this.quiz = res.quiz;
				this.elements = res.elements;
			});
	}

	//con esto verificamos si hay algun llamado a asistencia pendiente por responder
	llamadoAsistencia() {
		this.groupService
			.receiveCallAttendance(this.selectedGroupInfo.idGroup, 2)
			.subscribe((resp) => {
				if (resp.data != null && resp.data[0].status == 1) {
					const modalRef = this.modalService.open(
						ModalAlertAsistenciaComponent,
						{
							scrollable: true,
							windowClass: MODAL_DIALOG_TYPES.W30,
						}
					);
					modalRef.componentInstance.idGrupo = this.selectedGroupInfo.idGroup;
					modalRef.componentInstance.group = this.selectedGroupInfo.group;
					modalRef.componentInstance.fechaLlamada = resp.data[0].fecha;
				}
			});
	}
}
