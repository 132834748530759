import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';
import { Subscription } from 'rxjs';
import { QuizElementTypes } from 'src/app/core/models/quizzes/quiz-element-types.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { HypermediaTypes } from '../../../quiz-hypermedia/hypermedia-types.enum';
import { QuizHypermediaComponent } from '../../../quiz-hypermedia/quiz-hypermedia.component';

@Component({
    selector: 'app-quiz-edit-body-answer',
    templateUrl: './quiz-edit-body-answer.component.html',
    styleUrls: ['./quiz-edit-body-answer.component.scss']
})
export class QuizEditBodyAnswerComponent implements OnInit, OnChanges {
    @Input() answers: any[];
    @Output() saveAnswer: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteAnswer: EventEmitter<number> = new EventEmitter<number>();
		@Output() activarIA: EventEmitter<boolean> = new EventEmitter<boolean>();

    public answerForm: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder, private modalService: NgbModal) {
        this.answerForm = this.fb.group({
            answers: this.fb.array([])
        });
    }

    ngOnInit() {
        if (this.answersArray.controls.length <= 0) {
            this.addAnswer();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.answers && changes.answers.currentValue && changes.answers.currentValue.length > 0) {
            this.answersArray.clear();
            this.answers.forEach(q => { this.addAnswer(q); });
            this.addAnswer();
        }
    }

    public onTextBlur(index: number, onUpdate?:boolean, event?:any) {
        if(event){
            if (this.answersArray.controls[index].get('originalData').value !== event.target.value || onUpdate)
                this.answersArray.controls[index].get('data').setValue(event.target.value)
        }
        this.saveAnswer.emit({ element: { ...this.answersArray.controls[index].value } });
    }

    private addAnswer(answer?: any): void {
        const newAnswer: UntypedFormGroup = this.createAnswer(answer);
        this.answersArray.push(newAnswer);
    }

    onKeyUp(event: any, index: number) {
			const inputValue = event.target.value.trim();
			if (inputValue === "") {
				this.activarIA.emit(false); // Si el valor es una cadena vacía, desactiva la IA
			} else {
				this.activarIA.emit(true);  // Si hay texto, activa la IA
			}
			if (index === this.answersArray.length - 1) {
				this.addAnswer();
			}
		}


    public get answersArray(): UntypedFormArray {
        return this.answerForm.get('answers') as UntypedFormArray;
    }

    public get answersArrayData(): UntypedFormControl[] {
        return this.answersArray.controls.map(q => q.get('data') as UntypedFormControl);
    }

    public get answersArrayType(): UntypedFormControl[] {
        return this.answersArray.controls.map(q => q.get('type') as UntypedFormControl);
    }

    public get answersArrayWidth(): UntypedFormControl[] {
        return this.answersArray.controls.map(q => q.get('width') as UntypedFormControl);
    }

    public get answersArrayHeight(): UntypedFormControl[] {
        return this.answersArray.controls.map(q => q.get('height') as UntypedFormControl);
    }

    public get elementTypes() {
        return QuizElementTypes;
    }

    public onOpenHypermedia(event: MouseEvent, index: number) {
        event.stopPropagation();
        const currentControl = this.answersArray.controls[index];

        const modalRef = this.modalService.open(QuizHypermediaComponent,
            {
                scrollable: true,
                windowClass: MODAL_DIALOG_TYPES.W95
            }
        );

        modalRef.componentInstance.fileSelected.subscribe(data => {
            modalRef.close();

            currentControl.get('data').patchValue('');
            currentControl.get('type').patchValue(this.mapFileTypeToElementType(data.type));

            this.saveAnswer.emit({ element: currentControl.value, file: data.file });
        });
    }

    private mapFileTypeToElementType(fileType: HypermediaTypes): QuizElementTypes {
        switch (fileType) {
            case HypermediaTypes.IMAGE:
                return QuizElementTypes.A_PICTURES;
            case HypermediaTypes.VIDEO:
                return QuizElementTypes.A_VIDEOS;
            case HypermediaTypes.AUDIO:
                return QuizElementTypes.A_AUDIOS;
            case HypermediaTypes.PDF:
                return QuizElementTypes.A_PDFS;
            default:
                return QuizElementTypes.A_TEXTS;
        }
    }


    public onDeleteClick(index: number): void {
        const id: number = this.answersArray.controls[index].get('id').value;
        if (id) {
            this.deleteAnswer.emit(id);
            if (this.answersArray.controls.length === 2) {
                this.answersArray.removeAt(index);
            }
        }
        else
            this.answersArray.removeAt(index);
    }

    private createAnswer(answer?: any): UntypedFormGroup {
        return this.fb.group({
            id: answer ? answer.idQuizzesDataElements : '',
            auxId: this.answersArray.controls.length,
            data: answer ? answer.data : '',
            originalData: answer ? answer.data : '',
            type: answer ? answer.elementType : QuizElementTypes.A_TEXTS,
            order: answer ? answer.yPosition : Math.max.apply(null, this.answersArray.controls.map(c => c.get('order').value)) + 1,
            width: answer ? answer.xSize : 0,
            height: answer ? answer.ySize : 0
        });
    }

    public onResizeStop(event:IResizeEvent, index:number){

        //Set the width and the height of the image
        let width:number = event.size.width
        let height:number = event.size.height

        //Update the height and the width
        this.answersArray.controls[index].get('width').setValue(width)
        this.answersArray.controls[index].get('height').setValue(height)

        //Call to function to update the data in the backend
        this.onTextBlur(index, true)
    }

    trackBy(index, item) {
        return item.get('auxId').value;
    }
}
