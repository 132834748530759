<nb-card class="mb-0 col-12">
	<div class="closeModal">
		<nb-icon class="m-1 close-global" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-body>
		<app-loading [isVisible]="loading"></app-loading>

		<div class="d-flex flex-column h-100">
			<h2 class="bright-green-color">
				{{ "RECUPERARCONTRASENA.RECUPERARCONTRASENA" | translate }}
			</h2>

			<div class="" *ngIf="currentStep === '1'">
				<p class="text-alert">
					{{ "REGISTRO.INGRESEDATOS" | translate }}
				</p>
			</div>

			<!-- FIRST STEP-->
			<form [formGroup]="step1" (ngSubmit)="validateUser()" #Form1="ngForm" *ngIf="currentStep === '1'">
				<!-- NICK USER -->
				<div class="row mb-4">
					<label class="col-12 text-color-secondary" for="username">{{ 'LOGIN.ACCEDERNAME' | translate }}</label>
					<div class="col-12 mb-2">
						<input nbInput status="basic" fullWidth fieldSize="medium" shape="round" class="form-control-login" type="nick" formControlName="nick" placeholder="{{ 'REGISTRO.NICKEJEMPLO' | translate }}"/>
					</div>
				</div>

				<!-- SECURITY QUESTIONS -->
				<div class="row">
					<label class="col-12 text-color-secondary">{{ 'REGISTRO.QUESTION1' | translate }}</label>
					<div class="col-12 col-sm-6">
						<nb-select fullWidth fieldSize="medium" class="SelectGlobal" hero shape="round"	formControlName="idSecurityQuestions"	id="idSecurityQuestions">
							<nb-option class="SelectGlobalOption"	*ngFor="let question of securityQuestions" [value]="question.id">
								{{ question.question }}
							</nb-option>
						</nb-select>
						<div *ngFor="let validation of validationMessages.idSecurityQuestions">
							<div class="error-message" *ngIf="question1Control?.hasError(validation.type) && (question1Control?.dirty || question1Control?.touched || Form1.submitted)">
								{{ validation.message }}
							</div>
						</div>
					</div>

					<div class="col-12 col-sm-6 mt-4 mt-sm-0">
						<input nbInput shape="round"	fullWidth	fieldSize="medium" class="form-control-login" type="text" formControlName="securityAnswer" placeholder="{{ 'REGISTRO.MYANSWER1' | translate }}"/>
						<div *ngFor="let validation of validationMessages.securityAnswer">
							<div class="error-message" *ngIf="answer1Control?.hasError(validation.type) && (answer1Control?.dirty || answer1Control?.touched || Form1.submitted)">
								{{ validation.message }}
							</div>
						</div>
					</div>
				</div>

				<div class="row mt-4">
					<label class="col-12 text-color-secondary">{{ 'REGISTRO.QUESTION2' | translate }}</label>
					<div class="col-12 col-sm-6">
						<nb-select fullWidth fieldSize="medium" class="SelectGlobal" hero shape="round" formControlName="idSecurityQuestions2" id="idSecurityQuestions2">
							<nb-option class="SelectGlobalOption" *ngFor="let question of securityQuestions" [value]="question.id">
								{{ question.question }}
							</nb-option>
						</nb-select>
						<div *ngFor="let validation of validationMessages.idSecurityQuestions">
							<div class="error-message" *ngIf="question2Control?.hasError(validation.type) && (question2Control?.dirty || question2Control?.touched || Form1.submitted)">
								{{ validation.message }}
							</div>
						</div>
					</div>

					<div class="col-12 col-sm-6 mt-4 mt-sm-0">
						<input nbInput shape="round" fullWidth fieldSize="medium" class="form-control-login" type="text" formControlName="securityAnswer2" placeholder="{{ 'REGISTRO.MYANSWER2' | translate }}"/>
						<div *ngFor="let validation of validationMessages.securityAnswer2">
							<div class="error-message" *ngIf="answer2Control?.hasError(validation.type) && (answer2Control?.dirty || answer2Control?.touched || Form1.submitted)">
								{{ validation.message }}
							</div>
						</div>
					</div>
				</div>

				<!-- NEXT BUTTON -->
				<div class="row">
					<div class="col-12 py-5 d-flex justify-content-center">
						<button class="d-block w-100 button-primary" nbButton type="submit">
							<span>{{ "VALIDAR.VALIDARDATOS" | translate }}</span>
						</button>
					</div>
				</div>
			</form>
			<!-- END FIRST STEP-->

			<!-- SECOND STEP-->
			<form	[formGroup]="step2" (ngSubmit)="cambiarContrasena()" #Form2="ngForm" *ngIf="currentStep === '2'">
			<div class="row">
				<!-- PASSWORD -->
				<label class="col-12 mt-4 text-color-secondary">{{ 'USERDATA.CREATEPASSWORD' | translate }}</label>
				<div class="col-12 mt-2">
					<input nbInput shape="round" fullWidth fieldSize="medium" class="form-control-login" [type]="hidePassword ? 'password' : 'text'" formControlName="keyWord"/>
					<div *ngFor="let validation of validationMessages.keyword" class="mt-2">
						<div class="error-message" *ngIf="
								keywordControl?.hasError(validation.type) &&
								(keywordControl?.dirty ||
									keywordControl?.touched ||
									Form2.submitted)
							">
							{{ validation.message }}
						</div>
					</div>
					<button class="hide-button" type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-pressed]="hidePassword">
						<mat-icon>{{ hidePassword ? "visibility_off" : "visibility"	}}</mat-icon>
					</button>
				</div>
				<!-- REPEAT PASSWORD -->
				<label class="col-12 mt-4 text-color-secondary">{{ 'CAMBIARPASS.REPEATNEWPASS' | translate }}</label>
				<div class="col-12 mt-2">
					<input nbInput shape="round" fullWidth fieldSize="medium" class="form-control-login" [type]="hidePassword ? 'password' : 'text'"
					formControlName="repeatKeyWord" (input)="validateRepeatPassword()"/>
					<div *ngFor="let validation of validationMessages.repeatKeyWord" class="mt-2">
						<div class="error-message" *ngIf="
								repeatKeywordControl?.hasError(validation.type) &&
								(repeatKeywordControl?.dirty ||
									repeatKeywordControl?.touched ||
									Form2.submitted)
							">
							{{ validation.message }}
						</div>
					</div>
					<div class="error-message" *ngIf="!validaterepetpassw2">
						{{"CAMBIARPASS.NOREPEATED" | translate}}
					</div>
					<button class="hide-button" type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-pressed]="hidePassword">
						<mat-icon>{{	hidePassword ? "visibility_off" : "visibility" }}</mat-icon>
					</button>
				</div>
			</div>
			<!-- SUBMIT BUTTON-->
			<div class="row">
				<div class="col-12 py-5 d-flex justify-content-center">
					<button class="d-block w-100 button-primary" [disabled]="!validaterepetpassw2" nbButton type="submit">
						<span>{{ "CAMBIARPASS.CAMBIARPASS" | translate }}</span>
					</button>
				</div>
			</div>
		</form>
		<!-- END SECOND STEP-->

		</div>
	</nb-card-body>

</nb-card>

