import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
	ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WebcamInitError, WebcamImage, WebcamUtil } from "ngx-webcam";
import { Observable, Subject, timer } from "rxjs";

@Component({
	selector: "app-modal-microfono-audio",
	templateUrl: "./modal-microfono-audio.component.html",
	styleUrls: ["./modal-microfono-audio.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ModalMicrofonoAudioComponent implements OnInit, OnDestroy {
	mediaRecorder: any;
	@ViewChild("multiMediaContainer", { static: true })
	multiMediaContainer: ElementRef<HTMLElement>;

	chunks = [];
	audioFiles = [];

	_second = 1000;
	_minute = this._second * 60;
	_hour = this._minute * 60;
	_day = this._hour * 24;
	end: any;
	now: any;
	day: any;
	hours: any;
	minutes: any | 0 = "00";
	seconds: any | 0 = "00";
	source = timer(0, 1000);
	clock: any;
	grabando: boolean = false;
	files: File;

	soloAudio: boolean = false;
	soloVideo: boolean = false;
	soloFoto: boolean = false;
	videoFiles = [];
	videoRef: any;
	mediaStream: MediaStream;
	Recording: string = "../../../../../../../assets/images/Recording.gif";
	Stop: string = "../../../../../../../assets/images/stop.gif";
	microfonoDisponible: boolean = true;
	camaraDisponible: boolean = true;
	errorDispositivos: string = "";

	// toggle webcam on/off
	newPicture = true;
	public showWebcam = true;
	public allowCameraSwitch = true;
	public multipleWebcamsAvailable = false;
	public deviceId: string;
	mediaDevices: MediaDeviceInfo[];
	public videoOptions: MediaTrackConstraints = {
		// width: {ideal: 1024},
		// height: {ideal: 576}
	};
	public errors: WebcamInitError[] = [];

	// latest snapshot
	public webcamImage: WebcamImage = null;

	// webcam snapshot trigger
	private trigger: Subject<void> = new Subject<void>();
	// switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
	private nextWebcam: Subject<boolean | string> = new Subject<
		boolean | string
	>();
	imagen: any;

	constructor(
		private activeModal: NgbActiveModal,
		private dom: DomSanitizer,
		private cd: ChangeDetectorRef,
		private modalService: NgbModal
	) {}

	ngOnInit() {
		this.verificarDispositivos();
	}

	private verificarDispositivos() {
		navigator.mediaDevices
			.enumerateDevices()
			.then((devices) => {
				this.microfonoDisponible = devices.some((d) => d.kind === "audioinput");
				this.camaraDisponible = devices.some((d) => d.kind === "videoinput");
				this.cd.detectChanges();
			})
			.catch(() => {
				this.errorDispositivos = "Error al detectar dispositivos";
				this.cd.detectChanges();
			});
	}

	get requiredDevicesAvailable(): boolean {
		if (this.soloVideo)
			return this.camaraDisponible && this.microfonoDisponible;
		if (this.soloAudio) return this.microfonoDisponible;
		if (this.soloFoto) return this.camaraDisponible;
		return false;
	}

	// Método permisoMicro mejorado
	permisoMicro() {
		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then((stream) => this.configurarGrabadorAudio())
			.catch((error) => this.handleMediaError(error, "audio"));
	}

	// Método grabarVideo mejorado
	grabarVideo() {
		navigator.mediaDevices
			.getUserMedia({ audio: true, video: true })
			.then((stream) => this.configurarGrabadorVideo())
			.catch((error) => {
				this.handleMediaError(error, "video");
				this.grabando = false; // Reset en caso de error
			});
	}

	private handleMediaError(error: DOMException, type: "audio" | "video") {
		const deviceType = type === "audio" ? "micrófono" : "cámara";

		if (error.name === "NotAllowedError") {
			this.errorDispositivos = `Permiso denegado para el ${deviceType}`;
		} else if (error.name === "NotFoundError") {
			this.errorDispositivos = `No se encontró ${deviceType} disponible`;
		} else {
			this.errorDispositivos = `Error al acceder al ${deviceType}: ${error.message}`;
		}

		if (type === "audio") this.microfonoDisponible = false;
		if (type === "video") this.camaraDisponible = false;

		this.grabando = false; // Evitar que quede en estado incorrecto
		this.cd.detectChanges(); // Refrescar la vista
	}

	public handleInitError(error: WebcamInitError): void {
		if (error.mediaStreamError) {
			if (error.mediaStreamError.name === "NotAllowedError") {
				this.errorDispositivos = "Permiso de cámara denegado";
			} else if (error.mediaStreamError.name === "NotFoundError") {
				this.errorDispositivos = "Cámara no encontrada";
			} else {
				this.errorDispositivos = `Error de cámara: ${error.mediaStreamError.message}`;
			}
			this.camaraDisponible = false;
			this.cd.detectChanges();
		}
	}

	ngOnDestroy() {
		this.detenerTodosLosMedios();
		this.clock?.unsubscribe();
		this.reloadVid();
	}

	private detenerTodosLosMedios() {
		if (this.mediaStream) {
			this.mediaStream.getTracks().forEach((track) => {
				track.stop();
				track.enabled = false;
				track.dispatchEvent(new Event("ended")); // Forzar evento de finalización
			});
			this.mediaStream = null;
		}
	}

	private async configurarGrabadorAudio(): Promise<void> {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			this.mediaRecorder = new MediaRecorder(stream);
			this.mediaStream = stream;

			this.mediaRecorder.ondataavailable = (e) => {
				this.chunks.push(e.data);
			};

			this.mediaRecorder.onstop = (e) => {
				const blob = new Blob(this.chunks, { type: "audio/mp3; codecs=opus" });
				const id = Math.floor(Math.random() * 10000);
				this.files = new File([blob], `${id}-wav.mp3`, { type: "audio/mp3" });
				const audioURL = URL.createObjectURL(blob);
				this.audioFiles.push(this.dom.bypassSecurityTrustUrl(audioURL));
				this.chunks = [];
				this.detenerTodosLosMedios();
				this.cd.detectChanges();
			};
		} catch (error) {
			throw new Error(`Error configurando audio: ${error.message}`);
		}
	}

	private async configurarGrabadorVideo(): Promise<void> {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				audio: true,
				video: { width: 1280, height: 720 },
			});

			this.mediaRecorder = new MediaRecorder(stream, { mimeType: "video/mp4" });
			this.mediaStream = stream;
			this.videoRef.srcObject = this.mediaStream;
			this.chunks = [];

			this.mediaRecorder.ondataavailable = (e) => {
				if (e.data.size > 0) this.chunks.push(e.data);
			};

			this.mediaRecorder.onstop = () => {
				const blob = new Blob(this.chunks, { type: "video/mp4" });
				const id = Math.floor(Math.random() * 10000);
				this.files = new File([blob], `${id}-vid.mp4`, { type: "video/mp4" });
				const videoURL = URL.createObjectURL(blob);
				this.videoFiles.push(this.dom.bypassSecurityTrustUrl(videoURL));
				this.detenerTodosLosMedios();
				this.cd.detectChanges();
			};
		} catch (error) {
			throw new Error(`Error configurando video: ${error.message}`);
		}
	}

	// Método tomarFoto modificado
	tomarFoto() {
		if (!this.camaraDisponible) {
			this.errorDispositivos = "No se detectó una cámara disponible";
			return;
		}

		WebcamUtil.getAvailableVideoInputs().then(
			(mediaDevices: MediaDeviceInfo[]) => {
				this.multipleWebcamsAvailable = mediaDevices?.length > 0;
				this.mediaDevices = mediaDevices;

				if (!mediaDevices || mediaDevices.length === 0) {
					this.camaraDisponible = false;
					this.errorDispositivos = "No se encontraron cámaras disponibles";
				}
			}
		);
	}

	// Método urltoFile mejorado
	private urltoFile(
		dataUrl: string,
		filename: string,
		mimeType: string
	): Promise<File> {
		return fetch(dataUrl)
			.then((res) => res.arrayBuffer())
			.then((buf) => new File([buf], filename, { type: mimeType }));
	}

	// Método startRecording modificado
	startRecording() {
		if (!this.mediaRecorder || this.mediaRecorder.state === "recording") return;

		this.grabando = true;
		this.now = new Date();

		try {
			this.mediaRecorder.start();
			this.clock = this.source.subscribe(() => {
				this.end = new Date();
				this.showDate();
			});
		} catch (error) {
			console.error("Error al iniciar grabación:", error);
			this.grabando = false;
			this.clock?.unsubscribe();
		}
	}

	// Método stopRecording modificado
	stopRecording() {
		if (this.mediaRecorder?.state === "recording") {
			this.mediaRecorder.stop();
		}
		this.cleanupResources();
	}

	private cleanupResources() {
		this.grabando = false;
		this.clock?.unsubscribe();

		if (this.mediaStream) {
			this.mediaStream.getTracks().forEach((track) => track.stop());
		}

		this.chunks = [];
		this.mediaRecorder = null;
	}

	ngAfterViewInit() {
		this.chunks = [];
		this.videoFiles = [];

		if (this.soloAudio) {
			this.permisoMicro();
		} else if (this.soloVideo) {
			this.videoRef = document.getElementById("videoRef");
			this.videoRef.muted = true;
		} else if (this.soloFoto) {
			this.tomarFoto();
		}
	}

	showDate() {
		let distance = this.end - this.now;
		this.day = Math.floor(distance / this._day);
		this.hours = Math.floor((distance % this._day) / this._hour);
		this.minutes = Math.floor((distance % this._hour) / this._minute);

		this.seconds = Math.floor((distance % this._minute) / this._second);
		if (parseInt(this.minutes) < 10) {
			this.minutes = "0" + this.minutes;
		}
		if (parseInt(this.seconds) < 10) {
			this.seconds = "0" + this.seconds;
		}
	}

	aceptar() {
		this.activeModal.close({ files: this.files, videoUrl: this.videoFiles });
	}
	salir() {
		this.activeModal.close();
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	capturePhoto() {
		this.mediaRecorder.pause();
	}

	async startCamera() {
		try {
			// Reinicializar el MediaRecorder si es necesario
			if (!this.mediaRecorder || this.mediaRecorder.state === "inactive") {
				await this.initializeRecorder();
			}

			// Verificar si ya está grabando
			if (this.mediaRecorder?.state === "recording") {
				console.warn("La grabación ya está en curso");
				return;
			}

			// Validar existencia del mediaRecorder
			if (!this.mediaRecorder) {
				throw new Error("MediaRecorder no inicializado");
			}

			this.grabando = true;
			this.now = new Date();

			// Iniciar temporizador primero
			this.clock = this.source.subscribe(() => {
				this.end = new Date();
				this.showDate();
			});

			// Iniciar grabación
			this.mediaRecorder.start();
		} catch (error) {
			console.error("Error al iniciar la grabación:", error);
			this.handleRecordingError(error);
		}
	}

	private async initializeRecorder(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				if (this.soloVideo) {
					await this.configurarGrabadorVideo();
				} else if (this.soloAudio) {
					await this.configurarGrabadorAudio();
				}
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	private handleRecordingError(error: any): void {
		this.grabando = false;
		this.clock?.unsubscribe();

		// Mensajes específicos según tipo de error
		if (error.name === "NotFoundError") {
			this.errorDispositivos = "Dispositivo no encontrado";
		} else if (error.name === "NotAllowedError") {
			this.errorDispositivos = "Permiso denegado";
		} else {
			this.errorDispositivos = `Error técnico: ${error.message}`;
		}

		this.detenerTodosLosMedios();
		this.cd.detectChanges();
	}

	stopCamera() {
		this.grabando = false;
		this.clock.unsubscribe();
		setTimeout(() => {
			this.end = new Date();
			this.mediaRecorder.stop();
		}, 300);
	}

	finalizarTrans() {
		this.mediaRecorder.stop();
		this.mediaStream.getVideoTracks()[0].stop();
	}

	async reloadVid() {
		try {
			// 1. Detener grabación de forma segura
			if (this.mediaRecorder?.state === "recording") {
				await new Promise((resolve) => {
					this.mediaRecorder.onstop = resolve;
					this.mediaRecorder.stop();
				});
			}

			// 2. Liberar recursos multimedia
			this.detenerTodosLosMedios();

			// 3. Limpiar elemento de video
			if (this.videoRef) {
				this.videoRef.srcObject = null;
				this.videoRef.removeAttribute("src");
				this.videoRef.load();
			}

			// 4. Cerrar modal de forma asíncrona
			setTimeout(() => {
				if (this.activeModal) {
					this.activeModal.close({ reload: true });
				}
			}, 100);

			// 5. Limpieza final diferida
			setTimeout(() => {
				this.chunks = [];
				this.videoFiles = [];
				this.audioFiles = [];
				this.files = null;
				this.grabando = false;
				this.mediaRecorder = null;
				this.mediaStream = null;
				this.cd.detectChanges();
			}, 150);
		} catch (error) {
			console.error("Error al recargar video:", error);
			this.errorDispositivos = "Error al reiniciar la grabación";
			this.cd.detectChanges();
		}
	}

	public triggerSnapshot(): void {
		this.trigger.next();
	}

	public toggleWebcam(): void {
		this.showWebcam = !this.showWebcam;
	}

	public showNextWebcam(directionOrDeviceId: boolean | string): void {
		// true => move forward through devices
		// false => move backwards through devices
		// string => move to device with given deviceId
		this.nextWebcam.next(directionOrDeviceId);
	}

	public handleImage(webcamImage: WebcamImage): void {
		this.webcamImage = webcamImage;
		this.newPicture = false;
		this.toggleWebcam();
	}

	public cameraWasSwitched(deviceId: string): void {
		this.deviceId = deviceId;
	}

	public get triggerObservable(): Observable<void> {
		return this.trigger.asObservable();
	}

	public get nextWebcamObservable(): Observable<boolean | string> {
		return this.nextWebcam.asObservable();
	}

	savePicture() {
		const id = Math.floor(Math.random() * 10000);
		let nameFilePic = `${id}-pic.jpeg`;
		//Usage example:
		this.urltoFile(
			this.webcamImage.imageAsDataUrl,
			nameFilePic,
			"image/jpeg"
		).then((file) => {
			this.activeModal.close({ file, webcamImage: this.webcamImage });
		});
	}

	newFoto(webcamImage) {
		this.newPicture = true;
		this.webcamImage = null;
		this.toggleWebcam();
	}
}
