<div class="modal-header" align="center">
    <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'NODOS.CONECTARNODO' | translate }}</span>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body fondoEditarCurso" align="center">
    <div class="form-group col">
        <p>{{ 'SIGMACOMPONENT.CONNECTQUESTION' | translate }}</p>
        <div class="col-lg-12 col-md-12" style="display: flex; align-items: center;">
            <div style="text-align: center; padding-right: 20px;">
                <div><img data-toggle="tooltip" data-placement="top" title="{{connectN1.label}}" src="{{connectN1.url}}"
                         alt="Avatar" class="avatar"> </div>
            </div>
            <div>
                <button mat-raised-button (click)="changeConnectOrderItems()" mat-icon-button data-toggle="tooltip"
                        data-placement="top" title="">
                    <mat-icon class="mat" color="primary">sync_alt</mat-icon>
                </button>
            </div>
            <div style="text-align: center; padding-left: 20px;">
                <div><img data-toggle="tooltip" data-placement="top" title="{{connectN2.label}}" src="{{connectN2.url}}"
                         alt="Avatar" class="avatar"></div>
            </div>
        </div>
    </div>
    <div class="form-group col">
        <div class="form-group row">
            <label class="col-lg-2 col-form-label">{{'NODOS.TIPODELINEA'|translate }}</label>
            <div class="col-lg-10">
                <select id="linea" class="form-control" required [(ngModel)]="line">
                    <option value="0">{{'NODOS.SELECCIONAOPCION'|translate }}</option>
                    <option *ngFor="let line of lines" [value]="line">{{line}}</option>
                </select>
            </div>
        </div>
        <div *ngIf="line != '0'">
            <div class="form-group row">
                <label class="col-lg-2 col-form-label">{{'NODOS.TEXTO'|translate }}</label>
                <div class="col-lg-10">
                    <input type="text" class="form-control" required [(ngModel)]="label">
                </div>
            </div>
            <div *ngIf="line != 'branch'" class="form-group row">
                <label class="col-lg-2 col-form-label">{{'NODOS.TAMAÑO'|translate }}</label>
                <div class="col-lg-4">
                    <input type="number" class="form-control" [(ngModel)]="size" min="0" max="10">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-2 col-form-label">{{'NODOS.COLOR'|translate }}</label>
                <div class="col-lg-2">
                    <input [style.background]="color" [cpOKButton]="false" [cpSaveClickOutside]="true"
                           [cpOKButtonClass]="'btn btn-primary btn-xs'" [(colorPicker)]="color" readonly="readonly" />
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-6 col-md-6 mt-2">
                <button type="button" class="btn btn-secondary btn-block"
                        (click)="Cancelar()">{{'NODOS.CANCELAR'|translate }}</button>
            </div>
            <div class="col-lg-6 col-md-6 mt-2">
                <button type="button" class="btn btn-primary btn-block"
                        (click)="Conectar()">{{'NODOS.CONECTAR'|translate }}</button>
            </div>
        </div>
    </div>
</div>
