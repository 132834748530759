<nb-card class="no-edge-card mb-0 background_themecolor modal-height-100-62" (click)="closeFiltersPanel()">
	<app-loading [isVisible]="cargando"></app-loading>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-header class="px-3 py-0 flex flex-column border-bottom-0">
		<!-- Titulo de la pantalla -->
		<div class="row p-4 background_white">
			<div class="col-12 col-md-7 col-lg-8 modal-title px-2">
				<h4>{{'CURSOS.TITLE' | translate}}</h4>
			</div>
		</div>
		<!-- FILTROS -->
		<div class="row px-3 py-2 mt-3">
			<div class="col-12 col-xl-8">
				<div class="d-flex align-items-center gap-4">
					<!-- Select para ordenar los proyectos -->
					<div class="d-flex flex-column align-items-center">
						<nb-select name="lang1" class="SelectForm" [(selected)]="selectedOrder" placeholder="small" hero
							size="small" (selectedChange)="changeOrder($event)">
							<nb-option class="SelectFormOpt" *ngFor="let order of orderList" [value]="order.idOrder">
								{{ order.nombre}}
							</nb-option>
						</nb-select>
					</div>
					<nb-actions size="small">
						<!-- Historial de proyectos -->
						<nb-action icon="clock-outline" [ngClass]="{'selected-icons': historyFilterActive}" nbTooltip="Historial"
						nbTooltipPlacement="top" (click)="historyFilter()">
						</nb-action>
						<!-- Icono que abre los filtros -->
						<nb-action icon="funnel-outline" [ngClass]="{'selected-icons': iconSelected}" [nbPopover]="filtersTemplate"
							nbPopoverPlacement="bottom" nbTooltip="Filtros" nbTooltipPlacement="top" nbPopoverTrigger="noop"
							(click)="openFiltersPanel()">
						</nb-action>
						<!-- panel que contiene los filtros -->
						<ng-template #filtersTemplate>
							<nb-card class="m-0 background_themecolor filtersTemplate">
								<nb-card-header class="border-bottom-0">
									<p>{{'FILTER.FILTER' | translate}}</p>
									<div class="d-flex justify-content-center">
										<button nbButton size="small" class="btn-themecolor" type="button"
											(click)="clearFilters()">
											<span>{{'FILTER.CLEARFILTER' | translate}}</span>
										</button>
									</div>
								</nb-card-header>
								<nb-card-body class="container-filters">
									<div class="column-filters">
										<!-- Idioma -->
										<nb-select #selectLang name="lang1" class="SelectForm" hero size="small"
											placeholder="{{'FILTER.LANGUAGE' | translate}}" [(selected)]="selectedLang"
											(selectedChange)="applyFilters($event,1)">
											<nb-option class="SelectFormOpt" *ngFor="let lang of langList" [value]="lang.idLang">
												{{lang.nombre }}
											</nb-option>
										</nb-select>
										<!-- Asignatura -->
										<nb-select #selectSubject name="subject" class="SelectForm" hero size="small"
											placeholder="{{'FILTER.SUBJECT' | translate}}" [(selected)]="selectedSubject"
											(selectedChange)="applyFilters($event,2)">
											<nb-option class="SelectFormOpt" *ngFor="let subject of subjectsList"
												[value]="subject.idSubject">{{ subject.subject }}</nb-option>
										</nb-select>
										<!-- Pais -->
										<nb-select #selectCountry name="idCountry" class="SelectForm" hero size="small"
											[(selected)]="selectedCountry" placeholder="{{'FORM.SPAIN' | translate}}"
											(selectedChange)="searchLevels($event);applyFilters($event,3);">
											<nb-option class="SelectFormOpt" *ngFor="let country of countriesList"
												value="{{ country.idCountry }}">{{ country.country_es }}</nb-option>
										</nb-select>
										<!-- Nivel escolar -->
										<nb-select name="idCountryLevel" class="SelectForm" hero size="small"
											[(selected)]="selectedLevelCountry"
											[placeholder]="(!filterCountrySelected ? 'FILTER.SELECTED_COUNTRY' : 'FILTER.LEVEL') | translate"
											[disabled]="!filterCountrySelected" (selectedChange)="applyFilters($event,4)">
											<nb-option class="SelectFormOpt" *ngFor="let level of levelsOfCountry"
												[value]="level.idCountryLevel">
												{{ level.countryLevel }}
											</nb-option>
											<nb-option class="SelectFormOpt" *ngIf="notCountryLevelsForThisCountry">
												{{'MODALINFORMATIONCOURSE.NOTLEVELSFORCOUNTRY' | translate}}
											</nb-option>
										</nb-select>
										<!-- Proyectos transversales 
										<nb-toggle labelPosition="end" class="item-profile" id="mycourses" [(ngModel)]="trasversalProjects"
											(change)="applyFilters($event, 5)">
											{{'CURSOS.TRANSVERSAL' | translate}}
										</nb-toggle>
										-->
									</div>
								</nb-card-body>
							</nb-card>
						</ng-template>
						<!-- Boton para cambiar al modo grilla -->
						<nb-action *ngIf="modeList" icon="grid-outline" nbTooltip="{{ 'CURSOS.VERMOSAICO' | translate }}"
						nbTooltipPlacement="top" (click)="changeView(false)">
						</nb-action>
						<!-- Boton para cambiar al modo lista -->
						<nb-action *ngIf="!modeList" icon="list-outline" nbTooltip="{{ 'CURSOS.VERARBOL' | translate }}"
						nbTooltipPlacement="top" (click)="changeView(true)">
						</nb-action>
					</nb-actions>
					<div class="d-flex gap-4 w-100">
						<!-- Todos los proyectos -->
						<div *ngFor="let projectType of projectTypeList; let i= index" class="d-flex align-items-center flex-column gap-1 filter-buttons"
							nbTooltip="{{projectType.descripcion}}" nbTooltipPlacement="top">
							<div>
								<button (click)="applyFilters(i, 0)" nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center">
									<mat-icon svgIcon="ProyectoComoMuchosGrafos" *ngIf="projectType.id == null"></mat-icon>
									<mat-icon svgIcon="ProyectoComoMuchosGrafos" *ngIf="projectType.id == 1"></mat-icon>
									<mat-icon class="custom-icon_green" svgIcon="ProyectoComoMuchosGrafos" *ngIf="projectType.id == 2"></mat-icon>
									<mat-icon class="custom-icon_red" svgIcon="ProyectoComoMuchosGrafos" *ngIf="projectType.id == 3"></mat-icon>
									<mat-icon class="custom-icon_yellow" svgIcon="ProyectoComoMuchosGrafos" *ngIf="projectType.id == 4"></mat-icon>
									<mat-icon class="custom-icon_violet" svgIcon="ProyectoComoMuchosGrafos" *ngIf="projectType.id == 5"></mat-icon>
								</button>
							</div>
							<label class="label-header m-0">
								{{projectType.descripcion}}
							</label>
						</div>
					</div>
				</div>
			</div>
			<!-- Buscador -->
			<div class="col-12 col-xl-3 d-flex align-items-center">
				<nb-form-field [formGroup]="formulario" class="w-100">
					<input type="search" nbInput fullWidth fieldSize="small" class="form-control"
						placeholder="{{ 'CURSOS.SEARCH' | translate }}" formControlName="filtrado">
					<button nbSuffix nbButton ghost size="small">
						<nb-icon class="pointer" icon="search" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
					</button>
				</nb-form-field>
			</div>
		</div>
	</nb-card-header>
	<nb-card-body>
		<!-- VISTA MODO GRILLA -->
		<div *ngIf="!modeList" class="row background_white d-flex justify-content-center py-4 px-2 min-h-100">
			<div class="col-12">
				<div *ngIf="dataList.length === 0"></div>
				<div *ngIf="dataList.length > 0">
					<div class="d-flex flex-wrap gap-5 justify-content-center">
						<div *ngFor="let curso of dataList" class="card-projects">
							<nb-card class="pointer mb-0" (click)="openCurso(curso)"
							[ngClass]="{'project-info-1': curso.type == 1, 'project-info-2': curso.type == 2, 'project-info-3': curso.type == 3, 'project-info-4': curso.type == 4, 'project-info-5': curso.type == 5}">
								<nb-card-header class="pb-2 pl-0 pr-0 pt-0 border-bottom-0" nbTooltip="{{curso.title}}"
									nbTooltipPlacement="top">
									<!--ocultamos la imagen si la conexion es lenta-->
									<div class="img-container">
										<img [src]="curso.image | imagen:'cursos'" class="img-fluid" (error)="updateUrl($event)" *ngIf="networkSpeed >= 1">
										<img [src]="'../../assets/images/no-image.png'" class="img-fluid" *ngIf="networkSpeed < 1">
									</div>
								</nb-card-header>
								<nb-card-body class="p-2 d-flex justify-content-center align-items-center">
									<p class="courseTitle">
										{{ curso.title }}
										<span style="font-weight: bold;">({{ curso.level ? curso.level : 'Sin nivel especifico' | truncate:[15, '...'] }})</span>								
									</p>
								</nb-card-body>
								<nb-card-footer class="border-top-0">
									<div style="position: relative;" class="d-flex justify-content-center flex-wrap pb-2">
										<div *ngFor="let subject of curso.subjectsList" style="margin-right: 5px;">
											<nb-tag size="tiny" [style.background]="subject.color" appearance="filled" style="position: relative; z-index: 99999;"
															[text]="subject.acronym" nbTooltip="{{subject.subject}}" nbTooltipPlacement="top"></nb-tag>
										</div>
									</div>
								</nb-card-footer>
							</nb-card>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- VISTA MODO LISTA -->
		<div *ngIf="modeList" class="row min-h-100 gap-4">
			<!--LISTA-->
			<div class="col-12 col-xl-4 container-tree background_white">
				<!--Primer nivel (PROYECTOS)-->
				<ul *ngIf="dataList.length > 0" class="style-list py-1 px-4" style="list-style: none;">
					<li *ngFor="let curso of dataList; let i = index">
						<div class="d-flex pointer py-1 px-3 gap-2">
							<div (click)="viewTargets(i)">
								<nb-icon icon="chevron-right-outline" status="basic"
									*ngIf="!curso.childrenVisible && curso.children.length > 0"></nb-icon>
								<nb-icon icon="chevron-down-outline" status="basic"
									*ngIf="curso.childrenVisible && curso.children.length > 0"></nb-icon>
								<mat-icon svgIcon="ProyectoComoMuchosGrafos" *ngIf="curso.type == 1"></mat-icon>
								<mat-icon class="custom-icon_green" svgIcon="ProyectoComoMuchosGrafos" *ngIf="curso.type == 2"></mat-icon>
								<mat-icon class="custom-icon_red" svgIcon="ProyectoComoMuchosGrafos" *ngIf="curso.type == 3"></mat-icon>
								<mat-icon class="custom-icon_yellow" svgIcon="ProyectoComoMuchosGrafos" *ngIf="curso.type == 4"></mat-icon>
								<mat-icon class="custom-icon_violet" svgIcon="ProyectoComoMuchosGrafos" *ngIf="curso.type == 5"></mat-icon>
								<span> {{ curso.title }} </span>
							</div>
						</div>
						<!--Segundo nivel (IDEOGRAFOS)-->
						<ul *ngIf="curso.children.length > 0" class="style-list py-1 px-4" [ngStyle]="{'display': curso.childrenVisible ? 'block' : 'none'}">
							<li *ngFor="let target of curso.children; let j = index">
								<div class="d-flex pointer py-1 px-3 gap-2">
									<div (click)="viewNodes(i, j)">
										<nb-icon icon="chevron-right-outline" status="basic"
											*ngIf="!target.childrenVisible && target.children.length > 0"></nb-icon>
										<nb-icon icon="chevron-down-outline" status="basic"
											*ngIf="target.childrenVisible && target.children.length > 0"></nb-icon>
										<mat-icon svgIcon="ideografo" *ngIf="curso.type == 1"></mat-icon>
										<mat-icon class="custom-icon_green2" svgIcon="ideografo" *ngIf="curso.type == 2"></mat-icon>
										<mat-icon class="custom-icon_red2" svgIcon="ideografo" *ngIf="curso.type == 3"></mat-icon>
										<mat-icon class="custom-icon_yellow2" svgIcon="ideografo" *ngIf="curso.type == 4"></mat-icon>
										<mat-icon class="custom-icon_violet2" svgIcon="ideografo" *ngIf="curso.type == 5"></mat-icon>
										<span> {{ target.title }} </span>
									</div>
								</div>
								<!--Tercer nivel (NODOS Y QUIZZES SUELTOS)-->
								<ul *ngIf="target.children.length > 0" class="py-1 px-4" style="list-style: none;" [ngStyle]="{'display': target.childrenVisible ? 'block' : 'none'}">
									<li *ngFor="let nodos of target.children; let k = index">
										<div class="d-flex pointer py-1 px-3 gap-2" [class.selected]="selectedLabel === nodos">
											<div (click)="viewQuizzes(i, j, k)">
												<nb-icon icon="chevron-right-outline" status="basic"
													*ngIf="!nodos.childrenVisible && nodos.children.length > 0 && nodos.type == 0"></nb-icon>
												<nb-icon icon="chevron-down-outline" status="basic"
													*ngIf="nodos.childrenVisible && nodos.children.length > 0 && nodos.type == 0"></nb-icon>
												<mat-icon svgIcon="ideasvg" *ngIf="nodos.type == 0"></mat-icon>
												<mat-icon svgIcon="act" *ngIf="nodos.type == 1"></mat-icon>
												<mat-icon svgIcon="QuizMulti" *ngIf="nodos.type == 2"></mat-icon>
												<span> {{ nodos.title }} </span>
											</div>
											<div (click)="viewData(i, j, k, nodos)">
												<nb-icon icon="eye-outline" status="basic" *ngIf="selectedLabel === nodos"></nb-icon>
												<nb-icon icon="eye-off-2-outline" status="basic" *ngIf="selectedLabel !== nodos"></nb-icon>
											</div>
										</div>
										<!--Cuarto nivel (QUIZZES)-->
										<ul *ngIf="nodos.children.length > 0" class="py-1 px-8" style="list-style: none;" [ngStyle]="{'display': nodos.childrenVisible ? 'block' : 'none'}">
											<li *ngFor="let quizzes of nodos.children">
												<div class="d-flex pointer py-1 px-3 gap-2" [class.selected]="selectedLabel === quizzes">
													<mat-icon svgIcon="act" *ngIf="quizzes.type == 1"></mat-icon>
													<mat-icon svgIcon="QuizMulti" *ngIf="quizzes.type == 2"></mat-icon>
													<div>
														{{ quizzes.title }}
													</div>
													<div (click)="viewData(i, j, k, quizzes)">
														<nb-icon icon="eye-outline" status="basic" *ngIf="selectedLabel === quizzes"></nb-icon>
														<nb-icon icon="eye-off-2-outline" status="basic" *ngIf="selectedLabel !== quizzes"></nb-icon>
													</div>
												</div>
											</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<!--DETALLES-->
			<div class="col py-4 pl-xl-4 pr-xl-0 container-tree background_white">
				<!--editar nodo-->
				<div *ngIf="idSelected == 1">
					<app-node-detail-info
					[node]="node" [idCurso]="idCurso" [idMapa]="idMapa" [canEdit]="canEdit" [course]="course" [isNewNode]="isNewNode" [fromLista]="true"
					(closeInModeList)="hideData($event)"
					></app-node-detail-info>
				</div>
				<!--editar quiz simple-->
				<div *ngIf="idSelected == 2">
					<app-quiz-edit
					[quiz]="quiz" [elements]="elements" [courseId]="idCurso" [graphId]="idMapa" [fromLista]="true"
					(closeInModeList)="hideData($event)"
					></app-quiz-edit>
				</div>
				<!--editar quiz multiple-->
				<div *ngIf="idSelected == 3">
					<app-quiz-multiple-edit
					[quiz]="quiz" [elements]="elements" [courseId]="idCurso" [graphId]="idMapa" [quizFiles]="quizFiles" [quizzes]="quizzes" [fromLista]="true"
					(closeInModeList)="hideData($event)"
					></app-quiz-multiple-edit>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>
