<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="flex border-bottom-0">
		<div class="row align-items-center w-100">
			<div class="col-12 col-sm-10 col-md-8">
				<h4>{{ "MODALLISTCOURSES.ASSINGPROJECTSTO" | translate }} {{group.title}}</h4>
			</div>
			<div class="col-12 col-sm-12 col-md-3 text-right mt-md-0 mt-2">
				<button nbButton class="btn-themecolor" (click)="closeModal(true)" class="mr-1"
					*ngIf="group.assignedStudents === 0 && !newGroup">{{ "ALERTGROUP.ADDSTUDENT" | translate }}</button>
				<button nbButton class="btn-themecolor" (click)="closeModal(true)" class="mr-1" *ngIf="newGroup">{{ "FORM.NEXT" | translate }}</button>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'pulse' } }" (click)="closeModal('')"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<app-loading [isVisible]="cargando"></app-loading>

		<nb-card class="no-edge-card" *ngIf="screenView === 1">
			<div class="row m-1 justify-content-center">
				<div class="col-md-5 col-sm-12 col-12 p-0">
					<form [formGroup]="formulario" class="searchBar w-100" #thisForm="ngForm">
						<div class="form-group">
							<input type="search" nbInput fullWidth status="basic" class="form-control"
								placeholder="{{ 'MODALLISTCOURSES.SEARCHPROYECTSTOASSIGN' | translate }}" name="fiilter" name="filter"
								formControlName="filtrado" autocomplete="off" />
						</div>
					</form>
					<ul class="list-group" *ngIf="coursesList.length > 0">
						<li *ngFor=" let curso of coursesList | sortBy : 'asc' : 'courseTitle'"
							class="list-group-item list-group-item-action p-0 border-0 d-flex flex-column align-items-center">
							<div class="d-flex w-100 py-2 m-0 align-items-center color-header pointer" (click)="viewTargets(curso)">
								<div class="col-1">
									<nb-icon icon="arrow-ios-forward-outline" *ngIf="!curso.targetVisible"></nb-icon>
									<nb-icon icon="arrow-ios-downward-outline" *ngIf="curso.targetVisible"></nb-icon>
								</div>
								<div class="col-1 img-container p-0" ngbTooltip="{{ curso.idCourse }}" placement="bottom">
									<img [src]="curso.courseImage | imagen : 'cursos'" class="img-fluid" [alt]="curso.courseTitle"
										(error)="updateUrl($event)" *ngIf="networkSpeed >= 1"
										[ngClass]="{'project-info-1': curso.projectType == 1,
															'project-info-2': curso.projectType == 2,
															'project-info-3': curso.projectType == 3,
															'project-info-4': curso.projectType == 4,
															'project-info-5': curso.projectType == 5}"/>
									<img [src]="'../../../assets/images/no-image.png'" class="img-fluid" [alt]="curso.courseTitle" *ngIf="networkSpeed < 1"
										[ngClass]="{'project-info-1': curso.projectType == 1,
															'project-info-2': curso.projectType == 2,
															'project-info-3': curso.projectType == 3,
															'project-info-4': curso.projectType == 4,
															'project-info-5': curso.projectType == 5}"/>
								</div>
								<div class="col-9">
									{{ curso.courseTitle }}
								</div>
								<a (click)="agregarCurso(curso)"
									*ngIf="cameFromCompetitions === false && cameFromCenters === false"
									class="col-1 p-0 badge pointer flex align-items-center justify-content-end"
									ngbTooltip="{{ 'MODALLISTCOURSES.ASSIGNPROYECT' | translate }}" placement="left">
									<nb-icon class="arrow" icon="arrow-circle-right" status="basic"> </nb-icon>
								</a>
							</div>

							<ul class="col-12 m-0 py-3 px-5"
									[ngStyle]="{'display': curso.targetVisible ? 'block' : 'none'}">
								<li *ngFor="let ideografos of curso.targetList"
									class="py-2 d-flex align-items-center">
									<span class="col-8">
										{{ ideografos.targetTitle }}
									</span>
									<div class="col-4 justify-content-end">
										<span (click)="abrirGrafo(curso.idCourse, ideografos.idTarget, ideografos.targetTitle)"
										ngbTooltip="{{'QUIZZES.DIALOGS.VERCONTENIDO' | translate}}" placement="left">
										<!--<nb-icon icon="play-circle-outline" class="mr-3 pointer"></nb-icon>-->
										<mat-icon class="mr-3 pointer" svgIcon="graph"></mat-icon>
									</span>
										<span class="p-0 text-center pointer" (click)="agregarTarget(curso, ideografos)"
										ngbTooltip="{{'MODALLISTCOURSES.ASSIGNGRAPH' | translate}}" placement="left">
										<nb-icon class="arrow" icon="arrow-circle-right" status="basic"> </nb-icon>
									</span>
									</div>
								</li>
							</ul>
						</li>
					</ul>

					<!--<div *ngIf="coursesList.length === 0" class="alert alert-info" role="alert">
							{{ "MODALLISTCOURSES.TYPECOURSENAME" | translate }}
						</div>-->
				</div>
				<div class="col-md-1 col-sm-12 col-12 flex justify-content-center align-items-center my-md-0 my-4">
					<div class="vertical-line"></div>
				</div>

				<div class="col-md-5 col-sm-12 col-12 p-0">
					<div class="w-100 position-relative d-flex justify-content-center form-group">
						<h3 class="m-0">{{ 'MODALLISTCOURSES.ASSIGNEDPROYECTS' | translate }}</h3>
					</div>
					<div *ngIf="cameFromCompetitions === true; else normal">
						<ul class="col-12 m-0 py-3" *ngIf="targetSelected.length >= 1" >
							<li *ngFor="let ideografos of targetSelected"
								class="p-2 d-flex align-items-center">
								<span class="col-10">
									{{ ideografos.targetTitle | titlecase | truncate : [30, "..."] }}
								</span>
								<div class="col-2 p-0 flex justify-content-end">
									<span *ngIf="ideografos.haveQuizzesMultiples"
										(click)="editarMultiples(curso.idCourse, ideografos.idTarget)"
										ngbTooltip="{{'QUIZZES.MULTIPLES' | translate}}" placement="left">
										<mat-icon class="mr-3 pointer" svgIcon="AnadirActividadesMultiples"></mat-icon>
									</span>
									<span (click)="abrirGrafo(curso.idCourse, ideografos.idTarget, ideografos.targetTitle)"
										ngbTooltip="{{'QUIZZES.DIALOGS.VERCONTENIDO' | translate}}" placement="left">
										<!--<nb-icon icon="play-circle-outline" class="mr-3 pointer"></nb-icon>-->
										<mat-icon class="mr-3 pointer" svgIcon="graph"></mat-icon>
									</span>
									<span (click)="eliminarGrafo(curso, ideografos)"
										ngbTooltip="{{'MODALLISTCOURSES.UNASSIGNGRAPH' | translate}}" placement="left">
										<mat-icon class="pointer color-red-text">delete</mat-icon>
									</span>
								</div>
							</li>
						</ul>
					</div>

					<ng-template #normal>
						<ul class="list-group" *ngIf="cursosGrupo.length > 0">
							<li *ngFor="let curso of cursosGrupo | sortBy : 'asc' : 'courseTitle'"
								class="list-group-item list-group-item-action p-0 border-0 d-flex flex-column align-items-center">
								<div class="d-flex w-100 py-2 m-0 align-items-center color-header pointer" (click)="viewTargets(curso)">
									<div class="col-1">
										<nb-icon icon="arrow-ios-forward-outline" *ngIf="!curso.targetVisible"></nb-icon>
										<nb-icon icon="arrow-ios-downward-outline" *ngIf="curso.targetVisible"></nb-icon>
									</div>
									<div class="col-1 img-container" ngbTooltip="{{ curso.idCourse }}" placement="bottom">
										<img [src]="curso.courseImage | imagen : 'cursos'" class="img-fluid" [alt]="curso.courseTitle"
											(error)="updateUrl($event)" *ngIf="networkSpeed >= 1"
											[ngClass]="{'project-info-1': curso.projectType == 1,
											'project-info-2': curso.projectType == 2,
											'project-info-3': curso.projectType == 3,
											'project-info-4': curso.projectType == 4,
											'project-info-5': curso.projectType == 5}"/>
										<img [src]="'../../../assets/images/no-image.png'" class="img-fluid" [alt]="curso.courseTitle" *ngIf="networkSpeed < 1"
											[ngClass]="{'project-info-1': curso.projectType == 1,
											'project-info-2': curso.projectType == 2,
											'project-info-3': curso.projectType == 3,
											'project-info-4': curso.projectType == 4,
											'project-info-5': curso.projectType == 5}"/>
									</div>

									<div class="col-9">
										{{ curso.courseTitle }}
									</div>

									<a (click)="eliminarCurso(curso)"
										class="col-1 badge pointer flex align-items-center justify-content-center"
										ngbTooltip="{{ 'MODALLISTCOURSES.UNASSIGNPROYECT' | translate }}" placement="left">
										<mat-icon class="color-red-text">delete</mat-icon>
									</a>
								</div>

								<ul class="col-12 m-0 py-3"
										[ngStyle]="{'display': curso.targetVisible ? 'block' : 'none'}">
									<li *ngFor="let ideografos of curso.targetList"
										class="p-2 d-flex align-items-center">
										<span class="col-10">
											{{ ideografos.targetTitle | titlecase | truncate : [30, "..."] }}
										</span>
										<div class="col-2 p-0 flex justify-content-end">
											<span *ngIf="ideografos.haveQuizzesMultiples"
												(click)="editarMultiples(curso.idCourse, ideografos.idTarget)"
												ngbTooltip="{{'QUIZZES.MULTIPLES' | translate}}" placement="left">
												<mat-icon class="mr-3 pointer" svgIcon="AnadirActividadesMultiples"></mat-icon>
											</span>
											<span (click)="abrirGrafo(curso.idCourse, ideografos.idTarget, ideografos.targetTitle)"
												ngbTooltip="{{'QUIZZES.DIALOGS.VERCONTENIDO' | translate}}" placement="left">
												<!--<nb-icon icon="play-circle-outline" class="mr-3 pointer"></nb-icon>-->
												<mat-icon class="mr-3 pointer" svgIcon="graph"></mat-icon>
											</span>
											<span (click)="eliminarGrafo(curso, ideografos)"
												ngbTooltip="{{'MODALLISTCOURSES.UNASSIGNGRAPH' | translate}}" placement="left">
												<mat-icon class="pointer color-red-text">delete</mat-icon>
											</span>
										</div>
									</li>
								</ul>
							</li>
						</ul>
					</ng-template>




					<!--<div *ngIf="cursosGrupo.length === 0" class="alert alert-info" role="alert">
							{{ "MODALLISTCOURSES.NOCOURSESASSIGNED" | translate }}
						</div>-->
				</div>
			</div>
		</nb-card>
		<nb-card size="giant" *ngIf="screenView === 2">
			<nb-card-body>
				<div>
					<app-seleccion-multiples [listMultiples]="listMultiples" [configMultiples]="configMultiples" [target]="target"
						[curso]="curso" [idGroup]="group.idGroup" (action)="changeScreen($event)"></app-seleccion-multiples>
				</div>
			</nb-card-body>
		</nb-card>
	</nb-card-body>

</nb-card>
