<ul style="list-style-type: none;" class="p-0 m-0">
	<li *ngFor="let label of labels; let i = index">
		<nb-card class="no-edge-card mb-0" *ngIf="i < 1">
			<nb-card-body class="p-0">
				<div class="d-flex flex-wrap gap-4 justify-content-center">
					<div *ngFor="let dataset of datasets; let j = index" class="card-Mygroup"
						[ngStyle]="{'display': dataset.data[i] != 0 ? 'block' : 'none'}">
						<nb-card class="group-info border-body mb-0" [ngStyle]="{'background-color': colorKnowledge(dataset.data[i])}">
							<nb-card-header class="border-header">
								<div class="img-container">
									<img>
								</div>
							</nb-card-header>
							<nb-card-footer style="padding: 0.3rem 1rem;">
							</nb-card-footer>
						</nb-card>
					</div>
				</div>
			</nb-card-body>
		</nb-card>
	</li>
</ul>
