<form class="w-100 h-100 mt-4" [formGroup]="infoForm">
    <div class="row mb-2" >
        <div class="col-12">
        </div>
				<div class="col timer-canvas" *ngIf="showClock == true">
            <!-- <canvas #canvas></canvas> -->
						<countdown #cd [config]="config" (event)="handleCountDownEvent($event)" class="counter-down"></countdown>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p><strong>{{infoForm.get('instructions').value}}</strong></p>
        </div>
    </div>
</form>
