<nb-card class="no-edge-card mb-0 col-12 h-100">
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>

	<nb-card-body>
		<h5 class="pb-4" style="color: green;">
			{{ "GUIAPROYECTOS.TITLE" | translate }}
		</h5>
		<span style="color: green;">{{ "GUIAPROYECTOS.SUBTITLE1" | translate }}</span>
		<div><p>{{ "GUIAPROYECTOS.TEXT1" | translate }}</p></div>

		<span style="color: green;">{{ "GUIAPROYECTOS.SUBTITLE2" | translate }}</span>
		<div><p>{{ "GUIAPROYECTOS.TEXT2" | translate }}</p></div>

		<span style="color: green;">{{ "GUIAPROYECTOS.SUBTITLE3" | translate }}</span>
		<div><p>{{ "GUIAPROYECTOS.TEXT3" | translate }}</p></div>

		<span style="color: green;">{{ "GUIAPROYECTOS.SUBTITLE4" | translate }}</span>
		<div><p>{{ "GUIAPROYECTOS.TEXT4" | translate }}</p></div>

		<span style="color: green;">{{ "GUIAPROYECTOS.SUBTITLE5" | translate }}</span>
		<div><p>{{ "GUIAPROYECTOS.TEXT5" | translate }}</p></div>

		<span class="pointer" style="color: green;" (click)="showOpinar()"><strong>{{ "GUIAPROYECTOS.SUBTITLE6" | translate }}</strong></span>
		<p>{{ "GUIAPROYECTOS.TEXT6" | translate }}</p>
	</nb-card-body>
</nb-card>
