import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/core/services/login';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { UsersService } from 'src/app/core/services/users';

@Component({
  selector: 'app-modal-drop-out',
  templateUrl: './modal-drop-out.component.html',
  styleUrls: ['./modal-drop-out.component.scss']
})
export class ModalDropOutComponent implements OnInit{

	@Output() action: EventEmitter<boolean> = new EventEmitter<boolean>()

  mensaje: string;
	optionalTitle: string;
	disableCancel: boolean;
	lang: string;

  constructor(
		public translateService: TranslateService,
		private activeModal: NgbActiveModal,
		private userService: UsersService,
		private loginService: LoginService,
		private toaster: ToasterService
		){}

	ngOnInit(): void {
	}

  aceptar() {
		this.userService.dropOut().subscribe(res => {
			if(res.error.code === 0){
				this.toaster.success(this.translateService.instant('DARSEBAJA.UNSUBSCRIBEOK'));
				this.closeModal();
				this.logout();
			} else{
				this.toaster.error(this.translateService.instant('DARSEBAJA.UNSUBSCRIBEERROR'));
			}
		})
  }

  cancelar() {
		this.action.emit(false);
  }

  closeModal(sendData?: any) {
    this.activeModal.close(false);
  }

	logout(): void {
		localStorage.removeItem('selectedGroupInfo');
		this.loginService.logout(true);
	}
}
