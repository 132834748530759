export enum Profiles {
    Student = 'ESTUDIANTE',
    Father = 'PADRE',
    Teacher = 'PROFESOR',
    Author = 'AUTOR',
    Center = 'CENTRO',
    Employer = 'EMPLEADOR',
		Editor = 'EDITOR',
		Admin = 'ADMIN'
}
