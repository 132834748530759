<nb-card class="no-edge-card mb-0 modal-height-100-62">
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-header>
		<div class="d-flex align-items-center">
			<!--===== version desktop =====-->
			<div *ngIf="!isMobile" class="d-flex justify-content-between w-100 align-items-center pr-5 gap-4">
				<!-- Botones, visibles solo si se ha seleccionado una consulta -->
				<div *ngIf="verBotonesGraficas != 0" class="d-flex gap-4 w-100 justify-content-end">
					<!-- BOTON BARRAS -->
					<div class="d-flex align-items-center justify-content-center flex-column" style="gap: 5px;"
						nbTooltip="{{ 'CENTRO.BARRAS' | translate }}" nbTooltipPlacement="top">
						<div>
							<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
								[ngClass]="{ 'active-button': verBotonesGraficas == 1 }" (click)="verBarras()">
								<nb-icon class="small-icon" icon="bar-chart-outline"></nb-icon>
							</button>
						</div>
						<label class="label-header m-0">
							{{ 'CENTRO.BARRAS' | translate }}
						</label>
					</div>
				</div>
			</div>
			<!--===== version mobile =====-->
			<div *ngIf="isMobile" class="d-flex justify-content-between w-100 align-items-center px-3 gap-4">
				<!-- Icono para abrir graficas -->
				<div class="d-flex align-items-center justify-content-center flex-column gap-1" nbTooltip="Graficas"
					nbTooltipPlacement="top">
					<div>
						<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
							(click)="toggleAccordion()">
							<nb-icon class="small-icon" icon="activity-outline"></nb-icon>
						</button>
					</div>
					<label class="label-header m-0">
						Graficas
					</label>
				</div>
				<div [@accordion]="accordionOpen ? 'open' : 'closed'" class="accordion-content d-flex gap-4">
					<!-- Botones, visibles solo si se ha seleccionado una consulta -->
					<div *ngIf="verBotonesGraficas != 0" class="d-flex gap-4 flex-wrap justify-content-center">
						<!-- BOTON BARRAS -->
						<div class="d-flex align-items-center justify-content-center flex-column" style="gap: 5px;"
							nbTooltip="{{ 'CENTRO.BARRAS' | translate }}" nbTooltipPlacement="top">
							<div>
								<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
									[ngClass]="{ 'active-button': verBotonesGraficas == 2 }" (click)="verBarras()">
									<nb-icon class="small-icon" icon="bar-chart-outline"></nb-icon>
								</button>
							</div>
							<label class="label-header m-0">
								{{ 'CENTRO.BARRAS' | translate }}
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nb-card-header>
	<nb-card-body>
		<div class="row h-195">
			<!--===== version desktop =====-->
			<!-- Panel lateral -->
			<div *ngIf="!isMobile" class="col-12 col-xl-3">
				<div class="flex flex-column background_themecolor h-195 pl-3 pt-3 pb-3 pr-0 overflow-auto">
					<!--PRIMER NIVEL DEL ARBOL-->
					<ul *ngIf="allDatasets.length > 0" class="m-0 pt-0 pb-0 pl-0 pr-3" style="list-style: none;">
						<li *ngFor="let label_1 of allDatasets; let i = index">
							<div class="d-flex pointer py-2 px-3 gap-2" [class.selected]="selectedLabel === label_1">
								<div (click)="viewChildren2(i)">
									<nb-icon icon="chevron-right-outline" status="basic"
										*ngIf="!label_1.childrenVisible && label_1.children.length > 0">
									</nb-icon>
									<nb-icon icon="chevron-down-outline" status="basic"
										*ngIf="label_1.childrenVisible && label_1.children.length > 0">
									</nb-icon>
									<span> {{ label_1.label }} </span>
								</div>
								<div (click)="viewData(label_1.data, label_1)">
									<nb-icon icon="eye-outline" status="basic"
										*ngIf="(selectedLabel === label_1) && (label_1.data != null && label_1.data.length > 0)">
									</nb-icon>
									<nb-icon icon="eye-off-2-outline" status="basic"
										*ngIf="(selectedLabel !== label_1) && (label_1.data != null && label_1.data.length > 0)">
									</nb-icon>
								</div>
							</div>
							<!--SEGUNDO NIVEL DEL ARBOL-->
							<ul *ngIf="label_1.children.length > 0" class="style-list" [ngStyle]="{'display': label_1.childrenVisible ? 'block' : 'none'}">
								<li *ngFor="let label_2 of label_1.children; let j = index">
									<div class="d-flex pointer p-1 gap-2" [class.selected]="selectedLabel === label_2">
										<div (click)="viewChildren3(i, j)">
											<nb-icon icon="chevron-right-outline" status="basic"
												*ngIf="!label_2.childrenVisible && label_2.children.length > 0"> </nb-icon>
											<nb-icon icon="chevron-down-outline" status="basic"
												*ngIf="label_2.childrenVisible && label_2.children.length > 0"> </nb-icon>
											<span> {{ label_2.label }} </span>
										</div>
										<div (click)="viewData(label_2.data, label_2)">
											<nb-icon icon="eye-outline" status="basic"
												*ngIf="(selectedLabel === label_2) && (label_2.data != null && label_2.data.length > 0)"> </nb-icon>
											<nb-icon icon="eye-off-2-outline" status="basic"
												*ngIf="(selectedLabel !== label_2) && (label_2.data != null && label_2.data.length > 0)"> </nb-icon>
										</div>
									</div>
									<!--TERCER NIVEL DEL ARBOL-->
									<ul *ngIf="label_2.children.length > 0" class="p-0" style="list-style: none;" [ngStyle]="{'display': label_2.childrenVisible ? 'block' : 'none'}">
										<li *ngFor="let label_3 of label_2.children">
											<div class="d-flex pointer py-1 px-3 gap-2" [class.selected]="selectedLabel === label_3" (click)="viewData(label_3.data, label_3)">
												<div>
													{{ label_3.label }}
												</div>
												<div>
													<nb-icon icon="eye-outline" status="basic"
														*ngIf="(selectedLabel === label_3) && (label_3.data != null && label_3.data.length > 0)"> </nb-icon>
													<nb-icon icon="eye-off-2-outline" status="basic"
														*ngIf="(selectedLabel !== label_3) && (label_3.data != null && label_3.data.length > 0)"> </nb-icon>
												</div>
											</div>
										</li>
									</ul>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<!--===== version mobile =====-->
			<!-- Panel lateral -->
			<div *ngIf="isMobile" class="left-files" [ngClass]="{'show-files': isMenuOpen}">
				<div class="flex flex-column background_themecolor h-100 overflow-auto pl-3 pt-3 pb-3 pr-0">
					<div class="closeModal">
						<nb-icon class="m-1 pointer" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
							(click)="toggleMenu()" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
						</nb-icon>
					</div>
					<!--PRIMER NIVEL DEL ARBOL-->
					<ul *ngIf="allDatasets.length > 0" class="m-0 pt-0 pb-0 pl-0 pr-3" style="list-style: none;">
						<li *ngFor="let label_1 of allDatasets; let i = index">
							<div class="d-flex pointer py-2 px-3 gap-2" [class.selected]="selectedLabel === label_1">
								<div (click)="viewChildren2(i)">
									<nb-icon icon="chevron-right-outline" status="basic"
										*ngIf="!label_1.childrenVisible && label_1.children.length > 0"> </nb-icon>
									<nb-icon icon="chevron-down-outline" status="basic"
										*ngIf="label_1.childrenVisible && label_1.children.length > 0"> </nb-icon>
									<span> {{ label_1.label }} </span>
								</div>
								<div (click)="viewData(label_1.data, label_1)">
									<nb-icon icon="eye-outline" status="basic"
										*ngIf="(selectedLabel === label_1) && (label_1.data != null && label_1.data.length > 0)"> </nb-icon>
									<nb-icon icon="eye-off-2-outline" status="basic"
										*ngIf="(selectedLabel !== label_1) && (label_1.data != null && label_1.data.length > 0)"> </nb-icon>
								</div>
							</div>
							<!--SEGUNDO NIVEL DEL ARBOL-->
							<ul *ngIf="label_1.children.length > 0" class="style-list" [ngStyle]="{'display': label_1.childrenVisible ? 'block' : 'none'}">
								<li *ngFor="let label_2 of label_1.children; let j = index">
									<div class="d-flex pointer p-1 gap-2" [class.selected]="selectedLabel === label_2">
										<div (click)="viewChildren3(i, j)">
											<nb-icon icon="chevron-right-outline" status="basic"
												*ngIf="!label_2.childrenVisible && label_2.children.length > 0"> </nb-icon>
											<nb-icon icon="chevron-down-outline" status="basic"
												*ngIf="label_2.childrenVisible && label_2.children.length > 0"> </nb-icon>
											<span> {{ label_2.label }} </span>
										</div>
										<div (click)="viewData(label_2.data, label_2)">
											<nb-icon icon="eye-outline" status="basic"
												*ngIf="(selectedLabel === label_2) && (label_2.data != null && label_2.data.length > 0)"> </nb-icon>
											<nb-icon icon="eye-off-2-outline" status="basic"
												*ngIf="(selectedLabel !== label_2) && (label_2.data != null && label_2.data.length > 0)"> </nb-icon>
										</div>
									</div>
									<!--TERCER NIVEL DEL ARBOL-->
									<ul *ngIf="label_2.children.length > 0" class="p-0" style="list-style: none;" [ngStyle]="{'display': label_2.childrenVisible ? 'block' : 'none'}">
										<li *ngFor="let label_3 of label_2.children">
											<div class="d-flex pointer p-1 px-3 gap-2" [class.selected]="selectedLabel === label_3" (click)="viewData(label_3.data, label_3)">
												<div>
													{{ label_3.label }}
												</div>
												<div>
													<nb-icon icon="eye-outline" status="basic"
														*ngIf="(selectedLabel === label_3) && (label_3.data != null && label_3.data.length > 0)"> </nb-icon>
													<nb-icon icon="eye-off-2-outline" status="basic"
														*ngIf="(selectedLabel !== label_3) && (label_3.data != null && label_3.data.length > 0)"> </nb-icon>
												</div>
											</div>
										</li>
									</ul>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>

			<!--===== Graficos =====-->
			<div class="col-12 col-xl-9 h-195 overflow-auto" *ngIf="datasetsImported">
				<!-- GRAFICA DE BARRAS -->
				<div id="barras" class="w-100 h-100 px-5" *ngIf="verBotonesGraficas == 1">
					<app-barras-admin [consulta]="consulta" [role]="role" [datasetsImported]="datasetsImported" [labelsImported]="labelsImported"></app-barras-admin>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>
