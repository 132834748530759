import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminHistory } from '../models/users/user.models';

@Injectable({
  providedIn: 'root'
})
export class TablaInformeService {

  constructor(private http:HttpClient) { }
 //getTotalHistoryIndividualUsers
	getData(desde, hasta){
		return this.http.get(`user/getTotalHistoryUser/${desde}/${hasta}`)
	}

	getDataIndividual(desde, hasta, adminHistory: AdminHistory){
		return this.http.post<any>(`user/getTotalHistoryIndividual/${desde}/${hasta}`, adminHistory);
	}

	getGroupsOfUser(idUser, idCenter){
		return this.http.get(`group/getgroupsbyid/${idUser}/${idCenter}`)
	}
}
