<div>
		<!--BUTTONS RIGHT -->
		<div class="buttons-right-graph d-flex flex-column">

			<div *ngIf="canEdit && !esAutor()" class="mb-3 cursor-pointer not-show-mobile" ngbTooltip="{{'PADS.CAMBIAREDITOR' | translate}}"	(click)="cambiarEditor()">
				<!-- <mat-icon svgIcon="edit" class="icon-color-blue"></mat-icon> -->
				<mat-icon svgIcon="LapizLineaBlanco" class=""></mat-icon>
			</div>
			<div *ngIf="esAutor()" class="mb-3 cursor-pointer not-show-mobile" ngbTooltip="{{'PADS.CAMBIARESTUDIANTE' | translate}}" (click)="cambiarEstudiante()">
				<!-- <mat-icon svgIcon="play_arrow" class="icon-color-blue"></mat-icon> -->
				<mat-icon svgIcon="play_outline" class=""></mat-icon>
			</div>
			<div class=" mb-3 cursor-pointer not-show-mobile " ngbTooltip="{{'PADS.ZOOMINGRAPH' | translate}}" (click)="zoomIn()">
				<mat-icon svgIcon="acercar_ideografo" class=""></mat-icon>
				<!-- <nb-icon icon="acercar_ideografo"></nb-icon> -->
			</div>
			<div class="mb-3 cursor-pointer not-show-mobile " ngbTooltip="{{'PADS.ZOOMOUTGRAPH' | translate}}" (click)="zoomOut()">
				<mat-icon svgIcon="alejar_ideografo" class=""></mat-icon>
				<!-- <nb-icon icon="alejar_ideografo"></nb-icon> -->
			</div>
			<div class="cursor-pointer" ngbTooltip="{{'PADS.CENTERGRAPH' | translate}}" (click)="centerGraph()">
				<mat-icon svgIcon="CentrarMásEspacio" class=""></mat-icon>
				<!-- <nb-icon icon="radio-button-on"></nb-icon> -->
			</div>
			<div class="cursor-pointer" ngbTooltip="{{'PADS.FULLSCREEN' | translate}}" (click)="showFullscreen()"	*ngIf="!isFullscreen">
				<mat-icon svgIcon="simbolo_pantalla_completa" class=""></mat-icon>
				<!-- <nb-icon icon="radio-button-on"></nb-icon> -->
			</div>
			<div class="cursor-pointer" ngbTooltip="{{'PADS.FULLSCREEN' | translate}}" (click)="showFullscreen()"	*ngIf="isFullscreen">
				<mat-icon svgIcon="simbolo_empequenecer" class=""></mat-icon>
				<!-- <nb-icon icon="radio-button-on"></nb-icon> -->
			</div>
		</div>
</div>
