<div class="pad-right-container" [ngStyle]="{'visibility': isShow ? 'visible' : 'hidden' }">

    <div class="pad-buttons-right d-flex justify-content-center flex-column">
        <!-- TOP CENTER BUTTON -->
<!--        <div class="d-flex justify-content-center position-relative" (mouseenter)="onEnterPad(true)" (mouseleave)="onEnterPad(false)" *ngIf="!isTeacher">
            <label class="label top" *ngIf="isShowLabels" (click)="clickAction('closeModal')" data-click="yes">{{'PADSLABELS.GRAPH' | translate}}</label>
            <div class="circle-button" (click)="clickAction('closeModal')" [ngbTooltip]="!isShowLabels ? ('PADSLABELS.GRAPH' | translate) : ''">
                <mat-icon svgIcon="graph" class="icon-color-blue"></mat-icon>
            </div>
        </div>
-->
        <!-- LEFT AND RIGHT BUTTONS -->

<!--        <div class="d-flex justify-content-between align-items-center" (mouseenter)="onEnterPad(true)" (mouseleave)="onEnterPad(false)" *ngIf="!isTeacher">
            <div class="position-relative d-flex align-items-center">
                <label class="label left" *ngIf="isShowLabels">{{'PADSLABELS.HELP' | translate}}</label>
                <div class="circle-button disabled" (click)="clickAction('help')" [ngbTooltip]="!isShowLabels ? ('PADSLABELS.HELP' | translate) : ''">
                    <mat-icon svgIcon="sos" class="icon-color-blue"></mat-icon>
                </div>
            </div>
            <div class="position-relative d-flex align-items-center">
                <label class="label right" *ngIf="isShowLabels">{{'PADSLABELS.STAIRS' | translate}}</label>
                <div class="circle-button disabled" (click)="clickAction('stairs')" [ngbTooltip]="!isShowLabels ? ('PADSLABELS.STAIRS' | translate) : ''">
                    <mat-icon class="pointer" color="primary" svgIcon="stairs"></mat-icon>
                </div>
            </div>
        </div>
-->
        <!-- BOTTOM CENTER BUTTON -->
<!--        <div class="d-flex justify-content-center position-relative" (mouseenter)="onEnterPad(true)" (mouseleave)="onEnterPad(false)" *ngIf="!isTeacher">
            <div class="circle-button" (click)="clickAction('activities')" [ngbTooltip]="!isShowLabels ? ('PADSLABELS.ONLYACT' | translate) : ''">
                <img style="width: 18px; height: 29px;" src="assets/images/icons/act.png" />
            </div>
            <label class="label bottom" *ngIf="isShowLabels">{{'PADSLABELS.ONLYACT' | translate}}</label>
        </div>
-->
			<div class="position-relative d-flex flex-column align-items-center  ml-2 mr-2 not-show-mobile" (mouseenter)="onEnterPad(true)" (mouseleave)="onEnterPad(false)" *ngIf="isTeacher">
				<div class="circle-button" (click)="clickAction('challengeCase3')" [ngbTooltip]="!isShowLabels ? ('INSTANTACTIVITIES.EXPLICACIONCONTROLADA' | translate) : ''">
						<img style="width: 18px; height: 29px;" src="assets/images/icons/desafios_icon.svg" />
				</div>
				<div>
					<p class="info-course">{{('INSTANTACTIVITIES.EXPLICACIONCONTROLADA' | translate)}}</p>
				</div>
			</div>
    </div>
</div>
