

<div>
    <ng-template class="w-100"  #step10>
       <div  class="copilot-custom copilop-info edit-sidebar-cop" style="position: sticky;">
           <div class="row">
               <div class="col-12 flex justify-content-center align-items-center">
                   <h3 class="title-info">
                        {{'TUTORIALINICIO.TUTORIAL_TEXTO_00'| translate}}

                   </h3>
               </div>
               <div class="col-12 ">
                    <p class="text-info-help">
                        <!-- {{'TUTORIALINICIO.TUTORIAL_TEXTO_01'| translate}}
                    </p> -->
                </div>
           </div>
           <div  class="footer-btn">
               <div class="row  flex justify-content-center align-items-center">
                   <div class="col-2">
                       <button  class="btn btn-block btn-themecolor btn-sm button-ok" (click)="done()"  type="button">
                         {{'TUTORIALINICIO.BOTONES.ACEPTAR'| translate}}
                       </button>
                   </div>
               </div>
           </div>
       </div>
    </ng-template>
</div>



<div class="gif_container" hidden>
    <div class="dedo">
        <div class="row">
            <div class="col-6">
                <img  class="img_dedo_nodo" [src]="urlTutorial + 'dedodellado.gif'" alt="">
            </div>
            <div class="col-6">
                <img  class="img_dedo_quiz" [src]="urlTutorial + 'dedodellado.gif'" alt="">
            </div>
            <div class="col-12">
                <img [src]="urlTutorial + 'dedodesdeabajo.gif'" alt="">
            </div>

        </div>


    </div>
</div>

<aside class="left-sidebar show-avatar">

    <!-- <app-center-icon></app-center-icon> -->
    <app-user-account></app-user-account>
		<!-- <nb-icon ngbTooltip="{{ 'SIDEBAR.LOGOUT' | translate }}" (click)="logout()" icon="power-outline" class="mr-2 icon-sign-out"></nb-icon> -->

    <!-- <app-sidebarmenu></app-sidebarmenu> -->

</aside>

<div class="left-sidebar" style="margin-top: 100px;" *ngIf="loginService.esEstudiante() || loginService.esProfesor()">
	<div style="display: flex; align-items: center;">
			<nb-icon ngbTooltip="{{ 'PADS.PREVIOUSTARGET' | translate }}" placement="right" icon="arrow-back-outline" style="height: 30px; width: 30px; cursor: pointer;"
					(click)="verGrafoAnterior()" *ngIf="this.graphVisited_Student.length > 1 && this.graphVisited_Student[this.graphVisited_Student.length - 1].idGrafo != this.lastTarget"></nb-icon>
			<nb-icon ngbTooltip="{{ 'PADS.NEXTTARGET' | translate }}" placement="right" icon="arrow-forward-outline" style="height: 30px; width: 30px; cursor: pointer; margin-left: 5px;"
					(click)="verGrafoSiguiente()" *ngIf="this.graphVisited_Student.length > 1 && this.graphVisited_Student[0].idGrafo != this.lastTarget"></nb-icon>
	</div>
</div>

<div class="left-sidebar" style="margin-top: 100px;" *ngIf="loginService.esAutor()">
	<div style="display: flex; align-items: center;">
			<nb-icon ngbTooltip="{{ 'PADS.PREVIOUSTARGET' | translate }}" placement="right" icon="arrow-back-outline" style="height: 30px; width: 30px; cursor: pointer;"
					(click)="verGrafoAnteriorEditor()" *ngIf="this.graphVisited_Editor.length > 1 && this.graphVisited_Editor[this.graphVisited_Editor.length - 1].idGrafo != this.lastTarget_Editor"></nb-icon>
			<nb-icon ngbTooltip="{{ 'PADS.NEXTTARGET' | translate }}" placement="right" icon="arrow-forward-outline" style="height: 30px; width: 30px; cursor: pointer; margin-left: 5px;"
					(click)="verGrafoSiguienteEditor()" *ngIf="this.graphVisited_Editor.length > 1 && this.graphVisited_Editor[0].idGrafo != this.lastTarget_Editor"></nb-icon>
	</div>
</div>
