
<nb-card>
	<form (ngSubmit)="back()" #thisForm="ngForm" [formGroup]="form" novalidate>
		<nb-card-header  class="row d-flex">
			<div class="col-5 modal-title">
				<h6>{{'EDITARCURSOMAPA.ELECCIONMULTIPLES' | translate}}</h6>
			</div>
			<div class="col-2" style="display: flex; align-items: center;">
				{{ 'EDITARCURSOMAPA.SOLVEIA' | translate }}&nbsp;&nbsp;
				<nb-toggle labelPosition="end" formControlName="solveIA" (change)="change('solveIA', form.controls['solveIA'].value)"></nb-toggle>
			</div>
			<div class="col-2 ">
				<div class="dropdown">
					<button nbButton class="btn-themecolor" (click)="back()">
						{{'GRUPOS.VOLVER' | translate}}
					</button>
				</div>
			</div>
			<div style="background: #36f;border-radius: 10px;padding: 5px;">
				<h5 class="text-white">{{ 'EDITARCURSOMAPA.GRAFO' | translate }}: {{ target.tittle }}</h5>
			</div>
		</nb-card-header>
			<nb-card-body>
				<!-- ********** CANTIDAD DE ACTIVIDADES Y DURACION ********** -->
				<div class="d-flex align-items-start">
					<div class="col-2 card text-center">
						<h6>{{ 'PADSLABELS.ONLYACT' | translate }}</h6>
						<h6>{{ cantidadQM }}</h6>
						<br>
						<h6>{{ 'NODEFORMCOMPONENT.DURATION' | translate }}</h6>
						<h6>{{ formatDuration(duracionTotal) }}</h6>
					</div>
					<!--INFO PRIMER QUIZ-->
					<div class="col-1 text-center">
						<div>
							<h5>1</h5>
					</div>
					<div style="height: 40px; display: flex; align-items: center; justify-content: center;">
							<p>Intro</p>
					</div>
					<div style="height: 60px; display: flex; align-items: center; justify-content: center;">
							<img style="max-width: 100%; max-height: 100%; cursor: pointer;" class="icon-head" src="assets/images/icons/qm1.png" (click)="openImage('assets/images/icons/qm1.png')" />
					</div>
					<div id="myModal" class="modal" (click)="closeImage()">
						<span class="close">&times;</span>
						<img class="modal-content" style="max-width: 100%; max-height: 100%;  cursor: pointer;" [src]="modalImageSrc" />
					</div>
					</div>
					<!--INFO SEGUNDO QUIZ-->
					<div class="col-1 text-center">
						<div>
							<h5>2</h5>
						</div>
						<div style="height: 40px; display: flex; align-items: center; justify-content: center;">
							<p>Copiar</p>
						</div>
						<div style="height: 60px; display: flex; align-items: center; justify-content: center;">
							<img style="max-width: 100%; max-height: 100%;  cursor: pointer;" class="icon-head" src="assets/images/icons/qm2.png"  (click)="openImage('assets/images/icons/qm2.png')" />
						</div>
						<div id="myModal" class="modal" (click)="closeImage()">
							<span class="close">&times;</span>
							<img class="modal-content" style="max-width: 100%; max-height: 100%;  cursor: pointer;" [src]="modalImageSrc" />
						</div>
					</div>
					<!--INFO TERCER QUIZ-->
					<div class="col-1 text-center">
						<div>
							<h5>3</h5>
						</div>
						<div style="height: 40px; display: flex; align-items: center; justify-content: center;">
							<p>Entender</p>
						</div>
						<div style="height: 60px; display: flex; align-items: center; justify-content: center;">
							<img style="max-width: 100%; max-height: 100%;  cursor: pointer;" class="icon-head" src="assets/images/icons/qm3.png"  (click)="openImage('assets/images/icons/qm3.png')" />
						</div>
						<div id="myModal" class="modal" (click)="closeImage()">
							<span class="close">&times;</span>
							<img class="modal-content" style="max-width: 100%; max-height: 100%;  cursor: pointer;" [src]="modalImageSrc" />
						</div>
					</div>
					<!--INFO CUARTO QUIZ-->
					<div class="col-1 text-center">
						<div>
							<h5>4</h5>
						</div>
						<div style="height: 40px; display: flex; align-items: center; justify-content: center;">
							<p>Traducir texto</p>
						</div>
						<div style="height: 60px; display: flex; align-items: center; justify-content: center;">
							<img style="max-width: 100%; max-height: 100%;  cursor: pointer;" class="icon-head" src="assets/images/icons/qm4.png"  (click)="openImage('assets/images/icons/qm4.png')" />
						</div>
						<div id="myModal" class="modal" (click)="closeImage()">
							<span class="close">&times;</span>
							<img class="modal-content" style="max-width: 100%; max-height: 100%;  cursor: pointer;" [src]="modalImageSrc" />
						</div>
					</div>
					<!--INFO QUINTO QUIZ-->
					<div class="col-1 text-center">
						<div>
							<h5>5</h5>
						</div>
						<div style="height: 40px; display: flex; align-items: center; justify-content: center;">
							<p>Describir escrito</p>
						</div>
						<div style="height: 60px; display: flex; align-items: center; justify-content: center;">
							<img style="max-width: 100%; max-height: 100%;  cursor: pointer;" class="icon-head" src="assets/images/icons/qm5.png"  (click)="openImage('assets/images/icons/qm5.png')" />
						</div>
						<div id="myModal" class="modal" (click)="closeImage()">
							<span class="close">&times;</span>
							<img class="modal-content" style="max-width: 100%; max-height: 100%;  cursor: pointer;" [src]="modalImageSrc" />
						</div>
					</div>
					<!--INFO SEXTO QUIZ-->
					<div class="col-1 text-center">
						<div>
							<h5>6</h5>
						</div>
						<div style="height: 40px; display: flex; align-items: center; justify-content: center;">
							<p>Dictado</p>
						</div>
						<div style="height: 60px; display: flex; align-items: center; justify-content: center;">
							<img style="max-width: 100%; max-height: 100%;  cursor: pointer;" class="icon-head" src="assets/images/icons/qm6.png"  (click)="openImage('assets/images/icons/qm6.png')" />
						</div>
						<div id="myModal" class="modal" (click)="closeImage()">
							<span class="close">&times;</span>
							<img class="modal-content" style="max-width: 100%; max-height: 100%;  cursor: pointer;" [src]="modalImageSrc" />
						</div>
					</div>
					<!--INFO SEPTIMO QUIZ-->
					<div class="col-1 text-center">
						<div>
							<h5>7</h5>
						</div>
						<div style="height: 40px; display: flex; align-items: center; justify-content: center;">
							<p>Repetir</p>
						</div>
						<div style="height: 60px; display: flex; align-items: center; justify-content: center;">
							<img style="max-width: 100%; max-height: 100%;  cursor: pointer;" class="icon-head" src="assets/images/icons/qm7.png"  (click)="openImage('assets/images/icons/qm7.png')" />
						</div>
						<div id="myModal" class="modal" (click)="closeImage()">
							<span class="close">&times;</span>
							<img class="modal-content" style="max-width: 100%; max-height: 100%;  cursor: pointer;" [src]="modalImageSrc" />
						</div>
					</div>
					<!--INFO OCTAVO QUIZ-->
					<div class="col-1 text-center">
						<div>
							<h5>8</h5>
						</div>
						<div style="height: 40px; display: flex; align-items: center; justify-content: center;">
							<p>Leer voz alta</p>
						</div>
						<div style="height: 60px; display: flex; align-items: center; justify-content: center;">
							<img style="max-width: 100%; max-height: 100%;  cursor: pointer;" class="icon-head" src="assets/images/icons/qm8.png"  (click)="openImage('assets/images/icons/qm8.png')" />
						</div>
						<div id="myModal" class="modal" (click)="closeImage()">
							<span class="close">&times;</span>
							<img class="modal-content" style="max-width: 100%; max-height: 100%;  cursor: pointer;" [src]="modalImageSrc" />
						</div>
					</div>
					<!--INFO NOVENO QUIZ-->
					<div class="col-1 text-center">
						<div>
							<h5>9</h5>
						</div>
						<div style="height: 40px; display: flex; align-items: center; justify-content: center;">
							<p>Describir oral</p>
						</div>
						<div style="height: 60px; display: flex; align-items: center; justify-content: center;">
							<img style="max-width: 100%; max-height: 100%;  cursor: pointer;" class="icon-head" src="assets/images/icons/qm9.png"  (click)="openImage('assets/images/icons/qm9.png')" />
						</div>
						<div id="myModal" class="modal" (click)="closeImage()">
							<span class="close">&times;</span>
							<img class="modal-content" style="max-width: 100%; max-height: 100%;  cursor: pointer;" [src]="modalImageSrc" />
						</div>
					</div>
					<!--INFO DECIMO QUIZ-->
					<div class="col-1 text-center">
						<div>
							<h5>10</h5>
						</div>
						<div style="height: 40px; display: flex; align-items: center; justify-content: center;">
							<p>Intérprete</p>
						</div>
						<div style="height: 60px; display: flex; align-items: center; justify-content: center;">
							<img style="max-width: 100%; max-height: 100%;  cursor: pointer;" class="icon-head" src="assets/images/icons/qm10.png" (click)="openImage('assets/images/icons/qm10.png')" />
						</div>
						<div id="myModal" class="modal" (click)="closeImage()">
							<span class="close">&times;</span>
							<img class="modal-content" style="max-width: 100%; max-height: 100%;  cursor: pointer;" [src]="modalImageSrc" />
						</div>
					</div>
				</div>
					<!-- ********** TOGLES ********** -->
					<div>
							<div class="text-center">
								<div class="d-flex" style="padding-bottom: 20px;padding-top: 20px;">
										<div class="col-2"></div>
											<div class="col-1">
													<nb-toggle labelPosition="end" formControlName="qm1" (change)="change('qm1', form.controls['qm1'].value)"></nb-toggle>
											</div>
											<div class="col-1">
													<nb-toggle labelPosition="end" formControlName="qm2" (change)="change('qm2', form.controls['qm2'].value)"></nb-toggle>
											</div>
											<div class="col-1">
													<nb-toggle labelPosition="end" formControlName="qm3" (change)="change('qm3', form.controls['qm3'].value)"></nb-toggle>
											</div>
											<div class="col-1">
													<nb-toggle labelPosition="end" formControlName="qm4" (change)="change('qm4', form.controls['qm4'].value)"></nb-toggle>
											</div>
											<div class="col-1">
													<nb-toggle labelPosition="end" formControlName="qm5" (change)="change('qm5', form.controls['qm5'].value)"></nb-toggle>
											</div>
											<div class="col-1">
													<nb-toggle labelPosition="end" formControlName="qm6" (change)="change('qm6', form.controls['qm6'].value)"></nb-toggle>
											</div>
											<div class="col-1">
													<nb-toggle labelPosition="end" formControlName="qm7" (change)="change('qm7', form.controls['qm7'].value)"></nb-toggle>
											</div>
											<div class="col-1">
													<nb-toggle labelPosition="end" formControlName="qm8" (change)="change('qm8', form.controls['qm8'].value)"></nb-toggle>
											</div>
											<div class="col-1">
													<nb-toggle labelPosition="end" formControlName="qm9" (change)="change('qm9', form.controls['qm9'].value)"></nb-toggle>
											</div>
											<div class="col-1">
													<nb-toggle labelPosition="end" formControlName="qm10" (change)="change('qm10', form.controls['qm10'].value)"></nb-toggle>
											</div>
									</div>
							</div>
					</div>
				<!-- ********** TIPOS DE ACTIVIDADES (READING, LISTENING, WRITING Y SPEAKING) ********** -->
				<div class="d-flex">
					<div class="col-2 card">
						<div class="row d-flex">
								<div class="col-6">
										<h6>Reading</h6><br>
										<h6>Listening</h6><br>
										<h6>Writing</h6><br>
										<h6>Speaking</h6>
								</div>
								<div class="col-6" style="text-align: end;">
										<h6>2</h6><br>
										<h6>2</h6><br>
										<h6>1</h6><br>
										<h6>2</h6>
								</div>
						</div>
					</div>
					<!--CHECK PRIMER QUIZ-->
					<div class="col-1 text-center">
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
					</div>
					<!--CHECK SEGUNDO QUIZ-->
					<div class="col-1 text-center">
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
					</div>
					<!--CHECK TERCER QUIZ-->
					<div class="col-1 text-center">
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
					</div>
					<!--CHECK CUARTO QUIZ-->
					<div class="col-1 text-center">
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
					</div>
					<!--CHECK QUINTO QUIZ-->
					<div class="col-1 text-center">
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
					</div>
					<!--CHECK SEXTO QUIZ-->
					<div class="col-1 text-center">
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
					</div>
					<!--CHECK SEPTIMO QUIZ-->
					<div class="col-1 text-center">
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
					</div>
					<!--CHECK OCTAVO QUIZ-->
					<div class="col-1 text-center">
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
					</div>
					<!--CHECK NOVENO QUIZ-->
					<div class="col-1 text-center">
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
					</div>
					<!--CHECK DECIMO QUIZ-->
					<div class="col-1 text-center">
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: rgba(0, 0, 0, 0); pointer-events: none;"></nb-icon></div>
						<div><nb-icon class="icon m-1" icon="checkmark" style="color: green; pointer-events: none;"></nb-icon></div>
					</div>
				</div>
		</nb-card-body>
	</form>
</nb-card>

