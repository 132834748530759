<div class="sticky-top sticky-top-header">
    <div class="modal-header tittle">
        <!--div class="col-sm-1">
        <mat-icon class="icon-tittle icon-size-small pointer" (click)="atras('')">redo</mat-icon>
    </div>
    <div class="col-sm-1">
        <mat-icon class="icon-tittle icon-size-small pointer" (click)="adelante('')">undo</mat-icon>
    </div-->
        <div class="col-sm-1">
            <mat-icon class="icon-tittle icon-size-small pointer" (click)="save(type)">save</mat-icon>
        </div>
        <div class="col-sm-7 align-self-center text-center">
            <span *ngIf="node.id !== 'temp'; else newNodeTitle"
                class="textoFontSize08 font-weight-bold span-text-center">
                {{ 'NODOS.GRAFO' | translate }}: {{node.label}}
            </span>
            <ng-template #newNodeTitle>
                <span class="textoFontSize08 font-weight-bold span-text-center">
                    {{'NODOS.REGISTRARNODO' | translate}}
                </span>
            </ng-template>
        </div>
        <div class="col-sm-2 align-self-center text-center">
            <button type="button" class="close pointer" [ngStyle]="{'color':'rgb(29, 73, 153)'}" data-dismiss="modal"
                (click)="closeModal('')">
                <span class="textoFontSize18 font-weight-bold span-text-center" aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <!--div class="modal-header" align="center">
    <div class="col-sm-10 align-self-center text-center">
        <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{ 'TASK.TAREAS' | translate }}</span>
    </div>
    <div class="col-sm-2 align-self-center text-center">
        <button type="button" class="close pointer" [ngStyle]="{'color':'rgb(33, 76, 152)'}" data-dismiss="modal"
            (click)="closeModal('')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>-->
    <div class="options">
        <div class="row">
            <div class="modal fade w-100 max-vh-75" data-keyboard="false" data-backdrop="static" id="descModal"
                tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            {{ 'NODEFORMCOMPONENT.DESCRIPTION' | translate }}
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body well">
                            <textarea rows="5" class="form-control" id="message-text"
                                #descInput>{{node.description}}</textarea>

                            <div class="text-right well">
                                <button mat-button class="mt-1" data-dismiss="modal" type="submit"
                                    (click)="updateDescription(descInput.value)">SAVE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dropdown help" id="drop" style="z-index: 1;">
                <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <mat-icon class="icon-head icon-size-medium pointer"
                        ngbTooltip="{{'NODOS.INFORMATION' | translate }}" svgIcon="information"></mat-icon>
                </a>

                <div id="dropMenu" class="dropdown-menu p-0 w-75 h-auto" aria-labelledby="dropdownMenuLink"
                    style="min-width: 30vw;">
                    <app-node-form [desc]="updateDesc" [idCurso]="idCurso" [idMapa]="idMapa"
                        (newNode)="receiveNewNodeData($event)" (changed)="setPendingChanges($event)" #nodeForm>
                    </app-node-form>

                    <!--<app-quiz-form *ngIf="type === 'quiz'" [desc]="updateDesc" [idCurso]="idCurso"
                                    [idMapa]="idMapa"
                                    [type] = "quiz"
                                    (newNode)="receiveNewNodeData($event)"
                                    (changed)="setPendingChanges($event)"
                                    #nodeForm>
                    </app-quiz-form>-->
                </div>
            </div>
            <div class="container-Header-group2">
                <mat-icon class="icon-head  pointer" color="primary" data-toggle="tooltip" data-placement="top"
                    ngbTooltip="{{'NODOS.HELP' | translate }}" svgIcon="askforhelp"></mat-icon>

                <mat-icon class="icon-head  pointer" ngbTooltip="{{'NODOS.BOOK' | translate }}">menu_book</mat-icon>

                <mat-icon (click)="closeModal('')" class="icon-head  pointer"
                    ngbTooltip="{{'NODOS.GRAFO' | translate }}">reduce_capacity</mat-icon>

                <mat-icon class="icon-head  pointer" ngbTooltip="{{'NODOS.SHARE' | translate }}">publish</mat-icon>

                <mat-icon class="icon-head pointer" [matMenuTriggerFor]="menuRedesSociales"
                    ngbTooltip="{{'NODOS.SHARE' | translate }}">share</mat-icon>

                <mat-menu #menuRedesSociales="matMenu">
                    <div class="row mx-auto align-items-center justify-content-center">
                        <div class="col-lg-3" *ngFor="let btn of buttons ;let i = index">
                            <button *ngIf="i!=4" class="button-redes mt-2 pointer"
                                [ngStyle]="{'background-color': btn.color}" (click)="enviarRedesSociales(btn.id)">
                                <a class="enlace_desactivado" target="_blank"><i class="{{btn.icon}}"
                                        style="font-size:24px; color: white"></i></a>
                            </button>
                            <button *ngIf="i==4" class="mt-2" [ngStyle]="{'color': btn.color}">
                                <mat-icon class="button-salware pointer" svgIcon={{btn.icon}}
                                    (click)="seleccionarDestinatario()"></mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-menu>

                <mat-icon [matMenuTriggerFor]="menu" class="icon-head  pointer"
                    ngbTooltip="{{'NODOS.MESSAGE' | translate }}">sms</mat-icon>

                <mat-menu class="w-100" #menu="matMenu">
                    <button mat-menu-item>
                        <mat-icon>comment</mat-icon>
                        <span>{{'QUIZZES.COMENTARIO' | translate }}</span>
                    </button>
                    <button mat-menu-item>
                        <mat-icon>error_outline</mat-icon>
                        <span>{{'QUIZZES.ERROR' | translate }}</span>
                    </button>
                    <button mat-menu-item>
                        <mat-icon>flag</mat-icon>
                        <span>{{'QUIZZES.REPORTE' | translate }}</span>
                    </button>
                </mat-menu>

                <mat-icon class="icon-head  pointer" (click)="imprimir()" ngbTooltip="{{'NODOS.PRINT' | translate }}">
                    print</mat-icon>

                <mat-icon class="icon-head  pointer" ngbTooltip="{{'NODOS.INFORMATION' | translate }}">format_italic
                </mat-icon>

                <mat-icon class="icon-head  pointer" ngbTooltip="{{'NODOS.VISIBILITY' | translate }}">visibility
                </mat-icon>
            </div>
        </div>
    </div>
</div>
