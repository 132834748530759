import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { finalize, map } from 'rxjs/operators';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { QuizModel } from 'src/app/core/models/quizzes';
import { GraphService } from 'src/app/core/services/graph/graph.service';
import { MastersService } from 'src/app/core/services/masters';
import { NodeService } from 'src/app/core/services/node/node.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { UtilsService } from 'src/app/core/services/shared/utils.service';
import { LocalStorage } from 'src/app/core/utils/local-storage';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalAceptarCancelarComponent } from 'src/app/shared/components/modal';

@Component({
    selector: 'app-quiz-edit-info',
    templateUrl: './quiz-edit-info.component.html',
    styleUrls: ['./quiz-edit-info.component.scss']
})
export class QuizEditInfoComponent implements OnInit, OnDestroy {
    @Input() quiz: QuizModel;
    @Input() courseId: number;
    @Input() graphId: number;
    @Output() send = new EventEmitter<boolean>();

    public form: UntypedFormGroup;
    newQuiz: any;
    isLoading: boolean = false;
    private _templateElements: any = []
    private _published:boolean = false

    validationMessages = {
        level: [],
        duration: [],
        ordinal: []
    };

	constructor(public graphServ: GraphService,
		public nodeService: NodeService,
		public quizService: QuizzesService,
		private fb: UntypedFormBuilder,
		private localStorage: LocalStorage,
		private translateService: TranslateService,
		private utilsService: UtilsService,
		public activeModal: NgbActiveModal,
		private toaster: ToasterService,
		private mastersService: MastersService,
		private modalService: NgbModal) {
		this.traducirOpciones();
		this.form = this.fb.group(
			{
				level: [0, Validators.required],
				duration: ['00:00', Validators.required],
				ordinal: [0, Validators.required],
				published: [false]
			}
		);
	}

    ngOnInit() {
            this.quizService.getQuiz(Number(this.quiz.idOriginal), this.courseId, this.graphId).subscribe(result => {
                this._published = result.quiz.published? true: false;
                this.publishedControl.patchValue(result.quiz.published ? true : false)
            })

        this.readData(this.quiz.id);
    }

    ngOnDestroy(): void {
    }

    get levelControl(): UntypedFormControl {
        if (this.form) {
            return (this.form.get('level') as UntypedFormControl);
        } else {
            return null;
        }
    }

    get durationControl(): UntypedFormControl {
        if (this.form) {
            return (this.form.get('duration') as UntypedFormControl);
        } else {
            return null;
        }
    }

    get ordinalControl(): UntypedFormControl {
        if (this.form) {
            return (this.form.get('ordinal') as UntypedFormControl);
        } else {
            return null;
        }
    }

    get publishedControl(): UntypedFormControl {
        if (this.form) {
            return (this.form.get('published') as UntypedFormControl);
        } else {
            return null;
        }
    }

    public async readData(id: string) {
        if (id !== 'temp') {
            this.durationControl.patchValue(this.utilsService.minutesToTime(this.quiz.duration));
            this.levelControl.patchValue(this.quiz.quizSwlevel);
            this.ordinalControl.patchValue(this.quiz.ordinal);

        }
    }

    public save() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const fv = this.form.value;
            const tmpQuiz = { ...this.quiz };

            tmpQuiz.quizSwlevel = fv.level;
            tmpQuiz.duration = this.utilsService.timeToMinutes(fv.duration);
            tmpQuiz.ordinal = fv.ordinal;
            tmpQuiz.published = fv.published ? Date.now() : null

            this.isLoading = true;
            this.quizService.createQuiz(this.courseId, this.graphId, tmpQuiz).pipe(map((res: any) => res.data), finalize(() => this.isLoading = false)).subscribe(
                res => {
                    this.quizService.currentQuiz.next(res);
                    this.toaster.success(this.translateService.instant('QUIZFORMCOMPONENT.SAVED'));
                },
                err => {
                    this.toaster.error(this.translateService.instant('QUIZFORMCOMPONENT.ERROR'));
                }
            );
        }
    }

    private traducirOpciones() {
        // Recupero el lenguaje
        const lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
        this.translateService.use(lang);

        this.translateService.get('VALIDACIONES.LEVELREQUIRED').subscribe((res: string) => {
            this.validationMessages.level.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.DURATIONREQUIRED').subscribe((res: string) => {
            this.validationMessages.duration.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.ORDINALREQUIRED').subscribe((res: string) => {
            this.validationMessages.ordinal.push({ type: 'required', message: res });
        });
    }

    closeModal(sendData?: any) {
        this.activeModal.close();
    }



    checkPublishVisibility(): boolean{
        // let isQuestionFilled:boolean = false
        // if(this._templateElements.length){
        //     this._templateElements.forEach(element => {
        //         if(element.elementsType.description.startsWith('q') && element.templateElementQuizz.length)
        //             isQuestionFilled = true
        //     });
        // }
        // if(this._templateElements.length && isQuestionFilled)
        //     return true
        // return false
        return true
    }

    /**
     * Publish or unpublish node
     * @param $ev Click event in the switch component
    */

    publish($ev){

        $ev.preventDefault()
        $ev.stopImmediatePropagation()
        let currentValue:boolean = !this.form.get('published').value
        let modalMessage:string = currentValue ? this.translateService.instant('QUIZFORMCOMPONENT.PUBLISHMSG') : this.translateService.instant('QUIZFORMCOMPONENT.UNPUBLISHMSG')
        let errorMessage:string = currentValue ? this.translateService.instant('GENERAL.KOPUBLISH') : this.translateService.instant('GENERAL.KOUNPUBLISH')
        let okMessage:string = currentValue ? this.translateService.instant('GENERAL.OKPUBLISH') : this.translateService.instant('GENERAL.OKUNPUBLISH')

        //Open modal message alert to confirm the selection

        const modalRef = this.modalService.open(ModalAceptarCancelarComponent,
            {
                scrollable: true,
                windowClass: MODAL_DIALOG_TYPES.W30
            });


        modalRef.componentInstance.mensaje = modalMessage

        modalRef.result.then((result: boolean) => {
            if (result) {
                this.mastersService.setPublishType('quiz',Number(this.quiz.idOriginal),currentValue).subscribe(result => {
                    if(result.data){
                        this.toaster.success(okMessage)
                        this.form.get('published').setValue(currentValue) // Update form value
                    }
                    else
                        this.toaster.error(this.translateService.instant('QUIZFORMCOMPONENT.KOPUBLISHCONTENT'))
                }, err => {
                    this.toaster.error(errorMessage)
                })

            }
        });
    }

}
